/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import DebitTypeLookup from '../../../../../../../Components/Lookup/DebitTypeLookup'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'

const AddDebitModal = ({
  mode,
  batch,
  setBatch,
  handleAction,
  handleClose,
  isBusyCreating,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleDebitTypeSelect = debitType => {
    if (debitType.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'This debit type is locked!'
      )

    clearValidation('DebitTypeId')

    setBatch({
      ...batch,
      ...debitType,
      debitTypeId: debitType.id,
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="debit"
      handleAction={handleAction}
      handleClose={handleClose}
      actionText="create"
      disableCreate={isBusyCreating}
      disableClose={isBusyCreating}
    >
      {isBusyCreating ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['DebitTypeId']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('debitType')}
              </label>
              <DebitTypeLookup
                onSelect={handleDebitTypeSelect}
                displayValue={batch.description}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('priority')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="QueuePriority"
                value={batch.priority}
                onChange={e => {
                  setBatch({
                    ...batch,
                    priority: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['Reference']?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('reference')}
              </label>
              <textarea
                className="form-control"
                value={batch.reference}
                onChange={e => {
                  clearValidation('Reference')
                  setBatch({
                    ...batch,
                    reference: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AddDebitModal
