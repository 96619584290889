/** @format */

import React, { useState } from 'react'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import localize from '../../../../../Global/Localize'
import PdfFileLookup from '../../../../../Components/Lookup/PdfFileLookup'

const EditAsset = ({ mode, closeModal, loadData, asset, setAsset }) => {
  const [loading, setLoading] = useState(false)
  async function handleSubmit() {
    setLoading(true)
    const { success } = await postData('/Asset/update-asset', asset, false)

    if (success) {
      showNotification('asset', 'success')
      setLoading(false)
      setAsset(null)
      loadData()
      handleCloseModal()
    }
    setLoading(false)
  }

  function handleCloseModal() {
    closeModal()
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="asset"
        handleClose={handleCloseModal}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'edit' ? 'update' : ''}
        footerChildren={
          <>
            <Checkbox
              id="procurementAssetFlagForDisposal"
              onCheck={e => {
                setAsset({
                  ...asset,
                  isFlaggedForDisposal: e,
                })
              }}
              checked={asset?.isFlaggedForDisposal}
            />
            <label
              htmlFor="procurementAssetFlagForDisposal"
              className="text-capitalize mb-3"
            >
              {localize('flagForDisposal')}?
            </label>
          </>
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.itemRegisterDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('accumulatedDepreciationChartOfAccount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    asset?.itemRegisterAssetTypeAccumulatedDepreciationAccountAccountName
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('depreciationExpenseChartOfAccount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    asset?.itemRegisterAssetTypeDepreciationExpenseAccountAccountName
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('gain-lossChartOfAccount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.itemRegisterAssetTypeGainLossAccountAccountName}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('chartOfAccount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.itemRegisterAssetTypeChartOfAccountAccountName}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('picture')}
                </label>
                <PdfFileLookup
                  displayValue={asset?.fileName}
                  acceptedFiles={['application/jpeg']}
                  onSelect={r => {
                    setAsset({
                      ...asset,
                      assetImage: r.bufferString,
                      fileName: r.fileName,
                      fileExtension: 'jpeg',
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('assetType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.itemRegisterAssetTypeDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('supplier')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.supplierDescription}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('department')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.departmentDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2 ">
                <label className="col-md-2 text-capitalize">
                  {localize('serialNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.serialNumber || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      serialNumber: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('manufacturer')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.manufacturer || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      manufacturer: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('model')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.model || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      model: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('tagNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.tagNumber || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      tagNumber: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('location')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.locationDescription}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('purchasePrice')}
                </label>
                <MaskNumber
                  defaultMaskValue={asset?.purchasePrice}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('openingBookValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={asset?.openingBookValue}
                  className="form-control"
                  onMaskNumber={e => {
                    setAsset({
                      ...asset,
                      openingBookValue: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('usefulLife')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.usefulLife || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      usefulLife: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('residualValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={asset?.residualValue}
                  className="form-control"
                  onMaskNumber={e => {
                    setAsset({
                      ...asset,
                      residualValue: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center ">
                <label className="col-md-1 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={asset?.remarks || ''}
                  onChange={e => {
                    setAsset({
                      ...asset,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditAsset
