/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
function EducationVenueLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('educationVenueLookup')}
      url="/lookup/EducationVenue/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('enforceInvestmentsBalance') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.enforceInvestmentsBalance.toString())}
            </span>
          ),
          sortBy: 'enforceInvestmentsBalance',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default EducationVenueLookup
