/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SelectableCommissions from '../../../../../Components/Selectables/SelectableCommissions'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

function DebitTypeModal({
  mode,
  onHideModal,
  setSelectedDebitType,
  selectedDebitType,
  tabLoading,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/DebitType/create-debit-type-with-commissions'
        : '/DebitType/update-debit-type-with-commissions'
    const { success } = await postData(
      url,
      {
        debitType: selectedDebitType || {},
        commissionDTOs:
          selectedDebitType?.commissions.filter(x => x.checked) || [],
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      closeModal()
      showNotification('Debit Type', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedDebitType(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-lg"
        modalTitle={'debitType'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        actionText={mode === 'add' ? localize('create') : localize('update')}
        hideUpdate={mode === 'view'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                disabled={mode === 'view'}
                id="isDebitTypesLocked"
                onCheck={e => {
                  setSelectedDebitType({
                    ...selectedDebitType,
                    isLocked: e,
                  })
                }}
                checked={selectedDebitType?.isLocked}
              />
              <label htmlFor="isDebitTypesLocked" className="text-capitalize">
                {localize('isLocked')}?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2 g-3">
              <div className="col-md-12">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['DebitType.Description']?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={selectedDebitType?.description}
                    type="text"
                    className="form-control"
                    onChange={e => {
                      clearValidation(['DebitType.Description'])
                      setSelectedDebitType({
                        ...selectedDebitType,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-4">
                    {localize('productCode')}
                  </label>
                  <EnumsServerCombo
                    disabled={mode === 'view'}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ProductCode"
                    value={selectedDebitType?.customerAccountTypeProductCode}
                    onChange={e =>
                      setSelectedDebitType({
                        ...selectedDebitType,
                        customerAccountTypeProductCode: e.value,
                        customerAccountTypeTargetProductDescription: null,
                        customerAccountTypeTargetProductId: null,
                      })
                    }
                  />
                </div>
              </div>

              {selectedDebitType?.customerAccountTypeProductCode == 1 && (
                <div className="col-md-6">
                  <div
                    className="with-validation d-flex align-items-center mb-2"
                    validation-message={
                      globalValidationErrors[
                        'DebitType.CustomerAccountTypeTargetProductDescription'
                      ]?.message
                    }
                  >
                    <label className="text-capitalize col-2">
                      {localize('product')}
                    </label>
                    {mode === 'view' ? (
                      <input
                        defaultValue={
                          selectedDebitType?.customerAccountTypeTargetProductDescription
                        }
                        type="text"
                        className="form-control"
                        disabled
                      />
                    ) : (
                      <SavingsProductLookup
                        displayValue={
                          selectedDebitType?.customerAccountTypeTargetProductDescription
                        }
                        onSelect={v => {
                          clearValidation([
                            'DebitType.CustomerAccountTypeTargetProductDescription',
                          ])
                          setSelectedDebitType(prev => ({
                            ...prev,
                            customerAccountTypeTargetProductDescription:
                              v.description,
                            customerAccountTypeTargetProductId: v.id,
                          }))
                        }}
                      />
                    )}
                  </div>
                </div>
              )}

              {selectedDebitType?.customerAccountTypeProductCode == 2 && (
                <div className="col-md-6">
                  <div
                    className="with-validation d-flex align-items-center mb-2"
                    validation-message={
                      globalValidationErrors[
                        'DebitType.CustomerAccountTypeTargetProductDescription'
                      ]?.message
                    }
                  >
                    <label className="text-capitalize col-2">
                      {localize('product')}
                    </label>

                    <LoanProductLookup
                      displayValue={
                        selectedDebitType?.customerAccountTypeTargetProductDescription
                      }
                      onSelect={v => {
                        clearValidation([
                          'DebitType.CustomerAccountTypeTargetProductDescription',
                        ])
                        setSelectedDebitType(prev => ({
                          ...prev,
                          customerAccountTypeTargetProductDescription:
                            v.description,
                          customerAccountTypeTargetProductId: v.id,
                        }))
                      }}
                    />
                  </div>
                </div>
              )}

              {selectedDebitType?.customerAccountTypeProductCode == 3 && (
                <div className="col-md-6">
                  <div
                    className="with-validation d-flex align-items-center mb-2"
                    validation-message={
                      globalValidationErrors[
                        'DebitType.CustomerAccountTypeTargetProductDescription'
                      ]?.message
                    }
                  >
                    <label className="text-capitalize col-2">
                      {localize('product')}
                    </label>

                    <InvestmentProductLookup
                      displayValue={
                        selectedDebitType?.customerAccountTypeTargetProductDescription
                      }
                      onSelect={v => {
                        clearValidation([
                          'DebitType.CustomerAccountTypeTargetProductDescription',
                        ])
                        setSelectedDebitType(prev => ({
                          ...prev,
                          customerAccountTypeTargetProductDescription:
                            v.description,
                          customerAccountTypeTargetProductId: v.id,
                        }))
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <SelectableCommissions
                            disabled={mode === 'view'}
                            setSelectableCommissionItem={setSelectedDebitType}
                            selectableCommissionItem={selectedDebitType}
                            prefix={'create-debit-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default DebitTypeModal
