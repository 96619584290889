/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { batchEntryStatus } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, putData } from '../../../../Helpers/webApi'
import ActionModal from '../../../ActionModal/ActionModal'
import Checkbox from '../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import NonBlockingLoader from '../../../Loaders/NonBlockingLoader'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'

function BatchEntriesTab({
  withdrawalSettlementBatchId,
  refresh,
  setRefresh,
  showRemoveMenu,
  hiddenColumns = [],
  showReset,
  status,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [data, setData] = useState({
    pageCollection: [],
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    status: status ?? batchEntryStatus.PENDING,
  })
  const [customerFilter, setCustomerFilter] = useState(0)
  const [selectedEntries, setSelectedEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [mode, setMode] = useState(null)
  const [isBusyUnSetting, setIsBusyUnSetting] = useState(false)

  const columns = [
    {
      label: 'select',
      template: r => (
        <div className="d-flex align-items-center">
          <Checkbox
            checked={selectedEntries?.find(entry => entry?.id === r?.id)}
            onCheck={v => {
              if (v) {
                setSelectedEntries([...selectedEntries, r])
                setSelectedEntry(r)
              } else {
                setSelectedEntries(
                  selectedEntries.filter(entry => entry?.id !== r?.id)
                )
                setSelectedEntry(null)
              }
            }}
          />
        </div>
      ),
    },
    {
      label: 'status',
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: 'notificationNumber',
      template: r => <>{r?.paddedWithdrawalNotificationSerialNumber}</>,
    },
    {
      label: 'notificationStatus',
      template: r => <>{r?.withdrawalNotificationStatusDescription}</>,
    },
    {
      label: 'settlementMode',
      template: r => (
        <>{r?.withdrawalNotificationWithdrawalSettlementModeDescription}</>
      ),
    },
    {
      label: 'customerName',
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: 'customerType',
      template: r => (
        <>
          {
            r?.withdrawalNotificationCustomerMembershipClassCustomerTypeDescription
          }
        </>
      ),
    },
    {
      label: 'ref_1(Account #)',
      template: r => <>{r?.withdrawalNotificationCustomerReference1}</>,
    },
    {
      label: 'ref_2(Membership #)',
      template: r => <>{r?.withdrawalNotificationCustomerReference2}</>,
    },
    {
      label: 'ref_3(Personal File #)',
      template: r => <>{r?.withdrawalNotificationCustomerReference3}</>,
    },
    {
      label: 'transactionReference',
      template: r => (
        <>{r?.withdrawalNotificationTransactionReferenceDescription}</>
      ),
    },
    {
      label: 'reference',
      template: r => <>{r?.reference}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setMode(null)
    setSelectedEntry(null)
  }

  const fetchBatchEntries = async () => {
    setIsBusy(true)

    const url =
      '/WithdrawalSettlementBatch/find-withdrawal-settlement-batches-by-withdrawal-settlement-batch-id-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        withdrawalSettlementBatchId,
      },
      false
    )

    if (success) {
      setData(data)
      if (refresh) setRefresh(false)
    }

    setIsBusy(false)
  }

  const handleRemove = async r => {
    if (r) {
      setSelectedEntries([r])
    }

    const url =
      '/WithdrawalSettlementBatch/remove-withdrawal-settlement-batch-entries'

    if (selectedEntries?.length === 0 && r === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select at least one entry to remove',
      })

      return
    }

    setIsBusy(true)
    const { success } = await putData(
      url,
      selectedEntries?.length === 0 ? [r] : selectedEntries,
      false
    )

    if (success) {
      fetchBatchEntries()
      setSelectAll(false)
      setSelectedEntries([])
      showNotification('Membership Withdrawal Settlement Batches', 'success')
      setSelectedEntry(null)
    }

    setIsBusy(false)
  }

  const handleReset = async () => {
    if (selectedEntry?.status !== batchEntryStatus.EXCEPTION_RAISED)
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only reset entries with status Exception Raised'
      )

    setIsBusyUnSetting(true)
    const url = `/WithdrawalSettlementBatch/unpost-withdrawal-settlement-batch-entry?withdrawalSettlementBatchEntryId=${selectedEntry?.id}`

    const { success } = await putData(url, {}, false)

    if (success) {
      fetchBatchEntries()
      showNotification('Membership Withdrawal Settlement Batches', 'success')
    }

    setIsBusyUnSetting(false)
  }

  useEffect(() => {
    fetchBatchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (refresh) {
      fetchBatchEntries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <>
      {mode === 'unPost' && (
        <ActionModal
          modalTitle="membershipWithdrawalSettlementBatch"
          handleAction={handleReset}
          handleClose={handleClose}
          confirmText="yes"
          cancelText="no"
          disableAction={isBusyUnSetting}
          disableClose={isBusyUnSetting}
        >
          {isBusyUnSetting ? (
            <Loader />
          ) : (
            <p>
              Do you want to reset
              {' ' + selectedEntry?.paddedWithdrawalNotificationSerialNumber} ?
            </p>
          )}
        </ActionModal>
      )}

      <div className="row">
        <div className="card relative">
          <div className="card-body">
            <div className="col-md-12 py-2">
              <div className="row mb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center col-md-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                {hiddenColumns?.length === 0 && (
                  <div className="col-md-2">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        onCheck={e => {
                          setSelectAll(e)

                          if (e) {
                            setSelectedEntries(data.pageCollection)
                          } else {
                            setSelectedEntries([])
                          }
                        }}
                        id="-for-batch-entries"
                        checked={selectAll}
                      />
                      <label htmlFor="-for-batch-entries" className="ms-2">
                        {localize('toggleSelection')} ?
                      </label>
                    </div>
                  </div>
                )}
                <div
                  className={`col-md-3 d-flex align-items-center gap-2 ${
                    hiddenColumns?.length > 0 && 'offset-md-2'
                  }`}
                >
                  <label className="col-3">{localize('status')}</label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BatchEntryStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center justify-content-end gap-2">
                  <label htmlFor="searchTable">{localize('search')}</label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    enumsKey="CustomerFilter"
                    value={customerFilter}
                    onChange={e => setCustomerFilter(e.value)}
                  />
                  <div className="d-flex align-items-center">
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      value={reqParams.filterText}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                          customerFilter,
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') fetchBatchEntries()
                      }}
                    />
                    <button
                      onClick={() => {
                        fetchBatchEntries()
                      }}
                      className="btn btn-primary ms-2"
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <SimpleTable
                useText
                columns={columns?.filter(
                  (_c, i) => hiddenColumns?.indexOf(i) === -1
                )}
                data={data.pageCollection}
                contextMenu={r => (
                  <>
                    {showRemoveMenu && (
                      <div
                        className="ctxt-menu-item text-capitalize text-danger"
                        onClick={() => {
                          handleRemove(r)
                        }}
                      >
                        <i className="uil uil-times-circle me-2"></i>
                        <span>{localize('remove')}</span>
                      </div>
                    )}
                    {showReset && (
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          setMode('unPost')
                          setSelectedEntry(r)
                        }}
                      >
                        <i className="uil uil-history me-2"></i>
                        <span>{localize('reset')}</span>
                      </div>
                    )}
                  </>
                )}
              />

              {(selectAll || selectedEntry) && mode !== 'unPost' && (
                <div className="my-2 d-flex justify-content-end align-items-center p-2 bg-light rounded">
                  <button className="btn btn-danger" onClick={handleRemove}>
                    {localize('remove')}
                  </button>
                </div>
              )}

              <Pager
                pageIndex={reqParams.pageIndex}
                pageSize={reqParams.pageSize}
                itemsCount={data.itemsCount}
                onPageChange={p => {
                  setReqParams({ ...reqParams, pageIndex: p })
                }}
              />
            </div>
          </div>
          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
    </>
  )
}

export default BatchEntriesTab
