/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _a = {
  accountsManagement: { en: 'accounts management', sw: 'usimamizi wa hesabu' },
  approve: {
    en: 'approve',
    sw: 'kuidhinisha',
  },
  applications: {
    en: 'Applications',
    sw: 'Maombi Ya Mapumziko',
  },
  approvals: {
    en: 'Approvals',
    sw: 'Vibali',
  },
  appraiser: {
    en: 'Appraiser',
    sw: 'Mhakiki',
  },
  appraise: {
    en: 'Appraise',
    sw: 'Tathmini',
  },
  appraisalSummary: {
    en: 'Appraisal Summary',
    sw: 'Muhtasari Wa Tathmini',
  },
  attendance: {
    en: 'Attendance',
    sw: 'Mahudhurio',
  },
  authorization: {
    en: 'Authorization',
    sw: 'Idhini',
  },
  add: {
    en: 'add',
    sw: 'ongeza',
  },
  addNew: {
    en: 'add new',
    sw: 'ongeza mpya',
  },
  agencyTypeDescription: {
    en: 'agency type description',
    sw: 'aina ya wakala',
  },
  advanceRate: {
    en: 'advance rate',
    sw: 'kiwango cha mapema',
  },
  applicableCharges: {
    en: 'applicable charges',
    sw: 'gharama zinazotumika',
  },
  amount: {
    en: 'amount',
    sw: 'idadi',
  },
  autowireMediumDescription: {
    en: 'autowire medium description',
    sw: 'maelezo ya media ya kiotomatiki',
  },
  annualPercentageYield: {
    en: 'annual percentage yield',
    sw: 'asilimia ya mavuno ya kila mwaka',
  },
  automateLedgerFeeCalculation: {
    en: 'automate ledger fee calculation',
    sw: 'otomatiki hesabu ya ada ya leja',
  },
  auditTrails: {
    en: 'audit trails',
    sw: 'njia za ukaguzi',
  },
  auditLogs: {
    en: 'audit logs',
    sw: 'kumbukumbu za ukaguzi',
  },
  averageDate: {
    en: 'average date',
    sw: 'kiwango cha wastani',
  },
  addressLine: {
    en: 'address line',
    sw: 'mstari wa anwani',
  },
  addressLine1: {
    en: 'address line 1',
    sw: 'mstari wa anwani 1',
  },
  addressLine2: {
    en: 'address line 2',
    sw: 'mstari wa anwani 2',
  },
  addressMobileLine: {
    en: 'mobile line',
    sw: 'anwani ya simu ya mkononi',
  },
  alternateChannelManagement: {
    en: 'alternate channel management',
    sw: 'usimamizi wa kituo mbadala',
  },
  administrativeDivisions: {
    en: 'administrative divisions',
    sw: 'Idara za utawala',
  },
  accountNumber: {
    en: 'account number',
    sw: 'nambari ya akaunti',
  },
  adminDivision: {
    en: 'admin division',
    sw: 'msimamizi wa mgawanyiko',
  },
  age: {
    en: 'age',
    sw: 'miaka',
  },
  atmTerminal: {
    en: 'atm terminal',
    sw: 'kituo cha atm',
  },
  agencyType: {
    en: 'agency type',
    sw: 'aina ya wakala',
  },
  alternateChannels: {
    en: 'alternate channels',
    sw: 'chaneli mbadala',
  },
  averageRate: {
    en: 'average rate',
    sw: 'kiwango cha wastani',
  },
  accountNumberPadding: {
    en: 'account number padding',
    sw: 'nambari ya akaunti',
  },
  answerType: {
    en: 'answer type',
    sw: 'aina ya jibu',
  },
  appraisalPeriod: {
    en: 'appraisal period',
    sw: 'kipindi cha tathmini',
  },
  addBank: {
    en: 'add bank',
    sw: 'ongeza benki',
  },
  autoLock: {
    en: 'auto lock',
    sw: 'funga kiotomatiki',
  },
  adHocStandingOrders: {
    en: 'ad hoc standing orders',
    sw: 'ad hoc standing orders',
  },
  allowance: {
    en: 'allowance',
    sw: 'posho',
  },
  addChequeTypes: {
    en: 'add cheque types',
    sw: 'ongeza aina ya hundi',
  },
  addEmployeeType: {
    en: 'add employee type',
    sw: 'Ongeza aina ya mfanyakazi',
  },
  accountName: {
    en: 'account name',
    sw: 'jina la akaunti',
  },
  accountType: {
    en: 'account type',
    sw: 'aina ya akaunti',
  },
  accountCategory: {
    en: 'account category',
    sw: 'kategoria ya akaunti',
  },
  accountDetermination: {
    en: 'account determination',
    sw: 'uamuzi wa akaunti',
  },
  absoluteCellValue: {
    en: 'absolute cell value',
    sw: 'thamani kamili ya seli',
  },
  accountCode: {
    en: 'account code',
    sw: 'nambari ya akaunti',
  },
  alternateChannelCharges: {
    en: 'alternate channel charges',
    sw: 'gharama mbadala za kituo',
  },
  annualPercentageRate: {
    en: 'annual percentage rate',
    sw: 'kiwango cha asilimia ya kila mwaka',
  },
  address: {
    en: 'address',
    sw: 'anwani',
  },
  addBranch: {
    en: 'add branch',
    sw: 'ongeza tawi',
  },
  addLoanDisbursmentMode: {
    en: 'add loan disbursment mode',
    sw: 'ongeza hali ya malipo ya mkopo',
  },
  addLoanRepaymentMode: {
    en: 'add loan repayment mode',
    sw: 'ongeza hali ya ulipaji mkopo',
  },
  addTreasury: {
    en: 'add treasury',
    sw: 'ongeza hazina',
  },
  addTeller: {
    en: 'add teller',
    sw: 'ongeza muuzaji',
  },
  addPettyCashier: {
    en: 'add petty cashier',
    sw: 'ongeza keshia mdogo',
  },
  addExternalAgency: {
    en: 'add external agency',
    sw: 'ongeza wakala wa nje',
  },
  assets: {
    en: 'assets',
    sw: 'mali',
  },
  attachedGLAccounts: {
    en: 'attached g/l accounts',
    sw: 'akaunti za g/l zilizoambatishwa',
  },
  addLevy: {
    en: 'add levy',
    sw: 'ongeza ushuru',
  },
  addRevenueTax: {
    en: 'add revenue tax',
    sw: 'ongeza kodi ya mapato',
  },
  addIndefiniteCharge: {
    en: 'add indefinite charge',
    sw: 'ongeza malipo kwa muda usiojulikana',
  },
  alternateChannelType: {
    en: 'alternate channel type',
    sw: 'aina mbadala ya kituo',
  },
  appraisalMultiplier: {
    en: 'Appraisal multiplier',
    sw: 'kizidishi tathmini',
  },
  aggregateCheckOffRecoveryMode: {
    en: 'aggregate check-off recovery mode',
    sw: 'angalia hali ya uokoaji kwa jumla',
  },
  amountInWords: {
    en: 'amount in words',
    sw: 'kiasi cha pesa kwa maneno',
  },
  autoWireMedium: {
    en: 'auto wire medium',
    sw: 'otomatiki ya kati',
  },
  assetTypes: {
    en: 'asset types',
    sw: 'aina ya mali',
  },
  accumulatedDepreciationChartOfAccountName: {
    en: 'accumulated depreciation g/l account name',
    sw: 'Chati ya iliyoshuka thamani iliyokusanywa ya jina la akaunti',
  },
  administrativeDivision: {
    en: 'administrative division',
    sw: 'idara ya utawala',
  },
  addressPostalCode: {
    en: 'address postal code',
    sw: 'msimbo',
  },
  addressStreet: {
    en: 'address street',
    sw: 'mtaa wa anwani',
  },
  addressCity: {
    en: 'address city',
    sw: 'mji wa anwani',
  },
  account: {
    en: 'account',
    sw: 'akaunti',
  },
  accountAlerts: {
    en: 'account alerts',
    sw: 'arifa za akaunti',
  },
  adhocQueries: {
    en: 'adhoc queries',
    sw: 'maswali ya adhoc',
  },
  addressEmail: {
    en: 'address email',
    sw: 'anwani ya barua pepe',
  },
  addressLandline: {
    en: 'address landline',
    sw: 'anwani ya simu ya nyumbani',
  },
  addressInternet: {
    en: 'address internet',
    sw: 'anwani ya mtandao',
  },
  applicationUserName: {
    en: 'application username',
    sw: 'jina la mtumiaji wa programu',
  },
  alternateChannelConfiguration: {
    en: 'alternate channel configuration',
    sw: 'mpangilio mbadala wa kituo',
  },
  approval: {
    en: 'approval',
    sw: 'idhini',
  },
  addressLocationLatitude: {
    en: 'address location latitude',
    sw: 'latitudo la anwani',
  },
  authorized: {
    en: 'authorized',
    sw: 'imeidhinishwa',
  },
  associates: {
    en: 'associates',
    sw: 'washirika',
  },
  addressAddressLine1: {
    en: 'address line 1',
    sw: 'anwani ya kwanza',
  },
  addressAddressLine2: {
    en: 'address line 2',
    sw: 'anwani ya pili',
  },
  accountAlertDetails: {
    en: 'account alert details',
    sw: 'maelezo ya arifa ya akaunti',
  },
  accountAlertPreferences: {
    en: 'account alert preferences',
    sw: 'mapendeleo ya arifa ya akaunti',
  },
  attendees: {
    en: 'attendees',
    sw: 'washiriki',
  },
  approvalRemarks: {
    en: 'approval remarks',
    sw: 'maoni ya idhini',
  },
  approvedBy: {
    en: 'approved by',
    sw: 'imeidhinishwa na',
  },
  approvalDate: {
    en: 'approval date',
    sw: 'tarehe ya idhini',
  },
  availableBalance: {
    en: 'available balance',
    sw: 'salio lililopo',
  },
  acquisitionHistory: {
    en: 'acquisition history',
    sw: 'historia ya ununuzi',
  },
  addressLocationLongitude: {
    en: 'address location longitude',
    sw: 'anwani ya eneo',
  },
  associateCollection: {
    en: 'associate collection',
    sw: 'kusanyiko wa washirika',
  },
  accumulatedDepreciationChartOfAccount: {
    en: 'accumulated depreciation g/l account',
    sw: 'Chati ya iliyoshuka thamani ya g/l',
  },
  adhocRegisterQueries: {
    en: 'adhoc register queries',
    sw: 'maswali ya daftari la adhoc',
  },
  authorizedBy: {
    en: 'authorized by',
    sw: 'imeidhinishwa na',
  },
  authorizationRemarks: {
    en: 'authorization remarks',
    sw: 'maoni ya idhini',
  },
  authorizedDate: {
    en: 'authorized date',
    sw: 'tarehe ya idhini',
  },
  accessControlList: {
    en: 'access control list',
    sw: 'orodha ya udhibiti wa ufikiaji',
  },
  above: {
    en: 'above',
    sw: 'juu',
  },
  adminstrativeDivisions: {
    en: 'adminstrative divisions',
    sw: 'idara za utawala',
  },
  amountGuaranteed: {
    en: 'amount guaranteed',
    sw: 'kiasi kilichohakikishwa',
  },
  amountPledged: {
    en: 'amount pledged',
    sw: 'kiasi kilichothibitishwa',
  },
  appraisalFactor: {
    en: 'appraisal factor',
    sw: 'kigezo cha tathmini',
  },
  auditRemarks: {
    en: 'audit remarks',
    sw: 'maoni ya ukaguzi',
  },
  areaChiefName: {
    en: 'area chief name',
    sw: 'jina la mkuu wa eneo',
  },
  areaChiefIdNumber: {
    en: 'area chief id number',
    sw: 'nambari ya kitambulisho cha mkuu wa eneo',
  },
  areaChiefPhoneNumber: {
    en: 'area chief phone number',
    sw: 'nambari ya simu ya mkuu wa eneo',
  },
  areaChiefLocation: {
    en: 'area chief location',
    sw: 'eneo la mkuu wa eneo',
  },
  areaChiefDelegateIdNumber: {
    en: 'area chief delegate id number',
    sw: 'nambari ya kitambulisho cha msaidizi wa mkuu wa eneo',
  },
  areaChiefDelegatePfNumber: {
    en: 'area chief delegate personal file number',
    sw: 'nambari ya faili ya msaidizi wa mkuu wa eneo',
  },
  areaChiefDelegateSignatureDate: {
    en: 'area chief delegate signature date',
    sw: 'tarehe ya saini ya msaidizi wa mkuu wa eneo',
  },
  areaBoardMemberName: {
    en: 'area board member name',
    sw: 'jina la mjumbe wa bodi ya eneo',
  },
  areaBoardMemberIdNumber: {
    en: 'area board member id number',
    sw: 'nambari ya kitambulisho cha mjumbe wa bodi ya eneo',
  },
  areaBoardMemberPfNumber: {
    en: 'area board member personal file number',
    sw: 'nambari ya faili ya mjumbe wa bodi ya eneo',
  },
  areaBoardMemberSignatureDate: {
    en: 'area board member signature date',
    sw: 'tarehe ya saini ya mjumbe wa bodi ya eneo',
  },
  accruedInterest: {
    en: 'accrued interest',
    sw: 'riba iliyolipwa',
  },
  appropriation: {
    en: 'appropriation',
    sw: 'kutenga',
  },
  'authorized/rejectedBy': {
    en: 'authorized/rejected by',
    sw: 'imeidhinishwa/kataliwa na',
  },
  'authorized/rejectedDate': {
    en: 'authorized/rejected date',
    sw: 'tarehe ya idhini/kukataliwa',
  },
  accountStatus: {
    en: 'account status',
    sw: 'hali ya akaunti',
  },
  accountRemarks: {
    en: 'account remarks',
    sw: 'maoni ya akaunti',
  },
  appUserName: {
    en: 'app userName',
    sw: 'jina la mtumiaji wa programu',
  },
  accountSummary: {
    en: 'account summary',
    sw: 'muhtasari wa akaunti',
  },
  areaChiefIdentityCardNumber: {
    en: 'area chief identity card number',
    sw: 'nambari ya kitambulisho cha chifu',
  },
  areaChiefSignatureDate: {
    en: 'area chief signature date',
    sw: 'tarehe ya saini ya chifu',
  },
  areaChiefDelegateName: {
    en: 'area chief delegate name',
    sw: 'jina la msaidizi wa chifu',
  },
  applicationEmail: {
    en: 'application email',
    sw: 'barua pepe',
  },
  applicationCreationDate: {
    en: 'application creation date',
    sw: 'tarehe ya kutengeneza',
  },
  applicationIsApproved: {
    en: 'application is approved',
    sw: 'maombi yameidhinishwa',
  },
  applicationIsLockedOut: {
    en: 'application is locked out',
    sw: 'maombi yamefungwa',
  },
  applicationLastActivityDate: {
    en: 'application last activity date',
    sw: 'tarehe ya shughuli ya mwisho ya programu',
  },
  applicationLastLockoutDate: {
    en: 'application last lockout date',
    sw: 'tarehe ya mwisho ya kufungwa kwa programu',
  },
  applicationLastLoginDate: {
    en: 'application last login date',
    sw: 'tarehe ya mwisho ya kuingia kwenye programu',
  },
  applicationLastPasswordChangedDate: {
    en: 'application last password changed date',
    sw: 'tarehe ya mwisho ya mabadiliko ya nenosiri la programu',
  },
  authorizedBranches: {
    en: 'authorized branches',
    sw: 'matawi yaliyoidhinishwa',
  },
  authorizedRoles: {
    en: 'authorized roles',
    sw: 'majukumu yaliyoidhinishwa',
  },
  'g/LAccountName': {
    en: 'g/l account name',
    sw: 'jina la akaunti ya g/l',
  },
  adjustments: {
    en: 'adjustments',
    sw: 'marekebisho',
  },
  adjustmentType: {
    en: 'adjustment type',
    sw: 'aina ya marekebisho',
  },
  annualInterestRate: {
    en: 'annual interest rate',
    sw: 'kiwango cha riba',
  },
  allowSelfGuarantee: {
    en: 'allow self guarantee',
    sw: 'ruhusu dhamana ya mtu mwenyewe',
  },
  additionalInfo: {
    en: 'additional info',
    sw: 'habari zaidi',
  },
  apportionTo: {
    en: 'apportion to',
    sw: 'gawanya kwa',
  },
  apportionedComponent: {
    en: 'apportioned component',
    sw: 'sehemu iliyogawanywa',
  },
  apportionedAmount: {
    en: 'apportioned amount',
    sw: 'kiasi kilichogawanywa',
  },
  auxiliarySettings: {
    en: 'auxiliary settings',
    sw: 'mipangilio',
  },
  appraisalDeposits: {
    en: 'appraisal deposits',
    sw: 'amana za tathmini',
  },
  auxiliaryGuarantorFactors: {
    en: 'auxiliary guarantor factors',
    sw: 'vipengele vya mdhamini wa ziada',
  },
  auxiliaryDisbursementFactors: {
    en: 'auxiliary disbursement factors',
    sw: 'vipengele vya utoaji wa ziada',
  },
  auxiliaryAppraisalFactors: {
    en: 'auxiliary appraisal factors',
    sw: 'vipengele vya tathmini ya ziada',
  },
  auxiliaryLendingConditions: {
    en: 'auxiliary lending conditions',
    sw: 'vipengele vya mikopo ya ziada',
  },
  amountApplied: {
    en: 'amount applied',
    sw: 'kiasi kilichoombwa',
  },
  'authorization/RejectionRemarks': {
    en: 'authorization/rejection remarks',
    sw: 'maoni ya idhini/kukataliwa',
  },
  'authorized/RejectedBy': {
    en: 'authorized/rejected by',
    sw: 'imeidhinishwa/kataliwa na',
  },
  'authorized/RejectedDate': {
    en: 'authorized/rejected date',
    sw: 'tarehe ya idhini/kukataliwa',
  },
  autowire: {
    en: 'auto wire',
    sw: 'uhamisho wa otomatiki wa fedha',
  },
  accruedInterestBatches: {
    en: 'accrued interest batches',
    sw: 'makundi ya riba iliyolipwa',
  },
  apportioned: {
    en: 'apportioned',
    sw: 'imegawanywa',
  },
  auditedBy: {
    en: 'audited by',
    sw: 'imeangaliwa na',
  },
  auditedDate: {
    en: 'audited date',
    sw: 'tarehe ya ukaguzi',
  },
  apportionUnallocatedFunds: {
    en: 'apportion unallocated funds',
    sw: 'gawanya fedha zisizogawanywa',
  },
  autoWire: {
    en: 'auto wire',
    sw: 'uhamisho wa otomatiki wa fedha',
  },
  addBatchDetails: {
    en: 'add credit batch entries',
    sw: 'add credit batch entries',
  },
  appropriationStatement: {
    en: 'appropriation statement',
    sw: 'taarifa ya matumizi',
  },
  apportionedTo: {
    en: 'apportioned to',
    sw: 'gawanya kwa',
  },
  apportionment: {
    en: 'apportionment',
    sw: 'mgawanyo',
  },
  attendeeDetails: {
    en: 'attendee details',
    sw: 'maelezo ya mshiriki',
  },
  apportionFrom: {
    en: 'apportion from',
    sw: 'gawanya kutoka',
  },
  apportionedFrom: {
    en: 'apportioned from',
    sw: 'kugawanywa kutoka',
  },
  accruedInterestBatch: {
    en: 'accrued interest batch',
    sw: 'kundi la riba iliyolipwa',
  },
  april: {
    en: 'april',
    sw: 'aprili',
  },
  august: {
    en: 'august',
    sw: 'agosti',
  },
  'appointmentCustomerAccount/GLAccount': {
    en: 'appointment customer/g/l account',
    sw: 'akaunti ya mteja wa uteuzi/akaunti ya g/l',
  },
  authOption: {
    en: 'auth option',
    sw: 'chaguo la idhini',
  },
  approvedDate: {
    en: 'approved date',
    sw: 'tarehe ya idhini',
  },
  adhocLoanInterestCapitalization: {
    en: 'adhoc loan interest capitalization',
    sw: 'Mtaji wa riba ya mkopo wa ADHOC',
  },
  actualInterest: {
    en: 'actual interest',
    sw: 'riba halisi',
  },
  adHocStandingOrderExecution: {
    en: 'adhoc standing order execution',
    sw: 'utekelezaji wa amri ya kusimama ya adhoc',
  },
  action: {
    en: 'action',
    sw: 'hatua',
  },
  alternateChannelsCharges: {
    en: 'alternate channels charges',
    sw: 'gharama mbadala za kituo',
  },
  approveMembershipWithdrawal: {
    en: 'approve membership withdrawal',
    sw: 'idhinisha uondoaji wa uanachama',
  },
  appraisal: {
    en: 'appraisal',
    sw: 'tathmini',
  },
  approveLoanRescheduleRequest: {
    en: 'approve loan reschedule request',
    sw: 'idhinisha ombi la kurekebisha mkopo',
  },
  attachment: {
    en: 'attachment',
    sw: 'kiambatanisho',
  },
  approveRefundsProcessing: {
    en: 'approve refunds processing',
    sw: 'idhinisha usindikaji wa marejesho',
  },
  apiCode: {
    en: 'api code',
    sw: 'nambari ya api',
  },
  apiCodeDescription: {
    en: 'api code description',
    sw: 'maelezo ya nambari ya api',
  },
  asAt: {
    en: 'as at',
    sw: 'kama kwa',
  },
  abilityToPayPerPeriod: {
    en: 'ability to pay per period',
    sw: 'uwezo wa kulipa kwa kila kipindi',
  },
  abilityToPayOverLoanTerm: {
    en: 'ability to pay over loan term',
    sw: 'uwezo wa kulipa kwa kipindi cha mkopo',
  },
  attachedLoansBalance: {
    en: 'attached loans balance',
    sw: 'baki ya mikopo iliyowekwa',
  },
  attachedCarryForwardsBalance: {
    en: 'attached carry forwards balance',
    sw: 'baki iliyowekwa',
  },
  amountAppraised: {
    en: 'amount appraised',
    sw: 'kiasi kilichotathminiwa',
  },
  attachedClearanceCharges: {
    en: 'attached clearance charges',
    sw: 'gharama za kusafisha zilizowekwa',
  },
  appraisalRemarks: {
    en: 'appraisal remarks',
    sw: 'maoni ya tathmini',
  },
  approvedAmount: {
    en: 'approved amount',
    sw: 'kiasi kilichoidhinishwa',
  },
  accountInformation: {
    en: 'account information',
    sw: 'taarifa ya akaunti',
  },
  averagePrincipalMobileLoansActive: {
    en: 'average principal mobile loans active',
    sw: 'kiwango cha wastani cha mikopo ya simu',
  },
  averagePrincipalMobileLoansClosed: {
    en: 'average principal mobile loans closed',
    sw: 'kiwango cha wastani cha mikopo ya simu iliyofungwa',
  },
  averageNetSalary: {
    en: 'average net salary',
    sw: 'kiwango cha wastani cha mshahara',
  },
  amountQualified: {
    en: 'amount qualified',
    sw: 'kiasi kilichoidhinishwa',
  },
  actualRunDate: {
    en: 'actual run date',
    sw: 'tarehe ya kufanya',
  },
  appraisedAmount: {
    en: 'appraised amount',
    sw: 'kiasi kilichotathminiwa',
  },
  appraisedNetIncome: {
    en: 'appraised net income',
    sw: 'mapato halisi yaliyotathminiwa',
  },
  appraisedAmountRemarks: {
    en: 'appraised amount remarks',
    sw: 'maoni ya kiasi kilichotathminiwa',
  },
  appraisedBy: {
    en: 'appraised by',
    sw: 'tathminiwa na',
  },
  appraisedDate: {
    en: 'appraised date',
    sw: 'tarehe ya tathmini',
  },
  approvedPrincipalPayment: {
    en: 'approved principal payment',
    sw: 'malipo ya msingi yaliyoidhinishwa',
  },
  approvedInterestPayment: {
    en: 'approved interest payment',
    sw: 'malipo ya riba yaliyoidhinishwa',
  },
  authorize: {
    en: 'authorize',
    sw: 'idhinisha',
  },
  AddressAddressLine1: {
    en: 'address line 1',
    sw: 'anwani ya kwanza',
  },
  applicationDomainName: {
    en: 'application domain name',
    sw: 'jina la kikoa cha programu',
  },
  approvalOption: {
    en: 'approval option',
    sw: 'chaguo la idhini',
  },
  auditOption: {
    en: 'audit option',
    sw: 'chaguo la ukaguzi',
  },
  attachmentMode: {
    en: 'attachment mode',
    sw: 'njia ya kiambatanisho',
  },
  allowDiscretionaryTopup: {
    en: 'allow discretionary topup',
    sw: 'ruhusu kuongeza kiasi',
  },
  addGuarantorSubstitutionRequest: {
    en: 'add guarantor substitution request',
    sw: 'ongeza ombi la kubadilisha mdhamini',
  },
  adHocGuarantorsReleasing: {
    en: 'ad hoc guarantors releasing',
    sw: 'kuachiliwa kwa wadhamini wa adhoc',
  },

  adHocLoanInterestCharging: {
    en: 'ad hoc loan interest charging',
    sw: 'malipo ya mkopo',
  },
  arrearagesRecovery: {
    en: 'arrearages recovery',
    sw: 'marejesho ya malimbikizo',
  },
  annualPercentage: {
    en: 'annual percentage',
    sw: 'asilimia ya kila mwaka',
  },
  adhocLoanGuarantorStatusAdjustment: {
    en: 'adhoc loan guarantor status adjustment',
    sw: 'marekebisho ya hali ya mdhamini wa mkopo',
  },
  adjustStatus: {
    en: 'adjust status',
    sw: 'rekebisha hali',
  },
  assignedTo: {
    en: 'assigned to',
    sw: 'imepangiwa kwa',
  },
  appendSignature: {
    en: 'append signature',
    sw: 'ongeza saini',
  },
  authorizeBulkMessageBatch: {
    en: 'authorize bulk message batch',
    sw: 'idhinisha kundi la ujumbe',
  },
  accountStatements: {
    en: 'account statements',
    sw: 'taarifa za akaunti',
  },
  accountStatementsArchived: {
    en: 'account statements archived',
    sw: 'taarifa za akaunti zilizohifadhiwa',
  },
  accountStatistics: {
    en: 'account statistics',
    sw: 'takwimu za akaunti',
  },
  adhocArrearsRecoveryAvailableBalance: {
    en: 'adhoc arrears recovery available balance',
    sw: 'baki inayopatikana ya marejesho ya malimbikizo',
  },
  adhocInterestCapitalizationBalance: {
    en: 'adhoc interest capitalization balance',
    sw: 'baki ya mtaji wa riba ya adhoc',
  },
  adhocInterestCapitalizationAnnualPercentageRate: {
    en: 'adhoc interest capitalization annual percentage rate',
    sw: 'kiwango cha asilimia ya kila mwaka cha mtaji wa riba ya adhoc',
  },
  amountApproved: {
    en: 'amount approved',
    sw: 'kiasi kilichoidhinishwa',
  },
  approverRemarks: {
    en: 'approver remarks',
    sw: 'maoni ya muidhinishaji',
  },
  allowDiscrepancyOnTopUp: {
    en: 'allow discrepancy on top up',
    sw: 'ruhusu tofauti kwenye ongezeko la kiasi',
  },
  accountsListing: {
    en: 'accounts listing',
    sw: 'orodha ya akaunti',
  },
  availableOverDraftLimit: {
    en: 'available overdraft limit',
    sw: 'kikomo cha mkopo wa ziada kinachopatikana',
  },
  accountDetails: {
    en: 'account details',
    sw: 'maelezo ya akaunti',
  },
  alternateChannelDetails: {
    en: 'alternate channel details',
    sw: 'maelezo ya kituo mbadala',
  },
  arrears: {
    en: 'arrears',
    sw: 'malimbikizo',
  },
  activeFixedDeposit: {
    en: 'active fixed deposit',
    sw: 'amana ya akiba',
  },
  alertTrigger: {
    en: 'alert trigger',
    sw: 'kichocheo cha arifa',
  },
  alertType: {
    en: 'alert type',
    sw: 'aina ya arifa',
  },
  attachedLoanBalance: {
    en: 'attached loan balance',
    sw: 'baki ya mkopo uliowekwa',
  },
  appraiserRemarks: {
    en: 'appraiser remarks',
    sw: 'maoni ya tathmini',
  },
  accountClosure: {
    en: 'account closure',
    sw: 'ufungaji wa akaunti',
  },
  accountClosureRequests: {
    en: 'account closure requests',
    sw: 'maombi ya kufunga akaunti',
  },
  approved: {
    en: 'approved',
    sw: 'imeidhinishwa',
  },
  approveAccountClosure: {
    en: 'approve account closure',
    sw: 'idhinisha kufunga akaunti',
  },
  accountClosureRequestLookup: {
    en: 'account closure request lookup',
    sw: 'tafuta ombi la kufunga akaunti',
  },
  authorizeFixedDeposit: {
    en: 'authorize fixed deposit',
    sw: 'idhinisha amana ya akiba',
  },
  accountStatement: {
    en: 'account statement',
    sw: 'taarifa ya akaunti',
  },
  automatedClearing: {
    en: 'automated clearing',
    sw: 'kusafisha kiotomatiki',
  },
  automatedChequeClearingJournals: {
    en: 'automated cheque clearing journals',
    sw: 'majarida ya kusafisha hundi kiotomatiki',
  },
  automatedChequeProcessing: {
    en: 'automated cheque processing',
    sw: 'usindikaji wa hundi kiotomatiki',
  },
  automatedChequeCatalogue: {
    en: 'automated cheque catalogue',
    sw: 'orodha ya hundi kiotomatiki',
  },
  approvedAmountRemarks: {
    en: 'approved amount remarks',
    sw: 'maoni ya kiasi kilichoidhinishwa',
  },
  auditSuperSaverPayable: {
    en: 'audit super saver payable',
    sw: 'ukaguzi wa malipo ya kuhifadhi kwa wingi',
  },
  authorizeSuperSaverPayable: {
    en: 'authorize super saver payable',
    sw: 'idhinisha malipo ya kuhifadhi kwa wingi',
  },
  agencyBalance: {
    en: 'agency balance',
    sw: 'baki ya wakala',
  },
  accountClosurePayment: {
    en: 'account closure payment',
    sw: 'malipo ya kufunga akaunti',
  },
  amountFormula: {
    en: 'amount formula',
    sw: 'fomula ya kiasi',
  },
  apportionments: {
    en: 'apportionments',
    sw: 'mgawanyo',
  },
  actualPrincipal: {
    en: 'actual principal',
    sw: 'mtaji halisi',
  },
  autowireMedium: {
    en: 'autowire medium',
    sw: 'media ya kiotomatiki',
  },
  'authorization/rejectionRemarks': {
    en: 'authorization/rejection remarks',
    sw: 'maoni ya idhini/kukataliwa',
  },
  activationFeesAccountNumber: {
    en: 'activation fees account number',
    sw: 'nambari ya akaunti ya ada ya kuanzisha',
  },
  accountSigningInstructions: {
    en: 'account signing instructions',
    sw: 'maagizo ya kusaini akaunti',
  },
  accountPreview: {
    en: 'account preview',
    sw: 'muhtasari wa akaunti',
  },
  'verification/authorizationRemarks': {
    en: 'verification/authorization remarks',
    sw: 'maoni ya uhakiki/idhini',
  },
  alternateChannelsRegister: {
    en: 'alternate channels register',
    sw: 'sajili njia mbadala',
  },
  activities: {
    en: 'activities',
    sw: 'shughuli',
  },
  appraisalRecommendation: {
    en: 'appraisal recommendation',
    sw: 'mapendekezo ya tathmini',
  },
  'agency/webPortalRequests': {
    en: 'agency banking & web portal requests',
    sw: 'maombi ya wakala/ wavuti',
  },
  agencyBankingWebPortalRequests: {
    en: 'agency banking & web portal requests',
    sw: 'maombi ya wakala/ wavuti',
  },
  appraiseeAnswer: {
    en: 'appraisee answer',
    sw: 'jibu la tathmini',
  },
  achieved: {
    en: 'achieved',
    sw: 'imefikiwa',
  },
  appraiserAnswer: {
    en: 'appraiser answer',
    sw: 'jibu la mtathmini',
  },
  appraisalPeriodLookup: {
    en: 'appraisal period lookup',
    sw: 'tafuta kipindi cha tathmini',
  },
  'account#': {
    en: 'account number',
    sw: 'nambari ya akaunti',
  },
  alternateChannelActivationRequest: {
    en: 'alternate channel activation request',
    sw: 'ombi la kuanzisha njia mbadala',
  },
  archived: {
    en: 'archived',
    sw: 'imehifadhiwa',
  },
  additionalNotes: {
    en: 'additional notes',
    sw: 'maelezo zaidi',
  },
  addDocument: {
    en: 'add document',
    sw: 'ongeza hati',
  },
  addItemRegister: {
    en: 'item register',
    sw: 'sajili kitu',
  },
  assetType: {
    en: 'asset type',
    sw: 'aina ya mali',
  },
  assetTypeLookup: {
    en: 'asset type lookup',
    sw: 'tafuta aina ya mali',
  },
  applicableLevies: {
    en: 'applicable levies',
    sw: 'kodi husika',
  },
  assetMovement: {
    en: 'asset movement',
    sw: 'harakati ya mali',
  },
  assetMovementHistories: {
    en: 'asset movement histories',
    sw: 'historia ya harakati ya mali',
  },
  availableBeds: {
    en: 'available beds',
    sw: 'vitanda vilivyopo',
  },
  addressLandLine: {
    en: 'address landline',
    sw: 'nambari ya simu ya nyumbani',
  },
  asset: {
    en: 'asset',
    sw: 'mali',
  },
  approvalStageInRole: {
    en: 'approval stage in role',
    sw: 'hatua ya idhini katika jukumu',
  },
  approvalStageInWorkflow: {
    en: 'approval stage in workflow',
    sw: 'hatua ya idhini katika mchakato',
  },
  assetMovements: {
    en: 'asset movements',
    sw: 'harakati ya mali',
  },
  assetRegisterLookup: {
    en: 'asset register lookup',
    sw: 'tafuta sajili ya mali',
  },
  accumulatedChartOfAccountName: {
    en: 'accumulated g/l account name',
    sw: 'jina la akaunti ya g/l iliyoshuka thamani',
  },
  autoShop: {
    en: 'auto shop',
    sw: 'duka la magari',
  },
  assetFinanceOrders: {
    en: 'asset finance orders',
    sw: 'amri za fedha za mali',
  },
  accumulatedDepreciation: {
    en: 'accumulated depreciation',
    sw: 'ilishuka thamani',
  },
  'assetLife(yrs)': {
    en: 'asset life (years)',
    sw: 'maisha ya mali (miaka)',
  },
  'age(inDays)': {
    en: 'age (in days)',
    sw: 'umri (siku)',
  },
  addDisciplinaryCase: {
    en: 'disciplinary case',
    sw: 'ongeza kesi ya nidhamu',
  },
  activity: {
    en: 'activity',
    sw: 'shughuli',
  },
  accounts: {
    en: 'accounts',
    sw: 'akaunti',
  },
  appraisalAction: {
    en: 'appraisal action',
    sw: 'hatua ya tathmini',
  },
  approvalAction: {
    en: 'approval action',
    sw: 'hatua ya idhini',
  },
  attachToOwedLoanProduct: {
    en: 'attach to owed loan product',
    sw: '',
  },
  applicationUserDesignation: {
    en: 'application user designation',
    sw: 'cheo cha mtumiaji wa programu',
  },
  administrationManagement: {
    en: 'administration management',
    sw: 'usimamizi wa utawala',
  },
  alternateChannelsManagement: {
    en: 'alternate channels management',
    sw: 'usimamizi wa njia mbadala',
  },
  alternateChannel: {
    en: 'alternate channel',
    sw: 'njia mbadala',
  },
}

export default _a
