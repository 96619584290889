/** @format */
import React from 'react'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'

const GoodsDispatchNoteEntriesItems = ({
  unpostedEntries,
  mode,
  entries,
  handleRemove,
}) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{formatCurrency(r.itemRegisterEstimatedUnitCost)}</>,
    },
    {
      label: localize('asset'),
      template: r => <>{r.formattedAssetName}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{r.quantity}</>,
    },
  ]
  return (
    <div>
      <SimpleTable
        columns={columns}
        data={mode === 'add' ? unpostedEntries : entries}
        contextMenu={
          mode !== 'add'
            ? null
            : r => (
                <>
                  <div
                    onClick={() => {
                      handleRemove(r)
                    }}
                    className="ctxt-menu-item"
                  >
                    <i className="uil uil-trash text-danger"></i>
                    {localize('remove')}
                  </div>
                </>
              )
        }
      />
    </div>
  )
}

export default GoodsDispatchNoteEntriesItems
