/** @format */

/* eslint-disable */
import React, { useContext } from 'react'
import TabPill from './TabPill'

import './tabbedworkspace.scss'
import { NavigationContext } from '../../Contexts/NavigationContext'
import localize from '../../Global/Localize'
import Swal from 'sweetalert2'
import { generateGuid } from '../../Helpers/extensions'

function TabbedWorkspace({ windows }) {
  const {
    activeComponent,
    navigatedComponent,
    navigateToComponent,
    setActiveComponent,
    setNavigatedParentCode,
    setNavigatedMenuCode,
  } = useContext(NavigationContext)

  async function closeAll() {
    const closingWindows = navigatedComponent.filter(
      x => !x.hasOpenModal && x.code !== 0
    )
    // const toCloseWindowsString = closingWindows
    //   .map(n => n.description ?? n.label)
    //   .join(', ')

    const { isConfirmed } = await Swal.fire({
      title: 'Do you want to close the following Windows?',
      text: `${closingWindows.length} windows`,
      showDenyButton: true,
      confirmButtonText: 'Yes, Close',
      denyButtonText: `No`,
    })

    if (isConfirmed) {
      const cantClose = [...navigatedComponent].filter(
        n => n.hasOpenModal || n.code === 0
      )
      const menu = cantClose[cantClose?.length - 1]
      if (menu) {
        navigateToComponent(cantClose)
        setActiveComponent(menu.component)
        setNavigatedParentCode(menu.parentCode)
        setNavigatedMenuCode(menu.code)
      }
    }
  }

  return (
    <div className="nav-tab">
      <div className="nav-tab-list">
        {windows.map((w, i) => (
          <TabPill
            key={`tabbedPill-${i}`}
            menu={w}
            active={w.component === activeComponent}
            hasOpenModal={w?.hasOpenModal}
          />
        ))}
        {windows?.length > 2 && (
          <div
            className="nav-tab-pill close-all text-white bg-danger float-right"
            onClick={closeAll}
          >
            <i className="uil uil-times-circle"></i>
            <span>{localize('closeAll')}</span>
          </div>
        )}
      </div>
      {windows.map((w, i) => (
        <div
          key={`tabbedWindow-${i}`}
          className="nav-content"
          style={{
            display: w.component === activeComponent ? 'block' : 'none',
          }}
        >
          {w.component}
        </div>
      ))}
    </div>
  )
}

export default TabbedWorkspace
