/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../../Global/Localize'
import { reportSpoolDateFilter } from '../../../../../../../../Global/enumeration'
import { formatCurrency } from '../../../../../../../../Helpers/formatters'
import ConstituentEntries from './ConstituentEntries/ConstituentEntries'

function ViewTemplateBasedReportItem({ mode, handleClose, selectedEntry }) {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        handleAction
        handleClose={handleClose}
        modalTitle="templateBasedReports"
        cancelText="close"
        modalClassName="modal-xl"
        hideUpdate
      >
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center with-validation">
              <label htmlFor="batchNumber" className="text-capitalize col-4">
                {localize('batchNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedEntry?.paddedReportSpoolBatchNumber}
                disabled
                id="batchNumber"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="reference" className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.reportSpoolReference}
                disabled
                id="reference"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="dateFilter" className="text-capitalize col-4">
                {localize('dateFilter')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.reportSpoolDateFilterDescription}
                disabled
                id="dateFilter"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="cutoffDate" className="text-capitalize col-4">
                {localize('cutoffDate')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.reportSpoolCutOffDate}
                disabled
                id="cutoffDate"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="startDate" className="text-capitalize col-4">
                {localize('startDate')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.reportSpoolDurationStartDate}
                disabled
                id="startDate"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="reportSpoolDurationEndDate"
                className="text-capitalize col-4"
              >
                {localize('endDate')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.reportSpoolDurationEndDate}
                disabled
                id="reportSpoolDurationEndDate"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="description" className="text-capitalize col-4">
                {localize('itemName')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.description}
                disabled
                id="description"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="cellValueComputationTag"
                className="text-capitalize col-4"
              >
                {localize('cellValueComputationTag')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.cellValueComputationTag}
                disabled
                id="cellValueComputationTag"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="cellValueComputationModeDescription"
                className="text-capitalize col-4"
              >
                {localize('cellValueComputationMode')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={
                  selectedEntry?.cellValueComputationModeDescription
                }
                disabled
                id="cellValueComputationModeDescription"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="statusDescription"
                className="text-capitalize col-4"
              >
                {localize('status')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.statusDescription}
                disabled
                id="statusDescription"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="cellReference" className="text-capitalize col-4">
                {localize('cellReference')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.cellReference}
                disabled
                id="cellReference"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="cellReference" className="text-capitalize col-4">
                {localize('cellReference')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedEntry?.cellReference}
                disabled
                id="cellReference"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="cellValue" className="text-capitalize col-4">
                {localize('cellValue')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  selectedEntry?.cellValue,
                  null,
                  null,
                  false
                )}
                disabled
                id="cellValue"
              />
            </div>
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2  row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('constituentEntries')}
          </div>
          <div className="row g-3">
            <ConstituentEntries entry={selectedEntry} />
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}

export default ViewTemplateBasedReportItem
