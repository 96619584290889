/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import Loader from '../Loaders/Loader'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Pager from '../Tables/Pager'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import GroupedTable from '../Tables/GroupedTable'

export const DebitCardRegisterLookup = ({ onSelect, displayValue }) => {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'Card Type',
    value: 0,
    column: 'cardTypeDescription',
  })
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
    debitCardRegisterFilter: 15,
  })

  const columns = [
    {
      label: localize('status'),
      template: item => <>{item?.statusDescription}</>,
    },
    {
      label: localize('cardType'),
      template: item => <>{item?.cardTypeDescription}</>,
    },
    {
      label: localize('issuingNetwork'),
      template: item => <>{item?.issuingNetworkDescription}</>,
    },
    {
      label: localize('primaryAccountNumber'),
      template: item => <>{item?.primaryAccountNumber}</>,
    },
    {
      label: localize('validFrom'),
      template: item => <>{formatDate(item?.validFrom)}</>,
    },
    {
      label: localize('expires'),
      template: item => <>{formatDate(item?.expires)}</>,
    },
    {
      label: localize('dailyLimit'),
      template: item => <>{formatCurrency(item?.dailyLimit)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: item => (
        <>{item?.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerType'),
      template: item => (
        <>
          {item?.customerAccountCustomerMembershipClassCustomerTypeDescription}
        </>
      ),
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: item => <>{item?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: item => <>{item?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('remarks'),
      template: item => <>{item?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: item => <>{item?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: item => <>{formatDate(item?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]
  const [modalActive, setModalActive] = useState(false)

  const groupingColumns = [
    { value: 0, column: 'cardTypeDescription' },
    { value: 1, column: 'issuingNetworkDescription' },
    { value: 2, column: 'statusDescription' },
    { value: 3, column: 'createdBy' },
    { value: 4, column: 'modifiedBy' },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannel/find-debit-card-register-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setTableData(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])
  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('debitCardRegisterLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex row align-items-center justify-content-between mb-3">
                  <div className="d-flex align-items-center col-md-3">
                    <label
                      style={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select ms-2"
                      style={{ maxWidth: 'fit-content' }}
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div
                    className="d-flex align-items-center gap-2 col-md-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 'fit-content',
                        minWidth: 'fit-content',
                      }}
                      value={groupBy?.value}
                      enumsKey="DebitCardRegisterPropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>

                  <div
                    className="d-flex align-items-center gap-2 col-md-5"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 'fit-content',
                        minWidth: 'fit-content',
                      }}
                      value={reqParams?.debitCardRegisterFilter}
                      enumsKey="DebitCardRegisterFilter"
                      onChange={({ value }) =>
                        setReqParams({
                          ...reqParams,
                          debitCardRegisterFilter: value,
                          pageIndex: 0,
                        })
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.text}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                          pageIndex: 0,
                        })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          loadData()
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <GroupedTable
                  columns={columns}
                  data={tableData?.pageCollection || []}
                  groupBy={groupBy}
                  canSelect
                  onSelect={r => {
                    onSelect(r)
                    setModalActive(false)
                  }}
                />
                <Pager
                  itemsCount={tableData?.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
