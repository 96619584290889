/** @format */
import localize from '../../../../../Global/Localize'
import { FixedDepositTypeTiersEntries } from './FixedDepositTypeTiersEntries'
import { postData } from '../../../../../Helpers/webApi'
import { useState } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

export const AddFixedDepositTypeTiers = ({
  selectedFixedDepositType,
  setSelectedFixedDepositType,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [fixedDepositTypeTierModel, setFixedDepositTypeTierModel] =
    useState(null)

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnAddFixedDepositTypeTier = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/FixedDepositType/add-fixed-deposit-type-graduated-scale',
      {
        fixedDepositTypeGraduatedScales:
          selectedFixedDepositType?.fixedDepositTypeGraduatedScales || [],
        fixedDepositTypeGraduatedScale: fixedDepositTypeTierModel || {},
        startingValue:
          selectedFixedDepositType?.fixedDepositTypeGraduatedScales.length === 0
            ? fixedDepositTypeTierModel?.startingValue
            : null,
      },
      false,
    )
    if (success) {
      setSelectedFixedDepositType({
        ...selectedFixedDepositType,
        fixedDepositTypeGraduatedScales: [
          ...selectedFixedDepositType.fixedDepositTypeGraduatedScales,
          data.fixedDepositTypeGraduatedScale,
        ],
      })
      setFixedDepositTypeTierModel(null)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('tierDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          {selectedFixedDepositType?.fixedDepositTypeGraduatedScales.length ===
            0 && (
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('startingValue')}
              </label>
              <MaskNumber
                defaultMaskValue={
                  fixedDepositTypeTierModel?.startingValue || ''
                }
                onMaskNumber={e =>
                  setFixedDepositTypeTierModel({
                    ...fixedDepositTypeTierModel,
                    startingValue: e.target.value,
                  })
                }
              />
            </div>
          )}
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['FixedDepositTypeGraduatedScale.RangeLowerLimit']
                ?.message
            }
          >
            <label className="col-3 text-capitalize">
              {localize('rangeLowerLimit')}
            </label>
            <MaskNumber
              className="form-control"
              defaultMaskValue={
                fixedDepositTypeTierModel?.rangeLowerLimit || ''
              }
              onMaskNumber={e =>
                setFixedDepositTypeTierModel({
                  ...fixedDepositTypeTierModel,
                  rangeLowerLimit: e.target.value,
                })
              }
            />
          </div>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['FixedDepositTypeGraduatedScale.Percentage']
                ?.message
            }
          >
            <label className="col-3 text-capitalize">
              {localize('percentage')}
            </label>
            <MaskPercentage
              defaultMaskValue={fixedDepositTypeTierModel?.percentage || ''}
              onMaskChange={e => {
                clearValidation('FixedDepositTypeGraduatedScale.Percentage')
                setFixedDepositTypeTierModel({
                  ...fixedDepositTypeTierModel,
                  percentage: e.target.value,
                })
              }}
            />
          </div>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div
              className="bg-light d-flex justify-content-end align-items-center p-2 mt-2"
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={handleOnAddFixedDepositTypeTier}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('add')}
              </button>
            </div>
          </>
        )}
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 120,
              marginBottom: -10,
            }}
          >
            {localize('tiers')}
          </div>
          <FixedDepositTypeTiersEntries
            selectedFixedDepositType={selectedFixedDepositType}
            setSelectedFixedDepositType={setSelectedFixedDepositType}
          />
        </div>
      </div>
    </>
  )
}
