/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import Pager from '../../../../Components/Tables/Pager'
import CreateEmployeeDisciplinaryCase from './CreateEmployeeDisciplinaryCase'
import Swal from 'sweetalert2'
import { loadMedia } from '../../../../Helpers/Modules/media'
import { viewPDF } from '../../../../Helpers/Files/PDF'

function DisciplinaryCases() {
  const [isBusy, setIsBusy] = useState(false)
  const [cases, setCases] = useState(null)
  const [selectedCase, setSelectedCase] = useState(null)
  const [search, setSearch] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: search,
  })
  const columns = [
    {
      label: localize('employeeName'),
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedEmployeeCustomerSerialNumber}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.employeeDesignationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.employeeBranchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.employeeDepartmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeEmployeeTypeDescription}</>,
    },
    {
      label: localize('incidentDate'),
      template: r => <>{formatDate(r?.incidentDate, true)}</>,
    },
    {
      label: localize('document'),
      template: r => <>{r?.fileName}</>,
    },
    {
      label: localize('title'),
      template: r => <>{r?.fileTitle}</>,
    },
    {
      label: localize('mimeType'),
      template: r => <>{r?.fileMIMEType}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    fetchCases()
    // eslint-disable-next-line
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function fetchCases() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeDisciplinaryCase/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setCases(data)
    }
    setIsBusy(false)
  }

  async function loadMediaItem(id) {
    let mediaItem = null
    setIsBusy(true)
    mediaItem = await loadMedia(id)
    setIsBusy(false)
    if (mediaItem)
      setPdf(`data:${mediaItem?.contentType};base64,${mediaItem?.content}`)
    else Swal.fire('No File', 'Cant load file', 'error')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('employees'),
          localize('employeeDisciplinaryCases'),
        ]}
      />
      <div className="card card-body bg-white">
        <div className="mb-2">
          <button
            onClick={() => {
              setSelectedCase(null)
              setModalMode('add')
            }}
            className="btn btn-success px-5"
          >
            {localize('add')}
          </button>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="mb-2 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'max-content' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  style={{ maxWidth: 'fit-content' }}
                  className="form-select"
                  value={reqParams?.pageSize}
                  onChange={e =>
                    setReqParams({ pageIndex: 0, pageSize: e.target.value })
                  }
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </div>

              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('search')}
                </label>

                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      filterText: search,
                    })
                    setRefresh(!refresh)
                  }}
                >
                  <input
                    type="search"
                    value={search}
                    className="form-control"
                    onChange={e => setSearch(e.target.value)}
                  />
                </form>
                <button
                  className="btn btn-primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>

            <SimpleTable
              columns={columns}
              data={cases?.pageCollection || []}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => loadMediaItem(r?.id)}
                  >
                    <i className="uil uil-file"></i>
                    <span>{localize('viewFile')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setSelectedCase(r)
                      setModalMode('edit')
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span>{localize('edit')}</span>
                  </div>
                </>
              )}
            />
            <div className="mt-2">
              <Pager
                itemsCount={cases?.itemsCount || 0}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </>
        )}
      </div>
      {pdf && viewPDF(pdf, false, () => setPdf(null), null)}
      {modalMode === 'edit' && selectedCase && (
        <CreateEmployeeDisciplinaryCase
          refresh={() => setRefresh(!refresh)}
          close={() => {
            setModalMode(null)
            setSelectedCase(null)
          }}
          mode={modalMode}
          selectedCase={selectedCase}
        />
      )}
      {modalMode === 'add' && !selectedCase && (
        <CreateEmployeeDisciplinaryCase
          refresh={() => setRefresh(!refresh)}
          close={() => {
            setModalMode(null)
            setSelectedCase(null)
          }}
          mode={modalMode}
          selectedCase={selectedCase}
        />
      )}
    </>
  )
}

export default DisciplinaryCases
