/** @format */

/* eslint-disable */
import React from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import ListClosingAndCatalogue from '../Reusables/ListClosingAndCatalogue/ListClosingAndCatalogue'

function Catalogue() {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankReconciliation'),
          localize('catalogue'),
        ]}
      />

      <ListClosingAndCatalogue />
    </>
  )
}

export default Catalogue
