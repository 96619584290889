/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import localize from '../../../Global/Localize'
import { chartOfAccountType } from '../../../Global/enumeration'
import { viewPDF } from '../../../Helpers/Files/PDF'
import { downloadCSV } from '../../../Helpers/blobs'
import { pdfStatementType } from '../../../Helpers/constants'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import { getData, postData } from '../../../Helpers/webApi'
import ExportButton from '../../Buttons/ExportButton/ExportButton'
import Checkbox from '../../Checkbox/Checkbox'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import DatePicker from '../../Date/DatePicker'
import Loader from '../../Loaders/Loader'
import ChartOfAccountsLookup from '../../Lookup/ChartOfAccountsLookup'
import ModalTemplate from '../../ModalTemplate/ModalTemplate'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import ViewJournalEntries from './ViewJournalEntries/ViewJournalEntries'
import ViewTransactionsJournal from './ViewTransactionsJournal'

function Transactions({ glAccount, hidePrintAndExport, hideTopInputs }) {
  const [isBusy, setIsBusy] = useState(false)
  const [glId, setGlId] = useState(glAccount?.chartOfAccountId)
  const [dv, setDV] = useState(glAccount?.chartOfAccountName)
  const [glType, setGlType] = useState(glAccount?.chartOfAccountAccountType)
  const [distRule, setDistRule] = useState(
    glAccount?.chartOfAccountCostDistributionRuleDescription
  )
  const [search, setSearch] = useState('')
  const [transactions, setTransactions] = useState(null)
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [transJournalsTrs, setTransJournalsTrs] = useState(null)
  const [pdf, setPdf] = useState(null)
  const [mode, setMode] = useState(null)
  const [params, setParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    chartOfAccountId: glId,
    text: '',
    journalEntryFilter: 0,
    transactionDateFilter: 1,
    tallyDebitsCredits: false,
    fetchJournalEntries: false,
    dateRequestsFilter: null,
    startDate: null,
    endDate: null,
    customDate: false,
  })

  const auth = useAuth()
  const trColumns = [
    { label: 'branch', template: r => <>{r?.branchDescription}</> },
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r?.journalCreatedDate, true)}</>,
    },
    { label: 'reference', template: r => <>{r?.journalReference}</> },
    {
      label: 'primaryDescription',
      template: r => <>{r?.journalPrimaryDescription}</>,
    },
    { label: 'debit', template: r => <>{formatCurrency(r?.debit)}</> },
    { label: 'credit', template: r => <>{formatCurrency(r?.credit)}</> },
    {
      label: 'runningBalance',
      template: r => <>{formatCurrency(r?.runningBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: 'secondaryDescription',
      template: r => <>{r?.journalSecondaryDescription}</>,
    },
    {
      label: 'transactionCode',
      template: r => <>{r?.journalTransactionCodeDescription}</>,
    },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    {
      label: 'valueDate',
      template: r => <>{formatDate(r?.journalValueDate, true)}</>,
    },
    { label: 'appUserName', template: r => <>{r?.applicationUserName}</> },
    {
      label: 'localCurrency',
      template: r => <>{r?.journalLocalCurrencyDescription}</>,
    },
    { label: 'costCenter', template: r => <>{r?.costCenterDescription}</> },
    {
      label: 'costDistributionRule',
      template: r => <>{r?.costDistributionRule}</>,
    },
    {
      label: 'costDistributionPercentage',
      template: r => <>{r?.costDistributionPercentage}</>,
    },
    {
      label: 'debitCredit',
      template: r => <>{formatCurrency(r?.debitCredit)}</>,
      headerTextAlign: 'right',
    },
  ]

  async function loadTransactions() {
    if (!glId) return

    setIsBusy(true)
    let { success, data } = await getData(
      '/journalEntry/find-general-ledger-transactions-by-chart-of-account-id-and-date-range-and-filter-in-page',
      { ...params, chartOfAccountId: glId },
      false
    )
    if (success) {
      setTransactions(data)
    }

    setIsBusy(false)
  }

  const handlePrint = async () => {
    setIsBusy(true)

    const url = '/Base64/print-general-ledger-transactions'

    const { success, data } = await postData(
      url,
      {
        generalLedgerAccount: {
          id: glId,
        },
        startDate: params.startDate,
        endDate: params.endDate,
        dateRequestsFilter: params.dateRequestsFilter,
        customDate: params.customDate,
        statementType: pdfStatementType.GENERAL_LEDGER_ACCOUNT,
        transactionDateFilter: params.transactionDateFilter,
      },
      false
    )

    if (success) {
      setPdf(
        `data:${data?.contentType ?? 'application/pdf'};base64,${data?.content}`
      )
    }

    setIsBusy(false)
  }

  console.log(pdf)

  const handleExportButton = async () => {
    setIsBusy(true)

    const url = '/Base64/export-general-ledger-transactions'

    const { success, data } = await postData(
      url,
      {
        branch: {
          id: auth?.user?.profile?.cbs_EmployeeBranchId,
        },
        generalLedgerAccount: {
          id: glId,
        },
        startDate: params.startDate,
        endDate: params.endDate,
        dateRequestsFilter: params.dateRequestsFilter,
        customDate: params.customDate,
        statementType: pdfStatementType.GENERAL_LEDGER_ACCOUNT,
        transactionDateFilter: params.transactionDateFilter,
      },
      false
    )

    if (success) {
      downloadCSV(data?.content)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setSelectedTransaction(null)
    setMode(null)
  }

  useEffect(() => {
    loadTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.chartOfAccountId, params.pageIndex, params.pageSize])

  return (
    <>
      {mode === 'viewEntries' && (
        <ViewJournalEntries
          entry={selectedTransaction}
          handleClose={handleClose}
        />
      )}

      {transJournalsTrs && (
        <ModalTemplate
          modalMode="view"
          handleClose={() => setTransJournalsTrs(null)}
          modalTitle="transactionJournals"
          modalClassName="modal-xl"
          hideUpdate
        >
          {isBusy ? (
            <Loader />
          ) : (
            <ViewTransactionsJournal transaction={transJournalsTrs} />
          )}
        </ModalTemplate>
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          {hideTopInputs ? null : (
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('chartOfAccount')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={dv}
                  onSelect={p => {
                    setDV(p?.name)
                    setGlType(p?.type)
                    setDistRule(p?.costDistributionRuleDescription)
                    setGlId(p.id)
                  }}
                  canSelectHeader
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('type')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={chartOfAccountType[glType]?.name}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize col-4 pe-1">
                  {localize('costDistributionRule')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={distRule}
                />
              </div>
            </div>
          )}
          <hr />
          {!hidePrintAndExport && (
            <div className="d-flex align-items-center gap-2 mb-3">
              <ExportButton
                handleExportButton={handlePrint}
                label="print"
                icon="uil uil-print"
              />
              <ExportButton handleExportButton={handleExportButton} />
              <button className="btn btn-primary d-flex align-items-center gap-2">
                <i className="uil uil-print"></i>
                <span className="text-capitalize">{localize('report')}</span>
              </button>
            </div>
          )}

          <div className="bg-light p-2 mb-3 rounded d-flex align-items-center justify-content-between">
            <div className="">
              <RecordsPerPage reqParams={params} setReqParams={setParams} />
            </div>

            <div className="d-flex align-items-center gap-2">
              <label
                style={{ minWidth: 'max-content' }}
                htmlFor=""
                className="text-capitalize"
              >
                {localize('filterBy')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="TransactionDateFilter"
                onChange={e =>
                  setParams({
                    ...params,
                    transactionDateFilter: e.value,
                  })
                }
                sort
                value={params.transactionDateFilter}
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                className="text-capitalize"
                style={{ minWidth: 'max-content' }}
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                onSelect={v => {
                  if (v.custom) {
                    setParams({
                      ...params,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setParams({
                      ...params,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
                value={{
                  startDate: params.startDate,
                  endDate: params.endDate,
                }}
              />
            </div>
            <form
              onSubmit={e => {
                e.preventDefault()
                setParams({ ...params, text: search })
              }}
              className="col-md-4 d-flex align-items-center gap-1"
            >
              <label className="text-capitalize">{localize('search')}</label>
              <EnumsServerCombo
                customContainerStyle={{
                  minWidth: 150,
                  maxWidth: 150,
                }}
                enumsKey="JournalEntryFilter"
                onChange={e =>
                  setParams({ ...params, journalEntryFilter: e.value })
                }
                value={params.journalEntryFilter}
              />
              <input
                type="search"
                className="form-control"
                placeholder={localize('search') + '...'}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => {
                  loadTransactions()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>

          <div className="row p-2">
            <SimpleTable
              columns={trColumns}
              data={transactions?.pageCollection}
              canClickOnce={params.fetchJournalEntries}
              onClickOnce={r => setSelectedTransaction(r)}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => setTransJournalsTrs(r)}
                  >
                    <i className="uil uil-eye"></i>
                    <span className="text-capitalize">{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setSelectedTransaction(r)
                      setMode('viewEntries')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span className="text-capitalize">
                      {localize('viewEntries')}
                    </span>
                  </div>
                </>
              )}
            />
          </div>

          <div
            className="row bg-light p-2 m-1 mb-2"
            style={{ borderRadius: 5 }}
          >
            <div className="py-1 d-flex gap-3 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="tallyDCGLTr"
                  checked={params?.tallyDebitsCredits}
                  onCheck={v => {
                    setParams({ ...params, tallyDebitsCredits: v })
                  }}
                />
                <label
                  htmlFor="tallyDCGLTr"
                  className={`text-capitalize ${
                    params?.tallyDebitsCredits ? 'text-primary' : ''
                  }`}
                >
                  {localize('tallyDebitCredits')}
                </label>
              </div>

              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="fetchGLEntries"
                  checked={params.fetchJournalEntries}
                  onCheck={v => {
                    setParams({ ...params, fetchJournalEntries: v })
                  }}
                />
                <label
                  htmlFor="fetchGLEntries"
                  className={`text-capitalize ${
                    params?.fetchJournalEntries ? 'text-primary' : ''
                  }`}
                >
                  {localize('fetchJournalEntries')}
                </label>
              </div>

              <div className="flex-1"></div>
              <Pager
                itemsCount={transactions?.itemsCount}
                pageSize={params.pageSize}
                pageIndex={params.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setParams({ ...params, pageIndex: p })}
              />
            </div>

            <div
              className="py-3 px-4 mt-2 ms-1 row"
              style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
            >
              <div
                className="bg-light row px-2 text-capitalize"
                style={{
                  position: 'absolute',
                  top: -12,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('accountSummary')}
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize">
                  {localize('balanceBF')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control ms-2 text-end"
                  value={formatCurrency(
                    transactions?.balanceBroughtFoward,
                    null,
                    null,
                    false
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize">{localize('debits')}</label>
                <input
                  type="text"
                  disabled
                  className="form-control ms-2 text-end"
                  value={formatCurrency(
                    transactions?.totalDebits,
                    null,
                    null,
                    false
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize">{localize('credits')}</label>
                <input
                  type="text"
                  disabled
                  className="form-control ms-2 text-end"
                  value={formatCurrency(
                    transactions?.totalCredits,
                    null,
                    null,
                    false
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="text-capitalize">
                  {localize('balanceCF')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control ms-2 text-end"
                  value={formatCurrency(
                    transactions?.balanceCarriedForward,
                    null,
                    null,
                    false
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {pdf && viewPDF(pdf, false, () => setPdf(null), null)}
    </>
  )
}

export default Transactions
