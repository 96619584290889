/** @format */

import { createContext, useState, useEffect } from 'react'
import { dashboardMenu } from '../Components/LeftSidebar/dashboardMenu'
import allMenus from '../Components/LeftSidebar/menus'

import './versionband.scss'

export const NavigationContext = createContext(null)

export function NavigationContextProvider({ children }) {
  const [navigatedComponent, navigateToComponent] = useState([])
  const [navigatedMenuCode, setNavigatedMenuCode] = useState(0)
  const [navigatedParentCode, setNavigatedParentCode] = useState(0)
  const [activeComponent, setActiveComponent] = useState()
  const [flattenedMenus, setFlattenedMenus] = useState([])

  useEffect(() => {
    openDashboard()
  }, [])

  function openDashboard() {
    setNavigatedMenuCode(dashboardMenu.code)
    setNavigatedParentCode(dashboardMenu.code)
    setActiveComponent(dashboardMenu.component)
    navigateToComponent([dashboardMenu])
  }

  useEffect(() => {
    if (!!!activeComponent) {
      let lastItem = navigatedComponent[navigatedComponent.length - 1]
      if (lastItem) {
        setNavigatedMenuCode(lastItem.code)
        setNavigatedParentCode(lastItem.code)
        setActiveComponent(lastItem.component)
      } else {
        openDashboard()
      }
    }
    // eslint-disable-next-line
  }, [activeComponent])

  useEffect(() => {
    let flat = []
    for (let m of allMenus) {
      for (let s of m.setup) {
        flat.push(s)
      }
      for (let s of m.operations) {
        flat.push(s)
      }
    }
    traverseAllMenus(flat)
  }, [])

  function traverseAllMenus(menus) {
    let items = []

    function drillThroughMenus(menus) {
      for (let m of menus) {
        if (m.children) drillThroughMenus(m.children)
        else items.push(m)
      }
    }

    drillThroughMenus(menus)

    setFlattenedMenus(items)
  }

  return (
    <NavigationContext.Provider
      value={{
        navigatedComponent,
        navigateToComponent,
        navigatedMenuCode,
        setNavigatedMenuCode,
        navigatedParentCode,
        setNavigatedParentCode,
        activeComponent,
        setActiveComponent,
        flattenedMenus,
      }}
    >
      {children}
      <div
        style={{
          position: 'fixed',
          zIndex: 9999999,
          width: '100vw',
          bottom: 0,
          left: 0,
          fontSize: 12,
          letterSpacing: 1,
          // pointerEvents: 'none',
          // background: '#5b73e8',
        }}
        className="d-flex bg-dark align-items-center justify-content-between py-1 px-2 g-2 text-white version-band"
      >
        <span>
          Copyright &copy; 2012 - {new Date().getFullYear()} Centrino
          Technologies. All rights reserved
        </span>
        <span>Version 1.0</span>
      </div>
    </NavigationContext.Provider>
  )
}
