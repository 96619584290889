/** @format */

import localize from '../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ProductSection } from '../../Global/enumeration'
import GroupedTable from '../Tables/GroupedTable'
import Pager from '../Tables/Pager'
import Swal from 'sweetalert2'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'

export const CustomerAccountsFundsTransferLookup = ({
  displayValue,
  selectedCustomerAccount,
  accountsFundsTransfer,
  onSelect,
}) => {
  const [prevCustomerAccount, setPrevCustomerAccount] = useState(
    selectedCustomerAccount
  )

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageCount: 10,
    includeBalances: true,
    includeProductDescription: true,
  })

  if (
    selectedCustomerAccount &&
    prevCustomerAccount !== selectedCustomerAccount
  ) {
    setPrevCustomerAccount(selectedCustomerAccount)
    setReqParams({
      ...reqParams,
      customerId: selectedCustomerAccount.customerId,
    })
  }

  const handleOnActivatedModal = r => {
    if (!selectedCustomerAccount) {
      Swal.fire('Error', 'You must first select a source account', 'error')
      setModalActive(false)
    } else {
      setModalActive(true)
    }
  }

  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (selectedCustomerAccount && modalActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  const [groupBy, setGroupBy] = useState({
    label: localize('productCode'),
    column: 'customerAccountTypeProductCodeDescription',
  })

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('penaltyBalance'),
      template: r => <>{r.penaltyBalance}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r.availableBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{r.loanClassificationDate}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r.loanCaseTotalAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{r.loanBalanceExpected}</>,
    },
    {
      label: localize('LoanPrepaidAmount'),
      template: r => <>{r.loanPrepaidAmount}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{r.loanDefaultAmount}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => <>{r.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{r.loanLossProvisionAmount}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{r.loanCaseDisbursedDate}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{r.loanLastPaymentDueDate}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r.loanLastPayment}</>,
    },
    {
      label: localize('loanTermMonths'),
      template: r => <>{r.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentage'),
      template: r => <>{r.loanCaseLoanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('loanRoundingType'),
      template: r => <>{r.loanCaseLoanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('availableOverdraftLimit'),
      template: r => <>{r.availableOverdraftLimit}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{r.carryForwardsBalance}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      label: localize('accountStatus'),
      column: 'statusDescription',
    },
    {
      label: localize('recordStatus'),
      column: 'recordStatusDescription',
    },
    {
      label: localize('createdBy'),
      column: 'createdBy',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('productCode'),
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      column: 'customerAccountTypeTargetProductDescription',
    },
    {
      label: localize('productSection'),
      column: 'customerAccountTypeTargetProductSectionDescription',
    },
    {
      label: localize('branch'),
      column: 'branchDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccountsFundsTransfer/find-customer-accounts-by-customer-id-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
      setTableData(data.pageCollection)
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={handleOnActivatedModal}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {accountsFundsTransfer}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={handleOnActivatedModal}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('customerAccountsLookup')} - {displayValue}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setModalActive(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          {isBusy ? (
            <Loader />
          ) : (
            <ModalBody className="bg-white p-2">
              <div className="d-flex align-items-center justify-content-space-between ">
                <div className="d-flex align-items-center float-right my-2 col-4">
                  <label
                    className="text-capitalize"
                    htmlFor="LoanProductLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="LoanProductLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('groupBy')}
                  </label>
                  <select
                    className="form-select text-capitalize"
                    style={{ width: 'fit-content' }}
                    defaultValue={groupBy.column}
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumns.find(
                          f => f.column === e.target.value
                        )?.label,
                        column: e.target.value,
                      })
                    }
                  >
                    {groupingColumns.map(({ label, column }) => (
                      <option
                        key={`LoanProductLookup_${column}`}
                        className="text-capitalize"
                        value={column}
                      >
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-auto me-3 text-capitalize">
                    {localize('productSection')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ProductSection"
                    value={reqParams?.productSection}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        productSection: e.value,
                      })
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}></div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <div className="d-flex align-items-center float-right my-2">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      defaultValue={reqParams.filterText}
                      onChange={e => setSearch(e.target.value)}
                      type="search"
                      className="form-control ms-3"
                      id="searchTable"
                    />
                  </div>
                </form>
              </div>
              <div className=" py-1">
                <GroupedTable
                  canSelect
                  onSelect={r => {
                    setModalActive(false)
                    onSelect(r)
                  }}
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </ModalBody>
          )}
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
