/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

function RoleLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: 'description',
      template: r => <>{r.description}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        useText
        columns={columns}
        title={localize('roleLookup')}
        url="/lookup/Role/find-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default RoleLookup
