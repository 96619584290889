/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'

export const DispatchCollection = ({ dispatchData,onRemoveDispatchCollection }) => {
  const columns = [
    {
      label: localize('customerName'),
      template: r => <>{r.fileRegisterCustomerFullName}</>,
    },
    {
      label: localize('source'),
      template: r => <>{r.sourceDepartmentDescription}</>,
    },
    {
      label: localize('destination'),
      template: r => <>{r.destinationDepartmentDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('carrier'), template: r => <>{r.carrier}</> },
  ]

  return (
    <SimpleTable
      columns={columns}
      data={dispatchData}
      contextMenu={r => (
        <>
          <div
            onClick={() => {
               onRemoveDispatchCollection(r)
            }}
            className="ctxt-menu-item"
          >
            <i className="uil uil-trash text-danger"></i>
            {localize('remove')}
          </div>
        </>
      )}
    />
  )
}
