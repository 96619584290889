/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import ListLoanDisbursement from '../../../../../Components/ReUsables/LoanDisbursement/ListLoanDisbursement'
import localize from '../../../../../Global/Localize'
import {
  BatchAuditOption,
  batchStatus,
} from '../../../../../Global/enumeration'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import VerifyLoanBatch from './VerifyLoanBatch/VerifyLoanBatch'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

const BatchVerification = () => {
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reload, setReload] = useState(false)
  const [loan, setLoan] = useState({
    batchAuditOption: Object.keys(BatchAuditOption)[0],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleClose = () => {
    setMode(null)
    setLoan({
      batchAuditOption: Object.keys(BatchAuditOption)[0],
    })
  }

  const handleSelectVerifyBatch = batch => {
    if (batch?.isLocked)
      return Swal.fire({
        icon: 'info',
        title: 'Operation not allowed!',
        text: 'This batch is already locked!',
      })

    if (
      batch.status !==
      (Object.keys(batchStatus)[0] ||
        batch.batchStatus !== Object.keys(batchStatus)[4])
    )
      return Swal.fire({
        icon: 'info',
        title: 'Operation not allowed!',
        text: `This batch  must be in either Pending or Deferred status!`,
      })

    setMode('verify')
    setLoan({
      ...batch,
      batchAuditOption: Object.keys(BatchAuditOption)[0],
    })
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleVerifyBatch = async () => {
    if (!loan.auditRemarks) {
      return GlobalValidationErrors.set({
        AuditRemarks: {
          message: 'Audit Remarks is required',
        },
      })
    }

    setIsBusy(true)

    const url = '/LoanDisbursementBatch/audit-loan-disbursement-batch'

    const { success } = await postData(
      url,
      {
        loanDisbursementBatch: loan,
        batchAuditOption: loan.batchAuditOption,
      },
      false
    )

    if (success) {
      handleClose()
      setReload(true)
      showNotification('Verify Loan Disbursement Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode === 'verify' && (
        <VerifyLoanBatch
          loan={loan}
          handleClose={handleClose}
          mode={mode}
          handleVerifyBatch={handleVerifyBatch}
          globalValidationErrors={globalValidationErrors}
          clearValidation={clearValidation}
          setLoan={setLoan}
          isBusy={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanDisbursement'),
          localize('batchVerification'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListLoanDisbursement
            showVerify
            hiddenColumns={[0]}
            handleVerifyBatch={handleSelectVerifyBatch}
            isCreation={isBusy}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  )
}

export default BatchVerification
