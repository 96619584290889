/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import DatePicker from '../../../../../Components/Date/DatePicker'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

function CarryForwardsInstallments() {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)

  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  const columns = [
    {
      label: localize('fullName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    { label: localize('amount'), template: r => <>{r.amount}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: `${localize('ref')}_1 (${localize('account')}#)`,
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')}#)`,
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')}#)`,
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-carry-forward-installments-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('carryFowardsInstallments'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : error ? (
                <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                  <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                  <div className="fs-5 text-danger">Failed to Load Data</div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="text-capitalize me-2">
                        {localize('status')}
                      </label>
                      <EnumsServerCombo
                        value={reqParams?.customerFilter}
                        customContainerStyle={{ width: '100%' }}
                        enumsKey={'CustomerFilter'}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        htmlFor="searchTable"
                        className="text-capitalize me-2"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                  </div>{' '}
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarryForwardsInstallments
