/** @format */

import React from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import PayrollNumbersTab from '../Tab/PayrollNumbersTab'

const ViewPayrollNumbers = ({
  mode,
  handleClose,
  selectedCustomer,
  payrollNumbers,
  handlePayrollRemove,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="payrollNumbers"
      hideUpdate
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      {isBusy ? (
        <Loader />
      ) : (
        <PayrollNumbersTab
          customerId={selectedCustomer?.id}
          onPayrollRemove={handlePayrollRemove}
          data={payrollNumbers}
        />
      )}
    </ModalTemplate>
  )
}

export default ViewPayrollNumbers
