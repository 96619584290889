/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import ReportTemplateLookup from '../../../../../../Components/Lookup/ReportTemplateLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { reportSpoolDateFilter } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { getTodaysDate, parseDate } from '../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Loader from '../../../../../../Components/Loaders/Loader'

const CreateTemplateReport = ({
  handleClose,
  handleAction,
  mode,
  selectedTemplate,
  setSelectedTemplate,
  isBusyCreatingOrUpdating,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectReportTemplate = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a template that is not locked!'
      )
    else if (r?.parentId !== null)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a template that is a has no parent!'
      )

    setSelectedTemplate({
      ...selectedTemplate,
      ...r,
      rootTemplateId: r.id,
      parentDescription: r.description,
      transactionDateFilter: selectedTemplate?.transactionDateFilter,
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText="create"
      modalTitle="templateBasedReport"
      disableClose={isBusyCreatingOrUpdating}
      disableCreate={isBusyCreatingOrUpdating}
    >
      {isBusyCreatingOrUpdating ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors?.['Description']?.message
              }
            >
              <label htmlFor="reportTemplate" className="text-capitalize col-4">
                {localize('reportTemplate')}
              </label>
              <ReportTemplateLookup
                displayValue={selectedTemplate?.parentDescription}
                onSelect={r => {
                  clearValidation('Description')
                  handleSelectReportTemplate(r)
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label htmlFor="reference" className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedTemplate?.spreadsheetCellReference}
                onChange={e => {
                  setSelectedTemplate({
                    ...selectedTemplate,
                    spreadsheetCellReference: e.target.value,
                  })
                }}
                id="reference"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label htmlFor="dateFilter" className="text-capitalize col-4">
                {localize('dateFilter')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ReportSpoolDateFilter"
                value={selectedTemplate?.reportSpoolDateFilter}
                onChange={e => {
                  setSelectedTemplate({
                    ...selectedTemplate,
                    transactionDateFilter: e.value,
                    reportSpoolDateFilter: e.value,
                    dateFilter: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
          {selectedTemplate?.reportSpoolDateFilter ===
          Object.keys(reportSpoolDateFilter)[2] ? (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label htmlFor="startDate" className="text-capitalize col-4">
                    {localize('startDate')}
                  </label>
                  <CustomDatePicker
                    name={'startDate'}
                    defaultDate={parseDate(getTodaysDate())}
                    onDateChange={date => {
                      setSelectedTemplate({
                        ...selectedTemplate,
                        durationStartDate: date,
                      })
                    }}
                    maxDate={selectedTemplate?.durationStartDate}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <label htmlFor="endDate" className="text-capitalize col-2">
                    {localize('endDate')}
                  </label>
                  <CustomDatePicker
                    name={'endDate'}
                    defaultDate={parseDate(getTodaysDate())}
                    onDateChange={date => {
                      setSelectedTemplate({
                        ...selectedTemplate,
                        durationEndDate: date,
                      })
                    }}
                    minDate={selectedTemplate?.durationEndDate}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label htmlFor="cutOffDate" className="text-capitalize col-4">
                    {localize('cutOffDate')}
                  </label>
                  <CustomDatePicker
                    name={'templateCutOffDate'}
                    defaultDate={parseDate(getTodaysDate())}
                    onDateChange={date => {
                      setSelectedTemplate({
                        ...selectedTemplate,
                        cutOffDate: date,
                        durationStartDate: date,
                        durationEndDate: date,
                      })
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateTemplateReport
