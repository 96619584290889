/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import { customerMembershipStatus } from '../../../../Global/enumeration'
import Checkbox from '../../../Checkbox/Checkbox'

const MembershipStatuses = ({
  selectedStatuses,
  handleSelectStatus,
  handleSelectAllStatuses,
}) => {
  const [selectAllStatuses, setSelectAllStatuses] = useState(false)

  const statuses = Object.entries(customerMembershipStatus)
    .map(status => ({
      valueDescription: status[1]?.name,
      value: status[0],
    }))
    .sort((a, b) => a.valueDescription.localeCompare(b.valueDescription))

  return (
    <>
      {statuses.map(status => (
        <div className="d-flex align-items-center justify-content-start gap-2">
          <Checkbox
            id={`membership-status-${status?.value}`}
            checked={selectedStatuses?.find(x => x?.value === status?.value)}
            onCheck={value => handleSelectStatus(status, value)}
          />
          <label
            htmlFor={`membership-status-${status?.value}`}
            className="ms-2"
          >
            {status?.valueDescription}
          </label>
        </div>
      ))}
      <div className="d-flex align-items-center justify-content-start gap-2  p-2 bg-light rounded">
        <Checkbox
          id={`membership-status-select-all`}
          checked={selectAllStatuses}
          onCheck={value => {
            setSelectAllStatuses(value)
            handleSelectAllStatuses(statuses, value)
          }}
        />
        <label htmlFor={`membership-status-select-all`} className="ms-2">
          {localize('toggleSelection')}
        </label>
      </div>
    </>
  )
}

export default MembershipStatuses
