/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ActionModal from '../../../../../../../Components/ActionModal/ActionModal'

export const CreditBatchImportModal = ({
  handleUpload,
  handleCloseCreditBatchImportModal,
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false)

  return (
    <ActionModal
      modalTitle="debitBatchEntry"
      handleClose={handleCloseCreditBatchImportModal}
      handleAction={handleUpload}
      confirmText="upload"
      cancelText="cancel"
    >
      <div className="row">
        <div className="col-12">
          <h5 className="text-center">
            See advanced for credit batch file format.
          </h5>
        </div>

        <div className="col-12">
          <h6
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="text-decoration-underline"
            style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
          >
            Show advanced
          </h6>
        </div>

        {showAdvanced && (
          <div className="col-12 d-flex align-items-start flex-column rounded p-2">
            <p>Row 1 = Header Record</p>
            <p>Row 1, Column 1 = Payroll Number</p>
            <p>Row 1, Column 2 = Customer Name</p>
            <p>Row 1, Column 3 = Multiplier</p>
            <p>Row 1, Column 4 = Basis Value</p>
          </div>
        )}
      </div>
    </ActionModal>
  )
}
