/** @format */
import { formatDate } from '../../../../../../../Helpers/formatters'
import localize from '../../../../../../../Global/Localize'


export const CustomerPreviewIndividualParticulars = ({ customer }) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('individualType')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('nationality')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualNationalityDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <input
            className="form-control"
            disabled
            value={customer?.individualGenderDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('salutation')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualSalutationDescription}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualFirstName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualLastName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('birthDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(customer?.individualBirthDate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('educationLevel')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualEducationLevelDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardType')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualIdentityCardTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualIdentityCardNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardSerialNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualIdentityCardSerialNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('pin')}</label>
          <input
            className="form-control"
            disabled
            value={customer?.personalIdentificationNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentSector')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.employmentSectorDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentTerms')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualEmploymentTermsOfServiceDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentDesignation')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualEmploymentDesignation}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualEmploymentDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-3 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('maritalStatus')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualMaritalStatusDescription}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('disabilityType')}
          </label>
          <input
            className="form-control"
            disabled
            value={customer?.individualDisabilityTypeDescription}
          />
        </div>
      </div>
    </>
  )
}
