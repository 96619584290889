/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import ReversalBatchEntries from '../../../../../../../Components/ReUsables/BatchEntries/ReversalBatchEntries/ReversalBatchEntries'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'

const AuthorizeReversalModal = ({
  reversal,
  handleClose,
  setReversal,
  mode,
  clearValidation,
  globalValidationErrors,
  handleAuthorizeReversal,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalTitle="reversal"
      handleAction={handleAuthorizeReversal}
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-4">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={globalValidationErrors['Remarks']?.message}
          >
            <label className="text-capitalize col-4">
              {localize('remarks')}
            </label>
            <input
              disabled={isBusy}
              type="text"
              className="form-control"
              value={reversal?.authRemarks || ''}
              onChange={e => {
                clearValidation('Remarks')
                setReversal({
                  ...reversal,
                  authRemarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <label className="text-capitalize">{localize('authOption')}</label>
            <EnumsServerCombo
              disabled={isBusy}
              customContainerStyle={{ width: '100%' }}
              value={reversal?.batchAuthOption}
              enumsKey="BatchAuthOption"
              onChange={e => {
                setReversal({
                  ...reversal,
                  batchAuthOption: e.value,
                })
              }}
              sort
            />
          </div>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('batchNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reversal?.paddedBatchNumber}
                disabled
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('valueDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(reversal?.valueDate, true)}
                disabled
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reversal?.remarks}
                disabled
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reversal?.createdBy}
                disabled
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(reversal?.createdDate)}
                disabled
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-4">
                {localize('verifiedBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reversal?.auditedBy}
                disabled
              />
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('verifiedDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate(reversal?.auditedDate)}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('batchEntries')}
            </div>
            <div className="row mt-4">
              <ReversalBatchEntries
                showSelect={false}
                hiddenColumns={[0]}
                journalReversalBatchId={reversal?.id}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AuthorizeReversalModal
