/** @format */

import React, { useState } from 'react'
import ListingPage from '../../../../../../Components/ListingPage/Index'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { morguePatientSampleRecordStatus } from '../../../../../../Helpers/constants'
import { formatDate } from '../../../../../../Helpers/formatters'
import AddSample from './AddSample/AddSample'
import ReturnSample from './ReturnSample/ReturnSample'
import ViewPatientSample from './ViewPatientSample/ViewPatientSample'

const Samples = () => {
  const [refreshAt, setRefreshAt] = useState(false)
  const [mode, setMode] = useState(null)
  const [sample, setSample] = useState({})
  const params = {
    text: '',
    pageSize: 10,
    pageIndex: 0,
  }

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('technicianName'),
      template: r => <>{r?.morgueTechnicianFullName}</>,
    },
    {
      label: localize('morguePatient'),
      template: r => <>{r?.morguePatientFullName}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.morguePatientIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.morguePatientIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => (
        <>{r?.morguePatientIndividualIdentityCardTypeDescription}</>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.morguePatientIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.morguePatientIndividualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.morguePatientIndividualNationalityDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedMorguePatientSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.morguePatientAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.morguePatientAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.morguePatientAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.morguePatientAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.morguePatientAddressCity}</>,
    },
    {
      label: localize('technicianFirstName'),
      template: r => <>{r?.morgueTechnicianFirstName}</>,
    },
    {
      label: localize('technicianOtherNames'),
      template: r => <>{r?.morgueTechnicianOtherNames}</>,
    },
    { label: localize('returnedBy'), template: r => <>{r?.returnedBy}</> },
    {
      label: localize('returnedDate'),
      template: r => <>{formatDate(r?.returnedDate)}</>,
    },
    { label: localize('disposedBy'), template: r => <>{r?.disposedBy}</> },
    {
      label: localize('disposedDate'),
      template: r => <>{formatDate(r?.disposedDate)}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleSelectService = (r, mode) => {
    if (
      mode === 'return' &&
      r?.recordStatus === morguePatientSampleRecordStatus.RETURNED
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected sample is already returned'
      )

    setSample(r)
    setMode(mode)
  }

  const handleClose = () => {
    setMode(null)
  }
  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <AddSample
          fetchDocuments={() => setRefreshAt(!refreshAt)}
          handleClose={handleClose}
          mode={mode}
          sampleData={sample}
        />
      )}

      {mode === 'return' && (
        <ReturnSample
          handleClose={handleClose}
          mode={mode}
          sample={sample}
          fetchSamples={() => setRefreshAt(!refreshAt)}
        />
      )}

      {mode === 'view' && (
        <ViewPatientSample
          mode={mode}
          handleClose={handleClose}
          sample={sample}
        />
      )}

      <ListingPage
        map={[
          localize('morgueManagement'),
          localize('morguePatients'),
          localize('morguePatientSamples'),
        ]}
        columns={columns}
        url="/MorguePatientSample/find-morgue-patient-samples-by-filter-in-page"
        onAdd={handleClickAddButton}
        useText
        params={params}
        refreshAt={refreshAt}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelectService(r, 'view')
              }}
            >
              <i className="uil uil-eye"></i>
              <span>{localize('view')}</span>
            </div>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelectService(r, 'edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelectService(r, 'return')
              }}
            >
              <i className="uil uil-history-alt"></i>
              <span>{localize('return')}</span>
            </div>
          </>
        )}
      />
    </>
  )
}

export default Samples
