/** @format */

import localize from '../../../../../../Global/Localize'
import { IntraAccountApportionments } from './IntraAccountApportionments'
import {
  ApportionedComponent,
  ProductCode,
} from '../../../../../../Global/enumeration'
import TransactionReferenceLookup from '../../../../../../Components/Lookup/TransactionReferenceLookup'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { CustomerAccountsFundsTransferLookup } from '../../../../../../Components/Lookup/CustomerAccountsFundsTransferLookup'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { postData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../../Helpers/extensions'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const IntraAccountTransferApportionment = ({
  selectedCustomerAccount,
  indefiniteCharges,
  updateClicked,
  setUpdateClicked,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const [selectedApportionedComponent, setSelectedApportionedComponent] =
    useState(Object.keys(ApportionedComponent)[2])

  useEffect(() => {
    setValidationBag({})
  }, [selectedCustomerAccount])

  useEffect(() => {
    if (updateClicked) {
      postIntraAccountTransfer()
    }
  }, [updateClicked])

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }
  const [
    selectedFundsTransferCustomerAccount,
    setSelectedFundsTransferCustomerAccount,
  ] = useState(null)

  const [prevSelectedCustomerAccount, setPrevSelectedCustomerAccount] =
    useState(selectedCustomerAccount)

  const [apportionmentsEntries, setApportionmentsEntries] = useState([])
  const primaryDescription = !selectedCustomerAccount
    ? ''
    : `${selectedCustomerAccount?.accountName} ${selectedCustomerAccount?.fullAccountNumber}`
  const secondaryDescription = !selectedFundsTransferCustomerAccount
    ? ''
    : `${selectedFundsTransferCustomerAccount?.accountName} ${selectedFundsTransferCustomerAccount?.fullAccountNumber}`

  const [intraAccountTransferWrapper, setIntraAccountTransferWrapper] =
    useState({
      transactionReference: 'Intra-Account Transfer',
      primaryDescription: primaryDescription,
      secondaryDescription: secondaryDescription,
    })

  const [apportionmentEntryModel, setApportionmentEntryModel] = useState({
    apportionTo: 'Customer Account',
    apportionedComponent: Object.keys(ApportionedComponent)[2],
    apportionedComponentDescription:
      ApportionedComponent[Object.keys(ApportionedComponent)[2]].name,
    primaryDescription: !selectedCustomerAccount
      ? ''
      : `${selectedCustomerAccount?.accountName} ${selectedCustomerAccount?.fullAccountNumber}`,
    secondaryDescription: !selectedFundsTransferCustomerAccount
      ? ''
      : `${selectedFundsTransferCustomerAccount?.accountName} ${selectedFundsTransferCustomerAccount?.fullAccountNumber}`,
    reference: 'Intra-Account Transfer',
  })

  if (prevSelectedCustomerAccount !== selectedCustomerAccount) {
    setSelectedFundsTransferCustomerAccount(null)
    setPrevSelectedCustomerAccount(selectedCustomerAccount)
    setIntraAccountTransferWrapper({
      ...intraAccountTransferWrapper,
      debitCustomerAccountId: selectedCustomerAccount.id,
      debitCustomerAccount: selectedCustomerAccount,
      selectedCustomerAccount: selectedCustomerAccount,
    })
  }

  const handleOnSelectIntraAccountTransferApportionment = r => {
    setSelectedFundsTransferCustomerAccount(r)
    setIntraAccountTransferWrapper({
      ...intraAccountTransferWrapper,
      carryFowards: r.carryFowardsBalance,
      apportionTo: 1,
      recoverCarryFowards:
        r.customerAccountTypeProductCode === Object.keys(ProductCode)[1],
      apportionMents: apportionmentsEntries,
      creditCustomerAccountId: r.id,
      SelectedFundsTransferCustomerAccount: r,
      creditCustomerAccount: r,
      primaryDescription: primaryDescription,
      secondaryDescription: secondaryDescription,
      fullAccountNumber: r.fullAccountNumber,
      productDescription: r.customerAccountTypeTargetProductDescription,
    })
    setApportionmentEntryModel({
      ...apportionmentEntryModel,
      fullAccountNumber: r.fullAccountNumber,
      carryFowards: r.carryFowardsBalance,
      recoverCarryFowards:
        r.customerAccountTypeProductCode === Object.keys(ProductCode)[1],
      productCode: r.customerAccountTypeProductCode,
      primaryDescription: primaryDescription,
      secondaryDescription: secondaryDescription,
      productDescription: r.customerAccountTypeTargetProductDescription,
      debitCustomerAccountId: selectedCustomerAccount.id,
      debitCustomerAccount: selectedCustomerAccount,
      creditCustomerAccountId: r.id,
      creditCustomerAccount: r,
    })
  }

  const addApportionMent = async () => {
    let availableBalance = 0
    if (!selectedCustomerAccount) {
      Swal.fire({
        icon: 'error',
        title: 'Select A Customer Account',
      })
      return
    }

    if (!selectedFundsTransferCustomerAccount) {
      Swal.fire({
        icon: 'error',
        title: 'Select A Funds Transfer Customer Account',
      })
      return
    }

    if (
      selectedCustomerAccount.customerAccountTypeProductCode ===
      Object.keys(ProductCode)[0]
    ) {
      availableBalance = selectedCustomerAccount.availableBalance
    }
    if (
      selectedCustomerAccount.customerAccountTypeProductCode ===
      Object.keys(ProductCode)[2]
    ) {
      availableBalance = selectedCustomerAccount.availableBalance
    }

    const { success, data } = await postData(
      '/CustomerAccount/add-customer-account-apportionment',
      {
        ...intraAccountTransferWrapper,
        availableBalance: availableBalance,
        debitCustomerAccountId: selectedCustomerAccount.id,
        debitCustomerAccount: selectedCustomerAccount,
        selectedCustomerAccount: selectedCustomerAccount,
      },
      true
    )
    if (success) {
      if (data.result) {
        setSelectedFundsTransferCustomerAccount(null)
        setApportionmentsEntries([
          ...apportionmentsEntries,
          { ...apportionmentEntryModel, id: generateGuid() },
        ])
        setIntraAccountTransferWrapper({
          transactionReference: 'Intra-Account Transfer',
          primaryDescription: '',
          secondaryDescription: '',
          apportionMents: [...apportionmentsEntries, apportionmentEntryModel],
        })
        setApportionmentEntryModel({
          apportionTo: 'Customer Account',
          apportionedComponentDescription:
            ApportionedComponent[Object.keys(ApportionedComponent)[2]].name,
          apportionedComponent: Object.keys(ApportionedComponent)[2],
          reference: 'Intra-Account Transfer',
        })
      }
    }
  }

  const handleRemoveTransferAccount = r => {
    const filtered = apportionmentsEntries.filter(item => item.id !== r.id)
    setApportionmentsEntries(filtered)
    setIntraAccountTransferWrapper({
      ...intraAccountTransferWrapper,
      apportionMents: filtered,
    })
  }

  const postIntraAccountTransfer = async () => {
    const { success, data } = await postData(
      '/CustomerAccount/add-intra-account-journals-with-apportionments',
      {
        ...intraAccountTransferWrapper,
        debitCustomerAccountId: selectedCustomerAccount.id,
        debitCustomerAccount: selectedCustomerAccount,
        selectedCustomerAccount: selectedCustomerAccount,
        apportionedComponent: selectedApportionedComponent,
        dynamicCharges: indefiniteCharges.filter(x => x.checked),
      },
      true,
      'Intra-account-transfer'
    )
    if (success) {
      setUpdateClicked(false)
      setApportionmentsEntries([])
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row" style={{ marginTop: -10 }}>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fullAccountNumber')}
                    </label>
                    <CustomerAccountsFundsTransferLookup
                      onSelect={handleOnSelectIntraAccountTransferApportionment}
                      accountsFundsTransfer={
                        selectedFundsTransferCustomerAccount?.fullAccountNumber ||
                        ''
                      }
                      displayValue={selectedCustomerAccount?.customerFullName}
                      selectedCustomerAccount={selectedCustomerAccount}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('accountStatus')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.statusDescription ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('accountRemarks')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.remarks || ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('bookBalance')}
                    </label>
                    <input
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        selectedFundsTransferCustomerAccount?.bookBalance,
                        2,
                        2,
                        false
                      )}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('principalBalance')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.principalBalance ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('productName')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.customerAccountTypeTargetProductDescription ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('interestBalance')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.interestBalance ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('penaltyBalance')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.penaltyBalance ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('identificationNumber')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.customerIdentificationNumber ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('memberNumber')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.formattedCustomerMemberNumber ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('accountNumber')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.paddedCustomerAccountNumber ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.customerPersonalFileNumber ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('standingOrderInterest')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.standingOrderInterest ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('component')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="SelectedApportionedComponent"
                      value={selectedApportionedComponent}
                      onChange={e => {
                        setIntraAccountTransferWrapper({
                          ...intraAccountTransferWrapper,
                          apportionedComponentDescription:
                            ApportionedComponent[e.value].name,
                          apportionedComponent: e.value,
                        })
                        setSelectedApportionedComponent(e.value)
                      }}
                      sort
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('standingOrderPrincipal')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        selectedFundsTransferCustomerAccount?.standingOrderPrincipal ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    validation-message={
                      validationBag?.ApportionedAmount?.message ||
                      validationBag?.[
                        'SelectedFundsTransferCustomerAccount.Apportionment'
                      ]?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-1 text-capitalize ">
                      {localize('amount')}
                    </label>
                    <MaskNumber
                      defaultMaskValue={
                        intraAccountTransferWrapper?.apportionedAmount || ''
                      }
                      onMaskNumber={c => {
                        clearValidation(
                          'SelectedFundsTransferCustomerAccount.Apportionment'
                        )
                        clearValidation('ApportionedAmount')
                        setApportionmentEntryModel({
                          ...apportionmentEntryModel,
                          apportionedAmount: c.target.value,
                        })
                        setIntraAccountTransferWrapper({
                          ...intraAccountTransferWrapper,
                          apportionedAmount: c.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    validation-message={
                      validationBag?.PrimaryDescription?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('primaryDescription')}
                    </label>
                    <textarea
                      onChange={e => {
                        clearValidation('PrimaryDescription')
                        setIntraAccountTransferWrapper({
                          ...intraAccountTransferWrapper,
                          primaryDescription: e.target.value,
                        })
                        setApportionmentEntryModel({
                          ...apportionmentEntryModel,
                          primaryDescription: e.target.value,
                        })
                      }}
                      className="form-control"
                      value={
                        intraAccountTransferWrapper?.primaryDescription || ''
                      }
                    ></textarea>
                  </div>
                  <div
                    validation-message={
                      validationBag?.SecondaryDescription?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('secondaryDescription')}
                    </label>
                    <textarea
                      onChange={e => {
                        clearValidation('SecondaryDescription')
                        setIntraAccountTransferWrapper({
                          ...intraAccountTransferWrapper,
                          secondaryDescription: e.target.value,
                        })
                        setApportionmentEntryModel({
                          ...apportionmentEntryModel,
                          secondaryDescription: e.target.value,
                        })
                      }}
                      className="form-control"
                      value={
                        intraAccountTransferWrapper?.secondaryDescription || ''
                      }
                    ></textarea>
                  </div>
                  <div
                    validation-messagge={
                      validationBag?.TransactionReference?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('reference')}
                    </label>
                    <TransactionReferenceLookup
                      onSelect={r => {
                        setIntraAccountTransferWrapper({
                          ...intraAccountTransferWrapper,
                          transactionReference: r.description,
                        })
                        setApportionmentEntryModel({
                          ...apportionmentEntryModel,
                          reference: r.description,
                        })
                      }}
                      displayValue={
                        intraAccountTransferWrapper?.transactionReference
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className="bg-light d-flex justify-content-end align-items-center p-2"
                style={{ borderRadius: 5 }}
              >
                <button
                  onClick={addApportionMent}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('add')}
                </button>
              </div>

              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 310,
                    marginBottom: -10,
                  }}
                >
                  {`${localize('apportionmnets')}( ${localize(
                    'loan'
                  )}/${localize('investment')}/${localize('savings')})`}
                </div>
                <IntraAccountApportionments
                  handleOnRemoveCustomerTransferAccount={
                    handleRemoveTransferAccount
                  }
                  apportionments={apportionmentsEntries}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
