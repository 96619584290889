/** @format */

import Groups from '../../Pages/MicroCredit/Operations/Groups/Groups'
import Apportionments from '../../Pages/MicroCredit/Operations/Apportionments/Apportionments'
import MicroCreditOfficers from '../../Pages/MicroCredit/SetUp/Officers/MicroCreditOfficers'

const microCreditMenus = [
  {
    iconClass: 'uil uil-cell',
    label: 'microcreditManagement',
    code: 8,
    setup: [
      {
        label: 'officers',
        parentCode: 8,
        component: <MicroCreditOfficers />,
        code: 12001,
      },
    ],
    operations: [
      {
        label: 'groups',
        parentCode: 8,
        component: <Groups />,
        code: 12500,
      },
      {
        label:'apportionments',
        parentCode: 8,
        component:<Apportionments/>,
        code: 12501,
      }
    ],
  },
]

export default microCreditMenus
