/** @format */

import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { batchStatus } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import AuthorizeMessageBatch from './AuthorizeMessageBatch/AuthorizeMessageBatch'
import VerifyBulkMessage from './VerifyMessageBatch/VerifyMessageBatch'
import ViewBulkMessage from './ViewBulkMessage/ViewBulkMessage'

const BulkMessageBatches = () => {
  const [batches, setBatches] = useState([])
  const [batch, setBatch] = useState({
    batchAuthOption: 1,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [waitingVerify, setWaitingVerify] = useState(false)
  const [waitingAuthorize, setWaitingAuthorize] = useState(false)
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('batchNumber'),
      template: r => r?.paddedBatchNumber,
    },
    {
      label: localize('messageType'),
      template: r => r?.messageTypeDescription,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('messageSubject'),
      template: r => r?.messageSubject,
    },
    {
      label: localize('messageBody'),
      template: r => r?.messageBody,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchBatches = async () => {
    setIsBusy(true)

    const url =
      '/BulkMessageBatch/find-bulk-message-batches-by-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setBatches(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectBatch = (r, action) => {
    if (action === 'verify' && r?.status !== Object.keys(batchStatus)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only select batches with status "Pending"',
      })
    if (action === 'authorize' && r?.status !== Object.keys(batchStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only select batches with status "Audited"',
      })

    setBatch({
      ...r,
      batchAuthOption: 1,
    })
    setMode(action)
  }

  const handleClose = () => {
    setBatch({
      batchAuthOption: 1,
    })
    setMode(null)
  }

  const handleVerify = async () => {
    if (!batch.auditRemarks)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please enter remarks'
      )

    setWaitingVerify(true)

    const url = `/BulkMessageBatch/audit-bulk-message-batch`

    const { success } = await postData(
      url,
      {
        bulkMessageBatch: batch,
        batchAuthOption: batch?.batchAuthOption,
      },
      false
    )

    if (success) {
      handleClose()
      fetchBatches()
      showNotification('Verify Bulk Message', 'success')
      setWaitingVerify(false)
    } else {
      setWaitingVerify(false)
    }
  }
  const handleAuthorize = async () => {
    setWaitingAuthorize(true)
    if (!batch?.authorizationRemarks)
      return showNotification('Please enter remarks', 'error')

    const url = `/BulkMessageBatch/authorize-bulk-message-batch`

    const { success } = await postData(
      url,
      {
        bulkMessageBatch: batch,
        batchAuthOption: batch?.batchAuthOption,
      },
      false
    )

    if (success) {
      handleClose()
      fetchBatches()
      showNotification('Authorize Bulk Message', 'success')
      setWaitingAuthorize(false)
    } else {
      setWaitingAuthorize(false)
    }
  }

  useEffect(() => {
    fetchBatches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {mode === 'view' && (
        <ViewBulkMessage batch={batch} handleClose={handleClose} mode={mode} />
      )}

      {mode === 'verify' && (
        <VerifyBulkMessage
          batch={batch}
          handleClose={handleClose}
          mode={mode}
          setBatch={setBatch}
          handleVerify={handleVerify}
          isBusy={waitingVerify}
        />
      )}
      {mode === 'authorize' && (
        <AuthorizeMessageBatch
          batch={batch}
          handleClose={handleClose}
          mode={mode}
          setBatch={setBatch}
          handleAuthorize={handleAuthorize}
          isBusy={waitingAuthorize}
        />
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('bulkMessageBatches'),
        ]}
      />
      <div className="card rounded bg-white">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <label
                    className="text-capitalize me-2"
                    style={{ minWidth: 'fit-content', maxWidth: 'fit-content' }}
                  >
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      minWidth: 150,
                    }}
                    value={reqParams?.status}
                    enumsKey="BatchStatus"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, status: value })
                    }
                  />
                </div>
                <div className="d-flex align-items-center col-3">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchBatches()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchBatches()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={batches.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectBatch(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectBatch(r, 'verify')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      <span>{localize('verify')}</span>
                    </div>

                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectBatch(r, 'authorize')
                      }}
                    >
                      <i className="mdi mdi-lock-open-check-outline"></i>
                      <span>{localize('authorize')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={batches.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default BulkMessageBatches
