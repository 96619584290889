/** @format */

import TallyCounter from '../../../../../../Components/TallyCounter/TallyCounter'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import {
  CashTransferTransactionType,
  CONFIG,
  MONEY,
} from '../../../../../../Helpers/constants'
import React, { useEffect, useState } from 'react'
import { PointOfSaleTerminalLookup } from '../../../../../../Components/Lookup/PointOfSaleTerminalLookup'
import TellerLookup from '../../../../../../Components/Lookup/TellerLookup'
import { postData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'
import signalRService from '../../../../../../Helpers/signalR'

export const TransactionDetails = () => {
  const [total, setTotal] = useState(0)
  const [tally, setTally] = useState(null)
  const [resetValues, setResetValues] = useState(false)
  const [selectedTransactionType, setSelectedTransactionType] = useState(
    CashTransferTransactionType.TELLER_END_OF_DAY
  )
  const [cashTransferMetadata, setCashTransferMetadata] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [transactionModel, setTransactionModel] = useState(null)
  const [postingCashTransfer, setPostingCashTransfer] = useState(false)
  const [loadingCashTransferSummaries, setLoadingCashTransferSummaries] =
    useState(true)

  const fetchTellerSummariesOnTallyChange = async () => {
    setLoadingCashTransferSummaries(true)
    const { success, data } = await postData(
      '/lookup/Teller/fetch-teller-cash-transfer-metadata',
      {
        total: total,
      },
      false
    )

    if (success) {
      setCashTransferMetadata(data)
    }
    setLoadingCashTransferSummaries(false)
  }

  const fetchTellerSummariesOnMount = async () => {
    setIsBusy(true)
    setLoadingCashTransferSummaries(true)

    const { success, data } = await postData(
      '/lookup/Teller/fetch-teller-cash-transfer-metadata',
      {},
      false
    )

    if (success) {
      setCashTransferMetadata(data)
    }

    setIsBusy(false)
    setLoadingCashTransferSummaries(false)
  }

  const fetchTellerSummariesOnReset = async () => {
    setLoadingCashTransferSummaries(true)
    const { success, data } = await postData(
      '/lookup/Teller/fetch-teller-cash-transfer-metadata',
      {},
      false
    )

    if (success) {
      setCashTransferMetadata(data)
    }
    setLoadingCashTransferSummaries(false)
  }

  const getTallyByTotal = money => {
    return tally[money] ? tally[money]?.tallyByTotal : 0.0
  }

  const processTellerCashTransfer = async () => {
    setPostingCashTransfer(true)
    const { success, data } = await postData(
      '/lookup/Teller/process-teller-cash-transfer',
      {
        CashTransferTransactionType: selectedTransactionType,
        CurrencyCountModel: {
          total: total,
          oneThousandValue: getTallyByTotal(MONEY[0]?.name),
          fiveHundredValue: getTallyByTotal(MONEY[1]?.name),
          twoHundredValue: getTallyByTotal(MONEY[2]?.name),
          oneHundredValue: getTallyByTotal(MONEY[3]?.name),
          fiftyValue: getTallyByTotal(MONEY[4]?.name),
          fourtyValue: getTallyByTotal(MONEY[5]?.name),
          twentyValue: getTallyByTotal(MONEY[6]?.name),
          tenValue: getTallyByTotal(MONEY[7]?.name),
          fiveValue: getTallyByTotal(MONEY[8]?.name),
          oneValue: getTallyByTotal(MONEY[9]?.name),
          fiftyCentValue: getTallyByTotal(MONEY[10]?.name),
        },
        PointOfSaleTerminalDto: transactionModel?.posTerminal || {},
        OtherTeller: transactionModel?.otherTeller || {},
        PrimaryDescription: transactionModel?.primaryDescription,
        SecondaryDescription: transactionModel?.secondaryDescription,
        Reference: transactionModel?.reference,
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        await Swal.fire({
          icon: 'success',
          title: 'Teller Cash Transfer',
          text: data.message,
        }).then(result => {
          if (result.isConfirmed) {
            setResetValues(true)
          }
        })
      }
    }
    setPostingCashTransfer(false)
  }

  const handleOnResetValues = v => {
    setResetValues(v)
    fetchTellerSummariesOnReset()
  }

  const resetTransactionModel = () => {
    setTotal(0)
    setTally(null)
    setTransactionModel(null)
  }

  useEffect(() => {
    const onRefresh = event => {
      fetchTellerSummariesOnMount()
    }

    signalRService.addEventListener('tellerSummaries', onRefresh)

    return () => {
      signalRService.removeEventListener('tellerSummaries', onRefresh)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchTellerSummariesOnMount()
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <TallyCounter
            handleOnBlur={fetchTellerSummariesOnTallyChange}
            getTotal={total => setTotal(total)}
            getTally={tally => setTally(tally)}
            resetValues={resetValues}
            setResetValues={handleOnResetValues}
          />
        </div>
        <div className="col-md-8">
          {loadingCashTransferSummaries || postingCashTransfer ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('openingBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.openingBalance,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('totalReceipts')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.totalReceipts,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('totalPayments')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end "
                      value={formatCurrency(
                        cashTransferMetadata?.totalPayments,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('expectedCash')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.expectedCash,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('chequesPendingTransfer')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.chequesPendingTransfer,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('closingBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.closingBalance,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3 font-weight-semibold">
                      {localize(
                        `${cashTransferMetadata?.tellerCashBalanceStatusDescription}`
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        cashTransferMetadata?.shortage,
                        2,
                        2,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3">
                      {localize('transactionType')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{ width: '100%' }}
                      value={selectedTransactionType}
                      enumsKey="CashTransferTransactionType"
                      onChange={({ value }) => {
                        setSelectedTransactionType(value)
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="d-flex col-6 align-items-center">
                      <label className="text-capitalize col-6">
                        {localize('teller')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={cashTransferMetadata?.currentTeller}
                      />
                    </div>
                    {(selectedTransactionType ===
                      CashTransferTransactionType.TELLER_END_OF_DAY ||
                      selectedTransactionType ===
                        CashTransferTransactionType.TELLER_TO_TREASURY) && (
                      <TellerEndOfDay
                        cashTransferMetadata={cashTransferMetadata}
                      />
                    )}
                    {selectedTransactionType ===
                      CashTransferTransactionType.TELLER_POS_TO_TREASURY && (
                      <TellerPosToTreasury
                        transactionModel={transactionModel}
                        setTransactionModel={setTransactionModel}
                      />
                    )}
                    {selectedTransactionType ===
                      CashTransferTransactionType.TELLER_TO_TELLER && (
                      <TellerToOtherTeller
                        transactionModel={transactionModel}
                        setTransactionModel={setTransactionModel}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('primaryDescription')}
                      </label>
                      <input
                        className="form-control"
                        value={transactionModel?.primaryDescription}
                        onChange={e => {
                          setTransactionModel({
                            ...transactionModel,
                            primaryDescription: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('secondaryDescription')}
                      </label>
                      <input
                        className="form-control"
                        value={transactionModel?.secondaryDescription}
                        onChange={e => {
                          setTransactionModel({
                            ...transactionModel,
                            secondaryDescription: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('reference')}
                      </label>
                      <input
                        className="form-control"
                        value={transactionModel?.reference}
                        onChange={e => {
                          setTransactionModel({
                            ...transactionModel,
                            reference: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 bg-light rounded p-2">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-success"
                      onClick={processTellerCashTransfer}
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

const TellerEndOfDay = ({ cashTransferMetadata }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-2 text-capitalize">{localize('treasury')}</label>
        <input
          className="form-control"
          disabled
          value={cashTransferMetadata?.treasury}
        />
      </div>
    </>
  )
}

const TellerPosToTreasury = ({ transactionModel, setTransactionModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">
          {localize('posTerminal')}
        </label>
        <PointOfSaleTerminalLookup
          displayValue={transactionModel?.posTerminal?.description}
          onSelect={r => {
            setTransactionModel({ ...transactionModel, posTerminal: { ...r } })
          }}
        />
      </div>
    </>
  )
}

const TellerToOtherTeller = ({ transactionModel, setTransactionModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">
          {localize('teller')}({localize('other')})
        </label>
        <TellerLookup
          displayValue={transactionModel?.otherTeller?.description}
          onSelect={r => {
            setTransactionModel({ ...transactionModel, otherTeller: { ...r } })
          }}
        />
      </div>
    </>
  )
}
