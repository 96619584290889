/** @format */

import AutoShop from '../../Pages/VehicleFleet/Operations/Accounts/AutoShop/AutoShop'
import Collections from '../../Pages/VehicleFleet/Operations/Accounts/Collections/Collections'
import Statements from '../../Pages/VehicleFleet/Operations/Accounts/Statements/Statements'
import DisciplinaryCases from '../../Pages/VehicleFleet/Operations/Fleet/DisciplinaryCases/DisciplinaryCases'
import Personnel from '../../Pages/VehicleFleet/Operations/Fleet/Personnel/Personnel'
import Vehicles from '../../Pages/VehicleFleet/Operations/Vehicles/Vehicles'
import FleetProducts from '../../Pages/VehicleFleet/Setup/FeetProducts/FleetProducts'
import VehicleRoutes from '../../Pages/VehicleFleet/Setup/VehicleRoutes'

const vehicleFleetMenus = [
  {
    iconClass: 'uil uil-car-sideview',
    label: 'vehicleFleetManagement',
    code: 8500,
    parentCode: 0,
    setup: [
      {
        label: 'vehicleRoutes',
        parentCode: 0,
        component: <VehicleRoutes />,
        code: 8001,
      },
      {
        label: 'fleetProducts',
        code: 8002,
        component: <FleetProducts />,
      },
    ],
    operations: [
      {
        label: 'fleet',
        children: [
          {
            label: 'vehicles',
            code: 8501,
            component: <Vehicles />,
            parentCode: 8500,
          },
          {
            label: 'personnel',
            code: 8502,
            component: <Personnel />,
            parentCode: 8500,
          },
          {
            label: 'disciplinaryCases',
            code: 8503,
            component: <DisciplinaryCases />,
            parentCode: 8500,
          },
        ],
      },
      {
        label: 'accounts',
        code: 8504,
        children: [
          {
            label: 'collections',
            code: 8505,
            component: <Collections />,
            parentCode: 8504,
          },
          {
            label: 'statements',
            code: 8506,
            component: <Statements />,
            parentCode: 8504,
          },
          {
            label: 'autoShop',
            code: 8507,
            component: <AutoShop />,
            parentCode: 8504,
          },
        ],
      },
    ],
  },
]

export default vehicleFleetMenus
