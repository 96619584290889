/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import AddCustomer from '../../../../../Registry/Members/Partials/Customers/AddCustomer'

const LoanGuarantorsByCustomerAccount = ({
  customerId,
  customerAccountTypeTargetProductId,
  setReset,
  reset,
}) => {
  const [guarantors, setGuarantors] = useState([])
  const [guarantor, setGuarantor] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    { label: localize('customerName'), template: r => r?.customerFullName },
    {
      label: localize('membershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('identificationNumber'),
      template: r => r?.customerIdentificationNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanProductDescription,
    },
    {
      label: localize('loanCaseApprovedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => formatCurrency(r?.loanBalanceOutstanding),
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => r?.loanDefaultAmount,
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment),
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanBranch'),
      template: r => r?.loanCaseBranchDescription,
    },
    {
      label: localize('totalShares'),
      template: r => formatCurrency(r?.totalShares),
    },
    {
      label: localize('committedShares'),
      template: r => formatCurrency(r?.committedShares),
    },
    {
      label: localize('amountGuaranteed'),
      template: r => formatCurrency(r?.amountGuaranteed),
    },
    {
      label: localize('amountPledged'),
      template: r => formatCurrency(r?.amountPledged),
    },
    { label: localize('appraisalFactor'), template: r => r?.appraisalFactor },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const handleClose = () => {
    setMode(null)
    setGuarantor({})
  }

  const handleClickContextMenu = (r, mode) => {
    setGuarantor(r)
    setMode('edit')
  }

  const fetchLoanGuarantors = async () => {
    setIsBusy(true)

    const url =
      '/LoanCase/find-loan-guarantors-by-loanee-customer-id-and-loan-product-id'

    const { success, data } = await getData(
      url,
      {
        loaneeCustomerId: customerId,
        loanProductId: customerAccountTypeTargetProductId,
      },
      false
    )

    if (success) {
      setGuarantors(data?.result)

      if (reset) setReset(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (customerId && customerAccountTypeTargetProductId) {
      fetchLoanGuarantors()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customerAccountTypeTargetProductId])

  useEffect(() => {
    if (reset) {
      fetchLoanGuarantors(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {mode && (
            <AddCustomer
              closeModal={handleClose}
              mode="view"
              onMinimize={() => {}}
              refreshFromServer
              readOnly
              customer={{
                id: guarantor?.customerId,
              }}
            />
          )}

          <SimpleTable
            columns={columns}
            data={guarantors}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleClickContextMenu(r, 'view')
                  }}
                >
                  <i className="uil uil-eye"></i>
                  <span>{localize('view')}</span>
                </div>
              </>
            )}
          />
        </>
      )}
    </>
  )
}

export default LoanGuarantorsByCustomerAccount
