/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import AddLoanProduct from './AddLoanProduct'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function Loans() {
  const [search, setSearch] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [loanProducts, setLoanProducts] = useState(null)
  const [selectedLoanProduct, setSelectedLoanProduct] = useState(null)
  const [mode, setMode] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [refresh, setRefresh] = useState(false)

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    productSection: 0,
  })

  useEffect(() => {
    loadLoanProducts()
    // eslint-disable-next-line
  }, [
    reqParams?.pageSize,
    reqParams?.productSection,
    reqParams?.pageIndex,
    refresh,
  ])

  async function loadLoanProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanproduct/find-loan-product-by-section-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setLoanProducts(data)
    }

    setIsBusy(false)
  }

  const loanColumns = [
    { label: localize('code'), template: r => <>{r?.code}</> },
    {
      label: localize('documentReferenceNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('billReferenceNumber'),
      template: r => <>{r?.billReferenceNumber}</>,
    },
    {
      label: localize('productCategory'),
      template: r => <>{r?.loanRegistrationLoanProductCategoryDescription}</>,
    },
    {
      label: `${localize('term')} (${localize('months')})`,
      template: r => <>{r?.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => <>{r?.loanInterestAnnualPercentageRate}%</>,
    },
    {
      label: localize('offsettingPercentageRate'),
      template: r => <>{r?.loanInterestOffsettingPercentageRate}%</>,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => <>{r?.loanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('minimumPrincipalAmount'),
      template: r => <>{formatCurrency(r?.loanRegistrationMinimumAmount)}</>,
    },
    {
      label: localize('maximumPrincipalAmount'),
      template: r => <>{formatCurrency(r?.loanRegistrationMaximumAmount)}</>,
    },
    {
      label: localize('minimumChargeableInterestAmount'),
      template: r => (
        <>{formatCurrency(r?.loanRegistrationMinimumInterestAmount)}</>
      ),
    },
    {
      label: localize('minimumNetDisbursementAmount'),
      template: r => (
        <>{formatCurrency(r?.loanRegistrationMinimumNetDisbursementAmount)}</>
      ),
    },
    {
      label: localize('productSection'),
      template: r => <>{r?.loanRegistrationLoanProductSectionDescription}</>,
    },
    {
      label: localize('consecutiveIncome'),
      template: r => <>{r?.loanRegistrationConsecutiveIncome}</>,
    },
    {
      label: localize('investmentsMultiplier'),
      template: r => <>{r?.loanRegistrationInvestmentsMultiplier}</>,
    },
    {
      label: localize('minimumDeposits'),
      template: r => (
        <>{formatCurrency(r?.loanRegistrationMinimumInvestments)}</>
      ),
    },
    {
      label: localize('minimumGuarantors'),
      template: r => <>{r?.loanRegistrationMinimumGuarantors}</>,
    },
    {
      label: localize('maximumGuarantees'),
      template: r => <>{r?.loanRegistrationMaximumGuarantees}</>,
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r?.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => <>{r?.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('aggregateCheckOffRecoveryMode'),
      template: r => (
        <>{r?.loanRegistrationAggregateCheckOffRecoveryModeDescription}</>
      ),
    },
    {
      label: localize('payoutRecoveryMode'),
      template: r => <>{r?.loanRegistrationPayoutRecoveryModeDescription}</>,
    },
    {
      label: localize('payoutRecoveryPercentage'),
      template: r => <>{r?.loanRegistrationPayoutRecoveryPercentage}%</>,
    },
    {
      label: `${localize('minimumMembershipPeriod')} (${localize('months')})`,
      template: r => <>{r?.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('standingOrderTrigger'),
      template: r => <>{r?.loanRegistrationStandingOrderTriggerDescription}</>,
    },
    {
      label: `${localize('gracePeriod')} (${localize('days')})`,
      template: r => <>{r?.loanRegistrationGracePeriod}</>,
    },
    {
      label: localize('guarantorSecurityMode'),
      template: r => <>{r?.loanRegistrationGuarantorSecurityModeDescription}</>,
    },
    {
      label: localize('incomeAssessmentMode'),
      template: r => <>{r?.loanRegistrationIncomeAssessmentModeDescription}</>,
    },
    {
      label: localize('takeHomeType'),
      template: r => <>{r?.takeHomeTypeDescription}</>,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => <>{r?.takeHomePercentage}%</>,
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => <>{formatCurrency(r?.takeHomeFixedAmount)}</>,
    },
    {
      label: localize('deductiblesBasisValue'),
      template: r => <>{r?.deductiblesBasisValueDescription}</>,
    },
    {
      label: localize('principalGLAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('interestReceivedGLAccountName'),
      template: r => <>{r?.interestReceivedChartOfAccountName}</>,
    },
    {
      label: localize('interestReceivedGLAccountCostDistributionRule'),
      template: r => (
        <>{r?.interestReceivedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interestReceivableGLAccountName'),
      template: r => <>{r?.interestReceivableChartOfAccountName}</>,
    },
    {
      label: localize('interestReceivableGLAccountCostDistributionRule'),
      template: r => (
        <>
          {r?.interestReceivableChartOfAccountCostDistributionRuleDescription}
        </>
      ),
    },
    {
      label: localize('interestChargedGLAccountName'),
      template: r => <>{r?.interestChargedChartOfAccountName}</>,
    },
    {
      label: localize('interestChargedGLAccountCostDistributionRule'),
      template: r => (
        <>{r?.interestChargedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('penaltyReceivedGLAccountName'),
      template: r => <>{r?.penaltyReceivedChartOfAccountName}</>,
    },
    {
      label: localize('penaltyReceivedGLAccountCostDistributionRule'),
      template: r => (
        <>{r?.penaltyReceivedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('penaltyReceivableGLAccountName'),
      template: r => <>{r?.penaltyReceivableChartOfAccountName}</>,
    },
    {
      label: localize('penaltyReceivableGLAccountDistributionRule'),
      template: r => (
        <>{r?.penaltyReceivableChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('maximumSelfGuaranteeEligiblePercentage'),
      template: r => (
        <>{r?.loanRegistrationMaximumSelfGuaranteeEligiblePercentage}</>
      ),
    },
    {
      label: localize('roundingType'),
      template: r => <>{r?.loanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const closeModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('financialProducts'),
          localize('loanProducts'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white" style={{ position: 'relative' }}>
          <>
            <div className="mb-3">
              <AddNewButton
                handleClickAddButton={() => {
                  setSelectedLoanProduct(null)
                  setMode('add')
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between my-2">
              <RecordsPerPage
                className=""
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
              <div className="d-flex align-items-center gap-2">
                <label
                  style={{ minWidth: 'fit-content' }}
                  htmlFor=""
                  className="text-capitalize"
                >
                  {localize('productSection')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ProductSection"
                  value={reqParams?.productSection}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      productSection: e.value,
                    })
                  }
                />
              </div>
              <form
                className="d-flex align-items-center text-capitalize"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    filterText: search,
                  })
                  setRefresh(!refresh)
                }}
              >
                <label htmlFor="searchTable">{localize('search')}</label>

                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                ></input>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    setReqParams({ ...reqParams, filterText: search })
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </form>
            </div>
            <div className="py-2">
              <SimpleTable
                columns={loanColumns}
                data={loanProducts?.pageCollection || []}
                loading={isBusy}
                // canClickOnce
                // onClickOnce={r => setSelectedLoanProduct(r)}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setMode('view')
                        setSelectedLoanProduct(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setMode('edit')
                        setSelectedLoanProduct(r)
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewAuditLogs(r)
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <div className="pt-2">
                <Pager
                  itemsCount={loanProducts?.itemsCount || 0}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                  pageBatchSize={5}
                />
              </div>
            </div>
            {/*Todo: @Job remove comments*/}
            {/* {selectedLoanProduct && (
            <div className="p-2">
              <LoanModalsContainer
                loanProduct={selectedLoanProduct}
                checkFlag={(col, val) =>
                  setSelectedLoanProduct({ ...selectedLoanProduct, [col]: val })
                }
                // disabled
              />
            </div>
          )} */}
          </>
          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
      {(mode === 'add' || mode === 'edit') && (
        <AddLoanProduct
          mode={mode}
          loanProduct={selectedLoanProduct}
          closeModal={() => setMode(null)}
          loanCreated={l => {
            Swal.fire('Success', 'Loan Product Created Successfully', 'success')
            setMode(null)
            setSelectedLoanProduct(l)
          }}
        />
      )}

      {mode === 'view' && selectedLoanProduct && (
        <AddLoanProduct
          mode={mode}
          loanProduct={selectedLoanProduct}
          closeModal={() => setMode(null)}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default Loans
