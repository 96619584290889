/** @format */
import { ViewCustomerAddress } from '../../../../../../../Components/ReUsables/Customer/ViewCustomerAddress'

export const CustomerPreviewAddress = ({ customer }) => {
  return (
    <>
      <ViewCustomerAddress customer={customer} />
    </>
  )
}
