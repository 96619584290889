/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import './emailPager.scss'

function EmailPager({
  itemsCount,
  pageSize,
  pageIndex,
  onPageChange,
  pageBatchSize,
}) {
  const totalPages = Math.ceil(itemsCount / pageSize)
  const maxPagesArray = pageBatchSize || 10
  const [batch, setBatch] = useState([])
  const [batchIndex, setBatchIndex] = useState(0)
  const [pArray, setPArray] = useState([])

  useEffect(() => {
    const pagesArray = [],
      pagesStem = []
    for (let i = 0; i < totalPages; i += maxPagesArray) {
      pagesStem.push(i)
    }

    for (let p of pagesStem) {
      const pagesBatch = []
      const lastPage =
        p + maxPagesArray > totalPages ? totalPages : maxPagesArray + p
      for (let i = p; i < lastPage; i++) {
        pagesBatch.push(i)
      }
      pagesArray.push(pagesBatch)
    }

    setPArray(pagesArray)

    const batch = pagesArray.filter((batch, i) => {
      let len = batch.filter(p => p === pageIndex)?.length
      const isInside = len > 0
      if (isInside) {
        setBatchIndex(i)
        return true
      } else {
        return false
      }
    })

    setBatch(batch[0])
    // eslint-disable-next-line
  }, [pageIndex, pageSize, itemsCount])

  return (
    <div className="row email-pager">
      <div className="col-6">
        Showing {pageSize * pageIndex + 1} to{' '}
        {pageSize * (pageIndex + 1) > itemsCount
          ? itemsCount
          : pageSize * (pageIndex + 1)}{' '}
        of {itemsCount} emails
      </div>
      <div className="col-6 d-flex align-items-center justify-content-end dataTables_paginate">
        <ul
          style={{ cursor: 'pointer' }}
          className="pagination email-pagination"
        >
          <li
            className="page-item"
            onClick={() => {
              setBatch(pArray[0])
              setBatchIndex(0)
              onPageChange(0)
            }}
          >
            <span className="page-link ">
              <i className="uil uil-angle-double-left"></i>
            </span>
          </li>
          <li
            style={{ display: 'none' }}
            className="page-item"
            onClick={() => {
              let prev = pArray[batchIndex - 1]
              if (prev) {
                setBatchIndex(batchIndex - 1)
                setBatch(pArray[batchIndex - 1])
              }
            }}
          >
            <span className="page-link">
              <i className="uil uil-angle-left"></i>
            </span>
          </li>
          <li
            className="page-item"
            onClick={() => {
              pageIndex > 0
                ? onPageChange(pageIndex - 1)
                : Swal.fire({
                    icon: 'info',
                    text: 'This is the First Page',
                  })
            }}
          >
            <span className="page-link">
              <i className="uil uil-angle-left"></i>
            </span>
          </li>

          {batch &&
            batch.map(p => (
              <li
                className={`page-item ${pageIndex === p ? 'active' : ''}`}
                onClick={() => onPageChange(p)}
                key={p}
              >
                <span className="page-link">{p + 1}</span>
              </li>
            ))}
          <li
            className="page-item"
            onClick={() => {
              if (pArray?.length === 0) return

              let lastBatch = pArray[pArray?.length - 1]
              let lastPage = lastBatch[lastBatch?.length - 1]
              pageIndex < lastPage
                ? onPageChange(pageIndex + 1)
                : Swal.fire({
                    icon: 'info',
                    text: 'This is the Last Page',
                  })
            }}
          >
            <span className="page-link">
              <i className="uil uil-angle-right"></i>
            </span>
          </li>

          <li
            style={{ display: 'none' }}
            className="page-item"
            onClick={() => {
              let next = pArray[batchIndex + 1]
              if (next) {
                setBatch(pArray[batchIndex + 1])
                setBatchIndex(batchIndex + 1)
              }
            }}
          >
            <span className="page-link">
              <i className="uil uil-angle-right"></i>
            </span>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setBatch(pArray[pArray?.length - 1])
              setBatchIndex(pArray?.length - 1)
              onPageChange(totalPages - 1)
            }}
          >
            <span className="page-link">
              <i className="uil uil-angle-double-right"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default EmailPager
