/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import Specimen from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import { putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import CustomerAccountHistory from '../../../../../../Components/ReUsables/CustomerAccount/CustomerAccountHistory'
import Loader from '../../../../../../Components/Loaders/Loader'

const initialCustomerAccount = {
  recordAuditOption: 1,
  remarks: '',
}

const VerifyCustomerAccount = ({
  mode,
  handleClose,
  customerAccountInfo,
  fetchCustomerAccounts,
}) => {
  const [customerAccount, setCustomerAccount] = useState({
    ...initialCustomerAccount,
    ...customerAccountInfo,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleVerifyAccount = async () => {
    if (!customerAccount.remarks)
      return globalValidationErrors.set({
        Remarks: {
          message: 'Please enter remarks',
        },
      })

    const url = '/CustomerAccount/update-customer-account'
    setIsBusy(true)
    const { success } = await putData(
      url,
      {
        customerAccount,
        recordAuthOption: customerAccount.recordAuditOption,
        auditRemarks: customerAccount.remarks,
      },
      false
    )

    if (success) {
      handleClose()
      showNotification('Customer Account', 'success')
      setCustomerAccount(initialCustomerAccount)
      fetchCustomerAccounts()
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalTitle="customerAccount"
      cancelText="close"
      modalMode={mode}
      handleClose={() => {
        handleClose()
        setCustomerAccount(initialCustomerAccount)
      }}
      modalClassName="modal-xl"
      handleAction={handleVerifyAccount}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={globalValidationErrors?.['Remarks']?.message}
          >
            <label className="text-capitalize">{localize('remarks')}</label>
            <input
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('Remarks')
                setCustomerAccount({
                  ...customerAccount,
                  remarks: e.target.value,
                })
              }}
              disabled={isBusy}
            />
          </div>
          <EnumsServerCombo
            disabled={isBusy}
            customContainerStyle={{
              maxWidth: 150,
              minWidth: 150,
            }}
            enumsKey="RecordAuthOption"
            value={customerAccount?.recordAuditOption || ''}
            onChange={e => {
              setCustomerAccount({
                ...customerAccount,
                recordAuditOption: e.value,
              })
            }}
            sort
          />
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial readOnly customer={customerAccount} />

          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('history'),
                  item: (
                    <CustomerAccountHistory customerAccount={customerAccount} />
                  ),
                },
                {
                  label: localize('specimen'),
                  item: (
                    <Specimen
                      customer={{
                        id: customerAccount?.customerId,
                      }}
                      refetchFromServer
                      readOnly
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifyCustomerAccount
