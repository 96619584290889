/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'

import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import { getData, putData } from '../../../../../Helpers/webApi'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'

function AuxiliaryInvestmentAppraisalFactors({ loan, disabled }) {
  const [isBusy, setIsBusy] = useState(false)
  const [auxFactors, setAuxFactors] = useState([])
  const [iProduct, setIProduct] = useState(null)
  const [factor, setFactor] = useState({
    rangeLowerLimit: 0,
    rangeupperLimit: 0,
    loaneeMultiplier: 1,
    loaneeDivisor: 1,
    guarantorMultiplier: 1,
    balanceRange: 0,
  })

  useEffect(() => {
    loadConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadConditions() {
    setIsBusy(true)
    setIsBusy(true)
    const { success, data } = await getData(
      `/loanProduct/find-loan-product-auxilliary-appraisal-factor-by-loan-product-id`,
      { loanProductId: loan?.id },
      false
    )
    if (success) {
      setAuxFactors(data?.result)
    }
    setIsBusy(false)
    setIsBusy(false)
  }

  async function updateFactors() {
    setIsBusy(true)
    const { success } = await putData(
      `/LoanProduct/update-loan-product-auxilliary-appraisal-factors-by-loan-product-id?loanProductId=${loan?.id}`,
      auxFactors,
      false,
      'Appraisal Factors Updated!'
    )

    if (success) {
      loadConditions()
    }
    setIsBusy(false)
  }

  async function removeFactor(factor) {
    let filtered = [...auxFactors].filter(
      x => x.investmentProductId === factor.investmentProductId
    )
    let facsLength = filtered.length
    let currentIndex = filtered.findIndex(f => f.id === factor.id)
    if (currentIndex < facsLength - 1) {
      let res = await Swal.fire({
        title: 'Confirm',
        text:
          'Are you sure you want to remove this factor? This will also remove the ' +
          (facsLength - 1 - currentIndex) +
          ' factor(s) for ' +
          factor.investmentProductDescription +
          ' after it to maintain a valid sequence.',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
      })
      if (res.isConfirmed) {
        let fs = [...auxFactors].slice(0, currentIndex)
        let others = [...auxFactors].filter(
          x => x.investmentProductId !== factor.investmentProductId
        )
        setAuxFactors([...fs, ...others])
      }
    } else {
      let facs = [...auxFactors]
      facs = facs.filter(f => f.id !== factor.id)
      setAuxFactors(facs)
    }
  }

  function addFactor() {
    let factors = [...auxFactors].filter(
      x => x.investmentProductId === iProduct?.id
    )
    let lowerLimit = 0
    let newLimit = factor?.balanceRange - 0.01
    if (factors.length > 0) {
      lowerLimit = factors[factors.length - 1].rangeUpperLimit + 0.01

      if (Number(factor.balanceRange) <= lowerLimit)
        return Swal.fire(
          'Error',
          `Invalid Sequence for '${
            factors[factors.length - 1].investmentProductDescription
          }'`,
          'error'
        )
    }
    let allFactors = [...auxFactors]
    allFactors.push({
      id: generateGuid(),
      investmentProductId: iProduct?.id,
      investmentProductDescription: iProduct?.description,
      rangeLowerLimit: lowerLimit,
      rangeUpperLimit: newLimit,
      loaneeDivisor: factor?.loaneeDivisor,
      loaneeMultiplier: factor?.loaneeMultiplier,
      guarantorMultiplier: factor?.guarantorMultiplier,
    })
    setAuxFactors(allFactors)
    setFactor({
      rangeLowerLimit: 0,
      rangeupperLimit: 0,
      loaneeMultiplier: 1,
      loaneeDivisor: 1,
      guarantorMultiplier: 1,
      balanceRange: 0,
    })
  }

  const columns = [
    {
      label: localize('investmentProduct'),
      template: r => <>{r?.investmentProductDescription}</>,
    },
    {
      label: localize('balanceRange') + ' (' + localize('lowerLimit') + ')',
      template: r => <>{formatCurrency(r?.rangeLowerLimit)}</>,
    },
    {
      label: localize('balanceRange') + ' (' + localize('upperLimit') + ')',
      template: r => <>{formatCurrency(r?.rangeUpperLimit)}</>,
    },
    {
      label: localize('loaneeMultiplier'),
      template: r => <>{r?.loaneeMultiplier}</>,
    },
    {
      label: localize('loaneeDivisor'),
      template: r => <>{r?.loaneeDivisor}</>,
    },
    {
      label: localize('guarantorMultiplier'),
      template: r => <>{r?.guarantorMultiplier}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={auxFactors} loading={isBusy} />
    </>
  ) : (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('investmentProduct')}
                  </label>
                  <InvestmentProductLookup
                    displayValue={iProduct?.description}
                    onSelect={x => {
                      setIProduct(x)
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('productSection')}
                  </label>
                  <input
                    value={iProduct?.productSectionDescription}
                    type="text"
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('balanceRange')} ({localize('lowerLimit')})
                  </label>
                  <input
                    type="number"
                    value={factor?.balanceRange}
                    onChange={e =>
                      setFactor({ ...factor, balanceRange: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('loaneeMultiplier')}
                  </label>
                  <input
                    type="number"
                    value={factor?.loaneeMultiplier}
                    onChange={e =>
                      setFactor({ ...factor, loaneeMultiplier: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('loaneeDivisor')}
                  </label>
                  <input
                    type="number"
                    value={factor?.loaneeDivisor}
                    onChange={e =>
                      setFactor({ ...factor, loaneeDivisor: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('guarantorMultiplier')}
                  </label>
                  <input
                    type="number"
                    value={factor?.guarantorMultiplier}
                    onChange={e =>
                      setFactor({
                        ...factor,
                        guarantorMultiplier: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light d-flex align-items-center justify-content-end p-2 mb-3">
            <button
              className="btn btn-sm btn-success text-uppercase px-4"
              onClick={addFactor}
            >
              {localize('add')}
            </button>
          </div>
          <SimpleTable
            columns={columns}
            data={auxFactors}
            loading={isBusy}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => removeFactor(r)}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={updateFactors} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AuxiliaryInvestmentAppraisalFactors
