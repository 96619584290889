/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _d = {
  dashboard: { en: 'dashboard', sw: 'dashibodi' },
  departments: {
    en: 'departments',
    sw: 'idara',
  },
  designations: {
    en: 'designations',
    sw: 'uteuzi',
  },
  documents: {
    en: 'Documents',
    sw: 'Nyaraka',
  },
  disciplinaryCases: {
    en: 'Disciplinary Cases',
    sw: 'Kesi Za Nidhamu',
  },
  default: {
    en: 'default',
    sw: 'chaguo-msingi',
  },
  description: {
    en: 'name',
    sw: 'jina',
  },
  defaultCustomerFilter: {
    en: 'default customer filter',
    sw: 'kichujio chaguo msingi cha wateja',
  },
  daysCategory: {
    en: 'days category',
    sw: 'jamii ya siku',
  },
  dataAttachmentRemarks: {
    en: 'data attachment remarks',
    sw: 'maelezo ya viambatisho vya data',
  },
  disbursementCategoryDescription: {
    en: 'category',
    sw: 'kitengo',
  },
  disbursementTargetDescription: {
    en: 'disbursement target',
    sw: 'lengo la malipo',
  },
  daysPastDueLowerLimit: {
    en: 'days past due (lower limit)',
    sw: 'siku zilizopita kikomo cha chini kinachotarajiwa',
  },
  daysPastDueUpperLimit: {
    en: 'days past due (upper limit)',
    sw: 'siku zilizopita kikomo cha juu kinachohitajika',
  },
  duty: {
    en: 'duty',
    sw: 'wajibu',
  },
  dividendCodes: {
    en: 'dividend codes',
    sw: 'kanuni za gawio',
  },
  disabilityType: {
    en: 'disability type',
    sw: 'aina ya ulemavu',
  },
  days: {
    en: 'days',
    sw: 'masiku',
  },
  determination: {
    en: 'determination',
    sw: 'uamuzi',
  },
  directDebits: {
    en: 'direct debits',
    sw: 'madeni ya moja kwa moja',
  },
  debitTypes: {
    en: 'debit types',
    sw: 'aina za deni',
  },
  dividendComputedBy: {
    en: 'dividend computed by',
    sw: 'gawio lililokokotolewa na',
  },
  dividendComputedDate: {
    en: 'dividend computed date',
    sw: 'tarehe ya kukokotoa mgao',
  },
  department: {
    en: 'department',
    sw: 'idara',
  },
  disbursementDay: {
    en: 'disbursement day',
    sw: 'siku ya malipo',
  },
  disbursementFactors: {
    en: 'disbursement factors',
    sw: 'sababu za utoaji',
  },
  dailyLimit: {
    en: 'daily limit',
    sw: 'kikomo cha kila siku',
  },
  disbursementCategory: {
    en: 'disbursement category',
    sw: 'kategoria ya utoaji',
  },
  deductionCategoryDescription: {
    en: 'deduction category',
    sw: 'kategoria ya punguzo',
  },
  detailEntry: {
    en: 'detail entry',
    sw: 'ingizo la kina',
  },
  deduction: {
    en: 'deduction',
    sw: 'punguzo',
  },
  defaultMappings: {
    en: 'default mappings',
    sw: 'ramani ya chaguo-msingi',
  },
  documentReferenceNumber: {
    en: 'document ref number',
    sw: 'kumbukumbu ya hati',
  },
  depth: {
    en: 'depth',
    sw: 'kina',
  },
  duration: {
    en: 'duration',
    sw: 'muda',
  },
  deductionCategory: {
    en: 'deduction category',
    sw: 'kategoria ya makato',
  },
  dutyWorkStations: {
    en: 'duty/work stations',
    sw: 'vituo vya kazi vya wajibu',
  },
  "duty/workstationLookup": {
    en: 'duty/workstation lookup',
    sw: 'utafutaji wa wajibu/kituo cha kazi',

  },
  distributionDetails: {
    en: 'distribution details',
    sw: 'maelezo ya usambazaji',
  },
  deductiblesBasisValue: {
    en: 'deductibles basis value',
    sw: 'thamani ya msingi ya makato',
  },
  dataAttachmentRemark: {
    en: 'data attachment remark',
    sw: 'maoni ya kiambatisho cha data',
  },
  disbursementProduct: {
    en: 'disbursement product',
    sw: 'bidhaa ya malipo',
  },
  designation: {
    en: 'designation',
    sw: 'uteuzi',
  },
  disbursementProductCategory: {
    en: 'disbursement product category',
    sw: 'kategoria ya malipo',
  },
  depreciationExpenseChartOfAccountName: {
    en: 'depreciation expense g/l account name',
    sw: 'chati ya gharama ya kushuka kwa thamani ya jina la akaunti',
  },
  depreciation: {
    en: 'depreciation',
    sw: 'kushuka kwa thamani',
  },
  decliningFactor: {
    en: 'declining factor',
    sw: 'sababu ya kupungua',
  },
  dateEstablished: {
    en: 'date established',
    sw: 'tarehe iliyoanzishwa',
  },
  delegates: {
    en: 'delegates',
    sw: 'wajumbe',
  },
  dutyStation: {
    en: 'duty station',
    sw: 'kituo cha wajibu',
  },
  directors: {
    en: 'directors',
    sw: 'wakurugenzi',
  },
  domicileBranchLinkage: {
    en: 'domicile branch linkage',
    sw: 'uhusiano wa tawi la makazi',
  },
  DutyWorkstation: {
    en: 'duty workstation',
    sw: 'kituo cha kazi',
  },
  delegate: {
    en: 'delegate',
    sw: 'mjumbe',
  },
  domicileBranch: {
    en: 'domicile branch',
    sw: 'tawi la makaazi',
  },
  designationDescription: {
    en: 'designation description',
    sw: 'maelezo ya jina',
  },
  departmentDescription: {
    en: 'department description',
    sw: 'maelezo ya idara',
  },
  documentRegister: {
    en: 'document register',
    sw: 'daftari la hati',
  },
  documentReference: {
    en: 'document reference',
    sw: 'kumbukumbu ya hati',
  },
  dispatch: {
    en: 'dispatch',
    sw: 'tuma',
  },
  deductiblesBasisValueDescription: {
    en: 'deductibles basis value description',
    sw: 'maelezo ya thamani ya msingi ya makato',
  },
  dates: {
    en: 'dates',
    sw: 'tarehe',
  },
  dividendCode: {
    en: 'dividend code',
    sw: 'msimbo',
  },
  domicile: {
    en: 'domicile',
    sw: 'makazi',
  },
  director: {
    en: 'director',
    sw: 'mkurugenzi',
  },
  document: {
    en: 'document',
    sw: 'hati',
  },
  deposits: {
    en: 'deposits',
    sw: 'amana',
  },
  destination: {
    en: 'destination',
    sw: 'fikio',
  },
  dispatchCollections: {
    en: 'dispatch collections',
    sw: 'tuma makusanyo',
  },
  dispatchCollection: {
    en: 'dispatch collection',
    sw: ' tuma kusanyo',
  },
  designationLookup: {
    en: 'designation lookup',
    sw: 'utafutaji wa jina',
  },
  departmentLookup: {
    en: 'department lookup',
    sw: 'utafutaji wa idara',
  },
  depreciationExpenseChartOfAccount: {
    en: 'depreciation expense g/l account',
    sw: 'chati ya gharama ya g/l',
  },
  dateOfBirth: {
    en: 'date of birth',
    sw: 'tarehe ya kuzaliwa',
  },
  dateOfBirthFromPassport: {
    en: 'date of birth from passport',
    sw: 'tarehe ya kuzaliwa kutoka kwa pasipoti',
  },
  dateOfDeath: {
    en: 'date of death',
    sw: 'tarehe ya kifo',
  },
  dateOfIssue: {
    en: 'date of issue',
    sw: 'tarehe ya kutolewa',
  },
  dateOfExpiry: {
    en: 'date of expiry',
    sw: 'tarehe ya muda wa mwisho',
  },
  details: {
    en: 'details',
    sw: 'maelezo',
  },
  dispatchDate: {
    en: 'dispatch date',
    sw: 'tarehe ya kutuma',
  },
  delegateNames: {
    en: 'delegate names',
    sw: 'majina ya mjumbe',
  },
  debit: {
    en: 'debit',
    sw: 'deni',
  },
  dynamicSavingsFees: {
    en: 'dynamic savings fees',
    sw: 'ada za akiba za kudumu',
  },
  dividendsComputations: {
    en: 'dividends computations',
    sw: 'mahesabu ya gawio',
  },
  discrepancyMatching: {
    en: 'discrepancy matching',
    sw: 'kulinganisha utofauti',
  },
  directDebitRequests: {
    en: 'direct debit requests',
    sw: 'ombi la malipo ya moja kwa moja',
  },
  dutyWorkStation: {
    en: 'duty work station',
    sw: 'kituo cha kazi',
  },
  dateRange: {
    en: 'date range',
    sw: 'kipindi cha tarehe',
  },
  debits: {
    en: 'debits',
    sw: 'madeni',
  },
  dateFilter: {
    en: 'date filter',
    sw: 'kichujio cha tarehe',
  },
  durationStartDate: {
    en: 'duration start-date',
    sw: 'tarehe ya kuanza',
  },
  durationEndDate: {
    en: 'duration end-date',
    sw: 'tarehe ya mwisho',
  },
  deathDate: {
    en: 'death date',
    sw: 'tarehe ya kifo',
  },
  delegatePfNumber: {
    en: 'delegate personal file number',
    sw: 'nambari ya faili ya mjumbe',
  },
  'duty/WorkStationLinkage': {
    en: 'duty/Workstation Linkage',
    sw: 'Uhusiano wa Kituo cha Kazi/Wajibu',
  },
  debitCredit: {
    en: 'debit/credit',
    sw: 'deni/mkopo',
  },
  discrepancies: {
    en: 'discrepancies',
    sw: 'tofauti',
  },
  directDebitCatalogue: {
    en: 'direct debit catalogue',
    sw: 'orodha ya malipo',
  },
  drAccount: {
    en: 'DR account',
    sw: 'DR akaunti',
  },
  drAmount: {
    en: 'DR amount',
    sw: 'Kiasi cha DR',
  },
  '(days)': {
    en: '(days)',
    sw: '(siku)',
  },
  'deposits(Refundable)': {
    en: 'deposits (Refundable)',
    sw: 'amana (inayoweza kurudishwa)',
  },
  debitBatchEntry: {
    en: 'debit batch entry',
    sw: 'ingizo la kundi la deni',
  },
  deductibles: {
    en: 'deductibles',
    sw: 'vijito',
  },
  depositsMultiplier: {
    en: 'deposits multiplier',
    sw: 'kuzidisha amana',
  },
  debitType: {
    en: 'debit type',
    sw: 'aina ya deni',
  },
  debitBatchLookup: {
    en: 'debit batch lookup',
    sw: 'utafutaji wa kundi la deni',
  },
  debitTypeLookup: {
    en: 'debit type lookup',
    sw: 'utafutaji wa deni',
  },
  debitBatches: {
    en: 'debit batches',
    sw: 'kikundi cha deni',
  },
  discrepancyMatchingSingly: {
    en: 'discrepancy matching-singly',
    sw: 'kulinganisha utofauti-kwa peke yake',
  },
  discrepancyMatchingJointly: {
    en: 'discrepancy matching-jointly',
    sw: 'kulinganisha utofauti-kwa pamoja',
  },
  destinationBudget: {
    en: 'destination budget',
    sw: 'bajeti ya mwisho',
  },
  debitFullAccount: {
    en: 'debit full account',
    sw: 'akaunti kamili ya deni',
  },
  debitCustomerName: {
    en: 'debit customer name',
    sw: 'jina la mteja wa deni',
  },
  debitCustomerAccountNumber: {
    en: 'debit customer account number',
    sw: 'nambari ya akaunti ya mteja wa deni',
  },
  debitCustomerMemberNumber: {
    en: 'debit customer member number',
    sw: 'nambari ya mwanachama wa mteja wa deni',
  },
  debitCustomerPersonalFileNumber: {
    en: 'debit customer personal file number',
    sw: 'nambari ya faili la kibinafsi ya mteja wa deni',
  },
  debitChartOfAccountName: {
    en: 'debit g/l name',
    sw: 'jina la chati ya akaunti ya deni',
  },
  debitChartOfAccountCostDistributionRule: {
    en: 'debit g/l cost distribution rule',
    sw: 'chati ya deni ya sheria',
  },
  debitGLAccountName: {
    en: 'debit g/l account name',
    sw: 'jina la akaunti ya g/l ya deni',
  },
  debitFullAccountNumber: {
    en: 'debit full account number',
    sw: 'nambari kamili ya akaunti ya deni',
  },
  debitCreditRef: {
    en: 'debit ref',
    sw: 'deni ref',
  },
  date: {
    en: 'date',
    sw: 'tarehe',
  },
  debitBatch: {
    en: 'debit batch',
    sw: 'kundi la deni',
  },
  dividendsComputation: {
    en: 'dividends computation',
    sw: 'mahesabu ya gawio',
  },
  december: {
    en: 'december',
    sw: 'desemba',
  },
  'duty/Workstation': {
    en: 'duty/Work station',
    sw: 'wajibu/kituo vya kazi',
  },
  dutyStationDescription: {
    en: 'duty station description',
    sw: 'maelezo ya kituo cha kazi',
  },
  directDebitRequestsCatalogue: {
    en: 'direct debit requests (catalogue)',
    sw: '(orodha) ya ombi la malipo ya moja kwa moja',
  },
  directDebitRequestsLogs: {
    en: 'direct debit requests (logs)',
    sw: '(orodha) ya ombi la malipo ya moja kwa moja',
  },
  dutyStations: {
    en: 'duty stations',
    sw: 'vituo vya kazi',
  },
  dutyStationLinkage: {
    en: 'duty station linkage',
    sw: 'uhusiano wa kituo cha kazi',
  },
  disbursementQueues: {
    en: 'disbursement queues',
    sw: 'orodha ya malipo',
  },
  delinquencyStatus: {
    en: 'delinquency status',
    sw: 'hali ya malipo',
  },
  disbursementMode: {
    en: 'disbursement mode',
    sw: 'njia ya malipo',
  },
  delinquencyCode: {
    en: 'delinquency code',
    sw: 'nambari ya malipo',
  },
  delinquencySummary: {
    en: 'delinquency summary',
    sw: 'muhtasari wa malipo',
  },
  deliquencyStatusQueries: {
    en: 'deliquency status queries',
    sw: 'maswali ya hali ya malipo',
  },
  depositsBalance: {
    en: 'deposits balance',
    sw: 'salio la amana',
  },
  depositsQualification: {
    en: 'deposits qualification',
    sw: 'ustahiki wa amana',
  },
  deductionSchedule: {
    en: 'deduction schedule',
    sw: 'ratiba ya makato',
  },
  dataAttachmentPeriods: {
    en: 'data attachment periods',
    sw: 'kipindi cha viambatisho vya data',
  },
  dataEntryDetails: {
    en: 'data entry details',
    sw: 'maelezo ya kuingiza data',
  },
  dataPeriod: {
    en: 'data period',
    sw: 'kipindi cha data',
  },
  dataEntries: {
    en: 'data entries',
    sw: 'ingizo la data',
  },
  daysInArrears: {
    en: 'days in arrears',
    sw: 'siku kwa utathmini',
  },
  dateOpened: {
    en: 'date opened',
    sw: 'tarehe ya kufunguliwa',
  },
  disbursedDate: {
    en: 'disbursed date',
    sw: 'tarehe ya malipo',
  },
  disbursedAmount: {
    en: 'disbursed amount',
    sw: 'kiasi kilicholipwa',
  },
  disbursementRemarks: {
    en: 'disbursement remarks',
    sw: 'maelezo ya malipo',
  },
  deferredBy: {
    en: 'deferred by',
    sw: 'imeahirishwa na',
  },
  deferredDate: {
    en: 'deferred date',
    sw: 'tarehe ya kusogezwa mbele',
  },
  deferralRemarks: {
    en: 'deferral remarks',
    sw: 'maelezo ya kusogezwa mbele',
  },
  disbursementTarget: {
    en: 'disbursement target',
    sw: 'lengo la malipo',
  },
  disbursedBy: {
    en: 'disbursed by',
    sw: 'malipo yalifanywa na',
  },
  disburseMicroLoanLessDeductions: {
    en: 'disburse micro loan less deductions',
    sw: 'malipo ya mkopo mdogo bila makato',
  },
  dueDate: {
    en: 'due date',
    sw: 'tarehe ya kumaliza',
  },
  defaultedAmount: {
    en: 'defaulted amount',
    sw: 'kiasi kilichoshindwa kulipwa',
  },
  defaultTimeline: {
    en: 'default timeline',
    sw: 'kipindi cha chaguo-msingi',
  },
  DLRStatus: {
    en: 'DLR Status',
    sw: 'Hali ya DLR',
  },
  DLRReference: {
    en: 'DLR Reference',
    sw: 'rejeo ya DLR',
  },
  daysPastDue: {
    en: 'days past due',
    sw: 'siku zilizopita',
  },
  deductions: {
    en: 'deductions',
    sw: 'makato',
  },
  documentTitle: {
    en: 'document title',
    sw: 'jina la hati',
  },
  drawer: {
    en: 'drawer',
    sw: 'mlengwa',
  },
  drawerBank: {
    en: 'drawer bank',
    sw: 'benki ya mlengwa',
  },
  drawerBankBranch: {
    en: 'drawer bank branch',
    sw: 'tawi la benki ya mlengwa',
  },
  denominationOneThousandValue: {
    en: 'denomination one thousand value',
    sw: 'thamani ya noti elfu moja',
  },
  denominationFiveHundredValue: {
    en: 'denomination five hundred value',
    sw: 'thamani ya noti mia tano',
  },
  denominationTwoHundredValue: {
    en: 'denomination two hundred value',
    sw: 'thamani ya noti mia mbili',
  },
  denominationOneHundredValue: {
    en: 'denomination one hundred value',
    sw: 'thamani ya noti mia moja',
  },
  denominationFiftyValue: {
    en: 'denomination fifty value',
    sw: 'thamani ya noti hamsini',
  },
  denominationFourtyValue: {
    en: 'denomination forty value',
    sw: 'thamani ya noti arobaini',
  },
  denominationTwentyValue: {
    en: 'denomination twenty value',
    sw: 'thamani ya noti ishirini',
  },
  denominationTenValue: {
    en: 'denomination ten value',
    sw: 'thamani ya noti kumi',
  },
  denominationFiveValue: {
    en: 'denomination five value',
    sw: 'thamani ya noti tano',
  },
  denominationOneValue: {
    en: 'denomination one value',
    sw: 'thamani ya noti moja',
  },
  denominationFiftyCentValue: {
    en: 'denomination fifty cent value',
    sw: 'thamani ya senti hamsini',
  },
  deductionsSchedule: {
    en: 'deductions schedule',
    sw: 'ratiba ya makato',
  },
  deceased: {
    en: 'deceased',
    sw: 'marehemu',
  },
  'debit/credit': {
    en: 'debit/credit',
    sw: 'deni/mkopo',
  },
  'duration(Months)': {
    en: 'duration (months)',
    sw: 'kipindi (miezi)',
  },
  durationType: {
    en: 'duration type',
    sw: 'aina ya kipindi',
  },
  dateOfFileExchange: {
    en: 'date of file exchange',
    sw: 'tarehe ya kubadilishana faili',
  },
  destinationAccountBank: {
    en: 'destination account bank',
    sw: 'benki ya akaunti ya fikio',
  },
  destinationAccountBranch: {
    en: 'destination account branch',
    sw: 'tawi la akaunti ya fikio',
  },
  destinationAccount: {
    en: 'destination account',
    sw: 'akaunti ya fikio',
  },
  destinationAccountCheckDigit: {
    en: 'destination account check digit',
    sw: 'nambari ya kuthibitisha akaunti ya fikio',
  },
  destinationAccountCurrencyCode: {
    en: 'destination account currency code',
    sw: 'nambari ya sarafu ya akaunti ya fikio',
  },
  drawerBranch: {
    en: 'drawer branch',
    sw: 'tawi la mlengwa',
  },
  debitCardsRegister: {
    en: 'debit cards register',
    sw: 'sajili kadi za malipo',
  },
  debitCardRecord: {
    en: 'debit card record',
    sw: 'rekodi ya kadi ya malipo',
  },
  dependants: {
    en: 'dependants',
    sw: 'wategemezi',
  },
  disregardDateRange: {
    en: 'disregard date range',
    sw: 'poteza kipindi cha tarehe',
  },
  discreteSetDifferenceMode: {
    en: 'discrete set difference mode',
    sw: 'tofauti ya seti',
  },
  'disregardPAN/CardNumber?': {
    en: 'disregard PAN/CardNumber?',
    sw: 'poteza PAN/Nambari ya kadi?',
  },
  deathTime: {
    en: 'death time',
    sw: 'muda wa kifo',
  },
  disposedBy: {
    en: 'disposed by',
    sw: 'imeondolewa na',
  },
  disposedDate: {
    en: 'disposed date',
    sw: 'tarehe ya kuondolewa',
  },
  DirectPuchases: {
    en: 'direct purchases',
    sw: 'ununuzi wa moja kwa moja',
  },
  destinationDepartment: {
    en: 'destination department',
    sw: 'idara ya fikio',
  },
  destinationLocation: {
    en: 'destination location',
    sw: 'eneo la fikio',
  },
  depreciationMethod: {
    en: 'depreciation method',
    sw: 'njia ya kushuka kwa thamani',
  },
  dailyPatientAdmission: {
    en: 'daily patient admission',
    sw: 'idhini ya kila siku ya mgonjwa',
  },
  directPurchases: {
    en: 'direct purchases',
    sw: 'ununuzi wa moja kwa moja',
  },
  directPurchase: {
    en: 'direct purchase',
    sw: 'ununuzi wa moja kwa moja',
  },
  directPurchaseItems: {
    en: 'direct purchase items',
    sw: 'bidhaa za ununuzi wa moja kwa moja',
  },
  destinationlocation: {
    en: 'destination location',
    sw: 'eneo la fikio',
  },
  documentNumber: {
    en: 'document number',
    sw: 'nambari ya hati',
  },
  documentNo: {
    en: 'document number',
    sw: 'nambari ya hati',
  },
  daysToDeliver: {
    en: 'days to deliver',
    sw: 'siku za kuwasilisha',
  },
  decision: {
    en: 'decision',
    sw: 'uamuzi',
  },
  depreciations: {
    en: 'depreciations',
    sw: 'kushuka kwa thamani',
  },
  depreciationRate: {
    en: 'depreciation rate',
    sw: 'kiwango cha kushuka kwa thamani',
  },
  depreciationExpenses: {
    en: 'depreciation expenses',
    sw: 'gharama za kushuka kwa thamani',
  },
  disciplinaryType: {
    en: 'disciplinary type',
    sw: 'aina ya nidhamu',
  },
  directPurchaseApprovalitems: {
    en: 'direct purchase approval items',
    sw: 'bidhaa za idhini ya ununuzi wa moja kwa moja',
  },
  daysRemaining: {
    en: 'days remaining',
    sw: 'siku zilizobaki',
  },
  'document#': {
    en: 'document #',
    sw: 'nambari ya hati',
  },
  descriptions: {
    en: 'descriptions',
    sw: 'maelezo',
  },
  defaultCustomerFilterDescription: {
    en: 'default customer filter description',
    sw: 'maelezo ya chaguo-msingi ya kichujio cha wateja',
  },
  designationIsLocked: {
    en: 'designation is locked',
    sw: 'uteuzi umefungwa',
  },
  dataAttachementRemark: {
    en: 'data attachment remark',
    sw: 'maelezo ya viambatisho vya data',
  },
}
export default _d
