/** @format */
import localize from '../../../../../Global/Localize'
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { ChargeTierEntries } from './ChargeTierEntries'
import { chargeType } from '../../../../../Helpers/constants'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../../../Components/Loaders/Loader'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

export const AddTiers = ({ selectedCharge, setSelectedCharge, mode }) => {
  const [tierModel, setTierModel] = useState({
    chargeType: chargeType.PERCENTAGE,
  })
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnAddTier = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Commission/add-commission-graduated-scale-entry',
      {
        GraduatedScale: { ...tierModel },
        GraduatedScales: selectedCharge?.graduatedScales || [],
      },
      false,
    )
    if (success) {
      setSelectedCharge({
        ...selectedCharge,
        graduatedScales: data?.graduatedScales,
      })
      setTierModel({ chargeType: chargeType.PERCENTAGE })
    }

    setIsBusy(false)
  }

  return (
    <div
      className="p-2 mt-4"
      style={{
        border: 'solid 1px #0000001f',
        borderRadius: 10,
        position: 'relative',
      }}
    >
      <div
        className="bg-white px-2 row text-capitalize"
        style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
      >
        {localize('tierDetails')}
      </div>
      <div className="row" style={{ marginTop: -10 }}>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3">
            {localize('rangeLowerLimit')}
          </label>
          <MaskNumber
            defaultMaskValue={tierModel?.rangeLowerLimit || ''}
            onMaskNumber={e => {
              setTierModel({ ...tierModel, rangeLowerLimit: e.target.value })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3">
            {localize('chargeType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ChargeType"
            value={tierModel?.chargeType}
            onChange={e => {
              setTierModel({
                ...tierModel,
                chargeType: e.value,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={
            validationBag?.['GraduatedScale.ChargePercentage']?.message
          }
        >
          {tierModel?.chargeType === chargeType.PERCENTAGE ? (
            <>
              <label htmlFor="" className="text-capitalize me-3">
                {localize('charge')}
              </label>
              <MaskPercentage
                defaultMaskValue={tierModel?.chargePercentage || ''}
                onMaskChange={e => {
                  clearValidation('GraduatedScale.ChargePercentage')
                  setTierModel({
                    ...tierModel,
                    chargePercentage: e.target.value,
                  })
                }}
              />
            </>
          ) : (
            <>
              <label htmlFor="" className="text-capitalize me-3">
                {localize('charge')}
              </label>
              <MaskNumber
                defaultMaskValue={tierModel?.chargeFixedAmount || ''}
                onMaskNumber={e => {
                  setTierModel({
                    ...tierModel,
                    chargeFixedAmount: e.target.value,
                  })
                }}
              />
            </>
          )}
        </div>
      </div>
      {isBusy ? (
        <Loader />
      ) : (
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2"
          style={{ borderRadius: 5 }}
        >
          <button
            onClick={handleOnAddTier}
            className="btn btn-success text-uppercase ms-3 px-4"
          >
            {localize('add')}
          </button>
        </div>
      )}

      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
            marginBottom: -10,
          }}
        >
          {localize('tiers')}
        </div>
        <ChargeTierEntries
          mode={mode}
          selectedCharge={selectedCharge}
          setSelectedCharge={setSelectedCharge}
        />
      </div>
    </div>
  )
}
