/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import Loader from '../Loaders/Loader'

function AddressV2({ readOnly, parent, onChange, refetchFromServer = false }) {
  const [sel, setSel] = useState(parent)
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    readOnly || onChange(sel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel])

  useEffect(() => {
    refetchFromServer && parent?.id && refetchCustomerInfo()
    // eslint-disable-next-line
  }, [parent?.id])

  async function refetchCustomerInfo() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/customer/find-customer-by-id',
      {
        customerId: parent?.id,
      },
      false
    )
    if (success) {
      setFetching(false)
      setSel(data?.customer)
    } else {
      setFetching(false)
    }
  }

  return fetching ? (
    <Loader />
  ) : (
    <form onSubmit={e => e.preventDefault()}>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('addressLine')} 1
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressAddressLine1}
            onChange={e => {
              setSel({ ...sel, addressAddressLine1: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('addressLine')} 2
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressAddressLine2}
            onChange={e => {
              setSel({ ...sel, addressAddressLine2: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('physicalAddress')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressStreet}
            onChange={e => {
              setSel({ ...sel, addressStreet: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="row my-3">
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">{localize('city')}</label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressCity}
            onChange={e => {
              setSel({ ...sel, addressCity: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('postalCode')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressPostalCode}
            onChange={e =>
              setSel({ ...sel, addressPostalCode: e.target.value })
            }
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">{localize('email')}</label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressEmail}
            onChange={e => {
              setSel({ ...sel, addressEmail: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressLandLine}
            onChange={e => {
              setSel({ ...sel, addressLandLine: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressMobileLine}
            onChange={e => {
              setSel({ ...sel, addressMobileLine: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('internet')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressInternet}
            onChange={e => setSel({ ...sel, addressInternet: e.target.value })}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
      </div>
    </form>
  )
}

export default AddressV2
