/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { ViewPurchaseRequisitionEntries } from './ViewPurchaseRequisitionEntries'

export const ApprovePurchaseRequisitionModal = ({
  selectedPurchaseRequisition,
  isBusy,
}) => {
  const [reqParams, setReqParams] = useState({
    purchaseRequisitionId: selectedPurchaseRequisition?.purchaseRequisition?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [quotationEntries, setQuotationEntries] = useState([])
  const fetchPurchaseRequisitionItems = async () => {
    const { success, data } = await getData(
      '/PurchaseRequisition/find-purchase-requisitions-items-by-purchase-requisition-id-filter-in-page',
      {
        ...reqParams,
        purchaseRequisitionId: selectedPurchaseRequisition?.purchaseRequisition?.id,
      },
      false,
    )
    if (success) {
      setQuotationEntries(data)
    }
  }

  useEffect(() => {
    fetchPurchaseRequisitionItems()
  }, [selectedPurchaseRequisition?.purchaseRequisition?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('department')}
              </label>
              <input
                value={
                  selectedPurchaseRequisition?.purchaseRequisition
                    .departmentDescription
                }
                disabled
                className={'form-control'}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(
                  selectedPurchaseRequisition?.purchaseRequisition.expectedDate,
                  true,
                )}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                value={
                  selectedPurchaseRequisition?.purchaseRequisition?.createdBy
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                disabled
                className="form-control"
                value={formatDate(
                  selectedPurchaseRequisition?.purchaseRequisition?.createdDate,
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPurchaseRequisition?.purchaseRequisition?.branchDescription
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <Tab
                  tabItems={[
                    {
                      label: localize('purchaseRequisitionItems'),
                      item: (
                        <ViewPurchaseRequisitionEntries
                          entries={quotationEntries}
                          setReqParams={setReqParams}
                          reqParams={reqParams}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
