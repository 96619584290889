/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'

function FixedDepositsLiens({ loanCase }) {
  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedFixedDepositSerialNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.fixedDepositStatusDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.fixedDepositCategoryDescription}</>,
    },
    {
      label: localize('term') + `(${localize('months')})`,
      template: r => <>{r?.fixedDepositTerm}</>,
    },
    {
      label: localize('maturityAction'),
      template: r => <>{r?.fixedDepositMaturityActionDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.fixedDepositRemarks}</>,
    },
    {
      label: localize('value'),
      template: r => <>{formatCurrency(r?.value)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [fixedDeposits, setFixedDeposits] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    loadFixedDeposits()
    // eslint-disable-next-line
  }, [loanCase])

  async function loadFixedDeposits() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/fixedDeposit/find-fixed-deposit-liens-by-loan-case-id',
      { loanCaseId: loanCase?.id },
      false
    )

    if (success) {
      setFetching(false)
      setFixedDeposits(data?.result || [])
    } else {
      setFetching(false)
    }
  }

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <SimpleTable columns={columns} data={fixedDeposits} />
    </>
  )
}

export default FixedDepositsLiens
