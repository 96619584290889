/** @format */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import SalaryPeriodLookup from '../../../../../Components/Lookup/SalaryPeriodLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SalaryPaySlips from '../Payslips/Partials/SalaryPaySlips'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { salaryPeriodAuthOption } from '../../../../../Global/enumeration'
import { postData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'

const Verification = () => {
  const [salaryPaySplip, setSalaryPaySplip] = useState(null)

  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(salaryPeriodAuthOption)[0]
  )
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  async function updateSalaryPeriodVerification() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/SalaryPeriod/audit-sarary-period',
      {
        salaryPeriod: {
          ...salaryPaySplip,
        },
        salaryPeriodAuthOption: selectedAuth,
      },
      false
    )

    if (success) {
      setSalaryPaySplip(null)
      setIsBusy(false)
      setGlobalValidationErrors({})
      showNotification('Verify Salary Period', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('salary'),
          localize('verifySalaryPeriod'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('salaryPeriod')}
                    </label>
                    <SalaryPeriodLookup
                      displayValue={salaryPaySplip?.monthDescription}
                      onSelect={r => {
                        setSalaryPaySplip({
                          ...salaryPaySplip,
                          id: r.id,
                          monthDescription: r.monthDescription,
                          employeeTypeDescription: r.employeeTypeDescription,
                          statusDescription: r.statusDescription,
                          totalNetPay: r.totalNetPay,
                          valueDate: r.valueDate,
                          createdBy: r.createdBy,
                          createdDate: r.createdDate,
                          remarks: r.remarks,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('employeeType')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-3"
                      value={salaryPaySplip?.employeeTypeDescription}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('status')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-3"
                      value={salaryPaySplip?.statusDescription}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('valueDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={formatDate(salaryPaySplip?.valueDate)}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('createdBy')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-3"
                      value={salaryPaySplip?.createdBy}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-3 text-capitalize">
                      {localize('createdDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-3"
                      value={formatDate(salaryPaySplip?.createdDate)}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 d-flex align-items-center ">
                    <label className="col-md-2 text-capitalize">
                      {localize('totalNetPay')}
                    </label>
                    <MaskNumber
                      className="ms-5"
                      defaultMaskValue={salaryPaySplip?.totalNetPay}
                      disabled
                    />
                  </div>
                  <div className="col-md-8 d-flex align-items-center">
                    <label className="col-md-1 text-capitalize me-2">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-5"
                      value={salaryPaySplip?.remarks}
                      disabled
                    />
                  </div>
                </div>
                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 'fit-content',
                    }}
                  >
                    {localize('paySlips')}
                  </div>
                  <SalaryPaySlips paySlip={salaryPaySplip} />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <div
                      className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
                      validation-message={
                        globalValidationErrors['SalaryPeriod.AuditRemarks']
                          ?.message
                      }
                    >
                      <label className="text-capitalize col-3 me-2">
                        {localize('remarks')}
                      </label>
                      <input
                        defaultValue={salaryPaySplip?.auditRemarks}
                        type="text"
                        className="form-control ms-4"
                        onChange={e => {
                          clearValidation('SalaryPeriod.AuditRemarks')
                          setSalaryPaySplip({
                            ...salaryPaySplip,
                            auditRemarks: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-2 px-4 mt-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="SalaryPeriodAuthOption"
                        value={selectedAuth}
                        onChange={e => setSelectedAuth(e.value)}
                      />
                    </div>
                    <button
                      onClick={() => {
                        updateSalaryPeriodVerification()
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Verification
