/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import React, { useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { postData } from '../../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'

export const EditQuotationEntries = ({
  entries,
  onRefresh,
}) => {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const handleOnRemoveQuotationEntryItem = async () => {
    if (selectedEntries.length === 0) {
      return swal.fire(
        'Remove Quotation',
        'Please select at least one item',
        'warning',
      )
    }
    setIsBusy(true)
    const { success, data } = await postData(
      '/Quotation/remove-quotation',
      { QuotationItem: selectedEntries },
      false,
    )
    if (success) {
      onRefresh()
      return swal.fire(
        'Remove Quotation',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
    { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
    { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
    { label: localize('daysToDeliver'), template: r => <>{r.daysToDeliver}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            selectables
            data={entries?.pageCollection}
            columns={columns}
          />
          <div className="d-flex align-content-end justify-content-end mt-2 mb-2">
            <div className="d-flex align-items-center me-5">
              <button
                onClick={handleOnRemoveQuotationEntryItem}
                className="btn btn-primary px-5"
              >
                {localize('remove')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
