/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import { useEffect } from 'react'
import Loader from '../../../Loaders/Loader'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import SimpleTable from '../../../Tables/SimpleTable'
import Pager from '../../../Tables/Pager'

const CustomerBatchEntries = ({ bulkMessageBatchId }) => {
  const initialParams = {
    pageIndex: 0,
    pageSize: 10,
    text: '',
    customerFilter: 0,
    bulkMessageBatchId,
  }
  const [batchEntries, setBatchEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState(initialParams)

  const columns = [
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => r?.paddedCustomerAccountNumber,
    },
    {
      label: localize('customerPersonalFileNumber'),
      template: r => r?.customerPersonalFileNumber,
    },
    {
      label: localize('customerMembershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r?.addressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.addressAddressLine2,
    },
    { label: localize('physicalAddress'), template: r => r?.addressStreet },
    {
      label: localize('postalCode'),
      template: r => r?.addressPostalCode,
    },
    { label: localize('city'), template: r => r?.addressCity },
    { label: localize('emailAddress'), template: r => r?.addressEmail },
    { label: localize('landLine'), template: r => r?.addressLandLine },
    {
      label: localize('mobileLine'),
      template: r => r?.addressMobileLine,
    },
    { label: localize('addressInternet'), template: r => r?.addressInternet },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchBatchEntries = async () => {
    setIsBusy(true)

    const url =
      '/BulkMessageBatch/find-bulk-message-batch-entries-by-bulk-message-batch-id-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setBatchEntries(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex, reqParams.bulkMessageBatchId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                name=""
                id=""
                className="form-select ms-2"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e =>
                  setReqParams({ ...reqParams, pageSize: e.target.value })
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('search')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                value={reqParams?.customerFilter}
                enumsKey="CustomerFilter"
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, customerFilter: value })
                }
              />
              <input
                type="text"
                className="form-control"
                value={reqParams?.filterText}
                onChange={e =>
                  setReqParams({ ...reqParams, filterText: e.target.value })
                }
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchBatchEntries()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable columns={columns} data={batchEntries?.pageCollection} />
          <Pager
            itemsCount={batchEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default CustomerBatchEntries
