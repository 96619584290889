/** @format */

import localize from '../../../Global/Localize'
import DatePicker from '../../Date/DatePicker'
import { JournalVoucherStatus } from '../../../Global/enumeration'
import SimpleTable from '../../Tables/SimpleTable'
import Pager from '../../Tables/Pager'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import Loader from '../../Loaders/Loader'

export const ListJournalVoucher = ({ handleOnSelectVoucher }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    status: Object.keys(JournalVoucherStatus)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [sortedBy, setSortedBy] = useState(null)

  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('voucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    { label: localize('voucherType'), template: r => <>{r.typeDescription}</> },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 ${localize('account')}#`,
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 ${localize('membership')}#`,
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 ${localize('personalFile')}#`,
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.valueDate)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: `${localize('verification')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: `${localize('authorization')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/JournalVoucher/find-journal-vouchers-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
  }, [])

  useEffect(() => {
    loadTableData()
  }, [reqParams])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row">
              <div className="d-flex align-items-center col-3">
                <label
                  htmlFor="recordsPerPage"
                  className="text-capitalize"
                  style={{ marginLeft: -10 }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  name=""
                  id="recordsPerPage"
                  className="form-select ms-3"
                  style={{ maxWidth: 60 }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-3">
                <div
                  className="d-flex align-items-center me-5"
                  style={{ width: '300px' }}
                >
                  <label className="col-3 text-capitalize">
                    {localize('pickDate')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="JournalVoucherStatus"
                  value={reqParams.status}
                  onChange={e => {
                    setReqParams({ ...reqParams, status: e.value })
                  }}
                  sort
                />
              </div>
              <div className="col-3">
                <form
                  className="col d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams.filterText}
                    onChange={e => setSearch(e.target.value)}
                  ></input>
                </form>
              </div>
            </div>
            <div className="row col-12 mt-2">
              <SimpleTable
                canSelect
                onSelect={r => {
                  handleOnSelectVoucher(r)
                }}
                data={tableData}
                columns={columns}
                onSort={(c, i) => handleSort(c, i)}
                sortedBy={sortedBy}
              />
            </div>
            <div className="row col-12 mt-2">
              <Pager
                {...tableMeta}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </>
        </>
      )}
    </>
  )
}
