/** @format */

import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  RecordStatus,
  customerMembershipStatus,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatDate, getTodaysDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import AddEStatement from './AddEStatement/AddEStatement'

const initialStatement = {
  durationStartDate: getTodaysDate(),
  scheduleFrequency: 3,
}

const EStatements = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [statements, setStatements] = useState([])
  const [statement, setStatement] = useState(initialStatement)
  const [loanAccounts, setLoanAccounts] = useState([])
  const [investmentAccounts, setInvestmentAccounts] = useState([])
  const [savingAccounts, setSavingAccounts] = useState([])
  const [mode, setMode] = useState(null)
  const [customer, setCustomer] = useState({})
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    customerFilter: 0,
  })

  const columns = [
    {
      label: localize('customerType'),
      template: r => r?.customerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.customerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerReference3,
    },
    {
      label: localize('startDate'),
      template: r => formatDate(r?.durationStartDate),
    },
    {
      label: localize('endDate'),
      template: r => formatDate(r?.durationEndDate),
    },
    {
      label: localize('frequency'),
      template: r => r?.scheduleFrequencyDescription,
    },
    {
      label: localize('expectedRunDate'),
      template: r => formatDate(r?.scheduleExpectedRunDate),
    },
    {
      label: localize('actualRunDate'),
      template: r => formatDate(r?.scheduleActualRunDate),
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r?.isLocked?.toString(),
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const handleClickAddButton = () => {
    setMode('add')
    setStatement(initialStatement)
  }

  const handleClose = () => {
    setMode(null)
    setStatement(initialStatement)
  }

  const handleLookupCustomer = data => {
    if (data?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You select edit a locked customer',
      })

    if (data?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select customers with record status of approved',
      })

    if (
      data?.membershipStatus !== Object.keys(customerMembershipStatus)[0] &&
      data?.membershipStatus !== Object.keys(customerMembershipStatus)[2]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select customers with membership status of active or partially terminated',
      })

    setCustomer(data)
  }

  const fetchEStatements = async () => {
    setIsBusy(true)

    const url =
      '/ElectronicStatementOrder/find-electronic-statement-order-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setStatements(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectSavingAccount = (account, value) => {
    if (value) {
      setSavingAccounts([...savingAccounts, account])
    } else {
      setSavingAccounts(savingAccounts.filter(a => a?.id !== account?.id))
    }
  }

  const handleSelectInvestmentAccount = (account, value) => {
    if (value) {
      setInvestmentAccounts([...investmentAccounts, account])
    } else {
      setInvestmentAccounts(
        investmentAccounts.filter(a => a?.id !== account?.id)
      )
    }
  }

  const handleSelectLoanAccount = (account, value) => {
    if (value) {
      setLoanAccounts([...loanAccounts, account])
    } else {
      setLoanAccounts(loanAccounts.filter(a => a?.id !== account?.id))
    }
  }

  const selectAllLoanAccounts = (data, value) => {
    if (value) {
      setLoanAccounts(data)
    } else {
      setLoanAccounts([])
    }
  }

  const handleCreate = async () => {
    if (!customer?.id)
      return showNotification(
        'Create E-Statement',
        'Please select a customer',
        'warning'
      )

    const payload = {
      ...statement,
      ...customer,
      customerId: customer?.id,
    }

    setIsBusy(true)
    setLoadingCreate(true)
    const url = '/ElectronicStatementOrder/create-electronic-statement-order'

    const { success } = await postData(url, payload, false)

    if (success) {
      setIsBusy(false)
      showNotification('Create E-Statement', 'success')
      setLoadingCreate(false)
      handleClose()
      setLoanAccounts([])
      setSavingAccounts([])
      setInvestmentAccounts([])
      fetchEStatements()
    } else {
      setIsBusy(false)
      setLoadingCreate(false)
    }
  }

  useEffect(() => {
    fetchEStatements()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'add' && (
        <AddEStatement
          handleClose={handleClose}
          mode={mode}
          handleAction={handleCreate}
          statement={statement}
          setStatement={setStatement}
          loanAccounts={loanAccounts}
          savingAccounts={savingAccounts}
          investmentAccounts={investmentAccounts}
          handleSelectInvestmentAccount={handleSelectInvestmentAccount}
          handleSelectLoanAccount={handleSelectLoanAccount}
          handleSelectSavingAccount={handleSelectSavingAccount}
          customer={customer}
          handleLookupCustomer={handleLookupCustomer}
          selectAllLoanAccounts={selectAllLoanAccounts}
          isBusy={loadingCreate}
        />
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('customers'),
          localize('eStatementOrders'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-2">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center col-3">
                  <label
                    htmlFor="recordsPerPage"
                    style={{ marginLeft: -10, textTransform: 'capitalize' }}
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="recordsPerPage"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center col-4 col gap-2">
                  <label htmlFor="searchTable" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, customerFilter: value })
                    }
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control"
                    defaultValue={reqParams.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchEStatements()
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchEStatements()
                      }
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <SimpleTable
                columns={columns}
                data={statements?.pageCollection}
              />
              <Pager
                itemsCount={statements?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default EStatements
