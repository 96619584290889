/** @format */

import localize from '../../../../Global/Localize'
import SimpleTable from '../../../Tables/SimpleTable'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import Loader from '../../../Loaders/Loader'

export const CustomerMetadataBankAccounts = ({
  selectedCustomer,
  doFetchBankAccounts,
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchBankAccounts: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null,
    )
    if (success) {
      setData(data?.customerBankAccounts)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchBankAccounts) {
      loadData()
    }
  }, [doFetchBankAccounts])

  const columns = [
    {
      label: localize('bankCode'),
      template: r => <>{r.paddedBankBranchBankCode}</>,
    },
    {
      label: localize('bankName'),
      template: r => <>{r.bankBranchBankDescription}</>,
    },
    {
      label: localize('branchCode'),
      template: r => <>{r.paddedBankBranchCode}</>,
    },
    {
      label: localize('branchName'),
      template: r => <>{r.bankBranchDescription}</>,
    },
    { label: localize('accountNumber'), template: r => <>{r.accountNumber}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>{loading ? <Loader /> : <SimpleTable columns={columns} data={data} />}</>
  )
}
