/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'

function GLEntries({ entries = [], editable, onRemove }) {
  const columns = [
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('creditGLAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: `${localize('creditRef')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('creditRef')}_2 (${localize('member')} #)`,
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('creditRef')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('debitGLAccountName'),
      template: r => <>{r?.contraChartOfAccountName}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r?.contraCustomerAccountFullAccountNumber}</>,
    },
    {
      label: `${localize('debitCreditRef')}_1 (${localize('account')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('debitCreditRef')}_2 (${localize('member')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('debitCreditRef')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    {
      label: localize('contraCostCenter'),
      template: r => <>{r?.contraCostCenterDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        columns={columns}
        data={entries}
        contextMenu={
          editable
            ? r => (
                <>
                  <div
                    className="ctxt-menu-item text-danger"
                    onClick={() => onRemove(r)}
                  >
                    <i className="uil uil-times-circle"></i>
                    <span className="text-capitalize">
                      {localize('remove')}
                    </span>
                  </div>
                </>
              )
            : null
        }
      />
    </>
  )
}

export default GLEntries
