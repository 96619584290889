/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { formatDate, getTodaysDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../Helpers/webApi'
import { transactionDateFilter } from '../../../../../Global/enumeration'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import ViewLoanAgingSpoolModal from './ViewLoanAgingSpoolModal'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

const LoanAgingSpools = () => {
  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
      sortBy: 'paddedBatchNumber',
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
      sortBy: 'reference',
    },
    {
      label: localize('dateFilter'),
      template: r => <>{r.dateFilterDescription}</>,
      sortBy: 'dateFilterDescription',
    },
    {
      label: localize('cutOffDate'),
      template: r => <>{formatDate(r.cutOffDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedLoanAgingSpool, setSelectedLoanAgingSpool] = useState({
    dateFilter: Object.keys(transactionDateFilter)[1],
    cutOffDate: getTodaysDate(),
  })
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await postData(
      '/LoanAgingSpool/spool-loan-aging',
      selectedLoanAgingSpool || {
        reference: '',
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedLoanAgingSpool(null)
      setRefreshAt(!refreshAt)
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    setMode(null)
    setSelectedLoanAgingSpool(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/LoanAgingSpool/find-loan-aging-spools-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
      setError(true)
    }
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedLoanAgingSpool({
      dateFilter: Object.keys(transactionDateFilter)[1],
      cutOffDate: getTodaysDate(),
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('backOfficeManagement'), localize('loanAgingSpools')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <AddNewButton handleClickAddButton={handleAdd} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center col-2">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>

                        <div className="col-md-5">
                          <div className="d-flex align-items-center">
                            <label
                              className="text-capitalize col-md-2"
                              htmlFor="searchByDate"
                            >
                              {localize('dateRange')}
                            </label>
                            <DatePicker
                              onSelect={v => {
                                if (v.custom) {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: true,
                                    startDate: v.startDate,
                                    endDate: v.endDate,
                                  })
                                } else {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: false,
                                    dateRequestsFilter: v.value,
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        <form
                          className="d-flex align-items-center col-5 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              text: search,
                            })
                          }}
                        >
                          <label
                            className="ms-2 text-capitalize"
                            htmlFor="searchTable"
                          >
                            {localize('search')}
                          </label>
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.text}
                            onChange={e => {
                              setSearch(e.target.value)
                              setReqParams({
                                ...reqParams,
                                text: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="loanAgingSpoolsFilterButton"
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable
                          data={tableData}
                          columns={columns}
                          contextMenu={r => (
                            <>
                              <div
                                onClick={async () => {
                                  setMode('view')
                                  setSelectedLoanAgingSpool(r)
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-eye"></i>
                                {localize('view')}
                              </div>
                            </>
                          )}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'add' ? (
        <>
          <ModalTemplate
            modalMode={mode}
            modalTitle="loanAgingSpools"
            handleClose={closeModal}
            handleAction={handleSubmit}
            modalClassName="modal-lg"
            actionText={mode === 'add' ? 'create' : ''}
          >
            <div className="row mb-2">
              <div
                className="col-12 d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Reference']?.message
                }
              >
                <label className="col-1 text-capitalize me-2">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control ms-4"
                  value={selectedLoanAgingSpool?.reference || ''}
                  onChange={e => {
                    clearValidation('Reference')
                    setSelectedLoanAgingSpool({
                      ...selectedLoanAgingSpool,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('dateFilter')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="TransactionDateFilter"
                  value={selectedLoanAgingSpool?.dateFilter}
                  onChange={e =>
                    setSelectedLoanAgingSpool({
                      ...selectedLoanAgingSpool,
                      dateFilter: e.value,
                    })
                  }
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['CutOffDate']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('cut-offDate')}
                </label>
                <CustomDatePicker
                  defaultDate={getTodaysDate()}
                  name={'cutOffDate'}
                  onDateChange={date => {
                    setSelectedLoanAgingSpool({
                      ...selectedLoanAgingSpool,
                      cutOffDate: date,
                    })
                  }}
                />
              </div>
            </div>
          </ModalTemplate>
        </>
      ) : mode === 'view' ? (
        <ViewLoanAgingSpoolModal
          mode={mode}
          selectedLoanAgingSpool={selectedLoanAgingSpool}
          closeModal={() => {
            setMode(null)
            setSelectedLoanAgingSpool(null)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default LoanAgingSpools
