/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import Pager from '../../../Components/Tables/Pager'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import GroupedTable from '../../../Components/Tables/GroupedTable'
import { CustomerFilter } from '../../../Global/enumeration'
import { NextOfKinPreference } from './Partials/NextOfKinPreference'
import Tab from '../../../Components/Tabs/Tab'
import { NextOfKinHistory } from './Partials/NextOfKinHistory'
import NextOfKinModal from './Partials/NextOfKinModal'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'

function NextOfKin() {
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortedBy, setSortedBy] = useState(null)
  const [navigatedTab, setNavigatedTab] = useState(0)

  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('membershipClass'),
    column: 'membershipClassDescription',
  })

  const groupingColumns = [
    {
      label: localize('adminDivision'),
      column: 'administrativeDivisionDescription',
    },
    {
      label: localize('domicileBranch'),
      column: 'branchDescription',
    },
    {
      label: `${localize('duty')}/${localize('workStation')}`,
      column: 'dutyStationDescription',
    },
    {
      label: localize('electoralZone'),
      column: 'electoralZoneDescription',
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      column: 'employerDescription',
    },
    {
      label: localize('employmentSector'),
      column: 'employmentSectorDescription',
    },
    {
      label: localize('disabilityType'),
      column: 'individualDisabilityType',
    },
    {
      label: localize('gender'),
      column: 'individualGenderDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'individualIdentityCardTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'individualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'individualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'individualSalutationDescription',
    },
    {
      label: localize('membershipClass'),
      column: 'membershipClassDescription',
    },
    {
      label: localize('membershipStatus'),
      column: 'membershipStatusDescription',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [clickedOnce, setClickedOnce] = useState(false)

  const [nextOfKinPreference, setNextOfKinPreference] = useState([])
  const [nextOfKinHistory, setNextOfKinHistory] = useState([])

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => (
        <>
          {r.individualSalutationDescription} {r.individualFirstName}{' '}
          {r.individualLastName}
        </>
      ),
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.memberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.accountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.personalFileNumber}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.membershipStatusDescription}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r.individualBirthDate, true)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r.individualEmploymentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{formatDate(r.individualEmploymentDate, true)}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r.individualEmploymentDesignation}</>,
    },
    {
      label: localize('employmentTermsOfService'),
      template: r => <>{r.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: localize('disabilityType'),
      template: r => <>{r.individualDisabilityTypeDescription}</>,
    },
    {
      label: localize('hudumaNumber'),
      template: r => <>{r.individualHudumaNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('meetingFrequency'),
      template: r => <>{r.nonIndividualMeetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDay'),
      template: r => <>{r.nonIndividualMeetingDayOfWeekDescription}</>,
    },
    {
      label: localize('meetingTime'),
      template: r => <>{r.nonIndividualMeetingTime}</>,
    },
    {
      label: localize('meetingPlace'),
      template: r => <>{r.nonIndividualMeetingPlace}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.addressCity}</>,
    },
    {
      label: localize('emailAdress'),
      template: r => <>{r.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r.addressInternet}</>,
    },
    {
      label: localize('monthlyContribution'),
      template: r => <>{r.monthlyContribution}</>,
    },

    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.reference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.reference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.reference3}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r.electoralZoneDescription}</>,
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      template: r => <>{r.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r.individualRetirementAge}</>,
    },

    {
      label: localize('age'),
      template: r => <>{r.age}</>,
    },
    {
      label: `${localize('membershipPeriod')}(${localize('months')})`,
      template: r => <>{r.membershipPeriod}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r.administrativeDivisionDescription}</>,
    },
    {
      label: localize('isTaxExempt'),
      template: r => <>{r.isTaxExempt}</>,
    },
    {
      label: localize('inhibitGuaranteeing'),
      template: r => <>{r.inhibitGuaranteeing}</>,
    },
    {
      label: `${localize('isLocked')}`,
      template: r => <>{r.isLocked}</>,
    },
    {
      label: localize('recruitedBy'),
      template: r => <>{r.recruitedBy}</>,
    },

    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const handleOnClickOnce = async (r, navigatedTab) => {
    setClickedOnce(true)
    let endpoint
    switch (navigatedTab) {
      case 0:
        endpoint = '/Customer/find-nextofkin-collection-by-customer-id'
        break
      case 1:
        endpoint = '/Customer/find-nextofkin-history-collection-by-customer-id'
        break
      default:
        return
    }
    setIsBusy(true)
    const { success, data } = await getData(
      endpoint,
      {
        customerId: r.id,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      switch (navigatedTab) {
        case 0:
          setNextOfKinPreference(data.result)
          break
        case 1:
          setNextOfKinHistory(data.result)
          break
        default:
          return
      }
    } else {
      setIsBusy(false)
    }
  }

  const fetchNextOfKinHistory = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Customer/find-nextofkin-history-collection-by-customer-id',
      {
        customerId: r.id,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setNextOfKinHistory(data.result)
    }
  }

  async function loadTableData() {
    setError(false)
    setIsBusy(true)
    const { success, data } = await getData(
      '/customer/find-customers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('nextOfKin')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : error ? (
                <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                  <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                  <div className="fs-5 text-danger">Failed to Load Data</div>
                </div>
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-3">Group By: </label>
                      <select
                        name=""
                        id=""
                        className="form-select text-capitalize"
                        defaultValue={groupBy.column}
                        onChange={e =>
                          setGroupBy({
                            label: groupingColumns.find(
                              f => f.column === e.target.value
                            )?.label,
                            column: e.target.value,
                          })
                        }
                      >
                        {groupingColumns.map(({ label, column }) => (
                          <option className="text-capitalize" value={column}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams?.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                          })
                        }}
                      />
                    </div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <GroupedTable
                      canClickOnce
                      onClickOnce={row => {
                        setSelectedRow(row)
                        handleOnClickOnce(row, navigatedTab)
                      }}
                      groupBy={groupBy}
                      columns={columns}
                      data={tableData}
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              setMode('edit')
                              setSelectedRow(r)
                              fetchNextOfKinHistory(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('edit')}
                          </div>
                          <div
                            onClick={() => {
                              setMode('view')
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-search"></i>
                            <span className="text-capitalize">
                              {localize('view')}
                            </span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  <div className="row mt-3">
                    {clickedOnce && (
                      <Tab
                        onNavigate={c => {
                          setNavigatedTab(c.number)
                          handleOnClickOnce(selectedRow, c.number)
                        }}
                        tabItems={[
                          {
                            label: localize('nextOfKinPreferences'),
                            number: 0,
                            item: (
                              <NextOfKinPreference
                                doGrouping
                                nextOfKinPreference={nextOfKinPreference}
                              />
                            ),
                          },
                          {
                            label: localize('nextOfKinHistory'),
                            number: 1,
                            item: (
                              <NextOfKinHistory
                                nextOfKinHistory={nextOfKinHistory}
                              />
                            ),
                          },
                        ]}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <NextOfKinModal
        mode={mode}
        selectedCustomer={selectedRow}
        onHideModal={() => {
          setMode(null)
        }}
        nextOfKinHistory={nextOfKinHistory}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        onSetMode={m => {
          setMode(m)
        }}
      />
    </>
  )
}

export default NextOfKin
