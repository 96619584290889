/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function CostDistributionRuleLookup({ displayValue, onSelect }) {
  return (
    <>
      <SimpleLookup
        title={localize('costDistributionRuleLookup')}
        url="/lookup/CostDistributionRule/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('name'),
            template: r => <>{r.description}</>,
            sortBy: 'description',
          },
          {
            label: localize('startDate'),
            template: r => <>{formatDate(r.durationStartDate, true)}</>,
          },
          {
            label: localize('endDate'),
            template: r => <>{formatDate(r.durationEndDate, true)}</>,
          },
          {
            label: localize('isLocked'),
            template: r => (
              <span className="text-capitalize">{r.isLocked.toString()}</span>
            ),
          },
          {
            label: localize('createdBy'),
            template: r => <>{r.createdBy}</>,
            sortBy: 'createdBy',
          },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r.createdDate)}</>,
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default CostDistributionRuleLookup
