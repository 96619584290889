/** @format */

import React, { useState, useEffect } from 'react'
import Pager from '../../../../../Components/Tables/Pager'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../Global/Localize'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { ViewCustomerAccountStatement } from '../../../../../Components/ReUsables/Customer/CustomerAccountStatement/ViewCustomerAccountStatement'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'

const EmolumentBatchEntries = ({
  mode,
  entries,
  onSelectItem,
  loadEntries,
  reqParams,
  setReqParams,
  search,
  setSearch,
  entriesCount,
  close,
}) => {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)
  const [entryMode, setEntryMode] = useState(null)
  const [customer, setCustomer] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})

  const onCheckEntries = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedEntries([])
      setSelectedEntries([...entries])
    } else {
      setSelectedEntries([])
      setToggleSelection(checkStatus)
    }
  }

  const removeEntries = async () => {
    if (selectedEntries.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'Select Atleast One Entry',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    const { success } = await postData(
      '/EmolumentBatch/remove-emolument-batch-entries',
      selectedEntries,
      false,
      `Operation Completed Successfully`
    )
    if (success) {
      loadEntries()
    }
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
              onSelectItem([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
              onSelectItem(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('beneficiary'),
      template: r => <>{r.beneficiary}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  async function loadCustomerTableData(r) {
    const { success, data } = await getData(
      `/Customer/find-customer-by-id?customerId=${r.customerAccountCustomerId}`,
      {},
      false
    )
    if (success) {
      setCustomer(data || {})
    }
  }

  async function loadCustomerAccountTableData(r) {
    const { success, data } = await getData(
      `/CustomerAccount/find-customer-account?customerAccountId=${
        r.customerAccountId
      }&includeBalances=${true}&includeProductDescription=${true}`,
      {},
      false
    )
    if (success) {
      setCustomerAccount(data || {})
    }
  }

  const resetEmolumentBatch = reg => {
    const { success } = putData(
      '/EmolumentBatch/unpost-emolument-batch-entry',
      reg,
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      loadEntries()
    }
  }

  return (
    <>
      {entryMode === 'view' ? (
        <ModalTemplate
          modalMode={mode}
          handleClose={close}
          modalTitle="emolumentBatchEntries"
          modalClassName="modal-xl"
          hideCancel
          hideUpdate
        >
          <ViewCustomerAccountStatement
            selectedCustomerAccount={customerAccount}
            selectedCustomer={customer}
          />
        </ModalTemplate>
      ) : (
        ''
      )}
      <div className="row px-3 mb-2">
        <div className="d-flex align-items-center col-2">
          <label
            htmlFor="recordsPerPage"
            className=""
            style={{ marginLeft: -10, textTransform: 'capitalize' }}
          >
            {localize('recordsPerPage')}
          </label>
          <select
            name=""
            id="recordsPerPage"
            className="form-select ms-1"
            style={{ maxWidth: 'fit-content' }}
            defaultValue={reqParams.pageSize}
            onChange={e => {
              setReqParams({
                ...reqParams,
                pageIndex: 0,
                pageSize: e.target.value,
              })
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="col-md-2">
          <div className="d-flex align-items-center  me-2">
            <label className="text-capitalize col-3" htmlFor="searchBystatus">
              {localize('status')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchEntryStatus"
              value={reqParams?.status}
              onChange={e =>
                setReqParams({
                  ...reqParams,
                  status: e.value,
                })
              }
            />
          </div>
        </div>
        <form
          className="d-flex align-items-center col-8 col"
          onSubmit={e => {
            e.preventDefault()
            setReqParams({
              ...reqParams,
              text: search,
            })
          }}
        >
          <label className="col-1 ms-2 text-capitalize" htmlFor="searchTable">
            {localize('search')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CustomerFilter"
            value={reqParams?.customerFilter}
            onChange={e =>
              setReqParams({
                ...reqParams,
                customerFilter: e.value,
              })
            }
          />
          <input
            type="search"
            id="searchTable"
            className="form-control ms-2"
            defaultValue={reqParams.text}
            onChange={e => {
              setSearch(e.target.value)
              setReqParams({
                ...reqParams,
                text: e.target.value,
              })
            }}
          />
          <button
            id="emolumentBatchEntriesFilterButton"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              loadEntries()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </form>
      </div>
      <SimpleTable
        columns={mode === 'edit' ? columns : columns.slice(1, -1)}
        data={entries}
        contextMenu={r => (
          <>
            <div
              onClick={async () => {
                setEntryMode('view')
                await loadCustomerTableData(r)
                await loadCustomerAccountTableData(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
            <div
              onClick={async () => {
                if (r.status !== '2') {
                  showNotification(
                    'Emolument Payment Schedule',
                    'info',
                    'sorry, but you must first select a batch whose status is posted'
                  )
                } else {
                  resetEmolumentBatch(r)
                }
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-unlock-alt"></i>
              {localize('reset')}
            </div>
          </>
        )}
      />
      <div className="row mb-2 ">
        <div className="col-md-9">
          <div className="d-flex align-items-center mb-2"></div>
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
        {mode === 'view' && (
          <div className="col-3 d-flex ">
            <label className="col-2 me-1 text-capitalize mt-1">
              {localize('entries')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={entriesCount}
            />
          </div>
        )}
      </div>

      {mode === 'edit' && (
        <>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-selection"
                  checked={toggleSelection}
                  onCheck={v => {
                    onCheckEntries(v)
                  }}
                />
                <label
                  htmlFor="toggle-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex justify-content-end align-items-end">
                <button
                  onClick={removeEntries}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('remove')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EmolumentBatchEntries
