/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { parseDate } from '../../../../../Helpers/formatters'
import Swal from 'sweetalert2'

function Referees({ referees, readOnly, onChange }) {
  const [collection, setCollection] = useState(referees)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const columns = [
    { label: 'name', template: r => <>{r.witnessFullName}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r.witnessReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.witnessReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.witnessReference3}</>,
    },
    {
      label: 'gender',
      template: r => <>{r.witnessIndividualGenderDescription}</>,
    },
    {
      label: 'identityCardType',
      template: r => <>{r.witnessIdentityCardTypeDescription}</>,
    },
    {
      label: 'identityCardNumber',
      template: r => <>{r.witnessIdentityCardNumber}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r.witnessAddressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r.witnessAddressAddressLine2}</>,
    },
    { label: `physicalAddress`, template: r => <>{r.witnessAddressStreet}</> },
    { label: `postalCode`, template: r => <>{r.witnessAddressPostalCode}</> },
    { label: `city`, template: r => <>{r.witnessAddressCity}</> },
    { label: `emailAddress`, template: r => <>{r.witnessAddressEmail}</> },
    { label: `landLine`, template: r => <>{r.witnessAddressLandLine}</> },
    { label: `mobileLine`, template: r => <>{r.witnessAddressMobileLine}</> },
    { label: `remarks`, template: r => <>{r.remarks}</> },
  ]

  useEffect(() => {
    if (!readOnly) {
      onChange(collection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  return readOnly ? (
    <SimpleTable data={collection} columns={columns} />
  ) : (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerName')}
          </label>
          <CustomersLookup
            onSelect={c => setSelectedCustomer(c)}
            displayValue={selectedCustomer?.fullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.membershipClassCustomerTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerMember')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.memberNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employer')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.employerDescription}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('duty/Workstation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.dutyStationDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identification')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.identificationNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.administrativeDivisonDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('registrationDate')}
          </label>
          <input
            type="date"
            className="form-control"
            disabled
            value={parseDate(selectedCustomer?.registrationDate)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.accountNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.membershipNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('pfNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.personalFileNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.remarks}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-1 text-capitalize">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedCustomer?.newRemarks}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                newRemarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="bg-light d-flex align-items-center justify-content-end my-2 p-2">
        <button
          className="btn btn-success px-3"
          onClick={() => {
            let c = [...collection]
            if (
              collection.find(cus => cus.customerId === selectedCustomer?.id)
            ) {
              return Swal.fire('error', 'referee exists', 'error')
            }
            let entry = {
              id: selectedCustomer?.id,
              customerId: selectedCustomer?.id,
              witnessId: selectedCustomer?.id,
              witnessFullName: selectedCustomer?.fullName,
              witnessIndividualIdentityCardTypeDescription:
                selectedCustomer?.identityCardTypeDescription,
              witnessIndividualIdentityCardNumber:
                selectedCustomer?.individualIdentityCardNumber,
              witnessIndividualGenderDescription:
                selectedCustomer?.individualGenderDescription,
              witnessAddressAddressLine1: selectedCustomer?.addressAddressLine1,
              witnessAddressAddressLine2: selectedCustomer?.addressAddressLine2,
              witnessAddressStreet: selectedCustomer?.addressStreet,
              witnessAddressPostalCode: selectedCustomer?.addressPostalCode,
              witnessAddressCity: selectedCustomer?.addressCity,
              witnessAddressEmail: selectedCustomer?.addressEmail,
              witnessAddressLandLine: selectedCustomer?.addressLandLine,
              witnessAddressMobileLine: selectedCustomer?.addressMobileLine,
              witnessReference1: selectedCustomer?.accountNumber,
              witnessReference2: selectedCustomer?.memberNumber,
              witnessReference3: selectedCustomer?.personalFileNumber,
              remarks: selectedCustomer?.newRemarks,
            }
            c.push(entry)
            setCollection(c)
          }}
        >
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-3"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('refereeCollection')}
        </div>
        <SimpleTable
          data={collection}
          columns={columns}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-danger"
                onClick={() => {
                  let col = [...collection]
                  let i = col.findIndex(c => c.id === r.id)
                  col.splice(i, 1)
                  setCollection(col)
                }}
              >
                <i className="uil uil-times-circle"></i>
                <span className="text-capitalize">{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default Referees
