/** @format */

import ModulesAccessControlList from '../../Pages/SystemAdministration/AccessControlList/Modules/Index'
import AccessControlListOperations from '../../Pages/SystemAdministration/AccessControlList/Operations/Index'
import AuditLogs from '../../Pages/SystemAdministration/AuditLogs/AuditLogs'
import AuditTrails from '../../Pages/SystemAdministration/AuditTrails/Index'
import Banks from '../../Pages/SystemAdministration/Banks/Banks'
import Branches from '../../Pages/SystemAdministration/Branches/Branches'
import Companies from '../../Pages/SystemAdministration/Companies/V2/Companies'
import Currencies from '../../Pages/SystemAdministration/Currencies'
import ForexRates from '../../Pages/SystemAdministration/ForexRates'
import NumberingSeries from '../../Pages/SystemAdministration/NumberingSeries'
import PostingPeriods from '../../Pages/SystemAdministration/PostingPeriods'
import Reports from '../../Pages/SystemAdministration/Reports/Reports'
import RisksRegister from '../../Pages/SystemAdministration/RisksRegister/RisksRegister'
import Roles from '../../Pages/SystemAdministration/Roles/Index'
import TemporaryRoles from '../../Pages/SystemAdministration/TemporaryRoles/Index'
import Custom from '../../Pages/SystemAdministration/Workflow/Custom/Custom'
import Default from '../../Pages/SystemAdministration/Workflow/Default/Default'
import Users from '../../Pages/SystemAdministration/Operations/Users/Users'

const administrationMenus = [
  {
    iconClass: 'uil uil-cog',
    label: 'systemAdministration',
    code: 1,
    setup: [
      {
        label: 'currencies',
        component: <Currencies menuCode={1001} />,
        code: 1001,
        parentCode: 1,
        // disabled: true,
      },
      {
        label: 'forexRates',
        component: <ForexRates menuCode={1002} />,
        code: 1002,
        parentCode: 1,
        description: 'foreignExchangeRates',
      },
      {
        label: 'banks',
        component: <Banks menuCode={1003} />,
        code: 1003,
        parentCode: 1,
      },
      {
        label: 'companies',
        component: <Companies menuCode={1004} />,
        code: 1004,
        parentCode: 1,
      },
      {
        label: 'branches',
        component: <Branches menuCode={1005} />,
        code: 1005,
        parentCode: 1,
      },
      {
        label: 'postingPeriods',
        component: <PostingPeriods menuCode={1006} />,
        code: 1006,
        parentCode: 1,
      },
      {
        label: 'numberingSeries',
        component: <NumberingSeries menuCode={1007} />,
        code: 1007,
        parentCode: 1,
      },
    ],
    operations: [
      { label: 'reports', component: <Reports />, code: 1500, parentCode: 1 },
      {
        label: 'security',
        children: [
          {
            label: 'roles',
            component: <Roles menuCode={1502} />,
            code: 1502,
            parentCode: 1,
          },
          {
            label: 'users',
            component: <Users menuCode={1503} />,
            code: 1503,
            parentCode: 1,
          },
          {
            label: 'accessControlList',
            parentCode: 1,
            children: [
              {
                label: 'modules',
                component: <ModulesAccessControlList menuCode={1505} />,
                code: 1505,
                parentCode: 1,
                description: 'modulesACL',
              },
              {
                label: 'operations',
                component: <AccessControlListOperations menuCode={1506} />,
                code: 1506,
                parentCode: 1,
                description: 'operationsACL',
              },
              {
                label: 'temporaryRoles',
                component: <TemporaryRoles menuCode={1507} />,
                code: 1507,
                parentCode: 1,
              },
            ],
          },
        ],
      },
      {
        label: 'workflow',
        // disabled: true,
        children: [
          {
            label: 'default',
            component: <Default />,
            code: 1509,
            parentCode: 1,
            description: 'workflow(default)',
          },
          {
            label: 'custom',
            component: <Custom />,
            code: 1510,
            parentCode: 1,
            description: 'workflow(custom)',
          },
        ],
      },
      {
        label: 'risksAndAudit',
        // disabled: true,
        children: [
          {
            label: 'auditTrails',
            component: <AuditTrails />,
            code: 1512,
            parentCode: 1,
          },
          {
            label: 'auditLogs',
            component: <AuditLogs />,
            code: 1513,
            parentCode: 1,
          },
          {
            label: 'risksRegister',
            component: <RisksRegister />,
            code: 1514,
            parentCode: 1,
          },
        ],
      },
    ],
  },
]

export default administrationMenus
