/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { budgetAdjustmentType } from '../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import Tab from '../../../../../Components/Tabs/Tab'
import BudgetHierarchyLookup from '../../../../../Components/Lookup/BudgetHierarchyLookup'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

function Appropriation() {
  const [data, setData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [sortedBy, setSortedBy] = useState(null)
  const [tableData, setTableData] = useState([])
  const [appropriation, setAppropriation] = useState({})
  const [budgetHierarchyId, setBudgetHierachyId] = useState('')
  const [postingPeriodId, setPostingPeriodId] = useState('')
  const [reqParams, setReqParams] = useState({
    budgetHierarchyId: null,
    postingPeriodId: null,
  })

  const columns = [
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{r?.totalValue}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r?.runningBalance)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, true)}</>,
    },
  ]
  const tabItems = [
    {
      label: localize('appropriationStatement'),
      item: (
        <div className="p-2">
          <SimpleTable
            columns={columns}
            data={data?.result}
            onSort={(c, i) => handleSort(c, i)}
          />
        </div>
      ),
    },
  ]

  const fetchAppropriationData = async () => {
    setIsBusy(true)
    const url = '/Budget/find-budget-appropriations'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setData(data)
    }

    setIsBusy(false)
  }

  const handleCreateAppropriation = async () => {
    setIsBusy(true)
    const url = '/Budget/create-budget-appropriation'

    const { success } = await postData(
      url,
      { appropriation },
      false,
      'Operation completed successfully!'
    )

    if (success) {
      setIsBusy(false)
      fetchAppropriationData()
      setAppropriation({
        budgetAdjustmentType: Object.keys(budgetAdjustmentType)[0],
        description: '',
        totalValue: '',
      })
      showNotification('Budget Appropriation', 'success')
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${appropriation?.valueDate}`

    const { success, data } = await putData(url, false)

    if (success && !data?.result) {
      setIsBusy(false)
      setAppropriation({
        ...appropriation,
        valueDate: null,
      })

      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (appropriation?.valueDate) evaluateValueDate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appropriation?.valueDate])

  useEffect(() => {
    fetchAppropriationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('budgetManagement'),
          localize('appropriation'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="mb-4">
                <div className="row g-3 d-flex justify-content-between mb-2">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center with-validation">
                      <label
                        htmlFor="hierachy"
                        className="text-capitalize col-md-3"
                      >
                        {localize('hierachy')}
                      </label>
                      <BudgetHierarchyLookup
                        displayValue={
                          appropriation?.sourceBudgetHierarchyDescription
                        }
                        onSelect={x => {
                          setAppropriation({
                            ...appropriation,
                            budgetHierarchyId: x?.id,
                            sourceBudgetHierarchyDescription: x?.description,
                          })
                          setBudgetHierachyId(x?.id)
                          setReqParams({
                            ...reqParams,
                            budgetHierarchyId: budgetHierarchyId,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="postingPeriod"
                        className="text-capitalize col-md-3"
                      >
                        {localize('postingPeriod')}
                      </label>
                      <PostingPeriodLookup
                        displayValue={appropriation?.postingPeriodDescription}
                        onSelect={r => {
                          if (!r.isActive) {
                            return Swal.fire({
                              icon: 'error',
                              title: 'Operation not allowed',
                              text: 'Selected posting period is closed',
                            })
                          } else {
                            setAppropriation({
                              ...appropriation,
                              postingPeriodId: r?.id,
                              postingPeriodDescription: r?.description,
                            })
                            setPostingPeriodId(r?.id)
                            setReqParams({
                              ...reqParams,
                              postingPeriodId: postingPeriodId,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row g-3 d-flex justify-content-betwee mb-2">
                  <div className="col-md-6 d-flex">
                    <label className="text-capitalize col-md-3">
                      {localize('description')}
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      style={{ width: '100%' }}
                      value={appropriation?.description}
                      onChange={e => {
                        setAppropriation({
                          ...appropriation,
                          description: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row g-3 d-flex justify-content-between mb-2">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="adjustmentType"
                        className="text-capitalize col-md-3"
                      >
                        {localize('adjustmentType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="BudgetAdjustmentType"
                        value={appropriation?.budgetAdjustmentType}
                        onChange={e =>
                          setAppropriation({
                            ...appropriation,
                            budgetAdjustmentType: e.value,
                          })
                        }
                        sort
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="totalValue"
                        className="text-capitalize col-md-3"
                      >
                        {localize('totalValue')}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={''}
                        onChange={e =>
                          setAppropriation({
                            ...appropriation,
                            totalValue: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-2">
                <button
                  onClick={handleCreateAppropriation}
                  className="btn btn-success waves-effect waves-light text-capitalize"
                  style={{ maxWidth: 'fit-content' }}
                >
                  {localize('update')}
                </button>
              </div>
              <div className="mb-4">
                <Tab preload tabItems={tabItems} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Appropriation
