/** @format */

import { UserManager } from 'oidc-client-ts'

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  disablePKCE: false,
  response_mode: process.env.REACT_APP_RESPONSE_MODE,
  scope: process.env.REACT_APP_SCOPES,
  automaticSilentRenew: false,
  monitorSession:true,
  silentRequestTimeoutInSeconds: 300,
  silent_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI, // this uri should correspond to an existing route in your application
}

const userManager = new UserManager(oidcConfig)

export default userManager
