/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'

import { Modal, ModalBody } from 'reactstrap'
import localize from '../../../../Global/Localize'
// import WalkthroughV2 from '../../../../Components/Walkthrough/v2/WalkthroughV2'
import PrimaryDetails from './V2/PrimaryDetails'
import Charges from './V2/Charges'
import LoanFlags from './Tabs/LoanFlags'
import OffsetableLoanProducts from './V2/OffsetableLoanProducts'
import AuxilliarySettings from './V2/AuxilliarySettings'
import Cycles from './Tabs/Cycles'
import Deductibles from './Tabs/Deductibles'
import Walkthrough from '../../../../Components/Walkthrough/Walkthrough'
import { getData } from '../../../../Helpers/webApi'
import Loader from '../../../../Components/Loaders/Loader'

function AddLoanProduct({ mode, closeModal, loanProduct, loanCreated }) {
  const [isBusy, setIsBusy] = useState(false)
  const [loan, setLoan] = useState(loanProduct)
  const pages = [
    {
      title: localize('primaryDetails'),
      content: (
        <PrimaryDetails
          loan={loan}
          mode={mode}
          onCreateLoan={l => {
            loanCreated(l)
          }}
        />
      ),
    },
    {
      title: localize('charges'),
      content: <Charges loan={loan} mode={mode} />,
    },
    {
      title: localize('offsetableLoanProducts'),
      content: <OffsetableLoanProducts loan={loan} mode={mode} />,
    },
    {
      title: localize('auxiliarySettings'),
      content: <AuxilliarySettings loan={loan} mode={mode} />,
    },
    {
      title: localize('loanCycles'),
      content: <Cycles loanProduct={loan} disabled={mode === 'view'} />,
    },
    {
      title: localize('deductibles'),
      content: <Deductibles loanProduct={loan} disabled={mode === 'view'} />,
    },
    {
      title: localize('flags'),
      content: (
        <LoanFlags
          loan={loan}
          disabled={mode === 'view'}
          reloadLoan={reloadLoan}
        />
      ),
    },
  ]

  useEffect(() => {
    setInitPages(
      pages.map(p => ({
        ...p,
        component: p.content,
        skippable: true,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan?.id, mode])

  async function reloadLoan(id) {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-by-id',
      { loanProductId: id },
      false
    )
    if (success) {
      setLoan(data)
    }
    setIsBusy(false)
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [initPages, setInitPages] = useState(
    pages.map(p => ({
      ...p,
      component: p.content,
      skippable: !!loan?.id,
    }))
  )
  return (
    <>
      <Modal isOpen className="modal-xl">
        <ModalBody className="bg-white">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="text-capitalize d-flex align-items-center mb-2">
                <h4 style={{ marginTop: 5 }}>{localize(mode)}</h4>
                <i className="uil uil-angle-right mx-2"></i>
                <span className="text-muted fs-6 text-uppercase">
                  {localize('loans')}
                  {loanProduct && <> ({loanProduct?.description})</>}
                </span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Walkthrough
                pages={initPages}
                activePage={currentPage}
                changePage={p => setCurrentPage(p)}
                // changePage={p => alert(p)}
              />
              {/* <WalkthroughV2
            pages={pages}
            currentPage={currentPage}
            moveToPage={p => setCurrentPage(p)}
          /> */}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddLoanProduct
