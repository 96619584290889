/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import NonBlockingLoader from '../Loaders/NonBlockingLoader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function SimpleLookup({
  url,
  params,
  columns,
  displayValue,
  onSelect,
  title,
  useText,
  disabled,
}) {
  const [reqParams, setReqParams] = useState(params)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setIsBusy(false)
      setData(data)
      setTableData(data.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {title}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            <div className="d-flex align-items-center justify-content-space-between ">
              <div className="d-flex align-items-center my-2 col-4">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              <div style={{ flex: 1 }}></div>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({
                    ...reqParams,
                    [useText ? 'text' : 'filterText']: search,
                  })
                }}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="d-flex align-items-center my-2">
                  <label className="text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <input
                    defaultValue={
                      useText ? reqParams.text : reqParams.filterText
                    }
                    onChange={e => setSearch(e.target.value)}
                    type="search"
                    className="form-control ms-3"
                    id="searchTable"
                  />
                  <button
                    type="submit"
                    className="btn btn-primary ms-1 text-capitalize"
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </form>
            </div>
            <div className=" py-1">
              <SimpleTable
                columns={columns}
                data={tableData}
                onSelect={r => {
                  setModalActive(false)
                  onSelect(r)
                }}
                canSelect
                onSort={(c, i) => handleSort(c, i)}
                sortedBy={sortedBy}
              />
            </div>
            <Pager
              itemsCount={data.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={5}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
            {isBusy && <NonBlockingLoader />}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default SimpleLookup
