/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../../../Helpers/formatters'
import { postData, putData } from '../../../../../../Helpers/webApi'
import SelectableBranches from './SelectableBranches'
import SelectableDepartments from './SelectableDepartments'
import SelectableSalaryGroups from './SelectableSalaryGroups'

const SalaryProcessingEntities = ({
  salaryProcessing,
  selectedSalaryGroups,
  setSelectedSalaryGroups,
  selectedBranches,
  setSelectedBranches,
  selectedDepartments,
  setSelectedDepartments,
}) => {
  const [employees, setEmployees] = useState(null)
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [toggleEmployeesSelection, setToggleEmployeesSelection] =
    useState(false)
  const [tabLoading, setTabLoading] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [groups, setGroups] = useState([])
  const [departments, setDepartments] = useState([])
  const [branches, setBranches] = useState([])

  const employeesColumns = [
    {
      label: localize('select'),
      template: r => (
        <div className="mt-2">
          <Checkbox
            checked={selectedEmployees.find(x => x.id === r?.id)}
            onCheck={c => {
              if (c) {
                setSelectedEmployees([...selectedEmployees, r])
                onSelectEmployees([...selectedEmployees, r])
              } else {
                setSelectedEmployees(
                  selectedEmployees.filter(s => s.id !== r?.id)
                )
                onSelectEmployees(selectedEmployees.filter(s => s.id !== r?.id))
              }
            }}
          />
        </div>
      ),
    },
    {
      label: localize('name'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountNumber}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerPersonalFileNumber}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.customerSerialNumber}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.designationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.departmentDescription}</>,
    },

    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.bloodGroupDescription}</>,
    },
    {
      label: localize('nssfNumber'),
      template: r => <>{r?.nationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('nhifNumber'),
      template: r => <>{r?.nationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r?.customerPersonalIdentificationNumber}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleSelectBranches = b => {
    setBranches(b)
  }

  const handleSelectDepartments = d => {
    setDepartments(d)
  }

  const handlSelectGroups = g => {
    setGroups(g)
  }

  const onCheckEmployees = (checkStatus, employees) => {
    if (checkStatus) {
      setToggleEmployeesSelection(checkStatus)
      setSelectedEmployees([...employees])
    } else {
      setSelectedEmployees([])
      setToggleEmployeesSelection(checkStatus)
    }
  }

  const onSelectEmployees = (cust, checked) => {
    if (checked) {
      setEmployees([...employees, cust])
    } else {
      const newEntry = employees.filter(data => data?.id !== cust?.id)
      setEmployees(newEntry)
    }
  }

  const processEmployeesSalary = async () => {
    setIsBusy(true)
    const { success, data } = await putData(
      `/SalaryPeriod/process-salary`,
      {
        salaryPeriod: salaryProcessing,
        employees: selectedEmployees,
      },
      false
    )
    if (success) {
      setEmployees(data.result)
      setIsBusy(false)
      showNotification('Salary Processing', 'success')
    } else {
      setEmployees([])
      setIsBusy(false)
    }
  }

  const fetchEmployees = async () => {
    if (!salaryProcessing) return

    setTabLoading(true)
    const { success, data } = await postData(
      `/Employee/find-employees-by-salary-group-branches-and-departments`,
      {
        salaryPeriod: salaryProcessing,
        salaryGroups: groups,
        branches,
        departments,
      },
      false
    )

    if (success) {
      setEmployees(data)
      onCheckEmployees(true, data)
    }
    setTabLoading(false)
  }

  useEffect(() => {
    fetchEmployees()

    // eslint-disable-next-line
  }, [branches, departments, groups])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('salaryGroups'),
                  item: (
                    <>
                      <SelectableSalaryGroups
                        setSelectedSalaryGroups={setSelectedSalaryGroups}
                        selectedSalaryGroups={selectedSalaryGroups}
                        prefix={'salary-processing-salary-groups-modal'}
                        onItemsSelection={handlSelectGroups}
                      />
                    </>
                  ),
                },
                {
                  label: localize('branches'),
                  item: (
                    <>
                      <SelectableBranches
                        setSelectedBranches={setSelectedBranches}
                        selectedBranches={selectedBranches}
                        prefix={'salary-processing-branches-modal'}
                        onItemsSelection={handleSelectBranches}
                      />
                    </>
                  ),
                },
                {
                  label: localize('departments'),
                  item: (
                    <>
                      <SelectableDepartments
                        setSelectedDepartments={setSelectedDepartments}
                        selectedDepartments={selectedDepartments}
                        prefix={'salary-processing-departments-modal'}
                        onItemsSelection={handleSelectDepartments}
                      />
                    </>
                  ),
                },
                {
                  label: localize('employees'),

                  item: (
                    <>
                      {tabLoading ? (
                        <Loader />
                      ) : (
                        <SimpleTable
                          columns={employeesColumns}
                          data={employees}
                          selectables
                        />
                      )}
                      <div className="mb-3 g-3 mt-3">
                        <div className="d-flex align-items-center">
                          <Checkbox
                            id="toggle-employee-selection"
                            checked={toggleEmployeesSelection}
                            onCheck={v => {
                              onCheckEmployees(v, employees)
                            }}
                          />
                          <label
                            htmlFor="toggle-employee-selection"
                            className="text-capitalize ms-2"
                          >
                            {localize('toggleSelection')} ?
                          </label>
                        </div>
                        <div
                          className="d-flex justify-content-end align-items-center p-2"
                          style={{ borderRadius: 5 }}
                        >
                          <button
                            onClick={() => {
                              processEmployeesSalary()
                            }}
                            className="btn btn-success and remove ms-3 px-4"
                          >
                            {localize('update')}
                          </button>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  )
}

export default SalaryProcessingEntities
