/** @format */

import React from 'react'
import localize from '../../../../../../Global/Localize'
import MorguePatientLookup from '../../../../../../Components/Lookup/MorguePatientLookup'
import TransactionTypeLookup from '../../../../../../Components/Lookup/TransactionTypeLookup'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import { Spinner } from 'reactstrap'
import { useEffect } from 'react'

const PatientRefund = ({
  transaction,
  setTransaction,
  selectedMorguePatient,
  setSelectedMorguePatient,
  transactionType,
  setTransactionType,
}) => {
  const [availableBalance, setAvailableBalance] = useState(0)
  const [isBusy, setIsBusy] = useState(false)

  const fetchAvailableBalance = async () => {
    setIsBusy(true)

    const url = '/MorguePatient/find-morgue-patient-available-balance'

    const { success, data } = await getData(
      url,
      {
        id: transaction?.patientId,
      },
      false
    )

    if (success) {
      setAvailableBalance(data?.result)
    }

    setIsBusy(false)
  }

  const handleSelectPatientLookup = patient => {
    if (patient?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected patient is locked.'
      )

    setSelectedMorguePatient(patient)
  }

  const handleSelectTransactionType = transactionType => {
    if (transactionType?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected transaction type is locked.'
      )

    setTransactionType(transactionType)
  }

  useEffect(() => {
    if (selectedMorguePatient?.patientId) fetchAvailableBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMorguePatient?.patientId])

  return (
    <div className="row g-3">
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('morguePatient')}
        </label>

        <MorguePatientLookup
          displayValue={selectedMorguePatient?.fullName}
          onSelect={handleSelectPatientLookup}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('transactionType')}
        </label>

        <TransactionTypeLookup
          displayValue={transactionType?.description}
          onSelect={handleSelectTransactionType}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('availableBalance')}
        </label>

        {isBusy ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <MaskNumber
            disabled
            defaultMaskValue={availableBalance || 0}
          />
        )}
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('amount')}</label>

        <MaskNumber
          onMaskNumber={e => {
            setTransaction({
              ...transaction,
              amount: e.target.value,
            })
          }}
          defaultMaskValue={transaction?.amount || 0}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('reference')}</label>

        <input
          className="form-control"
          type="text"
          onChange={e => {
            setTransaction({
              ...transaction,
              reference: e.target.value,
            })
          }}
          value={transaction?.reference}
        />
      </div>
    </div>
  )
}

export default PatientRefund
