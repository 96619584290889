/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'

import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import {
  QueuePriority,
  apportionFrom,
  apportionTo,
  apportionedComponent,
  batchEntryStatus,
  interAccountTransferBatchType,
} from '../../../../../../Global/enumeration'
import localize from '../../../../../../Global/Localize'
import CustomerAccountDebitCreditPartial from '../../../../../../Components/ReUsables/CustomerAccount/CustomerAccountDebitCreditPartial'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import IAEntries from '../../Partials/IAEntries'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../../../../Helpers/extensions'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import DynamicCharges from '../../../../../../Components/Selectables/Plain/DynamicCharges'
import { object, string } from 'yup'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import TransactionReferenceLookup from '../../../../../../Components/Lookup/TransactionReferenceLookup'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
//import EnumsCombo from '../../../../../../Components/ComboBox/EnumsCombo'

function AddInterACTransfer({
  edit,
  mode,
  closeModal,
  transfer,
  loadTableData,
  dynamicChargesToBeSelected,
  setDynamicChargesToBeSelected,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [iTransfer, setITransfer] = useState({ type: 0, ...transfer })
  const [states, setStates] = useState({ debit: 1, credit: 1 })
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState({
    apportionFrom: states.debit,
    apportionTo: states.credit,
    apportionedComponent: 1,
    primaryDescription: '',
    secondaryDescription: '',
    reference: '',
    apportionedAmount: '',
  })
  const [selectedDebitAccount, setSelectedDebitAccount] = useState(null)
  const [selectedCreditAccount, setSelectedCreditAccount] = useState(null)
  const [debitChartOfAccount, setDebitChartOfAccount] = useState(null)
  const [creditChartOfAccount, setCreditChartOfAccount] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedDynamicCharges, setSelectedDynamicCharges] = useState([])
  const [
    interAccountTransferValidationBag,
    setInterAccountTransferValidationBag,
  ] = useState({})

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [apportionedAmount, setApportioned] = useState(null)
  const [shortageAmount, setShortage] = useState(null)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    interAccountTransferBatchEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const handleOnSelectDebitAccount = debitDetails => {
    if (!debitDetails.isLocked) {
      setSelectedDebitAccount({ ...selectedDebitAccount, ...debitDetails })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedDebitAccount(null)
    }
  }

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/InterAccountTransferBatch/find-inter-account-transfer-batch-entries-by-inter-account-transfer-batch-id-in-page`,
      {
        interAccountTransferBatchId: transfer.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
      setApportioned(data.totalApportioned)
      setShortage(data.totalShortage)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [transfer?.id, reqParams.pageSize, reqParams.pageIndex])

  const runValidation = async model => {
    let interAccountTransferSchema = object({
      primaryDescription: string().required('Primary Description is required'),
      secondaryDescription: string().required(
        'Secondary Description is required'
      ),
      reference: string().required('Reference is required'),
      apportionedAmount: string().required(),
    })

    try {
      await interAccountTransferSchema.validate(model, { abortEarly: false })
      return true
    } catch (e) {
      const validations = e.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setInterAccountTransferValidationBag(validations)
    }
  }

  const handleOnAddInterAccountTransferModel = async () => {
    if (!selectedDebitAccount && !debitChartOfAccount) {
      return Swal.fire({
        icon: 'info',
        text: 'Selected Debit Account',
        title: 'Ooops!',
        showCloseButton: true,
      })
    } else if (!selectedCreditAccount && !creditChartOfAccount) {
      return Swal.fire({
        icon: 'info',
        text: 'Selected Credit Account',
        title: 'Ooops!',
        showCloseButton: true,
      })
    } else {
      const modelValid = await runValidation(entry)
      if (modelValid) {
        if (mode === 'add') {
          stageEntry()
          clearFields()
        } else {
          addEntryToTheDB()
          clearFields()
        }
      }
    }
  }

  const addEntryToTheDB = async () => {
    const newIATEntry = {
      interAccountTransferBatchId: transfer.id,
      status: 1,
      statusDescription: Object.values(batchEntryStatus)[0].name,

      apportionFrom: entry?.apportionFrom,
      apportionFromDescription: apportionFrom[entry?.apportionFrom].name,
      apportionTo: entry?.apportionTo,
      apportionToDescription: apportionTo[entry?.apportionTo].name,
      apportionedComponent: entry?.apportionedComponent,
      apportionedComponentDescription:
        apportionedComponent[entry?.apportionedComponent].name,

      apportionedAmount: entry?.apportionedAmount,
      primaryDescription: entry?.primaryDescription,
      secondaryDescription: entry?.secondaryDescription,
      reference: entry?.reference,

      contraCustomerAccountId: selectedDebitAccount?.id,
      contraCustomerAccountFullAccountNumber:
        selectedDebitAccount?.fullAccountNumber,
      contraCustomerAccountCustomerFullName:
        selectedDebitAccount?.customerFullName,
      paddedContraCustomerAccountCustomerAccountNumber:
        selectedDebitAccount?.paddedCustomerAccountNumber,
      formattedContraCustomerAccountCustomerMemberNumber:
        selectedDebitAccount?.paddedCustomerMemberNumber,
      contraCustomerAccountCustomerPersonalFileNumber:
        selectedDebitAccount?.customerPersonalFileNumber,

      contraChartOfAccountId: debitChartOfAccount?.contraChartOfAccountId,
      contraChartOfAccountName: debitChartOfAccount?.contraChartOfAccountName,
      contraChartOfAccountAccountType: debitChartOfAccount?.accountType,
      contraChartOfAccountCostDistributionRuleId:
        debitChartOfAccount?.contraChartOfAccountCostDistributionRuleId,
      contraChartOfAccountCostDistributionRuleDescription:
        debitChartOfAccount?.contraChartOfAccountCostDistributionRuleDescription,

      customerId: selectedCustomer?.id,
      customerAccountId: selectedCreditAccount?.id,
      customerAccountFullAccountNumber:
        selectedCreditAccount?.fullAccountNumber,
      customerAccountCustomerFullName: selectedCreditAccount?.customerFullName,
      paddedCustomerAccountCustomerAccountNumber:
        selectedCreditAccount?.paddedCustomerAccountNumber,
      formattedCustomerAccountCustomerMemberNumber:
        selectedCreditAccount?.paddedCustomerMemberNumber,
      customerAccountCustomerPersonalFileNumber:
        selectedCreditAccount?.customerPersonalFileNumber,

      chartOfAccountId: creditChartOfAccount?.chartOfAccountId,
      chartOfAccountName: creditChartOfAccount?.chartOfAccountName,
      chartOfAccountAccountType:
        creditChartOfAccount?.chartOfAccountAccountType,
      contraChartOfAccountAccountTypeDescription:
        creditChartOfAccount?.contraChartOfAccountAccountTypeDescription,
      chartOfAccountCostDistributionRuleId:
        creditChartOfAccount?.chartOfAccountCostDistributionRuleId,
      chartOfAccountCostDistributionRuleDescription:
        creditChartOfAccount?.chartOfAccountCostDistributionRuleDescription,
    }
    setIsBusy(true)
    const { success } = await postData(
      '/InterAccountTransferBatch/create-inter-account-transfer-batch-entry',
      newIATEntry,
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      loadEntries()
    }
    setIsBusy(false)
  }

  const handleOnSelectCreditAccount = creditDetails => {
    if (!creditDetails.isLocked) {
      setSelectedCreditAccount({ ...selectedCreditAccount, ...creditDetails })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCreditAccount(null)
    }
  }

  function clearValidation(column) {
    setInterAccountTransferValidationBag(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({
        ...selectedCustomer,
        ...customer,
      })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Customer is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  async function postTransfer() {
    setIsBusy(true)
    const { success } = await putData(
      '/InterAccountTransferBatch/create-inter-account-transfer-batch-with-meta',
      {
        interAccountTransferBatch: iTransfer,
        interAccountTransferBatchEntryDTOs: entries,
        dynamicChargeDTOs: selectedDynamicCharges,
      },
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      closeModal()
      loadTableData()
    }
    setIsBusy(false)
  }

  async function editTransfer() {
    setIsBusy(true)
    const { success } = await putData(
      '/InterAccountTransferBatch/update-inter-account-transfer-batch',
      { ...iTransfer, id: transfer.id },
      false,
      'Operation Completed Successfully'
    )
    const {} = await putData(
      '/InterAccountTransferBatch/update-dynamic-charges-by-inter-account-transfer-batch-id',
      {
        interAccountTransferBatchId: transfer.id,
        dynamicCharges: selectedDynamicCharges,
      },
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      closeModal()
      loadTableData()
    }
    setIsBusy(false)
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.customerId !== cust?.customerId
      )
      setEntries(newEntry)
    }
  }

  async function stageEntry() {
    setEntries([
      ...entries,
      {
        id: generateGuid(),
        status: 1,
        statusDescription: Object.values(batchEntryStatus)[0].name,

        apportionFrom: entry?.apportionFrom,
        apportionFromDescription: apportionFrom[entry?.apportionFrom].name,
        apportionTo: entry?.apportionTo,
        apportionToDescription: apportionTo[entry?.apportionTo].name,
        apportionedComponent: entry?.apportionedComponent,
        apportionedComponentDescription:
          apportionedComponent[entry?.apportionedComponent].name,

        apportionedAmount: entry?.apportionedAmount,
        primaryDescription: entry?.primaryDescription,
        secondaryDescription: entry?.secondaryDescription,
        reference: entry?.reference,

        contraCustomerAccountId: selectedDebitAccount?.id,
        contraCustomerAccountFullAccountNumber:
          selectedDebitAccount?.fullAccountNumber,
        contraCustomerAccountCustomerFullName:
          selectedDebitAccount?.customerFullName,
        paddedContraCustomerAccountCustomerAccountNumber:
          selectedDebitAccount?.paddedCustomerAccountNumber,
        formattedContraCustomerAccountCustomerMemberNumber:
          selectedDebitAccount?.paddedCustomerMemberNumber,
        contraCustomerAccountCustomerPersonalFileNumber:
          selectedDebitAccount?.customerPersonalFileNumber,

        contraChartOfAccountId: debitChartOfAccount?.contraChartOfAccountId,
        contraChartOfAccountName: debitChartOfAccount?.contraChartOfAccountName,
        contraChartOfAccountAccountType: debitChartOfAccount?.accountType,
        contraChartOfAccountCostDistributionRuleId:
          debitChartOfAccount?.contraChartOfAccountCostDistributionRuleId,
        contraChartOfAccountCostDistributionRuleDescription:
          debitChartOfAccount?.contraChartOfAccountCostDistributionRuleDescription,

        customerId: selectedCustomer?.id,
        customerAccountId: selectedCreditAccount?.id,
        customerAccountFullAccountNumber:
          selectedCreditAccount?.fullAccountNumber,
        customerAccountCustomerFullName:
          selectedCreditAccount?.customerFullName,
        paddedCustomerAccountCustomerAccountNumber:
          selectedCreditAccount?.paddedCustomerAccountNumber,
        formattedCustomerAccountCustomerMemberNumber:
          selectedCreditAccount?.paddedCustomerMemberNumber,
        customerAccountCustomerPersonalFileNumber:
          selectedCreditAccount?.customerPersonalFileNumber,

        chartOfAccountId: creditChartOfAccount?.chartOfAccountId,
        chartOfAccountName: creditChartOfAccount?.chartOfAccountName,
        chartOfAccountAccountType:
          creditChartOfAccount?.chartOfAccountAccountType,
        contraChartOfAccountAccountTypeDescription:
          creditChartOfAccount?.contraChartOfAccountAccountTypeDescription,
        chartOfAccountCostDistributionRuleId:
          creditChartOfAccount?.chartOfAccountCostDistributionRuleId,
        chartOfAccountCostDistributionRuleDescription:
          creditChartOfAccount?.chartOfAccountCostDistributionRuleDescription,
      },
    ])
  }
  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  async function clearFields() {
    setSelectedDebitAccount(null)
    setSelectedCreditAccount(null)
    setDebitChartOfAccount(null)
    setCreditChartOfAccount(null)
    setSelectedCustomer(null)
    setEntry({
      apportionFrom: states.debit,
      apportionTo: states.credit,
      apportionedComponent: 1,
      primaryDescription: '',
      secondaryDescription: '',
      reference: '',
      apportionedAmount: '',
    })
  }
  return (
    <ModalTemplate
      modalMode={edit ? 'edit' : 'add'}
      modalTitle="interAccountTransferBatches"
      handleClose={closeModal}
      modalClassName="modal-xl"
      actionText={edit ? 'update' : 'create'}
      handleAction={() => {
        if (selectedDynamicCharges.length == 0 && mode == 'add') {
          return Swal.fire({
            icon: 'info',
            text: 'Selected At One Indefinite Charge',
            title: 'Ooops!',
            showCloseButton: true,
          })
        } else {
          if (mode === 'add') {
            postTransfer()
          } else {
            editTransfer()
          }
        }
      }}
      footerChildren={
        <>
          {mode === 'edit' ? (
            <>
              <div className="col-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('apportioned')}
                </label>
                <input
                  type="text"
                  style={{ textAlign: 'right' }}
                  className="form-control ms-2"
                  disabled
                  value={
                    Number(apportionedAmount) < 0
                      ? formatCurrency(apportionedAmount, null, null, false)
                      : apportionedAmount || ''
                  }
                />
              </div>

              <div className="col-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('shortage')}
                </label>
                <input
                  type="text"
                  style={{ textAlign: 'right' }}
                  className="form-control ms-2"
                  disabled
                  value={
                    Number(shortageAmount) < 0
                      ? formatCurrency(shortageAmount, null, null, false)
                      : shortageAmount || ''
                  }
                />
              </div>
            </>
          ) : (
            ''
          )}
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('batchType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="InterAccountTransferBatchType"
                value={iTransfer?.typeDescription}
                onChange={e => {
                  //eslint-disable-next-line
                  if (e.value == 1) {
                    setStates({ credit: 1, debit: 1 })
                  }
                  setITransfer({
                    ...iTransfer,
                    type: e.value,
                  })
                }}
              />
              {/* <EnumsCombo
            enums={interAccountTransferBatchType}
            onChange={t => setITransfer({ ...iTransfer, type: t.value })}
            value={iTransfer?.type || 0}
          /> */}
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('priority')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="QueuePriority"
                value={iTransfer?.priority}
                onChange={e =>
                  setITransfer({
                    ...iTransfer,
                    priority: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div
              className="col-12 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['InterAccountTransferBatch.Remarks']
                  ?.message
              }
            >
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <TransactionReferenceLookup
                displayValue={iTransfer?.remarks || ''}
                type="text"
                className="form-control ms-1"
                onSelect={v => {
                  clearValidation('InterAccountTransferBatch.Remarks')
                  setITransfer({
                    ...iTransfer,
                    remarks: v.description,
                  })
                }}
              />
            </div>
          </div>

          <Tab
            preload
            tabItems={[
              {
                label: localize('apportionment'),
                item: (
                  <>
                    <div
                      className="p-2 mt-2"
                      style={{
                        border: 'solid 1px #0000001f',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                    >
                      <div
                        className="bg-white px-2 row text-capitalize"
                        style={{
                          position: 'relative',
                          top: -20,
                          left: 30,
                          maxWidth: 'fit-content',
                        }}
                      >
                        {localize('debit')}
                      </div>
                      {
                        //eslint-disable-next-line
                        iTransfer?.type == 0 && (
                          <div className="col-12 d-flex align-items-center mb-2">
                            <label className="col-1 text-capitalize">
                              {localize('apportionedFrom')}
                            </label>
                            <EnumsServerCombo
                              customContainerStyle={{ width: '100%' }}
                              enumsKey="ApportionFrom"
                              value={states.debit}
                              onChange={e => {
                                setEntry({
                                  ...entry,
                                  apportionFrom: e.value,
                                })
                                setStates({ ...states, debit: e.value })
                              }}
                            />
                          </div>
                        )
                      }

                      {
                        // eslint-disable-next-line
                        states.debit == 1 ? (
                          <CustomerAccountDebitCreditPartial
                            selectedDebitAccount={selectedDebitAccount}
                            onCustomerDebitAccountLookup={r => {
                              handleOnSelectDebitAccount(r)
                            }}
                            lookupType="debit"
                          />
                        ) : (
                          <>
                            <div className="row mb-2">
                              <div className="col d-flex align-items-center ms-2">
                                <label
                                  htmlFor=""
                                  className="col-4 text-capitalize"
                                >
                                  {localize('chartOfAccountName')}
                                </label>
                                <ChartOfAccountsLookup
                                  displayValue={
                                    debitChartOfAccount?.contraChartOfAccountName
                                  }
                                  onSelect={r => {
                                    setDebitChartOfAccount({
                                      ...debitChartOfAccount,
                                      contraChartOfAccountId: r.id,
                                      contraChartOfAccountName: r.name,
                                      contraChartOfAccountAccountType: r.type,
                                      contraChartOfAccountAccountTypeDescription:
                                        r.typeDescription,
                                      contraChartOfAccountCostDistributionRuleId:
                                        r.costDistributionRuleId,
                                      contraChartOfAccountCostDistributionRuleDescription:
                                        r.costDistributionRuleDescription,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col d-flex align-items-center">
                                <label className="col-4 text-capitalize">
                                  {localize('accountType')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={
                                    debitChartOfAccount?.contraChartOfAccountAccountTypeDescription
                                  }
                                />
                              </div>
                              <div className="col-6 d-flex align-items-center">
                                <label className="col-2 text-capitalize">
                                  {localize('costDistributionRule')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control ms-2"
                                  disabled
                                  value={
                                    debitChartOfAccount?.contraChartOfAccountCostDistributionRuleDescription
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )
                      }
                    </div>

                    <div
                      className="p-2 mt-4 mb-4"
                      style={{
                        border: 'solid 1px #0000001f',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                    >
                      <div
                        className="bg-white px-2 row text-capitalize"
                        style={{
                          position: 'relative',
                          top: -20,
                          left: 30,
                          maxWidth: 'fit-content',
                        }}
                      >
                        {localize('credit')}
                      </div>
                      {
                        // eslint-disable-next-line
                        iTransfer?.type == 0 && (
                          <div className="col-12 d-flex align-items-center mb-2">
                            <label className="col-1 text-capitalize">
                              {localize('apportionedTo')}
                            </label>
                            <EnumsServerCombo
                              customContainerStyle={{ width: '100%' }}
                              enumsKey="ApportionTo"
                              value={states.credit}
                              onChange={e => {
                                setEntry({
                                  ...entry,
                                  apportionTo: e.value,
                                })
                                setStates({ ...states, credit: e.value })
                              }}
                            />
                          </div>
                        )
                      }
                      {
                        // eslint-disable-next-line
                        states.credit == 1 ? (
                          <>
                            <CustomerPartial
                              customer={selectedCustomer}
                              onCustomerLookup={r => {
                                handleOnSelectCustomer(r)
                              }}
                            />
                            {selectedCustomer?.id ? (
                              <CustomerAccountDebitCreditPartial
                                selectedCreditAccount={selectedCreditAccount}
                                onCustomerCreditAccountLookup={r => {
                                  handleOnSelectCreditAccount(r)
                                }}
                                lookupType="credit"
                                selectedCustomer={selectedCustomer}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            <div className="row mb-2">
                              <div className="col d-flex align-items-center ms-2">
                                <label
                                  htmlFor=""
                                  className="col-4 text-capitalize"
                                >
                                  {localize('chartOfAccountName')}
                                </label>
                                <ChartOfAccountsLookup
                                  displayValue={
                                    creditChartOfAccount?.chartOfAccountName
                                  }
                                  onSelect={r => {
                                    setCreditChartOfAccount({
                                      ...creditChartOfAccount,
                                      chartOfAccountId: r.id,
                                      chartOfAccountName: r.name,
                                      chartOfAccountAccountType: r.type,
                                      chartOfAccountAccountTypeDescription:
                                        r.typeDescription,
                                      chartOfAccountCostDistributionRuleId:
                                        r.costDistributionRuleId,
                                      chartOfAccountCostDistributionRuleDescription:
                                        r.costDistributionRuleDescription,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col d-flex align-items-center">
                                <label className="col-4 text-capitalize">
                                  {localize('accountType')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={
                                    creditChartOfAccount?.chartOfAccountAccountTypeDescription
                                  }
                                />
                              </div>
                              <div className="col-6 d-flex align-items-center">
                                <label className="col-2 text-capitalize">
                                  {localize('costDistributionRule')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control ms-2"
                                  disabled
                                  value={
                                    creditChartOfAccount?.chartOfAccountCostDistributionRuleDescription
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="row mt-2">
                      <div
                        validation-message={
                          interAccountTransferValidationBag?.primaryDescription
                        }
                        className="col d-flex align-items-center ms-2 with-validation"
                      >
                        <label className="col-4 text-capitalize">
                          {localize('primaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-2"
                          value={entry?.primaryDescription}
                          onChange={e => {
                            clearValidation('primaryDescription')
                            setEntry({
                              ...entry,
                              primaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div
                        validation-message={
                          interAccountTransferValidationBag?.secondaryDescription
                        }
                        className="col d-flex align-items-center ms-2 with-validation"
                      >
                        <label className="col-4 text-capitalize">
                          {localize('secondaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-2"
                          value={entry?.secondaryDescription}
                          onChange={e => {
                            clearValidation('secondaryDescription')
                            setEntry({
                              ...entry,
                              secondaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>

                      <div
                        validation-message={
                          interAccountTransferValidationBag?.reference
                        }
                        className="col d-flex align-items-center with-validation"
                      >
                        <label className="col-4 text-capitalize">
                          {localize('reference')}
                        </label>
                        <input
                          type="text"
                          value={entry?.reference}
                          className="form-control ms-2"
                          onChange={e => {
                            clearValidation('reference')
                            setEntry({
                              ...entry,
                              reference: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div
                        validation-message={
                          interAccountTransferValidationBag?.apportionedAmount
                        }
                        className="col d-flex align-items-center with-validation"
                      >
                        <label className="col-4 text-capitalize">
                          {localize('amount')}
                        </label>
                        <MaskNumber
                          className="form-control ms-2"
                          defaultMaskValue={entry?.apportionedAmount}
                          onMaskNumber={e => {
                            clearValidation('apportionedAmount')
                            setEntry({
                              ...entry,
                              apportionedAmount: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div className="col d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('component')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="ApportionedComponent"
                          value={entry?.apportionedComponentDescription}
                          onChange={e => {
                            //eslint-disable-next-line
                            setEntry({
                              ...entry,
                              apportionedComponent: e.value,
                            })
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
                      <button
                        //onClick={edit ? postEntry : stageEntry}
                        onClick={handleOnAddInterAccountTransferModel}
                        className="btn btn-success px-5"
                      >
                        {localize('add')}
                      </button>
                    </div>
                    <div className="mt-3">
                      <Tab
                        tabItems={[
                          {
                            label: localize('batchEntries'),
                            item: (
                              <IAEntries
                                edit={edit}
                                unpostedEntries={entries}
                                handleRemove={handleRemove}
                                mode={mode}
                                entries={existingEntries}
                                setApportioned={setApportioned}
                                setShortage={setShortage}
                                onSelectItem={onSelectItem}
                                loadEntries={loadEntries}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </>
                ),
              },
              {
                label: localize('indefiniteCharges'),
                item: (
                  <DynamicCharges
                    prefix={'create-inter-acc-transfer-dynamic-charges'}
                    dynamicChargesToBeSelected={dynamicChargesToBeSelected}
                    onCheckDynamicCharge={r => {
                      setDynamicChargesToBeSelected(r)
                      setSelectedDynamicCharges(r.filter(x => x.checked))
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </ModalTemplate>
  )
}

export default AddInterACTransfer
