/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import FIleSelectLookup from '../../../../Components/Lookup/FIleSelectLookup'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import Tab from '../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import toastr from 'toastr'
import Swal from 'sweetalert2'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import TrainingFacilitatorLookup from '../../../../Components/Lookup/TrainingFacilitatorLookup'
import TrainingTypeLookup from '../../../../Components/Lookup/TrainingTypeLookup'
import { stripNonNumericCharacters } from '../../../../Helpers/formatters'
import { toBase64 } from '../../../../Helpers/blobs'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'

function CreateTrainingPeriod({ mode, period, refreshPeriods, closeModal }) {
  const [tempEmployee, setTempEmployee] = useState(null)
  const [targetEmployees, setTargetEmployees] = useState([])
  const [tEmployees, setTEmployees] = useState(null)
  const [tParams, setTParams] = useState({
    pageSize: 10,
    pageIndex: 0,
    filterText: '',
  })
  const [selectedTargetEmployees, setSelectedTargetEmployees] = useState([])
  const [trainingPeriod, setTrainingPeriod] = useState(period)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyT, setIsBusyT] = useState(false)
  const [refreshTargets, setRefreshTargets] = useState(false)

  useEffect(() => {
    if (mode === 'edit') {
      loadTrainingPeriod()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (mode === 'edit') {
      loadTargetEmployees()
    }
    // eslint-disable-next-line
  }, [tParams?.pageSize, tParams?.pageIndex, refreshTargets, period?.id])

  const targetEmployeeColumns = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={!!selectedTargetEmployees.find(x => x?.id === r?.id)}
            onCheck={() => {
              const cIndex = selectedTargetEmployees.findIndex(
                x => x?.id === r?.id
              )
              if (cIndex === -1) {
                setSelectedTargetEmployees([...selectedTargetEmployees, r])
              } else {
                let tClone = [...selectedTargetEmployees]
                tClone.splice(cIndex, 1)
                setSelectedTargetEmployees(tClone)
              }
            }}
          />
        </>
      ),
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedCustomerSerialNumber}</>,
    },
    { label: localize('name'), template: r => <>{r?.customerFullName}</> },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.customerAddressCity}</> },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
  ]
  const targetEmployeeColumns2 = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={!!selectedTargetEmployees.find(x => x?.id === r?.id)}
            onCheck={() => {
              const cIndex = selectedTargetEmployees.findIndex(
                x => x?.id === r?.id
              )
              if (cIndex === -1) {
                setSelectedTargetEmployees([...selectedTargetEmployees, r])
              } else {
                let tClone = [...selectedTargetEmployees]
                tClone.splice(cIndex, 1)
                setSelectedTargetEmployees(tClone)
              }
            }}
          />
        </>
      ),
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.employeeCustomerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.employeePaddedCustomerSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.employeeCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.employeeCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.employeeCustomerReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r?.employeeCustomerAddressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r?.employeeCustomerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.employeeCustomerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.employeeCustomerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.employeeCustomerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.employeeCustomerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.employeeCustomerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.employeeCustomerAddressMobileLine}</>,
    },
  ]

  function addSelectedEmployee() {
    if (!targetEmployees.find(t => t?.id === tempEmployee?.id)) {
      setTargetEmployees([...targetEmployees, tempEmployee])
      setTempEmployee(null)
    } else {
      toastr.error(`${tempEmployee?.customerFullName} is already added!`)
      setTempEmployee(null)
    }
  }

  async function removeSelectedTargetEmployees() {
    const { isConfirmed } = await Swal.fire({
      title: 'Remove Target Employees?',
      text: `You are about to remove ${selectedTargetEmployees.length} employees`,
      showDenyButton: true,
      confirmButtonText: 'Yes, Remove',
      denyButtonText: `No`,
    })
    if (isConfirmed) {
      let tClone = [...targetEmployees]
      setTargetEmployees(
        tClone.filter(x => !selectedTargetEmployees.find(s => s.id === x.id))
      )
      setSelectedTargetEmployees([])
    }
  }

  async function createPeriod() {
    setIsBusy(true)
    const { success } = await postData(
      '/trainingPeriod/create',
      {
        trainingPeriod,
        targetEmployees: targetEmployees.map(t => {
          let tt = { ...t }
          delete tt.id
          tt.employeeId = t?.id
          tt.employeeCustomerId = t?.customerId
          return tt
        }),
      },
      false
    )
    if (success) {
      Swal.fire('Training Periods', 'Training Period Created!', 'success')
      setTrainingPeriod(null)
      setTargetEmployees([])
    }
    setIsBusy(false)
    refreshPeriods()
  }

  async function updatePeriod() {
    setIsBusy(true)
    const { success } = await putData(
      '/trainingPeriod/update-training-period',
      trainingPeriod,
      false,
      null,
      false
    )
    if (success) {
      closeModal()
      refreshPeriods()
      Swal.fire('Operation Successful', 'Training Period Updated!', 'success')
    }
    setIsBusy(false)
  }

  async function loadTrainingPeriod() {
    setIsBusy(true)
    setTParams({ ...tParams, trainingPeriodId: period?.id })
    const { success, data } = await getData(
      '/trainingPeriod/find-by-id',
      {
        trainingPeriodId: period?.id,
      },
      false,
      null,
      false
    )
    if (success) {
      setTrainingPeriod(data)
      setRefreshTargets()
    } else {
      toastr.info(
        'Failed to get selected latest training period info. Using the cached info'
      )
    }
    setIsBusy(false)
  }

  async function loadTargetEmployees() {
    setIsBusyT(true)
    const { success, data } = await getData(
      '/trainingPeriod/find-training-period-entries-by-period-id-filter-in-page',
      tParams,
      false,
      null,
      false
    )
    if (success) {
      setTEmployees(data)
    } else {
      toastr.info(
        'Failed to get selected latest training period info. Using the cached info'
      )
    }
    setIsBusyT(false)
  }

  const editTabItems = [
    {
      label: localize('targetEmployees'),
      item: (
        <>
          {isBusyT ? (
            <Loader />
          ) : (
            tEmployees && (
              <>
                <div className="d-flex align-items-center justify-content-between ps-3 pe-1 py-2 gap-2">
                  <RecordsPerPage
                    reqParams={tParams}
                    setReqParams={r =>
                      setTParams({ ...tParams, pageSize: r?.pageSize })
                    }
                  />
                  <div className="d-flex align-items-center gap-2">
                    <label htmlFor="" className="text-capitalize">
                      {localize('search')}
                    </label>
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        setRefreshTargets(!refreshTargets)
                      }}
                    >
                      <input
                        type="search"
                        value={tParams?.filterText}
                        onChange={e =>
                          setTParams({ ...tParams, filterText: e.target.value })
                        }
                        className="form-control"
                      />
                    </form>
                    <button
                      className="btn btn-primary"
                      onClick={() => setRefreshTargets(!refreshTargets)}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <SimpleTable
                  columns={targetEmployeeColumns2}
                  data={tEmployees?.pageCollection}
                />
                <div className="mt-2">
                  <Pager
                    itemsCount={tEmployees?.itemsCount}
                    pageSize={tParams?.pageSize}
                    pageIndex={tParams?.pageIndex}
                    onPageChange={p => setTParams({ ...tParams, pageIndex: p })}
                  />
                </div>
              </>
            )
          )}
        </>
      ),
    },
    { label: localize('markAttendance') },
  ]

  return isBusy ? (
    <Loader message="Please Wait" />
  ) : (
    <div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('name')}
          </label>
          <input
            type="text"
            className="form-control"
            value={trainingPeriod?.description}
            onChange={e =>
              setTrainingPeriod({
                ...trainingPeriod,
                description: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('trainingFacilitator')}
          </label>
          <TrainingFacilitatorLookup
            displayValue={trainingPeriod?.trainingFacilitatorDescription}
            onSelect={t =>
              setTrainingPeriod({
                ...trainingPeriod,
                trainingFacilitatorDescription: t?.description,
                trainingFacilitatorId: t?.id,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('trainingType')}
          </label>
          <TrainingTypeLookup
            onSelect={t =>
              setTrainingPeriod({
                ...trainingPeriod,
                trainingTypeId: t?.id,
                trainingTypeDescription: t?.description,
              })
            }
            displayValue={trainingPeriod?.trainingTypeDescription}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('venue')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e =>
              setTrainingPeriod({ ...trainingPeriod, venue: e.target.value })
            }
            value={trainingPeriod?.venue}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('status')}
          </label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey="TrainingPeriodStatus"
              value={trainingPeriod?.status}
              onChange={s =>
                setTrainingPeriod({ ...trainingPeriod, status: s.value })
              }
            />
          </div>
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalValue')}
          </label>
          <MaskNumber
            defaultMaskValue={trainingPeriod?.totalValue}
            onMaskNumber={v =>
              setTrainingPeriod({
                ...trainingPeriod,
                totalValue: stripNonNumericCharacters(v.target.value),
              })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('startDate')}
          </label>
          <CustomDatePicker
            defaultDate={trainingPeriod?.durationStartDate}
            onDateChange={d =>
              setTrainingPeriod({ ...trainingPeriod, durationStartDate: d })
            }
            name="trainingPeriodStartDatePicker"
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('endDate')}
          </label>
          <CustomDatePicker
            defaultDate={trainingPeriod?.durationEndDate}
            onDateChange={d =>
              setTrainingPeriod({ ...trainingPeriod, durationEndDate: d })
            }
            name="trainingPeriodEndDatePicker"
          />
        </div>
        <div className="col d-flex align-items-center gap-2"></div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('document')}
          </label>
          <FIleSelectLookup
            accept="application/pdf"
            displayValue={trainingPeriod?.fileName || ''}
            onSelect={async e => {
              let file = e.target.files[0]
              setTrainingPeriod({
                ...trainingPeriod,
                document: await toBase64(file),
                fileName: file?.name,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('document')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={trainingPeriod?.documentNumber}
            onChange={e =>
              setTrainingPeriod({
                ...trainingPeriod,
                documentNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('title')}
          </label>
          <input
            type="text"
            value={trainingPeriod?.fileTitle}
            onChange={e =>
              setTrainingPeriod({
                ...trainingPeriod,
                fileTitle: e.target.value,
              })
            }
            className="form-control"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('remarks')}
          </label>
          <textarea
            value={trainingPeriod?.remarks}
            onChange={e =>
              setTrainingPeriod({ ...trainingPeriod, remarks: e.target.value })
            }
            className="form-control"
            name=""
            id=""
            rows="5"
          ></textarea>
        </div>
        <div className="col d-flex gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('fileDescription')}
          </label>
          <textarea
            className="form-control"
            name=""
            id=""
            rows="5"
            value={trainingPeriod?.fileDescription}
            onChange={e =>
              setTrainingPeriod({
                ...trainingPeriod,
                fileDescription: e.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="col"></div>
      </div>

      <div
        className="border border-secondary rounded mt-3 p-2"
        style={{ position: 'relative' }}
      >
        <div
          className="px-2 bg-white text-capitalize"
          style={{
            position: 'absolute',
            top: 0,
            transform: 'translate(0, -60%)',
          }}
        >
          {localize('employeeDetails')}
        </div>
        <div className="row my-1 mb-2 gap-2">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize ">
              {localize('employee')}
            </label>
            <EmployeeLookup
              onSelect={e => setTempEmployee(e)}
              displayValue={tempEmployee?.customerFullName}
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize ">
              {localize('department')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={tempEmployee?.departmentDescription || ''}
            />
          </div>
        </div>
        {tempEmployee && (
          <div className="d-flex align-items-center justify-content-end p-1 bg-light rounded my-2 border border-info">
            <button
              className="btn btn-info btn-sm px-5"
              onClick={addSelectedEmployee}
            >
              {localize('add')}
            </button>
          </div>
        )}
        <Tab
          preload
          tabItems={
            mode === 'add'
              ? [
                  {
                    label: localize('targetEmployees'),
                    item: (
                      <>
                        <SimpleTable
                          columns={targetEmployeeColumns}
                          data={targetEmployees || []}
                        />
                        {selectedTargetEmployees.length > 0 && (
                          <div className="d-flex align-items-center justify-content-end p-1 bg-light rounded my-2 border border-danger">
                            <button
                              className="btn btn-danger btn-sm px-5"
                              onClick={removeSelectedTargetEmployees}
                            >
                              {localize('remove')} (
                              {selectedTargetEmployees.length})
                            </button>
                          </div>
                        )}
                      </>
                    ),
                  },
                ]
              : editTabItems
          }
        />
      </div>
      <hr />
      <div className="rounded d-flex gap-3 align-item-center justify-content-end">
        <button onClick={closeModal} className="btn btn-danger px-5">
          {localize('cancel')}
        </button>
        {(mode === 'add' || mode === 'edit') && (
          <button
            className="btn btn-success px-5"
            onClick={mode === 'add' ? createPeriod : updatePeriod}
          >
            {localize(mode === 'add' ? 'create' : 'update')}
          </button>
        )}
      </div>
    </div>
  )
}

export default CreateTrainingPeriod
