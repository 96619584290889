/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../../Global/Localize'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import { useEffect } from 'react'
import { formatDate } from '../../../../../../Helpers/formatters'

const Responses = ({ surveyId }) => {
  const [responses, setResponses] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerSurveyEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('customer'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('MSISDN'),
      template: r => r?.msisdn,
    },
    {
      label: localize('surveyEntry'),
      template: r => r?.surveyEntryDescription,
    },
    {
      label: localize('response'),
      template: r => r?.response,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
  ]

  const fetchResponses = async () => {
    setIsBusy(true)

    const url =
      '/Survey/find-customer-survey-entris-by-survey-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        surveyId,
      },
      false
    )

    if (success) {
      setResponses(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (surveyId) fetchResponses()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex, surveyId])

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <label
            style={{
              minWidth: 'fit-content',
              maxWidth: 'fit-content',
            }}
            className="text-capitalize"
          >
            {localize('recordsPerPage')}
          </label>
          <select
            className="form-select ms-2"
            style={{ maxWidth: 'fit-content' }}
            value={reqParams.pageSize}
            onChange={e =>
              setReqParams({ ...reqParams, pageSize: e.target.value })
            }
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div
          className="col d-flex align-items-center gap-2"
          style={{ maxWidth: 'fit-content' }}
        >
          <label
            style={{ minWidth: 'fit-content' }}
            className="text-capitalize me-2"
          >
            {localize('search')}
          </label>
          <EnumsServerCombo
            sort
            customContainerStyle={{
              maxWidth: 150,
              minWidth: 150,
            }}
            value={reqParams?.customerSurveyEntryFilter}
            enumsKey="CustomerSurveyEntryFilter"
            onChange={({ value }) =>
              setReqParams({ ...reqParams, customerSurveyEntryFilter: value })
            }
          />
          <input
            type="text"
            className="form-control"
            value={reqParams?.text}
            onChange={e =>
              setReqParams({
                ...reqParams,
                text: e.target.value,
                pageIndex: 0,
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                fetchResponses()
              }
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              fetchResponses()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </div>
      </div>

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={responses?.pageCollection} />
          <Pager
            itemsCount={responses?.itemsCount}
            {...reqParams}
            pageBatchSize={5}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default Responses
