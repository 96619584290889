/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import localize from '../../../../Global/Localize'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import LoanPurposesModal from './LoanPurposesModal'
import { LoanPurposeCategory } from '../../../../Helpers/constants'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function LoanPurposes() {
  const columns = [
    {
      label: localize('name'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
      sortBy: 'name',
    },
    {
      label: localize('category'),
      template: r => <>{r.categoryDescription}</>,
      sortBy: 'category',
    },
    {
      label: localize('depth'),
      template: r => <>{r.depth}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')

  const [sortedBy, setSortedBy] = useState(null)
  const [selectedLoanPurpose, setSelectedLoanPurpose] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/LoanPurpose/find-loan-purpose-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleOnEdit = r => {
    setSelectedLoanPurpose({ ...r })
    setMode('edit')
  }

  const handleOnAdd = async r => {
    setSelectedLoanPurpose({
      category: LoanPurposeCategory.HEADER_ENTRY,
    })
    setMode('add')
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }
  return (
    <>
      <BreadCrumb
        tree={[localize('creditManagement'), localize('loanPurposes')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body" style={{ position: 'relative' }}>
              <div className="row mb-2">
                <div className="col-md-4 gap-2">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleOnAdd} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex align-items-center col-3">
                  <RecordsPerPage
                    setReqParams={setReqParams}
                    reqParams={reqParams}
                  />
                </div>
                <div className="col-3 offset-3">
                  <div className="d-flex align-items-center me-5">
                    <Checkbox
                      id={'req-params--loan-traverse-tree'}
                      checked={reqParams.traverseTree}
                      onCheck={v => {
                        setReqParams({ ...reqParams, traverseTree: v })
                      }}
                    />
                    <label
                      htmlFor="req-params--loan-traverse-tree"
                      className="text-capitalize ms-2"
                    >
                      {localize('traverseTree')} ?
                    </label>
                  </div>
                </div>
                <div className="col-3">
                  <form
                    className="col d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                        pageIndex: 0,
                      })
                    }}
                  >
                    <label className={'text-capitalize'} htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.text}
                      onChange={e => setSearch(e.target.value)}
                    ></input>
                    <button type="submit" className="btn btn-primary ms-1">
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="row col-12 mt-2">
                <SimpleTable
                  contextMenu={e => (
                    <>
                      <div
                        onClick={() => {
                          handleOnEdit(e)
                        }}
                        className="ctxt-menu-item"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(e)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                  data={tableData}
                  columns={columns}
                  onSort={(c, i) => handleSort(c, i)}
                  sortedBy={sortedBy}
                />
              </div>
              <div className="row col-12 mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
              {isBusy && <NonBlockingLoader />}
            </div>
          </div>
        </div>
      </div>
      <LoanPurposesModal
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedLoanPurpose={setSelectedLoanPurpose}
        selectedLoanPurpose={selectedLoanPurpose}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default LoanPurposes
