/** @format */

/* eslint-disable */
import React from 'react'

const Submission = () => {
  return <div>Submission</div>
}

export default Submission
