/** @format */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CreateAlternateChannelActivationRequestModal } from './CreateAlternateChannelActivationRequestModal'
import localize from '../../../../../Global/Localize'
import { useState } from 'react'
import { alternateChannelType } from '../../../../../Helpers/constants'
import { postData, putData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { RejectAlternateChannelActivationRequestModal } from './RejectAlternateChannelActivationRequestModal'

function ChannelActivationRequestModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedAlternateChannelActivationRequest,
  setSelectedAlternateChannelActivationRequest,
  onSetMode,
  selectedCustomer,
  setSelectedCustomer,
}) {
  const [selectedAlternateChannelType, setSelectedAlternateChannelType] =
    useState(alternateChannelType.PESAPEPE)
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return handleOnCreateActivationRequest()
    }
    if (mode === 'reject') {
      return handleOnReject()
    }
  }

  const handleOnCreateActivationRequest = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/AlternateChannel/create-alternate-channel-activation-request',
      {
        CustomerId: selectedCustomer?.id,
        CustomerMembershipClassId: selectedCustomer?.membershipClassId,
        CustomerMembershipClassCustomerType:
          selectedCustomer?.membershipClassCustomerType,
        CustomerIndividualIdentityCardNumber:
          selectedCustomer?.individualIdentityCardNumber,
        CustomerNonIndividualRegistrationNumber:
          selectedCustomer?.nonIndividualRegistrationNumber,
        BypassIprsKYCValidation:
          selectedCustomer?.membershipClassBypassIprsKYCValidation,
        BypassMpesaKYCValidation:
          selectedCustomer?.membershipClassBypassMpesaKYCValidation,
        AlternateChannelType: selectedAlternateChannelType,
      },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onSetMode(null)
      swal.fire(
        'Alternate Channel Activation Request',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const handleOnReject = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/AlternateChannel/reject-alternate-channel-activation-request',
      {
        ...selectedAlternateChannelActivationRequest,
      },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onSetMode(null)
      swal.fire(
        'Alternate Channel Activation Request',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }
  return (
    <>
      <>
        {(mode === 'add' || mode === 'edit') && (
          <ModalTemplate
            disableCreate={isBusy}
            modalMode={mode}
            footerChildren={
              mode === 'reject' && (
                <div className="d-flex align-content-end justify-content-end">
                  <div className="d-flex align-items-center me-5">
                    <label className="col-3 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      className="form-control"
                      value={
                        selectedAlternateChannelActivationRequest?.rejectionRemarks ||
                        ''
                      }
                      onChange={e => {
                        setSelectedAlternateChannelActivationRequest({
                          ...selectedAlternateChannelActivationRequest,
                          rejectionRemarks: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              )
            }
            modalTitle={localize('alternateChannelActivationRequest')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateAlternateChannelActivationRequestModal
                isBusy={isBusy}
                selectedAlternateChannelType={selectedAlternateChannelType}
                setSelectedAlternateChannelType={
                  setSelectedAlternateChannelType
                }
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />
            )}
            {mode === 'reject' && (
              <RejectAlternateChannelActivationRequestModal
                selectedActivationRequest={
                  selectedAlternateChannelActivationRequest
                }
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default ChannelActivationRequestModal
