/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import MembershipWithdrawalNotificationsLookup from '../../../../../../Components/Lookup/MembershipWithdrawalNotificationsLookup'
import MembershipWithdrawalSettlementBatchLookup from '../../../../../../Components/Lookup/MembershipWithdrawalSettlementBatchLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import BatchEntriesTab from '../../../../../../Components/ReUsables/BatchEntries/WithdrawalSettlementBatchEntriesTab/BatchEntriesTab'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

function CreateBatchOriginationModal({
  handleCreateBatchOrigination,
  handleCancel,
  mode,
  setBatchOrigination,
  batchOrigination,
  isBusyUpdating,
}) {
  const [refresh, setRefresh] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <ModalTemplate
        handleAction={handleCreateBatchOrigination}
        handleClose={handleCancel}
        modalMode={mode}
        modalClassName={mode === 'edit' ? 'modal-xl' : 'modal-lg'}
        modalTitle="batchOrigination"
        disableClose={isBusyUpdating}
        disableCreate={isBusyUpdating}
      >
        {isBusyUpdating ? (
          <Loader />
        ) : (
          <>
            <div className="row py-2 g-3">
              {mode === 'edit' && (
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3" htmlFor="branch">
                      {localize('batch#')}
                    </label>
                    <div className="ms-2 w-100">
                      <MembershipWithdrawalSettlementBatchLookup
                        onSelect={r => {
                          clearValidation('batchNumber')
                          setBatchOrigination({
                            ...batchOrigination,
                            batchNumber: r.batchNumber,
                            batchId: r.id,
                          })
                        }}
                        displayValue={batchOrigination?.batchNumber}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['BranchDescription']?.message
                  }
                >
                  <label className="text-capitalize col-3" htmlFor="branch">
                    {localize('branch')}
                  </label>
                  {mode === 'edit' ? (
                    <>
                      <input
                        type="text"
                        className="form-control"
                        id="branch"
                        value={batchOrigination?.branchDescription}
                        disabled
                      />
                    </>
                  ) : (
                    <BranchLookup
                      onSelect={r => {
                        clearValidation('BranchDescription')
                        setBatchOrigination({
                          ...batchOrigination,
                          id: r.id,
                          branchDescription: r.description,
                          branchCode: r.code,
                          branchId: r.id,
                          branchCompanyDescription: r.branchCompanyDescription,
                        })
                      }}
                      displayValue={
                        batchOrigination?.description ||
                        batchOrigination?.branchDescription
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {mode === 'edit' ? (
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3" htmlFor="category">
                      {localize('category')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      id="category"
                      value={batchOrigination?.categoryDescription}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-3" htmlFor="category">
                      {localize('category')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="WithdrawalNotificationCategory"
                      value={batchOrigination?.category}
                      onChange={e => {
                        setBatchOrigination({
                          ...batchOrigination,
                          category: e.value,
                        })
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-3" htmlFor="priority">
                    {localize('priority')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="QueuePriority"
                    value={batchOrigination?.priority}
                    onChange={e => {
                      setBatchOrigination({
                        ...batchOrigination,
                        priority: e.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['Reference']?.message
                  }
                >
                  <label className="text-capitalize col-3" htmlFor="reference">
                    {localize('reference')}
                  </label>
                  <input
                    className={`form-control ${mode === 'edit' && 'ms-2'}`}
                    id="reference"
                    value={batchOrigination?.reference}
                    onChange={e => {
                      clearValidation('Reference')
                      setBatchOrigination({
                        ...batchOrigination,
                        reference: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            {mode === 'edit' && (
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('batchDetails')}
                </div>
                <div className="row py-2 g-3">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <label className="text-capitalize" htmlFor="target">
                        {localize(
                          'lookupTargetMembershipWithdrawalNotification(s)'
                        )}
                      </label>
                      <MembershipWithdrawalNotificationsLookup
                        branch={
                          batchOrigination?.branchDescription ||
                          batchOrigination?.description
                        }
                        branchId={batchOrigination?.branchId}
                        withdrawalSettlementBatchId={
                          batchOrigination?.batchId || batchOrigination?.id
                        }
                        setRefresh={setRefresh}
                        category={batchOrigination?.category}
                        categoryDescription={
                          batchOrigination?.categoryDescription
                        }
                        onSelect={r => {
                          setBatchOrigination({
                            ...batchOrigination,
                            targetMembershipWithdrawalNotificationIds: r.map(
                              m => m.id
                            ),
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-2 g-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('batchEntries'),
                        item: (
                          <div
                            style={{
                              maxHeight: '50dvh',
                              overflowY: 'auto',
                              overflowX: 'hidden',
                            }}
                          >
                            <BatchEntriesTab
                              showRemoveMenu
                              withdrawalSettlementBatchId={batchOrigination?.id}
                              refresh={refresh}
                              setRefresh={setRefresh}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateBatchOriginationModal
