/** @format */
import { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { CreateCompanyModal } from './CreatePartials/CreateCompanyModal'
import { postData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewCompanyModal } from './ViewPartials/ViewCompanyModal'
import { EditCompaniesModal } from './EditPartials/EditCompaniesModal'

export const CompaniesModal = ({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedCompany,
  setSelectedCompany,
  setCompanyWorkingTimes,
  companyWorkingTimes,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      addCompany()
    }
    if (mode === 'edit') {
      editCompany()
    }
  }

  const flattenCompanyFlags = companyFlags => {
    const flagsByValueAndCheckStatus = companyFlags?.map((flag, idx) => {
      return {
        [flag.value]: flag.checked,
      }
    })

    return flagsByValueAndCheckStatus.reduce((result, currentObject) => {
      Object.entries(currentObject).forEach(([key, value]) => {
        result[key] = value
      })

      return result
    }, {})
  }

  const addCompany = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Company/create-company',
      {
        Company: {
          ...selectedCompany,
          companyFlags: flattenCompanyFlags([...selectedCompany?.companyFlags]),
        },
        CompanyWorkingTimes: companyWorkingTimes,
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire('Create Company', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  const editCompany = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Company/update-company',
      {
        Company: {
          ...selectedCompany,
          companyFlags: flattenCompanyFlags([...selectedCompany?.companyFlags]),
        },
        CompanyWorkingTimes: companyWorkingTimes,
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire('Edit Company', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {(mode === 'add' || (mode === 'edit') | (mode === 'view')) && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('company')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateCompanyModal
                isBusy={isBusy}
                mode={mode}
                companyWorkingTimes={companyWorkingTimes}
                setCompanyWorkingTimes={setCompanyWorkingTimes}
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany}
              />
            )}
            {mode === 'view' && (
              <ViewCompanyModal selectedCompany={selectedCompany} />
            )}
            {mode === 'edit' && (
              <EditCompaniesModal
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                mode={mode}
                companyWorkingTimes={companyWorkingTimes}
                setCompanyWorkingTimes={setCompanyWorkingTimes}
                isBusy={isBusy}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}
