/** @format */
import localize from '../../../../../../Global/Localize'
import CurrencyLookup from '../../../../../../Components/Lookup/CurrencyLookup'
import PdfFileLookup from '../../../../../../Components/Lookup/PdfFileLookup'
import React, { useEffect } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import { Address } from './Address'
import { CompanyMiscellaneous } from './CompanyMiscellaneous'
import { CompanyWorkingTime } from './CompanyWorkingTime'
import { CompanyFlags } from './CompanyFlags'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CreateCompanyModal = ({
  mode,
  setSelectedCompany,
  selectedCompany,
  companyWorkingTimes,
  isBusy,
  setCompanyWorkingTimes,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  useEffect(() => {
    setValidationBag({})
  }, [mode])

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['Company.Description']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedCompany?.description}
                onChange={e => {
                  clearValidation('Company.Description')
                  setSelectedCompany({
                    ...selectedCompany,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('currency')}
              </label>
              <CurrencyLookup
                displayValue={selectedCompany?.currencyDescription}
                onSelect={r => {
                  setSelectedCompany({
                    ...selectedCompany,
                    currencyId: r.id,
                    currencyDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              validation-message={
                validationBag?.['Company.RegistrationNumber']?.message
              }
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('registrationNumber')}
              </label>
              <input
                className="form-control"
                value={selectedCompany?.registrationNumber}
                onChange={e => {
                  setSelectedCompany({
                    ...selectedCompany,
                    registrationNumber: e.target.value,
                  })
                  clearValidation('Company.RegistrationNumber')
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['Company.PersonalIdentificationNumber']?.message
              }
            >
              <label className="col-3 text-capitalize">{localize('PIN')}</label>
              <input
                className="form-control"
                value={selectedCompany?.personalIdentificationNumber}
                onChange={e => {
                  setSelectedCompany({
                    ...selectedCompany,
                    personalIdentificationNumber: e.target.value,
                  })
                  clearValidation('Company.PersonalIdentificationNumber')
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('mission')}
              </label>
              <input
                className="form-control"
                value={selectedCompany?.mission}
                onChange={e =>
                  setSelectedCompany({
                    ...selectedCompany,
                    mission: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('vision')}
              </label>
              <input
                className="form-control"
                value={selectedCompany?.vision}
                onChange={e =>
                  setSelectedCompany({
                    ...selectedCompany,
                    vision: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('motto')}
              </label>
              <input
                className="form-control"
                value={selectedCompany?.motto}
                onChange={e =>
                  setSelectedCompany({
                    ...selectedCompany,
                    motto: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('logo')}
              </label>
              <PdfFileLookup
                displayValue={selectedCompany?.fileName}
                acceptedFiles={['image/jpeg']}
                onSelect={r => {
                  setSelectedCompany({
                    ...selectedCompany,
                    logo: r.bufferString,
                    fileName: r.fileName,
                    fileExtension: 'jpeg',
                  })
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tab
                preload
                tabItems={[
                  {
                    label: localize('address'),
                    item: (
                      <Address
                        setSelectedCompany={setSelectedCompany}
                        selectedCompany={selectedCompany}
                      />
                    ),
                  },
                  {
                    label: localize('miscellaneous'),
                    item: (
                      <CompanyMiscellaneous
                        setSelectedCompany={setSelectedCompany}
                        selectedCompany={selectedCompany}
                      />
                    ),
                  },
                  {
                    label: localize('workingTime'),
                    item: (
                      <CompanyWorkingTime
                        selectedCompany={selectedCompany}
                        companyWorkingTimes={companyWorkingTimes}
                        setCompanyWorkingTimes={setCompanyWorkingTimes}
                      />
                    ),
                  },
                  {
                    label: localize('flags'),
                    item: (
                      <CompanyFlags
                        setSelectedCompany={setSelectedCompany}
                        selectedCompany={selectedCompany}
                        mode={'create'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
