/** @format */

import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { getData, postData } from '../../../../../../Helpers/webApi'
import Pager from '../../../../../../Components/Tables/Pager'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CheckOffDataProcessingDataEntries = ({
  onSelectDataEntry,
  selectedDataAttachmentPeriod,
  refreshEntries,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [
    prevSelectedDataAttachmentPeriod,
    setPrevSelectedDataAttachmentPeriod,
  ] = useState(selectedDataAttachmentPeriod)

  const [reqParams, setReqParams] = useState({
    text: '',
    dataAttachmentPeriodId: selectedDataAttachmentPeriod?.id,
    pageIndex: 0,
    pageSize: 10,
  })

  const [toggleSelection, setToggleSelection] = useState(false)

  const [
    checkOffDataProcessingDataEntries,
    setCheckOffDataProcessingDataEntries,
  ] = useState([])

  const loadData = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DataAttachmentPeriod/find-data-attachment-entries-by-data-attachment-period-id-and-filter-in-page',
      { ...reqParams, dataAttachmentPeriodId: r.id },
      false
    )
    if (success) {
      setCheckOffDataProcessingDataEntries(data)
    }
    setIsBusy(false)
  }

  if (prevSelectedDataAttachmentPeriod !== selectedDataAttachmentPeriod) {
    setPrevSelectedDataAttachmentPeriod(selectedDataAttachmentPeriod)
    setReqParams({
      ...reqParams,
      dataAttachmentPeriodId: selectedDataAttachmentPeriod?.id,
    })
    loadData(selectedDataAttachmentPeriod)
  }

  useEffect(() => {
    if (selectedDataAttachmentPeriod) {
      loadData(selectedDataAttachmentPeriod)
    }
  }, [refreshEntries])

  const handleOnRemoveDataEntry = async () => {
    if (selectedCheckOffDataProcessingDataEntries.length === 0) {
      await Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select at least one entry to remove',
      })
      return
    }
    setIsBusy(true)
    const { success } = await postData(
      '/DataAttachmentPeriod/remove-data-attachment-entries',
      {
        DataAttachmentEntries: selectedCheckOffDataProcessingDataEntries,
      },
      false,
      'Data AttachmentPeriod'
    )
    if (success) {
      loadData(selectedDataAttachmentPeriod)
    }
    setIsBusy(false)
  }

  const [
    selectedCheckOffDataProcessingDataEntries,
    setSelectedCheckOffDataProcessingDataEntries,
  ] = useState([])

  const columns = [
    {
      label: localize('select?'),
      template: r => (
        <Checkbox
          checked={selectedCheckOffDataProcessingDataEntries.find(
            x => x.id === r.id
          )}
          onCheck={c => {
            if (c) {
              setSelectedCheckOffDataProcessingDataEntries([
                ...selectedCheckOffDataProcessingDataEntries,
                r,
              ])
              onSelectDataEntry([
                ...selectedCheckOffDataProcessingDataEntries,
                r,
              ])
            } else {
              setSelectedCheckOffDataProcessingDataEntries(
                selectedCheckOffDataProcessingDataEntries.filter(
                  s => s.id !== r.id
                )
              )
              onSelectDataEntry(
                selectedCheckOffDataProcessingDataEntries.filter(
                  s => s.id !== r.id
                )
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('entryType'),
      template: r => <>{r.entryTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    { label: localize('employerName'), template: r => <>{r.employerName}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('productName'), template: r => <>{r.productName}</> },
    {
      label: localize('transactionType'),
      template: r => <>{r.transactionTypeDescription}</>,
    },
    {
      label: localize('sequenceNumber'),
      template: r => <>{r.sequenceNumber}</>,
    },
    {
      label: localize('newAmount'),
      template: r => <>{formatCurrency(r.newAmount)}</>,
    },
    {
      label: localize('currentAmount'),
      template: r => <>{formatCurrency(r.currentAmount)}</>,
    },
    {
      label: localize('newBalance'),
      template: r => <>{formatCurrency(r.newBalance)}</>,
    },
    {
      label: localize('currentBalance'),
      template: r => <>{formatCurrency(r.currentBalance)}</>,
    },
    { label: localize('newAbility'), template: r => <>{r.newAbility}</> },
    {
      label: localize('currentAbility'),
      template: r => <>{r.currentAbility}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row">
              <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
                <div className="d-flex align-items-center me-5">
                  <label className="text-capitalize me-3">
                    {localize('search')}
                  </label>
                  <input
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e => {
                      setReqParams({ ...reqParams, text: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
            <SimpleTable
              columns={columns}
              data={checkOffDataProcessingDataEntries?.pageCollection}
            />
            <div className="d-flex align-content-end justify-content-end mt-2 mb-2">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'toggle-selection'}
                  checked={toggleSelection}
                  onCheck={v => setToggleSelection(v)}
                />
                <label
                  htmlFor="toggle-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
              <div className="d-flex align-items-center me-5">
                <button
                  className="btn btn-sm btn-success text-uppercase px-4 mt- mb-2"
                  onClick={handleOnRemoveDataEntry}
                >
                  {localize('remove')}
                </button>
              </div>
            </div>
            <div className="mt-2">
              <Pager
                itemsCount={checkOffDataProcessingDataEntries?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </>
        </>
      )}
    </>
  )
}
