/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import GroupedTable from '../Tables/GroupedTable'
import Pager from '../Tables/Pager'

function CustomerAccountByCustomerIdLookup({
  displayValue,
  onSelect,
  selectedCustomer,
  passedColumn,
  disabled,
  groupingColumn,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    includeBalances: true,
    includeProductDescription: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (selectedCustomer?.id && modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCustomer?.id,
    selectedCustomer[passedColumn],
    modalActive,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])
  const [groupBy, setGroupBy] = useState({
    label: 'Record Status',
    value: 0,
    column: 'customerRecordStatusDescription',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r?.accountName}</> },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r?.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r?.customerReference3}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r?.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'statusDescription' },
    { value: 1, column: 'customerRecordStatusDescription' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'createdBy' },
    { value: 4, column: 'modifiedBy' },
    {
      value: 5,
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      value: 6,
      column: 'customerAccountTypeTargetProductDescription',
    },
    { value: 7, column: 'customerAccountTypeTargetProductSectionDescription' },
    { value: 8, column: 'branchDescription' },
    { value: 9, column: 'customerMembershipClassCustomerTypeDescription' },
    { value: 10, column: 'customerIndividualIdentityCardTypeDescription' },
    { value: 11, column: 'customerIndividualSalutationDescription' },
    { value: 12, column: 'customerIndividualGenderDescription' },
    { value: 13, column: 'customerIndividualMaritalStatusDescription' },
    { value: 14, column: 'customerIndividualNationalityDescription' },
    { value: 15, column: 'customerDutyStationDescription' },
    { value: 16, column: 'customerEmployerDescription' },
    { value: 17, column: 'customerAdministrativeDivisionDescription' },
  ]

  const handleClick = () => {
    if (!selectedCustomer?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a customer first',
      })

    if (!disabled) setModalActive(true)
  }

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccountByCustomer/find-customer-accounts-by-customer-id-and-filter-in-page',
      {
        customerId: selectedCustomer?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={handleClick}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={handleClick}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 py-3 text-capitalize">
            {localize('customerAccountsLookup')} - {selectedCustomer?.fullName}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between p-2">
                  <div className="d-flex align-items-center ms-2 col-3">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <div className="d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('groupBy')}
                      </label>
                      <EnumsServerCombo
                        sort
                        value={groupBy?.value}
                        enumsKey="CustomerAccountPropertyGroup"
                        onChange={({ label, value }) =>
                          setGroupBy({
                            label,
                            value,
                            column: groupingColumns[value]?.column,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center col-3 col">
                    <label
                      className="ms-2 text-capitalize me-2"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      enumsKey="CustomerFilter"
                      value={reqParams?.customerFilter}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          customerFilter: e.value,
                        })
                      }
                    />

                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          loadData()
                        }
                      }}
                    />
                    <button
                      id="customLookupFilterButton"
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="py-1">
                  <GroupedTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  pageSize={reqParams.pageSize}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default CustomerAccountByCustomerIdLookup
