/** @format */

import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import AssetMovements from './AssetMovements'
import AssetDepreciations from './AssetDepreciations'
import Tab from '../../../../../Components/Tabs/Tab'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

const ViewAsset = ({ mode, closeModal, asset }) => {
  function handleCloseModal() {
    closeModal()
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="asset"
        handleClose={handleCloseModal}
        modalClassName="modal-xl"
        hideUpdate
      >
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.itemRegisterDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('chartOfAccount')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.itemRegisterAssetTypeChartOfAccountAccountName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('depreciationExpenseChartOfAccount')}
              </label>
              <input
                type="text"
                className="form-control"
                value={
                  asset?.itemRegisterAssetTypeDepreciationExpenseAccountAccountName
                }
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('gain-lossChartOfAccount')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.itemRegisterAssetTypeGainLossAccountAccountName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('accumulatedChartOfAccountName')}
              </label>
              <input
                type="text"
                className="form-control"
                value={
                  asset?.itemRegisterAssetTypeAccumulatedDepreciationAccountAccountNames
                }
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('assetType')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.itemRegisterAssetTypeDescription}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('department')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.departmentDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('manufacturer')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.manufacturer}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('model')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.model}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('tagNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.tagNumber}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2 ">
              <label className="col-md-2 text-capitalize">
                {localize('serialNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.serialNumber}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('purchasePrice')}
              </label>
              <MaskNumber
                defaultMaskValue={asset?.purchasePrice}
                className="form-control"
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('residualValue')}
              </label>
              <MaskNumber
                defaultMaskValue={asset?.residualValue}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('supplier')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.supplierDescription}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-md-2 text-capitalize">
                {localize('location')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.locationDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center ">
              <label className="col-md-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={asset?.remarks}
                disabled
              />
            </div>
          </div>
          <div className="row col-12 mt-2">
            <Tab
              tabItems={[
                {
                  label: localize('picture'),
                  item: (
                    <>
                      {asset.assetImage ? (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <img
                              style={{ width: '200px', height: '200px' }}
                              src={`data:image/jpeg;base64,${asset?.assetImage}`}
                              alt="wait"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              height: '300px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span className="me-3 text-capitalize">
                              {localize('noAssetImage')}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ),
                },
                {
                  label: localize('movements'),
                  item: <AssetMovements asset={asset} />,
                },
                {
                  label: localize('depreciations'),
                  item: <AssetDepreciations asset={asset} />,
                },
              ]}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  )
}

export default ViewAsset
