/** @format */
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { useState } from 'react'
import { FlagUnflagBankersChequeBook } from './FlagUnflagBankersChequeBook'

export const BankersChequeBookChequeLeaves = ({
  entries,
  selectedBankersChequeBook,
}) => {
  const [showFlagUnflagChequeLeafModal, setShowFlagUnflagChequeLeafModal] =
    useState(false)

  const [selectedBankersChequeLeaf, setSelectedBankersChequeLeaf] =
    useState(null)

  const columns = [
    {
      label: localize('leafNumber'),
      template: r => <>{r.paddedLeafNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    { label: localize('payee'), template: r => <>{r.payee}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.Amount)}</>,
    },
    {
      label: localize('wordifiedAmount'),
      template: r => <>{r.wordifiedAmount}</>,
    },
    { label: localize('paddedAmount'), template: r => <>{r.paddedAmount}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    { label: localize('writtenBy'), template: r => <>{r.writtenBy}</> },
    {
      label: localize('writtenDate'),
      template: r => <>{formatDate(r.writtenDate)}</>,
    },
    { label: localize('printedBy'), template: r => <>{r.printedBy}</> },
    {
      label: localize('printedDate'),
      template: r => <>{formatDate(r.printedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                setShowFlagUnflagChequeLeafModal(true)
                setSelectedBankersChequeLeaf(e)
              }}
              className="ctxt-menu-item"
            >
              <i className="mdi mdi-flag"></i>
              {localize('flagUnflagBankersChequeBook')}
            </div>
          </>
        )}
        selectables
        columns={columns}
        data={entries}
      />
      {showFlagUnflagChequeLeafModal && (
        <FlagUnflagBankersChequeBook
          onFlagUnflagChequeLeaf={() => {
            setShowFlagUnflagChequeLeafModal(false)
            setSelectedBankersChequeLeaf(null)
          }}
          setSelectedBankersChequeLeaf={setSelectedBankersChequeLeaf}
          selectedBankersChequeLeaf={selectedBankersChequeLeaf}
          selectedBankersChequeBook={selectedBankersChequeBook}
        />
      )}
    </>
  )
}
