/** @format */

import React, { useEffect, useState } from 'react'
import { ProductCode } from '../../Global/enumeration'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../Loaders/Loader'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import GroupedTable from '../Tables/GroupedTable'
import Pager from '../Tables/Pager'

export const MicroCreditCustomerAccountLookup = ({
  displayValue,
  onSelect,
  productCode,
  group,
}) => {
  const [reqParams, setReqParams] = useState({
    productCodes: Object.keys(ProductCode)[0],
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeBalances: true,
    includeProductDescription: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, modalActive, productCode])

  const [groupBy, setGroupBy] = useState({
    label: localize(group?.label ?? 'Record Status'),
    value: group?.value ?? 0,
    column: group?.column ?? 'customerRecordStatusDescription',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'statusDescription' },
    { value: 1, column: 'customerRecordStatusDescription' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'createdBy' },
    { value: 4, column: 'modifiedBy' },
    {
      value: 5,
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      value: 6,
      column: 'customerAccountTypeTargetProductDescription',
    },
    { value: 7, column: 'customerAccountTypeTargetProductSectionDescription' },
    { value: 8, column: 'branchDescription' },
    { value: 9, column: 'customerMembershipClassCustomerTypeDescription' },
    { value: 10, column: 'customerIndividualIdentityCardTypeDescription' },
    { value: 11, column: 'customerIndividualSalutationDescription' },
    { value: 12, column: 'customerIndividualGenderDescription' },
    { value: 13, column: 'customerIndividualMaritalStatusDescription' },
    { value: 14, column: 'customerIndividualNationalityDescription' },
    { value: 15, column: 'customerDutyStationDescription' },
    { value: 16, column: 'customerEmployerDescription' },
    { value: 17, column: 'customerAdministrativeDivisionDescription' },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/MicroCreditGroup/find-micro-credit-customer-accounts-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('microCreditCustomerAccountLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center float-right my-2">
                    <label
                      className="text-capitalize"
                      htmlFor="LoanProductLookupPageSize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="LoanProductLookupPageSize"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      value={groupBy?.value}
                      enumsKey="CustomerAccountPropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="col-auto me-3 text-capitalize">
                      {localize('product')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="ProductCode"
                      value={reqParams?.productCodes}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          productCodes: e.value,
                          pageIndex: 0,
                        })
                      }}
                    />
                  </div>

                  <div
                    className="col d-flex align-items-center gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 150,
                        minWidth: 150,
                      }}
                      value={reqParams?.customerFilter}
                      enumsKey="CustomerFilter"
                      onChange={({ value }) =>
                        setReqParams({
                          ...reqParams,
                          customerFilter: value,
                          pageIndex: 0,
                        })
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                          pageIndex: 0,
                        })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') loadData()
                      }}
                    />
                    <button
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="py-1">
                  <GroupedTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>

          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('close')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
