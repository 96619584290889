/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import GroupedLoanGuarantors from '../../../../../Components/ReUsables/Customer/GroupedLoanGuarantors'
import MembershipStatuses from '../../../../../Components/ReUsables/Customer/MembershipStatuses/MembershipStatuses'
import InvestmentAccounts from '../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import SavingsAccounts from '../../../../../Components/ReUsables/SavingsAccounts/SavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  ProductCode,
  RecordStatus,
  standingOrderTrigger,
} from '../../../../../Global/enumeration'
import { guarantorAttachmentMode } from '../../../../../Helpers/constants'
import { getData, postData } from '../../../../../Helpers/webApi'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

const Attachment = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [customer, setCustomer] = useState({})
  const [loanProduct, setLoanProduct] = useState({})
  const [selectedLoanAccounts, setSelectedLoanAccounts] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])
  const [attachToOwedLoanProduct, setAttachToOwedLoanProduct] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [
    manualAllocationLoanGuarantorDTOs,
    setManualAllocationLoanGuarantorDTOs,
  ] = useState([])
  const [attachment, setAttachment] = useState({
    guarantorAttachmentMode: guarantorAttachmentMode.EQUAL_PORTIONS,
    standingOrderTrigger: Object.keys(standingOrderTrigger)[0],
  })
  const [customerAccounts, setCustomerAccounts] = useState({
    customerLoanAccounts: [],
    customerSavingsAccounts: [],
    customerInvestmentAccounts: [],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleOnLookup = customer => {
    if (customer?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer is locked',
      })

    if (customer?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Selected Customer record status must be approved',
      })

    clearValidation('Customer.Id')
    setCustomer(customer)
  }

  const handleSelectLoanProduct = loanProduct => {
    if (!customer?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    if (loanProduct?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Loan product is locked',
      })

    clearValidation('LoanProductDTO.Id')
    setLoanProduct(loanProduct)
  }

  const selectAllLoanAccounts = (accounts, value) => {
    if (value) {
      setSelectedLoanAccounts(accounts)
    } else {
      setSelectedLoanAccounts([])
    }
  }

  const handleSelectLoanAccount = (account, value) => {
    if (value) {
      setSelectedLoanAccounts([...selectedLoanAccounts, account])
    } else {
      setSelectedLoanAccounts(
        selectedLoanAccounts.filter(x => x.id !== account.id)
      )
    }
  }

  const handleSelectAllStatuses = (statuses, value) => {
    if (value) {
      setSelectedStatuses(statuses)
    } else {
      setSelectedStatuses([])
    }
  }

  const handleSelectStatus = (status, value) => {
    if (value) {
      setSelectedStatuses([...selectedStatuses, status])
    } else {
      setSelectedStatuses(
        selectedStatuses.filter(x => x.value !== status.value)
      )
    }
  }

  const handleClickUpdateButton = () => {
    if (!selectedLoanAccounts?.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one loan account',
      })

    if (!selectedStatuses?.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one membership status',
      })

    setShowConfirmModal(true)
  }

  const handleUpdate = async () => {
    setIsBusyUpdating(true)
    const url = '/LoanCase/attach-loan-guarantors'

    const { success } = await postData(
      url,
      {
        customer,
        loanProductDto: loanProduct,
        guarantorAttachmentMode: attachment?.guarantorAttachmentMode,
        standingOrderTrigger: attachment?.standingOrderTrigger,
        moduleNavigationItemCode: 1000,
        customerLoanAccountDTOs: selectedLoanAccounts,
        customerMembershipStatuses: selectedStatuses,
        manualAllocationLoanGuarantorDTOs,
        attachToOwedLoanProduct,
      },
      false
    )

    if (success) {
      setCustomer({})
      setLoanProduct({})
      setSelectedLoanAccounts([])
      setSelectedStatuses([])
      setAttachment({
        guarantorAttachmentMode: guarantorAttachmentMode.EQUAL_PORTIONS,
        standingOrderTrigger: Object.keys(standingOrderTrigger)[0],
      })
      setAttachToOwedLoanProduct(false)
      setManualAllocationLoanGuarantorDTOs([])
      showNotification('Guarantor Attachment', 'success')
      setCustomerAccounts({
        customerLoanAccounts: [],
        customerSavingsAccounts: [],
        customerInvestmentAccounts: [],
      })
      setGlobalValidationErrors({})
      setManualAllocationLoanGuarantorDTOs
    }

    setShowConfirmModal(false)
    setIsBusyUpdating(false)
  }

  const fetchCustomerAccounts = async () => {
    setIsBusy(true)
    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customer?.id,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)

      if (data?.customerLoanAccounts?.length > 0)
        selectAllLoanAccounts(
          data?.customerLoanAccounts?.filter(x => x?.bookBalance < 0),
          true
        )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (customer?.id) {
      fetchCustomerAccounts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  return (
    <>
      {showConfirmModal && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleAction={handleUpdate}
          handleClose={() => setShowConfirmModal(false)}
          modalTitle="guarantorAttachment"
          disableAction={isBusyUpdating}
          disableClose={isBusyUpdating}
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <p>Do you want to proceed?</p>
            {isBusyUpdating && <NonBlockingLoader />}
          </div>
        </ActionModal>
      )}
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('guarantorsAttachment'),
        ]}
      />
      <div className="card rounded bg-white relative">
        <div className="card-body">
          <CustomerPartial
            onCustomerLookup={handleOnLookup}
            customer={customer}
            message={globalValidationErrors?.['Customer.Id']?.message}
          />
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('attachmentMode')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="GuarantorAttachmentMode"
                  value={attachment?.guarantorAttachmentMode}
                  onChange={e =>
                    setAttachment({
                      ...attachment,
                      guarantorAttachmentMode: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize me-2">
                  {localize('standingOrderTrigger')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="StandingOrderTrigger"
                  value={attachment?.standingOrderTrigger}
                  onChange={e =>
                    setAttachment({
                      ...attachment,
                      standingOrderTrigger: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors?.['LoanProductDTO.Id']?.message
                }
              >
                <label className="col-4 text-capitalize me-2">
                  {localize('loanProduct')}
                </label>
                <LoanProductLookup
                  displayValue={loanProduct?.description}
                  onSelect={handleSelectLoanProduct}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('loansAccounts'),
                  item: (
                    <>
                      <LoanAccounts
                        data={customerAccounts?.customerLoanAccounts?.filter(
                          x => x?.bookBalance < 0
                        )}
                        handleSelectLoanAccount={handleSelectLoanAccount}
                        selectAllLoanAccounts={selectAllLoanAccounts}
                        selectedLoanAccounts={selectedLoanAccounts}
                        customerId={customer?.customerId}
                        showContextMenu
                        canSelect
                      />
                    </>
                  ),
                  icon: 'uil uil-edit',
                },
                {
                  label: localize('membershipStatuses'),
                  item: (
                    <>
                      <MembershipStatuses
                        handleSelectAllStatuses={handleSelectAllStatuses}
                        handleSelectStatus={handleSelectStatus}
                        selectedStatuses={selectedStatuses}
                      />
                    </>
                  ),
                  icon: 'uil uil-edit',
                },
                {
                  label: localize('savingsAccounts'),
                  item: (
                    <>
                      <SavingsAccounts
                        data={customerAccounts?.customerSavingsAccounts}
                      />
                    </>
                  ),
                },
                {
                  label: localize('investmentAccounts'),
                  item: (
                    <>
                      <InvestmentAccounts
                        data={customerAccounts?.customerInvestmentAccounts}
                      />
                    </>
                  ),
                },
                {
                  label: localize('loanGuarantors'),
                  item: (
                    <>
                      <GroupedLoanGuarantors
                        customerId={customer?.id}
                        setManualAllocationLoanGuarantorDTOs={
                          setManualAllocationLoanGuarantorDTOs
                        }
                        attachmentMode={attachment?.guarantorAttachmentMode}
                        filterByAttAchedGuarantors
                        resetManualAllocationLoanGuarantorDTOs
                      />
                    </>
                  ),
                  icon:
                    attachment?.guarantorAttachmentMode ===
                    guarantorAttachmentMode.MANUAL_ALLOCATION
                      ? 'uil uil-edit'
                      : 'uil uil-table',
                },
                {
                  label: localize('specimen'),
                  item: (
                    <>
                      <Specimen customer={customer} readOnly />
                    </>
                  ),
                },
              ]}
            />
          </div>

          <div className="mt-2 bg-white rounded py-3 px-2">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={attachToOwedLoanProduct}
                  onCheck={value => setAttachToOwedLoanProduct(value)}
                  id={`attach-to-owed-loan-product-${customer?.id}`}
                />

                <label
                  className="text-capitalize"
                  htmlFor={`attach-to-owed-loan-product-${customer?.id}`}
                >
                  {localize('attachToOwedLoanProduct') + ' ?'}
                </label>
              </div>

              <button
                onClick={handleClickUpdateButton}
                className="btn btn-success"
              >
                {localize('update')}
              </button>
            </div>
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
    </>
  )
}

export default Attachment
