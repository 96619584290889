/** @format */

import React from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'

const VerifySuperSaverPayable = ({
  handleClose,
  mode,
  handleVerifySuperSaverPayable,
  loading,
  superSaverPayable,
  globalValidationErrors,
  clearValidation,
  setSuperSaverPayable,
}) => {
  return (
    <ModalTemplate
      ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      actionText="update"
      handleAction={handleVerifySuperSaverPayable}
      modalTitle="auditSuperSaverPayable"
      modalClassName="modal-xl"
      disableCreate={loading}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={
              globalValidationErrors?.['AuditRemarks']?.message
            }
          >
            <label className="text-capitalize me-3">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              onChange={e => {
                clearValidation('AuditRemarks')
                setSuperSaverPayable({
                  ...superSaverPayable,
                  auditRemarks: e.target.value,
                })
              }}
              disabled={loading}
            />
          </div>
          <EnumsServerCombo
            customContainerStyle={{
              maxWidth: 200,
              minWidth: 200,
            }}
            enumsKey="SuperSaverPayableAuditOption"
            value={superSaverPayable?.superSaverPayableAuditOption}
            onChange={e => {
              setSuperSaverPayable({
                ...superSaverPayable,
                superSaverPayableAuditOption: e.target.value,
              })
            }}
            sort
            disabled={loading}
          />
        </div>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={`row g-3`}>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={superSaverPayable?.fullAccountNumber}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={superSaverPayable?.statusDescription}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>

              <input
                type="text"
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  superSaverPayable?.bookBalance,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={superSaverPayable?.customerFullName}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  superSaverPayable?.customerAccountCustomerMembershipClassCustomerTypeDescription
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  superSaverPayable?.customerAccountCustomerIndividualIdentityCardNumber
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  superSaverPayable?.formattedCustomerAccountCustomerMemberNumber
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  superSaverPayable?.formattedCustomerAccountCustomerAccountNumber
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  superSaverPayable?.customerAccountCustomerPersonalFileNumber
                }
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="col-4 text-capitalize">
                {localize('interest')}
              </label>

              <input
                type="text"
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  superSaverPayable?.amount,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="col-4 text-capitalize">
                {localize('withholdingTaxAmount')}
              </label>

              <input
                type="text"
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  superSaverPayable?.withholdingTaxAmount,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="col-4 text-capitalize">
                {localize('remarks')}
              </label>

              <input
                type="text"
                className="form-control"
                disabled
                value={superSaverPayable?.remarks}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifySuperSaverPayable
