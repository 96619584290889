/** @format */

import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatAsPercent } from '../../../../../Helpers/formatters'
import { useState } from 'react'
import ViewGLAccountTransactions
  from '../../../../../Components/ReUsables/GLAccounts/ViewGLAccountTransactions/ViewGLAccountTransactions'

export const ChargeSplits = ({ levySplits, setLevySplits, mode }) => {
  const columns = [
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    { label: localize('name'), template: r => <>{r.description}</> },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.percentage)}</>,
    },
  ]

  const [selectedLevySplit, setSelectedLevySplit] = useState(null)

  const renderContextMenu = e => {
    if (mode !== 'view') {
      return (
        <>
          <div
            onClick={() => {
              setLevySplits(levySplits.filter(x => x.id !== e.id))
            }}
            className="ctxt-menu-item"
          >
            <i className="uil uil-remove"></i>
            {localize('remove')}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div
            onClick={() => {
              setSelectedLevySplit(e)
            }}
            className="ctxt-menu-item"
          >
            <i className="uil uil-window"></i>
            {localize('viewAccountTransactions')}
          </div>
        </>
      )
    }
  }

  return (
    <>
      <SimpleTable
        columns={columns}
        data={levySplits}
        contextMenu={renderContextMenu}
      />
      {selectedLevySplit && (
        <ViewGLAccountTransactions
          mode={mode}
          handleClose={() => {
            setSelectedLevySplit(null)
          }}
          glAccount={selectedLevySplit}
        />
      )}
    </>
  )
}
