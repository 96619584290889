/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import CustomerAccountLookup from '../../../../../Components/Lookup/CustomerAccountLookup'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import AdjustGuarantorStatus from '../../../../../Components/ReUsables/Loan/LoanAccounts/AdjustGuarantorStatus/AdjustGuarantorStatus'
import LoanGuarantors from '../../../../../Components/ReUsables/Loan/LoanGuarantors/LoanGuarantors'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  ProductCode,
  RecordStatus,
  customerAccountStatus,
  customerMembershipStatus,
  guarantorSecurityMode,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'

const Management = () => {
  const [customerAccount, setCustomerAccount] = useState({})
  const [guarantor, setGuarantor] = useState({
    amountGuaranteed: 0.0,
  })
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedLoanGuarantors, setSelectedLoanGuarantors] = useState([])
  const [reload, setReload] = useState(false)
  const [loanProduct, setLoanProduct] = useState({})
  const [guarantorData, setGuarantorData] = useState({})
  const [isLoadingGuarantorData, setIsLoadingGuarantorData] = useState(false)

  const fetchGuarantorData = async () => {
    setIsLoadingGuarantorData(true)
    const url =
      '/LoanGuarantorSubstitutionRequest/retrieve-loan-gurantor-metadata'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: customerAccount?.id,
        customerId: guarantor?.id,
      },
      false
    )

    if (success) {
      setGuarantorData(data)
      setGuarantor({
        ...guarantor,
        amountGuaranteed: data?.amountGuaranteed,
      })
    } else {
      setGuarantorData({
        currentGuarantees: 0,
        guaranteedOutstandingBalances: 0,
        appraisalFactor: 0,
        totalShares: 0,
        committedShares: 0,
      })
      setGuarantor({
        amountGuaranteed: 0.0,
      })
    }

    setIsLoadingGuarantorData(false)
  }

  const handleAdjustStatus = () => {
    if (!customerAccount?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    setMode('adjustStatus')
  }

  const handelSelectCustomerAccount = async account => {
    if (account?.status === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This account is closed',
      })

    if (account?.customerAccountTypeProductCode !== Object.keys(ProductCode)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This account is not a loan account',
      })

    setCustomerAccount(account)
  }

  const handleSelectGuarantor = async data => {
    if (!customerAccount?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    if (
      data?.membershipStatus !== Object.keys(customerMembershipStatus)[0] &&
      data?.customerMembershipStatus !==
        Object.keys(customerMembershipStatus)[2]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This customer must be either active or partially terminated',
      })

    if (data?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This customer is locked',
      })

    if (data?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer record status must be in approved status',
      })

    setGuarantor({
      ...data,
      amountGuaranteed: 0.0,
    })
  }

  const handleClose = () => {
    setMode(null)
  }

  const fetchLoanProduct = async () => {
    setIsBusy(true)
    const url = '/LoanProduct/find-loan-product-by-id'

    const { success, data } = await getData(
      url,
      { loanProductId: customerAccount?.customerAccountTypeTargetProductId },
      false
    )

    if (success) {
      setLoanProduct(data?.loanProduct)
    }

    setIsBusy(false)
  }

  const handleSelectAllGuarantor = (guarantors, value) => {
    if (value) {
      setSelectedLoanGuarantors(guarantors)
    } else {
      setSelectedLoanGuarantors([])
    }
  }

  const handleSelectLoanGuarantor = (guarantor, value) => {
    if (value) {
      setSelectedLoanGuarantors([...selectedLoanGuarantors, guarantor])
    } else {
      setSelectedLoanGuarantors(
        selectedLoanGuarantors.filter(g => g.id !== guarantor?.id)
      )
    }
  }

  const handleAddGuarantor = async () => {
    if (!guarantor?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    setIsBusy(true)

    const url = '/LoanCase/add-loan-guarantors'

    const { success } = await postData(
      url,
      {
        loanCaseId: customerAccount?.loanCaseId,
        loanCaseCustomerId: customerAccount?.customerId,
        id: guarantor?.id,
        loaneeCustomerId: guarantor?.id,
        customerId: customerAccount?.customerId,
        ...guarantorData,
        ...guarantor,
        ...customerAccount,
      },
      false
    )

    if (success) {
      showNotification('Guarantor Management', 'success')
      setSelectedLoanGuarantors([])
      setIsBusy(false)
      setGuarantor({
        amountGuaranteed: 0.0,
      })
      setReload(true)
      setGuarantorData({
        currentGuarantees: 0,
        guaranteedOutstandingBalances: 0,
        appraisalFactor: 0,
        totalShares: 0,
        committedShares: 0,
      })
    }
    setIsBusy(false)
  }

  const handleRemove = async () => {
    setIsBusy(true)

    const url = '/LoanCase/remove-loan-guarantors'

    const { success } = await postData(url, selectedLoanGuarantors, false)

    if (success) {
      showNotification('guarantorManagement', 'success')
      setSelectedLoanGuarantors([])
      setReload(true)
      setGuarantor({
        amountGuaranteed: 0.0,
      })
    }

    setIsBusy(false)
  }

  const group = {
    label: 'Product Code',
    column: 'customerAccountTypeProductCodeDescription',
    value: 5,
  }

  useEffect(() => {
    if (customerAccount?.id && guarantor?.id) fetchGuarantorData()

    // eslint-disable-next-line
  }, [customerAccount?.id, guarantor?.id])

  useEffect(() => {
    if (customerAccount?.id) fetchLoanProduct()

    // eslint-disable-next-line
  }, [customerAccount?.id])

  return (
    <>
      {mode === 'adjustStatus' && (
        <AdjustGuarantorStatus
          customerId={customerAccount?.id}
          handleClose={handleClose}
          loanCaseId={customerAccount?.loanCaseId}
          mode={mode}
        />
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('management'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <label className=" col-4 text-capitalize">
                    {localize('fullAccountNumber')}
                  </label>
                  <CustomerAccountLookup
                    displayValue={customerAccount?.fullAccountNumber}
                    onSelect={handelSelectCustomerAccount}
                    productCode={Object.keys(ProductCode)[1]}
                    group={group}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountStatus')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={customerAccount?.customerMembershipStatusDescription}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountRemarks')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={customerAccount?.remarks}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize pe-2">
                    {localize('productName')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    value={
                      customerAccount?.customerAccountTypeTargetProductDescription
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <label className=" col-4 text-capitalize">
                    {localize('customerName')}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={customerAccount?.accountName || ''}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('customerType')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      customerAccount?.customerMembershipClassCustomerTypeDescription ||
                      ''
                    }
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('customerMember#')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerAccount?.paddedCustomerSerialNumber || ''}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('employerName')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerAccount?.customerEmployerDescription || ''}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <label className=" col-4 text-capitalize">
                    {localize('identification#')}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={customerAccount?.customerIdentificationNumber || ''}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('memberNumber')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerAccount?.formattedCustomerMemberNumber || ''}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountNumber')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerAccount?.paddedCustomerAccountNumber || ''}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('personalFileNumber')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerAccount?.customerPersonalFileNumber || ''}
                  />
                </div>
              </div>
              <div className="row bg-light py-2 px-1 rounded mt-2">
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-success"
                      onClick={handleAdjustStatus}
                    >
                      {localize('status Adj.')}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="p-2 mt-4"
                style={{
                  position: 'relative',
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                }}
              >
                <div
                  className="px-2 row text-capitalize bg-white"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('guarantorDetails')}
                </div>
                <div className="row mb-2">
                  <div className="col-3 d-flex align-items-center">
                    <label className=" col-4 text-capitalize">
                      {localize('customerName')}
                    </label>
                    <CustomersLookup
                      displayValue={guarantor?.fullName || ''}
                      onSelect={handleSelectGuarantor}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        guarantor?.membershipClassCustomerTypeDescription || ''
                      }
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('membershipStatus')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.membershipStatusDescription || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('joinDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={formatDate(guarantor?.registrationDate, true)}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('domicileBranch')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.branchDescription || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('employerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.employerDescription || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('duty/Workstation')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.dutyStationDescription || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('adminDivision')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.administrativeDivisionDescription || ''}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('identification#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.identificationNumber || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('memberNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.formattedMemberNumber || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.formattedAccountNumber || ''}
                    />
                  </div>
                  <div className=" col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={guarantor?.personalFileNumber || ''}
                    />
                  </div>
                </div>

                {isLoadingGuarantorData ? (
                  <Loader />
                ) : (
                  <>
                    <div className="row mb-2">
                      <div className=" col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('currentGuarantees')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={guarantorData?.currentGuarantees || ''}
                        />
                      </div>
                      <div className=" col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('balancesGuaranteed')}
                        </label>
                        <input
                          type="text"
                          className="form-control text-end"
                          disabled
                          value={formatCurrency(
                            guarantorData?.guaranteedOutstandingBalances,
                            null,
                            null,
                            false
                          )}
                        />
                      </div>
                      <div className=" col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('appraisalFactor')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={guarantorData?.appraisalFactor || ''}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize pe-2">
                          {localize('guarantorSecurityMode')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            Object.values(guarantorSecurityMode)[
                              loanProduct?.loanRegistrationGuarantorSecurityMode
                            ]?.name
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className=" col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('totalShares')}
                        </label>
                        <input
                          type="text"
                          className="form-control text-end"
                          disabled
                          value={formatCurrency(
                            guarantorData?.totalShares,
                            null,
                            null,
                            false
                          )}
                        />
                      </div>
                      <div className=" col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('committedShares')}
                        </label>
                        <input
                          type="text"
                          className="form-control text-end"
                          disabled
                          value={formatCurrency(
                            guarantorData?.committedShares,
                            null,
                            null,
                            false
                          )}
                        />
                      </div>
                      <div className=" col-6 d-flex align-items-center">
                        <label className="col-2 text-capitalize">
                          {localize('amountGuaranteed')}
                        </label>
                        <MaskNumber
                          defaultMaskValue={guarantor?.amountGuaranteed}
                          onMaskNumber={e => {
                            setGuarantor({
                              ...guarantor,
                              amountGuaranteed: e.target.rawValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row mb-2 bg-light rounded py-2 px-1 m-2">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      className="btn btn-success"
                      onClick={handleAddGuarantor}
                    >
                      {localize('add')}
                    </button>
                  </div>
                </div>
                <Tab
                  tabItems={[
                    {
                      label: localize('guarantor'),
                      item: (
                        <>
                          <LoanGuarantors
                            loanCaseId={customerAccount?.loanCaseId}
                            handleSelectAllGuarantors={handleSelectAllGuarantor}
                            handleSelectGuarantor={handleSelectLoanGuarantor}
                            selectedGuarantors={selectedLoanGuarantors}
                            handleRemove={handleRemove}
                            reload={reload}
                            setReload={setReload}
                            showRemoveButton
                          />
                        </>
                      ),
                      icon: 'uil uil-edit',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Management
