/** @format */

/* eslint-disable */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Loader from '../../../../Components/Loaders/Loader'

const ViewCostDistributions = ({
  handleClose,
  mode,
  costDistributionsTableData,
  costDistributionsTableColumns,
  title,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleClose={handleClose}
      modalTitle={title}
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable
          data={costDistributionsTableData}
          columns={costDistributionsTableColumns}
        />
      )}
    </ModalTemplate>
  )
}

export default ViewCostDistributions
