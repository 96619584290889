/** @format */

import { createContext, useState } from 'react'
import languages from '../Global/languages'

export const LanguageContext = createContext(null)

export function LanugageContextProvider({ children }) {
  const [selectedLanguage, setSelectedLanguage] = useState(languages['en'])

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        setSelectedLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
