/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../InputMasks/MaskNumber'
import ModalTemplate from '../../../../ModalTemplate/ModalTemplate'
import Loader from '../../../../Loaders/Loader'

function maskCurrency(value) {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })

  if (value || value === 0) {
    const numericValue =
      parseFloat(value.toString().replace(/[^0-9.-]/g, '')) || 0
    const formattedValue = numberFormatter.format(numericValue)
    return formattedValue
  }
  return ''
}

const EditLoanGuarantor = ({
  handleClose,
  mode,
  handleEditLoanGuarantor,
  guarantor,
  guarantorAmounts,
  setGuarantorAmounts,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalTitle="editLoanGuarantor"
      actionText="edit"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={handleEditLoanGuarantor}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col-3 d-flex align-items-center">
              <label className=" col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.customerFullName}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  guarantor?.customerMembershipClassCustomerTypeDescription
                }
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.customerMembershipStatusDescription}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('joinDate')}
              </label>
              <input type="text" className="form-control" disabled />
            </div>
          </div>
          <div className="row mb-2">
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identification#')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.customerIdentificationNumber}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.formattedCustomerMemberNumber}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.paddedCustomerAccountNumber}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.loanCaseDocumentReferenceNumber}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.loanCaseDocumentReferenceNumber}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanProduct')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.loanProductDescription}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanPurpose')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.loanCaseLoanPurposeDescription}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={guarantor?.loanCaseStatusDescription}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('totalShares')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  guarantor?.totalShares,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('committedShares')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  guarantor?.committedShares,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('amountGuaranteed')}
              </label>
              <MaskNumber
                defaultMaskValue={guarantorAmounts?.amountGuaranteed}
                onMaskNumber={e => {
                  setGuarantorAmounts({
                    ...guarantorAmounts,
                    amountGuaranteed: e.target.value,
                  })
                }}
              />
            </div>
            <div className=" col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('amountPledged')}
              </label>
              <MaskNumber
                defaultMaskValue={guarantorAmounts?.amountPledged}
                onMaskNumber={e => {
                  setGuarantorAmounts({
                    ...guarantorAmounts,
                    amountPledged: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="mt-4"></div>
        </>
      )}
    </ModalTemplate>
  )
}

export default EditLoanGuarantor
