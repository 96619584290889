/** @format */

import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { useState } from 'react'
import { CreateMicroCreditGroupContainer } from './CreateMicroCreditGroupContainer'
import {
  MeetingDayOfWeek,
  MeetingFrequency,
  MicroCreditGroupMemberDesignation,
  MicroCreditGroupType,
} from '../../../../../Helpers/constants'
import { postData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { EditMicroCreditGroupContainer } from './EditMicroCreditGroupContainer'

function MicroCreditGroupModal({
  mode,
  onHideModal,
  selectedMicroCreditGroup,
  setSelectedMicroCreditGroup,
  onUpdateRefreshAt,
  onSetMode,
}) {
  const [disableCreate, setDisableCreate] = useState(false)
  const [microCreditGroupMemberEntries, setMicroCreditGroupMemberEntries] =
    useState([])
  const [microCreditGroupModel, setMicroCreditGroupModel] = useState({
    meetingDayOfWeek: MeetingDayOfWeek.SUNDAY,
    meetingFrequency: MeetingFrequency.MONTHLY,
    type: MicroCreditGroupType.ROSCA,
  })
  const [microCreditGroupMember, setMicroCreditGroupMember] = useState({
    microCreditGroupMemberDesignation:
      MicroCreditGroupMemberDesignation.ORDINARY_MEMBER,
  })

  const handleSubmit = () => {
    if (mode === 'add') {
      handleOnAddMicroCreditWithMembers()
    }
    if (mode === 'edit') {
      handleonEditMicroCreditGroup()
    }
  }

  const handleonEditMicroCreditGroup = async () => {
    setDisableCreate(true)

    const { success, data } = await postData(
      '/MicroCreditGroup/update-micro-credit-group',
      {
        ...selectedMicroCreditGroup,
      },
      false,
      null,
    )
    if (success) {
      swal.fire(
        'Edit Micro Credit Group',
        `Operation Completed Successfully`,
        'success',
      )
      onHideModal()
    }
    setDisableCreate(false)
  }

  const handleOnAddMicroCreditWithMembers = async () => {
    setDisableCreate(true)
    const { success, data } = await postData(
      '/MicroCreditGroup/add-micro-credit-group-with-members',
      {
        MicroCreditGroup: microCreditGroupModel || {},
        GroupMembers: microCreditGroupMemberEntries,
      },
      false,
      null,
    )
    if (success) {
      swal.fire(
        'Create Micro Credit Group',
        `Operation Completed Successfully`,
        'success',
      )
      onHideModal()
    }
    setDisableCreate(false)
  }
  const onRemoveGroupMember = r => {
    setMicroCreditGroupMemberEntries(
      microCreditGroupMemberEntries.filter(m => m.id !== r.id),
    )
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            disableCreate={disableCreate}
            modalMode={mode}
            modalTitle={localize('microCreditGroup')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateMicroCreditGroupContainer
                mode={mode}
                disableCreate={disableCreate}
                handleOnRemove={onRemoveGroupMember}
                microCreditGroupMemberEntries={microCreditGroupMemberEntries}
                setMicroCreditGroupMemberEntries={
                  setMicroCreditGroupMemberEntries
                }
                microCreditGroupMember={microCreditGroupMember}
                setMicroCreditGroupMember={setMicroCreditGroupMember}
                microCreditGroupModel={microCreditGroupModel}
                setMicroCreditGroupModel={setMicroCreditGroupModel}
              />
            )}
            {mode === 'edit' && (
              <EditMicroCreditGroupContainer
                mode={mode}
                disableCreate={disableCreate}
                setSelectedMicroCreditGroup={setSelectedMicroCreditGroup}
                selectedMicroCreditGroup={selectedMicroCreditGroup}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default MicroCreditGroupModal
