/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../Components/Loaders/Loader'

function CustomerSalaryHistory({ customer, loanProduct, onGetLatestIncome }) {
  const [histories, setHistories] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    loadHistories()
    // eslint-disable-next-line
  }, [customer, loanProduct])

  useEffect(() => {
    Array.isArray(histories) && histories.length > 0
      ? onGetLatestIncome(histories[histories.length - 1]?.netPay)
      : onGetLatestIncome(0)
    // eslint-disable-next-line
  }, [histories])

  const columns = [
    {
      label: localize('salaryPeriod'),
      template: r => <>{r?.salaryPeriodPostingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r?.salaryPeriodMonthDescription}</>,
    },
    {
      label: localize('netPay'),
      template: r => <>{formatCurrency(r?.netPay)}</>,
    },
    {
      label: localize('salaryPeriodStatus'),
      template: r => <>{r?.salaryPeriodStatusDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function loadHistories() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/payslip/find-loan-appraisal-payslips-by-customer-id',
      {
        customerId: customer?.id,
        loanProductId: loanProduct?.id,
        includeProductDescription: true,
      },
      false
    )
    if (success) setHistories(data?.result || [])
    setIsBusy(false)
  }

  return isBusy ? (
    <Loader />
  ) : (
    <SimpleTable data={histories} columns={columns} />
  )
}

export default CustomerSalaryHistory
