/** @format */

import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Tab from '../../../../Components/Tabs/Tab'
import { ChequeTypeCommissions } from './ChequeTypeCommissions'
import { ChequeTypeOffsetableLoanProducts } from './ChequeTypeOffsetableLoanProducts'
import { ChequeTypeInvestmentProducts } from './ChequeTypeInvestmentProducts'
import React from 'react'
import Loader from '../../../../Components/Loaders/Loader'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

export const EditChequeTypeModal = ({
  selectedChequeType,
  setSelectedChequeType,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.['ChequeType.Description']?.message}
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedChequeType?.description}
                onChange={e =>{
                  setSelectedChequeType({
                    ...selectedChequeType,
                    description: e.target.value,
                  })
                  clearValidation('ChequeType.Description')
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('maturityDaysCategory')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="MaturityDaysCategory"
                value={selectedChequeType?.maturityDaysCategory}
                onChange={e => {
                  setSelectedChequeType({
                    ...selectedChequeType,
                    maturityDaysCategory: e.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('maturityPeriod')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedChequeType?.maturityPeriod}
                onMaskNumber={e =>
                  setSelectedChequeType({
                    ...selectedChequeType,
                    maturityPeriod: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chargeRecoveryMode')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ChequeTypeChargeRecoveryMode"
                value={selectedChequeType?.chargeRecoveryMode}
                onChange={e => {
                  setSelectedChequeType({
                    ...selectedChequeType,
                    chargeRecoveryMode: e.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('applicableCharges'),
                    item: (
                      <ChequeTypeCommissions
                        setSelectedChequeType={setSelectedChequeType}
                        selectedChequeType={selectedChequeType}
                        prefix={'edit-cheque-type-modal'}
                      />
                    ),
                  },
                  {
                    label: localize('offsetableLoanProducts'),
                    item: (
                      <ChequeTypeOffsetableLoanProducts
                        setSelectedChequeType={setSelectedChequeType}
                        selectedChequeType={selectedChequeType}
                        prefix={'edit-cheque-type-modal'}
                      />
                    ),
                  },
                  {
                    label: localize('offsetableInvestmentProducts'),
                    item: (
                      <ChequeTypeInvestmentProducts
                        setSelectedChequeType={setSelectedChequeType}
                        selectedChequeType={selectedChequeType}
                        prefix={'edit-cheque-type-modal'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
