export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const SET_CUSTOMERS_ACCOUNT_LISTINGS = "SET_CUSTOMERS_ACCOUNT_LISTINGS";
export const SET_CUSTOMER_CREDIT_SCORES = "SET_CUSTOMER_CREDIT_SCORES";
export const SET_CUSTOMER_ADDRESS = "SET_CUSTOMER_ADDRESS";
export const SET_CUSTOMER_ACCOUNT_BALANCES_SUMMARY = "SET_CUSTOMER_ACCOUNT_BALANCES_SUMMARY";

export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const SET_ACCOUNT_STATEMENTS = "SET_ACCOUNT_STATEMENTS";
export const SET_ACCOUNT_REPAYMENT_SCHEDULE = "SET_ACCOUNT_REPAYMENT_SCHEDULE";
export const SET_ACCOUNT_CARRY_FORWARDS = "SET_ACCOUNT_CARRY_FORWARDS";
export const SET_ACCOUNT_JOURNAL_ENTRIES = "SET_ACCOUNT_JOURNAL_ENTRIES";


export const setSelectedCustomer = (selectedCustomer) => ({
  type: SET_SELECTED_CUSTOMER,
  payload: selectedCustomer
});

export const setCustomersAccountListings = (accountListings) => ({
  type: SET_CUSTOMERS_ACCOUNT_LISTINGS,
  payload: accountListings
});

export const setCustomerCreditScores = (creditScores) => ({
  type: SET_CUSTOMER_CREDIT_SCORES,
  payload: creditScores
});

export const setCustomerAddress = (address) => ({
  type: SET_CUSTOMER_ADDRESS,
  payload: address
});

export const setCustomerAccountBalancesSummary = (balancesSummary) => ({
  type: SET_CUSTOMER_ACCOUNT_BALANCES_SUMMARY,
  payload: balancesSummary
});

// Action creators for accountDetailsReducer
export const setAccountDetails = (details) => ({
  type: SET_ACCOUNT_DETAILS,
  payload: details
});

export const setAccountStatements = (statements) => ({
  type: SET_ACCOUNT_STATEMENTS,
  payload: statements
});

export const setAccountRepaymentSchedule = (repaymentSchedule) => ({
  type: SET_ACCOUNT_REPAYMENT_SCHEDULE,
  payload: repaymentSchedule
});

export const setAccountCarryForwards = (carryForwards) => ({
  type: SET_ACCOUNT_CARRY_FORWARDS,
  payload: carryForwards
});

export const setAccountJournalEntries = (journalEntries) => ({
  type: SET_ACCOUNT_JOURNAL_ENTRIES,
  payload: journalEntries
});
