/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'

import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData, putData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function LoanFlags({ loan, disabled }) {
  const [isBusy, setIsBusy] = useState(false)
  const [loanProduct, setLoanProduct] = useState(null)

  const col1 = [
    {
      label: 'enforceSecurityRules',
      column: 'loanRegistrationSecurityRequired',
    },
    { label: 'microcredit', column: 'loanRegistrationMicrocredit' },
    { label: 'bypassVerfication', column: 'loanRegistrationBypassAudit' },
    {
      label: 'throttleScheduledArrearsRecovery',
      column: 'loanRegistrationThrottleScheduledArrearsRecovery',
    },
    {
      label: 'computeDeductibleChargeOnTopUp',
      column: 'computeDeductibleChargeOnTopUp',
    },
    {
      label: 'enforceConsecutiveIncomeValidation',
      column: 'loanRegistrationEnforceConsecutiveIncomeValidation',
    },
    {
      label: 'permitMBankingOffsetting',
      column: 'permitBankToMobileOffsetting',
    },
    {
      label: 'validateMinimumsOnLoanRegistration',
      column: 'validateMinimumsOnLoanRegistration',
    },
    {
      label: 'chargeForInterestOffsetting',
      column: 'loanInterestChargeForOffsetting',
    },
    {
      label: 'disregardGracePeriodForInitialRecovery',
      column: 'disregardGracePeriodForInitialRecoveryDate',
    },
    {
      label: 'requireSecurityValidationOnRegistration',
      column: 'requireSecurityValidationOnRegistration',
    },
  ]

  const col2 = [
    {
      label: 'rejectIfOwing',
      column: 'loanRegistrationRejectIfMemberHasBalance',
    },
    {
      label: 'enableAutomatedInterestCapitalization',
      column: 'enableAutomatedInterestCapitalization',
    },
    { label: 'chargeArrearsFee', column: 'loanRegistrationChargeArrearsFee' },
    {
      label: 'createStandingOrderOnLoanVerification',
      column: 'loanRegistrationCreateStandingOrderOnLoanAudit',
    },
    {
      label: 'throttleMBankingLoanDisbursement',
      column: 'loanRegistrationThrottleMicroLoanDisbursement',
    },
    {
      label: 'evaluateStandingOrderhistoryForArrearagesRecovery',
      column:
        'loanRegistrationEvaluateStandingOrderHistoryForArrearagesRecovery',
    },
    {
      label: 'suppressFringeBenefitTaxComputation',
      column: 'suppressFringeBenefitTaxComputation',
    },
    {
      label: 'rebasePaymentDueDate',
      column: 'rebasePaymloanRegistrationRebasePaymentDueDateentDueDate',
    },
    {
      label: 'topupLoanAppraisalDeposits',
      column: 'loanRegistrationTopupLoanAppraisalDeposits',
    },
    {
      label: 'requireCustomerSpecimenImages',
      column: 'requireCustomerSpecimenImages',
    },
    {
      label: 'disregardGracePeriodForInterestCapitalization',
      column: 'disregardGracePeriodForInterestCapitalization',
    },
  ]

  const col3 = [
    {
      label: 'allowSelfGuarantee',
      column: 'loanRegistrationAllowSelfGuarantee',
    },
    {
      label: 'enforceSystemAppraisalRecommendation',
      column: 'loanRegistrationEnforceSystemAppraisalRecommendation',
    },
    {
      label: 'excludeOutstandingLoansOnMaximumEntitlement',
      column: 'loanRegistrationExcludeOutstandingLoansOnMaximumEntitlement',
    },
    { label: 'concealMBankingListing', column: 'concealBankToMobileListing' },
    {
      label: 'loaneeMustBorrowScoredMBankingLoanLimit',
      column: 'loanRegistrationLoaneeMustBorrowScoredLoanLimit',
    },
    {
      label: 'capitalizeInterestPerLoanCaseParameters',
      column: 'loanInterestCapitalizePerLoanCaseParameters',
    },
    {
      label: 'disregardDepositsBalanceForInvestementBasedLoanAppraisal',
      column:
        'loanRegistrationDisregardDepositsBalanceForInvestmentBasedLoanAppraisal',
    },
    { label: 'throttleLoanRestructuring', column: 'throttleLoanRestructuring' },
    {
      label: 'applyPrincipalLoanClearanceFees',
      column: 'loanRegistrationApplyPrincipalLoanClearanceFees',
    },
    {
      label: 'recoverArrearagesPerProductSection',
      column: 'recoverArrearagesPerProductSection',
    },
    {
      label: 'suppressPreOffsetableLoanDeselection',
      column: 'suppressPreattachedLoansDeselection',
    },
  ]

  const col4 = [
    {
      label: 'chargeClearanceFee',
      column: 'loanRegistrationChargeClearanceFee',
    },
    {
      label: 'disburseMBankingLoanLessDeductions',
      column: 'loanRegistrationDisburseMicroLoanLessDeductions',
    },
    {
      label: 'considerDepositsBalanceForIncomeBasedLoanAppraisal',
      column:
        'loanRegistrationConsiderInvestmentsBalanceForIncomeBasedLoanAppraisal',
    },
    {
      label: 'netOffDeductibleInvestmentBalance',
      column: 'netOffDeductibleInvestmentBalance',
    },
    {
      label: 'allowDiscretioinaryTopUp',
      column: 'loanRegistrationAllowDiscretionaryTopup',
    },
    {
      label: 'permitPartialOffsetting',
      column: 'loanRegistrationPermitPartialOffsetting',
    },
    {
      label: 'chargeDeductibleFee',
      column: 'loanRegistrationChargeDeductiblesFee',
    },
    { label: 'isDefaultersProduct', column: 'isDefaultersProduct' },
    {
      label: 'disregardEmployerLoanClassificationDate',
      column: 'disregardEmployerLoanClassificationDate',
    },
    {
      label: 'suppressProductSectionForOutstandingLoansBalance',
      column: 'suppressProductSectionForOutstandingLoansBalance',
    },
    {
      label: 'preloadAssessedLoaneeIncome',
      column: 'preloadAssessedLoaneeIncome',
    },
  ]

  useEffect(() => {
    reloadLoan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function reloadLoan() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-by-id',
      { loanProductId: loan?.id },
      false
    )
    if (success) {
      setLoanProduct(data?.loanProduct)
    }
    setIsBusy(false)
  }

  function checkFlag(column, value) {
    setLoanProduct({ ...loanProduct, [column]: value })
  }

  async function updateFlags() {
    setIsBusy(true)
    await putData(
      '/loanProduct/update-loan-product',
      loanProduct,
      true,
      'Loan Product Updated!'
    )
    reloadLoan()
    setIsBusy(false)
  }

  return loanProduct ? (
    <div style={{ position: 'relative' }}>
      <div className="row">
        <div className="col">
          {col1.map(({ label, column }) => (
            <div className="d-flex align-items-start gap-2 mb-2">
              <div style={{ marginTop: -4, marginRight: -4 }} title={column}>
                <Checkbox
                  id={`loan-${column}`}
                  checked={!!loanProduct[column]}
                  onCheck={v => checkFlag(column, v)}
                  disabled={disabled}
                />
              </div>
              <label
                htmlFor={`loan-${column}`}
                className={`text-capitalize ${
                  !!loanProduct[column] ? 'text-primary fw-bold' : 'fw-normal'
                }`}
                style={{ maxWidth: 200, height: 25 }}
              >
                {localize(label)}
              </label>
            </div>
          ))}
        </div>
        <div className="col">
          {col2.map(({ label, column }) => (
            <div className="d-flex align-items-start gap-2 mb-2" title={column}>
              <div style={{ marginTop: -4, marginRight: -4 }}>
                <Checkbox
                  id={`loan-${column}`}
                  checked={!!loanProduct[column]}
                  onCheck={v => checkFlag(column, v)}
                  disabled={disabled}
                />
              </div>
              <label
                htmlFor={`loan-${column}`}
                className={`text-capitalize ${
                  !!loanProduct[column] ? 'text-primary fw-bold' : 'fw-normal'
                }`}
                style={{ maxWidth: 200, height: 25 }}
              >
                {localize(label)}
              </label>
            </div>
          ))}
        </div>
        <div className="col">
          {col3.map(({ label, column }) => (
            <div className="d-flex align-items-start gap-2 mb-2" title={column}>
              <div style={{ marginTop: -4, marginRight: -4 }}>
                <Checkbox
                  id={`loan-${column}`}
                  checked={!!loanProduct[column]}
                  onCheck={v => checkFlag(column, v)}
                  disabled={disabled}
                />
              </div>
              <label
                htmlFor={`loan-${column}`}
                className={`text-capitalize ${
                  !!loanProduct[column] ? 'text-primary fw-bold' : 'fw-normal'
                }`}
                style={{ maxWidth: 200, height: 25 }}
              >
                {localize(label)}
              </label>
            </div>
          ))}
        </div>
        <div className="col">
          {col4.map(({ label, column }) => (
            <div className="d-flex align-items-start gap-2 mb-2">
              <div style={{ marginTop: -4, marginRight: -4 }} title={column}>
                <Checkbox
                  id={`loan-${column}`}
                  checked={!!loanProduct[column]}
                  onCheck={v => checkFlag(column, v)}
                  disabled={disabled}
                />
              </div>
              <label
                htmlFor={`loan-${column}`}
                className={`text-capitalize ${
                  !!loanProduct[column] ? 'text-primary fw-bold' : 'fw-normal'
                }`}
                style={{ maxWidth: 200, height: 25 }}
              >
                {localize(label)}
              </label>
            </div>
          ))}
        </div>
      </div>
      {disabled || (
        <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
          <button onClick={updateFlags} className="btn btn-success px-5">
            {localize('update')}
          </button>
        </div>
      )}
      {isBusy && <NonBlockingLoader />}
    </div>
  ) : (
    <>No Loan product</>
  )
}

export default LoanFlags
