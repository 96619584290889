/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../../../Helpers/extensions'
import Loader from '../../../../../Components/Loaders/Loader'

function AuxiliaryDisbursementFactors({ disabled, loan }) {
  const [isBusy, setIsBusy] = useState(false)
  const [auxFactors, setAuxFactors] = useState(null)
  const [factor, setFactor] = useState({
    dDay: 0,
    gPeriod: 0,
    cInterest: false,
  })

  useEffect(() => {
    loadFactors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadFactors() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-auxiliary-disbursement-factors-by-loan-product-id',
      { loanProductId: loan?.id },
      false
    )
    if (success) {
      setAuxFactors(data?.result)
    }
    setIsBusy(false)
  }

  async function removeFactor(id) {
    let facsLength = auxFactors.length
    let currentIndex = auxFactors.findIndex(f => f.id === id)
    if (currentIndex < facsLength - 1) {
      let res = await Swal.fire({
        title: 'Confirm',
        text:
          'Are you sure you want to remove this factor? This will also remove the ' +
          (facsLength - 1 - currentIndex) +
          ' factor(s) after it to maintain a valid sequence.',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
        icon: 'question',
      })
      if (res.isConfirmed) {
        let fs = [...auxFactors].slice(0, currentIndex)
        setAuxFactors(fs)
      }
    } else {
      let facs = [...auxFactors]
      facs = facs.filter(f => f.id !== id)
      setAuxFactors(facs)
    }
  }

  async function addFactor() {
    let lowerLimit =
      auxFactors.length > 0
        ? Number(auxFactors[auxFactors.length - 1].disbursementDayUpperLimit) +
          1
        : 0
    let upperLimit = factor?.dDay - 1
    if (lowerLimit >= upperLimit) {
      return Swal.fire('Error', 'Invalid Sequence', 'error')
    }
    let facs = [
      ...auxFactors.sort(
        (a, b) => a.disbursementDayUpperLimit - b.disbursementDayUpperLimit
      ),
    ]
    facs.push({
      ...factor,
      id: generateGuid(),
      disbursementDayLowerLimit: lowerLimit,
      disbursementDayUpperLimit: upperLimit,
      gracePeriod: factor?.gPeriod,
      capitalizeProratedInterest: factor?.cInterest,
    })
    setAuxFactors(facs)
    setFactor({ dDay: 0, gPeriod: 0, cInterest: false })
  }

  async function updateFactors() {
    setIsBusy(true)
    const { success } = await putData(
      `/loanProduct/update-loan-product-auxiliary-disbursement-factors-by-loan-product-id?loanProductId=${loan?.id}`,
      auxFactors,
      false,
      'Auxilliary Disbursement Factors Updated!'
    )
    if (success) {
      loadFactors()
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('disbursementDay') + ' (' + localize('lowerLimit') + ')',
      template: r => <>{r?.disbursementDayLowerLimit}</>,
    },
    {
      label: localize('disbursementDay') + ' (' + localize('upperLimit') + ')',
      template: r => <>{r?.disbursementDayUpperLimit}</>,
    },
    { label: localize('gracePeriod'), template: r => <>{r?.gracePeriod}</> },
    {
      label:
        localize('capitalizeInterest') + ' (' + localize('prorated') + ')?',
      template: r => (
        <span className="text-capitalize">
          {r?.capitalizeProratedInterest.toString()}
        </span>
      ),
    },
    {
      label:
        localize('recoverInterestUpfront') + ' (' + localize('prorated') + ')?',
      template: r => <span className="text-capitalize"></span>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={auxFactors} />
    </>
  ) : (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('disbursementDay')} ({localize('lowerLimit')})
                  </label>
                  <input
                    type="number"
                    value={factor?.dDay}
                    onChange={e =>
                      setFactor({ ...factor, dDay: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('gracePeriod')}
                  </label>
                  <input
                    type="number"
                    value={factor?.gPeriod}
                    onChange={e =>
                      setFactor({ ...factor, gPeriod: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light d-flex align-items-center justify-content-end p-2 mb-3">
            <div className="d-flex align-items-center me-3">
              <Checkbox
                id="capitalizeInterestEditLoan"
                checked={factor?.cInterest}
                onCheck={v => setFactor({ ...factor, cInterest: v })}
              />
              <label
                htmlFor="capitalizeInterestEditLoan"
                className="text-capitalize ms-2"
              >
                {localize('capitalizeInterest')} ({localize('prorated')})?
              </label>
            </div>
            <div className="d-flex align-items-center me-5">
              <Checkbox
                id="recoverInterestUpfrontEditLoan"
                checked={factor?.recInterest}
                onCheck={v => setFactor({ ...factor, recInterest: v })}
              />
              <label
                htmlFor="recoverInterestUpfrontEditLoan"
                className="text-capitalize ms-2"
              >
                {localize('recoverInterestUpfront')} ({localize('prorated')})?
              </label>
            </div>
            <button
              onClick={addFactor}
              className="btn btn-sm btn-success text-uppercase px-4"
            >
              {localize('add')}
            </button>
          </div>
          <SimpleTable
            columns={columns}
            data={auxFactors}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => removeFactor(r?.id)}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={updateFactors} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AuxiliaryDisbursementFactors

// AuxiliaryDisbursementFactors
