/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddFixedDepositTypeModal } from './AddFixedDepositTypeModal'
import { EditFixedDepositTypeModal } from './EditFixedDepositTypeModal'
import React, { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Tab from '../../../../Components/Tabs/Tab'
import { FixedDepositTypeTiersEntries } from './Partials/FixedDepositTypeTiersEntries'
import { FixedDepositTypeLevies } from './Partials/FixedDepositTypeLevies'
import { FixedDepositTypeOffsetableLoanProducts } from './Partials/FixedDepositTypeOffsetableLoanProducts'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function FixedDepositTypesModal({
  mode,
  onHideModal,
  selectedFixedDepositType,
  setSelectedFixedDepositType,
  onUpdateRefreshAt,
  waitingMetadata,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addFixedDepositType()
    }
    if (mode === 'edit') {
      return editFixedDepositType()
    }
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  const addFixedDepositType = async () => {
    setIsBusy(true)

    const { success, data } = await postData(
      '/FixedDepositType/add-fixed-deposit-type-with-products',
      {
        FixedDepositType: { ...(selectedFixedDepositType || {}) },
        FixedDepositTypeGraduatedScales:
          selectedFixedDepositType.fixedDepositTypeGraduatedScales || [],
        Levies: selectedFixedDepositType.levies.filter(x => x.checked) || [],
        ProductCollectionInfo: {
          LoanProductCollection:
            selectedFixedDepositType.loanProducts.filter(x => x.checked) || [],
        },
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Fixed Deposit Type',
        'Operation Completed Successfully',
        'success',
      )
    }

    setIsBusy(false)
  }

  const editFixedDepositType = async () => {
    setIsBusy(true)

    const { success, data } = await postData(
      '/FixedDepositType/update-fixed-deposit-type-with-products',
      {
        FixedDepositType: { ...(selectedFixedDepositType || {}) },
        FixedDepositTypeGraduatedScales:
          selectedFixedDepositType.fixedDepositTypeGraduatedScales || [],
        Levies: selectedFixedDepositType.levies.filter(x => x.checked) || [],
        ProductCollectionInfo: {
          LoanProductCollection:
            selectedFixedDepositType.loanProducts.filter(x => x.checked) || [],
        },
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Fixed Deposit Type',
        'Operation Completed Successfully',
        'success',
      )
    }

    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('fixedDepositTypes')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disableCreate={isBusy}
            hideUpdate={mode === 'view'}
            footerChildren={
              mode !== 'view' && (
                <div className="d-flex align-content-end justify-content-end">
                  <div className="d-flex align-items-center me-5">
                    <Checkbox
                      id={'isLocked'}
                      checked={selectedFixedDepositType?.isLocked}
                      onCheck={v =>
                        setSelectedFixedDepositType({
                          ...selectedFixedDepositType,
                          isLocked: v,
                        })
                      }
                    />
                    <label htmlFor="isLocked" className="text-capitalize ms-2">
                      {localize('isLocked')}?
                    </label>
                  </div>
                </div>
              )
            }
          >
            {mode === 'add' && (
              <AddFixedDepositTypeModal
                isBusy={isBusy}
                setSelectedFixedDepositType={setSelectedFixedDepositType}
                selectedFixedDepositType={selectedFixedDepositType}
              />
            )}
            {mode === 'edit' && (
              <EditFixedDepositTypeModal
                isBusy={isBusy}
                setSelectedFixedDepositType={setSelectedFixedDepositType}
                selectedFixedDepositType={selectedFixedDepositType}
              />
            )}
            {mode === 'view' && (
              <>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center with-validation">
                    <label className="col-1 text-capitalize">
                      {localize('name')}
                    </label>
                    <input
                      className="form-control"
                      value={selectedFixedDepositType?.description}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('months')}
                    </label>
                    <input
                      value={selectedFixedDepositType?.months}
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('advanceRate')}
                    </label>
                    <input
                      value={selectedFixedDepositType?.advanceRate}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('glAccount')}
                    </label>
                    <input
                      className="form-control"
                      value={
                        selectedFixedDepositType?.chartOfAccountAccountName
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('interestExpenseGlAccount')}
                    </label>
                    <input
                      value={
                        selectedFixedDepositType?.interestExpenseChartOfAccountAccountName
                      }
                      className={'form-control'}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <Tab
                      tabItems={[
                        {
                          label: localize('tiers'),
                          item: (
                            <FixedDepositTypeTiersEntries
                              waiting={waitingMetadata}
                              selectedFixedDepositType={
                                selectedFixedDepositType
                              }
                              setSelectedFixedDepositType={
                                setSelectedFixedDepositType
                              }
                            />
                          ),
                        },
                        {
                          label: localize('levies'),
                          item: (
                            <FixedDepositTypeLevies
                              waiting={waitingMetadata}
                              disabled
                              prefix={'list-levies'}
                              selectedFixedDepositType={
                                selectedFixedDepositType
                              }
                              setSelectedFixedDepositType={
                                setSelectedFixedDepositType
                              }
                            />
                          ),
                        },
                        {
                          label: localize('offsetableLoanProducts'),
                          item: (
                            <FixedDepositTypeOffsetableLoanProducts
                              disabled
                              waiting={waitingMetadata}
                              prefix={'loans'}
                              selectedFixedDepositType={
                                selectedFixedDepositType
                              }
                              setSelectedFixedDepositType={
                                setSelectedFixedDepositType
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default FixedDepositTypesModal
