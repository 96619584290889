/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
const FuneralRiderClaimsLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('funeralRiderClaimLookup')}
      url="/lookup/FuneralRiderClaim/find-funeral-rider-claims-by-status-and-filter-in-page"
      params={{ status: 0, text: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        {
          label: localize('status'),
          template: r => <>{r.statusDescription}</>,
          sortBy: 'statusDescription',
        },
        {
          label: localize('claimType'),
          template: r => <>{r.claimTypeDescription}</>,
          sortBy: 'claimTypeDescription',
        },

        {
          label: localize('customerName'),
          template: r => <>{r.customerFullName}</>,
          sortBy: 'customerFullName',
        },
        {
          label: localize('identityNumber'),
          template: r => <>{r.customerIndividualIdentityCardNumber}</>,
          sortBy: 'customerIndividualIdentityCardNumber',
        },
        {
          label: localize('account'),
          template: r => <>{r.customerReference1}</>,
          sortBy: 'customerReference1',
        },
        {
          label: localize('membership'),
          template: r => <>{r.customerReference2}</>,
          sortBy: 'customerReference2',
        },
        {
          label: localize('personalFile'),
          template: r => <>{r.customerReference3}</>,
          sortBy: 'customerReference3',
        },
        {
          label: localize('pfNumber'),
          template: r => <>{r.funeralRiderClaimantPersonalFileNumber}</>,
          sortBy: 'funeralRiderClaimantPersonalFileNumber',
        },
        {
          label: localize('identityNumber'),
          template: r => <>{r.customerIndividualIdentityCardNumber}</>,
          sortBy: 'customerIndividualIdentityCardNumber',
        },
        {
          label: localize('name'),
          template: r => <>{r.funeralRiderClaimantName}</>,
          sortBy: 'funeralRiderClaimantName',
        },
        {
          label: localize('mobileNumber'),
          template: r => <>{r.funeralRiderClaimantMobileNumber}</>,
          sortBy: 'funeralRiderClaimantMobileNumber',
        },
        {
          label: localize('relationship'),
          template: r => <>{r.funeralRiderClaimantRelationship}</>,
          sortBy: 'funeralRiderClaimantRelationship',
        },
        {
          label: localize('signatureDate'),
          template: r => <>{formatDate(r.funeralRiderClaimantSignatureDate)}</>,
          sortBy: 'funeralRiderClaimantSignatureDate',
        },
        {
          label: localize('immediateSuperiorIdNumber'),
          template: r => <>{r.immediateSuperiorIdentityCardNumber}</>,
          sortBy: 'immediateSuperiorIdentityCardNumber',
        },
        {
          label: localize('immediateSuperiorName'),
          template: r => <>{r.immediateSuperiorName}</>,
          sortBy: 'immediateSuperiorName',
        },
        {
          label: localize('immediateSuperiorSignatureDate'),
          template: r => <>{formatDate(r.immediateSuperiorSignatureDate)}</>,
          sortBy: 'immediateSuperiorSignatureDate',
        },
        {
          label: localize('areaChiefName'),
          template: r => <>{r.areaChiefName}</>,
          sortBy: 'areaChiefName',
        },
        {
          label: localize('areaChiefIdentityCardNumber'),
          template: r => <>{r.areaChiefIdentityCardNumber}</>,
          sortBy: 'areaChiefIdentityCardNumber',
        },
        {
          label: localize('areaChiefSignatureDate'),
          template: r => <>{formatDate(r.areaChiefSignatureDate)}</>,
          sortBy: 'areaChiefSignatureDate',
        },
        {
          label: localize('areaChiefDelegateName'),
          template: r => <>{r.areaDelegateName}</>,
          sortBy: 'areaDelegateName',
        },
        {
          label: localize('areaChiefDelegateIdNumber'),
          template: r => <>{r.areaDelegateIdentityCardNumber}</>,
          sortBy: 'areaDelegateIdentityCardNumber',
        },
        {
          label: localize('areaChiefDelegatePfNumber'),
          template: r => <>{r.areaDelegatePersonalFileNumber}</>,
          sortBy: 'areaDelegatePersonalFileNumber',
        },
        {
          label: localize('areaChiefDelegateSignatureDate'),
          template: r => <>{formatDate(r.areaDelegateSignatureDate)}</>,
          sortBy: 'areaDelegateSignatureDate',
        },
        {
          label: localize('areaBoardMemberName'),
          template: r => <>{r.areaBoardMemberName}</>,
          sortBy: 'areaBoardMemberName',
        },
        {
          label: localize('areaBoardMemberIdNumber'),
          template: r => <>{r.areaBoardMemberIdentityCardNumber}</>,
          sortBy: 'areaBoardMemberIdentityCardNumber',
        },
        {
          label: localize('areaBoardMemberPfNumber'),
          template: r => <>{r.areaBoardMemberPersonalFileNumber}</>,
          sortBy: 'areaBoardMemberPersonalFileNumber',
        },
        {
          label: localize('areaBoardMemberSignatureDate'),
          template: r => <>{formatDate(r.areaBoardMemberSignatureDate)}</>,
          sortBy: 'areaBoardMemberSignatureDate',
        },
        {
          label: localize('claimDate'),
          template: r => <>{formatDate(r.claimDate)}</>,
          sortBy: 'claimDate',
        },
        {
          label: localize('dateOfDeath'),
          template: r => <>{formatDate(r.dateOfDeath)}</>,
          sortBy: 'dateOfDeath',
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
          sortBy: 'createdBy',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
          sortBy: 'createdDate',
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default FuneralRiderClaimsLookup
