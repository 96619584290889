/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import CreditBatchDiscrepancyLookup from '../../../../../../Components/Lookup/CreditBatchDiscrepancyLookup'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import {
  CreditBatchType,
  apportionTo,
  batchEntryStatus,
  batchStatus,
  creditDiscrepancyAuthOption,
} from '../../../../../../Global/enumeration'
import {
  formatCurrency,
  getTodaysDate,
  parseDate,
} from '../../../../../../Helpers/formatters'
import { putData } from '../../../../../../Helpers/webApi'
import AppointmentTab from './AppointmentTab/Apportionment'
import { useEffect } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'

function MatchingSingly() {
  const [isBusy, setIsBusy] = useState(false)
  const [values, setValues] = useState({
    valueDate: parseDate(getTodaysDate()),
    apportionTo: Object.keys(apportionTo)[0],
    creditDiscrepancyAuthOption: Object.keys(creditDiscrepancyAuthOption)[0],
  })
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)

  const handleSelectBatch = batch => {
    if (batch?.creditBatchStatus !== Object.keys(batchStatus)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Batch must be in posted status',
      })

    if (batch?.status !== Object.keys(batchEntryStatus)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Status  must be in pending status',
      })

    const getCreditBatchType = batch => {
      if (
        batch?.creditBatchType === Object.keys(CreditBatchType)[0] ||
        batch?.creditBatchType === Object.keys(CreditBatchType)[1] ||
        batch?.creditBatchType === Object.keys(CreditBatchType)[4]
      )
        return true
      else return false
    }

    if (!getCreditBatchType(batch))
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Batch type must be in payout, checkoff or save as you earn',
      })

    setValues({
      ...values,
      ...batch,
      creditBatchDiscrepancyId: batch?.id,
    })
  }

  const getUrl = () => {
    if (values?.apportionTo === Object.keys(apportionTo)[0])
      return `/CreditBatch/match-credit-batch-discrepancy-by-customer-account`
    else
      return '/CreditBatch/match-credit-batch-discrepancy-by-general-ledger-account'
  }

  const getParams = () => {
    if (values?.apportionTo === Object.keys(apportionTo)[0])
      return {
        creditBatchDiscrepancyId: values?.creditBatchDiscrepancyId,
        customerAccountDTO: values?.customer,
        discrepancyAuthOption: values?.creditDiscrepancyAuthOption,
      }
    else
      return {
        creditBatchDiscrepancy: values,
        chartOfAccountId: values?.chartOfAccountId,
        moduleNavigationItemCode: 1000,
        discrepancyAuthOption: values?.creditDiscrepancyAuthOption,
      }
  }

  const handleMatchingSingly = async () => {
    setIsBusy(true)
    if (!values?.creditBatchDiscrepancyId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a credit batch discrepancy to match',
      })

    const url = getUrl()

    const { success } = await putData(
      url,
      getParams(),
      false,
      'Credit Batch Discrepancy matched successfully.'
    )

    if (success) {
      setIsBusy(false)
      setValues({
        valueDate: parseDate(getTodaysDate()),
        apportionTo: Object.keys(apportionTo)[0],
        creditDiscrepancyAuthOption: Object.keys(
          creditDiscrepancyAuthOption
        )[0],
      })
    }
  }

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, false)
    if (success && data.result) {
      setIsBusy(false)
      setUpdateDate(false)
      setValues({
        ...values,
        valueDate: parseDate(valueDate),
      })
    } else {
      setIsBusy(false)
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  useEffect(() => {
    if (valueDate) evaluateValueDate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('recurringProcedures'),
          `${localize('discrepancyMatching')} - ${localize('singly')}`,
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3 mb-4">
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchNumber"
                    >
                      {localize('batchNumber')}
                    </label>
                    <CreditBatchDiscrepancyLookup
                      displayValue={values?.paddedCreditBatchBatchNumber}
                      onSelect={handleSelectBatch}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchType"
                    >
                      {localize('batchType')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchType"
                      value={values?.creditBatchTypeDescription}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchFileType"
                    >
                      {localize('batchFileType')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchFileType"
                      value={
                        values?.creditBatchCreditTypeBatchFileTypeDescription
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchTotalValue"
                    >
                      {localize('batchTotalValue')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      id="batchTotalValue"
                      value={formatCurrency(
                        values?.creditBatchTotalValue,
                        null,
                        null,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchMonth"
                    >
                      {localize('batchMonth')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchMonth"
                      value={values?.creditBatchMonthDescription}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="batchReference"
                    >
                      {localize('batchReference')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="batchReference"
                      value={values?.creditBatchReference}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column1">
                      {localize('column1')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column1"
                      value={values?.column1}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column2">
                      {localize('column2')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column2"
                      value={values?.column2}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column3">
                      {localize('column3')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column3"
                      value={values?.column3}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column4">
                      {localize('column4')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column4"
                      value={values?.column4}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column5">
                      {localize('column5')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column5"
                      value={values?.column5}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column6">
                      {localize('column6')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column6"
                      value={values?.column6}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column7">
                      {localize('column7')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column7"
                      value={values?.column7}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column8">
                      {localize('column8')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column8"
                      value={values?.column8}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column9">
                      {localize('column9')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column9"
                      value={values?.column9}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column10">
                      {localize('column10')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column10"
                      value={values?.column10}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column11">
                      {localize('column11')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column11"
                      value={values?.column11}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column12">
                      {localize('column12')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column12"
                      value={values?.column12}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column13">
                      {localize('column13')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column13"
                      value={values?.column13}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="column14">
                      {localize('column14')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="column14"
                      value={values?.column14}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4" htmlFor="remarks">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="remarks"
                      value={values?.remarks}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="valueDate"
                    >
                      {localize('valueDate')}
                    </label>
                    <CustomDatePicker
                      name="matchingSinglyValueDate"
                      defaultDate={valueDate}
                      onDateChange={date => {
                        setValueDate(date)
                      }}
                      setDateToNull={updateDate}
                      setUpdateDate={setUpdateDate}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="productCode"
                    >
                      {localize('productCode')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="productCode"
                      value={values?.productCodeDescription}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-4"
                      htmlFor="targetProduct"
                    >
                      {localize('targetProduct')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="targetProduct"
                      value={values?.targetProductDescription}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <Tab
                tabItems={[
                  {
                    label: localize('appointmentCustomerAccount/GLAccount'),
                    item: (
                      <AppointmentTab values={values} setValues={setValues} />
                    ),
                  },
                ]}
              />
              <div className="row mt-3 mb-light">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-end gap-4">
                    <div className="d-flex align-items-center">
                      <label className="text-capitalize">
                        {localize('authOption')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CreditDiscrepancyAuthOption"
                        value={values?.creditDiscrepancyAuthOption}
                        onChange={e =>
                          setValues({
                            ...values,
                            creditDiscrepancyAuthOption: e.value,
                          })
                        }
                      />
                    </div>

                    <button
                      className="btn btn-success"
                      onClick={handleMatchingSingly}
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MatchingSingly
