/** @format */

import { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import ViewExternalCheque from '../Partials/ViewExternalCheque/ViewExternalCheque'

const ExternalChequeCatalogue = () => {
  const [cheques, setCheques] = useState([])
  const [cheque, setCheque] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const columns = [
    {
      label: localize('branch'),
      template: item => <>{item?.branchDescription}</>,
    },

    {
      label: localize('chequeType'),
      template: item => <>{item?.chequeTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: item => <>{item?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: item => <>{item?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('chequeNumber'),
      template: item => <>{item?.number}</>,
    },
    {
      label: localize('amount'),
      template: item => <>{formatCurrency(item?.amount)}</>,
    },
    {
      label: localize('drawer'),
      template: item => <>{item?.drawer}</>,
    },
    {
      label: localize('drawerBank'),
      template: item => <>{item?.drawerBank}</>,
    },
    {
      label: localize('drawerBankBranch'),
      template: item => <>{item?.drawerBankBranch}</>,
    },
    {
      label: localize('writeDate'),
      template: item => <>{formatDate(item?.writeDate, true)}</>,
    },
    {
      label: localize('maturityDate'),
      template: item => <>{formatDate(item?.maturityDate, true)}</>,
    },
    {
      label: localize('tellerName'),
      template: item => <>{item?.tellerDescription}</>,
    },
    {
      label: localize('isTransferred') + ' ?',
      template: item => <>{item?.isTransferred?.toString()}</>,
    },
    {
      label: localize('transferredBy'),
      template: item => <>{item?.transferredBy}</>,
    },
    {
      label: localize('transferredDate'),
      template: item => <>{formatDate(item?.transferredDate, true)}</>,
    },
    {
      label: localize('isBanked') + ' ?',
      template: item => <>{item?.isBanked?.toString()}</>,
    },
    {
      label: localize('bankedBy'),
      template: item => <>{item?.bankedBy}</>,
    },
    {
      label: localize('bankedDate'),
      template: item => <>{formatDate(item?.bankedDate, true)}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setCheque({})
    setMode(null)
  }

  const handleSelectCheque = (r, m) => {
    setMode(m)
    setCheque(r)
  }

  const fetchCheques = async () => {
    setIsBusy(true)

    const url =
      '/ExternalCheque/find-external-cheques-by-date-range-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCheques(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchCheques()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'view' && (
        <ViewExternalCheque
          mode={mode}
          cheque={cheque}
          handleClose={handleClose}
          hiddenColumns={[0, 5, 7, 8, 9]}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('external'),
          localize('externalCheques'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex row align-items-center justify-content-between mb-3 mt-3 gap-1">
                <div className="d-flex align-items-center col-md-4">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label htmlFor="search" className="col-2 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div
                  className="col-md-5 d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCheques()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCheques()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                data={cheques?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectCheque(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      {localize('view')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={cheques.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ExternalChequeCatalogue
