/** @format */

import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import React, { useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'

export const CompanyFlags = ({ selectedCompany, setSelectedCompany, mode }) => {
  const fetchCompanyFlags = async () => {
    const { success, data } = await getData(
      '/Company/find-company-flags',
      {},
      false,
    )
    if (success) {
      setSelectedCompany({ ...selectedCompany, companyFlags: data })
    }
  }

  useEffect(() => {
    fetchCompanyFlags()
  }, [])

  const handleCheckboxChange = (i, r) => {
    let allFlags = selectedCompany.companyFlags.map((flag, index) => {
      if (index === i) {
        return { ...flag, checked: r }
      }
      return flag
    })

    setSelectedCompany({
      ...selectedCompany,
      companyFlags: allFlags,
    })
  }

  return (
    <>
      <div
        className="row mt-2"
        style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        {(selectedCompany?.companyFlags || [])
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((t, i) => (
            <div
              className="d-flex align-items-center col-3"
              key={`${t.value}-${i}`}
            >
              <Checkbox
                id={`${mode}-edit-${t.value}`}
                checked={t.checked}
                onCheck={r => {
                  handleCheckboxChange(i, r)
                }}
              />
              <label
                htmlFor={`${mode}-edit-${t.value}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  t.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {t.label}
              </label>
            </div>
          ))}
      </div>
    </>
  )
}
