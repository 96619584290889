/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from './../../../../Components/Tables/SimpleTable'
import CreateInventoryPackageType from './Partials/CreateInventoryPackageType'

const InventoryPackageTypes = () => {
  const [inventoryPackage, setInventoryPackage] = useState([])
  const [isBusy, setIsBusy] = useState(true)
  const [mode, setMode] = useState('')
  const [tableMeta, setTableMeta] = useState({})
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
  })

  useEffect(() => {
    fetchInventoryPackage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, true)}</>,
    },
  ]

  async function fetchInventoryPackage() {
    setIsBusy(true)
    const { data, success } = await getData(
      '/InventoryPackageType/find-inventory-package-types-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }
  const handleClose = () => {
    setMode('')
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreateInventoryPackageType
          modalTitle={
            mode === 'add'
              ? localize('createInventoryPackage')
              : localize('updateInventoryPackage')
          }
          modalMode={mode}
          setMode={setMode}
          mode={mode}
          loadTableData={fetchInventoryPackage}
          handleClose={handleClose}
          inventoryPackage={inventoryPackage}
          setInventoryPackage={setInventoryPackage}
        />
      )}
      <BreadCrumb
        tree={[
          localize('procurementAndAssetManagement'),
          localize('inventoryPackageType'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-start mb-3">
            {<AddNewButton handleClickAddButton={() => setMode('add')} />}
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row justify-content-between mb-3">
                <div className="d-flex col-6 align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <form
                  className="col-3 d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setRefresh(!refresh)
                  }}
                >
                  <label htmlFor="searchTable">Search</label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2 me-2"
                    defaultValue={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <button
                    id="supplyCategorySearch"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row">
                <SimpleTable
                  columns={columns}
                  contextMenu={r => (
                    <>
                      <div
                        onClick={async () => {
                          setMode('edit')
                          setInventoryPackage(r)
                        }}
                        className="ctxt-menu-item text-capitalize"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                    </>
                  )}
                  data={tableData}
                />
              </div>
              <div className="row mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InventoryPackageTypes
