/** @format */

import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData, postData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import LoanRescheduleRequestLookup from '../../../../../Components/Lookup/LoanRescheduleRequestLookup'
import { generateGuid } from '../../../../../Helpers/extensions'
import DynamicCharges from '../../../../../Components/Selectables/Plain/DynamicCharges'
import LoanRescheduleCustomerByIdLookup from '../../../../../Components/Lookup/LoanRescheduleCustomerByIdLookup'
import { CustomerFilter, ProductCode } from '../../../../../Global/enumeration'
import {
  formatDate,
  formatCurrency,
  getTodaysDate,
  formatAsPercent,
} from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

const Approval = () => {
  const [approval, setApproval] = useState({})
  const [customerData, setCustomerData] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})
  const [customer, setCustomer] = useState({})
  const [isDisabled, setIsDisabled] = useState(true)
  const [isBusy, setIsBusy] = useState(false)
  const [createParams, setCreateParams] = useState({})
  const [repaymentRescheduleTableData, setRepaymentScheduleTableData] =
    useState({})
  const [dynamicChargesToBeSelected, setDynamicChargesToBeSelected] = useState(
    []
  )
  const [reqParams, setReqParams] = useState({
    startDate: '',
    endDate: '',
    status: 0,
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[1],
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 15,
  })

  const getDynamicCharges = async () => {
    setIsBusy(true)
    const url = '/DynamicCharge/find-dynamic-charges'
    const { success, data } = await getData(url, null, false)

    if (success) {
      setIsBusy(false)
      const charges = data?.result.sort((a, b) =>
        a.description?.localeCompare(b.description)
      )

      setDynamicChargesToBeSelected(charges)
    } else {
      setIsBusy(false)
    }
  }

  const handleCheckDynamicCharge = updatedDynamicCharges => {
    setDynamicChargesToBeSelected(updatedDynamicCharges)
  }

  useEffect(() => {
    getDynamicCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchRepaymentScheduleData = async repaymentScheduleParams => {
    setIsBusy(true)
    const url = '/Journal/repayment-schedule'

    const { data, success } = await postData(
      url,
      { repaymentScheduleParams },
      false
    )

    if (success) {
      setIsBusy(false)
      setRepaymentScheduleTableData(
        data?.result.map(d => ({ ...d, id: generateGuid() }))
      )
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    handleFetchRepaymentScheduleData(customerAccount)
  }, [customerAccount, customerData])

  const loadCustomerData = async customerParams => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account',
      customerParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setCustomerData(data)
      setCustomerAccount({
        termInMonths: approval?.numberOfPeriods,
        paymentFrequencyPerYear:
          data.loanCaseLoanRegistrationPaymentFrequencyPerYear,
        customerData: approval?.customerData,
        interestCalculationMode: data?.loanCaseLoanInterestCalculationMode,
        roundingType:
          customerData?.customerAccountTypeTargetProductRoundingType,
        apr: data?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate,
        pv: data?.principalBalance,
        dtDate: getTodaysDate(),
        rebaseDueDate: true,
      })
      setCustomer({
        id: data?.customerId,
        accountName: data?.accountName,
      })
    } else {
      setIsBusy(false)
    }
  }

  const handleCreateApproval = async () => {
    setIsBusy(true)
    const url = '/LoanRescheduleRequest/authorize-loan-reschedule-request'

    const { success } = await postData(
      url,

      { createParams },
      false,
      'Loan approval completed successfully.'
    )

    if (success) {
      setIsBusy(true)
      setApproval({})
      setCreateParams({})
      setCustomerAccount({})
      setCustomerData({})
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectCustomerAccount = async x => {
    setApproval(x)
    const customerParams = {
      customerAccountId: x?.customerAccountId,
      includeBalances: true,
      includeProductDescription: true,
    }
    await loadCustomerData(customerParams)
    setReqParams({
      ...reqParams,
      id: x?.id,
    })
    setCreateParams({
      ...createParams,
      loanRescheduleRequest: x,
    })
    setIsDisabled(false)
  }

  const handleSelectBenefactorAccount = e => {
    if (e?.customerAccountTypeProductCode !== Object.keys(ProductCode)[0]) {
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a valid benefactor account!',
      })
    } else {
      setCustomerAccount(e)
      setCreateParams({
        ...createParams,
        benefactorCustomerAccountDTO: e,
      })
    }
  }

  const columns = [
    {
      label: localize('period'),
      template: r => <>{r?.period}</>,
    },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    {
      label: localize('startingBalance'),
      template: r => <>{formatCurrency(r?.startingBalance)}</>,
    },
    {
      label: localize('payment'),
      template: r => <>{formatCurrency(r?.payment)}</>,
    },
    {
      label: localize('interestPayment'),
      template: r => <>{formatCurrency(r?.interestPayment)}</>,
    },
    {
      label: localize('principalPayment'),
      template: r => <>{formatCurrency(r?.principalPayment)}</>,
    },
    {
      label: localize('endingBalance'),
      template: r => <>{formatCurrency(r?.endingBalance)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('rescheduling details'),
      item: (
        <div className="p-2 mb-4">
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('loanProduct')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.customerAccountTypeTargetProductDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('interestCalculationMode')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.loanCaseLoanInterestCalculationModeDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('annualPercentageRate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatAsPercent(
                    customerData?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate
                  )}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('paymentFrequencyPerYear')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('paymentPerPeriod')}
                </label>
                <input
                  type="number"
                  className="form-control text-end"
                  value={approval?.paymentPerPeriod}
                  disabled
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('numberofPeriods')}
                </label>
                <input
                  type="number"
                  className="form-control"
                  disabled
                  value={approval?.numberOfPeriods}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={approval?.reference}
                  disabled
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.createdBy}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.createdDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.statusDescription}
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('verifiedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.auditedBy}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('verifiedDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.auditedDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('verificationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={approval?.auditRemarks}
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row g-3 mb-4 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="benefactorAccount#"
                  className="text-capitalize col-6"
                >
                  {localize('benefactorAccount#')}
                </label>
                <LoanRescheduleCustomerByIdLookup
                  disabled={isDisabled}
                  selectedCustomer={customer}
                  onSelect={e => {
                    handleSelectBenefactorAccount(e)
                  }}
                  displayValue={customerAccount?.fullAccountNumber}
                  customer={customerData?.customerFullName}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('accountStatus')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerAccount?.statusDescription}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('accountRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerAccount?.remarks}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('productName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerAccount?.customerAccountTypeTargetProductDescription
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="mt-2 row"
            style={{
              position: 'relative',
              border: 'solid 1px #0000001f',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <div
              className="bg-white row px-2 text-capitalize mb-4"
              style={{
                position: 'absolute',
                top: '-8px',
                left: '30px',
                maxWidth: 'fit-content',
              }}
            >
              {localize('newRepaymentSchedule')}
            </div>
            <SimpleTable
              columns={columns}
              className="mt-4"
              data={repaymentRescheduleTableData || []}
            />
          </div>
        </div>
      ),
    },
    {
      label: localize('indefiniteCharges'),
      item: (
        <DynamicCharges
          prefix={'loan-reschedule-request-indefinite-charges'}
          dynamicChargesToBeSelected={dynamicChargesToBeSelected}
          onCheckDynamicCharge={x => {
            handleCheckDynamicCharge(x)
            setCreateParams({
              ...createParams,
              dynamicCharges: dynamicChargesToBeSelected,
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanRestructuring'),
          localize('approval'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3 mb-2 d-flex align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="fullAccountNumber"
                      className="text-capitalize col-6"
                    >
                      {localize('fullAccountNumber')}
                    </label>
                    <LoanRescheduleRequestLookup
                      displayValue={approval.fullAccountNumber}
                      statusFilter={8}
                      onSelect={async x => {
                        await handleSelectCustomerAccount(x)
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountStatus')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerAccountStatusDescription}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountRemarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerAccountRemarks}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('productName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        customerData?.customerAccountTypeTargetProductDescription
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-2 d-flex align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerFullName}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        approval?.customerAccountCustomerMembershipClassCustomerTypeDescription
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerSerial#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerAccountCustomerSerialNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('employerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={customerData?.customerEmployerDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-2 d-flex align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('identification#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        approval?.customerAccountCustomerIndividualIdentityCardNumber
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('memberNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerAccountCustomerMemberNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={approval?.customerAccountCustomerMemberNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        approval?.customerAccountCustomerPersonalFileNumber
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-4">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('bookBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(customerData?.bookBalance)}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('principalBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(customerData?.principalBalance)}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('interestBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(customerData?.interestBalance)}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('loanClassification')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={customerData?.loanClassificationDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <Tab preload tabItems={tabItems} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        authorizationRemarks: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="LoanRescheduleRequestProcessingOption"
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center ms-2">
                  <button
                    onClick={handleCreateApproval}
                    className="btn btn-success waves-effect waves-light text-capitalize"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    {localize('update')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Approval
