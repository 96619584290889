/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../Global/Localize'

import './pager.scss'
import { formatCurrency, numberWithCommas } from '../../Helpers/formatters'

function Pager({
  itemsCount,
  pageSize,
  pageIndex,
  onPageChange,
  pageBatchSize,
}) {
  const totalPages = Math.ceil(itemsCount / pageSize)
  const maxPagesArray = pageBatchSize || 5
  const [batch, setBatch] = useState([])
  const [batchIndex, setBatchIndex] = useState(0)
  const [pArray, setPArray] = useState([])

  useEffect(() => {
    const pagesArray = [],
      pagesStem = []
    for (let i = 0; i < totalPages; i += maxPagesArray) {
      pagesStem.push(i)
    }

    for (let p of pagesStem) {
      const pagesBatch = []
      const lastPage =
        p + maxPagesArray > totalPages ? totalPages : maxPagesArray + p
      for (let i = p; i < lastPage; i++) {
        pagesBatch.push(i)
      }
      pagesArray.push(pagesBatch)
    }

    setPArray(pagesArray)

    const batch = pagesArray.filter((batch, i) => {
      let len = batch.filter(p => p === pageIndex)?.length
      const isInside = len > 0
      if (isInside) {
        setBatchIndex(i)
        return true
      } else {
        return false
      }
    })

    setBatch(batch[0])
    // eslint-disable-next-line
  }, [pageIndex, pageSize, itemsCount])

  return itemsCount > 0 ? (
    <div className="d-flex align-items-center justify-content-between rounded p-2 cent-pager gap-3">
      <div className="pager-text">
        {localize('showing')}{' '}
        <strong>{numberWithCommas(pageSize * pageIndex + 1)}</strong>{' '}
        {localize('to')}{' '}
        <strong>
          {numberWithCommas(
            pageSize * (pageIndex + 1) > itemsCount
              ? itemsCount
              : pageSize * (pageIndex + 1)
          )}
        </strong>{' '}
        {localize('of')} <strong>{numberWithCommas(itemsCount)}</strong>{' '}
        {localize('itemsOnPage')}
      </div>

      <div className="d-flex align-items-center gap-2 pager-buttons">
        <div
          className="pager-fixed-button"
          onClick={() => {
            setBatch(pArray[0])
            setBatchIndex(0)
            onPageChange(0)
          }}
          style={{
            listStyle: 'none',
          }}
        >
          <span>
            <i className="uil uil-angle-double-left"></i>
          </span>
        </div>
        <div
          className="pager-fixed-button"
          style={{ display: 'none' }}
          onClick={() => {
            let prev = pArray[batchIndex - 1]
            if (prev) {
              setBatchIndex(batchIndex - 1)
              setBatch(pArray[batchIndex - 1])
            }
          }}
        >
          <span>
            <i className="uil uil-angle-left"></i>
          </span>
        </div>
        <div
          className="pager-fixed-button"
          style={{ listStyle: 'none' }}
          onClick={() => {
            pageIndex > 0
              ? onPageChange(pageIndex - 1)
              : Swal.fire({
                  icon: 'info',
                  text: 'This is the First Page',
                })
          }}
        >
          <span>
            <i className="uil uil-angle-left"></i>
          </span>
        </div>

        {batch &&
          batch.map(p => (
            <div
              style={{ listStyle: 'none' }}
              className={`pager-nav-button ${pageIndex === p ? 'active' : ''}`}
              onClick={() => onPageChange(p)}
              key={p}
            >
              <span>{numberWithCommas(p + 1)}</span>
            </div>
          ))}
        <div
          className="pager-fixed-button"
          style={{ listStyle: 'none' }}
          onClick={() => {
            if (pArray?.length === 0) return

            let lastBatch = pArray[pArray?.length - 1]
            let lastPage = lastBatch[lastBatch?.length - 1]
            pageIndex < lastPage
              ? onPageChange(pageIndex + 1)
              : Swal.fire({
                  icon: 'info',
                  text: 'This is the Last Page',
                })
          }}
        >
          <span>
            <i className="uil uil-angle-right"></i>
          </span>
        </div>

        <div
          className="pager-fixed-button"
          style={{ display: 'none', listStyle: 'none' }}
          onClick={() => {
            let next = pArray[batchIndex + 1]
            if (next) {
              setBatch(pArray[batchIndex + 1])
              setBatchIndex(batchIndex + 1)
            }
          }}
        >
          <span className="page-link">
            <i className="uil uil-angle-right"></i>
          </span>
        </div>
        <div
          className="pager-fixed-button"
          style={{ listStyle: 'none' }}
          onClick={() => {
            setBatch(pArray[pArray?.length - 1])
            setBatchIndex(pArray?.length - 1)
            onPageChange(totalPages - 1)
          }}
        >
          <span>
            <i className="uil uil-angle-double-right"></i>
          </span>
        </div>
      </div>
    </div>
  ) : (
    <>No Items</>
  )
}

export default Pager
