/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { evaluateLoanCaseDeductions } from '../../../../../Helpers/Modules/loan'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

function DeductionsSchedule({
  loanCase,
  loanDisbursementMode,
  attachedLoans,
  loanCaseVoluntaryDeductibles,
  onLoadSchedule,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [deductionsSchedule, setDeductionsSchedule] = useState([])

  async function evalLoanCaseDeductions() {
    if (loanCase && loanDisbursementMode && attachedLoans) {
      setIsBusy(true)

      let payload = await evaluateLoanCaseDeductions(
        loanCase,
        loanDisbursementMode,
        [...attachedLoans].filter(x => x.isSelected),
        loanCaseVoluntaryDeductibles
      )
      // console.log(loanCase, loanDisbursementMode)
      if (payload) {
        setDeductionsSchedule(payload)
      }
      setIsBusy(false)
    }
  }

  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('environmentOperatingSystem'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherBoardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    evalLoanCaseDeductions()

    // eslint-disable-next-line
  }, [
    attachedLoans,
    loanDisbursementMode,
    loanCase,
    loanCaseVoluntaryDeductibles,
  ])

  useEffect(() => {
    onLoadSchedule && onLoadSchedule(deductionsSchedule)
    // eslint-disable-next-line
  }, [deductionsSchedule])

  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={deductionsSchedule} />
        </>
      )}
    </div>
  )
}

export default DeductionsSchedule
