/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../../Helpers/formatters'
import {
  evaluateLoanGuarantorCommitments,
  getSystemRecommendation,
} from '../../../../../../Helpers/Modules/loan'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { findCustomerAccountsByCustomerIdAndCustomerAccountTypeTargetProductId } from '../../../../../../Helpers/Modules/customerAccount'
import Tab from '../../../../../../Components/Tabs/Tab'
import RepaymentSchedule from '../RepaymentSchedule'
import OffsetableLoans from '../OffsetableLoans'
import { generateGuid } from '../../../../../../Helpers/extensions'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../../../Helpers/webApi'
import DeductionsSchedule from '../DeductionsSchedule'
import VoluntaryDeductibles from '../VoluntaryDeductibles'

function Appraisal({
  customer,
  loanProduct,
  termInMonths,
  useLoanCase,
  loanCaseProp,
  abilityToPayOverLoanTerm,
}) {
  const [depositsPayload, setDepositsPayload] = useState(null)
  const [amountAppraised, setAmountAppraised] = useState(0)
  const [loanBalance, setLoanBalance] = useState(0)
  const [offsetableLoans, setOffsetableLoans] = useState([])
  const [balances, setBalances] = useState({})
  const [appraiserRemarks, setAppraiserRemarks] = useState('')
  const [outstandingLoansBalance, setOutstandingLoansBalance] = useState(0)
  const [considerCheckOffDeductions, setConsiderCheckOffDeductions] =
    useState(false)
  const [disbursementMode, setDisbursementMode] = useState(null)

  const [standingOrders, setStandingOrders] = useState([])
  const [fetching, setFetching] = useState(false)
  const [maximumEntitled, setMaximumEntitled] = useState(0)
  const [loanCase, setLoanCase] = useState(null)
  const [voluntaryDeductibles, setVoluntaryDeductibles] = useState([])
  const [totalDeductionsInSchedule, setTotalDeductionsInSchedule] = useState(0)
  const [netDisbursementAmount, setNetDisbursementAmount] = useState(0)
  const [recommendation, setRecommendation] = useState(null)

  useEffect(() => {
    loadStandingOrders()
    // eslint-disable-next-line
  }, [customer])

  const considerCODId = generateGuid()

  useEffect(() => {
    loanProduct?.id && customer?.id && evaluateGuarantorCommitments()
    loanProduct?.id && customer?.id && getCustomerAccounts()
    customer?.id && loanProduct?.id && loadDisbursementMode()

    // eslint-disable-next-line
  }, [loanProduct, customer])

  useEffect(() => {
    evaluateSystemRecommendation()
  }, [maximumEntitled, abilityToPayOverLoanTerm, loanCase])

  useEffect(
    () => {
      setBalances({
        ...balances,
        attachedLoanBalances: Math.abs(
          accumulateLoanAmount('principalBalance')
        ),
        carryForwardsBalance: accumulateLoanAmount('carryForwardsBalance'),
        clearanceCharges: accumulateLoanAmount('clearanceCharges'),
        penaltyBalance: accumulateLoanAmount('penaltyBalance'),
        interestBalance: Math.abs(accumulateLoanAmount('interestBalance')),
      })
    },
    // eslint-disable-next-line
    [offsetableLoans]
  )

  useEffect(() => {
    if (loanProduct && customer && depositsPayload)
      useLoanCase
        ? setLoanCase(loanCaseProp)
        : setLoanCase({
            customerId: customer?.id,
            customerEmployerId: customer?.employerId,
            customerMembershipClassPrincipalInvestmentProductId:
              customer?.membershipClassPrincipalInvestmentProductId,
            loanProductId: loanProduct?.id,
            approvedAmount: amountAppraised,
            loanInterestCalculationMode:
              loanProduct?.loanInterestCalculationMode,
            loanRegistrationTermInMonths: termInMonths,
            loanRegistrationPaymentFrequencyPerYear:
              loanProduct?.loanRegistrationPaymentFrequencyPerYear,
            loanInterestAnnualPercentageRate:
              loanProduct?.loanInterestAnnualPercentageRate,
            loanRegistrationRoundingType:
              loanProduct?.loanRegistrationRoundingType,
            loanRegistrationGracePeriod:
              loanProduct?.loanRegistrationGracePeriod,
            loanRegistrationRebasePaymentDueDate:
              loanProduct?.loanRegistrationRebasePaymentDueDate,
            loanInterestChargeMode: loanProduct?.loanInterestChargeMode,
            loanRegistrationMinimumInterestAmount:
              loanProduct?.loanRegistrationMinimumInterestAmount,
            loanInterestRecoveryMode: loanProduct?.loanInterestRecoveryMode,
            loanRegistrationLoanProductSection:
              loanProduct?.loanRegistrationLoanProductSection,
            loanProductComputeDeductibleChargeOnTopUp:
              loanProduct?.computeDeductibleChargeOnTopUp,
            loanProductDeductiblesBasisValue:
              loanProduct?.deductiblesBasisValue,
            loanProductNetOffDeductibleInvestmentBalance:
              loanProduct?.netOffDeductibleInvestmentBalance,
            loanRegistrationInvestmentsMultiplier:
              depositsPayload?.loaneeAppraisalFactor,
            loanProductInvestmentsBalance: depositsPayload?.totalShares,
            loanRegistrationChargeDeductiblesFee:
              loanProduct?.loanRegistrationChargeDeductiblesFee,
            loanProductDescription: loanProduct?.description,
            loanRegistrationTopupLoanAppraisalDeposits:
              loanProduct?.loanRegistrationTopupLoanAppraisalDeposits,
          })

    // eslint-disable-next-line
  }, [depositsPayload, loanProduct, customer, amountAppraised])

  useEffect(() => {
    setMaximumEntitled(
      loanProduct?.loanRegistrationExcludeOutstandingLoansOnMaximumEntitlement
        ? +depositsPayload?.loaneeAppraisalFactor *
            +depositsPayload?.totalShares
        : +depositsPayload?.loaneeAppraisalFactor *
            +depositsPayload?.totalShares +
            outstandingLoansBalance
    )
    // eslint-disable-next-line
  }, [depositsPayload, outstandingLoansBalance])

  useEffect(() => {
    calculateOutstandingLoansBalanceLoop()
    // calculateOutstandingLoansBalance()
    // eslint-disable-next-line
  }, [offsetableLoans])

  function accumulateLoanAmount(field) {
    return offsetableLoans
      .filter(x => x.isSelected)
      .reduce((acc, loan) => acc + Number(loan[field]), 0)
  }

  useEffect(() => {
    setNetDisbursementAmount(amountAppraised - totalDeductionsInSchedule)
  }, [totalDeductionsInSchedule, amountAppraised])

  async function loadDisbursementMode() {
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-disbursement-mode-by-customer-id',
      { customerId: customer?.id },
      false
    )
    if (success) {
      setDisbursementMode(data)
    }
  }

  // function calculateOutstandingLoansBalance() {
  //   let selectedItems = [...offsetableLoans].filter(x => x.isSelected)
  //   let notSelectedItems = [...offsetableLoans].filter(x => !x.isSelected)

  //   // same loans with same id
  //   let sameLoans = [...notSelectedItems].filter(
  //     x =>
  //       x.customerAccountCustomerAccountTypeTargetProductId === loanProduct?.id
  //   )
  //   let sameLoansBalance = sameLoans.reduce(
  //     (acc, loan) =>
  //       acc +
  //       Number(
  //         Math.abs(loan?.principalBalance) +
  //           Number(loan?.interestBalance) +
  //           Number(loan?.penaltyBalance) +
  //           Number(loan?.carryForwardsBalance)
  //       ),
  //     0
  //   )

  //   // other loans with same product section or suppressProductSectionForOutstandingLoansBalance
  //   // ! Testers retest (@TODO:Clinton)
  //   let otherLoansMinus = [...selectedItems].filter(
  //     x =>
  //       x.customerAccountCustomerAccountTypeTargetProductId !==
  //         loanProduct?.id &&
  //       (loanProduct?.suppressProductSectionForOutstandingLoansBalance ||
  //         x.customerAccountTypeTargetProductProductSection ===
  //           loanProduct?.loanRegistrationLoanProductSection) &&
  //       !loanProduct.customerAccountTypeTargetProductIsDefaultersProduct
  //   )
  //   let otherLoansBalanceMinus = otherLoansMinus.reduce(
  //     (acc, loan) =>
  //       acc +
  //       Number(
  //         Math.abs(loan?.principalBalance) +
  //           Number(loan?.interestBalance) +
  //           Number(loan?.penaltyBalance) +
  //           Number(loan?.carryForwardsBalance)
  //       ),
  //     0
  //   )

  //   let otherLoans = [...notSelectedItems].filter(
  //     x =>
  //       x.customerAccountCustomerAccountTypeTargetProductId !==
  //         loanProduct?.id &&
  //       (loanProduct?.suppressProductSectionForOutstandingLoansBalance ||
  //         x.customerAccountTypeTargetProductProductSection ===
  //           loanProduct?.loanRegistrationLoanProductSection) &&
  //       !loanProduct.customerAccountTypeTargetProductIsDefaultersProduct
  //   )
  //   let otherLoansBalance = otherLoans.reduce(
  //     (acc, loan) =>
  //       acc +
  //       Number(
  //         Math.abs(loan?.principalBalance) +
  //           Number(loan?.interestBalance) +
  //           Number(loan?.penaltyBalance) +
  //           Number(loan?.carryForwardsBalance)
  //       ),
  //     0
  //   )

  //   setOutstandingLoansBalance(
  //     sameLoansBalance + otherLoansBalance - otherLoansBalanceMinus
  //   )
  // }

  function calculateOutstandingLoansBalanceLoop() {
    let result = 0
    for (let selectionItem of offsetableLoans) {
      let totalValue = 0

      if (
        selectionItem.customerAccountCustomerAccountTypeTargetProductId ===
        loanProduct?.id
      ) {
        totalValue = selectionItem.isSelected
          ? 0
          : Number(selectionItem.principalBalance) +
            Number(selectionItem.interestBalance) +
            Number(selectionItem.penaltyBalance) +
            Number(selectionItem.carryForwardsBalance)

        result += totalValue * -1 > 0 ? totalValue : 0
      } else {
        if (
          loanProduct.suppressProductSectionForOutstandingLoansBalance ||
          selectionItem.customerAccountTypeTargetProductProductSection ===
            loanProduct?.loanRegistrationLoanProductSection
        ) {
          if (selectionItem.customerAccountTypeTargetProductIsDefaultersProduct)
            continue

          if (selectionItem.isSelected) {
            totalValue =
              Number(selectionItem.principalBalance) +
              Number(selectionItem.interestBalance) +
              Number(selectionItem.penaltyBalance) +
              Number(selectionItem.carryForwardsBalance)

            result -= totalValue * -1 > 0 ? 0 : totalValue
          } else {
            totalValue = selectionItem.isSelected
              ? 0
              : Number(selectionItem.principalBalance) +
                Number(selectionItem.interestBalance) +
                Number(selectionItem.penaltyBalance) +
                Number(selectionItem.carryForwardsBalance)

            result += totalValue * -1 > 0 ? totalValue : 0
          }
        }
      }
    }
    setOutstandingLoansBalance(result)
  }

  async function loadStandingOrders() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/standingOrder/find-standing-orders-by-beneficiary-customer-id',
      {
        beneficiaryCustomerId: customer?.id,
        includeProductDescription: true,
      },
      false
    )
    if (success) {
      setStandingOrders(data?.result || [])
    }
    setFetching(false)
  }

  // function calculateMaximumEntitled() {
  //   let result = 0
  //   let netOutstandingLoansBalance = outstandingLoansBalance

  //   if (
  //     considerCheckOffDeductions &&
  //     loanProduct.loanRegistrationLoanProductSection == 1 /** Bosa Loan */
  //   ) {
  //     var totalLoansCheckOffAmount = [...standingOrders].filter(x => x.trigger == 1 && x.beneficiaryCustomerAccountCustomerAccountTypeProductCode == 1).reduce((acc, s)=> acc + Number(s.principal) + Number(s.interest), 0)
  //     netOutstandingLoansBalance = (outstandingLoansBalance + totalLoansCheckOffAmount);
  //   }

  //   if (NewLoanCase.TotalLoansBalanceDivisor.HasValue && NewLoanCase.TotalLoansBalanceDivisor.Value != 0d)
  //       netOutstandingLoansBalance = (decimal)((double)netOutstandingLoansBalance / NewLoanCase.TotalLoansBalanceDivisor.Value);

  //   result = MaximumLoan + (NewLoanCase.LoanRegistrationExcludeOutstandingLoansOnMaximumEntitlement ? 0m : (netOutstandingLoansBalance));
  // }

  async function evaluateGuarantorCommitments() {
    let payload = await evaluateLoanGuarantorCommitments(
      customer?.id,
      loanProduct?.id
    )
    if (payload) {
      setDepositsPayload(payload)
    }
  }

  async function getCustomerAccounts() {
    let accounts =
      await findCustomerAccountsByCustomerIdAndCustomerAccountTypeTargetProductId(
        customer?.id,
        loanProduct?.id,
        true
      )
    let balance = 0
    if (accounts) {
      for (let i of accounts) {
        balance += +i?.bookBalance + +i?.carryForwardsBalance
      }
    }
    setLoanBalance(balance)
  }

  function evaluateSystemRecommendation() {
    setRecommendation(
      getSystemRecommendation(
        loanCase,
        abilityToPayOverLoanTerm,
        maximumEntitled,
        0,
        false
      )
    )
  }

  const tabItems = [
    {
      label: localize('offsetableLoans'),
      item: (
        <OffsetableLoans
          onChangeOffsetables={offsetables => setOffsetableLoans(offsetables)}
          loanProductId={loanProduct?.id}
          customerId={customer?.id}
        />
      ),
      icon: 'uil uil-edit',
    },
    {
      label: localize('voluntaryDeductibles'),
      item: (
        <VoluntaryDeductibles
          onChangeDeductibles={d => setVoluntaryDeductibles(d)}
        />
      ),
      icon: 'uil uil-edit',
    },
    {
      label: localize('deductionsSchedule'),
      item: (
        <DeductionsSchedule
          loanCase={loanCase}
          loanDisbursementMode={disbursementMode}
          attachedLoans={offsetableLoans}
          loanCaseVoluntaryDeductibles={voluntaryDeductibles}
          onLoadSchedule={schedule => {
            let total = 0
            for (let s of schedule) {
              total += Number(s?.amount)
            }
            setTotalDeductionsInSchedule(total)
          }}
        />
      ),
    },
    {
      label: localize('repaymentSchedule'),
      item: (
        <RepaymentSchedule
          loanProduct={loanProduct}
          amountAppraised={amountAppraised}
          termInMonths={termInMonths}
          useLoanCase={useLoanCase}
          loanCase={loanCase}
        />
      ),
    },
  ]

  return customer && loanProduct ? (
    <>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('amountApplied')}
          </label>
          <input
            type="text"
            className="form-control"
            value={formatCurrency(loanCase?.amountApplied, 2, 2, false)}
            disabled
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemRecommendation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              recommendation?.systemRecommendation,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemRemarks')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={recommendation?.remarks}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('securityQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('incomeQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(abilityToPayOverLoanTerm, 2, 2, false)}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumAmount')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              loanProduct?.loanRegistrationMaximumAmount,
              null,
              null,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumAmount')} %
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatAsPercent(100)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(loanBalance, 2, 2, false)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(depositsPayload?.totalShares, 2, 2, false)}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsMultiplier')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              depositsPayload?.loaneeAppraisalFactor,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              +depositsPayload?.loaneeAppraisalFactor *
                +depositsPayload?.totalShares,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center"></div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('outstandingLoansBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(outstandingLoansBalance, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumEntitled')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(maximumEntitled, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center"></div>
        <div className="col d-flex align-items-center"></div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedLoanBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              balances?.attachedLoanBalances || 0,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedCarryForwardsBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              balances?.carryForwardsBalance || 0,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedClearanceCharges')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(balances?.clearanceCharges || 0, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalAttachedBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              (balances?.clearanceCharges || 0) +
                (balances?.interestBalance || 0) +
                (balances?.carryForwardsBalance || 0) +
                (balances?.penaltyBalance || 0) +
                (balances?.attachedLoanBalances || 0),
              2,
              2,
              false
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('amountAppraised')}
          </label>
          <MaskNumber
            onBlur={e => {
              let amount = stripNonNumericCharacters(e.target.value)
              setAmountAppraised(amount)
              setLoanCase({
                ...loanCase,
                approvedAmount: amount,
              })
            }}
            onMaskNumber={e => {}}
            defaultMaskValue={amountAppraised}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('appraiserRemarks')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e => setAppraiserRemarks(e.target.value)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanAmount')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('paymentPerPeriod')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementMode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={disbursementMode?.description}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementCategory')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={disbursementMode?.disbursementCategoryDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementTarget')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={disbursementMode?.disbursementTargetDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('netDisbursementAmount')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'right' }}
            value={formatCurrency(netDisbursementAmount, 2, 2, false)}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end py-1 px-3 bg-light rounded mt-2">
        <Checkbox
          id={considerCODId}
          checked={considerCheckOffDeductions}
          onCheck={v => setConsiderCheckOffDeductions(v)}
        />
        <label htmlFor={considerCODId} className="text-capitalize ms-2">
          {localize('considerCheckOffDeductions')}
        </label>
      </div>
      <div className="mt-4">
        <Tab preload tabItems={tabItems} />
      </div>
    </>
  ) : (
    <>
      <h4 className="card-title">No Customer And Loan Product</h4>
      <p className="card-title-desc">
        You can only select a customer and a loan Product to proceed
      </p>
      <div className="">
        <div className="alert alert-danger fade show" role="alert">
          <h4 className="alert-heading">Customer & Loan Product Not Found!</h4>
          <p>Please select a customer in the previous page</p>
          <hr />
          <p className="mb-0">
            You can only select a customer & a loan product to proceed
          </p>
        </div>
      </div>
    </>
  )
}

export default Appraisal
