/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const SuppressedPermissions = ({ data, handleOnRemove }) => {
  const columns = [
    {
      label: localize('systemPermissionType'),
      template: r => <>{r.systemPermissionTypeDescription}</>,
    },
    { label: localize('reason'), template: r => <>{r.reason}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <SimpleTable
      columns={columns}
      data={data}
      contextMenu={e => handleOnRemove && (
        <>
          <div
            onClick={() => {
              handleOnRemove(e)
            }}
            className="ctxt-menu-item"
          >
            <i className="uil uil-trash text-danger"></i>
            {localize('remove')}
          </div>
        </>
      )}
    />
  )
}
