/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import SearchCustomer from '../SearchCustomer'
import localize from '../../../../../../Global/Localize'
import { customerAssociateRelationship } from '../../../../../../Global/enumeration'
import { getData, putData } from '../../../../../../Helpers/webApi'
import Tab from '../../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { generateGuid } from '../../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function Associates({ customer, readOnly }) {
  const [remarks, setRemarks] = useState('')
  const [relationship, setRelationship] = useState(0)
  const [associates, setAssociates] = useState([])
  const [sel, setSel] = useState(null)

  useEffect(() => {
    loadAssociates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      label: localize('relationship'),
      template: r => <>{r.relationshipDescription}</>,
    },
    { label: localize('name'), template: r => <>{r.associateFullName}</> },
    {
      label: localize('ref') + ' 1',
      template: r => <>{r.associateReference1}</>,
    },
    {
      label: localize('ref') + ' 2',
      template: r => <>{r.associateReference2}</>,
    },
    {
      label: localize('ref') + ' 3',
      template: r => <>{r.associateReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.associateIndividualGenderDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.associateIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.associateIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.associateIndividualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r.associateAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r.associateAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.associateAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.associateAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.associateAddressCity}</> },
    { label: localize('email'), template: r => <>{r.associateAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.associateAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.associateAddressMobileline}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]

  async function loadAssociates() {
    const { success, data } = await getData(
      '/customer/find-associate-collection-by-customer-id',
      { customerId: customer?.id }
    )
    if (success) {
      setAssociates(data?.result)
    }
  }

  function addAssociate() {
    let transformed = {
      associateId: sel?.id,
      associateFullName: sel?.fullName,
      associateReference1: sel.reference1,
      associateReference2: sel.reference2,
      associateReference3: sel.reference3,
      associateIndividualGenderDescription: sel?.individualGenderDescription,
      associateIndividualIdentityCardNumber: sel?.identityCardNumber,
      associateIndividualIdentityCardSerialNumber:
        sel?.identityCardSerialNumber,
      associateAddressAddressLine1: sel?.addressAddressLine1,
      associateAddressAddressLine2: sel?.addressAddressLine2,
      associateAddressStreet: sel?.addressStreet,
      associateAddressPostalCode: sel?.addressPostalCode,
      associateAddressCity: sel?.addressCity,
      associateAddressEmail: sel?.addressEmail,
      associateAddressLandLine: sel?.addressLandLine,
      associateAddressMobileline: sel?.addressMobileLine,
      remarks: remarks,
      relationship,
      relationshipDescription: customerAssociateRelationship[relationship],
      id: generateGuid(),
    }
    setAssociates([...associates, transformed])
  }

  function removeAssociate(a) {
    let ass = [...associates]
    // eslint-disable-next-line eqeqeq
    const ind = ass.findIndex(i => i.id == a.id)
    ass.splice(ind, 1)
    setAssociates(ass)
  }

  async function postAssociates() {
    const { success } = await putData(
      `/customer/update-associate-collection-by-customer-id?customerId=${customer?.id}`,
      associates
    )
    if (success) {
      await loadAssociates()
      Swal.fire('Operation Successful', 'Associates Updated', 'success')
    }
  }

  return readOnly ? (
    <SimpleTable data={associates} columns={columns} />
  ) : (
    <div>
      <SearchCustomer customer={sel} onSelect={c => setSel(c)} />
      <div className="row my-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-2">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CustomerAssociateRelationship"
            value={relationship}
            onChange={e => setRelationship(e.value)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-2">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            value={remarks}
            onChange={e => setRemarks(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-1 bg-light d-flex align-items-center justify-content-end p-2">
        <button className="btn btn-info px-4" onClick={addAssociate}>
          {localize('add')}
        </button>
      </div>
      <div className="mt-3">
        <Tab
          tabItems={[
            {
              label: localize('associateCollection'),
              item: (
                <SimpleTable
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item text-danger"
                        onClick={() => removeAssociate(r)}
                      >
                        <i className="uil uil-times-circle"></i>
                        <span className="text-capitalize">
                          {localize('remove')}
                        </span>
                      </div>
                    </>
                  )}
                  data={associates}
                  columns={columns}
                />
              ),
            },
          ]}
        />
      </div>
      <div className="my-3 d-flex align-items-center justify-content-end">
        <button className="btn btn-success px-5" onClick={postAssociates}>
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default Associates
