/** @format */
import Commissions from '../../../../Components/Selectables/Plain/Commissions'

export const CreditTypesApplicableCharges = ({
  creditTypeCommissions,
  loading,
  onCheckCreditTypeApplicableCharges,
  disabled,
  prefix,
}) => {
  return (
    <Commissions
      prefix={prefix}
      disabled={disabled}
      commissionsToBeSelected={creditTypeCommissions}
      isBusy={loading}
      onCheckCommissions={r => {
        onCheckCreditTypeApplicableCharges &&
          onCheckCreditTypeApplicableCharges(r)
      }}
    />
  )
}
