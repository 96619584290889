/** @format */

import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import Swal from 'sweetalert2'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import CostCentreLookup from '../../../../../../Components/Lookup/CostCentreLookup'
import CustomerAccountLookup from '../../../../../../Components/Lookup/CustomerAccountLookup'
import InventoryCatalogueLookup from '../../../../../../Components/Lookup/InventoryCatalogueLookup'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { productCode } from '../../../../../../Helpers/constants'
import { formatCurrency, parseDate } from '../../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'

const CreateReceivables = ({ handleClose, mode, fetchItems }) => {
  const [postingPeriod, setPostingPeriod] = useState({})
  const [costCenter, setCostCenter] = useState({})
  const [entry, setEntry] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [receivable, setReceivable] = useState({})
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)
  const [entries, setEntries] = useState([])
  const [selectedEntries, setSelectedEntries] = useState([])
  const [total, setTotal] = useState(0)

  const auth = useAuth()

  const entryColumns = [
    {
      // eslint-disable-next-line no-useless-concat
      label: 'select' + ' ?',
      template: r => (
        <Checkbox
          checked={selectedEntries?.find(e => e?.id === r?.id)}
          onCheck={value => {
            if (value) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              const newSelectedEntries = selectedEntries?.filter(
                s => s?.id !== r?.id
              )
              setSelectedEntries(newSelectedEntries)
            }
          }}
        />
      ),
    },
    {
      label: 'item',
      template: r => r?.itemRegisterDescription,
    },
    {
      label: 'primaryDescription',
      template: r => r?.primaryDescription,
    },
    {
      label: 'secondaryDescription',
      template: r => r?.secondaryDescription,
    },
    {
      label: 'reference',
      template: r => r?.reference,
    },
    {
      label: 'sellingPrice',
      template: r => formatCurrency(r?.sellingPrice),
    },
    {
      label: 'quantity',
      template: r => formatCurrency(r?.quantity),
    },
    {
      label: 'totalPrice',
      template: r => formatCurrency(r?.quantity * r?.sellingPrice),
    },
  ]

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, null, false)
    if (success && data?.result) {
      setIsBusy(false)
      setUpdateDate(false)
      setReceivable({
        ...receivable,
        valueDate: parseDate(valueDate),
      })
    } else {
      setIsBusy(false)
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  const fetchCurrentPostingPeriod = async () => {
    const url = '/PostingPeriod/find-current-posting-period'

    const { data } = await getData(url, {}, false)

    setPostingPeriod(data)
  }

  const handleSelectPostingPeriod = value => {
    if (value?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected posting period is locked'
      )

    if (value?.isClosed)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected posting period is is closed'
      )

    setPostingPeriod(value)
  }

  const handleSelectCostCenter = value => {
    if (value?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected cost center is locked'
      )

    setCostCenter(value)
  }

  const handleSelectCustomerAccount = value => {
    if (
      value?.customerAccountTypeProductCode !== productCode.SAVINGS?.toString()
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select a customer account with product code savings'
      )

    setCustomerAccount(value)
  }

  const handleSelectInventory = value => {
    setEntry({
      ...entry,
      ...value,
      quantity: entry?.quantity,
      inventoryId: value?.id,
    })
  }

  const handleAdd = () => {
    if (!entry?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'No selected inventory'
      )

    if (entries?.find(e => e?.id === entry?.id))
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected inventory already exists'
      )

    if (entry?.quantity === 0)
      return showNotification(
        'Operation not allowed',
        'error',
        'Quantity cannot be zero'
      )

    setEntries([
      ...entries,
      {
        ...entry,
      },
    ])
    setTotal(total + Number(entry?.quantity) * Number(entry?.sellingPrice))
    setEntry(null)
  }

  const handleRemove = () => {
    if (!selectedEntries?.length)
      return showNotification(
        'Operation not allowed',
        'error',
        'No selected entries'
      )

    const newEntries = entries.filter(
      e => !selectedEntries.find(s => s?.id === e?.id)
    )

    setEntries(newEntries)
    setSelectedEntries([])
    setTotal(
      newEntries.reduce(
        (acc, curr) =>
          acc + Number(curr?.quantity) * Number(curr?.sellingPrice),
        0
      )
    )
  }

  const handleCreate = async () => {
    setIsBusy(true)

    const url = '/AutoShopReceivable/add-auto-shop-receiveable'

    const { success } = await postData(
      url,
      {
        autoshopReceivable: {
          ...customerAccount,
          ...costCenter,
          ...receivable,
          postingPeriodId: postingPeriod?.id,
          customerAccountId: customerAccount?.id,
          costCenterId: costCenter?.id,
        },
        autoshopReceivableEntries: entries,
      },
      false
    )

    if (success) {
      showNotification('Create Auto Shop Receivable', 'success')
      handleClose()
      setCostCenter({})
      setCustomerAccount({})
      setReceivable({})
      setEntries([])
      setSelectedEntries([])
      setEntry({})
      fetchItems()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchCurrentPostingPeriod()
  }, [])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <ModalTemplate
      modalMode={mode}
      modalClassName="modal-xl"
      modalTitle={mode === 'add' ? 'createReceivables' : 'editReceivables'}
      handleClose={handleClose}
      disableCreate={isBusy}
      handleAction={handleCreate}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('postingPeriod')}
              </label>
              <PostingPeriodLookup
                displayValue={postingPeriod?.description}
                onSelect={handleSelectPostingPeriod}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('costCenter')}
              </label>
              <CostCentreLookup
                displayValue={costCenter?.description}
                onSelect={handleSelectCostCenter}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('branch')}
              </label>
              <input
                value={auth?.user?.profile?.cbs_EmployeeBranchDescription}
                disabled
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <CustomerAccountLookup
                displayValue={customerAccount?.fullAccountNumber}
                onSelect={handleSelectCustomerAccount}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                value={
                  customerAccount?.customerAccountTypeTargetProductDescription
                }
                disabled
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                value={customerAccount?.customerFullName}
                className="form-control"
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('availableBalance')}
              </label>
              <input
                value={formatCurrency(
                  customerAccount?.availableBalance,
                  null,
                  null,
                  false
                )}
                className="form-control text-end"
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                value={receivable?.remarks}
                className="form-control"
                onChange={e =>
                  setReceivable({ ...receivable, remarks: e.target.value })
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('valueDate')}
              </label>
              <CustomDatePicker
                defaultDate={receivable?.valueDate}
                name={'createReceivablesValueDate'}
                onDateChange={date => setValueDate(date)}
                setDateToNull={updateDate}
                setUpdateDate={setUpdateDate}
              />
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('entryDetails')}
            </div>

            <div className="row g-3">
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('inventory')}
                </label>
                <InventoryCatalogueLookup
                  displayValue={entry?.itemRegisterDescription || ''}
                  onSelect={handleSelectInventory}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('sellingPrice')}
                </label>
                <MaskNumber
                  defaultMaskValue={entry?.sellingPrice || 0}
                  onMaskNumber={e => {
                    setEntry({ ...entry, sellingPrice: e.target.rawValue })
                  }}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('quantity')}
                </label>
                <MaskNumber
                  defaultMaskValue={entry?.quantity || 0}
                  onMaskNumber={e => {
                    setEntry({ ...entry, quantity: e.target.rawValue })
                  }}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('primaryDescription')}
                </label>
                <input
                  className="form-control"
                  value={entry?.primaryDescription || ''}
                  onChange={e => {
                    setEntry({ ...entry, primaryDescription: e.target.value })
                  }}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('secondaryDescription')}
                </label>
                <input
                  className="form-control"
                  value={entry?.secondaryDescription || ''}
                  onChange={e => {
                    setEntry({ ...entry, secondaryDescription: e.target.value })
                  }}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  className="form-control"
                  value={entry?.reference || ''}
                  onChange={e => {
                    setEntry({ ...entry, reference: e.target.value })
                  }}
                />
              </div>
            </div>
            <div className="mt-3 bg-light rounded p-2 d-flex align-items-center justify-content-end">
              <button className="btn btn-success" onClick={handleAdd}>
                {localize('add')}
              </button>
            </div>

            <div className="mt-4">
              <Tab
                tabItems={[
                  {
                    label: localize('receivableEntries'),
                    item: (
                      <>
                        <SimpleTable columns={entryColumns} data={entries} />
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <div className="mt-3 bg-light rounded p-2 d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-item-center">
                <label className="text-capitalize me-3">
                  {localize('total')}
                </label>
                <input
                  className="form-control text-end w-50"
                  value={formatCurrency(total, null, null, false)}
                  disabled
                />
              </div>
              <button className="btn btn-danger" onClick={handleRemove}>
                {localize('remove')}
              </button>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateReceivables
