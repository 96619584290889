/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import Checkbox from '../Checkbox/Checkbox'
import Loader from '../Loaders/Loader'
import SimpleTable from '../Tables/SimpleTable'

function ReportTemplateLookup({ displayValue, onSelect }) {
  const columns = [
    {
      label: localize('name'),
      template: r => (
        <div
          className="p-0"
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        />
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('spreadsheetCellReference'),
      template: r => <>{r?.spreadsheetCellReference}</>,
    },
    {
      label: localize('valueComputationMode'),
      template: r => <>{r?.spreadsheetCellValueComputationModeDescription}</>,
    },
    {
      label: localize('valueComputationTag'),
      template: r => <>{r?.spreadsheetCellValueComputation}</>,
    },
    {
      label: localize('templateFileTitle'),
      template: r => <>{r?.templateFileTitle}</>,
    },
    {
      label: localize('templateFileMimeType'),
      template: r => <>{r?.templateFileMIMEType}</>,
    },
    { label: localize('absoluteCellValue'), template: r => <></> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [reports, setReports] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    updateDepth: true,
    traverseTree: true,
  })

  useEffect(() => {
    loadReports()
  }, [])

  function handleSearch() {
    const s = search.toLowerCase()
    const fl = [...reports].filter(
      r =>
        r?.description.toLowerCase().includes(s) ||
        r?.spreadsheetCellValueComputationModeDescription
          .toLowerCase()
          .includes(s) ||
        r?.templateFileTitle?.toLowerCase().includes(s) ||
        r?.createdBy?.toLowerCase().includes(s) ||
        r?.templateFileMIMEType?.toLowerCase().includes(s)
    )
    setFiltered(fl)
  }

  async function loadReports() {
    setIsBusy(true)
    setSearch('')
    const { success, data } = await getData(
      '/lookup/ReportTemplate/find-reports',
      reqParams,
      false
    )
    if (success) {
      setReports(data.result)
      setFiltered(data.result)
    }

    setIsBusy(false)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('reportTemplateLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-1">
                    <Checkbox
                      checked={reqParams?.traverseTree}
                      id={'traverseTree-report-template-lookup'}
                      onCheck={v => {
                        setReqParams({ ...reqParams, traverseTree: v })
                      }}
                    />
                    <label
                      htmlFor="traverseTree-report-template-lookup"
                      className="text-capitalize"
                    >
                      {localize('traverseTree')} ?
                    </label>
                  </div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSearch()
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex align-items-center my-2">
                      <label htmlFor="searchTable">Search</label>
                      <input
                        defaultValue={search}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control ms-3"
                        id="searchTable"
                      />
                    </div>

                    <button
                      type="submit"
                      onClick={() => loadReports()}
                      className="btn btn-primary ms-2"
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div
                  className=" py-1"
                  style={{
                    maxHeight: 'calc(100vh - 300px)',
                    overflowX: 'scroll',
                  }}
                >
                  <SimpleTable
                    columns={columns}
                    data={filtered}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-0">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default ReportTemplateLookup
