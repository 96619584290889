/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import Tab from '../Tabs/Tab'

function MorgueBedLookup({ displayValue, onSelect }) {
  const [reqParams, setReqParams] = useState({
    filterText: '',
    roomType: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [bedReqParams, setBedReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [searchBed, setSearchBed] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [isBusyLoadingRooms, setIsBusyLoadingRooms] = useState(false)
  const [beds, setBeds] = useState([])

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const bedsColumns = [
    {
      label: 'description',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleSelectRoom = room => {
    setSelectedRoom(room)
  }

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Room/find-rooms-by-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  const fetchRoomBeds = async () => {
    setIsBusyLoadingRooms(true)
    const { success, data } = await getData(
      '/Bed/find-valid-beds-by-room-id-filter-in-page',
      {
        roomId: selectedRoom?.id,
        ...bedReqParams,
      },
      false
    )
    if (success) {
      setBeds(data)
    }

    setIsBusyLoadingRooms(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  useEffect(() => {
    if (selectedRoom?.id) fetchRoomBeds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom?.id, bedReqParams?.pageIndex, bedReqParams?.pageSize])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('morgueBed')} {localize('lookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center my-2">
                    <label
                      className="text-capitalize"
                      htmlFor="morguePatientLookupPageSize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="morguePatientLookupPageSize"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize me-2">
                      {localize('roomType')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 180,
                        minWidth: 180,
                      }}
                      value={reqParams?.roomType}
                      enumsKey="RoomType"
                      onChange={({ value }) =>
                        setReqParams({
                          ...reqParams,
                          roomType: value,
                        })
                      }
                    />
                  </div>

                  <form
                    className="d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize me-3"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-3"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="py-1">
                  <SimpleTable
                    canClickOnce
                    onClickOnce={handleSelectRoom}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </div>
                <Pager
                  itemsCount={data?.itemsCount}
                  pageSize={reqParams?.pageSize}
                  pageIndex={reqParams?.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
            <hr />
            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: 'availableBeds',
                    item: isBusyLoadingRooms ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center my-2">
                            <label
                              className="text-capitalize"
                              htmlFor="morguePatientLookupPageSize"
                            >
                              {localize('recordsPerPage')}
                            </label>
                            <select
                              id="morguePatientLookupPageSize"
                              className="form-select ms-3"
                              style={{ width: 'fit-content' }}
                              defaultValue={reqParams.pageSize}
                              onChange={e => {
                                setBedReqParams({
                                  ...bedReqParams,
                                  pageIndex: 0,
                                  pageSize: e.target.value,
                                })
                              }}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>

                          <form
                            className="d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              setBedReqParams({
                                ...reqBedParams,
                                filterText: searchBed,
                              })
                            }}
                          >
                            <label
                              className="ms-2 text-capitalize me-3"
                              htmlFor="searchTable"
                            >
                              {localize('search')}
                            </label>
                            <input
                              type="search"
                              id="searchTable"
                              className="form-control ms-3"
                              defaultValue={bedReqParams.filterText}
                              onChange={e => {
                                setSearchBed(e.target.value)
                                setBedReqParams({
                                  ...bedReqParams,
                                  filterText: e.target.value,
                                })
                              }}
                            />
                            <button
                              type="submit"
                              className="btn btn-primary ms-2"
                              onClick={() => {
                                fetchRoomBeds()
                              }}
                            >
                              <i className="uil uil-filter"></i>
                            </button>
                          </form>
                        </div>
                        <div className="py-1">
                          <SimpleTable
                            onSelect={r => {
                              setModalActive(false)
                              onSelect(r)
                            }}
                            canSelect
                            columns={bedsColumns}
                            data={beds?.pageCollection}
                          />
                        </div>
                        <Pager
                          itemsCount={beds?.itemsCount}
                          pageSize={reqParams?.pageSize}
                          pageIndex={reqParams?.pageIndex}
                          pageBatchSize={10}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default MorgueBedLookup
