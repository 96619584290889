/** @format */
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

export const CatalogueFileMovementHistory = ({
  movementHistories,
  loading,
}) => {
  const columns = [
    {
      label: localize('source'),
      template: r => <>{r.sourceDepartmentDescription}</>,
    },
    {
      label: localize('destination'),
      template: r => <>{r.destinationDepartmentDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('carrier'), template: r => <>{r.carrier}</> },
    { label: localize('sender'), template: r => <>{r.sender}</> },
    {
      label: localize('dispatchDate'),
      template: r => <>{formatDate(formatDate(r.sendDate))}</>,
    },
    { label: localize('recipient'), template: r => <>{r.recipient}</> },
    {
      label: localize('receiveDate'),
      template: r => <>{formatDate(r.receiveDate)}</>,
    },
  ]

  return (
    <SimpleTable loading={loading} columns={columns} data={movementHistories} />
  )
}
