/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import BatchEntriesTab from '../../../../../../Components/ReUsables/BatchEntries/WithdrawalSettlementBatchEntriesTab/BatchEntriesTab'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { BatchAuditOption } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { parseDate } from '../../../../../../Helpers/formatters'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

function VerifyBatchVerificationModal({
  handleClose,
  batchOrigination,
  mode,
  reload,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedBatchAuditOption, setSelectedBatchAuditOption] = useState(
    Object.keys(BatchAuditOption)[0]
  )
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [auditRemarks, setAuditRemarks] = useState('')

  const clearValidation = column => {
    setGlobalValidationErrors(prevState => {
      delete prevState[column]
      return prevState
    })
  }

  const handleUpdate = async () => {
    if (!auditRemarks) {
      return GlobalValidationErrors.set({
        AuditRemarks: {
          message: 'Audit Remarks is required.',
        },
      })
    }

    setIsBusy(true)

    const { success } = await putData(
      `/WithdrawalSettlementBatch/audit-withdrawal-settlement-batch/?batchAuditOption=${selectedBatchAuditOption}`,
      {
        ...batchOrigination,
        auditRemarks,
      },
      false
    )
    if (success) {
      reload(true)
      showNotification(
        'Verify Membership Withdrawal Settlement Batch',
        'success'
      )
      handleClose()
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="membershipWithdrawalSettlementBatch"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleUpdate}
      handleClose={handleClose}
      modalClassName="modal-xl"
      footerChildren={
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-content-end">
            <div
              className="d-flex align-items-center with-validation me-5"
              validation-message={
                globalValidationErrors['AuditRemarks']?.message
              }
            >
              <label className="text-capitalize me-3">
                {localize('remarks')}
              </label>
              <input
                disabled={isBusy}
                className="form-control"
                value={auditRemarks}
                onChange={e => {
                  clearValidation('AuditRemarks')
                  setAuditRemarks(e.target.value)
                }}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <EnumsServerCombo
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                disabled={isBusy}
                enumsKey="BatchAuditOption"
                value={selectedBatchAuditOption}
                onChange={e => {
                  setSelectedBatchAuditOption(e.value)
                }}
              />
            </div>
          </div>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row py-2 g-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="branch">
                  {localize('batch#')}
                </label>

                <input
                  className="form-control"
                  type="text"
                  defaultValue={batchOrigination?.batchNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="category">
                  {localize('category')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  value={batchOrigination?.categoryDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="branch">
                  {localize('branch')}
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={batchOrigination?.branchDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="priority">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  disabled
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="QueuePriority"
                  value={batchOrigination?.priority}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="reference">
                  {localize('reference')}
                </label>
                <input
                  className={`form-control`}
                  id="reference"
                  value={batchOrigination?.reference}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="createdBy">
                  {localize('createdBy')}
                </label>
                <input
                  className={`form-control`}
                  id="createdBy"
                  value={batchOrigination?.createdBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="createdDate">
                  {localize('createdDate')}
                </label>
                <input
                  type="date"
                  className={`form-control`}
                  id="createdDate"
                  value={parseDate(batchOrigination?.createdDate)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  className="text-capitalize col-3"
                  htmlFor="statusDescription"
                >
                  {localize('statusDescription')}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="statusDescription"
                  value={batchOrigination?.statusDescription}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('batchDetails')}
            </div>
            <div className="row py-2 g-3">
              <Tab
                tabItems={[
                  {
                    label: localize('batchEntries'),
                    item: (
                      <>
                        <BatchEntriesTab
                          withdrawalSettlementBatchId={batchOrigination?.id}
                          hiddenColumns={[0]}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifyBatchVerificationModal
