/** @format */
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  getTodaysDate,
} from '../../../../../../../Helpers/formatters'
import { ItemRegisterLookup } from '../../../../../../../Components/Lookup/ItemRegisterLookup'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { EditRequisitionEntries } from './EditRequisitionEntries'

export const EditRequisitionModal = ({
  selectedRequisition,
  setSelectedRequisition,
  requisitionEntries,
  isBusy,
  setIsBusy,
  setRequisitionEntries,
}) => {
  const [requisitionItemModel, setRequisitionItemModel] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOnAddRequisition = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/Requisition/add-requisition-entries',
      {
        ItemRegister: requisitionItemModel?.itemRegister,
        Quantity: requisitionItemModel?.quantity,
        RequisitionItems: requisitionEntries,
      },
      false,
    )
    if (success) {
      let newEntries = [...requisitionEntries, data]
      setRequisitionEntries(newEntries)
      setRequisitionItemModel(null)
    }
    setLoading(false)
  }

  const [reqParams, setReqParams] = useState({
    requisitionId: selectedRequisition?.id,
    text: '',
    pageIndex: 0,
    pageSize: 100,
  })
  const fetchRequisitionItems = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Requisition/find-requisitions-items-by-requisition-id-filter-in-page',
      {
        ...reqParams,
        requisitionId: selectedRequisition?.id,
      },
      false,
    )
    if (success) {
      setRequisitionEntries([...data?.pageCollection])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchRequisitionItems()
  }, [selectedRequisition?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('reference')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedRequisition?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedRequisition?.departmentDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <CustomDatePicker
                name="expectedDate"
                defaultDate={selectedRequisition?.expectedDate}
                onDateChange={date => {
                  setSelectedRequisition({
                    ...selectedRequisition,
                    expectedDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('reason')}
              </label>
              <input
                className="form-control"
                value={selectedRequisition?.reason}
                onChange={e => {
                  setSelectedRequisition({
                    ...selectedRequisition,
                    reason: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                }}
              >
                {localize('itemDetails')}
              </div>
              <div className="row mb-3" style={{ marginTop: -10 }}>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-3">
                    {localize('itemRegister')}
                  </label>
                  <ItemRegisterLookup
                    displayValue={
                      requisitionItemModel?.itemRegister?.description || ''
                    }
                    onSelect={r => {
                      setRequisitionItemModel({
                        ...requisitionItemModel,
                        itemRegister: { ...r },
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-3">
                    {localize('inventoryCategory')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      requisitionItemModel?.itemRegister
                        ?.inventoryCategoryDescription || ''
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-3">
                    {localize('unitOfMeasure')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      requisitionItemModel?.itemRegister
                        ?.inventoryUnitOfMeasureDescription || ''
                    }
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: -10 }}>
                <div className="col-4 d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-3">
                    {localize('estimatedUnitCost')}
                  </label>
                  <input
                    className="form-control text-end"
                    disabled
                    value={formatCurrency(
                      requisitionItemModel?.itemRegister?.estimatedUnitCost ||
                        '',
                      2,
                      2,
                      false,
                    )}
                  />
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-3">
                    {localize('quantity')}
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    value={requisitionItemModel?.quantity || ''}
                    onChange={e =>
                      setRequisitionItemModel({
                        ...requisitionItemModel,
                        quantity: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={handleOnAddRequisition}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>
              )}
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                    marginBottom: -10,
                  }}
                >
                  {localize('requisitionItems')}
                </div>
                <EditRequisitionEntries
                  entries={{ pageCollection: requisitionEntries }}
                  onRefresh={() => {
                    fetchRequisitionItems()
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
