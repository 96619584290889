/** @format */

import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { AddFixedDepositTypeTiers } from './Partials/AddFixedDepositTypeTiers'
import { FixedDepositTypeLevies } from './Partials/FixedDepositTypeLevies'
import { FixedDepositTypeOffsetableLoanProducts } from './Partials/FixedDepositTypeOffsetableLoanProducts'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import Loader from '../../../../Components/Loaders/Loader'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

export const AddFixedDepositTypeModal = ({
  selectedFixedDepositType,
  setSelectedFixedDepositType,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['FixedDepositType.Description']?.message
              }
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedFixedDepositType?.description}
                onChange={e => {
                  setSelectedFixedDepositType({
                    ...selectedFixedDepositType,
                    description: e.target.value,
                  })
                  clearValidation('FixedDepositType.Description')
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('months')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedFixedDepositType?.months}
                onMaskNumber={r => {
                  setSelectedFixedDepositType({
                    ...selectedFixedDepositType,
                    months: r.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('advanceRate')}
              </label>
              <MaskPercentage
                defaultMaskValue={selectedFixedDepositType?.advanceRate}
                onMaskNumber={e => {
                  setSelectedFixedDepositType({
                    ...selectedFixedDepositType,
                    advanceRate: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['FixedDepositType.ChartOfAccountId']?.message
              }
            >
              <label className="col-2 text-capitalize">
                {localize('glAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={
                  selectedFixedDepositType?.chartOfAccountAccountName
                }
                onSelect={r => {
                  clearValidation('FixedDepositType.ChartOfAccountId')
                  setSelectedFixedDepositType({
                    ...selectedFixedDepositType,
                    chartOfAccountId: r.id,
                    chartOfAccountAccountName: r.name,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('interestExpenseGlAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={
                  selectedFixedDepositType?.interestExpenseChartOfAccountAccountName
                }
                onSelect={r => {
                  setSelectedFixedDepositType({
                    ...selectedFixedDepositType,
                    interestExpenseChartOfAccountId: r.id,
                    interestExpenseChartOfAccountAccountName: r.name,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('tiers'),
                    item: (
                      <AddFixedDepositTypeTiers
                        selectedFixedDepositType={selectedFixedDepositType}
                        setSelectedFixedDepositType={
                          setSelectedFixedDepositType
                        }
                      />
                    ),
                  },
                  {
                    label: localize('levies'),
                    item: (
                      <FixedDepositTypeLevies
                        selectedFixedDepositType={selectedFixedDepositType}
                        setSelectedFixedDepositType={
                          setSelectedFixedDepositType
                        }
                      />
                    ),
                  },
                  {
                    label: localize('offsetableLoanProducts'),
                    item: (
                      <FixedDepositTypeOffsetableLoanProducts
                        selectedFixedDepositType={selectedFixedDepositType}
                        setSelectedFixedDepositType={
                          setSelectedFixedDepositType
                        }
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
