/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import CustomersLookup from '../../../../../../Components/Lookup/CustomersLookup'
import AdministrativeDivisionLookup from '../../../../../../Components/Lookup/AdministrativeDivisionLookup'
import RelationshipManagerLookup from '../../../../../../Components/Lookup/RelationshipManagerLookup'
import MembershipClassLookup from '../../../../../../Components/Lookup/MembershipClassLookup'
import EmployerLookup from '../../../../../../Components/Lookup/EmployerLookup'
import DividendCodeLookup from '../../../../../../Components/Lookup/DividendCodeLookup'
import WorkstationLookup from '../../../../../../Components/Lookup/WorkstationLookup'
import ElectoralZonesLookup from '../../../../../../Components/Lookup/ElectoralZonesLookup'
import { parseDate } from '../../../../../../Helpers/formatters'
import { CustomerTypes } from '../../../../../../Global/enumeration'
import PartnershipParticulars from '../../Tabs/Particulars/PartnershipParticulars'
import IndividualParticulars from '../../Tabs/Particulars/IndividualParticulars'
import CorporationParticulars from '../../Tabs/Particulars/CorporationParticulars'
import MicrocreditParticulars from '../../Tabs/Particulars/MicrocreditParticulars'
import AddressV2 from '../../../../../../Components/Miscalleneous/AddressV2'
import OriginIdentifierLookup from '../../../../../../Components/Lookup/OriginIdentifierLookup'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function BasicDetails({ customer, mode, onChange, enrollment, readOnly }) {
  const editMode = mode === 'edit'
  const [selectedCustomer, setSelectedCustomer] = useState(customer)
  const [customerType, setCustomerType] = useState(
    customer?.membershipClassCustomerType
  )

  const customerTypeParticular = {
    0: (
      <IndividualParticulars
        readOnly={readOnly}
        customer={selectedCustomer}
        onChange={cst => setSelectedCustomer({ ...selectedCustomer, ...cst })}
      />
    ),
    1: (
      <PartnershipParticulars
        readOnly={readOnly}
        customer={selectedCustomer}
        onChange={(cst, members) => {
          setSelectedCustomer({ ...selectedCustomer, ...cst })
        }}
        noMembers
      />
    ),
    2: (
      <CorporationParticulars
        readOnly={readOnly}
        customer={selectedCustomer}
        onChange={(cst, members) => {
          setSelectedCustomer({ ...selectedCustomer, ...cst })
        }}
        noMembers
      />
    ),
    3: (
      <MicrocreditParticulars
        readOnly={readOnly}
        customer={selectedCustomer}
        onChange={cst => setSelectedCustomer({ ...selectedCustomer, ...cst })}
      />
    ),
  }

  useEffect(() => {
    onChange(selectedCustomer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer])

  return (
    <>
      <div className="my-3">
        <h4 className="text-capitalize">
          {localize('basicDetails')} {enrollment && 'enrol'}
        </h4>
      </div>
      <div className="row mb-1">
        <div className="col-3 d-flex align-items-center">
          <label className="pe-2 col-4 text-capitalize">
            {localize('membershipClass')}
          </label>
          <MembershipClassLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.membershipClassDescription}
            onSelect={e => {
              setSelectedCustomer({
                ...selectedCustomer,
                membershipClassId: e.id,
                membershipClassDescription: e.description,
                membershipClassCustomerType: e.customerType,
                membershipClassCustomerTypeDescription:
                  e.customerTypeDescription,
                membershipClassNumberingSeriesId: e.numberingSeriesId,
                membershipClassNumberingSeriesDescription:
                  e.numberingSeriesDescription,
              })
              setCustomerType(e.customerType)
            }}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize(editMode ? 'memberNumber' : 'customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={
              editMode
                ? selectedCustomer?.memberNumber
                : selectedCustomer?.membershipClassCustomerTypeDescription
            }
            disabled
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize(editMode ? 'accountNumber' : 'numberingSeries')}
          </label>
          <input
            type="text"
            className="form-control"
            value={
              editMode
                ? selectedCustomer?.accountNumber
                : selectedCustomer?.membershipClassNumberingSeriesDescription
            }
            disabled
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedCustomer?.branchDescription}
            disabled
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-3 d-flex align-items-center">
          <label
            className="pe-2 col-4 text-capitalize"
            style={{ wordWrap: 'break-word' }}
          >
            {localize('business')}&nbsp;/&nbsp;{localize('group')}&nbsp;/&nbsp;
            {localize('employer')}
          </label>
          <EmployerLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.employerDescription}
            onSelect={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                employerDescription: e.description,
                employerId: e.id,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize(enrollment ? 'enrollmentDate' : 'dividendCode')}
          </label>
          {enrollment ? (
            <input
              type={'date'}
              disabled={readOnly}
              className="form-control"
              value={parseDate(selectedCustomer?.enrollmentDate)}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  enrollmentDate: e.target.value,
                })
              }
            />
          ) : (
            <DividendCodeLookup
              disabled={readOnly}
              displayValue={selectedCustomer?.dividendCodeDescription}
              onSelect={d =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  dividendCodeDescription: d.description,
                  dividendCodeId: d.id,
                })
              }
            />
          )}
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={selectedCustomer?.personalFileNumber}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                personalFileNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('joinDate')}
          </label>
          <input
            disabled={readOnly}
            type="date"
            className="form-control"
            value={parseDate(selectedCustomer?.registrationDate)}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                registrationDate: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-3 d-flex align-items-center">
          <label className="pe-2 col-4 text-capitalize">
            {localize('duty')}/{localize('workStation')}
          </label>
          <WorkstationLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.dutyStationDescription}
            onSelect={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                dutyStationId: e.id,
                dutyStationDescription: e.description,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('electoralZone')}
          </label>
          <ElectoralZonesLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.electoralZoneDescription}
            onSelect={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                electoralZoneId: e.id,
                electoralZoneDescription: e.description,
              })
            }
          />
        </div>
        {editMode && !enrollment && (
          <>
            <div className="col-3 d-flex align-items-center">
              <label htmlFor="" className="pe-2 col-4 text-capitalize">
                {localize('membershipStatus')} ({localize('current')})
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedCustomer?.membershipStatusDescription}
                disabled
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label htmlFor="" className="pe-2 col-4 text-capitalize">
                {localize('membershipStatus')} ({localize('new')})
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerMembershipStatus"
                onChange={e =>
                  setSelectedCustomer({
                    ...selectedCustomer,
                    membershipStatus: e.value,
                  })
                }
              />
            </div>
          </>
        )}
        {((enrollment && !editMode) ||
          (!enrollment && !editMode) ||
          (enrollment && editMode)) && (
          <>
            <div className="col-3 d-flex align-items-center">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize(enrollment ? 'origin' : 'relationshipManager')}
              </label>
              {enrollment ? (
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="RequestOrigin"
                  value={selectedCustomer?.origin}
                  disabled={readOnly}
                  onChange={e =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      origin: e.value,
                    })
                  }
                />
              ) : (
                <RelationshipManagerLookup
                  disabled={readOnly}
                  displayValue={
                    selectedCustomer?.relationshipManagerDescription
                  }
                  onSelect={r =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      relationshipManagerId: r.id,
                      relationshipManagerDescription:
                        r.employeeCustomerFullName,
                    })
                  }
                />
              )}
            </div>

            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize(enrollment ? 'originIdentifier' : 'payrollNumbers')}
              </label>
              {enrollment ? (
                <>
                  {selectedCustomer?.origin > 1 ? (
                    <OriginIdentifierLookup
                      disabled={readOnly || selectedCustomer?.origin == 4}
                      displayValue={selectedCustomer?.originIdentifier}
                      onChange={oid => {}}
                    />
                  ) : (
                    <input
                      className="form-control"
                      placeholder="enter text..."
                      value={selectedCustomer?.originIdentifier}
                      disabled={readOnly}
                    />
                  )}
                </>
              ) : (
                <input
                  disabled={readOnly}
                  type="text"
                  className="form-control"
                  value={selectedCustomer?.payrollNumbers}
                  onChange={e =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      payrollNumbers: e.target.value,
                    })
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="row mb-1">
        <div className="col-3 d-flex align-items-center">
          <label className="pe-2 col-4 text-capitalize">
            {localize('administrativeDivision')}
          </label>
          <AdministrativeDivisionLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.administrativeDivisionDescription}
            onSelect={a =>
              setSelectedCustomer({
                ...selectedCustomer,
                administrativeDivisionId: a.id,
                administrativeDivisionDescription: a.description,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('recruitedBy')}
          </label>
          <CustomersLookup
            disabled={readOnly}
            displayValue={selectedCustomer?.recruitedBy}
            onSelect={c =>
              setSelectedCustomer({
                ...selectedCustomer,
                recruitedBy: c.memberNumber,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('monthlyContribution')}
          </label>
          <MaskNumber
            disabled={readOnly}
            className="form-control"
            defaultMaskValue={selectedCustomer?.monthlyContribution}
            // onClick={e => alert(formatCurrency(e.target.value, 2, 2, false))}
            onMaskNumber={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                monthlyContribution: e.target.value,
              })
            }
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="pe-2 col-4 text-capitalize">
            {localize('remarks')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={selectedCustomer?.remarks}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      {enrollment && (
        <div className=" mb-1 d-flex align-items-center">
          <label className="text-capitalize col-1">
            {localize('payrollNumbers')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control ms-1"
            value={selectedCustomer?.payrollNumbers}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                payrollNumbers: e.target.value,
              })
            }
          />
        </div>
      )}
      {customerType ? (
        <>
          <div className="mb-3 mt-4">
            <h4 className="text-capitalize">{`${CustomerTypes[customerType]?.name} Particulars`}</h4>
          </div>
          {customerTypeParticular[customerType]}
        </>
      ) : (
        <div className="alert alert-info mt-4 text-capitalize">
          {localize('select membership class above to view particulars')}
        </div>
      )}

      <>
        <div className="mb-3 mt-4">
          <h4 className="text-capitalize">{localize('address')}</h4>
        </div>
        <AddressV2
          readOnly={readOnly}
          parent={selectedCustomer}
          onChange={a => setSelectedCustomer({ ...selectedCustomer, ...a })}
        />
      </>
    </>
  )
}

export default BasicDetails
