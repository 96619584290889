/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { TellerTypes } from '../../Global/enumeration'
import { formatCurrency } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import SimpleLookupWithFilters from './SimpleLookupWithFilters'

function TellerLookup({ onSelect, displayValue }) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    tellerType: Object.keys(TellerTypes)[0],
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const columns = [
    {
      label: localize('code'),
      template: r => <>{r.paddedCode}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('lowerLimit'),
      template: r => <>{formatCurrency(r.rangeLowerLimit)}</>,
    },
    {
      label: localize('upperLimit'),
      template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
    },
    {
      label: localize('miniStatementItemsCap'),
      template: r => <>{r.miniStatementItemsCap}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('shortageChartOfAccountName'),
      template: r => <>{r.shortageChartOfAccountName}</>,
    },
    {
      label: localize('shortageChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.shortageChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('excessChartOfAccountName'),
      template: r => <>{r.excessChartOfAccountName}</>,
    },
    {
      label: localize('excessChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.excessChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('employeeName'),
      template: r => <>{r.employeeCustomerFullName}</>,
    },
    {
      label: localize('employeeBranch'),
      template: r => <>{r.employeeBranchDescription}</>,
    },
    {
      label: localize('posAgency'),
      template: r => <>{r.pointOfSaleAgencyDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
    {
      label: localize('createdDate'),
      template: r => <>{r.createdDate}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url = '/lookup/Teller/find-tellers-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.tellerType,
    reqParams.pageSize,
    reqParams.pageIndex,
    modalActive,
  ])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: 'all',
          opacity: 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('tellerLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>

                  <div className="d-flex align-items-center float-end my-2">
                    <label
                      className="text-capitalize me-2"
                      htmlFor="filterButton"
                    >
                      {localize('search')}
                    </label>

                    <div className="d-flex-align-items-center">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="TellerType"
                        value={reqParams.tellerType}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            tellerType: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                    </div>

                    <input
                      type="search"
                      id="search"
                      className="form-control col ms-2"
                      value={reqParams.text}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                          pageIndex: 0,
                        })
                      }}
                    />
                    <button
                      id="filterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )

  return (
    <>
      <SimpleLookupWithFilters
        paramKey={'tellerType'}
        enumKey={'TellerType'}
        title={localize('tellerLookup')}
        url="/lookup/Teller/find-tellers-by-filter-in-page"
        params={{ text: '', pageSize: 10, pageIndex: 0 }}
        columns={[]}
        displayValue={displayValue}
        onSelect={onSelect}
        useText
      />
    </>
  )
}
export default TellerLookup
