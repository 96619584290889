/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import { getData } from '../../../../../../Helpers/webApi'
import ViewAccruedInterestDataModal from '../../Partials/ViewAccruedInterestDataModal'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import AuthorizeAccruedInterestDataModal from './AuthorizeAccruedInterestDataModal'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

function AccruedInterest() {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
      sortBy: 'branchDescription',
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedBatchNumber}</>,
      sortBy: 'paddedBatchNumber',
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.accruedInterestPostingPeriodDescription}</>,
      sortBy: 'accruedInterestPostingPeriodDescription',
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('month'),
      template: r => <>{r?.monthDescription}</>,
      sortBy: 'monthDescription',
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
      sortBy: 'valueDate',
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
      sortBy: 'reference',
    },
    {
      label: localize('ignoreNegativeYield'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.ignoreNegativeYield.toString())}
        </span>
      ),
      sortBy: 'ignoreNegativeYield',
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r?.auditRemarks}</>,
      sortBy: 'auditRemarks',
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r?.auditedBy}</>,
      sortBy: 'auditedBy',
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r?.authorizationRemarks}</>,
      sortBy: 'authorizationRemarks',
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r?.authorizedBy}</>,
      sortBy: 'authorizedBy',
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r?.priorityDescription}</>,
      sortBy: 'priorityDescription',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    status: 8,
    startDate: null,
    endDate: null,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: false,
    dateRequestsFilter: 15,
    customDate: false,
  })

  const [mode, setMode] = useState(null)
  const [selectedAccruedInterest, setSelectedAccruedInterest] = useState()

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AccruedInterestBatch/find-accrued-interest-batch-by-status-authorised-branches-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }

    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('authorization'),
          localize('accruedInterestBatches'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2 mx-0">
                  <RecordsPerPage
                    className="col-2"
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                  <div className="d-flex align-items-center col-2">
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="BatchStatus"
                      value={reqParams?.status}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          status: e.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-md-4">
                    <div className="d-flex align-items-center">
                      <label
                        className="text-capitalize col-3"
                        htmlFor="searchByDate"
                      >
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <form
                    className="d-flex align-items-center col-4 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>

                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="accruedInterestFilterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>

                <div className="row col-12 mt-2">
                  <SimpleTable
                    contextMenu={r => (
                      <>
                        <div
                          onClick={async () => {
                            setMode('view')
                            setSelectedAccruedInterest(r)
                          }}
                          className="ctxt-menu-item"
                        >
                          <i className="uil uil-eye"></i>
                          {localize('view')}
                        </div>
                        <div
                          onClick={async () => {
                            setMode('authorize')
                            setSelectedAccruedInterest(r)
                          }}
                          className="ctxt-menu-item"
                        >
                          <i className="uil uil-unlock-alt"></i>
                          {localize('authorize')}
                        </div>
                      </>
                    )}
                    data={data}
                    columns={columns}
                  />
                </div>
                <div className="row col-12 mt-2">
                  <Pager
                    {...tableMeta}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {mode === 'authorize' ? (
        <AuthorizeAccruedInterestDataModal
          selectedAccruedInterest={selectedAccruedInterest}
          mode={mode}
          loadData={loadData}
          close={() => setMode(null)}
          setSelectedAccruedInterest={setSelectedAccruedInterest}
        />
      ) : (
        mode === 'view' && (
          <ViewAccruedInterestDataModal
            selectedAccruedInterest={selectedAccruedInterest}
            mode={mode}
            close={() => setMode(null)}
          />
        )
      )}
    </>
  )
}

export default AccruedInterest
