/** @format */

import localize from '../../../../../../Global/Localize'

export const EditAddress = ({ selectedCompany, setSelectedCompany }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressAddressLine1}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressAddressLine1: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressAddressLine2}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressAddressLine2: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressStreet}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressStreet: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('city')}</label>
          <input
            className="form-control"
            value={selectedCompany?.addressCity}
            onChange={e =>
              setSelectedCompany({
                ...selectedCompany,
                addressCity: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressPostalCode}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressPostalCode: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('email')}</label>
          <input
            className="form-control"
            value={selectedCompany?.addressEmail}
            onChange={e =>
              setSelectedCompany({
                ...selectedCompany,
                addressEmail: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('landline')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLandline}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressLandline: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressMobileLine}
            onChange={e =>
              setSelectedCompany({
                ...selectedCompany,
                addressMobileLine: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('internet')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressInternet}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressInternet: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('latitude')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLocationLatitude}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressLocationLatitude: e.target.value,
              })
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('longitude')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLocationLongitude}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                addressLocationLongitude: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
