/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { batchEntryStatus, journalFilter } from '../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import Checkbox from '../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'
import ViewSystemTransaction from '../../TransactionJournal/ViewSystemTransaction/ViewSystemTransaction'

const ReversalBatchEntries = ({
  journalReversalBatchId,
  status,
  hiddenColumns = [],
  handleSelectAllEntries,
  showSelect,
  handleSelectEntry,
  selectedEntries,
  handleRemoveBatchEntries,
  reload,
  setReload,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [batchEntries, setBatchEntries] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [mode, setMode] = useState(null)
  const [entry, setEntry] = useState({})
  const [reqParams, setReqParams] = useState({
    status: status ?? Object.keys(batchEntryStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    journalFilter: Object.keys(journalFilter)[5],
  })

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          onCheck={value => {
            handleSelectEntry(r, value)
          }}
          checked={selectedEntries?.find(e => e?.id === r?.id)}
        />
      ),
    },
    {
      label: localize('statusDescription'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('journalCreatedDate'),
      template: r => formatDate(r?.journal?.createdDate, true),
    },
    {
      label: localize('journalBranchDescription'),
      template: r => r?.journal?.branchDescription,
    },
    {
      label: localize('journalPrimaryDescription'),
      template: r => r?.journal?.primaryDescription,
    },
    {
      label: localize('journalSecondaryDescription'),
      template: r => r?.journal?.secondaryDescription,
    },
    {
      label: localize('journalReference'),
      template: r => r?.journal?.reference,
    },
    {
      label: localize('journalTotalValue'),
      template: r => formatCurrency(r?.journal?.totalValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('journalApplicationUserName'),
      template: r => r?.journal?.applicationUserName,
    },
    {
      label: localize('journalEnvironmentUserName'),
      template: r => r?.journal?.environmentUserName,
    },
    {
      label: localize('journalEnvironmentMachineName'),
      template: r => r?.journal?.environmentMachineName,
    },
    {
      label: localize('journalEnvironmentDomainName'),
      template: r => r?.journal?.environmentDomainName,
    },
    {
      label: localize('journalEnvironmentOSVersion'),
      template: r => r?.journal?.environmentOSVersion,
    },
    {
      label: localize('journalEnvironmentMACAddress'),
      template: r => r?.journal?.environmentMACAddress,
    },
    {
      label: localize('journalEnvironmentMotherboardSerialNumber'),
      template: r => r?.journal?.environmentMotherboardSerialNumber,
    },
    {
      label: localize('journalEnvironmentProcessorId'),
      template: r => r?.journal?.environmentProcessorId,
    },
    {
      label: localize('journalEnvironmentIPAddress'),
      template: r => r?.journal?.environmentIPAddress,
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('exceptionMessage'),
      template: r => r?.exceptionMessage,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchBatchEntries = async () => {
    setIsBusy(true)
    const url =
      '/JournalReversalBatch/find-journal-reversal-batch-entries-by-journal-reversal-batch-id-in-page'

    const { data, success } = await getData(
      url,
      {
        ...reqParams,
        journalReversalBatchId,
      },
      false
    )

    if (success) {
      setBatchEntries(data)
      if (reload) setReload(false)
    }
    setIsBusy(false)
  }

  const columnsToDisplay = columns?.filter(
    (_c, i) => hiddenColumns.indexOf(i) === -1
  )

  const handleViewEntry = (entry, mode) => {
    setMode(mode)
    setEntry(entry)
  }

  const handleClose = () => {
    setMode(null)
    setEntry({})
  }

  useEffect(() => {
    fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex, journalReversalBatchId])

  useEffect(() => {
    if (reload) fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {mode && (
            <ViewSystemTransaction
              transaction={entry?.journal}
              modalTitle="transactionJournal"
              handleClose={handleClose}
              mode={mode}
              title="journalEntry"
            />
          )}

          <div className="p-1">
            <div className="row mb-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center col-md-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              {showSelect && (
                <div className="col-md-2">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      onCheck={value => {
                        setSelectAll(value)
                        handleSelectAllEntries(
                          batchEntries.pageCollection,
                          value
                        )
                      }}
                      id="toggleSelection"
                      checked={selectAll}
                    />
                    <label htmlFor="toggleSelection" className="ms-2">
                      {localize('toggleSelection')}
                    </label>
                  </div>
                </div>
              )}
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 200,
                    minWidth: 200,
                  }}
                  enumsKey="BatchEntryStatus"
                  value={reqParams?.status}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                      pageIndex: 0,
                    })
                  }}
                />
              </div>

              <div
                className={`${
                  showSelect ? 'col-md-4' : 'col-md-4 offset-md-2'
                } d-flex align-items-center justify-content-end gap-2`}
              >
                <label htmlFor="search" className="text-capitalize">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                  enumsKey="JournalFilter"
                  value={reqParams.journalFilter}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      journalFilter: e.value,
                    })
                  }}
                />
                <input
                  type="search"
                  id="search"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchBatchEntries()
                    }
                  }}
                />

                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchBatchEntries()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <SimpleTable
                columns={columnsToDisplay}
                data={batchEntries.pageCollection}
                canClickOnce
                onClickOnce={() => {}}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewEntry(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                pageIndex={reqParams.pageIndex}
                pageSize={reqParams.pageSize}
                itemsCount={batchEntries.itemsCount}
                onPageChange={p => {
                  setReqParams({ ...reqParams, pageIndex: p })
                }}
              />
            </div>

            {showSelect && (
              <div className="row py-3 px-1 bg-light mt-3 mx-1">
                <div className="col">
                  <button
                    className="btn btn-danger float-end"
                    onClick={handleRemoveBatchEntries}
                  >
                    {localize('remove')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ReversalBatchEntries
