/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import Checkbox from '../../Checkbox/Checkbox'
import SimpleTable from '../../Tables/SimpleTable'

const SavingsAccounts = ({
  data,
  savingAccounts,
  handleSelectSaving,
  canSelect,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={savingAccounts?.find(x => x?.id === r?.id)}
          onCheck={value => {
            handleSelectSaving(r, value)
          }}
        />
      ),
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('productName'),
      template: r => r?.customerAccountTypeTargetProductDescription,
    },
    {
      label: localize('productSection'),
      template: r => r?.customerAccountTypeTargetProductSectionDescription,
    },
    {
      label: localize('bookBalance'),
      template: r => formatCurrency(r?.bookBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('availableBalance'),
      template: r => formatCurrency(r?.availableBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('recordStatus'),
      template: r => r?.recordStatusDescription,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const columnsToDisplay = columns.filter((_col, i) => i !== 0)

  return (
    <>
      <SimpleTable
        data={data}
        columns={canSelect ? columns : columnsToDisplay}
      />
    </>
  )
}

export default SavingsAccounts
