/** @format */

import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import SalaryPaySlips from './Partials/SalaryPaySlips'
import SalaryPeriodLookup from '../../../../../Components/Lookup/SalaryPeriodLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'

const Payslips = () => {
  const [salaryPaySplip, setSalaryPaySplip] = useState(null)
  return (
    <>
      <BreadCrumb
        tree={[localize('humanResourceManagement'), localize('salaryPaySplip')]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('salaryPeriod')}
                  </label>
                  <SalaryPeriodLookup
                    displayValue={salaryPaySplip?.monthDescription}
                    onSelect={r => {
                      setSalaryPaySplip({
                        ...salaryPaySplip,
                        id: r.id,
                        monthDescription: r.monthDescription,
                        employeeTypeDescription: r.employeeTypeDescription,
                        statusDescription: r.statusDescription,
                        totalNetPay: r.totalNetPay,
                        valueDate: r.valueDate,
                        createdBy: r.createdBy,
                        createdDate: r.createdDate,
                        remarks: r.remarks,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('employeeType')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={salaryPaySplip?.employeeTypeDescription}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('status')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={salaryPaySplip?.statusDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('valueDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formatDate(salaryPaySplip?.valueDate)}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('createdBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={salaryPaySplip?.createdBy}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('createdDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={formatDate(salaryPaySplip?.createdDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4 d-flex align-items-center ">
                  <label className="col-md-2 text-capitalize">
                    {localize('totalNetPay')}
                  </label>
                  <MaskNumber
                    className="ms-5"
                    defaultMaskValue={salaryPaySplip?.totalNetPay}
                    disabled
                  />
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-2">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-5"
                    value={salaryPaySplip?.remarks}
                    disabled
                  />
                </div>
              </div>
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('paySlips')}
                </div>
                <SalaryPaySlips paySlip={salaryPaySplip} />
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payslips
