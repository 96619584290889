/** @format */

import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { getCommissions } from '../../../../../Helpers/selectablesHelper'
import { getData } from '../../../../../Helpers/webApi'
import ApplicableCharges from '../../ApplicableCharges/ApplicableCharges'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'

const ViewApplicableCharges = ({ mode, handleClose, morgueService }) => {
  const [commissions, setCommissions] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCommissions, setSelectedCommissions] = useState([])

  const fetchCommissions = async () => {
    const data = await getCommissions()
    setCommissions(data)
  }

  const fetchCommissionsToBeSelected = async () => {
    setIsBusy(true)
    const url = '/MorgueService/find-commissions-by-morgue-service-id'

    const { data, success } = await getData(
      url,
      {
        morgueServiceId: morgueService?.id,
      },
      false
    )

    if (success) {
      setSelectedCommissions(data?.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (morgueService?.id) {
      fetchCommissions()
      fetchCommissionsToBeSelected()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [morgueService?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      hideUpdate
      modalTitle={`${localize('applicableCharges')} - ${
        morgueService?.description
      }`}
      disableClose={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <ApplicableCharges
          commissions={commissions}
          disabled
          selectedCommissions={selectedCommissions}
        />
      )}
    </ModalTemplate>
  )
}

export default ViewApplicableCharges
