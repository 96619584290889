/** @format */

import React, { useState, useEffect } from 'react'
import GoodsDispatchNoteEntriesItems from './GoodsDispatchNoteEntriesItems'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import Tab from '../../../../../../Components/Tabs/Tab'
import { getData } from '../../../../../../Helpers/webApi'

const ViewGoodsDispatchNote = ({ mode, goodsDispatchNote, closeModal }) => {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsDispatchNote/find-goods-dispatch-note-items-by-goods-dispatch-note-id-filter-in-page`,
      {
        goodsDispatchNoteId: goodsDispatchNote?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [goodsDispatchNote?.id, reqParams.pageSize, reqParams.pageIndex])

  const closePage = () => {
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={'view'}
      modalTitle="goodsDispatchNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.paddedReferenceNumber}
              />
            </div>

            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('department')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.departmentDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.branchDescription}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                defaultValue={goodsDispatchNote?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                defaultValue={formatDate(goodsDispatchNote?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('employee')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.customerFullName}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('goodsDispatchNoteItems'),
                  item: (
                    <GoodsDispatchNoteEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewGoodsDispatchNote
