/** @format */

import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import CreateGroupTextMessage from './CreateGroupTextMessage/CreateGroupTextMessage'
import CreateQuickText from './CreateQuickText/CreateQuickText'

const TextAlerts = () => {
  const [textAlerts, setTextAlerts] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [customer, setCustomer] = useState({})
  const [textAlert, setTextAlert] = useState({})
  const [reqParams, setReqParams] = useState({
    text: '',
    textAlertFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [groupBy, setGroupBy] = useState({
    label: 'Priority',
    value: 3,
    column: 'textMessagePriorityDescription',
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('recipient'),
      template: r => r?.textMessageRecipient,
    },
    {
      label: localize('message'),
      template: r => r?.maskedTextMessageBody,
    },
    {
      label: localize('floatBalance'),
      template: r => formatCurrency(r?.textMessageAvailableBalance),
    },
    {
      label: localize('DLRStatus'),
      template: r => r?.textMessageDLRStatusDescription,
    },
    {
      label: localize('DLRReference'),
      template: r => r?.textMessageReference,
    },
    {
      label: localize('origin'),
      template: r => r?.textMessageOriginDescription,
    },
    {
      label: localize('catalyst'),
      template: r => r?.textMessageCatalyst,
    },
    {
      label: localize('category'),
      template: r => r?.textMessageCategoryDescription,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.paddedCustomerAccountNumber,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerPersonalFileNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerReference1,
    },
    {
      label: localize('membershipNumber'),
      template: r => r?.customerReference2,
    },
    {
      label: localize('priority'),
      template: r => r?.textMessagePriorityDescription,
    },
    {
      label: localize('sendRetry'),
      template: r => r?.textMessageSendRetry,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'textMessageRecipient' },
    { value: 1, column: 'textMessageOriginDescription' },
    { value: 2, column: 'textMessageCatalyst' },
    { value: 3, column: 'textMessagePriorityDescription' },
    { value: 4, column: 'textMessageDLRStatusDescription' },
    {
      value: 5,
      column: 'textMessageCategoryDescription',
    },
    {
      value: 6,
      column: 'createdBy',
    },
  ]

  const fetchTextAlerts = async () => {
    setIsBusy(true)

    const url = '/TextAlert/find-text-alerts-by-date-range-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setTextAlerts(data)
    }

    setIsBusy(false)
  }

  const handleClickAddButton = mode => {
    setMode(mode)
    setTextAlert({})
  }

  const handleClose = () => {
    setMode(null)
    setTextAlert({})
  }

  const handleSelectUser = (user, value) => {
    if (value) {
      setSelectedUsers([...selectedUsers, user])
    } else {
      setSelectedUsers(selectedUsers.filter(u => u.id !== user.id))
    }
  }

  const handleCreateGroupMessage = async () => {
    if (!textAlert?.message)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter a message',
      })

    if (!selectedUsers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select at least one user',
      })

    setLoadingCreate(true)

    const url = '/BulkMessageBatch/add-text-alerts-with-history'

    const { success } = await postData(
      url,
      {
        textMessageBody: textAlert?.message,
        appendSignature: textAlert?.appendSignature,
        recipients: selectedUsers?.map(u => u.id).join(','),
        recipientDescriptions: selectedUsers?.map(u => u.description).join(','),
      },
      false
    )

    if (success) {
      handleClose()
      fetchTextAlerts()
      setSelectedUsers([])
      setLoadingCreate(false)
      showNotification('Create Group Text Message', 'success')
    } else {
      setLoadingCreate(false)
    }
  }

  const handleLookupCustomer = async c => {
    setCustomer(c)
  }

  useEffect(() => {
    fetchTextAlerts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'add' && (
        <CreateGroupTextMessage
          handleClose={handleClose}
          mode={mode}
          handleAction={handleCreateGroupMessage}
          isBusy={loadingCreate}
          textAlert={textAlert}
          setTextAlert={setTextAlert}
          handleSelectUser={handleSelectUser}
          selectedUsers={selectedUsers}
        />
      )}

      {mode === 'quickText' && (
        <CreateQuickText
          handleClose={handleClose}
          mode={mode}
          setTextAlert={setTextAlert}
          textAlert={textAlert}
          isBusy={loadingCreate}
          customer={customer}
          handleLookupCustomer={handleLookupCustomer}
          setCustomer={setCustomer}
          fetchTextAlerts={fetchTextAlerts}
        />
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('textAlerts'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
            <AddNewButton
              handleClickAddButton={() => handleClickAddButton('add')}
            />
            <AddNewButton
              handleClickAddButton={() => handleClickAddButton('quickText')}
              message="quickText"
            />
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={groupBy?.value}
                    enumsKey="TextAlertPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                    customStyle={{
                      minWidth: 200,
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.textAlertFilter}
                    enumsKey="TextAlertFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, textAlertFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchTextAlerts()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchTextAlerts()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={textAlerts?.pageCollection || []}
                groupBy={groupBy}
              />
              <Pager
                itemsCount={textAlerts?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TextAlerts
