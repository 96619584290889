/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import GroupedTable from '../../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../../Components/Tables/Pager'
import localize from '../../../../../../Global/Localize'
import { formatDate, parseDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import RegisterPatient from './RegisterPatient/RegisterPatient'
import ReleaseMorguePatient from './ReleaseMorguePatient/ReleaseMorguePatient'
import ViewMorguePatient from './ViewMorguePatient/ViewMorguePatient'
import MorgueStatement from './MorgueStatement/MorgueStatement'

const Register = () => {
  const [reqParams, setReqParams] = useState({
    text: '',
    morguePatientFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [patients, setPatients] = useState([])
  const [search, setSearch] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [patient, setPatient] = useState({})

  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'recordStatusDescription',
    value: 0,
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.fullName}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r?.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{parseDate(r?.individualBirthDate)}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'recordStatusDescription' },
    { value: 1, column: 'roomTypeDescription' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'modifiedBy' },
    { value: 4, column: 'individualIdentityCardTypeDescription' },
    {
      value: 5,
      column: 'individualSalutationDescription',
    },
    {
      value: 6,
      column: 'individualGenderDescription',
    },
    {
      value: 7,
      column: 'individualNationalityDescription',
    },
  ]

  async function fetchPatients() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/MorguePatient/find-morgue-patients-by-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setPatients(data)
    }

    setIsBusy(false)
  }

  const handleSelectPatient = (r, mode) => {
    setMode(mode)
    setPatient(r)
  }

  const handleClickAddButton = () => {
    setMode('add')
    setPatient({})
  }

  const handleClose = () => {
    setMode(null)
    setPatient({})
  }

  useEffect(() => {
    fetchPatients()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <RegisterPatient
          mode={mode}
          patientData={patient}
          handleClose={handleClose}
          fetchPatients={fetchPatients}
        />
      )}

      {mode === 'release' && (
        <ReleaseMorguePatient
          fetchPatients={fetchPatients}
          handleClose={handleClose}
          mode={mode}
          patientData={patient}
        />
      )}
      {mode === 'view' && (
        <ViewMorguePatient
          handleClose={handleClose}
          mode={mode}
          patientData={patient}
        />
      )}

      {mode === 'statement' && (
        <MorgueStatement
          handleClose={handleClose}
          mode={mode}
          patient={patient}
          morguePatient={patient}
        />
      )}

      <BreadCrumb
        tree={[
          localize('morgueManagement'),
          localize('morguePatients'),
          localize('register'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <AddNewButton handleClickAddButton={handleClickAddButton} />
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center my-2">
                  <label
                    className="text-capitalize"
                    htmlFor="morguePatientLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="morguePatientLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageCount: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 180,
                      minWidth: 180,
                    }}
                    value={groupBy?.value}
                    enumsKey="MorguePatientPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>

                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      text: search,
                    })
                  }}
                >
                  <label
                    className="ms-2 text-capitalize me-3"
                    htmlFor="searchTable"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.morguePatientFilter}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey={'MorguePatientFilter'}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        morguePatientFilter: e.value,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-3"
                    defaultValue={reqParams.text}
                    onChange={e => {
                      setSearch(e.target.value)
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchPatients()
                      }
                    }}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchPatients()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="py-1">
                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={patients?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleSelectPatient(r, 'statement')
                        }}
                      >
                        <i className="uil uil-files-landscapes-alt"></i>
                        {localize('statement')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleSelectPatient(r, 'view')
                        }}
                      >
                        <i className="uil uil-eye"></i>
                        {localize('view')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleSelectPatient(r, 'edit')
                        }}
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleSelectPatient(r, 'release')
                        }}
                      >
                        <i className="uil uil-user-times"></i>
                        {localize('release')}
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={patients.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Register
