/** @format */

import localize from '../../../../../../Global/Localize'

export const ViewAddress = ({ selectedCompany }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressAddressLine1}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressAddressLine2}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressStreet}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('city')}</label>
          <input
            className="form-control"
            value={selectedCompany?.addressCity}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressPostalCode}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('email')}</label>
          <input
            className="form-control"
            value={selectedCompany?.addressEmail}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('landline')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLandline}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressMobileLine}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('internet')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressInternet}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('latitude')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLocationLatitude}
            disabled
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('longitude')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.addressLocationLongitude}
            disabled
          />
        </div>
      </div>
    </>
  )
}
