/** @format */
import React, { useState } from 'react'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { fixedDepositAuthOption } from '../../../../Global/enumeration'
import FixedDepositLookup from '../../../../Components/Lookup/FixedDepositLookup'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'

const FixedDepositsAuthorization = () => {
  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },

    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [tabWaiting, setTabWaiting] = useState(false)
  const [iFixedDepositAuthorization, setIFixedDepositAuthorization] =
    useState(null)

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(fixedDepositAuthOption)[0]
  )
  const [tableData, setTableData] = useState([])

  const loadPayablesTableData = async r => {
    setTabWaiting(true)
    const { success, data } = await getData(
      `/FixedDeposit/find-fixed-deposit-payables-by-fixed-deposit-id?fixedDepositId=${r.id}`,
      {},
      false
    )
    if (success) {
      setTableData(data.result || [])
    }
    setTabWaiting(false)
  }

  async function updateFixedDepositAuthorization() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/FixedDeposit/authorize-fixed-deposit',
      {
        fixedDeposit: {
          status: 64,
          ...iFixedDepositAuthorization,
        },
        fixedDepositAuthOption: selectedAuth,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setIFixedDepositAuthorization(null)
      setGlobalValidationErrors({})
      showNotification(
        'Fixed Deposit Authorization',
        'success',
        'Operation completed successfully!'
      )
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('authorizeFixedDeposit'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.Id']?.message
                    }
                  >
                    <label className="col-3 text-capitalize">
                      {localize('fixedDepositType')}
                    </label>
                    <FixedDepositLookup
                      displayValue={
                        iFixedDepositAuthorization?.fixedDepositTypeDescription
                      }
                      onSelect={r => {
                        clearValidation('FixedDeposit.Id')
                        setIFixedDepositAuthorization({
                          ...iFixedDepositAuthorization,
                          id: r.id,
                          customerAccountId: r.customerAccountId,
                          branchId: r.branchId,
                          fixedDepositTypeDescription:
                            r.fixedDepositTypeDescription,
                          fixedDepositTypeId: r.fixedDepositTypeId,
                          paddedSerialNumber: r.paddedSerialNumber,
                          customerAccountFullAccountNumber:
                            r.customerAccountFullAccountNumber,
                          customerAccountCustomerFullName:
                            r.customerAccountCustomerFullName,
                          customerAccountCustomerMembershipClassCustomerTypeDescription:
                            r.customerAccountCustomerMembershipClassCustomerTypeDescription,
                          customerAccountCustomerIndividualIdentityCardNumber:
                            r.customerAccountCustomerIndividualIdentityCardNumber,
                          customerAccountCustomerReference1:
                            r.customerAccountCustomerReference1,
                          customerAccountCustomerReference2:
                            r.customerAccountCustomerReference2,
                          customerAccountCustomerReference2:
                            r.customerAccountCustomerReference2,
                          categoryDescription: r.categoryDescription,
                          value: r.value,
                          rate: r.rate,
                          term: r.term,
                          maturityActionDescription:
                            r.maturityActionDescription,
                          remarks: r.remarks,
                          createdBy: r.createdBy,
                          createdDate: r.createdDate,
                          isLocked: r.isLocked,
                          auditedBy: r.auditedBy,
                          auditedDate: r.auditedDate,
                          auditRemarks: r.auditRemarks,
                        })
                        loadPayablesTableData(r)
                      }}
                      status={64}
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fixedDepositNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.paddedSerialNumber || ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fullAccountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.customerAccountFullAccountNumber ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('customerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerFullName ||
                        ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('identityCardNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerIndividualIdentityCardNumber ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerReference1 ||
                        ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('membershipNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerReference2 ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.customerAccountCustomerReference3 ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('category')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositAuthorization?.categoryDescription || ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('value')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatCurrency(iFixedDepositAuthorization?.value) || ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('annualPercentageRate')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatAsPercent(iFixedDepositAuthorization?.rate) || ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('term(month)')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositAuthorization?.term || ''}
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('maturityAction')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositAuthorization?.maturityActionDescription ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={iFixedDepositAuthorization?.remarks || ''}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('createdBy')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositAuthorization?.createdBy || ''}
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('createdDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatDate(iFixedDepositAuthorization?.createdDate) ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <Checkbox
                      id="fixedDepositIsLocked"
                      disabled
                      checked={iFixedDepositAuthorization?.isLocked}
                    />

                    <label
                      htmlFor="fixedDepositIsLocked"
                      className="text-capitalize col-3"
                    >
                      {localize('isLocked')}
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('verifiedBy')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositAuthorization?.auditedBy || ''}
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('verifiedDateDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatDate(iFixedDepositAuthorization?.auditedDate) ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('verificationRemarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositAuthorization?.auditRemarks || ''}
                      disabled
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('fixedDepositPayables'),
                        item: (
                          <>
                            {tabWaiting ? (
                              <Loader />
                            ) : (
                              <div className="row col-12 mt-2">
                                <SimpleTable
                                  data={tableData}
                                  columns={columns}
                                />
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <div
                      className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
                      validation-message={
                        globalValidationErrors[
                          'FixedDeposit.AuthorizationRemarks'
                        ]?.message
                      }
                    >
                      <label className="text-capitalize col-3 me-2">
                        {localize('remarks')}
                      </label>
                      <input
                        defaultValue={
                          iFixedDepositAuthorization?.authorizationRemarks
                        }
                        type="text"
                        className="form-control ms-4"
                        onChange={e => {
                          clearValidation('FixedDeposit.AuthorizationRemarks')
                          setIFixedDepositAuthorization({
                            ...iFixedDepositAuthorization,
                            authorizationRemarks: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-2 px-4 mt-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="FixedDepositAuthOption"
                        value={selectedAuth}
                        onChange={e => setSelectedAuth(e.value)}
                      />
                    </div>
                    <button
                      onClick={() => {
                        updateFixedDepositAuthorization()
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default FixedDepositsAuthorization
