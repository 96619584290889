/** @format */

import localize from '../../../../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../../../../Components/Tables/SimpleTable'

export const CustomerAccountPreviewSigningInstructions = ({
  loading,
  signingInstructions,
}) => {
  const columns = [
    {
      label: localize('ManagementActionDescription'),
      template: r => <>{r.managementActionDescription}</>,
    },
    { label: localize('Remarks'), template: r => <>{r.remarks}</> },
    { label: localize('CreatedBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('CreatedDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={signingInstructions} />
      )}
    </>
  )
}
