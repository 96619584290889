/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../Global/Localize'
import ListingPage from '../../../Components/ListingPage/Index'
import { formatDate } from '../../../Helpers/formatters'
import DocumentRegisterModal from './DocumentRegisterModal'
import {
  CustomerDocumentType,
  CustomerFilter,
  ProductSection,
} from '../../../Global/enumeration'
import { getData } from '../../../Helpers/webApi'

function DocumentRegister() {
  const [modalMode, setModalMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [documentRegisterModel, setDocumentRegisterModel] = useState(null)
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    Object.keys(CustomerDocumentType)[0]
  )
  const columns = [
    { label: localize('type'), template: r => <>{r.typeDescription}</> },
    {
      label: localize('documentReference'),
      template: r => <>{r.documentReference}</>,
    },
    { label: localize('tile'), template: r => <>{r.fileTitle}</> },
    { label: localize('miMeTypef'), template: r => <>{r.fileMIMEType}</> },
    { label: localize('fullName'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('collateralValue'),
      template: r => <>{r.collateralValue}</>,
    },
    {
      label: localize('collateralAdvanceRate'),
      template: r => <>{r.collateralAdvanceRate}</>,
    },
    {
      label: localize('collateralStatus'),
      template: r => <>{r.collateralStatusDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.customerIndividualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('customerSerialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('street'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const getCustomerDocument = async r => {
    const { success, data } = await getData('/CustomerDocument/find-by-id', {
      customerDocumentId: r.id,
    })
    if (success) {
      setDocumentRegisterModel(data)
    }
  }

  return (
    <div>
      <ListingPage
        auxiliaryFilters={[
          {
            param: 'customerFilter',
            defaultFilter: 0,
            filterType: CustomerFilter,
          },
        ]}
        useText
        refreshAt={refreshAt}
        onAdd={() => {
          setDocumentRegisterModel(null)
          setModalMode('add')
        }}
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                setModalMode('edit')
                getCustomerDocument(r)
                setSelectedDocumentType(r.type)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('edit')}
            </div>
            <div
              onClick={() => {
                setModalMode('verify')
                getCustomerDocument(r)
                setSelectedDocumentType(r.type)
                setDocumentRegisterModel(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-comment-verify"></i>
              {localize('verify')}
            </div>
          </>
        )}
        url="/CustomerDocument/find-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        map={[localize('registryManagement'), localize('documentRegister')]}
      />
      <DocumentRegisterModal
        selectedDocumentType={selectedDocumentType}
        setSelectedDocumentType={setSelectedDocumentType}
        documentRegisterModel={documentRegisterModel}
        setDocumentRegisterModel={setDocumentRegisterModel}
        mode={modalMode}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
        refreshAt={refreshAt}
        onSetMode={m => {
          setModalMode(m)
        }}
        onHideModal={() => {
          setModalMode(null)
        }}
      />
    </div>
  )
}

export default DocumentRegister
