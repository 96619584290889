/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import { ProductCode } from '../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import localize from '../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../Components/Loaders/Loader'
import { useEffect } from 'react'

const CreateOverDraftProtection = ({
  mode,
  handleClose,
  fetchOverdrafts,
  overdraftId,
}) => {
  const [overDraft, setOverDraft] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const handleCloseModal = () => {
    handleClose()
    setOverDraft({})
  }

  const fetchCustomerAccountProtection = async () => {
    setIsBusy(true)
    const url = '/CustomerAccount/find-customer-account-overdraft-protection'

    const { success, data } = await getData(
      url,
      {
        customerAccountOverdraftProtectionId: overdraftId,
      },
      false
    )

    if (success) {
      setOverDraft({
        ...data,
        overDraftRemarks: data?.remarks,
      })
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleLookupCustomerAAccount = customerAccount => {
    if (
      customerAccount?.customerAccountTypeProductCode !==
      Object.keys(ProductCode)[0]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select a savings account',
      })

    setOverDraft({
      ...overDraft,
      ...customerAccount,
    })
  }

  const handleCreateOverDraft = async () => {
    // ! TODO: myke prevent selecting logged in user's account 01/10/2023
    if (!overDraft?.id)
      return Swal.fire({
        icon: 'error',
        title: 'No account selected',
        text: 'Please select an account',
      })
    if (!overDraft?.overdraftLimit)
      return Swal.fire({
        icon: 'error',
        title: 'No overdraft limit',
        text: 'Please enter an overdraft limit',
      })

    const url = '/CustomerAccount/create-customer-account-overdraft-protection'

    setIsBusy(true)

    const { success } = await postData(
      url,
      {
        ...overDraft,
        customerAccountCustomerId: overDraft?.customerId,
        customerAccountId: overDraft?.id,
        overdraftLimit: overDraft?.overdraftLimit,
        remarks: overDraft?.overDraftRemarks,
      },
      false
    )

    if (success) {
      showNotification('Create Overdraft Protection', 'success')
      fetchOverdrafts()
      handleCloseModal()
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleUpdateOverDraft = async () => {
    const url = '/CustomerAccount/update-customer-account-overdraft-protection'

    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        ...overDraft,
        overdraftLimit: overDraft?.overdraftLimit,
        remarks: overDraft?.overDraftRemarks,
      },
      false
    )

    if (success) {
      showNotification('Update Overdraft Protection', 'success')
      fetchOverdrafts()
      handleCloseModal()
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (overdraftId) fetchCustomerAccountProtection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdraftId])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={
        mode === 'add' ? 'CreateOverdraftProtection' : 'EditOverdraftProtection'
      }
      modalClassName="modal-xl"
      actionText={mode === 'add' ? 'Create' : 'Update'}
      disableCreate={isBusy}
      handleClose={handleCloseModal}
      handleAction={
        mode === 'add' ? handleCreateOverDraft : handleUpdateOverDraft
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            onCustomerLookup={handleLookupCustomerAAccount}
            customer={
              mode === 'add'
                ? overDraft
                : {
                    id: overDraft?.customerAccountId,
                  }
            }
            refreshFromServer={mode === 'edit'}
          />

          <div className="row g-2">
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 me-2">
                {localize('overDraftLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={overDraft?.overdraftLimit}
                onMaskNumber={e => {
                  setOverDraft({
                    ...overDraft,
                    overdraftLimit: e.target.rawValue,
                  })
                }}
              />
            </div>

            <div className="col-md-9 d-flex align-items-center">
              <label className="text-capitalize col-1 me-4">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control ms-3"
                value={overDraft?.overDraftRemarks}
                onChange={e => {
                  setOverDraft({
                    ...overDraft,
                    overDraftRemarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateOverDraftProtection
