/** @format */

import React, { useState, useEffect } from 'react'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { getData } from '../../../../Helpers/webApi'
import MicroCreditGroupModal from './Partials/MicroCreditGroupModal'
import Tab from '../../../../Components/Tabs/Tab'
import { ListGroupMembers } from './Partials/ListGroupMembers'
import { formatDate } from '../../../../Helpers/formatters'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'

function Groups() {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedValues, setSelectedValues] = useState({})
  const [sortedBy, setSortedBy] = useState(null)
  const [clickedOnce, setClickedOnce] = useState(false)

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerNonIndividualDescription}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('groupType'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('purpose'), template: r => <>{r.purpose}</> },
    { label: localize('activities'), template: r => <>{r.activities}</> },
    {
      label: localize('meetingFrequency'),
      template: r => <>{r.meetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDay'),
      template: r => <>{r.meetingDayOfWeekDescription}</>,
    },
    { label: localize('meetingTime'), template: r => <>{r.meetingTime}</> },
    { label: localize('meetingPlace'), template: r => <>{r.meetingPlace}</> },
    {
      label: localize('minimumMembers'),
      template: r => <>{r.minimumMembers}</>,
    },
    {
      label: localize('maximumMembers'),
      template: r => <>{r.maximumMembers}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('creditOfficer'),
      template: r => <>{r.microCreditOfficerEmployeeCustomerFullName}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/MicroCreditGroup/find-micro-credit-groups-by-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setIsBusy(false)
    }
  }

  const handleOnClickOnce = r => {
    setClickedOnce(true)
    setSelectedValues({ ...r })
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnContextMenu = row => {
    return (
      <>
        <>
          <div onClick={() => handleOnEdit(row)} className="ctxt-menu-item">
            <i className="uil uil-eye"></i>
            {localize('edit')}
          </div>
        </>
      </>
    )
  }

  const handleOnEdit = row => {
    setMode('edit')
    setSelectedValues({ ...row })
  }
  const handleOnAdd = () => {
    setMode('add')
  }
  return (
    <>
      <BreadCrumb
        tree={[localize('microCreditManagement'), localize('groups')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-3">
                {isBusy ? (
                  <Loader />
                ) : error ? (
                  <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                    <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                    <div className="fs-5 text-danger">Failed to Load Data</div>
                  </div>
                ) : (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <AddNewButton handleClickAddButton={handleOnAdd} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex align-items-center col-3">
                        <label
                          htmlFor="recordsPerPage"
                          className="text-capitalize"
                          style={{ marginLeft: -10 }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          name=""
                          id="recordsPerPage"
                          className="form-select ms-3"
                          style={{ maxWidth: 60 }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="col-6"></div>
                      <form
                        className="d-flex align-items-center col-3 col"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                          })
                        }}
                      >
                        <label
                          htmlFor="searchTable"
                          className="text-capitalize"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                    </div>
                    <div className="row col-12 mt-2">
                      <SimpleTable
                        canClickOnce
                        onClickOnce={handleOnClickOnce}
                        contextMenu={handleOnContextMenu}
                        data={tableData}
                        columns={columns}
                        onSort={(c, i) => handleSort(c, i)}
                        sortedBy={sortedBy}
                      />
                    </div>
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  {clickedOnce && (
                    <Tab
                      tabItems={[
                        {
                          label: localize('groupMembers'),
                          item: (
                            <ListGroupMembers
                              selectedMicroCreditGroup={selectedValues}
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <MicroCreditGroupModal
          selectedMicroCreditGroup={selectedValues}
          setSelectedMicroCreditGroup={setSelectedValues}
          onUpdateRefreshAt={r => {
            setRefreshAt(!refreshAt)
          }}
          onSetMode={m => {
            setMode(m)
          }}
          mode={mode}
          onHideModal={m => {
            setMode(null)
          }}
        />
      )}
    </>
  )
}

export default Groups
