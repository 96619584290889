/** @format */

import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import {
  debitCardStatus,
  recordAuthOption,
} from '../../../../../Helpers/constants'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const initialChannel = {
  recordAuthOption: recordAuthOption.APPROVE,
  remarks: '',
}

const VerifyDebitCardRegister = ({ mode, handleClose, fetchCards, card }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [cardData, setCardData] = useState(initialChannel)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleVerifyAccount = async () => {
    setIsBusy(true)

    const url = '/AlternateChannel/update-debit-card-register'

    const { success } = await putData(
      url,
      {
        ...card,
        remarks: cardData?.remarks,
        status:
          cardData?.recordAuthOption === recordAuthOption.APPROVE
            ? debitCardStatus.APPROVED
            : debitCardStatus.REJECTED,
      },
      false
    )

    if (success) {
      showNotification('Verify Debit Card', 'success')
      fetchCards()
      handleClose()
      setCardData(initialChannel)
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalTitle="debitCardRecord"
      cancelText="close"
      modalMode={mode}
      handleClose={() => {
        handleClose()
        setCardData(initialChannel)
      }}
      modalClassName="modal-xl"
      handleAction={handleVerifyAccount}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={globalValidationErrors['Remarks']?.message}
          >
            <label className="text-capitalize">{localize('remarks')}</label>
            <input
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('Remarks')
                setCardData({
                  ...cardData,
                  remarks: e.target.value,
                })
              }}
              disabled={isBusy}
            />
          </div>
          <EnumsServerCombo
            disabled={isBusy}
            customContainerStyle={{
              maxWidth: 150,
              minWidth: 150,
            }}
            enumsKey="RecordAuthOption"
            value={cardData?.recordAuthOption || ''}
            onChange={e => {
              setCardData({
                ...cardData,
                recordAuthOption: e.value,
              })
            }}
            sort
          />
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            customer={{
              id: card?.customerAccountId,
            }}
            readOnly
            refreshFromServer
          />
          <div className="row g-3">
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 ms-2">
                {localize('cardType')}
              </label>

              <input
                type="text"
                disabled
                className="form-control"
                value={card?.cardTypeDescription}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 ms-2">
                {localize('issuingNetwork')}
              </label>

              <input
                type="text"
                disabled
                className="form-control"
                value={card?.issuingNetworkDescription}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('dailyLimit')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2 text-end"
                value={formatCurrency(card?.dailyLimit, null, null, false)}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 me-2">
                {localize('validFrom')}
              </label>

              <input
                type="text"
                disabled
                className="form-control"
                value={card?.validFrom}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('expires')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2"
                value={card?.expires}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('pan/CardNumber')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2"
                value={card?.primaryAccountNumber}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('remarks')}
              </label>

              <input
                className="form-control ms-1"
                value={card?.remarks}
                disabled
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifyDebitCardRegister
