/** @format */
import localize from '../../../../Global/Localize'
import { SuppressedPermissions } from './SuppressedPermissions'
import { parseDate } from '../../../../Helpers/formatters'
import Loader from '../../../../Components/Loaders/Loader'

export const VerifyTemporaryRole = ({
  suppressedPermissionsTemporaryRoles,
  temporaryRoleEntity,
  isBusy,
}) => {
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <div className = 'relative'>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3">{localize('employee')}</label>
              <input
                className="form-control"
                disabled
                defaultValue={temporaryRoleEntity?.employeeCustomerFullName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={temporaryRoleEntity?.employeeBranchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  temporaryRoleEntity?.employeeDesignationDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  temporaryRoleEntity?.employeeDepartmentDescription
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('startDate')}
              </label>
              <input
                disabled
                className="form-control"
                defaultValue={parseDate(temporaryRoleEntity?.durationStartDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('startTime')}
              </label>
              <input
                disabled
                className="form-control"
                defaultValue={temporaryRoleEntity?.timeDurationStartTime}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('endTime')}
              </label>
              <input
                disabled
                className="form-control"
                defaultValue={temporaryRoleEntity?.timeDurationEndTime}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('endDate')}
              </label>
              <input
                disabled
                className="form-control"
                defaultValue={parseDate(temporaryRoleEntity?.durationEndDate)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('roleName')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={temporaryRoleEntity?.roleName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('reason')}
              </label>
              <input
                disabled
                className="form-control"
                defaultValue={temporaryRoleEntity?.reason}
              />
            </div>
          </div>

          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
                marginBottom: -10,
              }}
            >
              {localize('suppressedPermissions')}
            </div>
            <SuppressedPermissions data={suppressedPermissionsTemporaryRoles} />
          </div>
        </div>
      )}
    </>
  )
}
