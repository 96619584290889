/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import { DataAttachmentPeriodStatus } from '../../../../../Global/enumeration'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import { formatDate } from '../../../../../Helpers/formatters'
import CheckOffDataPeriodModal from './Partials/CheckOffDataPeriodModal'
import { FiltersContainer } from '../../../../../Components/Tables/FiltersContainer'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function Periods() {
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedRow, setSelectedRow] = useState({})
  const [reqParams, setReqParams] = useState({})
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DataAttachmentPeriod/find-data-attachment-periods-filter-in-page',
      {
        status: Object.keys(DataAttachmentPeriodStatus)[0],
        pageIndex: 0,
        pageSize: 10,
        text: '',
        startDate: null,
        dateRequestsFilter: 15,
        endDate: null,
        customDate: false,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('creditType'),
      template: r => <>{r.creditTypeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('authorizedBy'), template: r => <>{r.authorizedBy}</> },
    {
      label: localize('authorizedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('isActive'), template: r => <>{r.isActive}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const handleOnEdit = r => {
    setSelectedRow(r)
    setMode('edit')
  }

  const handleOnAdd = () => {
    setMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('creditManagement'), localize('dataAttachmentPeriods')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <FiltersContainer>
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor="recordsPerPage"
                          className="text-capitalize"
                          style={{ marginLeft: -10 }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          name=""
                          id="recordsPerPage"
                          className="form-select ms-3"
                          style={{ maxWidth: 60 }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                      <div></div>
                      <div className="d-flex align-items-center justify-content-end">
                        <label
                          htmlFor="searchTable"
                          className="text-capitalize me-2"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="DataAttachmentPeriodStatus"
                          value={reqParams.status}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                            })
                          }}
                        />

                        <form
                          className="d-flex align-items-center col-5 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              text: search,
                            })
                          }}
                        >
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.text}
                            onChange={e => setSearch(e.target.value)}
                          ></input>
                        </form>
                      </div>
                    </FiltersContainer>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              handleOnEdit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CheckOffDataPeriodModal
        selectedCheckOffDataPeriod={selectedRow}
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default Periods
