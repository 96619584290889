/** @format */

/* eslint-disable */
import React from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import ListClosingAndCatalogue from '../Reusables/ListClosingAndCatalogue/ListClosingAndCatalogue'

function Closing() {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankReconciliation'),
          localize('closing'),
        ]}
      />
      <ListClosingAndCatalogue showUpdate />
    </>
  )
}

export default Closing
