/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'

function EditableCell({ value, maxWidth, onChange, onBlur }) {
  const [editable, setEditable] = useState(false)
  const [initialValue, setInitialValue] = useState(value)

  useEffect(() => {
    onChange && onChange(initialValue)
    //eslint-disable-next-line
  }, [initialValue])

  useEffect(
    () => {
      onBlur && onBlur(initialValue)
    },
    //eslint-disable-next-line
    [editable]
  )
  return editable ? (
    <div style={{ width: 'fit-content' }}>
      <input
        className="form-control"
        // style={{ maxWidth: maxWidth || 'fit-content' }}
        value={initialValue}
        onChange={e => setInitialValue(e.target.value)}
        onBlur={() => setEditable(false)}
        autoFocus={true}
      />
    </div>
  ) : (
    <div
      style={{ width: '100%', height: '100%' }}
      onDoubleClick={() => setEditable(true)}
    >
      {initialValue || <span style={{ opacity: 0.3 }}>Enter Formula...</span>}
    </div>
  )
}

export default EditableCell
