/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import Checkbox from '../../../Checkbox/Checkbox'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'
import Loader from '../../../Loaders/Loader'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'

const ListEmployers = ({
  showContextMenu,
  showToggleButtons,
  showUpdateButton,
  contextMenu,
  onSelect,
  hiddenColumns,
  selectedEmployers,
  handleSelectAll,
  handleSelectEmployers,
  handleUpdate,
  toggleSelectAllEmployers,
  resetValues,
  setResetValues,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllEmployers, setSelectAllEmployers] = useState(false)
  const [employers, setEmployers] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEmployers.find(c => c.id === r?.id)}
          onCheck={v => handleSelectEmployers(r, v)}
        />
      ),
    },
    {
      label: localize('name'),
      template: r => r?.description,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r?.addressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.addressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.addressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.addressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.addressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r?.addressEmail,
    },
    {
      label: localize('landLine'),
      template: r => r?.addressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.addressMobileLine,
    },
    {
      label: localize('isLocked'),
      template: r => r?.isLocked?.toString(),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const filteredColumns = columns?.filter(
    (_c, i) => !hiddenColumns?.includes(i)
  )

  const fetchEmployers = async () => {
    setIsBusy(true)
    const url = '/Employer/find-employers-by-filter-in-page'

    const { data, success } = await getData(url, reqParams, false, null)

    if (success) {
      setEmployers(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchEmployers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    handleSelectAll(employers, selectAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll])

  useEffect(() => {
    if (resetValues) {
      setSelectAll(false)
      setSelectAllEmployers(false)
      setResetValues(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center  gap-5 py-3 mb-3">
            <div className="d-flex align-items-center">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="col  d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-center ">
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
              </div>
              <div className="d-flex align-items-center ">
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchEmployers()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchEmployers()
                  }}
                >
                  <i className="uil uil-filter ms-2"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: '40dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable
              columns={filteredColumns}
              data={employers.pageCollection}
              contextMenu={showContextMenu && contextMenu}
              onSelect={onSelect}
            />
          </div>
          <Pager
            itemsCount={employers.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="selectAll"
                    checked={selectAll}
                    onCheck={v => {
                      setSelectAll(v)
                    }}
                  />

                  <label htmlFor="selectAll" className="ms-2 text-capitalize">
                    {localize('togglePageSelection')}
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="checkAll"
                    checked={selectAllEmployers}
                    onCheck={v => {
                      setSelectAllEmployers(v)
                      toggleSelectAllEmployers(v)
                    }}
                  />

                  <label htmlFor="checkAll" className="ms-2 text-capitalize">
                    {localize(`select All ${employers.itemsCount} employers`)}
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center float-end">
                <button
                  onClick={handleUpdate}
                  className="btn btn-success float-end"
                >
                  {localize('update')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ListEmployers
