/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

const EmployeeTypeLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('employeeTypeLookup')}
      url="/employeetype/find-by-filter-in-page"
      params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
        },
        {
          label: localize('chartOfAccountName'),
          template: r => <>{r.chartOfAccountAccountName}</>,
        },
        {
          label: localize('chartOfAccountCostDistributionRuleDescription'),
          template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
        },
        {
          label: localize('category'),
          template: r => <>{r.categoryDescription}</>,
        },
        {
          label: localize('taxReliefAmount'),
          template: r => <>{r.taxReliefAmount}</>,
        },
        {
          label: localize('maximumProvidentFundReliefAmount'),
          template: r => <>{r.maximumProvidentFundReliefAmount}</>,
        },
        {
          label: localize('maximumInsuranceReliefAmount'),
          template: r => <>{r.maximumInsuranceReliefAmount}</>,
        },
        {
          label: localize('maximumNHIFReliefAmount'),
          template: r => <>{r.maximumNHIFReliefAmount}</>,
        },
        {
          label: localize('executePayoutStandingOrders') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.executePayoutStandingOrders.toString())}
            </span>
          ),
        },
        {
          label: localize('recoverIndefiniteCharges') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.recoverIndefiniteCharges.toString())}
            </span>
          ),
        },
        {
          label: localize('consolidatePostings') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.consolidatePostings.toString())}
            </span>
          ),
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default EmployeeTypeLookup
