/** @format */

/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Pager from '../../../../../../Components/Tables/Pager'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import { getData } from '../../../../../../Helpers/webApi'
import {
  formatCurrency,
  formatDate,
  parseDate,
} from '../../../../../../Helpers/formatters'
import { NavigationContext } from '../../../../../../Contexts/NavigationContext'
import ViewGLBatch from './ViewGLBatch'
import AddGLBatch from './AddGLBatch'
import { batchStatus } from '../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

function GeneralLedger({ menuCode, verification, authorization }) {
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [transfers, setTransfers] = useState([])
  const [reqParams, setReqParams] = useState({
    status: authorization ? 8 : 1, // defaulted to pending
    startDate: parseDate(new Date()),
    endDate: parseDate(new Date()),
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    customDate: false,
    dateRequestFilter: 0,
  })
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [mode, setMode] = useState(null)

  const { navigatedComponent, navigateToComponent } =
    useContext(NavigationContext)

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('ledgerNumber'),
      template: r => <>{r.paddedLedgerNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{formatDate(r.auditDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    let comps = [...navigatedComponent]
    let ci = comps.findIndex(c => c.code === menuCode)

    if (ci !== -1) {
      comps[ci].hasOpenModal =
        mode === 'add' || mode === 'edit' || mode === 'view'
    }

    navigateToComponent(comps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    loadTransfers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function loadTransfers() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/generalLedger/find-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTransfers(data)
    }
    setIsBusy(false)
  }

  function closeModal() {
    setSelectedBatch(null)
    setMode(null)
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('BatchProcedures'),
          localize('origination'),
          localize('generalLedger'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              {verification || authorization || (
                <div className="d-flex align-items-center mb-2">
                  <AddNewButton
                    handleClickAddButton={() => {
                      setSelectedBatch(null)
                      setMode('add')
                    }}
                  />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-between my-3">
                <RecordsPerPage
                  className="col-3"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BatchEntryStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({ ...reqParams, status: e.value })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2 col-3">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    customStyle={{ minWidth: 250 }}
                    onSelect={d =>
                      setReqParams({
                        ...reqParams,
                        customDate: d?.custom,
                        startDate: d?.startDate,
                        endDate: d?.endDate,
                        dateRequestFilter: d?.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <input
                    value={reqParams.filterText}
                    onChange={e =>
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }
                    type="text"
                    className="form-control ms-2 me-1"
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              {transfers && (
                <>
                  <SimpleTable
                    columns={columns}
                    data={transfers?.pageCollection || []}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            setSelectedBatch(r)
                            setMode('view')
                          }}
                        >
                          <i className="uil uil-search"></i>
                          <span className="text-capitalize">
                            {localize('view')}
                          </span>
                        </div>
                        {verification || authorization || (
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              // eslint-disable-next-line
                              if (r?.status == 1 || r?.status == 16) {
                                setSelectedBatch(r)
                                setMode('edit')
                              } else {
                                Swal.fire(
                                  'Invalid Operation',
                                  'Sorry, but you must first select a general ledger whose status is pending/deferred!',
                                  'info'
                                )
                              }
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            <span className="text-capitalize">
                              {localize('edit')}
                            </span>
                          </div>
                        )}
                        {verification && (
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              // eslint-disable-next-line
                              if (r?.status != 1) {
                                return Swal.fire(
                                  'Invalid Entry',
                                  'Sorry but you must first select a General Ledger whose status is pending!',
                                  'info'
                                )
                              }
                              setSelectedBatch(r)
                              setMode('verify')
                            }}
                          >
                            <i className="uil uil-bolt-alt"></i>
                            <span className="text-capitalize">
                              {localize('verify')}
                            </span>
                          </div>
                        )}
                        {authorization && (
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              // eslint-disable-next-line
                              if (r?.status != 8) {
                                return Swal.fire(
                                  'Invalid Entry',
                                  'Sorry but you must first select a General Ledger whose status is audited!',
                                  'info'
                                )
                              }
                              setSelectedBatch(r)
                              setMode('authorize')
                            }}
                          >
                            <i className="uil uil-bolt-alt"></i>
                            <span className="text-capitalize">
                              {localize('authorize')}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  />
                  <div className="ms-2">
                    <Pager
                      itemsCount={transfers?.itemsCount || 1}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {mode === 'add' && <AddGLBatch closeModal={closeModal} />}
      {mode === 'edit' && (
        <AddGLBatch batch={selectedBatch} closeModal={closeModal} mode="edit" />
      )}
      {mode === 'view' && (
        <ViewGLBatch batch={selectedBatch} closeModal={closeModal} />
      )}
      {mode === 'verify' && (
        <ViewGLBatch
          batch={selectedBatch}
          verify
          disableEntriesContext
          closeModal={closeModal}
        />
      )}
      {mode === 'authorize' && (
        <ViewGLBatch
          batch={selectedBatch}
          authorize
          disableEntriesContext
          closeModal={closeModal}
        />
      )}
    </>
  )
}

export default GeneralLedger
