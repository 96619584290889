/** @format */

import React from 'react'
import localize from '../../Global/Localize'

const RecordsPerPage = ({ reqParams, setReqParams, className }) => {
  return (
    <>
      <div className={`d-flex align-items-center gap-2 ${className}`}>
        <label
          htmlFor="recordsPerPage"
          style={{
            minWidth: 'max-content',
            textTransform: 'capitalize',
          }}
        >
          {localize('recordsPerPage')}
        </label>
        <select
          id="recordsPerPage"
          className="form-select"
          style={{ minWidth: 'max-content' }}
          defaultValue={reqParams.pageSize}
          onChange={e => {
            setReqParams({
              ...reqParams,
              pageIndex: 0,
              pageSize: e.target.value,
            })
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="400">400</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
      </div>
    </>
  )
}

export default RecordsPerPage
