/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import SpecimenMediaItem from './SpecimenMediaItem'

function Specimen({
  passport,
  signature,
  idFront,
  idBack,
  readOnly,
  onChange,
}) {
  const [media, setMedia] = useState({ passport, signature, idFront, idBack })

  useEffect(() => {
    if (!readOnly) onChange(media)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media])

  return (
    <div className="row">
      {Object.entries(media).map(([key, value], i) => (
        <div className="col" key={'specimen' + i}>
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize(key)}
            </div>
            <SpecimenMediaItem
              onChange={(key, image) => {
                setMedia(m => ({ ...m, [key]: image.toString().split(',')[1] }))
              }}
              base64={media[key]}
              readOnly={readOnly}
              itemKey={key}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Specimen
