/** @format */
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import localize from '../../../../../../../Global/Localize'
import {
  ChargeType,
  CreditBatchType,
} from '../../../../../../../Global/enumeration'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../../../../Components/InputMasks/MaskPercentage'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'

import { CreditBatchLookup } from '../../../../../../../Components/Lookup/CreditBatchLookup'
import { postData, putData } from '../../../../../../../Helpers/webApi'
import CustomerAccountLookup from '../../../../../../../Components/Lookup/CustomerAccountLookup'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { BatchEntries } from './BatchEntries'
import { CreditBatchFlags } from './CreditBatchFlags'
import { creditBatchFlagsList } from '../Extensions/creditBatchFlags'
import { MismatchedImportEntriesViewMode } from './MismatchedImportEntries'
import { parseDate } from '../../../../../../../Helpers/formatters'
import ImportButton from '../../../../../../../Components/Buttons/ImportButton/ImportButton'
import { toBase64 } from '../../../../../../../Helpers/blobs'
import Swal from 'sweetalert2'
import { CreditBatchImportModal } from './CreditBatchImportModal'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

export const EditCreditBatchModal = ({
  selectedBatchEntry,
  setSelectedBatchEntry,
}) => {
  const [selectedConcessionType, setSelectedConcessionType] = useState(
    Object.keys(ChargeType)[0]
  )

  const [refreshBatchEntries, setRefreshBatchEntries] = useState(false)
  const [creditBatchFlags, setCreditBatchFlags] = useState(creditBatchFlagsList)
  const [addImportBatchDetailsMode, setAddImportBatchDetailsMode] =
    useState(null)
  const handleOnClickAddBatchDetails = () => {
    setAddImportBatchDetailsMode('add')
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchNumber')}
          </label>
          <CreditBatchLookup
            displayValue={selectedBatchEntry?.paddedBatchNumber}
            onSelect={r => {
              setSelectedBatchEntry({ ...selectedBatchEntry, id: r.id })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.typeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('totalValue')}
          </label>
          <MaskNumber
            defaultMaskValue={selectedBatchEntry?.totalValue}
            onMaskNumber={e => {
              setSelectedBatchEntry({
                ...selectedBatchEntry,
                totalValue: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('creditType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.creditTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('concessionType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ChargeType"
            value={selectedConcessionType}
            onChange={e => {
              setSelectedConcessionType(e.value)
              setSelectedBatchEntry({
                ...selectedBatchEntry,
                concessionType: e.value,
              })
            }}
            sort
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('concession')}
          </label>
          {selectedConcessionType === Object.keys(ChargeType)[0] ? (
            <MaskPercentage
              defaultMaskValue={selectedBatchEntry?.concession}
              onMaskChange={e => {
                setSelectedBatchEntry({
                  ...selectedBatchEntry,
                  concession: e.target.value,
                })
              }}
            />
          ) : (
            <MaskNumber
              defaultMaskValue={selectedBatchEntry?.concession}
              onMaskChange={e => {
                setSelectedBatchEntry({
                  ...selectedBatchEntry,
                  concession: e.target.value,
                })
              }}
            />
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.postingPeriodDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('referenceMonth')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Month"
            value={selectedBatchEntry?.month}
            onChange={e => {
              setSelectedBatchEntry({
                ...selectedBatchEntry,
                month: e.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <CustomDatePicker />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('chartOfAccountName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.chartOfAccountName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('payrollNumberType')}
          </label>
          <input
            className="form-control"
            disabled
            style={{ wordBreak: 'break-word' }}
            value={selectedBatchEntry?.creditTypePayrollNumberTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('revenueTax')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.creditTypeRevenueTaxDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costCenter')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.costCenterDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.reference}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('checkOffCutOffDate')}
          </label>
          <CustomDatePicker
            defaultDate={parseDate(selectedBatchEntry?.checkOffCutOffDate)}
            onDateChange={e => {
              setSelectedBatchEntry({
                ...selectedBatchEntry,
                checkOffCutOffDate: e,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchFileType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedBatchEntry?.creditTypeBatchFileTypeDescription}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('priority')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="QueuePriority"
            value={selectedBatchEntry?.priority}
            onChange={e => {
              setSelectedBatchEntry({
                ...selectedBatchEntry,
                priority: e.value,
              })
            }}
            sort
          />
        </div>
      </div>

      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('creditBatch')} {localize('entryDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <AddImportBatchDetails
            onSetMode={m => {
              setAddImportBatchDetailsMode(m)
              setRefreshBatchEntries(!refreshBatchEntries)
            }}
            selectedBatchEntry={selectedBatchEntry}
            onHideModal={() => {
              setAddImportBatchDetailsMode(null)
            }}
            mode={addImportBatchDetailsMode}
          />
        </div>
      </div>

      <div className="row mb-3 mt-3">
        <div className="col-md-12">
          <Tab
            preload
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <BatchEntries
                    onSelectBatchEntry={r => {}}
                    refreshBatchEntries={refreshBatchEntries}
                    selectedBatchEntry={selectedBatchEntry}
                  />
                ),
              },
              {
                label: localize('mismatchedImportEntries'),
                item: (
                  <MismatchedImportEntriesViewMode
                    selectedBatchEntry={selectedBatchEntry}
                  />
                ),
              },
              {
                label: localize('flags'),
                item: (
                  <CreditBatchFlags
                    flags={creditBatchFlags || creditBatchFlagsList}
                    setFlags={setCreditBatchFlags}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export const AddImportBatchDetails = ({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onAddNewBatchEntry,
  onSetMode,
  selectedBatchEntry,
}) => {
  const ref = useRef(null)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [batchEntryDetailsModel, setBatchEntryDetailsModel] = useState({
    creditBatchId: selectedBatchEntry.id,
  })
  const [fileName, setFileName] = useState('')
  const [reload, setReload] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [newUpload, setNewUpload] = useState(false)
  const [mismatchedEntries, setMismatchedEntries] = useState([])

  const setCreditBatchReference = r => {
    switch (selectedBatchEntry.type) {
      case Object.keys(CreditBatchType)[0]:
        setBatchEntryDetailsModel({
          ...batchEntryDetailsModel,
          reference: r.customerReference1,
        })
        break
      case Object.keys(CreditBatchType)[1]:
        setBatchEntryDetailsModel({
          ...batchEntryDetailsModel,
          reference: r.customerReference3,
        })
        break
      case Object.keys(CreditBatchType)[4]:
        setBatchEntryDetailsModel({
          ...batchEntryDetailsModel,
          reference: r.customerReference1,
        })
        break
      case Object.keys(CreditBatchType)[5]:
        setBatchEntryDetailsModel({
          ...batchEntryDetailsModel,
          reference: r.customerReference1,
        })
        break
      default:
        break
    }
  }

  const handleClickImportButton = () => {
    setShowImportModal(true)
    setNewUpload(true)
  }

  const handleFileUploadToServer = async () => {
    const url = `/CreditBatch/parse-credit-batch-import`

    const { success, data } = await putData(
      url,
      {
        creditBatchId: selectedBatchEntry?.id,
        fileName: fileName?.fileName,
        buffer: fileName?.buffer,
      },
      true,
      'Batch entries imported successfully'
    )

    if (success) {
      setReload(true)
      setFileName('')
      setMismatchedEntries(data?.result)
      setShowImportModal(false)
      setNewUpload(false)
    }
  }

  const handleUpload = async () => {
    ref?.current?.click()

    ref.current.onchange = async e => {
      let file = e.target.files[0]
      try {
        let b64 = await toBase64(file)
        let buffer = b64.split(',')[1].toString()
        setFileName({
          buffer,
          fileName: file.name,
          fileExtension: 'csv',
        })
      } catch {
        Swal.fire('Invalid File', 'Error loading file', 'error')
        return
      }
    }
  }

  async function handleSubmit() {
    if (!batchEntryDetailsModel?.customerAccountId) {
      Swal.fire({
        icon: 'error',
        text: 'Customer Account Is Invalid',
        title: 'Add Credit Batch Entry Details',
        showCloseButton: true,
      })
      return
    }
    const { success } = await postData(
      '/CreditBatch/create-credit-batch-entry',
      { ...batchEntryDetailsModel },
      true,
      'Add Credit Batch Entry'
    )

    if (success) {
      onSetMode(null)
      setSelectedCustomerAccount(null)
      setBatchEntryDetailsModel({
        creditBatchId: selectedBatchEntry.id,
      })
    }
  }

  useEffect(() => {
    if (fileName) handleFileUploadToServer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, selectedBatchEntry?.id, newUpload])

  return (
    <>
      <>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <CustomerAccountLookup
              displayValue={selectedCustomerAccount?.fullAccountNumber}
              onSelect={r => {
                setCreditBatchReference(r)
                setSelectedCustomerAccount(r)
                setBatchEntryDetailsModel({
                  ...batchEntryDetailsModel,
                  customerAccountId: r.id,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedCustomerAccount?.statusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedCustomerAccount?.remarks || ''}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedCustomerAccount?.customerFullName || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customerType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccount?.customerMembershipClassCustomerTypeDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customerMemberNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccount?.formattedCustomerMemberNumber || ''
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedCustomerAccount?.customerEmployerDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('identificationNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccount?.customerIdentificationNumber || ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccount?.formattedCustomerMemberNumber || ''
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedCustomerAccount?.customerPersonalFileNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('costCenter')}
            </label>
            <CostCentreLookup
              displayValue={batchEntryDetailsModel?.costCenterDescription}
              onSelect={r => {
                setBatchEntryDetailsModel({
                  ...batchEntryDetailsModel,
                  costCenterDescription: r.description,
                  costCenterId: r.id,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('principal')}
            </label>
            <MaskNumber
              onMaskNumber={e => {
                setBatchEntryDetailsModel({
                  ...batchEntryDetailsModel,
                  principal: e.target.value,
                })
              }}
              defaultMaskValue={batchEntryDetailsModel?.principal}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('interest')}
            </label>
            <MaskNumber
              onMaskNumber={e => {
                setBatchEntryDetailsModel({
                  ...batchEntryDetailsModel,
                  interest: e.target.value,
                })
              }}
              defaultMaskValue={batchEntryDetailsModel?.interest}
            />
          </div>
          <div className="col-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control"
              defaultValue={batchEntryDetailsModel?.reference}
              onChange={e => {
                setBatchEntryDetailsModel({
                  ...batchEntryDetailsModel,
                  reference: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="d-flex align-content-end justify-content-end">
          <div className="d-flex align-items-center me-2">
            <ImportButton handleClickImportButton={handleClickImportButton} />
          </div>
          <div className="d-flex align-items-center me-2">
            <button
              onClick={handleSubmit}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          </div>
        </div>
      </>
      {showImportModal && (
        <CreditBatchImportModal
          handleCloseCreditBatchImportModal={() => setShowImportModal(false)}
          handleUpload={handleUpload}
          ref={ref}
        />
      )}

      <input
        type="file"
        ref={ref}
        className="d-none"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </>
  )
}
