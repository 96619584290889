/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'

const ViewAutoShop = ({ handleClose, mode, data }) => {
  const [receivable, setReceivable] = useState({})
  const [entries, setEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const entryColumns = [
    {
      label: 'item',
      template: r => r?.inventoryItemRegisterDescription,
    },
    {
      label: 'primaryDescription',
      template: r => r?.primaryDescription,
    },
    {
      label: 'secondaryDescription',
      template: r => r?.secondaryDescription,
    },
    {
      label: 'reference',
      template: r => r?.reference,
    },
    {
      label: 'sellingPrice',
      template: r => formatCurrency(r?.sellingPrice),
    },
    {
      label: 'quantity',
      template: r => formatCurrency(r?.quantity),
    },
    {
      label: 'totalPrice',
      template: r => formatCurrency(r?.totalPrice),
    },
  ]

  const fetchEntries = async () => {
    setIsBusy(true)
    const url =
      '/AutoShopReceivable/find-autoshop-receivable-entries-by-autoshop-receivable-id-in-page'

    const { success, data } = await getData(
      url,
      { autoshopReceivableId: receivable?.id, pageIndex: 0, pageSize: 1000 },
      false
    )

    if (success) {
      setEntries(data?.pageCollection)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (data?.id) {
      setReceivable(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id])

  useEffect(() => {
    if (receivable?.id) {
      fetchEntries()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivable?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      modalClassName="modal-xl"
      modalTitle={'viewReceivables'}
      handleClose={handleClose}
      hideUpdate
    >
      <>
        <div className="row g-3">
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('voucherNumber')}
            </label>
            <input
              className="form-control"
              value={receivable?.paddedVoucherNumber}
            />
          </div>

          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('branch')}
            </label>
            <input
              value={receivable?.branchDescription}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('postingPeriod')}
            </label>
            <input
              disabled
              value={receivable?.postingPeriodDescription}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('costDistributionRule')}
            </label>
            <input
              disabled
              className="form-control"
              value={receivable?.chartOfAccountCostDistributionRuleDescription}
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('costCenter')}
            </label>
            <input
              value={receivable?.costCenterDescription}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              value={formatDate(receivable?.valueDate, true)}
              className="form-control"
              disabled
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <input
              value={receivable?.customerAccountFullAccountNumber}
              className="form-control text-end"
              disabled
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              value={receivable?.customerAccountCustomerFullName}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              value={receivable?.remarks}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              value={receivable?.createdBy}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              value={formatDate(receivable?.createdDate)}
              disabled
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">{localize('total')}</label>
            <input
              value={formatCurrency(receivable?.total, null, null, false)}
              disabled
              className="form-control text-end"
            />
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2  row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('entryDetails')}
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <SimpleTable columns={entryColumns} data={entries} />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}

export default ViewAutoShop
