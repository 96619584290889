/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import localize from '../../../../Global/Localize'

function Flags({ membershipClass, onFlag, disabled, prefix }) {
  const [mClass, setMClass] = useState(membershipClass)
  useEffect(() => {
    onFlag(mClass)
  }, [mClass])

  return (
    <div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.bypassIprsKYCValidation}
            id={prefix + 'mc_bypassIprsKYCValidation'}
            onCheck={v => setMClass({ ...mClass, bypassIprsKYCValidation: v })}
          />
          <label
            htmlFor={prefix + 'mc_bypassIprsKYCValidation'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.bypassIprsKYCValidation ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('bypassIprsKYCValidation')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.bypassMpesaKYCValidation}
            id={prefix + 'mc_bypassMpesaKYCValidation'}
            onCheck={v => setMClass({ ...mClass, bypassMpesaKYCValidation: v })}
          />
          <label
            htmlFor={prefix + 'mc_bypassMpesaKYCValidation'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.bypassMpesaKYCValidation ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('bypassMpesaKYCValidation')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.enforceRetirementAge}
            id={prefix + 'mc_enforceRetirementAge'}
            onCheck={v => setMClass({ ...mClass, enforceRetirementAge: v })}
          />
          <label
            htmlFor={prefix + 'mc_enforceRetirementAge'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.enforceRetirementAge ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('enforceRetirementAge')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.tallyStatementEntries}
            id={prefix + 'mc_tallyStatementEntries'}
            onCheck={v => setMClass({ ...mClass, tallyStatementEntries: v })}
          />
          <label
            htmlFor={prefix + 'mc_tallyStatementEntries'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.tallyStatementEntries ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('tallyStatementEntries')}?
          </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.fetchStatementJournalEntries}
            id={prefix + 'mc_fetchStatementJournalEntries'}
            onCheck={v =>
              setMClass({ ...mClass, fetchStatementJournalEntries: v })
            }
          />
          <label
            htmlFor={prefix + 'mc_fetchStatementJournalEntries'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.fetchStatementJournalEntries ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('fetchStatementJournalEntries')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.excludeClearedLoansFromAccountsListing}
            id={prefix + 'mc_excludeClearedLoansFromAccountsListing'}
            onCheck={v =>
              setMClass({
                ...mClass,
                excludeClearedLoansFromAccountsListing: v,
              })
            }
          />
          <label
            htmlFor={prefix + 'mc_excludeClearedLoansFromAccountsListing'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.excludeClearedLoansFromAccountsListing
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('excludeClearedLoansFromAccountsListing')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.chargeForPrinting}
            id={prefix + 'mc_chargeForPrinting'}
            onCheck={v => setMClass({ ...mClass, chargeForPrinting: v })}
          />
          <label
            htmlFor={prefix + 'mc_chargeForPrinting'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.chargeForPrinting ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('chargeForPrinting')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.evaluateMembershipStatusForInterestCapitalization}
            id={prefix + 'mc_evaluateMembershipStatusForInterestCapitalization'}
            onCheck={v =>
              setMClass({
                ...mClass,
                evaluateMembershipStatusForInterestCapitalization: v,
              })
            }
          />
          <label
            htmlFor={
              prefix + 'mc_evaluateMembershipStatusForInterestCapitalization'
            }
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.evaluateMembershipStatusForInterestCapitalization
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('evaluateMembershipStatusForInterestCapitalization')}?
          </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={
              mClass?.closeSavingsAccountsOnMembershipTerminationNotification
            }
            id={
              prefix +
              'mc_closeSavingsAccountsOnMembershipTerminationNotification'
            }
            onCheck={v =>
              setMClass({
                ...mClass,
                closeSavingsAccountsOnMembershipTerminationNotification: v,
              })
            }
          />
          <label
            htmlFor={
              prefix +
              'mc_closeSavingsAccountsOnMembershipTerminationNotification'
            }
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.closeSavingsAccountsOnMembershipTerminationNotification
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize(
              'closeSavingsAccountsOnMembershipTerminationNotification'
            )}
            ?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.enableLegacyReferenceFields}
            id={prefix + 'mc_enableLegacyReferenceFields'}
            onCheck={v =>
              setMClass({ ...mClass, enableLegacyReferenceFields: v })
            }
          />
          <label
            htmlFor={prefix + 'mc_enableLegacyReferenceFields'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.enableLegacyReferenceFields ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('enableLegacyReferenceFields')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.requireProductSelection}
            id={prefix + 'mc_requireProductSelection'}
            onCheck={v => setMClass({ ...mClass, requireProductSelection: v })}
          />
          <label
            htmlFor={prefix + 'mc_requireProductSelection'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.requireProductSelection ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('requireProductSelection')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.validateBiometricSpecimen}
            id={prefix + 'mc_validateBiometricSpecimen'}
            onCheck={v =>
              setMClass({ ...mClass, validateBiometricSpecimen: v })
            }
          />
          <label
            htmlFor={prefix + 'mc_validateBiometricSpecimen'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.validateBiometricSpecimen ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('validateBiometricSpecimen')}?
          </label>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.enableMembershipTextAlerts}
            id={prefix + 'mc_enableMembershipTextAlerts'}
            onCheck={v =>
              setMClass({ ...mClass, enableMembershipTextAlerts: v })
            }
          />
          <label
            htmlFor={prefix + 'mc_enableMembershipTextAlerts'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.enableMembershipTextAlerts ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('enableMembershipTextAlerts')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.overrideMemberNumberFormatting}
            id={prefix + 'mc_overrideMemberNumberFormatting'}
            onCheck={v =>
              setMClass({ ...mClass, overrideMemberNumberFormatting: v })
            }
          />
          <label
            htmlFor={prefix + 'mc_overrideMemberNumberFormatting'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.overrideMemberNumberFormatting
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('overrideMemberNumberFormatting')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.recoverTextAlertTariffsFromPrincipalSavingsProduct}
            id={
              prefix + 'mc_recoverTextAlertTariffsFromPrincipalSavingsProduct'
            }
            onCheck={v =>
              setMClass({
                ...mClass,
                recoverTextAlertTariffsFromPrincipalSavingsProduct: v,
              })
            }
          />
          <label
            htmlFor={
              prefix + 'mc_recoverTextAlertTariffsFromPrincipalSavingsProduct'
            }
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.recoverTextAlertTariffsFromPrincipalSavingsProduct
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('recoverTextAlertTariffsFromPrincipalSavingsProduct')}?
          </label>
        </div>
        <div className="col-3 d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.isLocked}
            id={prefix + 'mc_isLocked'}
            onCheck={v => setMClass({ ...mClass, isLocked: v })}
          />
          <label
            htmlFor={prefix + 'mc_isLocked'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.isLocked ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('isLocked')}?
          </label>
        </div>
      </div>
      {/* <div className="col-3">
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.bypassIprsKYCValidation}
            id={prefix + 'mc_bypassIprsKYCValidation'}
            onCheck={v => setMClass({ ...mClass, bypassIprsKYCValidation: v })}
          />
          <label
            htmlFor={prefix + 'mc_bypassIprsKYCValidation'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.bypassIprsKYCValidation ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('bypassIprsKYCValidation')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            id={prefix + 'fetchStatementJournalEntries'}
            checked={mClass?.fetchStatementJournalEntries}
            onCheck={v =>
              setMClass({ ...mClass, fetchStatementJournalEntries: v })
            }
          />
          <label
            htmlFor={prefix + 'fetchStatementJournalEntries'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.fetchStatementJournalEntries ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('fetchStatementJournalEntries')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            id={
              prefix + 'closeSavingsAccountsOnMembershipTerminationNotification'
            }
            checked={
              mClass?.closeSavingsAccountsOnMembershipTerminationNotification
            }
            onCheck={v =>
              setMClass({
                ...mClass,
                closeSavingsAccountsOnMembershipTerminationNotification: v,
              })
            }
          />
          <label
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.closeSavingsAccountsOnMembershipTerminationNotification
                ? 'fw-bold text-primary'
                : ''
            }`}
            htmlFor={
              prefix + 'closeSavingsAccountsOnMembershipTerminationNotification'
            }
          >
            {localize('closeSavingsAccountOnMembershipTerminationNotification')}
            ?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            id={prefix + 'enableLegacyReferenceFields'}
            checked={mClass?.enableLegacyReferenceFields}
            onCheck={v =>
              setMClass({ ...mClass, enableLegacyReferenceFields: v })
            }
          />
          <label
            htmlFor={prefix + 'enableLegacyReferenceFields'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.enableLegacyReferenceFields ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('enableLegacyReferenceFields')}?
          </label>
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.bypassMpesaKYCValidation}
            id={prefix + 'bypassMpesaKYCValidation'}
            onCheck={v => setMClass({ ...mClass, bypassMpesaKYCValidation: v })}
          />
          <label
            htmlFor={prefix + 'bypassMpesaKYCValidation'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.bypassMpesaKYCValidation ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('bypassMpesaKYCValidation')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.excludeClearedLoansFromAccountsListing}
            id={prefix + 'excludeClearedLoansFromAccountsListing'}
            onCheck={v =>
              setMClass({
                ...mClass,
                excludeClearedLoansFromAccountsListing: v,
              })
            }
          />
          <label
            htmlFor={prefix + 'excludeClearedLoansFromAccountsListing'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.excludeClearedLoansFromAccountsListing
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('excludeClearedLoansFromAccountsListing')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.formatAccountNumber}
            id={prefix + 'formatAccountNumber'}
            onCheck={v => setMClass({ ...mClass, formatAccountNumber: v })}
          />
          <label
            htmlFor={prefix + 'formatAccountNumber'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.formatAccountNumber ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('formatAccountNumber')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.isLocked}
            id={prefix + 'isLocked'}
            onCheck={v => setMClass({ ...mClass, isLocked: v })}
          />
          <label
            htmlFor={prefix + 'isLocked'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.isLocked ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('isLocked')}?
          </label>
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.enforceRetirementAge}
            id={prefix + 'enforceRetirementAge'}
            onCheck={v => setMClass({ ...mClass, enforceRetirementAge: v })}
          />
          <label
            htmlFor={prefix + 'enforceRetirementAge'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.enforceRetirementAge ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('enforceRetirementAge')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.chargeForPrinting}
            id={prefix + 'chargeForPrinting'}
            onCheck={v => setMClass({ ...mClass, chargeForPrinting: v })}
          />
          <label
            htmlFor={prefix + 'chargeForPrinting'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.chargeForPrinting ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('chargeForPrinting')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.requireProductSelection}
            id={prefix + 'requireProductSelection'}
            onCheck={v => setMClass({ ...mClass, requireProductSelection: v })}
          />
          <label
            htmlFor={prefix + 'requireProductSelection'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.requireProductSelection ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('requireProductSelection')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.requireProductSelection}
            id={prefix + 'requireProductSelection'}
            onCheck={v => setMClass({ ...mClass, requireProductSelection: v })}
          />
          <label
            htmlFor={prefix + 'requireProductSelection'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.requireProductSelection ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('requireProductSelection')}?
          </label>
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.tallyStatementEntries}
            id={prefix + 'tallyStatementEntries'}
            onCheck={v => setMClass({ ...mClass, tallyStatementEntries: v })}
          />
          <label
            htmlFor={prefix + 'tallyStatementEntries'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.tallyStatementEntries ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('tallyStatementEntries')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.evaluateMembershipStatusForInterestCapitalization}
            id={prefix + 'evaluateMembershipStatusForInterestCapitalization'}
            onCheck={v =>
              setMClass({
                ...mClass,
                evaluateMembershipStatusForInterestCapitalization: v,
              })
            }
          />
          <label
            htmlFor={
              prefix + 'evaluateMembershipStatusForInterestCapitalization'
            }
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.evaluateMembershipStatusForInterestCapitalization
                ? 'fw-bold text-primary'
                : ''
            }`}
          >
            {localize('evaluateMembershipStatusForInterestCapitalization')}?
          </label>
        </div>
        <div className="d-flex align-items-center mb-1">
          <Checkbox
            disabled={disabled}
            checked={mClass?.validateBiometricSpecimen}
            id={prefix + 'validateBiometricSpecimen'}
            onCheck={v =>
              setMClass({ ...mClass, validateBiometricSpecimen: v })
            }
          />
          <label
            htmlFor={prefix + 'validateBiometricSpecimen'}
            className={`fw-normal ms-2 text-capitalize ${
              mClass?.validateBiometricSpecimen ? 'fw-bold text-primary' : ''
            }`}
          >
            {localize('validateBiometricSpecimen')}?
          </label>
        </div>
      </div> */}
    </div>
  )
}

export default Flags
