/** @format */

import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'

const InstantMessaging = () => {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('instantMessaging'),
        ]}
      />
      <div className="d-lg-flex mb-4">
        <div className="chat-leftsidebar bg-white">
          <div className="p-3">
            <div className="search-box chat-search-box">
              <div className="position-relative">
                <input
                  placeholder="Search..."
                  type="text"
                  className="form-control bg-light border-light rounded form-control"
                />
                <i className="uil uil-search search-icon" />
              </div>
            </div>
          </div>
          <div className="p-4 border-top">
            <h5 className="font-size-16 mb-3 text-capitalize">
              <i className="uil uil-user me-1"></i>
              {localize('users')}
            </h5>
          </div>
        </div>
        <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-1 bg-white">
          <div className="p-3 px-lg-4 border-bottom">
            <div className="row">
              <div className="col-6 col-md-4">
                <h5 className="font-size-16 mb-1 text-truncate">
                  <a className="text-dark" href="/chat">
                    Designer
                  </a>
                </h5>
                <p className="text-muted text-truncate mb-0">
                  <i className="uil uil-users-alt me-1"></i> 12 Members
                </p>
              </div>
              <div className="col-6 col-md-8">
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item">
                    <div className="dropdown">
                      <button
                        aria-haspopup="true"
                        className="btn nav-btn"
                        aria-expanded="false"
                      >
                        <i className="uil uil-search"></i>
                      </button>
                      <div
                        tabindex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu-end dropdown-menu-md dropdown-menu"
                      >
                        <form className="p-2">
                          <div>
                            <input
                              placeholder="Search..."
                              type="text"
                              className="form-control rounded form-control"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="p-3 chat-input-section">
            <div className="row">
              <div className="col">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control chat-input rounded"
                    placeholder="Enter Message..."
                    value=""
                  />
                </div>
              </div>
              <div className="col-auto">
                <button
                  type="submit"
                  className="chat-send w-md waves-effect waves-light btn btn-primary"
                >
                  <span className="d-none d-sm-inline-block me-2">Send</span>{' '}
                  <i className="mdi mdi-send float-end"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InstantMessaging
