/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BankReconciliationPeriodLookup from '../../../../../../Components/Lookup/BankReconciliationPeriodLookup'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import BankReconciliationEntries from './BankReconciliationEntries/BankReconciliationEntries'

const ListClosingAndCatalogue = ({ showUpdate }) => {
  const [selectedData, setSelectedData] = useState(null)

  const handleSelect = r => {
    setSelectedData(r)
  }

  return (
    <>
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="parentPeriod" className="text-capitalize col-4">
                  {localize('parentPeriod')}
                </label>

                <BankReconciliationPeriodLookup
                  displayValue={selectedData?.indentedDescription}
                  handleClickOnce={handleSelect}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="startDate" className="text-capitalize col-4">
                  {localize('startDate')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(selectedData?.durationStartDate, true) || ''}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="endDate" className="text-capitalize col-4">
                  {localize('endDate')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(selectedData?.durationEndDate, true) || ''}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="parentPeriod" className="text-capitalize col-4">
                  {localize('g/LAccountName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={selectedData?.chartOfAccountName || ''}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankBranchName"
                  className="text-capitalize col-4"
                >
                  {localize('bankBranchName')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="bankBranchName"
                  value={
                    selectedData?.branchDescription ||
                    selectedData?.bankBranchName ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankAccountNumber"
                  className="text-capitalize col-4"
                >
                  {localize('bankAccountNumber')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="bankAccountNumber"
                  value={selectedData?.bankAccountNumber || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankAccountBalance"
                  className="text-capitalize col-4"
                >
                  {localize('bankAccountBalance')}
                </label>

                <input
                  type="text"
                  className="form-control text-end"
                  value={
                    formatCurrency(
                      selectedData?.bankAccountBalance,
                      null,
                      null,
                      false
                    ) || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="remarks" className="text-capitalize col-4">
                  {localize('remarks')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="remarks"
                  value={selectedData?.remarks || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="px-2 bg-white text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('bankReconciliationEntries')}
            </div>

            <BankReconciliationEntries
              period={selectedData}
              showUpdate={showUpdate}
              setSelectedData={setSelectedData}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ListClosingAndCatalogue
