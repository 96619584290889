/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { AddRiskClassificationModeModal } from './AddRiskClassificationModeModal'
import { EditRiskClassificationModeModal } from './EditRiskClassificationModeModal'
import { ViewRiskClassificationModeModal } from './ViewRiskClassificationModeModal'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function RiskClassificationModeModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  setSelectedRiskClassificationMode,
  selectedRiskClassificationMode,
  riskClassificationSchedules,
  setRiskClassificationSchedules,
  waitingMetadata,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addRiskClassificationMode()
    }
    if (mode === 'edit') {
      return editRiskClassificationMode()
    }
  }

  useEffect(() => {
    return () => {
      GlobalValidationErrors.reset()
    }
  }, [mode])

  const addRiskClassificationMode = async () => {
    setIsBusy(true)

    const { success } = await postData(
      '/RiskClassificationMode/add-risk-classification-modes-with-schedules',
      {
        RiskClassificationMode: { ...selectedRiskClassificationMode },
        RiskClassificationSchedules: riskClassificationSchedules || [],
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Risk Classification Mode',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }
  const editRiskClassificationMode = async () => {
    setIsBusy(true)

    const { success, data } = await postData(
      '/RiskClassificationMode/update-risk-classification-modes-with-schedules',
      {
        RiskClassificationMode: { ...selectedRiskClassificationMode },
        RiskClassificationSchedules: riskClassificationSchedules || [],
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Risk Classification Mode',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }
  return (
    <>
      <>
        {(mode === 'add' || mode === 'edit' || mode === 'view') && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('riskClassificationMode')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disabledCreate={isBusy}
            hideUpdate={mode === 'view'}
          >
            {mode === 'add' && (
              <AddRiskClassificationModeModal
                setSelectedRiskClassificationMode={
                  setSelectedRiskClassificationMode
                }
                isBusy={isBusy}
                mode={mode}
                selectedRiskClassificationMode={selectedRiskClassificationMode}
                riskClassificationSchedules={riskClassificationSchedules}
                setRiskClassificationSchedules={setRiskClassificationSchedules}
                waitingMetadata={waitingMetadata}
              />
            )}
            {mode === 'edit' && (
              <EditRiskClassificationModeModal
                mode={mode}
                setSelectedRiskClassificationMode={
                  setSelectedRiskClassificationMode
                }
                isBusy={isBusy}
                riskClassificationSchedules={riskClassificationSchedules}
                setRiskClassificationSchedules={setRiskClassificationSchedules}
                selectedRiskClassificationMode={selectedRiskClassificationMode}
                waitingMetadata={waitingMetadata}
              />
            )}
            {mode === 'view' && (
              <ViewRiskClassificationModeModal
                riskClassificationSchedules={riskClassificationSchedules}
                mode={mode}
                setSelectedRiskClassificationMode={
                  setSelectedRiskClassificationMode
                }
                isBusy={isBusy}
                selectedRiskClassificationMode={selectedRiskClassificationMode}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default RiskClassificationModeModal
