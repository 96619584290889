/** @format */

import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
import React from 'react'

export const SupplierTypeLookup = ({ displayValue, onSelect }) => {
  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('glAccountName'),
      template: r => <>{r.chartOfAccountAccountName}</>,
    },
    {
      label: localize('gLAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('glAccountName(goodsReceivedNotInvoiced)'),
      template: r => <>{r.goodsReceivedNotInvoicedChartOfAccountName}</>,
    },
    {
      label: localize(
        'gLAccountCostDistributionRule(goodsReceivedNotInvoiced)',
      ),
      template: r => (
        <>
          {
            r.goodsReceivedNotInvoicedChartOfAccountCostDistributionRuleDescription
          }
        </>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <SimpleLookup
      useText
      title={localize('supplierTypeLookup')}
      url="/lookup/SupplierType/find-supplier-types-by-filter-in-page"
      params={{ text: '', pageSize: 10, pageIndex: 0 }}
      columns={columns}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
