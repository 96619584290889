/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import CustomerAccountByCustomerIdLookup from '../../Lookup/CustomerAccountByCustomerIdLookup'
import CustomerAccountLookup from '../../Lookup/CustomerAccountLookup'
import { formatCurrency } from '../../../Helpers/formatters'
function CustomerAccountDebitCreditPartial({
  onCustomerDebitAccountLookup,
  onCustomerCreditAccountLookup,
  selectedDebitAccount,
  selectedCreditAccount,
  selectedAccount,
  message,
  mode = 'none',
  selectedCustomer,
  lookupType,
}) {
  return (
    <>
      {lookupType === 'debit' ? (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 me-2 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <CustomerAccountLookup
                displayValue={
                  selectedDebitAccount?.fullAccountNumber || '' || ''
                }
                type="text"
                className="form-control ms-2"
                onSelect={v => {
                  onCustomerDebitAccountLookup({ ...v })
                }}
              />
            </div>

            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.remarks || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('availableBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDebitAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedDebitAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDebitAccount?.availableBalance || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDebitAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.customerFullName || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDebitAccount?.customerMembershipClassCustomerTypeDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerSerialNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.paddedCustomerSerialNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.customerEmployerDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identification#')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.customerIdentificationNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDebitAccount?.formattedCustomerMemberNumber || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDebitAccount?.formattedCustomerAccountNumber || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.customerPersonalFileNumber || ''}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mb-2">
            {selectedCustomer ? (
              <div className="col d-flex align-items-center">
                <label className="col-4 me-2 text-capitalize">
                  {localize('fullAccountNumber')}
                </label>
                <CustomerAccountByCustomerIdLookup
                  displayValue={selectedCreditAccount?.fullAccountNumber || ''}
                  type="text"
                  className="form-control ms-2"
                  onSelect={v => {
                    onCustomerCreditAccountLookup(v)
                  }}
                  selectedCustomer={selectedCustomer}
                />
              </div>
            ) : (
              <div className="col d-flex align-items-center">
                <label className="col-4 me-2 text-capitalize">
                  {localize('fullAccountNumber')}
                </label>
                <CustomerAccountLookup
                  displayValue={
                    selectedCreditAccount?.fullAccountNumber || '' || ''
                  }
                  type="text"
                  className="form-control ms-2"
                  onSelect={v => {
                    onCustomerCreditAccountLookup(v)
                  }}
                />
              </div>
            )}

            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedCreditAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default CustomerAccountDebitCreditPartial
