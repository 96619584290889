/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { QueuePriority, months } from '../../../../../../Global/enumeration'
import {
  getCurrentMonth,
  parseDate,
} from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import StandingOrderTab from './StandingOrderTab/StandingOrderTab'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function AdHocStandingOrderExecutionModal({
  modalMode,
  handleClose,
  data,
  adHocStandingOrder,
}) {
  const [customerData, setCustomerData] = useState({})
  const [selectedStandingOrders, setSelectedStandingOrders] = useState([])
  const [standingOrder, setStandingOrder] = useState({
    month: getCurrentMonth(),
    adhocInterestCapitalizationBalance: '',
    queuePriority: Object.keys(QueuePriority)[3],
  })
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)

  const fetchCustomerData = async () => {
    const url = '/CustomerAccount/find-customer-account'

    const { success, data } = await getData(url, {
      customerAccountId: adHocStandingOrder?.id,
      includeBalances: true,
      includeProductDescription: true,
    })

    if (success) {
      setCustomerData(data)
    }
  }

  const evaluateValueDate = async () => {
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url)
    if (success && data.result) {
      setUpdateDate(false)
      setStandingOrder({
        ...standingOrder,
        valueDate: parseDate(valueDate),
      })
    } else {
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  const handleSelect = (standingOrder, checked) => {
    if (checked) {
      setSelectedStandingOrders([...selectedStandingOrders, standingOrder])
    } else {
      setSelectedStandingOrders(
        selectedStandingOrders.filter(x => x.id !== standingOrder.id)
      )
    }
  }

  const onSelectAll = (standingOrders, checked) => {
    if (checked) {
      setSelectedStandingOrders(standingOrders)
    } else {
      setSelectedStandingOrders([])
    }
  }

  const handleSave = async () => {
    const url = '/RecurringBatch/execute-customer-account-standing-orders'
    const { success } = await putData(
      url,
      {
        standingOrderDTOs: selectedStandingOrders,
        month: standingOrder.month,
        valueDate: standingOrder.valueDate,
        priority: standingOrder.queuePriority,
        standingOrderExecutionMonths:
          standingOrder?.standingOrderExecutionMonths,
      },
      true,
      'Standing Order executed successfully.'
    )

    if (success) {
      handleClose()
      setSelectedStandingOrders([])
    }
  }

  const tabItems = [
    {
      label: localize('standingOrders'),
      item: (
        <>
          <StandingOrderTab
            customerId={adHocStandingOrder?.customerId}
            onSelectAll={onSelectAll}
            handleSelect={handleSelect}
            selectedStandingOrders={selectedStandingOrders}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    if (adHocStandingOrder?.id) {
      fetchCustomerData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adHocStandingOrder])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <ModalTemplate
      actionText={'save'}
      modalMode={modalMode}
      handleAction={handleSave}
      handleClose={handleClose}
      modalTitle="adHocStandingOrderExecution"
      modalClassName="modal-xl"
    >
      <div className="row g-3 py-2">
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label
              htmlFor="fullAccountNumber"
              className="col-4 text-capitalize"
            >
              {localize('fullAccountNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.fullAccountNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="accountStatus" className="col-4 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.statusDescription || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="remarks" className="col-4 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.remarks || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="productName" className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={
                customerData?.customerAccountTypeTargetProductDescription || ''
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="customerName" className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.customerFullName || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="customerType" className="col-4 text-capitalize">
              {localize('customerType')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={
                customerData?.customerMembershipClassCustomerTypeDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="customerMember#" className="col-4 text-capitalize">
              {localize('customerMember#')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.paddedCustomerSerialNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="employerName" className="col-4 text-capitalize">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.customerEmployerDescription || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="identification#" className="col-4 text-capitalize">
              {localize('identification#')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.customerIdentificationNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="memberNumber" className="col-4 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.formattedCustomerMemberNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="accountNumber" className="col-4 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.formattedCustomerAccountNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label
              htmlFor="personalFileNumber"
              className="col-4 text-capitalize"
            >
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.customerPersonalFileNumber || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="productSection" className="col-4 text-capitalize">
              {localize('productSection')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={
                customerData?.customerAccountTypeTargetProductSectionDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="bookBalance" className="col-4 text-capitalize">
              {localize('bookBalance')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.bookBalance || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="principalBalance" className="col-4 text-capitalize">
              {localize('principalBalance')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.principalBalance || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="interestBalance" className="col-4 text-capitalize">
              {localize('interestBalance')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={customerData?.interestBalance || ''}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="referenceMonth" className="col-4 text-capitalize">
              {localize('referenceMonth')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="Month"
              value={
                standingOrder?.month ||
                Object.keys(months)[standingOrder?.month]
              }
              onChange={e => {
                setStandingOrder({
                  ...standingOrder,
                  month: e.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="valueDate" className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <CustomDatePicker
              defaultDate={valueDate}
              name={'adHocStandingOrderExecutionValueDate'}
              onDateChange={date => setValueDate(date)}
              setDateToNull={updateDate}
              setUpdateDate={setUpdateDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="executionMonths" className="col-4 text-capitalize">
              {localize('executionMonths')}
            </label>
            <input
              type="number"
              min={1}
              defaultValue={1}
              className="form-control"
              value={standingOrder?.executionMonths || 1}
              onChange={e => {
                setStandingOrder({
                  ...standingOrder,
                  capitalizationMonths: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label htmlFor="priority" className="col-4 text-capitalize">
              {localize('priority')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="JournalEntryFilter"
              value={standingOrder?.queuePriority}
              onChange={e => {
                setStandingOrder({
                  ...standingOrder,
                  queuePriority: e.value,
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-12">
          <Tab tabItems={tabItems} />
        </div>
      </div>
    </ModalTemplate>
  )
}

export default AdHocStandingOrderExecutionModal
