/** @format */

/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import localize from '../../Global/Localize'
import { NavigationContext } from '../../Contexts/NavigationContext'
import signalRService from '../../Helpers/signalR'
import Swal from 'sweetalert2'

function TabPill({ menu, active, hasOpenModal }) {
  const {
    setActiveComponent,
    navigateToComponent,
    navigatedComponent,
    setNavigatedParentCode,
    setNavigatedMenuCode,
  } = useContext(NavigationContext)

  const [blocked, setBlocked] = useState(false)

  useEffect(() => {
    signalRService?.connection &&
      signalRService?.connection.on('RefreshMenus', menus => {
        let isAllowed =
          !!menus.find(m => Number(m) === Number(menu.code)) ||
          Number(menu.code) === 0
        setBlocked(!isAllowed)
      })
    //eslint-disable-next-line
  }, [])

  return (
    <div
      className={`nav-tab-pill ${active ? 'navigated' : ''} ${
        hasOpenModal ? 'has-open-modal' : ''
      } ${blocked ? 'blocked' : ''}`}
      onClick={() => {
        if (blocked) {
          return Swal.fire(
            'Blocked',
            'You are not allowed to visit this page',
            'error'
          )
        }
        setActiveComponent(menu.component)
        setNavigatedParentCode(menu.parentCode)
        setNavigatedMenuCode(menu.code)
      }}
    >
      <span className="text-capitalize">
        {localize(menu?.description || menu?.label)}
      </span>
      {
        // prevent closing this tab if it has an open modal
        menu?.hasOpenModal || (
          <button
            onClick={async e => {
              e.stopPropagation()
              // eslint-disable-next-line eqeqeq
              let ind = navigatedComponent.findIndex(
                // eslint-disable-next-line eqeqeq
                c => c.component == menu.component
              )
              if (ind >= 0) {
                let nc = [...navigatedComponent]
                nc.splice(ind, 1)
                await navigateToComponent(nc)
                // let css = navigatedComponent.length
                // eslint-disable-next-line eqeqeq
                if (active) {
                  setActiveComponent(null)
                  setNavigatedMenuCode(null)
                  setNavigatedParentCode(null)
                }
              }
            }}
            className="nav-tab-close"
          >
            &times;
          </button>
        )
      }
    </div>
  )
}

export default TabPill
