/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { batchStatus } from '../../Global/enumeration'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import DatePicker from '../Date/DatePicker'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'

const LoanDisbursementBatchLookup = ({ displayValue, onSelect }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: Object.keys(batchStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    filterByAuthorizedBranches: false,
    text: '',
  })

  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('batchNumber'),
      template: r => r.paddedBatchNumber,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('disbursementMode'),
      template: r => r.loanDisbursementModeDescription,
    },
    {
      label: localize('batchTotal'),
      template: r => formatCurrency(r.batchTotal),
    },
    {
      label: localize('loanProductCategory'),
      template: r => r.loanProductCategoryDescription,
    },
    {
      label: localize('reference'),
      template: r => r.reference,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r.isLocked.toString(),
    },
    {
      label: localize('priority'),
      template: r => r.priorityDescription,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => r.auditRemarks,
    },
    {
      label: localize('verified') + ' /' + localize('rejectedBy'),
      template: r => r.auditedBy,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => formatDate(r.auditedDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      '/lookup/LoanDisbursementBatch/find-loan-disbursement-batches-by-status-and-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleCancel = () => {
    setModalActive(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <div
          style={{
            maxHeight: '60dvh',
            overflow: 'auto',
          }}
        >
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white text-capitalize">
              {localize('loanDisbursementBatchLookup')}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-4">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor="recordsPerPage"
                          className="text-capitalize"
                          style={{
                            marginLeft: -10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          id="recordsPerPage"
                          className="form-select ms-3"
                          style={{ maxWidth: 'fit-content' }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="d-flex align-items-center">
                        <label htmlFor="status" className="text-capitalize">
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="BatchStatus"
                          value={reqParams.status}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                              pageIndex: 0,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                      <label htmlFor="search" className="col-3 text-capitalize">
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <div className="d-flex align-items-center justify-content-end">
                        <label htmlFor="search" className="text-capitalize">
                          {localize('search')}
                        </label>

                        <input
                          type="search"
                          id="search"
                          className="form-control col ms-2"
                          value={reqParams.text}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              text: e.target.value,
                            })
                          }}
                        />
                        <button
                          id="FilterButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={e => {
                            loadData()
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              loadData()
                            }
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" py-1">
                    <SimpleTable
                      columns={columns}
                      data={tableData}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                      canClickOnce
                      onClickOnce={r => {
                        setModalActive(false)
                        onSelect(r)
                      }}
                    />
                  </div>
                  <Pager
                    itemsCount={data.itemsCount}
                    pageSize={reqParams.pageSize}
                    pageIndex={reqParams.pageIndex}
                    pageBatchSize={5}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default LoanDisbursementBatchLookup
