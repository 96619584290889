/** @format */
import localize from '../../../../../../Global/Localize'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import CustomerAccountLookup from '../../../../../../Components/Lookup/CustomerAccountLookup'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useEffect, useState } from 'react'
import {
  ExpensePayableEntry,
  ExpensePayableType,
} from '../../../../../../Helpers/constants'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { CreatePayableReceivableEntries } from '../../Partials/CreatePayableReceivableEntries'
import Tab from '../../../../../../Components/Tabs/Tab'
import { ItemRegisterLookup } from '../../../../../../Components/Lookup/ItemRegisterLookup'
import { SupplierLookup } from '../../../../../../Components/Lookup/SupplierLookup'
import { useAuth } from 'react-oidc-context'
import CostCentreLookup from '../../../../../../Components/Lookup/CostCentreLookup'
import { getData, postData } from '../../../../../../Helpers/webApi'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CreatePayablesReceivableModal = ({
  expensePayable,
  setExpensePayable,
  entriesMetadata,
  setEntriesMetadata,
  expensePayableEntries,
  setExpensePayableEntries,
}) => {
  const [selectedExpensePayableType, setSelectedExpensePayableType] = useState(
    ExpensePayableType.DEBIT_GL_ACCOUNT,
  )
  const auth = useAuth()

  async function findCurrentPostingPeriod() {
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false,
    )
    if (success) {
      setExpensePayable({
        ...expensePayable,
        postingPeriodId: data.id || null,
        postingPeriodDescription: data.description || null,
      })
    }
  }

  useEffect(() => {
    findCurrentPostingPeriod()
  }, [])

  const handleOnRemove = async r => {
    let newEntries = [...expensePayableEntries].filter(x => x.id !== r.id)

    setExpensePayableEntries(newEntries)

    const { success, data } = await postData(
      '/lookup/ExpensePayables/remove-expense-payable-entries',
      {
        ExpensePayableEntries: newEntries || [],
        TotalValue: expensePayable?.totalValue,
      },
      false,
    )
    if (success) {
      setEntriesMetadata({
        apportioned: data.apportioned,
        shortage: data.shortage,
      })
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('voucherType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ExpensePayableType"
            value={selectedExpensePayableType}
            onChange={e => {
              setSelectedExpensePayableType(e.value)
              setExpensePayable({ ...expensePayable, type: e.value })
            }}
            sort
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('branch')}</label>
          <input
            className="form-control"
            disabled
            value={auth?.user?.profile?.cbs_EmployeeBranchDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <PostingPeriodLookup
            displayValue={expensePayable?.postingPeriodDescription}
            onSelect={r => {
              setExpensePayable({
                ...expensePayable,
                postingPeriod: r.id,
                postingPeriodDescription: r.description,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        {(selectedExpensePayableType === ExpensePayableType.DEBIT_GL_ACCOUNT ||
          selectedExpensePayableType ===
            ExpensePayableType.CREDIT_GL_ACCOUNT) && (
          <GeneralLedgerPartial
            expensePayable={expensePayable}
            setExpensePayable={setExpensePayable}
          />
        )}
        {(selectedExpensePayableType === ExpensePayableType.DEBIT_ASSET ||
          selectedExpensePayableType === ExpensePayableType.CREDIT_ASSET) && (
          <AssetPartial
            expensePayable={expensePayable}
            setExpensePayable={setExpensePayable}
          />
        )}
        {(selectedExpensePayableType ===
          ExpensePayableType.CREDIT_CUSTOMER_ACCOUNT ||
          selectedExpensePayableType ===
            ExpensePayableType.DEBIT_CUSTOMER_ACCOUNT) && (
          <CustomerAccountPartial
            expensePayable={expensePayable}
            setExpensePayable={setExpensePayable}
          />
        )}
        {(selectedExpensePayableType === ExpensePayableType.CREDIT_SUPPLIER ||
          selectedExpensePayableType === ExpensePayableType.DEBIT_SUPPLIER) && (
          <SupplierPartial
            expensePayable={expensePayable}
            setExpensePayable={setExpensePayable}
          />
        )}
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <CustomDatePicker
            defaultDate={expensePayable?.valueDate || ''}
            name={'valueDatePayableReceivable'}
            onDateChange={date =>
              setExpensePayable({ ...expensePayable, valueDate: date })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costCenter')}
          </label>
          <CostCentreLookup
            displayValue={expensePayable?.costCenterDescription}
            onSelect={r => {
              setExpensePayable({
                ...expensePayable,
                costCenterId: r.id,
                costCenterDescription: r.description,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('totalValue')}
          </label>
          <MaskNumber
            defaultMaskValue={expensePayable?.totalValue || ''}
            onMaskNumber={e => {
              setExpensePayable({
                ...expensePayable,
                totalValue: e.target.value,
              })
            }}
          />
        </div>
        <div className="col-8 d-flex align-items-center">
          <label
            style={{ marginRight: '4%' }}
            className="col-1 text-capitalize"
          >
            {localize('remarks')}
          </label>
          <input
            className="form-control"
            value={expensePayable?.remarks || ''}
            onChange={e => {
              setExpensePayable({ ...expensePayable, remarks: e.target.value })
            }}
          />
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <VoucherEntryDetailsCreator
              expensePayableEntries={expensePayableEntries}
              setExpensePayableEntries={setExpensePayableEntries}
              selectedExpensePayableType={selectedExpensePayableType}
              expensePayable={expensePayable}
              onGetEntriesMetadata={e => {
                setEntriesMetadata(e)
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <Tab
              tabItems={[
                {
                  label: localize('expensePayableEntries'),
                  item: (
                    <CreatePayableReceivableEntries
                      handleOnRemove={handleOnRemove}
                      data={expensePayableEntries}
                      shortage={entriesMetadata?.shortage}
                      apportioned={entriesMetadata?.apportioned}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const VoucherEntryDetailsCreator = ({
  selectedExpensePayableType,
  expensePayable,
  expensePayableEntries,
  setExpensePayableEntries,
  onGetEntriesMetadata,
}) => {
  const [entryModel, setEntryModel] = useState({
    expensePayableEntryType: ExpensePayableEntry.GL_ACCOUNT,
  })
  const auth = useAuth()
  const [isBusy, setIsBusy] = useState(false)

  const handleOnAddVoucherEntryDetails = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/lookup/ExpensePayables/compute-expense-payable-apportionments',
      {
        ExpensePayableEntries: expensePayableEntries || [],
        ExpensePayableEntry: entryModel?.expensePayableEntry || {},
        ExpensePayableEntryType: entryModel?.expensePayableEntryType,
        ExpensePayableType: selectedExpensePayableType,
        Amount: expensePayable?.totalValue,
        SelectedCustomerAccount: entryModel?.selectedCustomerAccount || {},
        SelectedExpensePayableEntryGLAccount:
          entryModel?.selectedExpensePayableEntryGLAccount || {},
        SelectedExpensePayableEntrySupplier:
          entryModel?.selectedExpensePayableEntrySupplier || {},
        SelectedItemRegister: entryModel?.selectedItemRegister || {},
        CostCenter: entryModel?.costCenter || {},
      },
      false,
    )
    if (success) {
      setExpensePayableEntries([
        ...expensePayableEntries,
        {
          ...data?.apportionedWrapper,
          branchDescription: auth?.user?.profile?.cbs_EmployeeBranchDescription,
        },
      ])
      onGetEntriesMetadata({
        apportioned: data.apportioned,
        shortage: data.shortage,
      })
      setEntryModel({
        expensePayableEntryType: ExpensePayableEntry.GL_ACCOUNT,
      })
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('voucherEntryDetails')}
        </div>
        <div className="row mb-3" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('entryType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="ExpensePayableEntryType"
              value={entryModel?.expensePayableEntryType}
              onChange={e => {
                setEntryModel({
                  ...entryModel,
                  expensePayableEntryType: e.value,
                })
              }}
              sort
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('branch')}
            </label>
            <input
              className="form-control"
              disabled
              value={auth?.user?.profile?.cbs_EmployeeBranchDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('costCenter')}
            </label>
            <CostCentreLookup
              displayValue={entryModel?.costCenter?.description}
              onSelect={r => {
                setEntryModel({ ...entryModel, costCenter: { ...r } })
              }}
            />
          </div>
        </div>
        <div className="row mb-3" style={{ marginTop: -10 }}>
          {entryModel?.expensePayableEntryType ===
            ExpensePayableEntry.GL_ACCOUNT && (
            <GeneralLedgerAccountPartialEntry
              setEntryModel={setEntryModel}
              entryModel={entryModel}
            />
          )}
          {entryModel?.expensePayableEntryType ===
            ExpensePayableEntry.ASSET && (
            <AssetPartialEntry
              setEntryModel={setEntryModel}
              entryModel={entryModel}
            />
          )}
          {entryModel?.expensePayableEntryType ===
            ExpensePayableEntry.CUSTOMER_ACCOUNT && (
            <CustomerAccountPartialEntry
              setEntryModel={setEntryModel}
              entryModel={entryModel}
            />
          )}
          {entryModel?.expensePayableEntryType ===
            ExpensePayableEntry.SUPPLIER && (
            <SupplierPartialEntry
              setEntryModel={setEntryModel}
              entryModel={entryModel}
            />
          )}
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('value')}
            </label>
            <MaskNumber
              onMaskNumber={r => {
                setEntryModel({
                  ...entryModel,
                  expensePayableEntry: {
                    ...entryModel?.expensePayableEntry,
                    value: r.target.value,
                  },
                })
              }}
              defaultMaskValue={entryModel?.expensePayableEntry?.value || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('primaryDescription')}
            </label>
            <input
              className="form-control"
              onChange={r => {
                setEntryModel({
                  ...entryModel,
                  expensePayableEntry: {
                    ...entryModel?.expensePayableEntry,
                    primaryDescription: r.target.value,
                  },
                })
              }}
              value={entryModel?.expensePayableEntry?.primaryDescription || ''}
            />
          </div>
        </div>
        <div className="row mb-3" style={{ marginTop: -10 }}>
          <div className="col-4 d-flex align-items-center">
            <label htmlFor="" className="col-3 text-capitalize">
              {localize('secondaryDescription')}
            </label>
            <input
              className="form-control"
              onChange={r => {
                setEntryModel({
                  ...entryModel,
                  expensePayableEntry: {
                    ...entryModel?.expensePayableEntry,
                    secondaryDescription: r.target.value,
                  },
                })
              }}
              value={
                entryModel?.expensePayableEntry?.secondaryDescription || ''
              }
            />
          </div>
          <div className="col-8 d-flex align-items-center">
            <label
              style={{ marginRight: '4%' }}
              className="col-1 text-capitalize"
            >
              {localize('reference')}
            </label>
            <input
              className="form-control"
              onChange={r => {
                setEntryModel({
                  ...entryModel,
                  expensePayableEntry: {
                    ...entryModel?.expensePayableEntry,
                    reference: r.target.value,
                  },
                })
              }}
              value={entryModel?.expensePayableEntry?.reference || ''}
            />
          </div>
        </div>
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2"
          style={{ borderRadius: 5 }}
        >
          {isBusy ? (
            <Loader />
          ) : (
            <button
              onClick={handleOnAddVoucherEntryDetails}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

const GeneralLedgerPartial = ({ expensePayable, setExpensePayable }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('glAccount')}</label>
        <ChartOfAccountsLookup
          displayValue={expensePayable?.chartOfAccountName}
          onSelect={r => {
            setExpensePayable({
              ...expensePayable,
              chartOfAccountName: r.name,
              chartOfAccountId: r.id,
            })
          }}
        />
      </div>
    </>
  )
}

const AssetPartial = ({ expensePayable, setExpensePayable }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('asset')}</label>
        <ItemRegisterLookup
          displayValue={expensePayable?.itemRegisterDescription}
          onSelect={e => {
            setExpensePayable({
              ...expensePayable,
              itemRegisterId: e.id,
              itemRegisterDescription: e.description,
            })
          }}
        />
      </div>
    </>
  )
}

const CustomerAccountPartial = ({ expensePayable, setExpensePayable }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">
          {localize('customerAccount')}
        </label>
        <CustomerAccountLookup
          onSelect={e => {
            setExpensePayable({
              ...expensePayable,
              customerAccountFullAccountNumber: e.fullAccountNumber,
              customerAccountId: e.id,
              chartOfAccountId:
                e.customerAccountTypeTargetProductChartOfAccountId,
            })
          }}
          displayValue={expensePayable?.customerAccountFullAccountNumber}
        />
      </div>
    </>
  )
}

const SupplierPartial = ({ expensePayable, setExpensePayable }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('supplier')}</label>
        <SupplierLookup
          displayValue={expensePayable?.supplierId}
          onSelect={r => {
            setExpensePayable({
              ...expensePayable,
              supplierId: r.id,
              chartOfAccountId: r.supplierTypeChartOfAccountId ?? '',
              supplierDescription: r.description,
            })
          }}
        />
      </div>
    </>
  )
}

const AssetPartialEntry = ({ entryModel, setEntryModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('asset')}</label>
        <ItemRegisterLookup
          displayValue={entryModel?.selectedItemRegister?.description}
          onSelect={r => {
            setEntryModel({ ...entryModel, selectedItemRegister: { ...r } })
          }}
        />
      </div>
    </>
  )
}

const CustomerAccountPartialEntry = ({ entryModel, setEntryModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">
          {localize('fullAccountNumber')}
        </label>
        <CustomerAccountLookup
          onSelect={r => {
            setEntryModel({ ...entryModel, selectedCustomerAccount: { ...r } })
          }}
          displayValue={entryModel?.selectedCustomerAccount?.fullAccountNumber}
        />
      </div>
    </>
  )
}

const GeneralLedgerAccountPartialEntry = ({ entryModel, setEntryModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('glAccount')}</label>
        <ChartOfAccountsLookup
          displayValue={entryModel?.selectedExpensePayableEntryGLAccount?.name}
          onSelect={r => {
            setEntryModel({
              ...entryModel,
              selectedExpensePayableEntryGLAccount: { ...r },
            })
          }}
        />
      </div>
    </>
  )
}

const SupplierPartialEntry = ({ entryModel, setEntryModel }) => {
  return (
    <>
      <div className="col d-flex align-items-center">
        <label className="col-3 text-capitalize">{localize('supplier')}</label>
        <SupplierLookup
          displayValue={
            entryModel?.selectedExpensePayableEntrySupplier?.description
          }
          onSelect={r => {
            setEntryModel({
              ...entryModel,
              selectedExpensePayableEntrySupplier: { ...r },
            })
          }}
        />
      </div>
    </>
  )
}
