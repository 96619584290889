/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import Loader from '../Loaders/Loader'
import { formatDate } from '../../Helpers/formatters'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Checkbox from '../Checkbox/Checkbox'
const InventoryCategoryLookup = ({ displayValue, onSelect, disabled }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: search,
    pageIndex: 0,
    pageSize: 10,
    traverseTree: false,
  })

  const columns = [
    {
      label: localize('name'),
      template: r => (
        <div
          className="p-0"
          dangerouslySetInnerHTML={{ __html: r?.indentedInventoryCategoryName }}
        />
      ),
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('depth'),
      template: r => <>{r?.depth}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const loadData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/InventoryCategory/find-inventory-categories-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
      setTableData(data)
    }
    setIsBusy(false)
  }
  const handleSort = (column, columnIndex) => {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])
  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('inventoryCategoryLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                  <div className="col-4 d-flex align-items-center justify-content-center">
                    <Checkbox
                      id="lookupTraverseTree"
                      checked={reqParams.traverseTree}
                      onCheck={value => {
                        setReqParams({
                          ...reqParams,
                          traverseTree: value,
                          updateDepth: value,
                        })
                      }}
                    />
                    <label
                      htmlFor="lookupTraverseTree"
                      className="col-4 text-capitalize ms-2"
                    >
                      {localize('traverseTree') + '?'}
                    </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <div className="d-flex align-items-center ">
                      <label className=" text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <input
                          type="search"
                          className="form-control ms-2"
                          defaultValue={reqParams?.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                      <button
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={loadData}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData?.pageCollection}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data?.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default InventoryCategoryLookup
