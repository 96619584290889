/** @format */

import React from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomerAccountHistory from '../../../../../../Components/ReUsables/CustomerAccount/CustomerAccountHistory'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { customerAccountManagementAction } from '../../../../../../Global/enumeration'
import AccountActivationTab from './AccountActivationTab/AccountActivationTab'
import AccountRemark from './AccountRemark/AccountRemark'
import Remark from './Remark/Remark'

const ManagementTab = ({ customerAccount, management, setManagement }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('operation')}
            </label>

            <EnumsServerCombo
              enumsKey="CustomerAccountManagementAction"
              sort
              customContainerStyle={{
                width: '100%',
              }}
              value={management?.managementAction}
              onChange={({ value }) => {
                setManagement({
                  ...management,
                  managementAction: value,
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <Tab
          preload
          tabItems={[
            (management.managementAction ===
              Object.keys(customerAccountManagementAction)[0] ||
              management.managementAction ===
                Object.keys(customerAccountManagementAction)[1] ||
              management.managementAction ===
                Object.keys(customerAccountManagementAction)[2] ||
              management.managementAction ===
                Object.keys(customerAccountManagementAction)[3] ||
              management.managementAction ===
                Object.keys(customerAccountManagementAction)[4]) && {
              label: localize(
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[0]
                  ? Object.values(customerAccountManagementAction)[0]?.name
                  : management.managementAction ===
                    Object.keys(customerAccountManagementAction)[1]
                  ? Object.values(customerAccountManagementAction)[1]?.name
                  : management.managementAction ===
                    Object.keys(customerAccountManagementAction)[2]
                  ? Object.values(customerAccountManagementAction)[2]?.name
                  : management.managementAction ===
                    Object.keys(customerAccountManagementAction)[3]
                  ? Object.values(customerAccountManagementAction)[3]?.name
                  : management.managementAction ===
                      Object.keys(customerAccountManagementAction)[4] &&
                    Object.values(customerAccountManagementAction)[4]?.name
              ),

              item:
                management.managementAction ===
                Object.keys(customerAccountManagementAction)[0] ? (
                  <AccountActivationTab
                    management={management}
                    setManagement={setManagement}
                    customerAccountInfo={customerAccount}
                  />
                ) : management.managementAction ===
                  Object.keys(customerAccountManagementAction)[1] ? (
                  <Remark
                    management={management}
                    setManagement={setManagement}
                  />
                ) : management.managementAction ===
                  Object.keys(customerAccountManagementAction)[2] ? (
                  <AccountRemark
                    management={management}
                    setManagement={setManagement}
                  />
                ) : management.managementAction ===
                  Object.keys(customerAccountManagementAction)[3] ? (
                  <Remark
                    management={management}
                    setManagement={setManagement}
                  />
                ) : (
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[4] && (
                    <Remark
                      management={management}
                      setManagement={setManagement}
                      title="accountSigningInstructions"
                    />
                  )
                ),
            },
            {
              label: localize(
                (management.managementAction ===
                  Object.keys(customerAccountManagementAction)[0] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[1] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[2] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[3] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[4] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[5] ||
                  management.managementAction ===
                    Object.keys(customerAccountManagementAction)[6]) &&
                  'history'
              ),
              item: (management.managementAction ===
                Object.keys(customerAccountManagementAction)[0] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[1] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[2] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[3] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[4] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[5] ||
                management.managementAction ===
                  Object.keys(customerAccountManagementAction)[6]) && (
                <CustomerAccountHistory customerAccount={customerAccount} />
              ),
            },
          ]}
        />
      </div>
    </>
  )
}

export default ManagementTab
