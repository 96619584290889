/**
 * eslint-disable
 *
 * @format
 */

import React, { useEffect, useState } from 'react'
import { formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Checkbox from '../Checkbox/Checkbox'

function LoanPurposeLookup({
  onSelect,
  displayValue,
  canSelectHeader = false,
}) {
  const columns = [
    {
      label: localize('name'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
      sortBy: 'name',
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
      sortBy: 'category',
    },
    {
      label: localize('depth'),
      template: r => <>{r?.depth}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/LoanPurpose/find-loan-purpose-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setIsBusy(false)
      setData(data)
      setTableData(data.pageCollection)
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('loanPurposeLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center my-2 col-4">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                        pageIndex: 0,
                      })
                    }}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="col-3">
                      <div className="d-flex align-items-center me-5">
                        <Checkbox
                          id={'req-params--loan-traverse-tree-lookup'}
                          checked={reqParams.traverseTree}
                          onCheck={v => {
                            setReqParams({ ...reqParams, traverseTree: v })
                          }}
                        />
                        <label
                          htmlFor="req-params--loan-traverse-tree-lookup"
                          className="text-capitalize ms-2"
                        >
                          {localize('traverseTree')}?
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control ms-3"
                        id="searchTable"
                      />
                      <button
                        type="submit"
                        className="btn btn-primary ms-1 text-capitalize"
                      >
                        <i className="uil uil-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default LoanPurposeLookup
