/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import {
  ChargeType,
  loanDeductibleProduct,
} from '../../../../../Global/enumeration'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData, putData } from '../../../../../Helpers/webApi'
import {
  formatAsPercent,
  formatCurrency,
} from '../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../../../Helpers/extensions'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

function Deductibles({ loanProduct, disabled }) {
  const [isBusy, setIsBusy] = useState(false)
  const initialDed = {
    type: 1,
    validateAppraisalProductsBalance: false,
    chargeType: 1,
    chargeTypeDescription: ChargeType[1].name,
    description: '',
    range: 0,
    chargePercentage: 0,
    chargeFixedAmount: 0,
  }
  const [deductibles, setDeductibles] = useState([])
  const [ded, setDed] = useState(initialDed)

  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.targetProductDescription}</>,
    },
    {
      label: `${localize('range')} (${localize('lowerLimit')})`,
      template: r => <>{formatCurrency(r?.rangeLowerLimit)}</>,
    },
    {
      label: `${localize('range')} (${localize('upperLimit')})`,
      template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
    {
      label: localize('validateAppraisalProductsBalance') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.validateAppraisalProductsBalance.toString()}
        </span>
      ),
    },
  ]

  useEffect(() => {
    loadDeductibles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadDeductibles() {
    setIsBusy(true)
    setIsBusy(true)
    // load stuff here
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-deductibles-by-loan-product-id',
      { loanProductId: loanProduct?.id },
      false
    )
    if (success) {
      setDeductibles(data?.result)
    }
    setIsBusy(false)
    setIsBusy(false)
  }

  async function postDeductibles() {
    setIsBusy(true)
    const { success } = await putData(
      `/LoanProduct/update-loan-product-deductibles-by-loan-product-id?loanProductId=${loanProduct?.id}`,
      deductibles,
      false,
      'Deductibles Updated!'
    )

    if (success) {
      loadDeductibles()
    }
    isBusy(false)
  }

  function removeRange(r) {
    let deds = [...deductibles]
    deds = deds.filter(x => x.id !== r.id)
    setDeductibles(deds)
  }

  function addRange() {
    let deds = [...deductibles]
      .filter(x => x.targetProductId === ded?.targetProductId)
      .sort((a, b) => a.rangeUpperLimit - b.rangeUpperLimit)
    let lowerLimit = 0
    let newLimit = Number(ded?.range) - 0.01
    if (deds.length > 0) {
      lowerLimit = Number(deds[deds.length - 1].rangeUpperLimit) + 0.01

      if (Number(ded?.range) <= lowerLimit) {
        return Swal.fire(
          'Error',
          `Invalid Sequence for '${
            deds[deds.length - 1].targetProductDescription
          }'`,
          'error'
        )
      }
    }
    let allDeds = [...deductibles]

    allDeds.push({
      id: generateGuid(),
      ...ded,
      rangeLowerLimit: lowerLimit,
      rangeUpperLimit: newLimit,
      typeDescription: loanDeductibleProduct[ded?.type]?.name,
    })
    setDeductibles(allDeds)
    setDed(initialDed)
  }

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={deductibles} loading={isBusy} />
    </>
  ) : (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  value={ded?.description}
                  onChange={e =>
                    setDed({ ...ded, description: e.target.value })
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('type')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="LoanDeductibleProduct"
                  value={ded?.type}
                  onChange={e => setDed({ ...ded, type: e.value })}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize(
                    ded?.type == 1
                      ? 'savingsProduct'
                      : ded?.type == 4
                      ? 'gLAccount'
                      : 'investmentProduct'
                  )}
                </label>
                {ded?.type == 1 ? (
                  <SavingsProductLookup
                    displayValue={ded?.targetProductDescription}
                    onSelect={p =>
                      setDed({
                        ...ded,
                        targetProductDescription: p?.description,
                        targetProductId: p?.id,
                      })
                    }
                  />
                ) : ded?.type == 4 ? (
                  <ChartOfAccountsLookup
                    displayValue={ded?.targetProductDescription}
                    onSelect={p =>
                      setDed({
                        ...ded,
                        targetProductDescription: p?.description,
                        targetProductId: p?.id,
                      })
                    }
                  />
                ) : (
                  <InvestmentProductLookup
                    displayValue={ded?.targetProductDescription}
                    onSelect={p =>
                      setDed({
                        ...ded,
                        targetProductDescription: p?.description,
                        targetProductId: p?.id,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('range')} ({localize('lowerLimit')})
                </label>
                <input
                  type="number"
                  value={ded?.range}
                  onChange={e => setDed({ ...ded, range: e.target.value })}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('chargeType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ChargeType"
                  value={ded?.chargeType}
                  onChange={e =>
                    setDed({
                      ...ded,
                      chargeType: e.value,
                      chargeTypeDescription: ChargeType[e.value].name,
                    })
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('charge')}
                </label>
                <input
                  type="number"
                  value={
                    ded?.chargeType === 1
                      ? ded?.chargePercentage
                      : ded?.chargeFixedAmount
                  }
                  onChange={e =>
                    setDed({
                      ...ded,
                      [ded?.chargeType == 1
                        ? 'chargePercentage'
                        : 'chargeFixedAmount']: Number(e.target.value),
                    })
                  }
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mb-2 bg-light p-2">
            <div
              className="d-flex align-items-center"
              style={{ transform: 'translateY(5px)' }}
            >
              <Checkbox
                id={'loanProductvalidateappraisalproductbalance'}
                onCheck={v =>
                  setDed({ ...ded, validateAppraisalProductsBalance: v })
                }
                checked={ded?.validateAppraisalProductsBalance}
              />
              <label
                htmlFor="loanProductvalidateappraisalproductbalance"
                className="ms-1 me-4 text-capitalize"
              >
                {localize('validateLoanAppraisalProductsBalance')}
              </label>
            </div>
            <button
              className="btn btn-sm btn-success text-uppercase px-5"
              onClick={addRange}
            >
              {localize('add')}
            </button>
          </div>
          <SimpleTable
            columns={columns}
            data={deductibles}
            loading={isBusy}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => removeRange(r)}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={postDeductibles} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default Deductibles
