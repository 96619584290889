/** @format */

import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import React from 'react'
import { formatAsPercent, formatCurrency } from '../../../../Helpers/formatters'
import { ChargeSplits } from './Partials/ChargeSplits'
import { chargeType } from '../../../../Helpers/constants'

export const ViewLevyModal = ({ selectedLevy,levySplits,mode }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('name')}</label>
          <input
            className="form-control"
            defaultValue={selectedLevy?.description}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('chargeType')}
          </label>
          <input
            className="form-control"
            value={selectedLevy?.chargeTypeDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('charge')}</label>
          {selectedLevy?.chargeType === chargeType.PERCENTAGE ? (
            <input
              value={formatAsPercent(selectedLevy?.chargePercentage)}
              disabled
              className="form-control"
            />
          ) : (
            <input
              value={formatCurrency(selectedLevy?.chargeFixedAmount, 2, 2, false)}
              disabled
              className="form-control text-end"
            />
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            tabItems={[
              {
                label: localize('chargeSplitting'),
                item: <ChargeSplits mode={mode} levySplits={levySplits} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
