/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _c = {
  cards: {
    en: 'Cards',
    sw: 'Kadi Za Mishahara',
  },
  currencies: { en: 'currencies', sw: 'sarafu' },
  closeSavingsAccountsOnMembershipTerminationNotification: {
    en: 'close savings accounts on membership termination notification',
    sw: 'funga akaunti za akiba kwenye arifa ya kukomesha uanachama',
  },
  currency: {
    en: 'currency',
    sw: 'sarafu',
  },
  customerValidationFailed: {
    en: 'customer validation failed',
    sw: 'udhibitisho wa mteja haujapita',
  },
  customerValidation: {
    en: 'customer validation',
    sw: 'uthibitisho wa mteja',
  },
  currentTellerBalance: {
    en: 'current teller balance',
    sw: 'salio la sasa la kikasha',
  },
  companies: {
    en: 'companies',
    sw: 'kampuni',
  },
  currentAvailableBalance: {
    en: 'current available balance',
    sw: 'pesa iliopo',
  },
  custom: {
    en: 'custom',
    sw: 'tanafsi',
  },
  createdBy: {
    en: 'created by',
    sw: 'imetengenezwa na',
  },
  createdAt: {
    en: 'created at',
    sw: 'imeundwa saa',
  },
  close: {
    en: 'close',
    sw: 'funga',
  },
  closeAll: {
    en: 'close all',
    sw: 'funga zote',
  },
  city: {
    en: 'city',
    sw: 'mji',
  },
  customerStatementPassword: {
    en: 'customer statement password',
    sw: 'nenosiri la taarifa ya mteja',
  },
  customerStatementFooter: {
    en: 'customer statement footer',
    sw: 'kijachini cha taarifa ya mteja',
  },
  chequeTypes: {
    en: 'cheque types',
    sw: 'aina za cheki',
  },
  chartOfAccountName: {
    en: 'g/l account name',
    sw: 'jina la akaunti',
  },
  chartOfAccountCostDistributionRuleDescription: {
    en: 'g/l account cost distr. rule',
    sw: 'usambazaji wa gharama g/l',
  },
  createdDate: {
    en: 'created date',
    sw: 'tarehe iliyoundwa',
  },
  complementPercentage: {
    en: 'complement percentage',
    sw: 'asilimia ya kukamilisha',
  },
  chargeBasisValue: {
    en: 'charge basis value',
    sw: 'thamani ya msingi wa malipo',
  },
  chartOfAccountCurrencyDescription: {
    en: 'g/l account currency',
    sw: 'chati ya maelezo ya sarafu ya akaunti',
  },
  concealBankToMobileListing: {
    en: 'conceal bank to mobile listing',
    sw: 'ficha benki kwa orodha ya simu za mkononi',
  },
  creditManagement: {
    en: 'credit management',
    sw: 'usimamizi wa mikopo',
  },
  consolidatePostings: {
    en: 'consolidate postings',
    sw: 'kuunganisha machapisho',
  },
  customerRelationshipManagement: {
    en: 'customer relationship management',
    sw: 'usimamizi wa uhusiano wa mteja',
  },
  chargeRecoveryModeDescription: {
    en: 'charge recovery mode',
    sw: 'maelezo ya hali ya kurejesha malipo',
  },
  customers: {
    en: 'customers',
    sw: 'wateja',
  },
  companyCode: {
    en: 'company code',
    sw: 'msimbo',
  },
  customerType: {
    en: 'customer type',
    sw: 'aina ya mteja',
  },
  chartOfAccount: {
    en: 'chart of account',
    sw: 'chati ya akaunti',
  },
  create: {
    en: 'create',
    sw: 'unda',
  },
  cancel: {
    en: 'cancel',
    sw: 'ghairi',
  },
  charges: {
    en: 'charges',
    sw: 'gharama',
  },
  costCentres: {
    en: 'cost centres',
    sw: 'vituo vya gharama',
  },
  costDistributionRules: {
    en: 'cost distribution rules',
    sw: 'kanuni za usambazaji wa gharama',
  },
  chartOfAccounts: {
    en: 'chart of accounts',
    sw: 'mpangilio wa akaunti za fedha',
  },
  chargesDetermination: {
    en: 'charges determination',
    sw: 'uamuzi wa gharama',
  },
  creditTypes: {
    en: 'credit types',
    sw: 'aina za mikopo',
  },
  closedBy: {
    en: 'closed by',
    sw: 'imefungwa na',
  },
  closedDate: {
    en: 'closed date',
    sw: 'tarehe iliyofungwa',
  },
  creditTypeDescription: {
    en: 'credit type description',
    sw: 'maelezo ya aina ya mikopo',
  },
  capitalizeInterest: {
    en: 'capitalize interest',
    sw: 'mtaji wa riba',
  },
  channelType: {
    en: 'channel type',
    sw: 'aina ya kituo',
  },
  classification: {
    en: 'classification',
    sw: 'uainishaji',
  },
  contributionValues: {
    en: 'contribution value',
    sw: 'thamani ya mchango',
  },
  clearedEffect: {
    en: 'cleared effect',
    sw: 'athari iliyosafishwa',
  },
  createDataAttachementRemark: {
    en: 'create data attachement remark',
    sw: 'Unda maoni ya ambatisha data',
  },
  chargeRecoveryMode: {
    en: 'charge recovery mode',
    sw: 'Hali ya kurejesha malipo',
  },
  createDepartment: {
    en: 'create department',
    sw: 'unda idara',
  },
  createPerformancePillar: {
    en: 'create performance pillar',
    sw: 'unda nguzo ya utendaji',
  },
  createPerformanceObjective: {
    en: 'create performance objective',
    sw: 'unda Malengo ya Utendaji',
  },
  createExitInterviewQuestion: {
    en: 'create exit interview question',
    sw: 'unda swali la mahojiano ya kutoka',
  },
  createPerformanceMeasure: {
    en: 'create performance measure',
    sw: 'unda kipimo cha utendaji',
  },
  createPerformancePeriod: {
    en: 'create performance period',
    sw: 'unda kipindi cha utendaji',
  },
  capital: {
    en: 'capital',
    sw: 'raslimali',
  },
  category: {
    en: 'category',
    sw: 'kategoria',
  },
  costDistributionRule: {
    en: 'cost distribution rule',
    sw: 'kanuni ya usambasaji wa gharama',
  },
  chargeTypeDescription: {
    en: 'charge type',
    sw: 'maelezo ya aina ya mikopo',
  },
  chargePercentage: {
    en: 'percentage',
    sw: 'asilimia ya malipo',
  },
  chargeFixedAmount: {
    en: 'fixed amount',
    sw: 'toza kiwango kisichobadilika',
  },
  chargeSplitting: {
    en: 'charge splitting',
    sw: 'kugawanyika kwa malipo',
  },
  chargeType: {
    en: 'charge type',
    sw: 'aina ya malipo',
  },
  computeChargeOnTopUp: {
    en: 'compute charge on top up',
    sw: 'hesabu malipo juu juu',
  },
  chargeBorneBy: {
    en: 'charge borne by',
    sw: 'malipo yanayobebwa na',
  },
  checkoffEntryType: {
    en: 'check-off entry type',
    sw: 'aina ya kiingilio cha malipo',
  },
  costDistribution: {
    en: 'cost distribution',
    sw: 'usambazaji wa gharama',
  },
  costDistributions: {
    en: 'cost distributions',
    sw: 'mgawanyo wa gharama',
  },
  costCenter: {
    en: 'cost center',
    sw: 'kituo cha gharama',
  },
  createCurrency: {
    en: 'create currency',
    sw: 'unda sarafu',
  },
  createForeignExchangeRate: {
    en: 'create foreign exchange rate',
    sw: 'kuunda kiwango cha ubadilishaji wa fedha za kigeni',
  },
  code: {
    en: 'code',
    sw: 'msimbo',
  },
  company: {
    en: 'company',
    sw: 'kampuni',
  },
  createIncomeAdjustment: {
    en: 'create income adjustment',
    sw: 'unda marekebisho ya mapato',
  },
  createLoaningRemark: {
    en: 'create loaning remark',
    sw: 'unda maoni ya mikopo',
  },
  classificationDescription: {
    en: 'classification description',
    sw: 'maelezo ya uainishaji',
  },
  createHoliday: {
    en: 'create holiday',
    sw: 'unda likizo',
  },
  createLeaveType: {
    en: 'create leave type',
    sw: 'tengeneza aina ya likizo',
  },
  createTrainingFacilitator: {
    en: 'create training facilitator',
    sw: 'tengeneza mwezeshaji wa mafunzo',
  },
  createTrainingType: {
    en: 'create training type',
    sw: 'create training type',
  },
  charge: {
    en: 'charge',
    sw: 'gharama',
  },
  collectionAccountPadding: {
    en: 'collection account padding',
    sw: 'akaunti ya ukusanyaji',
  },
  companyDescription: {
    en: 'company description',
    sw: 'maelezo ya kampuni',
  },
  controlAccount: {
    en: 'control account',
    sw: 'dhibiti akaunti',
  },
  chargeDetails: {
    en: 'charge details',
    sw: 'maelezo ya malipo',
  },
  concealMBankingListing: {
    en: 'conceal mbanking listing',
    sw: 'ficha orodha ya benki ya simu',
  },
  concealMobileBankingLimit: {
    en: 'conceal mobile banking limit',
    sw: 'ficha kikomo cha huduma ya benki ya simu',
  },
  consecutiveIncome: {
    en: 'consecutive income',
    sw: 'mapato yanayofuata kwa mpigo',
  },
  creditBatchType: {
    en: 'credit batch type',
    sw: 'aina ya bechi ya mkopo',
  },
  creditBatchFileType: {
    en: 'credit batch file type',
    sw: 'aina ya faili ya bechi ya mkopo',
  },
  chartOfAccountCurrency: {
    en: 'g/l account currency',
    sw: 'sarafu ya akaunti ya g/l',
  },
  chartOfAccountCostDistributionRule: {
    en: 'g/l account cost distribution rule',
    sw: 'Sheria ya usambazaji wa gharama ya akaunti ya g/l',
  },
  consolidationProduct: {
    en: 'consolidation product',
    sw: 'bidhaa ya ujumuishaji',
  },
  chargeUnconsolidatedBalance: {
    en: 'charge unconsolidated balance',
    sw: 'kutoza salio lisilounganishwa',
  },
  customerName: {
    en: 'customer name',
    sw: 'jina la mteja',
  },
  chargesExemption: {
    en: 'charges exemption',
    sw: 'msamaha wa malipo',
  },
  chargesExemptions: {
    en: 'charges exemption',
    sw: 'misamaha wa malipo',
  },
  concessionExceptions: {
    en: 'concession exceptions',
    sw: 'ubaguzi wa makubaliano',
  },
  customerDetails: {
    en: 'customer details',
    sw: 'maelezo ya mteja',
  },
  commission: {
    en: 'commission',
    sw: 'kamisheni',
  },
  concessionExceptionDetails: {
    en: 'concession exception details',
    sw: 'maelezo ya ubaguzi wa makubaliano',
  },
  creditType: {
    en: 'credit type',
    sw: 'aina ya mkopo',
  },
  customerFullName: {
    en: 'customer full name',
    sw: 'jina kamili la mteja',
  },
  customerPersonalFileNumber: {
    en: 'customer personal file number.',
    sw: 'nambari ya faili ya kibinafsi ya mteja',
  },
  customerIndividualNationalityDescription: {
    en: 'customer individual nationality description',
    sw: 'maelezo ya utaifa wa mteja',
  },
  customerIndividualIdentityCardTypeDescription: {
    en: 'customer individual identity card type description',
    sw: 'maelezo ya aina ya kitambulisho cha mteja',
  },
  customerIndividualIdentityCardNumber: {
    en: 'customer individual identity card number',
    sw: 'nambari ya kitambulisho cha mteja',
  },
  customerIndividualGenderDescription: {
    en: 'customer individual gender description',
    sw: 'maelezo ya jinsia ya mteja',
  },
  customerIndividualMaritalStatusDescription: {
    en: 'customer individual marital status description',
    sw: 'maelezo ya hali ya ndoa ya mteja',
  },
  costCenterDescription: {
    en: 'cost center description',
    sw: 'maelezo ya kituo cha gharama',
  },
  customerPersonalIdentificationNumber: {
    en: 'customer personal identification number',
    sw: 'nambari ya kitambulisho cha kibinafsi ya mteja',
  },
  conditionalLending: {
    en: 'conditional lending',
    sw: 'mikopo ya masharti',
  },
  customerQueries: {
    en: 'customer queries',
    sw: 'uulizaji wa mteja',
  },
  collateralValue: {
    en: 'collateral value',
    sw: 'thamani ya dhamana',
  },
  collateralAdvanceRate: {
    en: 'collateral advance rate',
    sw: 'kiwango cha dhamana',
  },
  collateralStatus: {
    en: 'collateral status',
    sw: 'hali ya dhamana',
  },
  customerSerialNumber: {
    en: 'customer serial number',
    sw: 'nambari ya mteja',
  },
  catalogue: {
    en: 'catalogue',
    sw: 'orodha',
  },
  current: {
    en: 'current',
    sw: 'sasa',
  },
  customerMember: {
    en: 'customer member',
    sw: 'mwanachama wa mteja',
  },
  chargeLookup: {
    en: 'charge lookup',
    sw: 'tafuta malipo',
  },
  carryForwardsBalance: {
    en: 'carry forwards balance',
    sw: 'hamisha salio',
  },
  customer: {
    en: 'customer',
    sw: 'mteja',
  },
  carrier: {
    en: 'carrier',
    sw: 'mbebaji',
  },
  chequeLeaves: {
    en: 'cheque leaves',
    sw: 'karatasi za hundi',
  },
  chequeLeave: {
    en: 'cheque leave',
    sw: 'karatasi ya hundi',
  },
  chargeExemptions: {
    en: 'charge exemptions',
    sw: 'misamaha wa malipo',
  },
  chargeExemption: {
    en: 'charge exemptions',
    sw: 'msamaha wa malipo',
  },
  companyLookup: {
    en: 'company lookup',
    sw: 'chagua kampuni',
  },
  chartOfAccountLookUp: {
    en: 'g/l account lookup',
    sw: 'utafutaji wa akaunti ya g/l',
  },
  customerLookup: {
    en: 'customer lookup',
    sw: 'chagua mteja',
  },
  citizenship: {
    en: 'citizenship',
    sw: 'uraia',
  },
  clan: {
    en: 'clan',
    sw: 'ukoo',
  },
  cusomerRegisterQueries: {
    en: 'customer register queries',
    sw: 'uulizaji wa usajili wa mteja',
  },
  class: {
    en: 'class',
    sw: 'darasa',
  },
  committedShares: {
    en: 'committed shares',
    sw: 'hisani zilizotolewa',
  },
  claimType: {
    en: 'claim type',
    sw: 'aina ya madai',
  },
  claimDate: {
    en: 'claim date',
    sw: 'tarehe ya madai',
  },
  claimantDetails: {
    en: 'claimant details',
    sw: 'maelezo ya mdai',
  },
  chiefNames: {
    en: 'chief names',
    sw: 'majina ya mkuu',
  },
  credit: {
    en: 'credit',
    sw: 'mkopo',
  },
  customerLoansNetting: {
    en: 'customer loans netting',
    sw: 'mikopo ya mteja',
  },
  closing: {
    en: 'closing',
    sw: 'kufunga',
  },
  checkTruncationRequests: {
    en: 'cheque truncation requests',
    sw: 'ombi la kukata hundi',
  },
  customerMemberNumber: {
    en: 'customer member number',
    sw: 'nambari ya mwanachama',
  },
  collections: {
    en: 'collections',
    sw: 'makusanyo',
  },
  credits: {
    en: 'credits',
    sw: 'mikopo',
  },
  cutOffDate: {
    en: 'cut-off date',
    sw: 'tarehe ya mwisho',
  },
  chiefIdNumber: {
    en: 'chief id number',
    sw: 'nambari ya kitambulisho cha chifu',
  },
  claimantNames: {
    en: 'claimant names',
    sw: 'majina ya mdai',
  },
  claimantMobileNumber: {
    en: 'claimant mobile number',
    sw: 'nambari ya simu ya mdai',
  },
  claimantPfNumber: {
    en: 'claimant personal file number',
    sw: 'nambari ya faili ya mdai',
  },
  claimantIdNumber: {
    en: 'claimant id number',
    sw: 'nambari ya kitambulisho cha mdai',
  },
  customSystemInitializationTime: {
    en: 'custom system initialization time',
    sw: 'wakati tanafsi wa kuanzisha mfumo',
  },
  customSystemLockTime: {
    en: 'custom system lock time',
    sw: 'wakati tanafsi wa kufunga mfumo',
  },
  costCenters: {
    en: 'cost centers',
    sw: 'vituo vya gharama',
  },
  chartOfAccountDetermination: {
    en: 'g/l account determination',
    sw: 'uamuzi wa akaunti ya g/l',
  },
  ChargesAndLevies: {
    en: 'Charges and Levies',
    sw: 'Gharama na Ushuru',
  },
  chartOfAccountTransactions: {
    en: 'g/l account transactions',
    sw: 'g/l akaunti ya shughuli',
  },
  costDistributionPercentage: {
    en: 'cost distribution percentage',
    sw: 'asilimia ya usambazaji wa gharama',
  },
  'costDistr.Rule': {
    en: 'cost distr. rule',
    sw: 'kanuni ya usambazaji wa gharama',
  },
  cellReference: {
    en: 'cell reference',
    sw: 'seli',
  },
  cellValue: {
    en: 'cell value',
    sw: 'thamani ya seli',
  },
  crAccount: {
    en: 'CR account',
    sw: 'CR akaunti',
  },
  crAmount: {
    en: 'CR amount',
    sw: 'Kiasi cha CR',
  },
  comments: {
    en: 'comments',
    sw: 'maoni',
  },
  chargeClearanceFee: {
    en: 'charge clearance fee',
    sw: 'gharama ya kuondoa',
  },
  chargeArrearsFee: {
    en: 'charge arrears fee',
    sw: 'gharama ya malimbikizo ya malipo',
  },
  customerAccountProduct: {
    en: 'customer account product',
    sw: 'bidhaa ya akaunti ya mteja',
  },
  createHierarchy: {
    en: 'create hierarchy',
    sw: 'unda muundo',
  },
  condition: {
    en: 'condition',
    sw: 'sharti',
  },
  concessionType: {
    en: 'concession type',
    sw: 'aina ya msamaha',
  },
  concessionPercentage: {
    en: 'concession percentage',
    sw: 'asilimia ya msamaha',
  },
  concessionFixedAmount: {
    en: 'concession fixed amount',
    sw: 'kiasi cha msamaha ',
  },
  checkOffCutOffDate: {
    en: 'check-off cut-off date',
    sw: 'tarehe ya mwisho',
  },
  costCentre: {
    en: 'cost center',
    sw: 'kituo cha gharama',
  },
  creditBatch: {
    en: 'credit batch',
    sw: 'kundi la mkopo',
  },
  concession: {
    en: 'concession',
    sw: 'msamaha',
  },
  creditTypeLookup: {
    en: 'credit type lookup',
    sw: 'tafuta aina ya mkopo',
  },
  cutoffDate: {
    en: 'cut-off date',
    sw: 'tarehe ya mwisho',
  },
  cellValueComputationMode: {
    en: 'cell value computation mode',
    sw: 'hali ya kuhesabu thamani ya seli',
  },
  cellValueComputationTag: {
    en: 'cell value computation tag',
    sw: 'alama ya kuhesabu thamani ya seli',
  },
  constituentEntries: {
    en: 'constituent entries',
    sw: 'ingizo la msingi',
  },
  customerAccountlookup: {
    en: 'customer account lookup',
    sw: 'uulizaji wa akaunti ya mteja',
  },
  creditOrigination: {
    en: 'credit (origination)',
    sw: 'mkopo (asili)',
  },
  creditVerification: {
    en: 'credit (verification)',
    sw: 'mkopo (uthibitisho)',
  },
  creditAuthorization: {
    en: 'credit (authorization)',
    sw: 'mkopo (idhini)',
  },
  createBudgetSupplement: {
    en: 'create budget supplement',
    sw: 'unda nyongeza ya bajeti',
  },
  component: {
    en: 'component',
    sw: 'sehemu',
  },
  creditBatchLookup: {
    en: 'credit batch lookup',
    sw: 'tafuta kundi la mkopo',
  },
  creditFullAccountNumber: {
    en: 'credit full account number',
    sw: 'nambari kamili ya akaunti ya mkopo',
  },
  creditCustomerName: {
    en: 'credit customer name',
    sw: 'jina la mkopo la mteja',
  },
  creditCustomerAccountNumber: {
    en: 'credit customer account number',
    sw: 'nambari ya akaunti ya mkopo ya mteja',
  },
  creditCustomerMemberNumber: {
    en: 'credit customer member number',
    sw: 'nambari ya mteja wa mkopo',
  },
  creditCustomerPersonalFileNumber: {
    en: 'credit customer personal file number',
    sw: 'nambari ya faili la kibinafsi la mteja wa mkopo',
  },
  creditChartOfAccountName: {
    en: 'credit chart of account name',
    sw: 'jina la akaunti ya mkopo ya g/l',
  },
  creditGLAccountName: {
    en: 'credit g/l account name',
    sw: 'jina la akaunti ya mkopo ya g/l',
  },
  contraCostCenter: {
    en: 'contra cost center',
    sw: 'kituo cha gharama cha contra',
  },
  creditRef: {
    en: 'credit ref',
    sw: 'mkopo ref',
  },
  customerMemberNo: {
    en: 'customer member number',
    sw: 'nambari ya mteja',
  },
  column1: {
    en: 'column 1',
    sw: 'safu 1',
  },
  column2: {
    en: 'column 2',
    sw: 'safu 2',
  },
  column3: {
    en: 'column 3',
    sw: 'safu 3',
  },
  column4: {
    en: 'column 4',
    sw: 'safu 4',
  },
  column5: {
    en: 'column 5',
    sw: 'safu 5',
  },
  column6: {
    en: 'column 6',
    sw: 'safu 6',
  },
  customerLoansNettingOff: {
    en: 'customer loans netting off',
    sw: 'mikopo ya Wateja kuondolewa',
  },
  column7: {
    en: 'column 7',
    sw: 'safu 7',
  },
  column8: {
    en: 'column 8',
    sw: 'safu 8',
  },
  column9: {
    en: 'column 9',
    sw: 'safu 9',
  },
  column10: {
    en: 'column 10',
    sw: 'safu 10',
  },
  column11: {
    en: 'column 11',
    sw: 'safu 11',
  },
  column12: {
    en: 'column 12',
    sw: 'safu 12',
  },
  column13: {
    en: 'column 13',
    sw: 'safu 13',
  },
  column14: {
    en: 'column 14',
    sw: 'safu 14',
  },
  creditBatchDiscrepancyLookup: {
    en: 'credit batch discrepancy lookup',
    sw: 'tafuta hitilafu ya kundi la mkopo',
  },
  creditBatchTotalValue: {
    en: 'credit batch total value',
    sw: 'jumla ya kundi la mkopo',
  },
  creditBatchMonthDescription: {
    en: 'credit batch month description',
    sw: 'mwezi wa kundi la mkopo',
  },
  creditBatchReference: {
    en: 'credit batch reference',
    sw: 'rejeleo la kundi la mkopo',
  },
  creditBatchTypeDescription: {
    en: 'credit batch type description',
    sw: 'aina ya kundi la mkopo',
  },
  creditTypeBatchFileTypeDescription: {
    en: 'credit type batch file type description',
    sw: 'aina ya faili ya kundi la mkopo',
  },
  'customerMember#': {
    en: 'customer member #',
    sw: 'nambari ya mteja #',
  },
  capitalizationBalance: {
    en: 'capitalization balance',
    sw: 'mtaji wa usawa',
  },
  capitalizationMonths: {
    en: 'capitalization months',
    sw: 'miezi ya mtaji',
  },
  capitalize: {
    en: 'capitalize',
    sw: 'mtaji',
  },
  checkBookDetails: {
    en: 'cheque book details',
    sw: 'maelezo ya kitabu cha hundi',
  },
  checkLeafDetails: {
    en: 'cheque leaf details',
    sw: 'maelezo ya karatasi ya hundi',
  },
  currencyLookup: {
    en: 'currency lookup',
    sw: 'tafuta sarafu',
  },
  creditBatches: {
    en: 'credit batches',
    sw: 'kundi la mkopo',
  },
  checkTruncationRequestsCatalogue: {
    en: 'cheque truncation requests (catalogue)',
    sw: 'ombi la kukata hundi (orodha)',
  },
  conditionalLendingList: {
    en: 'conditional lending list',
    sw: 'orodha ya mikopo ya masharti',
  },
  customerDocumentRegister: {
    en: 'customer document register',
    sw: 'daftari la hati za mteja',
  },
  customerRegisterQueries: {
    en: 'customer register queries',
    sw: 'uulizaji wa usajili wa mteja',
  },
  creditBatchesOrigination: {
    en: 'credit batch (origination)',
    sw: 'credit batch (origination)',
  },
  cancellation: {
    en: 'cancellation',
    sw: 'ukatishaji',
  },
  'consolidation&NettingOff': {
    en: 'consolidation & netting off',
    sw: 'ujumuishaji na kuondoa',
  },
  checkOffDataProcessing: {
    en: 'check-off data processing',
    sw: 'uchakataji wa data ya kukata',
  },
  checkOffPeriods: {
    en: 'check-off periods',
    sw: 'vipindi vya kukata',
  },
  checkOffProcessing: {
    en: 'check-off processing',
    sw: 'uchakataji wa kukata',
  },
  checkOffDataPeriodClosing: {
    en: 'check-off data period closing',
    sw: 'kufunga kipindi cha data ya kukata',
  },
  checkOffDataCatalogue: {
    en: 'check-off data catalogue',
    sw: 'orodha ya deta ya kukata',
  },
  carryForwardsAdjustment: {
    en: 'carry forwards adjustment',
    sw: 'marekebisho ya kusonga mbele',
  },
  carryForwardsInstallments: {
    en: 'carry forwards installments',
    sw: 'kusonga mbele kwa malipo ya awali',
  },
  creditReferenceBureau: {
    en: 'credit reference bureau',
    sw: 'taasisi ya marejeleo ya mkopo',
  },
  consumerScore: {
    en: 'consumer score',
    sw: 'alama ya mtumiaji',
  },
  creditReport: {
    en: 'credit report',
    sw: 'ripoti ya mkopo',
  },
  creditInformation: {
    en: 'credit information',
    sw: 'taarifa ya mkopo',
  },
  creditReferenceBureauSubmission: {
    en: 'credit reference bureau submission',
    sw: 'kutuma taarifa ya mkopo',
  },
  creditBatchesVerification: {
    en: 'credit batches (verification)',
    sw: 'credit batches (verification)',
  },
  createIdentityVerification: {
    en: 'create identity verification',
    sw: 'unda uthibitisho wa kitambulisho',
  },
  customerInformation: {
    en: 'customer information',
    sw: 'taarifa ya mteja',
  },
  creditScore: {
    en: 'credit score',
    sw: 'alama ya mkopo',
  },
  creditReportQueries: {
    en: 'credit report queries',
    sw: 'uulizaji wa ripoti ya mkopo',
  },
  checkOffDataPeriodLookup: {
    en: 'check-off data period lookup',
    sw: 'tafuta kipindi cha deta',
  },
  customerInfo: {
    en: 'Customer Info',
    sw: 'Taarifa ya Mteja',
  },
  considerCheckOffDeductions: {
    en: 'consider check-off deductions',
    sw: 'zingatia makato ya kukata',
  },
  customerFileNumber: {
    en: 'customer file number',
    sw: 'nambari ya faili ya mteja',
  },
  createLoanDisbursementBatch: {
    en: 'create loan disbursement batch',
    sw: 'unda kundi la malipo ya mkopo',
  },
  'customerSerial#': {
    en: 'customer serial #',
    sw: 'nambari ya mteja #',
  },
  customerIdentityCardNumber: {
    en: 'customer identity card number',
    sw: 'nambari ya kitambulisho cha mteja',
  },
  customerDutyStation: {
    en: 'customer duty station',
    sw: 'kituo cha kazi cha mteja',
  },
  customerEmployer: {
    en: 'customer employer',
    sw: 'mwajiri wa mteja',
  },
  currentBalance: {
    en: 'current balance',
    sw: 'baki lilopo',
  },
  currentAbility: {
    en: 'current ability',
    sw: 'uwezo wa sasa',
  },
  currentAmount: {
    en: 'current amount',
    sw: 'kiasi cha sasa',
  },
  consumerScoreQueries: {
    en: 'consumer score queries',
    sw: 'uulizaji wa alama ya mtumiaji',
  },
  creditInformationQueries: {
    en: 'credit information queries',
    sw: 'uulizaji wa taarifa ya mkopo',
  },
  'creditApplications(last12Months)': {
    en: 'credit applications (last 12 months)',
    sw: 'maombi ya mkopo (miezi 12 iliyopita)',
  },
  'creditApplications(last6Months)': {
    en: 'credit applications (last 6 months)',
    sw: 'maombi ya mkopo (miezi 6 iliyopita)',
  },
  'creditApplications(last3Months)': {
    en: 'credit applications (last 3 months)',
    sw: 'maombi ya mkopo (miezi 3 iliyopita)',
  },
  creditInfo: {
    en: 'credit info',
    sw: 'taarifa ya mkopo',
  },
  customerAccountNumber: {
    en: 'customer account number',
    sw: 'nambari ya akaunti ya mteja',
  },
  currentGuarantees: {
    en: 'current guarantees',
    sw: 'dhamana wa sasa',
  },
  creditScores: {
    en: 'credit scores',
    sw: 'alama za mkopo',
  },
  customerAccounts: {
    en: 'customer accounts',
    sw: 'akaunti za mteja',
  },
  carryForwadsBalance: {
    en: 'carry forwards balance',
    sw: 'hamisha salio',
  },
  capitalizedInterest: {
    en: 'capitalized interest',
    sw: 'mtaji wa riba',
  },
  chargeable: {
    en: 'chargeable',
    sw: 'inayotozwa',
  },
  cancelledBy: {
    en: 'cancelled by',
    sw: 'imefutwa na',
  },
  cancelledDate: {
    en: 'cancelled date',
    sw: 'tarehe ya kughairi',
  },
  cancellationDate: {
    en: 'cancellation date',
    sw: 'tarehe ya kughairiwa',
  },
  cancellationRemarks: {
    en: 'cancellation remarks',
    sw: 'maoni ya kughairiwa',
  },
  customerMembershipClassCustomerType: {
    en: 'customer membership class customer type',
    sw: 'aina ya mteja wa kiwango cha uanachama',
  },
  changeLog: {
    en: 'change log',
    sw: 'mabadiliko ya kumbukumbu',
  },
  columnName: {
    en: 'column name',
    sw: 'jina la safu',
  },
  createStandingOrderOnLoanAudit: {
    en: 'create standing order on loan audit',
    sw: 'unda amri ya kudumu kwenye ukaguzi wa mkopo',
  },
  customerAccountsLookup: {
    en: 'customer accounts lookup',
    sw: 'tafuta akaunti za mteja',
  },
  carryFowardsAdjustment: {
    en: 'carry forwards adjustment',
    sw: 'marekebisho ya kusonga mbele',
  },
  carryFowardsInstallments: {
    en: 'carry forwards installments',
    sw: 'kusonga mbele kwa malipo ya awali',
  },
  'cut-offDate': {
    en: 'cut-off date',
    sw: 'tarehe ya mwisho',
  },
  catalyst: {
    en: 'catalyst',
    sw: 'kichocheo',
  },
  createGroupTextMessage: {
    en: 'create group text message',
    sw: 'unda ujumbe wa maandishi wa kikundi',
  },
  createQuickText: {
    en: 'create quick text',
    sw: 'unda maandishi ya haraka',
  },
  customerMembershipStatus: {
    en: 'customer membership status',
    sw: 'hali ya uanachama wa mteja',
  },
  createEStatementOrder: {
    en: 'create e-statement order',
    sw: 'unda taarifa ya elektroniki',
  },
  carryFowardsStatement: {
    en: 'carry forward statement',
    sw: 'hamisha taarifa',
  },
  cancellationAction: {
    en: 'cancellation action',
    sw: 'hatua ya kughairi',
  },
  createQuickTextMessage: {
    en: 'create quick text message',
    sw: 'unda ujumbe wa maandishi ya haraka',
  },
  closeSavingsAccountOnMembershipTerminationNotification: {
    en: 'close savings account on membership termination notification',
    sw: 'funga akaunti ya akiba kwenye taarifa ya kusitisha uanachama',
  },
  chargeForPrinting: {
    en: 'charge for printing',
    sw: 'gharama ya kuchapisha',
  },
  chequeTypeDescription: {
    en: 'cheque type description',
    sw: 'maelezo ya aina ya hundi',
  },
  'collapse/expand': {
    en: 'collapse/expand',
    sw: 'kunja/kunjua',
  },
  createSurvey: {
    en: 'create survey',
    sw: 'unda uchunguzi',
  },
  closingRemark: {
    en: 'closing remark',
    sw: 'maoni ya kufunga',
  },
  cashTransfer: {
    en: 'cash transfer',
    sw: 'hamisha fedha',
  },
  cashWithdrawalRequests: {
    en: 'cash withdrawal requests',
    sw: 'ombi la kutoa fedha taslimu',
  },
  cashDepositRequests: {
    en: 'cash deposit requests',
    sw: 'ombi la kuweka fedha taslimu',
  },
  cashManagementRequests: {
    en: 'cash management requests',
    sw: 'ombi la usimamizi wa fedha',
  },
  customerReceipts: {
    en: 'customer receipts',
    sw: 'risiti za mteja',
  },
  chequesTransfer: {
    en: 'cheques transfer',
    sw: 'hamisha hundi',
  },
  cashRequisitions: {
    en: 'cash requisitions',
    sw: 'ombi la fedha taslimu',
  },
  cashTransferRequests: {
    en: 'cash transfer requests',
    sw: 'ombi la kuhamisha fedha',
  },
  customerAccountLookup: {
    en: 'customer account lookup',
    sw: 'tafuta akaunti ya mteja',
  },
  cashWithdrawalRequestAuthorization: {
    en: 'cash withdrawal request authorization',
    sw: 'idhini ya ombi la kutoa fedha taslimu',
  },
  cashDepositRequestAuthorization: {
    en: 'cash deposit request authorization',
    sw: 'idhini ya ombi la kuweka fedha taslimu',
  },
  cashPayment: {
    en: 'cash payment',
    sw: 'malipo ya fedha taslimu',
  },
  cashWithdrawal: {
    en: 'cash withdrawal',
    sw: 'kutoa fedha taslimu',
  },
  cashDeposit: {
    en: 'cash deposit',
    sw: 'kuweka fedha taslimu',
  },
  chequeDeposit: {
    en: 'cheque deposit',
    sw: 'kuweka hundi',
  },
  chargesAndLevies: {
    en: 'charges and levies',
    sw: 'gharama na ushuru',
  },
  customerAccountPreview: {
    en: 'customer account preview',
    sw: 'mtazamo wa akaunti ya mteja',
  },
  customerPreview: {
    en: 'customer preview',
    sw: 'mtazamo wa mteja',
  },
  ChequeTypeDescription: {
    en: 'cheque type description',
    sw: 'maelezo ya aina ya hundi',
  },
  CreatedBy: {
    en: 'created by',
    sw: 'imeundwa na',
  },
  CreatedDate: {
    en: 'created date',
    sw: 'imeundwa tarehe',
  },
  cashDepositDetails: {
    en: 'cash deposit changes',
    sw: 'mabadiliko ya kuweka fedha taslimu',
  },
  chequeType: {
    en: 'cheque type',
    sw: 'aina ya hundi',
  },
  chequeNumber: {
    en: 'cheque number',
    sw: 'nambari ya hundi',
  },
  chequeBook: {
    en: 'cheque book',
    sw: 'kitabu cha hundi',
  },
  clearanceCharges: {
    en: 'clearance charges',
    sw: 'gharama ya kuondoa',
  },
  customerAccountLoanCaseDisbursedDate: {
    en: 'customer account loan case disbursed date',
    sw: 'tarehe ya malipo ya mkopo ya akaunti ya mteja',
  },
  chequePayables: {
    en: 'cheque payables',
    sw: 'malipo ya hundi',
  },
  createAccountClosureRequest: {
    en: 'create account closure request',
    sw: 'unda ombi la kufunga akaunti',
  },
  cataloque: {
    en: 'catalogue',
    sw: 'orodha',
  },
  cheques: {
    en: 'cheques',
    sw: 'hundi',
  },
  chequeBanking: {
    en: 'cheque banking',
    sw: 'benki ya hundi',
  },
  clearance: {
    en: 'clearance',
    sw: 'kuondoa',
  },
  chequeClearing: {
    en: 'cheque clearing',
    sw: 'kuondoa hundi',
  },
  cashRequisition: {
    en: 'cash requisition',
    sw: 'ombi la fedha taslimu',
  },
  createSuperSaverPayable: {
    en: 'create super saver payable',
    sw: 'unda malipo ya kuhifadhi kwa wingi',
  },
  claimantName: {
    en: 'claimant name',
    sw: 'jina la mdai',
  },
  claimantNumber: {
    en: 'claimant number',
    sw: 'nambari ya mdai',
  },
  claimantIdentityNumber: {
    en: 'claimant identity number',
    sw: 'nambari ya kitambulisho cha mdai',
  },
  coopBank: {
    en: 'co-op bank',
    sw: 'benki ya co-op',
  },
  cashReceipt: {
    en: 'cash receipt',
    sw: 'risiti ya fedha taslimu',
  },
  chargesLevies: {
    en: 'charges & levies',
    sw: 'gharama na ushuru',
  },
  chequeReceipt: {
    en: 'cheque receipt',
    sw: 'risiti ya hundi',
  },
  chequeTypeLookup: {
    en: 'cheque type lookup',
    sw: 'tafuta aina ya hundi',
  },
  cashPickup: {
    en: 'cash pickup',
    sw: 'kuchukua fedha taslimu',
  },
  checkOffCutOfFDate: {
    en: 'check-off cut-off date',
    sw: 'tarehe ya mwisho',
  },
  customerAccount: {
    en: 'customer account',
    sw: 'akaunti ya mteja',
  },
  customerAccountStatement: {
    en: 'customer account statement',
    sw: 'taarifa ya akaunti ya mteja',
  },
  customerAccountManagement: {
    en: 'customer account management',
    sw: 'usimamizi wa akaunti ya mteja',
  },
  checkBooks: {
    en: 'cheque books',
    sw: 'vitabu vya hundi',
  },
  chequeBooks: {
    en: 'cheque books',
    sw: 'vitabu vya hundi',
  },
  chequeBookIsLocked: {
    en: 'cheque book is locked',
    sw: 'kitabu cha hundi kimefungwa',
  },
  chequeBookDetails: {
    en: 'cheque book details',
    sw: 'maelezo ya kitabu cha hundi',
  },
  createChequeBook: {
    en: 'create cheque book',
    sw: 'unda kitabu cha hundi',
  },
  createStandingOrder: {
    en: 'create standing order',
    sw: 'unda amri ya kudumu',
  },
  CreateOverdraftProtection: {
    en: 'create overdraft protection',
    sw: 'unda ulinzi wa mkopo',
  },
  chargeActivationFees: {
    en: 'charge activation fees',
    sw: 'toza gharama za kuanzisha',
  },
  chequesPendingTransfer: {
    en: 'cheques pending transfer',
    sw: 'hundi zinazosubiri kuhamishwa',
  },
  closingBalance: {
    en: 'closing balance',
    sw: 'baki la kufunga',
  },
  chequeWriting: {
    en: 'cheque writing',
    sw: 'kuandika hundi',
  },
  chequeLeaveNumber: {
    en: 'cheque leaf number',
    sw: 'nambari ya karatasi ya hundi',
  },
  chequeLeafNumber: {
    en: 'cheque leaf number',
    sw: 'nambari ya karatasi ya hundi',
  },
  chequeBookSerialNumber: {
    en: 'cheque book serial number',
    sw: 'nambari ya kitabu cha hundi',
  },
  chequeLeavesLookup: {
    en: 'cheque leaf lookup',
    sw: 'tafuta karatasi za hundi',
  },
  closeElectronicJournal: {
    en: 'close electronic journal',
    sw: 'funga jarida la elektroniki',
  },
  collectionAccountDetail: {
    en: 'collection account detail',
    sw: 'maelezo ya akaunti ya makusanyo',
  },
  chequeImages: {
    en: 'cheque images',
    sw: 'picha za hundi',
  },
  clear: {
    en: 'clear',
    sw: 'ondoa',
  },
  clearExternalCheque: {
    en: 'clear external cheque',
    sw: 'ondoa hundi za nje',
  },
  createSalaryPeriod: {
    en: 'create salary period',
    sw: 'unda kipindi cha mshahara',
  },
  chequePrinting: {
    en: 'cheque printing',
    sw: 'uchapishaji wa hundi',
  },
  channelManagement: {
    en: 'channel management',
    sw: 'usimamizi wa chaneli',
  },
  channelsRegister: {
    en: 'channels register',
    sw: 'sajili chaneli',
  },
  cardType: {
    en: 'card type',
    sw: 'aina ya kadi',
  },
  creditOfficer: {
    en: 'credit officer',
    sw: 'maafisa wa mkopo',
  },
  customerNonIndividualDescription: {
    en: 'customer name',
    sw: 'jina la mteja',
  },
  customerDutyStationDescription: {
    en: 'duty/workstation',
    sw: 'kituo cha kazi',
  },
  createSalaryHeads: {
    en: 'create salary heads',
    sw: 'unda wakuu wa mshahara',
  },
  cardRemarks: {
    en: 'card remarks',
    sw: 'maoni ya kadi',
  },
  cardValueType: {
    en: 'card value type',
    sw: 'aina ya thamani ya kadi',
  },
  cardPercentageValue: {
    en: 'card percentage value',
    sw: 'asilimia ya thamani ya kadi',
  },
  cardFixedValue: {
    en: 'card fixed value',
    sw: 'thamani ya kadi',
  },
  cardValue: {
    en: 'card value',
    sw: 'thamani ya kadi',
  },
  cardNumber: {
    en: 'card number',
    sw: 'nambari ya kadi',
  },
  channelRequestLog: {
    en: 'channel request log',
    sw: 'kumbukumbu ya ombi la chaneli',
  },
  customerMobileLine: {
    en: 'customer mobile line',
    sw: 'nambari ya simu ya mteja',
  },
  callbackPayload: {
    en: 'callback payload',
    sw: 'malipo ya kurudia',
  },
  customize: {
    en: 'customize',
    sw: 'tanafsisha',
  },
  customizeAlternateChannel: {
    en: 'customize alternate channel',
    sw: 'tanafsisha chaneli mbadala',
  },
  createDebitCardRecord: {
    en: 'debit card record',
    sw: 'unda rekodi ya kadi ya deni',
  },
  customerMembershipNumber: {
    en: 'customer membership number',
    sw: 'nambari ya uanachama ya mteja',
  },
  channelActivationRequests: {
    en: 'channel activation requests',
    sw: 'ombi la kuanzisha chaneli',
  },
  customerFilter: {
    en: 'customer filter',
    sw: 'chujio la mteja',
  },
  channelActivationRequestsArchived: {
    en: 'channel activation requests (archived)',
    sw: 'ombi la kuanzisha chaneli (imehifadhiwa)',
  },
  channelServiceEnquiries: {
    en: 'channel service enquiries',
    sw: 'uulizaji wa huduma ya chaneli',
  },
  channelReconciliation: {
    en: 'channel reconciliation',
    sw: 'upatanisho wa chaneli',
  },
  createChannelReconciliationPeriod: {
    en: 'channel reconciliation period',
    sw: 'unda kipindi cha upatanisho wa chaneli',
  },
  channelRequestsLog: {
    en: 'channel requests log',
    sw: 'kumbukumbu ya ombi la chaneli',
  },
  createSupplierTypes: {
    en: 'supplier types',
    sw: 'unda aina ya muuzaji',
  },
  createSupplyCategory: {
    en: 'supply category',
    sw: 'unda aina ya ugavi',
  },
  createInventoryCategory: {
    en: 'inventory category',
    sw: 'unda aina ya hesabu',
  },
  creditPeriod: {
    en: 'credit period',
    sw: 'kipindi cha mkopo',
  },
  createMorguePatient: {
    en: 'morgue patient',
    sw: 'unda mgonjwa wa maiti',
  },
  createSample: {
    en: 'create sample',
    sw: 'unda sampuli',
  },
  currentDepartment: {
    en: 'current department',
    sw: 'idara ya sasa',
  },
  currentCustodian: {
    en: 'current custodian',
    sw: 'mlezi wa sasa',
  },
  currentLocation: {
    en: 'current location',
    sw: 'eneo la sasa',
  },
  customerBranch: {
    en: 'customer branch',
    sw: 'tawi la mteja',
  },
  createVehicle: {
    en: 'create vehicle',
    sw: 'unda gari',
  },
  contribution: {
    en: 'contribution',
    sw: 'mchango',
  },
  createPersonnel: {
    en: 'create personnel',
    sw: 'unda mtu',
  },
  createReceivables: {
    en: 'create receivables',
    sw: 'unda malipo ya kupokea',
  },
  'creditPeriod(days)': {
    en: 'credit period (days)',
    sw: 'kipindi cha mkopo (siku)',
  },
  contains: {
    en: 'contains',
    sw: 'ina',
  },
  createInventoryUnitsOfMeasure: {
    en: 'inventory units of measure',
    sw: 'unda vipimo vya hesabu',
  },
  courtDate: {
    en: 'court date',
    sw: 'tarehe ya mahakama',
  },
  cashBail: {
    en: 'cash bail',
    sw: 'dhamana ya fedha taslimu',
  },
  court: {
    en: 'court',
    sw: 'mahakama',
  },
  createRiskRegister: {
    en: 'risk register',
    sw: 'sajili rejista ya hatari',
  },
  chequeTruncationRequests: {
    en: 'cheque truncation requests',
    sw: 'ombi la kukata hundi',
  },
  customerAccountProductName: {
    en: 'customer account product name',
    sw: 'jina la bidhaa ya akaunti ya mteja',
  },
  customerAccountName: {
    en: 'customer account name',
    sw: 'jina la akaunti ya mteja',
  },
  customerAccountStatus: {
    en: 'customer account status',
    sw: 'hali ya akaunti ya mteja',
  },
  changedField: {
    en: 'changed field',
    sw: 'eneo lililobadilika',
  },
  costDistributionRuleLookup: {
    en: 'cost distribution rule lookup',
    sw: 'tafuta sheria ya ugawaji wa gharama',
  },
  contributionPriority: {
    en: 'contribution priority',
    sw: 'kipaumbele cha mchango',
  },
  customerStatementPasswordDescription: {
    en: 'customer statement password description',
    sw: 'maelezo ya nenosiri la taarifa ya mteja',
  },
}

export default _c
