/** @format */
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../Tables/SimpleTable'
import React, { useEffect, useState } from 'react'
import Loader from '../../../Loaders/Loader'
import { postData } from '../../../../Helpers/webApi'

export const CustomerMetadataPayrollNumbers = ({
  selectedCustomer,
  doFetchPayrollNumbers,
}) => {
  const [payrollNumbers, setPayrollNumbers] = useState([])
  const [loading, setLoading] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        FetchPayrollNumbers: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setPayrollNumbers(data?.payrollNumbers)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchPayrollNumbers) {
      loadData()
    }
  }, [doFetchPayrollNumbers])
  const columns = [
    { label: 'numberType', template: r => <>{r.payrollNumberTypeDescription}</> },
    { label: 'reference', template: r => <>{r.reference}</> },
    { label: 'remarks', template: r => <>{r.remarks}</> },
    { label: 'modifiedBy', template: r => <>{r.modifiedBy}</> },
    { label: 'modifiedDate', template: r => <>{formatDate(r.modifiedDate)}</> },
    { label: 'createdBy', template: r => <>{r.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
  ]
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SimpleTable columns={columns} data={payrollNumbers} />
      )}
    </>
  )
}
