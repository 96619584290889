/** @format */
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { putData } from '../../../../../../../Helpers/webApi'
import { useEffect, useState } from 'react'
import { JournalVoucherAuthOption } from '../../../../../../../Global/enumeration'
import { ViewJournalVoucherAuthorizationModal } from './ViewJournalVoucherAuthorizationModal'
import { AuthorizeJournalVoucherModal } from './AuthorizeJournalVoucherModal'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'

function JournalVoucherAuthorizationModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedJournalVoucher,
}) {
  const [verificationModels, setVerificationModels] = useState({
    journalVoucherAuthOption: Object.keys(JournalVoucherAuthOption)[0],
  })

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const onAuthorizeJournalVoucher = async () => {
    await putData(
      '/JournalVoucher/authorize-journal-voucher',
      {
        JournalVoucher: {
          ...selectedJournalVoucher,
          authorizationRemarks: verificationModels.authorizationRemarks,
        },
        JournalVoucherAuthOption: verificationModels.journalVoucherAuthOption,
      },
      true,
      'Authorize Journal Voucher'
    )
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('journalVoucher')}
            handleAction={onAuthorizeJournalVoucher}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            modalClassName={'modal-xl'}
          >
            {mode === 'view' && (
              <ViewJournalVoucherAuthorizationModal
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
            {mode === 'authorize' && (
              <AuthorizeJournalVoucherModal
                validationErrors={globalValidationErrors}
                clearValidation={clearValidation}
                setVerificationModels={setVerificationModels}
                verificationModels={verificationModels}
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default JournalVoucherAuthorizationModal
