/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import MembershipDetailsCorporation from '../Shared/MembershipDetailsCorporation'
import { parseDate } from '../../../../../../Helpers/formatters'

function CorporationParticulars({
  customer,
  onChange,
  readOnly,
  members,
  noMembers,
}) {
  const [corp, setCorp] = useState(customer)
  const [memberCollection, setMemberCollection] = useState(members)
  useEffect(() => {
    if (!readOnly) {
      onChange(corp, memberCollection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corp, memberCollection])

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('name')}
          </label>
          <input
            type="text"
            className="form-control"
            value={corp?.nonIndividualDescription}
            onChange={e =>
              setCorp({
                ...corp,
                nonIndividualDescription: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registration')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={corp?.nonIndividualRegistrationNumber}
            onChange={e =>
              setCorp({
                ...corp,
                nonIndividualRegistrationNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registrationSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={corp?.nonIndividualRegistrationSerialNumber}
            onChange={e =>
              setCorp({
                ...corp,
                nonIndividualRegistrationSerialNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('dateEstablished')}
          </label>
          <input
            type="date"
            className="form-control"
            value={parseDate(corp?.nonIndividualDateEstablished)}
            onChange={e =>
              setCorp({
                ...corp,
                nonIndividualDateEstablished: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('PIN')} #
          </label>
          <input type="text" className="form-control" disabled={readOnly} />
        </div>
        <div className="col"></div>
      </div>
      {noMembers || (
        <MembershipDetailsCorporation
          readOnly={readOnly}
          customer={customer}
          members={members}
          onChange={m => setMemberCollection(m)}
        />
      )}
    </div>
  )
}

export default CorporationParticulars
