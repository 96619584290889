/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import { CustomerFilter } from '../../../../Global/enumeration'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import DatePicker from '../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function ChannelActivationRequestsArchived() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [groupBy, setGroupBy] = useState({
    label: localize('status'),
    column: 'statusDescription',
  })
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('customerFilter'),
      template: r => <>{r?.customerFilterDescription}</>,
    },
    {
      label: localize('exceptionMessage'),
      template: r => <>{r?.exceptionMessage}</>,
    },
    {
      label: localize('alternateChannelType'),
      template: r => <>{r?.alternateChannelTypeDescription}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('fullName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('billReference'),
      template: r => <>{r?.billReference}</>,
    },
    {
      label: localize('Payload Score (Input Value)'),
      template: r => (
        <>{r?.activationRequestPayloadLevenshteinScoreInputValue}</>
      ),
    },
    {
      label: localize('Payload Score(Test Value)'),
      template: r => (
        <>{r?.activationRequestPayloadLevenshteinScoreTestValue}</>
      ),
    },
    {
      label: localize('IPRS Score(Test Value)'),
      template: r => (
        <>{r?.populationRegisterPayloadLevenshteinScoreTestValue}</>
      ),
    },
    {
      label: localize('MPESA Score (Test Value)'),
      template: r => (
        <>{r?.consumerToBusinessPayloadLevenshteinScoreTestValue}</>
      ),
    },
    {
      label: localize('Payload Score (Percentage)'),
      template: r => (
        <>{r?.activationRequestPayloadLevenshteinScorePercentage}</>
      ),
    },
    {
      label: localize('IPRS Score (Percentage)'),
      template: r => (
        <>{r?.populationRegisterPayloadLevenshteinScorePercentage}</>
      ),
    },
    {
      label: localize('MPESA Score (Percentage)'),
      template: r => (
        <>{r?.consumerToBusinessPayloadLevenshteinScorePercentage}</>
      ),
    },
    {
      label: localize('scoreThreshold'),
      template: r => <>{r?.customerMembershipClassKYCScoreThreshold}</>,
    },
    { label: localize('overallScore'), template: r => <>{r?.overallScore}</> },
    {
      label: localize('payloadOrigin'),
      template: r => <>{r?.activationRequestPayloadOriginDescription}</>,
    },
    {
      label: localize('payloadTransactionCode'),
      template: r => <>{r?.activationRequestPayloadTransactionCode}</>,
    },
    {
      label: localize('payloadIdentityNumber'),
      template: r => <>{r?.activationRequestPayloadIdentityNumber}</>,
    },
    {
      label: localize('payloadFirstName'),
      template: r => <>{r?.activationRequestPayloadFirstName}</>,
    },
    {
      label: localize('payloadMiddleName'),
      template: r => <>{r?.activationRequestPayloadMiddleName}</>,
    },
    {
      label: localize('payloadLastName'),
      template: r => <>{r?.activationRequestPayloadLastName}</>,
    },
    {
      label: localize('payloadYearOfbirth'),
      template: r => <>{r?.activationRequestPayloadYearOfBirth}</>,
    },
    {
      label: localize('IPRS Status'),
      template: r => <>{r?.populationRegisterPayloadStatusDescription}</>,
    },
    {
      label: localize('IPRS ID number'),
      template: r => <>{r?.populationRegisterPayloadIDNumber}</>,
    },
    {
      label: localize('IPRS Serial Number'),
      template: r => <>{r?.populationRegisterPayloadSerialNumber}</>,
    },
    {
      label: localize('IPRS Gender'),
      template: r => <>{r?.populationRegisterPayloadGender}</>,
    },
    {
      label: localize('IPRS First Name'),
      template: r => <>{r?.populationRegisterPayloadFirstName}</>,
    },
    {
      label: localize('IPRS Other Name'),
      template: r => <>{r?.populationRegisterPayloadOtherName}</>,
    },
    {
      label: localize('IPRS SurName'),
      template: r => <>{r?.populationRegisterPayloadSurname}</>,
    },
    {
      label: localize('IPRS Date of Birth'),
      template: r => <>{r?.populationRegisterPayloadDateOfBirth}</>,
    },
    {
      label: localize('IPRS Date of Death'),
      template: r => <>{r?.populationRegisterPayloadDateOfDeath}</>,
    },
    {
      label: localize('IPRS Date of Issue'),
      template: r => <>{r?.populationRegisterPayloadDateOfIssue}</>,
    },
    {
      label: localize('IPRS Date of Expiry'),
      template: r => <>{r?.populationRegisterPayloadDateOfExpiry}</>,
    },
    {
      label: localize('MPESA Status'),
      template: r => <>{r?.consumerToBusinessPayloadStatusDescription}</>,
    },
    {
      label: localize('MPESA MSISDN'),
      template: r => <>{r?.consumerToBusinessPayloadMSISDN}</>,
    },
    {
      label: localize('MPESA Business Short Code'),
      template: r => <>{r?.consumerToBusinessPayloadBusinessShortCode}</>,
    },
    {
      label: localize('MPESA Trans ID'),
      template: r => <>{r?.consumerToBusinessPayloadTransID}</>,
    },
    {
      label: localize('MPESA Trans Amount'),
      template: r => <>{r?.consumerToBusinessPayloadTransAmount}</>,
    },
    {
      label: localize('MPESA Trans Time'),
      template: r => <>{r?.consumerToBusinessPayloadTransTime}</>,
    },
    {
      label: localize('MPESA Bill Ref Number'),
      template: r => <>{r?.consumerToBusinessPayloadBillRefNumber}</>,
    },
    {
      label: localize('MPESA KYC Info'),
      template: r => <>{r?.consumerToBusinessPayloadKYCInfo}</>,
    },
    {
      label: localize('verificationRemarks'),
      template: r => <>{r?.verificationRemarks}</>,
    },
    { label: localize('verifiedBy'), template: r => <>{r?.verifiedBy}</> },
    { label: localize('verifiedDate'), template: r => <>{r?.verifiedDate}</> },
    {
      label: localize('rejectionRemarks'),
      template: r => <>{r?.rejectionRemarks}</>,
    },
    { label: localize('rejectedBy'), template: r => <>{r?.rejectedBy}</> },
    { label: localize('rejectedDate'), template: r => <>{r?.rejectedDate}</> },
    {
      label: localize('isThirdPartyNotified'),
      template: r => <>{r?.isThirdPartyNotified}</>,
    },
    {
      label: localize('thirdPartyNotificationResponse'),
      template: r => <>{r?.thirdPartyNotificationResponse}</>,
    },
    {
      label: localize('bypassIprsKYCValidation'),
      template: r => <>{r?.bypassIprsKYCValidation?.toString()}</>,
    },
    {
      label: localize('bypassMpesaKYCValidation'),
      template: r => <>{r?.bypassMpesaKYCValidation?.toString()}</>,
    },
    {
      label: localize('IPN Enabled'),
      template: r => <>{r?.iPNEnabled?.toString()}</>,
    },
    {
      label: localize('IPN Status'),
      template: r => <>{r?.iPNStatusDescription}</>,
    },
    {
      label: localize('IPN Response'),
      template: r => <>{r?.redactedIPNResponse}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  const groupingColumns = [
    {
      label: localize('status'),
      column: 'statusDescription',
    },
    {
      label: localize('origin'),
      column: 'activationRequestPayloadOriginDescription',
    },
    {
      label: localize('alternateChannelType'),
      column: 'alternateChannelTypeDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
    {
      label: localize('salutation'),
      column: 'customerIndividualSalutationDescription',
    },
    {
      label: localize('gender'),
      column: 'customerIndividualGenderDescription',
    },
    {
      label: localize('membershipClass'),
      column: 'customerMembershipClassDescription',
    },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageCount: 10,
    pageIndex: 0,
    customerFilter: Object.keys(CustomerFilter)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [mode, setMode] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannel/find-alternate-channel-activation-requests-histories-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelActivationRequests'),
          localize('archived'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.customerIdentificationNumber}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2"></div>
                    <div className="col-md-5"></div>
                    <div className="col-md-3 d-flex align-items-center">
                      <label className="col-3">Group By: </label>
                      <select
                        name=""
                        id=""
                        className="form-select text-capitalize"
                        defaultValue={groupBy.column}
                        onChange={e =>
                          setGroupBy({
                            label: groupingColumns.find(
                              f => f.column === e.target.value
                            )?.label,
                            column: e.target.value,
                          })
                        }
                      >
                        {groupingColumns.map(({ label, column }) => (
                          <option className="text-capitalize" value={column}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-3">
                      <div
                        className="d-flex align-items-center me-5"
                        style={{ width: '300px' }}
                      >
                        <label className="col-3 text-capitalize">
                          {localize('pickDate')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                        sort
                      />
                    </div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <GroupedTable
                      groupBy={groupBy}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                      contextMenu={e => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(e)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      itemsCount={tableMeta?.itemsCount}
                      pageSize={reqParams?.pageCount}
                      pageIndex={reqParams?.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChannelActivationRequestsArchived
