/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import {
  IdentityCardType,
  gender,
  identityCardType,
  salutation,
  signatoryRelationship,
} from '../../../../../Global/enumeration'
import AddressV2 from '../../../../../Components/Miscalleneous/AddressV2'
import Tab from '../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { generateGuid } from '../../../../../Helpers/extensions'
import { getData, putData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function Signatories({ customer, readOnly }) {
  useEffect(() => {
    loadSignatories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [enrollment, setEnrollment] = useState({})
  const [enrollmentList, setEnrollmentList] = useState([])

  const columns = [
    { label: localize('name'), template: r => <>{r?.fullName}</> },
    {
      label: localize('gender'),
      template: r => <>{gender[r?.gender]?.name}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{IdentityCardType[r?.identityCardType].name}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.identityCardNumber}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.addressCity}</> },
    { label: localize('email'), template: r => <>{r?.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r?.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('relationship'),
      template: r => (
        <>{signatoryRelationship[r?.relationship]?.name.toString()}</>
      ),
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
  ]

  function stageEnrollment() {
    let staged = { ...enrollment, id: generateGuid() }
    let enr = [...enrollmentList]
    enr?.push(staged)
    setEnrollmentList(enr)
    setEnrollment({})
  }

  function removeStaged(r) {
    let staged = [...enrollmentList]
    // eslint-disable-next-line eqeqeq
    let ind = staged.findIndex(e => e.id == r.id)
    staged.splice(ind, 1)
    setEnrollmentList(staged)
  }

  async function loadSignatories() {
    const { success, data } = await getData(
      '/enrollmentRequest/find-enrollment-request-signatories-by-enrollment-request-id',
      { enrollmentRequestId: customer?.id }
    )
    if (success) {
      setEnrollmentList(data?.result || [])
    }
  }

  async function updateInfo() {
    await putData(
      `/enrollmentRequest/update-enrollment-request-signatories-by-enrollment-request-id?enrollmentRequestId=${customer?.id}`,
      enrollmentList,
      true,
      'Signatories Updated!!'
    )
    await loadSignatories()
  }

  return readOnly ? (
    <>
      <SimpleTable columns={columns} data={enrollmentList} />
    </>
  ) : (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={enrollment?.salutation}
            onChange={e =>
              setEnrollment({ ...enrollment, salutation: e.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={enrollment?.gender}
            onChange={e => setEnrollment({ ...enrollment, gender: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="SignatoryRelationship"
            value={enrollment?.relationship}
            onChange={e =>
              setEnrollment({ ...enrollment, relationship: e.value })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-3 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={enrollment?.firstName}
            onChange={e =>
              setEnrollment({ ...enrollment, firstName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-3 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={enrollment?.lastname}
            onChange={e =>
              setEnrollment({ ...enrollment, lastName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center"></div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-3 text-capitalize">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={enrollment?.identityCardType}
            onChange={e =>
              setEnrollment({ ...enrollment, identityCardType: e.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-3 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            value={enrollment?.identityCardNumber}
            onChange={e =>
              setEnrollment({
                ...enrollment,
                identityCardNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center"></div>
      </div>

      <div
        className="row mt-2 bg-light pt-3 pb-2 mb-2 mx-1"
        style={{ borderRadius: 5 }}
      >
        <AddressV2
          onChange={a => setEnrollment({ ...enrollment, ...a })}
          parent={enrollment}
        />
      </div>
      <div className="bg-light d-flex align-items-center justify-content-end p-2 mb-3">
        <button className="btn btn-info px-5" onClick={stageEnrollment}>
          {localize('add')}
        </button>
      </div>
      <Tab
        tabItems={[
          {
            label: localize('signatoryCollection'),
            item: (
              <SimpleTable
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-danger"
                      onClick={() => removeStaged(r)}
                    >
                      <i className="uil uil-times-circle"></i>
                      <span className="text-capitalize">
                        {localize('remove')}
                      </span>
                    </div>
                  </>
                )}
                columns={columns}
                data={enrollmentList}
              />
            ),
          },
        ]}
      />
      <div className="my-2 bg-light p-2 d-flex align-items-center justify-content-end">
        <button className="btn btn-success px-5" onClick={updateInfo}>
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default Signatories
