/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData, putData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Swal from 'sweetalert2'
import LoanRecoveryExceptionModal from './Partials/LoanRecoveryExceptionModal'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function LoanRecoveryExceptions() {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedLoanRecoveryExceptions, setSelectedLoanRecoveryExceptions] =
    useState([])

  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 1,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/CustomerAccount/find-recovery-exceptions-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setIsBusy(false)
    }
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedLoanRecoveryExceptions.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedLoanRecoveryExceptions([
                ...selectedLoanRecoveryExceptions,
                r,
              ])
            } else {
              setSelectedLoanRecoveryExceptions(
                selectedLoanRecoveryExceptions.filter(s => s.id !== r.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('branch'),
      template: r => <>{r.customerAccountBranchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => (
        <>{r.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('payoutRecoveryMode'),
      template: r => <>{r.payoutRecoveryModeDescription}</>,
    },
    {
      label: localize('payoutRecoveryPercentage'),
      template: r => <>{r.payoutRecoveryPercentage}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerTypeDescription}</>,
    },
    {
      label: localize('customerFullName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} # )`,
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} # )`,
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFileNumber')} # )`,
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const removeLoanRecoveryExceptions = async () => {
    setIsBusy(true)
    if (selectedLoanRecoveryExceptions.length === 0) {
      await Swal.fire(
        'Journal Voucher',
        'Please select at least one Loan Recovery Exception',
        'warning'
      )
      return
    }
    const { success } = await putData(
      '/CustomerAccount/remove-recovery-exceptions',
      {
        RecoveryExceptions: selectedLoanRecoveryExceptions,
      },
      false,
      'Remove Loan Recovery Exception'
    )
    if (success) {
      setIsBusy(false)
      setRefreshAt(!refreshAt)
      setSelectedLoanRecoveryExceptions([])
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('loanRecoveryExceptions'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : error ? (
                <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                  <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                  <div className="fs-5 text-danger">Failed to Load Data</div>
                </div>
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton
                          handleClickAddButton={() => {
                            setMode('add')
                          }}
                        />
                        <button
                          onClick={removeLoanRecoveryExceptions}
                          className="btn btn-success waves-effect waves-light text-capitalize ms-2"
                        >
                          <i className="uil uil-trash me-2"></i>
                          {localize('remove')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="my-2 d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center col"
                      style={{ maxWidth: 'fit-content' }}
                    >
                      <label
                        style={{ minWidth: 'fit-content' }}
                        className="text-capitalize me-1"
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select"
                        value={reqParams.pageSize}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            pageSize: e.target.value,
                          })
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                    <div
                      className="d-flex align-items-center col gap-1"
                      style={{ maxWidth: 'fit-content' }}
                    >
                      <label
                        htmlFor=""
                        style={{ minWidth: 'fit-content' }}
                        className="text-capitalize"
                      >
                        {localize('search')}
                      </label>
                      <div>
                        <EnumsServerCombo
                          value={reqParams.customerFilter}
                          enumsKey="CustomerFilter"
                          onChange={({ value }) =>
                            setReqParams({
                              ...reqParams,
                              customerFilter: value,
                            })
                          }
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        value={reqParams?.filterText}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setRefreshAt(!refreshAt)
                          setReqParams({ ...reqParams, pageIndex: 0 })
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoanRecoveryExceptionModal
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default LoanRecoveryExceptions
