/** @format */

import { PleaseWait } from '../../Loaders/PleaseWait'
import Checkbox from '../../Checkbox/Checkbox'
import React from 'react'

export const CreditTypes = ({
  disabled,
  isBusy,
  creditTypesToSelect,
  onCheckCreditType,
  prefix,
}) => {
  const handleOnCheckCreditType = (creditType, checkValue) => {
    const com = [...creditTypesToSelect].map(comm => {
      if (comm.id === creditType.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckCreditType(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {creditTypesToSelect.map(creditType => (
            <li
              key={creditType.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${creditType.id}`}
                checked={creditType.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleOnCheckCreditType(creditType, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${creditType.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  creditType.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {creditType?.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
