/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import CustomersLookup from '../../Lookup/CustomersLookup'
import localize from '../../../Global/Localize'

function CustomerLookupPartial({ customer, onLookup }) {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    selected && onLookup(selected)
    //eslint-disable-next-line
  }, [selected])

  useEffect(() => {
    setSelected(customer)
  }, [customer])

  return (
    <div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerName')}
          </label>
          <CustomersLookup
            onSelect={c =>
              setSelected({
                ...c,
                corporationId: c.id,
                memberType: 0,
                memberTypeDescription: 'Internal',
                signatory: false,
              })
            }
            displayValue={selected?.fullName || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.membershipClassCustomerTypeDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('membershipStatus')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.membershipStatusDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('membershipWdr.Date')}
          </label>
          <input type="date" className="form-control" disabled />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.branchDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.employerDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('dutyWorkStation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.dutyStationDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.administrativeDivisionDescription || ''}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identificationNumber')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.identificationNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.formattedMemberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.formattedAccountNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.personalFileNumber || ''}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerLookupPartial
