/** @format */
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'

export const AccountMetadataRepaymentSchedule = ({
  loading,
  selectedCustomerAccount,
}) => {

  const [repaymentSchedule, setRepaymentSchedule] = useState([])

  const getRepaymentSchedule = async () => {
    if (selectedCustomerAccount.loanCaseId) {
      const { success, data } = await getData(
        '/LoanCase/find-repayment-schedule-by-loan-case-id',
        {
          loanCaseId: selectedCustomerAccount?.loanCaseId,
        },
        false,
        null
      )
      if (success) {
        setRepaymentSchedule(data.result)
      }
    }
  }

  useEffect(() => {
    if(selectedCustomerAccount){
      getRepaymentSchedule()
    }
  }, [selectedCustomerAccount])


  const columns = [
    {
      label: localize('period'),
      template: r => r.period,
    },
    {
      label: localize('dueDate'),
      template: r => formatDate(r.dueDate),
    },
    {
      label: localize('startingBalance'),
      template: r => formatCurrency(r.startingBalance),
    },
    {
      label: localize('payment'),
      template: r => formatCurrency(r.payment),
    },
    {
      label: localize('interestPayment'),
      template: r => formatCurrency(r.interestPayment),
    },
    {
      label: localize('principalPayment'),
      template: r => formatCurrency(r.principalPayment),
    },
    {
      label: localize('endingBalance'),
      template: r => formatCurrency(r.endingBalance),
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <>
          {selectedCustomerAccount.loanCaseId ? (
            <>
              <SimpleTable selectables columns={columns} data={repaymentSchedule} />
            </>
          ) : (
            <>
              <div className="col-12 d-flex align-items-center justify-content-center">
                <span className="fs-4">
                  Sorry, but the loan account has no matching loan case
                </span>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
