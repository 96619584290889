/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { getData } from '../../../Helpers/webApi'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import Tab from '../../../Components/Tabs/Tab'
import Pager from '../../../Components/Tables/Pager'
import EditBankModal from './EditBankModal'
import { formatDate } from '../../../Helpers/formatters'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from './../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Banks() {
  const columns = [
    {
      label: localize('code'),
      template: r => <>{r?.paddedCode}</>,
      sortBy: 'paddedCode',
    },
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [branches, setBranches] = useState([])
  const [mode, setMode] = useState(null)
  const [selectedBank, setSelectedBank] = useState(null)
  const [onClickedOnce, setOnClickedOnce] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [tabLoading, setTabLoading] = useState(false)

  useEffect(() => {
    loadTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  const loadBankBranches = async r => {
    setTabLoading(true)
    const { success, data } = await getData(
      `/Bank/find-bank-branch-by-bank-id?BankId=${r.id}`,
      {},
      false
    )
    if (success) {
      setTabLoading(false)
      setBranches(data.result)
    }
    setTabLoading(false)
  }

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Bank/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedBank(null)
  }

  const handleClickOnce = async r => {
    await loadBankBranches(r)
    setOnClickedOnce(true)
    tabLoading(true)
  }

  const handleViewAuditLogs = item => {
    setSelectedBank(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('banks')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={selectedBank}
          title={selectedBank?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <AddNewButton handleClickAddButton={handleAdd} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="banksFilterbutton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      canClickOnce
                      onClickOnce={handleClickOnce}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedBank(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  {tabLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="row col-12 mt-2">
                        {onClickedOnce && (
                          <Tab
                            tabItems={[
                              {
                                label: localize('bankBranches'),
                                item: <BankBranches clientData={branches} />,
                              },
                            ]}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(mode === 'add' || mode === 'edit') && (
        <EditBankModal
          mode={mode}
          selectedBank={selectedBank}
          editMode={mode === 'edit'}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}

const BankBranches = ({ clientData, showLoader }) => {
  const columns = [
    {
      label: localize('code'),
      template: r => <>{r.paddedCode}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.addressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r.addressInternet}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{r.createdDate}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        loading={showLoader}
        data={clientData}
        columns={columns}
        selectables
      />
    </>
  )
}

export default Banks
