/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import localize from '../../../../Global/Localize'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import UsersModal from './UsersModal'
import { formatDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import { employeeFilter, userNameProvider } from '../../../../Helpers/constants'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Users() {
  const [tableData, setTableData] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [waitingMetadata, setWaitingMetadata] = useState(false)
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const groupingColumns = [
    {
      label: localize('branch'),
      column: 'employeeBranchDescription',
    },
    {
      label: `${localize('lockedOut')}(${localize('system')})`,
      column: 'employeeIsLocked',
    },
    {
      label: `${localize('bloodGroup')}`,
      column: 'employeeBloodGroupDescription',
    },
    {
      label: localize('gender'),
      column: 'employeeCustomerIndividualGenderDescription',
    },
    {
      label: `${localize('maritalStatus')}`,
      column: 'employeeCustomerIndividualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'employeeCustomerIndividualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'employeeCustomerIndividualSalutationDescription',
    },
    {
      label: localize('department'),
      column: 'employeeDepartmentDescription',
    },
    {
      label: localize('designation'),
      column: 'employeeDesignationDescription',
    },
    {
      label: localize('employeeType'),
      column: 'employeeEmployeeTypeDescription',
    },
    {
      label: localize('employmentStatus'),
      column: 'employeeEmploymentStatusDescription',
    },
    {
      label: `${localize('locked')}(${localize('employee')})`,
      column: 'isLocked',
    },
    {
      label: localize('printingMode'),
      column: 'employeePrintingModeDescription',
    },
  ]
  const [groupBy, setGroupBy] = useState({
    label: localize('branch'),
    column: 'employeeBranchDescription',
  })
  const columns = [
    {
      label: localize('applicationEmail'),
      template: r => <>{r?.employeeCustomerAddressEmail}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.employeeCustomerSerialNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.employeeCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.employeeCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => (
        <>{r?.employeeCustomerIndividualIdentityCardTypeDescription}</>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('designation'),
      template: r => <>{r?.employeeDesignationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.employeeBranchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.employeeDepartmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeEmployeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.employeeBloodGroupDescription}</>,
    },
    {
      label: localize('NSSF'),
      template: r => <>{r?.employeeNationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('NHIF'),
      template: r => <>{r?.employeeNationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r?.employeeCustomerPersonalIdentificationNumber}</>,
    },
    {
      label: localize('hireDate'),
      template: r => <>{formatDate(r?.employeeHireDate)}</>,
    },
    {
      label: localize('releaseDate'),
      template: r => <>{formatDate(r?.employeeReleaseDate)}</>,
    },
    {
      label: localize('inactivityTimeout'),
      template: r => <>{r?.employeeInactivityTimeout}</>,
    },
    {
      label: localize('customSystemInitializationTime'),
      template: r => <>{r?.employeeBranchCompanyTimeDurationStartTime}</>,
    },
    {
      label: localize('customSystemLockTime'),
      template: r => <>{r?.employeeBranchCompanyTimeDurationEndTime}</>,
    },
    {
      label: localize('enforceSystemInitializationLockTime'),
      template: r => (
        <span className="text-capitalize">
          {r?.employeeEnforceSystemInitializationLockTime.toString()}
        </span>
      ),
    },
    {
      label: localize('printingMode'),
      template: r => <>{r?.employeePrintingModeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('onlineNotificationsEnabled'),
      template: r => (
        <span className="text-capitalize">
          {r?.employeeOnlineNotificationsEnabled.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceBiometricsForLogin'),
      template: r => (
        <span className="text-capitalize">
          {r?.employeeEnforceBiometricsForLogin.toString()}
        </span>
      ),
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('applicationCreationDate'),
      template: r => <>{r?.applicationCreationDate}</>,
    },
    {
      label: localize('applicationIsApproved'),
      template: r => <>{r?.applicationIsApproved}</>,
    },
    {
      label: localize('applicationIsLockedOut'),
      template: r => <>{r?.applicationIsLockedOut}</>,
    },
    {
      label: localize('applicationLastActivityDate'),
      template: r => <>{r?.applicationLastActivityDate}</>,
    },
    {
      label: localize('applicationLastLockoutDate'),
      template: r => <>{r?.applicationLastLockoutDate}</>,
    },
    {
      label: localize('applicationLastLoginDate'),
      template: r => <>{r?.applicationLastLoginDate}</>,
    },
    {
      label: localize('applicationLastPasswordChangedDate'),
      template: r => <>{r?.applicationLastPasswordChangedDate}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    employeeFilter: employeeFilter.MEMBER_NUMBER,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  useEffect(() => {
    loadTableData()
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setWaiting(true)
    const { success, data } = await getData(
      '/User/find-user-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setWaiting(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setWaiting(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const fetchUserAuthorizedBranches = async r => {
    setWaitingMetadata(true)
    const { success, data } = await getData(
      '/User/find-user-authorized-branches',
      {
        employeeId: r?.employeeId,
      },
      false
    )
    if (success) {
      setSelectedUser({
        ...r,
        branches: data?.result,
      })
    }
    setWaitingMetadata(false)
  }

  const handleOnEdit = r => {
    fetchUserAuthorizedBranches({
      usernameProvider: userNameProvider.USER_DEFINED,
      ...r,
    })
    setMode('edit')
  }

  const handleOnView = r => {
    fetchUserAuthorizedBranches({
      ...r,
    })
    setMode('view')
  }

  const handleOnAdd = async r => {
    fetchUserAuthorizedBranches({
      usernameProvider: userNameProvider.USER_DEFINED,
    })
    setMode('add')
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('security'),
          localize('users'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {waiting ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="col-3">
                      <div className="col d-flex align-items-center">
                        <label className="col-3 text-capitalize">
                          {localize('groupBy')}
                        </label>
                        <select
                          className="form-select text-capitalize"
                          defaultValue={groupBy.column}
                          onChange={e =>
                            setGroupBy({
                              label: groupingColumns.find(
                                f => f.column === e.target.value
                              )?.label,
                              column: e.target.value,
                            })
                          }
                        >
                          {groupingColumns.map(({ label, column }) => (
                            <option className="text-capitalize" value={column}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 offset-2"
                      onSubmit={e => {
                        e.preventDefault()
                        setRefreshAt(!refreshAt)
                      }}
                    >
                      <label
                        htmlFor="searchTable"
                        className="text-capitalize me-2"
                      >
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="EmployeeFilter"
                        value={reqParams?.employeeFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            employeeFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                            pageIndex: 0,
                          })
                        }
                      ></input>
                      <button
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          setRefreshAt(!refreshAt)
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={tableData}
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              handleOnEdit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnView(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <UsersModal
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        waitingMetadata={waitingMetadata}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default Users
