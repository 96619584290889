/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import SurveyGroups from '../../Partials/SurveyGroups/SurveyGroups'
import { postData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../Components/Loaders/Loader'

const DispatchSurvey = ({ handleClose, mode, survey, fetchSurveys }) => {
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const handleSelectRecipient = group => {
    if (selectedRecipients.includes(group)) {
      setSelectedRecipients(prev => prev.filter(g => g?.id !== group?.id))
    } else {
      setSelectedRecipients(prev => [...prev, group])
    }
  }

  const dispatchSurvey = async () => {
    setIsBusy(true)

    const url = '/Survey/update-customer-survey-entries'

    const { success } = await postData(
      url,
      {
        surveyId: survey?.id,
        surveyGroupIds: selectedRecipients.map(g => g?.id),
      },
      false
    )

    if (success) {
      setIsBusy(false)
      fetchSurveys()
      handleClose()
      setSelectedRecipients([])
      showNotification('Dispatch Survey', 'success')
    } else {
      setIsBusy(false)
    }
  }

  return (
    <ModalTemplate
      modalTitle="dispatchSurvey"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={dispatchSurvey}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-1">
                  {localize('description')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={survey?.description}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-1">
                  {localize('entryQuestion')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={survey?.entryQuestion}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-1">
                  {localize('entryAnswer')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={survey?.entryAnswer}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('endDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={survey?.endDate}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('closingRemark')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={survey?.closingRemark}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={survey?.createdBy}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={formatDate(survey?.createdDate)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('modifiedBy')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={survey?.modifiedBy}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3">
                  {localize('modifiedDate')}
                </label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={formatDate(survey?.modifiedDate)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('recipients'),
                  item: (
                    <SurveyGroups
                      handelSelectGroup={handleSelectRecipient}
                      selectedGroups={selectedRecipients}
                    />
                  ),
                  icon: 'uil uil-edit',
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default DispatchSurvey
