/** @format */

import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import localize from '../../../../../Global/Localize'
import { FingerDigit } from '../../../../../Global/enumeration'

export const CreateFingerPrintBiometricRegister = ({
  createFingerPrintModel,
  setCreateFingerPrintModel,
  defaultPreferredDigit,
  setDefaultPreferredDigit,
}) => {
  return (
    <>
      <CustomerPartial
        onCustomerLookup={r => {
          setCreateFingerPrintModel({
            ...createFingerPrintModel,
            customer: r,
            customerId: r.id,
          })
        }}
        customer={createFingerPrintModel?.customer}
      />
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('preferredFingerDigit')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="FingerDigit"
            value={defaultPreferredDigit}
            onChange={e => {
              setCreateFingerPrintModel({
                ...createFingerPrintModel,
                preferredFingerDigit: e.value,
              })
              setDefaultPreferredDigit(e.value)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('remarks')}</label>
          <input
            defaultValue={createFingerPrintModel?.remarks}
            className="form-control"
            onChange={e => {
              setCreateFingerPrintModel({
                ...createFingerPrintModel,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
