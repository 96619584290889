/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../../../Components/Tabs/Tab'
import BatchEntries from './BatchEntries'
import { batchAuthOptions } from '../../../../../../Global/enumeration'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function ViewWireTransferBatch({
  closeModal,
  verify,
  batch,
  triggerRefresh,
  authorize,
}) {
  const [currentBatch, setCurrentBatch] = useState(batch)
  const [batchAuthOption, setBatchAuthOption] = useState(1) // defaulted to Post
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function auditBatch() {
    const { success } = await putData(
      `/wiretransferbatch/${
        authorize ? 'authorize' : 'audit'
      }-wire-transfer-batch`,
      { wireTransferBatch: currentBatch, batchAuthOption },
      true,
      'Batch Updated'
    )
    if (success) {
      triggerRefresh()
      closeModal()
    }
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>
            {localize(authorize ? 'authorize' : verify ? 'verify' : 'view')}
          </h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('wireTransfer')}
          </span>
          <button type="button" className="minimize">
            <span aria-hidden="true">_</span>
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('batchType')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.typeDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('totalValue')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={formatCurrency(batch?.totalValue, null, null, false)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('wireTransferType')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.wireTransferTypeDescription}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('priority')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.priorityDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.reference}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('chartOfAccountName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.wireTransferTypeChartOfAccountName}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={batch?.createdBy}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={formatDate(batch?.createdDate)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <Checkbox disabled checked={batch?.autowire} />
            <label htmlFor="" className="text-capitalize col-4 ms-2">
              {localize('autoWire')}
            </label>
          </div>
        </div>
        {verify || (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('verifiedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={batch?.auditedBy}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('verificationDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(batch?.auditDate)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('verificationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={batch?.auditRemarks}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('authorizedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={batch?.authorizedBy}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('authorizedDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(batch?.authorizedDate)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('authorizationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={batch?.authorizationRemarks}
                />
              </div>
            </div>
          </>
        )}
        <Tab
          tabItems={[
            {
              label: localize('batchEntries'),
              item: <BatchEntries batch={batch} readOnly />,
            },
          ]}
          preload
        />
      </ModalBody>
      {(verify || authorize) && (
        <ModalFooter className="bg-white">
          <div
            className="d-flex align-items-center gap-2 with-validation"
            validation-message={
              globalValidationErrors[
                `WireTransferBatch.${
                  authorize ? 'AuthorizationRemarks' : 'AuditRemarks'
                }`
              ]?.message
            }
          >
            <label htmlFor="" className="text-capitalize">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ minWidth: 300 }}
              value={
                authorize
                  ? currentBatch?.authorizationRemarks
                  : currentBatch?.auditRemarks
              }
              onChange={e => {
                setGlobalValidationErrors({})

                setCurrentBatch({
                  ...currentBatch,
                  [authorize ? 'authorizationRemarks' : 'auditRemarks']:
                    e.target.value,
                })
              }}
            />
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchAuthOptions"
              value={batchAuthOption}
              onChange={e => setBatchAuthOption(e.value)}
              sort
            />
          </div>
          <button className="btn btn-success px-5 ms-2" onClick={auditBatch}>
            {localize('update')}
          </button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ViewWireTransferBatch
