/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'

import { getData, postData, putData } from '../../../../../Helpers/webApi'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function PrimaryDetails({ loan, mode, onCreateLoan }) {
  const [loanProduct, setLoanProduct] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    mode !== 'add' && getLoanProduct()
  }, [loan?.id])

  async function getLoanProduct() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-by-id',
      { loanProductId: loan?.id }
    )
    if (success) {
      setLoanProduct(data?.loanProduct)
    }
    setIsBusy(false)
  }

  async function postLoanProduct() {
    mode === 'edit' ? editLoanProduct() : createLoanProduct()
  }

  async function editLoanProduct() {
    await putData(
      '/loanProduct/update-loan-product',
      loanProduct,
      true,
      'Loan Product Updated!'
    )
  }

  async function createLoanProduct() {
    const { success, data } = await postData(
      '/loanProduct/create-loan-product',
      loanProduct
    )
    if (success) {
      onCreateLoan(data)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="d-flex align-items-center">
        <label htmlFor="" className="col-1 text-capitalize">
          {localize('name')}
        </label>
        <input
          value={loanProduct?.description}
          onChange={e =>
            setLoanProduct({ ...loanProduct, description: e.target.value })
          }
          type="text"
          className="form-control"
          disabled={mode === 'view'}
        />
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('documentReferenceNumber')}
          </label>
          <input
            type="text"
            value={loanProduct?.documentReferenceNumber}
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                documentReferenceNumber: e.target.value,
              })
            }
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('recoveryPriority')}
          </label>
          <input
            type="number"
            value={loanProduct?.priority}
            onChange={e =>
              setLoanProduct({ ...loanProduct, priority: e.target.value })
            }
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('billReferenceNumber')}
          </label>
          <input
            type="text"
            value={loanProduct?.billReferenceNumber}
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                billReferenceNumber: e.target.value,
              })
            }
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('offsettingPercentageRate')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanInterestOffsettingPercentageRate: e.target.value,
              })
            }
            value={loanProduct?.loanInterestOffsettingPercentageRate}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('term')} ({localize('months')})
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationTermInMonths: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationTermInMonths}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('annualPercentageRate')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanInterestAnnualPercentageRate: e.target.value,
              })
            }
            value={loanProduct?.loanInterestAnnualPercentageRate}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('minPrincipalAmount')}
          </label>
          <MaskNumber
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumAmount: stripNonNumericCharacters(
                  e.target.value
                ),
              })
            }
            disabled={mode === 'view'}
            defaultMaskValue={loanProduct?.loanRegistrationMinimumAmount}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('maxPrincipalAmount')}
          </label>
          <MaskNumber
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMaximumAmount: stripNonNumericCharacters(
                  e.target.value
                ),
              })
            }
            disabled={mode === 'view'}
            defaultMaskValue={loanProduct?.loanRegistrationMaximumAmount}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('minMembershipPeriod')} ({localize('months')})
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumMembershipPeriod: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationMinimumMembershipPeriod}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('gracePeriod')} ({localize('days')})
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationGracePeriod: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationGracePeriod}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('minGuarantors')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumGuarantors: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationMinimumGuarantors}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('maxGuarantees')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMaximumGuarantees: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationMaximumGuarantees}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('interestCalculationMode')} ({localize('months')})
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="InterestCalculationMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanInterestCalculationMode: e.value,
              })
            }
            value={loanProduct?.loanInterestCalculationMode}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('paymentFrequencyPerYear')}
          </label>
          <EnumsServerCombo
            disabled={mode === 'view'}
            customContainerStyle={{ width: '100%' }}
            enumsKey="PaymentFrequencyPerYear"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationPaymentFrequencyPerYear: e.value,
              })
            }
            value={loanProduct?.loanRegistrationPaymentFrequencyPerYear}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('interestChargeMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="InterestChargeMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanInterestChargeMode: e.value,
              })
            }
            value={loanProduct?.loanInterestChargeMode}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('interestRecoveryMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="InterestRecoveryMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanInterestRecoveryMode: e.value,
              })
            }
            value={loanProduct?.loanInterestRecoveryMode}
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('depositsMultiplier')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationInvestmentsMultiplier: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationInvestmentsMultiplier}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('consecutiveIncome')}
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationConsecutiveIncome: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationConsecutiveIncome}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('minChargeableInterestAmount')}
          </label>
          <MaskNumber
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumCInterestAmount:
                  stripNonNumericCharacters(e.target.value),
              })
            }
            defaultMaskValue={
              loanProduct?.loanRegistrationMinimumCInterestAmount
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('maxSelfGuaranteeEligible')} %
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMaximumSelfGuaranteeEligiblePercentage:
                  e.target.value,
              })
            }
            value={
              loanProduct?.loanRegistrationMaximumSelfGuaranteeEligiblePercentage
            }
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('productSection')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ProductSection"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationLoanProductSection: e.value,
              })
            }
            value={loanProduct?.loanRegistrationLoanProductSection}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('productCategory')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="LoanProductCategory"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationLoanProductCategory: e.value,
              })
            }
            value={loanProduct?.loanRegistrationLoanProductCategory}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('payoutRecoveryMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="PayoutRecoveryMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationPayoutRecoveryMode: e.value,
              })
            }
            disabled={mode === 'view'}
            value={loanProduct?.loanRegistrationPayoutRecoveryMode}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('payoutRecovery')} %
          </label>
          <input
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationPayoutRecoveryPercentage: e.target.value,
              })
            }
            value={loanProduct?.loanRegistrationPayoutRecoveryPercentage}
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          {loanProduct?.loanRegistrationLoanProductSection == 0 && (
            <>
              <label htmlFor="" className="text-capitalize col-4">
                {localize('guarantorSecurityMode')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="GuarantorSecurityMode"
                onChange={e =>
                  setLoanProduct({
                    ...loanProduct,
                    loanRegistrationGuarantorSecurityMode: e.value,
                  })
                }
                value={loanProduct?.loanRegistrationGuarantorSecurityMode}
                disabled={mode === 'view'}
              />
            </>
          )}
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('standingOrderTrigger')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="StandingOrderTrigger"
            onChange={e => {
              setLoanProduct({
                ...loanProduct,
                loanRegistrationStandingOrderTrigger: e.value,
              })
            }}
            value={loanProduct?.loanRegistrationStandingOrderTrigger}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('takeHomeType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ChargeType"
            onChange={e => {
              setLoanProduct({ ...loanProduct, takeHomeType: e.value })
            }}
            value={loanProduct?.takeHomeType}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('takeHomeValue')}
          </label>
          {/* eslint-disable-next-line eqeqeq */}
          <MaskNumber
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                [loanProduct?.takeHomeType == 1
                  ? 'takeHomePercentage'
                  : 'takeHomeFixedAmount']: stripNonNumericCharacters(
                  e.target.value
                ),
              })
            }
            defaultMaskValue={
              loanProduct?.takeHomeType == 1
                ? loanProduct?.takeHomePercentage
                : loanProduct?.takeHomeFixedAmount
            }
            disabled={mode === 'view'}
          />
          {loanProduct?.takeHomeType == 1 && (
            <input
              style={{ maxWidth: 40 }}
              type="text"
              disabled
              className="form-control ms-1"
              value="%"
            />
          )}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('incomeAssessmentMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="LoaneeIncomeAssessmentMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationIncomeAssessmentMode: e.value,
              })
            }
            value={loanProduct?.loanRegistrationIncomeAssessmentMode}
            disabled={mode === 'view'}
          />
        </div>
        <div
          className="col-3 d-flex align-items-center"
          disabled={mode === 'view'}
        >
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('deductiblesBasisValue')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="LoanDeductiblesBasisValue"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                deductiblesBasisValue: e.value,
              })
            }
            value={loanProduct?.deductiblesBasisValue}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('aggregateCheckOffRecoveryMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="AggregateCheckOffRecoveryMode"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationAggregateCheckOffRecoveryMode: e.value,
              })
            }
            value={loanProduct?.loanRegistrationAggregateCheckOffRecoveryMode}
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('roundingType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="RoundingType"
            onChange={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationRoundingType: e.value,
              })
            }
            value={loanProduct?.loanRegistrationRoundingType}
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('principalGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.chartOfAccountAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                chartOfAccountAccountName: a.description,
                chartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('interestRecivableGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.interestReceivableChartOfAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                interestReceivableChartOfAccountName: a.description,
                interestReceivableChartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('interestReceivedGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.interestReceivedChartOfAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                interestReceivedChartOfAccountName: a.description,
                interestReceivedChartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('interestChargedGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.interestChargedChartOfAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                interestChargedChartOfAccountName: a.description,
                interestChargedChartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('minDeposits')}
          </label>
          <MaskNumber
            defaultMaskValue={loanProduct?.loanRegistrationMinimumInvestments}
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumInvestments: stripNonNumericCharacters(
                  e.target.value
                ),
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('penaltyReceivableGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.penaltyReceivableChartOfAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                penaltyReceivableChartOfAccountName: a.description,
                penaltyReceivableChartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('penaltyReceivedGLAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={loanProduct?.penaltyReceivedChartOfAccountName}
            onSelect={a =>
              setLoanProduct({
                ...loanProduct,
                penaltyReceivedChartOfAccountName: a.description,
                penaltyReceivedChartOfAccountId: a.id,
              })
            }
            disabled={mode === 'view'}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="col-4">
            {localize('minNetDisbursementAmount')}
          </label>
          <MaskNumber
            onMaskNumber={e =>
              setLoanProduct({
                ...loanProduct,
                loanRegistrationMinimumNetDisbursementAmount:
                  stripNonNumericCharacters(e.target.value),
              })
            }
            defaultMaskValue={
              loanProduct?.loanRegistrationMinimumNetDisbursementAmount
            }
            type="number"
            className="form-control"
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="mt-4 d-flex align-items-center justify-content-end bg-light p-2">
        <button onClick={postLoanProduct} className="btn btn-success px-4">
          {localize(mode === 'edit' ? 'update' : 'create')}
        </button>
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default PrimaryDetails
