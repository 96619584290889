/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _y = {
    yes: {
        en: "yes",
        sw: "ndio"
    },
    yesConfirm: {
        en: "yes, confirm",
        sw: "ndio, thibitisha"
    },
};
export default _y;
