/** @format */

import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import Loader from '../../../../Components/Loaders/Loader'

export const EditIncomeAdjustmentsModal = ({
  selectedIncomeAdjustment,
  setSelectedIncomeAdjustment,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedIncomeAdjustment?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedIncomeAdjustment({
                    ...selectedIncomeAdjustment,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('type')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="IncomeAdjustmentType"
                value={selectedIncomeAdjustment?.type}
                onChange={e => {
                  setSelectedIncomeAdjustment({
                    ...selectedIncomeAdjustment,
                    type: e.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
