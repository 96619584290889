/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
function IdentityScrub({ selectedCreditInformationQuery }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('status')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.statusDescription || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('reportType')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.reportTypeDescription || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('identityType')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.identityTypeDescription || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('identity#')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.identityNumber || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('fullName')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO?.fullName ||
              ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('dateOfBirth')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.dateOfBirth || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO?.gender || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('phone')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO?.phone || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('email')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO?.email || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('employmentName')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.employmentName || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('employmentDate')}
          </label>
          <input
            className="form-control ms-2"
            value={
              formatDate(
                selectedCreditInformationQuery.identityScrubQueryDTO
                  ?.employmentDate,
                true
              ) || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('postalAddress')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.postalAddress || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4 d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityScrubQueryDTO
                ?.physicalAddress || ''
            }
            disabled
          />
        </div>
      </div>
    </>
  )
}
export default IdentityScrub
