/** @format */

import React from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import ChequePayables from '../ChequePayables/ChequePayables'

const ViewExternalCheque = ({ handleClose, mode, cheque, hiddenColumns }) => {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="externalCheque"
      handleClose={handleClose}
      modalClassName="modal-xl"
      hideUpdate
      cancelText="close"
    >
      <>
        <div className="row g-2">
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('drawer')}
            </label>
            <input disabled value={cheque?.drawer} className="form-control" />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('drawerBank')}
            </label>
            <input
              disabled
              value={cheque?.drawerBank}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('drawerBranch')}
            </label>
            <input
              disabled
              value={cheque?.drawerBankBranch}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('chequeType')}
            </label>
            <input
              disabled
              value={cheque?.chequeTypeDescription}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('writeDate')}
            </label>
            <input
              disabled
              value={formatDate(cheque?.writeDate, true)}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('maturityDate')}
            </label>
            <input
              disabled
              value={formatDate(cheque?.maturityDate, true)}
              className="form-control"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('chequeNumber')}
            </label>
            <input disabled value={cheque?.number} className="form-control" />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('amount')}
            </label>
            <input
              disabled
              value={formatCurrency(cheque?.amount, null, null, false)}
              className="form-control text-end"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('remarks')}
            </label>
            <input disabled value={cheque?.remarks} className="form-control" />
          </div>

          <div className="col-md-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customer')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={cheque?.customerAccountCustomerFullName}
            />
          </div>

          <div className="col-md-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('identification#')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={
                cheque?.customerAccountCustomerPersonalIdentificationNumber
              }
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={cheque?.customerAccountCustomerReference2}
            />
          </div>

          <div className="col-md-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={cheque?.customerAccountCustomerReference1}
            />
          </div>
          <div className="col-md-4 offset-md-4 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={cheque?.customerAccountCustomerReference3}
            />
          </div>
        </div>

        <div className="mt-4">
          <Tab
            tabItems={[
              {
                label: localize('chequePayables'),
                item: (
                  <div
                    style={{
                      maxHeight: '50dvh',
                      overflow: 'auto',
                    }}
                  >
                    <ChequePayables
                      customerId={cheque?.customerAccountCustomerId}
                      hiddenColumns={hiddenColumns}
                      externalChequeId={cheque?.id}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </>
    </ModalTemplate>
  )
}

export default ViewExternalCheque
