/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../Helpers/formatters'
import Checkbox from '../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import LoanCaseLookup from '../../../Lookup/LoanCaseLookup'
import LoanDisbursementBatchLookup from '../../../Lookup/LoanDisbursementBatchLookup'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import LoanBatchEntries from '../../BatchEntries/LoanBatchEntries/LoanBatchEntries'

const EditLoanDisbursement = ({
  handleClose,
  mode,
  loan,
  setLoan,
  handleEditLoanDisbursement,
  isBusy,
}) => {
  const [reload, setReload] = useState(false)

  const handleSelectBatch = batch => {
    if (batch?.isLocked)
      return showNotification(
        'Operation not allowed',
        'info',
        'Selected batch is locked'
      )

    setLoan({
      ...loan,
      ...data,
    })
  }

  return (
    <>
      <ModalTemplate
        modalTitle="loanDisbursementBatch"
        cancelText="close"
        modalMode={mode}
        handleClose={handleClose}
        modalClassName="modal-xl"
        handleAction={handleEditLoanDisbursement}
        disableClose={isBusy}
        disableCreate={isBusy}
        footerChildren={
          <div className="d-flex align-items-center">
            <Checkbox
              id="loanDisbursementBatch"
              checked={loan?.requestForVerification}
              disabled={isBusy}
              onCheck={v => setLoan({ ...loan, requestForVerification: v })}
            />
            <label htmlFor="loanDisbursementBatch" className="text-capitalize">
              {localize('requestForVerification')} ?
            </label>
          </div>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row g-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('batchNumber')}
                  </label>
                  <LoanDisbursementBatchLookup
                    displayValue={loan?.paddedBatchNumber}
                    handleSelect={handleSelectBatch}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('batchTotal')}
                  </label>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={formatCurrency(loan?.batchTotal, null, null, false)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('category')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.loanProductCategoryDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('branch')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.branchDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('disbursementMode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.loanDisbursementModeDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.reference}
                    onChange={e => {
                      setLoan({
                        ...loan,
                        reference: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('priority')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="QueuePriority"
                    value={loan?.loanDisbursementBatchPriority}
                    onChange={e => {
                      setLoan({
                        ...loan,
                        loanDisbursementBatchPriority: e.value,
                      })
                    }}
                    sort
                  />
                </div>
              </div>
            </div>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('batchEntries')}
              </div>
              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize">
                      {localize('lookupTargetLoanApplication(s)')}
                    </label>
                    <LoanCaseLookup
                      batch={loan}
                      showUpdate
                      handleReloadData={_value => {
                        setReload(true)
                      }}
                    />
                  </div>
                </div>

                <LoanBatchEntries
                  showSelect
                  loanDisbursementBatchId={loan?.id}
                  setReload={setReload}
                  reload={reload}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditLoanDisbursement
