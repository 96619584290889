/** @format */

import React from 'react'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import { ViewCustomerAccountStatement } from './ViewCustomerAccountStatement'

const ViewCustomerAccountStatementModal = ({
  handleClose,
  customer,
  customerAccount,
}) => {
  return (
    <ModalTemplate
      modalTitle="customerAccountStatement"
      cancelText="close"
      hideUpdate
      modalMode="view"
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      <ViewCustomerAccountStatement
        selectedCustomer={customer}
        selectedCustomerAccount={customerAccount}
      />
    </ModalTemplate>
  )
}

export default ViewCustomerAccountStatementModal
