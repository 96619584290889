/**
 * eslint-disable eqeqeq
 *
 * @format
 */

/** @format */

import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import { GlobalValidationErrors } from '../Validation/ValidationState'
import Swal from 'sweetalert2'
import { CONFIG } from './constants'
import authInfo from './authHelper'

const vars = {
  backendUrl: CONFIG.API_BASE_URL,
}

const api = axios.create({
  baseURL: vars.backendUrl,
})

api.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${authInfo.currentUserProfile?.access_token}`
  config.headers['X-Machine'] = authInfo?.machineInformation
  return config
})

export async function gettingData(endPoint, params, showSwal = true) {
  try {
    let response = await api.get(`${vars.backendUrl}${endPoint}`, { params })
    return { success: true, data: response.data }
  } catch (err) {
    if (err?.response?.data?.type == 0) {
      GlobalValidationErrors.set(err?.response?.data?.reason)
    } else {
      showSwal &&
        Swal.fire('Operation Failed', err.response.data.reason, 'error')
    }
    return { success: false, data: err }
  }
}

export async function getData(
  endPoint,
  params,
  track = true,
  successMessage,
  showSwal
) {
  if (track) {
    const res = await trackPromise(
      handleSuccess(gettingData(endPoint, params, showSwal), successMessage)
    )
    return res
  } else {
    return await gettingData(endPoint, params, showSwal)
  }
}

export async function puttingData(endPoint, body) {
  GlobalValidationErrors.set({})
  try {
    let response = await api.put(`${vars.backendUrl}${endPoint}`, body)
    return { success: true, data: response.data }
  } catch (err) {
    if (err?.response?.data?.type == 0) {
      GlobalValidationErrors.set(err?.response?.data?.reason)
    } else {
      Swal.fire('Operation Failed', err.response.data.reason, 'error')
    }
    return { success: false, data: err.response.data }
  }
}

export async function putData(endPoint, body, track = true, successMessage) {
  if (track) {
    return await trackPromise(
      handleSuccess(puttingData(endPoint, body), successMessage)
    )
  } else {
    return await puttingData(endPoint, body)
  }
}

export async function postingData(endPoint, body) {
  GlobalValidationErrors.set({})
  try {
    let response = await api.post(`${vars.backendUrl}${endPoint}`, body)
    return { success: true, data: response?.data }
  } catch (err) {
    if (err?.response?.data?.type == 0) {
      GlobalValidationErrors.set(err?.response?.data?.reason)
    } else {
      Swal.fire('Operation Failed', err.response?.data?.reason, 'error')
    }
    return { success: false, data: err?.response?.data }
  }
}

export async function postData(endPoint, body, track = true, successMessage) {
  if (track) {
    return await trackPromise(
      handleSuccess(postingData(endPoint, body), successMessage)
    )
  } else {
    return await postingData(endPoint, body)
  }
}

function handleSuccess(promiseFunc, successMessage) {
  return promiseFunc.then(res => {
    if (successMessage && res.success) {
      Swal.fire({
        icon: 'success',
        title: 'Operation Successful',
        text: successMessage,
      })
    }
    return res
  })
}

export default vars
