/** @format */

import localize from '../../../../../Global/Localize'
import { formatCurrency } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

export const PayablesReceivablesEntries = ({ payableReceivableId }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    payableReceivableId: payableReceivableId,
    status: '1',
    pageIndex: 0,
    pageSize: 10,
  })
  const columns = [
    { label: localize('entryType'), template: r => <>{r.typeDescription}</> },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('glAccountName'),
      template: r => <>{r.chartOfAccountAccountName}</>,
    },
    {
      label: localize('glAccountCostDistributionRuleDescription'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('supplierName'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('supplierType'),
      template: r => <>{r.supplierSupplierTypeDescription}</>,
    },
    {
      label: localize('itemRegister'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    { label: localize('value'), template: r => <>{formatCurrency(r.value)}</> },
    {
      label: localize('revenueTax'),
      template: r => <>{r.revenueTaxDescription}</>,
    },
    {
      label: localize('revenueTaxTaxRate'),
      template: r => <>{r.revenueTaxTaxRate}</>,
    },
    { label: localize('taxInclusive'), template: r => <>{r.taxInclusive}</> },
  ]

  const [payableEntries, setPayableEntries] = useState([])

  const fetchJournalVoucherEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/ExpensePayables/find-expense-payables-entries-by-status-and-filter-in-page',
      { ...reqParams, payableReceivableId: id },
      false
    )
    if (success) {
      setPayableEntries(data)
    }
    setIsBusy(false)
  }

  const refetchData = () => {
    if (payableReceivableId) {
      fetchJournalVoucherEntries(payableReceivableId)
    }
  }

  useEffect(() => {
    refetchData()
  }, [payableReceivableId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-3">
              <label className="col-3 me-3 text-capitalize">
                {localize('status')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ExpensePayableEntryStatus"
                value={reqParams.status}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={payableEntries?.pageCollection}
          />
          <div className="d-flex justify-content-end bg-light d-flex p-2">
            <div className="d-flex align-items-center me-5">
              <label className="col-3 text-capitalize me-2">
                {localize('apportioned')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  payableEntries?.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label className="col-3 text-capitalize">
                {localize('shortage')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  payableEntries?.totalShortage,
                  2,
                  2,
                  false
                )}
              />
            </div>

          </div>
        </>
      )}
    </>
  )
}
