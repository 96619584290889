/** @format */
/* eslint-disable */
import React, { useEffect } from 'react'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../Global/Localize'
import { CustomerDocumentType } from '../../../Global/enumeration'
import CustomersLookup from '../../../Components/Lookup/CustomersLookup'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../Helpers/formatters'
import { MaskPercentage } from '../../../Components/InputMasks/MaskPercentage'
import { postData, putData } from '../../../Helpers/webApi'
import { PreviewPdf } from '../../../Components/ReUsables/Extensions/PreviewPdf'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import PdfFileLookup from '../../../Components/Lookup/PdfFileLookup'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'

function DocumentRegisterModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  documentRegisterModel,
  setDocumentRegisterModel,
  selectedDocumentType,
  setSelectedDocumentType,
}) {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [mode])

  async function handleSubmit() {
    const { success } =
      mode === 'add'
        ? await postData(
            '/CustomerDocument/Create',
            documentRegisterModel,
            true,
            'Document Register'
          )
        : await putData(
            '/CustomerDocument/Update',
            documentRegisterModel,
            true,
            'Document Register'
          )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('documentRegister')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('type')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="CustomerDocumentType"
                  value={selectedDocumentType}
                  onChange={e => {
                    setDocumentRegisterModel({
                      ...documentRegisterModel,
                      customerDocumentType: e.value,
                    })
                    setSelectedDocumentType(e.value)
                  }}
                />
              </div>
              <div
                validation-message={validationBag?.CustomerFullName?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('customer')}
                </label>
                {mode === 'add' || mode === 'edit' ? (
                  <CustomersLookup
                    displayValue={documentRegisterModel?.fullName}
                    onSelect={r => {
                      clearValidation('CustomerFullName')
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        fullName: r.fullName,
                        customerId: r.id,
                        customerFullName: r.fullName,
                      })
                    }}
                  />
                ) : (
                  <input
                    className="form-control"
                    disabled
                    defaultValue={documentRegisterModel?.fullName}
                  />
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div
                validation-message={validationBag?.FileTitle?.message}
                className="col-6 d-flex align-items-center with-validation"
              >
                <label className="col-2 text-capitalize">
                  {localize('title')}
                </label>
                <input
                  disabled={mode === 'verify'}
                  className="form-control"
                  defaultValue={documentRegisterModel?.fileTitle}
                  onChange={e => {
                    clearValidation('FileTitle')
                    setDocumentRegisterModel({
                      ...documentRegisterModel,
                      fileTitle: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                validation-message={validationBag?.DocumentReference?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3  text-capitalize">
                  {localize('documentReference')}
                </label>
                <input
                  disabled={mode === 'verify'}
                  className="form-control"
                  defaultValue={documentRegisterModel?.documentReference}
                  onChange={e => {
                    clearValidation('DocumentReference')
                    setDocumentRegisterModel({
                      ...documentRegisterModel,
                      documentReference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              {selectedDocumentType ===
                Object.keys(CustomerDocumentType)[1] && (
                <div className="col-6 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('collateralValue')}
                  </label>
                  <MaskNumber
                    disabled={mode === 'verify'}
                    defaultMaskValue={
                      documentRegisterModel?.collateralValue || ''
                    }
                    onMaskNumber={e => {
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        collateralValue: stripNonNumericCharacters(
                          e.target.value
                        ),
                      })
                    }}
                  />
                </div>
              )}
              {selectedDocumentType ===
                Object.keys(CustomerDocumentType)[1] && (
                <div className="col-6 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('collateralAdvanceRate')}
                  </label>
                  <MaskPercentage
                    disabled={mode === 'verify'}
                    defaultMaskValue={
                      documentRegisterModel?.collateralAdvanceRate || ''
                    }
                    onMaskChange={c => {
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        collateralAdvanceRate: c.target.value,
                      })
                    }}
                  />
                </div>
              )}
            </div>

            {(mode === 'add' || mode === 'edit') && (
              <div className="row mb-3">
                <div className="col-6 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('document')}
                  </label>
                  <PdfFileLookup
                    displayValue={documentRegisterModel?.fileName}
                    acceptedFiles={['application/pdf']}
                    onSelect={r => {
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        document: r.bufferString,
                        fileName: r.fileName,
                        fileExtension: 'pdf',
                      })
                    }}
                  />
                </div>
              </div>
            )}

            <div className="row mb-3">
              {mode === 'verify' && (
                <div className="col-6 d-flex align-items-center">
                  <label className="col-2 me-2 text-capitalize">
                    {localize('preview')}
                  </label>
                  <PreviewPdf
                    base64Pdf={`data:application/pdf;base64,${documentRegisterModel?.document}`}
                    displayValue={documentRegisterModel?.fileName}
                  />
                </div>
              )}
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="text-area-description"
                    className="form-label text-capitalize"
                  >
                    {localize('description')}
                  </label>
                  <textarea
                    disabled={mode === 'verify'}
                    onChange={e => {
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        fileDescription: e.target.value,
                      })
                    }}
                    className="form-control"
                    id="text-area-description"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
            {mode === 'verify' && (
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <label className="text-capitalize col-3">
                    {localize('remarks')}
                  </label>
                  <input className="form-control" />
                </div>
                <div className="d-flex align-items-center me-5">
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="RecordAuthOption"
                    onChange={e => {
                      setDocumentRegisterModel({
                        ...documentRegisterModel,
                        recordStatus: e.value,
                      })
                    }}
                  />
                </div>
              </div>
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default DocumentRegisterModal
