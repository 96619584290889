/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import WireTransferBatchLookup from '../../../../../../Components/Lookup/WireTransferBatchLookup'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { QueuePriority } from '../../../../../../Global/enumeration'
import Tab from '../../../../../../Components/Tabs/Tab'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import CustomerAccountByCustomerIdLookup from '../../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import BatchEntries from './BatchEntries'
import { stripNonNumericCharacters } from '../../../../../../Helpers/formatters'
import { postData, putData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function EditWireTransferBatch({ closeModal, batch }) {
  const [currentBatch, setCurrentBatch] = useState(batch)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [batchEntry, setBatchEntry] = useState(null)
  const [triggerBatchEntriesRefresh, setTriggerBatchEntriesRefresh] =
    useState(false)
  const [selectedBatches, setSelectedBatches] = useState([])

  async function addBatchEntry() {
    const { success } = await postData(
      '/wireTransferBatch/create-wire-transfer-batch-entry',
      { ...batchEntry, wireTransferBatchId: currentBatch?.id },
      true,
      'Batch entry created!'
    )

    if (success) {
      setTriggerBatchEntriesRefresh(!triggerBatchEntriesRefresh)
    }
  }

  async function removeSelectedEntries() {
    const { isConfirmed } = await Swal.fire({
      title: 'Sure',
      text: `Are you sure you want to remove ${selectedBatches.length} entries?`,
      icon: 'question',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    })

    if (isConfirmed) {
      const { success } = await putData(
        '/wireTransferBatch/remove-wire-transfer-batch-entries',
        selectedBatches,
        true,
        'Batch entries removed!'
      )

      if (success) {
        setTriggerBatchEntriesRefresh(!triggerBatchEntriesRefresh)
      }
    }
  }

  async function updateBatchEntry() {
    await putData(
      '/wireTransferBatch/update-wire-transfer-batch',
      currentBatch,
      true,
      'Batch Updated!'
    )
  }

  function toggleCheckAll(all, selected) {
    if (all.length === selected.length) {
      setSelectedBatches([])
    } else {
      setSelectedBatches(all)
    }
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>{localize('edit')}</h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('wireTransfer')}
          </span>
          <button type="button" className="minimize">
            <span aria-hidden="true">_</span>
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row">
          <div className="col d-flex align-items-center mb-2">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('batch')} #
            </label>

            <WireTransferBatchLookup
              displayValue={currentBatch?.paddedBatchNumber}
              disabled={true}
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('batchType')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={currentBatch?.typeDescription}
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('totalValue')}
            </label>
            <MaskNumber
              defaultMaskValue={currentBatch?.totalValue}
              onMaskNumber={e =>
                setCurrentBatch({
                  ...currentBatch,
                  totalValue: stripNonNumericCharacters(e.target.value),
                })
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('wireTransferType')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={currentBatch?.wireTransferTypeDescription}
            />
          </div>

          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('chartOfAccountName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={currentBatch?.chartOfAccountName}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('priority')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="QueuePriority"
              value={currentBatch?.priority}
              onChange={e =>
                setCurrentBatch({ ...currentBatch, priority: e.value })
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-1 text-capitalize">
              {localize('reference')}
            </label>
            <input
              type="text"
              value={currentBatch?.reference}
              onChange={e =>
                setCurrentBatch({ ...currentBatch, reference: e.target.value })
              }
              className="form-control"
            />
          </div>
        </div>
        <Tab
          preload
          tabItems={[
            {
              label: localize('batchDetails'),
              item: (
                <div>
                  <CustomerAccountPartial
                    customer={selectedAccount}
                    onCustomerLookup={c => setSelectedAccount(c)}
                  />
                  <div className="row" style={{ marginTop: -15 }}>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize col-4 me-2">
                        {localize('amount')}
                      </label>
                      <MaskNumber
                        value={batchEntry?.amount}
                        onMaskNumber={e =>
                          setBatchEntry({
                            ...batchEntry,
                            amount: stripNonNumericCharacters(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize col-4 me-2">
                        {localize('payee')} ({localize('bank')}/
                        {localize('branch')})
                      </label>
                      {selectedAccount && (
                        <CustomerAccountByCustomerIdLookup
                          displayValue={batchEntry?.customerBranch}
                          selectedCustomer={{
                            ...selectedAccount,
                            // replaced the id with the customer's Id
                            id: selectedAccount?.customerId,
                          }}
                          onSelect={ac =>
                            setBatchEntry({
                              ...batchEntry,
                              customerAccountId: ac.id,
                              customerAccountDescription: ac.fullAccountNumber,
                              customerBranch: ac.customerBranchDescription,
                            })
                          }
                        />
                      )}
                    </div>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize col-4 me-2">
                        {localize('accountNumber')}
                      </label>
                      <input
                        type="text"
                        value={batchEntry?.customerAccountDescription}
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize col-4 me-2">
                        {localize('reference')}
                      </label>
                      <input
                        value={batchEntry?.reference}
                        onChange={e =>
                          setBatchEntry({
                            ...batchEntry,
                            reference: e.target.value,
                          })
                        }
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-light p-2 d-flex align-items-center justify-content-end mt-2"
                    style={{ borderRadius: 10 }}
                  >
                    <button
                      onClick={addBatchEntry}
                      className="btn btn-success px-5"
                    >
                      {localize('add')}
                    </button>
                    <button className="btn btn-success px-5 ms-2">
                      {localize('import')}
                    </button>
                  </div>
                  <div className="my-2">
                    <Tab
                      preload
                      tabItems={[
                        {
                          label: localize('batchEntries'),
                          item: (
                            <>
                              <BatchEntries
                                batch={currentBatch}
                                refreshEntries={triggerBatchEntriesRefresh}
                                onSelect={b => {
                                  let sb = [...selectedBatches]
                                  const foundIndex = sb.findIndex(
                                    ba => b.id === ba.id
                                  )
                                  if (foundIndex !== -1) {
                                    sb.splice(foundIndex, 1)
                                    setSelectedBatches(sb)
                                  } else {
                                    sb.push(b)
                                    setSelectedBatches(sb)
                                  }
                                }}
                                selectedBatches={selectedBatches}
                                toggleCheckAll={toggleCheckAll}
                              />
                              {selectedBatches.length > 0 && (
                                <div className="mt-2 bg-light d-flex align-items-center justify-content-end p-2">
                                  <button
                                    onClick={removeSelectedEntries}
                                    className="btn btn-danger px-5"
                                  >
                                    {localize('remove')} (
                                    {selectedBatches?.length})
                                  </button>
                                </div>
                              )}
                            </>
                          ),
                        },
                        {
                          label: localize('mismatchedBatchEntries'),
                          item: (
                            <SimpleTable
                              data={[]}
                              columns={[
                                { label: 'column1', template: r => <></> },
                                { label: 'column2', template: r => <></> },
                                { label: 'column3', template: r => <></> },
                                { label: 'column4', template: r => <></> },
                                { label: 'column5', template: r => <></> },
                                { label: 'column6', template: r => <></> },
                                { label: 'column7', template: r => <></> },
                                { label: 'column8', template: r => <></> },
                                { label: 'remarks', template: r => <></> },
                              ]}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </ModalBody>
      <ModalFooter className="bg-white">
        <div className="d-flex align-items-center">
          <Checkbox
            checked={currentBatch?.autowire}
            onCheck={v => setCurrentBatch({ ...currentBatch, autowire: v })}
          />
          <label htmlFor="" className="text-capitalize ms-2">
            {localize('autowire')}
          </label>
        </div>

        <button
          className="btn btn-success px-5 ms-3"
          onClick={updateBatchEntry}
        >
          {localize('update')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default EditWireTransferBatch
