/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import InventoryCategoryLookup from '../../../../../Components/Lookup/InventoryCategoryLookup'
import PackageTypeLookup from '../../../../../Components/Lookup/PackageTypeLookup'
import UnitOfMeasureLookup from '../../../../../Components/Lookup/UnitOfMeasureLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../../Helpers/webApi'
import Levies from './Levies/Levies'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import AssetTypeLookup from '../../../../../Components/Lookup/AssetTypeLookup'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { useEffect } from 'react'

const CreateItemRegister = ({
  mode,
  setMode,
  handleClose,
  registerItem,
  setRegisterItem,
  loadTableData,
  itemType,
  setItemType,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [resetValue, setResetValue] = useState(false)
  const [gbv, setGbv] = GlobalValidationErrors.use()

  useEffect(() => {
    return () => setGbv({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectEntry = (item, value) => {
    if (value) {
      setSelectedItems([...selectedItems, item])
    } else {
      setSelectedItems(selectedItems.filter(x => x.id !== item.id))
    }
  }

  async function handleSubmit() {
    setIsBusy(true)

    const { success, data } = await postData(
      '/ItemRegister/add-new-item-register',
      registerItem,
      false
    )

    if (success) {
      if (selectedItems?.length > 0) {
        const url = '/ItemRegister/update-item-register-levies'

        const { success } = await postData(
          url,
          {
            itemRegisterId: data?.id,
            levies: selectedItems,
          },
          false
        )

        if (success) {
          showNotification('Item Register', 'success')
          setRegisterItem(null)
          loadTableData()
          setMode(null)
          setResetValue(true)
        }
      } else {
        showNotification('Item Register', 'success')
        setRegisterItem(null)
        loadTableData()
        setMode(null)
      }
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)

    const { success } = await putData(
      '/ItemRegister/update-item-register',
      registerItem,
      false
    )
    if (success) {
      showNotification('Register Item', 'success')
      setMode(null)
      setRegisterItem(null)
      loadTableData()
    }
    setIsBusy(false)
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={
          mode === 'add'
            ? localize('addItemRegister')
            : localize('updateItemRegister')
        }
        handleClose={handleClose}
        handleAction={mode === 'add' ? handleSubmit : handleEdit}
        modalClassName="modal-xl"
        footerChildren={
          <>
            <div className="d-flex align-items-center">
              <Checkbox
                id="itemRegisterIsLocked"
                onCheck={e => {
                  setRegisterItem({
                    ...registerItem,
                    isLocked: e,
                  })
                }}
                checked={registerItem?.isLocked}
              />
              <label htmlFor="itemRegisterIsLocked" className="text-capitalize">
                {localize('itemIsLocked') + '?'}
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('itemType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ItemRegisterType"
                  value={registerItem?.itemType}
                  onChange={e => {
                    setRegisterItem({
                      ...registerItem,
                      itemType: e.value,
                      itemTypeDescription: e.label,
                    })
                    setItemType(e.value)
                  }}
                />
              </div>

              {itemType === '2' ? (
                <div
                  className="with-validation d-flex col-6"
                  validation-message={gbv?.InventoryCategoryId?.message}
                >
                  <label className="text-capitalize col-2">
                    {localize('inventoryCategory')}
                  </label>

                  <InventoryCategoryLookup
                    displayValue={registerItem?.indentedInventoryCategoryName}
                    onSelect={e => {
                      clearValidation('InventoryCategoryId')
                      setRegisterItem({
                        ...registerItem,
                        inventoryCategoryId: e.id,
                        inventoryCategoryDescription: e.description,
                        inventoryCategoryChartOfAccountId: e.chartOfAccountId,
                        inventoryCategoryChartOfAccountAccountType:
                          e.chartOfAccountAccountType,
                        inventoryCategoryChartOfAccountAccountCode:
                          e.chartOfAccountAccountCode,
                        inventoryCategoryChartOfAccountAccountName:
                          e.chartOfAccountAccountName,
                        inventoryCategoryChartOfAccountName:
                          e.chartOfAccountName,
                        inventoryCategoryExpenseChartOfAccountId:
                          e.expenseChartOfAccountId,
                        inventoryCategoryExpenseChartOfAccountAccountType:
                          e.expenseChartOfAccountAccountType,
                        inventoryCategoryExpenseChartOfAccountAccountCode:
                          e.expenseChartOfAccountAccountCode,
                        inventoryCategoryExpenseChartOfAccountAccountName:
                          e.expenseChartOfAccountAccountName,
                        inventoryCategoryExpenseChartOfAccountName:
                          e.expenseChartOfAccountName,
                        indentedInventoryCategoryName:
                          e.indentedInventoryCategoryName,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className="d-flex col-6 with-validation"
                  validation-message={gbv?.AssetTypeId?.message}
                >
                  <label className="text-capitalize col-2">
                    {localize('assetType')}
                  </label>
                  <AssetTypeLookup
                    displayValue={registerItem?.assetTypeDescription}
                    onSelect={e => {
                      clearValidation('AssetTypeId')
                      setRegisterItem({
                        ...registerItem,
                        assetTypeId: e.id,
                        assetTypeChartOfAccountId: e.chartOfAccountId,
                        assetTypeDescription: e.description,
                        assetTypeUsefulLife: e.usefulLife,
                        assetTypeDepreciationMethod: e.depreciationMethod,
                        assetTypeDepreciationMethodDescription:
                          e.depreciationMethodDescription,
                      })
                    }}
                  />
                </div>
              )}
            </div>

            <div className=" d-flex row align-items-center mb-2">
              <div
                className="d-flex col-6 with-validation"
                validation-message={gbv?.InventoryUnitOfMeasureId?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('unitOfMeasure')}
                </label>
                <UnitOfMeasureLookup
                  displayValue={registerItem?.inventoryUnitOfMeasureDescription}
                  onSelect={e => {
                    clearValidation('InventoryUnitOfMeasureId')
                    setRegisterItem({
                      ...registerItem,
                      inventoryUnitOfMeasureId: e.id,
                      inventoryUnitOfMeasureDescription: e.description,
                    })
                  }}
                />
              </div>
              <div
                className="d-flex col-6 with-validation"
                validation-message={gbv?.InventoryPackageTypeId?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('package')}
                </label>
                <PackageTypeLookup
                  displayValue={registerItem?.inventoryPackageTypeDescription}
                  onSelect={e => {
                    clearValidation('InventoryPackageTypeId')
                    setRegisterItem({
                      ...registerItem,
                      inventoryPackageTypeId: e.id,
                      inventoryPackageTypeDescription: e.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div
                className="d-flex col-6 with-validation"
                validation-message={gbv?.Code?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('code')}
                </label>
                <input
                  className="form-control"
                  defaultValue={registerItem?.code}
                  onChange={e => {
                    clearValidation('Code')
                    setRegisterItem({ ...registerItem, code: e.target.value })
                  }}
                />
              </div>
              <div
                className="d-flex col-6 with-validation"
                validation-message={gbv?.Description?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('description')}
                </label>
                <input
                  className="form-control"
                  defaultValue={registerItem?.description}
                  onChange={e => {
                    clearValidation('Description')
                    setRegisterItem({
                      ...registerItem,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('estimatedUnitCost')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.estimatedUnitCost || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      estimatedUnitCost: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('reorderPoint')}
                </label>

                <MaskNumber
                  defaultMaskValue={registerItem?.reorderPoint || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      reorderPoint: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div
                className="d-flex col-6 with-validation"
                validation-message={gbv?.MaximumOrder?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('maximumOrder')}
                </label>

                <MaskNumber
                  defaultMaskValue={registerItem?.maximumOrder || 0}
                  onMaskNumber={e => {
                    clearValidation('MaximumOrder')
                    setRegisterItem({
                      ...registerItem,
                      maximumOrder: e.target.value,
                    })
                  }}
                  numeralThousandsGroupStyle="null"
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('unitsPerPack')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.unitsPerPack || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      unitsPerPack: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('palletTI')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.palletTI || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      palletTI: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('palletHI')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.palletHI || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      palletHI: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('unitNetWeight')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.unitNetWeight || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      unitNetWeight: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('unitGrossWeight')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.unitGrossWeight || 0}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      unitGrossWeight: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('leadDays')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.leadDays}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      leadDays: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('economicOrder')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.economicOrder}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      economicOrder: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('monthlyDemand')}
                </label>
                <MaskNumber
                  defaultMaskValue={registerItem?.monthlyDemand}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      monthlyDemand: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex col-6">
                <label className="text-capitalize col-2">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  defaultValue={registerItem?.remarks}
                  onChange={e =>
                    setRegisterItem({
                      ...registerItem,
                      remarks: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center mb-2">
              <div className="d-flex col-6 ">
                <label className="text-capitalize col-2">
                  {localize('sellingPrice')}
                </label>

                <MaskNumber
                  defaultMaskValue={registerItem?.sellingPrice}
                  onMaskNumber={e =>
                    setRegisterItem({
                      ...registerItem,
                      sellingPrice: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: localize('applicableLevies'),
                    item: (
                      <Levies
                        handleSelectEntry={handleSelectEntry}
                        selectedEntries={selectedItems}
                        resetValues={resetValue}
                        setResetValues={setResetValue}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateItemRegister
