/** @format */

/* eslint-disable */
import React from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
function BankBranchLookup({ onSelect, displayValue, bankId, bankDescription }) {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('bankBranchLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{bankDescription}</>
        </>
      }
      url="/lookup/BankBranch/find-bank-branches-by-filter-in-page"
      params={{
        bankId,
        filterText: '',
        pageSize: 10,
        pageIndex: 0,
      }}
      conditionalField="bankId"
      columns={[
        {
          label: localize('code'),
          template: r => <>{r.paddedCode}</>,
          sortBy: 'paddedCode',
        },
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('addressLine1'),
          template: r => <>{r.addressAddressLine1}</>,
          sortBy: 'addressAddressLine1',
        },
        {
          label: localize('addressLine2'),
          template: r => <>{r.addressAddressLine2}</>,
          sortBy: 'addressAddressLine2',
        },
        {
          label: localize('physicalAddress'),
          template: r => <>{r.addressStreet}</>,
          sortBy: 'addressStreet',
        },
        {
          label: localize('postalCode'),
          template: r => <>{r.addressPostalCode}</>,
          sortBy: 'addressPostalCode',
        },
        {
          label: localize('city'),
          template: r => <>{r.addressCity}</>,
          sortBy: 'addressCity',
        },
        {
          label: localize('emailAddress'),
          template: r => <>{r.addressEmail}</>,
          sortBy: 'addressEmail',
        },
        {
          label: localize('landLine'),
          template: r => <>{r.addressLandLine}</>,
          sortBy: 'addressLandLine',
        },
        {
          label: localize('mobileLine'),
          template: r => <>{r.addressMobileLine}</>,
          sortBy: 'addressMobileLine',
        },
        {
          label: localize('internetAddress'),
          template: r => <>{r.addressInternet}</>,
          sortBy: 'addressInternet',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default BankBranchLookup
