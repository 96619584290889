/** @format */

/* eslint-disable */
import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2'
import ImportButton from '../../../../../../Components/Buttons/ImportButton/ImportButton'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import MismatchedImportEntries from '../../../../../../Components/ReUsables/BatchProcedures/DebitBatches/MismatchedImportEntries/MismatchedImportEntries'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { toBase64 } from '../../../../../../Helpers/blobs'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ImportMessageCustomers from './ImportMessageCustomers/ImportMessageCustomers'
import TargetCustomers from './TargetCustomers/TargetCustomers'

const CreateQuickText = ({
  handleClose,
  isBusy,
  mode,
  textAlert,
  setTextAlert,
  customer,
  handleLookupCustomer,
  setCustomer,
  fetchTextAlerts,
}) => {
  const [recipients, setRecipients] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const [showImportModal, setShowImportModal] = useState(false)
  const [mismatchedImports, setMismatchedImports] = useState([])
  const [waitingForUpload, setWaitingForUpload] = useState(false)
  const [waitingCreateQuickText, setWaitingCreateQuickText] = useState(false)
  const ref = useRef(null)

  const handleShowImportModal = () => {
    setShowImportModal(true)
  }

  const handleCloseImportModal = () => {
    setShowImportModal(false)

    if (ref.current?.files?.length > 0) ref.current.value = ''
  }

  const handleAddRecipient = () => {
    if (customer?.id) {
      if (recipients.some(r => r.id === customer.id))
        return Swal.fire({
          title: 'Operation not allowed',
          text: `${customer.fullName} is already a recipient`,
          icon: 'error',
        })

      setRecipients([...recipients, customer])
      setCustomer({
        addressMobileLine: '',
      })
    } else {
      Swal.fire({
        title: 'Operation not allowed',
        text: 'You must select a customer',
        icon: 'error',
      })
    }
  }

  const handleRemove = () => {
    if (selectedRecipients.length === 0)
      return Swal.fire({
        title: 'Operation not allowed',
        text: 'You must select at least one recipient',
        icon: 'error',
      })

    setRecipients(
      recipients.filter(r => {
        return !selectedRecipients.some(sr => sr.id === r.id)
      })
    )

    setSelectedRecipients([])
  }

  const handleSelectRecipient = (r, value) => {
    if (value) {
      setSelectedRecipients([...selectedRecipients, r])
    } else {
      setSelectedRecipients(selectedRecipients.filter(sr => sr.id !== r.id))
    }
  }

  const handleUpload = async () => {
    ref?.current?.click()

    ref.current.onchange = async e => {
      let file = e.target.files[0]
      try {
        let b64 = await toBase64(file)
        let buffer = b64.split(',')[1].toString()

        uploadCustomers(buffer, file.name)
      } catch {
        Swal.fire('Invalid File', 'Error loading file', 'error')
        return
      }
    }
  }

  const uploadCustomers = async (buffer, fileName) => {
    setWaitingForUpload(true)

    const url = '/Base64/media-upload-base-64'

    const { success } = await postData(
      url,
      {
        fileName,
        buffer,
      },
      false
    )

    if (success) {
      const url = '/Base64/file-upload-done'

      const { data } = await getData(
        url,
        {
          fileName,
        },
        false
      )

      if (data?.succeeded) {
        const url = '/MessageGroup/parse-quick-alert-import'

        const { success, data } = await postData(
          url,
          {
            messageCategory: 0,
            fileName,
          },
          false
        )

        if (success) {
          setMismatchedImports(data?.mismatchedCollection)
          setRecipients(data?.matchedCollection4)
          showNotification('createQuickText', 'success')
          handleCloseImportModal()

          ref.current.value = ''
          setWaitingForUpload(false)
        } else {
          showNotification(
            'createQuickText',
            'error',
            'An error occurred while uploading customers'
          )

          ref.current.value = ''

          setWaitingForUpload(false)
        }
      }

      setWaitingForUpload(false)
    }

    setWaitingForUpload(false)
  }

  const handleCreateQuickText = async () => {
    if (recipients.length === 0)
      return Swal.fire({
        title: 'Operation not allowed',
        text: 'You must select at least one recipient',
        icon: 'error',
      })

    if (!textAlert?.message)
      return Swal.fire({
        title: 'Operation not allowed',
        text: 'You must enter a message',
        icon: 'error',
      })

    setWaitingCreateQuickText(true)

    const url = '/TextAlert/add-quick-text-alert'

    const { success } = await postData(
      url,
      {
        textMessageBody: textAlert?.message,
        appendSignature: textAlert?.appendSignature,
        recipients: recipients
          .map(r => `${r.id}:${r.addressMobileLine}`)
          .join(','),
      },
      false
    )

    if (success) {
      setWaitingCreateQuickText(true)

      showNotification('createQuickText', 'success')
      fetchTextAlerts()
      handleClose()
      setTextAlert({
        message: '',
        appendSignature: false,
      })
      setRecipients([])
      setSelectedRecipients([])
      setWaitingCreateQuickText(false)
      setCustomer({
        addressMobileLine: '',
      })
    } else {
      setWaitingCreateQuickText(false)
    }
  }

  return (
    <>
      {showImportModal && (
        <ImportMessageCustomers
          handleCloseImportModal={handleCloseImportModal}
          handleUpload={handleUpload}
          isBusy={waitingForUpload}
        />
      )}

      <input type="file" ref={ref} className="d-none" accept=".csv" />
      <ModalTemplate
        modalTitle="quickText"
        actionText="add"
        cancelText="close"
        modalClassName="modal-xl"
        handleClose={handleClose}
        modalMode={mode}
        handleAction={handleCreateQuickText}
        disableCreate={waitingCreateQuickText}
        footerChildren={
          <>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={textAlert?.appendSignature}
                onCheck={value => {
                  setTextAlert({
                    ...textAlert,
                    appendSignature: value,
                  })
                }}
                id={`appendSignatureQuickText`}
              />
              <label
                className="text-capitalize me-1"
                htmlFor={`appendSignatureQuickText`}
              >
                {localize('appendSignature') + ' ?'}
              </label>
            </div>
          </>
        }
      >
        {isBusy || waitingCreateQuickText ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-1">
                    {localize('message')}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder={localize('message')}
                    value={textAlert?.message}
                    onChange={e =>
                      setTextAlert({
                        ...textAlert,
                        message: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('recipients'),
                      item: (
                        <>
                          <CustomerPartial
                            customer={customer}
                            onCustomerLookup={handleLookupCustomer}
                          />
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <div className="d-flex align-items-center gap-4">
                                <label className="text-capitalize">
                                  {localize('mobileLine')}
                                </label>
                                <input
                                  className="form-control"
                                  value={customer?.addressMobileLine}
                                  onChange={e =>
                                    setCustomer({
                                      ...customer,
                                      addressMobileLine: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-end gap-3">
                            <button
                              className="btn btn-success"
                              onClick={handleAddRecipient}
                            >
                              {localize('add')}
                            </button>
                            <ImportButton
                              handleClickImportButton={handleShowImportModal}
                            />
                          </div>

                          <div className="mt-4">
                            <Tab
                              tabItems={[
                                {
                                  label: localize('targetCustomers'),
                                  item: (
                                    <TargetCustomers
                                      data={recipients}
                                      handleRemove={handleRemove}
                                      selectedRecipients={selectedRecipients}
                                      handleSelectRecipient={
                                        handleSelectRecipient
                                      }
                                    />
                                  ),
                                },
                                {
                                  label: localize('mismatchedImportEntries'),
                                  item: (
                                    <MismatchedImportEntries
                                      mismatchedEntries={mismatchedImports}
                                    />
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateQuickText
