/** @format */

import { PleaseWait } from '../../../../../Components/Loaders/PleaseWait'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const AuthorizedBranches = ({
  selectedUser,
  setSelectedUser,
  isBusy,
  prefix,
  disabled,
}) => {
  const handleCheckBranches = (selectedBranch, checkValue) => {
    const com = [...selectedUser?.branches].map(branch => {
      if (selectedBranch.id === branch.id) {
        return { ...branch, checked: checkValue }
      }
      return branch
    })
    setSelectedUser({ ...selectedUser, branches: com })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedUser?.branches?.map(branch => (
            <li
              key={branch.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${branch.id}`}
                checked={branch.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckBranches(branch, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${branch.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  branch.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {branch.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
