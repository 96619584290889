/** @format */

import React, { useEffect } from 'react'

import './showReport.scss'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../Global/Localize'

function ShowReport({ report, closeReport }) {
  useEffect(() => {
    loadReports()
    // eslint-disable-next-line
  }, [])

  async function loadReports() {
    console.log(encodeURI(report?.reportURL))
  }

  return (
    // <div className="report-viewer" onClick={closeReport}>
    <ModalTemplate
      handleClose={closeReport}
      hideUpdate
      modalClassName="modal-xl"
      modalMode={localize('view')}
      modalTitle={report?.reportName}
    >
      <iframe
        title="Report"
        className="report-content w-100"
        style={{ height: '75vh' }}
        onClick={e => {
          e.stopPropagation()
        }}
        loading="lazy"
        // allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src={
          report?.reportURL
          // 'http://reportserver/Reports/report/VanguardFinancialsReports/ChartOfAccountsListing?rs:Embed=true'
        }
      ></iframe>
    </ModalTemplate>
    // </div>
  )
}

export default ShowReport
