/** @format */

import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'

const ListNextOfKin = ({
  nextOfKins,
  showSelect,
  selectedNextOfKins,
  handleSelectNextOfKin,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <>
          <Checkbox
            checked={selectedNextOfKins?.find(
              kin => kin?.identityCardNumber === r?.identityCardNumber
            )}
            onCheck={value => {
              handleSelectNextOfKin(r, value)
            }}
          />
        </>
      ),
    },
    {
      label: localize('fullName'),
      template: r => <>{r?.fullName || r?.firstName }</>,
    },
    { label: localize('gender'), template: r => <>{r?.genderDescription}</> },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.identityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.identityCardNumber}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('addressStreet'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('addressPostalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('addressCity'), template: r => <>{r?.addressCity}</> },
    {
      label: localize('addressEmail'),
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: localize('addressLandLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('addressMobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('addressInternet'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r?.relationshipDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isPrimary'),
      template: r => <>{r?.isPrimary?.toString()}</>,
    },
  ]
  return (
    <SimpleTable
      data={nextOfKins}
      columns={
        showSelect
          ? columns
          : columns?.filter(c => c?.label !== localize('select') + ' ?')
      }
    />
  )
}

export default ListNextOfKin
