/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import Loader from '../../../../Components/Loaders/Loader'
import { postData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'

function CreateReferral({ close, refresh }) {
  const [employee, setEmployee] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [remarks, setRemarks] = useState('')
  const [isBusy, setIsBusy] = useState(false)

  async function addReferral() {
    setIsBusy(true)
    const { success } = await postData(
      '/employeeReferral/create',
      {
        employeeId: employee?.id,
        employeeCustomerFullName: employee?.customerFullName,
        customerId: customer?.id,
        customerFullName: customer?.fullName,
        remarks,
      },
      false
    )
    if (success) {
      Swal.fire('Operation Successful', 'Employee Referral Created', 'success')
      refresh()
      close()
    }
    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalTitle={localize('employeeReferral')}
      modalMode={'add'}
      handleClose={close}
      modalClassName="modal-xl"
      //   hideFooter={isBusy}
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={addReferral}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center gap-5 mb-4">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('employee')}
              </label>
              <EmployeeLookup
                displayValue={employee?.customerFullName}
                onSelect={e => setEmployee(e)}
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('department')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={employee?.departmentDescription}
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('designation')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={employee?.designationDescription}
              />
            </div>
          </div>
          <div
            className="rounded border border-secondary p-2 pt-3"
            style={{ position: 'relative' }}
          >
            <span
              htmlFor=""
              className="text-capitalize bg-white px-2"
              style={{ position: 'absolute', top: '-1em' }}
            >
              {localize('referralDetails')}
            </span>
            <CustomerPartial
              onCustomerLookup={c => setCustomer(c)}
              customer={customer}
            />
            <div className="row">
              <div className="d-flex align-items-center col-6">
                <label htmlFor="" className="text-capitalize col-2">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateReferral
