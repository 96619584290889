/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { withdrawalNotificationStatus } from '../../Helpers/constants'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import NonBlockingLoader from '../Loaders/NonBlockingLoader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function MembershipWithdrawalNotificationLookup({
  displayValue,
  handleSelect,
  status,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    status: status ?? withdrawalNotificationStatus.REGISTERED,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    customerFilter: 0,
  })

  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('terminationCategory'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('settlementMode'),
      template: r => <>{r?.withdrawalSettlementModeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('individualIdentityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('duty/Workstation'),
      template: r => <>{r?.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: localize('terminationRemarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('maturityDate'),
      template: r => <>{formatDate(r?.maturityDate)}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: localize('auditRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('auditedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('auditedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r?.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r?.settledDate)}</>,
    },
    {
      label: localize('settlementRemarks'),
      template: r => <>{r?.settlementRemarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      '/lookup/WithdrawalNotification/find-withdrawal-notifications-by-status-and-date-range-and-filter-in-page'
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        status: reqParams.status,
      },
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleCancel = () => {
    setModalActive(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <div
          style={{
            maxHeight: '60dvh',
            overflow: 'auto',
          }}
        >
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white text-capitalize">
              {localize('membershipWithdrawalNotificationLookup')}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-4">
              <div className="row mb-2">
                <div className="d-flex align-items-center col-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div className="col-md-2 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    enumsKey="WithdrawalNotificationStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize" htmlFor="searchByDate">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams.startDate,
                        endDate: reqParams.endDate,
                      }}
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <form
                  className="d-flex align-items-center col-md-4 gap-2"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                      pageIndex: 0,
                    })
                  }}
                >
                  <label htmlFor="searchTable" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      maxWidth: 180,
                      minWidth: 180,
                    }}
                    enumsKey="CustomerFilter"
                    value={reqParams?.customerFilter}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        customerFilter: e.value,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control"
                    defaultValue={reqParams.filterText}
                    onChange={e => {
                      setSearch(e.target.value)
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                      })
                    }}
                  />
                  <button
                    id="membershipWithdrawalNotificationLookupFilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      loadData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className=" py-1">
                <SimpleTable
                  columns={columns}
                  data={tableData}
                  onSort={(c, i) => handleSort(c, i)}
                  sortedBy={sortedBy}
                  canClickOnce
                  onClickOnce={r => {
                    setModalActive(false)
                    handleSelect(r)
                  }}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />

              {isBusy && <NonBlockingLoader />}
            </ModalBody>

            <ModalFooter className="bg-white p-2">
              <button
                className="btn btn-danger text-capitalize"
                onClick={handleCancel}
              >
                {localize('cancel')}
              </button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  )
}

export default MembershipWithdrawalNotificationLookup
