/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'

const EmolumentTypeLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      useText
      title={localize('emolumentTypeLookup')}
      url="/lookup/EmolumentTypeController/find-emolument-types-by-filter-in-page"
      params={{ text: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
        },
        {
          label: localize('chartOfAccountName'),
          template: r => <>{r.chartOfAccountName}</>,
        },
        {
          label: localize('chartOfAccountCostDistributionRule'),
          template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
        },
        {
          label: localize('chartOfAccountCurrency'),
          template: r => <>{r.chartOfAccountCurrencyDescription}</>,
        },
        {
          label: localize('amount'),
          template: r => <>{formatCurrency(r.amount)}</>,
        },
        {
          label: localize('transactionOwnership'),
          template: r => <>{r.transactionOwnershipDescription}</>,
        },
        {
          label: localize('autowireMedium'),
          template: r => <>{r.autowireMediumDescription}</>,
        },
        {
          label: localize('autowire'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.autowire?.toString())}
            </span>
          ),
        },
        {
          label: localize('isActive'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.isActive?.toString())}
            </span>
          ),
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked?.toString())}
            </span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default EmolumentTypeLookup
