/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { loanGuarantorAttachmentHistoryEntryStatus } from '../../../../../../Global/enumeration'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import GuarantorAttachmentHistories from '../../GuarantorAttachmentHistories/GuarantorAttachmentHistories'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'

const Relieve = ({
  mode,
  handleClose,
  selectedHistory,
  fetchAttachmentHistories,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedHistories, setSelectedHistories] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [customer, setCustomer] = useState({})
  const [showActionModal, setShowActionModal] = useState(false)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)

  const handleCloseActionModal = () => {
    setShowActionModal(false)
  }

  const handleClickUpdate = () => {
    setShowActionModal(true)
  }

  const handleSelectHistory = (history, value) => {
    if (
      history?.status ===
      Object.keys(loanGuarantorAttachmentHistoryEntryStatus)[1]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This guarantor has already been relieved!',
      })

    if (value) {
      setSelectedHistories([...selectedHistories, history])
    } else {
      setSelectedHistories(selectedHistories.filter(h => h.id !== history.id))
    }
  }

  const handleSelectAllHistories = (collection, value) => {
    if (value) {
      setSelectedHistories(
        collection?.filter(
          h =>
            h?.status !==
            Object.keys(loanGuarantorAttachmentHistoryEntryStatus)[1]
        )
      )
    } else {
      setSelectedHistories([])
    }
  }

  const fetchCustomerAccount = async () => {
    setIsBusy(true)
    const url = '/CustomerAccount/find-customer-account'

    const { data, success } = await getData(
      url,
      {
        customerAccountId: selectedHistory?.sourceCustomerAccountId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomer(data)
    }

    setIsBusy(false)
  }

  const handleUpdate = async () => {
    setIsBusyUpdating(true)
    const url = '/LoanCase/relieve-loan-guarantors'

    if (selectedHistory?.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one guarantor!',
      })

    const { success } = await postData(
      url,
      {
        loanGuarantorAttachmentHistoryEntries: selectedHistories,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setSelectAll(false)
      handleClose()
      fetchAttachmentHistories()
      setSelectedHistories([])
      showNotification('Guarantor Relieving/Reinstating', 'success')
      handleCloseActionModal()
    }

    setIsBusyUpdating(false)
  }

  useEffect(() => {
    fetchCustomerAccount()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHistory])

  return (
    <>
      {showActionModal && (
        <ActionModal
          disableAction={isBusyUpdating}
          disableClose={isBusyUpdating}
          handleAction={handleUpdate}
          handleClose={handleCloseActionModal}
          modalTitle="Guarantor Relieving/Reinstating"
          cancelText="no"
          confirmText="yes"
        >
          {isBusyUpdating ? <Loader /> : <p>Do you want to proceed ?</p>}
        </ActionModal>
      )}

      <ModalTemplate
        modalMode={mode}
        handleClose={handleClose}
        cancelText="close"
        handleAction={handleClickUpdate}
        actionText="update"
        modalTitle={localize('guarantorRelieving/Reinstating')}
        modalClassName="modal-xl"
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <CustomerAccountPartial readOnly customer={customer} />

            <div className="row g-2 mt-1">
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('bookBalance')}
                  </label>
                  <input
                    type="text"
                    className="ms-2 form-control text-end"
                    value={formatCurrency(
                      customer?.bookBalance,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('principalBalance')}
                  </label>
                  <input
                    type="text"
                    className="ms-2 form-control text-end"
                    value={formatCurrency(
                      customer?.principalBalance,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('interestBalance')}
                  </label>
                  <input
                    type="text"
                    className="ms-2 form-control text-end"
                    value={formatCurrency(
                      customer?.interestBalance,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('penaltyBalance')}
                  </label>
                  <input
                    type="text"
                    className="ms-2 form-control text-end"
                    value={formatCurrency(
                      customer?.penaltyBalance,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 mb-2">
              <Tab
                tabItems={[
                  {
                    label: localize('guarantors'),
                    item: (
                      <GuarantorAttachmentHistories
                        canSelect
                        handleSelectAllHistories={handleSelectAllHistories}
                        handleSelectHistory={handleSelectHistory}
                        loanGuarantorAttachmentHistoryId={selectedHistory?.id}
                        selectedHistories={selectedHistories}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default Relieve
