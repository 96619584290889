/** @format */
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../Tables/SimpleTable'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import Loader from '../../../Loaders/Loader'

export const CustomerMetadataSavingsAccount = ({
  selectedCustomer,
  doFetchSavingsAccounts,
}) => {
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeProductCodeDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{r.bookBalance}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{r.availableBalance}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('isDefault'),
      template: r => (
        <span className="text-capitalize">
          {r?.customerAccountTypeTargetProductIsDefault?.toString()}
        </span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchSavingsAccount: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setCustomerSavingsAccounts(data?.savingsAccounts)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchSavingsAccounts) {
      loadData()
    }
  }, [doFetchSavingsAccounts])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SimpleTable data={customerSavingsAccounts} columns={columns} />
      )}
    </>
  )
}
