/** @format */

import moment from 'moment'
/* eslint-disable */
import React from 'react'

export function formatCurrency(
  numberr,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  component = true
) {
  let number = Number(numberr)
  let negative = false
  if (number < 0) {
    negative = true
  }
  if (number || number === 0) {
    let numb = Math.abs(number).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    let str = negative ? `(${numb})` : numb
    return component ? <div style={{ textAlign: 'right' }}>{str}</div> : str
  } else {
    return '-'
  }
}

export function formatAsPercent(num) {
  if (!num) return '-'

  return new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100)
}

export function formatDate(date, noTime, stringFormat = 'DD-MM-YYYY') {
  if (date) {
    const dt = noTime
      ? moment(date).format(stringFormat)
      : moment(date).format(`${stringFormat} hh:mm:ss A`)
    return dt.toLowerCase() === 'invalid date' ? '--' : dt
  }
  return '--'
}

export function getTwoWeeksFromDate(date = new Date()) {
  return moment(date).subtract(2, 'weeks').format('YYYY-MM-DD')
}

export function getMonthsFromDate(date = new Date()) {
  return moment(date).add(2, 'months').format('YYYY-MM-DD')
}

export function getTodaysDate() {
  return moment().format('YYYY-MM-DD')
}

export function getLastDateOfMonth(date = new Date()) {
  return moment(date).endOf('month').format('YYYY-MM-DD')
}

export function stripNonNumericCharacters(value) {
  return value.replace(/[^0-9.]/g, '')
}

export default function rightAlignFragment(number) {
  return (
    <div style={{ textAlign: 'end', width: '100%', padding: 0 }}>{number}</div>
  )
}

export function parseDate(date) {
  let dt = moment(date).format('YYYY-MM-DD')
  return dt
}

export function parseTime(date) {
  return new Date(date).toLocaleTimeString('en-US', { hour12: false })
}

export const getCurrentMonth = () => {
  return moment().format('MMMM')
}

export const getTomorrowsDate = () => {
  return moment().add(1, 'days').format('YYYY-MM-DD')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function addDays(date, days) {
  return moment(date).add(days, 'days').format('YYYY-MM-DD')
}

export const getOneYearFromNow = () => {
  return moment().add(1, 'years').format('YYYY-MM-DD')
}

export const getCurrentTime = () => {
  return moment().format('HH:mm')
}

export const getSevenDaysAgoFromToday = () => {
  return moment().subtract(7, 'days').format('YYYY-MM-DD')
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
