/** @format */

import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import React, { useState } from 'react'
import {
  ApportionedComponent,
  ApportionTo,
} from '../../../../../Helpers/constants'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import { formatCurrency } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import swal from 'sweetalert2'
import FormulaInput from '../../../../../Components/Formula/FormulaInput'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { CustomerAccountsByMicroCreditGroupIdLookup } from '../../../../../Components/Lookup/CustomerAccountsByMicroCreditIdLookup'

export const GroupApportionmentSection = ({
  transactionModel,
  onAddApportionMent,
  onRemoveApportionment,
  setTransactionModel,
  resetEntries,
}) => {
  const [globalValidation, setGlobalValidation] = GlobalValidationErrors.use()
  const [entriesMetadata, setEntriesMetadata] = useState(null)
  const [loading, setLoading] = useState(false)
  const [prevResetEntries, setPrevResetEntries] = useState(resetEntries)

  const clearValidation = column => {
    setGlobalValidation(g => {
      delete g[column]
      return g
    })
  }

  const [apportionedEntries, setApportionedEntries] = useState([])

  const [apportionModel, setApportionModel] = useState({
    apportionTo: ApportionTo.CUSTOMER_ACCOUNT,
    apportionedComponent: ApportionedComponent.AGGREGATE,
  })

  const resetTransactionModel = () => {
    setTransactionModel({
      ...transactionModel,
      selectedMicroCreditCustomerAccount: null,
    })
  }

  const resetApportionModel = e => {
    setApportionModel({
      primaryDescription: '',
      secondaryDescription: '',
      reference: '',
      apportionTo: e || ApportionTo.CUSTOMER_ACCOUNT,
      apportionedComponent: ApportionedComponent.AGGREGATE,
    })
  }

  if (resetEntries !== prevResetEntries) {
    setPrevResetEntries(resetEntries)
    setTransactionModel({
      apportionTo: ApportionTo.CUSTOMER_ACCOUNT,
      apportionedComponent: ApportionedComponent.AGGREGATE,
    })
    setApportionedEntries([])
    setEntriesMetadata(null)
    resetApportionModel(ApportionTo.CUSTOMER_ACCOUNT)
  }

  const handleOnAddApportionmentEntry = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/MicroCreditGroup/compute-micro-credit-group-apportionments',
      {
        ApportionmentWrappers: apportionedEntries || [],
        ApportionmentWrapper: apportionModel || {},
        Amount: transactionModel?.amount || 0,
        apportionedComponent:
          apportionModel.apportionedComponent || ApportionedComponent.AGGREGATE,
        ApportionTo:
          apportionModel?.apportionTo || ApportionTo.CUSTOMER_ACCOUNT,
        SelectedMicroCreditCustomerAccount:
          transactionModel?.selectedMicroCreditCustomerAccount || {},
        SelectedGeneralLedgerAccount:
          apportionModel?.selectedGeneralLedgerAccount || {},
      },
      false,
      null,
    )
    if (success) {
      setApportionedEntries([...apportionedEntries, data.apportionedWrapper])
      onAddApportionMent(data.apportionedWrapper)
      setEntriesMetadata({
        apportioned: data.apportioned,
        shortage: data.shortage,
      })
      resetTransactionModel()
      resetApportionModel(apportionModel?.apportionTo)
    }
    setLoading(false)
  }

  const onRemove = async r => {
    let newApportionments = [...apportionedEntries].filter(x => x.id !== r.id)
    setApportionedEntries(newApportionments)
    onRemoveApportionment(r)

    const { success, data } = await postData(
      '/MicroCreditGroup/remove-micro-credit-group-apportionment',
      {
        ApportionmentWrappers: newApportionments,
        TotalValue: transactionModel.amount,
      },
      false,
      null,
    )
    if (success) {
      setEntriesMetadata({
        apportioned: data.apportioned,
        shortage: data.shortage,
      })
    }
  }

  return (
    <>
      {apportionModel?.apportionTo === ApportionTo.CUSTOMER_ACCOUNT ? (
        <>
          <ApportionToCustomerAccount
            globalValidation={globalValidation}
            clearValidation={clearValidation}
            apportionModel={apportionModel}
            onChangeApportionTo={e => {
              resetApportionModel(e)
              resetTransactionModel()
            }}
            setApportionModel={setApportionModel}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
          />
        </>
      ) : (
        <ApportionToGeneralLedgerAccount
          globalValidation={globalValidation}
          clearValidation={clearValidation}
          apportionModel={apportionModel}
          onChangeApportionTo={e => {
            resetApportionModel(e)
            resetTransactionModel()
          }}
          setApportionModel={setApportionModel}
          transactionModel={transactionModel}
          setTransactionModel={setTransactionModel}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="d-flex align-item-center justify-content-end rounded py-3 px-2 bg-light mt-2 mb-2">
          <button
            className="btn btn-success"
            onClick={handleOnAddApportionmentEntry}
          >
            {localize('add')}
          </button>
        </div>
      )}

      <div className="row mb-3">
        <div className="col-md-12">
          <GroupApportionmentEntries
            apportioned={entriesMetadata?.apportioned}
            shortage={entriesMetadata?.shortage}
            entries={apportionedEntries}
            handleOnRemove={onRemove}
            setApportionedEntries={setApportionedEntries}
          />
        </div>
      </div>
    </>
  )
}

const GroupApportionmentEntries = ({
  entries,
  apportioned,
  shortage,
  handleOnRemove,
}) => {
  const columns = [
    {
      label: localize('apportionTo'),
      template: r => <>{r.apportionToDescription}</>,
    },
    {
      label: localize('apportionedComponent'),
      template: r => <>{r.apportionedComponentDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('apportionedAmount'),
      template: r => <>{formatCurrency(r.apportionedAmount)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
  ]

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('apportionments')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <SimpleTable
            columns={columns}
            data={entries}
            contextMenu={e => (
              <>
                <div
                  onClick={() => {
                    handleOnRemove(e)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-trash"></i>
                  {localize('remove')}
                </div>
              </>
            )}
          />
        </div>
        <div className="d-flex justify-content-end bg-light d-flex p-2">
          <div className="d-flex align-items-center me-5">
            <label className="col-3 text-capitalize me-2">
              {localize('apportioned')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(apportioned, 2, 2, false)}
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <label className="col-3 text-capitalize">
              {localize('shortage')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(shortage, 2, 2, false)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const ApportionToGeneralLedgerAccount = ({
  transactionModel,
  setTransactionModel,
  apportionModel,
  setApportionModel,
  globalValidation,
  clearValidation,
  onChangeApportionTo,
}) => {
  const handleOnSelectGeneralLedgerAccount = r => {
    if (r.isLocked) {
      swal.fire('Customer Receipts', 'The Selected Account is Locked', 'error')
      return
    }
    if (r.isControlAccount) {
      swal.fire(
        'Customer Receipts',
        'The Selected Account is a control account',
        'error',
      )
      return
    }
    if (r.isReconciliationAccount) {
      swal.fire(
        'Customer Receipts',
        'The Selected Account is a reconciliation account',
        'error',
      )
      return
    }
    if (r.postAutomaticallyOnly) {
      swal.fire(
        'Customer Receipts',
        'The Selected Account is post automatically only',
        'error',
      )
      return
    }

    clearValidation('SelectedGeneralLedgerAccount')
    setApportionModel({
      ...apportionModel,
      selectedGeneralLedgerAccount: { ...r },
      primaryDescription: r?.description,
      secondaryDescription: 'MicroCredit Apportionment',
      reference: r?.typeDescription,
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('apportionTo')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ApportionTo"
            value={apportionModel?.apportionTo}
            onChange={e => {
              setApportionModel({
                ...apportionModel,
                apportionTo: e.value,
              })
              onChangeApportionTo(e.value)
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={
            globalValidation?.SelectedGeneralLedgerAccount?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('glAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={apportionModel?.selectedGeneralLedgerAccount?.name}
            onSelect={handleOnSelectGeneralLedgerAccount}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountType')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              apportionModel?.selectedGeneralLedgerAccount?.typeDescription
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costDistributionRule')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              apportionModel?.selectedGeneralLedgerAccount
                ?.costDistributionRuleDescription
            }
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.PrimaryDescription']
              ?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('primaryDescription')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.primaryDescription}
            onChange={e => {
              clearValidation('ApportionmentWrapper.PrimaryDescription')
              setApportionModel({
                ...apportionModel,
                primaryDescription: e.target.value,
              })
            }}
          />
        </div>
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.SecondaryDescription']
              ?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('secondaryDescription')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.secondaryDescription}
            onChange={e => {
              clearValidation('ApportionmentWrapper.SecondaryDescription')
              setApportionModel({
                ...apportionModel,
                secondaryDescription: e.target.value,
              })
            }}
          />
        </div>
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.Reference']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.reference}
            onChange={e => {
              clearValidation('ApportionmentWrapper.Reference')
              setApportionModel({
                ...apportionModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.ApportionedAmount']
              ?.message
          }
          className="col-4 d-flex align-items-center"
        >
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            defaultMaskValue={apportionModel?.apportionedAmount || ''}
            onMaskNumber={e => {
              clearValidation('ApportionmentWrapper.ApportionedAmount')
              setApportionModel({
                ...apportionModel,
                apportionedAmount: e.target.value,
              })
            }}
          />
        </div>

        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('component')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ApportionedComponent"
            value={transactionModel?.apportionTo}
            onChange={e => {
              setTransactionModel(e.value)
            }}
          />
        </div>
      </div>



    </>
  )
}

const ApportionToCustomerAccount = ({
  transactionModel,
  setTransactionModel,
  apportionModel,
  setApportionModel,
  globalValidation,
  clearValidation,
  onChangeApportionTo,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('apportionTo')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ApportionTo"
            value={apportionModel?.apportionTo}
            onChange={e => {
              setApportionModel({
                ...apportionModel,
                apportionTo: e.value,
              })
              onChangeApportionTo(e.value)
            }}
          />
        </div>
        {/*<div*/}
        {/*  className="col d-flex align-items-center with-validation"*/}
        {/*  validation-message={globalValidation?.Amount?.message}*/}
        {/*>*/}
        {/*  <label className="col-2 text-capitalize">{localize('amount')}</label>*/}

        {/*  <FormulaInput*/}
        {/*    value={transactionModel?.amount || ''}*/}
        {/*    useBlur*/}
        {/*    onChange={e => {*/}
        {/*      clearValidation('Amount')*/}
        {/*      setTransactionModel({*/}
        {/*        ...transactionModel,*/}
        {/*        amount: e,*/}
        {/*      })*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('fullAccountNumber')}
          </label>
          <CustomerAccountsByMicroCreditGroupIdLookup
            onSelect={v => {
              setTransactionModel({
                ...transactionModel,
                selectedMicroCreditCustomerAccount: v,
              })
              setApportionModel({
                ...apportionModel,
                primaryDescription: 'Current Account',
                secondaryDescription: 'Micro-Credit Apportionment',
                reference: v?.customerReference1,
              })
              clearValidation('ApportionmentWrapper.PrimaryDescription')
              clearValidation('ApportionmentWrapper.SecondaryDescription')
            }}
            displayValue={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.fullAccountNumber || ''
            }
            selectedCustomerAccount={transactionModel?.selectedCustomerAccount}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountStatus')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.statusDescription || ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('bookBalance')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(
              transactionModel?.selectedMicroCreditCustomerAccount?.bookBalance,
              2,
              2,
              false,
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('principalBalance')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.principalBalance,
              2,
              2,
              false,
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.customerIdentificationNumber || ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.formattedCustomerMemberNumber || ''
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountRemarks')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount?.remarks ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('productName')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.customerAccountTypeTargetProductDescription || ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('interestBalance')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.interestBalance,
              2,
              2,
              false,
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.formattedCustomerAccountNumber
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.personalFileNumber
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('standingOrderPrincipal')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(
              transactionModel?.selectedMicroCreditCustomerAccount
                ?.standingOrderPrincipal,
              2,
              2,
              false,
            )}
          />
        </div>
      </div>
      {/*<div className="row mb-3">*/}
      {/*  <div className="col d-flex align-items-center">*/}
      {/*    <label className="col-1 text-capitalize">*/}
      {/*      {localize('standingOrderInterest')}*/}
      {/*    </label>*/}
      {/*    <input*/}
      {/*      className="form-control"*/}
      {/*      disabled*/}
      {/*      value={formatCurrency(*/}
      {/*        transactionModel?.selectedCustomerAccount?.standingOrderInterest,*/}
      {/*        2,*/}
      {/*        2,*/}
      {/*        false,*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row mb-3">
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.ApportionedAmount']
              ?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <FormulaInput
            useBlur
            value={apportionModel?.apportionedAmount || ''}
            onChange={e => {
              clearValidation('ApportionmentWrapper.ApportionedAmount')
              setApportionModel({
                ...apportionModel,
                apportionedAmount: e,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('component')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ApportionedComponent"
            value={apportionModel?.apportionedComponent}
            onChange={e => {
              setApportionModel({
                ...apportionModel,
                apportionedComponent: e.value,
              })
            }}
          />
        </div>
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.PrimaryDescription']
              ?.message
          }
          className="col-4 d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('primaryDescription')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.primaryDescription || ''}
            onChange={e => {
              clearValidation('ApportionmentWrapper.PrimaryDescription')
              setApportionModel({
                ...apportionModel,
                primaryDescription: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.SecondaryDescription']
              ?.message
          }
          className="col-4 d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('secondaryDescription')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.secondaryDescription || ''}
            onChange={e => {
              clearValidation('ApportionmentWrapper.SecondaryDescription')
              setApportionModel({
                ...apportionModel,
                secondaryDescription: e.target.value,
              })
            }}
          />
        </div>
        <div
          validation-message={
            globalValidation?.['ApportionmentWrapper.Reference']?.message
          }
          className="col-4 d-flex align-items-center"
        >
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={apportionModel?.reference || ''}
            onChange={e => {
              clearValidation('ApportionmentWrapper.Reference')
              setApportionModel({
                ...apportionModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
