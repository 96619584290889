/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { AuditLogFilter } from '../../../Global/enumeration'
import Pager from '../../../Components/Tables/Pager'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import GroupedTable from '../../../Components/Tables/GroupedTable'
import { getData } from '../../../Helpers/webApi'
import DatePicker from '../../../Components/Date/DatePicker'
import { formatDate } from '../../../Helpers/formatters'
import { FiltersContainer } from '../../../Components/Tables/FiltersContainer'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'

function AuditTrails() {
  const [reqParams, setReqParams] = useState({
    pageSize: 10,
    pageIndex: 0,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    filter: '',
    auditTrailFilter: Object.keys(AuditLogFilter)[0],
  })

  const [isBusy, setIsBusy] = useState(false)
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [sortedBy, setSortedBy] = useState(null)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [auditTrailFilter, setAuditTrailFilter] = useState(
    Object.keys(AuditLogFilter)[0]
  )

  const [groupBy, setGroupBy] = useState({
    label: localize('eventType'),
    column: 'eventType',
  })

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AuditLog/find-audit-trails-by-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const groupingColumns = [
    {
      label: localize('eventType'),
      column: 'eventType',
    },
    {
      label: localize('applicationDomainName'),
      column: 'applicationDomainName',
    },
    {
      label: localize('applicationUserName'),
      column: 'applicationUserName',
    },
    {
      label: localize('applicationUserDesignation'),
      column: 'applicationUserDesignation',
    },
    {
      label: localize('environmentUserName'),
      column: 'environmentUserName',
    },
    {
      label: localize('environmentMachineName'),
      column: 'environmentMachineName',
    },
    {
      label: localize('environmentDomainName'),
      column: 'environmentDomainName',
    },
    {
      label: localize('environmentOSVersion'),
      column: 'environmentOSVersion',
    },
    {
      label: localize('environmentMACAddress'),
      column: 'environmentMACAddress',
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      column: 'environmentMotherboardSerialNumber',
    },
    {
      label: localize('environmentProcessorId'),
      column: 'environmentProcessorId',
    },
    {
      label: localize('environmentIPAddress'),
      column: 'environmentIPAddress',
    },
  ]

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('eventType'), template: r => <>{r?.eventType}</> },
    { label: localize('activity'), template: r => <>{r?.activity}</> },
    {
      label: localize('applicationDomainName'),
      template: r => <>{r?.applicationDomainName}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('applicationUserDesignation'),
      template: r => <>{r?.applicationUserDesignation}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('risksAndAudit'),
          localize('auditTrails'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <FiltersContainer>
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="d-flex align-items-center">
                      <label className="text-capitalize" htmlFor="searchByDate">
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams?.startDate,
                          endDate: reqParams?.endDate,
                        }}
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <label className="col-2 text-capitalize">
                        {localize('groupBy')}
                      </label>
                      <select
                        className="form-select text-capitalize"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={groupBy.column}
                        onChange={e =>
                          setGroupBy({
                            label: groupingColumns.find(
                              f => f.column === e.target.value
                            )?.label,
                            column: e.target.value,
                          })
                        }
                      >
                        {groupingColumns.map(({ label, column }) => (
                          <option className="text-capitalize" value={column}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <label
                        htmlFor="searchTable"
                        className="text-capitalize me-2"
                      >
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="AuditTrailFilter"
                        value={auditTrailFilter}
                        onChange={e => {
                          setAuditTrailFilter(e.value)
                        }}
                        sort
                      />
                      <form
                        className="d-flex align-items-center col-5 col"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filter: search,
                            auditTrailFilter: auditTrailFilter,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filter}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              filter: search,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </FiltersContainer>
                  <div className="row col-12 mt-2">
                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={tableData}
                    />
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuditTrails
