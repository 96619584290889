/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { vehicleFleetProductType } from '../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import CreateFeetProduct from './CreateFeetProduct/CreateFeetProduct'

const initialFleetProduct = {
  productTypeDescription: Object.keys(vehicleFleetProductType)[0],
  productType: Object.keys(vehicleFleetProductType)[0],
  minimumContribution: 0,
  priority: 0,
}

function FleetProducts() {
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [fleetProduct, setFleetProduct] = useState(initialFleetProduct)

  const columns = [
    {
      label: 'productType',
      template: r => <>{r?.productTypeDescription}</>,
    },
    {
      label: 'productName',
      template: r => <>{r?.targetProductDescription}</>,
    },
    {
      label: 'name',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'minimumContribution',
      template: r => <>{formatCurrency(r?.minimumContribution)}</>,
      headerTextAlign: 'right',
    },
    {
      label: 'priority',
      template: r => <>{formatCurrency(r?.priority)}</>,
      headerTextAlign: 'right',
    },
    {
      label: 'isStatutory',
      template: r => <>{r?.isStatutory.toString()?.toUpperCase()}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r?.isLocked.toString()?.toUpperCase()}</>,
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleAddButton = () => {
    setModalMode('add')
    setFleetProduct(initialFleetProduct)
  }

  const handleCancel = () => {
    setModalMode(null)
    setFleetProduct(initialFleetProduct)
  }

  return (
    <>
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateFeetProduct
          refresh={refresh}
          setRefresh={setRefresh}
          handleClose={handleCancel}
          mode={modalMode}
          fleetProduct={fleetProduct}
          setFleetProduct={setFleetProduct}
        />
      )}

      <>
        <ListingPage
          columns={columns}
          url="/vehicleFleetProduct/find-by-filter-in-page"
          params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
          map={[
            localize('vehicleFleetManagement'),
            localize('vehicleFleetProducts'),
          ]}
          onAdd={handleAddButton}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  setFleetProduct(r)
                  setModalMode('edit')
                }}
              >
                <i className="uil uil-edit-alt"></i>
                <span>{localize('edit')}</span>
              </div>
            </>
          )}
          refreshAt={refresh}
        />
      </>
    </>
  )
}

export default FleetProducts
