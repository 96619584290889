/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../Global/Localize'
import { employeeFilter } from '../../Helpers/constants'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import { formatDate } from '../../Helpers/formatters'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'

function EmployeeLookup({ displayValue, onSelect, disabled }) {
  const [reqParams, setReqParams] = useState({
    text: '',
    employeeFilter: employeeFilter.MEMBER_NUMBER,
    pageIndex: 0,
    pageSize: 10,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('email'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.customerPersonalFileNumber}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r.designationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r.departmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r.employeeTypeDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r.bloodGroupDescription}</>,
    },
    {
      label: localize('nssf'),
      template: r => <>{r.nationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('nhif'),
      template: r => <>{r.nationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r.customerPersonalIdentificationNumber}</>,
    },
    {
      label: localize('employmentStatus'),
      template: r => <>{r.employmentStatusDescription}</>,
    },
    {
      label: localize('hireDate'),
      template: r => <>{formatDate(r.hireDate)}</>,
    },
    {
      label: localize('releaseDate'),
      template: r => <>{formatDate(r.releaseDate)}</>,
    },
    {
      label: localize('inactivityTimeout'),
      template: r => <>{r.inactivityTimeout}</>,
    },
    {
      label: localize('timeDurationStartTime'),
      template: r => <>{r.timeDurationStartTime}</>,
    },
    {
      label: localize('timeDurationEndTime'),
      template: r => <>{r.timeDurationEndTime}</>,
    },
    {
      label: localize('enforceSystemInitializationLockTime'),
      template: r => (
        <span className="text-capitalize">
          {r?.enforceSystemInitializationLockTime?.toString()}
        </span>
      ),
    },
    {
      label: localize('printingModeDescription'),
      template: r => <>{r.printingModeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('onlineNotificationsEnabled'),
      template: r => (
        <span className="text-capitalize">
          {r?.onlineNotificationsEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceBiometricsForLogin'),
      template: r => (
        <span className="text-capitalize">
          {r?.enforceBiometricsForLogin?.toString()}
        </span>
      ),
    },
    {
      label: localize('useDefaultPrinter'),
      template: r => (
        <span className="text-capitalize">
          {r?.useDefaultPrinter?.toString()}
        </span>
      ),
    },
    {
      label: localize('electronicPayslipEnabled'),
      template: r => (
        <span className="text-capitalize">
          {r?.electronicPayslipEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/Employee/find-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('employeeLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center my-2 col-4">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                      })
                    }}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="d-flex align-items-center my-2">
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="EmployeeFilter"
                        value={reqParams?.employeeFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            employeeFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                      <input
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control ms-3"
                        id="searchTable"
                      />
                      <button
                        type="submit"
                        className="btn btn-primary ms-1 text-capitalize"
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default EmployeeLookup
