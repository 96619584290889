/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { RecallTracker } from './Partials/RecallTracker'
import Pager from '../../../../Components/Tables/Pager'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { CustomerFilter } from '../../../../Global/enumeration'
import Tab from '../../../../Components/Tabs/Tab'
import Swal from 'sweetalert2'
import { RecallFileMovementHistory } from './Partials/RecallFileMovementHistory'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'

function FileTrackingRecall() {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
  })

  const [fileTrackHistory, setFileTrackHistory] = useState([])
  const [fileMovementHistory, setFileMovementHistory] = useState([])
  const [loadingFileMovementHistory, setLoadingFileMovementHistory] =
    useState(false)
  const [selectedFileRegisters, setSelectedFileRegisters] = useState([])

  const [selectedFileRegistersHistory, setSelectedFileRegistersHistory] =
    useState([])

  const [
    initializedGetMovementHistoryAtLeastOnce,
    setInitializedGetMovementHistoryAtLeastOnce,
  ] = useState(false)

  const [toggleSelection, setToggleSelection] = useState(false)

  const getFileTrackHistory = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-registers-by-filter-excluding-last-destination-department-id-in-page',
      reqParams,
      false
    )
    if (success) {
      setFileTrackHistory(data)
    }
    setIsBusy(false)
  }

  const getFileMovementHistory = async r => {
    setIsBusy(false)
    setLoadingFileMovementHistory(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-movement-history-by-file-register-id',
      { fileRegisterId: r.id },
      false
    )
    if (success) {
      setFileMovementHistory(data.result || [])
      setLoadingFileMovementHistory(false)
    }
    setIsBusy(false)
  }

  async function handleSubmit() {
    if (selectedFileRegisters.length === 0) {
      Swal.fire('Operation Failed', 'Please Select at least one file', 'error')
      return
    }

    setIsBusy(false)
    const { success } = await putData(
      '/FileRegister/recall-files-request',
      {
        FileRegisters: selectedFileRegisters,
      },

      false,
      'File Tracking'
    )
    if (success) {
      setSelectedFileRegistersHistory([])
      setSelectedFileRegisters([])
      setFileMovementHistory([])
      getFileTrackHistory()
    }
    setIsBusy(false)
  }

  const onToggleSelection = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedFileRegisters([])
      setSelectedFileRegisters([...fileTrackHistory.pageCollection])
    } else {
      setSelectedFileRegisters([])
      setToggleSelection(checkStatus)
    }
  }

  useEffect(() => {
    getFileTrackHistory()
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('fileTracking'),
          localize('recall'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3 mt-3">
                    <div className="mb-3">
                      <div className="d-flex mt-3 mb-3 justify-content-between">
                        <div className="d-flex align-content-start justify-content-start">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            name=""
                            id="recordsPerPage"
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="d-flex align-content-end  align-items-center me-5">
                            <Checkbox
                              id="toggleSelection"
                              checked={toggleSelection}
                              onCheck={v => onToggleSelection(v)}
                            />
                            <label
                              htmlFor="toggleSelection"
                              className="text-capitalize ms-2"
                            >
                              {localize('toggleSelection')}
                            </label>
                          </div>
                          <div className="d-flex align-content-end justify-content-end align-items-center me-3">
                            <label className="col-3 text-capitalize">
                              {localize('search')}
                            </label>
                            <EnumsServerCombo
                              customContainerStyle={{ width: '100%' }}
                              enumsKey="CustomerFilter"
                              value={reqParams?.customerFilter}
                              onChange={e => {
                                setReqParams({
                                  ...reqParams,
                                  pageIndex: 0,
                                  customerFilter: e.value,
                                })
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center align-content-end justify-content-end  me-5">
                            <input
                              defaultValue={reqParams.text}
                              onChange={e =>
                                setReqParams({
                                  ...reqParams,
                                  text: e.target.value,
                                })
                              }
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  getFileTrackHistory()
                                }
                              }}
                              className="form-control"
                              placeholder="Enter text..."
                              type="text"
                            />
                            <button
                              id="FilterButton"
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                getFileTrackHistory()
                              }}
                            >
                              <i className="uil uil-filter"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <RecallTracker
                        destinations={fileTrackHistory}
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                        selectedFileRegistersHistory={
                          selectedFileRegistersHistory
                        }
                        setSelectedFileRegistersHistory={
                          setSelectedFileRegistersHistory
                        }
                        onGetFileMovementHistory={r => {
                          setInitializedGetMovementHistoryAtLeastOnce(true)
                          getFileMovementHistory(r)
                        }}
                        setSelectedFileRegisters={setSelectedFileRegisters}
                        selectedFileRegisters={selectedFileRegisters}
                      />
                    </div>
                    <Pager
                      itemsCount={fileTrackHistory.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                    <div className="d-flex mt-1 mb-3 justify-content-end">
                      <button
                        onClick={handleSubmit}
                        className="btn btn-success text-uppercase px-4 ms-auto"
                      >
                        {localize('recall')}
                      </button>
                    </div>
                  </div>
                  {initializedGetMovementHistoryAtLeastOnce && (
                    <div className="row mb-3 mt-3">
                      <Tab
                        tabItems={[
                          {
                            label: localize('fileMovementHistory'),
                            item: (
                              <RecallFileMovementHistory
                                movementHistories={fileMovementHistory}
                                loading={loadingFileMovementHistory}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileTrackingRecall
