/** @format */
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { postData, putData } from '../../../../../../../Helpers/webApi'
import { ViewJournalVoucherVerificationModal } from './ViewJournalVoucherVerificationModal'
import React, { useEffect, useState } from 'react'
import { VerifyJournalVoucherModal } from './VerifyJournalVoucherModal'
import { JournalVoucherAuthOption } from '../../../../../../../Global/enumeration'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import ActionModal from '../../../../../../../Components/ActionModal/ActionModal'
import Swal from 'sweetalert2'
import { SYSTEM_PERMISSION_TYPES } from '../../../../../../../Helpers/constants'

function JournalVoucherVerificationModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedJournalVoucher,
}) {
  const [verificationModels, setVerificationModels] = useState({
    journalVoucherAuthOption: Object.keys(JournalVoucherAuthOption)[0],
  })

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const [showAuthorizationDialog, setShowVerificationDialog] = useState(false)

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  const showSuccessModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Operation Successful',
      text: 'Create Journal Voucher',
    })
  }

  const onVerifyJournalVoucher = async () => {
    const { success, data } = await putData(
      '/JournalVoucher/audit-journal-voucher',
      {
        JournalVoucher: {
          ...selectedJournalVoucher,
          auditRemarks: verificationModels.auditRemarks,
        },
        JournalVoucherAuthOption: verificationModels.journalVoucherAuthOption,
      },
      true,
      'Verifying Journal Voucher'
    )
    if (success) {
      showActionAuthroizationModal()
    }
  }

  const handleOnCancelSendNotification = () => {
    setShowVerificationDialog(false)
  }

  const showActionAuthroizationModal = () => {
    setShowVerificationDialog(true)
  }

  const handleOnSendNotification = async () => {
    setShowVerificationDialog(false)
    showSuccessModal()
    await postData(
      '/OnlineNotification/send-notification-users-in-system-permission-type',
      {
        NotificationModel: {
          Description: `New Authorization Request for Voucher, ${selectedJournalVoucher.voucherNumber}`,
        },
        SystemPermissionType: SYSTEM_PERMISSION_TYPES.JOURNAL_VOUCHER_AUTHORIZATION,
      },
      false,
      null
    )
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('journalVoucher')}
            handleAction={onVerifyJournalVoucher}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'view' && (
              <ViewJournalVoucherVerificationModal
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
            {mode === 'verify' && (
              <VerifyJournalVoucherModal
                validationErrors={globalValidationErrors}
                clearValidation={clearValidation}
                setVerificationModels={setVerificationModels}
                verificationModels={verificationModels}
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
          </ModalTemplate>
        )}
      </>
      {showAuthorizationDialog && (
        <ActionModal
          cancelText={'NO'}
          confirmText={'YES'}
          modalTitle={'Journal Voucher Authorization Request'}
          handleAction={handleOnSendNotification}
          handleClose={handleOnCancelSendNotification}
        >
          <div>Do you want to request for authorization?</div>
        </ActionModal>
      )}
    </>
  )
}

export default JournalVoucherVerificationModal
