/** @format */

import React from 'react'
import { useState } from 'react'
import { postData, putData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'

const CreateInventoryPackageType = ({
  mode,
  setMode,
  handleClose,
  inventoryPackage,
  setInventoryPackage,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)

    const { success } = await postData(
      '/InventoryPackageType/add-inventory-package-type',
      inventoryPackage,
      true
    )

    if (success) {
      showNotification(
        'Inventory Package Type',
        'success',
        'Operation completed successfully!'
      )
      setInventoryPackage(null)
      loadTableData()
      setMode(null)
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)

    const { success } = await putData(
      '/InventoryPackageType/update-inventory-package-type',
      inventoryPackage,
      false
    )
    if (success) {
      showNotification('Update Inventory Package Type', 'success')
      setMode(null)
      setInventoryPackage(null)
      loadTableData()
    }
    setIsBusy(false)
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={
          mode === 'add'
            ? localize('createSupplyCategory')
            : localize('updateSupplyCategory')
        }
        handleClose={handleClose}
        handleAction={mode === 'add' ? handleSubmit : handleEdit}
        modalClassName="modal-xl"
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className=" d-flex row align-items-center mb-3">
              <div className="d-flex col-md-12 align-items-center gap-3">
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={inventoryPackage?.description}
                  onChange={e =>
                    setInventoryPackage({
                      ...inventoryPackage,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" d-flex row align-items-center">
              <div className="d-flex col-md-12 align-items-center gap-3">
                <label className="text-capitalize col-1">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={inventoryPackage?.remarks}
                  onChange={e =>
                    setInventoryPackage({
                      ...inventoryPackage,
                      remarks: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateInventoryPackageType
