/** @format */
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../Global/Localize'
import { RecordAuthOption } from '../../../../Global/enumeration'
import { useState } from 'react'
import Loader from '../../../../Components/Loaders/Loader'

export const VerifyTemporaryRoleAction = ({
  verifyTemporaryRoleActionModel,
  setVerifyTemporaryRoleActionModel,
  validationBag,
  clearValidation,
  isBusy,
}) => {
  const [
    defaultVerifyTemporaryRoleAction,
    setDefaultVerifyTemporaryRoleAction,
  ] = useState(Object.keys(RecordAuthOption)[0])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-content-end justify-content-end">
            <div
              validation-message={
                validationBag?.['EmployeeTemporaryRole.Remarks']?.message
              }
              className="d-flex align-items-center me-5 with-validation"
            >
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                defaultValue={verifyTemporaryRoleActionModel?.remarks}
                onChange={e => {
                  clearValidation('EmployeeTemporaryRole.Remarks')
                  setVerifyTemporaryRoleActionModel({
                    ...verifyTemporaryRoleActionModel,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RecordAuthOption"
                value={defaultVerifyTemporaryRoleAction}
                onChange={e => {
                  setDefaultVerifyTemporaryRoleAction(e.value)
                  setVerifyTemporaryRoleActionModel({
                    ...verifyTemporaryRoleActionModel,
                    action: e.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
