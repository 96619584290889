/** @format */

import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'

export const AccountMetadataCarryForwards = ({ selectedCustomerAccount }) => {
  const [carryFowardCommissions, setCarryFowardCommissions] = useState([])

  const [selectedGLAccount, setSelectedGLAccount] = useState(null)
  const [carryForwardEntries, setCarryForwardEntries] = useState([])

  const fetchGeneralCarryForwards = async () => {
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-carry-forward-general-ledger-accounts',
      {
        customerAccountId: selectedCustomerAccount?.id,
      },
      false,
      null
    )

    if (success) {
      setCarryFowardCommissions(prevState => {
        let kvp = {}
        data.result.forEach((item, idx) => {
          kvp[item.id] = {
            name: item.name,
            chartOfAccountId: item.id,
          }
        })
        setSelectedGLAccount(Object.keys(kvp)[0])
        return kvp
      })
    }
  }

  const fetchCarryForwardEntries = async () => {
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-carry-forwards-by-beneficiary-customer-account-id-and-chart-of-account-id',
      {
        beneficiaryCustomerAccountId: selectedCustomerAccount.id,
        beneficiaryChartOfAccountId: selectedGLAccount,
      },
      false,
      null
    )
    if (success) {
      setCarryForwardEntries(data.result)
    }
  }

  useEffect(() => {
    if (selectedCustomerAccount) {
      fetchGeneralCarryForwards()
    }
  }, [selectedCustomerAccount])

  useEffect(() => {
    if (selectedGLAccount) {
      fetchCarryForwardEntries()
    }
  }, [selectedGLAccount])

  const columns = [
    {
      label: localize('beneficiaryChartOfAccountName'),
      template: r => <>{r.beneficiaryChartOfAccountName}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <div className="d-flex align-content-end justify-content-end mb-2 mt-2">
        <div className="d-flex align-items-center me-5">
          <label className="col-auto me-3 text-capitalize">
            {localize('charge')}
          </label>
          <select
            value={selectedGLAccount}
            onChange={e => {
              setSelectedGLAccount(e.target.value)
            }}
            className="form-select form-control text-capitalize"
          >
            {Object.entries(carryFowardCommissions)
              .sort((a, b) => a[1].name.localeCompare(b[1].name))
              .map(([key, value]) => (
                <option className="text-capitalize" value={key}>
                  {value.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <SimpleTable columns={columns} data={carryForwardEntries} />
    </>
  )
}
