/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import { formatDate, getTwoWeeksFromDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import DatePicker from '../../../../Components/Date/DatePicker'
import { enrollmentRequestFilterDescriptions } from '../../../../Global/enumeration'
import { getData } from '../../../../Helpers/webApi'
import ReceiveEnrollment from './ReceiveEnrollment'
import Loader from '../../../../Components/Loaders/Loader'

function Enrollment() {
  const columns = [
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
    { label: 'status', template: r => <>{r.statusDescription}</> },
    // {
    //   label: 'serialNumber',
    //   template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    // },
    { label: 'origin', template: r => <>{r.originDescription}</> },
    // { label: 'originIdentifier', template: r => <>{r.originIdentifier}</> },
    {
      label: 'customerType',
      template: r => <>{r.membershipClassCustomerTypeDescription}</>,
    },
    { label: 'name', template: r => <>{r.fullName}</> },
    {
      label: 'individualType',
      template: r => <>{r.individualTypeDescription}</>,
    },
    { label: 'gender', template: r => <>{r.individualGenderDescription}</> },
    {
      label: 'maritalStatus',
      template: r => <>{r.individualMaritalStatusDescription}</>,
    },
    {
      label: 'identityCardType',
      template: r => <>{r.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCard') + ' #',
      template: r => <>{r.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerial') + ' #',
      template: r => <>{r.individualIdentityCardSerialNumber}</>,
    },
    {
      label: 'nationality',
      template: r => <>{r.individualNationalityDescription}</>,
    },
    {
      label: 'birthDate',
      template: r => <>{formatDate(r.individualBirthDate, true)}</>,
    },
    {
      label: 'employmentSector',
      template: r => <>{r.employmentSectorDescription}</>,
    },
    {
      label: 'employmentDate',
      template: r => <>{formatDate(r.individualEmploymentDate)}</>,
    },
    {
      label: 'employmentDesignation',
      template: r => <>{r.individualEmploymentDesignation}</>,
    },
    {
      label: 'employmentTerms',
      template: r => <>{r.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: 'educationLevel',
      template: r => <>{r.individualEducationLevelDescription}</>,
    },
    {
      label: 'membershipClass',
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: 'disabilityType',
      template: r => <>{r.individualDisabilityTypeDescription}</>,
    },
    { label: 'hudumaNumber', template: r => <>{r.individualHudumaNumber}</> },
    // { label: 'groupName', template: r => <>{r.status}</> },
    {
      label: localize('registration') + ' #',
      template: r => <>{r.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerial') + ' #',
      template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: 'dateEstablished',
      template: r => <>{formatDate(r.nonIndividualDateEstablished, true)}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: 'physicalAddress', template: r => <>{r.addressStreet}</> },
    { label: 'postalCode', template: r => <>{r.addressPostalCode}</> },
    { label: 'city', template: r => <>{r.addressCity}</> },
    { label: 'emailAddress', template: r => <>{r.addressEmail}</> },
    { label: 'landLine', template: r => <>{r.addressLandLine}</> },
    { label: 'mobileLine', template: r => <>{r.addressMobileLine}</> },
    // { label: 'monthlyContribution', template: r => <>{r.status}</> },
    {
      label: 'DutyWorkstation',
      template: r => <>{r.dutyStationDescription}</>,
    },
    {
      label: 'electoralZone',
      template: r => <>{r.electoralZoneDescription}</>,
    },
    {
      label: 'Business/Group/Employer',
      template: r => <>{r.employerDescription}</>,
    },
    {
      label: 'retirementAge',
      template: r => <>{r.individualRetirementDate}</>,
    },
    {
      label: 'adminDivision',
      template: r => <>{r.administrativeDivisionDescription}</>,
    },
    { label: 'age', template: r => <>{r.age}</> },
    {
      label: 'enrollmentDate',
      template: r => <>{formatDate(r.enrollmentDate)}</>,
    },
    { label: 'recruitedBy', template: r => <>{r.recruitedBy}</> },
    { label: 'payrollNumbers', template: r => <>{r.payrollNumbers}</> },
    // { label: localize('pin') + ' #', template: r => <>{r.status}</> },
    { label: 'remarks', template: r => <>{r.remarks}</> },
    {
      label: 'verification/RejectionRemarks',
      template: r => <>{r.authorizationRemarks}</>,
    },
    { label: 'authorized/rejectedBy', template: r => <>{r.authorizedBy}</> },
    { label: 'authorized/rejectedDate', template: r => <>{r.rejectedBy}</> },
    { label: 'modifiedBy', template: r => <>{r.modifiedBy}</> },
    { label: 'modifiedDate', template: r => <>{formatDate(r.modifiedDate)}</> },
    { label: 'createdBy', template: r => <>{r.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
  ]
  const groupingColumn = [
    { label: 'Origin', column: 'originDescription' },
    {
      label: 'Customer Type',
      column: 'membershipClassCustomerTypeDescription',
    },
    { label: 'Status', column: 'statusDescription' },
    { label: 'Recruited By', column: 'recruitedBy' },
    { label: 'Created By', column: 'createdBy' },
    { label: 'Verified By', column: 'verifiedBy' },
    { label: 'Authorized By', column: 'authorizedBy' },
    {
      label: 'Identity Card Type',
      column: 'individualIdentityCardTypeDescription',
    },
    { label: 'Salutation', column: 'individualSalutationDescription' },
    { label: 'Gender', column: 'individualGenderDescription' },
    { label: 'Marital Status', column: 'individualMaritalStatusDescription' },
    { label: 'Nationality', column: 'individualNationalityDescription' },
    { label: 'Disability Type', column: 'individualDisabilityTypeDescription' },
    { label: 'Duty Station', column: 'dutyStationDescription' },
    { label: 'Business / Group / Employer', column: 'employerDescription' },
    { label: 'Admin Division', column: 'administrativeDivisionDescription' },
    { label: 'Employment Sector', column: 'employmentSectorDescription' },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [groupBy, setGroupBy] = useState(groupingColumn[0])
  const [search, setSearch] = useState('')
  const [dates, setDates] = useState({
    startDate: getTwoWeeksFromDate(new Date()),
    endDate: new Date(),
  })
  const [modalMode, setModalMode] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [reqParams, setReqParams] = useState({
    ...dates,
    filterText: '',
    enrollmenRequestFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [enrollments, setEnrollments] = useState(null)

  async function loadEnrollments() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/enrollmentRequest/find-enrollment-request-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setEnrollments(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    loadEnrollments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('membersManagement'),
          localize('enrollment'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          <div className="row mb-2">
            <div className="d-flex align-items-center mb-2">
              <button
                className="btn btn-success"
                onClick={() => {
                  setModalMode('add')
                  setSelectedCustomer(null)
                }}
              >
                <i className="uil uil-plus-circle me-2"></i>
                {localize('receive')}
              </button>
            </div>
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="mb-2 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor=""
                    className="text-capitalize me-2"
                    style={{ minWidth: 'fit-content' }}
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select"
                    style={{ width: 'fit-content' }}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                    value={reqParams?.pageSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <label
                    htmlFor=""
                    className="text-capitalize me-2"
                    style={{ minWidth: 'fit-content' }}
                  >
                    {localize('groupBy')}
                  </label>
                  <select
                    className="form-select"
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumn.find(
                          g => g.column === e.target.value
                        )?.label,
                        column: e.target.value,
                      })
                    }
                  >
                    {groupingColumn.map(({ label, column }) => (
                      <option className="text-capitalize" value={column}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2">
                    {localize('dates')}
                  </label>
                  <DatePicker
                    value={dates}
                    onSelect={({ startDate, endDate }) =>
                      setDates({ ...dates, startDate, endDate })
                    }
                    useStartEnd
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <select
                    style={{ maxWidth: 150 }}
                    className="form-select me-2"
                    value={reqParams?.enrollmenRequestFilter}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        enrollmenRequestFilter: e.target.value,
                      })
                    }
                  >
                    {enrollmentRequestFilterDescriptions
                      .map((e, i) => ({ label: e, id: i }))
                      .sort((a, b) => a.label > b.label)
                      .map(({ label, id }) => (
                        <option value={id} className="text-capitalize">
                          {label}
                        </option>
                      ))}
                  </select>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({ ...reqParams, filterText: search })
                    }}
                  >
                    <input
                      type="search"
                      on
                      className="form-control"
                      onChange={e => setSearch(e.target.value)}
                      value={search}
                    />
                  </form>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={enrollments?.pageCollection}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setModalMode('view')
                        setSelectedCustomer(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      {localize('view')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setModalMode('edit')
                        setSelectedCustomer(r)
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      {localize('edit')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setModalMode('verify')
                        setSelectedCustomer(r)
                      }}
                    >
                      <i className="uil uil-check-circle"></i>
                      {localize('verify')}
                    </div>

                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setModalMode('authorize')
                        setSelectedCustomer(r)
                      }}
                    >
                      <i className="uil uil-lock"></i>
                      {localize('authorize')}
                    </div>
                  </>
                )}
              />
              <div className="my-2"></div>
              <Pager
                itemsCount={enrollments?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                onPageChange={p => {}}
                pageBatchSize={5}
              />
            </>
          )}
        </div>
      </div>
      {modalMode && (
        <ReceiveEnrollment
          readOnly={
            modalMode === 'view' ||
            modalMode === 'verify' ||
            modalMode === 'authorize'
          }
          customer={selectedCustomer}
          mode={modalMode}
          closeModal={() => setModalMode(null)}
        />
      )}
    </>
  )
}

export default Enrollment
