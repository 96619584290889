/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const PerformancePillarModal = ({
  mode,
  selectedPerformancePillar,
  setSelectedPerformancePillar,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const [showError, setShowError] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/PerformancePillar/update',
            selectedPerformancePillar || {
              description: '',
            },
            false
          )
        : await postData(
            '/PerformancePillar/create',
            selectedPerformancePillar || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Performance Pillars', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'performancePillar'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cPerformancePillarIsLocked"
              onCheck={e => {
                setSelectedPerformancePillar({
                  ...selectedPerformancePillar,
                  isLocked: e,
                })
              }}
              checked={selectedPerformancePillar?.isLocked}
            />
            <label
              htmlFor="cPerformancePillarIsLocked"
              className="text-capitalize"
            >
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedPerformancePillar?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedPerformancePillar({
                      ...selectedPerformancePillar,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={globalValidationErrors['Weight']?.message}
              >
                <label className="text-capitalize col-2">
                  {localize('weight')}
                </label>
                <div className="d-flex flex-column w-100">
                  <MaskNumber
                    defaultMaskValue={selectedPerformancePillar?.weight}
                    className={`
                  ${showError && 'border border-danger'}
                   `}
                    onMaskNumber={e => {
                      if (Number(e.target.value) < 0) {
                        setShowError(true)
                      } else {
                        setShowError(false)
                      }
                      clearValidation('Weight')
                      setSelectedPerformancePillar({
                        ...selectedPerformancePillar,
                        weight: e.target.value,
                      })
                    }}
                    numeralThousandsGroupStyle="none"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default PerformancePillarModal
