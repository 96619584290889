/** @format */

import { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'

const ChequePayables = ({
  customerId,
  handleSelectPayables,
  reload,
  setReload,
  hiddenColumns = [],
  externalChequeId,
}) => {
  const [payables, setPayables] = useState([])
  const [selectedPayables, setSelectedPayables] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: localize('select') + ' ?',
      template: item => (
        <Checkbox
          id={`select-cheque-payable-${item?.id}`}
          checked={selectedPayables.find(p => p.id === item?.id)}
          onCheck={value => {
            if (value) {
              setSelectedPayables([...selectedPayables, item])
            } else {
              setSelectedPayables(
                selectedPayables.filter(p => p.id !== item?.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: item => (
        <>{item?.customerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: item => <>{formatCurrency(item?.bookBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('principalBalance'),
      template: item => <>{formatCurrency(item?.principalBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('partialOffset(principal)'),
      template: item => <>{formatCurrency(item?.partialPrincipalOffset)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('interestBalance'),
      template: item => <>{formatCurrency(item?.interestBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('partialOffset(interest)'),
      template: item => <>{formatCurrency(item?.partialInterestOffset)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('partialOffsetFormula(principal)'),
      template: item => (
        <>{formatCurrency(item?.partialPrincipalOffsetMathExpression)}</>
      ),
      headerTextAlign: 'right',
    },
    {
      label: localize('partialOffsetFormula(interest)'),
      template: item => (
        <>{formatCurrency(item?.partialInterestOffsetMathExpression)}</>
      ),
      headerTextAlign: 'right',
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdBy)}</>,
    },
  ]

  const columnsToDisplay = columns.filter((_c, i) => !hiddenColumns.includes(i))

  const fetchPayables = async () => {
    setIsBusy(true)

    const url =
      '/ExternalCheque/find-external-cheque-payables-by-external-cheque-id'

    const { success, data } = await getData(
      url,
      { externalChequeId, customerId },
      false
    )

    if (success) {
      setIsBusy(false)

      setPayables(data?.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchPayables()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalChequeId])

  useEffect(() => {
    if (reload) setReload(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  useEffect(
    () => {
      if (handleSelectPayables) handleSelectPayables(selectedPayables)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPayables]
  )

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable columns={columnsToDisplay} data={payables} />
      )}
    </>
  )
}

export default ChequePayables
