/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import CreateMobileToBankCollectionModal from './CreateMobileToBankCollectionModal'

function MobileToBankCollection() {
  const columns = [
    {
      label: localize('code'),
      template: r => <>{r?.paddedCode}</>,
      sortBy: 'paddedCode',
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('mobileToBankTerminal'),
      template: r => <>{r?.mobileToBankTerminalDescription}</>,
      sortBy: 'mobileToBankTerminalDescription',
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
      sortBy: 'fullAccountNumber',
    },
    {
      label: localize('productName'),
      template: r => (
        <>{r?.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
      sortBy: 'customerFullName',
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r?.customerAccountCustomerReference1}</>,
      sortBy: 'customerAccountCustomerReference1',
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r?.customerAccountCustomerReference2}</>,
      sortBy: 'customerAccountCustomerReference3',
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r?.customerAccountCustomerReference3}</>,
      sortBy: 'customerAccountCustomerReference3',
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
      sortBy: 'addressMobileLine',
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
      sortBy: 'addressInternet',
    },
    {
      label: localize('receiveTextAlert'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.receiveTextAlert.toString())}
        </span>
      ),
    },
    {
      label: localize('receiveEmailAlert'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.receiveEmailAlert.toString())}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    mobileToBankCollectionFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedMobileToBankCollection, setSelectedMobileToBankCollection] =
    useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/MobileToBankCollection/create'
        : '/MobileToBankCollection/update'
    const { success } =
      mode === 'add'
        ? await postData(url, selectedMobileToBankCollection, false)
        : await putData(url, selectedMobileToBankCollection, false)

    if (success) {
      setMode(null)
      setSelectedMobileToBankCollection(null)
      setRefreshAt(true)
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedMobileToBankCollection(null)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.filterText])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/MobileToBankCollection/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedMobileToBankCollection(null)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('mobileToBank'),
          localize('mobileToBankCollections'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleAdd} />
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <label
                          className="ms-2 text-capitalize"
                          htmlFor="searchTable-cat"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          className="ms-2"
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="MobileToBankCollectionFilter"
                          value={reqParams?.mobileToBankCollectionFilter}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              mobileToBankRequestFilter: e.value,
                            })
                          }
                        />
                        <input
                          type="text"
                          id="searchTable-m2b-reconciliation"
                          className="form-control ms-2"
                          value={search}
                          onChange={e => {
                            setSearch(e.target.value)
                          }}
                        />
                        <button type="submit" className="btn btn-primary ms-2">
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('edit')
                              setSelectedMobileToBankCollection(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(mode === 'add' || mode === 'edit') && (
        <CreateMobileToBankCollectionModal
          mode={mode}
          selectedMobileToBankCollection={selectedMobileToBankCollection}
          setSelectedMobileToBankCollection={setSelectedMobileToBankCollection}
          closeModal={closeModal}
          loadTableData={loadTableData}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default MobileToBankCollection
