/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'

function MainMenuItem({
  iconClass,
  label,
  navigated,
  onNavigated,
  subMenuOpen,
  showIconsOnly,
}) {
  return (
    <>
      <div
        onClick={onNavigated}
        className={`cent-menu-item ${navigated ? 'navigated' : ''} ${
          subMenuOpen ? 'with-hover' : 'with-hover'
        }`}
        // title={label}
        centrino-label={localize(label)}
      >
        <i className={iconClass}></i>
        {showIconsOnly || (
          <span className="label" style={{ textTransform: 'capitalize' }}>
            {localize(label)}
          </span>
        )}
      </div>
    </>
  )
}

export default MainMenuItem
