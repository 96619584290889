/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import { DiscrepanciesTab } from './Partials/DiscrepanciesTab'
import { ViewCustomerSpecimen } from '../../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { postData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'

function MatchingJointly() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [selectedDiscreprancies, setSelectedDiscreprancies] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const handleOnClickUpdate = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/CreditBatch/match-credit-batch-by-customer-account',
      {
        CreditBatchDiscrepancy: selectedDiscreprancies,
        CustomerAccount: selectedCustomerAccount,
      },
      false,
      'Discrepancy Matching Jointly'
    )
    if (success) {
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('discrepancyMatching'),
          localize('jointly'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <CustomerAccountPartial
                    onCustomerLookup={r => {
                      setSelectedCustomerAccount(r)
                    }}
                    customer={selectedCustomerAccount}
                  />

                  <div className="row mt-12">
                    <Tab
                      preload
                      tabItems={[
                        {
                          label: localize('discrepancies'),
                          item: (
                            <DiscrepanciesTab
                              onSelectDiscreprancy={(r, c) => {
                                if (c) {
                                  setSelectedDiscreprancies([
                                    ...selectedDiscreprancies,
                                    r,
                                  ])
                                } else {
                                  setSelectedDiscreprancies(
                                    selectedDiscreprancies.filter(
                                      s => s.id !== r.id
                                    )
                                  )
                                }
                              }}
                            />
                          ),
                        },
                        {
                          label: localize('specimen'),
                          item: (
                            <ViewCustomerSpecimen customer={selectedCustomer} />
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex align-content-end justify-content-end mt-3">
                    <button
                      onClick={handleOnClickUpdate}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MatchingJointly
