/** @format */
import { PleaseWait } from '../../Loaders/PleaseWait'
import Checkbox from '../../Checkbox/Checkbox'
/* eslint-disable */
import React from 'react'

export const IndefiniteCharges = ({
  disabled,
  isBusy,
  indefiniteChargesToSelect,
  onCheckIndefiniteCharge,
  prefix,
}) => {
  const handleCheckIndefinitCharges = (indefiniteCharge, checkValue) => {
    const com = [...indefiniteChargesToSelect].map(comm => {
      if (comm.id === indefiniteCharge.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckIndefiniteCharge(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {indefiniteChargesToSelect.map(indefiniteCharge => (
            <li
              key={indefiniteCharge.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${indefiniteCharge.id}`}
                checked={indefiniteCharge.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckIndefinitCharges(indefiniteCharge, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${indefiniteCharge.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  indefiniteCharge.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {indefiniteCharge.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
