/** @format */
/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../../Global/Localize'
export const TreeItem = ({
  item,
  onSelectMenu,
  selectedNavigation,
  setSelectedNavigation,
  setSelectedMenu,
  selectedMenu,
  isSelected,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
    setSelectedMenu(item.menuCode)
    setSelectedNavigation(item.navigationCode)
    onSelectMenu(item)
  }

  return (
    <li>
      <div
        className={`${isSelected ? 'text-primary fw-bold' : ''}`}
        onClick={handleExpand}
      >
        {item.children.length === 0 ? (
          <i className="fas fa-folder mx-2 "></i>
        ) : (
          <>
            {isExpanded ? (
              <i className=" fas fa-folder-minus mx-2 "></i>
            ) : (
              <i className="fas fa-folder-plus mx-2"></i>
            )}
          </>
        )}
        <a
          className={`text-capitalize ${
            isSelected ? 'text-primary fw-bold' : ''
          } `}
        >
          {localize(item.description)}
        </a>
      </div>
      {item.children && isExpanded && (
        <ul>
          {item.children.map((subItem, index) => {
            return (
              <TreeItem
                isSelected={
                  selectedMenu === subItem.menuCode &&
                  selectedNavigation === subItem.navigationCode
                }
                onSelectMenu={onSelectMenu}
                selectedMenu={selectedMenu}
                selectedNavigation={selectedNavigation}
                setSelectedNavigation={setSelectedNavigation}
                setSelectedMenu={setSelectedMenu}
                key={index}
                item={subItem}
              />
            )
          })}
        </ul>
      )}
    </li>
  )
}
