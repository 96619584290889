/** @format */

import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'

export const EditMicroCreditOfficerModal = ({
  setSelectedMicroCreditOfficer,
  selectedMicroCreditOfficer,
  isBusy,
}) => {
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employee')}
              </label>
              <input
                className="form-control"
                value={selectedMicroCreditOfficer?.employeeCustomerFullName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedMicroCreditOfficer?.employeeDesignationDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employeeBranchDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedMicroCreditOfficer?.employeeDepartmentDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employeeType')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employeeTypeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employmentStatus')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employmentStatusDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                onChange={e => {
                  setSelectedMicroCreditOfficer({
                    ...selectedMicroCreditOfficer,
                    remarks: e.target.value,
                  })
                }}
                value={selectedMicroCreditOfficer?.remarks}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
