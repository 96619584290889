/** @format */

import React from 'react'
import localize from '../../../../../../Global/Localize'
import MorguePatientLookup from '../../../../../../Components/Lookup/MorguePatientLookup'
import TransactionTypeLookup from '../../../../../../Components/Lookup/TransactionTypeLookup'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'

const PatientCashReceipt = ({
  transaction,
  setTransaction,
  selectedMorguePatient,
  setSelectedMorguePatient,
  transactionType,
  setTransactionType,
}) => {
  const handleSelectPatientLookup = patient => {
    if (patient?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected patient is locked.'
      )

    setSelectedMorguePatient(patient)
  }

  const handleSelectTransactionType = t => {
    if (t?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected transaction type is locked.'
      )

    setTransactionType(t)
  }

  return (
    <div className="row g-3">
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('morguePatient')}
        </label>

        <MorguePatientLookup
          displayValue={selectedMorguePatient?.fullName}
          onSelect={handleSelectPatientLookup}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('transactionType')}
        </label>

        <TransactionTypeLookup
          displayValue={transactionType?.description}
          onSelect={handleSelectTransactionType}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('amount')}</label>

        <MaskNumber
          onMaskNumber={e => {
            setTransaction({
              ...transaction,
              amount: e.target.value,
            })
          }}
          defaultMaskValue={transaction?.amount || 0}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('reference')}</label>

        <input
          className="form-control"
          type="text"
          onChange={e => {
            setTransaction({
              ...transaction,
              reference: e.target.value,
            })
          }}
          value={transaction?.reference}
        />
      </div>
    </div>
  )
}

export default PatientCashReceipt
