/** @format */

import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useState } from 'react'
import Pager from '../../../../../../Components/Tables/Pager'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

export const BatchImportsEntries = ({ entries, reqParams, setReqParams }) => {
  const [search, setSearch] = useState()
  const [selectedDiscrepancies, setSelectedDiscrepancies] = useState([])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedDiscrepancies.find(x => x.id === r?.id)}
          onCheck={c => {
            if (c) {
              setSelectedDiscrepancies([...selectedDiscrepancies, r])
            } else {
              setSelectedDiscrepancies(
                selectedDiscrepancies.filter(s => s.id !== r?.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    { label: localize('column1'), template: r => <>{r?.column1}</> },
    { label: localize('column2'), template: r => <>{r?.column2}</> },
    { label: localize('column3'), template: r => <>{r?.column3}</> },
    { label: localize('column4'), template: r => <>{r?.column4}</> },
    { label: localize('column5'), template: r => <>{r?.column5}</> },
    { label: localize('column6'), template: r => <>{r?.column6}</> },
    { label: localize('column7'), template: r => <>{r?.column7}</> },
    { label: localize('column8'), template: r => <>{r?.column8}</> },
    {
      label: localize('outcomeDescription'),
      template: r => <>{r.outcomeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.Remarks}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <div className="row mb-3">
        <RecordsPerPage
          className="col-3"
          reqParams={reqParams}
          setReqParams={setReqParams}
        />
        <div className="col-3">
          <div
            className="d-flex align-items-center me-5"
            style={{ width: '300px' }}
          >
            <label className="col-3 text-capitalize">
              {localize('outCome')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="AlternateChannelReconciliationOutcome"
              value={reqParams.reconciliationOutcome}
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  reconciliationOutcome: e.value,
                  pageIndex: 0,
                })
              }}
              sort
            />
          </div>
        </div>
        <div className="col-3">
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="DiscrepancyFilter"
            value={reqParams.discrepancyFilter}
            onChange={e => {
              setReqParams({
                ...reqParams,
                discrepancyFilter: e.value,
                pageIndex: 0,
              })
            }}
            sort
          />
        </div>
        <div className="col-3">
          <form
            className="col d-flex align-items-center"
            onSubmit={e => {
              e.preventDefault()
              setReqParams({
                ...reqParams,
                filterText: search,
              })
            }}
          >
            <label className="text-capitalize" htmlFor="searchTable">
              {localize('search')}
            </label>
            <input
              type="search"
              id="searchTable"
              className="form-control ms-2"
              defaultValue={reqParams.filterText}
              onChange={e => setSearch(e.target.value)}
            ></input>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={() => {
                setReqParams({
                  ...reqParams,
                  filterText: search,
                })
              }}
            >
              <i className="uil uil-filter"></i>
            </button>
          </form>
        </div>
      </div>

      <SimpleTable columns={columns} data={entries?.pageCollection} />
      <div className="mb-2"></div>
      <Pager
        itemsCount={entries?.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
      <div className="row mb-3">
        <div className="d-flex align-content-end justify-content-end">
          <div className="d-flex align-items-center me-5 ">
            <label className="text-capitalize col-5">
              {localize('status')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchEntryStatus"
              value={reqParams.status}
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  status: e.value,
                  pageIndex: 0,
                })
              }}
              sort
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <label className="text-capitalize col-5">
              {localize('entries')}
            </label>
            <input
              className="form-control"
              disabled
              value={entries?.statusEntries}
            />
          </div>
        </div>
      </div>
    </>
  )
}
