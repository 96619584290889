/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import {
  CustomerFilter,
  batchEntryStatus,
} from '../../../../../Global/enumeration'
import Pager from '../../../../../Components/Tables/Pager'
import Swal from 'sweetalert2'
import EnumsCombo from '../../../../../Components/ComboBox/EnumsCombo'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
function LoanAgingSpoolEntries({
  selectedLoanAgingSpool,
  setEntriesTotal,
  setDisplayedEntries,
  reload,
  setReload,
}) {
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')
  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.paddedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.customerAccountLoanCaseLoanProductDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('defaultedAmount'),
      template: r => <>{formatCurrency(r.defaultedAmount)}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.customerAccountLoanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('classification'),
      template: r => <>{r.classificationDescription}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => (
        <>{formatCurrency(r.customerAccountLoanCaseTotalAmount)}</>
      ),
    },
    {
      label: localize('expectedBalance'),
      template: r => <>{formatCurrency(r.expectedBalance)}</>,
    },
    {
      label: localize('prepaidAmount'),
      template: r => <>{formatCurrency(r.prepaidAmount)}</>,
    },
    {
      label: localize('defaultTimeline'),
      template: r => <>{r.defaultTimeline}</>,
    },
    {
      label: localize('provisionAmount'),
      template: r => <>{formatCurrency(r.provisionAmount)}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => (
        <>{formatDate(r.customerAccountLoanCaseDisbursedDate, true)}</>
      ),
    },
    {
      label: localize('gracePeriod'),
      template: r => (
        <>{r.customerAccountLoanCaseLoanRegistrationGracePeriod}</>
      ),
    },
    {
      label: localize('lastPaymentDueDate'),
      template: r => <>{formatDate(r.lastPaymentDueDate, true)}</>,
    },
    {
      label: localize('lastPayment'),
      template: r => <>{formatCurrency(r.lastPayment)}</>,
    },
    {
      label: localize('loanTerm(Months)'),
      template: r => (
        <>{r.customerAccountLoanCaseLoanRegistrationTermInMonths}</>
      ),
    },
    {
      label: localize('annualPercentage'),
      template: r => (
        <>
          {formatAsPercent(
            r.customerAccountLoanCaseLoanInterestAnnualPercentageRate
          )}
        </>
      ),
    },
    {
      label: localize('paymentFrequestPerYear'),
      template: r => (
        <>
          {
            r.customerAccountLoanCaseLoanRegistrationPaymentFrequencyPerYearDescription
          }
        </>
      ),
    },
    {
      label: localize('interestCalculationMode'),
      template: r => (
        <>{r.customerAccountLoanCaseLoanInterestCalculationModeDescription}</>
      ),
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const loadLoanAgingSpoolEntries = async () => {
    const { success, data } = await getData(
      `/LoanAgingSpool/find-loan-aging-spool-entries-by-filter-in-page`,
      {
        loanAgingSpoolId: selectedLoanAgingSpool.id,
        ...reqParams,
      }
    )
    if (success) {
      setEntries(data)
      setEntriesTotal(data.totalItems)
      setDisplayedEntries(data.itemsCount)
      if (reload) setReload(false)
    } else {
      setEntries([])
    }
  }

  useEffect(() => {
    loadLoanAgingSpoolEntries()
  }, [
    selectedLoanAgingSpool?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
    reload,
  ])

  async function resetLoanSpoolEntry(r) {
    const { success } = await postData(
      `/LoanAgingSpool/un-post-loan-aging-spool-entry?loanAgingSpoolEntryId=${r.id}`
    )
    if (success) {
      Swal.fire('Success', 'Operation Completed Successfully', 'success')
      loadLoanAgingSpoolEntries()
    }
  }

  return (
    <>
      <div className="row px-3 mb-2">
        <div className="d-flex align-items-center col-2">
          <label
            htmlFor="recordsPerPage"
            className=""
            style={{ marginLeft: -10, textTransform: 'capitalize' }}
          >
            {localize('recordsPerPage')}
          </label>
          <select
            id="recordsPerPage"
            className="form-select ms-1"
            style={{ maxWidth: 'fit-content' }}
            defaultValue={reqParams.pageSize}
            onChange={e => {
              setReqParams({
                ...reqParams,
                pageIndex: 0,
                pageSize: e.target.value,
              })
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="100">200</option>
            <option value="100">300</option>
            <option value="100">400</option>
            <option value="100">500</option>
            <option value="100">1000</option>
          </select>
        </div>
        <div className="d-flex align-items-center col-2">
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="BatchEntryStatus"
            value={reqParams?.status}
            onChange={e =>
              setReqParams({
                ...reqParams,
                status: e.value,
              })
            }
          />
        </div>
        <form
          className="d-flex align-items-center col-8 col"
          onSubmit={e => {
            e.preventDefault()
            setReqParams({
              ...reqParams,
              filterText: search,
            })
          }}
        >
          <label className="ms-2 me-2 text-capitalize" htmlFor="searchTable">
            {localize('search')}
          </label>
          <EnumsCombo
            enums={CustomerFilter}
            onChange={e =>
              setReqParams({
                ...reqParams,
                customerFilter: e.value,
              })
            }
            value={reqParams?.customerFilter || 0}
          />
          <input
            type="search"
            id="searchTable"
            className="form-control ms-2"
            defaultValue={reqParams.filterText}
            onChange={e => {
              setSearch(e.target.value)
              setReqParams({
                ...reqParams,
                filterText: e.target.value,
              })
            }}
          />
          <button
            id="bankToBankRequestFilterButton"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              loadLoanAgingSpoolEntries()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </form>
      </div>
      <SimpleTable
        columns={columns}
        data={entries.pageCollection}
        selectables
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={async () => {
                if (r.status != 2) {
                  return Swal.fire(
                    'Ooops!',
                    `Sorry, but a ${r.statusDescription} can not be reset`,
                    'info'
                  )
                } else {
                  resetLoanSpoolEntry(r)
                }
              }}
            >
              <i className="uil uil-eye"></i>
              {localize('reset')}
            </div>
          </>
        )}
      />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
export default LoanAgingSpoolEntries
