/**
 * eslint-disable
 *
 * @format
 */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
function DepartmentLookup({ onSelect, displayValue, disabled }) {
  return (
    <SimpleLookup
      disabled={disabled}
      title={localize('departmentLookup')}
      url="/lookup/Department/find-department-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('isRegistry') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isRegistry.toString())}
            </span>
          ),
          sortBy: 'isRegistry',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default DepartmentLookup
