/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import AccruedInterestEntries from '../../Partials/AccruedInterestEntries'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { batchAuthOptions } from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function VerifyAccruedInterestDataModal({
  mode,
  selectedAccruedInterest,
  close,
  setSelectedAccruedInterest,
  loadData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(batchAuthOptions)[0]
  )
  function closeModal() {
    close()
    setSelectedAccruedInterest(null)
    setGlobalValidationErrors({})
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await putData(
      '/AccruedInterestBatch/audit-accrued-interest-batch',
      {
        accruedInterestBatch: {
          ...selectedAccruedInterest,
        },
        batchAuthOption: selectedAuth,
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      loadData()
      closeModal()
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="accruedInterestBatches"
        handleClose={closeModal}
        modalClassName="modal-xl"
        handleAction={handleSubmit}
        actionText={mode === 'view' ? 'refresh' : ''}
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('apportioned')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={apportionedAmount || ''}
              />
            </div>
            <div className="col-md-3 mt-3">
              <div
                className="d-flex align-items-center mb-1 with-validation"
                validation-message={
                  globalValidationErrors['AccruedInterestBatch.AuditRemarks']
                    ?.message
                }
              >
                <label className="text-capitalize col-2 me-2">
                  {localize('remarks')}
                </label>
                <input
                  defaultValue={selectedAccruedInterest?.auditRemarks}
                  type="text"
                  className="form-control ms-4"
                  onChange={e => {
                    clearValidation('AccruedInterestBatch.AuditRemarks')
                    setSelectedAccruedInterest({
                      ...selectedAccruedInterest,
                      auditRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <div className="d-flex align-items-center mb-2">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchAuthOptions"
                  onChange={e => setSelectedAuth(e.value)}
                />
              </div>
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('batch#')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.paddedBatchNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('referenceMonth')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.monthDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={formatDate(selectedAccruedInterest?.valueDate) || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('monthlyBalanceSpool')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedAccruedInterest?.customerAccountBalanceSpoolReference ||
                ''
              }
            />
          </div>
          <div className="col-8 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control ms-1"
              disabled
              value={
                selectedAccruedInterest?.customerAccountBalanceSpoolReference ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.createdBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.createdDate || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('priority')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.priorityDescription || ''}
            />
          </div>
        </div>

        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <AccruedInterestEntries
                    selectedAccruedInterest={selectedAccruedInterest}
                    setApportioned={setApportionedAmount}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default VerifyAccruedInterestDataModal
