/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import ViewInventory from './Partials/ViewInventory'
import EditInventory from './Partials/EditInventory'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

const InventoryCatalogue = () => {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('package'),
      template: r => <>{r.itemRegisterInventoryPackageTypeDescription}</>,
    },
    {
      label: localize('baseUom'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('supplier'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{formatCurrency(r.quantity)}</>,
    },
    {
      label: localize('reorderPoint'),
      template: r => <>{r.itemRegisterReorderPoint}</>,
    },
    {
      label: localize('maximumOrder'),
      template: r => <>{r.itemRegisterMaximumOrder}</>,
    },
    {
      label: localize('unitsPerPack'),
      template: r => <>{r.itemRegisterUnitsPerPack}</>,
    },
    {
      label: localize('palletTi'),
      template: r => <>{r.itemRegisterPalletTI}</>,
    },
    {
      label: localize('palletHi'),
      template: r => <>{r.itemRegisterPalletHI}</>,
    },
    {
      label: localize('unitNetWeight'),
      template: r => <>{r.itemRegisterUnitNetWeight}</>,
    },
    {
      label: localize('unitGrossWeight'),
      template: r => <>{r.itemRegisterUnitGrossWeight}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{formatCurrency(r.itemRegisterEstimatedUnitCost)}</>,
    },
    {
      label: localize('unitPrice'),
      template: r => <>{formatCurrency(r.unitPrice)}</>,
    },
    {
      label: localize('leadDays'),
      template: r => <>{r.itemRegisterLeadDays}</>,
    },
    {
      label: localize('economicOrder'),
      template: r => <>{r.itemRegisterEconomicOrder}</>,
    },
    {
      label: localize('monthlyDemand'),
      template: r => <>{r.itemRegisterMonthlyDemand}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [mode, setMode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    showBelowReorderLevel: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [inventory, setInventory] = useState(null)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/Inventory/find-inventories-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('inventory'),
          localize('catalogue'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row d-flex justify-content-between">
                    <RecordsPerPage
                      className="col-2"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-md-3">
                      <div className="d-flex align-items-center  me-2">
                        <Checkbox
                          id="inventoryShowBelowReorderLevel"
                          checked={reqParams.showBelowReorderLevel}
                          onCheck={value => {
                            setReqParams({
                              ...reqParams,
                              showBelowReorderLevel: value,
                            })
                          }}
                        />
                        <label
                          htmlFor="inventoryShowBelowReorderLevel"
                          className="text-capitalize me-2"
                        >
                          {localize('showBelowReorderLevel')}
                        </label>
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-md-3"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="ChequeWritingCataloguesFilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('view')
                              setInventory(r)
                            }}
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setInventory(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'view' ? (
        <ViewInventory
          inventory={inventory}
          mode={mode}
          closeModal={() => {
            setMode(null)
            setInventory(null)
          }}
        />
      ) : (
        ''
      )}
      {mode === 'edit' ? (
        <EditInventory
          inventory={inventory}
          setInventory={setInventory}
          mode={mode}
          closeModal={() => {
            setMode(null)
            setInventory(null)
          }}
          loadData={loadTableData}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default InventoryCatalogue
