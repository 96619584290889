/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import Pager from '../../../../Components/Tables/Pager'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import CreateInventoryCategories from './Partials/CreateInventoryCategories'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

const InventoryCategories = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyFetching, setIsBusyFetching] = useState(false)
  const [inventorySupplies, setInventorySupplies] = useState([])
  const [categories, setCategories] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    traverseTree: false,
    updateDepth: false,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    getInventoryCategoriesTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  const getInventoryCategoriesTableData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/InventoryCategory/find-inventory-categories-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setInventorySupplies(data)
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('name'),
      template: r => (
        <div
          className="p-0"
          dangerouslySetInnerHTML={{ __html: r?.indentedInventoryCategoryName }}
        />
      ),
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },

    {
      label: localize('isLocked') + '?',
      template: r => <>{r?.isLocked.toString()}</>,
    },
  ]
  const handleCreateOrUpdate = async () => {
    if (
      !categories?.chartOfAccountName ||
      !categories?.expenseChartOfAccountName ||
      (modalMode === 'add'
        ? !categories?.parentDescription
        : !categories.description)
    ) {
      GlobalValidationErrors.set({
        glAccount: !categories?.chartOfAccountName && {
          message: 'The G/L Account identifier is invalid!',
        },
        expenseGl: !categories?.expenseChartOfAccountName && {
          message: 'The Expense G/L Account identifier is invalid!',
        },
        parentDescription: (modalMode === 'add'
          ? !categories?.parentDescription
          : !categories?.description) && {
          message: 'The Name field is required.',
        },
      })
      return
    }

    setRefresh(false)
    const url =
      modalMode === 'add'
        ? '/InventoryCategory/add-inventory-category'
        : '/InventoryCategory/update-inventory-category'

    if (modalMode === 'add') {
      setIsBusyFetching(true)
      const { success } = await postData(
        url,
        categories,
        false,
        'Operation successful'
      )

      if (success) {
        setRefresh(true)
        setIsBusyFetching(false)
        handleClose()
        showNotification('Inventory Category', 'success')
      }

      setIsBusyFetching(false)
    } else if (modalMode === 'edit') {
      setIsBusyFetching(true)

      const { success } = await putData(
        url,
        categories,
        false,
        'Operation successful'
      )
      if (success) {
        setRefresh(true)
        handleClose()
        setIsBusyFetching(false)
        showNotification('Inventory Category', 'success')
      }

      setIsBusyFetching(false)
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  const clearValidationErrors = () => {
    clearValidation('parentDescription')
    clearValidation('description')
    clearValidation('expenseGl')
    clearValidation('glAccount')
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('inventoryCategories'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateInventoryCategories
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          categories={categories}
          setCategories={setCategories}
          refresh={refresh}
          setRefresh={setRefresh}
          isBusyFetching={isBusyFetching}
          globalValidationErrors={globalValidationErrors}
          clearValidation={clearValidation}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start mb-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                  setCategories({})
                  clearValidationErrors()
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3 d-flex justify-content-between">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col d-flex align-items-center justify-content-center">
                  <Checkbox
                    id="traverseTree"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                  <label
                    htmlFor="traverseTree"
                    className="col-4 text-capitalize ms-2"
                  >
                    {localize('traverseTree') + '?'}
                  </label>
                </div>
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <input
                        type="search"
                        className="form-control ms-2"
                        defaultValue={reqParams?.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={getInventoryCategoriesTableData}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={inventorySupplies?.pageCollection}
                  contextMenu={x => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setModalMode('edit')
                          setCategories(x)
                          clearValidationErrors()
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(x)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={inventorySupplies?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InventoryCategories
