/** @format */
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from '../Date/DatePicker'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import Loader from '../Loaders/Loader'

export const EnrollmentRequestLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('origin'),
      template: r => <>{r.originDescription}</>,
    },
    {
      label: localize('originIdentifier'),
      template: r => <>{r.originIdentifier}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassCustomerTypeDescription}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    {
      label: localize('individualType'),
      template: r => <>{r.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.individualIdentityCardNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.individualNationalityDescription}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r.individualBirthDate)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r.employmentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{r.individualEmploymentDate}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r.individualEmploymentDesignation}</>,
    },
    {
      label: localize('termsOfService'),
      template: r => <>{r.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: localize('disabilityType'),
      template: r => <>{r.individualDisabilityTypeDescription}</>,
    },
    {
      label: localize('hudumaNumber'),
      template: r => <>{r.individualHudumaNumber}</>,
    },
    {
      label: localize('nonIndividualDescription'),
      template: r => <>{r.nonIndividualDescription}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('dateEstablished'),
      template: r => <>{formatDate(r.nonIndividualDateEstablished)}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('monthlyContribution'),
      template: r => <>{r.monthlyContribution}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r.electoralZoneDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r.individualRetirementAge}</>,
    },
    {
      label: localize('administrativeDivision'),
      template: r => <>{r.administrativeDivisionDescription}</>,
    },
    { label: localize('age'), template: r => <>{r.age}</> },
    {
      label: localize('enrollmentDate'),
      template: r => <>{formatDate(r.enrollmentDate)}</>,
    },
    { label: localize('recruitedBy'), template: r => <>{r.recruitedBy}</> },
    {
      label: localize('payrollNumbers'),
      template: r => <>{r.payrollNumbers}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r.personalIdentificationNumber}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('auditRemarks'), template: r => <>{r.auditRemarks}</> },
    { label: localize('auditedBy'), template: r => <>{r.auditedBy}</> },
    {
      label: localize('auditedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorizationRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    { label: localize('authorizedBy'), template: r => <>{r.authorizedBy}</> },
    {
      label: localize('authorizedDate'),
      template: r => <>{r.authorizedDate}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageCount, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/EnrollmentRequest/find-enrollment-request-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('enrollmentRequestLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center float-right my-2 col-3">
                    <label
                      className="text-capitalize"
                      htmlFor="LoanProductLookupPageSize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="LoanProductLookupPageSize"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageCount}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageCount: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <label htmlFor="search" className="me-1 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                            pageIndex: 0,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                            pageIndex: 0,
                          })
                        }
                      }}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center"></div>

                  <form
                    className="d-flex align-items-center col-3 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          loadData()
                        }
                      }}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="customLookupFilterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
