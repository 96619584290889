/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatCurrency } from '../../Helpers/formatters'

const TreasuryLookup = ({ disabled, displayValue, onSelect }) => {
  const columns = [
    { label: localize('code'), template: r => r?.paddedCode },
    { label: localize('name'), template: r => r?.description },
    {
      label: localize('lowerLimit'),
      template: r => formatCurrency(r?.rangeLowerLimit),
    },
    {
      label: localize('upperLimit'),
      template: r => formatCurrency(r?.rangeUpperLimit),
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('gLAccountName'),
      template: r => r?.chartOfAccountName,
    },
    {
      label: localize('gLAccountCostDistributionRule'),
      template: r => r?.chartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r?.isLocked?.toString(),
    },
  ]

  return (
    <>
      <SimpleLookup
        disabled={disabled}
        url="/Treasury/find-treasuries-by-filter-in-page"
        params={{
          pageIndex: 0,
          text: '',
          pageSize: 10,
          includeBalances: true,
        }}
        columns={columns}
        title={localize('treasuryLookup')}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}

export default TreasuryLookup
