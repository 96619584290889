/** @format */

import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataLoanProductExemptions = ({ loading, data }) => {
  const columns = [
    {
      label: localize('exemption'),
      template: r => <>{r.loanProductExemptionDescription}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductExemptionLoanProductDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={data} columns={columns} />
      )}
    </>
  )
}
