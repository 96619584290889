/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Pager from '../../../../../../../Components/Tables/Pager'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { postData, putData } from '../../../../../../../Helpers/webApi'
import DatePicker from '../../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import { downloadBase64File } from '../../../../../../../Helpers/blobs'
import Swal from 'sweetalert2'
import { COLORS } from '../../../../../../../Helpers/constants'
import signalRService from '../../../../../../../Helpers/signalR'

export const AccountMetadataStatement = ({
  selectedCustomerAccount,
  onChargeForPrinting,
  selectedCustomer,
  selectedPrintingCustomerAccount,
}) => {
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    text: '',
    journalEntryFilter: '2',
    tallyDebitsCredits: false,
    customDate: false,
    dateRequestsFilter: 15,
    fetchJournalEntries: true,
  })
  const [search, setSearch] = useState('')
  const [finishedPrinting, setFinishedPrinting] = useState(true)

  const [customerAccountTransactions, setCustomerAccountTransactions] =
    useState(null)

  const [loadingCustomerStatements, setLoadingCustomerStatements] =
    useState(false)

  const [journalEntriesModal, setJournalEntriesModal] = useState(null)

  const [chargeForPrinting, setChargeForPrinting] = useState(false)
  const [finishedEmailing, setFinishedEmailing] = useState(false)

  const [
    generalLedgerTransactionsJournalEntries,
    setGeneralLedgerTransactionsJournalEntries,
  ] = useState([])

  useEffect(() => {
    if (selectedCustomerAccount) {
      fetchCustomerAccountStatementTransactions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCustomerAccount,
    reqParams.startDate,
    reqParams.endDate,
    reqParams.pageSize,
    reqParams.pageIndex,
    reqParams.journalEntryFilter,
    reqParams.fetchJournalEntries,
    reqParams.tallyDebitsCredits,
  ])

  const handleOnViewJournalEntries = generalLedgerTransaction => {
    setGeneralLedgerTransactionsJournalEntries(
      generalLedgerTransaction.journalEntries || []
    )
    setReqParams({ ...reqParams, fetchJournalEntries: true })
    setJournalEntriesModal('view')
  }

  const fetchCustomerAccountStatementTransactions = async () => {
    setLoadingCustomerStatements(true)
    const { success, data } = await postData(
      `/JournalEntry/find-general-ledger-transactions-by-customer-account-id-and-date-range-in-page`,
      {
        PageIndex: reqParams.pageIndex,
        PageSize: reqParams.pageSize,
        CustomerAccountDTO: { ...selectedCustomerAccount },
        StartDate: reqParams.customDate ? reqParams.startDate : new Date(),
        EndDate: reqParams.customDate ? reqParams.endDate : new Date(),
        Text: reqParams.text,
        JournalEntryFilter: reqParams.journalEntryFilter,
        TallyDebitsCredits: reqParams.tallyDebitsCredits,
        CustomDate: reqParams.customDate,
        DateRequestsFilter: reqParams.dateRequestsFilter,
        FetchJournalEntries: reqParams.fetchJournalEntries,
      },
      false,
      null
    )
    if (success) {
      setLoadingCustomerStatements(false)
      setCustomerAccountTransactions(data)
    }
  }

  const columns = [
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: localize('debit'), template: r => <>{formatCurrency(r.debit)}</> },
    {
      label: localize('credit'),
      template: r => <>{formatCurrency(r.credit)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.journalValueDate)}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.journalReference}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('localCurrency'),
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.formattedCustomerAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerReference3}</>,
    },
  ]

  const handleOnPrint = async () => {
    setFinishedPrinting(false)
    if (!selectedPrintingCustomerAccount && chargeForPrinting) {
      Swal.fire({
        icon: 'error',
        text: 'Select a customer account to be charged',
        title: 'Printing Customer Account Statement',
        showCloseButton: true,
      })
      setFinishedPrinting(true)
      return
    }

    const { success, data } = await postData(
      '/CustomerAccount/print-customer-account-statement',
      {
        selectedPrintingCustomerAccount:
          selectedPrintingCustomerAccount || selectedCustomerAccount,
        selectedCustomerAccount: selectedCustomerAccount,
        selectedCustomer: selectedCustomer,
        chargeForPrinting: chargeForPrinting,
        startDate: reqParams.customDate ? reqParams.startDate : new Date(),
        endDate: reqParams.customDate ? reqParams.endDate : new Date(),
        customDate: reqParams.customDate,
        dateRequestsFilter: reqParams.dateRequestsFilter,
      },
      false,
      null
    )
    if (success) {
      setFinishedPrinting(true)
      downloadBase64File(data.content, data.fileType)
    }
  }

  const handleOnEmail = () => {
    Swal.fire({
      title: 'Email Statement',
      text: 'Do you want to e-mail statement?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: COLORS.BLUE,
      cancelButtonColor: COLORS.RED,
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.isConfirmed) {
        sendEmail()
        setFinishedEmailing(true)
        showSuccessModal()
      }
    })
  }

  const showSuccessModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Operation Successful',
      text: 'Sending E-mail Statement',
    })
  }
  const sendEmail = async () => {
    setFinishedEmailing(false)
    if (!selectedCustomerAccount) {
      Swal.fire({
        icon: 'error',
        text: 'Select a customer account',
        title: 'Emailing Customer Account Statement',
        showCloseButton: true,
      })
      setFinishedEmailing(true)
      return
    }

    await putData(
      '/lookup/Utility/execute-on-demand-electronic-statement-order',
      {
        CustomerAccountDTOs: [selectedCustomerAccount],
        startDate: reqParams.customDate ? reqParams.startDate : new Date(),
        endDate: reqParams.customDate ? reqParams.endDate : new Date(),
      },
      false,
      null
    )
  }
  return (
    <>
      <div className="row p-2">
        <div className="bg-light p-2 m-1 mb-2" style={{ borderRadius: 5 }}>
          {finishedPrinting ? (
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('pickDate')}
              </label>
              <DatePicker
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
              <EnumsServerCombo
                value={reqParams?.journalEntryFilter || ''}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'JournalEntryFilter'}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    journalEntryFilter: e.value,
                    pageIndex: 0,
                  })
                }}
              />
              <form
                className="d-flex align-items-center col-3 col"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({ ...reqParams, text: search })
                }}
              >
                <input
                  style={{ width: 200 }}
                  type="search"
                  className="form-control"
                  placeholder={localize('search') + '...'}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchCustomerAccountStatementTransactions()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </form>
              <>
                <button onClick={handleOnPrint} className="btn btn-primary">
                  {localize('print')}
                </button>
                <button onClick={handleOnEmail} className="btn btn-primary">
                  {localize('email')}
                </button>
              </>
            </div>
          ) : (
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="m-3 spinner-border text-dark"></div>
              <span className="fs-4">Please Wait</span>
            </div>
          )}
        </div>
        {loadingCustomerStatements ? (
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait</span>
          </div>
        ) : (
          <SimpleTable
            contextMenu={e => (
              <>
                <div
                  onClick={() => {
                    handleOnViewJournalEntries(e)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-window"></i>
                  <span className="text-capitalize">
                    {localize('view')} {localize('journal')}{' '}
                    {localize('entries')}
                  </span>
                </div>
              </>
            )}
            selectables
            columns={columns}
            data={customerAccountTransactions?.pageCollection}
          />
        )}
      </div>

      <div className="row bg-light p-2 m-1 mb-2" style={{ borderRadius: 5 }}>
        <div className="py-1 d-flex gap-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <label className="text-capitalize" style={{ width: 'fit-content' }}>
              {localize('recordsPerPage')}
            </label>
            <select
              className="form-select"
              style={{ width: 'fit-content' }}
              value={reqParams.pageSize}
              onChange={e =>
                setReqParams({ ...reqParams, pageSize: e.target.value })
              }
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              id="tallyDCGLTr"
              checked={reqParams?.tallyDebitsCredits}
              onCheck={v => {
                setReqParams({ ...reqParams, tallyDebitsCredits: v })
              }}
            />
            <label
              htmlFor="tallyDCGLTr"
              className={`text-capitalize ${
                reqParams?.tallyDebitsCredits ? 'text-primary' : ''
              }`}
            >
              {localize('tallyDebitCredits')}
            </label>
          </div>

          <div className="d-flex align-items-center gap-2">
            <Checkbox
              id="chargeForPrinting"
              checked={chargeForPrinting}
              onCheck={v => {
                setChargeForPrinting(v)
                onChargeForPrinting(v)
              }}
            />
            <label
              htmlFor="chargeForPrinting"
              className={`text-capitalize ${
                chargeForPrinting ? 'text-primary' : ''
              }`}
            >
              {localize('chargeForPrinting')}?
            </label>
          </div>

          <div className="flex-1"></div>
          <Pager
            itemsCount={customerAccountTransactions?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={5}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>

        <div
          className="py-3 px-4 mt-2 ms-1 row"
          style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
        >
          <div
            className="bg-light row px-2 text-capitalize"
            style={{
              position: 'absolute',
              top: -12,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('accountSummary')}
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize">
              {localize('balanceBF')}
            </label>
            <input
              type="text"
              disabled
              className="form-control ms-2 text-end"
              value={formatCurrency(
                customerAccountTransactions?.balanceBroughtFoward,
                null,
                null,
                false
              )}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize">
              {localize('debits')}
            </label>
            <input
              type="text"
              disabled
              className="form-control ms-2 text-end"
              value={formatCurrency(
                customerAccountTransactions?.totalDebits,
                null,
                null,
                false
              )}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize">
              {localize('credits')}
            </label>
            <input
              type="text"
              disabled
              className="form-control ms-2 text-end"
              value={formatCurrency(
                customerAccountTransactions?.totalCredits,
                null,
                null,
                false
              )}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize">
              {localize('balanceCF')}
            </label>
            <input
              type="text"
              disabled
              className="form-control ms-2 text-end"
              value={formatCurrency(
                customerAccountTransactions?.balanceCarriedForward,
                null,
                null,
                false
              )}
            />
          </div>

          {journalEntriesModal && (
            <ModalTemplate
              hideUpdate
              modalMode={journalEntriesModal}
              modalTitle={`${localize('journalEntries')} - ${
                selectedCustomerAccount?.accountName
              } - ${selectedCustomerAccount?.fullAccountNumber}`}
              handleClose={() => {
                setJournalEntriesModal(null)
              }}
              modalClassName={'modal-xl'}
            >
              <CustomerAccountJournalEntries
                journalEntries={generalLedgerTransactionsJournalEntries}
              />
            </ModalTemplate>
          )}
        </div>
      </div>
    </>
  )
}

function CustomerAccountJournalEntries({ journalEntries }) {
  const columns = [
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: 'amount',
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    { label: 'chartOfAccountName', template: r => <>{r.chartOfAccountName}</> },
    { label: 'valueDate', template: r => <>{formatDate(r.valueDate, true)}</> },
    { label: 'remarks', template: r => <>{r.remarks}</> },
    {
      label: 'fullAccountNumber',
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: 'customerName',
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: 'memberNumber',
      template: r => <>{r.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: 'accountNumber',
      template: r => <>{r.paddedCustomerAccountCustomerAccountNumber}</>,
    },
    {
      label: 'personalFileNumber',
      template: r => <>{r.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    { label: 'costCenter', template: r => <>{r.costCenterDescription}</> },
    {
      label: 'costDistributionRule',
      template: r => <>{r.costDistributionRule}</>,
    },
    {
      label: 'costDistributionPercentage',
      template: r => <>{r.costDistributionPercentage}</>,
    },
    { label: 'createdBy', template: r => <>{r.createdBy}</> },
    {
      label: 'integrityHash',
      template: r => <>{r.costDistributionPercentage}</>,
    },
  ]
  return (
    <>
      <SimpleTable columns={columns} data={journalEntries} />
    </>
  )
}
