/** @format */

import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import Tab from '../../../../../../../Components/Tabs/Tab'
/* eslint-disable */
import React from 'react'
import { JournalVoucherAuthorizationEntriesAuthorize } from './JournalVoucherAuthorizationEntries'

export const AuthorizeJournalVoucherModal = ({
  selectedJournalVoucher,
  setVerificationModels,
  verificationModels,
  validationErrors,
  clearValidation,
  handleVerifyJournalVoucher,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('voucher')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.paddedVoucherNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('postingPeriod')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.postingPeriodDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chartOfAccountName')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.chartOfAccountName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('costDistributionRule')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedJournalVoucher?.chartOfAccountCostDistributionRuleDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('costCenter')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.costCenterDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.customerAccountFullAccountNumber}
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.customerAccountCustomerFullName}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('status')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.statusDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('voucherType')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.typeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('valueDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedJournalVoucher?.valueDate)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('totalValue')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  selectedJournalVoucher?.totalValue,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('primaryDescription')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.primaryDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('secondaryDescription')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.secondaryDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('reference')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.reference}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedJournalVoucher?.createdDate)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.auditedBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedJournalVoucher?.auditedDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verificationRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.auditRemarks}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.authorizedBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedJournalVoucher?.authorizedDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizationRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedJournalVoucher?.authorizationRemarks}
              />
            </div>
          </div>

          <Tab
            tabItems={[
              {
                label: localize('voucherEntries'),
                item: (
                  <JournalVoucherAuthorizationEntriesAuthorize
                    validationErrors={validationErrors}
                    clearValidation={clearValidation}
                    setVerificationModels={setVerificationModels}
                    verificationModels={verificationModels}
                    journalVoucherId={selectedJournalVoucher?.id}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
