/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { identityCardType } from '../../../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

const CreateIdentityVerification = ({
  mode,
  handleClose,
  handleAction,
  identityVerification,
  setIdentityVerification,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      modalTitle={
        mode === 'add'
          ? localize('createIdentityVerification')
          : localize('viewIdentityVerification')
      }
    >
      <div className="row">
        <div className="col-6 d-flex align-items-center">
          <div className="d-flex">
            <label className="text-capitalize" htmlFor="identityType">
              {localize('identityType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="IdentityCardType"
              value={identityVerification?.identityTypeDescription}
              onChange={e =>
                setIdentityVerification({
                  ...identityVerification,
                  identityType: e.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-6 d-flex align-items-center">
          <div className="d-flex">
            <label className="text-capitalize" htmlFor="identityNumber">
              {localize('identityNumber')}
            </label>
            <input
              id="identityNumber"
              className="form-control ms-3"
              type="text"
              onChange={e => {
                setIdentityVerification({
                  ...identityVerification,
                  identityNumber: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default CreateIdentityVerification
