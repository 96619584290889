/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import ListBatchAuthorization from '../../../../../Components/ReUsables/BatchAuthorization/ListBatchAuthorization'
import localize from '../../../../../Global/Localize'
import {
  QueuePriority,
  withdrawalNotificationCategory,
} from '../../../../../Global/enumeration'
import ViewBatchModal from '../Partials/ViewBatchModal/ViewBatchModal'
import AuthorizeBatchModal from './AuthorizeBatchModal/AuthorizationBatchModal'
import { batchStatus } from '../../../../../Helpers/constants'

function BatchAuthorization() {
  const [modalMode, setModalMode] = useState(null)
  const [batchOrigination, setBatchAuthorization] = useState({
    category: Object.keys(withdrawalNotificationCategory)[1],
    priority: Object.keys(QueuePriority)[3],
  })
  const [refresh, setRefresh] = useState(false)

  const handleClose = () => {
    setModalMode(null)
    setBatchAuthorization(null)
  }

  const handleBatchSelect = (batch, mode) => {
    setModalMode(mode)
    setBatchAuthorization(batch)
  }

  return (
    <>
      {modalMode === 'authorize' && (
        <AuthorizeBatchModal
          mode={modalMode}
          handleClose={handleClose}
          batchOrigination={batchOrigination}
          reload={value => {
            setRefresh(value)
          }}
        />
      )}
      {modalMode === 'view' && (
        <ViewBatchModal
          mode={modalMode}
          batchOrigination={batchOrigination}
          handleClose={handleClose}
          title="batchAuthorization"
        />
      )}
      <BreadCrumb
        tree={[localize('settlement'), localize('batchAuthorization')]}
      />
      <ListBatchAuthorization
        handleBatchSelect={handleBatchSelect}
        showAddButton
        showContextMenu
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default BatchAuthorization
