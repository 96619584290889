/** @format */

import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Tab from '../../../../Components/Tabs/Tab'
import { AuthorizedBranches } from './Partials/AuthorizedBranches'
import RoleLookup from '../../../../Components/Lookup/RoleLookup'
import { getData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import { userNameProvider } from '../../../../Helpers/constants'
import { ActiveDirectoryLookup } from '../../../../Components/Lookup/ActiveDirectoryLookup'
import Loader from '../../../../Components/Loaders/Loader'

export const AddUserModal = ({
  selectedUser,
  setSelectedUser,
  waitingMetadata,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnSelectEmployee = async r => {
    clearValidation('UserDto.EmployeeId')
    clearValidation('UserDto.EmployeeCustomerAddressEmail')
    const { success } = await getData('/User/find-user-by-employee-id', {
      employeeId: r?.id,
    })
    if (success) {
      setSelectedUser({
        ...selectedUser,
        employeeId: r.id,
        customerFullName: r.customerFullName,
        employeeCustomerAddressEmail: r.customerAddressEmail,
        employeeBranchDescription: r.branchDescription,
        employeeDepartmentDescription: r.departmentDescription,
        employeeDesignationDescription: r.designationDescription,
      })
    }
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              validation-message={
                validationBag?.['UserDto.EmployeeId']?.message ||
                validationBag?.['UserDto.EmployeeCustomerAddressEmail']?.message
              }
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('employee')}
              </label>
              <EmployeeLookup
                displayValue={selectedUser?.customerFullName || ''}
                onSelect={handleOnSelectEmployee}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeBranchDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('usernameProvider')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="UserNameProvider"
                value={selectedUser?.usernameProvider}
                onChange={e => {
                  setSelectedUser({
                    ...selectedUser,
                    usernameProvider: e.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDesignationDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDepartmentDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('emailAddress')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeCustomerAddressEmail || ''}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col-4 d-flex align-items-center with-validation"
              validation-message={validationBag?.['UserDto.RoleId']?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('role')}
              </label>
              <RoleLookup
                displayValue={selectedUser?.roleDescription || ''}
                onSelect={r => {
                  clearValidation('UserDto.RoleId')
                  setSelectedUser({
                    ...selectedUser,
                    roleId: r.id,
                    roleDescription: r.description,
                  })
                }}
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('userName')}
              </label>
              {selectedUser?.usernameProvider ===
              userNameProvider.USER_DEFINED ? (
                <input
                  className="form-control"
                  value={selectedUser?.description || ''}
                  onChange={e => {
                    clearValidation('UserDto.Description')
                    setSelectedUser({
                      ...selectedUser,
                      description: e.target.value,
                    })
                  }}
                />
              ) : (
                <ActiveDirectoryLookup />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('authorizedBranches'),
                    item: (
                      <AuthorizedBranches
                        isBusy={waitingMetadata}
                        prefix={'auth-create-branch'}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
