/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { getData, postData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import CustomerAccountPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import Swal from 'sweetalert2'
import Tab from '../../../../../Components/Tabs/Tab'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import {
  RoundingType,
  interestCalculationMode,
  interestChargeMode,
  interestRecoveryMode,
  paymentFrequencyPerYear,
  standingOrderTrigger,
} from '../../../../../Global/enumeration'
import LoanPurposeLookup from '../../../../../Components/Lookup/LoanPurposeLookup'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { getTodaysDate } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const LoanCaseLinkage = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [iLoanCaseLinkage, setILoanCaseLinkage] = useState({
    loanInterestCalculationMode: Object.keys(interestCalculationMode)[0],
    loanRegistrationPaymentFrequencyPerYear: Object.keys(
      paymentFrequencyPerYear
    )[5],
    loanInterestChargeMode: Object.keys(interestChargeMode)[1],
    loanRegistrationStandingOrderTrigger: Object.keys(standingOrderTrigger)[0],
    loanRegistrationRoundingType: Object.keys(RoundingType)[0],
    disbursedDate: getTodaysDate(),
  })

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [loanProduct, setLoanProduct] = useState(null)

  const handleOnSelectAccount = async account => {
    setIsBusy(true)
    if (!account.isLocked) {
      if (account.customerAccountTypeProductCode !== '2') {
        return Swal.fire({
          icon: 'info',
          text: 'Select a Loan Account',
          title: 'Ooops!',
          showCloseButton: true,
        })
      } else if (account.loanCaseId) {
        return Swal.fire({
          icon: 'info',
          text: 'Selected Account is Already Linked With Loan Case ',
          title: 'Ooops!',
          showCloseButton: true,
        })
      } else {
        const { success, data } = await getData(
          `/LoanProduct/find-loan-product-by-id?loanProductId=${account.customerAccountTypeTargetProductId}`,
          false
        )
        if (success) {
          setIsBusy(false)
          setIsBusy(false)
          setLoanProduct(data)
        } else {
          setIsBusy(false)
        }
        setSelectedAccount({ ...selectedAccount, ...account })
      }
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedAccount(null)
    }
  }

  async function updateLoanCaseLinkage() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/LoanCase/update-loan-case-linkage',
      {
        customerAccountId: selectedAccount.id,
        loanCase: {
          ...iLoanCaseLinkage,
          ...loanProduct.loanProduct,
          loanProductId: loanProduct.loanProduct.id,
        },
      },
      false
    )

    if (success) {
      setIsBusy(false)
      Swal.fire({
        icon: 'success',
        title: 'Operation Completed Successfully',
        html: `Serial Number : ${data.serialNumber},  
         <br/>
         Loan Number : ${data.documentReferenceNumber}`,
      })
      setILoanCaseLinkage({
        loanInterestCalculationMode: Object.keys(interestCalculationMode)[0],
        loanRegistrationPaymentFrequencyPerYear: Object.keys(
          paymentFrequencyPerYear
        )[5],
        loanInterestChargeMode: Object.keys(interestChargeMode)[1],
        loanRegistrationStandingOrderTrigger:
          Object.keys(standingOrderTrigger)[0],
        loanRegistrationRoundingType: Object.keys(RoundingType)[0],
      })
      setLoanProduct(null)
      setSelectedAccount(null)
      setGlobalValidationErrors({})
    } else {
      setIsBusy(false)
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('backOfficeManagement'), localize('loanCaseLinkage')]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <CustomerAccountPartial
                  selectedAccount={selectedAccount}
                  onCustomerAccountLookup={r => {
                    handleOnSelectAccount(r)
                    setILoanCaseLinkage({
                      loanRegistrationTermInMonths:
                        r.loanCaseLoanRegistrationTermInMonths,
                      loanInterestAnnualPercentageRate:
                        r.loanCaseLoanInterestAnnualPercentageRate,
                      loanRegistrationGracePeriod:
                        r.loanCaseLoanRegistrationGracePeriod,
                      loanInterestCalculationMode:
                        selectedAccount?.loanCaseLoanInterestCalculationMode,
                      loanRegistrationPaymentFrequencyPerYear:
                        selectedAccount?.loanCaseLoanRegistrationPaymentFrequencyPerYear,
                      loanInterestChargeMode:
                        selectedAccount?.customerAccountTypeTargetProductLoanInterestChargeMode,
                      loanRegistrationStandingOrderTrigger:
                        r.standingOrderTrigger,
                      loanRegistrationRoundingType:
                        r.loanCaseLoanRegistrationRoundingType,
                      branchId: r.branchId,
                      customerId: r.customerId,
                    })
                    if (
                      r.customerAccountTypeTargetProductLoanInterestChargeMode ==
                      768
                    ) {
                      setILoanCaseLinkage({
                        loanInterestRecoveryMode:
                          Object.values(interestRecoveryMode)[0],
                      })
                    }
                  }}
                />

                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('loanRegistrationDetails'),
                        item: (
                          <>
                            <div className="row mb-2">
                              <div
                                className="col d-flex align-items-center with-validation"
                                validation-message={
                                  globalValidationErrors[
                                    'LoanCase.DisbursedAmount'
                                  ]?.message
                                }
                              >
                                <label className="col-4 text-capitalize">
                                  {localize('disbursedAmount')}
                                </label>
                                <MaskNumber
                                  className="form-control"
                                  defaultMaskValue={
                                    iLoanCaseLinkage?.disbursedAmount
                                  }
                                  onMaskNumber={e => {
                                    clearValidation('LoanCase.DisbursedAmount')
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      disbursedAmount: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                              <div
                                className="col d-flex align-items-center with-validation"
                                validation-message={
                                  globalValidationErrors[
                                    'LoanCase.LoanRegistrationTermInMonths'
                                  ]?.message
                                }
                              >
                                <label className="col-4 text-capitalize">
                                  {localize('term(Months)')}
                                </label>
                                <input
                                  style={{ textAlign: 'right' }}
                                  className="form-control ms-2"
                                  value={
                                    iLoanCaseLinkage?.loanRegistrationTermInMonths
                                  }
                                  onChange={e => {
                                    clearValidation(
                                      'LoanCase.LoanRegistrationTermInMonths'
                                    )
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanRegistrationTermInMonths:
                                        e.target.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col d-flex align-items-center">
                                <label className="col-4 text-capitalize">
                                  {localize('annualPercentageRate')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control ms-2"
                                  value={
                                    iLoanCaseLinkage?.loanInterestAnnualPercentageRate ||
                                    ''
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanInterestAnnualPercentageRate:
                                        e.target.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col d-flex align-items-center">
                                <label className="col-4 text-capitalize">
                                  {localize('gracePeriod(days)')}
                                </label>
                                <input
                                  style={{ textAlign: 'right' }}
                                  type="number"
                                  className="form-control ms-2"
                                  value={
                                    iLoanCaseLinkage?.loanRegistrationGracePeriod
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanRegistrationGracePeriod:
                                        e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('interestCalculationMode')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="InterestCalculationMode"
                                  value={
                                    iLoanCaseLinkage?.loanInterestCalculationMode
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanInterestCalculationMode: e.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('paymentFrequencyPerYear')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ maxWidth: '100%' }}
                                  enumsKey="PaymentFrequencyPerYear"
                                  value={
                                    iLoanCaseLinkage?.loanRegistrationPaymentFrequencyPerYear ||
                                    ''
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanRegistrationPaymentFrequencyPerYear:
                                        e.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('interestChargeMode')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="InterestChargeMode"
                                  value={
                                    iLoanCaseLinkage?.loanInterestChargeMode ||
                                    ''
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanInterestChargeMode: e.value,
                                    })
                                  }}
                                />
                              </div>
                              {iLoanCaseLinkage?.loanInterestChargeMode ==
                              768 ? (
                                <div className="col-3 d-flex align-items-center mb-2">
                                  <label className="col-4 text-capitalize">
                                    {localize('interestRecoveryMode')}
                                  </label>
                                  <EnumsServerCombo
                                    customContainerStyle={{ width: '100%' }}
                                    enumsKey="InterestRecoveryMode"
                                    value={
                                      iLoanCaseLinkage?.loanInterestRecoveryMode ||
                                      ''
                                    }
                                    onChange={e => {
                                      setILoanCaseLinkage({
                                        ...iLoanCaseLinkage,
                                        loanInterestRecoveryMode: e.value,
                                      })
                                    }}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="row mb-2">
                              <div
                                className="col-3 d-flex align-items-center mb-2 with-validation"
                                validation-message={
                                  globalValidationErrors[
                                    'LoanCase.LoanPurposeId'
                                  ]?.message
                                }
                              >
                                <label className="col-4 text-capitalize">
                                  {localize('interestRecoveryMode')}
                                </label>
                                <LoanPurposeLookup
                                  displayValue={
                                    iLoanCaseLinkage?.loanPurposeDescription
                                  }
                                  onSelect={v => {
                                    clearValidation('LoanCase.LoanPurposeId')
                                    setILoanCaseLinkage(prev => ({
                                      ...prev,
                                      loanPurposeDescription: v.description,
                                      loanPurposeId: v.id,
                                    }))
                                  }}
                                />
                              </div>

                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('standingOrderTrigger')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="StandingOrderTrigger"
                                  value={
                                    iLoanCaseLinkage?.loanRegistrationStandingOrderTrigger ||
                                    ''
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanRegistrationStandingOrderTrigger:
                                        e.value,
                                    })
                                  }}
                                />
                              </div>

                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('roundingType')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="RoundingType"
                                  value={
                                    iLoanCaseLinkage?.loanRegistrationRoundingType ||
                                    ''
                                  }
                                  onChange={e => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      loanRegistrationRoundingType: e.value,
                                    })
                                  }}
                                />
                              </div>

                              <div className="col-3 d-flex align-items-center mb-2">
                                <label className="col-4 text-capitalize">
                                  {localize('disbursedDate')}
                                </label>
                                <CustomDatePicker
                                  defaultDate={getTodaysDate()}
                                  name={'disbursedDate'}
                                  onDateChange={date => {
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      disbursedDate: date,
                                    })
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div
                                className="col d-flex align-items-center with-validation"
                                validation-message={
                                  globalValidationErrors['LoanCase.Remarks']
                                    ?.message
                                }
                              >
                                <label className="col-1 text-capitalize">
                                  {localize('remarks')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={iLoanCaseLinkage?.remarks || ''}
                                  onChange={e => {
                                    clearValidation('LoanCase.Remarks')
                                    setILoanCaseLinkage({
                                      ...iLoanCaseLinkage,
                                      remarks: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={() => {
                        if (!selectedAccount) {
                          return Swal.fire({
                            icon: 'info',
                            text: 'Select Customer Account First',
                            title: 'Ooops!',
                            showCloseButton: true,
                          })
                        } else {
                          updateLoanCaseLinkage()
                        }
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoanCaseLinkage
