/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import BatchEntriesTab from '../../../../../../Components/ReUsables/BatchEntries/WithdrawalSettlementBatchEntriesTab/BatchEntriesTab'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { BatchAuditOption } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { parseDate } from '../../../../../../Helpers/formatters'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

function AuthorizeBatchModal({ handleClose, batchOrigination, reload, mode }) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedBatchAuthOption, setSelectedBatchAuditOption] = useState(
    Object.keys(BatchAuditOption)[0]
  )
  const [authorizationRemarks, setAuthorizationRemarks] = useState('')
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(prevState => {
      delete prevState[column]
      return prevState
    })
  }

  const handleUpdate = async () => {
    if (!authorizationRemarks) {
      return GlobalValidationErrors.set({
        AuthorizationRemarks: {
          message: 'Authorization Remarks is required.',
        },
      })
    }

    setIsBusy(true)

    const { success } = await putData(
      `/WithdrawalSettlementBatch/authorize-withdrawal-settlement-batch/?batchAuthOption=${selectedBatchAuthOption}`,
      { ...batchOrigination, authorizationRemarks },
      false
    )
    if (success) {
      reload(true)
      handleClose()
      showNotification(
        'Authorize Membership Withdrawal Settlement Batch',
        'success'
      )
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalMode={mode}
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleUpdate}
      handleClose={handleClose}
      modalClassName="modal-xl"
      modalTitle="membershipWithdrawalSettlementBatch"
      footerChildren={
        <>
          <div className="d-flex align-content-end justify-content-end gap-2">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors?.['AuthorizationRemarks']?.message
              }
            >
              <label className="text-capitalize me-3">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                onChange={e => {
                  setAuthorizationRemarks(e.target.value)
                  clearValidation('AuthorizationRemarks')
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              <EnumsServerCombo
                value={selectedBatchAuthOption}
                onChange={e => {
                  setSelectedBatchAuditOption(e.value)
                }}
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                enumsKey="BatchAuthOption"
              />
            </div>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row py-2 g-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="branch">
                  {localize('batch#')}
                </label>

                <input
                  className="form-control"
                  type="text"
                  defaultValue={batchOrigination.batchNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="category">
                  {localize('category')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  value={batchOrigination?.categoryDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="branch">
                  {localize('branch')}
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={batchOrigination.branchDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="priority">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="QueuePriority"
                  value={batchOrigination?.priority}
                  onChange={e => {
                    setSelectedBatchAuditOption(e.value)
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="reference">
                  {localize('reference')}
                </label>
                <input
                  className={`form-control`}
                  id="reference"
                  value={batchOrigination?.reference}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="createdBy">
                  {localize('createdBy')}
                </label>
                <input
                  className={`form-control`}
                  id="createdBy"
                  value={batchOrigination?.createdBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-3" htmlFor="createdDate">
                  {localize('createdDate')}
                </label>
                <input
                  type="date"
                  className={`form-control`}
                  id="createdDate"
                  value={parseDate(batchOrigination?.createdDate)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  className="text-capitalize col-3"
                  htmlFor="statusDescription"
                >
                  {localize('statusDescription')}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  id="statusDescription"
                  value={batchOrigination?.statusDescription}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('batchDetails')}
            </div>
            <div className="row py-2 g-3">
              <Tab
                tabItems={[
                  {
                    label: localize('batchEntries'),
                    item: (
                      <div
                        style={{
                          maxHeight: '50dvh',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}
                      >
                        <BatchEntriesTab
                          showRemoveMenu
                          withdrawalSettlementBatchId={
                            batchOrigination?.id || batchOrigination?.batchId
                          }
                          showReset
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AuthorizeBatchModal
