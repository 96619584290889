/** @format */

import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import { putData } from '../../../../../../../Helpers/webApi'
import { batchAuthOptions
} from '../../../../../../../Global/enumeration'
import { ViewCreditBatchesVerificationModal } from './ViewCreditBatchesVerificationModal'
import { VerifyCreditBatchesModal } from './VerifyCreditBatchesModal'

function CreditBatchesVerificationModal({
  mode,
  onHideModal,
  selectedBatchEntry,
}) {
  const [verificationModels, setVerificationModels] = useState({
    authOption: Object.keys(batchAuthOptions)[0],
  })
  const [gbv, setGbv] = GlobalValidationErrors.use()

  useEffect(() => {
    setGbv({})
  }, [mode])

  const clearValidation = column => {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const onVerifyJournalVoucher = async () => {
    await putData(
      '/CreditBatch/audit-credit-batch',
      {
        CreditBatch: {
          ...selectedBatchEntry,
          auditRemarks: verificationModels.auditRemarks,
        },
        BatchAuthOption: verificationModels.authOption,
      },
      true,
      'Verify Credit Batch'
    )
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('creditBatch')}
            handleAction={onVerifyJournalVoucher}
            hideUpdate={mode === 'view'}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'view' && (
              <ViewCreditBatchesVerificationModal
                selectedCreditBatch={selectedBatchEntry}
              />
            )}
            {mode === 'verify' && (
              <VerifyCreditBatchesModal
                validationErrors={gbv}
                clearValidation={clearValidation}
                verificationModels={verificationModels}
                setVerificationModels={setVerificationModels}
                selectedCreditBatch={selectedBatchEntry}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default CreditBatchesVerificationModal
