/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { formatDate } from '../../../../Helpers/formatters'
import localize from '../../../../Global/Localize'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../Components/Tabs/Tab'
import MobileToBankRequestEntries from './MobileToBankRequestEntries'
import Swal from 'sweetalert2'
import { putData } from '../../../../Helpers/webApi'
function ViewMobileToBankDataModal({
  mode,
  selectedMobileToBankRequest,
  close,
  entryStatus,
}) {
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [shortageAmount, setShortageAmount] = useState(null)
  function closeModal() {
    close()
  }
  function handleReset() {
    const { success } = putData(
      '/MobileToBankRequest/unpost-mobile-to-bank-request',
      { mobileToBankRequestId: selectedMobileToBankRequest.id },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      closeModal(null)
    }
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="mobileToBankRequests"
        handleClose={closeModal}
        modalClassName="modal-xl"
        handleAction={() => {
          if (selectedMobileToBankRequest.status == 0) {
            Swal.fire(
              'Ooops!',
              'Sorry, but a unmatched entry can not be reset',
              'info'
            )
          } else {
            handleReset()
          }
        }}
        actionText={mode === 'view' ? 'reset' : ''}
        footerChildren={
          <>
            {mode == 'view' ? (
              <>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('apportioned')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={apportionedAmount || ''}
                  />
                </div>

                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('shortage')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={shortageAmount || ''}
                  />
                </div>
                <button
                  className="btn btn-primary text-capitalize"
                  onClick={() => {
                    alert('printing')
                  }}
                >
                  {localize('print')}
                </button>
              </>
            ) : (
              ''
            )}
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mobileToBankTerminal')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedMobileToBankRequest?.mobileToBankTerminalDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionId')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.transID || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionAmount')}
            </label>
            <input
              type="number"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.transAmount || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('billReferenceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.billRefNumber || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mSISDN')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.msisdn || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('businessShortCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.businessShortCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('invoiceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.invoiceNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionTime')}
            </label>
            <input
              type="date"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.transTime, false) || ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('kYCInfor')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.kycInfo || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('senderType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.senderTypeDescription || ''}
            />
          </div>
          <div className="col-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.remarks || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.statusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('exceptionMessage')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.exceptionMessage || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.modifiedBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.modifiedDate, false) ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('recordStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.recordStatusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.createdBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.createdDate, false) ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.valueDate, false) || ''
              }
            />
          </div>
        </div>
        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <MobileToBankRequestEntries
                    showLoader={false}
                    selectedMobileToBankRequest={selectedMobileToBankRequest}
                    mode={mode}
                    entryStatus={entryStatus}
                    setApportioned={setApportionedAmount}
                    setShortage={setShortageAmount}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewMobileToBankDataModal
