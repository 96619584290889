/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { salutation } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateTechnician = ({
  mode,
  handleClose,
  refresh,
  setRefresh,
  technician,
  setTechnician,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleAddOrUpdate = async () => {
    setIsBusy(true)
    const message =
      mode === 'add' ? 'Create Morgue Technician' : 'Edit Morgue Technician'

    const url =
      mode === 'add'
        ? '/MorgueTechnician/add-morgue-technician'
        : '/MorgueTechnician/update-morgue-technician'

    const { success } = await postData(url, technician, false)

    if (success) {
      setRefresh(!refresh)
      setTechnician({
        salutation: Object.keys(salutation)[0],
      })
      showNotification(message, 'success')
      handleClose()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    setGlobalValidationErrors({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <ModalTemplate
      handleAction={handleAddOrUpdate}
      disableClose={isBusy}
      disableCreate={isBusy}
      modalMode={mode}
      modalTitle="technician"
      modalClassName="modal-xl"
      handleClose={handleClose}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            id="isLockedTechnician"
            checked={technician?.isLocked}
            onCheck={value => {
              setTechnician({
                ...technician,
                isLocked: value,
              })
            }}
          />
          <label htmlFor="isLockedTechnician" className="text-capitalize">
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="d-flex align-items-center col-md-4">
            <label className="text-capitalize  col-2">
              {localize('salutation')}
            </label>
            <EnumsServerCombo
              className="ms-2"
              value={technician?.salutation}
              customContainerStyle={{ width: '100%' }}
              enumsKey="Salutation"
              onChange={e => {
                setTechnician({
                  ...technician,
                  salutation: e.value,
                })
              }}
            />
          </div>
          <div className="col-md-4">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['FirstName']?.message}
            >
              <label htmlFor="firstName" className="text-capitalize col-3">
                {localize('firstName')}
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={technician?.firstName || ''}
                onChange={e => {
                  clearValidation('FirstName')
                  setTechnician({
                    ...technician,
                    firstName: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['OtherNames']?.message}
            >
              <label htmlFor="otherNames" className="text-capitalize col-3">
                {localize('otherNames')}
              </label>

              <input
                type="text"
                id="otherNames"
                className="form-control"
                value={technician?.otherNames || ''}
                onChange={e => {
                  clearValidation('OtherNames')
                  setTechnician({
                    ...technician,
                    otherNames: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateTechnician
