/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useState } from 'react'
import { CreateLoanRepaymentModeModal } from './CreateLoanRepaymentModeModal'
import { EditLoanRepaymentModeModal } from './EditLoanRepaymentModeModal'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewLoanRepaymentModeModal } from './ViewLoanRepaymentModeModal'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { standingOrderTrigger } from '../../../../Helpers/constants'

function LoanRepaymentModesModal({
  mode,
  onHideModal,
  waitingMetadata,
  selectedLoanRepaymentMode,
  setSelectedLoanRepaymentMode,
  onUpdateRefreshAt,
  repaymentModeMappings,
  setRepaymentModeMappings,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addLoanRepaymentMode()
    }
    if (mode === 'edit') {
      return editLoanRepaymentMode()
    }
  }

  const addLoanRepaymentMode = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/LoanRepaymentMode/add-loan-repayment-mode',
      { ...selectedLoanRepaymentMode },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onSetMode()
      setSelectedLoanRepaymentMode({
        standingOrderTrigger: standingOrderTrigger.PAYOUT,
      })
      swal.fire(
        'Add Loan Repayment Mode',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }
  const editLoanRepaymentMode = async () => {
    setIsBusy(true)
    const { success } = await postData(
      `/LoanRepaymentMode/update-loan-repayment-mode-with-mappings/?loanRepaymentModeId=${selectedLoanRepaymentMode?.id}`,
      {
        LoanRepaymentMode: { ...(selectedLoanRepaymentMode || {}) },
        LoanRepaymentModeMappings: repaymentModeMappings || [],
      },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onSetMode()
      setSelectedLoanRepaymentMode({
        standingOrderTrigger: standingOrderTrigger.PAYOUT,
      })
      swal.fire(
        'Edit Loan Repayment Mode',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit' || mode === 'view') && (
        <ModalTemplate
          disableCreate={isBusy}
          modalMode={mode}
          modalTitle={localize('loanRepaymentMode')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          hideUpdate={mode === 'view'}
          footerChildren={
            <div className="d-flex align-content-end justify-content-end">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'loan-repayment-is-locked'}
                  checked={selectedLoanRepaymentMode?.isLocked}
                  onCheck={v => {
                    setSelectedLoanRepaymentMode({
                      ...selectedLoanRepaymentMode,
                      isLocked: v,
                    })
                  }}
                />
                <label
                  htmlFor="loan-repayment-is-locked"
                  className="text-capitalize ms-2"
                >
                  {localize('isLocked')}?
                </label>
              </div>
            </div>
          }
          modalClassName={'modal-xl'}
        >
          {mode === 'add' && (
            <CreateLoanRepaymentModeModal
              setSelectedLoanRepaymentMode={setSelectedLoanRepaymentMode}
              selectedLoanRepaymentMode={selectedLoanRepaymentMode}
              isBusy={isBusy}
            />
          )}
          {mode === 'edit' && (
            <EditLoanRepaymentModeModal
              setSelectedLoanRepaymentMode={setSelectedLoanRepaymentMode}
              selectedLoanRepaymentMode={selectedLoanRepaymentMode}
              waitingMetadata={waitingMetadata}
              isBusy={isBusy}
              repaymentModeMappings={repaymentModeMappings}
              setRepaymentModeMappings={setRepaymentModeMappings}
            />
          )}
          {mode === 'view' && (
            <ViewLoanRepaymentModeModal
              repaymentModeMappings={repaymentModeMappings}
              waitingMetadata={waitingMetadata}
              selectedLoanRepaymentMode={selectedLoanRepaymentMode}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default LoanRepaymentModesModal
