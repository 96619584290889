/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { messageGroupTarget } from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'

function CustomTab({
  onSelectedCustomerChange,
  selectedCustomData,
  onSelectAll,
}) {
  const [selectAll, setSelectAll] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState({
    pageCollection: [],
  })

  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [search, setSearch] = useState('')
  const [customerFilter, setCustomerFilter] = useState(0)

  const columns = [
    {
      label: 'select',
      template: r => (
        <div className="d-flex align-items-center">
          <Checkbox
            id={r?.id}
            checked={selectedCustomData?.find(
              c => c.targetIdentifier === r?.id
            )}
            onCheck={v => onSelectedCustomerChange(r, v)}
          />
        </div>
      ),
    },
    {
      label: 'serialNumber',
      template: r => r?.paddedSerialNumber,
    },
    {
      label: 'name',
      template: r => r?.fullName,
    },
    {
      label: 'memberNumber',
      template: r => r?.paddedMemberNumber,
    },
    {
      label: 'accountNumber',
      template: r => r?.paddedAccountNumber,
    },
    {
      label: 'personalFileNumber',
      template: r => r?.personalFileNumber,
    },
    {
      label: 'membershipStatus',
      template: r => r?.membershipStatusDescription,
    },
    {
      label: 'membershipTerminationDate',
      template: r => formatDate(r?.membershipTerminationDate),
    },
    {
      label: 'individualType',
      template: r => r?.individualTypeDescription,
    },
    {
      label: 'gender',
      template: r => r?.individualGenderDescription,
    },
    {
      label: 'maritalStatus',
      template: r => r?.individualMaritalStatusDescription,
    },
    {
      label: 'nationality',
      template: r => r?.individualNationalityDescription,
    },
    {
      label: 'identityCardType',
      template: r => r?.individualIdentityCardTypeDescription,
    },
    {
      label: 'identityCardNumber',
      template: r => r?.individualIdentityCardNumber,
    },
    {
      label: 'identityCardSerial#',
      template: r => r?.individualIdentityCardSerialNumber,
    },
    {
      label: 'birthDate',
      template: r => formatDate(r?.individualBirthDate),
    },
    {
      label: 'employmentSector',
      template: r => r?.employmentSectorDescription,
    },
    {
      label: 'employmentDate',
      template: r => r?.individualEmploymentDate,
    },
    {
      label: 'employmentDesignation',
      template: r => r?.individualEmploymentDesignation,
    },
    {
      label: 'employmentTermsOfService',
      template: r => r?.individualEmploymentTermsOfServiceDescription,
    },
    {
      label: 'educationLevel',
      template: r => r?.individualEducationLevelDescription,
    },
    {
      label: 'membershipClass',
      template: r => r?.membershipClassDescription,
    },
    {
      label: 'disabilityType',
      template: r => r?.individualDisabilityTypeDescription,
    },
    {
      label: 'hudumaNumber',
      template: r => r?.individualHudumaNumber,
    },
    {
      label: 'groupName',
      template: r => r?.nonIndividualDescription,
    },
    {
      label: 'registrationNumber',
      template: r => r?.nonIndividualRegistrationNumber,
    },
    {
      label: 'registrationSerial#',
      template: r => r?.nonIndividualRegistrationSerialNumber,
    },
    {
      label: 'pin#',
      template: r => r?.personalIdentificationNumber,
    },
    {
      label: 'dateEstablished',
      template: r => <>{formatDate(r?.nonIndividualDateEstablished)}</>,
    },
    {
      label: 'addressLine1',
      template: r => r?.addressAddressLine1,
    },
    {
      label: 'addressLine2',
      template: r => r?.addressAddressLine2,
    },
    {
      label: 'physicalAddress',
      template: r => r?.addressAddressLine1,
    },
    {
      label: 'postalCode',
      template: r => r?.addressPostalCode,
    },
    {
      label: 'city',
      template: r => r?.addressCity,
    },
    {
      label: 'emailAddress',
      template: r => r?.addressEmail,
    },
    {
      label: 'landLine',
      template: r => r?.addressLandLine,
    },
    {
      label: 'mobileLine',
      template: r => r?.addressMobileLine,
    },
    {
      label: 'internetAddress',
      template: r => r?.addressInternet,
    },
    {
      label: 'ref_1(Account #)',
      template: r => r?.reference1,
    },
    {
      label: 'ref_2(Membership #)',
      template: r => r?.reference2,
    },
    {
      label: 'ref_3(Personal File #)',
      template: r => r?.reference3,
    },
    {
      label: 'branch',
      template: r => r?.branchDescription,
    },
    {
      label: 'duty/Workstation',
      template: r => r?.dutyStationDescription,
    },
    {
      label: 'electoralZone',
      template: r => r?.electoralZoneDescription,
    },
    {
      label: 'business/Group/Employer',
      template: r => r?.employerDescription,
    },
    {
      label: 'age',
      template: r => r?.age,
    },
    {
      label: 'membershipPeriod(Months)',
      template: r => r?.membershipPeriod,
    },
    {
      label: 'adminDivision',
      template: r => r?.administrativeDivisionDescription,
    },
    {
      label: 'joinDate',
      template: r => formatDate(r?.registrationDate),
    },
    {
      label: 'inhibitGuaranteeing',
      template: r => r?.inhibitGuaranteeing.toString(),
    },
    {
      label: 'isLocked',
      template: r => r?.isLocked.toString(),
    },
    {
      label: 'recruitedBy',
      template: r => r?.recruitedBy,
    },
    {
      label: 'remarks',
      template: r => r?.remarks,
    },
    {
      label: 'modifiedBy',
      template: r => r?.modifiedBy,
    },
    {
      label: 'modifiedDate',
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchCustomers = async () => {
    setIsBusy(true)
    const url = '/Customer/find-customers-by-filter-in-page'

    const { success, data: d } = await getData(url, reqParams, false)

    if (success) {
      setData({
        ...d,
        pageCollection: d.pageCollection?.map(e => ({
          ...e,
          targetIdentifier: e.id,
          messageGroupTarget: Object.keys(messageGroupTarget)[1],
          surveyGroupTarget: Object.keys(messageGroupTarget)[1],
        })),
      })
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.customerFilter,
    reqParams.pageNumber,
    reqParams.pageSize,
    reqParams.pageIndex,
    reqParams.filterText,
  ])

  return isBusy ? (
    <Loader />
  ) : (
    <div className="row">
      <div className="card">
        <div className="card-body">
          <div className="col-md-12 position-sticky top-0 bg-white py-2">
            <div className="row mb-4 d-flex justify-content-between">
              <div className="d-flex align-items-center col-md-4">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <Checkbox
                    onCheck={e => {
                      setSelectAll(e)
                      onSelectAll(data.pageCollection, e)
                    }}
                    id="toggle-custom-group-targets"
                    checked={selectAll}
                  />
                  <label htmlFor="toggle-custom-group-targets" className="ms-2">
                    {localize('toggleSelection')}
                  </label>
                </div>
              </div>

              <div className="col-md-5">
                <form
                  className="d-flex align-items-center float-end gap-2"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                      pageIndex: 0,
                    })
                  }}
                >
                  <label htmlFor="searchTable">{localize('search')}</label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="CustomerFilter"
                    value={customerFilter}
                    onChange={e => setCustomerFilter(e.value)}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    value={search}
                    onChange={e => {
                      if (e.target.value === '') {
                        setReqParams({ ...reqParams, filterText: '' })
                      }

                      setSearch(e.target.value)
                    }}
                  />
                  <button className="btn btn-primary ms-2">
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SimpleTable
              useText
              url="/Customer/find-customers-by-filter-in-page"
              columns={columns}
              data={data.pageCollection}
            />
            <Pager
              pageIndex={reqParams.pageIndex}
              pageSize={reqParams.pageSize}
              itemsCount={data.itemsCount}
              onPageChange={p => {
                setReqParams({ ...reqParams, pageIndex: p })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomTab
