/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { payrollNumberFilter } from '../../../../../Global/enumeration'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const PayrollNumbers = () => {
  const columns = [
    {
      label: localize('numberType'),
      template: r => <>{r.payrollNumberTypeDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: `${localize('duty')}/${localize('workStation')}`,
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r.customerAddressInternet}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate, true)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [error, setError] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    payrollNumberFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('numberType'),
    column: 'payrollNumberTypeDescription',
  })
  const [refreshAt, setRefreshAt] = useState(false)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/PayrollNumber/find-payroll-number-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setError(true)
    }
    setIsBusy(false)
  }
  const groupingColumns = [
    {
      label: localize('numberType'),
      column: 'payrollNumberTypeDescription',
    },
    {
      label: `${localize('duty')}/${localize('workStation')}`,
      column: 'customerDutyStationDescription',
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      column: 'customerEmployerDescription',
    },
    {
      label: localize('gender'),
      column: 'customerIndividualGenderDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'customerIndividualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'customerIndividualSalutationDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('membershipClass'),
      column: 'customerMembershipClassDescription',
    },
    {
      label: localize('ref_1(Account #)'),
      column: 'customerReference1',
    },
    {
      label: localize('ref_2(Membership #)'),
      column: 'customerReference2',
    },
    {
      label: localize('ref_3(Personal File #)'),
      column: 'customerReference3',
    },
  ]
  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('payrollNumbersRegister'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                {' '}
                {error ? (
                  <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                    <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                    <div className="fs-5 text-danger">
                      {localize('failedToLoadData')}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row mb-2 mx-0">
                      <div className="d-flex align-items-center col-2">
                        <label
                          htmlFor="recordsPerPage"
                          className=""
                          style={{
                            marginLeft: -10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          name=""
                          id="recordsPerPage"
                          className="form-select ms-1"
                          style={{ maxWidth: 'fit-content' }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>

                      <div className="col-4 d-flex align-items-center">
                        <label className="col-2  text-capitalize">
                          {localize('groupBy')}
                        </label>
                        <select
                          className="form-select text-capitalize"
                          defaultValue={groupBy.column}
                          style={{ maxWidth: 'fit-content' }}
                          onChange={e =>
                            setGroupBy({
                              label: groupingColumns.find(
                                f => f.column === e.target.value
                              )?.label,
                              column: e.target.value,
                            })
                          }
                        >
                          {groupingColumns.map(({ label, column }) => (
                            <option className="text-capitalize" value={column}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <form
                        className="d-flex align-items-center col-6 col"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                          })
                        }}
                      >
                        <label
                          className="ms-2 text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="PayrollNumberFilter"
                          value={reqParams?.payrollNumberFilter}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              payrollNumberFilter: e.value,
                            })
                          }
                        />
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => {
                            setSearch(e.target.value)
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                            })
                          }}
                        />
                        <button
                          id="payrollNumberFilter Button"
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            loadData()
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>

                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={data?.pageCollection}
                    />
                    <div className="mb-2"></div>
                    <Pager
                      itemsCount={data.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PayrollNumbers
