/** @format */

/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import localize from '../../../../../Global/Localize'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import BasicDetails from './AddCustomerScreens/BasicDetails'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import MembershipDetails from './AddCustomerScreens/MembershipDetails'
import Swal from 'sweetalert2'
import Specimen from './AddCustomerScreens/Specimen'
import Referees from './AddCustomerScreens/Referees'
import Associates from './AddCustomerScreens/Associates'
import BankAccounts from './AddCustomerScreens/BankAccounts'
import CreditTypes from './AddCustomerScreens/CreditTypes'
import MandatoryParameters from './AddCustomerScreens/MandatoryParameters'
import { NavigationContext } from '../../../../../Contexts/NavigationContext'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function AddCustomer({
  closeModal,
  mode,
  customer,
  readOnly,
  menuCode,
  onMinimize,
  refreshFromServer,
  refreshCustomers,
}) {
  const { navigatedMenuCode } = useContext(NavigationContext)
  const [selectedCustomer, setSelectedCustomer] = useState(customer)
  const [activePage, setActivePage] = useState(0)
  const [minimized, setMinimized] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [specimen, setSpecimen] = useState(null)
  const [authOption, setAuthOption] = useState(1)
  const [remarks, setRemarks] = useState('')

  async function updateSpecimen() {
    setIsBusy(true)
    await putData(
      `/customer/update-customer-specimen/${selectedCustomer?.id}`,
      specimen,
      false
    )
    setIsBusy(false)
  }

  const customerPages = [
    {
      title: localize('details'),
      icon: '',
      component: (
        <BasicDetails
          mode={mode}
          readOnly={readOnly}
          onChange={c => setSelectedCustomer({ ...c })}
          customer={selectedCustomer}
        />
      ),
      skippable: !!selectedCustomer?.id,
      hidden: false,
      actions: (
        <>
          <button
            className="btn btn-primary ms-2"
            disabled={!selectedCustomer?.id}
            onClick={moveNextPage}
          >
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>
          <button
            className="btn btn-success ms-5"
            onClick={handleCreateCustomer}
            disabled={readOnly}
          >
            <i className="uil uil-check"></i>
            {localize('saveCustomer')}
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-2">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('membershipDetails'),
      icon: '',
      component: <MembershipDetails customer={selectedCustomer} />,
      skippable: true,
      hidden: true,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('specimen'),
      icon: '',
      component: (
        <Specimen
          onChange={s => setSpecimen(s)}
          customer={selectedCustomer}
          readOnly={readOnly}
        />
      ),
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>
          <button className="btn btn-success ms-5" onClick={updateSpecimen}>
            {localize('update')}
          </button>
          <button onClick={closeModal} className="btn btn-danger ms-3">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('referees'),
      icon: '',
      component: <Referees readOnly={readOnly} customer={selectedCustomer} />,
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" oonClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('associates'),
      icon: '',
      component: <Associates readOnly={readOnly} customer={selectedCustomer} />,
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('bankAccounts'),
      icon: '',
      component: (
        <BankAccounts readOnly={readOnly} customer={selectedCustomer} />
      ),
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('creditTypes'),
      icon: '',
      component: <CreditTypes readOnly={readOnly} customer={customer} />,
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>
          {mode === 'add' && (
            <button className="btn btn-primary ms-2" onClick={moveNextPage}>
              {localize('next')}
              <i className="uil uil-angle-right"></i>
            </button>
          )}

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('mandatoryParameters'),
      icon: '',
      component: <MandatoryParameters customer={selectedCustomer} />,
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('back')}
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
  ]

  function minimizeModal() {
    setMinimized(!minimized)
  }

  function moveNextPage() {
    setActivePage(activePage + 1)
  }

  function movePrevPage() {
    setActivePage(activePage - 1)
  }

  useEffect(() => {
    onMinimize(minimized)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimized])

  useEffect(() => {
    mode !== 'add' && refreshFromServer && loadCustomerDetails()
    // eslint-disable-next-line
  }, [])

  async function loadCustomerDetails() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/customer/find-customer-by-id',
      {
        customerId: customer?.id,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setSelectedCustomer(data?.customer)
    } else {
      setIsBusy(false)
    }
  }

  async function handleCreateCustomer() {
    const payload = {
      ...selectedCustomer,
      // @TODO: retrieve this data after wiring the auth server
    }

    const { success, data } =
      mode === 'add'
        ? await postData('/customer/create-customer', {
            ...payload,
          })
        : await putData('/customer/update-customer', payload)
    if (success) {
      // setSelectedCustomer(data)
      const payloadString =
        mode === 'add'
          ? `Full Name: ${data.fullName}<br />Member Number: ${data.formattedMemberNumber}<br />Account Number: ${data.formattedAccountNumber}`
          : 'customer updated successfuly'
      //   Swal.fire('Operation Successful', payloadString, 'success')
      Swal.fire({
        title: 'Operation Successful',
        html: payloadString,
        icon: 'success',
      })
      setActivePage(activePage + 1)
    }
  }

  async function verify() {
    setIsBusy(true)
    const { success, data } = await putData(
      `/customer/verify-customer/${selectedCustomer?.id}`,
      { auditOption: authOption, remarks }
    )
    if (success && data?.result) {
      refreshCustomers()
      Swal.fire(
        'Operation Successful',
        'Operation Completed successfully',
        'success'
      )
      closeModal()
    } else {
      Swal.fire(
        'Operation Failed',
        `<div>
          <strong className='text-danger'>Operation Not Completed</strong>
          <p>
           <ul style='font-size:small;'>
           ${data?.messages?.map(m => `<li>${m}</li>`)}
           </ul></p>
         </div>`,
        'error'
      )
    }
    setIsBusy(false)
  }

  return minimized ? (
    <div className="modal-minimized" onClick={minimizeModal}>
      <i className="uil uil-clapper-board fs-2"></i>
    </div>
  ) : (
    <Modal
      isOpen={menuCode === navigatedMenuCode || !minimized}
      className="modal-xl"
    >
      <ModalBody className="bg-white">
        <div style={{ position: 'relative' }}>
          {/* <TabsHeader menuCode={menuCode} /> */}
          <div className="text-capitalize d-flex align-items-center mb-2">
            <h4 style={{ marginTop: 5 }}>{localize(mode)}</h4>
            <i className="uil uil-angle-right mx-2"></i>
            <span className="text-muted fs-6 text-uppercase">
              {localize('customers')}
            </span>
            <button type="button" className="minimize" onClick={minimizeModal}>
              <span aria-hidden="true">_</span>
            </button>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Walkthrough
            noActions={mode === 'verify'}
            pages={
              selectedCustomer?.membershipClassCustomerType == 1 ||
              selectedCustomer?.membershipClassCustomerType == 2
                ? mode === 'add'
                  ? customerPages
                  : [...customerPages].filter((m, i) => i < 7)
                : (mode === 'add'
                    ? customerPages
                    : [...customerPages].filter((m, i) => i < 7)
                  ).filter((x, i) => i !== 1)
            }
            activePage={activePage}
            changePage={p => setActivePage(p)}
          />
          {mode === 'verify' && (
            <>
              <hr />
              <div
                className="p-2 d-flex align-items-center justify-content-end mt-2"
                style={{
                  // border: 'solid 1px black',
                  background: '#5b73e834',
                  borderRadius: 5,
                  // boxShadow: '#0004 0 0 20px',
                }}
              >
                <label htmlFor="" className="text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  className=" form-control ms-1"
                  style={{ maxWidth: 200 }}
                  placeholder="Enter Text..."
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
                ></input>
                <div className="ms-2">
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="RecordAuthOption"
                    value={authOption}
                    onChange={e => setAuthOption(e.value)}
                  />
                </div>
                <button className="btn px-5 btn-success ms-3" onClick={verify}>
                  {localize('update')}
                </button>
              </div>
            </>
          )}
          {isBusy && <NonBlockingLoader />}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AddCustomer
