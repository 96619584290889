/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../../Global/Localize'
import {
  CustomerFilter,
  batchEntryStatus,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import ActionModal from '../../../ActionModal/ActionModal'
import Checkbox from '../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'

const LoanBatchEntries = ({
  showSelect,
  hiddenColumns = [],
  status,
  loanDisbursementBatchId,
  showView,
  showReset,
  reload,
  setReload,
}) => {
  const [batchEntries, setBatchEntries] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [mode, setMode] = useState(null)
  const [batch, setBatch] = useState({})
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyRemoving, setIsBusyRemoving] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    status: status ?? Object.keys(batchEntryStatus)[0],
    customerFilter: Object.keys(CustomerFilter)[0],
  })

  const handleSelectEntry = (entry, value) => {
    if (value) {
      setSelectedEntries([...selectedEntries, entry])
    } else {
      setSelectedEntries(selectedEntries.filter(e => e.id !== entry.id))
    }
  }

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedEntries?.find(e => e.id === r?.id)}
          onCheck={value => handleSelectEntry(r, value)}
        />
      ),
    },

    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    {
      label: localize('receivedDate'),
      template: r => formatDate(r?.loanCaseReceivedDate, true),
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('customerFileNumber'),
      template: r => r?.loanCaseCustomerReference3,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanCaseLoanProductDescription,
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('annualPercentageRate'),
      template: r =>
        formatAsPercent(r?.loanCaseLoanInterestAnnualPercentageRate),
    },
    {
      label: localize('term(Months)'),
      template: r => r?.loanCaseLoanRegistrationTermInMonths,
    },
    {
      label: localize('approvedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
    },
    {
      label: localize('customerType'),
      template: r => r?.loanCaseCustomerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.loanCaseCustomerReference1,
    },
    {
      label: localize('membershipNumber'),
      template: r => r?.loanCaseCustomerReference2,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const columnsToDisplay = columns.filter((_x, i) => !hiddenColumns.includes(i))

  const handleSelectAllEntries = (data, value) => {
    if (value) {
      setSelectedEntries(data)
    } else {
      setSelectedEntries([])
    }
  }

  const fetchBatchEntries = async () => {
    setIsBusy(true)
    const url =
      '/LoanDisbursementBatch/find-loan-disbursement-batch-entries-by-loan-disbursement-batch-id-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        loanDisbursementBatchId,
      },
      false
    )

    if (success) {
      setBatchEntries(data)
      if (reload) setReload(false)
    }

    setIsBusy(false)
  }

  const handleSelectBatch = (data, action) => {
    if (action === 'reset' && data.status !== Object.keys(batchEntryStatus)[4])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Only entries with status "Exception Raised" can be reset',
      })

    setBatch(data)
    setMode(action)
  }

  const handleCloseBatchModal = () => {
    setMode(null)
    setBatch({})
  }

  const handleResetBatch = () => {
    const url = '/LoanDisbursementBatch/unpost-loan-disbursement-batch-entry'

    const { success } = postData(url, batch?.id, false)

    if (success) {
      fetchBatchEntries()
      setBatch({})
      showNotification('Reset Loan Disbursement Batch', 'success')
    }
  }

  const handleClickRemoveButton = () => {
    setMode('remove')
  }

  const handleRemoveBatchEntries = async () => {
    if (selectedEntries.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one entry to remove',
      })

    setIsBusyRemoving(true)

    const url = '/LoanDisbursementBatch/remove-loan-disbursement-batch-entries'

    const { success } = await postData(url, selectedEntries, false)

    if (success) {
      fetchBatchEntries(false)
      setSelectedEntries([])
      setSelectAll(false)
      showNotification('Remove Loan Disbursement Batch Entry', 'success')
      handleCloseBatchModal()
    }

    setIsBusyRemoving(false)
  }

  useEffect(() => {
    fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, loanDisbursementBatchId])

  useEffect(() => {
    if (reload) fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      {mode === 'reset' && (
        <ActionModal
          confirmText="reset"
          handleAction={handleResetBatch}
          handleClose={handleCloseBatchModal}
          modalTitle={'resetBatchEntries'}
        >
          <p>Do you want to reset the selected batch entry ?</p>
        </ActionModal>
      )}

      {mode === 'remove' && (
        <ActionModal
          confirmText="yes"
          handleAction={handleRemoveBatchEntries}
          handleClose={handleCloseBatchModal}
          modalTitle={'removeBatchEntries'}
          cancelText="no"
          disableAction={isBusyRemoving}
          disableClose={isBusyRemoving}
        >
          {isBusyRemoving ? (
            <Loader />
          ) : (
            <p>
              Do you want to remove the selected batch
              {selectedEntries?.length > 1 ? ' entries' : ' entry'} ?
            </p>
          )}
        </ActionModal>
      )}

      <>
        {isBusy ? (
          <Loader />
        ) : (
          <div className="p-3">
            <div className="row mb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center col-md-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              {showSelect && (
                <div className="col-md-2">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      onCheck={value => {
                        setSelectAll(value)
                        handleSelectAllEntries(
                          batchEntries?.pageCollection,
                          value
                        )
                      }}
                      id="toggleSelection"
                      checked={selectAll}
                    />
                    <label htmlFor="toggleSelection" className="ms-2">
                      {localize('toggleSelection')}
                    </label>
                  </div>
                </div>
              )}
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: 200 }}
                  enumsKey="batchEntryStatus"
                  value={reqParams.status}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                      pageIndex: 0,
                    })
                  }}
                />
              </div>
              <div
                className={`${
                  showSelect ? 'col-md-4' : 'col-md-4 offset-md-2'
                } d-flex align-items-center justify-content-end`}
              >
                <label htmlFor="search" className="text-capitalize me-2">
                  {localize('search')}
                </label>

                <div className="d-flex align-items-center">
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="CustomerFilter"
                    value={reqParams.customerFilter}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        customerFilter: e.value,
                        pageIndex: 0,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="search"
                    className="form-control ms-2"
                    value={reqParams.filterText}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                      })
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchBatchEntries()
                      }
                    }}
                  />
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchBatchEntries()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <SimpleTable
                columns={columnsToDisplay}
                data={batchEntries?.pageCollection}
                contextMenu={r => (
                  <>
                    <>
                      {showView && (
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleSelectBatch(r, 'view')
                          }}
                        >
                          <i className="uil uil-eye"></i>
                          <span>{localize('view')}</span>
                        </div>
                      )}
                      {showReset && (
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleSelectBatch(r, 'reset')
                          }}
                        >
                          <i className="mdi mdi-link-off"></i>
                          <span>{localize('reset')}</span>
                        </div>
                      )}
                    </>
                  </>
                )}
              />
              <Pager
                pageIndex={reqParams.pageIndex}
                pageSize={reqParams.pageSize}
                itemsCount={batchEntries.itemsCount}
                onPageChange={p => {
                  setReqParams({ ...reqParams, pageIndex: p })
                }}
              />
            </div>

            {showSelect && (
              <div className="d-flex justify-content-end align-items-center p-2 bg-light rounded">
                <div className="col">
                  <button
                    className="btn btn-danger float-end"
                    onClick={handleClickRemoveButton}
                  >
                    {localize('remove')}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </>
  )
}

export default LoanBatchEntries
