/** @format */

/* eslint-disable */
import React from 'react'
import Swal from 'sweetalert2'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import BankLinkageLookup from '../../../../../../Components/Lookup/BankLinkageLookup'
import BankReconciliationPeriodLookup from '../../../../../../Components/Lookup/BankReconciliationPeriodLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import Loader from '../../../../../../Components/Loaders/Loader'

const CreatePeriod = ({
  selectedPeriod,
  setSelectedPeriod,
  mode,
  handleClose,
  handleAction,
  clearValidation,
  globalValidationErrors,
  isBusyCreatingOrUpdating,
}) => {
  const handleClickOnce = r => {
    if (r.statusDescription !== 'Closed')
      return Swal.fire({
        icon: 'error',
        title: 'Operation Failed.',
        text: 'You can only select closed parent period...',
      })

    setSelectedPeriod({
      ...selectedPeriod,
      ...r,
      durationEndDate: selectedPeriod?.durationEndDate,
      bankAccountBalance: '',
      remarks: '',
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      handleAction={handleAction}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle="bankReconciliationPeriod"
      disableClose={isBusyCreatingOrUpdating}
      disableCreate={isBusyCreatingOrUpdating}
    >
      {isBusyCreatingOrUpdating ? (
        <Loader />
      ) : (
        <div className="row g-3">
          {mode === 'add' ? (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="parentPeriod"
                    className="text-capitalize col-4"
                  >
                    {localize('parentPeriod')}
                  </label>

                  <BankReconciliationPeriodLookup
                    displayValue={selectedPeriod?.indentedDescription}
                    handleClickOnce={r => {
                      handleClickOnce(r)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label htmlFor="startDate" className="text-capitalize col-4">
                    {localize('startDate')}
                  </label>

                  <CustomDatePicker
                    name="startDate-parentPeriod"
                    defaultDate={selectedPeriod?.durationStartDate}
                    onDateChange={date => {
                      setSelectedPeriod({
                        ...selectedPeriod,
                        durationStartDate: date,
                      })
                    }}
                    maxDate={selectedPeriod?.endDate}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['ChartOfAccountId']?.message
                  }
                >
                  <label
                    htmlFor="parentPeriod"
                    className="text-capitalize col-4"
                  >
                    {localize('g/LAccountName')}
                  </label>
                  <BankLinkageLookup
                    displayValue={selectedPeriod?.chartOfAccountName}
                    onSelect={r => {
                      clearValidation('ChartOfAccountId')
                      setSelectedPeriod({
                        ...selectedPeriod,
                        ...r,
                        chartOfAccountName: r.chartOfAccountName,
                        bankBranchName: r.bankBranchName,
                        remarks: '',
                        id: selectedPeriod?.id,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label htmlFor="endDate" className="text-capitalize col-4">
                    {localize('endDate')}
                  </label>

                  <CustomDatePicker
                    name="endDate-parentPeriod"
                    defaultDate={selectedPeriod?.durationEndDate}
                    onDateChange={date => {
                      setSelectedPeriod({
                        ...selectedPeriod,
                        durationEndDate: date,
                      })
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="postingPeriod"
                    className="text-capitalize col-4"
                  >
                    {localize('postingPeriod')}
                  </label>

                  <input
                    value={selectedPeriod?.postingPeriodDescription}
                    disabled
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="referenceMonth"
                    className="text-capitalize col-4"
                  >
                    {localize('referenceMonth')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="referenceMonth"
                    value={selectedPeriod?.monthDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="g/LAccountName"
                    className="text-capitalize col-4"
                  >
                    {localize('g/LAccountName')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="g/LAccountName"
                    value={selectedPeriod?.chartOfAccountName}
                    disabled
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label htmlFor="bankBranchName" className="text-capitalize col-4">
                {localize('bankBranchName')}
              </label>

              <input
                type="text"
                className="form-control"
                id="bankBranchName"
                value={
                  selectedPeriod?.branchDescription ||
                  selectedPeriod?.bankBranchName
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label
                htmlFor="bankAccountNumber"
                className="text-capitalize col-4"
              >
                {localize('bankAccountNumber')}
              </label>

              <input
                type="text"
                className="form-control"
                id="bankAccountNumber"
                value={selectedPeriod?.bankAccountNumber}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label
                htmlFor="bankAccountBalance"
                className="text-capitalize col-4"
              >
                {localize('bankAccountBalance')}
              </label>

              <MaskNumber
                type="text"
                className="form-control"
                id="bankAccountBalance"
                defaultMaskValue={selectedPeriod?.bankAccountBalance || 0}
                onMaskNumber={e => {
                  setSelectedPeriod({
                    ...selectedPeriod,
                    bankAccountBalance: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label htmlFor="remarks" className="text-capitalize col-4">
                {localize('remarks')}
              </label>

              <input
                type="text"
                className="form-control"
                id="remarks"
                value={selectedPeriod?.remarks}
                onChange={e => {
                  setSelectedPeriod({
                    ...selectedPeriod,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreatePeriod
