/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { postData } from '../../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../../Components/Loaders/Loader'

const AuthorizeCashTransferRequest = ({
  mode,
  handleClose,
  request,
  setRequest,
  fetchRequests,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleAuthorize = async () => {
    if (!request?.authorizationRemarks)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please enter remarks',
      })

    setIsBusy(true)

    const url = `/TreasuryManagementRequest/authorize-treasury-management-request?treasuryManagementRequestAuthOption=${request?.treasuryManagementRequestAuthOption}`

    const { success } = await postData(
      url,
      {
        treasuryManagementRequest: request,
        treasuryManagementRequestAuthOption:
          request?.treasuryManagementRequestAuthOption,
      },
      false
    )

    if (success) {
      fetchRequests()
      handleClose()
      showNotification('Treasury Cash Transfer Authorization', 'success')
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <ModalTemplate
      modalTitle="treasuryManagementRequestAuthorization"
      handleAction={handleAuthorize}
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3 ms-2">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={isBusy}
              value={request?.authorizationRemarks || ''}
              onChange={e => {
                setRequest({
                  ...request,
                  authorizationRemarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center gap-3 me-2">
            <label className="text-capitalize">{localize('authOption')}</label>
            <EnumsServerCombo
              sort
              customContainerStyle={{
                minWidth: 150,
              }}
              disabled={isBusy}
              value={request?.treasuryManagementRequestAuthOption}
              enumsKey="TreasuryManagementRequestAuthOption"
              onChange={({ value }) =>
                setRequest({
                  ...request,
                  treasuryManagementRequestAuthOption: value,
                })
              }
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('transactionType')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.treasuryTrailTypeDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('transactionSource')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.treasuryTrailSourceDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('transactionDestination')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.treasuryTrailDestinationDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('primaryDescription')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.primaryDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('secondaryDescription')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.secondaryDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.reference}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('status')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.statusDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('variance')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(request?.variance, null, null, false)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('varianceKind')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.varianceKindDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.createdBy}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(request?.createdDate)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('branch')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.branchDescription}
                disabled
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AuthorizeCashTransferRequest
