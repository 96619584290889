/** @format */

import React, { useState } from 'react'
import ListingPage from '../../../../../Components/ListingPage/Index'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import AddPersonnel from './AddPersonnel/AddPersonnel'
import ViewPersonnel from './ViewPersonnel/ViewPersonnel'

const Personnel = () => {
  const [personnel, setPersonnel] = useState({})
  const [mode, setMode] = useState(null)
  const [refresh, setRefresh] = useState(false)

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('customerBranch'),
      template: r => <>{r?.customerBranchDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('vehicleRegistrationNumber'),
      template: r => <>{r?.vehicleRegistrationNumber}</>,
    },
    {
      label: localize('badgeImageNumber'),
      template: r => <>{r?.badgeNumber}</>,
    },
    {
      label: localize('badgeExpiryDate'),
      template: r => <>{formatDate(r?.badgeExpiryDate, true)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleClose = () => {
    setMode(null)
    setPersonnel({})
  }

  const handleSelectPersonnel = (r, mode) => {
    setPersonnel(r)
    setMode(mode)
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <AddPersonnel
          fetchPersonnel={() => setRefresh(!refresh)}
          handleClose={handleClose}
          mode={mode}
          personnelData={personnel}
        />
      )}

      {mode === 'view' && (
        <ViewPersonnel
          handleClose={handleClose}
          mode={mode}
          personnelData={personnel}
        />
      )}

      <ListingPage
        url="/VehiclePersonnel/find-by-filter-in-page"
        map={[localize('fleet'), localize('vehiclePersonnel')]}
        columns={columns}
        onAdd={handleClickAddButton}
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelectPersonnel(r, 'view')
              }}
            >
              <i className="uil uil-eye"></i>
              <span>{localize('view')}</span>
            </div>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelectPersonnel(r, 'edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
      />
    </>
  )
}

export default Personnel
