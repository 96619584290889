/** @format */
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useState } from 'react'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { GeneralCashTransactionCategory } from '../../../../../../../Helpers/constants'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../../../../Helpers/formatters'

export const SundryTransactionSectionCashPayment = ({
  transactionModel,
  onBlurSundryCashPaymentAmount,
  loading,
  setTransactionModel,
}) => {
  const [
    selectedGeneralCashTransactionCategory,
    setSelectedGeneralCashTransactionCategory,
  ] = useState(GeneralCashTransactionCategory.SUNDRY)
  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('transactionType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="GeneralCashTransactionCategory"
              value={selectedGeneralCashTransactionCategory}
              onChange={e => {
                setSelectedGeneralCashTransactionCategory(e.value)
                setTransactionModel({
                  ...transactionModel,
                  generalCashTransactionCategory: e.value,
                })
              }}
            />
          </div>
        </div>
        {selectedGeneralCashTransactionCategory ===
          GeneralCashTransactionCategory.SUNDRY && (
          <>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('glAccount')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={
                    transactionModel?.SelectedGeneralLedgerAccount?.name
                  }
                  onSelect={r => {
                    setTransactionModel({
                      ...transactionModel,
                      SelectedGeneralLedgerAccount: { ...r },
                    })
                  }}
                />
              </div>
              <div className="col-6 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('accountType')}
                </label>
                <input
                  className="form-control"
                  value={
                    transactionModel?.SelectedGeneralLedgerAccount
                      ?.typeDescription || ''
                  }
                  disabled
                />
              </div>
            </div>
          </>
        )}
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('amount')}
            </label>
            <MaskNumber
              defaultMaskValue={transactionModel?.paymentAmount || ''}
              onBlur={onBlurSundryCashPaymentAmount}
              onMaskNumber={e => {
                setTransactionModel({
                  ...transactionModel,
                  paymentAmount: stripNonNumericCharacters(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">{localize('payee')}</label>
            <input
              className="form-control"
              value={transactionModel?.cashPaymentTransactionModel?.payee || ''}
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    payee: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('narration')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.cashPaymentTransactionModel?.narration || ''
              }
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    narration: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.cashPaymentTransactionModel?.reference || ''
              }
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    reference: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
