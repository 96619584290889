/** @format */

import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  BatchEntryStatus,
  DiscrepancyFilter,
} from '../../../../../../../Global/enumeration'
import { getData } from '../../../../../../../Helpers/webApi'
import DatePicker from '../../../../../../../Components/Date/DatePicker'
import Pager from '../../../../../../../Components/Tables/Pager'
import Loader from '../../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

export const DiscrepanciesTab = ({ onSelectDiscreprancy }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [discrepancies, setDiscrepancies] = useState([])
  const [selectedDiscrepancies, setSelectedDiscrepancies] = useState([])
  const [reqParams, setReqParams] = useState({
    status: Object.keys(BatchEntryStatus)[0],
    startDate: null,
    endDate: null,
    filterText: '',
    discrepancyFilter: Object.keys(DiscrepancyFilter)[0],
    pageIndex: 0,
    pageSize: 10,
    customDate: true,
    dateRequestsFilter: 15,
  })

  const fetchCreditBatchDiscrepancies = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-discrepancies-in-page',
      { ...reqParams },
      false
    )
    if (success) {
      setIsBusy(false)
      setDiscrepancies(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchCreditBatchDiscrepancies()
  }, [])

  useEffect(() => {
    fetchCreditBatchDiscrepancies()
  }, [
    reqParams.pageIndex,
    reqParams.pageSize,
    reqParams.discrepancyFilter,
    reqParams.dateRequestsFilter,
    reqParams.customDate,
    reqParams.startDate,
    reqParams.endDate,
  ])

  const columns = [
    {
      label: `${localize('select')}?`,
      template: r => (
        <Checkbox
          checked={selectedDiscrepancies.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedDiscrepancies([...selectedDiscrepancies, r])
              onSelectDiscreprancy(r, c)
            } else {
              setSelectedDiscrepancies(
                selectedDiscrepancies.filter(s => s.id !== r.id)
              )
              onSelectDiscreprancy(r, c)
            }
          }}
        />
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedCreditBatchBatchNumber}</>,
    },
    {
      label: localize('batchTotalValue'),
      template: r => <>{formatCurrency(r.creditBatchTotalValue)}</>,
    },
    {
      label: localize('batchMonth'),
      template: r => <>{r.creditBatchMonthDescription}</>,
    },
    {
      label: localize('batchReference'),
      template: r => <>{r.creditBatchReference}</>,
    },
    { label: localize('column1'), template: r => <>{r.column1}</> },
    { label: localize('column2'), template: r => <>{r.column2}</> },
    { label: localize('column3'), template: r => <>{r.column3}</> },
    { label: localize('column4'), template: r => <>{r.column4}</> },
    { label: localize('column5'), template: r => <>{r.column5}</> },
    { label: localize('column6'), template: r => <>{r.column6}</> },
    { label: localize('column7'), template: r => <>{r.column7}</> },
    { label: localize('column8'), template: r => <>{r.column8}</> },
    { label: localize('column9'), template: r => <>{r.column9}</> },
    { label: localize('column10'), template: r => <>{r.column10}</> },
    { label: localize('column11'), template: r => <>{r.column11}</> },
    { label: localize('column12'), template: r => <>{r.column12}</> },
    { label: localize('column13'), template: r => <>{r.column13}</> },
    { label: localize('column14'), template: r => <>{r.column14}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('batchType'),
      template: r => <>{r.creditBatchTypeDescription}</>,
    },
    {
      label: localize('batchFileType'),
      template: r => <>{r.creditBatchCreditTypeBatchFileTypeDescription}</>,
    },
    {
      label: localize('productCode'),
      template: r => <>{r.productCodeDescription}</>,
    },
    {
      label: localize('targetProductCode'),
      template: r => <>{r.paddedTargetProductCode}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
  ]
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex mt-3 mb-3">
            <div className="d-flex align-items-start me-auto">
              <div className="d-flex align-items-center me-5">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="DiscrepancyFilter"
                  value={reqParams.discrepancyFilter}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      discrepancyFilter: e.value,
                    })
                  }}
                  sort
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <label className="col-3 text-capitalize">
                  {localize('search')}
                </label>
                <input
                  defaultValue={reqParams.filterText}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Enter text..."
                  type="text"
                />
              </div>
              <div
                className="d-flex align-items-center me-5"
                style={{ width: '300px' }}
              >
                <label className="col-3 text-capitalize">
                  {localize('pickDate')}
                </label>
                <DatePicker
                  onSelect={v => {
                    if (v.custom) {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    } else {
                      setReqParams({
                        ...reqParams,
                        customDate: false,
                        dateRequestsFilter: v.value,
                      })
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <SimpleTable columns={columns} data={discrepancies.pageCollection} />
          <Pager
            itemsCount={discrepancies.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}
