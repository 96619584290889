/** @format */

/* eslint-disable */
import React from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Transactions from '../../../../../Components/ReUsables/GLAccounts/Transactions'

function GLAccountTransactionsArchived() {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('transactionsJournal'),
          localize('gLAccountTransactionsArchived'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <Transactions hidePrintAndExport glAccount={null} />
        </div>
      </div>
    </>
  )
}

export default GLAccountTransactionsArchived
