/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import ListDebitBatches from '../../../../../../Components/ReUsables/BatchProcedures/DebitBatches/ListDebitBatches'
import localize from '../../../../../../Global/Localize'
import {
  batchAuthOptions,
  batchStatus,
} from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import VerifyDebitBatch from './VerifyDebitBatch/VerifyDebitBatch'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

function Debit() {
  const [mode, setMode] = useState(null)
  const [shortage, setShortage] = useState(null)
  const [reload, setReload] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [batch, setBatch] = useState({
    batchAuthOption: Number(Object.keys(batchAuthOptions)[0]),
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectBatch = data => {
    if (data.status !== Object.keys(batchStatus)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only verify pending batches',
      })

    setMode('verify')
    setBatch({
      ...data,
      ...batch,
    })
  }

  const handleClose = () => {
    setMode(null)
    setBatch({
      batchAuthOption: Object.keys(batchAuthOptions)[0],
    })
    clearValidation('Remarks')
  }

  const handleVerify = async () => {
    if (!batch?.auditRemarks)
      return GlobalValidationErrors.set({
        Remarks: {
          message: 'Remarks is required',
        },
      })

    if (shortage === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'The shortage amount  should not be zero',
      })

    setIsBusy(true)
    const url = '/DebitBatch/audit-debit-batch'

    const { success } = await putData(
      url,
      {
        debitBatch: batch,
        batchAuthOption: batch.batchAuthOption,
      },
      false
    )

    if (success) {
      handleClose()
      setShortage(null)
      setReload(true)
      showNotification('Debit Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <VerifyDebitBatch
          batch={batch}
          handleClose={handleClose}
          mode={mode}
          setBatch={setBatch}
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          handleVerifyBatch={handleVerify}
          isBusy={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('verification'),
          localize('debit'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListDebitBatches
            showVerify
            getShortage={value => setShortage(value)}
            handleVerifyBatch={handleSelectBatch}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  )
}

export default Debit
