/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../Helpers/webApi'
import DatePicker from '../../../../Components/Date/DatePicker'
import { formatDate } from '../../../../Helpers/formatters'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import MoveAsset from './Partials/MoveAsset'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

const AssetMovement = () => {
  const columns = [
    {
      label: localize('tag'),
      template: r => <>{r.assetTagNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.assetItemRegisterDescription}</>,
    },
    {
      label: localize('sourceDepartment'),
      template: r => <>{r.sourceDepartmentDescription}</>,
    },
    {
      label: localize('destinationDepartment'),
      template: r => <>{r.destinationDepartmentDescription}</>,
    },
    {
      label: localize('sourceLocation'),
      template: r => <>{r.sourceLocationDescription}</>,
    },
    {
      label: localize('destinationlocation'),
      template: r => <>{r.destinationLocationDescription}</>,
    },
    {
      label: localize('sender'),
      template: r => <>{r.sender}</>,
    },
    {
      label: localize('recipient'),
      template: r => <>{r.recipient}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [asset, setAsset] = useState(null)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Asset/find-assets-movement-histories-by-filter-and-date-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAddAction = () => {
    setMode('move')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('asset'),
          localize('assetMovementHistories'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <button
                      onClick={() => handleAddAction()}
                      className="btn btn-success waves-effect waves-light text-capitalize"
                    >
                      <i className="mdi mdi-plus me-2"></i>
                      {localize('moveAsset')}
                    </button>
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row d-flex justify-content-between">
                    <RecordsPerPage
                      className="col-2"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-md-3">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-md-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-md-3"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="ChequeWritingCataloguesFilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable data={tableData} columns={columns} />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <MoveAsset
          mode={mode}
          close={() => {
            setMode(null)
            setAsset(null)
          }}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}

export default AssetMovement
