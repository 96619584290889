/** @format */
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { UsersInRoleACL } from './UsersInRoleACL'
import { ModulesACL } from './ModulesACL'
import { OperationsACL } from './OperationsACL'

export const ViewUserRoleRelationship = ({
  roleBeingViewed,
  usersInRoleDetails,
  modulesAccessControlListDetails,
  operationsAccessControlListDetails,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('roleName')}
          </label>
          <input
            className="form-control"
            disabled
            value={roleBeingViewed?.description}
          />
        </div>
      </div>
      <div className="row mb-3">
        <Tab
          tabItems={[
            {
              label: localize('usersInRole'),
              item: <UsersInRoleACL usersInRoleAcl={usersInRoleDetails} />,
            },
            {
              label: localize('modulesAccessControlList'),
              item: <ModulesACL modulesAcl={modulesAccessControlListDetails} />,
            },
            {
              label: localize('operationsAccessControlList'),
              item: (
                <OperationsACL
                  operationsAcl={operationsAccessControlListDetails}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  )
}
