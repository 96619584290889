/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const DataAttachmentRemarksModal = ({
  mode,
  selectedDataAttachementRemark,
  setSelectedDataAttachementRemark,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/DataAttachmentPeriod/update-data-attachment-remark',
            selectedDataAttachementRemark || {
              description: '',
            },
            false
          )
        : await postData(
            '/DataAttachmentPeriod/add-data-attachment-remark',
            selectedDataAttachementRemark || {
              description: '',
            },
            false
          )

    if (success) {
      closeModal()
      loadTableData()
      showNotification('Data Attachement Remark', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalTitle={'dataAttachmentRemark'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cDataAttachmentRemarkIsLocked"
              onCheck={e => {
                setSelectedDataAttachementRemark({
                  ...selectedDataAttachementRemark,
                  isLocked: e,
                })
              }}
              checked={selectedDataAttachementRemark?.isLocked}
            />
            <label
              htmlFor="cDataAttachmentRemarkIsLocked"
              className="text-capitalize mb-2"
            >
              {localize('isLocked')} ?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row mb-2 align-items-center g-3">
          <div
            className="with-validation d-flex align-items-center gap-2 mb-2"
            validation-message={globalValidationErrors['Description']?.message}
          >
            <label className="text-capitalize col-1">{localize('name')}</label>
            <input
              defaultValue={selectedDataAttachementRemark?.description}
              type="text"
              className="form-control"
              onChange={e => {
                clearValidation('Description')
                setSelectedDataAttachementRemark({
                  ...selectedDataAttachementRemark,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default DataAttachmentRemarksModal
