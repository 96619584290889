/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { dividendComputationFilter } from '../../../../../Global/enumeration'
import {
  formatAsPercent,
  formatCurrency,
} from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import InitiateDividendComputationModal from './InitiateDividendComputationModal/InitiateDividendComputationModal'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function DividendsComputation() {
  const [isBusy, setIsBusy] = useState(false)
  const [dividends, setDividends] = useState([])
  const [mode, setMode] = useState(null)
  const [dividend, setDividend] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    dividendComputationFilter: Object.keys(dividendComputationFilter)[16],
  })
  const [groupBy, setGroupBy] = useState({
    label: localize('customerName'),
    column: 'customerFullName',
  })

  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => r.fullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r => r.productDescription,
    },
    {
      label: localize('customerType'),
      template: r =>
        r.customerAccountCustomerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r.customerAccountCustomerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r.customerAccountCustomerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r.customerAccountCustomerReference3,
    },
    {
      label: localize('postingPeriod'),
      template: r => r.postingPeriodDescription,
    },
    {
      label: localize('balance'),
      template: r => formatCurrency(r.balance),
    },
    {
      label: localize('dividendsValue'),
      template: r => formatCurrency(r.dividendsValue),
    },
    {
      label: localize('interestRate'),
      template: r => formatAsPercent(r.interestRate),
    },
    {
      label: localize('withholdingTax'),
      template: r => formatAsPercent(r.withholdingTax),
    },
  ]

  const groupingColumns = [
    {
      label: localize('customerName'),
      column: 'customerFullName',
    },
    {
      label: localize('branch'),
      column: 'customerAccountBranchDescription',
    },
    {
      label: localize('accountNumber'),
      column: 'customerAccountCustomerReference1',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('recordStatus'),
      column: 'recordStatusDescription',
    },
    {
      label: localize('createdBy'),
      column: 'createdBy',
    },
  ]

  const fetchDividends = async () => {
    setIsBusy(true)
    const url = '/DividendComputation/find-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setDividends(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleClickAddButton = () => {
    setMode('edit')
    setDividend(null)
  }

  const handleClose = () => {
    setMode(null)
    setDividend(null)
  }

  const handleUpdatePeriod = async () => {
    setIsBusy(true)
    const url = '/PostingPeriod/compute-posting-period-dividend'

    const { success } = await putData(
      url,
      dividend,
      false,
      'Dividend Computation updated successfully.'
    )

    if (success) {
      setIsBusy(false)
      handleClose()
      fetchDividends()
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchDividends()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.dividendComputationFilter,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  return (
    <>
      {mode && (
        <InitiateDividendComputationModal
          mode={mode}
          dividend={dividend}
          handleAction={handleUpdatePeriod}
          handleClose={handleClose}
          setDividend={setDividend}
        />
      )}
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('recurringProcedures'),
          localize('dividendsComputation'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleClickAddButton} />
                </div>
              </div>
              <div className="row mb-2 py-3 px-2 d-flex align-items-center">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="recordsPerPage"
                      className="text-capitalize"
                      style={{ marginLeft: -10, textTransform: 'capitalize' }}
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="recordsPerPage"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="groupByDividends"
                      className="text-capitalize"
                    >
                      {localize('groupBy')}
                    </label>
                    <select
                      id="groupByDividends"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={groupBy.column}
                      onChange={e => {
                        setGroupBy({
                          label: groupingColumns.find(
                            f => f.column === e.target.value
                          )?.label,
                          column: e.target.value,
                        })
                      }}
                    >
                      {groupingColumns
                        .sort((a, b) => a?.label?.localeCompare(b?.label))
                        .map(({ label, column }) => (
                          <option
                            key={`group_dividends-c_by_${column}`}
                            className="text-capitalize"
                            value={column}
                          >
                            {label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="d-flex align-items-center justify-content-end">
                    <label htmlFor="search" className="text-capitalize">
                      {localize('search')}
                    </label>

                    <div className="d-flex align-item-center row">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="DividendComputationFilter"
                        value={reqParams.dividendComputationFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            dividendComputationFilter: e.value,
                          })
                        }}
                        sort
                      />
                    </div>

                    <input
                      type="search"
                      id="search"
                      className="form-control col ms-2"
                      value={reqParams.filterText}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          fetchDividends()
                        }
                      }}
                    />
                    <button
                      id="FilterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        fetchDividends()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={dividends.pageCollection}
                />
              </div>
              <Pager
                itemsCount={dividends.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default DividendsComputation
