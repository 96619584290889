/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import { LoansGuaranteed } from '../../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import InvestmentAccounts from '../../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import {
  ProductCode,
  customerAccountStatus,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'

const initialCustomerAccountValues = {
  customerLoanAccounts: [],
  customerSavingsAccounts: [],
  customerInvestmentAccounts: [],
}

const initialBalanceValues = {
  netPartialRefundable: '',
  netLoansBalance: '',
  netRefundable: '',
  netInvestmentsBalance: '',
  backOfficeInvestmentsBalance: '',
  backOfficeLoansBalance: '',
}

const CreateAccountClosureRequest = ({
  handleClose,
  mode,
  fetchAccountClosureRequests,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [customerAccount, setCustomerAccount] = useState({})
  const [loadAccounts, setLoadAccounts] = useState(false)
  const [loansGuaranteed, setLoansGuaranteed] = useState([])
  const [customerAccounts, setCustomerAccounts] = useState(
    initialCustomerAccountValues
  )
  const [balance, setBalance] = useState(initialBalanceValues)

  const handleCustomerAccountLookup = account => {
    if (account?.status === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Selected customer account is already closed!',
      })

    if (account?.customerAccountTypeProductCode !== Object.keys(ProductCode)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Selected customer account is not a savings account!',
      })

    setCustomerAccount(account)
  }

  const fetchCustomerAccounts = async () => {
    setLoadAccounts(true)

    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customerAccount?.customerId,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)
      setLoadAccounts(false)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    } else setLoadAccounts(false)
  }

  const fetchLoansGuaranteed = async () => {
    setLoadAccounts(true)
    const url = '/LoanCase/find-loan-guarantors-by-loanee-customer-id'

    const { success, data } = await getData(
      url,
      {
        loaneeCustomerId: customerAccount?.customerId,
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      setLoadAccounts(false)
      setLoansGuaranteed(data?.result)
    } else {
      setLoadAccounts(false)
    }
  }

  const handleCreateAccountClosureRequest = async () => {
    setIsBusy(true)

    const url = '/AccountClosureRequest/add-account-closure-request'

    const { success } = await postData(
      url,
      {
        ...customerAccount,
        customerAccountId: customerAccount?.id,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      fetchAccountClosureRequests()
      handleClose()
      setCustomerAccount({})
      setCustomerAccounts(initialCustomerAccountValues)
      setBalance(initialBalanceValues)
      showNotification('Create Account Closure Request', 'success')
    } else setIsBusy(false)
  }

  const handleClickCloseButton = () => {
    handleClose()
    setCustomerAccount({})
    setCustomerAccounts(initialCustomerAccountValues)
    setBalance(initialBalanceValues)
  }

  useEffect(() => {
    if (customerAccount?.customerId) {
      fetchCustomerAccounts()
      fetchLoansGuaranteed()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccount?.customerId])

  return (
    <ModalTemplate
      modalTitle="createAccountClosureRequest"
      handleAction={handleCreateAccountClosureRequest}
      cancelText="close"
      modalMode={mode}
      actionText="create"
      handleClose={handleClickCloseButton}
      modalClassName="modal-xl"
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            onCustomerLookup={handleCustomerAccountLookup}
            customer={customerAccount}
          />

          <div className="row mt-2 g-2">
            <div className="col-md-12 d-flex align-items-center">
              <label className="text-capitalize col-1">
                {localize('reason')}
              </label>
              <input
                type="text"
                className="form-control ms-1"
                placeholder="Enter reason for account closure"
                value={customerAccount?.reason}
                onChange={e =>
                  setCustomerAccount({
                    ...customerAccount,
                    reason: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('loansAccounts'),
                  item: (
                    <>
                      {loadAccounts ? (
                        <Loader />
                      ) : (
                        <LoanAccounts
                          data={customerAccounts?.customerLoanAccounts?.filter(
                            x => x?.bookBalance < 0
                          )}
                        />
                      )}
                    </>
                  ),
                },
                {
                  label: localize('investmentAccounts'),
                  item: (
                    <>
                      {loadAccounts ? (
                        <Loader />
                      ) : (
                        <InvestmentAccounts
                          data={customerAccounts?.customerInvestmentAccounts}
                        />
                      )}
                    </>
                  ),
                },
                {
                  label: localize('loansGuaranteed'),
                  item: (
                    <>
                      {loadAccounts ? (
                        <Loader />
                      ) : (
                        <LoansGuaranteed loanGuarantors={loansGuaranteed} />
                      )}
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('deposits(Refundable)')}
                </label>

                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    balance?.netInvestmentsBalance,
                    null,
                    null,
                    false
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('loansBalance')}
                </label>

                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    balance?.netLoansBalance,
                    null,
                    null,
                    false
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('netRefundable')}
                </label>

                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    balance?.netRefundable,
                    null,
                    null,
                    false
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateAccountClosureRequest
