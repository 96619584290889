/** @format */
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import CustomerAccountLookup from '../../../../../Components/Lookup/CustomerAccountLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import { IntraAccountTransferApportionment } from './Partials/IntraAccountTransferApportionment'
import { IntraAccountTransferIndefiniteCharges } from './Partials/IntraAccountTransferIndefiniteCharges'
import { getDynamicCharges } from '../../../../../Helpers/selectablesHelper'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { putData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'

function IntraAccountTransfer() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [indefiniteCharges, setIndefiniteCharges] = useState([])
  const [updateClicked, setUpdateClicked] = useState(false)

  useEffect(() => {
    getIndefiniteCharges()
  }, [])

  const getIndefiniteCharges = async () => {
    setIndefiniteCharges(await getDynamicCharges())
  }

  const handleOnChangeValueDate = async e => {
    setIsBusy(true)
    const { success, data } = await putData(
      `/PostingPeriod/evaluate-value-date-validity/?valueDate=${e}`,
      {},
      false
    )
    if (success) {
      setIsBusy(false)
      setSelectedCustomerAccount({ ...selectedCustomerAccount, valueDate: e })
    } else {
      setIsBusy(false)
      setSelectedCustomerAccount({
        ...selectedCustomerAccount,
        valueDate: null,
      })
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('intraAccountTransfer'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('fullAccountNumber')}
                      </label>
                      <CustomerAccountLookup
                        onSelect={r => {
                          setSelectedCustomerAccount(r)
                        }}
                        displayValue={selectedCustomerAccount?.customerFullName}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('accountStatus')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={selectedCustomerAccount?.statusDescription}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('remarks')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={selectedCustomerAccount?.remarks}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('availableBalance')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={formatCurrency(
                          selectedCustomerAccount?.availableBalance,
                          null,
                          null,
                          false
                        )}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('productName')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.customerAccountTypeTargetProductDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('customerName')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={selectedCustomerAccount?.customerFullName}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('customerType')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.customerMembershipClassCustomerTypeDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('customerMemberNo')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.paddedCustomerSerialNumber
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('employerName')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.customerEmployerDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('identificationNumber')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.customerIdentificationNumber
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('memberNumber')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.formattedCustomerMemberNumber
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('accountNumber')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.formattedCustomerAccountNumber
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-4 d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('personalFileNumber')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedCustomerAccount?.customerPersonalFileNumber
                        }
                      />
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('valueDate')}
                      </label>
                      <CustomDatePicker
                        name={'value-date-intra'}
                        defaultDate={selectedCustomerAccount?.valueDate}
                        onDateChange={handleOnChangeValueDate}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <Tab
                      preload
                      tabItems={[
                        {
                          label: localize('apportionment'),
                          item: (
                            <IntraAccountTransferApportionment
                              indefiniteCharges={indefiniteCharges}
                              updateClicked={updateClicked}
                              setUpdateClicked={setUpdateClicked}
                              selectedCustomerAccount={selectedCustomerAccount}
                            />
                          ),
                        },
                        {
                          label: localize('indefiniteCharges'),
                          item: (
                            <IntraAccountTransferIndefiniteCharges
                              indefiniteCharges={indefiniteCharges}
                              setIndefiniteCharges={setIndefiniteCharges}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <div
                    onClick={() => {
                      setUpdateClicked(true)
                    }}
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button className="btn btn-success text-uppercase ms-3 px-4">
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default IntraAccountTransfer
