/** @format */

import { useState } from 'react'
import localize from '../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import SimpleTable from '../../Tables/SimpleTable'
import ViewCustomerAccountStatementModal from './CustomerAccountStatement/ViewCustomerAccountStatementModal'

export const ViewCustomerInvestmentAccounts = ({
  customerInvestmentAccounts,
  handleExecuteStandingOrder,
  hideContextMenu,
  canShowStatement,
}) => {
  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r?.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: `${localize('isRefundable')} ?`,
      template: r => (
        <span className="text-capitalize">
          {r?.customerAccountTypeTargetProductIsRefundable?.toString()}
        </span>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r?.bookBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [account, setAccount] = useState({})
  const [showStatement, setShowStatement] = useState(false)

  const handleViewStatement = r => {
    setAccount(r)
    setShowStatement(true)
  }

  const handleClose = () => {
    setShowStatement(false)
    setAccount({})
  }

  return (
    <>
      {showStatement && (
        <ViewCustomerAccountStatementModal
          customer={{
            id: account?.customerId,
          }}
          customerAccount={account}
          handleClose={handleClose}
        />
      )}
      <SimpleTable
        data={customerInvestmentAccounts}
        columns={columns}
        contextMenu={r => (
          <>
            {!hideContextMenu && (
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleExecuteStandingOrder(r)
                }}
              >
                <i className="uil uil-bolt text-warning"></i>
                <span>{localize('executeStandingOrder')}</span>
              </div>
            )}
            {canShowStatement && (
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleViewStatement(r)
                }}
              >
                <i className="uil uil-eye"></i>
                <span>{localize('view')}</span>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
