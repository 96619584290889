/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'

const InventoryMovementHistories = ({ inventory }) => {
  const [reqParams, setReqParams] = useState({
    filter: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const columns = [
    {
      label: localize('details'),
      template: r => <>{r.details}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{formatCurrency(r.quantity)}</>,
    },
    {
      label: localize('unitCost'),
      template: r => <>{formatCurrency(r.unitCost)}</>,
    },
    {
      label: localize('balance'),
      template: r => <>{formatCurrency(r.balance)}</>,
    },
    {
      label: localize('source'),
      template: r => <>{r.source}</>,
    },
    {
      label: localize('destination'),
      template: r => <>{r.destination}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [search, setSearch] = useState('')
  const [entriesCount, setEntriesCount] = useState(null)

  const loadMovementHistories = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/InventoryMovementHistory/find-inventory-movement-histories-by-inventory-id-and-filter-in-page`,
      { inventoryId: inventory?.id, ...reqParams },
      false
    )
    if (success) {
      setIsBusy(false)
      setEntries(data.pageCollection)
      setEntriesCount(data.itemsCount)
    } else {
      setIsBusy(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadMovementHistories()
    // eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row d-flex justify-content-between mb-2">
            <RecordsPerPage
              className="col-3"
              reqParams={reqParams}
              setReqParams={setReqParams}
            />
            <form
              className="d-flex align-items-center col-md-3"
              onSubmit={e => {
                e.preventDefault()
                setReqParams({
                  ...reqParams,
                  filter: search,
                })
              }}
            >
              <label className="text-capitalize" htmlFor="searchTable">
                {localize('search')}
              </label>
              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                defaultValue={reqParams.filter}
                onChange={e => {
                  setSearch(e.target.value)
                  setReqParams({
                    ...reqParams,
                    filter: e.target.value,
                  })
                }}
              />
              <button
                id="ChequeWritingCataloguesFilterButton"
                type="button"
                className="btn btn-primary ms-1"
                onClick={() => {
                  loadMovementHistories()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>
          <SimpleTable columns={columns} data={entries} />
          <div className="row col-12 mt-2">
            <Pager
              itemsCount={entriesCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </div>
        </>
      )}
    </>
  )
}

export default InventoryMovementHistories
