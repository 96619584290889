/** @format */

import React from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'

const EmployeeTypeLoanProducts = ({
  disabled,
  isBusy,
  selectedEmployeeType,
  setSelectedEmployeeType,
  prefix,
}) => {
  const handleCheckOffsetableLoanProducts = (loanProducts, checkValue) => {
    const com = [...selectedEmployeeType?.loanProductCollection].map(comm => {
      if (comm.id === loanProducts.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedEmployeeType({
      ...selectedEmployeeType,
      loanProductCollection: com,
    })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedEmployeeType?.loanProductCollection.map(loanProducts => (
            <li
              key={loanProducts.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${loanProducts.id}`}
                checked={loanProducts.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckOffsetableLoanProducts(loanProducts, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${loanProducts.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  loanProducts.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {loanProducts.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default EmployeeTypeLoanProducts
