/** @format */

import { CONFIG } from './constants'
import signalRService from './signalR'

export function pingCompanionApp() {
  return fetch(CONFIG.COMPANION_APP_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.json()
    })
    .then(data => {
      signalRService.setMachineInformation(data.machineInformation)
      return data
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
