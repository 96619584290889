/** @format */

import localize from '../../../Global/Localize'
/* eslint-disable */
import React from 'react'
import { parseDate } from '../../../Helpers/formatters'

export const ViewCustomer = ({
  customer,
  setCustomRemarks,
  customRemarks,
  remarksVisible,
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('name')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.fullName || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.membershipClassCustomerTypeDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.formattedMemberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employer')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.employerDescription || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('dutyStation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.dutyStationDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.identificationNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('administrativeDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.administrativeDivisionDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registrationDate')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={parseDate(customer?.registrationDate) || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.branchDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.formattedMemberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.formattedAccountNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.personalFileNumber || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('ref_1(Account #)')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.reference1 || ''}
          />
        </div>
        {remarksVisible && (
          <>
            <div className="col-md-9">
              <div className="d-flex align-items-center mb-2">
                <label className="col-1  text-capitalize me-2">
                  {localize('remarks')}
                </label>
                <input
                  value={customRemarks?.remarks}
                  type="text"
                  className="form-control ms-3"
                  onChange={e => {
                    setCustomRemarks({
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
