/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'

export const TellerChartOfAccountStatement = ({ onFetchTellerStatements }) => {
  const [tellerStatments, setTellerStatments] = useState(null)
  const [base64Receipt, setBase64Receipt] = useState('')
  const [showReceiptPreview, setShowReceiptPreview] = useState(false)
  const [loadingTellersStatments, setLoadingTellersStatments] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    text: '',
    journalEntryFilter: '2',
    transactionDateFilter: '2',
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [search, setSearch] = useState('')

  const fetchTellerStatements = async () => {
    setLoadingTellersStatments(true)
    const { success, data } = await getData(
      '/lookup/Teller/find-teller-transactions-by-date-range-and-filter-in-page',
      {
        ...reqParams,
      },
      false,
      null
    )
    if (success) {
      setLoadingTellersStatments(false)
      setTellerStatments(data)
      onFetchTellerStatements({
        newTellerBalance: data.newTellerBalance,
        previousTellerBalance: data.previousTellerBalance,
      })
    }
  }

  useEffect(() => {
    fetchTellerStatements()
  }, [reqParams.pageSize, reqParams.pageIndex, reqParams.customDate])

  const handleOnPrint = async r => {
    const { success, data } = await getData(
      '/Base64/get-media-by-sku',
      {
        sku: r.journalId,
      },
      false,
      null
    )
    if (success) {
      if (data.content) {
        downloadBase64File(data.content, 'receipt.txt')
      } else {
        Swal.fire(
          'Teller/Savings Receipt Payments',
          'Sorry,but the selected transaction has no receipt',
          'error'
        )
      }
    }
  }

  const handleOnViewReceipt = async r => {
    const { success, data } = await getData(
      '/lookup/Teller/get-teller-receipt',
      {
        sku: r.journalId,
      },
      false,
      null
    )
    if (success) {
      if (data.content) {
        setBase64Receipt(data.content)
        setShowReceiptPreview(true)
      } else {
        Swal.fire(
          'Teller/Savings Receipt Payments',
          'Sorry,but the selected transaction has no receipt',
          'error'
        )
      }
    }
  }

  const downloadBase64File = (base64, fileName) => {
    const link = document.createElement('a')
    link.href = `data:text/plain;base64,${base64}`
    link.download = fileName
    link.click()
  }

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: localize('debit'), template: r => <>{formatCurrency(r.debit)}</> },
    {
      label: localize('credit'),
      template: r => <>{formatCurrency(r.credit)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.journalReference}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('transactionCode'),
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.journalValueDate)}</>,
    },
    {
      label: localize('localCurrency'),
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    {
      label: localize('debitCredit'),
      template: r => <>{formatCurrency(r.debitCredit)}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
  ]
  return (
    <>
      <div className="row p-2">
        <div className="row bg-light p-2 m-1 mb-2" style={{ borderRadius: 5 }}>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize">
              {localize('pickDate')}
            </label>
            <DatePicker
              onSelect={v => {
                if (v.custom) {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                  })
                } else {
                  setReqParams({
                    ...reqParams,
                    customDate: false,
                    dateRequestsFilter: v.value,
                  })
                }
              }}
            />
            <EnumsServerCombo
              value={reqParams?.journalEntryFilter || ''}
              customContainerStyle={{ width: '100%' }}
              enumsKey={'TransactionDateFilter'}
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  transactionDateFilter: e.value,
                  pageIndex: 0,
                })
              }}
            />
            <form
              className="d-flex align-items-center col-3 col"
              onSubmit={e => {
                e.preventDefault()
                setReqParams({ ...reqParams, text: search })
              }}
            >
              <input
                style={{ width: 200 }}
                type="search"
                className="form-control"
                placeholder={localize('search') + '...'}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <button
                id="FilterButton"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  fetchTellerStatements()
                }}
              >
                <i className="mdi mdi-filter-variant"></i>
              </button>
            </form>
          </div>

          <div className="py-1 d-flex gap-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 mt-2">
              <label
                className="text-capitalize"
                style={{ width: 'fit-content' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select"
                style={{ width: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e =>
                  setReqParams({ ...reqParams, pageSize: e.target.value })
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="flex-1"></div>
          </div>
        </div>

        {loadingTellersStatments ? (
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait</span>
          </div>
        ) : (
          <>
            <SimpleTable
              selectables
              contextMenu={e => (
                <>
                  <div
                    onClick={() => {
                      handleOnPrint(e)
                    }}
                    className="ctxt-menu-item text-capitalize"
                  >
                    <i className="fas fa-print"></i>
                    {localize('print')} {localize('receipt')}
                  </div>
                  <div
                    onClick={() => {
                      handleOnViewReceipt(e)
                    }}
                    className="ctxt-menu-item text-capitalize"
                  >
                    <i className=" fas fa-eye"></i>
                    {localize('view')} {localize('receipt')}
                  </div>
                </>
              )}
              columns={columns}
              data={tellerStatments?.generalLedgerTransactions?.pageCollection}
            />
            <div className="mt-3">
              <Pager
                itemsCount={
                  tellerStatments?.generalLedgerTransactions?.itemsCount
                }
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </>
        )}
      </div>
      {showReceiptPreview && (
        <ViewCustomerReceipt
          base64={base64Receipt}
          show={showReceiptPreview}
          setShow={setShowReceiptPreview}
        />
      )}
    </>
  )
}

const ViewCustomerReceipt = ({ base64, show, setShow }) => {
  return (
    <>
      <div
        className={`view-specimen ${show ? 'shown' : ''}`}
        onClick={() => {
          setShow(false)
        }}
      >
        <div className="close-it" onClick={() => setShow(false)}>
          <i className="uil uil-times"></i>
        </div>
        <img
          onClick={e => e.stopPropagation()}
          src={base64}
          alt=""
          style={{
            borderRadius: 5,
            objectFit: 'contain',
            boxShadow: 'black 0 0 50px',
          }}
        />{' '}
      </div>
    </>
  )
}
