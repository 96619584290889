/** @format */

import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { CreateCreditBatchModal } from './CreateCreditBatchModal'
import { EditCreditBatchModal } from './EditCreditBatchModal'
import { ViewCreditBatchModal } from './ViewCreditBatchModal'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import { useEffect, useState } from 'react'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import { postData } from '../../../../../../../Helpers/webApi'
import {
  ChargeType,
  QueuePriority,
  months, CreditBatchType
} from '../../../../../../../Global/enumeration'

function CreditBatchModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  setSelectedBatchEntry,
  onSetMode,
  selectedBatchEntry,
}) {
  const [finalCreateBatchModel, setFinalCreateBatchModel] = useState({
    concessionType: Object.keys(ChargeType)[0],
    month: Object.keys(months)[0],
    queuePriority: Object.keys(QueuePriority)[3],
    type: Object.keys(CreditBatchType)[0],
  })
  const [gbv, setGbv] = GlobalValidationErrors.use()

  useEffect(() => {
    setGbv({})
  }, [mode])

  const clearValidation = column => {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  async function handleSubmit() {
    const { success } = await postData(
      '/CreditBatch/create-credit-batch',
      {
        ...finalCreateBatchModel,
      },
      true,
      'CreditBatch'
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('creditBatch')}
            handleAction={handleSubmit}
            hideUpdate={mode === 'view' || mode === 'edit'}
            footerChildren={
              mode === 'add' && (
                <div className="d-flex align-content-end justify-content-end">
                  <div className="d-flex align-items-center me-5">
                    <Checkbox
                      id={'preserveAccountBalance'}
                      checked={finalCreateBatchModel?.preserveAccountBalance}
                      onCheck={v =>
                        setFinalCreateBatchModel({
                          ...finalCreateBatchModel,
                          preserveAccountBalance: v,
                        })
                      }
                    />
                    <label
                      htmlFor="preserveAccountBalance"
                      className="text-capitalize ms-2"
                    >
                      {localize('preserveAccountBalance')}?
                    </label>
                  </div>
                </div>
              )
            }
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateCreditBatchModal
                validationBag={gbv}
                clearValidation={clearValidation}
                finalCreateBatchModel={finalCreateBatchModel}
                setFinalCreateBatchModel={setFinalCreateBatchModel}
              />
            )}
            {mode === 'edit' && (
              <EditCreditBatchModal
                setSelectedBatchEntry={setSelectedBatchEntry}
                selectedBatchEntry={selectedBatchEntry}
              />
            )}
            {mode === 'view' && (
              <ViewCreditBatchModal selectedCreditBatch={selectedBatchEntry} />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default CreditBatchModal
