/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React from 'react'

export const ChequeBookLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('paymentVoucherLookup')}
      url="/lookup/ChequeBook/find-cheque-books-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('serialNumber'),
          template: r => <>{r.paddedSerialNumber}</>,
        },
        {
          label: localize('isActive'),
          template: r => (
            <span className="text-capitalize">{r?.isActive.toString()}</span>
          ),
        },
        {
          label: localize('initialVoucherNumber'),
          template: r => <>{r.initialVoucherNumber}</>,
        },
        {
          label: localize('numberOfVouchers'),
          template: r => <>{r.numberOfVouchers}</>,
        },
        {
          label: localize('fullAccountNumber'),
          template: r => <>{r.fullAccountNumber}</>,
        },
        {
          label: localize('customerName'),
          template: r => <>{r.customerAccountCustomerFullName}</>,
        },
        {
          label: localize('ref_1(Account #)'),
          template: r => <>{r.customerAccountCustomerReference1}</>,
        },
        {
          label: localize('ref_2(Membership #)'),
          template: r => <>{r.customerAccountCustomerReference2}</>,
        },
        {
          label: localize('ref_3(Personal File #)'),
          template: r => <>{r.customerAccountCustomerReference3}</>,
        },
        { label: localize('remarks'), template: r => <>{r.remarks}</> },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">{r.isLocked.toString()}</span>
          ),
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
