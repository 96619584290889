/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import { formatDate, getTodaysDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../../Components/Loaders/Loader'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import { PurchaseOrderModal } from './Partials/PurchaseOrderModal'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'

function PurchaseOrdersOrigination() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)
  const [sortedBy, setSortedBy] = useState(null)

  const columns = [
    {
      label: localize('referenceNumber'),
      template: r => <>{r.paddedReferenceNumber}</>,
    },
    {
      label: localize('supplier'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('supplierMobileLine'),
      template: r => <>{r.supplierAddressMobileLine}</>,
    },
    {
      label: localize('supplierEmail'),
      template: r => <>{r.supplierAddressEmail}</>,
    },
    {
      label: localize('expectedDate'),
      template: r => <>{formatDate(r.expectedDate)}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    recordStatus: '0',
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PurchaseOrder/find-purchase-orders-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnEdit = r => {
    setSelectedPurchaseOrder({ ...r })
    setMode('edit')
  }

  const handleOnView = r => {
    setSelectedPurchaseOrder({ ...r })
    setMode('view')
  }

  const handleOnAdd = async r => {
    setSelectedPurchaseOrder({
      expectedDate: getTodaysDate(),
    })
    setMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementsAndAssetManagement'),
          localize('procurement'),
          localize('purchaseOrders'),
          localize('origination'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3">
                      <div
                        className="d-flex align-items-center me-5"
                        style={{ width: '300px' }}
                      >
                        <label className="col-3 text-capitalize">
                          {localize('pickDate')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="WorkflowRecordStatus"
                        value={reqParams.recordStatus}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            recordStatus: e.value,
                            pageIndex: 0,
                          })
                        }}
                        sort
                      />
                    </div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-search"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PurchaseOrderModal
        selectedPurchaseOrder={selectedPurchaseOrder}
        setSelectedPurchaseOrder={setSelectedPurchaseOrder}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
        onSetMode={m => {
          setMode(m)
          setSelectedPurchaseOrder(null)
        }}
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
      />
    </>
  )
}

export default PurchaseOrdersOrigination
