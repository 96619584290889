/** @format */
import localize from '../../../../Global/Localize'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import TimePicker from '../../../../Components/Date/TimePicker'
import Tab from '../../../../Components/Tabs/Tab'
import { AuthorizedRoles } from './AuthorizedRoles'
import Loader from '../../../../Components/Loaders/Loader'

export const CreateTemporaryRole = ({
  selectedTemporaryRole,
  setSelectedTemporaryRole,
  globalValidation,
  waitingMetadata,
  isBusy,
  roles,
  setRoles,
  clearGlobalValidation,
}) => {
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              validation-message={globalValidation?.EmployeeId?.message}
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3">{localize('employee')}</label>
              <EmployeeLookup
                displayValue={selectedTemporaryRole?.customerFullName}
                onSelect={r => {
                  clearGlobalValidation('EmployeeId')
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    customerFullName: r.customerFullName,
                    branchDescription: r.branchDescription,
                    designationDescription: r.designationDescription,
                    departmentDescription: r.departmentDescription,
                    employeeId: r.id,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedTemporaryRole?.branchDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label
                style={{ wordBreak: 'break-word' }}
                className="col-3 text-capitalize"
              >
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedTemporaryRole?.designationDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label
                style={{ wordBreak: 'break-word' }}
                className="col-3 text-capitalize"
              >
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedTemporaryRole?.departmentDescription || ''}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              validation-message={globalValidation?.DurationStartDate?.message}
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('startDate')}
              </label>
              <CustomDatePicker
                onDateChange={e => {
                  clearGlobalValidation('DurationStartDate')
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    durationStartDate: e,
                  })
                }}
                defaultDate={
                  selectedTemporaryRole?.durationStartDate || new Date()
                }
                name={'temporary-role-startDate'}
              />
            </div>
            <div
              validation-message={
                globalValidation?.TimeDurationStartTime?.message
              }
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('startTime')}
              </label>
              <TimePicker
                name={'startTime'}
                defaultTime={
                  selectedTemporaryRole?.timeDurationStartTime || new Date()
                }
                onTimeChange={c => {
                  clearGlobalValidation('TimeDurationStartTime')
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    timeDurationStartTime: c,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('endTime')}
              </label>
              <TimePicker
                name={'endTime'}
                defaultTime={
                  selectedTemporaryRole?.timeDurationEndTime || new Date()
                }
                onTimeChange={c => {
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    timeDurationEndTime: c,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('endDate')}
              </label>
              <CustomDatePicker
                onDateChange={e => {
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    durationEndDate: e,
                  })
                }}
                defaultDate={
                  selectedTemporaryRole?.durationEndDate || new Date()
                }
                name={'temporary-role-endDate'}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              validation-message={globalValidation?.Reason?.message}
              className="col d-flex align-items-center with-validation"
            >
              <label
                style={{ width: 5.9 + '%' }}
                className="col-1 text-capitalize"
              >
                {localize('reason')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedTemporaryRole?.reason}
                onChange={e => {
                  clearGlobalValidation('Reason')
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    reason: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('temporaryRoles'),
                    item: (
                      <AuthorizedRoles
                        isBusy={waitingMetadata}
                        prefix={'temp-role-check'}
                        rolesToBeSelected={roles}
                        onCheckRole={r => {
                          setRoles(r)
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
