/** @format */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { ViewCustomerAccountStatement } from '../../../../../Components/ReUsables/Customer/CustomerAccountStatement/ViewCustomerAccountStatement'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'

function ViewFixedDeposit({ mode, close, selectedFixedDeposit }) {
  const [customer, setCustomer] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadCustomerTableData()
    loadCustomerAccountTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadCustomerTableData() {
    setLoading(true)
    const { success, data } = await getData(
      `/Customer/find-customer-by-id?customerId=${selectedFixedDeposit?.customerAccountCustomerId}`,
      {},
      false
    )
    if (success) {
      setCustomer(data || {})
    }
    setLoading(false)
  }

  async function loadCustomerAccountTableData() {
    setLoading(true)
    const { success, data } = await getData(
      `/CustomerAccount/find-customer-account?customerAccountId=${
        selectedFixedDeposit?.customerAccountId
      }&includeBalances=${true}&includeProductDescription=${true}`,
      {},
      false
    )
    if (success) {
      setCustomerAccount(data || {})
    }
    setLoading(false)
  }
  function closeModal() {
    close()
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="fixedDeposits"
        handleClose={closeModal}
        modalClassName="modal-xl"
        hideCancel
        hideUpdate
      >
        <div className="mt-3">
          <Tab
            tabItems={[
              {
                label: localize('fixedDepositDetails'),
                item: (
                  <>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('fixedDepositType')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedFixedDeposit?.fixedDepositTypeDescription ||
                                ''
                              }
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('fixedDepositNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.paddedSerialNumber || ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('fullAccountNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.customerAccountFullAccountNumber ||
                                ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('customerName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerFullName ||
                                ''
                              }
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('customerType')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('identityCardNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerIndividualIdentityCardNumber ||
                                ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('accountNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerReference1 ||
                                ''
                              }
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('membershipNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerReference2 ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('personalFileNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.customerAccountCustomerReference3 ||
                                ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('category')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedFixedDeposit?.categoryDescription || ''
                              }
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('value')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                formatCurrency(selectedFixedDeposit?.value) ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('annualPercentageRate')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                formatAsPercent(selectedFixedDeposit?.rate) ||
                                ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('term(month)')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedFixedDeposit?.term || ''}
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('maturityAction')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.maturityActionDescription ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('remarks')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={selectedFixedDeposit?.remarks || ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('createdBy')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedFixedDeposit?.createdBy || ''}
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('createdDate')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                formatDate(selectedFixedDeposit?.createdDate) ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <Checkbox
                              id="fixedDepositIsLocked"
                              disabled
                              checked={selectedFixedDeposit?.isLocked}
                            />

                            <label
                              htmlFor="fixedDepositIsLocked"
                              className="text-capitalize col-3"
                            >
                              {localize('isLocked')}
                            </label>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('verifiedBy')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedFixedDeposit?.auditedBy || ''}
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('verifiedDate')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                formatDate(selectedFixedDeposit?.auditedDate) ||
                                ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('verificationRemarks')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.verificationRemarks || ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('authorizedBy')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedFixedDeposit?.authorizedBy || ''}
                              disabled
                            />
                          </div>

                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('authorizedDate')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                formatDate(
                                  selectedFixedDeposit?.authorizedDate
                                ) || ''
                              }
                              disabled
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-3 text-capitalize">
                              {localize('authorizationRemarks')}
                            </label>
                            <input
                              type="text"
                              className="form-control me-3"
                              value={
                                selectedFixedDeposit?.authorizationRemarks || ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ),
              },
              {
                label: localize('accountStatement'),
                item: (
                  <>
                    <ViewCustomerAccountStatement
                      selectedCustomerAccount={customerAccount}
                      selectedCustomer={customer}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewFixedDeposit
