/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import MembershipClassLookup from '../../../../../Components/Lookup/MembershipClassLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import EmployerLookup from '../../../../../Components/Lookup/EmployerLookup'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import DividendCodeLookup from '../../../../../Components/Lookup/DividendCodeLookup'
import WorkstationLookup from '../../../../../Components/Lookup/WorkstationLookup'
import ElectoralZonesLookup from '../../../../../Components/Lookup/ElectoralZonesLookup'
import { parseDate } from '../../../../../Helpers/formatters'
import {
  CustomerTypes,
  customerMembershipStatus,
} from '../../../../../Global/enumeration'
import AdministrativeDivisionLookup from '../../../../../Components/Lookup/AdministrativeDivisionLookup'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import IndividualParticulars from '../Tabs/Particulars/IndividualParticulars'
import PartnershipParticulars from '../Tabs/Particulars/PartnershipParticulars'
import CorporationParticulars from '../Tabs/Particulars/CorporationParticulars'
import MicrocreditParticulars from '../Tabs/Particulars/MicrocreditParticulars'
import Address from '../Tabs/Address'
import Specimen from '../Tabs/Specimen'
import Referees from '../Tabs/Referees'
import Associates from '../Tabs/Associates'
import BankAccounts from '../Tabs/BankAccounts'
import CreditTypes from '../Tabs/CreditTypes'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import RelationshipManagerLookup from '../../../../../Components/Lookup/RelationshipManagerLookup'
import axios from 'axios'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function EditCustomer({ mode, customer, hideModal, refreshCustomers }) {
  const editMode = mode === 'edit'
  const [customerType, setCustomerType] = useState(
    customer?.membershipClassCustomerType
  )
  const [selectedCustomer, setSelectedCustomer] = useState(customer)
  const [partnerMembers, setPartnerMembers] = useState([])
  const [corporationMembers, setCorporationMembers] = useState([])

  const customerTypeParticular = {
    0: (
      <IndividualParticulars
        customer={selectedCustomer}
        onChange={cst => setSelectedCustomer({ ...selectedCustomer, ...cst })}
      />
    ),
    1: (
      <PartnershipParticulars
        customer={selectedCustomer}
        onChange={(cst, members) => {
          setSelectedCustomer({ ...selectedCustomer, ...cst })
          setPartnerMembers(members)
        }}
        members={partnerMembers}
      />
    ),
    2: (
      <CorporationParticulars
        customer={selectedCustomer}
        onChange={(cst, members) => {
          setSelectedCustomer({ ...selectedCustomer, ...cst })
          setCorporationMembers(members)
        }}
        members={corporationMembers}
      />
    ),
    3: (
      <MicrocreditParticulars
        customer={selectedCustomer}
        onChange={cst => setSelectedCustomer({ ...selectedCustomer, ...cst })}
      />
    ),
  }

  const [creditTypes, setCreditTypes] = useState([])
  const [bankAccounts, setBankAccounts] = useState([])
  const [associates, setAssociates] = useState([])
  const [referees, setReferees] = useState([])
  // const [specimen, setSpecimen] = useState(null)

  // useEffect(() => {
  //   if (selectedCustomer?.id) {
  //     loadCustomerDetails()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // async function loadCustomerDetails() {
  //   const { success, data } = await getData('/customer/find-customer-by-id', {
  //     customerId: selectedCustomer?.id,
  //   })
  //   if (success) {
  //     setSpecimen(data?.customer)
  //   }
  // }

  // const [specimen, setSpecimen] = useState(null)

  // useEffect(() => {
  //   if (selectedCustomer?.id) {
  //     loadCustomerDetails()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // async function loadCustomerDetails() {
  //   const { success, data } = await getData('/customer/find-customer-by-id', {
  //     customerId: selectedCustomer?.id,
  //   })
  //   if (success) {
  //     setSpecimen(data?.customer)
  //   }
  // }

  // const [specimen, setSpecimen] = useState(null)

  // useEffect(() => {
  //   if (selectedCustomer?.id) {
  //     loadCustomerDetails()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // async function loadCustomerDetails() {
  //   const { success, data } = await getData('/customer/find-customer-by-id', {
  //     customerId: selectedCustomer?.id,
  //   })
  //   if (success) {
  //     setSpecimen(data?.customer)
  //   }
  // }

  async function loadMemberCollection() {
    setPartnerMembers([])
    const { success, data } = await getData(
      '/customer/find-partnarship-member-collection-by-partnarship-id',
      { partnershipId: customer?.id }
    )
    if (success) {
      setPartnerMembers(data?.result)
    }
  }

  async function loadCreditTypes() {
    const { success, data } = await getData(
      '/customer/find-credit-types-by-customer-id',
      { customerId: customer.id }
    )
    if (success) {
      setCreditTypes(data?.result)
    }
  }

  async function loadBankAccounts() {
    const { success, data } = await getData(
      '/customer/find-bank-account-collection-by-customer-id',
      { customerId: customer.id }
    )
    if (success) {
      setBankAccounts(data?.result)
    }
  }

  async function loadAssociates() {
    const { success, data } = await getData(
      '/customer/find-associate-collection-by-customer-id',
      { customerId: customer.id }
    )
    if (success) {
      setAssociates(data?.result)
    }
  }

  async function loadReferees() {
    const { success, data } = await getData(
      '/customer/find-referee-collection-by-customer-id',
      { customerId: customer.id }
    )
    if (success) {
      setReferees(data?.result)
    }
  }

  async function loadCorporationMembers() {
    const { success, data } = await getData(
      '/customer/find-corporation-member-collection-by-corporation-id',
      { corporationId: customer.id }
    )
    if (success) {
      setCorporationMembers(data?.result)
    }
  }

  useEffect(() => {
    loadCreditTypes()
    loadBankAccounts()
    loadAssociates()
    loadReferees()
    loadMemberCollection()
    loadCorporationMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [query] = useState({
    passport: selectedCustomer?.passportImageId,
    signature: selectedCustomer?.signatureImageId,
    idFront: selectedCustomer?.identityCardFrontSideImageId,
    idBack: selectedCustomer?.identityCardBackSideImageId,
  })
  const [media, setMedia] = useState({})
  useEffect(() => {
    loadAllMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadAllMedia() {
    let it = 0
    let mm = {}
    for (let m in query) {
      it += 1
      let { Content } = await loadMedia(query[m])

      if (Content) mm[m] = Content
    }
    if (it >= 3) {
      setMedia({ ...mm })
    }
  }

  async function loadMedia(id) {
    // ! @TODO: @Job Change this to the correct URL 29/09/2023

    let { data } = await axios.get(
      `http://197.232.2.36:8083/Base64MediaService.svc/api/GetMedia/?sku=${id}`,
      {
        headers: {
          Application_Domain_Name: 'scs_live',
          Application_User_Name: 'su_scs_live',
        },
      }
    )
    return data
    // return data?.Content
  }

  const tabItems = [
    CustomerTypes[customerType] && {
      label: localize(
        CustomerTypes[customerType]
          ? `${CustomerTypes[customerType]?.name} Particulars`
          : '-'
      ),
      item: customerTypeParticular[customerType] || (
        <span className="fw-bold text-primary">
          Select Membership Class First
        </span>
      ),
    },
    {
      label: localize('address'),
      item: (
        <Address
          customer={selectedCustomer}
          onChange={c => setSelectedCustomer({ ...selectedCustomer, ...c })}
        />
      ),
    },
    {
      label: localize('specimen'),
      item: (
        <Specimen
          passport={media?.passport}
          signature={media?.signature}
          idFront={media?.idFront}
          idBack={media?.idBack}
          onChange={spec => {
            setMedia({ ...spec })
            setSelectedCustomer({
              ...selectedCustomer,
              ...spec,
            })
          }}
        />
      ),
    },
    {
      label: localize('referees'),
      item: <Referees referees={referees} onChange={r => setReferees(r)} />,
    },
    {
      label: localize('associates'),
      item: (
        <Associates associates={associates} onChange={a => setAssociates(a)} />
      ),
    },
    {
      label: localize('bankAccounts'),
      item: (
        <BankAccounts
          accounts={bankAccounts}
          onChange={b => setBankAccounts(b)}
        />
      ),
    },
    {
      label: localize('creditTypes'),
      item: (
        <CreditTypes
          selected={creditTypes}
          onSelect={ct => {
            setCreditTypes(ct)
          }}
        />
      ),
    },
  ]

  function removeId(item) {
    if (item?.id) {
      let idArr = item?.id.toString().split('-')
      let upd = item
      if (idArr.length < 4) {
        delete upd.id
      }
      return upd
    } else {
      return item
    }
  }

  async function editCustomer() {
    let payload = {
      customer: selectedCustomer,
      partnershipMemberDTOs: partnerMembers.map(removeId),
      corporationMemberDTOs: corporationMembers.map(removeId),
      refereeDTOs: referees.map(removeId),
      customerAssociateDTOs: associates.map(removeId),
      customerBankAccountDTOs: bankAccounts.map(removeId),
      creditTypeDTOs: creditTypes,
      passportBase64MediaDTO: null,
      signatureBase64MediaDTO: null,
      identityCardFrontSideBase64MediaDTO: null,
      identityCardBackSideBase64MediaDTO: null,
      customerType: selectedCustomer?.membershipClassCustomerType,
      PassportBase64MediaDTO: {
        FileName: 'passport',
        FileType: 'image/jpeg',
        ContentType: 'image/jpeg',
        Content: selectedCustomer?.passport,
      },
      SignatureBase64MediaDTO: {
        FileName: 'passport',
        FileType: 'image/jpeg',
        ContentType: 'image/jpeg',
        Content: selectedCustomer?.signature,
      },
      IdentityCardFrontSideBase64MediaDTO: {
        FileName: 'passport',
        FileType: 'image/jpeg',
        ContentType: 'image/jpeg',
        Content: selectedCustomer?.idFront,
      },
      IdentityCardBackSideBase64MediaDTO: {
        FileName: 'passport',
        FileType: 'image/jpeg',
        ContentType: 'image/jpeg',
        Content: selectedCustomer?.idBack,
      },
    }

    const { success } = editMode
      ? await putData('/customer/update-customer-with-meta', payload)
      : await postData('/customer/create-customer-with-meta', payload)

    if (success) {
      Swal.fire(
        'Success',
        editMode ? 'Customer Updated!' : 'Customer Created!',
        'success'
      )
      hideModal()
      refreshCustomers()
    }
  }

  return (
    <>
      <ModalHeader className="text-capitalize bg-white">
        {localize(mode)} editing
        <i className="uil uil-angle-right mx-2"></i>
        <span className="text-muted fs-6 text-uppercase">
          {localize('customers')}
        </span>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={hideModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row mb-1">
          <div className="col-3 d-flex align-items-center">
            <label
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('membershipClass')}
            </label>
            <MembershipClassLookup
              displayValue={selectedCustomer?.membershipClassDescription}
              onSelect={e => {
                setSelectedCustomer({
                  ...selectedCustomer,
                  membershipClassId: e.id,
                  membershipClassDescription: e.description,
                  membershipClassCustomerType: e.customerType,
                  membershipClassCustomerTypeDescription:
                    e.customerTypeDescription,
                  membershipClassNumberingSeriesId: e.numberingSeriesId,
                  membershipClassNumberingSeriesDescription:
                    e.numberingSeriesDescription,
                })
                setCustomerType(e.customerType)
              }}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize(editMode ? 'memberNumber' : 'customerType')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={
                editMode
                  ? selectedCustomer?.memberNumber
                  : selectedCustomer?.membershipClassCustomerTypeDescription
              }
              disabled
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize(editMode ? 'accountNumber' : 'numberingSeries')}
            </label>
            <input
              type="text"
              className="form-control"
              value={
                editMode
                  ? selectedCustomer?.accountNumber
                  : selectedCustomer?.membershipClassNumberingSeriesDescription
              }
              disabled
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('domicileBranch')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedCustomer?.branchDescription}
              disabled
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-3 d-flex align-items-center">
            <label
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('business')}/{localize('group')}/{localize('employer')}
            </label>
            <EmployerLookup
              displayValue={selectedCustomer?.employerDescription}
              onSelect={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  employerDescription: e.description,
                  employerId: e.id,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('dividendCode')}
            </label>
            <DividendCodeLookup
              displayValue={selectedCustomer?.dividendCodeDescription}
              onSelect={d =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  dividendCodeDescription: d.description,
                  dividendCodeId: d.id,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedCustomer?.personalFileNumber}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  personalFileNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('joinDate')}
            </label>
            <input
              type="date"
              className="form-control"
              value={parseDate(selectedCustomer?.registrationDate)}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  registrationDate: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-3 d-flex align-items-center">
            <label
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('duty')}/{localize('workStation')}
            </label>
            <WorkstationLookup
              displayValue={selectedCustomer?.dutyStationDescription}
              onSelect={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  dutyStationId: e.id,
                  dutyStationDescription: e.description,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('electoralZone')}
            </label>
            <ElectoralZonesLookup
              displayValue={selectedCustomer?.electoralZoneDescription}
              onSelect={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  electoralZoneId: e.id,
                  electoralZoneDescription: e.description,
                })
              }
            />
          </div>
          {editMode && (
            <>
              <div className="col-3 d-flex align-items-center">
                <label
                  htmlFor=""
                  className="pe-2 col-6 text-capitalize"
                  style={{ fontSize: 12 }}
                >
                  {localize('membershipStatus')} ({localize('current')})
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={selectedCustomer?.membershipStatusDescription}
                  disabled
                />
              </div>
              <div className="col-3 d-flex align-items-center">
                <label
                  htmlFor=""
                  className="pe-2 col-6 text-capitalize"
                  style={{ fontSize: 12 }}
                >
                  {localize('membershipStatus')} ({localize('new')})
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="CustomerMembershipStatus"
                  onChange={e =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      membershipStatus: e.value,
                    })
                  }
                />
              </div>
            </>
          )}
          {editMode || (
            <>
              <div className="col-3 d-flex align-items-center">
                <label
                  htmlFor=""
                  className="col-6 text-capitalize"
                  style={{ fontSize: 12 }}
                >
                  {localize('relationshipManager')}
                </label>
                <RelationshipManagerLookup
                  displayValue={
                    selectedCustomer?.relationshipManagerDescription
                  }
                  onSelect={r =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      relationshipManagerId: r.id,
                      relationshipManagerDescription:
                        r.employeeCustomerFullName,
                    })
                  }
                />
              </div>
              <div className="col-3 d-flex align-items-center">
                <label
                  className="col-6 text-capitalize"
                  style={{ fontSize: 12 }}
                >
                  {localize('payrollNumbers')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedCustomer?.payrollNumbers}
                  onChange={e =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      payrollNumbers: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}
        </div>
        <div className="row mb-1">
          <div className="col-3 d-flex align-items-center">
            <label
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('administrativeDivision')}
            </label>
            <AdministrativeDivisionLookup
              displayValue={selectedCustomer?.administrativeDivisionDescription}
              onSelect={a =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  administrativeDivisionId: a.id,
                  administrativeDivisionDescription: a.description,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('recruitedBy')}
            </label>
            <CustomersLookup
              displayValue={selectedCustomer?.recruitedBy}
              onSelect={c =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  recruitedBy: c.memberNumber,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('monthlyContribution')}
            </label>
            <input
              type="number"
              className="form-control"
              value={selectedCustomer?.monthlyContribution}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  monthlyContribution: e.target.value,
                })
              }
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label
              htmlFor=""
              className="pe-2 col-6 text-capitalize"
              style={{ fontSize: 12 }}
            >
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedCustomer?.remarks}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  remarks: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row mt-3">
          <Tab tabItems={tabItems.filter(item => !!item === true)} />
        </div>
      </ModalBody>
      <ModalFooter className="bg-white">
        <Checkbox
          checked={selectedCustomer?.isTaxExempt}
          onCheck={v =>
            setSelectedCustomer({ ...selectedCustomer, isTaxExempt: v })
          }
        />
        <label htmlFor="taxe" className="text-capitalize">
          {localize('isTaxExempt')}?
        </label>
        <div className="mx-3"></div>
        <Checkbox
          checked={selectedCustomer?.inhibitGuaranteeing}
          onCheck={v =>
            setSelectedCustomer({ ...selectedCustomer, inhibitGuaranteeing: v })
          }
        />
        <label htmlFor="ig" className="text-capitalize">
          {localize('inhibitGuaranteeing')}?
        </label>
        <div className="mx-3"></div>
        <Checkbox
          checked={selectedCustomer?.isLocked}
          onCheck={v =>
            setSelectedCustomer({ ...selectedCustomer, isLocked: v })
          }
        />
        <label htmlFor="locked" className="text-capitalize">
          {localize('isLocked')}?
        </label>
        <div className="mx-3"></div>
        <button
          onClick={editCustomer}
          className="btn btn-success text-capitalize"
        >
          {mode === 'edit' ? localize('update') : localize('Create')}
        </button>
      </ModalFooter>
    </>
  )
}

export default EditCustomer
