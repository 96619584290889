/** @format */
import localize from '../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import SimpleTable from '../../../Tables/SimpleTable'
import { useEffect, useState } from 'react'
import Loader from '../../../Loaders/Loader'
import { postData } from '../../../../Helpers/webApi'

export const CustomerMetadataLoanAccounts = ({
  selectedCustomer,
  doFetchLoanAccounts,
}) => {
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchLoanAccounts: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setCustomerLoanAccounts([...data?.loansAccounts])
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchLoanAccounts) {
      loadData()
    }
  }, [doFetchLoanAccounts])

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r.loanCaseTotalAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r.loanDefaultAmount)}</>,
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision(Percentage)'),
      template: r => <>{formatAsPercent(r.loanLossProvisionPercentage)}</>,
    },
    {
      label: localize('loanLossProvision(Amount)'),
      template: r => <>{formatCurrency(r.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{formatDate(r.loanCaseDisbursedDate)}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{formatDate(r.loanLastPaymentDueDate)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r.loanLastPayment}</>,
    },
    {
      label: localize('loanTerm(Months)'),
      template: r => <>{r.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r => (
        <>{formatAsPercent(r.loanCaseLoanInterestCalculationModeDescription)}</>
      ),
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{formatCurrency(r.carryForwardsBalance)}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SimpleTable columns={columns} data={customerLoanAccounts} />
      )}
    </>
  )
}
