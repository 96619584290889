/** @format */

import AccountAlerts from '../../Pages/Registry/AccountAlerts/Index'
import AdhocPopulationRegisterQuery from '../../Pages/Registry/AdhocPopulationRegisterQuery/Index'
import BiometricExemptions from '../../Pages/Registry/BiometricRegister/Exemptions/Index'
import FingerPrintBiometricRegister from '../../Pages/Registry/BiometricRegister/FingerPrint/Index'
import ChargesExemptions from '../../Pages/Registry/ChargesExemptions/Index'
import ConcessionExceptions from '../../Pages/Registry/ConcessionsExemptions/Index'
import ConditionalLending from '../../Pages/Registry/ConditionalLending/Index'
import DividendCodes from '../../Pages/Registry/Setup/DividendCodes/DividendCodes'
import DocumentRegister from '../../Pages/Registry/DocumentRegister/Index'
import EducationRegister from '../../Pages/Registry/EducationRegister/Index'
import EducationVenue from '../../Pages/Registry/EducationVenue/Index'
import ElectoralZones from '../../Pages/Registry/Setup/ElectoralZones/ElectoralZones'
import FileTrackingCatalogue from '../../Pages/Registry/FileTracking/Catalogue/Index'
import FileTrackingDispatchMultiDestination from '../../Pages/Registry/FileTracking/Dispatch/MultiDestination/Index'
import FileTrackingSingleDestinationDispatch from '../../Pages/Registry/FileTracking/Dispatch/SingleDestination/Index'
import FileTrackingRecall from '../../Pages/Registry/FileTracking/Recall/Index'
import FileTrackingReceive from '../../Pages/Registry/FileTracking/Receive/Index'
import LoanProductsExemptions from '../../Pages/Registry/LoanProductsExemptions/Index'
import Customers from '../../Pages/Registry/Members/Customers'
import DomicileBranchLinkage from '../../Pages/Registry/Members/DomicileBranchLinkage/DomicileBranchLinkage'
import DutyWorkStationLinkage from '../../Pages/Registry/Members/DutyWorkStationLinkage/DutyWorkStationLinkage'
import Enrollment from '../../Pages/Registry/Members/Enrollment/Enrollment'
import PayrollNumbers from '../../Pages/Registry/Members/PayrollNumbers/PayrollNumbers'
import MembershipActivation from '../../Pages/Registry/MembershipActivation/Index'
import Approval from '../../Pages/Registry/MembershipWithdrawal/Approval/Approval'
import Catalogue from '../../Pages/Registry/MembershipWithdrawal/Catalogue/Catalogue'
import BatchAuthorization from '../../Pages/Registry/MembershipWithdrawal/Settlement/BatchAuthorization/BatchAuthorization'
import BatchOrigination from '../../Pages/Registry/MembershipWithdrawal/Settlement/BatchOrigination/BatchOrigination'
import BatchVerification from '../../Pages/Registry/MembershipWithdrawal/Settlement/BatchVerification/BatchVerification'
import FuneralRiderClaims from '../../Pages/Registry/MembershipWithdrawal/Settlement/FuneralRiderClaims/FuneralRiderClaims'
import Verification from '../../Pages/Registry/MembershipWithdrawal/Verification/Verification'
import NextOfKin from '../../Pages/Registry/NextOfKin/Index'
import PopulationRegisterQuery from '../../Pages/Registry/PopulationRegisterQuery/Index'
import AdministrativeDivisions from '../../Pages/Registry/Setup/AdministrativeDivisions/AdministrativeDivisions'
import Employers from '../../Pages/Registry/Setup/Employers/Employers'
import EmploymentSectors from '../../Pages/Registry/Setup/EmploymentSectors/EmploymentSectors'
import MembershipClasses from '../../Pages/Registry/Setup/MembershipClasses/MembershipClasses'
import WorkStations from '../../Pages/Registry/Setup/WorkStations/WorkStations'
import Delegates from '../../Pages/Registry/Members/Delegates/Delegates'
import Directors from '../../Pages/Registry/Members/Directors/Directors'

const registryMenus = [
  {
    iconClass: 'uil uil-book-open',
    label: 'registryManagement',
    code: 3,
    setup: [
      {
        label: 'employers',
        component: <Employers menuCode={4001} />,
        parentCode: 3,
        code: 4001,
      },
      {
        label: 'dutyWorkStations',
        component: <WorkStations menuCode={4002} />,
        parentCode: 3,
        code: 4002,
        description: 'dutyStations',
      },
      {
        label: 'administrativeDivisions',
        component: <AdministrativeDivisions menuCode={4003} />,
        parentCode: 3,
        code: 4003,
      },
      {
        label: 'employmentSectors',
        component: <EmploymentSectors menuCode={4004} />,
        parentCode: 3,
        code: 4004,
      },
      {
        label: 'membershipClasses',
        component: <MembershipClasses menuCode={4005} />,
        parentCode: 3,
        code: 4005,
      },
      {
        label: 'electoralZones',
        component: <ElectoralZones menuCode={4006} />,
        parentCode: 3,
        code: 4006,
      },
      {
        label: 'dividendCodes',
        component: <DividendCodes menuCode={4007} />,
        parentCode: 3,
        code: 4007,
      },
    ],
    operations: [
      {
        label: 'membersManagement',
        children: [
          {
            label: 'enrollment',
            code: 4501,
            parentCode: 3,
            component: <Enrollment menuCode={4501} />,
            description: 'enrollmentRequests',
          },
          {
            label: 'customers',
            component: <Customers menuCode={4502} />,
            code: 4502,
            parentCode: 3,
          },
          {
            label: 'delegates',
            component: <Delegates menuCode={4503} />,
            code: 4503,
            parentCode: 3,
          },
          {
            label: 'directors',
            component: <Directors menuCode={4504} />,
            code: 4504,
            parentCode: 3,
          },
          {
            label: 'domicileBranchLinkage',
            component: <DomicileBranchLinkage menuCode={4505} />,
            code: 4505,
            parentCode: 3,
          },
          {
            label: 'nextOfKin',
            component: <NextOfKin menuCode={4506} />,
            code: 4506,
            parentCode: 3,
          },
          {
            label: 'chargesExemption',
            component: <ChargesExemptions menuCode={4507} />,
            code: 4507,
            parentCode: 3,
          },
          {
            label: 'membershipActivation',
            component: <MembershipActivation menuCode={4508} />,
            code: 4508,
            parentCode: 3,
          },
          {
            label: 'payrollNumbers',
            component: <PayrollNumbers menuCode={4509} />,
            parentCode: 3,
            code: 4509,
          },

          {
            label: 'conditionalLending',
            component: <ConditionalLending menuCode={4510} />,
            code: 4510,
            parentCode: 3,
            description: 'conditionalLendingList',
          },
          {
            label: 'loanProductsExemptions',
            component: <LoanProductsExemptions menuCode={4511} />,
            parentCode: 3,
            code: 4511,
          },
          {
            label: 'duty/WorkStationLinkage',
            component: <DutyWorkStationLinkage menuCode={4512} />,
            parentCode: 3,
            code: 4512,
            description: 'dutyStationLinkage',
          },
          {
            label: 'concessionExceptions',
            component: <ConcessionExceptions menuCode={4513} />,
            parentCode: 3,
            code: 4513,
          },
          {
            label: 'accountAlerts',
            component: <AccountAlerts menuCode={4514} />,
            parentCode: 3,
            code: 4514,
          },
          {
            label: 'documentRegister',
            component: <DocumentRegister menuCode={4515} />,
            parentCode: 3,
            code: 4515,
            description: 'customerDocumentRegister',
          },
        ],
      },
      {
        label: 'membersEducation',
        children: [
          {
            label: 'venue',
            component: <EducationVenue menuCode={4517} />,
            code: 4517,
            parentCode: 3,
            description: 'membersEducationVenues',
          },
          {
            label: 'register',
            component: <EducationRegister menuCode={4518} />,
            code: 4518,
            parentCode: 3,
            description: 'membersEducationRegister',
          },
        ],
      },
      {
        label: 'populationRegisterQueries',
        children: [
          {
            label: 'customerQueries',
            component: <PopulationRegisterQuery menuCode={4520} />,
            code: 4520,
            parentCode: 3,
            description: 'customerRegisterQueries',
          },
          {
            label: 'adhocQueries',
            component: <AdhocPopulationRegisterQuery menuCode={4521} />,
            code: 4521,
            parentCode: 3,
            description: 'adhocRegisterQueries',
          },
        ],
      },
      {
        label: 'membershipWithdrawal',
        children: [
          {
            label: 'catalogue',
            component: <Catalogue menuCode={4523} />,
            code: 4523,
            parentCode: 3,
            description: 'membershipWithdrawalNotifications',
          },
          {
            label: 'approval',
            component: <Approval menuCode={4524} />,
            code: 4524,
            parentCode: 3,
            description: 'approveMembershipWithdrawal',
          },
          {
            label: 'verification',
            component: <Verification menuCode={4525} />,
            code: 4525,
            parentCode: 3,
            description: 'verifyMembershipWithdrawal',
          },
          {
            label: 'settlement',
            children: [
              {
                label: 'batchOrigination',
                component: <BatchOrigination menuCode={4527} />,
                code: 4527,
                parentCode: 3,
                description: 'membershipWithdrawalSettlementBatches',
              },
              {
                label: 'batchVerification',
                component: <BatchVerification menuCode={4528} />,
                code: 4528,
                parentCode: 3,
                description: 'membershipWithdrawalSettlementBatches',
              },
              {
                label: 'batchAuthorization',
                component: <BatchAuthorization menuCode={4529} />,
                code: 4529,
                parentCode: 3,
                description: 'membershipWithdrawalSettlementBatches',
              },
              {
                label: 'funeralRiderClaims',
                component: <FuneralRiderClaims menuCode={4530} />,
                code: 4530,
                parentCode: 3,
              },
            ],
          },
        ],
      },
      {
        label: 'fileTracking',
        children: [
          {
            label: 'dispatch',
            children: [
              {
                label: 'multiDestination',
                component: (
                  <FileTrackingDispatchMultiDestination menuCode={4533} />
                ),
                code: 4533,
                parentCode: 3,
                description: 'fileDispatch(Multi-Destination)',
              },
              {
                label: 'singleDestination',
                component: (
                  <FileTrackingSingleDestinationDispatch menuCode={4534} />
                ),
                code: 4534,
                parentCode: 3,
                description: 'fileDispatch(Single-Destination)',
              },
            ],
          },
          {
            label: 'receive',
            component: <FileTrackingReceive menuCode={4535} />,
            code: 4535,
            parentCode: 3,
            description: 'fileReception',
          },
          {
            label: 'recall',
            component: <FileTrackingRecall menuCode={4536} />,
            code: 4536,
            parentCode: 3,
            description: 'fileRecall',
          },
          {
            label: 'catalogue',
            component: <FileTrackingCatalogue menuCode={4537} />,
            code: 4537,
            parentCode: 3,
            description: 'fileRegister',
          },
        ],
      },
      {
        label: 'biometricRegister',
        children: [
          {
            label: 'fingerPrint',
            component: <FingerPrintBiometricRegister menuCode={4539} />,
            code: 4539,
            parentCode: 3,
            description: 'fingerPrintBiometricRegister',
          },
          {
            label: 'exemptions',
            component: <BiometricExemptions menuCode={4543} />,
            code: 4543,
            parentCode: 3,
            description: 'biometricExemptions',
          },
        ],
      },
    ],
  },
]

export default registryMenus
