/** @format */

import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  customerAccountManagementAction,
  customerAccountRemarkType,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { putData } from '../../../../../Helpers/webApi'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import ManagementTab from './ManagementTab/ManagementTab'
import { useEffect } from 'react'

const initialAction = {
  remarkType: Object.keys(customerAccountRemarkType)[0],
  remark: '',
  managementAction: Object.keys(customerAccountManagementAction)[2],
}

const Management = () => {
  const [customerAccount, setCustomerAccount] = useState({})
  const [management, setManagement] = useState(initialAction)
  const [isBusy, setIsBusy] = useState(false)

  const handleLookupCustomerAccount = r => {
    setCustomerAccount(r)

    //TODO: myke-confirm api does not allow one to change the customer account of logged in user 27/09/2023
  }

  const handleUpdate = async () => {
    if (!customerAccount?.id)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a customer account to proceed'
      )

    if (
      !management?.remark &&
      management?.managementAction !==
        Object.keys(customerAccountManagementAction)[5] &&
      management?.managementAction !==
        Object.keys(customerAccountManagementAction)[6]
    )
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please enter a remark to proceed'
      )

    const url = '/CustomerAccount/manage-customer-account'
    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        customerAccount,
        managementAction: management.managementAction,
        remarkType: management.remarkType,
        remarks: management.remark,
        chargeAccountActivationFee: management?.chargeAccountActivationFee,
        activationFeeCustomerAccountId:
          management?.activationFeeCustomerAccountId,
      },
      false
    )

    if (success) {
      setManagement(initialAction)
      setCustomerAccount({})
      setIsBusy(false)
      showNotification('Customer Account Management', 'success')
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('customerAccountManagement'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <CustomerAccountPartial
                onCustomerLookup={handleLookupCustomerAccount}
                customer={customerAccount}
              />

              <div className="mt-4">
                <Tab
                  tabItems={[
                    {
                      label: localize('management'),
                      item: (
                        <ManagementTab
                          customerAccount={customerAccount}
                          management={management}
                          setManagement={setManagement}
                        />
                      ),
                    },

                    {
                      label: localize('specimen'),
                      item: (
                        <Specimen
                          customer={{
                            id: customerAccount.customerId,
                          }}
                          readOnly
                          refetchFromServer
                        />
                      ),
                    },
                  ]}
                />
              </div>

              {management?.managementAction !==
                Object.keys(customerAccountManagementAction)[5] &&
                management?.managementAction !==
                  Object.keys(customerAccountManagementAction)[6] && (
                  <div className="mt-3 bg-light rounded p-2 d-flex justify-content-end gap-4 align-items-center">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        id="piggyBankEnabled"
                        checked={management?.piggyBankEnabled}
                        onCheck={value => {
                          setManagement({
                            ...management,
                            piggyBankEnabled: value,
                          })
                        }}
                      />

                      <label
                        className="text-capitalize ms-2"
                        htmlFor="piggyBankEnabled"
                      >
                        {localize('piggyBankEnabled')}
                      </label>
                    </div>
                    <button className="btn btn-success" onClick={handleUpdate}>
                      {localize('update')}
                    </button>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Management
