/** @format */

import localize from '../../../../../Global/Localize'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import { MicroCreditOfficerLookup } from '../../../../../Components/Lookup/MicroCreditOfficerLookup'
import { MicroCreditGroupLookup } from '../../../../../Components/Lookup/MicroCreditGroupLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import TimePicker from '../../../../../Components/Date/TimePicker'
import swal from 'sweetalert2'

export const CreateMicroCreditGroup = ({
  microCreditGroupModel,
  setMicroCreditGroupModel,
}) => {
  const handleSelectMicroCreditGroupCustomer = r => {
    if (!r.isLocked && r.membershipClassCustomerType === '3') {
      setMicroCreditGroupModel({
        ...microCreditGroupModel,
        customerId: r?.id,
        fullName: r.fullName,
      })
    } else {
      swal.fire(
        'Create Micro Credit Group',
        'Selected Customer must be of type micro credit',
        'error',
      )
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('name')}</label>
          <CustomersLookup
            displayValue={
              microCreditGroupModel?.fullName ||
              microCreditGroupModel?.customerNonIndividualDescription
            }
            onSelect={handleSelectMicroCreditGroupCustomer}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('creditOfficer')}
          </label>
          <MicroCreditOfficerLookup
            displayValue={
              microCreditGroupModel?.employeeCustomerFullName ||
              microCreditGroupModel?.microCreditOfficerEmployeeCustomerFullName
            }
            onSelect={r => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                microCreditOfficerId: r.id,
                employeeCustomerFullName: r.employeeCustomerFullName,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('parentGroup')}
          </label>
          <MicroCreditGroupLookup
            displayValue={
              microCreditGroupModel?.customerNonIndividualDescription
            }
            onSelect={r => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                parentId: r.id,
                customerNonIndividualDescription:
                  r.customerNonIndividualDescription,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('type')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="MicroCreditGroupType"
            value={microCreditGroupModel?.type}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                type: e.value,
              })
            }}
            sort
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('purpose')}</label>
          <input
            className="form-control"
            defaultValue={microCreditGroupModel?.purpose}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                purpose: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('activities')}
          </label>
          <input
            className="form-control"
            defaultValue={microCreditGroupModel?.activities}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                activities: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('meetingFrequency')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="MeetingFrequency"
            value={microCreditGroupModel?.meetingFrequency}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                meetingFrequency: e.value,
              })
            }}
            sort
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('meetingDay')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="MeetingDayOfWeek"
            value={microCreditGroupModel?.meetingDayOfWeek}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                meetingDayOfWeek: e.value,
              })
            }}
            sort
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('meetingTime')}
          </label>
          <TimePicker
            name={'startTime'}
            defaultTime={microCreditGroupModel?.meetingTime}
            onTimeChange={c => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                meetingTime: c,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('minimumMembers')}
          </label>
          <input
            type="number"
            className="form-control"
            defaultValue={microCreditGroupModel?.minimumMembers}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                minimumMembers: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('maximumMembers')}
          </label>
          <input
            type="number"
            className="form-control"
            defaultValue={microCreditGroupModel?.maximumMembers}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                maximumMembers: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('meetingPlace')}
          </label>
          <input
            className="form-control"
            defaultValue={microCreditGroupModel?.meetingPlace}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                meetingPlace: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            defaultValue={microCreditGroupModel?.remarks}
            onChange={e => {
              setMicroCreditGroupModel({
                ...microCreditGroupModel,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
