/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { putData } from '../../../../Helpers/webApi'
import ActionModal from '../../../ActionModal/ActionModal'
import Checkbox from '../../../Checkbox/Checkbox'
import Loader from '../../../Loaders/Loader'
import JournalLookup from '../../../Lookup/JournalLookup'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import Tab from '../../../Tabs/Tab'
import ReversalBatchEntries from '../../BatchEntries/ReversalBatchEntries/ReversalBatchEntries'

const EditReversalBatchModal = ({
  handleClose,
  mode,
  reversal,
  fetchReversals,
  setReversal,
}) => {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [reload, setReload] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyRemoving, setIsBusyRemoving] = useState(false)

  const handleClickRemoveButton = () => {
    if (selectedEntries.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select entries to remove',
      })

    setShowRemoveModal(true)
  }

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false)
  }

  const handleSelectAllEntries = (entries, value) => {
    if (value) {
      setSelectedEntries(entries)
    } else {
      setSelectedEntries([])
    }
  }

  const handleSelectEntry = (entry, value) => {
    if (value) {
      setSelectedEntries([...selectedEntries, entry])
    } else {
      setSelectedEntries(
        selectedEntries.filter(selectedEntry => selectedEntry !== entry)
      )
    }
  }

  const handleEditReverseBatch = async () => {
    setIsBusy(true)
    const url = `/JournalReversalBatch/update-journal-reversal-batch?requestForVerification=${reversal?.requestForVerification}`

    const { success } = await putData(url, reversal, false)

    if (success) {
      handleClose()
      showNotification('Update Journal Reversal Batch', 'success')
      fetchReversals()
    }

    setIsBusy(false)
  }

  const handleRemoveBatchEntries = async () => {
    setIsBusyRemoving(true)
    const url = '/JournalReversalBatch/remove-journal-reversal-batch-entries'

    const { success } = await putData(url, selectedEntries, false)

    if (success) {
      setReload(true)
      setSelectedEntries([])
      showNotification('Remove Journal Reversal Batch Entries', 'success')
      handleCloseRemoveModal()
    }

    setIsBusyRemoving(false)
  }

  return (
    <>
      {showRemoveModal && (
        <ActionModal
          modalTitle="removeReversalBatchEntries"
          handleClose={handleCloseRemoveModal}
          disableAction={isBusyRemoving}
          handleAction={handleRemoveBatchEntries}
          disableClose={isBusyRemoving}
        >
          {isBusyRemoving ? (
            <Loader />
          ) : (
            <p>Are you sure you want to remove selected entries?</p>
          )}
        </ActionModal>
      )}

      <ModalTemplate
        modalTitle="journalReversalBatch"
        actionText="update"
        modalMode={mode}
        handleClose={handleClose}
        modalClassName="modal-xl"
        handleAction={handleEditReverseBatch}
        disableClose={isBusy}
        disableCreate={isBusy}
        footerChildren={
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              id={'requestForVerification-reversal-batch'}
              checked={reversal?.requestForVerification}
              onCheck={value => {
                setReversal({
                  ...reversal,
                  requestForVerification: value,
                })
              }}
            />

            <label
              htmlFor={'requestForVerification-reversal-batch'}
              className="text-capitalize"
            >
              {localize('requestForVerification')} ?
            </label>
          </div>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('batchNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reversal?.paddedBatchNumber}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('valueDate')}
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={formatDate(reversal?.valueDate, true)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-1">
                    {localize('remarks')}
                  </label>
                  <textarea
                    className="form-control"
                    value={reversal?.remarks}
                  />
                </div>
              </div>
            </div>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('batchDetails')}
              </div>

              <div className="row mb-2 mt-2">
                <div className="col-md-12 d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('targetTransactionCode')}
                  </label>

                  <JournalLookup
                    reversal={reversal}
                    handleRefresh={value => {
                      setReload(value)
                    }}
                  />
                </div>
              </div>

              <div className="row py-3 px-2">
                <Tab
                  preload
                  tabItems={[
                    {
                      label: localize('batchEntries'),
                      item: (
                        <>
                          <ReversalBatchEntries
                            journalReversalBatchId={reversal?.id}
                            showSelect
                            handleSelectAllEntries={handleSelectAllEntries}
                            selectedEntries={selectedEntries}
                            handleSelectEntry={handleSelectEntry}
                            reload={reload}
                            setReload={setReload}
                            handleRemoveBatchEntries={handleClickRemoveButton}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditReversalBatchModal
