/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData, putData } from '../../../../../Helpers/webApi'
import { generateGuid } from '../../../../../Helpers/extensions'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'

function AuxiliaryGuarantorFactors({ disabled, loan }) {
  const [isBusy, setIsBusy] = useState(false)
  const [auxFactors, setAuxFactors] = useState([])
  const [factor, setFactor] = useState({ amount: 0, minimumGuarantors: 0 })

  useEffect(() => {
    loadFactors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadFactors() {
    setIsBusy(true)
    const { success, data } = await getData(
      `/LoanProduct/find-loan-product-auxiliary-guarantor-factors-by-loan-product-id`,
      { loanProductId: loan?.id },
      false
    )
    if (success) {
      setAuxFactors(data?.result)
    }
    setIsBusy(false)
  }

  function addFactor() {
    let lowerLimit =
      auxFactors.length > 0
        ? Number(auxFactors[auxFactors.length - 1].amountAppliedUpperLimit) +
          0.01
        : 0
    let upperLimit = factor?.amount - 0.01
    if (lowerLimit >= upperLimit) {
      return Swal.fire('Error', 'Invalid Sequence', 'error')
    }

    let facs = [
      ...auxFactors.sort(
        (a, b) => a.amountAppliedUpperLimit - b.amountAppliedUpperLimit
      ),
    ]
    facs.push({
      ...factor,
      id: generateGuid(),
      amountAppliedLowerLimit: lowerLimit,
      amountAppliedUpperLimit: upperLimit,
    })
    setAuxFactors(facs)
    setFactor(f => ({ ...f, amount: 0, minimumGuarantors: 0 }))
  }

  async function updateFactors() {
    setIsBusy(true)
    const { success } = await putData(
      `/loanProduct/update-loan-product-auxiliary-quarantor-factors-by-loan-product-id?loanProductId=${loan?.id}`,
      auxFactors,
      false,
      'Auxilliary Guarantor Factors Updated!'
    )
    if (success) {
      loadFactors()
    }
    setIsBusy(false)
  }

  async function removeFactor(id) {
    let facsLength = auxFactors.length
    let currentIndex = auxFactors.findIndex(f => f.id === id)
    if (currentIndex < facsLength - 1) {
      let res = await Swal.fire({
        title: 'Confirm',
        text:
          'Are you sure you want to remove this factor? This will also remove the ' +
          (facsLength - 1 - currentIndex) +
          ' factor(s) after it to maintain a valid sequence.',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
      })
      if (res.isConfirmed) {
        let fs = [...auxFactors].slice(0, currentIndex)
        setAuxFactors(fs)
      }
    } else {
      let facs = [...auxFactors]
      facs = facs.filter(f => f.id !== id)
      setAuxFactors(facs)
    }
  }

  const columns = [
    {
      label: localize('amountApplied') + ' (' + localize('lowerLimit') + ')',
      template: r => <>{formatCurrency(r?.amountAppliedLowerLimit)}</>,
    },
    {
      label: localize('amountApplied') + ' (' + localize('upperLimit') + ')',
      template: r => <>{formatCurrency(r?.amountAppliedUpperLimit)}</>,
    },
    {
      label: localize('minimumGuarantors'),
      template: r => <>{r?.minimumGuarantors}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={auxFactors} />
    </>
  ) : (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('amountApplied')} ({localize('lowerLimit')})
                  </label>
                  <input
                    type="number"
                    value={factor?.amount}
                    onChange={e =>
                      setFactor({ ...factor, amount: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('minimumGuarantors')}
                  </label>
                  <input
                    type="number"
                    value={factor?.minimumGuarantors}
                    onChange={e =>
                      setFactor({
                        ...factor,
                        minimumGuarantors: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light d-flex align-items-center justify-content-end p-2 mb-3">
            <button
              onClick={addFactor}
              className="btn btn-sm btn-success text-uppercase px-4"
            >
              {localize('add')}
            </button>
          </div>
          <SimpleTable
            columns={columns}
            data={auxFactors}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => removeFactor(r?.id)}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={updateFactors} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AuxiliaryGuarantorFactors

// AuxiliaryDisbursementFactors
