/** @format */

import localize from '../../../../../../Global/Localize'
/* eslint-disable */
import React, { useState } from 'react'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import GroupedTable from '../../../../../../Components/Tables/GroupedTable'
import AccountDetailsArchivedModal from './AccountDetailsArchivedModal'

export const AccountStatementsArchivedListing = ({
  loading,
  selectedCustomer,
  onChangeProductSection,
  accountStatementsListing,
  balanceSummaryProductSection,
  balanceSummary,
}) => {
  const [groupBy, setGroupBy] = useState({
    label: localize('productSection'),
    column: 'customerAccountTypeTargetProductSectionDescription',
  })

  const [mode, setMode] = useState(null)

  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r.availableBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('penaltyBalance'),
      template: r => <>{formatCurrency(r.penaltyBalance)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{formatDate(r.loanClassificationDate)}</>,
    },
    {
      label: localize('expectedPaymentDueDate'),
      template: r => <>{formatDate(r.loanExpectedPaymentDueDate)}</>,
    },
    {
      label: localize('loanClassificationMode'),
      template: r => <>{r.loanClassificationMode}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r.loanCaseTotalAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r.loanDefaultAmount)}</>,
    },
    {
      label: localize('interestArrearsAmount'),
      template: r => <>{formatCurrency(r.interestDefaultAmount)}</>,
    },
    {
      label: localize('loanDefaultDate'),
      template: r => <>{formatDate(r.loanDefaultDate)}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => <>{r.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{formatCurrency(r.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{formatDate(r.loanCaseDisbursedDate)}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{formatDate(r.loanLastPaymentDueDate)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{formatCurrency(r.loanLastPayment)}</>,
    },
    {
      label: localize('loanTermMonths'),
      template: r => <>{r.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r => <>{r.loanCaseLoanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('loanRoundingType'),
      template: r => <>{r.loanCaseLoanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('availableOverDraftLimit'),
      template: r => <>{formatCurrency(r.availableOverdraftLimit)}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{formatCurrency(r.carryForwardsBalance)}</>,
    },
    {
      label: localize('piggyBankEnabled'),
      template: r => (
        <span className="text-capitalize">
          {r.piggyBankEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const handleOnViewAccountDetails = r => {
    setMode('view')
    setSelectedCustomerAccount({ ...r })
  }

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait...</span>
          </div>
        </>
      ) : (
        <>
          <GroupedTable
            canSelect
            contextMenu={e => (
              <>
                <div
                  onClick={() => {
                    handleOnViewAccountDetails(e)
                  }}
                  className="ctxt-menu-item p-2 text-capitalize"
                >
                  <i className="uil uil-window"></i>
                  <span className="text-capitalize">
                    {localize('view')} {localize('account')}{' '}
                    {localize('details')}
                  </span>
                </div>
              </>
            )}
            groupBy={groupBy}
            columns={columns}
            data={accountStatementsListing.pageCollection}
          />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-item-center me-5">
              <label className="text-capitalize">
                {localize('productSection')}
              </label>
              <EnumsServerCombo
                value={balanceSummaryProductSection}
                onChange={onChangeProductSection}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'CustomerAccountBalancesFilter'}
              />
            </div>
          </div>
        </>
      )}
      <AccountDetailsArchivedModal
        selectedCustomer={selectedCustomer}
        setSelectedCustomerAccount={setSelectedCustomerAccount}
        selectedCustomerAccount={selectedCustomerAccount}
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
      />
    </>
  )
}
