/** @format */
import DirectDebits from '../../../../Components/Selectables/Plain/DirectDebits'

export const CreditTypesApplicableDirectDebits = ({
  creditTypeDirectDebits,
  loading,
  disabled,
  onCheckCreditTypeApplicableDirectDebits,
  prefix,
}) => {
  return (
    <DirectDebits
      disabled={disabled}
      isBusy={loading}
      prefix={prefix}
      onCheckDirectDebit={r => {
        onCheckCreditTypeApplicableDirectDebits &&
          onCheckCreditTypeApplicableDirectDebits(r)
      }}
      directDebitsToSelect={creditTypeDirectDebits}
    />
  )
}
