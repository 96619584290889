/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../../Helpers/webApi'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import ViewStandingOrder from './ViewStandingOrder'

function StandingOrders({ customer }) {
  const [standingOrders, setStandingOrders] = useState([])
  const [fetching, setFetching] = useState(false)
  useEffect(() => {
    loadStandingOrders()
    // eslint-disable-next-line
  }, [customer])

  async function loadStandingOrders() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/standingOrder/find-standing-orders-by-beneficiary-customer-id',
      {
        beneficiaryCustomerId: customer?.id,
        includeProductDescription: true,
      },
      false
    )
    if (success) {
      setStandingOrders(data?.result || [])
    }
    setFetching(false)
  }

  const [standingOrder, setStandingOrder] = useState(null)

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('trigger'), template: r => <>{r?.triggerDescription}</> },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r?.beneficiaryProductDescription}</>,
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => <>{formatCurrency(r?.paymentPerPeriod)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r?.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r?.interest)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
    {
      label: localize('beneficiaryAccountName'),
      template: r => <>{r?.beneficiaryCustomerAccountAccountName}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.durationStartDate, true)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.durationEndDate, true)}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => (
        <>{formatDate(r?.beneficiaryCustomerAccountLoanCaseDisbursedDate)}</>
      ),
    },
    {
      label: localize('disbursedAmount'),
      template: r => (
        <>
          {formatCurrency(r?.beneficiaryCustomerAccountLoanCaseDisbursedAmount)}
        </>
      ),
    },
    {
      label: localize('capitalizedInterest'),
      template: r => <>{formatCurrency(r?.capitalizedInterest)}</>,
    },
    {
      label: localize('beneficiaryCustomerName'),
      template: r => <>{r?.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('ref')}_1 ${localize(
        'account'
      )} #`,
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('ref')}_2 ${localize(
        'member'
      )} #`,
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('ref')}_3 ${localize(
        'personalFile'
      )} #`,
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r?.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r?.benefactorProductDescription}</>,
    },
    {
      label: localize('benefactorCustomerName'),
      template: r => <>{r?.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorAccountName'),
      template: r => <>{r?.benefactorCustomerAccountAccountName}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('ref')}_1 ${localize(
        'account'
      )} #`,
      template: r => <>{r?.benefactorCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('ref')}_2 ${localize(
        'member'
      )} #`,
      template: r => <>{r?.benefactorCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('ref')}_3 ${localize(
        'personalFile'
      )} #`,
      template: r => <>{r?.benefactorCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r?.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r?.scheduleFrequencyDescription}</>,
    },
    {
      label: localize('expectedRunDate'),
      template: r => <>{formatDate(r?.scheduleExpectedRunDate, true)}</>,
    },
    {
      label: localize('actualRunDate'),
      template: r => <>{formatDate(r?.scheduleActualRunDate, true)}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('chargeable') + '?',
      template: r => (
        <span className="text-capitalize">{r?.chargeable?.toString()}</span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.cratedBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <SimpleTable
        columns={columns}
        data={standingOrders}
        contextMenu={r => (
          <>
            <div className="ctxt-menu-item" onClick={() => setStandingOrder(r)}>
              <i className="uil uil-search-alt"></i>
              <span className="text-capitalize">{localize('view')}</span>
            </div>
          </>
        )}
      />
      {standingOrder && (
        <>
          <ViewStandingOrder
            standingOrderId={standingOrder?.id}
            onClose={() => setStandingOrder(null)}
          />
        </>
      )}
    </>
  )
}

export default StandingOrders
