/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'

import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import EmployeeTypeModal from './EmployeeTypeModal'

function EmployeeTypes() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
      sortBy: 'categoryDescription',
    },
    {
      label: localize('taxReliefAmount'),
      template: r => formatCurrency(r?.taxReliefAmount),
    },
    {
      label: localize('maximumProvidentFundReliefAmount'),
      template: r => formatCurrency(r?.maximumProvidentFundReliefAmount),
    },
    {
      label: localize('maximumInsuranceReliefAmount'),
      template: r => formatCurrency(r?.maximumInsuranceReliefAmount),
    },
    {
      label: localize('maximumNHIFReliefAmount'),
      template: r => formatCurrency(r?.maximumNHIFReliefAmount),
    },
    {
      label: localize('executePayoutStandingOrders'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.executePayoutStandingOrders.toString())}
        </span>
      ),
      sortBy: 'executePayoutStandingOrders',
    },
    {
      label: localize('executeAdHocStandingOrders'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.executeAdHocStandingOrders.toString())}
        </span>
      ),
      sortBy: 'executeAdHocStandingOrders',
    },
    {
      label: localize('recoverIndefiniteCharges'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.recoverIndefiniteCharges.toString())}
        </span>
      ),
      sortBy: 'recoverIndefiniteCharges',
    },
    {
      label: localize('consolidatePostings'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.consolidatePostings.toString())}
        </span>
      ),
      sortBy: 'consolidatePostings',
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedEmployeeType, setSelectedEmployeeType] = useState(null)
  const [tabLoading, setTabLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/EmployeeType/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  const fetchEmployeeTypeMetadata = async id => {
    setTabLoading(true)
    const { success, data } = await getData(
      '/EmployeeType/find-employee-type-metadata',
      { employeeTypeId: id },
      false
    )
    if (success) {
      setTabLoading(false)
      return data
    } else {
      setTabLoading(false)
      return null
    }
  }

  const handleOnEdit = async r => {
    setMode('edit')
    const metadata = await fetchEmployeeTypeMetadata(r?.id)
    setSelectedEmployeeType({
      ...r,
      investmentProductCollection: metadata?.investmentProductCollection || [],
      loanProductCollection: metadata?.loanProductCollection || [],
      savingsProductCollection: metadata?.savingsProductCollection || [],
    })
  }

  const handleOnAdd = async () => {
    setMode('add')
    const metadata = await fetchEmployeeTypeMetadata(
      '00000000-0000-0000-0000-000000000000'
    )
    setSelectedEmployeeType({
      investmentProductCollection: metadata?.investmentProductCollection || [],
      loanProductCollection: metadata?.loanProductCollection || [],
      savingsProductCollection: metadata?.savingsProductCollection || [],
    })
  }

  const handleOnView = async r => {
    setMode('view')
    const metadata = await fetchEmployeeTypeMetadata(r?.id)
    setSelectedEmployeeType({
      ...r,
      investmentProductCollection: metadata?.investmentProductCollection || [],
      loanProductCollection: metadata?.loanProductCollection || [],
      savingsProductCollection: metadata?.savingsProductCollection || [],
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('humanResourceManagement'), localize('employeeTypes')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleOnAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="employeeTypeFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <EmployeeTypeModal
          selectedEmployeeType={selectedEmployeeType}
          setSelectedEmployeeType={setSelectedEmployeeType}
          mode={mode}
          onHideModal={() => {
            setMode(null)
          }}
          tabLoading={tabLoading}
          loadTableData={loadTableData}
          tableData={tableData}
        />
      )}
    </>
  )
}

export default EmployeeTypes
