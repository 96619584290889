/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../../../Helpers/formatters'
import GroupedTable from '../../../../../../../../../Components/Tables/GroupedTable'
import { getData } from '../../../../../../../../../Helpers/webApi'

export const CustomerAccounts = ({ selectedCustomer }) => {
  const [groupBy, setGroupBy] = useState({
    label: localize('productSection'),
    column: 'customerAccountTypeTargetProductSectionDescription',
  })

  const [customerAccountStatements, setCustomerAccountStatements] =
    useState(null)

  const [
    loadingCustomerAccountStatements,
    setLoadingCustomerAccountStatements,
  ] = useState(false)

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r.availableBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('penaltyBalance'),
      template: r => <>{formatCurrency(r.penaltyBalance)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{formatDate(r.loanClassificationDate)}</>,
    },
    {
      label: localize('expectedPaymentDueDate'),
      template: r => <>{formatDate(r.loanExpectedPaymentDueDate)}</>,
    },
    {
      label: localize('loanClassificationMode'),
      template: r => <>{r.loanClassificationMode}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r.loanCaseTotalAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r.loanDefaultAmount)}</>,
    },
    {
      label: localize('interestArrearsAmount'),
      template: r => <>{formatCurrency(r.interestDefaultAmount)}</>,
    },
    {
      label: localize('loanDefaultDate'),
      template: r => <>{formatDate(r.loanDefaultDate)}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => <>{r.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{formatCurrency(r.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{formatDate(r.loanCaseDisbursedDate)}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{formatDate(r.loanLastPaymentDueDate)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{formatCurrency(r.loanLastPayment)}</>,
    },
    {
      label: localize('loanTermMonths'),
      template: r => <>{r.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r => <>{r.loanCaseLoanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('loanRoundingType'),
      template: r => <>{r.loanCaseLoanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('availableOverDraftLimit'),
      template: r => <>{formatCurrency(r.availableOverdraftLimit)}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{formatCurrency(r.carryForwardsBalance)}</>,
    },
    {
      label: localize('piggyBankEnabled'),
      template: r => (
        <span className="text-capitalize">
          {r.piggyBankEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchCustomerAccounts = async () => {
    setLoadingCustomerAccountStatements(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccount/find-customer-accounts-by-customer-id',
      {
        customerId: selectedCustomer?.id,
        includeBalances: true,
        includeProductDescription: true,
      },
      false,
      null
    )
    if (success) {
      setCustomerAccountStatements(data)
      setLoadingCustomerAccountStatements(false)
    }
  }

  useEffect(() => {
    if (selectedCustomer) {
      fetchCustomerAccounts()
    }
  }, [selectedCustomer])

  return (
    <>
      {loadingCustomerAccountStatements ? (
        <>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait...</span>
          </div>
        </>
      ) : (
        <>
          <GroupedTable
            groupBy={groupBy}
            columns={columns}
            data={customerAccountStatements?.pageCollection}
          />
        </>
      )}
    </>
  )
}
