/** @format */

import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../Helpers/webApi'
import { ModuleInformation } from './Partials/ModuleInformation'
import {
  entitiesToBeChecked,
  getRoles,
} from '../../../../Helpers/selectablesHelper'
import Select from 'react-select'

function ModulesAccessControlList() {
  const [isBusy, setIsBusy] = useState(false)
  const [moduleItems, setModuleItems] = useState([])
  const [rolesToBeSelected, setRolesToBeSelected] = useState([])
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [selectedModule, setSelectedModule] = useState(null)
  const [showAuthorizedModal, setShowAuthorizedModal] = useState(false)
  const [currentNavigation, setCurrentNavigation] = useState(null)
  const [selMod, setSelMod] = useState(null)
  useEffect(() => {
    fetchModuleItems()
  }, [])

  useEffect(() => {
    fetchRoles()
  }, [])

  const fetchModuleItems = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AccessControl/find-access-control-list',
      null,
      false,
    )
    if (success) {
      setIsBusy(false)
      setModuleItems(data)
      setSelectedModule(data.accountsManagementModuleAccessList)
    } else {
      setIsBusy(false)
    }
  }

  const fetchRoles = async () => {
    setRoles(await getRoles())
    if (!selectedMenu) {
      setRolesToBeSelected(roles)
    }
  }

  const fetchRolesToBeSelected = async r => {
    if (r.children && r.children.length === 0) {
      setCurrentNavigation({
        Module: selectedModule.description,
        ParentDescription: r.parentDescription,
        Description: r.description,
        MenuCode: r.menuCode,
      })
      setIsBusy(true)
      setLoading(true)
      setShowAuthorizedModal(true)
      const { success, data } = await getData(
        `/lookup/Role/find-roles-in-menu`,
        {
          menuId: r.menuCode,
        },
        false,
      )
      if (success) {
        setIsBusy(false)
        setRolesToBeSelected(
          entitiesToBeChecked([...roles], data.result || [...roles]),
        )
        setLoading(false)
      } else {
        setIsBusy(false)
      }
    }
  }

  const addMenuInRole = async r => {
    setLoading(true)
    await postData(
      `${
        r.checkValue
          ? `/lookup/Role/add-menu-in-role?roleId=${r.selectedRole.id}&menuCode=${selectedMenu.menuCode}`
          : `/lookup/Role/remove-roles-in-menu?roleId=${r.selectedRole.id}&menuCode=${selectedMenu.menuCode}`
      }`,
      {},
      false,
      'Access Control List',
    )
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('accessControlList')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-auto me-2 text-capitalize">
                      {localize('module')}
                    </label>
                    <div style={{ width: '100%' }} className="text-capitalize">
                      <Select
                        // value={selMod}
                        onChange={e => {
                          setSelMod(e.value)
                          setSelectedModule(moduleItems[e.value])
                        }}
                        options={Object.entries(moduleItems)
                          .sort((a, b) =>
                            a[1]?.description?.localeCompare(b[1]?.description),
                          )
                          .map(([key, value]) => ({
                            label: localize(value.description) || key,
                            value: key,
                          }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <ModuleInformation
                    handleMenuSelected={r => {
                      setSelectedMenu(r)
                      fetchRolesToBeSelected(r)
                    }}
                    handleOnSingleCheck={addMenuInRole}
                    currentNavigation={currentNavigation}
                    showAuthorizedModal={showAuthorizedModal}
                    setShowAuthorizedModal={setShowAuthorizedModal}
                    rolesToBeSelected={rolesToBeSelected}
                    module={selectedModule}
                    isBusy={loading}
                    addMenuInRole={addMenuInRole}
                    setRolesToBeSelected={setRolesToBeSelected}
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModulesAccessControlList
