/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import Loader from '../../../../Components/Loaders/Loader'
import MembershipWithdrawalNotificationLookup from '../../../../Components/Lookup/MembershipWithdrawalNotificationLookup'
import MembershipWithdrawalSettlementBatchLookup from '../../../../Components/Lookup/MembershipWithdrawalSettlementBatchLookup'
import WithdrawalSettlementModeLookup from '../../../../Components/Lookup/WithdrawalSettlementModeLookup'
import AdHocLoanInterestCapitalization from '../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { LoansGuaranteed } from '../../../../Components/ReUsables/Customer/LoansGuaranteed'
import { ViewCustomerInvestmentAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  membershipWithdrawalAuditOption,
  withdrawalNotificationCategory,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import {
  batchStatus,
  withdrawalNotificationStatus,
} from '../../../../Helpers/constants'
import { getData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import AdHocStandingOrderExecutionModal from '../Catalogue/AddCatalogueModal/AdHocStandingOrderExecutionModal/AdHocStandingOrderExecutionModal'
import { formatCurrency } from '../../../../Helpers/formatters'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function Verification() {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingAccounts, setIsBusyLoadingAccounts] = useState(false)
  const [customer, setCustomer] = useState({})
  const [adHocStandingOrder, setAdHocStandingOrder] = useState({})
  const [showAdHocStandingOrderModal, setShowAdHocStandingOrderModal] =
    useState(false)
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    []
  )
  const [balance, setBalance] = useState({
    netPartialRefundable: '',
    netLoansBalance: '',
    netRefundable: '',
    netInvestmentsBalance: '',
    backOfficeInvestmentsBalance: '',
    backOfficeLoansBalance: '',
  })
  const [customerLoansGuarantor, setCustomerLoansGuarantor] = useState([])
  const [verification, setVerification] = useState({
    membershipWithdrawalAuditOption: Object.keys(
      membershipWithdrawalAuditOption
    )[0],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const deceased = Object.keys(withdrawalNotificationCategory)[0]
  const voluntary = Object.keys(withdrawalNotificationCategory)[1]
  const retiree = Object.keys(withdrawalNotificationCategory)[2]

  const handleSelectWithdrawalNotification = r => {
    if (r?.status !== withdrawalNotificationStatus.APPROVED)
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select an approved withdrawal notification'
      )

    clearValidation('WithdrawalNotification.Id')
    setCustomer(r)
  }

  const handleSelectSettlementBatch = r => {
    if (!customer?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Sorry, please select a withdrawal notification first'
      )

    if (r?.status !== batchStatus.PENDING)
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select a pending batch'
      )
    if (r?.category !== customer?.category)
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select a settlement mode in the same category as the withdrawal notification'
      )

    clearValidation('WithdrawalNotification.SettlementBatchId')
    setVerification({
      ...verification,
      settlementBatchNumber: r?.paddedBatchNumber,
      settlementBatchId: r?.id,
    })
  }

  const handleSelectMode = e => {
    if (e?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        "You can't select a locked settlement mode"
      )

    clearValidation('WithdrawalNotification.WithdrawalSettlementModeId')

    setVerification({
      ...verification,
      ...e,
      consolidationInvestmentProductDescription:
        e?.consolidationInvestmentProductDescription,
      savingsProductDescription: e?.savingsProductDescription,
      description: e?.description,
      withdrawalSettlementModeId: e?.id,
      settlementBatchNumber: verification?.settlementBatchNumber,
    })
  }

  const getCustomerAccounts = async () => {
    setIsBusyLoadingAccounts(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: customer?.customerId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerSavingsAccounts(data.customerSavingsAccounts)
      setCustomerLoanAccounts(data.customerLoanAccounts)
      setCustomerInvestmentAccounts(data.customerInvestmentAccounts)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    }

    setIsBusyLoadingAccounts(false)
  }

  const getCustomerGuaranteedLoans = async () => {
    setIsBusyLoadingAccounts(true)

    const { success, data } = await getData(
      '/LoanCase/find-loan-guarantor-by-customer-id',
      {
        customerId: customer?.customerId,
        includeOutstandingBalance: true,
        filterClearedLoans: true,
      },
      false
    )
    if (success) {
      setCustomerLoansGuarantor(data?.result)
    }

    setIsBusyLoadingAccounts(false)
  }

  const handleVerify = async () => {
    setIsBusy(true)
    const url = '/WithdrawalNotification/audit-withdrawal-notification'

    const { success } = await putData(
      url,
      {
        withdrawalNotification: {
          remarks: verification?.remarks,
          id: customer?.id,
          settlementBatchId: verification?.settlementBatchId,
          withdrawalSettlementModeId: verification?.withdrawalSettlementModeId,
        },
        membershipWithdrawalAuditOption:
          verification?.membershipWithdrawalAuditOption,
      },
      false
    )

    if (success) {
      setVerification({
        membershipWithdrawalAuditOption: Object.keys(
          membershipWithdrawalAuditOption
        )[0],
      })
      setCustomer({})
      setCustomerSavingsAccounts([])
      setCustomerLoanAccounts([])
      setCustomerInvestmentAccounts([])
      setBalance({
        netPartialRefundable: '',
        netLoansBalance: '',
        netRefundable: '',
        netInvestmentsBalance: '',
        backOfficeInvestmentsBalance: '',
        backOfficeLoansBalance: '',
      })
      setCustomerLoansGuarantor([])
      setGlobalValidationErrors({})
      showNotification('Approve Membership Withdrawal', 'success')
    }

    setIsBusy(false)
  }
  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    if (customer?.customerId) {
      getCustomerAccounts()
      getCustomerGuaranteedLoans()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerId])

  const tabItems = [
    {
      label: localize('loanAccounts'),
      item: (
        <>
          {isBusyLoadingAccounts ? (
            <Loader />
          ) : (
            <ViewCustomerLoanAccounts
              customerLoanAccounts={customerLoanAccounts}
              handleAdHocLoan={r => {
                setLoanAccount(r)
                setShowAdHocModal(true)
              }}
              hiddenColumnsIndexes={[0]}
            />
          )}
        </>
      ),
    },
    {
      label: localize('savingsAccounts'),
      item: (
        <>
          {isBusyLoadingAccounts ? (
            <Loader />
          ) : (
            <ViewCustomerSavingsAccounts
              customerSavingsAccounts={customerSavingsAccounts}
            />
          )}
        </>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <>
          {isBusyLoadingAccounts ? (
            <Loader />
          ) : (
            <ViewCustomerInvestmentAccounts
              customerInvestmentAccounts={customerInvestmentAccounts}
              handleExecuteStandingOrder={r => {
                setAdHocStandingOrder(r)
                setShowAdHocStandingOrderModal(true)
              }}
            />
          )}
        </>
      ),
    },
    {
      label: localize('loanGuaranteed'),
      item: (
        <>
          {isBusyLoadingAccounts ? (
            <Loader />
          ) : (
            <LoansGuaranteed loanGuarantors={customerLoansGuarantor} />
          )}
        </>
      ),
    },
  ]

  return (
    <>
      {showAdHocModal && (
        <AdHocLoanInterestCapitalization
          handleClose={() => {
            setShowAdHocModal(false)
          }}
          loanAccount={loanAccount}
        />
      )}
      {showAdHocStandingOrderModal && (
        <AdHocStandingOrderExecutionModal
          handleClose={() => {
            setShowAdHocStandingOrderModal(false)
          }}
          adHocStandingOrder={adHocStandingOrder}
        />
      )}

      <BreadCrumb
        tree={[
          localize('verifyMembershipWithdrawal'),
          localize('verification'),
        ]}
      />
      <div className="card bg-white rounded relative">
        <div className="card-body">
          <div className="row g-3 mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['WithdrawalNotification.Id']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <MembershipWithdrawalNotificationLookup
                displayValue={customer?.customerFullName}
                status={withdrawalNotificationStatus.APPROVED}
                handleSelect={handleSelectWithdrawalNotification}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  customer?.customerMembershipClassCustomerTypeDescription || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                value={customer?.customerMembershipStatusDescription || ''}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipWdr.Date')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={customer?.customerMembershipWithdrawalDate || ''}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('domicileBranch')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerBranchDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerEmployerDescription || ''}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('dutyWorkStation')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerDutyStationDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('adminDivision')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  customer?.customerAdministrativeDivisionDescription || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerIdentificationNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.formattedCustomerMemberNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.paddedCustomerAccountNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerPersonalFileNumber || ''}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('transactionReference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.transactionReferenceDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationCategory')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.categoryDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.remarks || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.createdBy || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={customer?.createdDate || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.statusDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.approvedBy || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvalDate')}
                </label>
                <CustomDatePicker
                  defaultDate={customer?.approvedDate || ''}
                  name={'approvalDate'}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvalRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.approvalRemarks || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 pe-2 text-capitalize">
                  {localize('settlementBatchNumber')}
                </label>
                <MembershipWithdrawalSettlementBatchLookup
                  displayValue={verification?.settlementBatchNumber}
                  onSelect={handleSelectSettlementBatch}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="d-flex align-items-center mt-1 with-validation"
                validation-message={
                  globalValidationErrors[
                    'WithdrawalNotification.WithdrawalSettlementModeId'
                  ]?.message
                }
              >
                <label className="col-4 text-capitalize">
                  {localize('selectMode')}
                </label>
                <WithdrawalSettlementModeLookup
                  displayValue={verification?.description || ''}
                  onSelect={handleSelectMode}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('consolidationProduct')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={verification?.savingsProductDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('disbursementProduct')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    verification?.consolidationInvestmentProductDescription ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3">
              <Tab tabItems={tabItems} />
            </div>
          </div>

          <div className="bg-light rounded p-2">
            {customer && customer.terminationCategory !== deceased && (
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('deposits(Refundable)')}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance.netInvestmentsBalance
                          : balance.backOfficeInvestmentsBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('loansBalance')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance.netLoansBalance
                          : balance.backOfficeLoansBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('netRefundable')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance?.netRefundable
                          : balance?.netPartialRefundable,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-md-4 offset-md-4">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['WithdrawalNotification.Remarks']
                      ?.message
                  }
                >
                  <label className="col-4 text-capitalize">
                    {localize('auditRemarks')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={verification?.remarks || ''}
                    onChange={e => {
                      clearValidation('WithdrawalNotification.Remarks')
                      setVerification({
                        ...verification,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-between align-items-center">
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 170,
                  }}
                  enumsKey="MembershipWithdrawalAuditOption"
                  value={verification.membershipWithdrawalAuditOption}
                  onChange={e => {
                    setVerification({
                      ...verification,
                      membershipWithdrawalAuditOption: e.value,
                    })
                  }}
                />

                <button className="btn btn-success" onClick={handleVerify}>
                  {localize('update')}
                </button>
              </div>
            </div>
          </div>

          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
    </>
  )
}

export default Verification
