/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
function AccruedInterestEntries({
  selectedAccruedInterest,
  setApportioned,
  setEntriesTotal,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 2,
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: false,
  })
  const [search, setSearch] = useState('')

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('yield'),
      template: r => <>{formatCurrency(r.yield)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('exceptionMessage'),
      template: r => <>{r.exceptionMessage}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const loodAccruedInterestEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/AccruedInterestBatch/find-accrued-interest-batch-entries-by-accrued-interest-batch-id-in-page`,
      {
        accruedInterestId: selectedAccruedInterest.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setIsBusy(true)
      setEntries(data.pageCollection)
      setApportioned(data.totalApportioned)
      setEntriesTotal(data.totalItems)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loodAccruedInterestEntries()
  }, [selectedAccruedInterest?.id, reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row px-3 mb-2">
            <RecordsPerPage
              className="col-2"
              reqParams={reqParams}
              setReqParams={setReqParams}
            />
            <div className="d-flex align-items-center col-2">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={reqParams?.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>
            <form
              className="d-flex align-items-center col-8 col"
              onSubmit={e => {
                e.preventDefault()
                setReqParams({
                  ...reqParams,
                  filterText: search,
                })
              }}
            >
              <label className="ms-2 text-capitalize" htmlFor="searchTable">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerFilter"
                value={reqParams?.customerFilter}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    customerFilter: e.value,
                  })
                }
              />
              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                defaultValue={reqParams.filterText}
                onChange={e => {
                  setSearch(e.target.value)
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                  })
                }}
              />
              <button
                id="bankToBankRequestFilterButton"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  loodAccruedInterestEntries()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>
          <SimpleTable selectables columns={columns} data={entries} />
        </>
      )}
    </>
  )
}
export default AccruedInterestEntries
