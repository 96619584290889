/** @format */

import localize from '../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import { alternateChannelType } from '../../../Global/enumeration'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import { getData, postData } from '../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../Helpers/extensions'
import { formatDate } from '../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import { object, string } from 'yup'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'

export const MessageTypeIndicators = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedAlternateChannelType, setSelectedAlternateChannelType] =
    useState('64')
  const [messageTypeIndicatorsCollection, setMessageTypeIndicatorsCollection] =
    useState([])
  const [messageTypeIndicatorModel, setMessageTypeIndicatorModel] = useState({
    messageTypeIndicator: '',
    description: '',
  })

  function clearValidation(column) {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    getAlternateChannelTypeMessageTypeIndicators(selectedAlternateChannelType)
  }, [])

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const runValidation = async model => {
    let messageTypeIndicatorSchema = object({
      description: string().required('Description is required'),
      messageTypeIndicator: string().required('Enter message type indicator'),
    })
    try {
      await messageTypeIndicatorSchema.validate(model, { abortEarly: false })
      return true
    } catch (e) {
      const validations = e.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setValidationBag(validations)
    }
  }

  const addMessageTypeIndicator = async () => {
    let newMessageTypeIndicatorModel = { ...messageTypeIndicatorModel }
    const modelValid = await runValidation(newMessageTypeIndicatorModel)
    if (modelValid) {
      if (
        messageTypeIndicatorsCollection.findIndex(
          x =>
            x.messageTypeIndicator ===
            messageTypeIndicatorModel.messageTypeIndicator
        ) > -1
      ) {
        Swal.fire({
          icon: 'error',
          text: `${messageTypeIndicatorModel.messageTypeIndicator} message Type Indicator already exists  `,
          title: 'Add Message Type Indicator',
          showCloseButton: true,
        })
        return
      }
      setMessageTypeIndicatorsCollection([
        ...messageTypeIndicatorsCollection,
        {
          messageTypeIndicator: messageTypeIndicatorModel.messageTypeIndicator,
          description: messageTypeIndicatorModel.description,
          clientId: generateGuid(),
        },
      ])
      setMessageTypeIndicatorModel({
        messageTypeIndicator: '',
        description: '',
      })
    }
  }

  const removeMessageTypeIndicator = entry => {
    setMessageTypeIndicatorsCollection(
      messageTypeIndicatorsCollection.filter(item => {
        const itemClientId = item.id || item.clientId
        const entryClientId = entry.id || entry.clientId
        return itemClientId !== entryClientId
      })
    )
  }

  const getAlternateChannelTypeMessageTypeIndicators =
    async alternateChannelType => {
      setIsBusy(true)
      const { success, data } = await getData(
        '/AlternateChannelConfiguration/find-alternate-channel-message-type-indicators',
        {
          alternateChannelType,
        },
        false
      )
      if (success) {
        setIsBusy(false)
        setMessageTypeIndicatorsCollection(data.result || [])
      } else {
        setIsBusy(false)
      }
    }

  async function handleSubmit(finalData) {
    setIsBusy(true)
    const { success } = await postData(
      '/AlternateChannelConfiguration/update-alternate-channel-message-type-indicators',
      finalData,
      false,
      'Message Type Indicators'
    )

    if (success) {
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('messageTypeIndicators'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center with-validation">
                      <label className="col-auto me-2 text-capitalize">
                        {localize('channelType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="AlternateChannelType"
                        value={selectedAlternateChannelType}
                        onChange={c => {
                          setSelectedAlternateChannelType(c.value)
                          getAlternateChannelTypeMessageTypeIndicators(c.value)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="p-2 mt-4"
                    style={{
                      border: 'solid 1px #0000001f',
                      borderRadius: 10,
                      position: 'relative',
                    }}
                  >
                    <div
                      className="bg-white px-2 row text-capitalize"
                      style={{
                        position: 'relative',
                        top: -20,
                        left: 30,
                        maxWidth: 200,
                        marginBottom: -10,
                      }}
                    >
                      {localize('messageTypeIndicatorDetails')}
                    </div>
                    <div className="row mb-3">
                      <div
                        validation-message={validationBag?.messageTypeIndicator}
                        className="col d-flex align-items-center with-validation"
                      >
                        <label className="col-3 text-capitalize">
                          {localize('messageTypeIndicator')}
                        </label>
                        <input
                          className="form-control"
                          value={messageTypeIndicatorModel.messageTypeIndicator}
                          onChange={c => {
                            clearValidation('messageTypeIndicator')
                            setMessageTypeIndicatorModel({
                              ...messageTypeIndicatorModel,
                              messageTypeIndicator: c.target.value,
                            })
                          }}
                        />
                      </div>
                      <div
                        validation-message={validationBag?.description}
                        className="col d-flex align-items-center with-validation"
                      >
                        <label className="col-2 text-capitalize">
                          {localize('description')}
                        </label>
                        <input
                          className="form-control"
                          value={messageTypeIndicatorModel.description}
                          onChange={c => {
                            clearValidation('description')
                            setMessageTypeIndicatorModel({
                              ...messageTypeIndicatorModel,
                              description: c.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="bg-light d-flex justify-content-end align-items-center p-2"
                      style={{ borderRadius: 5 }}
                    >
                      <button
                        onClick={() => addMessageTypeIndicator()}
                        className="btn btn-success text-uppercase ms-3 px-4"
                      >
                        {localize('add')}
                      </button>
                    </div>
                    <MessageTypeIndicatorsDetails
                      addMode
                      onRemoveMessageTypeIndicator={r => {
                        removeMessageTypeIndicator(r)
                      }}
                      messageTypeIndicators={messageTypeIndicatorsCollection}
                    />
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        onClick={() => {
                          handleSubmit({
                            AlternateChannelType: selectedAlternateChannelType,
                            MessageTypeIndicators:
                              messageTypeIndicatorsCollection,
                          })
                        }}
                        className="btn btn-success text-uppercase px-4"
                        type="button"
                      >
                        {localize('update')}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function MessageTypeIndicatorsDetails({
  addMode,
  onRemoveMessageTypeIndicator,
  messageTypeIndicators,
}) {
  const columns = [
    {
      label: localize('messageTypeIndicator'),
      template: r => <>{r?.messageTypeIndicator}</>,
    },
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <span className="text-capitalize">{r?.createdBy}</span>,
    },
    {
      label: localize('createdDate'),
      template: r => (
        <span className="text-capitalize">{formatDate(r?.createdDate)}</span>
      ),
    },
  ]

  return (
    <SimpleTable
      selectables
      contextMenu={r =>
        addMode && (
          <>
            <div
              onClick={() => {
                onRemoveMessageTypeIndicator(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-times-circle text-danger text-capitalize"></i>
              {localize('remove')}
            </div>
          </>
        )
      }
      columns={columns}
      data={messageTypeIndicators}
    />
  )
}
