/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import DebitBatchEntries from '../../BatchEntries/DebitBatchEntries/DebitBatchEntries'
import { batchEntryStatus } from '../../../../Global/enumeration'

const ViewDebitBatchModal = ({ batch, handleClose, mode }) => {
  return (
    <ModalTemplate
      modalTitle="debitBatch"
      hideUpdate
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      <div className="row g-3">
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('debitType')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.debitTypeDescription}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('priority')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.priorityDescription}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.reference}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.createdBy}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.debitBatch?.createdDate)}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.statusDescription}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('verifiedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.auditedBy}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('verifiedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.debitBatch?.auditedDate)}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('verificationRemarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.auditRemarks}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('authorizedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.authorizedBy}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('authorizedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.debitBatch?.authorizedDate)}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('authorizationRemarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.debitBatch?.authorizationRemarks}
              disabled
            />
          </div>
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2  row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('batchEntries')}
        </div>
        <div className="row mt-4">
          <DebitBatchEntries
            debitBatchId={batch?.debitBatch?.id}
            status={Object.keys(batchEntryStatus)[0]}
            showSelect={false}
            hiddenColumns={[0]}
          />
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ViewDebitBatchModal
