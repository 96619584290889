/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import Tab from '../Tabs/Tab'
import { getData } from '../../Helpers/webApi'
import SimpleTableWithSearchAndPaging from '../Tables/SimpleTableWithSearchAndPaging'
import { formatDate } from '../../Helpers/formatters'
import Swal from 'sweetalert2'

function ChartOfAccountsLookup({
  displayValue,
  onSelect,
  canSelectHeader,
  disabled,
}) {
  const [modalActive, setModalActive] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    chartOfAccountType: 1000,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [data, setData] = useState(null)

  useEffect(() => {
    if (modalActive) loadChartOfAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  async function loadChartOfAccounts() {
    setIsBusy(true)
    setData(null)
    const resp = await getData(
      '/lookup/ChartOfAccounts/find-chart-of-accounts-by-filter-in-page',
      reqParams,
      false
    )
    if (resp.success) {
      setData(resp.data)
    }

    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('accountName'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedName }} />
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('costDistributionRule'),
      template: r => <>{r?.costDistributionRuleDescription}</>,
    },
    {
      label: localize('currency'),
      template: r => <>{r?.currencyDescription}</>,
    },
    { label: localize('treeDepth'), template: r => <>{r?.depth}</> },
    {
      label: localize('isControlAcount') + '?',
      template: r => <>{r?.isControlAccount}</>,
    },
    {
      label: localize('isReconciliationAccount') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.isReconciliationAccount.toString()}
        </span>
      ),
    },
    {
      label: localize('postAutomaticallyOnly') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.postAutomaticallyOnly.toString()}
        </span>
      ),
    },
    {
      label: localize('isConfidential') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isConfidential.toString()} </span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()} </span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('assets'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            isBusy={isBusy}
            data={data}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {
              if (r?.category === '4096' && !canSelectHeader) {
                Swal.fire('Invalid Item', 'Cannot select header entry', 'info')
              } else {
                setModalActive(false)
                onSelect(r)
              }
            }}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
      ),
      enum: 1000,
    },
    {
      label: localize('liabilities'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            isBusy={isBusy}
            data={data}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {
              if (r?.category === '4096' && !canSelectHeader) {
                Swal.fire('Invalid Item', 'Cannot select header entry', 'info')
              } else {
                setModalActive(false)
                onSelect(r)
              }
            }}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
      ),
      enum: 2000,
    },
    {
      label: localize('equity') + '/' + localize('capital'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {
              if (r?.category === '4096' && !canSelectHeader) {
                Swal.fire('Invalid Item', 'Cannot select header entry', 'info')
              } else {
                setModalActive(false)
                onSelect(r)
              }
            }}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
      ),
      enum: 3000,
    },
    {
      label: localize('income') + '/' + localize('revenue'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            isBusy={isBusy}
            data={data}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {
              if (r?.category === '4096' && !canSelectHeader) {
                Swal.fire('Invalid Item', 'Cannot select header entry', 'info')
              } else {
                setModalActive(false)
                onSelect(r)
              }
            }}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
      ),
      enum: 4000,
    },
    {
      label: localize('expenses'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            isBusy={isBusy}
            data={data}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {
              if (r?.category === '4096' && !canSelectHeader) {
                Swal.fire('Invalid Item', 'Cannot select header entry', 'info')
              } else {
                setModalActive(false)
                onSelect(r)
              }
            }}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </div>
      ),
      enum: 5000,
    },
  ]
  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white">
            <span className="text-capitalize">
              {localize('chartOfAccountLookUp')}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white">
            <Tab
              tabItems={tabItems}
              onNavigate={x => {
                setReqParams({ ...reqParams, chartOfAccountType: x.enum })
              }}
            />
          </ModalBody>
          <ModalFooter className="bg-white">
            <button
              className="btn btn-danger text-capitalize px-3"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default ChartOfAccountsLookup
