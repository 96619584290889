/** @format */

import React, { useEffect, useState } from 'react'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { getData } from '../../../../Helpers/webApi'
import { formatDate } from '../../../../Helpers/formatters'
import ChequeTypesModal from './ChequeTypesModal'
import { chequeTypeChargeRecoveryMode, maturityDaysCategory } from '../../../../Helpers/constants'

function ChequeTypes() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [waitingMetadata, setWaitingMetadata] = useState(false)
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedChequeType, setSelectedChequeType] = useState(null)
  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('maturityDaysCategoryDescription'),
      template: r => <>{r.maturityDaysCategoryDescription}</>,
    },
    {
      label: localize('maturityPeriod'),
      template: r => <>{r.maturityPeriod}</>,
    },
    {
      label: localize('chargeRecoveryModeDescription'),
      template: r => <>{r.chargeRecoveryModeDescription}</>,
    },
    {
      label: localize('preserveAccountBalance'),
      template: r => (
        <span className="text-capitalize">
          {r.preserveAccountBalance.toString()}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/ChequeType/find-cheque-types-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const fetchChequeTypeMetadata = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/ChequeType/find-cheque-type-metadata',
      { chequeTypeId: id },
      false
    )
    if (success) {
      setIsBusy(false)
      return data
    } else {
      setIsBusy(false)
      return null
    }
  }

  const fetchViewChequeTypeMetadata = async id => {
    setWaitingMetadata(true)
    const { success, data } = await getData(
      '/ChequeType/find-cheque-type-metadata',
      { chequeTypeId: id },
      false
    )
    if (success) {
      setWaitingMetadata(false)
      return data
    } else {
      setWaitingMetadata(false)
      return null
    }
  }

  const handleOnEdit = async r => {
    const metadata = await fetchChequeTypeMetadata(r?.id)
    setSelectedChequeType({
      ...r,
      commissions: metadata?.commissions || [],
      investmentProducts: metadata?.investmentProducts || [],
      loanProducts: metadata?.loanProducts || [],
    })
    setMode('edit')
  }

  const handleOnAdd = async r => {
    setMode('add')
    const metadata = await fetchChequeTypeMetadata(
      '00000000-0000-0000-0000-000000000000'
    )
    setSelectedChequeType({
      maturityDaysCategory: maturityDaysCategory.BUSINESS_DAYS,
      chargeRecoveryMode: chequeTypeChargeRecoveryMode.ON_CHEQUE_DEPOSIT,
      commissions: metadata?.commissions || [],
      investmentProducts: metadata?.investmentProducts || [],
      loanProducts: metadata?.loanProducts || [],
    })
  }

  const handleOnViewChequeTypeMetadata = async r => {
    setMode('view')
    const metadata = await fetchViewChequeTypeMetadata(r?.id)
    setSelectedChequeType({
      ...r,
      commissions: metadata?.commissions || [],
      investmentProducts: metadata?.investmentProducts || [],
      loanProducts: metadata?.loanProducts || [],
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('frontOfficeManagement'), localize('chequeType')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <label
                          className={'text-capitalize'}
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                              pageIndex: 0,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnViewChequeTypeMetadata(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChequeTypesModal
        waitingMetadata={waitingMetadata}
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedChequeType={setSelectedChequeType}
        selectedChequeType={selectedChequeType}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default ChequeTypes
