/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'

const MorgueServices = ({ patientData, setAmount }) => {
  const [services, setServices] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const fetchServices = async () => {
    setIsBusy(true)
    const url = '/MorguePatient/find-morgue-services-by-morgue-patient-id'
    const { success, data } = await getData(
      url,
      {
        morguePatientId: patientData?.id,
      },
      false
    )

    if (success) {
      setServices(data?.morgueServices)
      if (setAmount) setAmount(data?.totalAmount)
    }

    setIsBusy(false)
  }

  const columns = [
    { label: 'description', template: r => <>{r?.description}</> },
    { label: 'multiplier', template: r => <>{r?.multiplier}</> },
    { label: 'amount', template: r => <>{formatCurrency(r?.amount)}</> },
  ]

  useEffect(() => {
    if (patientData?.id) fetchServices()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData?.id])

  return (
    <>
      {isBusy ? <Loader /> : <SimpleTable columns={columns} data={services} />}
    </>
  )
}

export default MorgueServices
