/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { getData, putData } from '../../../../../../Helpers/webApi'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { batchEntryStatus } from '../../../../../../Global/enumeration'
import Pager from '../../../../../../Components/Tables/Pager'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { generateGuid } from '../../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

function LedgerEntries({
  batch,
  disableContext,
  canSelect,
  showRemove,
  refreshEntries,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [params, setParams] = useState({
    generalLedgerId: batch?.id,
    status: 1,
    pageIndex: 0,
    pageSize: 10,
  })
  const [entries, setEntries] = useState(null)

  const [selected, setSelected] = useState([])

  async function loadEntries() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/generalLedger/find-entries-by-general-ledger-id-in-page',
      params,
      false
    )
    if (success) {
      setSelected([])
      setEntries(data)
    }
    setIsBusy(false)
  }

  async function removeSelectedEntries() {
    const { isConfirmed } = await Swal.fire({
      title: 'Sure',
      text: `Are you sure you want to remove ${selected.length} Entries?`,
      icon: 'question',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    })
    if (isConfirmed) {
      setIsBusy(true)
      const { success } = await putData(
        '/generalLedger/remove-general-ledger-entries',
        selected,
        false,
        selected.length + ' Entries Removed!'
      )
      if (success) {
        setRefresh(!refresh)
      }
      setIsBusy(false)
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.pageSize, params?.pageIndex, refresh, refreshEntries])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={selected.includes(r)}
            onCheck={() => selectEntry(r)}
          />
        </>
      ),
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('creditGLAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: `${localize('creditRef')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('creditRef')}_2 (${localize('member')} #)`,
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('creditRef')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('debitGLAccountName'),
      template: r => <>{r?.contraChartOfAccountName}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r?.contraCustomerAccountFullAccountNumber}</>,
    },
    {
      label: `${localize('debitCreditRef')}_1 (${localize('account')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('debitCreditRef')}_2 (${localize('member')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('debitCreditRef')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.contraCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    {
      label: localize('contraCostCenter'),
      template: r => <>{r?.contraCostCenterDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const componentId = generateGuid()

  function selectEntry(entry) {
    const foundIndex = selected.findIndex(s => s.id === entry.id)
    if (foundIndex === -1) {
      setSelected([...selected, entry])
    } else {
      let sel = [...selected]
      sel.splice(foundIndex, 1)
      setSelected(sel)
    }
  }

  function toggleSelectAll() {
    if (selected.length === (entries?.pageCollection || []).length) {
      setSelected([])
    } else {
      setSelected(entries?.pageCollection || [])
    }
  }

  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className=" my-2 d-flex align-items-center justify-content-between">
            <RecordsPerPage
              className="col-3"
              reqParams={params}
              setReqParams={setParams}
            />
            {canSelect && (
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id={`${componentId}-selectAll`}
                  onCheck={toggleSelectAll}
                  checked={
                    (entries?.pageCollection || []).length ===
                      selected.length &&
                    (entries?.pageCollection || []).length > 0
                  }
                />
                <label
                  htmlFor={`${componentId}-selectAll`}
                  className="text-capitalize"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
            )}
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize"
              >
                {localize('status')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={params?.status}
                onChange={e => setParams({ ...params, status: e.value })}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <label
                  style={{ minWidth: 'fit-content' }}
                  className="text-capitalize"
                >
                  {localize('apportioned')}
                </label>
                <input
                  value={formatCurrency(
                    entries?.totalApportioned,
                    null,
                    null,
                    false
                  )}
                  style={{ textAlign: 'end' }}
                  type="text"
                  className="form-control ms-2"
                  disabled
                />
              </div>

              <div className="d-flex align-items-center ms-3">
                <label
                  style={{ minWidth: 'fit-content' }}
                  className="text-capitalize"
                >
                  {localize('shortage')}
                </label>
                <input
                  value={formatCurrency(
                    entries?.totalShortage,
                    null,
                    null,
                    false
                  )}
                  style={{ textAlign: 'end' }}
                  type="text"
                  className="form-control ms-2"
                  disabled
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                value={entries?.statusEntries}
                className="form-control ms-2"
                disabled
                style={{ maxWidth: 'fit-content' }}
              />
              <button
                className="btn btn-primary ms-2"
                onClick={() => setRefresh(!refresh)}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>

          <SimpleTable
            columns={canSelect ? columns : [...columns].slice(1, -1)}
            data={entries?.pageCollection || []}
            contextMenu={
              disableContext
                ? null
                : r => (
                    <>
                      <div className="ctxt-menu-item">
                        <i className="uil uil-sync"></i>
                        <span className="text-capitalize">
                          {localize('reset')}
                        </span>
                      </div>
                    </>
                  )
            }
          />
          <div className="mt-2">
            <Pager
              itemsCount={entries?.itemsCount || 0}
              pageSize={params?.pageSize}
              pageIndex={params?.pageIndex}
              onPageChange={p => setParams({ ...params, pageIndex: p })}
              pageBatchSize={5}
            />
          </div>
          {selected.length > 0 && showRemove && (
            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                className="btn btn-danger px-4"
                onClick={removeSelectedEntries}
              >
                {localize('remove')} ({selected.length})
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LedgerEntries
