/** @format */

import React, { useState } from 'react'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { postData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const DirectPurchaseEntriesItems = ({
  edit,
  unpostedEntries,
  handleRemove,
  mode,
  entries,
  onSelectItem,
  loadEntries,
}) => {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const onCheckEntries = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedEntries([])
      setSelectedEntries([...entries])
    } else {
      setSelectedEntries([])
      setToggleSelection(checkStatus)
    }
  }

  const removeEntries = async () => {
    if (selectedEntries.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'Select Atleast One Entry',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    setIsBusy(true)
    const { success } = await postData(
      '/DirectPurchase/remove-direct-purchase-items',
      selectedEntries,
      false
    )
    if (success) {
      loadEntries()
      showNotification('Direct Purchase', 'success')
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
              onSelectItem([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
              onSelectItem(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('location'),
      template: r => <>{r.locationDescription}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{r.quantity}</>,
    },
    {
      label: localize('unitCost'),
      template: r => <>{formatCurrency(r.unitCost)}</>,
    },
    {
      label: localize('manufacturer'),
      template: r => <>{r.manufacturer}</>,
    },
    {
      label: localize('model'),
      template: r => <>{r.model}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.serialNumber}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
  ]
  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={edit ? columns : columns.slice(1, -1)}
            data={mode === 'add' ? unpostedEntries : entries}
            contextMenu={
              mode !== 'add'
                ? null
                : r => (
                    <>
                      <div
                        onClick={() => {
                          handleRemove(r)
                        }}
                        className="ctxt-menu-item"
                      >
                        <i className="uil uil-trash text-danger"></i>
                        {localize('remove')}
                      </div>
                    </>
                  )
            }
          />
          {mode === 'edit' && (
            <>
              <div className="row mb-3 g-3 mt-2">
                <div className="col-md-6">
                  <div className="d-flex align-items-center mb-2">
                    <Checkbox
                      id="toggle-selection"
                      checked={toggleSelection}
                      onCheck={v => {
                        onCheckEntries(v)
                      }}
                    />
                    <label
                      htmlFor="toggle-selection"
                      className="text-capitalize ms-2"
                    >
                      {localize('toggleSelection')}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex justify-content-end align-items-end">
                    <button
                      onClick={removeEntries}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('remove')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default DirectPurchaseEntriesItems
