/** @format */

import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import ListNextOfKin from './ListNextOfKin'

const NextOfKinDetails = ({
  nextOfKin,
  setNextOfKin,
  readOnly,
  nextOfKins,
  handleAddNextOfKin,
  handleRemoveNextOfKin,
  handleSelectNextOfKin,
  selectedNextOfKins,
  isBusyFetchingNextOfKin,
}) => {
  return (
    <>
      <div className="row g-3">
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            className="ms-lg-1"
            enumsKey="Salutation"
            value={nextOfKin?.nextOfKinSalutation}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                nextOfKinSalutation: e.value,
                nextOfKinSalutationDescription: e?.label,
              })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={nextOfKin?.nextOfKinGender}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                nextOfKinGender: e.value,
                genderDescription: e?.label,
              })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="SignatoryRelationship"
            value={nextOfKin?.nextOfKinRelationship}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                nextOfKinRelationship: e.value,
                nextOfKinRelationshipDescription: e?.label,
              })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control ms-lg-1"
            value={nextOfKin?.firstName}
            onChange={e =>
              setNextOfKin({ ...nextOfKin, firstName: e.target.value })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.lastName}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, lastName: e.target.value })
              setNextOfKin({
                ...nextOfKin,
              })
            }}
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={nextOfKin?.nextOfKinIdentityCardType}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                nextOfKinIdentityCardType: e.value,
                identityCardTypeDescription: e?.label,
              })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-lg-1"
            value={nextOfKin?.identityCardNumber}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                identityCardNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('addressLine')} 1
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressAddressLine1}
            onChange={e => {
              setNextOfKin({
                ...nextOfKin,
                addressAddressLine1: e.target.value,
              })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('addressLine')} 2
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressAddressLine2}
            onChange={e => {
              setNextOfKin({
                ...nextOfKin,
                addressAddressLine2: e.target.value,
              })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('physicalAddress')}
          </label>
          <input
            type="text"
            className="form-control ms-lg-1"
            value={nextOfKin?.addressStreet}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, addressStreet: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">{localize('city')}</label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressCity}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, addressCity: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('postalCode')}
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressPostalCode}
            onChange={e =>
              setNextOfKin({ ...nextOfKin, addressPostalCode: e.target.value })
            }
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">{localize('email')}</label>
          <input
            type="text"
            className="form-control ms-lg-1"
            value={nextOfKin?.addressEmail}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, addressEmail: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressLandLine}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, addressLandLine: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <label className="text-capitalize col-3">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={nextOfKin?.addressMobileLine}
            onChange={e => {
              setNextOfKin({ ...nextOfKin, addressMobileLine: e.target.value })
            }}
            disabled={readOnly}
            autoComplete="off"
          />
        </div>
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('remarks')}</label>
          <textarea
            className="form-control"
            value={nextOfKin?.remarks}
            onChange={e =>
              setNextOfKin({
                ...nextOfKin,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="mt-3 rounded bg-light p-2 d-flex align-items-center justify-content-end gap-2">
        <div className="d-flex align-item-center">
          <Checkbox
            id="isPrimaryNextOfKin"
            checked={nextOfKin?.isPrimary}
            onCheck={value => {
              setNextOfKin({
                ...nextOfKin,
                isPrimary: value,
              })
            }}
          />
        </div>

        <label htmlFor="isPrimaryNextOfKin" className="text-capitalize">
          {localize('isPrimary') + ' ?'}
        </label>

        <button className="btn btn-success" onClick={handleAddNextOfKin}>
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2  row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('nextOfKins')}
        </div>

        {isBusyFetchingNextOfKin ? (
          <Loader />
        ) : (
          <>
            <ListNextOfKin
              handleSelectNextOfKin={handleSelectNextOfKin}
              nextOfKins={nextOfKins}
              selectedNextOfKins={selectedNextOfKins}
              showSelect
            />
            <div className="mt-3 rounded bg-light p-2 d-flex align-items-center justify-content-end gap-2">
              <button
                className="btn btn-danger"
                onClick={handleRemoveNextOfKin}
              >
                {localize('remove')}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default NextOfKinDetails
