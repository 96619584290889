/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import VehicleLookup from '../../../../../Components/Lookup/VehicleLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import CustomerPreview from '../Collections/CustomerPreview/CustomerPreview'
import ProductsListing from './ProductsListing/ProductsListing'
import SelectedProductDetails from './SelectedProductDetails/SelectedProductDetails'

const Statements = () => {
  const [vehicle, setVehicle] = useState({})
  const [products, setProducts] = useState([])
  const [customer, setCustomer] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})
  const [isBusyLoadingProducts, setIsBusyLoadingProducts] = useState(false)
  const [customerAccount, setCustomerAccount] = useState({})

  const fetchProducts = async () => {
    setIsBusyLoadingProducts(true)

    const url = `/Vehicle/find-vehicle-products-by-vehicle-id?vehicleId=${vehicle?.id}`

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setProducts(data?.result)
      setSelectedProduct(data?.result?.[0])
    }

    setIsBusyLoadingProducts(false)
  }

  const handleSelectVehicle = vehicle => {
    setVehicle(vehicle)
  }

  const handleSelectProduct = product => {
    setSelectedProduct(product)
  }

  const fetchCustomerAccount = async () => {
    const url = `/lookup/CustomerAccount/find-customer-account`

    const { success, data } = await getData(
      url,
      {
        customerAccountId: selectedProduct?.customerAccountId,
        includeBalances: false,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccount(data)
    }
  }
  const fetchCustomer = async () => {
    const url = `/lookup/customer/find-customer-by-id`

    const { success, data } = await getData(
      url,
      {
        customerId: vehicle?.customerId,
      },
      false
    )

    if (success) {
      setCustomer(data?.customer)
    }
  }

  useEffect(() => {
    if (vehicle?.id) {
      fetchProducts()
      fetchCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.id])

  useEffect(() => {
    if (selectedProduct?.customerAccountId) {
      fetchCustomerAccount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.customerAccountId])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('vehicleFleet'),
          localize('accounts'),
          localize('vehicleStatements'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('vehicleDetails'),
                  item: (
                    <>
                      <div className="row g-2 mb-4">
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-4">
                            {localize('registrationNumber')}
                          </label>
                          <VehicleLookup
                            onSelect={handleSelectVehicle}
                            displayValue={vehicle?.registrationNumber}
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-4">
                            {localize('fleetNumber')}
                          </label>
                          <input
                            className="form-control"
                            value={vehicle?.fleetNumber}
                            disabled
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-4">
                            {localize('vehicleRoute')}
                          </label>
                          <input
                            className="form-control"
                            value={vehicle?.vehicleRouteDescription}
                            disabled
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-4">
                            {localize('vehicleSerialNumber')}
                          </label>
                          <input
                            className="form-control"
                            value={vehicle?.paddedSerialNumber}
                            disabled
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <label className="text-capitalize col-2 pe-2">
                            {localize('documentReferenceNumber')}
                          </label>
                          <input
                            className="form-control"
                            value={vehicle?.documentReferenceNumber}
                            disabled
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <label className="text-capitalize col-2">
                            {localize('remarks')}
                          </label>
                          <input
                            className="form-control"
                            value={vehicle?.remarks}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <Tab
                          tabItems={[
                            {
                              label: localize('productsListing'),
                              item: (
                                <ProductsListing
                                  products={products}
                                  onSelect={handleSelectProduct}
                                  isBusyLoadingProducts={isBusyLoadingProducts}
                                />
                              ),
                            },
                            {
                              label: localize('customerPreview'),
                              item: (
                                <CustomerPreview
                                  customerId={vehicle?.customerId}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  label: localize('selectedProductDetails'),
                  item: (
                    <SelectedProductDetails
                      vehicle={selectedProduct}
                      customerAccount={customerAccount}
                      customer={customer}
                      customerAccountList={products}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Statements
