/** @format */

import React from 'react'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import PdfFileLookup from '../../../../../../Components/Lookup/PdfFileLookup'
import VehiclePersonnelLookup from '../../../../../../Components/Lookup/VehiclePersonnelLookup'
import localize from '../../../../../../Global/Localize'
import { MaskNumber } from './../../../../../../Components/InputMasks/MaskNumber'
import ModalTemplate from './../../../../../../Components/ModalTemplate/ModalTemplate'

const CreateDisciplinaryCase = ({
  mode,
  disciplinaryCase,
  setDisciplinaryCase,
  handleAction,
  handleClose,
  isBusy,
}) => {
  return (
    <ModalTemplate
      disableCreate={isBusy}
      modalMode={mode}
      handleAction={handleAction}
      actionText={mode === 'add' ? localize('create') : localize('update')}
      modalTitle={
        mode === 'add'
          ? localize('addDisciplinaryCase')
          : localize('editDisciplinaryCase')
      }
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      <div className="row d-flex">
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('personnel')}
            </label>
            <VehiclePersonnelLookup
              displayValue={disciplinaryCase?.vehiclePersonnelCustomerFullName}
              onSelect={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  id: e.id,
                  vehiclePersonnelId: e.id,
                  vehiclePersonnelSerialNumber: e.serialNumber,
                  vehiclePersonnelCustomerId: e.customerId,
                  vehiclePersonnelCustomerBranchId: e.customerBranchId,
                  vehiclePersonnelCustomerBranchCode: e.customerBranchCode,
                  vehiclePersonnelCustomerBranchDescription:
                    e.customerBranchDescription,
                  vehiclePersonnelCustomerBranchAccountNumberPadding:
                    e.customerBranchAccountNumberPadding,
                  vehiclePersonnelCustomerDutyStationId:
                    e.customerDutyStationId,
                  vehiclePersonnelCustomerDutyStationDescription:
                    e.customerDutyStationDescription,
                  vehiclePersonnelCustomerEmployerId: e.customerEmployerId,
                  vehiclePersonnelCustomerEmployerDescription:
                    e.customerEmployerDescription,
                  vehiclePersonnelCustomerEmployerLoanClassificationDate:
                    e.customerEmployerLoanClassificationDate,
                  vehiclePersonnelCustomerEmployerEnforceLoanClassificationDate:
                    e.customerEmployerEnforceLoanClassificationDate,
                  vehiclePersonnelCustomerAdministrativeDivisionId:
                    e.customerAdministrativeDivisionId,
                  vehiclePersonnelCustomerAdministrativeDivisionDescription:
                    e.customerAdministrativeDivisionDescription,
                  vehiclePersonnelCustomerMembershipClassId:
                    e.customerMembershipClassId,
                  vehiclePersonnelCustomerMembershipClassCode:
                    e.customerMembershipClassCode,
                  vehiclePersonnelCustomerMembershipClassDescription:
                    e.customerMembershipClassDescription,
                  vehiclePersonnelCustomerMembershipClassMemberNumberPrefix:
                    e.customerMembershipClassMemberNumberPrefix,
                  vehiclePersonnelCustomerMembershipClassCustomerType:
                    e.customerMembershipClassCustomerType,

                  vehiclePersonnelCustomerMembershipClassCustomerTypeDescription:
                    e.customerMembershipClassCustomerTypeDescription,
                  vehiclePersonnelCustomerMembershipClassMemberNumberPadding:
                    e.customerMembershipClassMemberNumberPadding,
                  vehiclePersonnelCustomerMembershipStatus:
                    e.customerMembershipStatus,
                  vehiclePersonnelCustomerMembershipStatusDescription:
                    e.customerMembershipStatusDescription,
                  vehiclePersonnelCustomerIndividualSalutation:
                    e.customerIndividualSalutation,
                  vehiclePersonnelCustomerIndividualSalutationDescription:
                    e.customerIndividualSalutationDescription,
                  vehiclePersonnelCustomerIndividualGender:
                    e.customerIndividualGender,
                  vehiclePersonnelCustomerIndividualGenderDescription:
                    e.customerIndividualGenderDescription,
                  vehiclePersonnelCustomerIndividualMaritalStatus:
                    e.customerIndividualMaritalStatus,
                  vehiclePersonnelCustomerIndividualMaritalStatusDescription:
                    e.customerIndividualMaritalStatusDescription,
                  vehiclePersonnelCustomerIndividualIdentityCardType:
                    e.customerIndividualIdentityCardType,
                  vehiclePersonnelCustomerIndividualIdentityCardTypeDescription:
                    e.customerIndividualIdentityCardTypeDescription,
                  vehiclePersonnelCustomerIndividualIdentityCardNumber:
                    e.customerIndividualIdentityCardNumber,
                  vehiclePersonnelCustomerIndividualNationality:
                    e.customerIndividualNationality,
                  vehiclePersonnelCustomerIndividualNationalityDescription:
                    e.customerIndividualNationalityDescription,
                  vehiclePersonnelCustomerIndividualEmploymentTermsOfService:
                    e.customerIndividualEmploymentTermsOfService,
                  vehiclePersonnelCustomerIndividualEmploymentTermsOfServiceDescription:
                    e.customerIndividualEmploymentTermsOfServiceDescription,
                  vehiclePersonnelCustomerSerialNumber: e.customerSerialNumber,
                  vehiclePersonnelPaddedCustomerSerialNumber:
                    e.paddedCustomerSerialNumber,
                  vehiclePersonnelCustomerMemberNumber: e.customerMemberNumber,
                  vehiclePersonnelPaddedCustomerMemberNumber:
                    e.paddedCustomerMemberNumber,
                  vehiclePersonnelFormattedCustomerMemberNumber:
                    e.formattedCustomerMemberNumber,
                  vehiclePersonnelCustomerAccountNumber:
                    e.customerAccountNumber,
                  vehiclePersonnelPaddedCustomerAccountNumber:
                    e.paddedCustomerAccountNumber,
                  vehiclePersonnelCustomerPersonalFileNumber:
                    e.customerPersonalFileNumber,
                  vehiclePersonnelCustomerIndividualFirstName:
                    e.customerIndividualFirstName,
                  vehiclePersonnelCustomerIndividualLastName:
                    e.customerIndividualLastName,
                  vehiclePersonnelCustomerIndividualType:
                    e.customerIndividualType,
                  vehiclePersonnelCustomerIndividualTypeDescription:
                    e.customerIndividualTypeDescription,
                  vehiclePersonnelCustomerNonIndividualDescription:
                    e.customerNonIndividualDescription,
                  vehiclePersonnelCustomerNonIndividualRegistrationNumber:
                    e.customerNonIndividualRegistrationNumber,
                  vehiclePersonnelCustomerPersonalIdentificationNumber:
                    e.customerPersonalIdentificationNumber,
                  vehiclePersonnelCustomerNonIndividualDateEstablished:
                    e.customerNonIndividualDateEstablished,
                  vehiclePersonnelCustomerIdentificationNumber:
                    e.customerIdentificationNumber,
                  vehiclePersonnelCustomerAddressAddressLine1:
                    e.customerAddressAddressLine1,
                  vehiclePersonnelCustomerAddressAddressLine2:
                    e.customerAddressAddressLine2,
                  vehiclePersonnelCustomerAddressStreet:
                    e.customerAddressStreet,
                  vehiclePersonnelCustomerAddressPostalCode:
                    e.customerAddressPostalCode,
                  vehiclePersonnelCustomerAddressCity: e.customerAddressCity,
                  vehiclePersonnelCustomerAddressEmail: e.customerAddressEmail,
                  vehiclePersonnelCustomerAddressLandLine:
                    e.customerAddressLandLine,
                  vehiclePersonnelCustomerAddressMobileLine:
                    e.customerAddressMobileLine,
                  vehiclePersonnelCustomerReference1: e.customerReference1,
                  vehiclePersonnelCustomerReference2: e.customerReference2,
                  vehiclePersonnelCustomerReference3: e.customerReference3,
                  vehiclePersonnelCustomerRecordStatus: e.customerRecordStatus,
                  vehiclePersonnelCustomerRecordStatusDescription:
                    e.customerRecordStatusDescription,
                  vehiclePersonnelVehicleId: e.vehicleId,
                  vehiclePersonnelVehicleCustomerId: e.vehicleCustomerId,
                  vehiclePersonnelVehicleVehicleRouteId:
                    e.vehicleVehicleRouteId,
                  vehiclePersonnelVehicleVehicleRouteDescription:
                    e.vehicleVehicleRouteDescription,
                  vehiclePersonnelVehicleSerialNumber: e.vehicleSerialNumber,
                  vehiclePersonnelVehiclePaddedSerialNumber:
                    e.vehiclePaddedSerialNumber,
                  vehiclePersonnelVehicleRegistrationNumber:
                    e.vehicleRegistrationNumber,
                  vehiclePersonnelVehicleFleetNumber: e.vehicleFleetNumber,
                  vehiclePersonnelVehicleDocumentReferenceNumber:
                    e.vehicleDocumentReferenceNumber,
                  vehiclePersonnelVehicleRemarks: e.vehicleRemarks,
                  vehiclePersonnelVehicleIsLocked: e.vehicleIsLocked,
                  vehiclePersonnelType: e.type,
                  vehiclePersonnelTypeDescription:
                    e.customerIndividualTypeDescription,
                  vehiclePersonnelBadgeImageId: e.badgeImageId,
                  vehiclePersonnelBadgeNumber: e.badgeNumber,
                  vehiclePersonnelBadgeExpiryDate: e.badgeExpiryDate,
                  vehiclePersonnelCustomerFullName: e.customerFullName,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('document')}
            </label>
            <PdfFileLookup
              displayValue={disciplinaryCase?.fileName}
              acceptedFiles={['application/pdf']}
              onSelect={r => {
                console.log('this is the pdf file', r)
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  document: r.bufferString,
                  fileName: r.fileName,
                  fileExtension: 'pdf',
                  fileMIMEType: r.mimeType,
                  fileBuffer: r.bufferString,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">{localize('title')}</label>
            <input className="form-control" />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('fileDescription')}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Text..."
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  fileDescription: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('incidentDate')}
            </label>
            <CustomDatePicker
              name="disciplinaryCasesIncidentDate"
              defaultDate={disciplinaryCase?.incidentDate}
              onDateChange={d => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  incidentDate: d,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">{localize('court')}</label>
            <input
              className="form-control"
              type="text"
              placeholder="Enter Text..."
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  court: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('courtDate')}
            </label>
            <CustomDatePicker
              name={'disciplinaryCasesCourtDate'}
              defaultDate={disciplinaryCase?.courtDate}
              onDateChange={d => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  courtDate: d,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('cashBail')}
            </label>
            <MaskNumber
              defaultMaskValue={disciplinaryCase?.cashBail}
              onMaskNumber={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  cashBail: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex col-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('sentForRefunds')}
            </label>
            <MaskNumber
              defaultMaskValue={disciplinaryCase?.sentForRefunds}
              onMaskNumber={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  sentForRefunds: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex col-4">
            <label className="text-capitalize col-3">
              {localize('refunded')}
            </label>
            <MaskNumber
              defaultMaskValue={disciplinaryCase?.refunded}
              onMaskNumber={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  refunded: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex">
            <label className="text-capitalize col-1">
              {localize('description')}
            </label>
            <textarea
              className="form-control"
              rows="2"
              placeholder="Enter Text..."
              value={disciplinaryCase?.description}
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex">
            <label className="text-capitalize col-1">
              {localize('remarks')}
            </label>
            <textarea
              className="form-control"
              rows="2"
              placeholder="Enter Text..."
              value={disciplinaryCase?.remarks}
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex">
            <label className="text-capitalize col-1">
              {localize('orders')}
            </label>
            <textarea
              className="form-control"
              rows="2"
              placeholder="Enter Text..."
              value={disciplinaryCase?.orders}
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  orders: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center mb-2">
          <div className="d-flex">
            <label className="text-capitalize col-1">
              {localize('finalOrders')}
            </label>
            <textarea
              className="form-control"
              rows="2"
              placeholder="Enter Text..."
              value={disciplinaryCase?.finalOrders}
              onChange={e => {
                setDisciplinaryCase({
                  ...disciplinaryCase,
                  finalOrders: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default CreateDisciplinaryCase
