/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function DebitTypeLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: localize('name'),
      template: r => r.description,
    },
    {
      label: localize('productCode'),
      template: r => <>{r.customerAccountTypeProductCodeDescription}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  return (
    <>
      <SimpleLookup
        title={localize('debitTypeLookup')}
        url="/lookup/DebitType/find-debit-type-by-filter-in-page"
        params={{
          filterText: '',
          pageSize: 10,
          pageIndex: 0,
          updateDepth: true,
          traverseTree: true,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}
export default DebitTypeLookup
