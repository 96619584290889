/** @format */

import React, { useEffect, useState } from 'react'
import ActionModal from '../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import {
  RecordStatus,
  alternateChannelType,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import CustomizeAlternateChannel from './CustomizeAlternateChannel/CustomizeAlternateChannel'
import VerifyChannelRegister from './VerifyChannelRegister/VerifyChannelRegister'

const ChannelsRegister = () => {
  const [channels, setChannels] = useState([])
  const [channel, setChannel] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyUnBlocking, setIsBusyUnBlocking] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'Record Status',
    value: 0,
    column: 'recordStatusDescription',
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    type: Object.keys(alternateChannelType)[6],
    recordStatus: Object.keys(RecordStatus)[4],
    alternateChannelFilter: 0,
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('channelType'),
      template: item => <>{item?.typeDescription}</>,
    },
    {
      label: localize('recordStatus'),
      template: item => <>{item?.recordStatusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: item => (
        <>{item?.formattedCustomerAccountCustomerMemberNumber}</>
      ),
    },
    {
      label: localize('accountNumber'),
      template: item => (
        <>{item?.formattedCustomerAccountCustomerAccountNumber}</>
      ),
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('primaryAccountNumber'),
      template: item => <>{item?.maskedCardNumber}</>,
    },
    {
      label: localize('validFrom'),
      template: item => <>{formatDate(item?.validFrom, true)}</>,
    },
    {
      label: localize('expires'),
      template: item => <>{formatDate(item?.expires, true)}</>,
    },
    {
      label: localize('dailyLimit'),
      template: item => <>{formatCurrency(item?.dailyLimit)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: item => <>{item?.productDescription}</>,
    },
    {
      label: localize('remarks'),
      template: item => <>{item?.remarks}</>,
    },
    {
      label: localize('isThirdPartyNotified') + ' ?',
      template: item => <>{item?.isThirdPartyNotified?.toString()}</>,
    },
    {
      label: localize('thirdPartyResponse'),
      template: item => <>{item?.thirdPartyResponse}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: item => <>{item?.isLocked?.toString()}</>,
    },
    {
      label: localize('recruitedBy'),
      template: item => <>{item?.recruitedBy}</>,
    },
    {
      label: localize('modifiedBy'),
      template: item => <>{item?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: item => <>{formatDate(item?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'recordStatusDescription' },
    { value: 1, column: 'recruitedBy' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'modifiedBy' },
    { value: 4, column: 'customerAccountBranchDescription' },
  ]

  const fetchAlternateChannels = async () => {
    const url =
      reqParams.recordStatus === Object.keys(RecordStatus)[4]
        ? '/AlternateChannel/find-alternate-channel-by-type-and-filter-in-page'
        : '/AlternateChannel/find-alternate-channel-by-record-status-and-filter-in-page'

    setIsBusy(true)

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setChannels(data)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setChannel({})
  }

  const handleSelectChannel = (data, mode) => {
    if (
      mode === 'verify' &&
      data?.recordStatus !== Object.keys(RecordStatus)[0] &&
      data?.recordStatus !== Object.keys(RecordStatus)[1]
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only verify an edited or new alternate Channel.'
      )

    setChannel(data)
    setMode(mode)
  }

  const handleUnblockChannel = async () => {
    setIsBusyUnBlocking(true)

    const url = '/BankToMobileRequest/unblock-alternate-channel'

    const { success } = await postData(url, channel, false)

    if (success) {
      showNotification('Unblock Alternate Channel', 'success')
      handleClose()
      fetchAlternateChannels()
    }

    setIsBusyUnBlocking(false)
  }

  const handleResetPin = async () => {
    setIsBusyUnBlocking(true)

    const url = '/BankToMobileRequest/reset-alternate-channel-pin'

    const { success } = await postData(url, channel, false)

    if (success) {
      showNotification('Reset Alternate Channel Pin', 'success')
      handleClose()
      fetchAlternateChannels()
    }

    setIsBusyUnBlocking(false)
  }

  useEffect(() => {
    fetchAlternateChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={channel}
          title={`${channel?.typeDescription} : ${channel?.maskedCardNumber} -> ${channel?.customerFullName}`}
        />
      )}

      {mode === 'unBlock' && (
        <ActionModal
          modalTitle={localize('alternateChannelsRegister')}
          handleClose={handleClose}
          cancelText="no"
          confirmText="yes"
          handleAction={handleUnblockChannel}
          disableAction={isBusyUnBlocking}
          disableClose={isBusyUnBlocking}
        >
          {isBusyUnBlocking ? (
            <Loader />
          ) : (
            <p>Do you want to unblock {channel?.cardNumber} ?</p>
          )}
        </ActionModal>
      )}

      {mode === 'resetPin' && (
        <ActionModal
          modalTitle={localize('alternateChannelsRegister')}
          handleClose={handleClose}
          cancelText="no"
          confirmText="yes"
          handleAction={handleResetPin}
          disableAction={isBusyUnBlocking}
          disableClose={isBusyUnBlocking}
        >
          {isBusyUnBlocking ? (
            <Loader />
          ) : (
            <p>Do you want to reset pin for {channel?.cardNumber} ?</p>
          )}
        </ActionModal>
      )}

      {mode === 'verify' && (
        <VerifyChannelRegister
          channel={channel}
          fetchChannels={fetchAlternateChannels}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      {mode === 'customize' && (
        <CustomizeAlternateChannel
          channel={channel}
          handleClose={handleClose}
          fetchAlternateChannels={fetchAlternateChannels}
          mode={mode}
          setChannel={setChannel}
        />
      )}

      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('alternateChannelsRegister'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <div className="d-flex align-items-center col-md-3">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>

              <div className="d-flex row align-items-center justify-content-between mb-3">
                <div className="d-flex col-md-2 align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('type')}
                  </label>

                  <EnumsServerCombo
                    onChange={({ value }) => {
                      setReqParams({
                        ...reqParams,
                        type: value,
                        pageIndex: 0,
                      })
                    }}
                    enumsKey="AlternateChannelType"
                    customContainerStyle={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    sort
                    value={reqParams?.type}
                  />
                </div>
                <div
                  className="d-flex align-items-center gap-2 col-md-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={groupBy?.value}
                    enumsKey="AlternateChannelPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div
                  className="d-flex align-items-center gap-2 col-md-3"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('recordStatus')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={reqParams?.recordStatus}
                    enumsKey="RecordStatusFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        recordStatus: value,
                        pageIndex: 0,
                      })
                    }
                  />
                </div>

                <div
                  className="d-flex align-items-center gap-2 col-md-5"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={reqParams?.alternateChannelFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        alternateChannelFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchAlternateChannels()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchAlternateChannels()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={channels?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChannel(r, 'customize')
                      }}
                    >
                      <i className="uil uil-adjust"></i>
                      {localize('customize')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChannel(r, 'verify')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      {localize('verify')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChannel(r, 'resetPin')
                      }}
                    >
                      <i className="uil uil-redo"></i>
                      {localize('resetPin')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChannel(r, 'unBlock')
                      }}
                    >
                      <i className="uil  uil-lock-open-alt"></i>
                      {localize('unBlock')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChannel(r, 'auditLogs')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      {localize('viewAuditLogs')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={channels?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ChannelsRegister
