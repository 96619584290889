/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import localize from '../../../../../../Global/Localize'
import {
  QueuePriority,
  wireTransferBatchType,
} from '../../../../../../Global/enumeration'
import WireTransferTypeLookup from '../../../../../../Components/Lookup/WireTransferTypeLookup'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { postData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function AddWireTransferBatch({ closeModal, onCreateBatch }) {
  const initialBatch = {
    type: 0,
    priority: 3,
    totalValue: 0,
    wireTransferTypeId: null,
    wireTransferTypeDescription: '',
    reference: '',
    autowire: false,
  }
  const [currentBatch, setCurrentBatch] = useState(initialBatch)
  const [minimized, setMinimized] = useState(false)

  async function createBatch() {
    const { success } = await postData(
      '/wireTransferBatch/create-wire-transfer-batch',
      currentBatch
    )
    if (success) {
      Swal.fire('Success', 'Batch Created!', 'success')
      onCreateBatch()
    }
  }

  async function toggleMinimized() {
    setMinimized(!minimized)
  }

  return minimized ? (
    <div className="modal-minimized" onClick={toggleMinimized}>
      <i className="uil uil-clapper-board fs-2"></i>
    </div>
  ) : (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>{localize('add')}</h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('wireTransfer')}
          </span>
          <button type="button" className="minimize" onClick={toggleMinimized}>
            <span aria-hidden="true">_</span>
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('batchType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="WireTransferBatchType"
              value={currentBatch?.type}
              onChange={e =>
                setCurrentBatch({ ...currentBatch, type: e.value })
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('totalValue')}
            </label>
            <MaskNumber
              defaultMaskValue={currentBatch?.totalValue}
              onMaskNumber={e =>
                setCurrentBatch({
                  ...currentBatch,
                  totalValue: stripNonNumericCharacters(e.target.value),
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('wireTransferType')}
            </label>
            <WireTransferTypeLookup
              displayValue={currentBatch?.wireTransferTypeDescription}
              onSelect={w =>
                setCurrentBatch({
                  ...currentBatch,
                  wireTransferTypeDescription: w.description,
                  wireTransferTypeId: w.id,
                })
              }
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('priority')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="QueuePriority"
              value={currentBatch?.priority}
              onChange={e =>
                setCurrentBatch({ ...currentBatch, priority: e.value })
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('reference')}
            </label>
            <input
              value={currentBatch?.reference}
              onChange={e =>
                setCurrentBatch({ ...currentBatch, reference: e.target.value })
              }
              type="text"
              className="form-control"
            />
          </div>
          <div className="col"></div>
        </div>
        <div
          className="mt-4 d-flex align-items-center justify-content-end p-2 bg-light"
          style={{ borderRadius: 10 }}
        >
          <div className="d-flex align-items-center">
            <Checkbox
              checked={currentBatch?.autowire}
              onCheck={v => setCurrentBatch({ ...currentBatch, autowire: v })}
              id={`autowire-add-batch`}
            />
            <label htmlFor={`autowire-add-batch`} className="text-capitalize">
              {localize('autowire')}?
            </label>
          </div>
          <button className="btn btn-success px-5 ms-5" onClick={createBatch}>
            {localize('create')}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AddWireTransferBatch
