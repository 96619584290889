/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import { CustomerReceiptContainer } from './Partials/MainSection/CustomerReceiptContainer'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import {
  ApportionedComponent,
  ApportionTo,
} from '../../../../../Helpers/constants'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import { downloadReceipt } from '../../../../../Helpers/blobs'
import Swal from 'sweetalert2'

const CustomerReceipts = () => {
  const [transactionModel, setTransactionModel] = useState({
    apportionTo: ApportionTo.CUSTOMER_ACCOUNT,
    apportionedComponent: ApportionedComponent.AGGREGATE,
  })

  const [apportionMents, setApportionMents] = useState([])

  const [globalValidation, setGlobalValidation] = GlobalValidationErrors.use()
  const [loading, setLoading] = useState(false)
  const [resetEntries, setResetEntries] = useState(0)

  const handleOnUpdate = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/process-customer-receipts',
      {
        apportionmentWrappers: apportionMents || [],
        totalValue: transactionModel?.amount || 0,
        selectedCustomer: transactionModel?.selectedCustomer || {},
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        await Swal.fire({
          icon: 'success',
          title: 'Operation Successful',
          text: 'Customer Receipts',
        }).then(result => {
          setResetEntries(Math.floor(Date.now() / 1000))
          if (result.isConfirmed) {
            downloadReceipt(data.message, 'receipt.txt')
          }
        })
      }
    } else {
      if (data?.type === 0) {
        const messages = Object.values(data?.reason).map(entry => entry.message)
        const htmlContent = `<ul>${messages}</ul>`
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          html: htmlContent,
        })
      }
    }
    setLoading(false)
  }

  const handleOnSelectCustomer = r => {
    setTransactionModel({
      ...transactionModel,
      selectedCustomer: { ...r, customerId: r.id },
    })
    clearValidation('SelectedCustomerAccount.Id')
  }

  const clearValidation = column => {
    setGlobalValidation(g => {
      delete g[column]
      return g
    })
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('tellers'),
          localize('customerReceipts'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-3">
                <CustomerPartial
                  message={
                    globalValidation?.['SelectedCustomerAccount.Id']?.message
                  }
                  customer={transactionModel?.selectedCustomer}
                  onCustomerLookup={handleOnSelectCustomer}
                />
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <CustomerReceiptContainer
                    resetEntries={resetEntries}
                    onRemoveApportionment={r => {
                      setApportionMents(
                        apportionMents.filter(x => x.id !== r.id)
                      )
                    }}
                    onAddApportionMent={e => {
                      setApportionMents([...apportionMents, e])
                    }}
                    transactionModel={transactionModel}
                    setTransactionModel={setTransactionModel}
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="d-flex align-item-center justify-content-end rounded py-3 px-2 bg-light mt-2 mb-2">
                      <button
                        className="btn btn-success"
                        onClick={handleOnUpdate}
                      >
                        {localize('update')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerReceipts
