/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  gender,
  identityCardType,
  nationality,
  partnershipRelationship,
  salutation,
} from '../../../../../../Global/enumeration'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { getRandomNumber } from '../../../../../../Helpers/misc'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function MemberDetailsPartial({ members, onChange, readOnly }) {
  const [memberCollection, setMemberCollection] = useState([])
  const [cMember, setCMember] = useState(null)

  useEffect(() => {
    if (!readOnly) onChange(memberCollection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCollection])

  function addMember() {
    if (cMember?.firstName && cMember.lastName && cMember.identityCardNumber) {
      let mc = [...memberCollection]
      mc.push({
        ...cMember,
        fullName: `${cMember.firstName} ${cMember.lastName}`,
        id: getRandomNumber(memberCollection.map(m => m.id)),
      })
      setMemberCollection(mc)
      setCMember(null)
    } else {
      Swal.fire(
        'Error',
        'FirstName, Other Names and Identity Card # are required',
        'error'
      )
    }
  }

  function removeMember(m) {
    let mc = [...memberCollection]
    let i = mc.findIndex(mem => mem.id === m.id)
    mc.splice(i, 1)
    setMemberCollection(mc)
  }
  return (
    <div
      className="p-2 mt-4"
      style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
    >
      <div
        className="bg-white row px-2 text-capitalize"
        style={{
          position: 'relative',
          top: -20,
          left: 30,
          maxWidth: 'fit-content',
        }}
      >
        {localize('memberDetails')}
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="PartnershipRelationship"
            value={
              cMember?.relationship || Object.keys(partnershipRelationship)[0]
            }
            onChange={e =>
              setCMember({
                ...cMember,
                relationship: e.value,
                relationshipDescription: partnershipRelationship[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('nationality')}
          </label>
          <select
            className="form-select"
            value={cMember?.nationality || nationality[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                nationality: e.target.value,
                nationalityDescription: nationality[e.target.value],
              })
            }
          >
            {nationality.map((nat, i) => (
              <option key={`nationlity_${i}`} value={i}>
                {nat}
              </option>
            ))}
          </select>
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('gender')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={cMember?.gender || Object.keys(gender)[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                gender: e.value,
                genderDescription: gender[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={cMember?.salutation || Object.keys(salutation)[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                salutation: e.value,
                salutationDescription: salutation[e.value].name,
              })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.firstName || ''}
            onChange={e =>
              setCMember({ ...cMember, firstName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.lastName || ''}
            onChange={e => setCMember({ ...cMember, lastName: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('family')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.family || ''}
            onChange={e => setCMember({ ...cMember, family: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('clan')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.clan || ''}
            onChange={e => setCMember({ ...cMember, clan: e.target.value })}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('ethnicGroup')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.ethnicGroup || ''}
            onChange={e =>
              setCMember({ ...cMember, ethnicGroup: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={
              cMember?.identityCardType || Object.keys(identityCardType)[0]
            }
            onChange={e =>
              setCMember({
                ...cMember,
                identityCardType: e.value,
                identityCardTypeDescription: identityCardType[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCard')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.identityCardNumber || ''}
            onChange={e =>
              setCMember({ ...cMember, identityCardNumber: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.identityCardSerialNumber || ''}
            onChange={e =>
              setCMember({
                ...cMember,
                identityCardSerialNumber: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('addressLine')} 1
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.addressAddressLine1 || ''}
            onChange={e =>
              setCMember({ ...cMember, addressAddressLine1: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.addressAddressLine2 || ''}
            onChange={e =>
              setCMember({ ...cMember, addressAddressLine2: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.addressMobileLine || ''}
            onChange={e =>
              setCMember({ ...cMember, addressMobileLine: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('email')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.addressEmail || ''}
            onChange={e =>
              setCMember({ ...cMember, addressEmail: e.target.value })
            }
          />
        </div>
      </div>
      <div className="my-2 p-2 bg-light d-flex align-items-center justify-content-end">
        <Checkbox
          id="membershipDetailsPartialSignatory"
          checked={cMember?.signatory}
          onCheck={v => setCMember({ ...cMember, signatory: v })}
        />
        <label
          htmlFor="membershipDetailsPartialSignatory"
          className="text-capitalize me-4 ms-2"
        >
          {localize('signatory')}
        </label>
        <button onClick={addMember} className="btn btn-success px-5">
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-3"
        style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
      >
        <div
          className="bg-white row px-2 text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('memberCollection')}
        </div>
        <div className="mx-2">
          <SimpleTable
            data={members}
            columns={[
              { label: 'name', template: r => <>{r?.fullName}</> },
              { label: 'family', template: r => <>{r?.family}</> },
              { label: 'ethnicGroup', template: r => <>{r?.ethnicGroup}</> },
              { label: 'gender', template: r => <>{r?.genderDescription}</> },
              {
                label: 'relationship',
                template: r => <>{r?.relationshipDescription}</>,
              },
              {
                label: 'nationality',
                template: r => <>{r?.nationalityDescription}</>,
              },
              {
                label: 'identityCardType',
                template: r => <>{r?.identityCardTypeDescription}</>,
              },
              {
                label: localize('identityCard') + ' #',
                template: r => <>{r?.identityCardNumber}</>,
              },
              {
                label: localize('identityCardSerial') + ' #',
                template: r => <>{r?.identityCardSerialNumber}</>,
              },
              {
                label: 'addressLine',
                template: r => <>{r?.addressAddressLine1}</>,
              },
              {
                label: 'addressLine',
                template: r => <>{r?.addressAddressLine2}</>,
              },
              {
                label: 'physicalAddress',
                template: r => <>{r?.addressStreet}</>,
              },
              {
                label: 'postalCode',
                template: r => <>{r?.addressPostalCode}</>,
              },
              { label: 'city', template: r => <>{r?.addressCity}</> },
              { label: 'emailAddress', template: r => <>{r?.addressEmail}</> },
              {
                label: 'mobileLine',
                template: r => <>{r?.addressMobileLine}</>,
              },
              {
                label: localize('signatory') + '?',
                template: r => <>{r?.signatory}</>,
              },
              { label: 'remarks', template: r => <>{r?.remarks}</> },
            ]}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => {
                    removeMember(r)
                  }}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default MemberDetailsPartial
