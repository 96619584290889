/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../../Global/Localize'
function LendorSector({ selectedCreditInformationQuery }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('bank(AccountNPA)')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.sectorBankAccountNPA || ''}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('bank(AccountPerforming)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorBankAccountPerforming || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('bank(AccountNPAHistory)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorBankAccountNPAHistory || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfb(AccountNPA)')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.sectorMFBAccountNPA || ''}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfb(AccountPerforming)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorMFBAccountPerforming || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfb(AccountNPAHistory)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorMFBAccountNPAHistory || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfi(AccountNPA)')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.sectorMFIAccountNPA || ''}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfi(AccountPerforming)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorMFIAccountPerforming || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mfi(AccountNPAHistory)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorMFIAccountNPAHistory || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('sacco(AcountNPA)')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.sectorSACCOAccountNPA || ''}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('sacco(AcountPerforming)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorSACCOAccountPerforming || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('sacco(AcountNPAHistory)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorSACCOAccountNPAHistory || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('other(AccountNPA)')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.sectorOTHERAccountNPA || ''}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('other(AccountPerforming)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorOTHERAccountPerforming || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('other(AccountNPAHistory)')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.sectorOTHERAccountNPAHistory || ''
            }
            disabled
          />
        </div>
      </div>
    </>
  )
}
export default LendorSector
