/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'

function InvestmentProducts({ disabled, selected, onSelect, prefix }) {
  const [investmentProducts, setInvestmentProducts] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedInvProducts, setSelectedInvProducts] = useState(selected)

  async function loadInvestmentProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/investmentProduct/find-investment-products',
      {},
      false
    )
    if (success) {
      setInvestmentProducts(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadInvestmentProducts()
  }, [])

  useEffect(() => {
    if (!disabled) onSelect(selectedInvProducts)
  }, [selectedInvProducts])

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        investmentProducts?.map(i => (
          <div key={i.id} className="d-flex align-items-center">
            <Checkbox
              checked={selectedInvProducts?.find(item => item.id === i.id)}
              disabled={disabled}
              margined
              onCheck={c => {
                if (c) {
                  let sip = [...selectedInvProducts, i]
                  setSelectedInvProducts(sip)
                } else {
                  let sip = [...selectedInvProducts]?.filter(
                    ip => ip?.id !== i?.id
                  )
                  setSelectedInvProducts(sip)
                }
              }}
              id={`${prefix}_investmentProduct_${i.id}`}
            />
            <label
              htmlFor={`${prefix}_investmentProduct_${i.id}`}
              className={`fw-normal ms-2 cursor-pointer ${
                selectedInvProducts?.find(ip => i?.id === ip?.id)
                  ? 'text-primary fw-bold'
                  : ''
              }`}
            >
              {i?.description}
            </label>
          </div>
        ))
      )}
    </div>
  )
}

export default InvestmentProducts
