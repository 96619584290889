/** @format */

import React, { useState, useEffect } from 'react'
import LoanCaseParital from './LoanCaseParital'
import StandingOrders from '../PreQualification/StandingOrders'
import CustomerLoanApplications from '../PreQualification/CustomerLoanApplications'
import localize from '../../../../../Global/Localize'
import CustomerAccountsList from '../PreQualification/CustomerAccountsList'
import BankAccounts from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/BankAccounts'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import CustomerCreditScores from '../PreQualification/CustomerCreditScores'
import Tab from '../../../../../Components/Tabs/Tab'
import LoansGuaranteed from '../../../../Registry/Members/Partials/Tabs/LoansGuaranteed'

function LoanRequest({
  approval,
  loanCase,
  onSelect,
  onChangeLoanTerm,
  onChangeReceivedDate,
  onChangeAmountApplied,
  setLoanCase,
}) {
  const [customerWrapper, setCustomerWrapper] = useState({ id: '' })
  const [amountApplied, setAmountApplied] = useState()
  const [receivedDate, setReceivedDate] = useState()
  const [termInMonths, setTermInMonths] = useState()

  useEffect(() => {
    onSelect && onSelect(loanCase)
    setCustomerWrapper({ id: loanCase?.customerId })
    // eslint-disable-next-line
  }, [loanCase])

  useEffect(() => {
    onChangeLoanTerm && onChangeLoanTerm(termInMonths)
    // eslint-disable-next-line
  }, [termInMonths])
  useEffect(() => {
    onChangeReceivedDate && onChangeReceivedDate(receivedDate)
    // eslint-disable-next-line
  }, [receivedDate])
  useEffect(() => {
    onChangeAmountApplied && onChangeAmountApplied(amountApplied)
    // eslint-disable-next-line
  }, [amountApplied])

  const tabItems = [
    {
      label: localize('creditScores'),
      item: <CustomerCreditScores customer={customerWrapper} />,
    },
    {
      label: localize('customerAccounts'),
      item: (
        <>
          <CustomerAccountsList customer={customerWrapper} />
        </>
      ),
    },
    {
      label: localize('standingOrders'),
      item: (
        <>
          <StandingOrders customer={customerWrapper} />
        </>
      ),
    },
    {
      label: localize('loanApplications'),
      item: <CustomerLoanApplications customer={customerWrapper} />,
    },
    {
      label: localize('loansGuaranteed'),
      item: <LoansGuaranteed customer={customerWrapper} />,
    },
    {
      label: localize('bankAccounts'),
      item: (
        <>
          <BankAccounts readOnly customer={customerWrapper} />
        </>
      ),
    },
    {
      label: localize('specimen'),
      icon: 'mdi mdi-camera',
      item: (
        <>
          <Specimen readOnly customer={customerWrapper} />
        </>
      ),
    },
  ]

  return (
    <div>
      <LoanCaseParital
        approval={approval}
        loanCase={loanCase}
        setLoanCase={setLoanCase}
        readOnly
        onSelect={onSelect}
        onUpdate={u => {
          setAmountApplied(u?.amountApplied)
          setReceivedDate(u?.receivedDate)
          setTermInMonths(u?.termInMonths)
        }}
      />
      {loanCase && (
        <>
          <h4 className="mb-3 mt-4 text-capitalize">
            {localize('particulars')}
          </h4>
          <Tab tabItems={tabItems} />
        </>
      )}
    </div>
  )
}

export default LoanRequest
