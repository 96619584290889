/** @format */

import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataActiveFixedDeposit = ({
  fixedDeposits,
  selectedCustomerAccount,
  loading,
}) => {
  const columns = [
    {
      label: localize('typeDescription'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    { label: localize('value'), template: r => <>{r.value}</> },
    { label: localize('term'), template: r => <>{r.term}</> },
    { label: localize('annualPercentageRate'), template: r => <>{r.tate}</> },
    {
      label: localize('maturityDate'),
      template: r => <>{formatDate(r.maturityDate)}</>,
    },
    {
      label: localize('expectedInterest'),
      template: r => <>{r.expectedInterest}</>,
    },
    { label: localize('totalExpected'), template: r => <>{r.totalExpected}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={fixedDeposits} />
      )}
    </>
  )
}
