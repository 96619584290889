/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { roomType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import CreateMorgueRoom from './CreateMorgueRoom/CreateMorgueRoom'
import Pager from '../../../../Components/Tables/Pager'
import Loader from '../../../../Components/Loaders/Loader'

function MorgueRooms() {
  const [morgueRoom, setMorgueRoom] = useState({})
  const [morgueRooms, setMorgueRooms] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    roomType: roomType.ORDINARY,
  })

  const columns = [
    {
      label: 'name',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'g/LAccountName',
      template: r => <>{r?.chartOfAccountAccountName}</>,
    },
    {
      label: 'gLAccountCostDistributionRule',
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: 'funeralHome',
      template: r => <>{r?.funeralHomeDescription}</>,
    },
    {
      label: 'type',
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchMorgueRooms = async () => {
    setIsBusy(true)
    const url = '/Room/find-rooms-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setMorgueRooms(data)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setModalMode(null)
    setMorgueRoom({
      type: roomType.ORDINARY,
    })
  }

  const handleClickAddButton = () => {
    setModalMode('add')
    setMorgueRoom({
      type: roomType.ORDINARY,
    })
  }

  useEffect(() => {
    fetchMorgueRooms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      <BreadCrumb
        tree={[localize('morgueManagement'), localize('morgueRooms')]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3 d-flex align-items-center justify-content-between">
                <div className="col-md-4">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>

                <div className="col-md-4">
                  <div className="d-flex align-items-center gap-2">
                    <label className="text-capitalize">
                      {localize('roomType')}
                    </label>
                    <EnumsServerCombo
                      enumsKey="RoomType"
                      value={reqParams.roomType}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          roomType: e.value,
                        })
                      }}
                    />
                  </div>
                </div>

                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                      pageIndex: 0,
                    })
                  }}
                  className="col-md-3 d-flex align-items-center gap-2"
                >
                  <label className="text-capitalize">
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    value={reqParams.filterText}
                    className="form-control"
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                  />

                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => fetchMorgueRooms()}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>

              <SimpleTable
                data={morgueRooms?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setMorgueRoom(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={morgueRooms?.itemsCount}
                pageIndex={reqParams.pageIndex}
                pageSize={reqParams.pageSize}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {modalMode && (
        <CreateMorgueRoom
          handleClose={handleClose}
          mode={modalMode}
          morgueRoom={morgueRoom}
          setMorgueRoom={setMorgueRoom}
          fetchMorgueRooms={fetchMorgueRooms}
        />
      )}
    </>
  )
}

export default MorgueRooms
