/** @format */
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatAsPercent, formatCurrency } from '../../../../../Helpers/formatters'

export const FixedDepositTypeTiersEntries = ({
  selectedFixedDepositType,
  setSelectedFixedDepositType,
}) => {
  const columns = [
    {
      label: localize('rangeLowerLimit'),
      template: r => <>{formatCurrency(r.rangeLowerLimit)}</>,
    },
    {
      label: localize('rangeUpperLimit'),
      template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.percentage)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        columns={columns}
        data={selectedFixedDepositType?.fixedDepositTypeGraduatedScales}
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                setSelectedFixedDepositType({
                  ...selectedFixedDepositType,
                  fixedDepositTypeGraduatedScales:
                    selectedFixedDepositType.fixedDepositTypeGraduatedScales.filter(
                      r => r.id !== e.id,
                    ),
                })
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash"></i>
              {localize('remove')}
            </div>
          </>
        )}
      />
    </>
  )
}
