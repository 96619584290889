/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AdHocLoanInterestCapitalization from '../../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import { ViewCustomerLoanAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { RecordStatus } from '../../../../../Global/enumeration'
import { getData } from '../../../../../Helpers/webApi'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import Loader from '../../../../../Components/Loaders/Loader'

const LoanInterestChargingAdHoc = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [customer, setCustomer] = useState({})
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})

  const getCustomerAccounts = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: customer?.id,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setCustomerLoanAccounts(
        data.customerLoanAccounts.filter(
          r => r.bookBalance < 0 || r.bookBalance > 0
        )
      )
    }
  }

  const onCustomerLookup = r => {
    if (r?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Selected customer is locked',
      })

    if (r?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only charge interest on approved customers',
      })

    setCustomer(r)
  }

  useEffect(() => {
    if (customer?.id) {
      getCustomerAccounts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('adHocLoanInterestCharging'),
        ]}
      />

      {showAdHocModal && (
        <AdHocLoanInterestCapitalization
          handleClose={() => {
            setShowAdHocModal(false)
            setLoanAccount({})
          }}
          loanAccount={loanAccount}
          modalMode="execute"
        />
      )}

      <div className="card bg-white rounded">
        <div className="card-body">
          <CustomerPartial
            customer={customer}
            onCustomerLookup={onCustomerLookup}
          />

          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('loanAccounts'),
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <ViewCustomerLoanAccounts
                      customerLoanAccounts={customerLoanAccounts}
                      handleAdHocLoan={r => {
                        setLoanAccount(r)
                        setShowAdHocModal(true)
                      }}
                      hiddenColumnsIndexes={[0]}
                    />
                  ),
                },
                {
                  label: localize('specimen'),
                  item: <Specimen customer={customer} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default LoanInterestChargingAdHoc
