/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import localize from '../../../../../Global/Localize'
import CustomerAccountDebitCreditPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerAccountDebitCreditPartial'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { postData } from '../../../../../Helpers/webApi'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import Swal from 'sweetalert2'
import CustomerAccountRefundProcessingDebitCreditPartial from '../partials/CustomerAccountRefundProcessingDebitCreditPartial'
import RefundTypeLookup from '../../../../../Components/Lookup/RefundTypeLookup'
import { refundNotificationRegistrationOption } from '../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function AddRefundProcessing({
  closeModal,
  refundNotification,
  loadTableData,
}) {
  const [iRefundNotification, setIRefundNotification] = useState({
    type: 0,
    ...refundNotification,
  })

  const [selectedDebitAccount, setSelectedDebitAccount] = useState(null)
  const [selectedCreditAccount, setSelectedCreditAccount] = useState(null)

  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(refundNotificationRegistrationOption)[0]
  )

  const handleOnSelectDebitAccount = debitDetails => {
    if (!debitDetails.isLocked) {
      setSelectedDebitAccount({ ...selectedDebitAccount, ...debitDetails })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedDebitAccount(null)
    }
  }

  const handleOnSelectCreditAccount = creditDetails => {
    if (!creditDetails.isLocked) {
      setSelectedCreditAccount({ ...selectedCreditAccount, ...creditDetails })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCreditAccount(null)
    }
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({
        ...selectedCustomer,
        ...customer,
      })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Customer is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  async function postRefundNotification() {
    const { success } = await postData(
      '/RefundNotification/add-refund-notification',
      {
        refundNotification: {
          ...selectedCustomer,
          debitCustomerAccountId: selectedDebitAccount?.id,
          ...selectedDebitAccount,
          creditCustomerAccountId: selectedCreditAccount?.id,
          ...selectedCreditAccount,
          ...iRefundNotification,
        },
        refundNotificationRegistrationOption: selectedAuth,
      },
      true,
      'Operation Completed Successfully'
    )
    if (success) {
      closeModal()
      loadTableData()
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={'add'}
      modalTitle="refundNotifications"
      handleClose={closeModal}
      modalClassName="modal-xl"
      actionText={'create'}
      handleAction={postRefundNotification}
      footerChildren={
        <>
          <div className="col-md-4 mt-3">
            <div className="d-flex align-items-center mb-2">
              <label className="col-4 text-capitalize">
                {localize('registrationAction')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RefundNotificationRegistrationOption"
                onChange={e => setSelectedAuth(e.value)}
              />
            </div>
          </div>
        </>
      }
    >
      <CustomerPartial
        customer={selectedCustomer}
        onCustomerLookup={r => {
          clearValidation('RefundNotification.CustomerId')
          handleOnSelectCustomer(r)
        }}
        message={
          globalValidationErrors['RefundNotification.CustomerId']?.message
        }
      />
      <div className="row mb-2">
        <div
          className="col-3 d-flex align-items-center with-validation"
          validation-message={
            globalValidationErrors['RefundNotification.RefundTypeId']?.message
          }
        >
          <label className="col-4 text-capitalize">
            {localize('refundType')}
          </label>
          <RefundTypeLookup
            displayValue={iRefundNotification?.refundTypeDescription}
            onSelect={d => {
              clearValidation('RefundNotification.RefundTypeId')
              setIRefundNotification({
                ...iRefundNotification,
                refundTypeDescription: d.description,
                refundTypeId: d.id,
              })
            }}
          />
        </div>
        <div
          className="col-9 d-flex align-items-center with-validation"
          validation-message={
            globalValidationErrors['RefundNotification.Remarks']?.message
          }
        >
          <label className="col-1 text-capitalize me-2">
            {localize('remarks')}
          </label>
          <input
            defaultValue={iRefundNotification?.remarks}
            className="form-control ms-4"
            onChange={e => {
              clearValidation('RefundNotification.Remarks')
              setIRefundNotification({
                ...iRefundNotification,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <>
        <div
          className="p-2 mt-2"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('debit')}
          </div>
          {!selectedCustomer ? (
            <CustomerAccountRefundProcessingDebitCreditPartial
              onCustomerDebitAccountLookup={r => {
                return Swal.fire({
                  icon: 'info',
                  text: 'Select a Customer First',
                  title: 'Ooops!',
                  showCloseButton: true,
                })
              }}
              selectedCustomer="null"
            />
          ) : (
            <CustomerAccountRefundProcessingDebitCreditPartial
              selectedDebitAccount={selectedDebitAccount}
              onCustomerDebitAccountLookup={r => {
                clearValidation('RefundNotification.DebitCustomerAccountId')
                handleOnSelectDebitAccount(r)
              }}
              lookupType="debit"
              selectedCustomer={selectedCustomer}
              iRefundNotification={iRefundNotification}
              setIRefundNotification={setIRefundNotification}
              message={
                globalValidationErrors[
                  'RefundNotification.DebitCustomerAccountId'
                ]?.message
              }
            />
          )}
        </div>
        <div
          className="p-2 mt-4 mb-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('credit')}
          </div>
          {!selectedCustomer ? (
            <CustomerAccountRefundProcessingDebitCreditPartial
              onCustomerCreditAccountLookup={r => {
                return Swal.fire({
                  icon: 'info',
                  text: 'Select a Customer First',
                  title: 'Ooops!',
                  showCloseButton: true,
                })
              }}
              selectedCustomer="null"
            />
          ) : (
            <CustomerAccountRefundProcessingDebitCreditPartial
              selectedCreditAccount={selectedCreditAccount}
              onCustomerCreditAccountLookup={r => {
                clearValidation('RefundNotification.CreditCustomerAccountId')
                handleOnSelectCreditAccount(r)
              }}
              lookupType="credit"
              selectedCustomer={selectedCustomer}
              message={
                globalValidationErrors[
                  'RefundNotification.CreditCustomerAccountId'
                ]?.message
              }
            />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}
export default AddRefundProcessing
