/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import { formatCurrency, formatDate, parseDate } from '../../Helpers/formatters'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
function WireTransferBatchLookup({ displayValue, onSelect, disabled }) {
  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('batchType'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('wireTransferType'),
      template: r => <>{r.wireTransferTypeDescription}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('autowire') + '?',
      template: r => (
        <span className="text-capitalize">{r.autowire.toString()}</span>
      ),
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verifiedBy/RejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verifiedDate/RejectedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: 1,
    startDate: parseDate(new Date()),
    endDate: parseDate(new Date()),
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    customDate: false,
    dateRequestFilter: 0,
  })
  const [refresh, setRefresh] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])

  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive, refresh])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/WireTranferBatch/find-by-status-and-filter-in-page',
      reqParams,
      false
    )

    if (success) {
      setData(data)
      setTableData(data)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }
  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('wireTransferBatchLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center my-2">
                    <label
                      className="text-capitalize"
                      htmlFor="lookupRecordsPerPage"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="lookupRecordsPerPage"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label htmlFor="" className="text-capitalize me-2 col-3">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      customStyle={{ minWidth: 250 }}
                      onSelect={d =>
                        setReqParams({
                          ...reqParams,
                          customDate: d?.custom,
                          startDate: d?.startDate,
                          endDate: d?.endDate,
                          dateRequestFilter: d?.value,
                        })
                      }
                    />
                  </div>

                  <div className="d-flex align-items-center my-2">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      defaultValue={reqParams.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }
                      type="search"
                      className="form-control ms-3"
                      id="searchTable"
                    />
                    <button
                      className="btn btn-primary ms-2"
                      onClick={() => setRefresh(!refresh)}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData?.pageCollection || []}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default WireTransferBatchLookup
