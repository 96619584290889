/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../Helpers/webApi'
import { CustomerFilter } from '../../../../Global/enumeration'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import FingerPrintRegistrationModal from './Partials/FingerPrintRegistrationModal'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'

function FingerPrintBiometricRegister() {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [acquisitionHistory, setAcquisitionHistory] = useState([])
  const [
    selectedFingerprintBiometricRegister,
    setSelectedFingerprintBiometricRegister,
  ] = useState(null)

  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [acquisitionHistoryReqParams, setAcquisitionHistoryReqParams] =
    useState({
      pageIndex: 0,
      pageSize: 10,
    })
  const columns = [
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('preferredFingerDigit'),
      template: r => <>{r.preferredFingerDigit}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [fingerPrintSpecimens, setFingerPrintSpecimens] = useState(null)

  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  useEffect(() => {
    if (selectedFingerprintBiometricRegister) {
      getAcquisitionHistory(selectedFingerprintBiometricRegister)
    }
  }, [acquisitionHistoryReqParams])

  const resetState = () => {
    setAcquisitionHistory([])
    setSelectedCustomer(null)
    setFingerPrintSpecimens(null)
  }

  const handleOnEdit = async r => {
    resetState()
    setMode('edit')
    await getCustomer(r)
  }

  const handleOnView = async r => {
    resetState()
    setMode('view')
    await getCustomer(r)
    await getAcquisitionHistory(r)
    await getFingerPrintSpecimens(r)
    setSelectedFingerprintBiometricRegister(r)
  }

  const handleOnAdd = () => {
    resetState()
    setMode('add')
  }

  const handleOnManage = () => {}

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/FingerprintBiometricRegister/find-fingerprint-biometric-registers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
    }
    setIsBusy(true)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const getAcquisitionHistory = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/FingerprintBiometricRegister/find-fingerprint-acquisition-history-in-page',
      {
        fingerprintBiometricRegisterId: r.id,
        ...acquisitionHistoryReqParams,
      },
      false
    )
    if (success) {
      setAcquisitionHistory(data)
    }
    setIsBusy(false)
  }

  const getCustomer = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Customer/find-customer-by-id',
      {
        customerId: r.customerId,
      },
      false
    )
    if (success) {
      setSelectedCustomer(data || null)
    }
    setIsBusy(false)
  }

  const getFingerPrintSpecimens = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/FingerprintBiometricRegister/find-fingerprint-specimens',
      {
        fingerprintBiometricRegisterId: r.id,
      },
      false
    )
    if (success) {
      setFingerPrintSpecimens(data || null)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('fingerPrintBiometricRegister'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error && (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        Failed to Load Data
                      </div>
                    </div>
                  )}
                  <>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <AddNewButton
                            handleClickAddButton={() => {
                              handleOnAdd()
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex align-items-center col-3">
                        <label
                          htmlFor="recordsPerPage"
                          className="text-capitalize"
                          style={{ marginLeft: -10 }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          name=""
                          id="recordsPerPage"
                          className="form-select ms-3"
                          style={{ maxWidth: 60 }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="col-6"></div>
                      <form
                        className="d-flex align-items-center col-3 col"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <label
                          htmlFor="searchTable"
                          className="text-capitalize"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                    </div>
                    <div className="row col-12 mt-2">
                      <SimpleTable
                        contextMenu={r => (
                          <>
                            <div
                              onClick={() => {
                                handleOnView(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('view')}
                            </div>
                            <div
                              onClick={() => {
                                handleOnEdit(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-pen"></i>
                              {localize('edit')}
                            </div>
                            <div
                              onClick={() => {
                                setMode('manage')
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-shutter-alt"></i>
                              {localize('manage')}
                            </div>
                          </>
                        )}
                        data={tableData}
                        columns={columns}
                        onSort={(c, i) => handleSort(c, i)}
                        sortedBy={sortedBy}
                      />
                    </div>
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <FingerPrintRegistrationModal
        reqParams={acquisitionHistoryReqParams}
        setReqParams={setAcquisitionHistoryReqParams}
        selectedCustomer={selectedCustomer}
        acquisitionHistory={acquisitionHistory}
        fingerPrintSpecimens={fingerPrintSpecimens}
        onHideModal={m => {
          setMode(null)
        }}
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default FingerPrintBiometricRegister
