/** @format */

import React, { useEffect, useState } from 'react'
import LineChart from '../../../../../Charts/ChartJS/PieChart/LineChart'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import {
  getSevenDaysAgoFromToday,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'

const DailyPatientAdmission = () => {
  const [dailyAdmissions, setDailyAdmissions] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    startDate: getSevenDaysAgoFromToday(),
    endDate: getTodaysDate(),
  })

  const fetchDailyAdmissions = async () => {
    setIsBusy(true)
    const url =
      '/MorguePatient/find-morgue-patients-admission-daily-summary-statistics'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setDailyAdmissions(data?.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchDailyAdmissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('morgueManagement'),
          localize('visualStatistics'),
          localize('dailyPatientAdmission'),
        ]}
      />

      <div className="card bg-white">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('startDate')}
              </label>
              <CustomDatePicker
                name="dailyPatientAdmissionStartDate"
                defaultDate={reqParams?.startDate}
                onDateChange={date =>
                  setReqParams({ ...reqParams, startDate: date })
                }
                maxDate={reqParams?.endDate}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('endDate')}
              </label>
              <CustomDatePicker
                name="dailyPatientAdmissionEndDate"
                defaultDate={reqParams?.endDate}
                onDateChange={date =>
                  setReqParams({ ...reqParams, endDate: date })
                }
                minDate={getSevenDaysAgoFromToday()}
                maxDate={getTodaysDate()}
              />
            </div>
          </div>

          <div className="mt-2">
            {isBusy ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-md-8 d-flex  justify-content-center">
                  <LineChart
                    label={localize('dailyPatientAdmission')}
                    labels={dailyAdmissions?.map(d => d?.formattedDate)}
                    dataSet={dailyAdmissions?.map(d => d?.total)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DailyPatientAdmission
