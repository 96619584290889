/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import SimpleTable from '../../Tables/SimpleTable'

const ChangeLog = ({ auditRecords }) => {
  const columns = [
    {
      label: localize('columnName'),
      template: r => r?.columnName,
    },
    {
      label: localize('originalValue'),
      template: r => r?.originalValue,
    },
    {
      label: localize('newValue'),
      template: r => r?.newValue,
    },
  ]

  return (
    <>
      <SimpleTable data={auditRecords} columns={columns} />
    </>
  )
}

export default ChangeLog
