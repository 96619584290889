/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../../Helpers/extensions'
import {
  getTodaysDate,
  getTomorrowsDate,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import SurveyEntries from '../SurveyEntries/SurveyEntries'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Loader from '../../../../../../Components/Loaders/Loader'

const AddSurvey = ({ handleClose, mode, survey, setSurvey, fetchSurveys }) => {
  const [entry, setEntry] = useState({})
  const [selectedEntries, setSelectedEntries] = useState([])
  const [surveyEntries, setSurveyEntries] = useState([])
  const [waitingEntries, setWaitingEntries] = useState(false)
  const [waitingCreate, setWaitingCreate] = useState(false)
  const [surveyMetaData, setSurveyMetaData] = useState({})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
  })

  const fetchEntries = async () => {
    setWaitingEntries(true)

    const url = '/Survey/find-survey-entries-by-survey-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        surveyId: survey?.id,
      },
      false
    )

    if (success) {
      setSurveyEntries(data?.pageCollection)
      setWaitingEntries(false)
      setSurveyMetaData(data)
    } else {
      setSurveyEntries([])
      setWaitingEntries(false)
    }
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectEntries = (entry, value) => {
    if (value) {
      if (selectedEntries.includes(entry?.description))
        return Swal.fire({
          icon: 'error',
          title: 'Operation not allowed!',
          text: 'This entry already exists!',
        })

      setSelectedEntries([...selectedEntries, entry])
    } else {
      setSelectedEntries(
        selectedEntries.filter(e => e?.description !== entry?.description)
      )
    }
  }

  const handleClickAddButton = () => {
    if (!entry?.description)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please enter a description!',
      })

    if (surveyEntries.find(e => e?.description === entry?.description))
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'This entry already exists!',
      })

    setSurveyEntries(
      [
        ...surveyEntries,
        {
          ...entry,
          id: entry?.id ?? generateGuid(),
        },
      ],
      {
        itemsCount: surveyEntries?.length + 1,
      }
    )
    setEntry({
      description: '',
    })
  }

  const handleRemoveEntry = async () => {
    if (!selectedEntries.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select an entry!',
      })

    const url = '/Survey/remove-survey-entries'

    setWaitingEntries(true)

    if (mode === 'edit') {
      const { success } = await postData(
        url,
        selectedEntries.map(en => ({
          ...en,
          surveyId: survey?.id,
        })),
        false
      )

      if (success) {
        showNotification('Remove Survey Entries', 'success')
        fetchEntries()
        setWaitingEntries(false)
      }
    }

    setSurveyEntries(
      surveyEntries.filter(
        e => !selectedEntries.find(s => s?.description === e?.description)
      )
    )
    setSelectedEntries([])
    setWaitingEntries(false)
  }

  const handleCreateSurvey = async () => {
    if (
      (!survey?.description || !survey?.endDate || !survey?.entryQuestion) &&
      mode === 'add'
    )
      return GlobalValidationErrors.set({
        Description: !survey?.description && {
          message: 'Please enter a description!',
        },

        EntryAnswer: !survey?.entryAnswer && {
          message: 'Please enter an entry answer!',
        },

        EntryQuestion: !survey?.entryQuestion && {
          message: 'Please enter an entry question!',
        },
      })

    const url =
      mode === 'add' ? '/Survey/create-survey' : '/Survey/update-survey'

    setWaitingCreate(true)

    const { success, data } = await postData(url, survey, false)

    if (success) {
      const url = '/Survey/update-survey-entries-by-survey-id'

      const { success } = await postData(
        url,
        {
          surveyId: survey?.id || data?.id,
          surveyEntries: surveyEntries.map(e => ({
            ...e,
            surveyId: survey?.id || data?.id,
          })),
        },
        false
      )

      if (success) {
        showNotification('Create Survey', 'success')
        handleClose()
        setSelectedEntries([])
        setSurveyEntries([])
        setSurveyMetaData([])
        setSurvey({
          endDate: getTomorrowsDate(),
        })
        fetchSurveys()
      }
    } else {
      setWaitingCreate(false)
    }
  }

  useEffect(() => {
    if (survey?.id) fetchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, survey?.id])

  return (
    <ModalTemplate
      modalTitle={
        mode === 'edit' ? localize('editSurvey') : localize('createSurvey')
      }
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={handleCreateSurvey}
      disableCreate={waitingCreate}
    >
      {waitingCreate ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validationMessage={
                  globalValidationErrors?.['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('description')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={survey?.description}
                  onChange={e => {
                    clearValidation('Description')
                    setSurvey({
                      ...survey,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('endDate')}
                </label>
                <CustomDatePicker
                  defaultDate={survey?.endDate}
                  name={'surveyEndDate'}
                  minDate={getTodaysDate()}
                  onDateChange={date => {
                    setSurvey({
                      ...survey,
                      endDate: date,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="d-flex align-items-center with-validation"
                validationMessage={
                  globalValidationErrors?.['EntryQuestion']?.message
                }
              >
                <label className="text-capitalize col-1">
                  {localize('entryQuestion')}
                </label>
                <textarea
                  className="form-control"
                  value={survey?.entryQuestion}
                  onChange={e => {
                    clearValidation('EntryQuestion')
                    setSurvey({
                      ...survey,
                      entryQuestion: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors?.['EntryAnswer']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('entryAnswer')}
                </label>
                <textarea
                  className="form-control"
                  value={survey?.entryAnswer}
                  onChange={e => {
                    clearValidation('EntryAnswer')
                    setSurvey({
                      ...survey,
                      entryAnswer: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('closingRemark')}
                </label>
                <textarea
                  className="form-control"
                  value={survey?.closingRemark}
                  onChange={e =>
                    setSurvey({
                      ...survey,
                      closingRemark: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('entries')}
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-1">
                    {localize('description')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={entry?.description}
                    onChange={e =>
                      setEntry({
                        ...entry,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="d-flex align-items-center justify-content-end p-2 bg-light rounded">
                  <AddNewButton
                    handleClickAddButton={handleClickAddButton}
                    message="add"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <Tab
                tabItems={[
                  {
                    label: localize('entries'),
                    item: (
                      <SurveyEntries
                        surveyId={survey?.id}
                        selectedEntries={selectedEntries}
                        handleSelectEntry={handleSelectEntries}
                        fetchEntries={fetchEntries}
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                        surveyEntries={surveyEntries}
                        isBusy={waitingEntries}
                        handleRemoveEntry={handleRemoveEntry}
                        canSelect
                        data={surveyMetaData}
                      />
                    ),
                    icon: 'uil uil-edit',
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddSurvey
