/** @format */

import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'

function LoanCaseFixedDepositLiens({ fixedDepositLiens }) {
  const [liens, setLiens] = useState(fixedDepositLiens)

  useEffect(() => {
    setLiens(fixedDepositLiens)
  }, [fixedDepositLiens])

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedFixedDepositSerialNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.fixedDepositStatusDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.fixedDepositCategoryDescription}</>,
    },
    {
      label: `${localize('term')} (${localize('months')})`,
      template: r => <>{r?.fixedDepositTerm}</>,
    },
    {
      label: localize('maturityAction'),
      template: r => <>{r?.fixedDepositMaturityActionDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('value'),
      template: r => <>{formatCurrency(r?.value)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return <SimpleTable columns={columns} data={liens} />
}

export default LoanCaseFixedDepositLiens
