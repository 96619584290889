/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../../Global/Localize'
import { getData } from '../../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../../../Components/Loaders/Loader'
function MonthlyScores({ selectedCreditInformationQuery }) {
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [isBusy, setIsBusy] = useState(false)

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/CreditReferenceBureau/find-credit-information-query-monthly-scores`,
      {
        creditInformationQueryId: selectedCreditInformationQuery.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setEntries(data.pageCollection)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [
    selectedCreditInformationQuery?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])
  const columns = [
    {
      label: localize('month'),
      template: r => <>{r.month}</>,
    },
    {
      label: localize('creditScore'),
      template: r => <>{r.creditScore}</>,
    },
  ]
  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={entries} />
        </>
      )}
    </div>
  )
}
export default MonthlyScores
