/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { postData, putData } from '../../../../Helpers/webApi'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../Components/Loaders/Loader'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'

const CreateRiskRegister = ({
  mode,
  setMode,
  riskRegister,
  setRiskRegister,
  handleClose,
  fetchRisksRegister,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleAdd = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/RiskRegister/create',
      riskRegister,
      false
    )
    if (success) {
      showNotification(
        'Create risk Register',
        'success',
        'Operation completed successfully!'
      )
      setMode(null)
      setRiskRegister(null)
      fetchRisksRegister()
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)
    const { success } = await putData(
      '/RiskRegister/update',
      riskRegister,
      false
    )
    if (success) {
      showNotification('Edit Risk Register', 'success')
      setMode(null)
      setRiskRegister(null)
      fetchRisksRegister()
    }
    setIsBusy(false)
  }
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={
        mode === 'add'
          ? localize('createRiskRegister')
          : localize('updateRiskRegister')
      }
      actionText={mode === 'add' ? 'create' : 'update'}
      handleAction={mode === 'add' ? handleAdd : handleEdit}
      handleClose={() => {
        handleClose()
      }}
      modalClassName="modal-xl"
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('description')}
              </label>
              <input
                type="text"
                className="form-control"
                value={riskRegister?.description}
                placeholder="Enter Text..."
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('owner')}
              </label>
              <input
                type="text"
                className="form-control"
                value={riskRegister?.owner}
                placeholder="Enter Text..."
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    owner: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-cente mb-3">
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('occurrenceDate')}
              </label>
              <CustomDatePicker
                name="occurrenceDate"
                defaultDate={riskRegister?.occurrenceDate}
                onDateChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    occurrenceDate: e,
                  })
                }}
              />
            </div>
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('frequency')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RiskLevel"
                value={riskRegister?.frequency}
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    frequency: e.value,
                    frequencyDescription: e.label,
                  })
                }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('impact')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RiskLevel"
                value={riskRegister?.impact}
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    impact: e.value,
                    impactDescription: e.label,
                  })
                }}
              />
            </div>
            <div className="col-6 d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('severity')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RiskLevel"
                value={riskRegister?.severity}
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    severity: e.value,
                    severityDescription: e.label,
                  })
                }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-12 text-capitalize d-flex">
              <label className="text-capitalize col-1">
                {localize('mitigation')}
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Text..."
                rows="2"
                value={riskRegister?.mitigation}
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    mitigation: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row d-flex align-items-center mb-3">
            <div className="col-12 text-capitalize d-flex">
              <label className="text-capitalize col-1">
                {localize('remarks')}
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Text..."
                rows="2"
                value={riskRegister?.remarks}
                onChange={e => {
                  setRiskRegister({
                    ...riskRegister,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateRiskRegister
