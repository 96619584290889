/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { formatDate } from '../../../../../../Helpers/formatters'
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import CustomerBatchEntries from '../../../../../../Components/ReUsables/BatchEntries/CustomerBatchEntries/CustomerBatchEntries'

const ViewBulkMessage = ({ batch, handleClose, mode }) => {
  return (
    <ModalTemplate
      modalTitle="bulkMessageBatch"
      hideUpdate
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      <div className="row g-3">
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4 ms-1">
              {localize('batchNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.paddedBatchNumber || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('messageType')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.messageTypeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.reference || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.statusDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-1">
              {localize('messageSubject')}
            </label>
            <textarea
              className="form-control"
              value={batch?.messageSubject}
              disabled
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-1">
              {localize('messageBody')}
            </label>
            <textarea
              className="form-control"
              value={batch?.messageBody}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4 ms-1">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.createdBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.createdDate) || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('verifiedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.auditedBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4 ms-1">
              {localize('verifiedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.verifiedDate) || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('verificationRemarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.auditRemarks || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('authorizedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.authorizedBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('authorizedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(batch?.authorizedDate) || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4 ms-1">
              {localize('authorizationRemarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={batch?.authorizationRemarks || ''}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <Tab
          tabItems={[
            {
              label: localize('batchEntries'),
              item: <CustomerBatchEntries bulkMessageBatchId={batch?.id} />,
            },
          ]}
        />
      </div>
    </ModalTemplate>
  )
}

export default ViewBulkMessage
