/** @format */

import React, { useState } from 'react'
import swal from 'sweetalert2'
import { postData } from '../../../../../../../Helpers/webApi'
import localize from '../../../../../../../Global/Localize'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const EditPurchaseRequisitionItems = ({ entries, onRefresh }) => {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const handleOnRemovePurchaseRequisitionItem = async () => {
    if (selectedEntries.length === 0) {
      return swal.fire(
        'Remove Purchase Requisition',
        'Please select at least one item',
        'warning',
      )
    }
    setIsBusy(true)
    const { success, data } = await postData(
      '/PurchaseRequisition/remove-purchase-requisition-item',
      { PurchaseRequisitionItems: selectedEntries },
      false,
    )
    if (success) {
      onRefresh()
      return swal.fire(
        'Remove Requisition',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    { label: localize('description'), template: r => <>{r.description}</> },
    { label: localize('reason'), template: r => <>{r.reason}</> },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            selectables
            data={entries?.pageCollection}
            columns={columns}
          />
          <div className="d-flex align-content-end justify-content-end mt-2 mb-2">
            <div className="d-flex align-items-center me-5">
              <button
                onClick={handleOnRemovePurchaseRequisitionItem}
                className="btn btn-primary px-5"
              >
                {localize('remove')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
