/** @format */

import localize from '../../../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'

export const ViewIdentitySpecimen = ({ identityVerification }) => {
  const [photo, setPhoto] = useState('')
  const [fingerprint, setFingerprint] = useState('')
  const [signature, setSignature] = useState('')
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (identityVerification?.id) {
      fetchidentityVerificationSpecimen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityVerification?.id])

  const fetchidentityVerificationSpecimen = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditReferenceBureau/find-identity-verification-query',
      {
        identityVerificationQueryId: identityVerification?.id,
      },
      false
    )
    if (success) {
      setPhoto(data?.identityVerification?.photo)
      setFingerprint(data?.identityVerification?.fingerprint)
      setSignature(data?.identityVerification?.signature)
    }
    setIsBusy(false)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col">
              <div
                className="p-2 mt-2"
                style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
              >
                <div
                  className="bg-white row px-2 text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('photo')}
                </div>
                <img
                  src={photo}
                  alt=""
                  srcSet=""
                  style={{
                    width: '100%',
                    height: '100%',
                    minHeight: 250,
                    background: '#0002',
                    border: 'none',
                    borderRadius: 5,
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div
                className="p-2 mt-2"
                style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
              >
                <div
                  className="bg-white row px-2 text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('signature')}
                </div>
                <img
                  src={signature}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    minHeight: 250,
                    background: '#0002',
                    border: 'none',
                    borderRadius: 5,
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div
                className="p-2 mt-2"
                style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
              >
                <div
                  className="bg-white row px-2 text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('fingerprint')}
                </div>
                <img
                  src={fingerprint}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    minHeight: 250,
                    background: '#0002',
                    border: 'none',
                    borderRadius: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
