/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import SavingsProductLookup from '../../../../Components/Lookup/SavingsProductLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SelectableCommissions from '../../../../Components/Selectables/SelectableCommissions'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function LoanDisbursmentModeModal({
  mode,
  onHideModal,
  setSelectedLoanDisbursementMode,
  selectedLoanDisbursementMode,
  tabLoading,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/LoanDisbursementMode/add-loan-disbursement-mode-with-commissions'
        : '/LoanDisbursementMode/update-loan-disbursement-mode-with-commissions'
    const { success } = await postData(
      url,
      {
        loanDisbursementMode: selectedLoanDisbursementMode || {},
        commissionDTOs:
          selectedLoanDisbursementMode?.commissions.filter(x => x.checked) ||
          [],
      },
      false
    )

    if (success) {
      loadTableData()
      closeModal()
      showNotification('Loan Disbursement Mode', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedLoanDisbursementMode(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalTitle={'loanDisbursementMode'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        hideUpdate={mode === 'view'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                disabled={mode === 'view'}
                id="cDisModeRecoverArrearages"
                onCheck={e => {
                  setSelectedLoanDisbursementMode({
                    ...selectedLoanDisbursementMode,
                    recoverSundryArrearages: e,
                  })
                }}
                checked={selectedLoanDisbursementMode?.recoverSundryArrearages}
              />
              <label
                htmlFor="cDisModeRecoverArrearages"
                className="text-capitalize"
              >
                {localize('recoverArrearages')}?
              </label>
              <div className="mx-3"></div>
              {mode === 'edit' || mode === 'view' ? (
                <>
                  <Checkbox
                    disabled={mode === 'view'}
                    id="cDisModeIsDefault"
                    onCheck={e => {
                      setSelectedLoanDisbursementMode({
                        ...selectedLoanDisbursementMode,
                        isDefault: e,
                      })
                    }}
                    checked={selectedLoanDisbursementMode?.isDefault}
                  />
                  <label
                    htmlFor="cDisModeIsDefault"
                    className="text-capitalize"
                  >
                    {localize('isDefault')}?
                  </label>
                  <div className="mx-3"></div>
                </>
              ) : (
                ''
              )}
              <Checkbox
                disabled={mode === 'view'}
                id="isDisModeLocked"
                onCheck={e => {
                  setSelectedLoanDisbursementMode({
                    ...selectedLoanDisbursementMode,
                    isLocked: e,
                  })
                }}
                checked={selectedLoanDisbursementMode?.isLocked}
              />
              <label htmlFor="isDisModeLocked" className="text-capitalize">
                {localize('isLocked')}?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-3 g-3">
              <div className="col-md-12">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['LoanDisbursementMode.Description']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-1">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    className="form-control ms-5"
                    defaultValue={selectedLoanDisbursementMode?.description}
                    onChange={e => {
                      clearValidation(['LoanDisbursementMode.Description'])
                      setSelectedLoanDisbursementMode({
                        ...selectedLoanDisbursementMode,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-2 me-5">
                    {localize('disbursementCategory')}
                  </label>
                  <EnumsServerCombo
                    disabled={mode === 'view'}
                    value={selectedLoanDisbursementMode?.disbursementCategory}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="DisbursementCategory"
                    onChange={e =>
                      setSelectedLoanDisbursementMode({
                        ...selectedLoanDisbursementMode,
                        disbursementCategory: e.value,
                      })
                    }
                  />
                </div>
              </div>
              {selectedLoanDisbursementMode?.disbursementCategory === '0' ? (
                <div className="col-md-6">
                  <div
                    className="with-validation d-flex align-items-center mb-2"
                    validation-message={
                      globalValidationErrors[
                        'LoanDisbursementMode.SavingsProductId'
                      ]?.message
                    }
                  >
                    <label className="text-capitalize col-3">
                      {localize('savingsProduct')}
                    </label>
                    {mode === 'view' ? (
                      <input
                        disabled
                        className="form-control ms-5"
                        defaultValue={
                          selectedLoanDisbursementMode?.savingsProductDescription
                        }
                      />
                    ) : (
                      <SavingsProductLookup
                        displayValue={
                          selectedLoanDisbursementMode?.savingsProductDescription
                        }
                        onSelect={v => {
                          clearValidation([
                            'LoanDisbursementMode.SavingsProductId',
                          ])
                          setSelectedLoanDisbursementMode(prev => ({
                            ...prev,
                            savingsProductDescription: v.description,
                            savingsProductId: v.id,
                          }))
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="col-md-6">
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-3">
                      {localize('glAccountName')}
                    </label>
                    {mode === 'view' ? (
                      <input
                        disabled
                        className="form-control ms-5"
                        defaultValue={
                          selectedLoanDisbursementMode?.chartOfAccountName
                        }
                      />
                    ) : (
                      <ChartOfAccountsLookup
                        displayValue={
                          selectedLoanDisbursementMode?.chartOfAccountName
                        }
                        onSelect={v => {
                          setSelectedLoanDisbursementMode(prev => ({
                            ...prev,
                            chartOfAccountName: v.description,
                            chartOfAccountId: v.id,
                          }))
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <SelectableCommissions
                            disabled={mode === 'view'}
                            setSelectableCommissionItem={
                              setSelectedLoanDisbursementMode
                            }
                            selectableCommissionItem={
                              selectedLoanDisbursementMode
                            }
                            prefix={'create-debit-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default LoanDisbursmentModeModal
