/** @format */

import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import React from 'react'
import { formatCurrency } from '../../../../../Helpers/formatters'

export const CreatePayableReceivableEntries = ({
  data,
  handleOnRemove,
  apportioned,
  shortage,
}) => {
  const columns = [
    { label: localize('entryType'), template: r => <>{r.typeDescription}</> },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('glAccountName'),
      template: r => <>{r.chartOfAccountAccountName}</>,
    },
    {
      label: localize('glAccountCostDistributionRuleDescription'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('supplierName'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('supplierType'),
      template: r => <>{r.supplierSupplierTypeDescription}</>,
    },
    {
      label: localize('itemRegister'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    { label: localize('value'), template: r => <>{formatCurrency(r.value)}</> },
    {
      label: localize('revenueTax'),
      template: r => <>{r.revenueTaxDescription}</>,
    },
    {
      label: localize('revenueTaxTaxRate'),
      template: r => <>{r.revenueTaxTaxRate}</>,
    },
    { label: localize('taxInclusive'), template: r => <>{r.taxInclusive}</> },
  ]
  return (
    <>
      <SimpleTable
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                handleOnRemove(e)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash text-danger"></i>
              {localize('remove')}
            </div>
          </>
        )}
        columns={columns}
        data={data}
      />
      <div className="d-flex justify-content-end bg-light d-flex p-2">
        <div className="d-flex align-items-center me-5">
          <label className="col-3 text-capitalize me-2">
            {localize('apportioned')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(apportioned, 2, 2, false)}
          />
        </div>
        <div className="d-flex align-items-center me-5">
          <label className="col-3 text-capitalize">
            {localize('shortage')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(shortage, 2, 2, false)}
          />
        </div>
      </div>
    </>
  )
}
