/** @format */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { AddEmolumentTypeModal } from './AddEmolumentTypeModal'
import { EditEmolumentTypeModal } from './EditEmolumentTypeModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewEmolumentTypeModal } from './ViewEmolumentTypeModal'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

function EmolumentTypesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedEmolumentType,
  setSelectedEmolumentType,
  waitingMetadata,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addEmolumentType()
    }
    if (mode === 'edit') {
      return editEmolumentType()
    }
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  const addEmolumentType = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/EmolumentType/add-emolument-type-with-commissions-and-savings-products',
      {
        EmolumentType: selectedEmolumentType || {},
        Commissions: selectedEmolumentType?.commissions.filter(x => x.checked),
        SavingsProducts: selectedEmolumentType?.savingsProducts.filter(
          x => x.checked,
        ),
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedEmolumentType(null)
      swal.fire(
        'Add Emolument Type',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editEmolumentType = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/EmolumentType/update-emolument-type-with-commissions-and-savings-products',
      {
        EmolumentType: selectedEmolumentType || {},
        Commissions: selectedEmolumentType?.commissions.filter(x => x.checked),
        SavingsProducts: selectedEmolumentType?.savingsProducts.filter(
          x => x.checked,
        ),
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedEmolumentType(null)
      swal.fire(
        'Edit Emolument Type',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('emolumentType')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <AddEmolumentTypeModal
                isBusy={isBusy}
                waitingMetadata={waitingMetadata}
                selectedEmolumentType={selectedEmolumentType}
                setSelectedEmolumentType={setSelectedEmolumentType}
              />
            )}
            {mode === 'edit' && (
              <EditEmolumentTypeModal
                isBusy={isBusy}
                waitingMetadata={waitingMetadata}
                selectedEmolumentType={selectedEmolumentType}
                setSelectedEmolumentType={setSelectedEmolumentType}
              />
            )}
            {mode === 'view' && (
              <ViewEmolumentTypeModal
                waitingMetadata={waitingMetadata}
                selectedEmolumentType={selectedEmolumentType}
                setSelectedEmolumentType={setSelectedEmolumentType}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default EmolumentTypesModal
