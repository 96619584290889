/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import localize from '../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from '../../../Tables/SimpleTable'
import Pager from '../../../Tables/Pager'
import Loader from '../../../Loaders/Loader'

function StandingOrderExecutionHistoryTab({ standingOrderId }) {
  const [isBusy, setIsBusy] = useState(false)
  const [standingOrders, setStandingOrders] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r.beneficiaryProductDescription}</>,
    },
    {
      label: localize('expectedPrincipal'),
      template: r => <>{formatCurrency(r.expectedPrincipal)}</>,
    },
    {
      label: localize('expectedInterest'),
      template: r => <>{formatCurrency(r.ExpectedInterest)}</>,
    },
    {
      label: localize('actualInterest'),
      template: r => <>{formatCurrency(r.actualInterest)}</>,
    },
    {
      label: localize('residualPrincipal'),
      template: r => <>{formatCurrency(r.actualPrincipal)}</>,
    },
    {
      label: localize('residualInterest'),
      template: r => <>{formatCurrency(r.residualPrincipal)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r.chargeFixedAmount)}</>,
    },
    {
      label: localize('trigger'),
      template: r => <>{r.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('beneficiaryName'),
      template: r => <>{r.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r.benefactorProductDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.beneficiaryName}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.transactionCodeDescription}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const fetchStandingOrders = async () => {
    const url = '/StandingOrder/find-history-by-standing-order-id-in-page'

    const { success, data } = await getData(
      url,
      {
        standingOrderId,
        ...reqParams,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setStandingOrders(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (standingOrderId) fetchStandingOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, standingOrderId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            data={standingOrders?.pageCollection}
            columns={columns}
          />
          <Pager
            itemsCount={standingOrders?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => {
              setReqParams({ ...reqParams, pageIndex: p })
            }}
          />
        </>
      )}
    </>
  )
}

export default StandingOrderExecutionHistoryTab
