/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  CustomerFilter,
  loanGuarantorSubstitutionRegistrationOption,
  loanGuarantorSubstitutionRequestStatus,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../Helpers/extensions'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import CreateSubstitution from './CreateSubstitution/CreateSubstitution'
import ViewSubstitutes from './ViewSubstitutes/ViewSubstitutes'

const Substitution = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [guarantorSubstitutionRequests, setGuarantorSubstitutionRequests] =
    useState([])
  const [mode, setMode] = useState(null)
  const [substitutionAction, setSubstitutionAction] = useState(
    Object.keys(loanGuarantorSubstitutionRegistrationOption)[0]
  )
  const [guarantorSubstitutionRequest, setGuarantorSubstitutionRequest] =
    useState({})
  const [loansGuaranteed, setLoansGuaranteed] = useState([])
  const [selectedLoans, setSelectedLoans] = useState([])
  const [guarantorSubstitutes, setSelectedGuarantorSubstitutes] = useState([])
  const [selectedSubstitutes, setSelectedSubstitutes] = useState([])
  const [customer, setCustomer] = useState({})
  const [substituteDetails, setSubstituteDetails] = useState({})
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    customerFilter: Object.keys(CustomerFilter)[0],
    filterText: '',
  })

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => r?.paddedSerialNumber,
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    { label: localize('customerName'), template: r => r?.customerFullName },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.paddedCustomerAccountNumber,
    },
    {
      label: localize('identityCardNumber'),
      template: r => r?.customerIndividualIdentityCardNumber,
    },
    {
      label: localize('AddressAddressLine1'),
      template: r => r?.customerAddressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.customerAddressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.customerAddressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.customerAddressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.customerAddressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r?.customerAddressEmail,
    },
    {
      label: localize('landline'),
      template: r => r?.customerAddressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('duty') + ' ' + localize('workStation'),
      template: r => r?.customerDutyStationDescription,
    },
    {
      label: localize('employer'),
      template: r => r?.customerEmployerDescription,
    },
    { label: localize('remarks'), template: r => r?.remarks },
    { label: localize('modifiedBy'), template: r => r?.modifiedBy },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchGuarantorSubstitutionRequests = async (track = true) => {
    setIsBusy(true)
    const url =
      '/LoanGuarantorSubstitutionRequest/find-loan-guarantor-substitution-requests-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false, track)

    if (success) {
      setIsBusy(false)
      setGuarantorSubstitutionRequests(data)
    } else {
      setIsBusy(false)
    }
  }

  const fetchLoansGuaranteed = async () => {
    setIsBusy(true)
    const url = '/LoanCase/find-loan-guarantors-by-loanee-customer-id'

    const { success, data } = await getData(
      url,
      {
        loaneeCustomerId: customer?.id,
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      setLoansGuaranteed(data?.result)
    }

    setIsBusy(false)
  }

  const fetchLoanGuarantorSubstitutionRequestEntries = async () => {
    const url =
      '/LoanGuarantorSubstitutionRequest/find-loan-guarantor-substitution-request-entries-by-loan-guarantor-substitution-request-id'
    const { success, data } = await getData(
      url,
      {
        loanGuarantorSubstitutionRequestId: guarantorSubstitutionRequest?.id,
      },
      false
    )

    if (success) {
      setSelectedGuarantorSubstitutes(data?.result)
    }
  }

  const handleClickAddButton = () => {
    setMode('add')
    setGuarantorSubstitutionRequest({})
    setLoansGuaranteed([])
    setSelectedLoans([])
    setSubstituteDetails({})
    setSelectedGuarantorSubstitutes([])
    setSelectedSubstitutes([])
    setCustomer({})
    setSubstitutionAction(
      Object.keys(loanGuarantorSubstitutionRegistrationOption)[0]
    )
  }

  const handleClose = () => {
    setIsBusyUpdating(false)
    setMode(null)
    setGuarantorSubstitutionRequest({})
    setCustomer({})
    setSelectedLoans([])
    setLoansGuaranteed([])
    setSubstituteDetails({})
    setSubstitutionAction(
      Object.keys(loanGuarantorSubstitutionRegistrationOption)[0]
    )
  }

  const handleSelectLoan = (loan, value) => {
    if (value) {
      setSelectedLoans([...selectedLoans, loan])
    } else {
      setSelectedLoans(selectedLoans.filter(x => x.id !== loan.id))
    }
  }

  const handleClickContextMenu = (r, action) => {
    if (
      r?.status !== Object.keys(loanGuarantorSubstitutionRequestStatus)[1] &&
      action === 'edit'
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Only deferred requests can be edited',
      })

    setMode(action)
    setGuarantorSubstitutionRequest(r)

    if (action === 'edit') {
      setCustomer({
        id: r?.customerId,
        fullName: r?.customerFullName,
        membershipClassCustomerTypeDescription:
          r?.customerMembershipClassCustomerTypeDescription,
        paddedSerialNumber: r?.customerPaddedSerialNumber,
        employerDescription: r?.customerEmployerDescription,
        identificationNumber: r?.customerIdentificationNumber,
        reference2: r?.customerReference2,
        reference3: r?.customerReference3,
        reference1: r?.customerReference1,
        remarks: r?.remarks,
      })
      setSubstitutionAction(r?.status)
    }
  }

  const handleAddSelectedSubstitutes = () => {
    if (!substituteDetails?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a substitute customer first',
      })

    if (!selectedLoans?.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one loan',
      })

    const newSubstitutes = selectedLoans.map(x => ({
      id: guarantorSubstitutionRequest?.id,
      loanGuarantorSubstitutionRequestId:
        guarantorSubstitutionRequest?.id || generateGuid(),
      loanGuarantorId: x?.id,
      loanGuarantorLoanCaseDocumentReferenceNumber:
        x?.loanCaseDocumentReferenceNumber,
      loanGuarantorLoanCaseStatus: x?.loanCaseStatus,
      loanGuarantorLoanCaseStatusDescription: x?.statusDescription,
      loanGuarantorLoanProductDescription: x?.loanProductDescription,
      loanGuarantorLoanProduct: x?.loanProduct,
      loanGuarantorLoanCaseLoanPurposeDescription:
        x?.loanCaseLoanPurposeDescription,
      maximumGuarantees: x?.loanProductLoanRegistrationMaximumGuarantees,
      currentGuarantees: guarantorSubstitutes.filter(
        y => y?.status === 1 && y?.loanProductLoanRegistrationLoanProductSection
      )?.length,
      substituteCustomerFullName: substituteDetails?.fullName,
      substituteCustomerMembershipClassCustomerType:
        substituteDetails?.membershipClassCustomerType,
      formattedSubstituteCustomerMemberNumber:
        substituteDetails?.formattedMemberNumber,
      paddedSubstituteCustomerAccountNumber:
        substituteDetails?.paddedAccountNumber,
      substituteCustomerMembershipClassNumberingSeriesPrefix:
        substituteDetails?.membershipClassNumberingSeriesPrefix,
      substituteCustomerIndividualSalutation:
        substituteDetails?.individualSalutation,
      substituteCustomerIndividualFirstName:
        substituteDetails?.individualFirstName,
      substituteCustomerIndividualLastName:
        substituteDetails?.individualLastName,
      substituteCustomerIndividualIdentityCardNumber:
        substituteDetails?.individualIdentityCardNumber,
      substituteCustomerNonIndividualDescription:
        substituteDetails?.nonIndividualDescription,
      substituteCustomerNonIndividualRegistrationNumber:
        substituteDetails?.nonIndividualRegistrationNumber,
      substituteCustomerMemberNumber: substituteDetails?.memberNumber,
      substituteCustomerAccountNumber: substituteDetails?.accountNumber,
      substituteCustomerAddressAddressLine1:
        substituteDetails?.addressAddressLine1,
      substituteCustomerAddressAddressLine2:
        substituteDetails?.addressAddressLine2,
      substituteCustomerAddressStreet: substituteDetails?.addressStreet,
      substituteCustomerAddressPostalCode: substituteDetails?.addressPostalCode,
      substituteCustomerAddressCity: substituteDetails?.addressCity,
      substituteCustomerAddressEmail: substituteDetails?.addressEmail,
      substituteCustomerAddressLandLine: substituteDetails?.addressLandLine,
      substituteCustomerAddressMobileLine: substituteDetails?.addressMobileLine,
      substituteCustomerDutyStationDescription:
        substituteDetails?.dutyStationDescription,
      substituteCustomerEmployerDescription:
        substituteDetails?.employerDescription,
      substituteCustomerId: substituteDetails?.id,
      substituteCustomerBranchId: substituteDetails?.branchId,
    }))

    setSelectedGuarantorSubstitutes([
      ...guarantorSubstitutes,
      ...newSubstitutes,
    ])
    setSelectedLoans([])
    setSubstituteDetails({})
    setSelectedSubstitutes([])
  }

  const handleRemoveSelectedSubstitute = () => {
    if (selectedSubstitutes?.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one substitute',
      })

    setSelectedGuarantorSubstitutes(
      guarantorSubstitutes.filter(
        x =>
          !selectedSubstitutes.some(
            y => y?.loanGuarantorId === x?.loanGuarantorId
          )
      )
    )
    setSelectedSubstitutes([])
  }

  const handleSelectGuarantorSubstitute = (substitute, value) => {
    if (value) {
      setSelectedSubstitutes([...selectedSubstitutes, substitute])
    } else {
      setSelectedSubstitutes(
        selectedSubstitutes.filter(
          x => x?.loanGuarantorId !== substitute?.loanGuarantorId
        )
      )
    }
  }

  const handleCreateOrUpdate = async () => {
    if (!customer?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    if (!customer?.remarks)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please enter remarks',
      })

    if (!guarantorSubstitutes?.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one substitute',
      })

    const params = {
      loanGuarantorSubstitutionRequest: {
        ...customer,
        customerId: customer?.id,
      },
      loanGuarantorSubstitutionRequestEntryCollection:
        mode === 'add'
          ? guarantorSubstitutes
          : guarantorSubstitutes.map(x => ({
              ...x,
              id: guarantorSubstitutionRequest?.id,
              loanGuarantorSubstitutionRequestId:
                guarantorSubstitutionRequest?.id,
            })),
      loanGuarantorSubstitutionRegistrationOption: substitutionAction,
    }
    const url =
      mode === 'add'
        ? '/LoanGuarantorSubstitutionRequest/create-loan-guarantor-substitution-request'
        : '/LoanGuarantorSubstitutionRequest/update-loan-guarantor-substitution-request'

    setIsBusyUpdating(true)

    const { success } = await (mode === 'add'
      ? postData(
          url,
          params,

          false
        )
      : putData(url, params, false))

    if (success) {
      handleClose()
      fetchGuarantorSubstitutionRequests(false)
      showNotification('Loan Guarantor Substitution ', 'success')
    }

    setIsBusyUpdating(false)
  }

  useEffect(() => {
    fetchGuarantorSubstitutionRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (customer?.id) fetchLoansGuaranteed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  useEffect(() => {
    if (guarantorSubstitutionRequest?.id)
      fetchLoanGuarantorSubstitutionRequestEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guarantorSubstitutionRequest?.id])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreateSubstitution
          mode={mode}
          handleClose={handleClose}
          customer={customer}
          setCustomer={setCustomer}
          loansGuaranteed={loansGuaranteed}
          handleSelectLoan={handleSelectLoan}
          selectedLoans={selectedLoans}
          substituteDetails={substituteDetails}
          setSubstituteDetails={setSubstituteDetails}
          substitutionAction={substitutionAction}
          setSubstitutionAction={setSubstitutionAction}
          handleAdd={handleAddSelectedSubstitutes}
          selectedSubstitutes={guarantorSubstitutes}
          handleRemoveSelectedSubstitute={handleRemoveSelectedSubstitute}
          handleSelectGuarantorSubstitute={handleSelectGuarantorSubstitute}
          selectedGuarantorSubstitutes={selectedSubstitutes}
          handleAction={handleCreateOrUpdate}
          isBusyUpdating={isBusyUpdating}
        />
      )}

      {mode === 'view-audit-log' && (
        <ViewAuditLogs
          data={guarantorSubstitutionRequest}
          handleClose={handleClose}
          mode={mode}
          title={`${localize('guarantorSubstitutionRequest')} -> ${
            guarantorSubstitutionRequest?.customerFullName
          }`}
        />
      )}

      {mode === 'view' && (
        <ViewSubstitutes
          guarantorSubstitutionRequestId={guarantorSubstitutionRequest?.id}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('guarantorSubstitutionRequests'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-md-3">
                  <div className="d-flex align-items-center justify-content-start">
                    <AddNewButton handleClickAddButton={handleClickAddButton} />
                  </div>
                </div>
              </div>

              <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
                <div className="d-flex align-items-center col-md-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>

                <div className="col-md-4 d-flex align-items-center">
                  <label htmlFor="search" className="col-3 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams.startDate,
                      endDate: reqParams.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>

                <div className="col-md-5">
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <label htmlFor="search" className="text-capitalize ms-2">
                      {localize('search')}
                    </label>

                    <div className="d-flex align-items-center justify-content-end">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                          })
                        }}
                        value={reqParams.customerFilter}
                        sort
                      />

                      <input
                        type="search"
                        id="search"
                        className="form-control ms-2"
                        value={reqParams.filterText}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                            pageIndex: 0,
                          })
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchGuarantorSubstitutionRequests()
                          }
                        }}
                      />
                      <button
                        id="FilterButton"
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={e => {
                          fetchGuarantorSubstitutionRequests()
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchGuarantorSubstitutionRequests()
                          }
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <SimpleTable
                data={guarantorSubstitutionRequests?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleClickContextMenu(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('viewSubstitutes')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleClickContextMenu(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleClickContextMenu(r, 'view-audit-log')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={guarantorSubstitutionRequests?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Substitution
