/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import { CheckOffDataPeriodLookup } from '../../../../../Components/Lookup/CheckOffDataPeriodLookup'
import { getData } from '../../../../../Helpers/webApi'
import { formatCurrency } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'

const Catalogue = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    dataAttachmentPeriodId: null,
  })
  const [search, setSearch] = useState('')
  const [dataProcessingPeriodModal, setDataProcessingPeriodModal] =
    useState(null)
  const [data, setData] = useState([])

  useEffect(() => {
    if (reqParams.dataAttachmentPeriodId !== null) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DataAttachmentPeriod/find-data-attachment-entries-by-data-attachment-period-id-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
      setData([])
    }
  }
  const columns = [
    {
      label: localize('entryType'),
      template: r =>
        r.customerAccountCustomerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('employerName'),
      template: r => r.customerAccountCustomerEmployerDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r.fullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r => r.productDescription,
    },
    {
      label: localize('transactionType'),
      template: r => r.transactionTypeDescription,
    },
    {
      label: localize('sequenceNumber'),
      template: r => r.sequenceNumber,
    },
    {
      label: localize('newAmount'),
      template: r => formatCurrency(r.newAmount),
    },
    {
      label: localize('currentAmount'),
      template: r => formatCurrency(r.currentAmount),
    },
    {
      label: localize('newBalance'),
      template: r => formatCurrency(r.newBalance),
    },
    {
      label: localize('currentBalance'),
      template: r => formatCurrency(r.currentBalance),
    },
    {
      label: localize('newAbility'),
      template: r => formatCurrency(r.newAbility),
    },
    {
      label: localize('currentAbility'),
      template: r => formatCurrency(r.currentAbility),
    },
    {
      label: localize('referenceNumber'),
      template: r => r.reference,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('modifiedBy'),
      template: r => r.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => r.modifiedDate,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => r.formattedCreatedDate,
    },
  ]
  return (
    <>
      <BreadCrumb
        tree={[localize('checkOffDataProcessing'), localize('catalogue')]}
      />
      <div className="card bg-white">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-1">
                <div className="col-6 d-flex align-items-center mb-4">
                  <label className="text-capitalize me-3">
                    {localize('dataPeriod')}
                  </label>
                  <CheckOffDataPeriodLookup
                    displayValue={
                      dataProcessingPeriodModal?.postingPeriodDescription
                    }
                    onSelect={r => {
                      setReqParams({
                        ...reqParams,
                        dataAttachmentPeriodId: r.id,
                      })
                      setDataProcessingPeriodModal({
                        ...dataProcessingPeriodModal,
                        postingPeriodDescription: r.postingPeriodDescription,
                        creditTypeDescription: r.creditTypeDescription,
                        remarks: r.remarks,
                      })
                    }}
                  />
                </div>
                <div className="col-6 d-flex align-items-center mb-4">
                  <label className="text-capitalize">
                    {localize('creditType')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-1"
                    value={dataProcessingPeriodModal?.creditTypeDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="d-flex">
                  <label className="text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    disabled
                    value={dataProcessingPeriodModal?.remarks}
                  />
                </div>
              </div>
              <div
                className="p-2"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('dataEntries')}
                </div>
                <div className="row mb-3">
                  <div className="col-6 d-flex align-items-center">
                    <label className="text-capitalize">
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>30</option>
                    </select>
                  </div>
                  <div className="col-6 d-flex justify-content-end pe-5">
                    <label className="text-capitalize">
                      {localize('search')}
                    </label>
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({ ...reqParams, text: search })
                      }}
                    >
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-4"
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                  </div>
                </div>
                <div className="row p-2">
                  <SimpleTable columns={columns} data={data?.pageCollection} />
                  <div className="mt-2">
                    <Pager
                      itemsCount={data.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default Catalogue
