/** @format */

import localize from '../../../../Global/Localize'
import { useState } from 'react'
import { CUSTOMER_METADATA_ACTIVE_TABS } from './constants'
import Tab from '../../../Tabs/Tab'
import { CustomerMetadataLoansGuaranteed } from './CustomerMetadataLoansGuaranteed'
import { CustomerMetadataIndividualParticulars } from './CustomerMetadataIndividualParticulars'
import { CustomerMetadataAddress } from './CustomerMetadataAddress'
import { CustomerMetadataSavingsAccount } from './CustomerMetadataSavingsAccount'
import { CustomerMetadataLoanAccounts } from './CustomerMetadataLoanAccounts'
import { CustomerMetadataInvestmentAccounts } from './CustomerMetadataInvestmentAccounts'
import { CustomerMetadataPayrollNumbers } from './CustomerMetadataPayrollNumbers'
import { CustomerMetadataCreditTypes } from './CustomerMetadataCreditTypes'
import { CustomerMetadataReferees } from './CustomerMetadataReferees'
import { CustomerMetadataAssociates } from './CustomerMetadataAssociates'
import { CustomerMetadataBankAccounts } from './CustomerMetadataBankAccounts'
import { formatDate } from '../../../../Helpers/formatters'

export const CustomerMetadata = ({ selectedCustomer }) => {
  const [fetch, setFetch] = useState({
    fetchLoansGuaranteed: false,
    fetchSavingsAccount: false,
    fetchLoanAccounts: false,
    fetchInvestmentAccounts: false,
    fetchPayrollNumbers: false,
    fetchCreditTypes: false,
    fetchReferees: false,
    fetchAssociates: false,
    fetchBankAccounts: false,
  })

  let tabItems = [
    // {
    //   label: localize('specimen'),
    //   tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.SPECIMEN,
    //   item: <CustomerMetadataSpecimen selectedCustomer={selectedCustomer} />,
    // },
    {
      label: localize('loansGuaranteed'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.LOANS_GUARANTEED,
      item: (
        <CustomerMetadataLoansGuaranteed
          doFetchLoansGuaranteed={fetch.fetchLoansGuaranteed}
          selectedCustomer={selectedCustomer}
        />
      ),
    },
    {
      label: localize('individualParticulars'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.INDIVIDUAL_PARTICULARS,
      item: (
        <CustomerMetadataIndividualParticulars
          selectedCustomer={selectedCustomer}
        />
      ),
    },
    {
      label: localize('address'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.ADDRESS,
      item: <CustomerMetadataAddress selectedCustomer={selectedCustomer} />,
    },
    {
      label: localize('savingsAccount'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.SAVINGS_ACCOUNT,
      item: (
        <CustomerMetadataSavingsAccount
          selectedCustomer={selectedCustomer}
          doFetchSavingsAccount={fetch.fetchSavingsAccount}
        />
      ),
    },
    {
      label: localize('loanAccounts'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.LOAN_ACCOUNTS,
      item: (
        <CustomerMetadataLoanAccounts
          selectedCustomer={selectedCustomer}
          doFetchLoanAccounts={fetch.fetchLoanAccounts}
        />
      ),
    },
    {
      label: localize('investmentAccounts'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.INVESTMENT_ACCOUNTS,
      item: (
        <CustomerMetadataInvestmentAccounts
          selectedCustomer={selectedCustomer}
          doFetchInvestmentAccounts={fetch.fetchInvestmentAccounts}
        />
      ),
    },
    {
      label: localize('payrollNumbers'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.PAYROLL_NUMBERS,
      item: (
        <CustomerMetadataPayrollNumbers
          selectedCustomer={selectedCustomer}
          doFetchPayrollNumbers={fetch.fetchPayrollNumbers}
        />
      ),
    },
    {
      label: localize('creditTypes'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.CREDIT_TYPES,
      item: (
        <CustomerMetadataCreditTypes
          selectedCustomer={selectedCustomer}
          doFetchCreditTypes={fetch.fetchCreditTypes}
        />
      ),
    },
    {
      label: localize('referees'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.REFEREES,
      item: (
        <CustomerMetadataReferees
          selectedCustomer={selectedCustomer}
          doFetchReferees={fetch.fetchReferees}
        />
      ),
    },
    {
      label: localize('associates'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.ASSOCIATES,
      item: (
        <CustomerMetadataAssociates
          selectedCustomer={selectedCustomer}
          doFetchAssociates={fetch.fetchAssociates}
        />
      ),
    },
    {
      label: localize('bankAccounts'),
      tabIdentifier: CUSTOMER_METADATA_ACTIVE_TABS.BANK_ACCOUNTS,
      item: (
        <CustomerMetadataBankAccounts
          selectedCustomer={selectedCustomer}
          doFetchBankAccounts={fetch.fetchBankAccounts}
        />
      ),
    },
  ]
  const handleOnNavigate = tab => {
    switch (tab.tabIdentifier) {
      case CUSTOMER_METADATA_ACTIVE_TABS.LOANS_GUARANTEED:
        setFetch({ ...fetch, fetchLoansGuaranteed: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.SAVINGS_ACCOUNT:
        setFetch({ ...fetch, fetchSavingsAccount: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.LOAN_ACCOUNTS:
        setFetch({ ...fetch, fetchLoanAccounts: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.INVESTMENT_ACCOUNTS:
        setFetch({ ...fetch, fetchInvestmentAccounts: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.PAYROLL_NUMBERS:
        setFetch({ ...fetch, fetchPayrollNumbers: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.CREDIT_TYPES:
        setFetch({ ...fetch, fetchCreditTypes: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.REFEREES:
        setFetch({ ...fetch, fetchReferees: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.ASSOCIATES:
        setFetch({ ...fetch, fetchAssociates: true })
        break
      case CUSTOMER_METADATA_ACTIVE_TABS.BANK_ACCOUNTS:
        setFetch({ ...fetch, fetchBankAccounts: true })
        break
      default:
        break
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('membershipClass')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.membershipClassDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.formattedMemberNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('domicileBranch')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.branchDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('businessGroupEmployer')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.employerDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('dividendCode')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.dividendCodeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.personalFileNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('joinDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedCustomer?.registrationDate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('dutyWorkStation')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.dutyStationDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('electoralZone')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.electoralZoneDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('relationshipManager')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.employeeCustomerFullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('membershipStatus')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.membershipStatusDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('adminDivision')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.administrativeDivisionDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('recruitedBy')}
          </label>
          <input className="form-control" disabled />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input className="form-control" disabled />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('membership')}
          </label>
          <input className="form-control" disabled />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input className="form-control" disabled />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab preload tabItems={tabItems} onNavigate={handleOnNavigate} />
        </div>
      </div>
    </>
  )
}
