/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from './../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import EmployeeLookup from '../../../../../../Components/Lookup/EmployeeLookup'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../../Components/Tabs/Tab'
import { getData } from '../../../../../../Helpers/webApi'
import AppraisalPeriodLookup from './../../../../../../Components/Lookup/AppraisalPeriodLookup'
import { formatDate } from './../../../../../../Helpers/formatters'

const AppraisalSummary = () => {
  const [employee, setEmployee] = useState('')
  const [appraisalPeriod, setAppraisalPeriod] = useState('')
  const [appraisalSummary, setAppraisalSummary] = useState([])
  const [recommendation, setRecommendation] = useState('')
  const [reqParams, setReqParams] = useState({
    employeeId: '',
    employeeAppraisalPeriodId: '',
  })

  useEffect(() => {
    loadAppraisalSummary()
    findEmployeeRecommendation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  const loadAppraisalSummary = async () => {
    const url = '/EmployeeAppraisal/find-employee-appraisal-by-period'
    if (reqParams?.employeeId && reqParams?.employeeAppraisalPeriodId) {
      const { success, data } = await getData(url, reqParams, false)
      if (success) {
        setAppraisalSummary(data?.result)
      }
    }
  }

  const findEmployeeRecommendation = async () => {
    const url = '/EmployeeAppraisalPeriod/find-recommendation'

    if (reqParams?.employeeId && reqParams?.employeeAppraisalPeriodId) {
      const { success, data } = await getData(
        url,
        {
          employeeId: employee?.id,
          employeeAppraisalPeriodId: appraisalPeriod?.id,
        },
        false
      )

      if (success) {
        setRecommendation(data?.recommendation)
      }
    }
  }

  const columns = [
    {
      label: localize('description'),
      template: r => <>{r.performanceMeasureDescription}</>,
    },
    {
      label: localize('target'),
      template: r => <>{r.performanceMeasureTarget}</>,
    },
    {
      label: localize('weight'),
      template: r => <>{r.performanceMeasureWeight}</>,
    },
    {
      label: localize('appraiseeAnswer'),
      template: r => <>{r.appraiseeAnswer}</>,
    },
    {
      label: localize('achieved'),
      template: r => <>{r.achieved}</>,
    },
    {
      label: localize('score'),
      template: r => <>{r.score}</>,
    },
    {
      label: localize('appraiserAnswer'),
      template: r => <>{r.appraiserAnswer}</>,
    },
    {
      label: localize('appraiserRemarks'),
      template: r => <>{r.appraiserRemarks}</>,
    },
    {
      label: localize('appraisedBy'),
      template: r => <>{r.appraisedBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('appraisedDate'),
      template: r => <>{formatDate(r.appraisedDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('appraisalSummary'),
      item: (
        <SimpleTable
          columns={columns}
          data={appraisalSummary}
          className="mt-4"
        />
      ),
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResources'),
          localize('performanceManagement'),
          localize('appraisalSummary'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="d-flex row align-items-center mb-5">
            <div className="d-flex col-4 align-items-center">
              <label className="col-4  me-1 text-capitalize">
                {localize('appraisalPeriod')}
              </label>
              <AppraisalPeriodLookup
                displayValue={appraisalPeriod?.description}
                onSelect={e => {
                  setAppraisalPeriod({
                    ...appraisalPeriod,
                    employeeAppraisalPeriodId: e?.id,
                    description: e?.description,
                    durationStartDate: e?.durationStartDate,
                    durationEndDate: e?.durationEndDate,
                  })
                  setReqParams({
                    ...reqParams,
                    employeeAppraisalPeriodId: e?.id,
                  })
                }}
              />
            </div>
            <div className="d-flex col-4 align-items-center">
              <label className="text-capitalize">{localize('startDate')}</label>
              <input
                className="form-control"
                value={formatDate(appraisalPeriod?.durationStartDate)}
              />
            </div>
            <div className="d-flex col-4 align-items-center">
              <label className="text-capitalize">{localize('endDate')}</label>
              <input
                className="form-control"
                value={formatDate(appraisalPeriod?.durationEndDate)}
              />
            </div>
          </div>
          <div
            className="p-2 mt-3"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('employeeDetails')}
            </div>
            <div className="d-flex row mb-3 align-items-center">
              <div className="col-4 d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('employee')}
                </label>
                <EmployeeLookup
                  displayValue={employee?.customerFullName}
                  onSelect={e => {
                    setEmployee({
                      ...employee,
                      employeeId: e.id,
                      customerFullName: e.customerFullName,
                      departmentDescription: e.departmentDescription,
                      branchDescription: e.branchDescription,
                    })
                    setReqParams({
                      ...reqParams,
                      employeeId: e.id,
                    })
                  }}
                />
              </div>
              <div className="d-flex col-4 align-items-center">
                <label className="text-capitalize col-3">
                  {localize('department')}
                </label>
                <input
                  className="form-control"
                  value={employee?.departmentDescription}
                />
              </div>
              <div className="d-flex col-4 align-items-center">
                <label className="text-capitalize col-2">
                  {localize('branch')}
                </label>
                <input
                  className="form-control"
                  value={employee?.branchDescription}
                />
              </div>
            </div>
            <div className="d-flex row mb-3 align-items-center">
              <div className="d-flex col-12">
                <label className="text-capitalize">
                  {localize('overallRecommendation')}
                </label>
                <input className="form-control" value={recommendation} />
              </div>
            </div>
            <div className="mb-2">
              <Tab tabItems={tabItems} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppraisalSummary
