/** @format */

import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { chequeBookType } from '../../../../../../Global/enumeration'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import Loader from '../../../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

const CreateChequeBook = ({
  mode,
  handleClose,
  fetchChequeBooks,
  chequeBookId,
}) => {
  const [chequeBook, setChequeBook] = useState({
    type: Object.keys(chequeBookType)[0],
  })
  const [isBusy, setIsBusy] = useState(false)
  const [customerAccount, setCustomerAccount] = useState({})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const fetchChequeBook = async () => {
    setIsBusy(true)

    const url = '/ChequeBook/find-chequebook-by-id'

    const { success, data } = await getData(url, { chequeBookId }, false)

    if (success) {
      setChequeBook(data)
      setIsBusy(false)
      setCustomerAccount({
        id: data.customerAccountId,
      })
    }

    setIsBusy(false)
  }

  const handleLookupCustomerAccount = customerAccount => {
    setCustomerAccount(customerAccount)
  }

  const handleCreateOrUpdateChequeBook = async () => {
    if (
      !chequeBook?.reference ||
      !chequeBook?.remarks ||
      !customerAccount?.id
    ) {
      return GlobalValidationErrors.set({
        Reference: !chequeBook?.reference && {
          message: 'Reference is required',
        },

        Remarks: !chequeBook?.remarks && {
          message: 'Remarks is required',
        },

        CustomerAccount: !customerAccount?.id && {
          message: 'Customer Account is required',
        },
      })
    }

    const url =
      mode === 'add'
        ? '/ChequeBook/create-cheque-book?moduleNavigationItemCode=1000'
        : '/ChequeBook/update-cheque-book'

    setIsBusy(true)

    const { success } =
      mode === 'add'
        ? await postData(
            url,
            {
              ...chequeBook,
              ...customerAccount,
              customerAccountId: customerAccount?.id,
            },
            false
          )
        : await putData(url, chequeBook, false)

    if (success) {
      setIsBusy(false)
      setCustomerAccount({})
      setChequeBook({
        type: Object.keys(chequeBookType)[0],
      })
      fetchChequeBooks()
      handleClose()
      showNotification(
        `
      ${mode === 'add' ? 'Create Cheque Book' : 'Update Cheque Book'}
      `,
        'success'
      )
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (mode === 'edit' && chequeBookId) fetchChequeBook()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chequeBookId, mode])

  return (
    <ModalTemplate
      handleClose={() => {
        handleClose()
        setCustomerAccount({})
      }}
      modalMode={mode}
      modalTitle={mode === 'add' ? 'createChequeBook' : 'editChequeBook'}
      modalClassName="modal-xl"
      handleAction={handleCreateOrUpdateChequeBook}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        mode === 'edit' && (
          <div className="d-flex align-items-center justify-content-between gap-4">
            <div className="d-flex align-items-center">
              <Checkbox
                id="checkBookIsActive"
                checked={chequeBook?.isActive}
                onCheck={value => {
                  setChequeBook({
                    ...chequeBook,
                    isActive: value,
                  })
                }}
                disabled={isBusy}
              />

              <label
                className="text-capitalize ms-2"
                htmlFor="checkBookIsActive"
              >
                {localize('isActive') + ' ?'}
              </label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                id="checkBookIsLocked"
                checked={chequeBook?.isLocked}
                onCheck={value => {
                  setChequeBook({
                    ...chequeBook,
                    isLocked: value,
                  })
                }}
                disabled={isBusy}
              />

              <label
                className="text-capitalize ms-2"
                htmlFor="checkBookIsLocked"
              >
                {localize('chequeBookIsLocked') + ' ?'}
              </label>
            </div>
          </div>
        )
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            customer={{
              id: customerAccount?.id,
            }}
            refreshFromServer
            onCustomerLookup={handleLookupCustomerAccount}
            message={globalValidationErrors?.['CustomerAccount']?.message}
            onClearValidation={() => {
              clearValidation('CustomerAccount')
            }}
            readOnly={mode === 'edit'}
          />

          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('chequeBookDetails')}
            </div>

            <div className="row g-2">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('type')}
                  </label>

                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    disabled={mode === 'edit'}
                    value={chequeBook?.type}
                    enumsKey="ChequeBookType"
                    onChange={({ value }) =>
                      setChequeBook({
                        ...chequeBook,
                        type: value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors?.['Reference']?.message
                  }
                >
                  <label className="col-2 text-capitalize">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={chequeBook?.reference}
                    disabled={mode === 'edit'}
                    onChange={({ target }) => {
                      clearValidation('Reference')
                      setChequeBook({
                        ...chequeBook,
                        reference: target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('initialVoucherNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={chequeBook?.initialVoucherNumber}
                    disabled={mode === 'edit'}
                    onChange={({ target }) =>
                      setChequeBook({
                        ...chequeBook,
                        initialVoucherNumber: target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('numberOfVouchers')}
                  </label>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={chequeBook?.numberOfVouchers}
                    disabled={mode === 'edit'}
                    onChange={({ target }) =>
                      setChequeBook({
                        ...chequeBook,
                        numberOfVouchers: target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors?.['Remarks']?.message
                  }
                >
                  <label className="col-1 text-capitalize">
                    {localize('remarks')}
                  </label>
                  <textarea
                    className="form-control"
                    value={chequeBook?.remarks}
                    onChange={({ target }) => {
                      clearValidation('Remarks')
                      setChequeBook({
                        ...chequeBook,
                        remarks: target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateChequeBook
