/** @format */

import localize from '../../../../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../../../../Components/Tables/SimpleTable'

export const CustomerAccountPreviewUnclearedCheques = ({
  unclearedCheques,
  loading,
}) => {
  const columns = [
    { label: localize('number'), template: r => <>{r.number}</> },
    { label: localize('amount'), template: r => <>{r.amount}</> },
    { label: localize('drawer'), template: r => <>{r.drawer}</> },
    { label: localize('drawerBank'), template: r => <>{r.drawerBank}</> },
    {
      label: localize('drawerBankBranch'),
      template: r => <>{r.drawerBankBranch}</>,
    },
    {
      label: localize('writeDate'),
      template: r => <>{formatDate(r.writeDate)}</>,
    },
    {
      label: localize('chequeTypeDescription'),
      template: r => <>{r.chequeTypeDescription}</>,
    },
    {
      label: localize('maturityDate'),
      template: r => <>{formatDate(r.maturityDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]
  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={unclearedCheques} />
      )}
    </>
  )
}
