/** @format */

import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Tab from '../../../../Components/Tabs/Tab'
import { AddChargeSplit } from './Partials/AddChargeSplit'
import { AddTiers } from './Partials/AddTiers'
import { ChargeLevies } from './Partials/ChargeLevies'
import React from 'react'

export const EditChargesModal = ({
  selectedCharge,
  setSelectedCharge,
  isBusy,
  waitingMetadata,
  mode,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['Commission.Description']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                defaultValue={selectedCharge?.description}
                className="form-control"
                onChange={e => {
                  clearValidation('Commission.Description')
                  setSelectedCharge({
                    ...selectedCharge,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('maximumCharge')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedCharge?.maximumCharge}
                onMaskNumber={e =>
                  setSelectedCharge({
                    ...selectedCharge,
                    maximumCharge: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('roundingType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="RoundingType"
                value={selectedCharge?.roundingType}
                onChange={e => {
                  setSelectedCharge({
                    ...selectedCharge,
                    roundingType: e.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                preload
                tabItems={[
                  {
                    label: localize('chargeSplitting'),
                    item: (
                      <AddChargeSplit
                        mode={mode}
                        selectedCharge={selectedCharge}
                        setSelectedCharge={setSelectedCharge}
                        waitingMetadata={waitingMetadata}
                      />
                    ),
                  },
                  {
                    label: localize('tiers'),
                    item: (
                      <AddTiers
                        mode={mode}
                        selectedCharge={selectedCharge}
                        setSelectedCharge={setSelectedCharge}
                        waitingMetadata={waitingMetadata}
                      />
                    ),
                  },
                  {
                    label: localize('levies'),
                    item: (
                      <ChargeLevies
                        setSelectedCharge={setSelectedCharge}
                        selectedCharge={selectedCharge}
                        isBusy={waitingMetadata}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
