/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _s = {
  salary: {
    en: 'salary',
    sw: 'mshahara',
  },
  setup: { en: 'setup', sw: 'Ufunguaji' },
  systemAdministration: {
    en: 'system administration',
    sw: 'utawala wa mfumo',
  },
  summary: {
    en: 'summary',
    sw: 'muhtasari',
  },
  selfAppraisal: {
    en: 'self appraisal',
    sw: 'Kujitathmini',
  },
  search: {
    en: 'search',
    sw: 'Tafuta',
  },
  shortageChartOfAccountName: {
    en: 'shortage g/l account name',
    sw: 'Chati ya uhaba wa jina la akaunti',
  },
  shortageChartOfAccountCostDistributionRuleDescription: {
    en: 'shortage g/l cost dist. rule',
    sw: 'chati ya uhaba wa maelezo ya sheria ya usambazaji wa gharama ya akaunti',
  },
  select: {
    en: 'select',
    sw: 'chagua',
  },
  statusDescription: {
    en: 'status description',
    sw: 'maelezo ya hali',
  },
  security: {
    en: 'security',
    sw: 'usalama',
  },
  symbol: {
    en: 'symbol',
    sw: 'ishara',
  },
  systemInitializationTime: {
    en: 'system initialization time',
    sw: 'wakati wa kuanzisha mfumo',
  },
  systemLockTime: {
    en: 'system lock time',
    sw: 'wakati wa kufunga mfumo',
  },
  startDate: {
    en: 'start date',
    sw: 'tarehe ya kuanza',
  },
  serialNumber: {
    en: 'serial number',
    sw: 'nambari ya ufululizo',
  },
  savings: {
    en: 'savings',
    sw: 'akiba',
  },
  standingOrderTriggerDescription: {
    en: 'standing order trigger',
    sw: 'kichochezi cha mpangilio uliosimama',
  },
  savingsProducts: {
    en: 'savings products',
    sw: 'akiba za bidhaa',
  },
  savingProduct: {
    en: 'saving product',
    sw: 'akiba ya bidhaa',
  },
  standingOrderTrigger: {
    en: 'standing order trigger',
    sw: 'kichochezi cha mpangilio uliosimama',
  },
  startingValue: {
    en: 'starting value',
    sw: 'thamani ya kuanzia',
  },
  spreadsheetCellReference: {
    en: 'spreadsheet cell reference',
    sw: 'rejeleo la kisanduku cha lahajedwali',
  },
  systemTransactionCode: {
    en: 'system transaction code',
    sw: 'kanuni ya muamala ya mfumo',
  },
  showing: {
    en: 'Showing',
    sw: 'Inaonyesha vipengele',
  },
  schemes: {
    en: 'schemes',
    sw: 'miradi',
  },
  savingsProduct: {
    en: 'savings product',
    sw: 'bidhaa ya akiba',
  },
  standingOrder: {
    en: 'standing order',
    sw: 'agizo kuu',
  },
  systemGLAccountCode: {
    en: 'system g/l account code',
    sw: 'nambari ya akaunti ya g/l ya mfumo',
  },
  savingsProductLookup: {
    en: 'savings product lookup',
    sw: 'utafutaji wa bidhaa za akiba',
  },
  suppressAuthorization: {
    en: 'suppress authorization',
    sw: 'kandamisha uidhinishaji',
  },
  shortageChartOfAccount: {
    en: 'shortage g/l account',
    sw: 'chati ya uhaba wa akaunti',
  },
  status: {
    en: 'status',
    sw: 'hali',
  },
  suppressNettingOff: {
    en: 'suppress netting off',
    sw: 'kandamiza mitego',
  },
  surveyGroups: {
    en: 'survey groups',
    sw: 'vikundi vya utafiti',
  },
  systemPermissionType: {
    en: 'system permission type',
    sw: 'aina ya ruhusa ya mfumo',
  },
  salutation: {
    en: 'salutation',
    sw: 'salamu',
  },
  specimen: {
    en: 'specimen',
    sw: 'kielelezo',
  },
  signature: {
    en: 'signature',
    sw: 'sahihi',
  },
  savingsAccount: {
    en: 'savings account',
    sw: 'akaunti ya akiba',
  },
  shortageChartOfAccountCostDistributionRule: {
    en: 'shortage g/l account cost distribution rule',
    sw: 'usambazaji wa gharama ya akaunti ya g/l',
  },
  street: {
    en: 'street',
    sw: 'barabara',
  },
  settlement: {
    en: 'settlement',
    sw: 'makazi',
  },
  save: {
    en: 'save',
    sw: 'hifadhi',
  },
  savingsAccounts: {
    en: 'savings accounts',
    sw: 'akaunti za akiba',
  },
  size: {
    en: 'size',
    sw: 'ukubwa',
  },
  specimenIdentity: {
    en: 'specimen identity',
    sw: 'kitambulisho cha kielelezo',
  },
  specimenFingerprint: {
    en: 'specimen fingerprint',
    sw: 'alama ya vidole ya kielelezo',
  },
  settlementMode: {
    en: 'settlement mode',
    sw: 'hali ya makazi',
  },
  settledBy: {
    en: 'settled by',
    sw: 'imewekwa na',
  },
  settlementRemarks: {
    en: 'settlement remarks',
    sw: 'maoni ya makazi',
  },
  source: {
    en: 'source',
    sw: 'chanzo',
  },
  singleDestination: {
    en: 'single destination',
    sw: 'fikio moja',
  },
  sender: {
    en: 'sender',
    sw: 'mtumaji',
  },
  sendDate: {
    en: 'send date',
    sw: 'tarehe ya kutuma',
  },
  suffix: {
    en: 'suffix',
    sw: 'kiambishi',
  },
  surname: {
    en: 'surname',
    sw: 'jina la ukoo',
  },
  settledRemarks: {
    en: 'settled remarks',
    sw: 'maoni yaliyowekwa',
  },
  settlementType: {
    en: 'settlement type',
    sw: 'aina ya makazi',
  },
  settledDate: {
    en: 'settled date',
    sw: 'tarehe iliyowekwa',
  },
  settlementBatchNumber: {
    en: 'settlement batch number',
    sw: 'nambari ya kundi la makazi',
  },
  selectMode: {
    en: 'select mode',
    sw: 'hali ya kuchagua',
  },
  single: {
    en: 'single',
    sw: 'moja',
  },
  signatureDate: {
    en: 'signature date',
    sw: 'tarehe ya sahihi',
  },
  signatories: {
    en: 'signatories',
    sw: 'saini',
  },
  spouseDetails: {
    en: 'spouse details',
    sw: 'maelezo ya mke/mume',
  },
  systemTransactions: {
    en: 'system transactions',
    sw: 'muamala ya mfumo',
  },
  systemTransactionsArchived: {
    en: 'system transactions (archived)',
    sw: 'shughuli za mfumo (zilizohifadhiwa)',
  },
  standingOrderExecution: {
    en: 'standing order execution',
    sw: 'utekelezaji wa amri',
  },
  supplements: {
    en: 'supplements',
    sw: 'virutubisho',
  },
  secondaryDescription: {
    en: 'secondary description',
    sw: 'maelezo ya sekondari',
  },
  saveCustomer: {
    en: 'save customer',
    sw: 'hifadhi mteja',
  },
  setups: {
    en: 'setups',
    sw: 'seti',
  },
  systemTransaction: {
    en: 'system transaction',
    sw: 'shughuli za mfumo',
  },
  systemAccountTransactionsArchived: {
    en: 'system account transactions (archived)',
    sw: 'shughuli za akaunti ya mfumo (zilizohifadhiwa)',
  },
  startTime: {
    en: 'start time',
    sw: 'wakati wa kuanza',
  },
  selectAll: {
    en: 'select all',
    sw: 'chagua yote',
  },
  senderType: {
    en: 'sender type',
    sw: 'aina ya mtumaji',
  },
  spreadSheetCellReference: {
    en: 'spreadsheet cell reference',
    sw: 'rejeleo la seli',
  },
  standingOrderTriggers: {
    en: 'standing order triggers',
    sw: 'sababisho za amri ya kusimama',
  },
  standingOrderPrincipal: {
    en: 'standing order principal',
    sw: 'amri ya msingi ya kusimama',
  },
  suppressAccountAlerts: {
    en: 'suppress account alerts',
    sw: 'kandamiza tahadhari za akaunti',
  },
  suppressMinimumBalanceValidation: {
    en: 'suppress minimum balance validation',
    sw: 'kandamiza uthibitisho wa kiwango cha chini cha usawa',
  },
  suppressGracePeriodValidation: {
    en: 'suppress grace period validation',
    sw: 'kandamiza uthibitisho wa kipindi cha neema',
  },
  suppressMultiPayoutRecovery: {
    en: 'suppress multi payout recovery',
    sw: 'kandamiza urejeshaji wa malipo mengi',
  },
  sourceBudget: {
    en: 'source budget',
    sw: 'bajeti ya chanzo',
  },
  shortage: {
    en: 'shortage',
    sw: 'uhaba',
  },
  standingOrderInterest: {
    en: 'standing order interest',
    sw: 'amri ya riba',
  },
  spooledMonthlyAccountBalances: {
    en: 'spooled monthly account balances',
    sw: 'balansi za akaunti za kila mwezi zilizopangwa',
  },
  september: {
    en: 'september',
    sw: 'septemba',
  },
  standingOrderExecutionHistory: {
    en: 'standing order execution history',
    sw: 'historia ya utekelezaji wa amri',
  },
  standingOrders: {
    en: 'standing orders',
    sw: 'maagizo ya makuu',
  },
  systemTransactionsJournal: {
    en: 'system transactions journal',
    sw: 'jarida la shughuli za mfumo',
  },
  substitution: {
    en: 'substitution',
    sw: 'badala',
  },
  settlementBatchOrigination: {
    en: 'settlement batch origination',
    sw: 'asili ya kundi la makazi',
  },
  settlementBatchVerification: {
    en: 'settlement batch verification',
    sw: 'uthibitisho wa kundi la makazi',
  },
  settlementBatchAuthorization: {
    en: 'settlement batch authorization',
    sw: 'idhini ya kundi la makazi',
  },
  submission: {
    en: 'submission',
    sw: 'kuwasilisha',
  },
  securityQualification: {
    en: 'security qualification',
    sw: 'ustahiki wa usalama',
  },
  systemRecommendation: {
    en: 'system recommendation',
    sw: 'mapendekezo ya mfumo',
  },
  systemRemarks: {
    en: 'system remarks',
    sw: 'maoni ya mfumo',
  },
  'savingsAccounts(consolidate)': {
    en: 'savings accounts (consolidate)',
    sw: 'akaunti za akiba (ujumuishaji)',
  },
  sequenceNumber: {
    en: 'sequence number',
    sw: 'nambari ya mfululizo',
  },
  stakeholders: {
    en: 'stakeholders',
    sw: 'wadau',
  },
  stakeholderType: {
    en: 'stakeholder type',
    sw: 'aina ya wadau',
  },
  'sacco(AcountNPA)': {
    en: 'SACCO (account NPA)',
    sw: 'SACCO (akaunti NPA)',
  },
  'sacco(AcountPerforming)': {
    en: 'SACCO (account performing)',
    sw: 'SACCO (akaunti inayofanya)',
  },
  'sacco(AcountNPAHistory)': {
    en: 'SACCO (account NPA history)',
    sw: 'SACCO (historia ya akaunti NPA)',
  },
  systemAppraisalRemarks: {
    en: 'system appraisal remarks',
    sw: 'maoni ya tathmini ya mfumo',
  },
  systemAppraisedAmount: {
    en: 'system appraised amount',
    sw: 'kiasi kilichokadiriwa na mfumo',
  },
  substituteDetails: {
    en: 'substitute details',
    sw: 'maelezo ya badala',
  },
  substitutes: {
    en: 'substitutes',
    sw: 'badala ya',
  },
  startingBalance: {
    en: 'starting balance',
    sw: 'baki ya kuanzia',
  },
  substituteCustomerAddressLandLine: {
    en: 'substitute customer address land line',
    sw: 'mstahiki wa anwani ya mteja',
  },
  'savingsProducts(benefactor)': {
    en: 'savings products (benefactor)',
    sw: 'bidhaa za akiba (mstahiki)',
  },
  sendRetry: {
    en: 'send retry',
    sw: 'jaribu kutuma tena',
  },
  shortCode: {
    en: 'short code',
    sw: 'msimbo',
  },
  surveys: {
    en: 'surveys',
    sw: 'utafiti',
  },
  standingOrderExecutionMonths: {
    en: 'standing order execution months',
    sw: 'utekelezaji wa amri(miezi)',
  },
  strictlySelfGuarantee: {
    en: 'strictly self guarantee',
    sw: 'dhamana ya kibinafsi',
  },
  statementDetails: {
    en: 'statement details',
    sw: 'maelezo ya taarifa',
  },
  statementType: {
    en: 'statement type',
    sw: 'aina ya taarifa',
  },
  statement: {
    en: 'statement',
    sw: 'taarifa',
  },
  ssrsHost: {
    en: 'ssrs host',
    sw: ' mmiliki wa ssrs',
  },
  ssrsPort: {
    en: 'ssrs port',
    sw: 'kituo cha ssrs',
  },
  surveyEntry: {
    en: 'survey entry',
    sw: 'ingizo la utafiti',
  },
  'savingsReceipts/Payments': {
    en: 'savings receipts/payments',
    sw: 'stakabadhi za akiba/malipo',
  },
  sundryReceiptsPayments: {
    en: 'sundry receipts/payments',
    sw: 'malipo ya stakabadhi mbalimbali',
  },
  sundryPayments: {
    en: 'sundry payments',
    sw: 'malipo mbalimbali',
  },
  signingInstructions: {
    en: 'signing instructions',
    sw: 'maagizo ya kusaini',
  },
  standingOrderDurationStartDate: {
    en: 'standing order duration start date',
    sw: 'tarehe ya kuanza kipindi cha agizo la kusimama',
  },
  standingOrderDurationEndDate: {
    en: 'standing order duration end date',
    sw: 'tarehe ya mwisho ya kipindi cha agizo la kusimama',
  },
  subject: {
    en: 'subject',
    sw: 'mada',
  },
  superSaverPayables: {
    en: 'super saver payables',
    sw: 'malipo ya kuhifadhi kwa wingi',
  },
  sundryPayment: {
    en: 'sundry payment',
    sw: 'malipo mbalimbali',
  },
  'spouse/ClaimantName': {
    en: 'spouse/claimant name',
    sw: 'jina la mke/mume/mteja',
  },
  'spouse/ClaimantNumber': {
    en: 'spouse/claimant number',
    sw: 'nambari ya mke/mume/mteja',
  },
  'spouse/ClaimantIdentityNumber': {
    en: 'spouse/claimant identity number',
    sw: 'nambari ya kitambulisho cha mke/mume/mteja',
  },
  selectedAccountStatement: {
    en: 'selected account statement',
    sw: 'taarifa ya akaunti iliyochaguliwa',
  },
  signatoryDetails: {
    en: 'signatory details',
    sw: 'maelezo ya saini',
  },
  salaryHistory: {
    en: 'salary history',
    sw: 'historia ya mshahara',
  },
  sourceOfFunds: {
    en: 'source of funds',
    sw: 'chanzo cha fedha',
  },
  sourceOfFund: {
    en: 'source of fund',
    sw: 'chanzo cha fedha',
  },
  salaryPeriods: {
    en: 'salary periods',
    sw: 'vipindi vya mshahara',
  },
  supplierName: {
    en: 'supplier name',
    sw: 'jina la muuzaji',
  },
  supplierType: {
    en: 'supplier type',
    sw: 'aina ya muuzaji',
  },
  salaryGroups: {
    en: 'salary groups',
    sw: 'vikundi vya mishahara',
  },
  salaryGroupEntryDetails: {
    en: 'salary group entry details',
    sw: 'maelezo ya kuingia kwenye kikundi cha mishahara',
  },
  salaryHead: {
    en: 'salary head',
    sw: 'mkuu wa mshahara',
  },
  salaryHeads: {
    en: 'salary heads',
    sw: 'wakuu wa mishahara',
  },
  salaryHeadLookup: {
    en: 'salary head lookup',
    sw: 'utafutaji wa mkuu wa mshahara',
  },
  salaryGroupEntries: {
    en: 'salary group entries',
    sw: 'maingizo ya kikundi cha mishahara',
  },
  salaryGroup: {
    en: 'salary group',
    sw: 'kikundi cha mishahara',
  },
  salaryCard: {
    en: 'salary card',
    sw: 'kadi ya mshahara',
  },
  salaryCards: {
    en: 'salary cards',
    sw: 'kadi za mshahara',
  },
  salaryCardEntries: {
    en: 'salary card entries',
    sw: 'maingizo ya kadi ya mshahara',
  },
  salaryGroupLookup: {
    en: 'salary group lookup',
    sw: 'utafutaji wa kikundi cha mishahara',
  },
  score: {
    en: 'score',
    sw: 'alama',
  },
  salaryProcessing: {
    en: 'salary processing',
    sw: 'usindikaji wa mshahara',
  },
  salaryPeriod: {
    en: 'salary period',
    sw: 'kipindi cha mshahara',
  },
  salaryPeriodLookup: {
    en: 'salary period lookup',
    sw: 'utafutaji wa kipindi cha mshahara',
  },
  salaryPaySlips: {
    en: 'salary payslips',
    sw: 'stakabadhi za mshahara',
  },
  salaryPaySlip: {
    en: 'salary payslip',
    sw: 'stakabadhi ya mshahara',
  },
  salaryPaySplip: {
    en: 'salary payslip',
    sw: 'stakabadhi ya mshahara',
  },
  salaryPeriodStatus: {
    en: 'salary period status',
    sw: 'hali ya kipindi cha mshahara',
  },
  selfGuarantee: {
    en: 'self guarantee',
    sw: 'dhamana ya kibinafsi',
  },
  scoreThreshold: {
    en: 'score threshold',
    sw: 'kipimo cha alama',
  },
  supplyCategories: {
    en: 'supply categories',
    sw: 'kategoria ya usambazaji',
  },
  supplierTypes: {
    en: 'supplier types',
    sw: 'aina ya muuzaji',
  },
  SupplyCategories: {
    en: 'supply categories',
    sw: 'kategoria ya usambazaji',
  },
  suppliers: {
    en: 'suppliers',
    sw: 'wauzaji',
  },
  showBelowReorderLevel: {
    en: 'show below reorder level',
    sw: 'onyesha chini ya kiwango cha upya',
  },
  sourceDepartment: {
    en: 'source department',
    sw: 'wizara ya chanzo',
  },
  sourceLocation: {
    en: 'source location',
    sw: 'eneo la chanzo',
  },
  supplierTypeLookup: {
    en: 'supplier type lookup',
    sw: 'utafutaji wa aina ya muuzaji',
  },
  samples: {
    en: 'samples',
    sw: 'sampuli',
  },
  services: {
    en: 'services',
    sw: 'huduma',
  },
  supplier: {
    en: 'supplier',
    sw: 'muuzaji',
  },
  sellingPrice: {
    en: 'selling price',
    sw: 'bei ya kuuza',
  },
  statements: {
    en: 'statements',
    sw: 'taarifa',
  },
  supplierMobileNumber: {
    en: 'supplier mobile number',
    sw: 'nambari ya simu ya muuzaji',
  },
  supplierEmail: {
    en: 'supplier email',
    sw: 'barua pepe ya muuzaji',
  },
  supplierLookup: {
    en: 'supplier lookup',
    sw: 'utafutaji wa muuzaji',
  },
  supplierMobileLine: {
    en: 'supplier mobile line',
    sw: 'simu ya muuzaji',
  },
  sentForRefunds: {
    en: 'sent for refunds',
    sw: 'tumwa kwa marejesho',
  },
  stage: {
    en: 'stage',
    sw: 'hatua',
  },
  severity: {
    en: 'severity',
    sw: 'ukali',
  },
  suppressedPermissionDetails: {
    en: 'suppressed permission details',
    sw: 'maelezo ya ruhusa iliyokandamizwa',
  },
  suppressedPermissions: {
    en: 'suppressed permissions',
    sw: 'ruhusa iliyokandamizwa',
  },
  selectedProductDetails: {
    en: 'selected product details',
    sw: 'maelezo ya bidhaa iliyochaguliwa',
  },
  settings: {
    en: 'settings',
    sw: 'mipangilio',
  },
  sequentialNumber: {
    en: 'sequential number',
    sw: 'nambari ya mfululizo',
  },
}

export default _s
