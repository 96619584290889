/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import { employerType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import EditEmployersModal from './EditEmployersModal'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { useEffect } from 'react'
import { getData } from '../../../../Helpers/webApi'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function Employers() {
  const [refresh, setRefresh] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [selectedEmployer, setSelectedEmployer] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableMeta, setTableMeta] = useState({})
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })

  const columns = [
    {
      label: localize('descriptions'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
      sortBy: 'description',
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
      sortBy: 'type',
    },
    {
      label: localize('companyCode'),
      template: r => <>{r?.companyCode}</>,
      sortBy: 'companyCode',
    },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{formatDate(r?.loanClassificationDate, true)}</>,
    },
    {
      label:
        localize('interestArreasGracePeriod') + '(' + localize('days') + ')',
      template: r => <>{r?.interestArrearsGracePeriod}</>,
      sortBy: 'interestArreasGracePeriod',
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r?.addressAddressLine1}</>,
      sortBy: 'addressAddressLine1',
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r?.addressAddressLine2}</>,
      sortBy: 'addressAddressLine2',
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
      sortBy: 'addressStreet',
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('email'),
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('enforceLoanClassificationDate') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.enforceLoanClassificationDate.toString())}
        </span>
      ),
    },
    {
      label: localize('throttleScoring') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.throttleScoring?.toString())}
        </span>
      ),
    },
    {
      label: localize('throttleInternalLoaning') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.throttleInternalLoaning?.toString())}
        </span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  useEffect(() => {
    loadEmployers()
  }, [reqParams])

  const handleClose = () => {
    setModalMode(null)
    setSelectedEmployer(null)
  }

  const loadEmployers = async () => {
    setIsBusy(true)
    setRefresh(!refresh)
    const url = '/employer/find-employers-by-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    setSelectedEmployer({
      type: employerType.HEADER_ENTRY,
    })
    setModalMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('employers')]}
      />
      {modalMode && (
        <EditEmployersModal
          mode={modalMode}
          selectedEmployer={selectedEmployer}
          close={handleClose}
          refresh={() => setRefresh(!refresh)}
        />
      )}
      <div className="card bg-white">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="traverse"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                  <label htmlFor="traverse" className="text-capitalize ms-2">
                    {localize('traverseTree') + '?'}
                  </label>
                </div>
                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <label htmlFor="searchTable" className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control"
                    defaultValue={reqParams?.filterText}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <button
                    id="filterEmployerBtn"
                    type="button"
                    className="btn btn-primary ms-1"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row">
                <SimpleTable
                  columns={columns}
                  data={tableData}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setSelectedEmployer(r)
                          setModalMode('edit')
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        <span className="text-capitalize">
                          {localize('edit')}
                        </span>
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Employers
