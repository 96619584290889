/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { electoralZoneType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CreateElectoralZone from './CreateElectoralZone/CreateElectoralZone'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'

function ElectoralZones() {
  const [modalMode, setModalMode] = useState(null)
  const [selectedZone, setSelectedZone] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [gve, setGve] = GlobalValidationErrors.use()
  const [search, setSearch] = useState('')
  const [tableMeta, setTableMeta] = useState({})
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })

  const columns = [
    {
      label: localize('description'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
    },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(`${r?.isLocked.toString()}`)}
        </span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadElectoralZones()
  }, [reqParams, refresh])

  const handleClickAddButton = () => {
    setSelectedZone({
      type: electoralZoneType.HEADER_ENTRY,
    })
    setModalMode('add')
  }

  const handleClose = () => {
    setModalMode(null)
    setSelectedZone(null)
  }

  function clearValidation(column) {
    setGve(g => {
      delete g[column]
      return g
    })
  }

  async function handlePost() {
    setIsBusy(true)

    const { success } =
      modalMode === 'add'
        ? await postData('/electoralzone/create', selectedZone, false)
        : await putData('/electoralzone/update', selectedZone, false)

    if (success) {
      setModalMode(null)
      setRefresh(!refresh)
      showNotification('Electoral Zones', 'success')
    }

    setIsBusy(false)
  }

  const loadElectoralZones = async () => {
    setIsBusy(true)
    const url = '/electoralzone/find-by-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('electoralZones')]}
      />
      {modalMode && (
        <CreateElectoralZone
          clearValidation={clearValidation}
          gve={gve}
          handleClose={handleClose}
          handlePost={handlePost}
          isBusy={isBusy}
          mode={modalMode}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
        />
      )}
      <div className="card bg-white">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-2">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="traverse"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                  <label htmlFor="traverse" className="text-capitalize ms-2">
                    {localize('traverseTree') + '?'}
                  </label>
                </div>
                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <label htmlFor="searchTable" className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control"
                    defaultValue={reqParams?.filterText}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <button
                    id="filterEmployerBtn"
                    type="button"
                    className="btn btn-primary ms-1"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <SimpleTable
                columns={columns}
                data={tableData}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setSelectedZone(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                  </>
                )}
              />
              <div className="mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ElectoralZones
