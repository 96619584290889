/** @format */

import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import LocationLookup from '../../../../../../Components/Lookup/LocationLookup'
import { generateGuid } from '../../../../../../Helpers/extensions'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import InvoiceEntriesItems from './InvoiceEntriesItems'
import { SupplierLookup } from '../../../../../../Components/Lookup/SupplierLookup'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import PdfFileLookup from '../../../../../../Components/Lookup/PdfFileLookup'
import GoodsReceivedNoteLookup from '../../../../../../Components/Lookup/GoodsReceivedNoteLookup'
import GoodsReceivedNoteItemLookup from '../../../../../../Components/Lookup/GoodsReceivedNoteItemLookup'

const AddInvoice = ({
  edit,
  mode,
  closeModal,
  invoice,
  setInvoice,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState(null)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/Invoice/find-invoices-items-by-invoice-id-filter-in-page`,
      {
        invoiceId: invoice?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [invoice?.id, reqParams.pageSize, reqParams.pageIndex])

  const addEntryToTheDB = async () => {
    if (!entry) {
      showNotification(
        'Invoice',
        'info',
        'Sorry, but you must first select a received notes item!'
      )
    } else {
      if (
        existingEntries.find(
          d => d.goodsReceivedNoteItemId === entry.goodsReceivedNoteItemId
        )
      ) {
        showNotification(
          'Invoice',
          'info',
          'Sorry, but the selected received notes item is already added !'
        )
      } else {
        setIsBusy(true)
        const { success } = await postData(
          '/Invoice/update-invoice-items-by-invoice-id',
          { invoiceId: invoice?.id, invoiceItems: [entry] },
          false
        )
        if (success) {
          showNotification('Invoice', 'success')
          loadEntries()
          setEntry(null)
          setEntry({ quantity: '', unitCost: '' })
        }
        setIsBusy(false)
      }
    }
  }

  async function postinvoice() {
    setIsBusy(true)
    const { success } = await postData(
      '/Invoice/add-new-invoice-with-invoice-items',
      {
        invoice: { ...invoice },
        invoiceItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Invoice', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  async function editinvoice() {
    setIsBusy(true)
    const { success } = await postData(
      '/Invoice/update-new-invoice-with-invoice-items',
      {
        invoice: {
          ...invoice,
          id: invoice.id,
          remarks: invoice?.remarks,
        },
        invoiceItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Invoice', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.invoiceId !== cust?.invoiceId
      )
      setEntries(newEntry)
    }
  }

  async function stageEntry() {
    if (
      entries.find(
        d => d.goodsReceivedNoteItemId === entry.goodsReceivedNoteItemId
      )
    ) {
      showNotification(
        'Invoice',
        'info',
        'Sorry, but the selected received notes item is already added !'
      )
    } else {
      setEntries([
        ...entries,
        {
          invoiceId: invoice?.id,
          itemRegisterItemTypeDescription:
            entry?.itemRegisterItemTypeDescription,
          itemRegisterId: entry?.itemRegisterId,
          goodsReceivedNoteItemId: entry?.goodsReceivedNoteItemId,
          itemRegisterDescription: entry?.itemRegisterDescription,
          itemRegisterInventoryCategoryDescription:
            entry?.itemRegisterInventoryCategoryDescription,
          itemRegisterAssetTypeDescription:
            entry?.itemRegisterAssetTypeDescription,
          itemRegisterInventoryUnitOfMeasureDescription:
            entry?.itemRegisterInventoryUnitOfMeasureDescription,
          quantity: entry?.quantity,
          itemRegisterInventoryCategoryChartOfAccountName:
            entry?.itemRegisterInventoryCategoryChartOfAccountName,
          unitCost: entry?.unitCost,
          totalCost: entry?.totalCost,
          status: entry?.status,
        },
      ])
      setEntry(null)
      setEntry({ quantity: '', unitCost: '' })
    }
  }
  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const closePage = () => {
    setGlobalValidationErrors({})
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={edit ? 'edit' : 'add'}
      modalTitle="invoice"
      handleClose={closePage}
      modalClassName="modal-xl"
      actionText={edit ? 'update' : 'create'}
      handleAction={() => {
        if (mode === 'add') {
          if (!entries) {
            showNotification(
              'Invoice',
              'info',
              'sorry, but there should be at least one  item added to the invoice'
            )
          } else {
            postinvoice()
          }
        } else {
          editinvoice()
        }
      }}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['Invoice.SupplierId']?.message
              }
            >
              <label className="text-capitalize col-md-2">
                {localize('supplier')}
              </label>
              <SupplierLookup
                displayValue={invoice?.supplierDescription}
                onSelect={r => {
                  clearValidation('Invoice.SupplierId')
                  setInvoice({
                    ...invoice,
                    supplierId: r.id,
                    supplierDescription: r.description,
                    supplierAddressMobileLine: r.addressMobileLine,
                    supplierAddressEmail: r.addressEmail,
                    supplierCreditPeriod: r.creditPeriod,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierAddressMobileLine}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierAddressEmail}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('creditPeriod')}
              </label>
              <input
                className="form-control"
                disabled
                value={invoice?.supplierCreditPeriod}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize me-3">
                {localize('invoiceDate')}
              </label>
              <CustomDatePicker
                defaultDate={invoice?.invoiceDate}
                name={'invoiceDate'}
                onDateChange={date => {
                  setInvoice({
                    ...invoice,
                    invoiceDate: date,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['Invoice.InvoiceNumber']?.message
              }
            >
              <label className="col-md-1 text-capitalize">
                {localize('invoice#')}
              </label>
              <input
                className="form-control ms-3"
                onChange={e => {
                  clearValidation('Invoice.InvoiceNumber')
                  setInvoice({
                    ...invoice,
                    invoiceNumber: e.target.value,
                  })
                }}
                value={invoice?.invoiceNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['Invoice.FileName']?.message
              }
            >
              <label className="col-md-2 text-capitalize">
                {localize('document')}
              </label>
              <PdfFileLookup
                displayValue={invoice?.fileName}
                acceptedFiles={['application/pdf']}
                onSelect={r => {
                  clearValidation('Invoice.FileName')
                  setInvoice({
                    ...invoice,
                    invoiceDocument: r.bufferString,
                    fileName: r.fileName,
                    fileExtension: 'pdf',
                  })
                }}
              />
            </div>

            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['Invoice.FileTitle']?.message
              }
            >
              <label className="col-md-1 text-capitalize">
                {localize('title')}
              </label>
              <input
                className="form-control ms-3"
                onChange={e => {
                  clearValidation('Invoice.FileTitle')
                  setInvoice({
                    ...invoice,
                    fileTitle: e.target.value,
                  })
                }}
                value={invoice?.fileTitle || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control ms-3"
                onChange={e =>
                  setInvoice({
                    ...invoice,
                    remarks: e.target.value,
                  })
                }
                value={invoice?.remarks || ''}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['Invoice.FileDescription']?.message
              }
            >
              <label className="text-capitalize me-4">
                {localize('description')}
              </label>
              <textarea
                className="form-control"
                onChange={e => {
                  clearValidation('Invoice.FileDescription')
                  setInvoice({
                    ...invoice,
                    fileDescription: e.target.value,
                  })
                }}
                value={invoice?.fileDescription || ''}
              />
            </div>
          </div>
          <>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('itemDetails')}
              </div>

              <>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize me-3">
                      {localize('noteReference')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: invoice?.supplierId ? 1 : 0.6,
                        pointerEvents: invoice?.supplierId ? 'all' : 'none',
                      }}
                    >
                      <GoodsReceivedNoteLookup
                        displayValue={entry?.paddedReferenceNumber}
                        onSelect={r => {
                          setEntry({
                            ...entry,
                            noteId: r.id,
                            paddedReferenceNumber: r.paddedReferenceNumber,
                          })
                        }}
                        supplierId={invoice?.supplierId}
                        supplierDescription={invoice?.supplierDescription}
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize me-3">
                      {localize('itemDescription')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: entry?.noteId ? 1 : 0.6,
                        pointerEvents: entry?.noteId ? 'all' : 'none',
                      }}
                    >
                      <GoodsReceivedNoteItemLookup
                        displayValue={entry?.itemRegisterDescription}
                        onSelect={r => {
                          setEntry({
                            ...entry,
                            goodsReceivedNoteItemId: r.id,
                            itemRegisterItemTypeDescription:
                              r.itemRegisterItemTypeDescription,
                            itemRegisterId: r.itemRegisterId,
                            itemRegisterDescription: r.itemRegisterDescription,
                            itemRegisterAssetTypeDescription:
                              r.itemRegisterAssetTypeDescription,
                            itemRegisterInventoryCategoryDescription:
                              r.itemRegisterInventoryCategoryDescription,
                            itemRegisterInventoryUnitOfMeasureDescription:
                              r.itemRegisterInventoryUnitOfMeasureDescription,
                            quantity: r.quantity,
                            itemRegisterInventoryCategoryChartOfAccountName:
                              r.itemRegisterInventoryCategoryChartOfAccountName,
                            unitCost: r.unitCost,
                            totalCost: r.totalCost,
                            status: r.status,
                          })
                        }}
                        goodsReceivedNoteId={entry?.noteId}
                        noteRefNumber={entry?.paddedReferenceNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('inventoryCategory')}
                    </label>
                    <input
                      className="form-control ms-3"
                      disabled
                      displayValue={
                        entry?.itemRegisterInventoryCategoryDescription
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-2 text-capitalize">
                      {localize('unitOfMeasure')}
                    </label>
                    <input
                      className="form-control ms-3"
                      disabled
                      displayValue={
                        entry?.itemRegisterInventoryUnitOfMeasureDescription
                      }
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('quantity')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.quantity}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-2 text-capitalize">
                      {localize('chartOfAccountName')}
                    </label>
                    <input
                      className="form-control ms-3"
                      disabled
                      value={
                        entry?.itemRegisterInventoryCategoryChartOfAccountName
                      }
                    />
                  </div>
                </div>
                <div className="row mb-1 mt-2">
                  <div className="col-md-6 d-flex align-items-center">
                    <label className="col-md-1 text-capitalize">
                      {localize('unitCost')}
                    </label>
                    <MaskNumber
                      className="form-control ms-4"
                      defaultMaskValue={entry?.unitCost}
                      disabled
                    />
                  </div>
                </div>
              </>
            </div>

            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  if (mode === 'add') {
                    if (!entry?.itemRegisterDescription) {
                      showNotification('Invoice', 'info', 'please select item')
                    } else {
                      stageEntry()
                    }
                  } else {
                    addEntryToTheDB()
                  }
                }}
                className="btn btn-success px-5"
              >
                {localize('add')}
              </button>
            </div>
            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: localize('invoiceItems'),
                    item: (
                      <InvoiceEntriesItems
                        edit={edit}
                        unpostedEntries={entries}
                        handleRemove={handleRemove}
                        mode={mode}
                        entries={existingEntries}
                        onSelectItem={onSelectItem}
                        loadEntries={loadEntries}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddInvoice
