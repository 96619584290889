/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { postData } from '../../../../Helpers/webApi'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import TellerLookup from '../../../../Components/Lookup/TellerLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'

const PointOfSaleTerminalsModal = ({
  mode,
  selectedPointOfSaleTerminal,
  setSelectedPointOfSaleTerminal,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'edit'
        ? '/PointOfSaleTerminal/update-point-of-sale-terminal'
        : '/PointOfSaleTerminal/add-point-of-sale-terminal'
    const { success } = await postData(
      url,
      selectedPointOfSaleTerminal || {
        description: '',
      },
      false
    )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Point Of Sale Terminals', 'success')
    }
    setIsBusy(false)
  }

  function closeValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const close = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'pointOfSaleTerminal'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={close}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cPointOfSaleTerminalIsLocked"
              onCheck={e => {
                setSelectedPointOfSaleTerminal({
                  ...selectedPointOfSaleTerminal,
                  isLocked: e,
                })
              }}
              checked={selectedPointOfSaleTerminal?.isLocked}
            />
            <label
              htmlFor="cPointOfSaleTerminalIsLocked"
              className="text-capitalize"
            >
              {localize('isLocked')} ?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedPointOfSaleTerminal?.description}
                  className="form-control"
                  onChange={e => {
                    closeValidation('Description')
                    setSelectedPointOfSaleTerminal({
                      ...selectedPointOfSaleTerminal,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['TellerDescription']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('teller')}
                </label>
                {mode === 'edit' ? (
                  <input
                    defaultValue={
                      selectedPointOfSaleTerminal?.tellerDescription
                    }
                    className="form-control"
                    disabled
                  />
                ) : (
                  <TellerLookup
                    displayValue={
                      selectedPointOfSaleTerminal?.tellerDescription
                    }
                    onSelect={v => {
                      if (v.type !== '2' && v.type !== '3') {
                        showNotification(
                          'POS Terminals',
                          'info',
                          'Sorry, but you must first select a POS teller'
                        )
                      } else {
                        closeValidation('TellerDescription')
                        setSelectedPointOfSaleTerminal(prev => ({
                          ...prev,
                          tellerDescription: v.description,
                          tellerId: v.id,
                        }))
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['BranchDescription']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('branch')}
                </label>
                <BranchLookup
                  displayValue={selectedPointOfSaleTerminal?.branchDescription}
                  onSelect={v => {
                    closeValidation('BranchDescription')
                    setSelectedPointOfSaleTerminal(prev => ({
                      ...prev,
                      branchDescription: v.description,
                      branchId: v.id,
                    }))
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['UniqueIdentifier']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('uniqueIdentifier')}
                </label>
                <input
                  defaultValue={selectedPointOfSaleTerminal?.uniqueIdentifier}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    closeValidation('UniqueIdentifier')
                    setSelectedPointOfSaleTerminal({
                      ...selectedPointOfSaleTerminal,
                      uniqueIdentifier: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={globalValidationErrors['MSISDN']?.message}
              >
                <label className="text-capitalize col-4">
                  {localize('msisdnMobileNumber')}
                </label>
                <input
                  defaultValue={selectedPointOfSaleTerminal?.msisdn}
                  type="tel"
                  className="form-control"
                  onChange={e => {
                    closeValidation('MSISDN')
                    setSelectedPointOfSaleTerminal({
                      ...selectedPointOfSaleTerminal,
                      msisdn: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default PointOfSaleTerminalsModal
