/** @format */

import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import ImportButton from '../../../../../../Components/Buttons/ImportButton/ImportButton'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { toBase64 } from '../../../../../../Helpers/blobs'
import { formatDate } from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'

const AutomatedChequeClearingJournals = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [journals, setJournals] = useState([])
  const [journal, setJournal] = useState({})
  const [isBusyClosing, setIsBusyClosing] = useState(false)
  const [mode, setMode] = useState(null)
  const ref = useRef(null)

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
  })

  const columns = [
    {
      label: localize('status'),
      template: item => <>{item?.statusDescription}</>,
    },

    {
      label: localize('fileName'),
      template: item => <>{item?.headerRecordFileSerialNumber}</>,
    },
    {
      label: localize('dateOfFileExchange'),
      template: item => <>{formatDate(item?.headerRecordDateOfFileExchange)}</>,
    },
    {
      label: localize('closedBy'),
      template: item => <>{item?.closedBy}</>,
    },
    {
      label: localize('closedDate'),
      template: item => <>{item?.closedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{item?.createdDate}</>,
    },
  ]

  const handleClose = () => {
    setJournal({})
    setMode(null)
  }

  const fetchElectronicJournals = async () => {
    const url = '/ElectronicJournal/find-electronic-journals-by-filter-in-page'

    setIsBusy(true)

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setJournals(data)
    }

    setIsBusy(false)
  }

  const handleSelectJournal = (journal, mode) => {
    setJournal(journal)
    setMode(mode)
  }

  const handleCloseJournal = async () => {
    if (journal?.status === 2?.toString())
      return showNotification(
        'Operation Not Allowed',
        'error',
        'Journal is already closed'
      )

    // TODO: Myke -finish 04/10/2023
    const url = '/ElectronicJournal/close-electronic-journal'

    setIsBusyClosing(true)

    const { success } = await postData(url, journal, false)

    if (success) {
      showNotification('Close Journal', 'success')
      handleClose()
      fetchElectronicJournals()
    }

    setIsBusyClosing(false)
  }

  const handleClickImportButton = () => {
    ref?.current?.click()
  }

  const handleImportElectronicJournals = async e => {

    if (e.target.files[0].name.split('.')[1] !== 'PRM')
      return showNotification(
        'Operation not allowed',
        'error',
        'File must be of type .PRM'
      )

    let file = e.target.files[0]
    try {
      let b64 = await toBase64(file)
      let buffer = b64.split(',')[1].toString()

      uploadJournals(buffer, file.name)
    } catch (error) {
      return showNotification(
        'Import Electronic Journal',
        'error',
        'Error uploading file'
      )
    }
  }

  const uploadJournals = async (buffer, fileName) => {
    setIsBusy(true)

    const url = '/Base64/media-upload-base-64'

    const { success } = await postData(
      url,
      {
        fileName,
        buffer,
      },
      false
    )

    if (success) {
      const url = '/Base64/file-upload-done'

      const { data } = await getData(
        url,
        {
          fileName,
        },
        false
      )

      if (data?.succeeded) {
        const url = `/ElectronicJournal/parse-electronic-journal`

        const { success } = await postData(
          url,
          {
            Filename: fileName,
          },
          false
        )

        if (success) {
          ref.current.value = ''
          showNotification('Import Electronic Journal', 'success')
          fetchElectronicJournals()
        } else {
          ref.current.value = ''
        }
      }
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchElectronicJournals()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'close' && (
        <ActionModal
          modalTitle="closeElectronicJournal"
          confirmText="yes"
          handleClose={handleClose}
          handleAction={handleCloseJournal}
          cancelText="no"
          disableAction={isBusyClosing}
        >
          {isBusyClosing ? (
            <Loader />
          ) : (
            <p>
              Are you sure you want to close this journal? <br />
            </p>
          )}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('automatedClearing'),
          localize('electronicJournals'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <ImportButton handleClickImportButton={handleClickImportButton} />
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex row align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center col-md-3">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div className="d-flex align-items-center col-md-2">
                  <label className="text-capitalize me-2">
                    {localize('status')}
                  </label>

                  <EnumsServerCombo
                    onChange={({ value }) => {
                      setReqParams({
                        ...reqParams,
                        status: value,
                        pageIndex: 0,
                      })
                    }}
                    enumsKey="ElectronicJournalStatus"
                    customContainerStyle={{
                      width: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    sort
                    value={reqParams?.status}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label htmlFor="search" className="col-2 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div
                  className="col-md-4 d-flex align-items-center justify-content-end gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchElectronicJournals()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchElectronicJournals()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                data={journals?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectJournal(r, 'close')
                      }}
                    >
                      <i className="uil uil-file-lock-alt"></i>
                      {localize('close')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={journals.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}

          <input
            type="file"
            accept=".PRM"
            ref={ref}
            className="d-none"
            onChange={handleImportElectronicJournals}
          />
        </div>
      </div>
    </>
  )
}

export default AutomatedChequeClearingJournals
