/** @format */
import { GeneralLedgerTransactionType } from '../../../../../Helpers/constants'
import localize from '../../../../../Global/Localize'
import { ChargesAndLevies } from './Partials/ChargesAndLevies'
import { SundryTransactionSectionCashPayment } from './Partials/TransactionType/SundryTransactionSectionCashPayment'
import { SundryTransactionSectionCashReceipt } from './Partials/TransactionType/SundryTransactionSectionCashReceipt'
import { SundryTransactionSectionAccountClosurePayment } from './Partials/TransactionType/SundryTransactionSectionAccountClosurePayment'
import { SundryTransactionSectionCashPickup } from './Partials/TransactionType/SundryTransactionSectionCashPickup'
import { SundryTransactionSectionChequeReceipt } from './Partials/TransactionType/SundryTransactionSectionChequeReceipt'
import { SundryTransactionSectionFuneralRiderClaim } from './Partials/TransactionType/SundryTransactionSectionFuneralRiderClaim'
import { SundryTransactionSectionPettyCashRequisition } from './Partials/TransactionType/SundryTransactionSectionPettyCashRequisition'
import { SundryTransactionSectionSundryPayment } from './Partials/TransactionType/SundryTransactionSectionSundryPayment'
import Tab from '../../../../../Components/Tabs/Tab'
import React from 'react'

export const SundryTransactionSection = ({
  selectedGeneralLedgerTransactionType,
  transactionModel,
  loading,
  tariffs,
  setTransactionModel,
}) => {
  let tabsToShow = []

  switch (selectedGeneralLedgerTransactionType) {
    case GeneralLedgerTransactionType.CASH_PAYMENT:
      tabsToShow = [
        {
          label: localize('cashPayment'),
          item: (
            <SundryTransactionSectionCashPayment
              loading={loading}
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.CASH_PAYMENT_ACCOUNT_CLOSURE:
      tabsToShow = [
        {
          label: localize('accountClosurePayment'),
          item: (
            <SundryTransactionSectionAccountClosurePayment
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.CASH_PICKUP:
      tabsToShow = [
        {
          label: localize('cashPickup'),
          item: (
            <SundryTransactionSectionCashPickup
              loading={loading}
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.CASH_RECEIPT:
      tabsToShow = [
        {
          label: localize('cashReceipt'),
          item: (
            <SundryTransactionSectionCashReceipt
              onChangeTransactionCategory={e => {}}
              onGetTotalValue={e => {}}
              loading={loading}
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.CHEQUE_RECEIPT:
      tabsToShow = [
        {
          label: localize('chequeReceipt'),
          item: (
            <SundryTransactionSectionChequeReceipt
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.FUNERAL_RIDER_CLAIM:
      tabsToShow = [
        {
          label: localize('funeralRiderClaim'),
          item: (
            <SundryTransactionSectionFuneralRiderClaim
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.PETTY_CASH_REQUISITION:
      tabsToShow = [
        {
          label: localize('pettyCashRequisition'),
          item: (
            <SundryTransactionSectionPettyCashRequisition
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    case GeneralLedgerTransactionType.SUNDRY_PAYMENT:
      tabsToShow = [
        {
          label: localize('sundryPayment'),
          item: (
            <SundryTransactionSectionSundryPayment
              transactionModel={transactionModel}
              setTransactionModel={setTransactionModel}
            />
          ),
        },
        {
          label: localize('chargesLevies'),
          item: <ChargesAndLevies tariffs={tariffs} />,
        },
      ]
      break
    default:
      break
  }

  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <Tab preload tabItems={tabsToShow} />
      </div>
    </>
  )
}
