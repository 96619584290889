/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import TransactionDetails from './TransactionDetails/TransactionDetails'
import CashTransferRequests from './CashTransferRequests/CashTransferRequests'
import GLAccountStatement from '../../../../../Components/ReUsables/GLAccounts/GLAccountStatement'
import { getData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import Tab from '../../../../../Components/Tabs/Tab'

const CashTransfer = () => {
  const [activePage, setActivePage] = useState(0)
  const [treasuryTrailType, setTreasuryTrailType] = useState(4)
  const [treasury, setTreasury] = useState({})
  const [selectedGlAccount, setSelectedGlAccount] = useState({})
  const [glAccount, setGlAccount] = useState({})
  const [transferRequest, setTransferRequest] = useState({})
  const [treasuryTrailCategory, setTreasuryTrailCategory] = useState(0)

  const handleSelectTreasury = treasury => {
    setTreasury(treasury)
  }

  const handleSelectGLAccount = glAccount => {
    setSelectedGlAccount(glAccount)
  }

  const fetchGlAccount = async () => {
    const url = '/ChartOfAccount/find-general-ledger-account'

    const { success, data } = await getData(
      url,
      {
        chartOfAccountId: selectedGlAccount.chartOfAccountId,
        includeBalances: true,
      },
      false
    )

    if (success) {
      setGlAccount(data)
    }
  }

  const fetchManagementRequestGeneralLedgerAccount = async () => {
    const url =
      '/TreasuryManagementRequest/find-treasury-management-request-general-ledger-account'

    const { success, data } = await getData(
      url,
      {
        treasuryManagementRequestId: transferRequest.id,
        treasuryTrailCategory,
      },
      false
    )

    if (success) {
      setGlAccount(data)
    }
  }

  useEffect(() => {
    if (selectedGlAccount?.id) fetchGlAccount()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGlAccount?.id])

  useEffect(() => {
    if (transferRequest?.id) fetchManagementRequestGeneralLedgerAccount()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferRequest?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('treasury'),
          localize('treasuryCashTransfer'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          <Tab
            tabItems={[
              {
                label: localize('cashTransfer'),
                item: (
                  <TransactionDetails
                    setTreasuryTrailType={setTreasuryTrailType}
                    treasuryTrailType={treasuryTrailType}
                    handleSelectTreasury={handleSelectTreasury}
                    handleSelectGLAccount={handleSelectGLAccount}
                  />
                ),
              },
              {
                label: localize('cashTransferRequests'),
                item: (
                  <CashTransferRequests
                    treasury={treasury}
                    setTransferRequest={setTransferRequest}
                    setTreasuryTrailCategory={setTreasuryTrailCategory}
                  />
                ),
              },
              {
                label: localize('g/lAccountStatement'),
                item: <GLAccountStatement glAccount={glAccount} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default CashTransfer
