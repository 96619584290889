/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'

function MandatoryCharges({ all, onSelect, selected, displayColumn }) {
  function isSelected(d) {
    return !!selected.find(s => s.id === d.id)
  }
  return (
    <div>
      {all.map(d => (
        <div className="d-flex align-items-center">
          <Checkbox
            id={d.id}
            onCheck={() => onSelect(d)}
            checked={isSelected(d)}
          />
          <label
            htmlFor={d.id}
            className={`text-capitalize ms-2 ${
              isSelected(d) ? 'text-primary fw-bold' : 'fw-light'
            }`}
            style={{ cursor: 'pointer' }}
          >
            {displayColumn ? d[displayColumn] : d.description}
          </label>
        </div>
      ))}
    </div>
  )
}

export default MandatoryCharges
