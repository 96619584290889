/** @format */

import TallyCounter from '../../../../../../../Components/TallyCounter/TallyCounter'
import React, { useEffect, useState } from 'react'
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { GeneralCashTransactionCategory } from '../../../../../../../Helpers/constants'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

export const SundryTransactionSectionCashReceipt = ({
  onGetTotalValue,
  transactionModel,
  loading,
  setTransactionModel,
  onChangeTransactionCategory,
}) => {
  const [resetValues, setResetValues] = useState(false)
  const [selectedTransactionCategory, setSelectedTransactionCategory] =
    useState(GeneralCashTransactionCategory.SUNDRY)
  const [tallyValues, setTallyValues] = useState(null)

  const [totalValue, setTotalValue] = useState(0)

  useEffect(() => {
    setTransactionModel({
      ...transactionModel,
      currencyCountModel: {
        ...transactionModel.CurrencyCountModel,
        total: totalValue,
      },
    })
  }, [totalValue])

  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col-md-4">
            <TallyCounter
              setResetValues={setResetValues}
              resetValues={resetValues}
              getTally={v => {
                setTallyValues(v)
              }}
              getTotal={v => {
                setTotalValue(v)
              }}
            />
          </div>
          <div className="col-md-8">
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('transactionCategory')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="GeneralCashTransactionCategory"
                  value={selectedTransactionCategory}
                  onChange={e => {
                    setSelectedTransactionCategory(e.value)
                    onChangeTransactionCategory(e.value)
                    setTransactionModel({
                      ...transactionModel,
                      generalCashTransactionCategory: e.value,
                    })
                  }}
                />
              </div>
            </div>
            {selectedTransactionCategory ===
              GeneralCashTransactionCategory.SUNDRY && (
              <>
                <div className="row mb-3">
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-6 text-capitalize">
                      {localize('glAccount')}
                    </label>
                    <ChartOfAccountsLookup
                      displayValue={
                        transactionModel?.SelectedGeneralLedgerAccount?.name
                      }
                      onSelect={r => {
                        setTransactionModel({
                          ...transactionModel,
                          SelectedGeneralLedgerAccount: { ...r },
                        })
                      }}
                    />
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('accountType')}
                    </label>
                    <input
                      className="form-control"
                      value={
                        transactionModel?.SelectedGeneralLedgerAccount
                          ?.typeDescription || ''
                      }
                      disabled
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('amount')}
                </label>
                <input
                  value={formatCurrency(
                    transactionModel?.currencyCountModel?.total || '',
                    2,
                    2,
                    false
                  )}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('payer')}
                </label>
                <input
                  className="form-control"
                  value={
                    transactionModel?.cashPaymentTransactionModel?.payee || ''
                  }
                  onChange={e => {
                    setTransactionModel({
                      ...transactionModel,
                      cashPaymentTransactionModel: {
                        ...transactionModel.cashPaymentTransactionModel,
                        payee: e.target.value,
                      },
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('narration')}
                </label>
                <input
                  className="form-control"
                  value={
                    transactionModel?.cashPaymentTransactionModel?.narration ||
                    ''
                  }
                  onChange={e => {
                    setTransactionModel({
                      ...transactionModel,
                      cashPaymentTransactionModel: {
                        ...transactionModel.cashPaymentTransactionModel,
                        narration: e.target.value,
                      },
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  className="form-control"
                  value={
                    transactionModel?.cashPaymentTransactionModel?.reference ||
                    ''
                  }
                  onChange={e => {
                    setTransactionModel({
                      ...transactionModel,
                      cashPaymentTransactionModel: {
                        ...transactionModel.cashPaymentTransactionModel,
                        reference: e.target.value,
                      },
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
