/** @format */
import Checkbox from '../../../../Components/Checkbox/Checkbox'
/* eslint-disable */
import React from 'react'
import { ACTIONS } from '../Reducers/reducer'

export const CreditTypesFlags = ({
  creditTypeFlags,
  creditTypeDispatcher,
  onCheckFlag,
  prefix,
  mode,
}) => {
  const handleCheckboxChange = i => {
    creditTypeDispatcher({
      type: ACTIONS.CHECK_CREDIT_TYPE_FLAG,
      payload: creditTypeFlags.map((flag, index) => {
        if (index === i) {
          return { ...flag, checked: !flag.checked }
        }
        return flag
      }),
    })
  }

  return (
    <div className="row mt-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      {creditTypeFlags
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((t, i) => (
          <div className="col-md-3" key={`${t.value}-${i}`}>
            <div className="d-flex col-auto align-items-center mb-2">
              <Checkbox
                id={`${
                  mode === 'add' ? 'add' + prefix : 'edit' + prefix
                }-flag-${t.value}`}
                checked={t.checked}
                disabled={mode !== 'add' && mode !== 'edit'}
                onCheck={() => {
                  handleCheckboxChange(i)
                }}
              />
              <label
                htmlFor={`${
                  mode === 'add' ? 'add' + prefix : 'edit' + prefix
                }-flag-${t.value}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  t.checked ? 'text-primary fw-bold' : ''
                } `}
              >
                {t.label}
              </label>
            </div>
          </div>
        ))}
    </div>
  )
}
