/** @format */
import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import { getData } from '../../../../../Helpers/webApi'
import CancelGoodsDispatchNote from './Partials/CancelGoodsDispatchNote'

function GoodsDispatchNotesCancellation() {
  const columns = [
    {
      label: localize('reference#'),
      template: r => r.paddedReferenceNumber,
    },
    {
      label: localize('department'),
      template: r => r.departmentDescription,
    },
    {
      label: localize('recepientName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 1,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedGoodsDispatchNote, setSelectedGoodsDispatchNote] =
    useState(null)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/GoodsDispatchNote/find-goods-dispatch-notes-by-filter-and-date-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('goodsDispatchNotes'),
          localize('cancellation'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchBystatus"
                        >
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="InventoryDispatchStatus"
                          value={reqParams?.status}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        className="col-2 ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="goodsDispatchNoteTerminationFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('cancel')
                              setSelectedGoodsDispatchNote(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-cancel"></i>
                            {localize('cancel')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'cancel' && (
        <CancelGoodsDispatchNote
          goodsDispatchNote={selectedGoodsDispatchNote}
          setGoodsDispatchNote={setSelectedGoodsDispatchNote}
          closeModal={() => {
            setMode(null)
            setSelectedGoodsDispatchNote(null)
          }}
          loadTableData={loadTableData}
          mode={mode}
        />
      )}
    </>
  )
}
export default GoodsDispatchNotesCancellation
