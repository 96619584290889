/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EmployeeLookup from '../../../../../Components/Lookup/EmployeeLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'

const CreateRelationshipManager = ({
  mode,
  handleClose,
  handleCreateOrUpdate,
  isBusy,
  manager,
  setManager,
  globalValidationErrors,
  clearValidation,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={
        mode === 'add' ? 'createRelationshipManager' : 'editRelationshipManager'
      }
      handleAction={handleCreateOrUpdate}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            id="isLockedManager"
            checked={manager?.isLocked}
            disabled={isBusy}
            onCheck={e => {
              setManager({
                ...manager,
                isLocked: e,
              })
            }}
          />
          <label
            className="ms-2 me-4 text-capitalize"
            htmlFor="isLockedManager"
          >
            {localize('isLocked')}
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-4">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['EmployeeId']?.message}
            >
              <label htmlFor="employee" className="text-capitalize col-3 me-2">
                {localize('employee')}
              </label>

              <EmployeeLookup
                displayValue={
                  manager?.customerFullName ||
                  manager?.employeeCustomerFullName ||
                  ''
                }
                onSelect={e => {
                  clearValidation('EmployeeId')
                  setManager({
                    ...manager,
                    employeeCustomerId: e?.customerId,
                    employeeId: e?.id,
                    customerFullName: e?.customerFullName,
                    employeeCustomerFullName: e?.employeeCustomerFullName,
                    designationDescription: e?.designationDescription,
                    employeeDesignationDescription:
                      e?.employeeDesignationDescription,
                    branchCompanyDescription: e.branchCompanyDescription,
                    employeeBranchDescription: e.employeeBranchDescription,
                    departmentDescription:
                      e?.employeeEmploymentStatusDescription,
                    employeeDepartmentDescription:
                      e?.employeeDepartmentDescription,
                    employeeTypeCategoryDescription:
                      e?.employeeTypeCategoryDescription,
                    employeeEmployeeTypeDescription:
                      e?.employeeEmployeeTypeDescription,
                    employeeEmploymentStatusDescription:
                      e?.employeeEmploymentStatusDescription,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('designation')}
              </label>
              <input
                type="text"
                id="designation"
                className="form-control ms-2"
                defaultValue={
                  manager?.designationDescription ||
                  manager?.employeeDesignationDescription ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="branch" className="text-capitalize col-3">
                {localize('branch')}
              </label>

              <input
                type="text"
                id="branch"
                className="form-control ms-2"
                defaultValue={
                  manager?.branchCompanyDescription ||
                  manager?.employeeBranchDescription ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="department" className="text-capitalize col-3">
                {localize('department')}
              </label>

              <input
                type="text"
                id="department"
                className="form-control ms-2"
                defaultValue={
                  manager?.departmentDescription ||
                  manager?.employeeDepartmentDescription ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="employeeType" className="text-capitalize col-3">
                {localize('employeeType')}
              </label>

              <input
                type="text"
                id="employeeType"
                className="form-control ms-2"
                defaultValue={
                  manager?.employeeTypeCategoryDescription ||
                  manager?.employeeEmployeeTypeDescription ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="employeeStatus" className="text-capitalize col-3">
                {localize('employeeStatus')}
              </label>

              <input
                type="text"
                id="employeeStatus"
                className="form-control ms-2"
                defaultValue={
                  manager?.employmentStatusDescription ||
                  manager?.employeeEmploymentStatusDescription ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="d-flex align-items-center- with-validation"
              validation-message={globalValidationErrors['Remarks']?.message}
            >
              <label htmlFor="remarks" className="text-capitalize col-1">
                {localize('remarks')}
              </label>

              <input
                type="text"
                id="remarks"
                className="form-control ms-1"
                defaultValue={manager?.remarks || ''}
                onChange={e => {
                  clearValidation('Remarks')
                  setManager({
                    ...manager,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateRelationshipManager
