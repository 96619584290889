/** @format */

import React, { useState } from 'react'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'

const SalaryGroupEntries = ({ mode, entries }) => {
  const columns = [
    {
      label: localize('salaryHead'),
      template: r => <>{r.salaryHeadDescription}</>,
    },
    {
      label: localize('isOneOff'),
      template: r => (
        <span className="text-capitalize">
          {r.salaryHeadIsOneOff?.toString()}
        </span>
      ),
    },
    {
      label: localize('valueType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentageValue'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('fixedValue'),
      template: r => <>{formatCurrency(r.chargeFixedAmount)}</>,
    },
    {
      label: localize('minimumValue'),
      template: r => <>{formatCurrency(r.minimumValue)}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r.roundingTypeDescription}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return <SimpleTable columns={columns} data={entries} />
}

export default SalaryGroupEntries
