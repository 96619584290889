/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import AddVehicle from './AddVehicle/AddVehicle'
import ViewVehicle from './ViewVehicle/ViewVehicle'
import Pager from '../../../../Components/Tables/Pager'

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([])
  const [vehicle, setVehicle] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    vehicleFilter: 26,
  })

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r?.registrationNumber}</>,
    },
    { label: localize('fleetNumber'), template: r => <>{r?.fleetNumber}</> },
    {
      label: localize('vehicleRoute'),
      template: r => <>{r?.vehicleRouteDescription}</>,
    },
    {
      label: localize('customerBranch'),
      template: r => <>{r?.customerBranchDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landline'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('documentReferenceNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    {
      label: localize('isLocked'),
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchVehicles = async () => {
    setIsBusy(true)
    const url = '/Vehicle/find-vehicles-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setVehicles(data)
    }

    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleSelectVehicle = (vehicle, mode) => {
    setVehicle(vehicle)
    setMode(mode)
  }

  const handleClose = () => {
    setMode(null)
    setVehicle(null)
  }

  useEffect(() => {
    fetchVehicles()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <AddVehicle
          handleClose={handleClose}
          mode={mode}
          vehicleData={vehicle}
          fetchVehicles={fetchVehicles}
        />
      )}

      {mode === 'view' && (
        <ViewVehicle
          handleClose={handleClose}
          mode={mode}
          vehicleData={vehicle}
        />
      )}

      <BreadCrumb tree={[localize('fleet'), localize('vehicles')]} />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <AddNewButton handleClickAddButton={handleClickAddButton} />
            </div>
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center my-2">
                  <label
                    className="text-capitalize"
                    htmlFor="morguePatientLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="morguePatientLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="d-flex align-items-center">
                  <label
                    className="ms-2 text-capitalize me-3"
                    htmlFor="searchTable"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.vehicleFilter}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey={'VehicleFilter'}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        vehicleFilter: e.value,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-3"
                    defaultValue={reqParams.filterText}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchVehicles()
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchVehicles()
                      }
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={vehicles?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectVehicle(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectVehicle(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={vehicles.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Vehicles
