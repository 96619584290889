/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import Pager from '../../../../Components/Tables/Pager'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import ViewAsset from './Partials/ViewAsset'
import EditAsset from './Partials/EditAsset'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

const Catalogue = () => {
  const columns = [
    {
      label: localize('tagNumber'),
      template: r => <>{r.tagNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.serialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('assetLife(yrs)'),
      template: r => <>{r.usefulLife}</>,
    },
    {
      label: localize('depreciationMethod'),
      template: r => (
        <>{r.itemRegisterAssetTypeDepreciationMethodDescription}</>
      ),
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.itemRegisterAssetTypeChartOfAccountAccountName}</>,
    },
    {
      label: localize('accumulatedDepreciationChartOfAccountName'),
      template: r => (
        <>{r.itemRegisterAssetTypeAccumulatedDepreciationAccountAccountName}</>
      ),
    },
    {
      label: localize('depreciationExpenseChartOfAccountName'),
      template: r => (
        <>{r.itemRegisterAssetTypeDepreciationExpenseAccountAccountName}</>
      ),
    },
    {
      label: localize('gain-lossChartOfAccountName'),
      template: r => <>{r.itemRegisterAssetTypeGainLossAccountAccountName}</>,
    },
    {
      label: localize('supplier'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r.departmentDescription}</>,
    },
    {
      label: localize('manufacturer'),
      template: r => <>{r.manufacturer}</>,
    },
    {
      label: localize('model'),
      template: r => <>{r.model}</>,
    },
    {
      label: localize('purchasePrice'),
      template: r => <>{formatCurrency(r.purchasePrice)}</>,
    },
    {
      label: localize('residualValue'),
      template: r => <>{formatCurrency(r.residualValue)}</>,
    },
    {
      label: localize('location'),
      template: r => <>{r.locationDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('isFlaggedForDisposal') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isFlaggedForDisposal?.toString())}
        </span>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    showBelowReorderLevel: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [asset, setAsset] = useState(null)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Asset/find-assets-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const findAsset = async r => {
    console.log(r)
    setIsBusy(true)
    const { success, data } = await getData(
      '/Asset/find-asset',
      { assetId: r.id },
      false
    )
    if (success) {
      console.log(data)
      setIsBusy(false)
      setAsset(data)
      setMode('view')
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('asset'),
          localize('catalogue'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row d-flex justify-content-between">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <form
                      className="d-flex align-items-center col-md-5"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="ChequeWritingCataloguesFilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setAsset(r)
                              setMode('view')
                            }}
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setAsset(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'view' ? (
        <ViewAsset
          asset={asset}
          mode={mode}
          closeModal={() => {
            setMode(null)
            setAsset(null)
          }}
        />
      ) : (
        ''
      )}
      {mode === 'edit' ? (
        <EditAsset
          asset={asset}
          setAsset={setAsset}
          mode={mode}
          closeModal={() => {
            setMode(null)
            setAsset(null)
          }}
          loadData={loadTableData}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Catalogue
