/** @format */

import {
  entitiesToBeChecked,
  getCommissions,
  getDirectDebits,
  getInvestmentProducts,
  getLoanProducts,
  getSavingsProduct,
} from '../../../../Helpers/selectablesHelper'
import { getData } from '../../../../Helpers/webApi'

export const getCommissionsToBeSelected = async r => {
  const commissions = await getCommissions()
  const { success, data } = await getData(
    `/CreditType/find-commissions-by-credit-type-id`,
    {
      creditTypeId: r.id,
    },
    false
  )
  if (success) {
    return entitiesToBeChecked(commissions, data.result || [])
  }
}

export const getDirectDebitsToBeSelected = async r => {
  const directDebits = await getDirectDebits()
  const { success, data } = await getData(
    `/DirectDebit/find-direct-debits-by-credit-type-id`,
    {
      creditTypeId: r.id,
    },
    false
  )
  if (success) {
    return entitiesToBeChecked(directDebits, data.result || [])
  }
}

export const getCreditTypeAttachedLoanProductsToBeSelected = async r => {
  const loanProducts = await getLoanProducts()
  const { success, data } = await getData(
    `/CreditType/find-attached-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    },
    false
  )
  if (success) {
    return entitiesToBeChecked(loanProducts, data.loanProductCollection || [])
  }
}

export const getCreditTypeAttachedInvestmentProductsToBeSelected = async r => {
  const investmentProducts = await getInvestmentProducts()
  const { success, data } = await getData(
    `/CreditType/find-attached-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(
      investmentProducts,
      data.investmentProductCollection || []
    )
  }
}

export const getCreditTypeAttachedSavingsProductsToBeSelected = async r => {
  const savingsProducts = await getSavingsProduct()
  const { success, data } = await getData(
    `/CreditType/find-attached-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(
      savingsProducts,
      data.savingsProductCollection || []
    )
  }
}

export const getCreditTypeConcessionExemptionInvestmentProducts = async r => {
  const investmentProducts = await getInvestmentProducts()
  const { success, data } = await getData(
    `/CreditType/find-concession-exempt-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(
      investmentProducts,
      data.investmentProductCollection || []
    )
  }
}

export const getCreditTypeConcessionExemptionLoanProducts = async r => {
  const loanProducts = await getLoanProducts()
  const { success, data } = await getData(
    `/CreditType/find-concession-exempt-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(loanProducts, data.loanProductCollection || [])
  }
}

export const getCreditTypeConcessionExemptionSavingsProducts = async r => {
  const savingsProduct = await getSavingsProduct()
  const { success, data } = await getData(
    `/CreditType/find-concession-exempt-products-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(
      savingsProduct,
      data.savingsProductCollection || []
    )
  }
}

export const getCreditTypeBenefactorInvestmentProducts = async r => {
  const investmentProducts = await getInvestmentProducts()
  const { success, data } = await getData(
    `/CreditType/find-benefactors-by-credit-type-id`,
    {
      creditTypeId: r.id,
    }
  )
  if (success) {
    return entitiesToBeChecked(
      investmentProducts,
      data.investmentProductCollection || []
    )
  }
}
