/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function CostCentres() {
  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const reqParams = {
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  }
  const [refresh, setRefresh] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [modalState, setModalState] = useState(null)
  const [selectedCenter, setSelectedCenter] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  async function handleEdit() {
    setIsBusy(true)
    const { success } = await putData('/costcenter/update', selectedCenter)
    if (success) {
      setIsBusy(false)
      setRefresh(!refresh)
      setSelectedCenter(null)
      setModalState(null)
      Swal.fire({
        icon: 'success',
        title: 'Operation Successful',
        text: 'Cost Center Updated',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Operation Failed',
        text: 'Cost Center Not Updated',
      })
    }

    setIsBusy(false)
  }

  async function handleAdd() {
    const { success } = await postData('/costcenter/create', selectedCenter)
    if (success) {
      setRefresh(!refresh)
      setSelectedCenter(null)
      setModalState(null)
      Swal.fire({
        icon: 'success',
        title: 'Operation Successful',
        text: 'Cost Center Created',
      })
    }
  }

  function closeModal() {
    setModalState(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalState('auditLogs')
  }

  return (
    <>
      <ListingPage
        url="/costCenter/find-by-filter-in-page"
        columns={columns}
        params={reqParams}
        map={[
          localize('accountsManagement'),
          localize('GLAccounts'),
          localize('costCentres'),
        ]}
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={() => {
                setSelectedCenter(r)
                setModalState('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span className="text-capitalize">{localize('edit')}</span>
            </div>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleViewAuditLogs(r)
              }}
            >
              <i className="uil uil-server"></i>
              <span>{localize('viewAuditLogs')}</span>
            </div>
          </>
        )}
        onAdd={() => {
          setSelectedCenter(null)
          setModalState('add')
        }}
      />
      {modalState === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={modalState}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      {modalState === 'add' ||
        (modalState === 'edit' && (
          <Modal isOpen>
            <ModalHeader className="bg-white text-capitalize">
              {localize(modalState)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('costCentres')}
              </span>
              {isBusy || (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </ModalHeader>
            <ModalBody className="bg-white">
              <div
                className="with-validation d-flex align-items-center"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize me-3 col-2">
                  {localize('name')}
                </label>
                <input
                  value={selectedCenter?.description}
                  onChange={e => {
                    setGlobalValidationErrors(gve => delete gve['Description'])
                    setSelectedCenter({
                      ...selectedCenter,
                      description: e.target.value,
                    })
                  }}
                  type="text"
                  className="form-control"
                />
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <div className="d-flex align-items-center mt-3">
                <Checkbox
                  id="editCostCenterIsLocked"
                  checked={selectedCenter?.isLocked}
                  onCheck={v =>
                    setSelectedCenter({ ...selectedCenter, isLocked: v })
                  }
                />
                <label
                  htmlFor="editCostCenterIsLocked"
                  className="text-capitalize ms-1 me-3"
                >
                  {localize('isLocked')}
                </label>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <button
                    className="btn btn-danger px-4 me-2"
                    onClick={closeModal}
                  >
                    {localize('cancel')}
                  </button>
                  <button
                    onClick={modalState === 'edit' ? handleEdit : handleAdd}
                    className="btn btn-success text-uppercase px-4"
                  >
                    {localize(modalState === 'add' ? 'create' : 'update')}
                  </button>
                </>
              )}
            </ModalFooter>
          </Modal>
        ))}
    </>
  )
}

export default CostCentres
