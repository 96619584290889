/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import CreditBatchModal from './Partials/CreditBatchModal'
import {
  batchStatus,
  CreditBatchType,
} from '../../../../../../Global/enumeration'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import { FiltersContainer } from '../../../../../../Components/Tables/FiltersContainer'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import ViewAuditLogs from '../../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Credit() {
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    status: Object.keys(batchStatus)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    type: Object.keys(CreditBatchType)[0],
  })

  const [isBusy, setIsBusy] = useState(false)
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [sortedBy, setSortedBy] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [tableData, setTableData] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const columns = [
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedBatchNumber}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.postingPeriodDescription}</>,
    },
    { label: localize('batchType'), template: r => <>{r?.typeDescription}</> },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('month'), template: r => <>{r?.monthDescription}</> },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r?.totalValue)}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('creditType'),
      template: r => <>{r?.creditTypeDescription}</>,
    },
    {
      label: localize('payrollNumber'),
      template: r => <>{r?.creditTypePayrollNumberTypeDescription}</>,
    },
    {
      label: localize('revenueTax'),
      template: r => <>{r?.creditTypeRevenueTaxDescription}</>,
    },
    {
      label: localize('revenueTaxRate'),
      template: r => (
        <span className="text-end">
          {formatAsPercent(r?.creditTypeRevenueTaxTaxRate)}
        </span>
      ),
    },
    {
      label: localize('concessionType'),
      template: r => <>{r?.concessionTypeDescription}</>,
    },
    {
      label: localize('concessionPercentage'),
      template: r => (
        <span className="text-end" style={{ textAlign: 'right' }}>
          {formatAsPercent(r?.concessionPercentage)}
        </span>
      ),
    },
    {
      label: localize('concessionFixedAmount'),
      template: r => <>{formatCurrency(r?.concessionFixedAmount)}</>,
    },
    {
      label: localize('checkOffCutOffDate'),
      template: r => <>{formatDate(r?.checkOffCutOffDate)}</>,
    },
    {
      label: localize('enforceCheckoffCutOffDate'),
      template: r => (
        <span className="text-capitalize">
          {r?.enforceCheckoffCutOffDate.toString()}
        </span>
      ),
    },
    {
      label: localize('preserveAccountBalance'),
      template: r => (
        <span className="text-capitalize">
          {r?.preserveAccountBalance.toString()}
        </span>
      ),
    },
    {
      label: localize('fuzzyMatching'),
      template: r => (
        <span className="text-capitalize">{r?.fuzzyMatching.toString()}</span>
      ),
    },
    { label: localize('auditRemarks'), template: r => <>{r?.auditRemarks}</> },
    {
      label: `${localize('verified')}/${localize('rejected')} by`,
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} date`,
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: `${localize('authorization')}/${localize('verification')} remarks`,
      template: r => <>{r?.authorizationRemarks}</>,
    },
    { label: localize('authorizedBy'), template: r => <>{r?.authorizedBy}</> },
    {
      label: `${localize('authorization')}/${localize('verification')} date`,
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    {
      label: localize('priorityDescription'),
      template: r => <>{r?.priorityDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batches-by-status-and-type-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
  }, [])

  useEffect(() => {
    loadTableData()
  }, [
    reqParams.pageSize,
    reqParams.pageIndex,
    reqParams.dateRequestsFilter,
    reqParams.customDate,
    reqParams.type,
    reqParams.status,
    refreshAt,
  ])

  const [mode, setMode] = useState(null)
  const [selectedBatchEntry, setSelectedBatchEntry] = useState(null)

  const handleOnAdd = () => {
    setMode('add')
  }

  const handleOnEdit = r => {
    if (r.status === Object.keys(batchStatus)[1]) {
      Swal.fire({
        icon: 'error',
        text: 'Batch Has Already Been Posted',
        title: 'Edit Credit Batch',
        showCloseButton: true,
      })
      return
    }
    setMode('edit')
    setSelectedBatchEntry(r)
  }

  const handleOnView = r => {
    setMode('view')
    setSelectedBatchEntry(r)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('credit'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col">
                      <AddNewButton handleClickAddButton={handleOnAdd} />
                    </div>
                  </div>
                  <div className="row">
                    <FiltersContainer>
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize me-3">
                          {localize('type')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="CreditBatchType"
                          value={reqParams?.type}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              type: e.value,
                              pageIndex: 0,
                            })
                          }}
                        />
                      </div>

                      <div className="d-flex align-items-center">
                        <label className="text-capitalize me-3">
                          {localize('pickDate')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>

                      <div className="d-flex align-items-center">
                        <label className="text-capitalize me-3">
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="BatchStatus"
                          value={reqParams?.status}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                              pageIndex: 0,
                            })
                          }}
                        />
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="input-group">
                          <input
                            defaultValue={reqParams.filterText}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                filterText: e.target.value,
                              })
                            }
                            className="form-control me-2"
                            placeholder="Enter text..."
                            type="text"
                          />
                          <button
                            id="customLookupFilterButton"
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </div>
                      </div>
                    </FiltersContainer>

                    <div className="row col-12 mt-2">
                      <SimpleTable
                        contextMenu={e => (
                          <>
                            <div
                              onClick={() => {
                                handleOnEdit(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-edit"></i>
                              {localize('edit')}
                            </div>
                            <div
                              onClick={() => {
                                handleOnView(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('view')}
                            </div>
                            <div
                              className="ctxt-menu-item text-capitalize"
                              onClick={() => {
                                handleViewAuditLogs(e)
                              }}
                            >
                              <i className="uil uil-server"></i>
                              <span>{localize('viewAuditLog')}</span>
                            </div>
                          </>
                        )}
                        data={tableData}
                        columns={columns}
                        onSort={(c, i) => handleSort(c, i)}
                        sortedBy={sortedBy}
                      />
                    </div>
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreditBatchModal
        setSelectedBatchEntry={setSelectedBatchEntry}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        selectedBatchEntry={selectedBatchEntry}
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        onSetMode={m => {
          setMode(m)
        }}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}
    </>
  )
}

export default Credit
