/** @format */

import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const FixedDepositTypeOffsetableLoanProducts = ({
  disabled,
  isBusy,
  selectedFixedDepositType,
  setSelectedFixedDepositType,
  prefix,
}) => {
  const handleOnCheckFixedDepositTypeLoanProducts = (
    loanProducts,
    checkValue
  ) => {
    const com = [...selectedFixedDepositType?.loanProducts].map(comm => {
      if (comm.id === loanProducts.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedFixedDepositType({
      ...selectedFixedDepositType,
      loanProducts: com,
    })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedFixedDepositType?.loanProducts.map(loanProducts => (
            <li
              key={loanProducts.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${loanProducts.id}`}
                checked={loanProducts.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleOnCheckFixedDepositTypeLoanProducts(loanProducts, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${loanProducts.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  loanProducts.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {loanProducts.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
