/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
/* eslint-disable */
import React from 'react'
import { formatDate } from '../../Helpers/formatters'

export const ChequeTypeLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('chequeTypeLookup')}
      url="/lookup/ChequeType/find-cheque-types-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        { label: localize('name'), template: r => <>{r.description}</> },
        {
          label: localize('maturityDaysCategory'),
          template: r => <>{r.maturityDaysCategoryDescription}</>,
        },
        {
          label: localize('maturityPeriod'),
          template: r => <>{r.maturityPeriod}</>,
        },
        {
          label: localize('chargeRecoveryMode'),
          template: r => <>{r.chargeRecoveryModeDescription}</>,
        },
        {
          label: localize('preserveAccountBalance'),
          template: r => <>{r.preserveAccountBalance}</>,
        },
        { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
