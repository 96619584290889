/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddTellersModal } from './AddTellersModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { EditTellersModal } from './EditTellersModal'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function TellersModal({
  mode,
  onHideModal,
  selectedTeller,
  setSelectedTeller,
  onUpdateRefreshAt,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addTeller()
    }
    if (mode === 'edit') {
      return editTeller()
    }
  }

  const addTeller = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Teller/add-teller',
      { ...(selectedTeller || {}) },
      false,
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire('Add Teller', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  const editTeller = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Teller/update-teller',
      { ...(selectedTeller || {}) },
      false,
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire('Edit Teller', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('teller')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('miniStatementsItemCap')}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      defaultValue={selectedTeller?.miniStatementItemsCap}
                      onChange={e => {
                        setSelectedTeller({
                          ...selectedTeller,
                          miniStatementItemsCap: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center me-5">
                  <Checkbox
                    id={'isLocked'}
                    checked={selectedTeller?.isLocked}
                    onCheck={v =>
                      setSelectedTeller({ ...selectedTeller, isLocked: v })
                    }
                  />
                  <label htmlFor="isLocked" className="text-capitalize ms-2">
                    {localize('isLocked')}?
                  </label>
                </div>
              </div>
            }
          >
            {mode === 'add' && (
              <AddTellersModal
                isBusy={isBusy}
                selectedTeller={selectedTeller}
                setSelectedTeller={setSelectedTeller}
              />
            )}
            {mode === 'edit' && (
              <EditTellersModal
                isBusy={isBusy}
                selectedTeller={selectedTeller}
                setSelectedTeller={setSelectedTeller}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default TellersModal
