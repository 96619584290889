/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../Components/Date/DatePicker'
import ViewCustomerAccountBalanceSpoolDataModal from './ViewCustomerAccountBalanceSpoolDataModal'
import CreateCustomerAccountBalanceSpoolDataModal from './CreateCustomerAccountBalanceSpoolDataModal'
import { formatDate } from '../../../../../Helpers/formatters'
import {
  getInvestmentProducts,
  getSavingsProduct,
} from '../../../../../Helpers/selectablesHelper'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function MonthlyAccountBalance() {
  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
      sortBy: 'paddedBatchNumber',
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
      sortBy: 'postingPeriodDescription',
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r.durationStartDate)}</>,
      sortBy: 'durationStartDate',
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r.durationEndDate)}</>,
      sortBy: 'durationEndDate',
    },
    {
      label: localize('balanceStatistic'),
      template: r => <>{r.statisticDescription}</>,
      sortBy: 'statisticDescription',
    },
    {
      label: localize('dateFilter'),
      template: r => <>{r.dateFilterDescription}</>,
      sortBy: 'dateFilterDescription',
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
      sortBy: 'reference',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    dateRequestsFilter: 15,
    customDate: false,
  })

  const [mode, setMode] = useState(null)
  const [
    selectedCustomerAccountBalanceSpoolRequest,
    setSelectedCustomerAccountBalanceSpoolRequest,
  ] = useState()

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  const [
    customerAccountBalanceSpoolRequestInvestmentProducts,
    setCustomerAccountBalanceSpoolRequestInvestmentProducts,
  ] = useState([])
  const [
    customerAccountBalanceSpoolRequestSavingsProducts,
    setCustomerAccountBalanceSpoolRequestSavingsProducts,
  ] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccountBalanceSpool/find-customer-account-balance-spools-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedCustomerAccountBalanceSpoolRequest(null)
    setCustomerAccountBalanceSpoolRequestInvestmentProducts([])
    setCustomerAccountBalanceSpoolRequestSavingsProducts([])
    setCustomerAccountBalanceSpoolRequestInvestmentProducts(
      await getInvestmentProducts()
    )
    setCustomerAccountBalanceSpoolRequestSavingsProducts(
      await getSavingsProduct()
    )
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('recurringProcedures'),
          localize('monthlyAccountBalances'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <AddNewButton handleClickAddButton={handleAdd} />
                    </div>
                  </div>
                </div>
                <div className="row mb-2 mx-0">
                  <RecordsPerPage
                    className="col-2"
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                  <div className="col-md-5">
                    <div className="d-flex align-items-center ms-3">
                      <label
                        className="text-capitalize col-2"
                        htmlFor="searchByDate"
                      >
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <form
                    className="d-flex align-items-center col-5 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>

                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="customerAccountBalanceSpoolRequestFilterButton"
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>

                <div className="row col-12 mt-2">
                  <SimpleTable
                    contextMenu={r => (
                      <>
                        <div
                          onClick={async () => {
                            setMode('view')
                            setSelectedCustomerAccountBalanceSpoolRequest(r)
                          }}
                          className="ctxt-menu-item"
                        >
                          <i className="uil uil-eye"></i>
                          {localize('view')}
                        </div>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleViewAuditLogs(r)
                          }}
                        >
                          <i className="uil uil-server"></i>
                          <span>{localize('viewAuditLog')}</span>
                        </div>
                      </>
                    )}
                    data={data}
                    columns={columns}
                  />
                </div>
                <div className="row col-12 mt-2">
                  <Pager
                    {...tableMeta}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {mode === 'add' ? (
        <CreateCustomerAccountBalanceSpoolDataModal
          selectedCustomerAccountBalanceSpoolRequest={
            selectedCustomerAccountBalanceSpoolRequest
          }
          loadData={loadData}
          investmentProductsToBeSelected={
            customerAccountBalanceSpoolRequestInvestmentProducts
          }
          savingsProductsToBeSelected={
            customerAccountBalanceSpoolRequestSavingsProducts
          }
          customerAccountBalanceSpoolRequestModel={
            selectedCustomerAccountBalanceSpoolRequest
          }
          setCustomerAccountBalanceSpoolRequestModel={
            setSelectedCustomerAccountBalanceSpoolRequest
          }
          onSetMode={m => {
            setMode(m)
          }}
          mode={mode}
          onHideModal={handleCloseModal}
          setInvestmentProductsToBeSelected={
            setCustomerAccountBalanceSpoolRequestInvestmentProducts
          }
          setSavingsToBeSelected={
            setCustomerAccountBalanceSpoolRequestSavingsProducts
          }
        />
      ) : (
        mode === 'view' && (
          <ViewCustomerAccountBalanceSpoolDataModal
            selectedCustomerAccountBalanceSpoolRequest={
              selectedCustomerAccountBalanceSpoolRequest
            }
            mode={mode}
            close={handleCloseModal}
          />
        )
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}
    </>
  )
}

export default MonthlyAccountBalance
