/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../../Components/Tabs/Tab'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import CustomerAccountsList from '../PreQualification/CustomerAccountsList'
import CustomerLoanApplications from '../PreQualification/CustomerLoanApplications'
import StandingOrders from '../PreQualification/StandingOrders'
import AddressV2 from '../../../../../Components/Miscalleneous/AddressV2'
import LoanDeductions from './LoanDeductions'
import LoanCollaterals from './LoanCollaterals'
import LoanCaseHistory from './LoanCaseHistory'
import LoanGuarantors from './LoanGuarantors'
import ExistingGuarantors from './ExistingGuarantors'
import FixedDepositsLiens from './FixedDepositsLiens'
import LoanApplicationLookup from '../../../../../Components/Lookup/LoanApplicationLookup'

function ViewLoanCase({
  loanCase,
  refreshFromServer,
  readOnly = true,
  onClose,
  asModal = true,
  asPartial,
  onSelectLoanCase,
}) {
  const [loan, setLoan] = useState(loanCase)
  const [isBusy, setIsBusy] = useState(false)

  async function getLoanCase() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanCase/find-loan-case',
      {
        loanCaseId: loan?.id,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setLoan(data)
    }
  }

  useEffect(() => {
    refreshFromServer && getLoanCase()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    asPartial && onSelectLoanCase(loan)
    // eslint-disable-next-line
  }, [loan])

  const tabItems = [
    { label: localize('qualification'), item: <>Qualification</> },
    { label: localize('appraisal'), item: <>Qualification</> },
    { label: localize('repayments'), item: <>Qualification</> },
    {
      label: localize('loanGuarantors'),
      item: <LoanGuarantors loanCase={loan} />,
    },
    {
      label: localize('existingGuarantors'),
      item: <ExistingGuarantors loanCase={loan} />,
    },
    {
      label: localize('loanHistory'),
      item: <LoanCaseHistory loanCase={loan} />,
    },
    {
      label: localize('standingOrders'),
      item: <StandingOrders customer={{ id: loan?.customerId }} />,
    },
    {
      label: localize('loanApplications'),
      item: <CustomerLoanApplications customer={{ id: loan?.customerId }} />,
    },
    {
      label: localize('loanCollaterals'),
      item: <LoanCollaterals loanCase={loan} />,
    },
    {
      label: localize('fixedDepositLiens'),
      item: <FixedDepositsLiens loanCase={loan} />,
    },
    {
      label: localize('deductions'),
      item: <LoanDeductions loanCase={loan} />,
    },
    {
      label: localize('customerAccounts'),
      item: <CustomerAccountsList customer={{ id: loan?.customerId }} />,
    },
    {
      label: localize('address'),
      item: (
        <AddressV2
          refetchFromServer
          parent={{ id: loan?.customerId }}
          readOnly
        />
      ),
    },
    {
      label: localize('specimen'),
      item: (
        <Specimen
          refetchFromServer
          readOnly={readOnly}
          customer={{ id: loan?.customerId }}
        />
      ),
    },
  ]

  function LoanCaseDetails() {
    return isBusy ? (
      <div className="bg-white p-5 d-flex align-items-center justify-content-center">
        <div className="m-2 spinner-border text-dark"></div>
        <span className="fs-4 text-capitalize">fetching loan case</span>
      </div>
    ) : (
      <>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanNumber')}
            </label>
            {asPartial ? (
              <LoanApplicationLookup
                displayValue={loan?.documentReferenceNumber}
                onLookup={l => setLoan(l)}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                disabled={readOnly}
                value={loan?.documentReferenceNumber}
              />
            )}
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.customerFullName}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.paddedCustomerMemberNumber}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.customerPersonalFileNumber}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanProduct')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.loanProductDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanPurpose')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.loanPurposeDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('receivedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={formatDate(loan?.receivedDate, true)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={readOnly}
              value={loan?.documentReferenceNumber}
            />
          </div>
        </div>
        {asPartial ? (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('maximumAmount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(
                    loan?.loanRegistrationMaximumAmount,
                    2,
                    2,
                    false
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('maximumAmount')} %
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatAsPercent(loan?.maximumAmountPercentage)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('amountApplied')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(loan?.amountApplied, 2, 2, false)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('term')} ({localize('months')})
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.loanRegistrationTermInMonths}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('branch')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.branchDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.remarks}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={formatDate(loan?.createdDate)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.createdBy}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-3 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('relationshipManager')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.relationshipManagerId}
                />
              </div>
              <div className="col-3 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('amountApproved')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(loan?.approvedAmount, 2, 2, false)}
                />
              </div>
              <div className="col-6 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-2">
                  {localize('approverRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.approvalRemarks}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('relationshipManager')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={'no field in dto'}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('maximumAmount')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(
                    loan?.loanRegistrationMaximumAmount,
                    2,
                    2,
                    false
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('amountApplied')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(loan?.amountApplied, 2, 2, false)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('term')} ({localize('months')})
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.loanRegistrationTermInMonths}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('disbursementBatch')} #
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={'no field in dto'}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('branch')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.branchDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={formatDate(loan?.createdDate)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.createdBy}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('disbursementMode')} ('am not sure!')
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.savingsProductDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('disbursementCategory')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={
                    loan?.loanDisbursementModeDisbursementCategoryDescription
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('customerAge')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.customerAge}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.statusDescription}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-4">
                  {localize('amountApproved')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  style={{ textAlign: 'end' }}
                  value={formatCurrency(loan?.approvedAmount, 2, 2, false)}
                />
              </div>
              <div className="col-6 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-2">
                  {localize('approverRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={readOnly}
                  value={loan?.approvalRemarks}
                />
              </div>
              <div className="col-3"></div>
            </div>
          </>
        )}

        <div className="my-3 bg-light rounded d-flex align-items-center justify-content-end pt-3 pb-2 px-4 gap-4">
          <div className="d-flex align-items-center gap-2">
            <Checkbox disabled checked={loan?.strictlySelfGuarantee} />
            <label
              htmlFor=""
              className={`text-capitalize ${
                loan?.strictlySelfGuarantee ? 'text-primary' : ''
              }`}
            >
              {localize('strictlySelfGuarantee')}?
            </label>
          </div>

          <div className="d-flex align-items-center gap-2">
            <Checkbox
              disabled
              checked={loan?.loanRegistrationAllowSelfGuarantee}
            />
            <label
              htmlFor=""
              className={`text-capitalize ${
                loan?.loanRegistrationAllowSelfGuarantee ? 'text-primary' : ''
              }`}
            >
              {localize('allowSelfGuarantee')}?
            </label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              disabled
              checked={loan?.loanRegistrationPermitPartialOffsetting}
            />
            <label
              htmlFor=""
              className={`text-capitalize ${
                loan?.loanRegistrationPermitPartialOffsetting
                  ? 'text-primary'
                  : ''
              }`}
            >
              {localize('permitPartialOffsetting')}?
            </label>
          </div>
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              disabled
              checked={loan?.loanRegistrationAllowDiscretionaryTopup}
            />
            <label
              htmlFor=""
              className={`text-capitalize ${
                loan?.loanRegistrationAllowDiscretionaryTopup
                  ? 'text-primary'
                  : ''
              }`}
            >
              {localize('allowDiscrepancyOnTopUp')}?
            </label>
          </div>
        </div>
        <div className="row mx-1">
          <Tab tabItems={tabItems} />
        </div>
      </>
    )
  }

  return asModal ? (
    <ModalTemplate
      actionText={`${localize('print')} (${localize('audit')})`}
      modalClassName="modal-xl"
      handleClose={onClose}
      modalMode={'view'}
      modalTitle={'loanCase'}
    >
      <LoanCaseDetails />
    </ModalTemplate>
  ) : (
    <LoanCaseDetails />
  )
}

export default ViewLoanCase
