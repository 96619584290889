/** @format */

/* eslint-disable */
import React from 'react'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'

import AuxiliaryGuarantorFactors from '../Tabs/AuxiliaryGuarantorFactors'
import AuxiliaryDisbursementFactors from '../Tabs/AuxiliaryDisbursementFactors'
import AuxiliaryInvestmentAppraisalFactors from '../Tabs/AuxiliaryInvestmentAppraisalFactors'
import AuxilliaryLendingConditions from '../Tabs/AuxilliaryLendingConditions'

function AuxilliarySettings({ loan, mode }) {
  const tabItems = [
    {
      label: localize('auxiliaryGuarantorFactors'),
      item: (
        <AuxiliaryGuarantorFactors disabled={mode === 'view'} loan={loan} />
      ),
    },
    {
      label: localize('auxiliaryDisbursementFactors'),
      item: (
        <AuxiliaryDisbursementFactors disabled={mode === 'view'} loan={loan} />
      ),
    },
    {
      label: localize('auxiliaryAppraisalFactors'),
      item: (
        <AuxiliaryInvestmentAppraisalFactors
          loan={loan}
          disabled={mode === 'view'}
        />
      ),
    },
    {
      label: localize('auxiliaryLendingConditions'),
      item: (
        <AuxilliaryLendingConditions loan={loan} disabled={mode === 'view'} />
      ),
    },
  ]
  return (
    <>
      <Tab preload tabItems={tabItems} />
    </>
  )
}

export default AuxilliarySettings
