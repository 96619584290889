/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../Checkbox/Checkbox'
import { PleaseWait } from '../../Loaders/PleaseWait'
function DynamicCharges({
  disabled,
  isBusy,
  dynamicChargesToBeSelected,
  onCheckDynamicCharge,
  prefix,
}) {
  const handleCheckDynamicCharges = (dynamicCharges, checkValue) => {
    const com = [...dynamicChargesToBeSelected].map(comm => {
      if (comm.id === dynamicCharges.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckDynamicCharge(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {dynamicChargesToBeSelected?.map(dynamicCharge => (
            <li
              key={dynamicCharge.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${dynamicCharge.id}`}
                checked={dynamicCharge.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckDynamicCharges(dynamicCharge, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${dynamicCharge.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  dynamicCharge.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {dynamicCharge.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
export default DynamicCharges
