/** @format */

/* eslint-disable */
import React from 'react'
import { generateGuid } from '../../Helpers/extensions'
import './checkbox.scss'

function Checkbox({ checked, onCheck, disabled, id }) {
  const newId = !!id ? id.toString() : generateGuid()
  return (
    <div
      className="checkbox-wrapper-19 text-primary"
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <input
        type="checkbox"
        id={newId}
        checked={!!checked}
        onChange={v => onCheck(v.target.checked)}
        disabled={disabled}
      />
      <label
        htmlFor={newId}
        className="check-box"
        style={{ opacity: `${disabled ? 0.6 : 1} !important` }}
      ></label>
    </div>
  )
}

export default Checkbox
