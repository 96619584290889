/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { dutyStationType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CreateWorkStation from './CreateWorkStation/CreateWorkStation'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import Loader from '../../../../Components/Loaders/Loader'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function WorkStations() {
  const [modalMode, setModalMode] = useState(null)
  const [selectedStation, setSelectedStation] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [gbv, setGbv] = GlobalValidationErrors.use()
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    traverseTree: true,
    updateDepth: true,
  })
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState({})
  const [tableMeta, setTableMeta] = useState([])

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line
  }, [reqParams, refresh])

  const columns = [
    {
      label: localize('description'),
      template: r => (
        <span
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        ></span>
      ),
    },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/dutystation/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }
  const handleClose = () => {
    setModalMode(null)
    setSelectedStation({})
  }

  async function handleCreate() {
    setIsBusy(true)
    const { success } = await postData(
      '/dutyStation/create',
      selectedStation,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Duty WorkStation', 'success')
      handleClose()
    }

    setIsBusy(false)
  }

  async function handleUpdate() {
    setIsBusy(true)
    setRefresh(!refresh)
    const { success } = await putData(
      '/dutyStation/update',
      selectedStation,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Duty WorkStation', 'success')
      handleClose()
    }

    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    setSelectedStation({
      type: dutyStationType.HEADER_ENTRY,
    })
    setModalMode('add')
  }

  return (
    <>
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateWorkStation
          clearValidation={clearValidation}
          gbv={gbv}
          handleClose={handleClose}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          modalMode={modalMode}
          selectedStation={selectedStation}
          setSelectedStation={setSelectedStation}
          isBusy={isBusy}
        />
      )}
      <BreadCrumb
        tree={[localize('registryManagement'), localize('dutyWorkStations')]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3 ms-2">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="traverse"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                  <label className="text-capitalize" htmlFor="traverse">
                    {localize('traverseTree')}
                  </label>
                </div>

                <form
                  className="align-items-center d-flex"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({ ...reqParams, filterText: search })
                  }}
                >
                  <label className="text-capitalize">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams?.filterText}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      loadTableData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <SimpleTable
                data={tableData}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setSelectedStation(r)
                        console.log('This is selected station', r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <div className="row mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default WorkStations
