/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import localize from '../../../../../../Global/Localize'
import { bankReconciliationAdjustmentType } from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const Adjustments = ({
  bankReconciliationPeriodId,
  handleResetSelectedPeriod,
}) => {
  const [adjustment, setAdjustment] = useState({
    adjustmentType: Object.keys(bankReconciliationAdjustmentType)[0],
  })

  const handleSelectPeriod = period => {
    if (!period?.isActive)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected period is not active!',
      })

    setAdjustment({
      ...adjustment,
      ...period,
      postingPeriodDescription: period.description,
      journalEntryJournalPostingPeriodId: period.id,
    })
  }

  const handleSelectGL = account => {
    if (account?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected account is locked!',
      })

    if (!account?.category)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected account  does not have a category!',
      })

    if (account?.isControlAccount)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected account is a control account!',
      })

    if (account?.isReconciliationAccount)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected account is a reconciliation account!',
      })

    if (account?.postAutomaticallyOnly)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected account is a post automatically only!',
      })

    setAdjustment({
      ...adjustment,
      ...account,
      JournalEntryChartOfAccountId: account.id,
      id: adjustment.id,
    })
  }

  const handleBranchSelect = branch => {
    if (branch?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selected branch is locked!',
      })
    setAdjustment({
      ...adjustment,
      ...branch,
      branchDescription: branch.description,
      JournalEntryJournalBranchId: branch.id,
    })
  }

  const handleUpdate = async () => {
    const url = '/BankReconciliationPeriod/create-bank-reconciliation-entry'
    const { success } = await putData(
      url,
      { ...adjustment, bankReconciliationPeriodId },
      false
    )

    if (success) {
      setAdjustment({
        adjustmentType: Object.keys(bankReconciliationAdjustmentType)[0],
      })
      handleResetSelectedPeriod()
      showNotification('Create Bank Reconciliation Entry', 'success')
    }
  }

  return (
    <div className="row g-3 py-4">
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="postingPeriod" className="text-capitalize col-4">
            {localize('postingPeriod')}
          </label>
          <PostingPeriodLookup
            displayValue={adjustment?.postingPeriodDescription}
            onSelect={period => {
              handleSelectPeriod(period)
            }}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="branch" className="text-capitalize col-4">
            {localize('branch')}
          </label>
          <BranchLookup
            displayValue={adjustment?.branchDescription}
            onSelect={handleBranchSelect}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="adjustmentType" className="text-capitalize col-4">
            {localize('adjustmentType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="BankReconciliationAdjustmentType"
            value={adjustment?.adjustmentType}
            onChange={e => {
              setAdjustment({
                ...adjustment,
                adjustmentType: e.value,
              })
            }}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="g/LAccount" className="text-capitalize col-4">
            {localize('g/LAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={adjustment?.categoryDescription}
            onSelect={account => {
              handleSelectGL(account)
            }}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="value" className="text-capitalize col-4">
            {localize('value')}
          </label>
          <MaskNumber
            defaultMaskValue={adjustment?.journalEntryAmount || 0}
            onMaskNumber={e => {
              setAdjustment({
                ...adjustment,
                journalEntryAmount: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex align-items-center">
          <label htmlFor="remarks" className="text-capitalize col-4">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            value={adjustment?.remarks || ''}
            onChange={e => {
              setAdjustment({
                ...adjustment,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="col mx-2 bg-light py-3 px-2 rounded">
        <button onClick={handleUpdate} className="btn btn-success float-end">
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default Adjustments
