/** @format */

import React from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { useState } from 'react'
import localize from '../../../../../Global/Localize'
import { recordAuthOption } from '../../../../../Helpers/constants'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { putData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'

const initialChannel = {
  recordAuthOption: recordAuthOption.APPROVE,
  remarks: '',
}

const VerifyChannelRegister = ({
  mode,
  handleClose,
  fetchChannels,
  channel,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [channelData, setChannelData] = useState(initialChannel)

  const handleVerifyAccount = async () => {
    setIsBusy(true)

    const url = '/AlternateChannel/update-alternate-channel'

    const { success } = await putData(
      url,
      {
        ...channel,
        remarks: channelData?.remarks,
        recordAuthOption: channelData.recordAuthOption,
      },
      false
    )

    if (success) {
      showNotification('Verify Alternate Channel', 'success')
      fetchChannels()
      handleClose()
      setChannelData(initialChannel)
    }
  }

  return (
    <ModalTemplate
      modalTitle="alternateChannel"
      cancelText="close"
      modalMode={mode}
      handleClose={() => {
        handleClose()
        setChannelData(initialChannel)
      }}
      modalClassName="modal-xl"
      handleAction={handleVerifyAccount}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="d-flex align-items-center with-validation">
            <label className="text-capitalize">{localize('remarks')}</label>
            <input
              type="text"
              className="form-control ms-2"
              onChange={e => {
                setChannelData({
                  ...channelData,
                  remarks: e.target.value,
                })
              }}
              disabled={isBusy}
            />
          </div>
          <EnumsServerCombo
            disabled={isBusy}
            customContainerStyle={{
              maxWidth: 150,
              minWidth: 150,
            }}
            enumsKey="RecordAuthOption"
            value={channelData?.recordAuthOption || ''}
            onChange={e => {
              setChannelData({
                ...channelData,
                recordAuthOption: e.value,
              })
            }}
            sort
          />
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('channelType')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2"
                value={channel?.typeDescription}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('dailyLimit')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2 text-end"
                value={formatCurrency(channel?.dailyLimit, null, null, false)}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 me-2">
                {localize('validFrom')}
              </label>

              <input
                type="text"
                disabled
                className="form-control"
                value={channel?.validFrom}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('expires')}
              </label>

              <input
                type="text"
                disabled
                className="form-control ms-2"
                value={channel?.expires}
              />
            </div>
            <div className="col-md-12 d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>

              <input
                className="form-control ms-1"
                value={channel?.remarks}
                disabled
              />
            </div>
          </div>
          <CustomerAccountPartial
            customer={{
              id: channel?.customerAccountId,
            }}
            readOnly
            refreshFromServer
          />
          <div className="mt-2 d-flex justify-content-lg-end align-items-center gap-3">
            <div className="d-flex align-items-center">
              <Checkbox
                checked={channel?.isThirdPartNotified}
                id={'isThirdPartyNotifiedVerifyChannelRegister'}
                disabled
              />

              <label
                className="text-capitalize"
                htmlFor="isThirdPartyNotifiedVerifyChannelRegister"
              >
                {localize('isThirdPartyNotified')} ?
              </label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                disabled
                checked={channel?.isLocked}
                id={'isLockedVerifyChannelRegister'}
              />

              <label
                className="text-capitalize"
                htmlFor="isLockedVerifyChannelRegister"
              >
                {localize('isLocked')} ?
              </label>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifyChannelRegister
