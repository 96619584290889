/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import ListReversalBatches from '../../../../../../Components/ReUsables/ListReversalBatches/ListReversalBatches'
import localize from '../../../../../../Global/Localize'
import {
  batchAuthOptions,
  batchStatus,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import AuthorizeReversalModal from './AuthorizeReversalModal/AuthorizeReversalModal'

function Reversal() {
  const [mode, setMode] = useState(null)
  const [reversal, setReversal] = useState({
    batchAuthOption: Object.keys(batchAuthOptions)[0],
  })
  const [isBusy, setIsBusy] = useState(false)
  const [reload, setReload] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleClose = () => {
    setMode(null)
    setReversal({
      batchAuthOption: Object.keys(batchAuthOptions)[0],
    })
  }

  const handleSelectAuthReversal = data => {
    if (data?.status !== Object.keys(batchStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only authorize a reversal whose status is audited!',
      })

    setMode('authorize')
    setReversal({
      ...reversal,
      ...data,
    })

    clearValidation('Remarks')
  }

  const handleVerifyReversal = async () => {
    if (!reversal?.authRemarks)
      return GlobalValidationErrors.set({
        Remarks: {
          message: 'Remarks is required',
        },
      })

    setIsBusy(true)

    const url = '/JournalReversalBatch/authorize-journal-reversal-batch'
    const body = {
      journalReversalBatch: {
        ...reversal,
        authorizationRemarks: reversal?.authRemarks,
      },
      batchAuthOption: reversal.batchAuthOption,
    }

    const { success } = await putData(url, body, false)

    if (success) {
      handleClose()
      setReload(true)
      showNotification('Authorize Journal Reversal Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <AuthorizeReversalModal
          reversal={reversal}
          handleClose={handleClose}
          mode={mode}
          setReversal={setReversal}
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          handleAuthorizeReversal={handleVerifyReversal}
          isBusy={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('reversal'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListReversalBatches
            showAuthorize
            handleSelectAuthReversal={handleSelectAuthReversal}
            reload={reload}
            setReload={setReload}
            status={Object.keys(batchStatus)[3]}
          />
        </div>
      </div>
    </>
  )
}

export default Reversal
