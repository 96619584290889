/** @format */

import localize from '../../../../../../Global/Localize'
import { DataAttachmentTransactionType } from '../../../../../../Global/enumeration'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
/* eslint-disable */
import React, { useState } from 'react'
import { CustomerAccountsFundsTransferLookup } from '../../../../../../Components/Lookup/CustomerAccountsFundsTransferLookup'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const ExistingCustomerDataEntryDetails = ({
  onSelectCustomerAccount,
  onSelectDataAttachmentTransactionType,
  initializeDataAttachmentEntryModel,
  setInitializeDataAttachmentEntryModel,
}) => {
  const [selectedExisting, setSelectedExistingCustomer] = useState(null)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)

  const [
    selectedDataAttachmentTransactionType,
    setSelectedDataAttachmentTransactionType,
  ] = useState(Object.keys(DataAttachmentTransactionType)[0])

  const [focusedInputs, setFocusedInputs] = useState({
    newAbility: true,
    currentBalance: true,
    currentAmount: true,
    newBalance: true,
  })

  const handleOnSelectCustomer = r => {
    setSelectedExistingCustomer({ ...r, customerId: r.id })
  }

  return (
    <>
      <div className="row mb-3">
        <CustomerPartial
          customer={selectedExisting}
          onCustomerLookup={handleOnSelectCustomer}
        />
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('entryDetails')}
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <CustomerAccountsFundsTransferLookup
              accountsFundsTransfer={
                selectedCustomerAccount?.fullAccountNumber || ''
              }
              displayValue={selectedCustomerAccount?.fullAccountNumber}
              selectedCustomerAccount={selectedExisting}
              onSelect={r => {
                setSelectedCustomerAccount(r)
                onSelectCustomerAccount(r)
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.statusDescription}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.remarks}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                selectedCustomerAccount?.customerAccountTypeTargetProductDescription
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="DataAttachmentTransactionType"
              value={selectedDataAttachmentTransactionType}
              onChange={e => {
                setSelectedDataAttachmentTransactionType(e.value)
                onSelectDataAttachmentTransactionType(e.value)
              }}
              sort
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('sequenceNumber')}
            </label>
            <input
              type="number"
              className="form-control"
              onChange={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  sequenceNumber: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control"
              defaultValue={initializeDataAttachmentEntryModel?.reference}
              onChange={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  reference: e.target.value,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              className="form-control"
              defaultValue={initializeDataAttachmentEntryModel?.remarks}
              onChange={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('currentAmount')}
            </label>
            {focusedInputs.currentAmount ? (
              <input
                onFocus={() => {
                  setFocusedInputs({
                    ...focusedInputs,
                    currentAmount: false,
                  })
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    currentAmount: 0,
                  })
                }}
                value={formatCurrency(
                  initializeDataAttachmentEntryModel?.currentAmount,
                  2,
                  2,
                  false
                )}
                className="form-control"
              />
            ) : (
              <MaskNumber
                onMaskNumber={e => {
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    currentAmount: e.target.value,
                  })
                }}
                defaultMaskValue={
                  initializeDataAttachmentEntryModel?.currentAmount
                }
              />
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('currentBalance')}
            </label>
            {focusedInputs.currentBalance ? (
              <input
                onFocus={() => {
                  setFocusedInputs({
                    ...focusedInputs,
                    currentBalance: false,
                  })
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    currentBalance: 0,
                  })
                }}
                className={'form-control text-end'}
                value={formatCurrency(
                  initializeDataAttachmentEntryModel?.currentBalance,
                  2,
                  2,
                  false
                )}
              />
            ) : (
              <MaskNumber
                defaultMaskValue={
                  initializeDataAttachmentEntryModel?.currentBalance
                }
                onMaskNumber={c => {
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    currentBalance: c.target.value,
                  })
                }}
              />
            )}
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('currentAbility')}
            </label>
            <MaskNumber
              onMaskNumber={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  currentAbility: e.target.value,
                })
              }}
              defaultMaskValue={
                initializeDataAttachmentEntryModel?.currentAbility
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('newAmount')}
            </label>
            <MaskNumber
              onMaskNumber={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  newAmount: e.target.value,
                })
              }}
              defaultMaskValue={initializeDataAttachmentEntryModel?.newAmount}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('newBalance')}
            </label>
            {focusedInputs.newBalance ? (
              <input
                className="form-control text-end"
                value={formatCurrency(
                  initializeDataAttachmentEntryModel?.newBalance,
                  2,
                  2,
                  false
                )}
                onFocus={e => {
                  setFocusedInputs({
                    ...focusedInputs,
                    newBalance: false,
                  })
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    newBalance: 0,
                  })
                }}
              />
            ) : (
              <MaskNumber
                onMaskNumber={e => {
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    newBalance: e.target.value,
                  })
                }}
                defaultMaskValue={
                  initializeDataAttachmentEntryModel?.newBalance
                }
              />
            )}
          </div>
          <div className="col-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('newAbility')}
            </label>
            {focusedInputs.newAbility ? (
              <input
                className="form-control text-end"
                value={formatCurrency(
                  initializeDataAttachmentEntryModel?.newAbility,
                  2,
                  2,
                  false
                )}
                onFocus={e => {
                  setFocusedInputs({
                    ...focusedInputs,
                    newAbility: false,
                  })
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    newAbility: 0,
                  })
                }}
              />
            ) : (
              <MaskNumber
                onMaskNumber={r => {
                  setInitializeDataAttachmentEntryModel({
                    ...initializeDataAttachmentEntryModel,
                    newAbility: r.target.value,
                  })
                }}
                defaultMaskValue={
                  initializeDataAttachmentEntryModel?.newAbility
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
