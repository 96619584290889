/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import DirectPurchaseEntriesItems from './DirectPurchaseEntriesItems'
import { formatDate } from '../../../../../../Helpers/formatters'
import DirectPurchaseApprovals from './DirectPurchaseApprovals'

const ViewDirectPurchase = ({ mode, closeModal, directPurchase }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [approvalEntries, setApprovalEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/DirectPurchase/find-direct-purchase-item-by-direct-purchase-id-and-filter-in-page`,
      {
        directPurchaseId: directPurchase?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const loadApprovalEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DirectPurchase/find-direct-purchase-approvals',
      {
        directPurchaseId: directPurchase?.id,
      },
      false
    )
    if (success) {
      setApprovalEntries(data.result)
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    loadApprovalEntries()
    // eslint-disable-next-line
  }, [directPurchase?.id, reqParams.pageSize, reqParams.pageIndex])

  const closePage = () => {
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={'view'}
      modalTitle="directPurchase"
      handleClose={closePage}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.createdBy}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={formatDate(directPurchase?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('modifiedBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.modifiedBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('modifiedDate')}
              </label>
              <input
                className="form-control "
                disabled
                defaultValue={formatDate(directPurchase?.modifiedDate, true)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('authorizedDate')}
              </label>
              <input
                className="form-control "
                disabled
                defaultValue={formatDate(directPurchase?.authorizedDate, true)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('recordStatus')}
              </label>
              <input
                className="form-control  "
                disabled
                defaultValue={directPurchase?.recordStatusDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  me-2">
                {localize('remarks')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.remarks}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('directPurchaseItems'),
                  item: (
                    <DirectPurchaseEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
                {
                  label: localize('approvals'),
                  item: <DirectPurchaseApprovals entries={approvalEntries} />,
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewDirectPurchase
