/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './floatingmenu.scss'

function FloatingContextMenu({ children }) {
  const [useFloatingMenu, setUseFloatingMenu] = useState(false)
  useEffect(() => {
    loadSetting()
  }, [])
  async function loadSetting() {
    let val = await localStorage.getItem('useFloatingMenu')
    setUseFloatingMenu(val === 'true')
  }
  return (
    useFloatingMenu && (
      <div
        className="floating-context-menu"
        onClick={() => {
          console.log(children)
        }}
      >
        {children}
      </div>
    )
  )
}

export default FloatingContextMenu
