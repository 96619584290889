/** @format */

import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import InventoryMovementHistories from './InventoryMovementHistories'
import localize from '../../../../../Global/Localize'

const ViewInventory = ({ mode, closeModal, inventory }) => {
  function handleCloseModal() {
    closeModal()
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="inventory"
        handleClose={handleCloseModal}
        modalClassName="modal-xl"
        hideUpdate
      >
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col text-capitalize">{localize('name')}</label>
              <input
                type="text"
                className="form-control ms-3 me-2"
                value={inventory?.itemRegisterDescription || ''}
                disabled
              />
            </div>
          </div>
          <div className="row col-12 mt-2">
            <Tab
              tabItems={[
                {
                  label: localize('movementHistory'),
                  item: <InventoryMovementHistories inventory={inventory} />,
                },
              ]}
            />
          </div>
        </>
      </ModalTemplate>
    </>
  )
}

export default ViewInventory
