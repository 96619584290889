/** @format */
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
/* eslint-disable */
import React from 'react'
import { creditBatchFlagsList } from '../Extensions/creditBatchFlags'

export const CreditBatchFlags = ({
  flags = creditBatchFlagsList,
  setFlags = () => {},
  prefix,
  editMode,
}) => {
  const handleCheckboxChange = i => {
    setFlags(
      [flags.length === 0 ? creditBatchFlagsList : flags].map((flag, index) => {
        if (index === i) {
          return { ...flag, checked: !flag.checked }
        }
        return flag
      })
    )
  }
  return (
    <div className="row mt-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      {creditBatchFlagsList
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t, i) => (
          <div
            className="d-flex align-items-center col-3"
            key={`${t.value}-${i}`}
          >
            <Checkbox
              id={
                editMode
                  ? `${prefix}-edit-${t.value}`
                  : `${prefix}-add-${t.value}`
              }
              checked={t.checked}
              onCheck={r => {
                handleCheckboxChange(i)
              }}
            />
            <label
              htmlFor={
                editMode
                  ? `${prefix}-edit-${t.value}`
                  : `${prefix}-add-${t.value}`
              }
              className={`fw-normal ms-2 cursor-pointer ${
                t.checked ? 'text-primary fw-bold' : ''
              }`}
            >
              {t.name}
            </label>
          </div>
        ))}
    </div>
  )
}
