/** @format */
import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { SupplierLookup } from '../../../../../../Components/Lookup/SupplierLookup'
import PdfFileLookup from '../../../../../../Components/Lookup/PdfFileLookup'
import PurchaseOrderLookup from '../../../../../../Components/Lookup/PurchaseOrderLookup'
import PurchaseOrderItemLookup from '../../../../../../Components/Lookup/PurchaseOrderItemLookup'
import LocationLookup from '../../../../../../Components/Lookup/LocationLookup'
import GoodsReceivedNoteEntriesItems from './GoodsReceivedNoteEntriesItems'

const AddgoodsReceivedNote = ({
  edit,
  mode,
  closeModal,
  goodsReceivedNote,
  setGoodsReceivedNote,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState(null)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsReceivedNote/find-goods-received-note-items-by-goods-received-note-id-filter-in-page`,
      {
        goodsReceivedNoteId: goodsReceivedNote?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [goodsReceivedNote?.id, reqParams.pageSize, reqParams.pageIndex])

  const addEntryToTheDB = async () => {
    if (!entry) {
      showNotification(
        'goodsReceivedNote',
        'info',
        'Sorry, but you must first select a received notes item!'
      )
    } else {
      if (
        existingEntries.find(
          d => d.goodsReceivedNoteItemId === entry.goodsReceivedNoteItemId
        )
      ) {
        showNotification(
          'goodsReceivedNote',
          'info',
          'Sorry, but the selected received notes item is already added !'
        )
      } else {
        setIsBusy(true)
        const { success } = await postData(
          '/GoodsReceivedNote/update-goods-received-note-items-by-goods-received-note-id',
          {
            goodsReceivedNoteId: goodsReceivedNote?.id,
            goodsReceivedNoteItems: [entry],
          },
          false
        )
        if (success) {
          showNotification('goods Received Note', 'success')
          loadEntries()
          setEntry(null)
          setEntry({ quantity: '', unitCost: '' })
        }
        setIsBusy(false)
      }
    }
  }

  async function postGoodsReceivedNote() {
    setIsBusy(true)
    const { success } = await postData(
      '/GoodsReceivedNote/add-goods-received-note-with-items',
      {
        goodsReceivedNote: { ...goodsReceivedNote, recordStatus: 0 },
        goodsReceivedNoteItems: entries,
      },
      false
    )
    if (success) {
      showNotification('goodsReceivedNote', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  async function editGoodsReceivedNote() {
    setIsBusy(true)
    const { success } = await postData(
      '/GoodsReceivedNote/update-goods-received-note-with-items',
      {
        goodsReceivedNote: {
          ...goodsReceivedNote,
          id: goodsReceivedNote.id,
          remarks: goodsReceivedNote?.remarks,
        },
        goodsReceivedNoteItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Goods Received Note', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.goodsReceivedNoteId !== cust?.goodsReceivedNoteId
      )
      setEntries(newEntry)
    }
  }

  async function stageEntry() {
    if (
      entries.find(
        d => d.goodsReceivedNoteItemId === entry.goodsReceivedNoteItemId
      )
    ) {
      showNotification(
        'Goods Received Note',
        'info',
        'Sorry, but the selected received notes item is already added !'
      )
    } else {
      setEntries([
        ...entries,
        {
          goodsReceivedNoteId: goodsReceivedNote?.id,
          itemRegisterItemTypeDescription:
            entry?.itemRegisterItemTypeDescription,
          itemRegisterId: entry?.itemRegisterId,
          goodsReceivedNoteItemId: entry?.goodsReceivedNoteItemId,
          itemRegisterDescription: entry?.itemRegisterDescription,
          itemRegisterInventoryCategoryDescription:
            entry?.itemRegisterInventoryCategoryDescription,
          itemRegisterAssetTypeDescription:
            entry?.itemRegisterAssetTypeDescription,
          itemRegisterInventoryUnitOfMeasureDescription:
            entry?.itemRegisterInventoryUnitOfMeasureDescription,
          quantity: entry?.quantity,
          unitCost: entry?.unitCost,
          totalCost: entry?.totalCost,
          status: entry?.status,
          model: entry?.model,
          manufacturer: entry?.manufacturer,
          serialNumber: entry?.serialNumber,
        },
      ])
      setEntry(null)
      setEntry({ quantity: '', unitCost: '' })
    }
  }
  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const closePage = () => {
    setGlobalValidationErrors({})
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={edit ? 'edit' : 'add'}
      modalTitle="goodsReceivedNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      actionText={edit ? 'update' : 'create'}
      handleAction={() => {
        if (mode === 'add') {
          if (!entries) {
            showNotification(
              'Goods Received Note',
              'info',
              'sorry, but there should be at least one  item added to the goodsReceivedNote'
            )
          } else {
            postGoodsReceivedNote()
          }
        } else {
          editGoodsReceivedNote()
        }
      }}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['goodsReceivedNote.SupplierId']?.message
              }
            >
              <label className="text-capitalize col-2 me-1">
                {localize('supplier')}
              </label>
              <SupplierLookup
                displayValue={goodsReceivedNote?.supplierDescription}
                onSelect={r => {
                  clearValidation('goodsReceivedNote.SupplierId')
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    supplierId: r.id,
                    supplierDescription: r.description,
                    supplierAddressMobileLine: r.addressMobileLine,
                    supplierAddressEmail: r.addressEmail,
                    supplierCreditPeriod: r.creditPeriod,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize me-3">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.supplierAddressMobileLine}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control ms-1"
                disabled
                value={goodsReceivedNote?.supplierAddressEmail}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize me-3">
                {localize('location')}
              </label>
              <LocationLookup
                displayValue={goodsReceivedNote?.locationDescription}
                onSelect={r => {
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    locationId: r.id,
                    locationDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['goodsReceivedNote.FileName']?.message
              }
            >
              <label className="col-2 text-capitalize me-1">
                {localize('document')}
              </label>
              <PdfFileLookup
                displayValue={goodsReceivedNote?.fileName}
                acceptedFiles={['application/pdf']}
                onSelect={r => {
                  clearValidation('goodsReceivedNote.FileName')
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    goodsReceivedNoteDocument: r.bufferString,
                    fileName: r.fileName,
                    fileExtension: 'pdf',
                  })
                }}
              />
            </div>

            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('document#')}
              </label>
              <input
                className="form-control ms-3"
                onChange={e =>
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    documentNumber: e.target.value,
                  })
                }
                value={goodsReceivedNote?.documentNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['goodsReceivedNote.FileTitle']?.message
              }
            >
              <label className="text-capitalize col-1 me-1">
                {localize('title')}
              </label>
              <input
                className="form-control"
                onChange={e => {
                  clearValidation('goodsReceivedNote.FileTitle')
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    fileTitle: e.target.value,
                  })
                }}
                value={goodsReceivedNote?.fileTitle || ''}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['goodsReceivedNote.FileDescription']
                  ?.message
              }
            >
              <label className="text-capitalize col-1 me-1">
                {localize('description')}
              </label>
              <textarea
                className="form-control"
                onChange={e => {
                  clearValidation('goodsReceivedNote.FileDescription')
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    fileDescription: e.target.value,
                  })
                }}
                value={goodsReceivedNote?.fileDescription || ''}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-1 me-1">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                onChange={e =>
                  setGoodsReceivedNote({
                    ...goodsReceivedNote,
                    remarks: e.target.value,
                  })
                }
                value={goodsReceivedNote?.remarks || ''}
              />
            </div>
          </div>
          <>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('itemDetails')}
              </div>

              <>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize me-3">
                      {localize('purchaseOrder')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: goodsReceivedNote?.supplierId ? 1 : 0.6,
                        pointerEvents: goodsReceivedNote?.supplierId
                          ? 'all'
                          : 'none',
                      }}
                    >
                      <PurchaseOrderLookup
                        displayValue={entry?.paddedReferenceNumber}
                        onSelect={r => {
                          setEntry({
                            ...entry,
                            noteId: r.id,
                            paddedReferenceNumber: r.paddedReferenceNumber,
                          })
                        }}
                        supplierId={goodsReceivedNote?.supplierId}
                        supplierDescription={
                          goodsReceivedNote?.supplierDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize me-3">
                      {localize('itemDescription')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: entry?.noteId ? 1 : 0.6,
                        pointerEvents: entry?.noteId ? 'all' : 'none',
                      }}
                    >
                      <PurchaseOrderItemLookup
                        displayValue={entry?.itemRegisterDescription}
                        onSelect={r => {
                          setEntry({
                            ...entry,
                            goodsReceivedNoteItemId: r.id,
                            itemRegisterDescription: r.itemRegisterDescription,
                            itemRegisterAssetTypeDescription:
                              r.itemRegisterAssetTypeDescription,

                            itemRegisterItemTypeDescription:
                              r.itemRegisterItemTypeDescription,
                            itemRegisterId: r.itemRegisterId,
                            itemRegisterInventoryCategoryDescription:
                              r.itemRegisterInventoryCategoryDescription,
                            itemRegisterInventoryUnitOfMeasureDescription:
                              r.itemRegisterInventoryUnitOfMeasureDescription,
                            quantity: r.quantity,
                            unitCost: r.unitCost,
                            totalCost: r.totalCost,
                            status: r.status,
                          })
                        }}
                        purchaseOrderId={entry?.noteId}
                        noteRefNumber={entry?.paddedReferenceNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('assetType')}
                    </label>
                    <input
                      className="form-control ms-3"
                      disabled
                      displayValue={entry?.itemRegisterAssetTypeDescription}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize">
                      {localize('unitOfMeasure')}
                    </label>
                    <input
                      className="form-control ms-3"
                      disabled
                      displayValue={
                        entry?.itemRegisterInventoryUnitOfMeasureDescription
                      }
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('quantity')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.quantity}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize">
                      {localize('unitCost')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.unitCost}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-1 mt-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('model')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          model: e.target.value,
                        })
                      }
                      value={entry?.model || ''}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize">
                      {localize('manufacturer')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          manufacturer: e.target.value,
                        })
                      }
                      value={entry?.manufacturer || ''}
                    />
                  </div>
                </div>
                <div className="row mb-1 mt-2">
                  <div className="col-md-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('serialNumber')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          serialNumber: e.target.value,
                        })
                      }
                      value={entry?.serialNumber || ''}
                    />
                  </div>
                </div>
              </>
            </div>

            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  if (mode === 'add') {
                    if (!entry?.model) {
                      showNotification(
                        'Goods Received Note',
                        'info',
                        'please enter model'
                      )
                    } else if (!entry?.manufacturer) {
                      showNotification(
                        'Goods Received Note',
                        'info',
                        'please enter manufacturer'
                      )
                    } else if (!entry?.serialNumber) {
                      showNotification(
                        'Goods Received Note',
                        'info',
                        'please enter serial number'
                      )
                    } else {
                      stageEntry()
                    }
                  } else {
                    addEntryToTheDB()
                  }
                }}
                className="btn btn-success px-5"
              >
                {localize('add')}
              </button>
            </div>
            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: localize('goodsReceivedNoteItems'),
                    item: (
                      <GoodsReceivedNoteEntriesItems
                        edit={edit}
                        unpostedEntries={entries}
                        handleRemove={handleRemove}
                        mode={mode}
                        entries={existingEntries}
                        onSelectItem={onSelectItem}
                        loadEntries={loadEntries}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddgoodsReceivedNote
