/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../../Components/Loaders/Loader'
import ListReversalBatches from '../../../../../../Components/ReUsables/ListReversalBatches/ListReversalBatches'
import localize from '../../../../../../Global/Localize'
import {
  batchAuthOptions,
  batchStatus,
} from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import VerifyReversalModal from './VerifyReversalModal/VerifyReversalModal'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

function Reversal() {
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reversal, setReversal] = useState({
    batchAuthOption: Object.keys(batchAuthOptions)[0],
  })
  const [reload, setReload] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleClose = () => {
    setMode(null)
    setReversal({
      batchAuthOption: Object.keys(batchAuthOptions)[0],
    })
  }

  const handleSelectVerifyReversal = data => {
    if (data.status !== Object.keys(batchStatus)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only verify pending batches',
      })

    setMode('verify')
    setReversal({
      ...reversal,
      ...data,
    })
    clearValidation('Remarks')
  }

  const handleVerifyReversal = async () => {
    setIsBusy(true)
    if (!reversal?.verificationRemarks)
      return GlobalValidationErrors.set({
        Remarks: {
          message: 'Remarks is required',
        },
      })

    const url = '/JournalReversalBatch/audit-journal-reversal-batch'
    const body = {
      journalReversalBatch: {
        ...reversal,
        auditRemarks: reversal?.verificationRemarks,
      },
      batchAuthOption: reversal.batchAuthOption,
    }

    const { success } = await putData(url, body, false)

    if (success) {
      handleClose()
      setReload(true)
      showNotification('Verify Journal Reversal Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <VerifyReversalModal
          reversal={reversal}
          handleClose={handleClose}
          mode={mode}
          setReversal={setReversal}
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          handleVerifyReversal={handleVerifyReversal}
          isBusy={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('reversal'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListReversalBatches
            showVerify
            handleVerifyReversal={handleSelectVerifyReversal}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  )
}

export default Reversal
