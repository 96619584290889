/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../Components/Loaders/Loader'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import CreditType from '../../../../../Components/ReUsables/RecurringProcedures/CreditType/CreditType'
import CustomersTab from '../../../../../Components/ReUsables/RecurringProcedures/CustomersTab/CustomersTab'
import EmployersTab from '../../../../../Components/ReUsables/RecurringProcedures/EmployersTab/EmployersTab'
import LoansTab from '../../../../../Components/ReUsables/RecurringProcedures/LoansTab/LoansTab'
import StandingOrderTriggers from '../../../../../Components/ReUsables/StandingOrderTriggers/StandingOrderTriggers'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  QueuePriority,
  months,
  standingOrderExecutionFilter,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import {
  getCurrentMonth,
  getTodaysDate,
  parseDate,
} from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'

function LoanInterestCharging() {
  const [isBusy, setIsBusy] = useState(false)
  const [dynamicSavingsFees, setDynamicSavingsFees] = useState({
    month: Object.keys(months).find(
      key => months[key].name === getCurrentMonth()
    ),
    queuePriority: Object.keys(QueuePriority)[3],
    StandingOrderExecutionFilter: Object.keys(standingOrderExecutionFilter)[0],
    valueDate: parseDate(getTodaysDate()),
  })
  const [employers, setEmployers] = useState([])
  const [selectAllEmployers, setSelectAllEmployers] = useState(false)
  const [customers, setCustomers] = useState([])
  const [selectAllCustomers, setSelectAllCustomers] = useState(false)
  const [creditTypes, setCreditTypes] = useState([])
  const [selectAllCreditTypes, setSelectAllCreditTypes] = useState(false)
  const [isBusyLoadingValueDate, setIsBusyLoadingValueDate] = useState(false)
  const [loanProducts, setLoanProducts] = useState([])
  const [triggers, setTriggers] = useState([])
  const [resetValues, setResetValues] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    Object.keys(standingOrderExecutionFilter)[0]
  )
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)

  const toggleSelectedTab = tab => {
    setSelectedTab(tab)
  }

  const fetchPostingPeriod = async () => {
    setIsBusy(true)
    const url = '/PostingPeriod/find-current-posting-period'

    const { data, success } = await getData(url, null, false)

    if (success) {
      setIsBusy(false)
      setDynamicSavingsFees({
        ...dynamicSavingsFees,
        ...data,
      })
    } else {
      setIsBusy(false)
    }
  }

  const getUpdateMethod = () => {
    return selectedTab === Object.keys(standingOrderExecutionFilter)[0]
      ? handleUpdateEmployers()
      : selectedTab === Object.keys(standingOrderExecutionFilter)[1]
      ? handleUpdateCustomers()
      : handleUpdateCreditTypes()
  }

  const handleEmployersChange = employers => {
    setEmployers(employers)
  }

  const handleSelect = r => {
    if (r.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'This period is locked',
      })

    setDynamicSavingsFees({
      ...dynamicSavingsFees,
      ...r,
    })
  }

  const handleSelectAllEmployers = value => {
    setSelectAllEmployers(value)
  }

  const handleSelectAllCredits = value => {
    setCreditTypes(value)
  }

  const handleSelectIndividualCredits = value => {
    setCreditTypes(value)
  }

  const toggleSelectAllCredits = value => {
    setSelectAllCreditTypes(value)
  }

  const handleSelectCustomer = value => {
    setCustomers(value)
  }

  const toggleSelectAllCustomers = value => {
    setSelectAllCustomers(value)
  }

  const handleSelectLoanProducts = value => {
    setLoanProducts(value)
  }

  const handleSelectTriggers = value => {
    setTriggers(value)
  }

  const handleUpdateCreditTypes = async () => {
    if (!creditTypes.length)
      return Swal.fire({
        icon: 'error',
        title: 'No credit types selected',
        text: 'Please select a credit type',
      })

    if (!loanProducts.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select a loan product',
      })

    if (!triggers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'There are no standing order triggers selected.',
      })

    setIsBusy(true)

    const url =
      '/RecurringBatch/capitalize-interest-by-credit-types-and-loan-products'

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        creditTypeDTOs: creditTypes,
        loanProductDTOs: loanProducts,
        standingOrderTriggers: triggers,
        selectAllCreditTypes,
      },
      false
    )

    if (success) {
      setCreditTypes([])
      setResetValues(true)
      showNotification(
        'Capitalize Interest By Credit Types And Loan Products',
        'success'
      )
    }

    setIsBusy(false)
  }

  const handleUpdateEmployers = async () => {
    const url =
      '/RecurringBatch/capitalize-interest-by-employers-and-loan-products'

    if (!employers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select an employer',
      })

    if (!loanProducts.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select a loan product',
      })

    if (!triggers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'There are no standing order triggers selected.',
      })

    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        employerDTOs: employers,
        loanProductDTOs: loanProducts,
        standingOrderTriggers: triggers,
        selectAllEmployers,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setEmployers([])
      setResetValues(true)
      showNotification(
        'Capitalize Interest By Employers And Loan Products',
        'success'
      )
    }

    setIsBusy(false)
  }

  const handleUpdateCustomers = async () => {
    const url =
      '/RecurringBatch/capitalize-interest-by-customers-and-loan-products'

    if (!customers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select a customer',
      })

    if (!loanProducts.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'Please select a loan product',
      })

    if (!triggers.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation failed',
        text: 'There are no standing order triggers selected.',
      })

    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        customerDTOs: customers,
        loanProductDTOs: loanProducts,
        standingOrderTriggers: triggers,
        selectAllCustomers,
      },
      false
    )

    if (success) {
      setCustomers([])
      setResetValues(true)
      showNotification(
        'Capitalize Interest By Customers And Loan Products',
        'success'
      )
    }

    setIsBusy(false)
  }

  const evaluateValueDate = async () => {
    setIsBusyLoadingValueDate(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, {}, false)
    if (success && data.result) {
      setIsBusyLoadingValueDate(false)
      setUpdateDate(false)
      setDynamicSavingsFees({
        ...dynamicSavingsFees,
        valueDate: parseDate(valueDate),
      })
    } else {
      setIsBusyLoadingValueDate(false)
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  useEffect(() => {
    fetchPostingPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('loanInterestCharging'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3 mb-3">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="postingPeriod"
                      className="text-capitalize col-4"
                    >
                      {localize('postingPeriod')}
                    </label>
                    <PostingPeriodLookup
                      displayValue={dynamicSavingsFees?.description}
                      onSelect={handleSelect}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="referenceMonth"
                      className="text-capitalize col-4"
                    >
                      {localize('referenceMonth')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="Month"
                      value={dynamicSavingsFees.month}
                      onChange={e => {
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          month: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label htmlFor="priority" className="text-capitalize col-4">
                      {localize('priority')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="QueuePriority"
                      value={dynamicSavingsFees.queuePriority}
                      onChange={e => {
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          queuePriority: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="executionFilter"
                      className="text-capitalize col-4"
                    >
                      {localize('executionFilter')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="standingOrderExecutionFilter"
                      value={dynamicSavingsFees.StandingOrderExecutionFilter}
                      onChange={e => {
                        toggleSelectedTab(e.value)
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          StandingOrderExecutionFilter: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  {isBusyLoadingValueDate ? (
                    <span className="spinner-border spinner-border-md" />
                  ) : (
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="valueDate"
                        className="text-capitalize col-4"
                      >
                        {localize('valueDate')}
                      </label>
                      <CustomDatePicker
                        name="standingOrderExecutionValueDate"
                        defaultDate={valueDate}
                        onDateChange={date => {
                          setValueDate(date)
                        }}
                        setDateToNull={updateDate}
                        setUpdateDate={setUpdateDate}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row g-3 mb-3">
                <Tab
                  preload
                  tabItems={[
                    {
                      label: localize(
                        dynamicSavingsFees?.StandingOrderExecutionFilter ===
                          Object.keys(standingOrderExecutionFilter)[0]
                          ? 'employers'
                          : dynamicSavingsFees?.StandingOrderExecutionFilter ===
                            Object.keys(standingOrderExecutionFilter)[1]
                          ? 'customers'
                          : 'creditTypes'
                      ),
                      item: (
                        <div className="ps-1">
                          {dynamicSavingsFees?.StandingOrderExecutionFilter ===
                          Object.keys(standingOrderExecutionFilter)[0] ? (
                            <EmployersTab
                              handleSelectAllEmployers={
                                handleSelectAllEmployers
                              }
                              handleSelectedEmployersChange={
                                handleEmployersChange
                              }
                              handleUpdateEmployers={handleUpdateEmployers}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                            />
                          ) : dynamicSavingsFees?.StandingOrderExecutionFilter ===
                            Object.keys(standingOrderExecutionFilter)[1] ? (
                            <CustomersTab
                              handleSelectCustomer={handleSelectCustomer}
                              toggleSelectAllCustomers={
                                toggleSelectAllCustomers
                              }
                              handleUpdateCustomers={handleUpdateCustomers}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                              showImport
                            />
                          ) : (
                            <CreditType
                              handleSelectAllCredits={handleSelectAllCredits}
                              handleSelectIndividualCredits={
                                handleSelectIndividualCredits
                              }
                              toggleSelectAllCredits={toggleSelectAllCredits}
                              handleUpdateCreditTypes={handleUpdateCreditTypes}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                            />
                          )}
                        </div>
                      ),
                    },

                    {
                      label: localize('loanProducts'),
                      item: (
                        <LoansTab
                          handleUpdateLoanProducts={getUpdateMethod}
                          handleSelectLoanProducts={handleSelectLoanProducts}
                          setResetValues={setResetValues}
                          resetValues={resetValues}
                        />
                      ),
                    },
                    {
                      label: localize('standingOrderTriggers'),
                      item: (
                        <StandingOrderTriggers
                          handleSelectTriggers={handleSelectTriggers}
                          handleUpdate={getUpdateMethod}
                          setResetValues={setResetValues}
                          resetValues={resetValues}
                          id={'standingOrderTriggers'}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default LoanInterestCharging
