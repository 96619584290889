/** @format */

import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import AddressV2 from '../../../../Components/Miscalleneous/AddressV2'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../../Helpers/extensions'

function DependantsPartial({ dependant }) {
  const [dep, setDep] = useState(null)
  const [dependants, setDependants] = useState([])

  const columns = [
    { label: localize('select'), template: r => <></> },
    { label: localize('name'), template: r => <>{r?.fullName}</> },
    { label: localize('gender'), template: r => <></> },
    { label: localize('relationship'), template: r => <></> },
    { label: localize('addressLine') + ' 1', template: r => <></> },
    { label: localize('addressLine') + ' 2', template: r => <></> },
    { label: localize('physicalAddress'), template: r => <></> },
    { label: localize('postalCode'), template: r => <></> },
    { label: localize('city'), template: r => <></> },
    { label: localize('emailAddress'), template: r => <></> },
    { label: localize('landLine'), template: r => <></> },
    { label: localize('mobileLine'), template: r => <></> },
    { label: localize('internetAddress'), template: r => <></> },
    { label: localize('remarks'), template: r => <></> },
  ]

  async function stageDep() {
    if (!dep?.firstName || !dep?.lastName)
      return Swal.fire(
        'Error',
        'First Name and Other Name is required',
        'error'
      )
    setDependants([
      ...dependants,
      {
        id: generateGuid(),
        ...dep,
        fullName: dep?.firstName + ' ' + dep.lastName,
      },
    ])
    setDep(null)
  }

  return (
    <div>
      <div className="row px-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('salutation')}
          </label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey="Salutation"
              value={dep?.salutation}
              onChange={e =>
                setDep({
                  ...dep,
                  salutation: e.value,
                  salutationDescription: e.label,
                })
              }
            />
          </div>
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('gender')}
          </label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey="Gender"
              value={dep?.gender}
              onChange={e =>
                setDep({ ...dep, gender: e.value, genderDescription: e.label })
              }
            />
          </div>
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('relationship')}
          </label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey={
                dependant
                  ? 'DependantRelationship'
                  : 'EmergencyContactRelationship'
              }
              value={dep?.relationship}
              onChange={e =>
                setDep({
                  ...dep,
                  relationship: e.value,
                  relationshipDescription: e.label,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 px-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={dep?.firstName}
            onChange={e => setDep({ ...dep, firstName: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={dep?.lastName}
            onChange={e => setDep({ ...dep, lastName: e.target.value })}
          />
        </div>
        <div className="col"></div>
      </div>
      <div className="rounded p-2 mt-2 bg-light">
        <AddressV2 onChange={a => setDep({ ...dep, ...a })} />
      </div>
      <div
        className="border border-secondary rounded p-2 bg-light d-flex align-items-center justify-content-end mt-2"
        onClick={stageDep}
      >
        <button className="btn btn-success px-5">{localize('add')}</button>
      </div>
      <div
        className="border border-secondary mt-3 rounded p-2 pt-3"
        style={{ position: 'relative' }}
      >
        <div
          className="px-2 text-capitalize bg-white"
          style={{ maxWidth: 'fit-content', position: 'absolute', top: -13 }}
        >
          {localize(dependant ? 'dependants' : 'emergencyContacts')}
        </div>
        <SimpleTable columns={columns} data={dependants} />
        <div className="mt-2 rounded bg-light d-flex align-items-center justify-content-end p-2 border border-secondary">
          <button className="btn btn-danger px-4">{localize('remove')}</button>
        </div>
      </div>
    </div>
  )
}

export default DependantsPartial
