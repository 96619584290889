/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SelectableCommissions from '../../../../../Components/Selectables/SelectableCommissions'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'

function WireTransferTypeModal({
  mode,
  onHideModal,
  setSelectedWireTransferType,
  selectedWireTransferType,
  tabLoading,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/WireTransferType/create-wire-transafe-type-with-commissions'
        : '/WireTransferType/update-wire-transfer-type-with-commissions'
    const { success } = await postData(
      url,
      {
        wireTransferType: selectedWireTransferType || {},
        commissionDTOs:
          selectedWireTransferType?.commissions.filter(x => x.checked) || [],
      },
      false
    )
    if (success) {
      setIsBusy(false)
      loadTableData()
      closeModal()
      showNotification('Wire Transfer Type', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedWireTransferType(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-lg"
        modalTitle={'wireTransferType'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        actionText={mode === 'add' ? localize('create') : localize('update')}
        hideUpdate={mode === 'view'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              {mode === 'edit' ? (
                <>
                  <Checkbox
                    disabled={mode === 'view'}
                    id="IsWireTransferTypesDefault"
                    onCheck={e => {
                      setSelectedWireTransferType({
                        ...selectedWireTransferType,
                        isDefault: e,
                      })
                    }}
                    checked={selectedWireTransferType?.isDefault}
                  />
                  <label
                    htmlFor="IsWireTransferTypesDefault"
                    className="text-capitalize"
                  >
                    {localize('isDefault')}?
                  </label>
                  <div className="mx-3"></div>
                </>
              ) : (
                ''
              )}
              <Checkbox
                disabled={mode === 'view'}
                id="IsWireTransferTypesLocked"
                onCheck={e => {
                  setSelectedWireTransferType({
                    ...selectedWireTransferType,
                    isLocked: e,
                  })
                }}
                checked={selectedWireTransferType?.isLocked}
              />
              <label
                htmlFor="IsWireTransferTypesLocked"
                className="text-capitalize"
              >
                {localize('isLocked')}?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2 g-3">
              <div className="col-md-12">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['WireTransferType.Description']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={selectedWireTransferType?.description}
                    className="form-control"
                    onChange={e => {
                      clearValidation(['WireTransferType.Description'])
                      setSelectedWireTransferType({
                        ...selectedWireTransferType,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3 align-items-center">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'WireTransferType.ChartOfAccountName'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-4">
                    {localize('chartOfAccountName')}
                  </label>

                  {mode === 'view' ? (
                    <input
                      defaultValue={
                        selectedWireTransferType?.chartOfAccountName
                      }
                      className="form-control"
                      disabled
                    />
                  ) : (
                    <ChartOfAccountsLookup
                      displayValue={
                        selectedWireTransferType?.chartOfAccountName
                      }
                      onSelect={r => {
                        clearValidation(['WireTransferType.ChartOfAccountName'])
                        setSelectedWireTransferType({
                          ...selectedWireTransferType,
                          chartOfAccountId: r.id,
                          chartOfAccountName: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('transactionOwnership')}
                  </label>
                  <EnumsServerCombo
                    disabled={mode === 'view'}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="TransactionOwnership"
                    value={selectedWireTransferType?.transactionOwnership}
                    onChange={e =>
                      setSelectedWireTransferType({
                        ...selectedWireTransferType,
                        transactionOwnership: e.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <SelectableCommissions
                            disabled={mode === 'view'}
                            setSelectableCommissionItem={
                              setSelectedWireTransferType
                            }
                            selectableCommissionItem={selectedWireTransferType}
                            prefix={'create-wire-transfer-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default WireTransferTypeModal
