/** @format */

import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerMetadata } from '../../../../../Components/ReUsables/Customer/CustomerMetadata/CustomerMetadata'

export const ListGroupMembers = ({ selectedMicroCreditGroup }) => {
  const [groupMembers, setGroupMembers] = useState([])
  const [prevSelectedMicroCreditGroup, setPrevSelectedMicroCreditGroup] =
    useState(selectedMicroCreditGroup)
  const [loading, setLoading] = useState(false)
  const [customerBeingViwed, setCustomerBeingViwed] = useState(null)

  const fetchGroupMembers = async () => {
    setLoading(true)
    const { success, data } = await getData(
      '/MicroCreditGroup/find-micro-credit-group-members-by-micro-credit-group-id',
      { microCreditGroupId: selectedMicroCreditGroup?.id },
      false,
      null,
    )
    if (success) {
      setGroupMembers(data.result)
      setLoading(false)
    }
    setLoading(false)
  }

  if (prevSelectedMicroCreditGroup !== selectedMicroCreditGroup) {
    setPrevSelectedMicroCreditGroup(selectedMicroCreditGroup)
    fetchGroupMembers()
  }

  const columns = [
    {
      label: localize('memberType'),
      template: r => <>{r.memberTypeDescription}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.groupMemberName}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r.designationDescription}</>,
    },
    { label: localize('loanCycle'), template: r => <>{r.loanCycle}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.customerMembershipStatusDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('family'), template: r => <>{r.family}</> },
    { label: localize('clan'), template: r => <>{r.clan}</> },
    { label: localize('ethnicGroup'), template: r => <>{r.ethnicGroup}</> },
    {
      label: localize('genderDescription'),
      template: r => <>{r.ethnicGroup}</>,
    },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r.relationshipDescription}</>,
    },
    {
      label: localize('nationalityDescription'),
      template: r => <>{r.nationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internet'),
      template: r => <>{r.addressInternet}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchCustomer = async e => {
    setLoading(true)
    const { success, data } = await getData(
      '/lookup/Customer/find-customer-by-id',
      { customerId: e?.customerId, loadSpecimens: false },
      false,
      null,
    )
    if (success) {
      setCustomerBeingViwed({ ...data?.customer })
    }
    setLoading(false)
  }

  const handleOnViewCustomer = e => {
    fetchCustomer(e)
  }

  return (
    <>
      {customerBeingViwed && (
        <ModalTemplate
          modalMode={'View'}
          modalTitle={localize('customer')}
          hideUpdate
          handleClose={() => {
            setCustomerBeingViwed(null)
          }}
          modalClassName={'modal-xl'}
        >
          <CustomerMetadata selectedCustomer={customerBeingViwed} />
        </ModalTemplate>
      )}

      {loading ? (
        <Loader />
      ) : (
        <SimpleTable
          contextMenu={e => (
            <>
              <div
                onClick={() => {
                  handleOnViewCustomer(e)
                }}
                className="ctxt-menu-item"
              >
                <i className="mdi mdi-account-arrow-left"></i>
                {localize('viewCustomer')}
              </div>
            </>
          )}
          selectables
          data={groupMembers}
          columns={columns}
        />
      )}
    </>
  )
}
