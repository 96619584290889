/** @format */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'
import DatePicker from '../../../../../../Components/Date/DatePicker'

const InHouseChequeCatalogue = () => {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('chequeLeaveNumber'),
      template: r => <>{r.paddedBankersChequeLeafLeafNumber}</>,
    },
    {
      label: localize('chequeBookSerialNumber'),
      template: r => (
        <>{r.paddedBankersChequeLeafBankersChequeBookSerialNumber}</>
      ),
    },
    {
      label: localize('sourceOfFunds'),
      template: r => <>{r.fundingDescription}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.debitChartOfAccountName}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.debitCustomerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.debitCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.payee}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('amountInWords'),
      template: r => <>{r.wordifiedAmount}</>,
    },
    {
      label: localize('paddedAmount'),
      template: r => <>{r.paddedAmount}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('chargeable') + ' ?',
      template: r => r.chargeable?.toString(),
    },
    {
      label: localize('isPrinted') + ' ?',
      template: r => r.isPrinted?.toString(),
    },
    {
      label: localize('printedNumber'),
      template: r => <>{r.printedNumber}</>,
    },
    {
      label: localize('printedBy'),
      template: r => <>{r.printedBy}</>,
    },
    {
      label: localize('printedDate'),
      template: r => <>{formatDate(r.printedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/InHouseCheque/find-inhouse-cheques-by-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('frontOfficeManagement'), localize('inhouseCheques')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row d-flex justify-content-between">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <div className="col-md-3">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-md-2"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-md-3"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="ChequeWritingCataloguesFilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable data={tableData} columns={columns} />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default InHouseChequeCatalogue
