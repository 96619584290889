/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../../../../Components/Loaders/Loader'
import ViewGLAccountTransactions from '../../../../../../../../../Components/ReUsables/GLAccounts/ViewGLAccountTransactions/ViewGLAccountTransactions'
import Pager from '../../../../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../../../Helpers/webApi'

function ConstituentEntries({ entry }) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('gLAccountName'),
      template: r => r?.chartOfAccountName,
    },
    {
      label: localize('gLAccountCostDistrRule'),
      template: r => r?.chartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('g/LAccountCurrency'),
      template: r => r?.chartOfAccountCurrencyDescription,
    },
    {
      label: localize('bookBalance'),
      template: r => r?.formattedBalance,
    },
    {
      label: localize('exceptionMessage'),
      template: r => r?.exceptionMessage,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchEntries = async () => {
    setIsBusy(true)
    const url =
      '/ReportTemplate/find-report-spool-entry-constituents-by-filter-in-page'
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        reportSpoolEntryId: entry.id,
      },
      false
    )

    if (success) {
      setEntries(data)
    }
    setIsBusy(false)
  }

  const handleMenuClick = (r, mode) => {
    setModalMode(mode)
    setSelectedEntry(r)
  }

  const handleClose = () => {
    setModalMode(null)
    setSelectedEntry(null)
  }

  useEffect(() => {
    fetchEntries()
    // eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize, entry])

  return (
    <>
      {modalMode && (
        <ViewGLAccountTransactions
          handleClose={handleClose}
          mode={modalMode}
          glAccount={selectedEntry}
        />
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center row mb-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="recordsPerPage" className="text-capitalize">
                {localize('recordsPerPage')}
              </label>
              <select
                id="recordsPerPage"
                className="form-select ms-2"
                style={{ maxWidth: 'fit-content' }}
                defaultValue={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="col d-flex align-items-center justify-content-evenly">
              <label htmlFor="search" className="text-capitalize">
                {localize('apportioned')}
              </label>
              <input
                type="text"
                disabled
                value={formatCurrency(
                  entries?.totalApportioned,
                  null,
                  null,
                  false
                )}
                className="form-control text-end"
                style={{
                  maxWidth: 'fit-content',
                }}
              />
            </div>
            <div className="col d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-center">
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchEntries()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: '50dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable
              columns={columns}
              data={entries?.pageCollection}
              canClickOnce
              onClickOnce={r => {}}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleMenuClick(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                </>
              )}
            />
          </div>
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default ConstituentEntries
