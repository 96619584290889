/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import localize from '../../../../Global/Localize'
import Pager from '../../../../Components/Tables/Pager'
import RiskClassificationModeModal from './RiskClassificationModeModal'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function RiskClassificationModes() {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('negligibleBalance'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.negligibleBalance)}</>,
    },
    {
      label: localize('isActive'),
      template: r => (
        <span className="text-capitalize">{r?.isActive?.toString()}</span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [tableData, setTableData] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [search, setSearch] = useState('')
  const [waitingMetadata, setWaitingMetadata] = useState(false)
  const [riskClassificationSchedules, setRiskClassificationSchedules] =
    useState([])
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedRiskClassificationMode, setSelectedRiskClassificationMode] =
    useState(null)

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setWaiting(true)
    const { success, data } = await getData(
      '/RiskClassificationMode/find-risk-classification-modes-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setWaiting(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const fetchRiskClassificationSchedules = async r => {
    setWaitingMetadata(true)
    const { success, data } = await getData(
      '/RiskClassificationMode/find-risk-classification-schedules-by-risk-classification-mode-id',
      {
        riskClassificationModeId: r?.id,
      },
      false
    )
    if (success) {
      setWaitingMetadata(false)
      return data
    } else {
      setWaitingMetadata(false)
      return null
    }
  }

  const handleOnEdit = async r => {
    setSelectedRiskClassificationMode({
      ...r,
    })
    const riskClassificationSchedules = await fetchRiskClassificationSchedules(
      r
    )

    setRiskClassificationSchedules(riskClassificationSchedules?.result || [])
    setMode('edit')
  }

  const handleOnView = async r => {
    setSelectedRiskClassificationMode({
      ...r,
    })
    const riskClassificationSchedules = await fetchRiskClassificationSchedules(
      r
    )

    setRiskClassificationSchedules(riskClassificationSchedules?.result || [])
    setMode('view')
  }

  const handleOnAdd = async r => {
    setSelectedRiskClassificationMode(null)
    setRiskClassificationSchedules([])
    setMode('add')
  }

  const handleViewAuditLogs = item => {
    setSelectedRiskClassificationMode(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('riskClassificationMode'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {waiting ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <RecordsPerPage
                        setReqParams={setReqParams}
                        reqParams={reqParams}
                      />
                    </div>
                    <div className="col-3 offset-6">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <label
                          className={'text-capitalize'}
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              text: search,
                              pageIndex: 0,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(e)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <RiskClassificationModeModal
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        riskClassificationSchedules={riskClassificationSchedules}
        setRiskClassificationSchedules={setRiskClassificationSchedules}
        setSelectedRiskClassificationMode={setSelectedRiskClassificationMode}
        selectedRiskClassificationMode={selectedRiskClassificationMode}
        onSetMode={m => setMode(m)}
        waitingMetadata={waitingMetadata}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={selectedRiskClassificationMode}
          title={selectedRiskClassificationMode?.description}
        />
      )}
    </>
  )
}

export default RiskClassificationModes
