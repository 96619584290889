/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {
  CustomerFilter,
  refundNotificationStatus,
} from '../../Global/enumeration'
import DatePicker from '../Date/DatePicker'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'

function RefundNotificationLookup({ displayValue, onSelect }) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: Object.keys(refundNotificationStatus)[2],
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
      sortBy: 'paddedCustomerSerialNumber',
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
      sortBy: 'branchDescription',
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('refundType'),
      template: r => <>{r.refundTypeDescription}</>,
      sortBy: 'refundTypeDescription',
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
      sortBy: 'customerFullName',
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r.debitFullAccountNumber}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.creditFullAccountNumber}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('customerIdentityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('customerDutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('customerEmployer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r.approvedDate, true)}</>,
    },
    {
      label: localize('auditRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('auditedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('auditedDate'),
      template: r => <>{formatDate(r.auditedDate, true)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r.settledDate)}</>,
    },
    {
      label: localize('settlementRemarks'),
      template: r => <>{r.settlementRemarks}</>,
    },
    {
      label: localize('invalidatedBy'),
      template: r => <>{r.invalidatedBy}</>,
    },
    {
      label: localize('invalidatedDate'),
      template: r => <>{formatDate(r.invalidatedDate, true)}</>,
    },
    {
      label: localize('invalidationRemarks'),
      template: r => <>{r.invalidationRemarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      '/lookup/RefundNotification/find-refund-notifications-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.customerFilter,
    reqParams.pageSize,
    reqParams.pageIndex,
    reqParams.status,
    modalActive,
  ])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: 'all',
          opacity: 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('refundNotificationLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize"
                      htmlFor="lookupRecordsPerPage"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="lookupRecordsPerPage"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize me-2"
                      htmlFor="batchStatus"
                    >
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="RefundNotificationStatus"
                      value={reqParams.status}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          status: e.value,
                        })
                      }}
                    />
                  </div>
                  <div className="d-flex align-item-center">
                    <label htmlFor="search" className="me-1 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                            pageIndex: 0,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                            pageIndex: 0,
                          })
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center float-end my-2">
                    <label
                      className="text-capitalize me-2"
                      htmlFor="filterButton"
                    >
                      {localize('search')}
                    </label>

                    <div className="d-flex-align-items-center">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                    </div>

                    <input
                      type="search"
                      id="search"
                      className="form-control col ms-2"
                      value={reqParams.text}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="filterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default RefundNotificationLookup
