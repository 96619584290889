/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const SalaryHeadLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('salaryHeadLookup')}
      url="/lookup/SalaryHead/find-by-filter-in-page"
      params={{
        filterText: '',
        pageSize: 10,
        pageIndex: 0,
        includeProductDescription: true,
      }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
        },
        {
          label: localize('type'),
          template: r => <>{r.typeDescription}</>,
        },
        {
          label: localize('category'),
          template: r => <>{r.categoryDescription}</>,
        },
        {
          label: localize('isOneOff'),
          template: r => (
            <span className="text-capitalize">{r.isOneOff?.toString()}</span>
          ),
        },
        {
          label: localize('productCode'),
          template: r => <>{r.customerAccountTypeProductCodeDescription}</>,
        },
        {
          label: localize('targetProduct'),
          template: r => <>{r.productDescription}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default SalaryHeadLookup
