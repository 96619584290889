/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

const BankersChequeBooksLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('bankersChequeBookLookup')}
      url="/lookup/BankersChequeBook/find-bankers-cheque-books-by-filter-in-page"
      params={{ text: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('bankLinkageBranchDescription'),
          template: r => <>{r.bankLinkageBranchDescription}</>,
        },
        {
          label: localize('serialNumber'),
          template: r => <>{r.paddedSerialNumber}</>,
        },
        {
          label: localize('numberOfLeaves'),
          template: r => <>{r.numberOfLeaves}</>,
        },
        {
          label: localize('initialLeafNumber'),
          template: r => <>{r.initialLeafNumber}</>,
        },
        {
          label: localize('bankLinkageChartOfAccountName'),
          template: r => <>{r.bankLinkageChartOfAccountName}</>,
        },
        {
          label: localize('bankLinkageBankName'),
          template: r => <>{r.bankLinkageBankName}</>,
        },
        {
          label: localize('bankLinkageBankBranchName'),
          template: r => <>{r.bankLinkageBankBranchName}</>,
        },
        {
          label: localize('bankLinkageBankAccountNumber'),
          template: r => <>{r.bankLinkageBankAccountNumber}</>,
        },
        { label: localize('remarks'), template: r => <>{r.remarks}</> },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">{r.isLocked.toString()}</span>
          ),
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default BankersChequeBooksLookup
