/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../../../Global/Localize'
import {
  RecordStatus,
  customerAccountStatus,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { toBase64 } from '../../../../../Helpers/blobs'
import { postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ActionModal from '../../../../ActionModal/ActionModal'
import ImportButton from '../../../../Buttons/ImportButton/ImportButton'
import Checkbox from '../../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../../ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../InputMasks/MaskNumber'
import Loader from '../../../../Loaders/Loader'
import DebitBatchLookup from '../../../../Lookup/DebitBatchLookup'
import ModalTemplate from '../../../../ModalTemplate/ModalTemplate'
import Tab from '../../../../Tabs/Tab'
import DebitBatchEntries from '../../../BatchEntries/DebitBatchEntries/DebitBatchEntries'
import { CustomerAccountPartial } from '../../../Customer/CustomerAccountPartial'
import MismatchedImportEntries from '../MismatchedImportEntries/MismatchedImportEntries'
import ImportModal from './ImportModal/ImportModal'

const EditDebitBatchModal = ({
  batch,
  handleClose,
  mode,
  setBatch,
  fetchDebitBatches,
}) => {
  const ref = useRef(null)
  const [selectedEntries, setSelectedEntries] = useState([])
  const [reload, setReload] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [fileName, setFileName] = useState('')
  const [mismatchedEntries, setMismatchedEntries] = useState([])
  const [showImportModal, setShowImportModal] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyRemoving, setIsBusyRemoving] = useState(false)
  const [newUpload, setNewUpload] = useState(false)
  const [isBusyAdding, setIsBusyAdding] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  console.log(batch)

  const handleSelect = debitBatch => {
    setBatch({
      ...batch,
      debitBatch,
      debitBatchId: debitBatch?.id,
    })
  }

  const handleSelectCustomer = customer => {
    if (customer?.closed === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Customer account is closed',
      })

    if (customer?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Customer account record status must be approved',
      })

    clearValidation('CustomerId')
    setBatch({
      ...batch,
      customer,
    })
  }

  const handleClickImportButton = () => {
    setShowImportModal(true)
    setNewUpload(true)
  }

  const handleSelectAllEntries = (entries, value) => {
    if (value) {
      setSelectedEntries(entries)
    } else {
      setSelectedEntries([])
    }
  }

  const handleSelectEntry = (entry, value) => {
    if (value) {
      setSelectedEntries([...selectedEntries, entry])
    } else {
      setSelectedEntries(selectedEntries.filter(e => e.id !== entry.id))
    }
  }

  const handleEditBatch = async () => {
    setIsBusy(true)

    const url = `/DebitBatch/update-debit-batch?requestForVerification=${batch?.requestForVerification}`
    const { success } = await putData(url, batch?.debitBatch, false)

    if (success) {
      setBatch({})
      setNewUpload(false)
      showNotification('Debit Batch Entry', 'success')
      handleClose()
      fetchDebitBatches()
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleAddBatch = async () => {
    setIsBusyAdding(true)
    const url = '/DebitBatch/create-debit-batch-entry'

    if (!batch?.customer)
      return GlobalValidationErrors.set({
        CustomerId: {
          message: 'Customer is required',
        },
      })

    if (!batch?.reference)
      return GlobalValidationErrors.set({
        Reference: {
          message: 'Reference is required',
        },
      })

    if (!batch.multiplier || Number(batch?.multiplier) === 0)
      return GlobalValidationErrors.set({
        Multiplier: {
          message: 'Multiplier must be greater than 0',
        },
      })

    const { success } = await postData(
      url,
      {
        debitBatchId: batch?.debitBatchId,
        ...batch?.debitBatch,
        multiplier: batch?.multiplier,
        basisValue: batch?.basisValue,
        reference: batch?.reference,
        ...batch?.customer,
        customerAccountId: batch?.customer?.id,
      },
      false
    )

    if (success) {
      setReload(true)
      setBatch({
        ...batch,
        multiplier: 0,
        basisValue: 0,
        reference: '',
        customer: null,
      })
      showNotification('Debit Batch Entry', 'success')
    }

    setIsBusyAdding(false)
  }

  const handleRemoveBatchEntries = async () => {
    setIsBusyRemoving(true)
    const url = '/DebitBatch/remove-debit-batch-entries'
    const { success } = await putData(url, selectedEntries, false)

    if (success) {
      setReload(true)
      setSelectedEntries([])
      showNotification('Debit Batch Entry', 'success')
      setModalMode(null)
    }

    setIsBusyRemoving(false)
  }

  const handleUpload = async () => {
    ref?.current?.click()

    ref.current.onchange = async e => {
      let file = e.target.files[0]
      try {
        let b64 = await toBase64(file)
        let buffer = b64.split(',')[1].toString()
        setFileName({
          buffer,
          fileName: file.name,
          fileExtension: 'csv',
        })
      } catch {
        Swal.fire('Invalid File', 'Error loading file', 'error')
        return
      }
    }
  }

  const handleFileUploadToServer = async () => {
    const url = `/DebitBatch/parse-debit-batch-import`

    const { success, data } = await postData(
      url,
      {
        debitBatchId: batch?.debitBatchId,
        fileName: fileName?.fileName,
        buffer: fileName?.buffer,
      },
      false
    )

    if (success) {
      setReload(true)
      setFileName('')
      setMismatchedEntries(data?.result)
      setShowImportModal(false)
      setNewUpload(false)
      showNotification('Debit Batch Entry', 'success')
    }
  }

  const handleClickRemoveButton = () => {
    if (selectedEntries.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'No entries selected',
        text: 'Please select entries to remove',
      })

    setModalMode('remove')
  }

  const handleCloseRemoveModal = () => {
    setModalMode(null)
  }

  useEffect(() => {
    if (fileName) handleFileUploadToServer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, batch?.debitBatchId, newUpload])

  return (
    <>
      {showImportModal && (
        <ImportModal
          handleCloseImportModal={() => setShowImportModal(false)}
          handleUpload={handleUpload}
          ref={ref}
        />
      )}

      {modalMode === 'remove' && (
        <ActionModal
          modalTitle="batchEntries"
          handleAction={handleRemoveBatchEntries}
          handleClose={handleCloseRemoveModal}
          disableAction={isBusyRemoving}
        >
          {isBusyRemoving ? (
            <Loader />
          ) : (
            <p>Are you sure you want to remove the selected entries?</p>
          )}
        </ActionModal>
      )}

      <input
        type="file"
        ref={ref}
        className="d-none"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <ModalTemplate
        modalTitle="debitBatchEntry"
        actionText="update"
        modalMode={mode}
        handleClose={handleClose}
        modalClassName="modal-xl"
        handleAction={handleEditBatch}
        disableCreate={isBusy}
        disableClose={isBusy}
        footerChildren={
          <>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                id="request-for-verification-debit-batch"
                checked={batch?.requestForVerification}
                onCheck={value => {
                  setBatch({
                    ...batch,
                    requestForVerification: value,
                  })
                }}
              />

              <label
                htmlFor="request-for-verification-debit-batch"
                className="text-capitalize"
              >
                {localize('requestForVerification')} ?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row g-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('batchNumber')}
                  </label>
                  <DebitBatchLookup
                    displayValue={batch?.debitBatch?.paddedBatchNumber || ''}
                    handleSelect={handleSelect}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('type')}
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={batch?.debitBatch?.debitTypeDescription}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('priority')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="QueuePriority"
                    value={batch?.debitBatch?.priority}
                    onChange={e => {
                      setBatch({
                        ...batch,
                        debitBatch: {
                          ...batch?.debitBatch,
                          priority: e.value,
                        },
                      })
                    }}
                    sort
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-1">
                    {localize('reference')}
                  </label>
                  <textarea
                    className="form-control"
                    value={batch?.debitBatch?.reference}
                    onChange={e => {
                      setBatch({
                        ...batch,
                        debitBatch: {
                          ...batch?.debitBatch,
                          reference: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('batchDetails')}
              </div>

              {isBusyAdding ? (
                <Loader />
              ) : (
                <>
                  <CustomerAccountPartial
                    onCustomerLookup={handleSelectCustomer}
                    customer={batch?.customer}
                    hideMargin
                    message={globalValidationErrors['CustomerId']?.message}
                  />
                  <div className="row g-2 mt-1">
                    <div className="col-md-3">
                      <div
                        className="d-flex align-items-center with-validation"
                        validation-message={
                          globalValidationErrors['Multiplier']?.message
                        }
                      >
                        <label className="text-capitalize col-4">
                          {localize('multiplier')}
                        </label>
                        <MaskNumber
                          defaultMaskValue={batch?.multiplier}
                          className="ms-2"
                          onMaskNumber={e => {
                            clearValidation('Multiplier')
                            setBatch({
                              ...batch,
                              multiplier: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize col-4">
                          {localize('basisValue')}
                        </label>
                        <MaskNumber
                          className="ms-2"
                          defaultMaskValue={batch?.basisValue}
                          onMaskNumber={e => {
                            setBatch({
                              ...batch,
                              basisValue: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="d-flex align-items-center with-validation"
                        validation-message={
                          globalValidationErrors['Reference']?.message
                        }
                      >
                        <label className="text-capitalize col-2">
                          {localize('reference')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-1"
                          value={batch?.reference}
                          onChange={e => {
                            clearValidation('Reference')
                            setBatch({
                              ...batch,
                              reference: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row bg-light py-2 px-1 mt-3 mx-1 rounded">
                    <div className="col">
                      <div className="d-flex align-items-center justify-content-end gap-3">
                        <button
                          className="btn btn-success"
                          onClick={handleAddBatch}
                        >
                          {localize('add')}
                        </button>
                        <ImportButton
                          handleClickImportButton={handleClickImportButton}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row mt-3 py-3 px-2">
                <Tab
                  preload
                  tabItems={[
                    {
                      label: localize('batchEntries'),
                      item: (
                        <DebitBatchEntries
                          debitBatchId={batch?.debitBatchId}
                          showSelect
                          handleSelectAllEntries={handleSelectAllEntries}
                          selectedEntries={selectedEntries}
                          handleSelectEntry={handleSelectEntry}
                          reload={reload}
                          setReload={setReload}
                          handleRemoveBatchEntries={handleClickRemoveButton}
                        />
                      ),
                    },
                    {
                      label: localize('mismatchedImportEntries'),
                      item: (
                        <>
                          <MismatchedImportEntries
                            mismatchedEntries={mismatchedEntries}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditDebitBatchModal
