/** @format */

/* eslint-disable */
import React, { useState, useContext } from 'react'

import logoSm from '../../assets/images/logo-sm.png'
import logoDark from '../../assets/images/logo-dark.png'
import logoLight from '../../assets/images/logo-light.png'

import github from '../../assets/images/brands/github.png'
import bitbucket from '../../assets/images/brands/bitbucket.png'
import dribbble from '../../assets/images/brands/dribbble.png'
import dropbox from '../../assets/images/brands/dropbox.png'
import mail_chimp from '../../assets/images/brands/mail_chimp.png'
import slack from '../../assets/images/brands/slack.png'

import { Form, Input, Button, Row, Col } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import LanguageDropdown from './LanguageDropdown'
import NotificationDropdown from './NotificationDropdown'
import UserPill from '../Auth/UserPill'
import { UserContext } from '../../Contexts/UserContext'
import { useAuth } from 'react-oidc-context'
import Shortcuts from '../Shortcut/Shortcuts'

function toggleFullscreen() {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      )
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen()
    }
  }
}

function Header({ menuCollapsed }) {
  const auth = useAuth()
  const [socialDrp, setsocialDrp] = useState(false)

  const { handleLock, handleLogOut } = useContext(UserContext)

  return (
    <header id="page-topbar" style={{ left: menuCollapsed ? 50 : 250 }}>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <a href="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="20" />
              </span>
            </a>

            <a href="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="20" />
              </span>
            </a>
          </div>

          <button
            type="button"
            onClick={() => {}}
            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          <Form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
              />
              <span className="uil-search"></span>
            </div>
          </Form>
        </div>

        <div className="d-flex">
          <Dropdown className="d-inline-block d-lg-none ms-2" type="button">
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              tag="button"
            >
              {' '}
              <i className="uil-search" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
              <Form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <Button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </DropdownMenu>
          </Dropdown>

          <LanguageDropdown />

          <Dropdown
            className="d-none d-lg-inline-block ms-1"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp)
            }}
          >
            <DropdownToggle
              className="btn header-item noti-icon waves-effect"
              tag="button"
            >
              <i className="uil-apps"></i>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu-lg dropdown-menu-end bg-white shadow-lg p-2 "
              style={{ minWidth: 'fit-content', marginRight: -200 }}
              end
            >
              <Shortcuts />
            </DropdownMenu>
          </Dropdown>

          <Dropdown className="d-none d-lg-inline-block ms-1">
            <button
              type="button"
              onClick={() => {
                toggleFullscreen()
              }}
              className="btn header-item noti-icon waves-effect"
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path"></i>
            </button>
          </Dropdown>

          <NotificationDropdown />

          <UserPill
            onLock={handleLock}
            onLogout={handleLogOut}
            user={{
              name: auth?.user?.profile?.cbs_Description,
              branch: auth?.user?.profile?.cbs_EmployeeBranchDescription,
              department:
                auth?.user?.profile?.cbs_EmployeeDepartmentDescription,
              role: auth?.user?.profile?.cbs_RoleDescription,
            }}
          />

          <div
            onClick={() => {
              //props.showRightSidebarAction(!props.showRightSidebar)
            }}
            className="dropdown d-inline-block"
          >
            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <i className="uil-cog"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
