/** @format */

import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import localize from '../../../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Swal from 'sweetalert2'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import SalaryHeadLookup from '../../../../../../Components/Lookup/SalaryHeadLookup'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { RoundingType, chargeType } from '../../../../../../Global/enumeration'
import {
  formatAsPercent,
  formatCurrency,
} from '../../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../../Helpers/extensions'

const EditSalaryGroupModal = ({
  mode,
  editMode,
  close,
  selectedSalaryGroup,
  loadTableData,
}) => {
  const tBrColumns = [
    {
      label: localize('salaryHead'),
      template: r => <>{r.salaryHeadDescription}</>,
    },
    {
      label: localize('isOneOff'),
      template: r => (
        <span className="text-capitalize">
          {r.salaryHeadIsOneOff?.toString()}
        </span>
      ),
    },
    {
      label: localize('valueType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentageValue'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('fixedValue'),
      template: r => <>{formatCurrency(r.chargeFixedAmount)}</>,
    },
    {
      label: localize('minimumValue'),
      template: r => <>{formatCurrency(r.minimumValue)}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r.roundingTypeDescription}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tempEntries, setTempEntries] = useState([])
  const [tBr, setTBr] = useState({
    chargeType: Object.keys(chargeType)[1],
    roundingType: Object.keys(RoundingType)[0],
  })
  const [entryMode, setEntryMode] = useState('add')
  const [salaryGroup, setSalaryGroup] = useState(
    editMode ? selectedSalaryGroup : {}
  )
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    loadData()
  }, [])

  async function loadData() {
    if (editMode) {
      const { success, data } = await getData(
        '/SalaryGroup/find-salary-group-entries-by-group-id',
        { salaryGroupId: selectedSalaryGroup.id },
        false
      )

      if (success) {
        setIsBusy(false)
        setTempEntries(data.result)
      } else {
        setIsBusy(false)
      }
    }
  }

  async function createSalaryGroup() {
    setIsBusy(true)
    for (let b of tempEntries) {
      delete b.id
    }

    const { success } = await postData(
      '/SalaryGroup/create-salary-group-with-entries',
      {
        salaryGroup,
        salaryGroupEntries: tempEntries,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: '',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
    }
  }

  async function editSalaryGroup() {
    setIsBusy(true)
    for (let b of tempEntries) {
      if (b.id.toString().split('-').length < 5) delete b.id
    }
    const { success, data } = await postData(
      '/SalaryGroup/update-salary-group-with-entries',
      {
        salaryGroup,
        salaryGroupEntries: tempEntries,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'SalaryGroups',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white text-capitalize">
        {localize(mode)}
        <i className="uil uil-angle-right mx-2"></i>
        <span className="text-muted fs-6 text-uppercase">
          {localize('salaryGroups')}
        </span>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="bg-white">
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              <div
                className="with-validation col d-flex align-items-center"
                validation-message={
                  globalValidationErrors['SalaryGroup.Description']?.message
                }
              >
                <label className="text-capitalize">{localize('name')}</label>
                <input
                  type="text"
                  className="form-control ms-3"
                  defaultValue={
                    editMode
                      ? selectedSalaryGroup?.description
                      : salaryGroup?.description
                  }
                  onChange={e => {
                    clearValidation(['SalaryGroup.Description'])
                    setSalaryGroup({
                      ...salaryGroup,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>

            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('salaryGroupEntryDetails')}
              </div>
              <div className="row mb-2">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-md-1">
                    {localize('salaryHead')}
                  </label>
                  <SalaryHeadLookup
                    displayValue={tBr.salaryHeadDescription || ''}
                    onSelect={r => {
                      setTBr({
                        ...tBr,
                        salaryHeadId: r.id,
                        salaryHeadDescription: r.description,
                        salaryHeadIsOneOff: r.isOneOff,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center mb-2">
                  <label className="col-md-2 text-capitalize">
                    {localize('valueType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    value={tBr?.chargeType}
                    enumsKey={'ChargeType'}
                    onChange={e => {
                      setTBr({
                        ...tBr,
                        chargeType: e.value,
                        chargeTypeDescription: chargeType[e.value].name,
                      })
                    }}
                  />
                </div>
                {tBr?.chargeType === '1' ? (
                  <div className="col d-flex align-items-center mb-2">
                    <label className="text-capitalize col-md-2">
                      {localize('value')}
                    </label>
                    <input
                      className="form-control"
                      onChange={e =>
                        setTBr({
                          ...tBr,
                          chargePercentage: e.target.value,
                        })
                      }
                      defaultValue={tBr.chargePercentage || ''}
                    />
                  </div>
                ) : (
                  ''
                )}

                {tBr?.chargeType === '2' ? (
                  <div className="col d-flex align-items-center mb-2">
                    <label className="text-capitalize col-md-2">
                      {localize('value')}
                    </label>
                    <MaskNumber
                      defaultMaskValue={tBr?.chargeFixedAmount || ''}
                      className="form-control"
                      onMaskNumber={e => {
                        setTBr({
                          ...tBr,
                          chargeFixedAmount: e.target.value,
                        })
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="row mb-2">
                <div className="col d-flex align-items-center mb-2">
                  <label className="text-capitalize col-md-2">
                    {localize('minimumValue')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={tBr?.minimumValue || ''}
                    className="form-control"
                    onMaskNumber={e => {
                      setTBr({
                        ...tBr,
                        minimumValue: e.target.value,
                      })
                    }}
                  />
                </div>

                <div className="col d-flex align-items-center mb-2">
                  <label className="col-2 text-capitalize">
                    {localize('roundingType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    value={tBr?.roundingType}
                    enumsKey={'RoundingType'}
                    onChange={e => {
                      setTBr({
                        ...tBr,
                        roundingType: e.value,
                        roundingTypeDescription: RoundingType[e.value].name,
                      })
                    }}
                  />
                </div>
              </div>

              <div className="row  mb-2">
                <div className="d-flex align-items-center justify-content-end">
                  {entryMode === 'edit' && (
                    <button
                      onClick={() => {
                        setEntryMode('add')
                        setTBr({})
                      }}
                      className="text-uppercase btn btn-outline-danger px-4"
                    >
                      {localize('cancel')}
                    </button>
                  )}
                  {entryMode === 'add' && (
                    <button
                      onClick={() => {
                        if (!tBr.salaryHeadDescription) {
                          Swal.fire({
                            icon: 'info',
                            text: 'salary head field is empty',
                            title: 'Salary Group',
                          })
                        } else if (
                          !tBr.chargePercentage &&
                          !tBr.chargeFixedAmount
                        ) {
                          Swal.fire({
                            icon: 'info',
                            text: 'value field is empty',
                            title: 'Salary Group',
                          })
                        } else {
                          setTempEntries([
                            ...tempEntries,
                            { id: generateGuid(), ...tBr },
                          ])
                          setTBr({ chargeType: Object.keys(chargeType)[1] })
                        }
                      }}
                      className="btn btn-success px-5 mx-0 text-uppercase"
                    >
                      {localize('add')}
                    </button>
                  )}
                </div>
              </div>

              <div
                className="p-2 mt-2"
                style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
              >
                <div
                  className="bg-white row px-2 text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('salaryGroupEntries')}
                </div>
                <div style={{ marginTop: -15 }}>
                  {
                    <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                      <SimpleTable
                        columns={tBrColumns}
                        data={tempEntries}
                        contextMenu={r => (
                          <>
                            <div
                              className="ctxt-menu-item text-capitalize text-danger"
                              onClick={() => {
                                let ts = tempEntries
                                let found = ts.findIndex(t => t.id === r.id)
                                ts.splice(found, 1)
                                setTempEntries(ts)
                              }}
                            >
                              <i className="uil uil-times-circle text-danger text-capitalize"></i>
                              {localize('remove')}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="bg-white">
        <button
          className="text-uppercase btn btn-danger px-3"
          onClick={closeModal}
        >
          {localize('cancel')}
        </button>
        <button
          onClick={editMode ? editSalaryGroup : createSalaryGroup}
          className="text-uppercase px-4 btn btn-success"
        >
          {localize(editMode ? 'update' : 'create')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default EditSalaryGroupModal
