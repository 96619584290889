/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Pager from '../Tables/Pager'
import GroupedTable from '../Tables/GroupedTable'
import { formatDate } from '../../Helpers/formatters'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import { ProductCode } from '../../Global/enumeration'
import Loader from '../Loaders/Loader'
function CustomerAccountsByCustomerIdLookup({
  displayValue,
  productCode,
  onSelect,
  selectedCustomer,
  passedColumn,
  disabled,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    productCode: productCode ?? Object.keys(ProductCode)[0],
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageCount: 50,
    includeBalances: true,
    includeProductDescription: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedCustomer?.customerId && modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCustomer?.customerId,
    modalActive,
    productCode,
    reqParams.pageCount,
    reqParams.pageIndex,
  ])

  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'customerRecordStatusDescription',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      label: localize('branch'),
      column: 'branchDescription',
    },
    {
      label: localize('createdBy'),
      column: 'createdBy',
    },
    {
      label: localize('productCode'),
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      column: 'customerAccountTypeTargetProductDescription',
    },
    {
      label: localize('productSection'),
      column: 'customerAccountTypeTargetProductSectionDescription',
    },
    {
      label: localize('adminDivision'),
      column: 'customerAdministrativeDivisionDescription',
    },
    {
      label: localize('dutyStation'),
      column: 'customerDutyStationDescription',
    },
    {
      label: localize('businessGroupEmployer'),
      column: 'customerEmployerDescription',
    },
    {
      label: localize('gender'),
      column: 'customerIndividualGenderDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'customerIndividualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'customerIndividualSalutationDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'loanInterestRecoveryModeDescription',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('recordStatus'),
      column: 'customerRecordStatusDescription',
    },
    {
      label: localize('recruitedBy'),
      column: 'createdBy',
    },
    {
      label: localize('accountStatus'),
      column: 'statusDescription',
    },
  ]

  const handleClick = () => {
    if (!selectedCustomer?.customerId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a customer first',
      })

    if (!disabled) setModalActive(true)
  }

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccountByCustomer/find-customer-accounts-by-customer-id-and-filter-in-page',
      { customerId: selectedCustomer.customerId, ...reqParams },
      false
    )
    if (success) {
      setData(data)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={handleClick}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={handleClick}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('customerAccountsLookup')} -
            {selectedCustomer?.accountName}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center float-right my-2 col-3">
                    <label
                      className="text-capitalize"
                      htmlFor="LoanProductLookupPageSizej"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="LoanProductLookupPageSizeg"
                  className="form-select ms-3"
                  style={{ width: 'fit-content' }}
                  defaultValue={reqParams.pageCount}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageCount: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-3 d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('groupBy')}
                </label>
                <select
                  className="form-select text-capitalize"
                  style={{ width: 'fit-content' }}
                  defaultValue={groupBy.column}
                  onChange={e =>
                    setGroupBy({
                      label: groupingColumns.find(
                        f => f.column === e.target.value
                      )?.label,
                      column: e.target.value,
                    })
                  }
                >
                  {groupingColumns.map(({ label, column }) => (
                    <option className="text-capitalize" value={column}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex align-items-center">
                <label className="col-auto me-3 text-capitalize">
                  {localize('product')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ProductCode"
                  value={reqParams?.productCode}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      productCode: e.value,
                    })
                  }}
                />
              </div>

                  <form
                    className="d-flex align-items-center col-3 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="CustomerFilter"
                      value={reqParams?.customerFilter}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          customerFilter: e.value,
                        })
                      }
                    />

                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="customLookupFilterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="py-1">
                  {loading ? (
                <Loader />
              ) : (
                <>
                  <GroupedTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </>
              )}
            </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageCount}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default CustomerAccountsByCustomerIdLookup
