/** @format */

import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

const ElectronicJournalLookup = ({ onSelect, displayValue, disabled }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [journals, setJournals] = useState([])
  const [modalActive, setModalActive] = useState(false)
  const [tableData, setTableData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
  })

  const columns = [
    {
      label: localize('status'),
      template: item => <>{item?.statusDescription}</>,
    },

    {
      label: localize('fileName'),
      template: item => <>{item?.headerRecordFileSerialNumber}</>,
    },
    {
      label: localize('dateOfFileExchange'),
      template: item => <>{formatDate(item?.headerRecordDateOfFileExchange)}</>,
    },
    {
      label: localize('closedBy'),
      template: item => <>{item?.closedBy}</>,
    },
    {
      label: localize('closedDate'),
      template: item => <>{item?.closedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{item?.createdDate}</>,
    },
  ]

  const handleClose = () => {
    setModalActive(false)
  }

  const fetchElectronicJournals = async () => {
    const url = '/ElectronicJournal/find-electronic-journals-by-filter-in-page'

    setIsBusy(true)

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setJournals(data)
      setTableData(data?.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleSelectElectronicJournal = item => {
    setModalActive(false)
    onSelect(item)
  }

  useEffect(() => {
    if (modalActive) fetchElectronicJournals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('electronicJournal')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex row align-items-center justify-content-between mb-3">
                  <div className="d-flex align-items-center col-md-3">
                    <label
                      style={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select ms-2"
                      style={{ maxWidth: 'fit-content' }}
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center col-md-2">
                    <label className="text-capitalize me-2">
                      {localize('status')}
                    </label>

                    <EnumsServerCombo
                      onChange={({ value }) => {
                        setReqParams({
                          ...reqParams,
                          status: value,
                          pageIndex: 0,
                        })
                      }}
                      enumsKey="ElectronicJournalStatus"
                      customContainerStyle={{
                        width: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      sort
                      value={reqParams?.status}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <label htmlFor="search" className="col-2 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-end gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.text}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                          pageIndex: 0,
                        })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          fetchElectronicJournals()
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        fetchElectronicJournals()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <SimpleTable
                  data={journals?.pageCollection}
                  columns={columns}
                  canSelect
                  onSelect={handleSelectElectronicJournal}
                  onSort={handleSort}
                />
                <Pager
                  itemsCount={journals.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={handleClose}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default ElectronicJournalLookup
