/** @format */

import AssetTypes from '../../Pages/ProcurementAndAssetManagement/Setups/AssetTypes/AssetTypes'
import AssetMovement from '../../Pages/ProcurementAndAssetManagement/Operations/Assets/AssetMovement'
import Catalogue from '../../Pages/ProcurementAndAssetManagement/Operations/Assets/Catalogue'
import InventoryCatalogue from '../../Pages/ProcurementAndAssetManagement/Operations/Inventory/Catalogue'
import MovementCatalogue from '../../Pages/ProcurementAndAssetManagement/Operations/Inventory/MovementCatalogue'
import InventoryCategories from '../../Pages/ProcurementAndAssetManagement/Setups/InventoryCategories/InventoryCategories'
import InventoryPackageTypes from '../../Pages/ProcurementAndAssetManagement/Setups/InventoryPackageTypes/InventoryPackageTypes'
import InventoryUnitsOfMeasure from '../../Pages/ProcurementAndAssetManagement/Setups/InventoryUnitsOfMeasure/InventoryUnitsOfMeasure'
import ItemsRegister from '../../Pages/ProcurementAndAssetManagement/Setups/ItemsRegister/ItemsRegister'
import SupplierTypes from '../../Pages/ProcurementAndAssetManagement/Setups/SupplierTypes/SupplierTypes'
import SupplyCategories from '../../Pages/ProcurementAndAssetManagement/Setups/SupplyCategories/SupplyCategories'
import Suppliers from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Suppliers/Suppliers'
import Quotations from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Quotations/Quotations'
import AssetFinanceOrders from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/AssetFinanceOrders/AssetFinanceOrders'
import RequisitionsOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Requisitions/Origination/Origination'
import RequisitionsApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Requisitions/Approval/Approval'
import PurchaseRequisitionsApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/PurchaseRequisitions/Approval/Approval'
import PurchaseOrdersOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/PurchaseOrders/Origination/Origination'
import PurchaseOrdersApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/PurchaseOrders/Approval/Approval'
import GoodsReceivedNotesOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/GoodsReceivedNotes/Origination'
import GoodsReceivedNotesApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/GoodsReceivedNotes/Approval'
import InvoicesOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Invoices/Origination'
import InvoicesApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/Invoices/Approval'
import GoodsDispatchNotesOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/GoodsDispatchNotes/Origination'
import GoodsDispatchNotesCancellation from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/GoodsDispatchNotes/Cancellation'
import DirectPurchasesOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/DirectPurchases/Origination'
import DirectPurchasesApproval from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/DirectPurchases/Approval'
import PurchaseRequisitionsOrigination from '../../Pages/ProcurementAndAssetManagement/Operations/Procurement/PurchaseRequisitions/Origination/Origination'

const procurementAndAssetsManagement = [
  {
    iconClass: 'uil uil-analytics',
    label: 'procurementAndAssetsManagement',
    code: 12,
    setup: [
      {
        label: 'assetTypes',
        component: <AssetTypes />,
        code: 7001,
        parentCode: 12,
      },
      {
        label: 'supplierTypes',
        component: <SupplierTypes />,
        code: 7002,
        parentCode: 12,
      },
      {
        label: 'SupplyCategories',
        component: <SupplyCategories />,
        code: 7003,
        parentCode: 12,
      },
      {
        label: 'InventoryCategories',
        component: <InventoryCategories />,
        code: 7004,
        parentCode: 12,
      },
      {
        label: 'InventoryPackageTypes',
        component: <InventoryPackageTypes />,
        code: 7005,
        parentCode: 12,
      },
      {
        label: 'InventoryUnitsOfMeasure',
        component: <InventoryUnitsOfMeasure />,
        code: 7006,
        parentCode: 12,
      },
      {
        label: 'ItemsRegister',
        component: <ItemsRegister />,
        code: 7007,
        parentCode: 12,
      },
    ],
    operations: [
      {
        label: 'Procurement',
        code: 7500,
        children: [
          {
            label: 'suppliers',
            code: 7501,
            component: <Suppliers />,
            parentCode: 7500,
          },
          {
            label: 'quotations',
            code: 7502,
            parentCode: 7500,
            component: <Quotations />,
          },
          {
            label: 'assetFinanceOrders',
            code: 7503,
            parentCode: 7500,
            component: <AssetFinanceOrders />,
          },
          {
            label: 'requisitions',
            code: 7504,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <RequisitionsOrigination />,
                code: 7505,
                parentCode: 7504,
              },
              {
                label: 'approval',
                component: <RequisitionsApproval />,
                code: 7506,
                parentCode: 7504,
              },
            ],
          },
          {
            label: 'purchaseRequisitions',
            code: 7507,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <PurchaseRequisitionsOrigination />,
                code: 7508,
                parentCode: 7507,
              },
              {
                label: 'approval',
                component: <PurchaseRequisitionsApproval />,
                code: 7509,
                parentCode: 7507,
              },
            ],
          },
          {
            label: 'purchaseOrders',
            code: 7510,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <PurchaseOrdersOrigination />,
                code: 7511,
                parentCode: 7510,
              },
              {
                label: 'approval',
                component: <PurchaseOrdersApproval />,
                code: 7512,
                parentCode: 7510,
              },
            ],
          },
          {
            label: 'goodsReceivedNotes',
            code: 7513,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <GoodsReceivedNotesOrigination />,
                code: 7514,
                parentCode: 7513,
              },
              {
                label: 'approval',
                component: <GoodsReceivedNotesApproval />,
                code: 7515,
                parentCode: 7513,
              },
            ],
          },
          {
            label: 'invoices',
            code: 7516,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <InvoicesOrigination />,
                code: 7517,
                parentCode: 7516,
              },
              {
                label: 'approval',
                component: <InvoicesApproval />,
                code: 7518,
                parentCode: 7516,
              },
            ],
          },
          {
            label: 'goodsDispatchNotes',
            code: 7519,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <GoodsDispatchNotesOrigination />,
                code: 7520,
                parentCode: 7519,
              },
              {
                label: 'cancellation',
                component: <GoodsDispatchNotesCancellation />,
                code: 7521,
                parentCode: 7519,
              },
            ],
          },
          {
            label: 'directPurchases',
            code: 7522,
            parentCode: 7500,
            children: [
              {
                label: 'origination',
                component: <DirectPurchasesOrigination />,
                code: 7523,
                parentCode: 7522,
              },
              {
                label: 'approval',
                component: <DirectPurchasesApproval />,
                code: 7524,
                parentCode: 7522,
              },
            ],
          },
        ],
      },
      {
        label: 'assets',
        code: 7525,
        parentCode: 0,
        children: [
          {
            label: 'catalogue',
            component: <Catalogue />,
            code: 7526,
            parentCode: 7525,
          },
          {
            label: 'assetMovement',
            component: <AssetMovement />,
            code: 7527,
            parentCode: 7525,
          },
        ],
      },
      {
        label: 'inventory',
        code: 7528,
        parentCode: 0,
        children: [
          {
            label: 'catalogue',
            component: <InventoryCatalogue />,
            code: 7529,
            parentCode: 7528,
          },
          {
            label: 'movementCatalogue',
            component: <MovementCatalogue />,
            code: 7530,
            parentCode: 7528,
          },
        ],
      },
    ],
  },
]

export default procurementAndAssetsManagement
