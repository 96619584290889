/** @format */

/* eslint-disable */
import React from 'react'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

function BudgetHierarchyLookup({ displayValue, onSelect }) {
  const columns = [
    { label: localize('name'), template: r => <>{r.description}</> },
    {
      label: localize('category'),
      template: r => <>{r.categoryDescription}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('gLAccountCostDistrRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.cratedBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <SimpleLookup
      title={localize('budgetHierarchiesLookup')}
      url={'/lookup/Budget/find-budget-hierarchies-by-filter-in-page'}
      params={{
        filterText: '',
        pageIndex: 0,
        pageSize: 10,
        updateDepth: false,
        traverseTree: false,
      }}
      columns={columns}
      displayValue={displayValue}
      onSelect={onSelect}
    />
  )
}

export default BudgetHierarchyLookup
