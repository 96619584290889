/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../../Components/Loaders/Loader'
import MorguePatientLookup from '../../../../../../../Components/Lookup/MorguePatientLookup'
import MorgueTechnicianLookup from '../../../../../../../Components/Lookup/MorgueTechnicianLookup'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../../../Helpers/webApi'

const AddSample = ({ mode, handleClose, fetchDocuments, sampleData }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [sample, setSample] = useState({})
  const [patient, setPatient] = useState({})
  const [technician, setTechnician] = useState({})

  const handleSelectPatient = data => {
    if (data?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected patient is locked'
      )

    setPatient(data)
  }

  const handleSelectTechnician = data => {
    if (data?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected technician is locked'
      )

    setTechnician(data)
  }

  const handleCreateSample = async () => {
    if (!patient?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select patient'
      )

    if (!technician?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select technician'
      )

    setIsBusy(true)

    const url = '/MorguePatientSample/add-morgue-patient-sample'

    const { success } = await postData(
      url,
      {
        ...patient,
        ...technician,
        morguePatientId: patient?.id,
        morgueTechnicianId: technician?.id,
        description: sample?.description,
        remarks: sample?.remarks,
      },
      false
    )

    if (success) {
      showNotification('Create Morgue Sample', 'success')
      fetchDocuments()
      handleClose()
      setSample({})
      setPatient({})
    }

    setIsBusy(false)
  }

  const handleEditSample = async () => {
    if (!sample?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select sample'
      )

    setIsBusy(true)

    const url = '/MorguePatientSample/update-morgue-patient-sample'

    const { success } = await postData(
      url,
      {
        ...sample,
        morguePatientId: patient?.id,
        morgueTechnicianId: technician?.id,
        description: sample?.description,
        remarks: sample?.remarks,
        ...patient,
        ...technician,
      },
      false
    )

    if (success) {
      showNotification('Update Morgue Sample', 'success')
      fetchDocuments()
      handleClose()
      setSample({})
      setPatient({})
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (sampleData?.id && mode === 'edit') {
      setSample(sampleData)
      setPatient({
        id: sampleData?.morguePatientId,
        fullName: sampleData?.morguePatientFullName,
      })
      setTechnician({
        id: sampleData?.morgueTechnicianId,
        fullName: sampleData?.morgueTechnicianFullName,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleData?.id])

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      handleAction={mode === 'add' ? handleCreateSample : handleEditSample}
      actionText={mode === 'add' ? 'create' : 'update'}
      handleClose={handleClose}
      modalMode={mode}
      modalTitle={mode === 'add' ? 'createSample' : 'editSample'}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('morguePatient')}
            </label>
            <MorguePatientLookup
              displayValue={patient?.fullName || patient?.morguePatientFullName}
              onSelect={handleSelectPatient}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('technician')}
            </label>
            <MorgueTechnicianLookup
              displayValue={technician?.fullName}
              onSelect={handleSelectTechnician}
            />
          </div>

          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('description')}
            </label>
            <textarea
              className="form-control"
              value={sample?.description}
              onChange={e =>
                setSample({ ...sample, description: e.target.value })
              }
            />
          </div>
          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('remarks')}
            </label>
            <textarea
              className="form-control"
              value={sample?.remarks}
              onChange={e => setSample({ ...sample, remarks: e.target.value })}
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AddSample
