/** @format */

import flatpickr from 'flatpickr'
/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import './date-picker.scss'
import 'flatpickr/dist/flatpickr.min.css'
import { parseTime } from '../../Helpers/formatters'

export default function TimePicker({
  defaultTime,
  name,
  onTimeChange,
  enableTime,
  minDate,
  maxDate,
  className,
  disabled,
  placeholder,
  twentyFourHourFormat = true,
}) {
  const ref = useRef()

  useEffect(() => {
    flatpickr(`#${name}`, {
      onClose: date => {
        onTimeChange(parseTime(date))
      },
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: twentyFourHourFormat,
      defaultDate: defaultTime,
    })
  }, [defaultTime, enableTime, minDate, maxDate, name, onTimeChange])

  useEffect(() => {
    if (!disabled && ref.current) {
      ref.current.addEventListener('click', () => {
        document.getElementById(name).click()
      })
    }
  }, [disabled, name])

  return (
    <div className="custom-date-picker">
      <input
        className={`form-control ${className} ${
          disabled ? 'bg-light' : ''
        } flatpickr flatpickr-input active`}
        type="text"
        placeholder={placeholder}
        readOnly="readonly"
        id={name}
        disabled={disabled}
      />
      {!disabled && (
        <i className="far fa-clock fs-4 text-primary" ref={ref}></i>
      )}
    </div>
  )
}
