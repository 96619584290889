/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import localize from '../../../../Global/Localize'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import rightAlignFragment, {
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import ChargesModal from './ChargesModal'
import { roundingType } from '../../../../Helpers/constants'

function Charges() {
  const [tableData, setTableData] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [search, setSearch] = useState('')
  const [waitingMetadata, setWaitingMetadata] = useState(false)
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedCharge, setSelectedCharge] = useState(null)
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('maximumCharge'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumCharge)}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r?.roundingTypeDescription}</>,
    },
    {
      label: localize('permitOverdrawing'),
      template: r => (
        <span className="text-capitalize">
          {r?.permitOverdrawing?.toString()}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setWaiting(true)
    const { success, data } = await getData(
      '/Commission/find-commission-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setWaiting(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setWaiting(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnEdit = r => {
    fetchCommissionMetadata(r)
    setMode('edit')
  }

  const handleOnView = r => {
    fetchCommissionMetadata(r)
    setMode('view')
  }

  const handleOnAdd = ()  => {
    fetchCommissionMetadata({})
    setMode('add')
  }
  const fetchCommissionMetadata = async r => {
    setWaitingMetadata(true)
    const { success, data } = await getData(
      '/Commission/find-commission-metadata',
      {
        commissionId: r.id || '00000000-0000-0000-0000-000000000000',
      },
      false,
    )
    if (success) {
      setSelectedCharge({
        ...r,
        roundingType: roundingType.NO_ROUNDING,
        commissionSplits: data?.commissionSplits,
        graduatedScales: data?.graduatedScales,
        levies: data?.levies,
      })
    }
    setWaitingMetadata(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('leviesAndCharges'),
          localize('charges'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {waiting ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <label
                          className={'text-capitalize'}
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                              pageIndex: 0,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-search"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChargesModal
        waitingMetadata={waitingMetadata}
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedCharge={setSelectedCharge}
        selectedCharge={selectedCharge}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default Charges
