/** @format */

import React from 'react'
import { Pie } from 'react-chartjs-2'
import { COLORS } from '../../../Helpers/constants'

const PieChart = ({
  labels = [],
  datasetsData = [],
  backgroundColor = [COLORS.BLUE, COLORS.RED],
  hoverBackgroundColor = [COLORS.LIGHT_BLUE, COLORS.LIGHT_RED],
  hoverBorderColor = '#fff',
  pieWidth = 100,
  pieHeight = 50,
}) => {
  const data = {
    labels,
    datasets: [
      {
        data: datasetsData,
        backgroundColor,
        hoverBackgroundColor,
        hoverBorderColor,
      },
    ],
  }

  return <Pie width={pieWidth} height={pieHeight} data={data} />
}

export default PieChart
