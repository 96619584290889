/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'

import localize from '../../../../../../Global/Localize'
import { parseDate } from '../../../../../../Helpers/formatters'

function MicrocreditParticulars({ customer, onChange, readOnly }) {
  const [micro, setMicro] = useState(customer)
  useEffect(() => {
    if (!readOnly) onChange(micro)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micro])
  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('name')}
          </label>
          <input
            type="text"
            className="form-control"
            value={micro?.nonIndividualDescription}
            onChange={e =>
              setMicro({
                ...micro,
                nonIndividualDescription: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registration')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={micro?.nonIndividualRegistrationNumber}
            onChange={e =>
              setMicro({
                ...micro,
                nonIndividualRegistrationNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registrationSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={micro?.nonIndividualRegistrationSerialNumber}
            onChange={e =>
              setMicro({
                ...micro,
                nonIndividualRegistrationSerialNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('dateEstablished')}
          </label>
          <input
            type="date"
            className="form-control"
            value={parseDate(micro?.nonIndividualDateEstablished)}
            onChange={e =>
              setMicro({
                ...micro,
                nonIndividualDateEstablished: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('PIN')} #
          </label>
          <input type="text" className="form-control" disabled={readOnly} />
        </div>
        <div className="col"></div>
      </div>
    </div>
  )
}

export default MicrocreditParticulars
