/** @format */

import React, { useState, useEffect } from 'react'
import FormulaInput from '../../../../../Components/Formula/FormulaInput'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { evaluateEligibleIncomeDeductions } from '../../../../../Helpers/Modules/loan'
import Loader from '../../../../../Components/Loaders/Loader'

function IncomeAdjustments({
  customerId,
  loanProductId,
  loanCaseId,
  onChange,
}) {
  const [incomeAdjustments, setIncomeAdjustments] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    onChange && onChange(incomeAdjustments)
    // eslint-disable-next-line
  }, [incomeAdjustments])

  const incomeColumns = [
    { label: 'name', template: r => <>{r?.description}</> },
    { label: 'type', template: r => <>{r?.typeDescription}</> },
    {
      label: 'amount',
      template: r => (
        <FormulaInput
          style={{ maxWidth: 200 }}
          value={Number(r?.amount)}
          useBlur
          onChange={value => {
            let clonedAdj = [...incomeAdjustments]
            let foundIndex = clonedAdj.findIndex(x => x.id === r.id)
            let foundItem = clonedAdj[foundIndex]
            if (foundItem) {
              foundItem.amount = value
            }
            setIncomeAdjustments(clonedAdj)
          }}
        />
      ),
    },
  ]

  useEffect(() => {
    loadIncomeAdjustments()
    // eslint-disable-next-line
  }, [customerId, loanProductId, loanCaseId])

  async function loadIncomeAdjustments() {
    setIsBusy(true)
    let iAdj = await evaluateEligibleIncomeDeductions(
      customerId,
      loanProductId,
      loanCaseId
    )
    setIncomeAdjustments(iAdj)
    setIsBusy(false)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable
          selectables
          columns={incomeColumns}
          data={incomeAdjustments}
        />
      )}
    </>
  )
}

export default IncomeAdjustments
