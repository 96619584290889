/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import { getData } from '../../../../../../Helpers/webApi'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

const SignatoriesTab = ({
  customerAccountId,
  selectedData,
  handleSelectData,
  reload,
  setReload,
  handleRemoveSignatories,
}) => {
  const [signatories, setSignatories] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedSignatories, setSelectedSignatories] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    customerAccountId: customerAccountId ?? null,
  })

  function handleSelectSignatory(data, value) {
    if (value) {
      setSelectedSignatories([...selectedSignatories, data])
    } else {
      setSelectedSignatories(selectedSignatories.filter(c => c.id !== data.id))
    }
  }

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedData?.find(x => x?.id === r?.id)}
          onCheck={value => handleSelectSignatory(r, value)}
          id={`selectSignatoryCheckbox-${r.id}`}
        />
      ),
    },
    {
      label: localize('fullName'),
      template: r => r?.fullName,
    },
    {
      label: localize('gender'),
      template: r => r?.genderDescription,
    },
    {
      label: localize('identityCardType'),
      template: r => r?.identityCardTypeDescription,
    },
    {
      label: localize('identityCardNumber'),
      template: r => r?.identityCardNumber,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r?.addressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.addressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.addressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.addressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.addressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r?.addressEmail,
    },
    {
      label: localize('landline'),
      template: r => r?.addressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.addressMobileLine,
    },
    {
      label: localize('addressInternet'),
      template: r => r?.addressInternet,
    },
    {
      label: localize('relationship'),
      template: r => r?.relationshipDescription,
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchSignatories = async () => {
    setIsBusy(true)

    const url =
      '/CustomerAccount/find-customer-account-signatories-by-customer-account-id-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setSignatories(data)
      setIsBusy(false)
      if (reload) setReload(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectAllSignatories = value => {
    if (value) {
      setSelectedSignatories(signatories?.pageCollection)
    } else {
      setSelectedSignatories([])
    }
  }

  useEffect(() => {
    if (customerAccountId) fetchSignatories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, customerAccountId])

  useEffect(() => {
    handleSelectData(selectedSignatories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSignatories])

  useEffect(() => {
    if (reload) fetchSignatories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center gap-lg-5">
            <div className="d-flex align-items-center ">
              <label
                htmlFor="recordsPerPage"
                className="text-capitalize"
                style={{ marginLeft: -10, textTransform: 'capitalize' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                id="recordsPerPage"
                className="form-select ms-3"
                style={{ maxWidth: 'fit-content' }}
                defaultValue={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="d-flex align-items-center">
              <Checkbox
                id="select all signatories"
                checked={selectAll}
                onCheck={value => {
                  setSelectAll(value)
                  handleSelectAllSignatories(value)
                }}
              />
            </div>
          </div>

          <SimpleTable data={signatories?.pageCollection} columns={columns} />
          <Pager
            itemsCount={signatories.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="d-flex justify-content-end p-2 rounded bg-light mt-2">
            <button
              className="btn btn-danger"
              onClick={handleRemoveSignatories}
            >
              {localize('remove')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default SignatoriesTab
