/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

export const RequisitionEntries = ({ entries }) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{formatCurrency(r.itemRegisterEstimatedUnitCost)}</>,
    },
    { label: localize('quantityRequested'), template: r => <>{r.quantity}</> },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={entries} />
    </>
  )
}
