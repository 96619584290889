/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { postData, putData } from '../../../../Helpers/webApi'
import { useState } from 'react'
import { AddUserModal } from './AddUserModal'
import swal from 'sweetalert2'
import { EditUserModal } from './EditUserModal'
import { ViewUserModal } from './ViewUserModal'

function UsersModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedUser,
  waitingMetadata,
  setSelectedUser,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const addUser = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/User/create-user',
      {
        userDto: {
          id: selectedUser?.id || '',
          employeeId: selectedUser?.employeeId || '',
          roleId: selectedUser?.roleId || '',
          description: selectedUser?.description || '',
          employeeCustomerAddressEmail:
            selectedUser?.employeeCustomerAddressEmail || '',
        },
        branches: selectedUser?.branches?.filter(x => x.checked),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedUser(null)
      swal.fire('Create User', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  const editUser = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/User/update-user',
      {
        UserDto: {
          id: selectedUser?.id || '',
          employeeId: selectedUser?.employeeId || '',
          roleId: selectedUser?.roleId || '',
          description: selectedUser?.description || '',
          employeeCustomerAddressEmail:
            selectedUser?.employeeCustomerAddressEmail || '',
        },
        branches: selectedUser?.branches?.filter(x => x.checked),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedUser(null)
      swal.fire('Edit User', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {(mode === 'add' || mode === 'edit' || mode === 'view') && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('user')}
            handleAction={() => {
              mode === 'add' ? addUser() : editUser()
            }}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disableCreate={isBusy}
          >
            {mode === 'add' && (
              <AddUserModal
                isBusy={isBusy}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                waitingMetadata={waitingMetadata}
              />
            )}
            {mode === 'edit' && (
              <EditUserModal
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                waitingMetadata={waitingMetadata}
                isBusy={isBusy}
              />
            )}
            {mode === 'view' && (
              <ViewUserModal
                isBusy={isBusy}
                selectedUser={selectedUser}
                waitingMetadata={waitingMetadata}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default UsersModal
