/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { Reorder } from 'framer-motion'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'

export const EditCompanyMiscellaneous = ({
  selectedCompany,
  setSelectedCompany,
}) => {
  const [priorityList, setPriorityList] = useState(
    (
      selectedCompany?.recoveryPriority?.split(',') || [
        'BosaLoans',
        'BosaInvestments',
        'BosaSavings',
        'FosaLoans',
        'FosaInvestments',
        'FosaSavings',
        'BosaLoans',
        'BosaInvestments',
        'BosaSavings',
      ]
    ).map((val, idx) => {
      return {
        id: idx,
        label: val,
      }
    }),
  )

  useEffect(() => {
    setSelectedCompany({
      ...selectedCompany,
      recoveryPriority: priorityList.map(x => x.label).join(','),
    })
  }, [priorityList])

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('transactionReceiptTopIndentation')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle="none"
            defaultMaskValue={selectedCompany?.transactionReceiptTopIndentation}
            onMaskNumber={e => {
              setSelectedCompany({
                ...selectedCompany,
                transactionReceiptTopIndentation: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('transactionReceiptLeftIndentation')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle="none"
            defaultMaskValue={
              selectedCompany?.transactionReceiptLeftIndentation
            }
            onMaskNumber={e => {
              setSelectedCompany({
                ...selectedCompany,
                transactionReceiptLeftIndentation: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('transactionReceiptFooter')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle="none"
            defaultMaskValue={selectedCompany?.transactionReceiptFooter}
            onMaskNumber={e => {
              setSelectedCompany({
                ...selectedCompany,
                transactionReceiptFooter: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('biometricsThreshold')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle="none"
            defaultMaskValue={selectedCompany?.biometricsThreshold}
            onMaskNumber={e => {
              setSelectedCompany({
                ...selectedCompany,
                biometricsThreshold: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('maximumLoanEligibilityInvestmentsMultiplier')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle="none"
            defaultMaskValue={selectedCompany?.maximumLoanEligibilityInvestmentsMultiplier}
            onMaskNumber={e => {
              setSelectedCompany({
                ...selectedCompany,
                maximumLoanEligibilityInvestmentsMultiplier: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('logoMaxWidth')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.maxImageWidth}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                maxImageWidth: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('logoMaxHeight')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.maxImageHeight}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                maxImageHeight: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('defaultCustomerFilter')}
          </label>
          <EnumsServerCombo
            value={selectedCompany?.defaultCustomerFilter}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                defaultCustomerFilter: e.value,
              })
            }}
            customContainerStyle={{ width: '100%' }}
            enumsKey={'CustomerFilter'}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerStatementPassword')}
          </label>
          <EnumsServerCombo
            value={selectedCompany?.customerStatementPassword}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                customerStatementPassword: e.value,
              })
            }}
            customContainerStyle={{ width: '100%' }}
            enumsKey={'CustomerStatementPassword'}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reportDisplayName')}
          </label>
          <input
            className="form-control"
            value={selectedCompany?.applicationDisplayName}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                applicationDisplayName: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            style={{ marginBottom: 15 + 'rem' }}
            className="col-3 text-capitalize"
          >
            {localize('recoveryPriority')}
          </label>
          <div
            style={{
              width: '100%',
              pointerEvents: 'auto',
              opacity: 1,
            }}
          >
            <Reorder.Group
              values={priorityList || []}
              className={`form-control basicdnd-container ${'y'}-axis`}
              axis={'y'}
              onReorder={setPriorityList}
              layout
            >
              {priorityList.map((item, index) => (
                <Reorder.Item
                  value={item}
                  key={item.id}
                  className="basicdnd-item"
                >
                  {item.label}
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </div>
        </div>

        <div className="col d-flex align-items-center">
          <label
            className="col-3 text-capitalize"
            style={{ marginBottom: 15 + 'rem' }}
          >
            {localize('customerStatementFooter')}
          </label>
          <input
            style={{ marginBottom: 15 + 'rem' }}
            className="form-control"
            value={selectedCompany?.customerStatementFooter}
            onChange={e => {
              setSelectedCompany({
                ...selectedCompany,
                customerStatementFooter: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
