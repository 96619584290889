/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import PerformanceObjectiveModal from './PerformanceObjectiveModal'

function PerformanceObjectives() {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [refresh, setRefresh] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: localize('department'),
    value: 1,
    column: 'departmentDescription',
  })
  const [mode, setMode] = useState(null)
  const [selectedPerformanceObjective, setSelectedPerformanceObjective] =
    useState()
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('performancePillar'),
      template: r => <>{r?.performancePillarDescription}</>,
      sortBy: 'performancePillarDescription',
    },
    {
      label: localize('department'),
      template: r => <>{r?.departmentDescription}</>,
      sortBy: 'departmentDescription',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
      sortBy: 'modifiedBy',
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PerformanceObjective/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    }
    setIsBusy(false)
  }
  const groupingColumns = [
    {
      value: 0,
      column: 'performancePillarDescription',
    },
    {
      value: 1,
      column: 'departmentDescription',
    },
  ]

  function closeModal() {
    setMode(null)
    setSelectedPerformanceObjective(null)
    setGlobalValidationErrors({})
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedPerformanceObjective(null)
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('performanceObjectives'),
        ]}
      />
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-md-4">
                <AddNewButton handleClickAddButton={handleAdd} />
              </div>
            </div>
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize me-3">
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        width: 150,
                        minWidth: 150,
                      }}
                      value={groupBy?.value}
                      enumsKey="PerformanceObjectivePropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex align-item-center gap-2">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setRefresh(!refresh)
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                            pageIndex: 0,
                          })
                        }
                      />
                    </form>
                    <button
                      id="perfomanceObjectiveFilterButton"
                      className="btn btn-primary"
                      onClick={() => setRefresh(!refresh)}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>

                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setMode('edit')
                          setSelectedPerformanceObjective(r)
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                    </>
                  )}
                />
                <div className="mb-2"></div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      {mode && (
        <PerformanceObjectiveModal
          mode={mode}
          selectedPerformanceObjective={selectedPerformanceObjective}
          setSelectedPerformanceObjective={setSelectedPerformanceObjective}
          closeModal={closeModal}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}
export default PerformanceObjectives
