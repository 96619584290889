/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import BranchLookup from '../../../../../Components/Lookup/BranchLookup'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import {
  tellerCashRequisitionAuthStatus,
  tellerCashTransactionAuthOption,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

const CashRequisitions = () => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s?.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('voucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('narration'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('paidBy'),
      template: r => <>{r.paidBy}</>,
    },
    {
      label: localize('paidDate'),
      template: r => <>{formatDate(r.paidDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [requisitions, setCashRequisitions] = useState([])
  const [requisition, setRequisition] = useState(null)
  const [selectedEntries, setSelectedEntries] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [reqParams, setReqParams] = useState({
    branchId: null,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    status: 1,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  async function loadCashRequisitions() {
    if (!requisition?.id) return

    setIsBusy(true)
    const { success, data } = await getData(
      '/TellerCashRequisition/find-teller-cash-requisitions-by-branch-id-and-filter-in-page',
      {
        ...reqParams,
        branchId: requisition?.id,
      },
      false
    )
    if (success) {
      setCashRequisitions(data)
    }
    setIsBusy(false)
  }

  const handleSelectBranch = r => {
    if (r?.isLocked)
      return Swal.fire({
        icon: 'error',
        text: 'sorry, but this branch is locked',
        title: 'Operation not allowed',
      })

    setRequisition(r)
  }

  const handleSelectAllRequisitions = v => {
    setSelectAll(v)
    if (v) {
      setSelectedEntries(requisitions?.pageCollection)
    } else {
      setSelectedEntries([])
    }
  }

  const handleReject = async () => {
    if (
      selectedEntries.filter(
        x => x.status === Object.keys(tellerCashRequisitionAuthStatus)[0]
      )?.length === 0
    )
      return Swal.fire({
        icon: 'error',
        text: 'please select entries in pending status',
        title: 'Operation not allowed',
      })

    if (selectedEntries.length === 0)
      return Swal.fire({
        icon: 'error',
        text: 'please select at least one entry',
        title: 'Operation not allowed',
      })

    setIsBusy(true)

    const url = '/TellerCashRequisition/authorize-teller-cash-requisitions'

    const { success } = await postData(
      url,
      {
        tellerCashRequisitions: selectedEntries.filter(
          x => x.status === Object.keys(tellerCashRequisitionAuthStatus)[0]
        ),
        tellerCashTransactionAuthOption: Object.keys(
          tellerCashTransactionAuthOption
        )[1],
        authorizationRemarks: 'Reject',
      },
      false
    )

    if (success) {
      setIsBusy(false)
      showNotification('Teller cash requisitions', 'success')
      setSelectedEntries([])
      setSelectAll(false)
      loadCashRequisitions()
    } else {
      setIsBusy(false)
    }
  }

  const handleAuthorize = async () => {
    if (
      selectedEntries.filter(
        x => x.status === Object.keys(tellerCashRequisitionAuthStatus)[0]
      )?.length === 0
    )
      return Swal.fire({
        icon: 'error',
        text: 'please select entries in pending status',
        title: 'Operation not allowed',
      })

    if (selectedEntries.length === 0)
      return Swal.fire({
        icon: 'error',
        text: 'please select at least one entry',
        title: 'Operation not allowed',
      })

    setIsBusy(true)

    const url = '/TellerCashRequisition/authorize-teller-cash-requisitions'

    const { success } = await postData(
      url,
      {
        tellerCashRequisitions: selectedEntries.filter(
          x => x.status === Object.keys(tellerCashRequisitionAuthStatus)[0]
        ),
        tellerCashTransactionAuthOption: Object.keys(
          tellerCashTransactionAuthOption
        )[0],
        authorizationRemarks: 'Authorize',
      },
      false
    )

    if (success) {
      setIsBusy(false)
      showNotification('Teller cash requisitions', 'success')
      setSelectedEntries([])
      setSelectAll(false)
      loadCashRequisitions()
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (requisition?.id) loadCashRequisitions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, requisition?.id])

  useEffect(() => {
    if (selectedEntries?.length === 0) setSelectAll(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntries?.length])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('treasury'),
          localize('tellerCashRequisitions'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <>
                <div className="row mb-3">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <label
                        className="text-capitalize col-1"
                        htmlFor="searchByBranchId"
                      >
                        {localize('branch')}
                      </label>
                      <BranchLookup
                        displayValue={requisition?.description}
                        onSelect={handleSelectBranch}
                      />
                    </div>
                  </div>
                </div>

                {isBusy ? (
                  <Loader />
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                      <div className="d-flex align-items-center">
                        <label
                          style={{
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                          }}
                          className="text-capitalize"
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          className="form-select ms-2"
                          style={{ maxWidth: 'fit-content' }}
                          value={reqParams.pageSize}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              pageSize: e.target.value,
                            })
                          }
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <div
                        className="col d-flex align-items-center gap-2"
                        style={{ maxWidth: 'fit-content' }}
                      >
                        <label
                          style={{ minWidth: 'fit-content' }}
                          className="text-capitalize me-2"
                        >
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          sort
                          customContainerStyle={{
                            maxWidth: 150,
                            minWidth: 150,
                          }}
                          value={reqParams?.status}
                          enumsKey="TellerCashRequisitionAuthStatus"
                          onChange={({ value }) => {
                            setReqParams({
                              ...reqParams,
                              status: value,
                            })
                            setSelectAll(false)
                            setSelectedEntries([])
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <label
                          style={{ minWidth: 'fit-content' }}
                          className="text-capitalize me-2"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                      <div
                        className="col d-flex align-items-center gap-2"
                        style={{ maxWidth: 'fit-content' }}
                      >
                        <label
                          style={{ minWidth: 'fit-content' }}
                          className="text-capitalize me-2"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={reqParams?.text}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              text: e.target.value,
                              pageIndex: 0,
                            })
                          }
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              loadCashRequisitions()
                            }
                          }}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            loadCashRequisitions()
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </div>
                    </div>

                    <SimpleTable
                      data={requisitions?.pageCollection}
                      columns={columns}
                    />
                    <Pager
                      itemsCount={requisitions.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />

                    <div className="mt-3 bg-light rounded d-flex align-items-center justify-content-between p-2">
                      <div className="d-flex align-items-center">
                        <Checkbox
                          checked={selectAll}
                          onCheck={v => {
                            handleSelectAllRequisitions(v)
                          }}
                          id={'selectAllCashRequisitions'}
                        />

                        <label
                          className="text-capitalize ms-2"
                          htmlFor="selectAllCashRequisitions"
                        >
                          {localize('selectAll')}
                        </label>
                      </div>

                      <div className="d-flex justify-content-end align-items-center gap-3">
                        <button
                          className="btn btn-success"
                          onClick={handleReject}
                        >
                          {localize('reject')}
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={handleAuthorize}
                        >
                          {localize('authorize')}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CashRequisitions
