/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import DelegatesModal from './DelegatesModal'

function Delegates() {
  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedCustomerSerialNumber}</>,
      sortBy: 'paddedCustomerSerialNumber',
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
      sortBy: 'customerFullName',
    },
    {
      label: localize('account'),
      template: r => <>{r?.customerReference1}</>,
      sortBy: 'customerReference1',
    },
    {
      label: localize('membership'),
      template: r => <>{r?.customerReference2}</>,
      sortBy: 'customerReference2',
    },
    {
      label: localize('personalFile'),
      template: r => <>{r?.customerReference3}</>,
      sortBy: 'customerReference3',
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
      sortBy: 'customerIndividualNationalityDescription',
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
      sortBy: 'customerIndividualIdentityCardNumber',
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
      sortBy: 'customerIndividualGenderDescription',
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
      sortBy: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
      sortBy: 'remarks',
    },
    {
      label: localize('isAdministrative') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isAdministrative.toString())}
        </span>
      ),
      sortBy: 'isAdministrative',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedDelegate, setSelectedDelegate] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Delegate/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedDelegate(null)
  }

  function closeModal() {
    setMode(null)
    setSelectedDelegate(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('delegates')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="DelegateFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedDelegate({
                                id: r?.id,
                                customerId: r?.customerId,
                                customerFullName: r?.customerFullName,
                                fullName: r?.customerFullName,
                                membershipClassCustomerTypeDescription:
                                  r?.customerMembershipClassCustomerTypeDescription,
                                membershipStatusDescription:
                                  r?.customerMembershipClassCustomerStatusDescription,
                                branchDescription: r?.customerBranchDescription,
                                employerDescription:
                                  r?.customerEmployerDescription,
                                registrationDate: r?.customerRegistrationDate,
                                dutyStationDescription:
                                  r?.customerDutyStationDescription,
                                administrativeDivisionDescription:
                                  r?.customerAdministrativeDivisionDescription,
                                identificationNumber: r?.identificationNumber,
                                reference2: r?.customerReference2,
                                reference1: r?.customerReference1,
                                reference3: r?.customerReference3,
                                addressAddressLine1:
                                  r?.customerAddressAddressLine1,
                                addressAddressLine2:
                                  r?.customerAddressAddressLine2,
                                addressStreet: r?.customerAddressStreet,
                                addressCity: r?.customerAddressCity,
                                addressPostalCode: r?.customerAddressPostalCode,
                                addressEmail: r?.customerAddressEmail,
                                addressLandLine: r?.customerAddressLandLine,
                                addressMobileLine: r?.customerAddressMobileLine,
                                addressInternet: r?.customerAddressInternet,
                                isAdministrative: r?.isAdministrative,
                                isLocked: r?.isLocked,
                                remarks: r?.remarks,
                              })
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />

                    {mode && (
                      <DelegatesModal
                        mode={mode}
                        selectedDelegate={selectedDelegate}
                        setSelectedDelegate={setSelectedDelegate}
                        closeModal={closeModal}
                        loadTableData={loadTableData}
                      />
                    )}
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Delegates
