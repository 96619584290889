/** @format */

/* eslint-disable */
import React from 'react'
import { formatCurrency } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import { parseDate } from '../../../../../Helpers/formatters'

const ViewLogs = ({ handleClose, handleAction, mode, logs }) => {
  const tabItems = [
    {
      label: localize('image'),
      item: (
        <div className="p-2">
          <div
            className="mt-2 row"
            style={{
              position: 'relative',
              border: 'solid 1px #0000001f',
              borderRadius: '5px',
              height: '64px',
            }}
          >
            <div
              className="bg-light row px-2 text-capitalize"
              style={{
                position: 'absolute',
                top: '-4px',
                left: '30px',
                maxWidth: 'fit-content',
              }}
            >
              {localize('passport')}
            </div>
          </div>
        </div>
      ),
    },
  ]

  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={'ok'}
      modalTitle={localize('directDebitRequestsLogs')}
      modalClassName="modal-xl"
    >
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label htmlFor="status" className="text-capitalize col-6">
              {localize('status')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.status}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('loanRef')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.loanRef}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('payerName')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.payerName}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('payerBankCode')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.payerBankCode}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('payerBranchCode')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.payerBranchCode}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('payerAccountNo')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.payerAccountNo}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('payerMobileNumber')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.payerMobileNumber}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('originatorBankCode')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.originatorBankCode}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('originatorBranchCode')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.originatorBranchCode}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('originatorAccount')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.originatorAccount}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('originatorCode')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.originatorCode}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('mISAccountNumber')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.misAccountNumber}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('mISAccountDetails')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.misAccountDetails}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('reference')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.reference}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('startDate')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={parseDate(logs?.startDate)}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('endDate')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={parseDate(logs?.endDate)}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('dueDate')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={parseDate(logs?.dueDate)}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('dateOfEntry')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.dateOfEntry}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('cancelledBy')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.cancelledBy}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('dateCancelled')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.dateCancelled}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('amount')}
            </label>
            <input
              disabled
              type="text"
              className="form-control text-end"
              value={formatCurrency(logs?.amount, null, null, false)}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('collectionType')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.collectionType}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('transactionID')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.transactionId}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('isCancelled')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.isCancelled.toString()}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('frequency')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.frequency}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('inputter')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.inputter}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('verifiedBy')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.verifiedBy}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('policy1')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.policy1}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('policy2')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.policy2}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('reasonCancelled')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.reasonCancelled}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('modifiedBy')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.modifiedBy}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-6">
              {localize('modifiedDate')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.modifiedDate}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdBy')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={logs?.createdBy}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdDate')}
            </label>
            <input
              disabled
              type="text"
              className="form-control"
              value={parseDate(logs?.createdDate)}
            />
          </div>
        </div>
      </div>

      <div className="mb-4">
        <Tab preload tabItems={tabItems} />
      </div>
    </ModalTemplate>
  )
}
export default ViewLogs
