/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'

function Address({ customer, readOnly, onChange }) {
  const [sel, setSel] = useState(customer)

  useEffect(() => {
    if (!readOnly) {
      onChange(sel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel])

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('addressLine')} 1
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressAddressLine1}
            onChange={e =>
              setSel({ ...sel, addressAddressLine1: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('addressLine')} 2
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressAddressLine2}
            onChange={e =>
              setSel({ ...sel, addressAddressLine2: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('physicalAddress')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressStreet}
            onChange={e => setSel({ ...sel, addressStreet: e.target.value })}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('city')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressCity}
            onChange={e => setSel({ ...sel, addressCity: e.target.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('postalCode')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressPostalCode}
            onChange={e =>
              setSel({ ...sel, addressPostalCode: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('email')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressEmail}
            onChange={e => setSel({ ...sel, addressEmail: e.target.value })}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressLandLine}
            onChange={e => setSel({ ...sel, addressLandLine: e.target.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressMobileLine}
            onChange={e =>
              setSel({ ...sel, addressMobileLine: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('internet')}
          </label>
          <input
            type="text"
            className="form-control"
            value={sel?.addressInternet}
            onChange={e => setSel({ ...sel, addressInternet: e.target.value })}
            disabled={readOnly}
          />
        </div>
      </div>
    </div>
  )
}

export default Address
