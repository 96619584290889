/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import { chequeTruncationRequestFilter } from '../../../../../Global/enumeration'

function Catalogue() {
  const [data, setData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: 0,
    startDate: null,
    endDate: null,
    filterText: '',
    chequeTruncationRequestFilter: 22,
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 15,
  })
  const [groupBy, setGroupBy] = useState({
    label: 'type',
    column: 'typeDescription',
  })

  const columns = [
    {
      label: localize('resultCode'),
      template: r => <>{r?.resultCode}</>,
    },
    {
      label: localize('reason'),
      template: r => <>{r?.reason}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.customerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('currency'),
      template: r => <>{r?.currency}</>,
    },
    {
      label: localize('refNumber'),
      template: r => <>{r?.refNumber}</>,
    },
    {
      label: localize('drAccount'),
      template: r => <>{r.drAccount}</>,
    },
    {
      label: localize('drAmount'),
      template: r => <>{formatCurrency(r?.drAmount)}</>,
    },
    {
      label: localize('crAccount'),
      template: r => <>{r?.crAccount}</>,
    },
    {
      label: localize('crAmount'),
      template: r => <>{formatCurrency(r?.crAmount)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('postingDate'),
      template: r => <>{r?.postingDate}</>,
    },
    {
      label: localize('narrative'),
      template: r => <>{r?.narrative}</>,
    },
    {
      label: localize('mICR'),
      template: r => <>{r?.micr}</>,
    },
    {
      label: localize('chequeNumber'),
      template: r => <>{r?.chequeNumber}</>,
    },
    {
      label: localize('inputter'),
      template: r => <>{r?.inputter}</>,
    },
    {
      label: localize('branchNumber'),
      template: r => <>{r?.branchNumber}</>,
    },
    {
      label: localize('comments'),
      template: r => <>{r?.comments}</>,
    },
    {
      label: localize('indicator'),
      template: r => <>{r?.indicator}</>,
    },
    {
      label: localize('unpaidCode'),
      template: r => <>{r?.unpaidCode}</>,
    },
    {
      label: localize('transactionType'),
      template: r => <>{r?.transactionType}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchChequeTruncationRequestsCatalogue = async () => {
    setIsBusy(true)
    const url = '/ChequeTruncationRequest/find-by-filter-in-page'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setData(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchChequeTruncationRequestsCatalogue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('checkTruncationRequests'),
          localize('checkTruncationRequestsCatalogue'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div className="row d-flex justify-content-between mb-4">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col d-flex align-items-center space-between">
                  <label className="text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <select
                    className="form-select text-capitalize ms-3"
                    value={reqParams.chequeTruncationRequestFilter}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        chequeTruncationRequestFilter: e.target.value,
                      })
                    }
                  >
                    {Object.entries(chequeTruncationRequestFilter)
                      .map((e, i) => ({ label: e[1].name, value: e[0] }))
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((e, i) => (
                        <option
                          key={`system_filter_transaction_${i}`}
                          className="text-capitalize"
                          value={e.value}
                        >
                          {localize(e.label)}
                        </option>
                      ))}
                  </select>
                  <form
                    className="d-flex align-items-center col-4"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => setSearch(e.target.value)}
                    ></input>
                  </form>
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      fetchChequeTruncationRequestsCatalogue()
                    }}
                  >
                    <i className="mdi mdi-filter-variant"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="d-flex align-items-center col-4">
                  <label className="text-capitalize col-3">
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ChequeTruncationRequestPropertyGroup"
                    value={groupBy?.column === 'typeDescription' ? 0 : 1}
                    onChange={e => {
                      setGroupBy(
                        e.value == 0
                          ? { column: 'typeDescription', label: 'type' }
                          : { column: 'statusDescription', label: 'status' }
                      )
                    }}
                  />
                </div>
                <div className="d-flex align-items-center col">
                  <div className="d-flex align-items-center ms-4">
                    <label className="text-capitalize col-md-6">
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="ChequeTruncationRequestStatus"
                      value={reqParams?.status}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          status: parseInt(e.value),
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="col d-flex align-items-center">
                  <label htmlFor="search" className="text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                />
              </div>
              <Pager
                itemsCount={data?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Catalogue
