/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AdministrativeDivisionLookup from '../../../../../Components/Lookup/AdministrativeDivisionLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'

const CreateAdministrativeDivision = ({
  modalMode,
  handleCreate,
  handleUpdate,
  handleClose,
  isBusy,
  division,
  setDivision,
  gbv,
  clearValidation,
}) => {
  return (
    <ModalTemplate
      modalMode={modalMode}
      handleAction={modalMode === 'add' ? handleCreate : handleUpdate}
      handleClose={handleClose}
      disableClose={isBusy}
      disableCreate={isBusy}
      modalTitle="administrativeDivision"
      footerChildren={
        modalMode === 'edit' && (
          <div className=" d-flex align-items-center">
            <Checkbox
              id="editAdminDivIsLocked"
              checked={division?.isLocked}
              onCheck={v => setDivision({ ...division, isLocked: v })}
              disabled={isBusy}
            />
            <label
              htmlFor="editAdminDivIsLocked"
              className="text-capitalize me-3"
            >
              {localize('isLocked') + '?'}
            </label>
          </div>
        )
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('parent')}
            </label>
            <AdministrativeDivisionLookup
              displayValue={division?.parentDescription}
              onSelect={d =>
                setDivision({
                  ...division,
                  parentDescription: d.description,
                  parentId: d.id,
                })
              }
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center with-validation"
            validation-message={gbv?.Description?.message}
          >
            <label className="col-4 text-capitalize">{localize('name')}</label>
            <input
              type="text"
              className="form-control"
              value={division?.description}
              onChange={e => {
                clearValidation('Description')
                setDivision({ ...division, description: e.target.value })
              }}
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center with-validation"
            validation-message={gbv?.Type?.message}
          >
            <label className="col-4 text-capitalize">{localize('type')}</label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="AdministrativeDivisionType"
              value={division?.type}
              onChange={e => {
                clearValidation('Type')
                setDivision({
                  ...division,
                  type: e.value === 'select...' ? null : e.value,
                })
              }}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={division?.remarks}
              onChange={e =>
                setDivision({ ...division, remarks: e.target.value })
              }
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateAdministrativeDivision
