/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { PointOfSaleAgencyFilter } from '../../Global/enumeration'
import SimpleLookupWithFilters from './SimpleLookupWithFilters'

function PointOfSaleAgencyLookup({ onSelect, displayValue }) {
  return (
    <>
      <SimpleLookupWithFilters
        auxiliaryFilters={[
          {
            param: 'pointOfSaleAgencyFilter',
            defaultFilter: 0,
            filterType: PointOfSaleAgencyFilter,
          },
        ]}
        title={localize('pointOfSaleAgencyLookup')}
        url="/lookup/PointOfSaleAgency/find-pointofsale-agencies-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('Description'),
            template: r => <>{r.description}</>,
          },
          {
            label: localize('CommercialActivity'),
            template: r => <>{r.commercialActivity}</>,
          },
          {
            label: localize('PersonalIdentificationNumber'),
            template: r => <>{r.personalIdentificationNumber}</>,
          },
          {
            label: localize('CustomerFullName'),
            template: r => <>{r.customerFullName}</>,
          },
          {
            label: localize(
              'FloatCustomerAccountCustomerAccountTypeTargetProductDescription'
            ),
            template: r => (
              <>
                {
                  r.floatCustomerAccountCustomerAccountTypeTargetProductDescription
                }
              </>
            ),
          },
          {
            label: localize(
              'CommissionCustomerAccountCustomerAccountTypeTargetProductDescription'
            ),
            template: r => (
              <>
                {
                  r.commissionCustomerAccountCustomerAccountTypeTargetProductDescription
                }
              </>
            ),
          },
          {
            label: localize('AddressAddressLine1'),
            template: r => <>{r.addressAddressLine1}</>,
          },
          {
            label: localize('AddressAddressLine2'),
            template: r => <>{r.addressAddressLine2}</>,
          },
          {
            label: localize('AddressStreet'),
            template: r => <>{r.addressStreet}</>,
          },
          {
            label: localize('AddressPostalCode'),
            template: r => <>{r.addressPostalCode}</>,
          },
          {
            label: localize('AddressCity'),
            template: r => <>{r.addressCity}</>,
          },
          {
            label: localize('AddressEmail'),
            template: r => <>{r.addressEmail}</>,
          },
          {
            label: localize('AddressLandLine'),
            template: r => <>{r.addressLandLine}</>,
          },
          {
            label: localize('AddressMobileLine'),
            template: r => <>{r.addressMobileLine}</>,
          },
          { label: localize('Age'), template: r => <>{r.age}</> },
          {
            label: localize('RegistrationDate'),
            template: r => <>{r.registrationDate}</>,
          },
          {
            label: localize('ModifiedBy'),
            template: r => <>{r.modifiedBy}</>,
          },
          {
            label: localize('ModifiedDate'),
            template: r => <>{r.modifiedDate}</>,
          },
          { label: localize('CreatedBy'), template: r => <>{r.createdBy}</> },
          {
            label: localize('createdDate'),
            template: r => <>{r.createdDate}</>,
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default PointOfSaleAgencyLookup
