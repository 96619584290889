/** @format */

import { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import signalRService from '../../../../../Helpers/signalR'
import RolesTab from '../../../Setup/MessageGroups/RolesTab'

const Memorandum = () => {
  const [memo, setMemo] = useState('')
  const [selectedRoles, setSelectedRoles] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const handleSelectRole = (role, value) => {
    if (value) {
      setSelectedRoles([...selectedRoles, role])
    } else {
      setSelectedRoles(selectedRoles.filter(x => x.id !== role.id))
    }
  }

  const onSelectAll = (roles, value) => {
    if (value) {
      setSelectedRoles(roles)
    } else {
      setSelectedRoles([])
    }
  }

  const handleNotifyUsers = async () => {
    setIsBusy(true)

    for (const role of selectedRoles) {
      await signalRService.notifyUsersInRole(role, `MEMO: ${memo?.message}`)
    }

    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('memorandum'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-1">
                    {localize('message')}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder={localize('message')}
                    value={memo?.message}
                    onChange={e =>
                      setMemo({ ...memo, message: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('recipients'),
                      item: (
                        <RolesTab
                          onSelectAll={onSelectAll}
                          onSelectedRoleChange={handleSelectRole}
                          selectedRoleData={selectedRoles}
                        />
                      ),
                    },
                  ]}
                />
              </div>

              <div
                className="d-flex justify-content-end align-items-center p-2 rounded bg-light m-2"
                onClick={handleNotifyUsers}
              >
                <button className="btn btn-success" onClick={handleNotifyUsers}>
                  {localize('send')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Memorandum
