/** @format */

/**
 * NOTE: All labels must be in camel case
 */

import accountsMenus from './accountsMenus'
import administrationMenus from './administrationMenus'
import alternateChannelsMenus from './alternateChannelsMenus'
import backOfficeMenus from './backOfficeMenus'
import frontOfficeMenus from './frontOfficeMenus'
import humanResourceMenus from './humanResourceMenus'
import messagingMenus from './messagingMenus'
import microCreditMenus from './microCreditMenus'
import morgueMenus from './morgueMenus'
import registryMenus from './registryMenus'
import vehicleFleetMenus from './vehicleFleetMenus'
import procurementMenus from './procurementMenus'

const allMenus = [
  ...administrationMenus,
  ...accountsMenus,
  ...frontOfficeMenus,
  ...backOfficeMenus,
  ...registryMenus,
  ...microCreditMenus,
  ...humanResourceMenus,
  ...messagingMenus,
  ...alternateChannelsMenus,
  ...procurementMenus,
  ...vehicleFleetMenus,
  ...morgueMenus,
]

export default allMenus
