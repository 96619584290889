/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
function LoanRepaymentModeLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('departmentLookup')}
      url="/lookup/LoanRepaymentMode/find-loan-repayment-mode-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('standingOrderTrigger'),
          template: r => <>{r.standingOrderTriggerDescription}</>,
          sortBy: 'standingOrderTriggerDescription',
        },
        {
          label: localize('creditType'),
          template: r => <>{r.creditTypeDescription}</>,
          sortBy: 'creditTypeDescription',
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default LoanRepaymentModeLookup
