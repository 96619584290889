/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import { postData, putData } from '../../../../../Helpers/webApi'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import CreateChannelReconciliationPeriods from './Partials/CreateChannelReconciliationPeriods'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { alternateChannelReconciliationPeriodStatus } from '../../../../../Global/enumeration'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function ChannelReconciliationPeriods() {
  const [isBusy, setIsBusy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [channelPeriods, setChannelPeriods] = useState([])
  const [periods, setPeriods] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    status: Object.keys(alternateChannelReconciliationPeriodStatus)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  useEffect(() => {
    getChannelReconciliationPeriodsTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  const getChannelReconciliationPeriodsTableData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannelReconciliationPeriod/find-alternate-channel-reconciliation-reconciliation-periods-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setChannelPeriods(data)
    } else {
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedBatchNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('alternateChannelType'),
      template: r => <>{r?.alternateChannelTypeDescription}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.durationStartDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.durationEndDate)}</>,
    },
    {
      label: localize('transactionBackingStore'),
      template: r => <>{r?.transactionBackingStoreDescription}</>,
    },
    {
      label: localize('discreteSetDifferenceMode'),
      template: r => <>{r?.discreteSetDifferenceModeDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r?.authorizedBy}</>,
    },
    {
      label: localize('authorization/rejectionRemarks'),
      template: r => <>{r?.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectionDate'),
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    {
      label: localize('disregardPAN/CardNumber?'),
      template: r => <>{}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const handleCreateOrUpdate = async () => {
    const url =
      modalMode === 'add'
        ? '/AlternateChannelReconciliationPeriod/create-alternate-channel-reconciliation-period'
        : '/AlternateChannelReconciliationPeriod/update-updateAlternate-channel-reconciliation-period'

    if (modalMode === 'add') {
      setLoading(true)
      const { success } = await postData(
        url,
        periods,
        false,
        'Operation successful'
      )

      if (success) {
        setLoading(false)
        handleClose()
        showNotification(
          'Channel Reconciliation Periods created successfully',
          'success'
        )
        setRefresh(true)
      }

      setLoading(false)
    } else if (modalMode === 'edit') {
      setLoading(true)

      const { success } = await putData(
        url,
        periods,
        false,
        'Operation successful'
      )
      if (success) {
        setLoading(false)
        handleClose()
        showNotification(
          'Channel Reconciliation Periods Updated Successfully',
          'success'
        )
        setRefresh(true)
      }

      setLoading(false)
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  const handleDateSelect = v => {
    if (v.custom) {
      setReqParams({
        ...reqParams,
        customDate: true,
        startDate: v.startDate,
        endDate: v.endDate,
      })
    } else {
      setReqParams({
        ...reqParams,
        customDate: false,
        dateRequestsFilter: v.value,
      })
    }
  }

  const handleViewAuditLogs = item => {
    setPeriods(item)
    setModalMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelReconciliation'),
          localize('periods'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateChannelReconciliationPeriods
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          periods={periods}
          setPeriods={setPeriods}
          refresh={refresh}
          setRefresh={setRefresh}
          isLoading={loading}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={periods}
          title={periods?.paddedBatchNumber}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start mb-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                  setPeriods({})
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center col">
                  <label htmlFor="status" className="text-capitalize col-3">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    className="ms-2"
                    customContainerStyle={{ width: '50%' }}
                    enumsKey="DataAttachmentPeriodStatus"
                    value={reqParams.status.toString()}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }}
                    sort
                  />
                </div>
                <div className="d-flex align-items-center col-4">
                  <label
                    className="text-capitalize col-3"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {localize('dateRange')}
                  </label>
                  <div className="col ms-2">
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        handleDateSelect(v)
                      }}
                    />
                  </div>
                </div>
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <input
                        type="search"
                        className="form-control ms-2"
                        defaultValue={reqParams?.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={getChannelReconciliationPeriodsTableData}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={channelPeriods?.pageCollection}
                  contextMenu={x => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setModalMode('edit')
                          setPeriods(x)
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(x)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={channelPeriods?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ChannelReconciliationPeriods
