/** @format */

import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import EmolumentBatchEntries from './EmolumentBatchEntries'
import Tab from '../../../../../Components/Tabs/Tab'
import EmolumentBatchLookup from '../../../../../Components/Lookup/EmolumentBatchLookup'
import Loader from '../../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { getData, postData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import CustomerAccountPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import { customerAutoCompleteFilter } from '../../../../../Global/enumeration'

const EditEmolumentsPaymentSchedules = ({
  mode,
  close,
  selectedEmolumentsPaymentSchedule,
  setSelectedEmolumentsPaymentSchedule,
  loadTableData,
}) => {
  const [reqParams, setReqParams] = useState({
    status: 1,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const [entries, setEntries] = useState([])
  const [batchId, setBatchId] = useState(selectedEmolumentsPaymentSchedule.id)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [emolumentBatchEntry, setEmolumentBatchEntry] = useState(null)

  const handleOnSelectAccount = account => {
    if (!account.isLocked) {
      setSelectedAccount({ ...selectedAccount, ...account })
    } else {
      showNotification(
        'Edit Emolument Batch',
        'info',
        'Selected Account is Locked!'
      )

      setSelectedAccount(null)
    }
  }

  const updateEmonumentBatch = async () => {
    setLoading(true)
    const { success } = await postData(
      '/EmolumentBatch/update-emolument-batch',
      selectedEmolumentsPaymentSchedule,
      false
    )

    if (success) {
      showNotification(
        'Emolument Batch',
        'success',
        'Operation completed successfully!'
      )
      setLoading(false)
      setSelectedEmolumentsPaymentSchedule(null)
      loadTableData()
      close()
    }
    setLoading(false)
  }

  const updateEmonumentBatchEntries = async () => {
    setLoading(true)
    if (
      entries.find(
        d => d.customerAccountId === emolumentBatchEntry.customerAccountId
      )
    ) {
      setLoading(false)
      showNotification('Emolument Batch', 'info', 'Entry already exists!')
      setEmolumentBatchEntry(null)
      setSelectedAccount(null)
    } else {
      const { success } = await postData(
        '/EmolumentBatch/add-emolument-batch-entry',
        {
          emolumentBatchId: batchId,
          ...emolumentBatchEntry,
        },
        false
      )

      if (success) {
        showNotification(
          'Emolument Batch',
          'success',
          'Operation completed successfully!'
        )
        setLoading(false)
        setEmolumentBatchEntry(null)
        loadEntries()
        setSelectedAccount(null)
      }
      setLoading(false)
    }
  }

  const loadEntries = async () => {
    setLoading(true)
    const { success, data } = await getData(
      `/EmolumentBatch/find-emolument-batch-entries-by-emolument-batch-id-in-page`,
      {
        emolumentBatchId: batchId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setLoading(false)
      setEntries(data.pageCollection)
    } else {
      setLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [batchId, reqParams.pageSize, reqParams.pageIndex])

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.emolumentBatchId !== cust?.emolumentBatchId
      )
      setEntries(newEntry)
    }
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="emolumentsPaymentSchedule"
        handleClose={close}
        modalClassName="modal-xl"
        handleAction={updateEmonumentBatch}
      >
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center mb-2  ">
                  <label className="col-3 text-capitalize">
                    {localize('batch#')}
                  </label>
                  <EmolumentBatchLookup
                    displayValue={
                      selectedEmolumentsPaymentSchedule?.paddedBatchNumber
                    }
                    onSelect={async r => {
                      setBatchId(r.id)
                      setSelectedEmolumentsPaymentSchedule({
                        ...selectedEmolumentsPaymentSchedule,
                        emolumentTypeId: r.emolumentTypeId,
                        paddedBatchNumber: r.paddedBatchNumber,
                        emolumentTypeDescription: r.emolumentTypeDescription,
                        emolumentTypeAmount: r.emolumentTypeAmount,
                        chartOfAccountName: r.chartOfAccountName,
                        chartOfAccountId: r.chartOfAccountId,
                        reference: r.reference,
                        priority: r.priority,
                      })
                      await loadEntries()
                    }}
                    status={1}
                  />
                </div>
                <div className="col d-flex align-items-center mb-2  ">
                  <label className="col-3 text-capitalize">
                    {localize('emolumentType')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedEmolumentsPaymentSchedule?.emolumentTypeDescription
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center mb-2">
                  <label className="col-2 me-5 text-capitalize">
                    {localize('amount')}
                  </label>
                  <MaskNumber
                    className="form-control  ms-4"
                    defaultMaskValue={
                      selectedEmolumentsPaymentSchedule?.emolumentTypeAmount
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('postingPeriod')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      selectedEmolumentsPaymentSchedule?.postingPeriodDescription
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('chartOfAccountName')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedEmolumentsPaymentSchedule?.chartOfAccountName
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize me-3">
                    {localize('priority')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    value={selectedEmolumentsPaymentSchedule?.priority}
                    enumsKey={'QueuePriority'}
                    onChange={e => {
                      setSelectedEmolumentsPaymentSchedule({
                        ...selectedEmolumentsPaymentSchedule,
                        priority: e.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-1 text-capitalize">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEmolumentsPaymentSchedule?.reference}
                    disabled
                  />
                </div>
              </div>
            </>
          )}
          <div
            className="p-2 mt-2"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('batchDetails')}
            </div>

            <CustomerAccountPartial
              selectedAccount={selectedAccount}
              onCustomerAccountLookup={r => {
                handleOnSelectAccount(r)
                setEmolumentBatchEntry({
                  customerAccountId: r.id,
                  customerAccountCustomerReference2: r.customerReference2,
                  customerAccountCustomerReference1: r.customerReference1,
                  customerAccountCustomerReference3: r.customerReference3,
                  customerIndividualIdentityCardNumber:
                    r.customerIndividualIdentityCardNumber,
                  customerAccountCustomerAddressMobileLine:
                    r.customerAddressMobileLine,
                  beneficiary: r.customerFullName,
                  reference: r.customerReference1,
                  filterCreteria: Object.keys(customerAutoCompleteFilter)[0],
                })
              }}
            />
            <div className="row mt-2">
              <div className="col-3 d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('filterCreteria')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    width: '100%',
                  }}
                  value={emolumentBatchEntry?.filterCreteria}
                  enumsKey={'CustomerAutoCompleteFilter'}
                  onChange={e => {
                    setEmolumentBatchEntry({
                      ...emolumentBatchEntry,
                      filterCreteria: e.value,
                    })
                  }}
                />
              </div>
              {emolumentBatchEntry?.filterCreteria === '0' ? (
                <div className="col-md-9 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-4">
                    {localize('memberNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    value={
                      emolumentBatchEntry?.customerAccountCustomerReference2
                    }
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
              {emolumentBatchEntry?.filterCreteria === '1' ? (
                <div className="col-md-9 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-4">
                    {localize('accountNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    value={
                      emolumentBatchEntry?.customerAccountCustomerReference1
                    }
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
              {emolumentBatchEntry?.filterCreteria === '2' ? (
                <div className="col-md-9 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-4">
                    {localize('personalFileNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    value={
                      emolumentBatchEntry?.customerAccountCustomerReference3
                    }
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
              {emolumentBatchEntry?.filterCreteria === '3' ? (
                <div className="col-md-9 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-4">
                    {localize('identityCardNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    value={
                      emolumentBatchEntry?.customerIndividualIdentityCardNumber
                    }
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
              {emolumentBatchEntry?.filterCreteria === '4' ? (
                <div className="col-md-9 d-flex align-items-center">
                  <label className="col-md-1 text-capitalize me-4">
                    {localize('mobileLineNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-4"
                    value={
                      emolumentBatchEntry?.customerAccountCustomerAddressMobileLine
                    }
                    disabled
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="row mt-2">
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-3 text-capitalize me-3">
                  {localize('beneficiary')}
                </label>
                <input
                  type="text"
                  className="form-control ms-4"
                  defaultValue={emolumentBatchEntry?.beneficiary}
                  disabled
                />
              </div>
              <div className="col-md-9 d-flex align-items-center">
                <label className="col-1 text-capitalize me-4">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control ms-4"
                  defaultValue={emolumentBatchEntry?.reference}
                  disabled
                />
              </div>
            </div>
            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  if (!selectedEmolumentsPaymentSchedule) {
                    showNotification(
                      'Emolument Batch',
                      'info',
                      'sorry, but you must first select a batch!'
                    )
                  } else if (!emolumentBatchEntry) {
                    showNotification(
                      'Emolument Batch',
                      'info',
                      'sorry, but you must first select a customer account!'
                    )
                  } else {
                    updateEmonumentBatchEntries()
                  }
                }}
                className="btn btn-success px-5"
              >
                {localize('add')}
              </button>
            </div>
          </div>
          <div className="row col-12 mt-2">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Tab
                  tabItems={[
                    {
                      label: localize('batchEntries'),
                      item: (
                        <EmolumentBatchEntries
                          mode={mode}
                          selectedEmolumentsPaymentSchedule={
                            selectedEmolumentsPaymentSchedule
                          }
                          entries={entries}
                          loadEntries={loadEntries}
                          reqParams={reqParams}
                          setReqParams={setReqParams}
                          search={search}
                          setSearch={setSearch}
                          onSelectItem={onSelectItem}
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}
          </div>
        </>
      </ModalTemplate>
    </>
  )
}

export default EditEmolumentsPaymentSchedules
