/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import { useState } from 'react'
import { useEffect } from 'react'
import { getData } from '../../../Helpers/webApi'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import Tab from '../../../Components/Tabs/Tab'
import Pager from '../../../Components/Tables/Pager'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../Helpers/formatters'
import EditBranchModal from './EditBranchModal'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Branches() {
  const columns = [
    { label: localize('paddedCode'), template: r => <>{r?.paddedCode}</> },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('numberingSeries'),
      template: r => <>{r?.numberingSeriesDescription}</>,
    },
    {
      label: localize('company'),
      template: r => <>{r?.companyDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.addressCity}</> },
    { label: localize('emailAddress'), template: r => <>{r?.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r?.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('latitude'),
      template: r => <>{r?.addressLocationLatitude}</>,
    },
    {
      label: localize('longitude'),
      template: r => <>{r?.addressLocationLongitude}</>,
    },
    {
      label: localize('overrideAccountNumberFormatting'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.overrideAccountNumberFormatting?.toString())}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked?.toString())}
        </span>
      ),
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tabLoading, setTabLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [locations, setLocations] = useState([])
  const [operationsAcl, setOperationsAcl] = useState([])
  const [mode, setMode] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [onClickedOnce, setOnClickedOnce] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Branch/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const loadBranchLocations = async r => {
    setTabLoading(true)
    const { success, data } = await getData(
      `/Branch/find-location-by-branch-id?branchId=${r.id}`,
      {},
      false
    )
    if (success) {
      setTabLoading(false)
      setLocations(data || [])
    }
    setTabLoading(false)
  }

  const loadBranchOperationsAcl = async r => {
    setTabLoading(true)
    const { success, data } = await getData(
      `/ModuleNavigationItem/get-system-permission-type-in-branch?branchId=${r.id}`,
      {},
      false
    )
    if (success) {
      setTabLoading(false)
      setOperationsAcl(data || [])
    }
    setTabLoading(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedBranch(null)
  }

  const handleOnClickOnce = async r => {
    await loadBranchLocations(r)
    await loadBranchOperationsAcl(r)
    setOnClickedOnce(true)
  }

  const handleViewAuditLogs = item => {
    setSelectedBranch(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('branches')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={selectedBranch}
          title={selectedBranch?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleAdd} />
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="branchesFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      canClickOnce
                      onClickOnce={handleOnClickOnce}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedBranch(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                  {tabLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="row col-12 mt-2">
                        {onClickedOnce && (
                          <Tab
                            tabItems={[
                              {
                                label: localize('locations'),
                                item: (
                                  <BranchLocations clientData={locations} />
                                ),
                              },
                              {
                                label: localize('operationsAccessControl'),
                                item: (
                                  <BranchOperationsACL
                                    clientData={operationsAcl}
                                  />
                                ),
                              },
                            ]}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(mode === 'add' || mode === 'edit') && (
        <EditBranchModal
          mode={mode}
          selectedBranch={selectedBranch}
          editMode={mode === 'edit'}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}

const BranchLocations = ({ clientData, showLoader }) => {
  const columns = [
    { label: localize('code'), template: r => <>{r?.paddedCode}</> },
    { label: localize('name'), template: r => <>{r?.description}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        loading={showLoader}
        data={clientData}
        columns={columns}
        selectables
      />
    </>
  )
}

const BranchOperationsACL = ({ clientData, showLoader }) => {
  const columns = [
    {
      label: localize('systemPermissionType'),
      template: r => <>{r.systemPermissionTypeDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        loading={showLoader}
        data={clientData}
        columns={columns}
        selectables
      />
    </>
  )
}

export default Branches
