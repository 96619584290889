/** @format */

import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

export const CreateAlternateChannelActivationRequestModal = ({
  selectedCustomer,
  setSelectedCustomer,
  selectedAlternateChannelType,
  setSelectedAlternateChannelType,
  isBusy,
}) => {
  const handleOnSelectCustomer = customer => {
    setSelectedCustomer(customer)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {' '}
          <CustomerPartial
            onCustomerLookup={handleOnSelectCustomer}
            customer={selectedCustomer}
          />
          <div className="row mb-3">
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('channelType')}
              </label>
              <EnumsServerCombo
                enumsKey="AlternateChannelType"
                customContainerStyle={{
                  width: '100%',
                }}
                sort
                value={selectedAlternateChannelType}
                onChange={e => setSelectedAlternateChannelType(e.value)}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
