/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import DesignationLookup from '../../../../Components/Lookup/DesignationLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { SystemTransactionCode } from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../Helpers/extensions'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function EditDesignationModal({
  mode,
  editMode,
  close,
  selectedDesignation,
  loadTableData,
}) {
  const tBrColumns = [
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    {
      label: localize('threshold'),
      template: r => <>{formatCurrency(r?.threshold)}</>,
      headerTextAlign: 'right',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const initialTbr = {
    type: Object.keys(SystemTransactionCode)[1],
    typeDescription: SystemTransactionCode[1].name,
    threshold: '',
  }

  const [tempTransactionThresholds, setTempTransactionThresholds] = useState([])
  const [tBr, setTBr] = useState(initialTbr)
  const [isBusy, setIsBusy] = useState(false)
  const [transactionThresholdsMode, setTransactionThresholdsMode] =
    useState('add')
  const [designation, setDesignation] = useState(
    editMode ? selectedDesignation : {}
  )
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    loadData()
    if (editMode) {
      getParentDesignation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadData() {
    if (editMode) {
      setIsBusy(true)
      const { success, data } = await getData(
        '/Designation/find-transaction-threshold-by-designation-id',
        { designationId: selectedDesignation?.id },
        false
      )
      if (success) {
        setTempTransactionThresholds(data.result)
      }
      setIsBusy(false)
    }
  }

  const getParentDesignation = async () => {
    if (!designation?.parentId) return

    setIsBusy(true)
    const { success, data } = await getData(
      '/Designation/find-designation-by-id',
      { designationId: designation?.parentId },
      false
    )
    if (success) {
      setDesignation({
        ...designation,
        parentDescription: data.description,
      })
    }
    setIsBusy(false)
  }

  async function createDesignation() {
    setIsBusy(true)
    const { success } = await postData(
      '/Designation/create-designation-with-transaction-thresholds',
      {
        Designation: designation,
        TransactionThresholdDTOs: [],
      },
      false
    )
    if (success) {
      loadTableData()
      close()
      showNotification('Designations', 'success')
    }
    setIsBusy(false)
  }

  async function editDesignation() {
    setIsBusy(true)
    for (let b of tempTransactionThresholds) {
      if (b.id.toString().split('-').length < 5) delete b.id
    }
    const { success } = await putData(
      '/Designation/update-designation-with-transaction-thresholds',
      {
        Designation: designation,
        TransactionThresholdDTOs: tempTransactionThresholds,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      showNotification('Designations', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-xl"
        modalTitle={'designation'}
        modalMode={mode}
        handleAction={editMode ? editDesignation : createDesignation}
        handleClose={closeModal}
        actionText={editMode ? 'update' : 'create'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                id="cDesignationIsLocked"
                onCheck={e => {
                  setDesignation({
                    ...designation,
                    isLocked: e,
                  })
                }}
                checked={designation?.isLocked}
              />
              <label
                htmlFor="cDesignationIsLocked"
                className="text-capitalize mb-2"
              >
                {localize('designationIsLocked')} ?
              </label>
              <div className="mx-3"></div>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-2">
                    {localize('parentDesignation')}
                  </label>
                  <DesignationLookup
                    displayValue={designation?.parentDescription}
                    onSelect={d => {
                      if (d?.isLocked) {
                        return showNotification(
                          'Designations',
                          'info',
                          'Sorry, but the selected item is locked'
                        )
                      } else {
                        setDesignation({
                          ...designation,
                          parentDescription: d.description,
                          parentId: d.id,
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['Designation.Description']?.message
                  }
                >
                  <label className="text-capitalize col-1">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={
                      editMode
                        ? selectedDesignation?.description
                        : designation?.description
                    }
                    onChange={v => {
                      clearValidation(['Designation.Description'])
                      const value = v.target.value
                      setDesignation(prev => ({
                        ...prev,
                        description: value,
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-1">
                    {localize('remarks')}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={
                      editMode
                        ? selectedDesignation?.remarks
                        : designation?.remarks
                    }
                    onChange={v => {
                      const value = v.target.value
                      setDesignation(prev => ({
                        ...prev,
                        remarks: value,
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
            {editMode && (
              <>
                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 'fit-content',
                    }}
                  >
                    {localize('transactionThresholdDetails')}
                  </div>
                  <div className="row mb-3 g-3">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <label className="text-capitalize col-1">
                          {localize('type')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="SystemTransactionCode"
                          value={tBr?.type}
                          onChange={v =>
                            setTBr({
                              ...tBr,
                              type: v.value,
                              typeDescription:
                                SystemTransactionCode[v.value].name,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <label className="text-capitalize col-1">
                          {localize('threshold')}
                        </label>
                        <MaskNumber
                          defaultMaskValue={tBr?.threshold || 0}
                          onMaskNumber={c => {
                            setTBr({
                              ...tBr,
                              threshold: stripNonNumericCharacters(
                                c.target.value
                              ),
                            })
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {transactionThresholdsMode === 'edit' && (
                          <button
                            onClick={() => {
                              setTransactionThresholdsMode('add')
                              setTBr(initialTbr)
                            }}
                            className="text-uppercase btn btn-outline-danger px-4"
                          >
                            {localize('cancel')}
                          </button>
                        )}
                        {transactionThresholdsMode === 'edit' && (
                          <button
                            onClick={() => {
                              let transactionThresholdsList = [
                                ...tempTransactionThresholds,
                              ]
                              let newList = []
                              for (let b of transactionThresholdsList) {
                                if (b.id === tBr?.id) {
                                  newList.push(tBr)
                                } else {
                                  newList.push(b)
                                }
                              }
                              setTempTransactionThresholds(newList)
                              setTransactionThresholdsMode('add')
                              setTBr(initialTbr)
                            }}
                            className="btn btn-success ms-2 text-uppercase px-4"
                          >
                            {localize('update')}
                          </button>
                        )}
                        {transactionThresholdsMode === 'add' && (
                          <button
                            onClick={() => {
                              let transactionThresholdsList = [
                                ...tempTransactionThresholds,
                              ]
                              if (transactionThresholdsList.length === 0) {
                                setTempTransactionThresholds([
                                  ...tempTransactionThresholds,
                                  {
                                    id: generateGuid(),
                                    ...tBr,
                                  },
                                ])
                                setTBr(initialTbr)
                              } else {
                                let threshHold = [
                                  ...transactionThresholdsList,
                                ].find(t => t.type === tBr?.type)
                                if (threshHold) {
                                  showNotification(
                                    'Designations',
                                    'error',
                                    'sorry but transaction threshold alredy exists'
                                  )
                                  Swal.fire({
                                    icon: 'error',
                                    text: 'sorry but transaction threshold alredy exists',
                                    title: 'Designations',
                                    showCloseButton: true,
                                  })
                                } else {
                                  setTempTransactionThresholds([
                                    ...tempTransactionThresholds,
                                    {
                                      id: generateGuid(),
                                      ...tBr,
                                    },
                                  ])
                                  setTBr(initialTbr)
                                }
                              }
                            }}
                            className="btn btn-success px-5 mx-0 text-uppercase"
                          >
                            {localize('add')}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-2 mt-2"
                    style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
                  >
                    <div
                      className="bg-white row px-2 text-capitalize"
                      style={{
                        position: 'relative',
                        top: -20,
                        left: 30,
                        maxWidth: 'fit-content',
                      }}
                    >
                      {localize('transactionThresholdPreferences')}
                    </div>
                    <div style={{ marginTop: -15 }}>
                      {isBusy ? (
                        <div className="text-capitalize fw-bold">
                          {localize('pleaseWait') + '...'}
                        </div>
                      ) : (
                        <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                          <SimpleTable
                            columns={tBrColumns}
                            data={tempTransactionThresholds}
                            contextMenu={r => (
                              <>
                                <div
                                  className="ctxt-menu-item text-capitalize text-danger"
                                  onClick={() => {
                                    let ts = tempTransactionThresholds
                                    let found = ts.findIndex(
                                      t => t.id === r?.id
                                    )
                                    ts.splice(found, 1)
                                    setTempTransactionThresholds(ts)
                                  }}
                                >
                                  <i className="uil uil-times-circle text-danger text-capitalize"></i>
                                  {localize('remove')}
                                </div>
                              </>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default EditDesignationModal
