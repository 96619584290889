/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'

const AddNewButton = ({ handleClickAddButton, message }) => {
  return (
    <button
      onClick={handleClickAddButton}
      className="btn btn-success waves-effect waves-light text-capitalize"
    >
      <i className="mdi mdi-plus me-2"></i> {localize(message || 'addNew')}
    </button>
  )
}

export default AddNewButton
