/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import CreateMorgueBed from './CreateMorgueBed/CreateMorgueBed'

function MorgueBeds() {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [modalMode, setModalMode] = useState(null)
  const [bed, setBed] = useState({})
  const [groupBy, setGroupBy] = useState({
    label: localize('roomType'),
    column: 'roomTypeDescription',
    value: 0,
  })
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')

  const columns = [
    {
      label: 'description',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'roomDescription',
      template: r => <>{r?.roomDescription}</>,
    },
    {
      label: 'roomType',
      template: r => <>{r?.roomTypeDescription}</>,
    },
    {
      label: 'roomIsLocked' + ' ?',
      template: r => <>{r?.roomIsLocked?.toString()}</>,
    },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'isOccupied' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isOccupied?.toString()}</span>
      ),
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      column: 'roomTypeDescription',
      value: 0,
    },
    {
      value: 1,
      column: 'createdBy',
    },
    {
      value: 2,
      column: 'modifiedBy',
    },
    {
      value: 3,
      column: 'isOccupied',
    },
    {
      value: 3,
      column: 'isLocked',
    },
  ]

  const fetchMorgueBeds = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Bed/find-beds-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  const handleCancel = () => {
    setBed({})
    setModalMode(null)
  }

  useEffect(() => {
    fetchMorgueBeds()
    //eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.text])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <BreadCrumb tree={[localize('morgue'), localize('morgueBeds')]} />
        </div>
      </div>
      <div className="card rounded  bg-white">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <div className="d-flex align-items-center col-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('groupBy')}
                  </label>

                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={groupBy?.value}
                    enumsKey="BedPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div className="col-md-3 offset-md-3">
                  <form
                    className="d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({ ...reqParams, text: search, pageIndex: 0 })
                    }}
                  >
                    <label
                      htmlFor="searchTable"
                      className="ms-2 text-capitalize"
                    >
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                    <button className="btn btn-primary ms-2">
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={data.pageCollection}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setBed(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => {
                  setReqParams({ ...reqParams, pageIndex: p })
                }}
              />
            </>
          )}
        </div>
      </div>

      {modalMode && (
        <CreateMorgueBed
          bed={bed}
          fetchMorgueBeds={fetchMorgueBeds}
          handleClose={handleCancel}
          mode={modalMode}
          setBed={setBed}
        />
      )}
    </>
  )
}

export default MorgueBeds
