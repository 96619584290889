/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import SuppliersModal from './Partials/SuppliersModal'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function Suppliers() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [sortedBy, setSortedBy] = useState(null)

  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('supplierType'),
      template: r => <>{r.supplierTypeDescription}</>,
    },
    { label: localize('creditPeriod'), template: r => <>{r.creditPeriod}</> },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r.availableBalance)}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    text: '',
    pageSize: 10,
    pageIndex: 0,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Supplier/find-suppliers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnEdit = r => {
    setSelectedSupplier({ ...r })
    setMode('edit')
  }

  const handleOnView = r => {
    setSelectedSupplier({ ...r })
    setMode('view')
  }

  const handleOnAdd = r => {
    setSelectedSupplier(null)
    setMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetManagement'),
          localize('procurement'),
          localize('suppliers'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-search"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <SuppliersModal
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default Suppliers
