/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../../Components/Tables/Pager'
import {
  JournalVoucherAuthOption,
  JournalVoucherEntryStatus,
} from '../../../../../../../Global/enumeration'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'
import {
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../../../Helpers/formatters'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

export const JournalVoucherAuthorizationEntriesAuthorize = ({
  journalVoucherId,
  verificationModels,
  setVerificationModels,
  validationErrors,
  clearValidation,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    journalVoucherId: journalVoucherId,
    status: Object.keys(JournalVoucherEntryStatus)[0],
    pageIndex: 0,
    pageSize: 10,
  })

  const [journalVoucherEntries, setJournalVoucherEntries] = useState(null)

  const fetchJournalVoucherEntries = async id => {
    const { success, data } = await getData(
      '/JournalVoucher/find-journal-voucher-entries-by-journal-voucher-id-in-page',
      { ...reqParams, journalVoucherId: id },
      false
    )
    if (success) {
      setIsBusy(false)
      setJournalVoucherEntries(data)
    } else {
      setIsBusy(false)
    }
  }

  const refetchData = () => {
    if (journalVoucherId) {
      fetchJournalVoucherEntries(journalVoucherId)
    }
  }

  useEffect(() => {
    refetchData()
  }, [journalVoucherId])

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(stripNonNumericCharacters(r.amount))}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => (
        <>
          {r.customerAccountTypeTargetProductChartOfAccountName ||
            r.chartOfAccountName}
        </>
      ),
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')}#)`,
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')}#)`,
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFileNumber')}#)`,
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-3">
              <label className="col-3 text-capitalize me-3">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="JournalVoucherEntryStatus"
                value={reqParams.discrepancyFilter}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }}
                sort
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <input
                defaultValue={reqParams.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                  })
                }
                className="form-control"
                placeholder="Enter text..."
                type="text"
              />
              <button
                id="customLookupFilterButton"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  refetchData()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>

          <SimpleTable
            columns={columns}
            data={journalVoucherEntries?.pageCollection}
          />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportionment')}
              </label>
              <input
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(
                  journalVoucherEntries?.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  journalVoucherEntries?.totalShortage,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('entries')}
              </label>
              <input
                className="form-control"
                disabled
                value={journalVoucherEntries?.statusEntries}
              />
            </div>
          </div>
          <Pager
            itemsCount={journalVoucherEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div
            style={{ borderRadius: 5 }}
            className="bg-light d-flex align-content-end justify-content-end p-2 mt-3 mb-3"
          >
            <div className="d-flex align-items-center me-5">
              <Checkbox
                id={'suppress-maker-checker-validation'}
                checked={verificationModels?.suppressMakerCheckerValidation}
                onCheck={v =>
                  setVerificationModels({
                    ...verificationModels,
                    suppressMakerCheckerValidation: v,
                  })
                }
              />
              <label
                htmlFor="suppress-maker-checker-validation"
                className="text-capitalize ms-2"
              >
                {localize('suppress-maker-checker-validation')}?
              </label>
            </div>
            <div className="d-flex align-items-center me-5">
              <div
                validation-message={
                  validationErrors?.['JournalVoucher.AuthorizationRemarks']
                    ?.message
                }
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  defaultValue={verificationModels?.authorizationRemarks}
                  onChange={e => {
                    clearValidation('JournalVoucher.AuthorizationRemarks')
                    setVerificationModels({
                      ...verificationModels,
                      authorizationRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center me-5">
              <label className="col-3 text-capitalize me-3">
                {localize('action')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="JournalVoucherAuthOption"
                value={verificationModels?.journalVoucherAuthOption}
                onChange={e => {
                  setVerificationModels({
                    ...verificationModels,
                    journalVoucherAuthOption: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const JournalVoucherAuthorizeEntriesView = ({ journalVoucherId }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    journalVoucherId: journalVoucherId,
    status: Object.keys(JournalVoucherEntryStatus)[0],
    pageIndex: 0,
    pageSize: 10,
  })

  const [journalVoucherEntries, setJournalVoucherEntries] = useState(null)
  const [selectedAuthOption, setSelectedAuthOption] = useState(
    Object.keys(JournalVoucherAuthOption)[0]
  )

  const fetchJournalVoucherEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/JournalVoucher/find-journal-voucher-entries-by-journal-voucher-id-in-page',
      { ...reqParams, journalVoucherId: id },
      false
    )
    if (success) {
      setIsBusy(false)
      setJournalVoucherEntries(data)
    } else {
      setIsBusy(false)
    }
  }

  const refetchData = () => {
    if (journalVoucherId) {
      fetchJournalVoucherEntries(journalVoucherId)
    }
  }

  useEffect(() => {
    refetchData()
  }, [journalVoucherId])

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(stripNonNumericCharacters(r.amount))}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => (
        <>
          {r.customerAccountTypeTargetProductChartOfAccountName ||
            r.chartOfAccountName}
        </>
      ),
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')}#)`,
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')}#)`,
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFileNumber')}#)`,
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex mt-3 mb-3">
            <div className="d-flex align-items-start me-auto">
              <div className="d-flex align-items-center me-5">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="JournalVoucherEntryStatus"
                  value={reqParams.discrepancyFilter}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                    })
                  }}
                  sort
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <label className="col-3 text-capitalize">
                  {localize('search')}
                </label>
                <input
                  defaultValue={reqParams.filterText}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Enter text..."
                  type="text"
                />
                <button
                  id="customLookupFilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    refetchData()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={journalVoucherEntries?.pageCollection}
          />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportionment')}
              </label>
              <input
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(
                  journalVoucherEntries?.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  journalVoucherEntries?.totalShortage,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('entries')}
              </label>
              <input
                className="form-control"
                disabled
                value={journalVoucherEntries?.statusEntries}
              />
            </div>
          </div>
          <Pager
            itemsCount={journalVoucherEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}
