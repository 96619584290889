/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'

const EmployeeTypeSavingsProducts = ({
  disabled,
  isBusy,
  selectedEmployeeType,
  setSelectedEmployeeType,
  prefix,
}) => {
  const handleCheckOffsetableSavingsProducts = (
    savingsProducts,
    checkValue
  ) => {
    const com = [...selectedEmployeeType?.savingsProductCollection].map(
      comm => {
        if (comm.id === savingsProducts.id) {
          return { ...comm, checked: checkValue }
        }
        return comm
      }
    )

    setSelectedEmployeeType({
      ...selectedEmployeeType,
      savingsProductCollection: com,
    })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedEmployeeType?.savingsProductCollection.map(
            savingsProducts => (
              <li
                key={savingsProducts.id}
                style={{ listStyle: 'none' }}
                className=" d-flex align-items-center"
              >
                <Checkbox
                  id={`${prefix ? prefix : 'com'}-${savingsProducts.id}`}
                  checked={savingsProducts.checked}
                  disabled={disabled}
                  margined
                  onCheck={c => {
                    handleCheckOffsetableSavingsProducts(savingsProducts, c)
                  }}
                />
                <label
                  htmlFor={`${prefix ? prefix : 'com'}-${savingsProducts.id}`}
                  className={`fw-normal ms-2 cursor-pointer ${
                    savingsProducts.checked ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {savingsProducts.description}
                </label>
              </li>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default EmployeeTypeSavingsProducts
