/** @format */

import localize from '../../../../../../Global/Localize'
import React from 'react'
import { ViewSupplierStatement } from './ViewSupplierStatement'
import Tab from '../../../../../../Components/Tabs/Tab'

export const ViewSupplierModal = ({ selectedSupplier }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('name')}</label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.description}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('supplierType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.supplierTypeDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressAddressLine1}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressAddressLine2}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressStreet}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressPostalCode}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('city')}</label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressCity}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('email')}</label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressEmail}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('landLine')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressLandLine}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.addressMobileLine}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('creditPeriod')}
          </label>
          <input
            className="form-control"
            disabled
            type="number"
            value={selectedSupplier?.creditPeriod}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            disabled
            value={selectedSupplier?.remarks}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            tabItems={[
              {
                label: localize('accountStatement'),
                item: (
                  <ViewSupplierStatement selectedSupplier={selectedSupplier} />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
