/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import InvestmentAccounts from '../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import SavingsAccounts from '../../../../../Components/ReUsables/SavingsAccounts/SavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { ProductCode, RecordStatus } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { getData, postData } from '../../../../../Helpers/webApi'
import Specimen from '../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'

const ConsolidationAndNettingOff = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyUpdating, setIsBusyIsBusyUpdating] = useState(false)
  const [customer, setCustomer] = useState({})
  const [investmentProduct, setInvestmentProduct] = useState({})
  const [customerAccounts, setCustomerAccounts] = useState({
    customerLoanAccounts: [],
    customerSavingsAccounts: [],
    customerInvestmentAccounts: [],
  })
  const [selectedLoanAccounts, setSelectedLoanAccounts] = useState([])
  const [mode, setMode] = useState(null)

  const fetchCustomerAccounts = async () => {
    setIsBusy(true)
    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customer?.id,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)
    }

    setIsBusy(false)
  }

  const handleOnLookup = customer => {
    if (customer?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected Customer is locked'
      )

    if (customer?.recordStatus !== Object.keys(RecordStatus)[2])
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected Customer record status must be approved'
      )

    setCustomer(customer)
  }

  const handleSelectInvestmentProduct = product => {
    if (!customer?.id)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a customer first'
      )

    if (product?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected beneficiary Product is locked'
      )

    setInvestmentProduct(product)
  }

  const selectAllLoanAccounts = (accounts, value) => {
    if (value) {
      setSelectedLoanAccounts(accounts)
    } else {
      setSelectedLoanAccounts([])
    }
  }

  const handleSelectLoanAccount = (account, value) => {
    if (value) {
      setSelectedLoanAccounts([...selectedLoanAccounts, account])
    } else {
      setSelectedLoanAccounts(
        selectedLoanAccounts.filter(x => x.id !== account.id)
      )
    }
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  const handleClickUpdateButton = () => {
    if (!customer?.id)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a customer first'
      )

    if (!investmentProduct?.id)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a beneficiary product'
      )

    if (!selectedLoanAccounts?.length)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a loan account'
      )

    setMode('update')
  }

  const handleUpdate = async () => {
    setIsBusyIsBusyUpdating(true)
    const url = '/LoanCase/consolidate-customer-funds-then-net-off-loans'

    const { success } = await postData(
      url,
      {
        customer,
        customerLoanAccountDTOs: selectedLoanAccounts,
        beneficiaryInvestmentProductId: investmentProduct?.id,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setCustomer({})
      setInvestmentProduct({})
      setSelectedLoanAccounts([])
      setCustomerAccounts({
        customerLoanAccounts: [],
        customerSavingsAccounts: [],
        customerInvestmentAccounts: [],
      })
      showNotification('fundsConsolidation&NettingOff', 'success')
      handleCloseModal()
    }

    setIsBusyIsBusyUpdating(false)
  }

  useEffect(() => {
    if (customer?.id) fetchCustomerAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  return (
    <>
      {mode === 'update' && (
        <ActionModal
          modalTitle="fundsConsolidation&NettingOff"
          handleClose={handleCloseModal}
          handleAction={handleUpdate}
          confirmText="yes"
          cancelText="no"
          disableAction={isBusyUpdating}
          disableClose={isBusyUpdating}
        >
          {isBusyUpdating ? <Loader /> : <p>Do you want to proceed ?</p>}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('fundsConsolidation&NettingOff'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <CustomerPartial
                  onCustomerLookup={handleOnLookup}
                  customer={customer}
                />

                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('beneficiaryProduct')}
                    </label>
                    <InvestmentProductLookup
                      displayValue={investmentProduct?.description}
                      onSelect={handleSelectInvestmentProduct}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Tab
                  tabItems={[
                    {
                      label: localize('savingsAccounts(consolidate)'),
                      item: (
                        <>
                          <SavingsAccounts
                            data={customerAccounts?.customerSavingsAccounts}
                          />
                        </>
                      ),
                    },
                    {
                      label: localize('investmentAccounts(consolidate)'),
                      item: (
                        <>
                          <InvestmentAccounts
                            data={customerAccounts?.customerInvestmentAccounts}
                          />
                        </>
                      ),
                    },
                    {
                      label: localize('loansAccounts(netOff)'),
                      item: (
                        <>
                          <LoanAccounts
                            data={customerAccounts?.customerLoanAccounts}
                            handleSelectLoanAccount={handleSelectLoanAccount}
                            selectAllLoanAccounts={selectAllLoanAccounts}
                            selectedLoanAccounts={selectedLoanAccounts}
                            canSelect
                          />
                        </>
                      ),
                      icon: "uil uil-edit"
                    },
                    {
                      label: localize('specimen'),
                      item: (
                        <>
                          <Specimen customer={customer} readOnly />
                        </>
                      ),
                    },
                  ]}
                />
              </div>

              <div className="mt-2 bg-white rounded py-3 px-2">
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    onClick={handleClickUpdateButton}
                    className="btn btn-success"
                  >
                    {localize('update')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ConsolidationAndNettingOff
