/** @format */

import React from 'react'
import { useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import EmployeeLookup from '../../../../../../Components/Lookup/EmployeeLookup'
import { getData, putData } from '../../../../../../Helpers/webApi'
import { useEffect } from 'react'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../Components/Loaders/Loader'

const Recommendation = () => {
  const [employee, setEmployee] = useState({})
  const [recommendation, setRecommendation] = useState({})
  const [employeeAppraisalPeriod, setEmployeeAppraisalPeriod] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const fetchCurrentUser = async () => {
    const url = '/AccessControl/find-current-user'

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setEmployee(data)
    }
  }

  const fetchEmployeeCurrentAppraisalPeriod = async () => {
    setIsBusy(true)

    const url = '/EmployeeAppraisalPeriod/find-current'

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setEmployeeAppraisalPeriod(data)
    }

    setIsBusy(false)
  }

  const findEmployeeRecommendation = async () => {
    const url = '/EmployeeAppraisalPeriod/find-recommendation'

    const { success, data } = await getData(
      url,
      {
        employeeId: employee?.id,
        employeeAppraisalPeriodId: employeeAppraisalPeriod?.id,
      },
      false
    )

    if (success) {
      setRecommendation(data?.description)
    }
  }

  const handleUpdateRecommendation = async () => {
    setIsBusy(true)

    const url = '/EmployeeAppraisalPeriod/update-recommendation'

    const { success } = await putData(
      url,
      {
        id: recommendation?.id,
        recommendation: recommendation?.recommendation,
        employeeId: employee?.id,
        employeeCustomerFullName: employee?.customerFullName,
        employeeAppraisalPeriodId: employeeAppraisalPeriod?.id,
        employeeAppraisalPeriodDescription:
          employeeAppraisalPeriod?.description,
      },
      false
    )

    if (success) {
      setRecommendation({})
      showNotification('Appraisal Recommendation.', 'success')
      fetchCurrentUser()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchCurrentUser()
    fetchEmployeeCurrentAppraisalPeriod()
  }, [])

  useEffect(() => {
    if (employee?.id && employeeAppraisalPeriod?.id)
      findEmployeeRecommendation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee?.id, employeeAppraisalPeriod?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResource'),
          localize('performanceManagement'),
          localize('appraisalRecommendation'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3">
                <div className="col-md-6 d-flex align-items-center">
                  <label className="col-4  me-1 text-capitalize">
                    {localize('employee')}
                  </label>
                  <EmployeeLookup
                    displayValue={employee?.customerFullName}
                    onSelect={e => setEmployee(e)}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('appraisalPeriod')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={employeeAppraisalPeriod?.description}
                    disabled
                  />
                </div>

                <div className="col-md-12 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('recommendation')}
                  </label>

                  <textarea
                    className="form-control"
                    rows="3"
                    value={recommendation?.recommendation}
                    onChange={e =>
                      setRecommendation({
                        ...recommendation,
                        recommendation: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="mt-4 rounded bg-light d-flex justify-content-end p-2">
                <button
                  onClick={handleUpdateRecommendation}
                  className="btn btn-success"
                >
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Recommendation
