/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import VehicleLookup from '../../../../../Components/Lookup/VehicleLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { downloadReceipt } from '../../../../../Helpers/blobs'
import {
  apportionTo,
  apportionedComponent,
  journalEntryReferenceType,
} from '../../../../../Helpers/constants'
import { generateGuid } from '../../../../../Helpers/extensions'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import CustomerPreview from './CustomerPreview/CustomerPreview'
import TellerGLAccountStatement from './TellerGLAccountStatement/TellerGLAccountStatement'
import Transaction from './Transaction/Transaction'

const Collections = () => {
  const [vehicle, setVehicle] = useState({})
  const [transaction, setTransaction] = useState({
    paymentMode: 0,
    transactionAmount: 0,
  })
  const [apportionments, setApportionments] = useState([])
  const [teller, setTeller] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingApportionments, setIsBusyLoadingApportionments] =
    useState(false)
  const [previousTellerBalance, setPreviousTellerBalance] = useState(0)
  const [newTellerBalance, setNewTellerBalance] = useState(0)
  const [apportioned, setApportioned] = useState(0)
  const [shortage, setShortage] = useState(0)
  const [totalValue, setTotalValue] = useState(0)
  const [selectedApportionments, setSelectedApportionments] = useState([])
  const [isBusyFetchingTeller, setIsBusyFetchingTeller] = useState(false)
  const [mode, setMode] = useState(null)
  const [receipt, setReceipt] = useState({})

  const handleSelectApportionment = (r, value) => {
    if (value) {
      setSelectedApportionments([...selectedApportionments, r])
    } else {
      setSelectedApportionments(
        selectedApportionments.filter(
          item => item?.productDescription !== r?.productDescription
        )
      )
    }
  }

  const handleClose = () => {
    setMode(null)
  }

  const handleRemove = () => {
    if (selectedApportionments.length === 0)
      return showNotification(
        'Operation not allowed',
        'error',
        'Sorry, but you must first select an apportionment!'
      )

    const newApportionments = apportionments.filter(
      item => !selectedApportionments.some(i => i?.id === item?.id)
    )

    setApportionments(newApportionments)
    setSelectedApportionments([])
    setApportioned(
      newApportionments.reduce((a, b) => a + Number(b.apportionedAmount), 0)
    )
    setShortage(
      totalValue -
        newApportionments.reduce((a, b) => a + Number(b.apportionedAmount), 0)
    )
  }

  const fetchCurrentTeller = async () => {
    setIsBusyFetchingTeller(true)
    const url = '/Teller/find-teller-by-employee-id'

    const { success, data } = await getData(
      url,
      {
        includeBalance: true,
      },
      false
    )

    if (success) {
      setTeller(data)
      setPreviousTellerBalance(data?.bookBalance)
      setNewTellerBalance(data?.bookBalance)
    }

    setIsBusyFetchingTeller(false)
  }

  const handleSelectVehicle = vehicle => {
    setVehicle(vehicle)
  }

  const fetchApportionments = async () => {
    setIsBusyLoadingApportionments(true)

    const url = `/Vehicle/find-vehicle-products-by-vehicle-id?vehicleId=${vehicle?.id}`

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setApportionments(
        data?.result?.map(item => ({
          reference: `Vehicle Route: ${vehicle.vehicleRouteDescription}`,
          apportionTo: apportionTo.CUSTOMER_ACCOUNT,
          apportionedComponent: apportionedComponent.AGGREGATE,
          creditCustomerAccountId: item.customerAccountId,
          debitCustomerAccountId: item.customerAccountId,
          fullAccountNumber: item.fullAccountNumber,
          productDescription: item.vehicleFleetProductDescription,
          primaryDescription: `Vehicle Reg. #: ${vehicle.registrationNumber}`,
          secondaryDescription: `Vehicle Fleet #: ${vehicle.fleetNumber}`,
          journalEntryReferenceId: item.id,
          journalEntryReferenceType: journalEntryReferenceType.VEHICLE_PRODUCT,
          creditChartOfAccountId: generateGuid(),
          contribution: item.contribution,
          apportionedAmount: item.contribution,
        }))
      )

      setApportioned(
        data?.result?.reduce((a, b) => a + Number(b.contribution), 0)
      )
      setShortage(
        totalValue -
          data?.result?.reduce((a, b) => a + Number(b.contribution), 0)
      )
    }

    setIsBusyLoadingApportionments(false)
  }

  const handleClickUpdate = () => {
    setMode('proceed')
  }

  const handleUpdate = async () => {
    setIsBusy(true)

    const url = '/Vehicle/process-vehicle-collection-transaction'

    const { success, data } = await postData(
      url,
      {
        vehicleCollectionPaymentMode: transaction?.paymentMode,
        selectedVehicle: vehicle,
        transactionAmount: transaction?.transactionAmount,
        apportionmentWrappers: apportionments,
      },
      false
    )

    if (success) {
      Swal.fire(
        'Vehicle Collections',
        'Operation completed successfully!',
        'success'
      ).then(() => {
        setReceipt(data?.message)
        setMode('print')
      })
    }

    setIsBusy(false)
  }

  const handlePrint = async () => {
    downloadReceipt(receipt, 'receipt.txt')

    if (mode === 'print') {
      setMode('re-print')
    }
  }

  const handleRePrint = async () => {
    downloadReceipt(receipt, 'receipt.txt')

    if (mode === 'print') {
      setMode('re-print')
    }
  }

  const handleClosePrint = () => {
    setMode(null)
    setReceipt({})
    setApportionments([])
    setTransaction({
      paymentMode: 0,
      transactionAmount: 0,
    })
    setApportioned(0)
    setShortage(0)
    setTotalValue(0)
    fetchApportionments()
  }

  useEffect(() => {
    if (vehicle?.id) {
      fetchApportionments()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle?.id])

  useEffect(() => {
    fetchCurrentTeller()
  }, [])

  return (
    <>
      {mode === 'proceed' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={handleClose}
          modalTitle="vehicleCollections"
          handleAction={handleUpdate}
          disableAction={isBusy}
          disableClose={isBusy}
        >
          {isBusy ? <Loader /> : <p>Do you want to proceed ?</p>}
        </ActionModal>
      )}

      {mode === 'print' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={handleClosePrint}
          modalTitle="vehicleCollections"
          handleAction={handlePrint}
        >
          <p>Do you want to print ?</p>
        </ActionModal>
      )}
      {mode === 're-print' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={handleClosePrint}
          modalTitle="vehicleCollections"
          handleAction={handleRePrint}
        >
          <p>Do you want to re-print ?</p>
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('vehicleFleet'),
          localize('accounts'),
          localize('collections'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusyFetchingTeller ? (
            <Loader />
          ) : (
            <>
              <div className="row g-2 mb-4">
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('registrationNumber')}
                  </label>
                  <VehicleLookup
                    onSelect={handleSelectVehicle}
                    displayValue={vehicle?.registrationNumber}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('fleetNumber')}
                  </label>
                  <input
                    className="form-control"
                    value={vehicle?.fleetNumber}
                    disabled
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('vehicleRoute')}
                  </label>
                  <input
                    className="form-control"
                    value={vehicle?.vehicleRouteDescription}
                    disabled
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('vehicleSerialNumber')}
                  </label>
                  <input
                    className="form-control"
                    value={vehicle?.paddedSerialNumber}
                    disabled
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('documentReferenceNumber')}
                  </label>
                  <input
                    className="form-control"
                    value={vehicle?.documentReferenceNumber}
                    disabled
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <label className="text-capitalize col-2">
                    {localize('remarks')}
                  </label>
                  <input
                    className="form-control"
                    value={vehicle?.remarks}
                    disabled
                  />
                </div>
              </div>
              <Tab
                preload
                tabItems={[
                  {
                    label: localize('transaction'),
                    item: (
                      <Transaction
                        transaction={transaction}
                        setTransaction={setTransaction}
                        apportionments={apportionments}
                        isBusyLoadingApportionments={
                          isBusyLoadingApportionments
                        }
                        apportioned={apportioned}
                        setApportioned={setApportioned}
                        shortage={shortage}
                        selectedApportionments={selectedApportionments}
                        handleRemove={handleRemove}
                        handleSelectApportionment={handleSelectApportionment}
                        previousTellerBalance={previousTellerBalance}
                        setNewTellerBalance={setNewTellerBalance}
                        setShortage={setShortage}
                        setTotalValue={setTotalValue}
                        setApportionments={setApportionments}
                        totalValue={totalValue}
                      />
                    ),
                  },
                  {
                    label: localize('tellerGlAccountStatement'),
                    item: (
                      <TellerGLAccountStatement
                        chartOfAccountId={teller?.chartOfAccountId}
                        previousTellerBalance={previousTellerBalance}
                        newTellerBalance={newTellerBalance}
                        setNewTellerBalance={setNewTellerBalance}
                        setPreviousTellerBalance={setPreviousTellerBalance}
                        teller={teller}
                      />
                    ),
                  },
                  {
                    label: localize('customerPreview'),
                    item: <CustomerPreview customerId={vehicle?.customerId} />,
                  },
                ]}
              />
              <div className="mt-4 border border-primary rounded p-2 gap-3 d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('currentTellerBalance')}
                  </label>
                  <input
                    className="form-control text-end"
                    value={formatCurrency(
                      previousTellerBalance,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('newTellerBalance')}
                  </label>
                  <input
                    className="form-control text-end"
                    value={formatCurrency(newTellerBalance, null, null, false)}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-4 bg-light rounded p-2 d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleClickUpdate}>
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Collections
