/** @format */
import localize from '../../../../../../Global/Localize'
import { SupplierLookup } from '../../../../../../Components/Lookup/SupplierLookup'
import { ItemRegisterLookup } from '../../../../../../Components/Lookup/ItemRegisterLookup'
import { QuoationEntries } from './QuoationEntries'
import React, { useState } from 'react'
import { postData } from '../../../../../../Helpers/webApi'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { getTodaysDate } from '../../../../../../Helpers/formatters'
import PdfFileLookup from '../../../../../../Components/Lookup/PdfFileLookup'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CreateQuotationModal = ({
  selectedQuotation,
  setSelectedQuotation,
  quotationEntries,
  isBusy,
  setQuotationEntries,
}) => {
  const [quotationModel, setQuotationModel] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOnAddQuotation = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/Quotation/add-quotation-entries',
      {
        ...quotationModel,
        QuotationItems: quotationEntries,
      },
      false,
    )
    if (success) {
      setQuotationEntries([...quotationEntries, data])
      setQuotationModel(null)
    }
    setLoading(false)
  }

  const handleOnRemoveQuotationEntries = e => {
    setQuotationEntries(quotationEntries.filter(x => x.id !== e.id))
  }
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('supplier')}
              </label>
              <SupplierLookup
                displayValue={selectedQuotation?.supplierDescription}
                onSelect={r => {
                  setSelectedQuotation({
                    ...selectedQuotation,
                    supplierDescription: r.description,
                    supplierId: r.id,
                    addressMobileLine: r.addressMobileLine,
                    addressEmail: r.addressEmail,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.addressMobileLine}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.addressEmail}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('quotationDate')}
              </label>
              <CustomDatePicker
                name="quotationDate"
                defaultDate={selectedQuotation?.quotationDate}
                onDateChange={date => {
                  setSelectedQuotation({
                    ...selectedQuotation,
                    quotationDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('validityPeriod')}
              </label>
              <input
                type="number"
                className="form-control"
                value={selectedQuotation?.period}
                onChange={e => {
                  setSelectedQuotation({
                    ...selectedQuotation,
                    period: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('documentNo')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.documentNumber}
                onChange={e => {
                  setSelectedQuotation({
                    ...selectedQuotation,
                    documentNumber: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('document')}
              </label>
              <PdfFileLookup
                displayValue={selectedQuotation?.fileName}
                acceptedFiles={['application/pdf']}
                onSelect={r => {
                  setSelectedQuotation({
                    ...selectedQuotation,
                    document: r.bufferString,
                    fileName: r.fileName,
                    fileExtension: 'pdf',
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('title')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.fileTitle}
                onChange={e =>
                  setSelectedQuotation({
                    ...selectedQuotation,
                    fileTitle: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('description')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.fileDescription}
                onChange={e =>
                  setSelectedQuotation({
                    ...selectedQuotation,
                    fileDescription: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.remarks}
                onChange={e =>
                  setSelectedQuotation({
                    ...selectedQuotation,
                    remarks: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                  }}
                >
                  {localize('itemDetails')}
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('itemDescription')}
                    </label>
                    <ItemRegisterLookup
                      displayValue={
                        quotationModel?.itemRegister?.description || ''
                      }
                      onSelect={r => {
                        setQuotationModel({
                          ...quotationModel,
                          itemRegister: { ...r },
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('inventoryCategory')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        quotationModel?.itemRegister
                          ?.inventoryCategoryDescription || ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('unitOfMeasure')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        quotationModel?.itemRegister
                          ?.inventoryUnitOfMeasureDescription || ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('quantity')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.quantity || ''}
                      onChange={e =>
                        setQuotationModel({
                          ...quotationModel,
                          quantity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('unitCost')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.unitCost || ''}
                      onChange={e =>
                        setQuotationModel({
                          ...quotationModel,
                          unitCost: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('daysToDeliver')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.daysToDeliver || ''}
                      onChange={e => {
                        setQuotationModel({
                          ...quotationModel,
                          daysToDeliver: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={handleOnAddQuotation}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('add')}
                    </button>
                  </div>
                )}

                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 120,
                      marginBottom: -10,
                    }}
                  >
                    {localize('quotationItems')}
                  </div>
                  <QuoationEntries
                    entries={quotationEntries}
                    handleOnRemove={handleOnRemoveQuotationEntries}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
