/** @format */

/* eslint-disable */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'

const CreateSupplierTypes = ({
  handleClose,
  handleAction,
  mode,
  types,
  setTypes,
  isLoading,
  globalValidationErrors,
  clearValidation,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle={localize('supplierTypes')}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            onCheck={x => {
              setTypes({
                ...types,
                isLocked: x,
              })
            }}
            checked={types?.isLocked}
            id="isLocked"
          />
          <label htmlFor="isLocked" className="text-capitalize">
            {localize('isLocked') + '?'}
          </label>
        </div>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col-12 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['description']?.message
              }
            >
              <label htmlFor="name" className="text-capitalize col-1">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                value={types.description}
                onChange={e => {
                  setTypes({
                    ...types,
                    description: e.target.value,
                  })
                  clearValidation('description')
                }}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-6 d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['glAccount']?.message}
            >
              <label htmlFor="g/lAccount" className="text-capitalize col-2">
                {localize('g/LAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={types?.chartOfAccountName}
                onSelect={e => {
                  clearValidation('glAccount')
                  setTypes({
                    ...types,
                    chartOfAccountName: e?.name,
                    chartOfAccountId: e?.id,
                  })
                }}
                disabled={mode === 'add' ? false : true}
              />
            </div>
            <div
              className="col-6 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['goodsRecievedNotInvoicedGl']?.message
              }
            >
              <label htmlFor="name" className="text-capitalize col-2">
                {localize('gRNIG/LAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={types?.goodsReceivedNotInvoicedChartOfAccountName}
                onSelect={e => {
                  clearValidation('goodsRecievedNotInvoicedGl')
                  setTypes({
                    ...types,
                    goodsReceivedNotInvoicedChartOfAccountName: e?.name,
                    goodsReceivedNotInvoicedChartOfAccountId: e?.id,
                  })
                }}
                disabled={mode === 'add' ? false : true}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default CreateSupplierTypes
