/** @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Pager from '../Tables/Pager'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import GroupedTable from '../Tables/GroupedTable'
import { ProductSection } from '../../Global/enumeration'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'

export const LoanProductLookup = ({ displayValue, onSelect }) => {
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    productSection: 1,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  const [groupBy, setGroupBy] = useState({
    label: localize('productCategory'),
    column: 'loanRegistrationLoanProductCategoryDescription',
  })

  const columns = [
    { label: localize('paddedCode'), template: r => <>{r.paddedCode}</> },
    {
      label: localize('documentReferenceNumber'),
      template: r => <>{r.documentReferenceNumber}</>,
    },
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('billReferenceNumber'),
      template: r => <>{r.billReferenceNumber}</>,
    },
    {
      label: localize('productCategory'),
      template: r => <>{r.loanRegistrationLoanProductCategoryDescription}</>,
    },
    {
      label: localize('termInMonths'),
      template: r => <>{r.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => <>{r.loanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('offsettingPercentageRate'),
      template: r => <>{r.loanInterestOffsettingPercentageRate}</>,
    },
    {
      label: localize('interestCalculationModeDescription'),
      template: r => <>{r.loanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('paymentFrequencyPerYearDescription'),
      template: r => (
        <>{r.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('minimumPrincipalAmount'),
      template: r => <>{r.loanRegistrationMinimumAmount}</>,
    },
    {
      label: localize('maximumPrincipalAmount'),
      template: r => <>{r.loanRegistrationMaximumAmount}</>,
    },
    {
      label: localize('minimumChargeableInterestAmount'),
      template: r => <>{r.loanRegistrationMinimumInterestAmount}</>,
    },
    {
      label: localize('minimumNetDisbursementAmount'),
      template: r => <>{r.loanRegistrationMinimumNetDisbursementAmount}</>,
    },
    {
      label: localize('productSection'),
      template: r => <>{r.loanRegistrationLoanProductSectionDescription}</>,
    },
    {
      label: localize('consecutiveIncome'),
      template: r => <>{r.loanRegistrationConsecutiveIncome}</>,
    },
    {
      label: localize('investmentsMultiplier'),
      template: r => <>{r.loanRegistrationInvestmentsMultiplier}</>,
    },
    {
      label: localize('minimumDeposits'),
      template: r => <>{r.loanRegistrationMinimumInvestments}</>,
    },
    {
      label: localize('minimumGuarantors'),
      template: r => <>{r.loanRegistrationMinimumGuarantors}</>,
    },
    {
      label: localize('maximumGuarantees'),
      template: r => <>{r.loanRegistrationMaximumGuarantees}</>,
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('recoveryMode'),
      template: r => <>{r.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('aggregateCheckOffRecoveryMode'),
      template: r => (
        <>{r.loanRegistrationAggregateCheckOffRecoveryModeDescription}</>
      ),
    },
    {
      label: localize('payoutRecoveryMode'),
      template: r => <>{r.loanRegistrationPayoutRecoveryModeDescription}</>,
    },
    {
      label: localize('payoutRecoveryPercentage'),
      template: r => <>{r.loanRegistrationPayoutRecoveryPercentage}</>,
    },
    {
      label: localize('minimumMembershipPeriod'),
      template: r => <>{r.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('standingOrderTrigger'),
      template: r => <>{r.loanRegistrationStandingOrderTriggerDescription}</>,
    },
    {
      label: localize('gracePeriod'),
      template: r => <>{r.loanRegistrationGracePeriod}</>,
    },
    {
      label: localize('guarantorSecurityMode'),
      template: r => <>{r.loanRegistrationGuarantorSecurityModeDescription}</>,
    },
    {
      label: localize('incomeAssessmentMode'),
      template: r => <>{r.loanRegistrationIncomeAssessmentModeDescription}</>,
    },
    {
      label: localize('takeHomeType'),
      template: r => <>{r.takeHomeTypeDescription}</>,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => <>{r.takeHomePercentage}</>,
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => <>{r.takeHomeFixedAmount}</>,
    },
    {
      label: localize('deductiblesBasisValue'),
      template: r => <>{r.deductiblesBasisValueDescription}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r.loanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('principalChartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('principalChartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('interestReceivedChartOfAccountName'),
      template: r => <>{r.interestReceivedChartOfAccountName}</>,
    },
    {
      label: localize('interestReceivedChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.interestReceivedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interestReceivableChartOfAccountName'),
      template: r => <>{r.interestReceivableChartOfAccountName}</>,
    },
    {
      label: localize('interestReceivableChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.interestReceivableChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interestChargedChartOfAccountName'),
      template: r => <>{r.interestChargedChartOfAccountName}</>,
    },
    {
      label: localize('interestChargedChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.interestChargedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('penaltyReceivedChartOfAccountName'),
      template: r => <>{r.penaltyReceivedChartOfAccountName}</>,
    },
    {
      label: localize('penaltyReceivedChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.penaltyReceivedChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('penaltyReceivableChartOfAccountName'),
      template: r => <>{r.penaltyReceivableChartOfAccountName}</>,
    },
    {
      label: localize('penaltyReceivableChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.penaltyReceivableChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('maximumSelfGuaranteeEligiblePercentage'),
      template: r => (
        <>{r.loanRegistrationMaximumSelfGuaranteeEligiblePercentage}</>
      ),
    },
    {
      label: localize('rejectIfOwing'),
      template: r => <>{r.loanRegistrationRejectIfMemberHasBalance}</>,
    },
    {
      label: localize('enforceSecurityRule'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationSecurityRequired?.toString()}
        </span>
      ),
    },
    {
      label: localize('allowSelfGuarantee'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationAllowSelfGuarantee?.toString()}
        </span>
      ),
    },
    {
      label: localize('chargeClearanceFee'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationChargeClearanceFee?.toString()}
        </span>
      ),
    },
    {
      label: localize('microcredit'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationMicrocredit?.toString()}
        </span>
      ),
    },
    {
      label: localize('trackArrears'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationTrackArrears?.toString()}
        </span>
      ),
    },
    {
      label: localize('throttleScheduledArrearsRecovery'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationThrottleScheduledArrearsRecovery?.toString()}
        </span>
      ),
    },
    {
      label: localize('bypassAudit'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationBypassAudit?.toString()}
        </span>
      ),
    },
    {
      label: localize('chargeArrearsFee'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationChargeArrearsFee?.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceSystemAppraisalRecommendation'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationEnforceSystemAppraisalRecommendation?.toString()}
        </span>
      ),
    },
    {
      label: localize('disburseMicroLoanLessDeductions'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationDisburseMicroLoanLessDeductions?.toString()}
        </span>
      ),
    },
    {
      label: localize('createStandingOrderOnLoanAudit'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationCreateStandingOrderOnLoanAudit?.toString()}
        </span>
      ),
    },
    {
      label: localize('throttleMicroLoanDisbursement'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationThrottleMicroLoanDisbursement?.toString()}
        </span>
      ),
    },
    {
      label: localize('loaneeMustBorrowScoredLoanLimit'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationLoaneeMustBorrowScoredLoanLimit?.toString()}
        </span>
      ),
    },
    {
      label: localize('allowDiscretionaryTopup'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationAllowDiscretionaryTopup?.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceConsecutiveIncomeValidation'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationEnforceConsecutiveIncomeValidation?.toString()}
        </span>
      ),
    },
    {
      label: localize('evaluateStandingOrderHistoryForArrearagesRecovery'),
      template: r => (
        <span className="text-capitalize">
          {r.loanRegistrationEvaluateStandingOrderHistoryForArrearagesRecovery?.toString()}
        </span>
      ),
    },
    {
      label: localize('permitPartialOffsetting'),
      template: r => <>{r.loanRegistrationPermitPartialOffsetting}</>,
    },
    { label: localize('priority'), template: r => <>{r.priority}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked?.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      label: localize('productCategory'),
      column: 'loanRegistrationLoanProductCategoryDescription',
    },
    {
      label: localize('interestCalculationMode'),
      column: 'loanInterestCalculationModeDescription',
    },
    {
      label: localize('interestChargeMode'),
      column: 'loanInterestChargeModeDescription',
    },
    {
      label: localize('interestRecoveryMode'),
      column: 'loanInterestRecoveryModeDescription',
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/LoanProduct/find-loan-product-by-section-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
      setTableData(data.pageCollection)
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('loanProductLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          {isBusy ? (
            <ModalBody className="bg-white">
              <div className="d-flex align-items-center justify-content-center p-5">
                <div className="d-flex align-items-center">
                  <i className="m-3 spinner-border spinner-sm text-black"></i>
                  <span className="text-capitalize fs-4">
                    {localize('pleaseWait')}
                  </span>
                </div>
              </div>
            </ModalBody>
          ) : (
            <ModalBody className="bg-white p-2">
              <div className="d-flex align-items-center justify-content-space-between ">
                <div className="d-flex align-items-center float-right my-2 col-4">
                  <label
                    className="text-capitalize"
                    htmlFor="LoanProductLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="LoanProductLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('groupBy')}
                  </label>
                  <select
                    className="form-select text-capitalize"
                    style={{ width: 'fit-content' }}
                    defaultValue={groupBy.column}
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumns.find(
                          f => f.column === e.target.value
                        )?.label,
                        column: e.target.value,
                      })
                    }
                  >
                    {groupingColumns.map(({ label, column }) => (
                      <option
                        key={`LoanProductLookup_${column}`}
                        className="text-capitalize"
                        value={column}
                      >
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-auto me-3 text-capitalize">
                    {localize('productSection')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ProductSection"
                    value={reqParams?.productSection}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        productSection: e.value,
                      })
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}></div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <div className="d-flex align-items-center float-right my-2">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      defaultValue={reqParams.filterText}
                      onChange={e => setSearch(e.target.value)}
                      type="search"
                      className="form-control ms-3"
                      id="searchTable"
                    />
                  </div>
                </form>
              </div>
              <div className=" py-1">
                <GroupedTable
                  canSelect
                  onSelect={r => {
                    onSelect(r)
                    setModalActive(false)
                  }}
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </ModalBody>
          )}
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
