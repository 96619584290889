/** @format */

import { ViewCustomer } from '../../../../../Components/ReUsables/Customer/ViewCustomer'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { FingerPrintSpecimen } from './FingerPrintSpecimen'
import { AcquisitionHistory } from './AcquisitionHistory'

export const ViewFingerPrintBiometricRegister = ({
  selectedCustomer,
  reqParams,
  setReqParams,
  acquisitionHistory,
  fingerPrintSpecimens,
}) => {
  return (
    <>
      <ViewCustomer customer={selectedCustomer?.customer} />
      <div className="row mt-2 mb-2">
        <Tab
          tabItems={[
            {
              label: localize('fingerPrintSpecimen'),
              item: <FingerPrintSpecimen specimen={fingerPrintSpecimens} />,
            },
            {
              label: localize('acquisitionHistory'),
              item: (
                <AcquisitionHistory
                  setReqParams={setReqParams}
                  reqParams={reqParams}
                  acquisitionHistory={acquisitionHistory}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  )
}
