/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { budgetManagementCategory } from '../../../../../../Global/enumeration'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import BudgetHierarchyLookup from '../../../../../../Components/Lookup/BudgetHierarchyLookup'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

const CreateHierarchy = ({
  handleClose,
  handleAction,
  mode,
  hierarchy,
  setHierarchy,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle={
        mode === 'add'
          ? localize('createHierarchy')
          : localize('updateHierarchy')
      }
      footerChildren={
        <>
          <Checkbox
            id="hierarchyislocked"
            onCheck={e => {
              setHierarchy({
                ...hierarchy,
                isLocked: e,
              })
            }}
            checked={hierarchy?.isLocked}
          />
          <label htmlFor="hierarchyislocked" className="text-capitalize">
            {localize('isLocked')}?
          </label>
        </>
      }
    >
      <div className="row g-3">
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="name" className="text-capitalize col-4">
              {localize('name')}
            </label>
            <input
              type="text"
              id="name1"
              className="form-control"
              value={hierarchy?.description}
              onChange={e => {
                setHierarchy({
                  ...hierarchy,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="parentHierarchy" className="text-capitalize col-4">
              {localize('parentHierarchy')}
            </label>

            <BudgetHierarchyLookup
              className="form-select"
              displayValue={hierarchy?.budgetName}
              onSelect={d =>
                setHierarchy({
                  ...hierarchy,
                  indentedDescription: d.description,
                  budgetName: d.description,
                  parentId: d.id,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="category" className="text-capitalize col-4">
              {localize('category')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              value={hierarchy?.categoryDescription}
              onChange={e => {
                setHierarchy({
                  ...hierarchy,
                  categoryDescription: e.value,
                })
              }}
              sort
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="lookup" className="text-capitalize col-4">
              {localize('gLAccount')}
            </label>
            <ChartOfAccountsLookup
              displayValue={hierarchy?.name}
              onSelect={r => {
                setHierarchy({
                  ...hierarchy,
                  chartOfAccountCostDistributionRuleId:
                    r.costDistributionRuleId,
                  chartOfAccountId: r.id,
                  name: r.description,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center with-validation">
            <label htmlFor="remarks" className="text-capitalize col-4">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={hierarchy?.remarks}
              id="name"
              onChange={e =>
                setHierarchy({
                  ...hierarchy,
                  remarks: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="branch" className="text-capitalize col-4">
              {localize('branch')}
            </label>
            <BranchLookup
              displayValue={hierarchy?.branchDescription}
              onSelect={r => {
                setHierarchy({
                  ...hierarchy,
                  branchDescription: r.description,
                  branchId: r.id,
                })
              }}
            />
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}
export default CreateHierarchy
