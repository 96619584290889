/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../Global/Localize'
import { QueuePriority, batchStatus } from '../../../Global/enumeration'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import { getData, postData, putData } from '../../../Helpers/webApi'
import ActionModal from '../../ActionModal/ActionModal'
import AddNewButton from '../../Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../Checkbox/Checkbox'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import DatePicker from '../../Date/DatePicker'
import Loader from '../../Loaders/Loader'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import EditLoanDisbursement from './EditLoanDisbursement/EditLoanDisbursement'
import ViewLoanDisbursement from './ViewLoanDisbursement/ViewLoanDisbursement'

const ListLoanDisbursement = ({
  showAuthorize,
  showEdit,
  showVerify,
  handleVerifyBatch,
  handleSelectAuthBatch,
  hiddenColumns = [],
  handleClickAddButton,
  showCreateButton,
  status,
  reload,
  setReload,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [loans, setLoans] = useState([])
  const [loan, setLoan] = useState({
    queuePriority: Object.keys(QueuePriority)[3],
  })
  const [selectAll, setSelectAll] = useState(false)
  const [selectedLoans, setSelectedLoans] = useState([])
  const [mode, setMode] = useState(null)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: status ?? Object.keys(batchStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    filterByAuthorizedBranches: false,
    text: '',
  })

  const handleCheckLoan = (loan, value) => {
    if (value) {
      setSelectedLoans([...selectedLoans, loan])
    } else {
      setSelectedLoans(selectedLoans?.filter(x => x.id !== loan.id))
    }
  }

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedLoans?.find(l => l?.id === r?.id)}
          onCheck={value => handleCheckLoan(r, value)}
        />
      ),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('batchNumber'),
      template: r => r?.paddedBatchNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('disbursementMode'),
      template: r => r?.loanDisbursementModeDescription,
    },
    {
      label: localize('batchTotal'),
      template: r => formatCurrency(r?.batchTotal),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanProductCategory'),
      template: r => r?.loanProductCategoryDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r?.isLocked?.toString(),
    },
    {
      label: localize('priority'),
      template: r => r?.priorityDescription,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('verified') + ' /' + localize('rejectedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
  ]

  const columnsToDisplay = columns?.filter(
    (_x, i) => !hiddenColumns.includes(i)
  )

  const fetchLoans = async () => {
    setIsBusy(true)

    const url =
      '/LoanDisbursementBatch/find-loan-disbursement-batches-by-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setLoans(data)
      setReload(false)
    }

    setIsBusy(false)
  }

  const handleSelectLoan = (loan, action) => {
    setLoan({
      ...loan,
      loanDisbursementBatchPriority: Object.keys(QueuePriority)[3],
    })
    setMode(action)
  }

  const handleLockUnlock = async () => {
    setIsBusy(true)
    if (selectedLoans.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select at least one loan disbursement batch to lock/unlock',
      })

    const url = '/LoanDisbursementBatch/toggle-lock-loan-disbursement-batches'

    const { success } = await postData(
      url,
      selectedLoans,
      false,
      'Loan Disbursement Batch Locked/Unlocked Successfully.'
    )

    if (success) {
      fetchLoans(false)
      setSelectedLoans([])
      setSelectAll(false)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setLoan({
      loanDisbursementBatchPriority: Object.keys(QueuePriority)[3],
    })
  }

  const handleEditLoanDisbursement = async () => {
    setIsBusyUpdating(true)
    const url = '/LoanDisbursementBatch/update-loan-disbursement-batch'

    const { success } = await putData(
      url,
      {
        ...loan,
        priority: loan?.loanDisbursementBatchPriority,
      },
      false
    )

    if (success) {
      fetchLoans()
      setMode(null)
      showNotification('Loan Disbursement Batch', 'success')
      setMode(null)
    }

    setIsBusyUpdating(false)
  }

  useEffect(() => {
    fetchLoans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.status, reqParams.pageSize])

  useEffect(() => {
    if (reload) fetchLoans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      <>
        {mode === 'view-action-modal' && (
          <ActionModal
            cancelText="no"
            confirmText="yes"
            modalTitle="verification"
            handleAction={handleRequestForVerification}
            handleClose={handleClose}
            showCloseButton
          >
            <p className="text-center">
              Do you want to request for verification?
            </p>
          </ActionModal>
        )}

        {mode === 'view' && (
          <ViewLoanDisbursement
            loan={loan}
            handleClose={handleClose}
            mode={mode}
          />
        )}

        {mode === 'edit' && (
          <EditLoanDisbursement
            handleClose={handleClose}
            loan={loan}
            mode={mode}
            setLoan={setLoan}
            handleEditLoanDisbursement={handleEditLoanDisbursement}
            isBusy={isBusyUpdating}
          />
        )}

        {showCreateButton && (
          <div className="row mb-2">
            <div className="col-md-3">
              <div className="d-flex align-items-center justify-content-start">
                <AddNewButton handleClickAddButton={handleClickAddButton} />
              </div>
            </div>
          </div>
        )}

        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-flex align-items-center">
                  <label htmlFor="status" className="text-capitalize me-2">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="batchStatus"
                    value={reqParams.status}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                        pageIndex: 0,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <label htmlFor="search" className="col-3 text-capitalize">
                  {localize('dateRange')}
                </label>
                <DatePicker
                  onSelect={v => {
                    if (v.custom) {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    } else {
                      setReqParams({
                        ...reqParams,
                        customDate: false,
                        dateRequestsFilter: v.value,
                      })
                    }
                  }}
                />
              </div>

              <div className="col-md-3">
                <div className={`d-flex align-items-center gap-2`}>
                  <label className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams.text}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchLoans()
                      }
                    }}
                  />
                  <button
                    onClick={() => fetchLoans()}
                    className="btn btn-primary"
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
            </div>
            <SimpleTable
              columns={columnsToDisplay}
              data={loans.pageCollection}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectLoan(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  {showEdit && (
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectLoan(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  )}
                  {showVerify && (
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleVerifyBatch(r)
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      <span>{localize('verify')}</span>
                    </div>
                  )}

                  {showAuthorize && (
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectAuthBatch(r)
                      }}
                    >
                      <i className="mdi mdi-lock-open-check-outline"></i>
                      <span>{localize('authorize')}</span>
                    </div>
                  )}
                </>
              )}
            />
            <Pager
              itemsCount={loans.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
            {showCreateButton && (
              <div className="row py-3 px-2 bg-light">
                <div className="d-flex justify-content-between align-items-center gap-4">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      id="selectAllLoanDis"
                      checked={selectAll}
                      onCheck={value => {
                        setSelectAll(value)
                        if (value) {
                          setSelectedLoans(loans?.pageCollection)
                        } else {
                          setSelectedLoans([])
                        }
                      }}
                    />
                    <label
                      htmlFor="selectAllLoanDis"
                      className="text-capitalize"
                    >
                      {localize('toggleSelection')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={handleLockUnlock}
                    >
                      {localize('lock/Unlock')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  )
}

export default ListLoanDisbursement
