/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import JournalEntries from './JournalEntries'
import { getData } from '../../../Helpers/webApi'
import { formatDate } from '../../../Helpers/formatters'
import Loader from '../../Loaders/Loader'

function ViewTransactionsJournal({ transaction }) {
  const [isBusy, setIsBusy] = useState(false)
  const [details, setDetails] = useState(null)
  useEffect(() => {
    loadTransactionsJournal()
  }, [])

  async function loadTransactionsJournal() {
    setIsBusy(false)
    const { success, data } = await getData(
      '/journal/find-journal-with-journal-entries',
      { journalId: transaction.journalId },
      false
    )
    if (success) {
      setDetails(data)
    }
    setIsBusy(false)
  }
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('transactionCode')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.transactionCodeDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('branch')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.branchDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.postingPeriodDescription}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('primaryDescription')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.primaryDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('secondaryDescription')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.secondaryDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.reference}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('totalValue')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.formattedTotalValue}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('valueDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(details?.journal?.valueDate, true)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('localCurrency')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.localCurrencyDescription}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('appUserName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.applicationUserName}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envUserName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentUserName}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envMachineName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentMachineName}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envDomainName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentDomainName}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envOSVersion')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentOSVersion}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envMACAddress')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentMACAddress}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envMotherboardSerial')} #
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentMotherboardSerialNumber}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envProcessorId')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentProcessorId}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('envIPAddress')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.environmentIPAddress}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(details?.journal?.createdDate)}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-4 text-capitalize">
                  {localize('integrityHash')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={details?.journal?.integrityHash}
                />
              </div>
              <div className="col"></div>
            </div>

            <div
              className="py-3 px-4 mt-4 ms-1 row"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 5,
                position: 'relative',
              }}
            >
              <div
                className="bg-white row px-2 text-capitalize"
                style={{
                  position: 'absolute',
                  top: -12,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('journalEntries')}
              </div>
              <JournalEntries
                journalEntries={details?.journalEntries?.message?.result || []}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewTransactionsJournal
