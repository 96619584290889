/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import CustomerLookupPartial from '../../../../../../Components/ReUsables/Customer/CustomerLookupPartial'
import localize from '../../../../../../Global/Localize'
import BankAccounts from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/BankAccounts'
import Specimen from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import CustomerLoanApplications from '../CustomerLoanApplications'
import StandingOrders from '../StandingOrders'
import CustomerAccountsList from '../CustomerAccountsList'
import CustomerCreditScores from '../CustomerCreditScores'
import Tab from '../../../../../../Components/Tabs/Tab'

function CustomerInfo({ customer, onChangeCustomer }) {
  const [selectedCustomer, setSelectedCustomer] = useState(customer)

  useEffect(() => {
    selectedCustomer && onChangeCustomer(selectedCustomer)
    //eslint-disable-next-line
  }, [selectedCustomer])

  const tabItems = [
    {
      label: localize('creditScores'),
      item: <CustomerCreditScores customer={selectedCustomer} />,
    },
    {
      label: localize('customerAccounts'),
      item: (
        <>
          <CustomerAccountsList customer={selectedCustomer} />
        </>
      ),
    },
    {
      label: localize('standingOrders'),
      item: (
        <>
          <StandingOrders customer={selectedCustomer} />
        </>
      ),
    },
    {
      label: localize('loanApplications'),
      item: <CustomerLoanApplications customer={selectedCustomer} />,
    },
    {
      label: localize('bankAccounts'),
      item: (
        <>
          <BankAccounts readOnly customer={selectedCustomer} />
        </>
      ),
    },
    {
      label: localize('specimen'),
      icon: 'mdi mdi-camera',
      item: (
        <>
          <Specimen readOnly customer={selectedCustomer} />
        </>
      ),
    },
  ]

  return (
    <>
      <h4 className="text-capitalize mt-2 mb-3">{localize('customerInfo')}</h4>
      <CustomerLookupPartial
        customer={selectedCustomer}
        onLookup={c => setSelectedCustomer(c)}
      />
      {selectedCustomer && (
        <>
          <h4 className="mb-3 mt-4 text-capitalize">
            {localize('particulars')}
          </h4>
          <Tab tabItems={tabItems} />
        </>
      )}
    </>
  )
}

export default CustomerInfo
