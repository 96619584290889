/**
 * eslint-disable
 *
 * @format
 */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
function AppraisalPeriodLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('appraisalPeriodLookup')}
      url="/lookup/AppraisalPeriod/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        { label: localize('name'), template: r => <>{r.description}</> },
        {
          label: localize('startDate'),
          template: r => <>{formatDate(r.durationStartDate)}</>,
        },
        {
          label: localize('endDate'),
          template: r => <>{formatDate(r.durationEndDate)}</>,
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('isActive') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isActive.toString())}
            </span>
          ),
          sortBy: 'isActive',
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default AppraisalPeriodLookup
