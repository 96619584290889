/** @format */

import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import PayrollNumberTypeLookup from '../../../../../Components/Lookup/PayrollNumberTypeLookup'
import PayrollNumbersTab from '../Tab/PayrollNumbersTab'
import Loader from '../../../../../Components/Loaders/Loader'

const EditPayrollNumbers = ({
  modalMode,
  handleCloseModal,
  selectedCustomer,
  payrollNumbers,
  handlePayrollRemove,
  handleUpdatePayroll,
  globalValidationErrors,
  payrollNumber,
  setPayrollNumber,
  clearValidation,
  setSelectedCustomer,
  handleAddPayrollNumber,
  isBusyUpdating,
}) => {
  return (
    <ModalTemplate
      modalMode={modalMode}
      handleClose={handleCloseModal}
      modalTitle={localize('payrollNumbers')}
      modalClassName="modal-xl"
      handleAction={handleUpdatePayroll}
      disableClose={isBusyUpdating}
      disableCreate={isBusyUpdating}
    >
      {isBusyUpdating ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="customerName" className="text-capitalize col-3">
                  {localize('customerName')}
                </label>

                <input
                  type="text"
                  id="customerName"
                  className="form-control ms-2"
                  value={selectedCustomer?.fullName || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="customerType" className="text-capitalize col-3">
                  {localize('customerType')}
                </label>

                <input
                  type="text"
                  id="customerType"
                  className="form-control ms-2"
                  value={
                    selectedCustomer?.membershipClassCustomerTypeDescription ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="customerMember"
                  className="text-capitalize col-3"
                >
                  {localize('customerMember#')}
                </label>

                <input
                  type="text"
                  id="customerMember"
                  className="form-control ms-2"
                  value={selectedCustomer?.memberNumber || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="employer" className="text-capitalize col-3">
                  {localize('employer')}
                </label>

                <input
                  type="text"
                  id="employer"
                  className="form-control ms-2"
                  value={selectedCustomer?.employerDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="dutyWorkStation"
                  className="text-capitalize col-3"
                >
                  {localize('duty/Workstation')}
                </label>

                <input
                  type="text"
                  id="dutyWorkStation"
                  className="form-control ms-2"
                  value={selectedCustomer?.dutyStationDescription || ''}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="identification"
                  className="text-capitalize col-3"
                >
                  {localize('identification#')}
                </label>

                <input
                  type="text"
                  id="identification"
                  className="form-control ms-2"
                  value={selectedCustomer?.identificationNumber || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="adminDivision"
                  className="text-capitalize col-3"
                >
                  {localize('adminDivision')}
                </label>

                <input
                  type="text"
                  id="adminDivision"
                  className="form-control ms-2"
                  value={
                    selectedCustomer?.administrativeDivisionDescription || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="registrationDate"
                  className="text-capitalize col-3"
                >
                  {localize('registrationDate')}
                </label>

                <input
                  type="date"
                  id="registrationDate"
                  className="form-control ms-2"
                  value={
                    formatDate(selectedCustomer?.registrationDate, true) || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="membershipStatus"
                  className="text-capitalize col-3"
                >
                  {localize('membershipStatus')}
                </label>

                <input
                  type="text"
                  id="membershipStatus"
                  className="form-control ms-2"
                  value={selectedCustomer?.membershipStatusDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="memberNumber" className="text-capitalize col-3">
                  {localize('memberNumber')}
                </label>

                <input
                  type="text"
                  id="memberNumber"
                  className="form-control ms-2"
                  value={selectedCustomer?.memberNumber || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="accountNumber"
                  className="text-capitalize col-3"
                >
                  {localize('accountNumber')}
                </label>

                <input
                  type="text"
                  id="accountNumber"
                  className="form-control ms-2"
                  value={selectedCustomer?.paddedAccountNumber || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="personalFileNumber"
                  className="text-capitalize col-3"
                >
                  {localize('personalFileNumber')}
                </label>

                <input
                  type="text"
                  id="personalFileNumber"
                  className="form-control ms-2"
                  value={selectedCustomer?.personalFileNumber || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('payrollNumbers')}
            </div>
            <div className="row g-3">
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['NumberType']?.message
                  }
                >
                  <label htmlFor="numberType" className="text-capitalize col-2">
                    {localize('numberType')}
                  </label>

                  <PayrollNumberTypeLookup
                    displayValue={payrollNumber?.description}
                    onSelect={value => {
                      clearValidation('NumberType')
                      setSelectedCustomer({
                        ...selectedCustomer,
                      })
                      setPayrollNumber({
                        ...payrollNumber,
                        payrollNumberTypeId: value?.id,
                        payrollNumberTypeDescription: value?.description,
                        ...value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['Reference']?.message
                  }
                >
                  <label htmlFor="reference" className="text-capitalize col-2">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    id="reference"
                    className="form-control"
                    value={payrollNumber?.reference || ''}
                    onChange={e => {
                      clearValidation('Reference')
                      setPayrollNumber({
                        ...payrollNumber,
                        reference: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['Remarks']?.message
                  }
                >
                  <label htmlFor="reference" className="text-capitalize col-1">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    id="remarks"
                    className="form-control"
                    value={payrollNumber?.remarks || ''}
                    onChange={e => {
                      clearValidation('Remarks')
                      setPayrollNumber({
                        ...payrollNumber,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row g-3 p-1 bg-light rounded m-2">
              <div className="col-md-12">
                <button
                  className="btn btn-success float-end"
                  onClick={handleAddPayrollNumber}
                >
                  {localize('add')}
                </button>
              </div>
            </div>

            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('payrollNumbers')}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <PayrollNumbersTab
                    customerId={selectedCustomer?.id}
                    showContextMenu
                    onPayrollRemove={handlePayrollRemove}
                    data={payrollNumbers}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default EditPayrollNumbers
