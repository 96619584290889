/** @format */
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { CreditTypeLookup } from '../../../../Components/Lookup/CreditTypeLookup'
import React, { useState } from 'react'
import EmployerLookup from '../../../../Components/Lookup/EmployerLookup'
import { LoanProductLookup } from '../../../../Components/Lookup/LoanProductLookup'
import { EditLoanRepaymentModeMappings } from './EditLoanRepaymentModeMappings'
import { generateGuid } from '../../../../Helpers/extensions'
import swal from 'sweetalert2'

export const EditLoanRepaymentModeModal = ({
  selectedLoanRepaymentMode,
  setSelectedLoanRepaymentMode,
  waitingMetadata,
  repaymentModeMappings,
  setRepaymentModeMappings,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const [loanRepaymentModeMapping, setLoanRepaymentModeMapping] = useState(null)
  const [loanRepaymentModeValidationBag, setLoanRepaymentModeValidationBag] =
    useState({})
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const clearLoanRepaymentModeValidationBag = column => {
    setLoanRepaymentModeValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnAddLoanRepaymentModeMappings = () => {
    if (!loanRepaymentModeMapping?.loanProductId) {
      setLoanRepaymentModeValidationBag({
        ...loanRepaymentModeValidationBag,
        loanProductId: {
          message: 'Loan Product is required',
        },
      })

      return
    }
    if (!loanRepaymentModeMapping?.employerId) {
      setLoanRepaymentModeValidationBag({
        ...loanRepaymentModeValidationBag,
        employerId: {
          message: 'Employer is required',
        },
      })
      return
    }
    if (
      repaymentModeMappings.find(
        x =>
          x.loanProductId === loanRepaymentModeMapping.loanProductId &&
          x.employerId === loanRepaymentModeMapping.employerId,
      )
    ) {
      swal.fire(
        'Edit Loan Repayment Mode mappings',
        `${loanRepaymentModeMapping?.loanProductDescription} is already added for ${loanRepaymentModeMapping?.employerDescription}`,
        'error',
      )
      return
    }
    setRepaymentModeMappings([
      ...repaymentModeMappings,
      {
        id: generateGuid(),
        employerId: loanRepaymentModeMapping?.employerId,
        employerDescription: loanRepaymentModeMapping?.employerDescription,
        loanProductId: loanRepaymentModeMapping?.loanProductId,
        loanProductDescription:
          loanRepaymentModeMapping?.loanProductDescription,
        loanRepaymentModeId: selectedLoanRepaymentMode?.id,
      },
    ])
    setLoanRepaymentModeMapping(null)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedLoanRepaymentMode?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('standingOrderTrigger')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="StandingOrderTrigger"
                value={selectedLoanRepaymentMode?.standingOrderTrigger}
                onChange={e =>
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    standingOrderTrigger: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('creditType')}
              </label>
              <CreditTypeLookup
                displayValue={selectedLoanRepaymentMode?.creditTypeDescription}
                onSelect={e => {
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    creditTypeId: e.id,
                    creditTypeDescription: e.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                  }}
                >
                  {localize('defaultMappingDetails')}
                </div>
                <div className="row" style={{ marginTop: -10 }}>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      loanRepaymentModeValidationBag?.employerId?.message
                    }
                  >
                    <label htmlFor="" className="text-capitalize me-3">
                      {localize('businessGroupEmployer')}
                    </label>
                    <EmployerLookup
                      canSelectHeader
                      displayValue={
                        loanRepaymentModeMapping?.employerDescription
                      }
                      onSelect={r => {
                        clearLoanRepaymentModeValidationBag('employerId')
                        setLoanRepaymentModeMapping({
                          ...loanRepaymentModeMapping,
                          employerId: r.id,
                          employerDescription: r.description,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      loanRepaymentModeValidationBag?.loanProductId?.message
                    }
                  >
                    <label htmlFor="" className="text-capitalize me-3">
                      {localize('loanProduct')}
                    </label>
                    <LoanProductLookup
                      displayValue={
                        loanRepaymentModeMapping?.loanProductDescription
                      }
                      onSelect={r => {
                        clearLoanRepaymentModeValidationBag('loanProductId')
                        setLoanRepaymentModeMapping({
                          ...loanRepaymentModeMapping,
                          loanProductId: r.id,
                          loanProductDescription: r.description,
                        })
                      }}
                    />
                  </div>
                </div>
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={handleOnAddLoanRepaymentModeMappings}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>
                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 120,
                      marginBottom: -10,
                    }}
                  >
                    {localize('defaultMappings')}
                  </div>
                  <EditLoanRepaymentModeMappings
                    waitingMetadata={waitingMetadata}
                    repaymentModeMappings={repaymentModeMappings}
                    setRepaymentModeMappings={setRepaymentModeMappings}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
