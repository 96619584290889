/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { ViewPurchaseOrdersEntries } from './ViewPurchaseOrdersEntries'

export const ApprovePurchaseOrdersModal = ({
  selectedPurchaseOrder,
  isBusy,
}) => {
  const [reqParams, setReqParams] = useState({
    purchaseOrderId: selectedPurchaseOrder?.purchaseOrder?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [purchaseOrderEntries, setPurchaseOrderEntries] = useState([])
  const fetchPurchaseOrderItems = async () => {
    const { success, data } = await getData(
      '/PurchaseOrder/find-purchase-order-items-by-purchase-order-id',
      {
        ...reqParams,
        purchaseOrderId: selectedPurchaseOrder?.purchaseOrder?.id,
      },
      false,
    )
    if (success) {
      setPurchaseOrderEntries(data?.result)
    }
  }

  useEffect(() => {
    fetchPurchaseOrderItems()
  }, [selectedPurchaseOrder?.purchaseOrder?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('supplier')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPurchaseOrder?.purchaseOrder?.supplierDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <input
                className={'form-control'}
                disabled
                value={formatDate(
                  selectedPurchaseOrder?.purchaseOrder?.expectedDate,
                  true,
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPurchaseOrder?.purchaseOrder
                    ?.supplierAddressMobileLine
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPurchaseOrder?.purchaseOrder?.supplierAddressEmail
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <Tab
                  tabItems={[
                    {
                      label: localize('purchaseOrderEntries'),
                      item: (
                        <ViewPurchaseOrdersEntries
                          entries={purchaseOrderEntries}
                          setReqParams={setReqParams}
                          reqParams={reqParams}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
