/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../Components/Tables/Pager'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import ConditionalLendingModal from './Partials/ConditionalLendingModal'
import Loader from '../../../Components/Loaders/Loader'

function ConditionalLending() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
      sortBy: 'loanProductDescription',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [selectedConditionalLending, setSelectedConditionalLending] =
    useState(null)
  const [mode, setMode] = useState(null)

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/ConditionalLending/find-conditional-lending-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedConditionalLending(null)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedConditionalLending(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('conditionalLending')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="holidayFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedConditionalLending(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />

                    {mode && (
                      <ConditionalLendingModal
                        mode={mode}
                        loadTableData={loadTableData}
                        onSetMode={m => {
                          setMode(m)
                        }}
                        onHideModal={closeModal}
                        setSelectedConditionalLending={
                          setSelectedConditionalLending
                        }
                        selectedConditionalLending={selectedConditionalLending}
                      />
                    )}
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ConditionalLending
