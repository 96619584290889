/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'

const ImportButton = ({ handleClickImportButton }) => {
  return (
    <button
      onClick={handleClickImportButton}
      className="btn btn-primary waves-effect waves-light text-capitalize"
    >
      <i className="uil uil-import me-2"></i> {localize('import')}
    </button>
  )
}

export default ImportButton
