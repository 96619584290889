/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import {
  formatDate,
  getTodaysDate,
  getTwoWeeksFromDate,
  parseDate,
} from '../../Helpers/formatters'
import CustomDatePicker from './custom-date-picker'
import './date-picker.scss'
import moment from 'moment'

function DatePicker({ onSelect, customOnly, useStartEnd, customStyle, value }) {
  const ref = useRef(null)
  const [dropdown, setDropdown] = useState(false)
  const [custom, setCustom] = useState({
    startDate: getTwoWeeksFromDate(),
    endDate: getTodaysDate(),
  })
  const [customModal, setCustomModal] = useState(false)
  const [display, setDisplay] = useState(
    `${formatDate(value?.startDate, true)} to ${formatDate(
      value?.endDate,
      true
    )}`
  )

  const list = customOnly
    ? [{ label: 'choose dates', value: custom, custom: true }]
    : [
        { label: `15 ${localize('days')}`, value: 15 },
        { label: `30 ${localize('days')}`, value: 30 },
        { label: `3 ${localize('months')}`, value: 90 },
        { label: `6 ${localize('months')}`, value: 180 },
        { label: `${localize('custom')}`, value: custom, custom: true },
      ]

  function acceptCustomDates() {
    if (custom?.startDate && custom?.endDate) {
      onSelect({ ...custom, custom: true })
      setDropdown(false)
      setCustomModal(false)
      setDisplay(`${custom?.startDate} to ${custom?.endDate}`)
    } else {
      Swal.fire('Error', 'Please select Start and End Dates', 'error')
    }
  }

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  function evaluateDate(d) {
    let startDate = moment().add('days', -Number(d.value))
    return {
      startDate: formatDate(startDate, true, 'YYYY-MM-DD'),
      endDate: formatDate(new Date(), true, 'YYYY-MM-DD'),
      value: d.value,
    }
  }

  return (
    <div className="container-fluid picker">
      <div
        onClick={() => setDropdown(!dropdown)}
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          ...customStyle,
        }}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {display === '-- to --' ? list[0].label : display}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
        >
          <i className="uil uil-calendar-alt"></i>
        </button>
      </div>
      {dropdown && (
        <div ref={ref} className="picker-dropdown bg-white p-2 container-fluid">
          {list.map(d => (
            <div
              onClick={() => {
                if (d.custom) {
                  setDropdown(false)
                  setCustomModal(true)
                  setCustom({
                    startDate: getTwoWeeksFromDate(),
                    endDate: getTodaysDate(),
                  })
                } else {
                  setCustom(null)
                  setDisplay(d.label)
                  setDropdown(false)

                  onSelect(
                    useStartEnd
                      ? {
                          startDate: parseDate(
                            new Date().setDate(new Date().getDate() - d.value)
                          ),
                          endDate: parseDate(new Date()),
                        }
                      : evaluateDate(d)
                  )
                }
              }}
              className="text-capitalize picker-item"
            >
              {d.label}
            </div>
          ))}
        </div>
      )}

      {customModal && (
        <Modal isOpen className="modal-lg">
          <ModalHeader className="bg-white text-capitalize">
            {localize('select')} {localize('date')}
          </ModalHeader>
          <ModalBody className="bg-white d-flex align-items-center gap-4">
            <div className="d-flex align-items-center col">
              <label
                htmlFor="dateFrom"
                className="text-capitalize"
                style={{ width: 100 }}
              >
                {localize('startDate')}
              </label>

              <CustomDatePicker
                name="dateFrom"
                onDateChange={date => {
                  setCustom({ ...custom, startDate: parseDate(date) })
                }}
                defaultDate={custom?.startDate}
                maxDate={getTodaysDate()}
              />
            </div>
            <div className="d-flex align-items-center col">
              <label
                htmlFor="dateTo"
                className="text-capitalize"
                style={{ width: 100 }}
              >
                {localize('endDate')}
              </label>
              <CustomDatePicker
                name="dateTo"
                onDateChange={date => {
                  setCustom({ ...custom, endDate: parseDate(date) })
                }}
                defaultDate={custom?.endDate}
                maxDate={parseDate(getTodaysDate())}
                minDate={custom?.startDate}
              />
            </div>
          </ModalBody>
          <ModalFooter className="bg-white">
            <button
              className="btn btn-danger px-2"
              onClick={() => {
                setDropdown(false)
                setCustomModal(false)
              }}
            >
              {localize('cancel')}
            </button>
            <button
              className="btn btn-success px-4"
              onClick={acceptCustomDates}
            >
              {localize('ok')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default DatePicker
