/** @format */
import localize from '../../../../../../../Global/Localize'
import { FuneralRiderClaimPayableLookup } from '../../../../../../../Components/Lookup/FuneralRiderClaimPayableLookup'

export const SundryTransactionSectionFuneralRiderClaim = ({
  transactionModel,
  setTransactionModel,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('beneficiary')}
          </label>
          <FuneralRiderClaimPayableLookup
            displayValue={
              transactionModel?.selectedFuneralRiderClaimPayable?.beneficiary
            }
            onSelect={r => {
              setTransactionModel({
                ...transactionModel,
                selectedFuneralRiderClaimPayable: { ...r },
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('claimType')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.funeralRiderClaimClaimTypeDescription
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberName')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.customerFullName
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('spouse/ClaimantName')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.funeralRiderClaimFuneralRiderClaimantName
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberMobileNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.funeralRiderClaimCustomerAddressMobileLine
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('spouse/ClaimantNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.selectedFuneralRiderClaimPayableuneralRiderClaimFuneralRiderClaimantMobileNumber
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberAccountNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.funeralRiderClaimCustomerReference1
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('spouse/ClaimantIdentityNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable
                ?.funeralRiderClaimFuneralRiderClaimantIdentityCardNumber
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            className="col-1 text-capitalize"
            style={{ marginRight: '4.5rem' }}
          >
            {localize('amount')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              transactionModel?.selectedFuneralRiderClaimPayable?.amount
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            className="col-1 text-capitalize"
            style={{ marginRight: '4.5rem' }}
          >
            {localize('payee')}
          </label>
          <input
            className="form-control"
            value={transactionModel?.cashPaymentTransactionModel?.payee || ''}
            onChange={e => {
              setTransactionModel({
                ...transactionModel,
                cashPaymentTransactionModel: {
                  ...transactionModel.cashPaymentTransactionModel,
                  payee: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            className="col-1 text-capitalize"
            style={{ marginRight: '4.5rem' }}
          >
            {localize('narration')}
          </label>
          <input
            className="form-control"
            value={
              transactionModel?.cashPaymentTransactionModel?.narration || ''
            }
            onChange={e => {
              setTransactionModel({
                ...transactionModel,
                cashPaymentTransactionModel: {
                  ...transactionModel.cashPaymentTransactionModel,
                  narration: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            className="col-1 text-capitalize"
            style={{ marginRight: '4.5rem' }}
          >
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={
              transactionModel?.cashPaymentTransactionModel?.reference || ''
            }
            onChange={e => {
              setTransactionModel({
                ...transactionModel,
                cashPaymentTransactionModel: {
                  ...transactionModel.cashPaymentTransactionModel,
                  reference: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
