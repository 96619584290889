/** @format */
import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import EmolumentBatchEntries from './EmolumentBatchEntries'
import Tab from '../../../../../Components/Tabs/Tab'
import { getData, postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { batchAuthOptions } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

const AuthorizeEmolumentsPaymentSchedules = ({
  mode,
  close,
  selectedEmolumentsPaymentSchedule,
  setSelectedEmolumentsPaymentSchedule,
  loadTableData,
}) => {
  const [reqParams, setReqParams] = useState({
    status: 2,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [search, setSearch] = useState('')
  const [entries, setEntries] = useState([])
  const [entriesCount, setEntriesCount] = useState('')
  const [batchId, setBatchId] = useState(selectedEmolumentsPaymentSchedule.id)
  const [loading, setLoading] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(batchAuthOptions)[0]
  )

  const loadEntries = async () => {
    setLoading(true)
    const { success, data } = await getData(
      `/EmolumentBatch/find-emolument-batch-entries-by-emolument-batch-id-in-page`,
      {
        emolumentBatchId: batchId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setLoading(false)
      setEntries(data.pageCollection)
      setEntriesCount(data.itemsCount)
    } else {
      setLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [batchId, reqParams.pageSize, reqParams.pageIndex])

  async function verifyEmonumentBatch() {
    setLoading(true)
    const { success } = await postData(
      '/EmolumentBatch/authorize-emolument-batch',
      {
        emolumentBatch: {
          ...selectedEmolumentsPaymentSchedule,
          id: batchId,
        },
        batchAuthOption: selectedAuth,
      },
      false
    )

    if (success) {
      showNotification(
        'Emolument Payment Schedule',
        'success',
        'Operation completed successfully!'
      )
      setSelectedEmolumentsPaymentSchedule(null)
      loadTableData()
      close()
      setLoading(false)
    }
    setLoading(false)
  }
  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="emolumentsPaymentSchedule"
        handleClose={close}
        modalClassName="modal-xl"
        handleAction={verifyEmonumentBatch}
        footerChildren={
          <>
            <div className="col-md-3 mt-3">
              <div
                className="d-flex align-items-center mb-1 with-validation"
                validation-message={
                  globalValidationErrors['EmolumentBatch.AuthorizationRemarks']
                    ?.message
                }
              >
                <label className="text-capitalize col-2 me-2">
                  {localize('remarks')}
                </label>
                <input
                  defaultValue={
                    selectedEmolumentsPaymentSchedule?.authorizationRemarks
                  }
                  type="text"
                  className="form-control ms-4"
                  onChange={e => {
                    clearValidation('EmolumentBatch.AuthorizationRemarks')
                    setSelectedEmolumentsPaymentSchedule({
                      ...selectedEmolumentsPaymentSchedule,
                      authorizationRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <div className="d-flex align-items-center mb-2">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchAuthOption"
                  value={selectedAuth}
                  onChange={e => setSelectedAuth(e.value)}
                />
              </div>
            </div>
          </>
        }
      >
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-3 text-capitalize">
                {localize('batch#')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.paddedBatchNumber}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-3 text-capitalize">
                {localize('emolumentType')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedEmolumentsPaymentSchedule?.emolumentTypeDescription
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-2 me-5 text-capitalize">
                {localize('amount')}
              </label>
              <MaskNumber
                className="form-control me-3 ms-3"
                defaultMaskValue={
                  selectedEmolumentsPaymentSchedule?.emolumentTypeAmount
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('postingPeriodDescription')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedEmolumentsPaymentSchedule?.postingPeriodDescription
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chartOfAccountName')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.chartOfAccountName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('priorityDescription')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.priorityDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.reference}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.createdBy}
                disabled
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={formatDate(
                  selectedEmolumentsPaymentSchedule?.createdDate
                )}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedBy')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.auditedBy}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedDate')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={formatDate(
                  selectedEmolumentsPaymentSchedule?.auditedDate
                )}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verificationRemarks')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.auditRemarks}
                disabled
              />
            </div>
          </div>
          <div className="row col-12 mt-2">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Tab
                  tabItems={[
                    {
                      label: localize('batchEntries'),
                      item: (
                        <EmolumentBatchEntries
                          mode={mode}
                          selectedEmolumentsPaymentSchedule={
                            selectedEmolumentsPaymentSchedule
                          }
                          reqParams={reqParams}
                          setReqParams={setReqParams}
                          search={search}
                          setSearch={setSearch}
                          entries={entries}
                          loadEntries={loadEntries}
                          entriesCount={entriesCount}
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}
          </div>
        </>
      </ModalTemplate>
    </>
  )
}

export default AuthorizeEmolumentsPaymentSchedules
