/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
function ViewAccountInformationHistory({ entry }) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/CreditReferenceBureau/find-credit-information-query-entry-account-histories`,
      {
        creditInformationQueryEntryId: entry.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setEntries(data.pageCollection)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [entry?.id, reqParams.pageSize, reqParams.pageIndex])
  const columns = [
    {
      label: localize('accountStatus'),
      template: r => <>{r.accountStatus}</>,
    },
    {
      label: localize('daysInArrears'),
      template: r => <>{r.daysInArrears}</>,
    },
    {
      label: localize('lastPaymentAmount'),
      template: r => <>{formatCurrency(r.lastPaymentAmount)}</>,
    },
    {
      label: localize('lastPaymentDate'),
      template: r => <>{formatDate(r.lastPaymentDate, true)}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.month}</>,
    },
    {
      label: localize('overdueBalance'),
      template: r => <>{formatCurrency(r.overdueBalance)}</>,
    },
  ]
  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={entries} />
        </>
      )}
    </div>
  )
}
export default ViewAccountInformationHistory
