/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import { LoansGuaranteed } from '../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import InvestmentAccounts from '../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  ProductCode,
  accountClosureRequestStatus,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import CustomerAccountClosure from '../Partials/CustomerAccountClosure/CustomerAccountClosure'

const initialCustomerAccountValues = {
  customerLoanAccounts: [],
  customerSavingsAccounts: [],
  customerInvestmentAccounts: [],
}

const initialBalanceValues = {
  netPartialRefundable: '',
  netLoansBalance: '',
  netRefundable: '',
  netInvestmentsBalance: '',
  backOfficeInvestmentsBalance: '',
  backOfficeLoansBalance: '',
}

const Verification = () => {
  const [customer, setCustomer] = useState({
    auditRemarks: '',
    auditOption: 1,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [loadAccounts, setLoadAccounts] = useState(false)
  const [loansGuaranteed, setLoansGuaranteed] = useState([])
  const [balance, setBalance] = useState(initialBalanceValues)
  const [customerAccounts, setCustomerAccounts] = useState(
    initialCustomerAccountValues
  )

  const fetchCustomerAccounts = async () => {
    setLoadAccounts(true)

    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customer?.customerAccountCustomerId,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)
      setLoadAccounts(false)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    } else setLoadAccounts(false)
  }

  const fetchLoansGuaranteed = async () => {
    setLoadAccounts(true)
    const url = '/LoanCase/find-loan-guarantors-by-loanee-customer-id'

    const { success, data } = await getData(
      url,
      {
        loaneeCustomerId: customer?.customerAccountCustomerId,
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      setLoadAccounts(false)
      setLoansGuaranteed(data?.result)
    } else {
      setLoadAccounts(false)
    }
  }

  const onCustomerAccountLookup = account => {
    if (account?.status !== Object.keys(accountClosureRequestStatus)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only select accounts with status approved',
      })

    setCustomer({
      ...account,
      auditOption: 1,
    })
  }

  const handleAVerifyRequest = async () => {
    setIsBusy(true)

    const url = `/AccountClosureRequest/audit-account-closure-request`

    const { success } = await postData(
      url,
      {
        accountClosureRequest: customer,
        accountClosureAuditOption: customer?.auditOption,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      showNotification('Account Closure Verification', 'success')
      setCustomer({
        auditRemarks: '',
        auditOption: 1,
      })

      setLoansGuaranteed([])
      setCustomerAccounts(initialCustomerAccountValues)
      setBalance(initialBalanceValues)
    } else setIsBusy(false)
  }

  useEffect(() => {
    if (customer?.customerAccountCustomerId) {
      fetchCustomerAccounts()
      fetchLoansGuaranteed()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerAccountCustomerId])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('accountClosure'),
          localize('verifyAccountClosure'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <CustomerAccountClosure
                customer={customer}
                onCustomerAccountLookup={onCustomerAccountLookup}
                status="2"
              />

              <div className="mt-4">
                <Tab
                  tabItems={[
                    {
                      label: localize('loansAccounts'),
                      item: (
                        <>
                          {loadAccounts ? (
                            <Loader />
                          ) : (
                            <LoanAccounts
                              data={customerAccounts?.customerLoanAccounts?.filter(
                                x => x?.bookBalance < 0
                              )}
                            />
                          )}
                        </>
                      ),
                    },
                    {
                      label: localize('investmentAccounts'),
                      item: (
                        <>
                          {loadAccounts ? (
                            <Loader />
                          ) : (
                            <InvestmentAccounts
                              data={
                                customerAccounts?.customerInvestmentAccounts
                              }
                            />
                          )}
                        </>
                      ),
                    },
                    {
                      label: localize('loansGuaranteed'),
                      item: (
                        <>
                          {loadAccounts ? (
                            <Loader />
                          ) : (
                            <LoansGuaranteed loanGuarantors={loansGuaranteed} />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('deposits(Refundable)')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        balance?.netInvestmentsBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('loansBalance')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        balance?.netLoansBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('netRefundable')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        balance?.netRefundable,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 d-flex align-items-center justify-content-end p-2 rounded bg-light gap-3">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('auditRemarks')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={customer?.auditRemarks}
                    onChange={e =>
                      setCustomer({ ...customer, auditRemarks: e.target.value })
                    }
                  />
                </div>

                <EnumsServerCombo
                  enumsKey="AccountClosureAuditOption"
                  onChange={({ value }) =>
                    setCustomer({ ...customer, auditOption: value })
                  }
                  value={customer?.auditOption}
                  sort
                  customContainerStyle={{
                    maxWidth: 'fit-content',
                    minWidth: 'fit-content',
                  }}
                />

                <button
                  onClick={handleAVerifyRequest}
                  className="btn btn-success"
                >
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Verification
