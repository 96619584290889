/** @format */

import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../Components/Tabs/Tab'

const ViewDesignationModal = ({
  mode,
  close,
  selectedDesignation,
  setSelectedDesignation,
}) => {
  const columns = [
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    {
      label: localize('threshold'),
      template: r => <>{formatCurrency(r?.threshold)}</>,
      headerTextAlign: 'right',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [thresholdPreferences, setThresholdPreferences] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    getParentDesignation()
    loadTransactionThresholdPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDesignation?.id])

  const loadTransactionThresholdPreferences = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/Designation/find-transaction-threshold-by-designation-id?designationId=${selectedDesignation?.id}`,
      {},
      false
    )
    if (success) {
      setIsBusy(false)
      setThresholdPreferences(data.result)
    } else {
      setIsBusy(false)
      setThresholdPreferences([])
    }
  }

  const getParentDesignation = async () => {
    if (!selectedDesignation?.parentId) return

    setIsBusy(true)
    const { success, data } = await getData(
      '/Designation/find-designation-by-id',
      { designationId: selectedDesignation?.parentId },
      false
    )
    if (success) {
      setSelectedDesignation({
        ...selectedDesignation,
        parentDescription: data.description,
      })
    }
    setIsBusy(false)
  }

  function closeModal() {
    close()
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-xl"
        modalTitle={'designation'}
        modalMode={mode}
        hideUpdate
        handleClose={closeModal}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                id="cDesignationIsLocked"
                disabled
                checked={selectedDesignation?.isLocked}
              />
              <label
                htmlFor="cDesignationIsLocked"
                className="text-capitalize mb-2"
              >
                {localize('designationIsLocked')} ?
              </label>
              <div className="mx-3"></div>
            </div>
          </>
        }
      >
        <>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-2">
                  {localize('parentDesignation')}
                </label>
                <input
                  className="form-control"
                  defaultValue={selectedDesignation?.parentDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  className="form-control"
                  defaultValue={selectedDesignation?.description}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-1">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  defaultValue={selectedDesignation?.remarks}
                  disabled
                />
              </div>
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <div className="row col-12 mt-2">
              {selectedDesignation && (
                <Tab
                  tabItems={[
                    {
                      label: localize('transactionThresholdPreferences'),
                      item: (
                        <SimpleTable
                          loading={true}
                          data={thresholdPreferences}
                          columns={columns}
                          selectables
                        />
                      ),
                    },
                  ]}
                />
              )}
            </div>
          )}
        </>
      </ModalTemplate>
    </>
  )
}

export default ViewDesignationModal
