/** @format */
/* eslint-disable */
import React, { useState } from 'react'
import './ModuleInformation.scss'
import { AuthorizedRoles } from './AuthorizedRoles'
import localize from '../../../../../Global/Localize'
import { AccessControlTree } from './AccessControlTree'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export const ModuleInformation = ({
  module,
  currentNavigation,
  rolesToBeSelected,
  setRolesToBeSelected,
  handleMenuSelected,
  isBusy,
  handleOnSingleCheck,
  showAuthorizedModal,
  setShowAuthorizedModal,
}) => {
  const [selectedNavigation, setSelectedNavigation] = useState(10)

  const onTreeMenuSelect = r => {
    handleMenuSelected(r)
  }

  return (
    <>
      <div className="row mt-2 g-2">
        <div
          className="col-md-6"
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          <h3 className="text-capitalize">{localize('setups')}</h3>
          <AccessControlTree
            navigation={10}
            selectedNavigation={selectedNavigation}
            setSelectedNavigation={setSelectedNavigation}
            key={`setups-${1}`}
            handleOnSelectMenu={onTreeMenuSelect}
            data={module?.setups}
          />
        </div>
        <div
          className="col-md-6"
          style={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          <h3 className="text-capitalize">{localize('operations')}</h3>
          <AccessControlTree
            navigation={20}
            selectedNavigation={selectedNavigation}
            setSelectedNavigation={setSelectedNavigation}
            key={`operations-${2}`}
            handleOnSelectMenu={onTreeMenuSelect}
            data={module?.operations}
          />
        </div>
      </div>
      <div className="row mt-2">
        {showAuthorizedModal && (
          <Modal isOpen className="modal-lg">
            <ModalHeader className="bg-white text-capitalize">
              {localize('authorizedRoles')}
              <AuthorizedRolesBreadCrumb tree={currentNavigation} />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowAuthorizedModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>

            <ModalBody className="bg-white">
              <AuthorizedRoles
                prefix={'module-roles'}
                onSingleCheck={handleOnSingleCheck}
                onCheckRole={r => {
                  setRolesToBeSelected(r)
                }}
                isBusy={isBusy}
                rolesToBeSelected={rolesToBeSelected}
              />
            </ModalBody>
          </Modal>
        )}
      </div>
    </>
  )
}

function AuthorizedRolesBreadCrumb({ tree }) {
  let breadCrumbItems = [
    tree?.Module,
    `${tree?.Description} (${tree?.MenuCode})`,
  ]
  if (tree?.ParentDescription) {
    breadCrumbItems.splice(1, 0, tree.ParentDescription)
  }

  return (
    <div className="row">
      <div className="col-12 col">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadCrumbItems.map((t, i) => (
                <li
                  key={i}
                  style={{ textTransform: 'capitalize' }}
                  className={`breadcrumb-item ${
                    i === tree.length - 1 ? 'active' : ''
                  }`}
                >
                  <a href="/#">{t}</a>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}
