/** @format */

import { postData, putData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { CreateSupplierModal } from './CreateSupplierModal'
import { useState } from 'react'
import { EditSupplierModal } from './EditSupplierModal'
import swal from 'sweetalert2'
import { ViewSupplierStatement } from './ViewSupplierStatement'
import { ViewSupplierModal } from './ViewSupplierModal'

function SuppliersModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedSupplier,
  setSelectedSupplier,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      handleOnAddSupplier()
    }
    if (mode === 'edit') {
      handleOnEditSupplier()
    }
  }

  const handleOnAddSupplier = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Supplier/add-supplier-with-categories',
      {
        Supplier: { ...selectedSupplier },
        SupplyCategories: selectedSupplier.selectedSupplyCategories,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      return swal.fire(
        'Create Supplier',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const handleOnEditSupplier = async () => {
    setIsBusy(true)
    const { success, data } = await putData(
      '/Supplier/update-supplier-with-supplier-categories',
      {
        Supplier: { ...selectedSupplier },
        SupplyCategories: selectedSupplier.selectedSupplyCategories,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      return swal.fire(
        'Edit Supplier',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('suppliers')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            hideUpdate={mode === 'view'}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateSupplierModal
                isBusy={isBusy}
                setSupplierModel={setSelectedSupplier}
                supplierModel={selectedSupplier}
              />
            )}
            {mode === 'edit' && (
              <EditSupplierModal
                setIsBusy={setIsBusy}
                isBusy={isBusy}
                setSupplierModel={setSelectedSupplier}
                supplierModel={selectedSupplier}
              />
            )}
            {mode === 'view' && (
              <ViewSupplierModal selectedSupplier={selectedSupplier} />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default SuppliersModal
