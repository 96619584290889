/** @format */

import localize from '../../../../../../../Global/Localize'
import CustomersLookup from '../../../../../../../Components/Lookup/CustomersLookup'
import CustomerAccountLookup from '../../../../../../../Components/Lookup/CustomerAccountLookup'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'

export const VoucherDetailsCustomer = ({
  setJournalVoucherEntryModel,
  journalVoucherEntryModel,
  refreshVoucherJournalDetails,
}) => {
  const [voucherDetailsCustomerModel, setVoucherDetailsCustomerModel] =
    useState(null)

  useEffect(() => {
    return () => {
      setVoucherDetailsCustomerModel(null)
    }
  }, [refreshVoucherJournalDetails])

  //
  // if (prevJournalVoucherEntryModel !== journalVoucherEntryModel) {
  //   setPrevJournalVoucherEntryModel(journalVoucherEntryModel)
  //   setVoucherDetailsCustomerModel(null)
  // }

  const handleOnAddVoucherDetailsCustomerCustomerInformation = r => {
    setVoucherDetailsCustomerModel({
      ...voucherDetailsCustomerModel,
      customerFullName: r.fullName,
      customerId: r.id,
      customerSerialNumber: r.serialNumber,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        customerFullName: r.fullName,
        customerId: r.id,
        customerAccountCustomerFullName: r.fullName,
        customerSerialNumber: r.serialNumber,
      },
    })
  }

  const handleOnAddVoucherDetailsCustomerCostCenter = r => {
    setVoucherDetailsCustomerModel({
      ...voucherDetailsCustomerModel,
      costCenter: r.description,
      costCenterId: r.id,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        costCenterDescription: r.description,
        costCenterId: r.id,
      },
    })
  }

  const handleOnAddVoucherDetailsCustomerCustomerAccount = r => {
    setVoucherDetailsCustomerModel({
      ...voucherDetailsCustomerModel,
      accountNumber: r.fullAccountNumber,
      accountNumberId: r.id,
      customerAccountTypeTargetProductDescription:
        r.customerAccountTypeTargetProductDescription,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        customerAccountFullAccountNumber: r.fullAccountNumber,
        accountNumberId: r.id,
        customerAccountTypeTargetProductDescription:
          r.customerAccountTypeTargetProductDescription,
        id: r.id,
        chartOfAccountId: r.customerAccountTypeTargetProductChartOfAccountId,
        branchCode: r.branchCode,
        customerSerialNumber: r.customerSerialNumber,
        customerAccountTypeProductCode: r.customerAccountTypeProductCode,
        customerAccountTypeTargetProductCode: r.customerAccountTypeProductCode,
        customerMembershipClassCustomerType:
          r.customerMembershipClassCustomerType,
        customerIndividualSalutation: r.customerIndividualSalutation,
        customerIndividualFirstName: r.customerIndividualFirstName,
        customerIndividualLastName: r.customerIndividualLastName,
        customerNonIndividualDescription: r.customerNonIndividualDescription,
        customerReference1: r.customerReference1,
        customerReference2: r.customerReference2,
        customerReference3: r.customerReference3,
        customerAccountCustomerReference1: r.customerReference1,
        customerAccountCustomerReference2: r.customerReference2,
        customerAccountCustomerReference3: r.customerReference3,
        customerAccountTypeTargetProductChartOfAccountId:
          r.customerAccountTypeTargetProductChartOfAccountId,
        customerAccountTypeTargetProductChartOfAccountType:
          r.customerAccountTypeTargetProductChartOfAccountType,
        customerAccountTypeTargetProductChartOfAccountCode:
          r.customerAccountTypeTargetProductChartOfAccountCode,
        customerAccountTypeTargetProductChartOfAccountName:
          r.customerAccountTypeTargetProductChartOfAccountName,
      },
    })
  }

  const handleOnUpdatevoucherDetailsCustomerModelAmount = e => {
    setVoucherDetailsCustomerModel({
      ...voucherDetailsCustomerModel,
      amount: e.target.value,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        amount: e.target.value,
      },
    })
  }

  const handleOnUpdatevoucherDetailsCustomerModelRemarks = e => {
    setVoucherDetailsCustomerModel({
      ...voucherDetailsCustomerModel,
      remarks: e.target.value,
    })

    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        remarks: e.target.value,
      },
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerName')}
          </label>
          <CustomersLookup
            displayValue={voucherDetailsCustomerModel?.customerFullName}
            onSelect={r => {
              handleOnAddVoucherDetailsCustomerCustomerInformation(r)
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerSerialNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={voucherDetailsCustomerModel?.customerSerialNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costCenter')}
          </label>
          <CostCentreLookup
            displayValue={voucherDetailsCustomerModel?.costCenter}
            onSelect={r => {
              handleOnAddVoucherDetailsCustomerCostCenter(r)
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('fullAccountNumber')}
          </label>
          <CustomerAccountLookup
            displayValue={voucherDetailsCustomerModel?.accountNumber}
            onSelect={r => {
              handleOnAddVoucherDetailsCustomerCustomerAccount(r)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('productName')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              voucherDetailsCustomerModel?.customerAccountTypeTargetProductDescription
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            defaultMaskValue={voucherDetailsCustomerModel?.amount}
            onMaskNumber={r => {
              handleOnUpdatevoucherDetailsCustomerModelAmount(r)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            defaultValue={voucherDetailsCustomerModel?.remarks}
            onChange={e => {
              handleOnUpdatevoucherDetailsCustomerModelRemarks(e)
            }}
          />
        </div>
      </div>
    </>
  )
}
