/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import CustomerAccountByCustomerIdLookup from '../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import VehicleFleetProductLookup from '../../../../../Components/Lookup/VehicleFleetProductLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { RecordStatus } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { vehicleFleetProductType } from '../../../../../Helpers/constants'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import VehicleRouteLookup from '../../../../../Components/Lookup/VehicleRouteLookup'

const AddVehicle = ({ handleClose, mode, vehicleData, fetchVehicles }) => {
  const [customer, setCustomer] = useState({})
  const [vehicleProduct, setVehicleProduct] = useState({})
  const [vehicle, setVehicle] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [vehicleProducts, setVehicleProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isBusyLoadingProducts, setIsBusyLoadingProducts] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectProduct = (p, value) => {
    if (value) {
      setSelectedProducts([...selectedProducts, p])
    } else {
      setSelectedProducts(
        selectedProducts.filter(
          x => x.vehicleFleetProductId !== p?.vehicleFleetProductId
        )
      )
    }
  }

  const columns = [
    {
      // eslint-disable-next-line no-useless-concat
      label: 'select' + ' ?',
      template: r => (
        <Checkbox
          checked={selectedProducts?.find(
            x => x?.vehicleFleetProductId === r?.vehicleFleetProductId
          )}
          onCheck={value => {
            handleSelectProduct(r, value)
          }}
        />
      ),
    },
    {
      label: 'productType',
      template: r => r?.vehicleFleetProductProductTypeDescription,
    },
    {
      label: 'productName',
      template: r => r?.vehicleFleetProductDescription,
    },
    { label: 'fullAccountNumber', template: r => r?.fullAccountNumber },
    { label: 'contribution', template: r => r?.contribution },
    { label: 'remarks', template: r => r?.remarks },
    { label: 'createdDate', template: r => formatDate(r?.createdDate) },
  ]

  const handleSelectCustomer = c => {
    if (c?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Customer is locked'
      )

    if (c?.recordStatus !== Object.keys(RecordStatus)[2])
      return showNotification(
        'Operation not allowed',
        'error',
        'Customer is not approved'
      )

    clearValidation('CustomerFullName')

    setCustomer({
      ...c,
      customerId: c?.id,
      customerFullName: c?.fullName,
    })
  }

  const handleSelectRoute = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Route is locked'
      )

    clearValidation('VehicleRouteDescription')
    setVehicle({
      ...r,
      vehicleRouteId: r?.id,
      vehicleRouteDescription: r?.description,
    })
  }

  const handleSelectVehicleFleetProduct = p => {
    if (p?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected Product is locked'
      )

    if (p?.productType === vehicleFleetProductType.INVESTMENT_PRODUCT) {
      setVehicleProduct({
        ...vehicleProduct,
        investmentProductId: p?.customerAccountTypeTargetProductId,
      })
    } else if (p?.productType === vehicleFleetProductType.SAVINGS_PRODUCT) {
      setVehicleProduct({
        ...vehicleProduct,
        savingsProductId: p?.customerAccountTypeTargetProductId,
      })
    }

    setVehicleProduct({
      ...vehicleProduct,
      fleetProductId: p?.id,
      contribution: p?.minimumContribution,
      vehicleFleetProductId: p?.id,
      vehicleFleetProductProductTypeDescription: p?.productTypeDescription,
      vehicleFleetProductDescription: p?.description,
      vehicleFleetProductProductType: p?.productType,
      targetProductDescription: p?.targetProductDescription,
      remarks: '',
    })
  }

  const handleSelectCustomerAccount = cu => {
    setVehicleProduct({
      ...vehicleProduct,
      fullAccountNumber: cu?.fullAccountNumber,
      customerAccountId: cu?.id,
      remarks: '',
    })
  }

  const handleAdd = () => {
    if (!vehicleProduct?.vehicleFleetProductId) {
      return showNotification(
        'Operation not allowed',
        'error',
        'No product selected'
      )
    }

    if (
      vehicleProducts?.find(
        x => x?.vehicleFleetProductId === vehicleProduct?.vehicleFleetProductId
      )
    ) {
      return showNotification(
        'Operation not allowed',
        'error',
        'Product already added'
      )
    }

    setVehicleProducts([...vehicleProducts, vehicleProduct])
    setVehicleProduct({
      contribution: 0,
    })
  }

  const handleRemove = () => {
    if (selectedProducts?.length === 0) {
      return showNotification(
        'Operation not allowed',
        'error',
        'No product selected'
      )
    }

    setVehicleProducts(
      vehicleProducts.filter(
        x =>
          !selectedProducts.find(
            y => y?.vehicleFleetProductId === x?.vehicleFleetProductId
          )
      )
    )
  }

  const handleCreateVehicle = async () => {
    setIsBusy(true)

    const url = '/Vehicle/create-vehicle-with-products'

    const { success } = await postData(
      url,
      {
        vehicle: {
          ...vehicle,
          ...customer,
        },
        vehicleProducts: vehicleProducts?.map(v => ({
          ...v,
          vehicleId: vehicleData?.id,
        })),
      },
      false
    )

    if (success) {
      showNotification('Create Vehicle', 'success')
      setCustomer({})
      setVehicle({})
      handleClose()
      setVehicleProducts([])
      fetchVehicles()
    }

    setIsBusy(false)
  }

  const findVehicleProducts = async () => {
    setIsBusyLoadingProducts(true)
    const url = `/Vehicle/find-vehicle-products-by-vehicle-id?vehicleId=${vehicleData?.id}`
    const { success, data } = await getData(url, {}, false)
    if (success) {
      setVehicleProducts(data?.result)
    }

    setIsBusyLoadingProducts(false)
  }

  const handleUpdateVehicle = async () => {
    setIsBusy(true)

    const url = '/Vehicle/update-vehicle-with-products'

    const { success } = await putData(
      url,
      {
        vehicle: {
          ...vehicle,
          ...customer,
          id: vehicleData?.id,
        },
        vehicleProducts: vehicleProducts?.map(v => ({
          ...v,
          vehicleId: vehicleData?.id,
        })),
      },
      false
    )

    if (success) {
      showNotification('Update Vehicle', 'success')
      setCustomer({})
      setVehicle({})
      handleClose()
      setVehicleProducts([])
      fetchVehicles()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (mode === 'edit' && vehicleData?.id) {
      setVehicle({
        ...vehicleData,
        vehicleRouteId: vehicleData?.vehicleRouteId,
        vehicleRouteDescription: vehicleData?.vehicleRouteDescription,
        customerId: vehicleData?.customerId,
        customerFullName: vehicleData?.fullName,
      })

      setCustomer({
        customerId: vehicleData?.customerId,
        customerFullName: vehicleData?.customerFullName,
        id: vehicleData?.customerId,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, vehicleData?.id])

  useEffect(() => {
    if (mode === 'edit' && vehicleData?.id) {
      findVehicleProducts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, vehicleData?.id])

  return (
    <ModalTemplate
      modalMode={mode === 'edit' ? 'edit' : 'create'}
      handleClose={handleClose}
      modalTitle={mode === 'edit' ? 'editVehicle' : 'createVehicle'}
      disableCreate={isBusy}
      modalClassName="modal-xl"
      actionText={mode === 'edit' ? 'update' : 'create'}
      handleAction={mode === 'edit' ? handleUpdateVehicle : handleCreateVehicle}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              disabled={isBusy}
              checked={vehicle?.isLocked}
              onCheck={v => setVehicle({ ...vehicle, isLocked: v })}
            />
            <label className="text-capitalize">
              {localize('isLocked') + ' ?'}
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerPartial
            customer={customer}
            onCustomerLookup={handleSelectCustomer}
            refreshFromServer
            message={globalValidationErrors['CustomerFullName']?.message}
          />
          <div className="row gy-2">
            <div
              className="col-md-3 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['VehicleRouteDescription']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('vehicleRoute')}
              </label>
              <VehicleRouteLookup
                displayValue={vehicle?.vehicleRouteDescription}
                onSelect={handleSelectRoute}
              />
            </div>
            <div
              className="col-md-3 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['RegistrationNumber']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('registrationNumber')}
              </label>
              <input
                className="form-control ms-2"
                value={vehicle?.registrationNumber}
                onChange={e => {
                  clearValidation('RegistrationNumber')
                  setVehicle({ ...vehicle, registrationNumber: e.target.value })
                }}
              />
            </div>
            <div
              className="col-md-3 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['FleetNumber']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('fleetNumber')}
              </label>
              <input
                className="form-control ms-2"
                value={vehicle?.fleetNumber}
                onChange={e => {
                  clearValidation('FleetNumber')
                  setVehicle({ ...vehicle, fleetNumber: e.target.value })
                }}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('documentReferenceNumber')}
              </label>
              <input
                className="form-control ms-2"
                value={vehicle?.documentReferenceNumber}
                onChange={e => {
                  setVehicle({
                    ...vehicle,
                    documentReferenceNumber: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-md-12 d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={vehicle?.remarks}
                onChange={e => {
                  setVehicle({
                    ...vehicle,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('vehicleProductDetails')}
              </div>
              <Tab
                tabItems={[
                  {
                    label: localize('vehicleProducts'),
                    item: (
                      <>
                        <div className="row g-3">
                          <div className="col-md-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('fleetProduct')}
                            </label>

                            <VehicleFleetProductLookup
                              displayValue={
                                vehicleProduct?.targetProductDescription
                              }
                              onSelect={handleSelectVehicleFleetProduct}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('contribution')}
                            </label>
                            <MaskNumber
                              defaultMaskValue={vehicleProduct?.contribution}
                              onMaskNumber={e => {
                                setVehicleProduct({
                                  ...vehicleProduct,
                                  contribution: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('fullAccountNumber')}
                            </label>
                            <CustomerAccountByCustomerIdLookup
                              displayValue={vehicleProduct?.fullAccountNumber}
                              onSelect={handleSelectCustomerAccount}
                              selectedCustomer={customer}
                            />
                          </div>
                          <div className="col-md-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('remarks')}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleProduct?.remarks}
                              onChange={e => {
                                setVehicleProduct({
                                  ...vehicleProduct,
                                  remarks: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-3 bg-light p-2 d-flex justify-content-end rounded align-items-center">
                          <button
                            className="btn btn-success"
                            onClick={handleAdd}
                          >
                            {localize('add')}
                          </button>
                        </div>
                        <div
                          className="p-2 mt-4"
                          style={{
                            border: 'solid 1px #0000001f',
                            borderRadius: 10,
                            position: 'relative',
                          }}
                        >
                          <div
                            className="bg-white px-2  row text-capitalize"
                            style={{
                              position: 'relative',
                              top: -20,
                              left: 30,
                              maxWidth: 'fit-content',
                            }}
                          >
                            {localize('vehicleProducts')}
                          </div>

                          {isBusyLoadingProducts ? (
                            <Loader />
                          ) : (
                            <>
                              <SimpleTable
                                columns={columns}
                                data={vehicleProducts}
                              />
                              <div className="d-flex justify-content-end mt-2">
                                <button
                                  className="btn btn-danger"
                                  onClick={handleRemove}
                                >
                                  {localize('remove')}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddVehicle
