/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import IAEntries from '../../Partials/IAEntries'
import { getData, putData } from '../../../../../../Helpers/webApi'
import DynamicCharges from '../../../../../../Components/Selectables/Plain/DynamicCharges'
import { formatDate } from '../../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { batchAuthOptions } from '../../../../../../Global/enumeration'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function VerifyInterACTransfer({
  mode,
  selectedInterAccountTransferBatch,
  setSelectedInterAccountTransferBatch,
  closeModal,
  dynamicChargesToBeSelected,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [apportionedAmount, setApportioned] = useState(null)
  const [shortageAmount, setShortage] = useState(null)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    interAccountTransferBatchEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(batchAuthOptions)[0]
  )

  const loadEntries = async () => {
    const { success, data } = await getData(
      `/InterAccountTransferBatch/find-inter-account-transfer-batch-entries-by-inter-account-transfer-batch-id-in-page`,
      {
        interAccountTransferBatchId: selectedInterAccountTransferBatch.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setEntries(data.pageCollection)
      setApportioned(data.totalApportioned)
      setShortage(data.totalShortage)
    } else {
      setIsBusy(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [
    selectedInterAccountTransferBatch?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  async function handleSubmit() {
    const { success } = await putData(
      '/InterAccountTransferBatch/audit-inter-account-transfer-batch',
      {
        interAccountTransferBatch: {
          ...selectedInterAccountTransferBatch,
        },
        batchAuthOption: selectedAuth,
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      setSelectedInterAccountTransferBatch(null)
      loadTableData()
      closeThis()
    } else {
      setIsBusy(false)
    }
  }

  function closeThis() {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('InterAccountTransfers')}
        handleClose={closeThis}
        modalClassName="modal-xl"
        handleAction={handleSubmit}
        actionText={mode === 'view' ? 'refresh' : ''}
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('apportioned')}
              </label>
              <MaskNumber
                className="form-control ms-2"
                defaultMaskValue={apportionedAmount}
                disabled
              />
            </div>

            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('shortage')}
              </label>
              <MaskNumber
                className="form-control ms-2"
                defaultMaskValue={shortageAmount}
                disabled
              />
            </div>
            <div className="col-md-3 mt-3">
              <div
                className="d-flex align-items-center mb-1 with-validation"
                validation-message={
                  globalValidationErrors[
                    'InterAccountTransferBatch.AuditRemarks'
                  ]?.message
                }
              >
                <label className="text-capitalize col-2 me-2">
                  {localize('remarks')}
                </label>
                <input
                  defaultValue={selectedInterAccountTransferBatch?.auditRemarks}
                  type="text"
                  className="form-control ms-4"
                  onChange={e => {
                    clearValidation('InterAccountTransferBatch.AuditRemarks')
                    setSelectedInterAccountTransferBatch({
                      ...selectedInterAccountTransferBatch,
                      auditRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <div className="d-flex align-items-center mb-2">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchAuthOptions"
                  onChange={e => setSelectedAuth(e.value)}
                />
              </div>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('batchNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedInterAccountTransferBatch?.batchNumber || ''}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('batchType')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedInterAccountTransferBatch?.typeDescription || ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center ">
                <label className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedInterAccountTransferBatch?.remarks || ''}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedInterAccountTransferBatch?.createdBy || ''}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    formatDate(
                      selectedInterAccountTransferBatch?.createdDate
                    ) || ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('priority')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedInterAccountTransferBatch?.priorityDescription || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row col-12 mt-2">
              <Tab
                tabItems={[
                  {
                    label: localize('batchEntries'),
                    item: (
                      <IAEntries
                        mode={mode}
                        unpostedEntries={entries}
                        entries={entries}
                        setApportioned={setApportioned}
                        setShortage={setShortage}
                      />
                    ),
                  },
                  {
                    label: localize('indefiniteCharges'),
                    item: (
                      <DynamicCharges
                        prefix={'create-inter-acc-transfer-dynamic-charges'}
                        dynamicChargesToBeSelected={dynamicChargesToBeSelected}
                        disabled
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default VerifyInterACTransfer
