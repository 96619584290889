/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
import Swal from 'sweetalert2'
import { putData } from '../../../../Helpers/webApi'
import { ViewCustomer } from '../../../../Components/ReUsables/Customer/ViewCustomer'
import { NextOfKinDetails } from './NextOfKinDetails'
import Tab from '../../../../Components/Tabs/Tab'
import { NextOfKinHistory } from './NextOfKinHistory'
import { useState } from 'react'

function NextOfKinModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedCustomer,
  nextOfKinHistory,
}) {
  const [selectedKins, setSelectedKins] = useState([])

  async function handleSubmit(finalData) {
    const { success } = await putData(
      '/Customer/update-next-of-kin-collection',
      finalData
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Edit Next Of Kin Details',
        showCloseButton: true,
      })
    }
  }
  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <div>
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('nextOfKin')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <ViewCustomer customer={selectedCustomer} />
              <div className="row mt-3">
                <Tab
                  tabItems={[
                    {
                      label: localize('nextOfKinDetails'),
                      item: (
                        <NextOfKinDetails
                          onAddNewNextOfKin={c => {
                            setSelectedKins(c)
                          }}
                          selectedCustomer={selectedCustomer}
                        />
                      ),
                    },
                    {
                      label: localize('nextOfKinHistory'),
                      item: (
                        <NextOfKinHistory nextOfKinHistory={nextOfKinHistory} />
                      ),
                    },
                  ]}
                />
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <button className="btn btn-danger px-4" onClick={onHideModal}>
                {localize('cancel')}
              </button>
              <button
                onClick={async () => {
                  await handleSubmit({
                    customerId: selectedCustomer.id,
                    nextOfKin: selectedKins,
                  })
                }}
                className={`btn btn-success text-uppercase px-4`}
                type="button"
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </>
  )
}
export default NextOfKinModal
