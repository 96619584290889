/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { formatDate, parseDate } from '../../../../../../Helpers/formatters'
import BatchEntries from './BatchEntries/BatchEntries'

const ViewTemplateBasedReport = ({ mode, handleClose, selectedTemplate }) => {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        handleAction
        handleClose={handleClose}
        modalTitle="templateBasedReport"
        cancelText="close"
        modalClassName="modal-xl"
        hideUpdate
      >
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center with-validation">
              <label htmlFor="batchNumber" className="text-capitalize col-4">
                {localize('batchNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedTemplate?.paddedBatchNumber}
                disabled
                id="batchNumber"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="reference" className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedTemplate?.reference}
                disabled
                id="reference"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="dateFilter" className="text-capitalize col-4">
                {localize('dateFilter')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedTemplate?.dateFilterDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="cutoffDate" className="text-capitalize col-4">
                {localize('cutOffDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(selectedTemplate?.cutOffDate, true)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="startDate" className="text-capitalize col-4">
                {localize('startDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(selectedTemplate?.durationStartDate, true)}
                disabled
                id="startDate"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="durationEndDate"
                className="text-capitalize col-4"
              >
                {localize('endDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(selectedTemplate?.durationEndDate, true)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="createdBy" className="text-capitalize col-4">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedTemplate?.createdBy}
                disabled
                id="createdBy"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="createdDate" className="text-capitalize col-4">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={parseDate(selectedTemplate?.createdDate)}
                disabled
                id="createdDate"
              />
            </div>
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2  row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('batchEntries')}
          </div>
          <div className="row g-3">
            <BatchEntries entry={selectedTemplate} />
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}

export default ViewTemplateBasedReport
