/** @format */

import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { putData } from '../../../../Helpers/webApi'
import Specimen from '../../../../Pages/Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import AddressV2 from '../../../Miscalleneous/AddressV2'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import Tab from '../../../Tabs/Tab'

const EditSignatory = ({
  mode,
  handleClose,
  signatory,
  setSignatory,
  fetchCustomerAccounts,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleUpdateSignatory = async () => {
    setIsBusy(true)

    const url = '/CustomerAccount/update-customer-account-signatory'

    const { success } = await putData(url, signatory, false)

    if (success) {
      handleClose()
      showNotification('Edit Signatory', 'success')
      fetchCustomerAccounts()
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      modalTitle="editSignatory"
      handleAction={handleUpdateSignatory}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('salutation')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="Salutation"
                value={signatory?.salutation}
                onChange={e =>
                  setSignatory({ ...signatory, salutation: e.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('gender')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="Gender"
                value={signatory?.gender}
                onChange={e => setSignatory({ ...signatory, gender: e.value })}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('relationship')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="SignatoryRelationship"
                value={signatory?.relationship}
                onChange={e =>
                  setSignatory({ ...signatory, relationship: e.value })
                }
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('firstName')}
              </label>
              <input
                type="text"
                className="form-control"
                value={signatory?.firstName}
                onChange={e =>
                  setSignatory({ ...signatory, firstName: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('otherNames')}
              </label>
              <input
                type="text"
                className="form-control"
                value={signatory?.lastName}
                onChange={e =>
                  setSignatory({ ...signatory, lastName: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                value={signatory?.customerName}
                onChange={e =>
                  setSignatory({ ...signatory, customerName: e.target.value })
                }
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('identityCardType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="IdentityCardType"
                value={signatory?.identityCardType}
                onChange={e =>
                  setSignatory({ ...signatory, identityCardType: e.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('identityCardNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={signatory?.identityCardNumber}
                onChange={e =>
                  setSignatory({
                    ...signatory,
                    identityCardNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={signatory?.remarks}
                onChange={e =>
                  setSignatory({
                    ...signatory,
                    remarks: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('address'),
                  item: (
                    <AddressV2
                      onChange={data => {
                        setSignatory({
                          ...signatory,
                          ...data,
                        })
                      }}
                      parent={signatory}
                    />
                  ),
                  icon: 'uil uil-edit',
                },
                {
                  label: localize('specimen'),
                  item: <Specimen customer={signatory} refetchFromServer />,
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default EditSignatory
