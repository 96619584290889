/** @format */

import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
import React from 'react'

export const PointOfSaleTerminalLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    { label: localize('teller'), template: r => <>{r.tellerDescription}</> },
    {
      label: localize('tellerType'),
      template: r => <>{r.tellerTypeDescription}</>,
    },
    { label: localize('name'), template: r => <>{r.description}</> },
    {
      label: localize('uniqueIdentifier'),
      template: r => <>{r.uniqueIdentifier}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        title={localize('pointOfSaleTerminalLookup')}
        url="/lookup/PointOfSaleTerminal/find-point-of-sale-terminal-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={columns}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}
