/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import AddNewButton from '../../Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../Checkbox/Checkbox'
import Loader from '../../Loaders/Loader'
import SimpleTable from '../../Tables/SimpleTable'

function ListBankReconciliation({
  handleAddButtonClick,
  showAddButton,
  handleSelectPeriod,
  canClickOnce,
  refresh,
  showContextMenu,
  handleClickOnce,
  canSelect,
  setRefresh,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [periods, setPeriods] = useState([])
  const [reqParams, setReqParams] = useState({
    traverseTree: true,
    filterText: '',
    updateDepth: true,
  })

  const columns = [
    {
      label: localize('description'),
      template: r => (
        <span
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        ></span>
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('durationStartDate'),
      template: r => formatDate(r?.durationStartDate, true),
    },
    {
      label: localize('durationEndDate'),
      template: r => formatDate(r?.durationEndDate, true),
    },
    {
      label: localize('g/LAccountName'),
      template: r => r?.chartOfAccountName,
    },
    {
      label: localize('bankBranchName'),
      template: r => r?.bankBranchName,
    },
    {
      label: localize('bankAccountNumber'),
      template: r => r?.bankAccountNumber,
    },
    {
      label: localize('bankAccountBalance'),
      template: r => formatCurrency(r?.bankAccountBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('authorizedBy') + '/' + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label:
        localize('authorizationRemarks') + '/' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorizedDate') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchPeriods = async () => {
    setIsBusy(true)
    const url = '/BankReconciliationPeriod/find-bank-reconciliation-periods'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setPeriods(data)

      if (setRefresh) setRefresh(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchPeriods()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.updateDepth, reqParams.traverseTree, refresh])

  return (
    <>
      {showAddButton && (
        <div className="row mb-3">
          <div className="col">
            <AddNewButton handleClickAddButton={handleAddButtonClick} />
          </div>
        </div>
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row d-flex justify-content-between align-items-center mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <Checkbox
                id={
                  showAddButton
                    ? 'bankReconciliationLookupTraverseTree'
                    : 'bankReconciliationLookupTraverseTreeNoAdd'
                }
                checked={reqParams.updateDepth && reqParams.traverseTree}
                onCheck={value => {
                  setReqParams({
                    ...reqParams,
                    traverseTree: value,
                    updateDepth: value,
                  })
                }}
              />
              <label
                htmlFor={
                  showAddButton
                    ? 'bankReconciliationLookupTraverseTree'
                    : 'bankReconciliationLookupTraverseTreeNoAdd'
                }
                className="text-capitalize me-2"
              >
                {localize('traverseTree')}
              </label>
            </div>
            <div className="d-flex col-md-4 align-items-center justify-content-end offset-5">
              <div className="d-flex align-items-center">
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchPeriods()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchPeriods()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <SimpleTable
              columns={columns}
              data={periods}
              canClickOnce={canClickOnce}
              onClickOnce={() => {}}
              canSelect={canSelect}
              onSelect={r => handleClickOnce(r)}
              contextMenu={r =>
                showContextMenu && (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectPeriod(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )
              }
            />
          </div>
        </>
      )}
    </>
  )
}

export default ListBankReconciliation
