/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import { SupplyCategories } from './SupplyCategories'
import { SupplierTypeLookup } from '../../../../../../Components/Lookup/SupplierTypeLookup'
import { getData } from '../../../../../../Helpers/webApi'
import { entitiesToBeChecked } from '../../../../../../Helpers/selectablesHelper'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CreateSupplierModal = ({
  supplierModel,
  setSupplierModel,
  isBusy,
}) => {
  const [supplyCategories, setSupplyCategories] = useState([])

  const fetchSupplyCategories = async () => {
    const { success, data } = await getData(
      '/lookup/SupplierType/find-supplier-categories',
      {},
      false
    )
    if (success) {
      setSupplyCategories(entitiesToBeChecked(data.result, []))
    }
  }

  useEffect(() => {
    fetchSupplyCategories()
  }, [])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.description}
                onChange={e =>
                  setSupplierModel({
                    ...supplierModel,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('supplierType')}
              </label>
              <SupplierTypeLookup
                displayValue={supplierModel?.supplierTypeDescription}
                onSelect={e => {
                  setSupplierModel({
                    ...supplierModel,
                    supplierTypeId: e.id,
                    supplierTypeDescription: e.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('addressLine1')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressAddressLine1}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressAddressLine1: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('addressLine2')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressAddressLine2}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressAddressLine2: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('physicalAddress')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressStreet}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressStreet: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('postalCode')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressPostalCode}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressPostalCode: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('city')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressCity}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressCity: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressEmail}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressEmail: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('landLine')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressLandLine}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressLandLine: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('mobileLine')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.addressMobileLine}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    addressMobileLine: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('creditPeriod')}
              </label>
              <input
                className="form-control"
                type="number"
                value={supplierModel?.creditPeriod}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    creditPeriod: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                value={supplierModel?.remarks}
                onChange={e => {
                  setSupplierModel({
                    ...supplierModel,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mt-12">
            <Tab
              tabItems={[
                {
                  label: localize('supplyCategories'),
                  item: (
                    <SupplyCategories
                      supplyCategoriesToSelect={supplyCategories}
                      prefix={'sup'}
                      isBusy={isBusy}
                      onCheckSupplyCategory={e => {
                        setSupplyCategories(e)
                        setSupplierModel({
                          ...supplierModel,
                          selectedSupplyCategories: e.filter(c => c.checked),
                        })
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  )
}
