/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../Global/Localize'
import { getData, postData, putData } from '../../Helpers/webApi'
import { formatDate } from '../../Helpers/formatters'
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Checkbox from '../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../Validation/ValidationState'
import { MaskNumber } from '../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import Loader from '../../Components/Loaders/Loader'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import SimpleTable from '../../Components/Tables/SimpleTable'
import Pager from '../../Components/Tables/Pager'
import AddNewButton from '../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import NonBlockingLoader from '../../Components/Loaders/NonBlockingLoader'

function Currencies() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('symbol'),
      template: r => <>{r?.symbol}</>,
      sortBy: 'symbol',
    },
    { label: localize('isoCode'), template: r => <>{r?.isoCode}</> },
    {
      label: localize('isoNumber'),
      template: r => <>{r?.isoNumber}</>,
      sortBy: 'isoNumber',
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdBy',
    },
  ]
  const [mode, setMode] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [showError, setShowError] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/currency/update',
            selectedCurrency || {
              description: '',
            },
            false
          )
        : await postData(
            '/currency/create',
            selectedCurrency || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedCurrency(null)
      loadTableData()
      showNotification('Currency', 'success')
    }
    setIsBusy(false)
  }

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/currency/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  const handleAdd = async () => {
    setMode('add')
    setSelectedCurrency(null)
  }

  function closeModal() {
    setMode(null)
    setSelectedCurrency(null)
    setGlobalValidationErrors({})
    setShowError(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleViewAuditLogs = item => {
    setSelectedCurrency(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('currencies')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={selectedCurrency}
          title={selectedCurrency?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton
                      handleClickAddButton={() => {
                        handleAdd()
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ position: 'relative' }}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>

                  <div className="d-flex align-item-center gap-2">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setRefresh(!refresh)
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                            pageIndex: 0,
                          })
                        }
                      />
                    </form>
                    <button
                      id="currencyFilterButton"
                      className="btn btn-primary ms-1"
                      onClick={() => setRefresh(!refresh)}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="row col-12 mt-2">
                  <SimpleTable
                    data={tableData}
                    columns={columns}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            setMode('edit')
                            setSelectedCurrency(r)
                          }}
                        >
                          <i className="uil uil-edit-alt"></i>
                          {localize('edit')}
                        </div>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleViewAuditLogs(r)
                          }}
                        >
                          <i className="uil uil-server"></i>
                          <span>{localize('viewAuditLog')}</span>
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="row col-12 mt-2">
                  <Pager
                    {...tableMeta}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
                {isBusy && <NonBlockingLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(mode === 'edit' || mode === 'add') && (
        <Modal isOpen className="modal-lg" backdrop={true} keyboard>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('currencies')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {isBusy ? (
                <Loader />
              ) : (
                <Row>
                  <Col>
                    <div
                      className="with-validation d-flex align-items-center gap-2 mb-2"
                      validation-message={
                        globalValidationErrors['Description']?.message
                      }
                    >
                      <label htmlFor="cName" className="col-2 text-capitalize">
                        {localize('name')}
                      </label>
                      <input
                        defaultValue={selectedCurrency?.description}
                        type="text"
                        className="form-control"
                        onChange={e => {
                          clearValidation('Description')
                          setSelectedCurrency({
                            ...selectedCurrency,
                            description: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      className="with-validation d-flex align-items-center gap-2"
                      validation-message={
                        globalValidationErrors['ISOCode']?.message
                      }
                    >
                      <label
                        htmlFor="cIsoCode"
                        className="col-2 text-capitalize"
                      >
                        {localize('isoCode')}
                      </label>
                      <input
                        defaultValue={selectedCurrency?.isoCode}
                        type="text"
                        className="form-control"
                        onChange={e => {
                          clearValidation('ISOCode')
                          setSelectedCurrency({
                            ...selectedCurrency,
                            isoCode: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="with-validation d-flex align-items-center gap-2 mb-2"
                      validation-message={
                        globalValidationErrors['Symbol']?.message
                      }
                    >
                      <label htmlFor="cName" className="col-2 text-capitalize">
                        {localize('symbol')}
                      </label>
                      <input
                        defaultValue={selectedCurrency?.symbol}
                        type="text"
                        className="form-control"
                        onChange={e => {
                          clearValidation('Symbol')
                          setSelectedCurrency({
                            ...selectedCurrency,
                            symbol: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <label
                        htmlFor="cIsoCode"
                        className="col-2 text-capitalize"
                      >
                        {localize('isoNumber')}
                      </label>
                      <MaskNumber
                        defaultMaskValue={selectedCurrency?.isoNumber}
                        className={`
                       ${showError && 'border border-danger'}
                      `}
                        onMaskNumber={e => {
                          if (Number(e.target.value) > 100) {
                            setShowError(true)
                          } else {
                            setShowError(false)
                          }
                          setSelectedCurrency({
                            ...selectedCurrency,
                            isoNumber: e.target.value,
                          })
                        }}
                        numeralThousandsGroupStyle="none"
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter className="bg-white">
              <Checkbox
                id="editCurrenciesIsLockedCheckbox"
                margined
                checked={selectedCurrency?.isLocked}
                onCheck={e =>
                  setSelectedCurrency({
                    ...selectedCurrency,
                    isLocked: e,
                  })
                }
              />
              <label
                htmlFor="editCurrenciesIsLockedCheckbox"
                className="text-capitalize me-2 mb-3"
              >
                {localize('isLocked')}?
              </label>
              <button
                className="btn btn-danger text-capitalize"
                onClick={closeModal}
              >
                {localize('cancel')}
              </button>
              <button
                className="btn btn-success text-capitalize"
                onClick={handleSubmit}
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}

export default Currencies
