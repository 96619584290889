/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../../Components/Loaders/Loader'
import FIleSelectLookup from '../../../../../../../Components/Lookup/FIleSelectLookup'
import MorguePatientLookup from '../../../../../../../Components/Lookup/MorguePatientLookup'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import { toBase64 } from '../../../../../../../Helpers/blobs'
import { getData, postData } from '../../../../../../../Helpers/webApi'

const AddDocument = ({ mode, handleClose, fetchDocuments, documentData }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [document, setDocument] = useState({})
  const [patient, setPatient] = useState({})
  const [file, setFile] = useState({})

  const handleSelectPatient = data => {
    if (data?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected patient is locked'
      )

    setPatient(data)
  }

  const fetchPatient = async () => {
    setIsBusy(true)

    const url = '/MorguePatient/find-morgue-patient'
  }

  const handleFileLookup = async e => {
    const file = e.target.files[0]

    let base64File = await toBase64(file)
    let buffer = base64File.split(',')[1].toString()

    setFile({
      name: file.name,
      buffer,
    })
  }

  const handleCreateDocument = async () => {
    if (!patient?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a patient'
      )

    setIsBusy(true)

    if (!file?.name)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a file'
      )

    const url = '/Base64/media-upload-base-64'

    const { success } = await postData(
      url,
      {
        fileName: file.name,
        buffer: file?.buffer,
      },
      false
    )

    if (success) {
      const url = '/Base64/file-upload-done'
      const { data } = await getData(
        url,
        {
          fileName: file.name,
        },
        false
      )

      if (data?.succeeded) {
        const url = '/MorguePatientDocument/add-morgue-patient'

        const { success, data: result } = await postData(
          url,
          {
            morguePatientId: patient?.id,
            fileName: data?.fileName,
            fileBuffer: file?.buffer,
            fileTitle: document?.fileTitle,
            fileDescription: document?.fileDescription,
            fileMIMEType: data?.fileMIMEType,
            morguePatientFullName: patient?.fullName,
            ...patient,
          },
          false
        )

        if (success) {
          const url = '/Base64/post-file'
          const { success } = await postData(
            url,
            {
              sku: result?.id,
              fileName: data?.fileName,
              fileType: 'MorguePatientDocument',
              fileRemarks: document?.fileTitle,
            },
            false
          )

          if (success) {
            showNotification('Add Morgue Patient Document', 'success')
            fetchDocuments()
            handleClose()
            setDocument({})
            setPatient({})
            setFile({})
          }
        }
      }
    }

    setIsBusy(false)
  }
  const handleEditDocument = async () => {
    setIsBusy(true)

    if (file?.name) {
      const url = '/Base64/media-upload-base-64'

      const { success } = await postData(
        url,
        {
          fileName: file.name,
          buffer: file?.buffer,
        },
        false
      )
      if (success) {
        const url = '/Base64/file-upload-done'
        const { data } = await getData(
          url,
          {
            fileName: file.name,
          },
          false
        )

        if (data?.succeeded) {
          const url = '/MorguePatientDocument/update-morgue-patient'

          const { success, data: result } = await postData(
            url,
            {
              morguePatientId: patient?.id,
              fileName: data?.fileName,
              fileBuffer: file?.buffer,
              fileTitle: document?.fileTitle,
              fileDescription: document?.fileDescription,
              fileMIMEType: data?.fileMIMEType,
              morguePatientFullName: patient?.fullName,
              ...patient,
            },
            false
          )

          if (success) {
            const url = '/Base64/post-file'
            const { success } = await postData(
              url,
              {
                sku: result?.id,
                fileName: data?.fileName,
                fileType: 'MorguePatientDocument',
                fileRemarks: document?.fileTitle,
              },
              false
            )

            if (success) {
              showNotification('Update morgue patient document', 'success')
              fetchDocuments()
              handleClose()
              setDocument({})
              setPatient({})
              setFile({})
            }
          }
        }
      }
    } else {
      const url = '/MorguePatientDocument/update-morgue-patient'

      const { success } = await postData(
        url,
        {
          ...documentData,
          morguePatientId: patient?.id,
          fileName: documentData?.fileName,
          fileBuffer: documentData?.buffer,
          fileTitle: document?.fileTitle || documentData?.fileTitle,
          fileDescription:
            document?.fileDescription || documentData?.fileDescription,
          fileMIMEType: documentData?.fileMIMEType,
          ...patient,
        },
        false
      )

      if (success) {
        showNotification('Update morgue patient document', 'success')
        fetchDocuments()
        handleClose()
        setDocument({})
        setPatient({})
        setFile({})
      }
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (documentData?.id) setDocument(documentData)
    setPatient({
      id: documentData?.morguePatientId,
      fullName: documentData?.morguePatientFullName,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData?.id])

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      handleAction={mode === 'add' ? handleCreateDocument : handleEditDocument}
      actionText={mode === 'add' ? 'create' : 'update'}
      handleClose={handleClose}
      modalMode={mode}
      modalTitle={mode === 'add' ? 'addDocument' : 'editDocument'}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('morguePatient')}
            </label>
            <MorguePatientLookup
              displayValue={patient?.fullName}
              onSelect={handleSelectPatient}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('document')}
            </label>
            <FIleSelectLookup
              displayValue={file?.name || documentData?.fileName}
              onSelect={handleFileLookup}
              accept=".pdf"
            />
          </div>

          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">{localize('title')}</label>
            <input
              type="text"
              className="form-control"
              value={document?.fileTitle}
              onChange={e =>
                setDocument({ ...document, fileTitle: e.target.value })
              }
            />
          </div>
          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('description')}
            </label>
            <textarea
              className="form-control"
              value={document?.fileDescription}
              onChange={e =>
                setDocument({ ...document, fileDescription: e.target.value })
              }
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AddDocument
