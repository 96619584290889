/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData, postData } from '../../../../../Helpers/webApi'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import AddLoanRequest from './AddLoanRequest'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import ViewLoanRequest from './ViewLoanRequest'
import Swal from 'sweetalert2'

const LoanRequests = () => {
  const initialParams = {
    pageIndex: 0,
    pageSize: 10,
    startDate: getTodaysDate(),
    endDate: getTodaysDate(),
    filterText: '',
    loanRequestFilter: 0,
  }

  const [modalMode, setModalMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [requests, setRequests] = useState([])
  const [error, setError] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState(initialParams)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [groupBy, setGroupBy] = useState({
    label: 'Product',
    value: 0,
    column: 'loanProductDescription',
  })

  const groupingColumns = [
    { value: 0, column: 'loanProductDescription' },
    { value: 1, column: 'loanPurposeDescription' },
    { value: 2, column: 'statusDescription' },
    { value: 3, column: 'originDescription' },
    { value: 4, column: 'ipnStatusDescription' },
    {
      value: 5,
      column: 'loanProductLoanRegistrationLoanProductCategoryDescription',
    },
    {
      value: 6,
      column: 'loanProductLoanRegistrationLoanProductSectionDescription',
    },
    { value: 7, column: 'customerEmployerDescription' },
  ]

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('origin'), template: r => <>{r?.originDescription}</> },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.formattedCustomerAccountNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanPurposeDescription}</>,
    },
    {
      label: localize('receivedDate'),
      template: r => <>{formatDate(r?.receivedDate)}</>,
    },
    {
      label: localize('amountApplied'),
      template: r => <>{formatCurrency(r?.amountApplied)}</>,
    },
    {
      label: localize('disbursedAmount') + ` (${localize('net')})`,
      template: r => <>{formatCurrency(r?.netDisbursedAmount)}</>,
    },
    {
      label: `${localize('term')} (${localize('months')})`,
      template: r => (
        <div style={{ textAlign: 'center' }}>{r?.termInMonths}</div>
      ),
    },
    {
      label: localize('annualPercentageRate'),
      template: r => (
        <div style={{ textAlign: 'center' }}>
          {formatAsPercent(r?.annualPercentageRate)}
        </div>
      ),
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: `${localize('Ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('Ref')}_2 (${localize('account')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: `${localize('Ref')}_3 (${localize('account')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('registeredBy'),
      template: r => <>{r?.registeredBy}</>,
    },
    {
      label: localize('registrationDate'),
      template: r => <>{formatDate(r?.registeredDate)}</>,
    },
    {
      label: localize('cancelledBy'),
      template: r => <>{r?.cancelledBy}</>,
    },

    {
      label: localize('cancellationRemarks'),
      template: r => <>{r?.cancellationRemarks}</>,
    },
    {
      label: localize('ipnEnabled'),
      template: r => (
        <span className="text-capitalize">{r?.ipnEnabled.toString()}</span>
      ),
    },
    {
      label: localize('ipnStatus'),
      template: r => <>{r?.ipnStatusDescription}</>,
    },
    {
      label: localize('ipnResponse'),
      template: r => <>{r?.ipnResponse}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  useEffect(() => {
    loadRequests()
    // eslint-disable-next-line
  }, [reqParams?.pageSize, reqParams?.pageIndex, refresh])

  async function loadRequests() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanRequest/find-loan-requests-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setError(null)
      setRequests(data)
    } else {
      setError(data)
    }
    setIsBusy(false)
  }

  async function activateLoanRequest(id) {
    setIsBusy(true)
    const { success, data } = await postData(
      '/loanRequest/activate-loan-request?loanRequestId=' + id,
      id,
      false
    )
    setRefresh(!refresh)
    if (success) {
      Swal.fire('Operation Successful', 'Request Activated', 'success')
    }
    setIsBusy(false)
  }
  async function cancelLoanRequest(id) {
    setIsBusy(true)
    const { success, data } = await postData(
      '/loanRequest/cancel-loan-request?loanRequestId=' + id,
      id,
      false
    )
    setRefresh(!refresh)
    if (success) {
      Swal.fire('Operation Successful', 'Request Rejected', 'success')
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanRequests'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          {isBusy ? (
            <div className="d-flex align-items-center justify-content-center p-5">
              <div className="d-flex align-items-center">
                <i className="m-3 spinner-border spinner-sm text-black"></i>
                <span className="text-capitalize fs-4">
                  {localize('pleaseWait')}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-3">
                <button
                  className="btn btn-success px-5"
                  onClick={() => setModalMode('add')}
                >
                  {localize('receive')}
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor=""
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    name=""
                    id=""
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    htmlFor=""
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={groupBy?.value}
                    enumsKey="loanRequestPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    htmlFor=""
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    htmlFor=""
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.loanRequestFilter}
                    enumsKey="loanRequestFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, loanRequestFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setRefresh(!refresh)
                      setReqParams({ ...reqParams, pageIndex: 0 })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={requests?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div className="ctxt-menu-item">
                      <i className="uil uil-file"></i>
                      <span className="text-capitalize">
                        {localize('viewFile')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedRequest(r)
                        setModalMode('view')
                      }}
                    >
                      <i className="uil uil-search-alt"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>

                    <div
                      className="ctxt-menu-item text-danger fw-bold"
                      onClick={() => cancelLoanRequest(r?.id)}
                    >
                      <i className="uil uil-times-circle"></i>
                      <span className="text-capitalize">
                        {localize('reject')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item text-success fw-bold"
                      onClick={() => activateLoanRequest(r?.id)}
                    >
                      <i className="uil uil-sync"></i>
                      <span className="text-capitalize">
                        {localize('reOpen')}
                      </span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={requests?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {modalMode === 'add' && (
        <ModalTemplate
          modalTitle={localize('loanRequest')}
          modalMode={localize('receive')}
          modalClassName="modal-xl"
          handleClose={() => setModalMode(null)}
        >
          <AddLoanRequest />
        </ModalTemplate>
      )}

      {modalMode === 'view' && selectedRequest && (
        <ModalTemplate
          modalTitle={localize('loanRequest')}
          modalMode={localize('view')}
          modalClassName="modal-xl"
          handleClose={() => {
            setModalMode(null)
            setSelectedRequest(null)
          }}
          hideUpdate
        >
          <ViewLoanRequest loanRequest={selectedRequest} />
        </ModalTemplate>
      )}
    </>
  )
}

export default LoanRequests
