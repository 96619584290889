/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import ViewSystemTransaction from '../SystemTransactions/ViewSystemTransaction/ViewSystemTransaction'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function SystemTransactions() {
  const [isBusy, setIsBusy] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [transaction, setTransaction] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    journalEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [groupBy, setGroupBy] = useState({
    label: localize('branch'),
    column: 'branchDescription',
    value: 0,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('transactionDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('primaryDescription'),
      template: r => r?.primaryDescription,
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r?.totalValue),
    },
    {
      label: localize('secondaryDescription'),
      template: r => r?.secondaryDescription,
    },
    {
      label: localize('valueDate'),
      template: r => formatDate(r?.valueDate, true),
    },
    {
      label: localize('localCurrency'),
      template: r => r?.localCurrencyDescription,
    },
    {
      label: localize('applicationUserName'),
      template: r => r?.applicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r?.environmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r?.environmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r?.environmentDomainName,
    },
    {
      label: localize('environmentOperatingSystemVersion'),
      template: r => r?.environmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r?.environmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r?.environmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r?.environmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r?.environmentIPAddress,
    },
    {
      label: localize('integrityHash'),
      template: r => r?.integrityHash,
    },
  ]

  const groupingColumns = [
    {
      column: 'branchDescription',
      value: 0,
    },
    {
      column: 'postingPeriodDescription',
      value: 1,
    },
    {
      column: 'applicationUserName',
      value: 2,
    },
    {
      column: 'environmentUserName',
      value: 3,
    },
    {
      column: 'environmentMachineName',
      value: 4,
    },
    {
      column: 'environmentDomainName',
      value: 5,
    },
    {
      column: 'environmentOSVersion',
      value: 6,
    },
    {
      column: 'environmentMACAddress',
      value: 7,
    },
    {
      column: 'environmentMotherboardSerialNumber',
      value: 8,
    },
    {
      column: 'environmentProcessorId',
      value: 9,
    },
    {
      column: 'environmentIPAddress',
      value: 10,
    },
  ]

  const fetchTransactions = async () => {
    setIsBusy(true)
    const url = '/Journal/find-journal-archives-by-date-range-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setTransactions(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectTransaction = (transaction, mode) => {
    setTransaction(transaction)
    setModalMode(mode)
  }

  const handleClose = () => {
    setModalMode(null)
    setTransaction({})
  }

  useEffect(
    () => {
      fetchTransactions()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reqParams.pageIndex, reqParams.pageSize, reqParams.transactionCode]
  )

  return (
    <>
      {modalMode === 'view' && (
        <ViewSystemTransaction
          transaction={transaction}
          handleClose={handleClose}
          mode={modalMode}
          title="journalEntriesArchived"
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('transactionsJournal'),
          localize('systemTransactionsArchived'),
        ]}
      />
      <div className="card bg-white rounded relative">
        <div className="card-body">
          <div className="row g-3 mb-3 d-flex justify-content-between align-items-center">
            <RecordsPerPage
              reqParams={reqParams}
              setReqParams={setReqParams}
              className="col-md-3"
            />
            <div className="col-md-2 d-flex align-items-center">
              <label className="text-capitalize me-1" htmlFor="groupBy">
                {localize('groupBy')}
              </label>

              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                value={groupBy?.value}
                enumsKey="JournalPropertyGroup"
                onChange={({ label, value }) =>
                  setGroupBy({
                    label,
                    value,
                    column: groupingColumns[value]?.column,
                  })
                }
              />
            </div>
            <div className="col-md-3 d-flex align-items-center gap-1">
              <label htmlFor="search" className="text-capitalize">
                {localize('dateRange')}
              </label>
              <DatePicker
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center gap-2 justify-content-end">
              <div className="d-flex align-items-center gap-1">
                <label className="text-capitalize" htmlFor="systemTransactions">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    minWidth: 150,
                    maxWidth: 150,
                  }}
                  enumsKey="JournalEntryFilter"
                  value={reqParams.journalEntryFilter}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      journalEntryFilter: e.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="search"
                  id="systemTransactions"
                  className="form-control ms-1"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                      pageIndex: 0,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchTransactions()
                    }
                  }}
                />
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchTransactions()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <GroupedTable
              groupBy={groupBy}
              columns={columns}
              data={transactions.pageCollection}
              canClickOnce
              onClickOnce={() => {}}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectTransaction(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                </>
              )}
            />
          </div>
          <Pager
            itemsCount={transactions.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
    </>
  )
}

export default SystemTransactions
