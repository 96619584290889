/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatCurrency, formatDate } from '../../Helpers/formatters'

const InHouseChequeLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('inHouseChequeLookup')}
      url="/lookup/InHouseCheque/find-unprinted-in-house-cheques-by-branch-id-and-filter-in-page"
      params={{ text: '', pageIndex: 0, pageSize: 10 }}
      columns={[
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        {
          label: localize('chequeLeaveNumber'),
          template: r => <>{r.paddedBankersChequeLeafLeafNumber}</>,
        },
        {
          label: localize('chequeBookSerialNumber'),
          template: r => (
            <>{r.paddedBankersChequeLeafBankersChequeBookSerialNumber}</>
          ),
        },
        {
          label: localize('sourceOfFunds'),
          template: r => <>{r.fundingDescription}</>,
        },
        {
          label: localize('chartOfAccountName'),
          template: r => <>{r.debitChartOfAccountName}</>,
        },
        {
          label: localize('fullAccountNumber'),
          template: r => <>{r.debitCustomerAccountFullAccountNumber}</>,
        },
        {
          label: localize('customerName'),
          template: r => <>{r.debitCustomerAccountCustomerFullName}</>,
        },
        {
          label: localize('payee'),
          template: r => <>{r.payee}</>,
        },
        {
          label: localize('amount'),
          template: r => <>{formatCurrency(r.amount)}</>,
        },
        {
          label: localize('amountInWords'),
          template: r => <>{r.wordifiedAmount}</>,
        },
        {
          label: localize('paddedAmount'),
          template: r => <>{r.paddedAmount}</>,
        },
        {
          label: localize('reference'),
          template: r => <>{r.reference}</>,
        },
        {
          label: localize('chargeable') + ' ?',
          template: r => r.chargeable?.toString(),
        },
        {
          label: localize('isPrinted') + ' ?',
          template: r => r.isPrinted?.toString(),
        },
        {
          label: localize('printedNumber'),
          template: r => <>{r.printedNumber}</>,
        },
        {
          label: localize('printedBy'),
          template: r => <>{r.printedBy}</>,
        },
        {
          label: localize('printedDate'),
          template: r => <>{formatDate(r.printedDate)}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default InHouseChequeLookup
