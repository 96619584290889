/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../../Helpers/webApi'

function ExistingGuarantors({ loanCase }) {
  const [guarantors, setGuarantors] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    findLoanGuarantors()
    // eslint-disable-next-line
  }, [])

  async function findLoanGuarantors() {
    setFetching(true)
    const { success, data } = await getData(
      '/loanCase/find-loan-guarantors-by-loanee-customer-id',
      {
        loanCaseId: loanCase?.id,
        loaneeCustomerId: loanCase?.customerId,
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      setFetching(false)
      setGuarantors(
        data?.result?.filter(x => x.loanCaseId !== loanCase?.id) || []
      )
    } else {
      setFetching(false)
    }
  }

  const columns = [
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r?.loanCaseStatusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedLoaneeCustomerMemberNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r?.totalShares)}</>,
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r?.committedShares)}</>,
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r?.amountGuaranteed)}</>,
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r?.amountPledged)}</>,
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => <>{formatCurrency(r?.loanPrincipalBalanceOutstanding)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r?.loanClassificationDescription}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r?.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r?.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r?.loanDefaultAmount)}</>,
    },
    {
      label: `${localize('loanArrearsTimeline')} (${localize('days ')})`,
      template: r => <>{r?.loanDefaultTimeline}</>,
    },
    {
      label: `${localize('loanLossProvision')} (${localize('percentage ')})`,
      template: r => <>{r?.loanLossProvisionPercentage}</>,
    },
    {
      label: `${localize('loanLossProvision')} (${localize('amount ')})`,
      template: r => <>{r?.loanLossProvisionAmount}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r?.loanLastPaymentDueDate}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r?.loanCaseBranchDescription}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanCaseLoanPurposeDescription}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => (
        <div style={{ textAlign: 'center' }}>{r?.appraisalFactor}</div>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <SimpleTable columns={columns} data={guarantors} />
    </>
  )
}

export default ExistingGuarantors
