/** @format */

import React, { useState, useEffect } from 'react'
import CustomerLookupPartial from '../../../../../Components/ReUsables/Customer/CustomerLookupPartial'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import AddCustomer from '../../../../Registry/Members/Partials/Customers/AddCustomer'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'

function GuarantorsPartial({
  loanCaseId,
  loaneeCustomerId,
  guarantorSecurityMode,
  guarantorSecurityModeDescription,
  loanProductId,
  guarantors,
  totalGuaranteed,
  onChangeGuarantors,
  reloadGuarantors,
}) {
  const [customer, setCustomer] = useState(null)
  const [viewedCustomer, setViewedCustomer] = useState(null)
  const [currentGuarantors, setCurrentGuarantors] = useState([])
  const [commitments, setCommitments] = useState(null)
  const [selectedGuarantors, setSelectedGuarantors] = useState([])
  const [validationResult, setValidationResult] = useState({
    result: false,
    messages: null,
  })

  useEffect(() => {
    setCurrentGuarantors(guarantors)
  }, [guarantors])

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedGuarantors.find(x => x.id === r.id)}
          onCheck={() => {
            let isChecked = !!selectedGuarantors.find(x => x.id === r.id)
            if (isChecked) {
              setSelectedGuarantors(
                [...selectedGuarantors].filter(x => x.id !== r.id)
              )
            } else {
              setSelectedGuarantors([...selectedGuarantors, r])
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    { label: localize('customerName'), template: r => r?.customerFullName },
    {
      label: localize('membershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('identificationNumber'),
      template: r => r?.customerIdentificationNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanProductDescription,
    },
    {
      label: localize('loanCaseApprovedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => formatCurrency(r?.loanBalanceOutstanding),
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => r?.loanDefaultAmount,
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment),
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanBranch'),
      template: r => r?.loanCaseBranchDescription,
    },
    {
      label: localize('totalShares'),
      template: r => formatCurrency(r?.totalShares),
    },
    {
      label: localize('committedShares'),
      template: r => formatCurrency(r?.committedShares),
    },
    {
      label: localize('amountGuaranteed'),
      template: r => formatCurrency(r?.amountGuaranteed),
    },
    {
      label: localize('amountPledged'),
      template: r => formatCurrency(r?.amountPledged),
    },
    { label: localize('appraisalFactor'), template: r => r?.appraisalFactor },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  async function handleRemoveGuarantors() {
    if (Array.isArray(selectedGuarantors) && selectedGuarantors?.length > 0) {
      let notSelected = guarantors.filter(
        x => !selectedGuarantors.map(s => s.id).includes(x.id)
      )
      const { success, data } = await putData(
        '/loanOrigination/update-loan-guarantors-by-loan-case-id',
        {
          loanGuarantors: notSelected,
          loanCaseId,
        }
      )
      if (success) {
        setSelectedGuarantors([])
      }
    } else {
      Swal.fire('Error', 'Please Select a Guarantor First', 'error')
    }
    reloadGuarantors()
  }

  async function handleAddGuarantors() {
    if (commitments) {
      const { success, data } = await postData(
        '/loanOrigination/add-loan-case-guarantor',
        { ...commitments, loanCaseId }
      )
      if (success) {
        setCommitments({ amountGuaranteed: 0, amountPledged: 0 })
        setCustomer(null)
      }
    } else {
      Swal.fire('Error', 'Please Select a Guarantor First', 'error')
    }
    reloadGuarantors()
  }

  async function validateLoanGuarantor(customerId) {
    setCustomer(null)
    const { success, data } = await postData(
      '/loanOrigination/validate-loan-guarantor',
      {
        guarantorCustomerId: customerId,
        loaneeCustomerId,
        loanProductId,
        loanCaseId,
        loanGuarantors: guarantors,
      },
      true,
      null,
      false
    )
    if (success) {
      setValidationResult(data)
      return data?.result
    }
    return false
  }

  useEffect(() => {
    loaneeCustomerId &&
      customer?.id &&
      loanProductId &&
      evaluateGuarantorCommitments()
    // eslint-disable-next-line
  }, [loaneeCustomerId, customer?.id, loanProductId])

  async function evaluateGuarantorCommitments() {
    const { success, data } = await getData(
      '/loanOrigination/evaluate-guarantor-commitments',
      {
        loanProductId,
        loaneeCustomerId,
        customerId: customer?.id,
        guarantorSecurityMode,
      },
      false
    )
    if (success) {
      setCommitments(data)
      onChangeGuarantors()
    }
  }

  // load the guarantors

  return (
    <>
      <CustomerLookupPartial
        customer={customer}
        onLookup={async c => {
          let proceed = await validateLoanGuarantor(c?.id)
          if (proceed) setCustomer(c)
          else {
            setCommitments(null)
          }
        }}
      />
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('currentGuarantees')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(commitments?.currentGuarantees, 2, 2, false)}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('balancesGuaranteed')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              commitments?.guaranteedOutstandingBalances,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('appraisalFactor')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={commitments?.appraisalFactor}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('guarantorSecurityMode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={guarantorSecurityModeDescription}
          />
        </div>
      </div>
      {guarantorSecurityMode == 1 && (
        <div className="row mt-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('totalShares')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={formatCurrency(commitments?.totalShares, 2, 2, false)}
              style={{ textAlign: 'end' }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('committedShares')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={formatCurrency(commitments?.committedShares, 2, 2, false)}
              style={{ textAlign: 'end' }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('amountGuaranteed')}
            </label>
            <MaskNumber
              defaultMaskValue={commitments?.amountGuaranteed || 0}
              type="text"
              className="form-control"
              disabled={!commitments}
              useBlur
              onMaskNumber={() => {}}
              onBlur={e => {
                let val = stripNonNumericCharacters(e.target.value)
                setCommitments({ ...commitments, amountGuaranteed: val })
              }}
            />
          </div>
          <div className="col d-flex align-items-center"></div>
        </div>
      )}
      {guarantorSecurityMode == 0 && (
        <div className="row mt-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('amountPledged')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
            />
          </div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
      )}
      {commitments && (
        <div className="rounded my-2 bg-light d-flex align-items-center justify-content-end p-2">
          <button
            onClick={handleAddGuarantors}
            className="btn btn-success px-5"
          >
            {localize('add')}
          </button>
        </div>
      )}
      <div
        className="mb-3 mt-4 p-2 border rounded border-secondary"
        style={{ position: 'relative' }}
      >
        <div
          className="bg-white text-capitalize px-2"
          style={{ position: 'absolute', left: 20, top: -13 }}
        >
          {localize('loanGuarantors')}
        </div>
        <SimpleTable
          columns={columns}
          data={guarantors}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item"
                onClick={() => setViewedCustomer({ id: r?.customerId })}
              >
                <i className="uil uil-eye"></i>
                <span className="text-capitalize">{localize('view')}</span>
              </div>
            </>
          )}
        />
        <div className="p-2 mt-1 rounded bg-light d-flex align-items-center justify-content-end gap-2">
          <label htmlFor="" className="text-capitalize">
            {localize('totalGuaranteed')}
          </label>
          <input
            type="text"
            className="form-control"
            style={{ width: '18rem', textAlign: 'end' }}
            disabled
            value={formatCurrency(totalGuaranteed, 2, 2, false)}
          />
          {selectedGuarantors?.length > 0 && (
            <button
              onClick={handleRemoveGuarantors}
              className="btn btn-danger px-5"
            >
              {localize('remove')} ({selectedGuarantors?.length})
            </button>
          )}
        </div>
      </div>
      {viewedCustomer && (
        <AddCustomer
          refreshFromServer
          readOnly={true}
          mode={'view'}
          customer={viewedCustomer}
          closeModal={() => setViewedCustomer(null)}
          onMinimize={() => {}}
        />
      )}
      {validationResult?.messages &&
        Array.isArray(validationResult?.messages) &&
        validationResult?.messages.length > 0 && (
          <>
            <ActionModal
              modalTitle={localize(
                validationResult?.result
                  ? 'customerValidation'
                  : 'customerValidationFailed'
              )}
              hideCloseButton
              confirmText={localize('ok')}
              handleAction={() => {
                setValidationResult({ ...validationResult, messages: [] })
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <i
                  className={`uil uil-exclamation-circle fs-1 text-${
                    validationResult?.result ? 'primary' : 'danger'
                  }`}
                ></i>
                <ul>
                  {validationResult?.messages.map(m => (
                    <li>{m}</li>
                  ))}
                </ul>
              </div>
            </ActionModal>
          </>
        )}
    </>
  )
}

export default GuarantorsPartial
