/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import ListingPage from '../../../Components/ListingPage/Index'
import RolesModal from './Partials/RolesModal'
import { getData } from '../../../Helpers/webApi'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Roles() {
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)
  const [modulesAccessControlList, setModulesAccessControlList] = useState([])
  const [operationsAccessControlList, setOperationsAccessControlList] =
    useState([])

  const [usersInRoleList, setUsersInRoleList] = useState([])
  const fetchOperationsAcl = async r => {
    const { success, data } = await getData(
      '/SystemPermissionTypeInRole/find-system-permission-types-in-role-by-role-id',
      { roleId: r.id }
    )
    if (success) {
      setOperationsAccessControlList(data.result)
    }
  }

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  const fetchUsersInRole = async r => {
    const { success, data } = await getData(
      '/User/find-employees-by-role-id',
      {
        roleId: r.id,
        ...reqParams,
      }
    )
    if (success) {
      setUsersInRoleList(data)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
  }

  useEffect(() => {
    if (selectedRole) {
      fetchUsersInRole(selectedRole)
    }
  }, [reqParams, refreshAt])

  const fetchModulesAcl = async r => {
    const { success, data } = await getData('/lookup/Role/get-menus-for-role', {
      roleId: r.id,
    })
    if (success) {
      setModulesAccessControlList(data)
    }
  }

  const handleOnViewUserRoleRelationship = async r => {
    setMode('view')
    setSelectedRole(r)
    fetchUsersInRole(r)
    fetchModulesAcl(r)
    fetchOperationsAcl(r)
  }

  const columns = [
    { label: localize('name'), template: r => <>{r.description}</> },
  ]

  const handleViewAuditLogs = item => {
    setSelectedRole(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <div>
      <ListingPage
        useText
        onAdd={() => {
          setMode('add')
          setSelectedRole(null)
        }}
        refreshAt={refreshAt}
        url="/lookup/Role/find-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        map={[
          localize('systemAdministration'),
          localize('security'),
          localize('roles'),
        ]}
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                handleOnViewUserRoleRelationship(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleViewAuditLogs(r)
              }}
            >
              <i className="uil uil-server"></i>
              <span>{localize('viewAuditLog')}</span>
            </div>
          </>
        )}
      />
      <RolesModal
        reqParams={reqParams}
        tableMeta={tableMeta}
        setTableMeta={setTableMeta}
        setReqParams={setReqParams}
        modulesAccessControlList={modulesAccessControlList}
        operationsAccessControlList={operationsAccessControlList}
        usersInRoleList={usersInRoleList}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        mode={mode}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onSetMode={m => {
          setMode(m)
        }}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={selectedRole}
          title={selectedRole?.description}
        />
      )}
    </div>
  )
}

export default Roles
