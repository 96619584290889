/** @format */

import React, { useState } from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const PurchaseOrderItemLookup = ({
  onSelect,
  displayValue,
  purchaseOrderId,
  noteRefNumber,
}) => {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('goodsReceivedNoteLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{noteRefNumber}</>
        </>
      }
      url="/lookup/PurchaseOrder/find-purchase-order-items-by-purchase-order-id-and-filter-in-page"
      params={{
        ...reqParams,
        purchaseOrderId,
      }}
      conditionalField="purchaseOrderId"
      columns={[
        {
          label: localize('itemType'),
          template: r => <>{r.itemRegisterItemTypeDescription}</>,
        },
        {
          label: localize('item'),
          template: r => <>{r.itemRegisterDescription}</>,
        },
        {
          label: localize('inventoryCategory'),
          template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
        },
        {
          label: localize('assetType'),
          template: r => <>{r.itemRegisterAssetTypeDescription}</>,
        },
        {
          label: localize('unitOfMeasure'),
          template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
        },
        { label: localize('quantity'), template: r => <>{r.quantity}</> },
        { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
        { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default PurchaseOrderItemLookup
