/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
  parseDate,
} from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import GroupedTable from '../Tables/GroupedTable'
import Pager from '../Tables/Pager'
import Loader from '../Loaders/Loader'

function MorguePatientLookup({ displayValue, onSelect }) {
  const [reqParams, setReqParams] = useState({
    text: '',
    morguePatientFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'recordStatusDescription',
    value: 0,
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.fullName}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r?.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{parseDate(r?.individualBirthDate)}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{parseDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'recordStatusDescription' },
    { value: 1, column: 'roomTypeDescription' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'modifiedBy' },
    { value: 4, column: 'individualIdentityCardTypeDescription' },
    {
      value: 5,
      column: 'individualSalutationDescription',
    },
    {
      value: 6,
      column: 'individualGenderDescription',
    },
    {
      value: 7,
      column: 'individualNationalityDescription',
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/MorguePatient/find-morgue-patients-by-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setData(data)
      setTableData(data?.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('morguePatient')} {localize('lookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center my-2">
                    <label
                      className="text-capitalize"
                      htmlFor="morguePatientLookupPageSize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="morguePatientLookupPageSize"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageCount: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize me-2">
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 180,
                        minWidth: 180,
                      }}
                      value={groupBy?.value}
                      enumsKey="MorguePatientPropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>

                  <form
                    className="d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize me-3"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      value={reqParams?.morguePatientFilter}
                      customContainerStyle={{ width: '100%' }}
                      enumsKey={'MorguePatientFilter'}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          morguePatientFilter: e.value,
                        })
                      }}
                    />
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-3"
                      defaultValue={reqParams.text}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                        })
                      }}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="py-1">
                  <GroupedTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default MorguePatientLookup
