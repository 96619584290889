/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import BankBranchLookup from '../../../../Components/Lookup/BankBranchLookup'
import BankLookup from '../../../../Components/Lookup/BankLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function BankLinkages() {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
      sortBy: 'branchDescription',
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
      sortBy: 'chartOfAccountName',
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
      sortBy: 'chartOfAccountCostDistributionRuleDescription',
    },
    {
      label: localize('chartOfAccountCurrencyDescription'),
      template: r => <>{r?.chartOfAccountCurrencyDescription}</>,
      sortBy: 'chartOfAccountCurrencyDescription',
    },
    {
      label: localize('bankName'),
      template: r => <>{r?.bankName}</>,
      sortBy: 'bankName',
    },
    {
      label: localize('bankBranch'),
      template: r => <>{r?.bankBranchName}</>,
      sortBy: 'bankBranchName',
    },
    {
      label: localize('bankAccountNumber'),
      template: r => <>{r?.bankAccountNumber}</>,
      sortBy: 'bankAccountNumber',
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
      sortBy: 'remarks',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]
  const [selectedBankLinkage, setSelectedBankLinkage] = useState(null)
  const [mode, setMode] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/BankLinkage/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedBankLinkage(null)
  }

  function closeModal() {
    setMode(null)
    setSelectedBankLinkage(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/BankLinkage/update',
            selectedBankLinkage || { bankName: '' },
            false
          )
        : await postData(
            '/BankLinkage/create',
            selectedBankLinkage || { bankName: '' },
            false
          )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setIsBusy(false)
      setMode(null)
      setSelectedBankLinkage(null)
      showNotification('Bank Linkages', 'success')
      loadTableData()
    }
    setIsBusy(false)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('miscellaneous'),
          localize('bankLinkages'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center ">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="payrollNumberTypesFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedBankLinkage(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(mode === 'add' || mode === 'view') && (
        <ModalTemplate
          modalClassName="modal-xl"
          modalTitle={'bankLinkage'}
          modalMode={mode}
          handleAction={handleSubmit}
          handleClose={closeModal}
          actionText={mode === 'add' ? 'create' : 'update'}
          footerChildren={
            <>
              <div className="d-flex align-items-center me-3 gap-2">
                <Checkbox
                  id="cBankLinkageIsLocked"
                  onCheck={e => {
                    setSelectedBankLinkage({
                      ...selectedBankLinkage,
                      isLocked: e,
                    })
                  }}
                  checked={selectedBankLinkage?.isLocked}
                />
                <label
                  htmlFor="cBankLinkageIsLocked"
                  className="text-capitalize"
                >
                  {localize('bankLinkageIsLocked')}?
                </label>
              </div>
            </>
          }
        >
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3 g-3">
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center mb-2 with-validation"
                    validation-message={
                      globalValidationErrors?.BankName?.message
                    }
                  >
                    <label className="text-capitalize col-3">
                      {localize('bankName')}
                    </label>
                    <BankLookup
                      displayValue={selectedBankLinkage?.bankName}
                      onSelect={v => {
                        setGlobalValidationErrors(g => {
                          delete g?.BankName
                          return g
                        })
                        setSelectedBankLinkage(prev => ({
                          ...prev,
                          bankName: v.description,
                          bankId: v.id,
                          bankBranchName: '',
                          bankBranchId: '',
                        }))
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center mb-2 with-validation"
                    validation-message={
                      globalValidationErrors?.BankBranchName?.message
                    }
                  >
                    <label className="text-capitalize col-3">
                      {localize('bankBranchName')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: selectedBankLinkage?.bankName ? 1 : 0.6,
                        pointerEvents: selectedBankLinkage ? 'all' : 'none',
                      }}
                    >
                      <BankBranchLookup
                        displayValue={selectedBankLinkage?.bankBranchName}
                        onSelect={v => {
                          clearValidation('BankBranchName')
                          setSelectedBankLinkage(prev => ({
                            ...prev,
                            bankBranchName: v.description,
                            bankBranchId: v.id,
                          }))
                        }}
                        bankId={selectedBankLinkage?.bankId}
                        bankDescription={selectedBankLinkage?.bankName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center mb-2 with-validation"
                    validation-message={
                      globalValidationErrors?.BankAccountNumber?.message
                    }
                  >
                    <label className="text-capitalize col-4">
                      {localize('bankAccountNumber')}
                    </label>
                    <input
                      defaultValue={selectedBankLinkage?.bankAccountNumber}
                      type="text"
                      required
                      className="form-control"
                      onChange={e => {
                        clearValidation('BankAccountNumber')
                        setSelectedBankLinkage({
                          ...selectedBankLinkage,
                          bankAccountNumber: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3 g-3">
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center mb-2 with-validation"
                    validation-message={
                      globalValidationErrors?.ChartOfAccountName?.message
                    }
                  >
                    <label className="text-capitalize col-3">
                      {localize('glAccount')}
                    </label>
                    <ChartOfAccountsLookup
                      displayValue={selectedBankLinkage?.chartOfAccountName}
                      onSelect={r => {
                        clearValidation('ChartOfAccountName')
                        setSelectedBankLinkage({
                          ...selectedBankLinkage,
                          chartOfAccountId: r?.id,
                          chartOfAccountName: r?.description,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center mb-2 with-validation"
                    validation-message={
                      globalValidationErrors?.BranchDescription?.message
                    }
                  >
                    <label className="text-capitalize col-3">
                      {localize('branchName')}
                    </label>
                    <BranchLookup
                      displayValue={selectedBankLinkage?.branchDescription}
                      onSelect={v => {
                        clearValidation('BranchDescription')
                        setSelectedBankLinkage(prev => ({
                          ...prev,
                          branchDescription: v.description,
                          branchId: v.id,
                        }))
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('remarks')}
                    </label>
                    <input
                      defaultValue={selectedBankLinkage?.remarks}
                      type="text"
                      required
                      className="form-control"
                      onChange={e =>
                        setSelectedBankLinkage({
                          ...selectedBankLinkage,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalTemplate>
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.branchDescription}
        />
      )}
    </>
  )
}
export default BankLinkages
