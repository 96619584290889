/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './simpletable.scss'
import localize from '../../Global/Localize'
import { UncontrolledTooltip } from 'reactstrap'
import FloatingContextMenu from '../ContextMenu/FloatingContextMenu'
import ContextMenu2 from '../ContextMenu/ContextMenu2'

function GroupedTable({
  columns,
  data,
  contextMenu,
  canSelect,
  onSelect,
  onClickOnce,
  canClickOnce,
  groupBy,
  maxHeight,
  selectables,
}) {
  const [ctxtMenuPosition, setCtxtMenuPosition] = useState([0, 0])
  const [showCtxtMenu, setShowCtxtMenu] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [ctxParentId, setCtxParentId] = useState('x')
  const [visibilityMap, setVisibilityMap] = useState([])
  const [groupedData, setGroupedData] = useState(null)

  function showContextMenu(e, row, parentId) {
    if (contextMenu) {
      e.preventDefault()
      setCtxParentId(parentId)
      // setCtxtMenuPosition([e.clientX, e.clientY])
      setShowCtxtMenu(true)
      setSelectedRow(row)
    }
  }

  useEffect(() => {
    window.addEventListener('contextmenu', setPosition)

    return () => {
      window.removeEventListener('contextmenu', setPosition)
    }
  }, [ctxParentId])

  function setPosition(e) {
    setCtxtMenuPosition([e.x, e.y])
  }

  function hideContextMenu(r) {
    if (canClickOnce) {
      onClickOnce(r)
      setSelectedRow(r)
    }
    setShowCtxtMenu(false)
  }

  useEffect(() => {
    if (data && data.length === 0) setGroupedData(null)

    if (data && data.length > 0) groupData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy, data])

  function groupData() {
    const { column } = groupBy

    const grouped = data.reduce((group, item) => {
      const col = item[column]
      group[col] = group[col] ?? []
      group[col].push(item)
      return group
    }, {})
    let vMap = {}
    if (grouped) {
      for (let g in grouped) {
        vMap[g] = true
      }
      setVisibilityMap(vMap)
    }
    setGroupedData(grouped)
  }

  return (
    <>
      <div
        style={{
          margin: 0,
          padding: 0,
          overflowX: 'scroll',
          maxHeight: maxHeight || '100%',
          position: 'relative',
        }}
        className={selectables ? 'selectables' : ''}
      >
        <table
          className="table cent-table"
          id="centrinotable"
          style={{ minWidth: '100%' }}
        >
          <tr
            style={selectables ? { position: 'sticky', top: 0, zIndex: 5 } : {}}
          >
            {columns.map(c => (
              <th className="text-dark py-2 px-3">{localize(c.label)}</th>
            ))}
          </tr>
          <>
            {groupedData &&
              Object.entries(groupedData).map(([group, rows]) => (
                <>
                  <tr
                    className="bg-secondary text-white cursor-pointer"
                    style={{
                      borderTop: 'solid 2px white',
                      borderBottom: 'solid 2px white',
                    }}
                    onClick={() => {
                      setVisibilityMap({
                        ...visibilityMap,
                        [group]: !visibilityMap[group],
                      })
                    }}
                  >
                    <td
                      colSpan={columns.length}
                      className="text-capitalize"
                      onMouseDown={() => setShowCtxtMenu(false)}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <i
                        className="bx bx-caret-right"
                        style={{
                          transform: `rotate(${
                            visibilityMap[group] ? 45 : 0
                          }deg) scale(1.2)`,
                        }}
                      ></i>
                      {localize(groupBy.label)}: {localize(group)} (
                      {`${rows.length} ${localize('items')}`})
                    </td>
                  </tr>
                  <>
                    {visibilityMap[group] &&
                      rows.map(r => (
                        <>
                          <tr
                            id={`row-${r.id}`}
                            className={` data-row ${
                              selectedRow?.id === r?.id ? 'selected' : ''
                            }`}
                            onDoubleClick={() =>
                              canSelect ? onSelect(r) : hideContextMenu()
                            }
                            onClick={() => {
                              hideContextMenu(r)
                            }}
                            onContextMenu={e =>
                              showContextMenu(e, r, `row-${r.id}`)
                            }
                          >
                            {columns.map(c => (
                              <td className="px-3 p-2">{c.template(r)}</td>
                            ))}
                            {contextMenu && (
                              <FloatingContextMenu>
                                {contextMenu(selectedRow)}
                              </FloatingContextMenu>
                            )}
                          </tr>
                          {canClickOnce && (
                            <UncontrolledTooltip
                              placement="auto"
                              target={`row-${r.id}`}
                              className="text-capitalize"
                            >
                              {localize(
                                'select or click row to show more details',
                              )}
                            </UncontrolledTooltip>
                          )}
                        </>
                      ))}
                  </>
                </>
              ))}
          </>
        </table>
      </div>
      {showCtxtMenu && contextMenu && (
        <ContextMenu2
          position={ctxtMenuPosition}
          hide={() => setShowCtxtMenu(false)}
        >
          {contextMenu(selectedRow)}
        </ContextMenu2>
      )}
    </>
  )
}

export default GroupedTable
