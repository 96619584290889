/** @format */

import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

import './pdfviewer.scss'
import localize from '../../Global/Localize'

function PDFViewer({ base64, close }) {
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <div
      onKeyDown={e => console.log(e)}
      onClick={close}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999999,
        width: '100vw',
        height: '100vh',
      }}
      className="doc-container d-flex align-items-center justify-content-center"
    >
      <div
        className="doc-content bg-white "
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <Document
          onPassword={(password, response) => {
            let input = prompt('Enter a correct password to view this document')
            if (input != null) {
              password(input)
            } else {
              close()
            }
          }}
          file={base64}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            renderTextLayer={false}
            renderForms={true}
            rotate={rotate}
            scale={scale}
            pageNumber={pageNumber}
          />
        </Document>
        <div className="doc-label p-2 rounded bg-white d-flex align-items-center gap-2">
          <button
            onClick={() => setRotate(rotate - 90)}
            className="btn btn-outline-primary"
          >
            <i className="bx bx-rotate-left"></i>
          </button>

          <button
            className="btn btn-outline-primary me-4"
            onClick={() => setRotate(rotate + 90)}
          >
            <i className="bx bx-rotate-right"></i>
          </button>
          <button
            onClick={() => setScale(scale - 0.05)}
            className="btn btn-outline-primary"
          >
            <i className="uil uil-search-minus"></i>
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => setScale(1)}
          >
            <i className="uil uil-search"></i>
          </button>
          <button
            className="btn btn-outline-primary me-4"
            onClick={() => setScale(scale + 0.05)}
          >
            <i className="uil uil-search-plus"></i>
          </button>
          <button
            className="btn btn-outline-primary"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <i className="uil uil-angle-left"></i>
          </button>
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <button
            className="btn btn-outline-primary"
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <i className="uil uil-angle-right"></i>
          </button>
          <a
            title={localize('download')}
            href={base64}
            download
            className="btn btn-primary ms-4"
          >
            <i className="uil uil-file-download-alt"></i>
          </a>
          <button className="btn btn-danger ms-2" onClick={close}>
            <i className="uil uil-times"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default PDFViewer
