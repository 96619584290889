/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../../Helpers/formatters'
import {
  entitiesToBeChecked,
  getDynamicCharges,
} from '../../../../../../Helpers/selectablesHelper'
import { getData } from '../../../../../../Helpers/webApi'
import ViewInterAccountTransferDataModal from '../../Partials/ViewInterAccountTransferDataModal'

import AuthorizeInterACTransfer from './AuthorizeInterACTransfer'
import localize from '../../../../../../Global/Localize'
import Swal from 'sweetalert2'
import testtone from './../../../../../../assets/sounds/testtone.mp3'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function InterAccountTransfer() {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('batchType'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{formatDate(r.auditedDate, true)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate, false)}</>,
    },

    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 8,
    startDate: null,
    endDate: null,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [
    selectedInterAccountTransferBatch,
    setSelectedInterAccountTransferBatch,
  ] = useState(null)
  const [dynamicCharges, setDynamicCharges] = useState([])
  const [playNotification, setPlayNotification] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/InterAccountTransferBatch/find-inter-account-transfer-batch-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }

    setIsBusy(false)
  }

  const dynamicChargesToBeSelected = async r => {
    setIsBusy(true)
    const dynamicCharges = await getDynamicCharges()
    const { success, data } = await getData(
      `/InterAccountTransferBatch/find-dynamic-charges-by-inter-account-transfer-batch-id?interAccountTransferBatchId=${r.id}`,
      false
    )
    if (success) {
      setDynamicCharges(entitiesToBeChecked(dynamicCharges, data.result))
    }

    setIsBusy(false)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('interAccountTransferBatches'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <RecordsPerPage
                      className="col-2"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="d-flex align-items-center col-2">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="BatchStatus"
                        value={reqParams?.status}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            status: e.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize col-md-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label
                        className="ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="populationQueryFilterButton"
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('view')
                              setSelectedInterAccountTransferBatch(r)
                              await dynamicChargesToBeSelected(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status == 1) {
                                const audio = new Audio(testtone)
                                audio.play()
                                setPlayNotification(true)

                                return Swal.fire({
                                  icon: 'info',
                                  text: 'sorry, but you must first select an inter account transfer whose status is audited!',
                                  title: 'Ooops!',
                                  showCloseButton: true,
                                })
                                setPlayNotification(false)
                              } else {
                                setMode('authorize')
                                setSelectedInterAccountTransferBatch(r)
                                await dynamicChargesToBeSelected(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-unlock-alt"></i>
                            {localize('authorize')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {mode === 'authorize' && (
        <AuthorizeInterACTransfer
          mode={mode}
          selectedInterAccountTransferBatch={selectedInterAccountTransferBatch}
          setSelectedInterAccountTransferBatch={
            setSelectedInterAccountTransferBatch
          }
          closeModal={() => {
            setMode(null)
            setSelectedInterAccountTransferBatch(null)
          }}
          dynamicChargesToBeSelected={dynamicCharges}
          setDynamicChargesToBeSelected={setDynamicCharges}
          loadTableData={loadTableData}
        />
      )}
      {mode === 'view' && (
        <ViewInterAccountTransferDataModal
          mode={mode}
          selectedInterAccountTransferBatch={selectedInterAccountTransferBatch}
          closeModal={() => {
            setMode(null)
            setSelectedInterAccountTransferBatch(null)
          }}
          dynamicChargesToBeSelected={dynamicCharges}
          setDynamicChargesToBeSelected={setDynamicCharges}
        />
      )}

      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}
    </>
  )
}

export default InterAccountTransfer
