/** @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ReportLookup from '../../../../../Components/Lookup/ReportLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import ShowReport from '../../../../SystemAdministration/Reports/ShowReport'

const UserDefinedReports = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [reports, setReports] = useState([])
  const [mode, setMode] = useState(null)
  const [cReport, setCReport] = useState(null)
  const [reqParams, setReqParams] = useState({
    parentId: '',
    updateDepth: true,
    traverseTree: true,
  })
  const [selectedReport, setSelectedReport] = useState(null)

  const columns = [
    {
      label: '',
      template: r => (
        <a
          href={localize('#')}
          onClick={() => setCReport(r)}
          className="text-capitalize"
        >
          {localize('view')}
        </a>
      ),
    },
    {
      label: localize('reportName'),
      template: r => (
        <span
          dangerouslySetInnerHTML={{
            __html: r?.indentedReportName,
          }}
        ></span>
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('depth'),
      template: r => <>{r?.depth}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.parentId, reqParams.updateDepth, reqParams.traverseTree])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Report/find-by-parent-id',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setReports(data.result || [])
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectReport = (r, m) => {
    if (!r?.reportURL && m === 'view')
      return showNotification(
        'Operation not allowed',
        'error',
        "Selected report doesn't have a report URL"
      )

    if (m === 'view' && r?.reportURL) {
      window.open(r?.reportURL, '_blank', 'noopener,noreferrer')
      setMode(null)
      setSelectedReport(null)
      return
    }
  }

  return (
    <>
      {cReport && (
        <ShowReport report={cReport} closeReport={() => setCReport(null)} />
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('utilities'),
          localize('userDefinedReports'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center col-md-10">
                  <label
                    className="text-capitalize col-1"
                    htmlFor="searchByParentId"
                  >
                    {localize('parentReport')}
                  </label>
                  <ReportLookup
                    displayValue={selectedReport?.reportName}
                    onSelect={r => {
                      setReqParams({
                        ...reqParams,
                        parentId: r?.id,
                      })
                      setSelectedReport(r)
                    }}
                  />
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-end mt-3">
                  <Checkbox
                    checked={reqParams.traverseTree}
                    onCheck={v =>
                      setReqParams({
                        ...reqParams,
                        traverseTree: v,
                        updateDepth: v,
                      })
                    }
                  />
                  <label
                    htmlFor="reports-traverse-tree"
                    className="text-capitalize ms-2"
                  >
                    {localize('traverseTree')}
                  </label>
                </div>
              </div>
              <div className="row col-md-12">
                <SimpleTable data={reports} columns={columns} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserDefinedReports
