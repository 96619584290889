/** @format */

import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
/* eslint-disable */
import React from 'react'

export const AcquisitionHistory = ({
  acquisitionHistory,
  reqParams,
  setReqParams,
}) => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('fingerDigit'),
      template: r => <>{r.fingerDigitDescription}</>,
    },
    {
      label: localize('acquisitionEvent'),
      template: r => <>{r.acquisitionEventDescription}</>,
    },
    { label: localize('deviceName'), template: r => <>{r.deviceName}</> },
    {
      label: localize('templateFormat'),
      template: r => <>{r.templateFormat}</>,
    },
    {
      label: localize('templateFormatDesc'),
      template: r => <>{r.templateFormatDesc}</>,
    },
    {
      label: localize('templateQuality'),
      template: r => <>{r.templateQuality}</>,
    },
    {
      label: localize('authenticationScore'),
      template: r => <>{r.authenticationScore}</>,
    },
    {
      label: localize('qualityThreshold'),
      template: r => <>{r.qualityThreshold}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('applicationDisplayName'),
      template: r => <>{r.applicationDisplayName}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r.environmentDomainName}</>,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => <>{r.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r.environmentIPAddress}</>,
    },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={acquisitionHistory.pageCollection} />
      <Pager
        itemsCount={acquisitionHistory.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
