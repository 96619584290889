/** @format */

import React, { useState } from 'react'
import localize from '../../Global/Localize'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import { formatCurrency, formatDate } from '../../Helpers/formatters'

const BankersChequeLeavesLookup = ({
  onSelect,
  displayValue,
  bankersChequeBookId,
  chequeBookSerialNumber,
}) => {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('chequeLeafLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{chequeBookSerialNumber}</>
        </>
      }
      url="/lookup/BankersChequeBook/find-bankers-cheque-leaves-by-bankers-cheque-book-id-and-filter-in-page"
      params={{
        ...reqParams,
        bankersChequeBookId,
      }}
      conditionalField="bankersChequeBookId"
      columns={[
        {
          label: localize('leaveNumber'),
          template: r => <>{r.paddedLeafNumber}</>,
        },
        {
          label: localize('status'),
          template: r => <>{r.statusDescription}</>,
        },
        {
          label: localize('payee'),
          template: r => <>{r.payee}</>,
        },
        {
          label: localize('amount'),
          template: r => <>{formatCurrency(r.amount)}</>,
        },
        {
          label: localize('amountInWords'),
          template: r => <>{r.wordifiedAmount}</>,
        },
        {
          label: localize('paddedAmount'),
          template: r => <>{r.paddedAmount}</>,
        },
        {
          label: localize('reference'),
          template: r => <>{r.reference}</>,
        },
        {
          label: localize('written/flaggedBy'),
          template: r => <>{r.writtedBy}</>,
        },
        {
          label: localize('written/flaggedDate'),
          template: r => <>{formatDate(r.writtenDate)}</>,
        },
        {
          label: localize('printedBy'),
          template: r => <>{r.printedBy}</>,
        },
        {
          label: localize('printedDate'),
          template: r => <>{formatDate(r.printedDate)}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
      isList={false}
    />
  )
}

export default BankersChequeLeavesLookup
