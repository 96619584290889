/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../../Helpers/webApi'
import SpecimenMediaItem from '../../../../../../Registry/Members/Partials/Tabs/SpecimenMediaItem'
import localize from '../../../../../../../Global/Localize'

function BadgeSpecimen({
  readOnly,
  refetchFromServer = true,
  vehiclePersonnel,
  setVehiclePersonnel,
}) {
  const [personnel, setPersonnel] = useState({})
  const [fetching, setFetching] = useState(false)
  const query = {
    badgePicture: personnel?.badgeImageId,
  }

  const [media, setMedia] = useState({
    badgePicture: null,
  })

  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    loadAllMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnel])

  useEffect(() => {
    refetchFromServer && vehiclePersonnel?.id && refetchPersonnelInfo()
    // eslint-disable-next-line
  }, [vehiclePersonnel?.id])

  async function refetchPersonnelInfo() {
    setFetching(true)
    const { success, data } = await getData(
      '/VehiclePersonnel/find-by-id',
      {
        VehiclePersonnelId: vehiclePersonnel?.id,
      },
      false,
      null,
      false
    )
    if (success) {
      setFetching(false)
      setPersonnel(data)
    } else {
      setFetching(false)
    }
  }

  async function loadAllMedia() {
    for (let i = 0; i < Object.values(query).length; i++) {
      const imageData = await loadMedia(
        Object.values(query)[i],
        Object.keys(query)[i]
      )

      if (imageData) {
        setMedia(media => ({
          ...media,
          [Object.keys(query)[
            i
          ]]: `data:${imageData.ContentType};base64,${imageData.content}`,
        }))
      }
    }
  }

  async function loadMedia(sku, key) {
    if (sku) {
      setIsBusy(key)
      const { success, data } = await getData(
        '/base64/get-media-by-sku',
        {
          sku,
        },
        false
      )
      if (success) {
        setIsBusy(null)
        return data
      } else {
        setIsBusy(null)
        return null
      }
    } else {
    }
  }

  useEffect(() => {
    if (vehiclePersonnel?.badgeImageId) setPersonnel(vehiclePersonnel)

    // eslint-disable-next-line
  }, [vehiclePersonnel?.badgeImageId])

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <div className="d-flex align-items-center justify-content-between mb-5">
      {Object.entries(query).map(([key, value]) => (
        <div
          className="spec-item-add-customer"
          key={`spec-item-add-customer-${key}-${value}`}
        >
          <label className="mb-2 text-capitalize">{localize(key)}</label>
          <SpecimenMediaItem
            isBusy={isBusy === key}
            readOnly={readOnly}
            itemKey={key}
            base64={vehiclePersonnel ? media[key] : null}
            onChange={(key, photo) => {
              setMedia({ ...media, [key]: photo })
              let spl = photo.split(',')
              let content = spl[1]

              setVehiclePersonnel(p => ({
                ...p,
                badgeBuffer: content,
              }))
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default BadgeSpecimen
