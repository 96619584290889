/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import {
  customerMembershipStatus,
  messageGroupTarget,
} from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import CreateMessageGroup from './CreateMessageGroup/CreateMessageGroup'

function MessageGroups() {
  const [message, setMessage] = useState({
    target: Object.keys(messageGroupTarget)[1],
    membershipStatus: Object.keys(customerMembershipStatus)[0],
  })
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)

  const columns = [
    {
      label: 'name',
      template: r => r?.description,
    },
    {
      label: 'target',
      template: r => r?.targetDescription,
    },
    {
      label: 'membershipStatus',
      template: r => r?.membershipStatusDescription,
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const targetMessage =
    Object.entries(messageGroupTarget).find(m => m[0] === message.target) || ''

  const handleClickAddButton = () => {
    setMessage({
      target: Object.keys(messageGroupTarget)[1],
      membershipStatus: Object.keys(customerMembershipStatus)[0],
    })
    setModalMode('add')
  }

  const handleCancel = () => {
    setMessage({
      target: Object.keys(messageGroupTarget)[1],
      membershipStatus: Object.keys(customerMembershipStatus)[0],
    })
    setModalMode(null)
  }

  return (
    <>
      <ListingPage
        url="/MessageGroup/find-message-groups-by-filter-in-page"
        columns={columns}
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        onAdd={handleClickAddButton}
        map={[
          localize('customerRelationshipManagement'),
          localize('messageGroups'),
        ]}
        useText
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setMessage(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
      />
      {modalMode && (
        <CreateMessageGroup
          handleClose={handleCancel}
          mode={modalMode}
          refresh={refresh}
          setRefresh={setRefresh}
          targetMessage={targetMessage}
          message={message}
          setMessage={setMessage}
        />
      )}
    </>
  )
}

export default MessageGroups
