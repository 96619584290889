/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Commissions from '../../../../Components/Selectables/Plain/Commissions'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  DynamicChargeInstallmentsBasisValue,
  DynamicChargeRecoveryMode,
  DynamicChargeRecoverySource,
  LoanCaseDeductionCategory,
} from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import {
  entitiesToBeChecked,
  getCommissions,
} from '../../../../Helpers/selectablesHelper'
import { getData } from '../../../../Helpers/webApi'
import IndefintieChargeModal from './Partials/IndefintieChargeModal'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function ChargesDeterminationIndefinite() {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('deductionCategoryDescription'),
      template: r => <>{r?.deductionCategoryDescription}</>,
    },
    {
      label: localize('recoveryModeDescription'),
      template: r => <>{r?.recoveryModeDescription}</>,
    },
    {
      label: localize('recoverySourceDescription'),
      template: r => <>{r?.recoverySourceDescription}</>,
    },
    { label: localize('installments'), template: r => <>{r?.installments}</> },
    {
      label: localize('installmentsBasisValueDescription'),
      template: r => <>{r?.installmentsBasisValueDescription}</>,
    },
    {
      label: localize('factorInLoanTerm'),
      template: r => (
        <span className="text-capitalize">
          {r?.factorInLoanTerm.toString()}
        </span>
      ),
    },
    {
      label: localize('computeChargeOnTopUp'),
      template: r => (
        <span className="text-capitalize">
          {r?.computeChargeOnTopUp.toString()}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [commissions, setCommissions] = useState([])
  const [loadingCommissions, setLoadingCommissions] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  const [indefiniteChargeModel, setIndefiniteChargeModel] = useState({
    deductionCategory: Object.keys(LoanCaseDeductionCategory)[2],
    recoveryMode: Object.keys(DynamicChargeRecoveryMode)[0],
    recoverySource: Object.keys(DynamicChargeRecoverySource)[1],
    installmentsBasisValue: Object.keys(DynamicChargeInstallmentsBasisValue)[0],
  })

  const [clickedOnce, setClickedOnce] = useState(false)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DynamicCharge/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  const getCommissionsToBeSelected = async r => {
    setIsBusy(true)
    setLoadingCommissions(true)
    const commissions = await getCommissions()
    const { success, data } = await getData(
      '/DynamicCharge/find-commissions-by-dynamic-charge-id',
      { dynamicChargeId: r.id },
      false
    )
    if (success) {
      setCommissions(entitiesToBeChecked(commissions, data.result))
    }
    setIsBusy(false)
    setLoadingCommissions(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const closeModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('chargesDetermination'),
          localize('indefiniteCharges'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton
                          handleClickAddButton={async () => {
                            setMode('add')
                            setCommissions([])
                            setCommissions(await getCommissions())
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-6"></div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                      <button
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('edit')
                              setIndefiniteChargeModel(r)
                              setCommissions([])
                              await getCommissionsToBeSelected(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      onClickOnce={async r => {
                        setClickedOnce(true)
                        setCommissions([])
                        await getCommissionsToBeSelected(r)
                      }}
                      canClickOnce
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
              {clickedOnce && (
                <div className="row mt-3">
                  <div className="col md-12">
                    <Tab
                      tabItems={[
                        {
                          label: localize('applicableCharges'),
                          item: (
                            <Commissions
                              disabled
                              isBusy={loadingCommissions}
                              commissionsToBeSelected={commissions}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <IndefintieChargeModal
        mode={mode === 'add' || mode === 'edit'}
        indefiniteChargeModel={indefiniteChargeModel}
        setIndefiniteChargeModel={setIndefiniteChargeModel}
        commissionsToBeSelected={commissions}
        setCommissionsToBeSelected={setCommissions}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onSetMode={m => {
          setMode(m)
        }}
      />
    </>
  )
}

export default ChargesDeterminationIndefinite
