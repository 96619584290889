/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { messageGroupTarget } from '../../../../Global/enumeration'
import { getData } from '../../../../Helpers/webApi'

function EmployerTab({
  onSelectedEmployersChange,
  selectedEmployersData,
  onSelectAll,
}) {
  const [employers, setEmployers] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const fetchEmployers = async () => {
    setIsBusy(true)
    const url =
      '/Employer/find-employers-by-traversal-and-depth-update?updateDepth=false&traverseTree=false'
    const { success, data } = await getData(url, {}, false)
    if (success) {
      setEmployers(
        data?.result?.map(e => ({
          ...e,
          targetIdentifier: e.id,
          messageGroupTarget: Object.keys(messageGroupTarget)[0],
          surveyGroupTarget: Object.keys(messageGroupTarget)[0],
        }))
      )
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchEmployers()
  }, [])

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <div className="row  mb-2 position-sticky top-0 bg-white">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-start">
            <Checkbox
              id="select-all-employers-in-CRM"
              checked={selectAll}
              onCheck={v => {
                setSelectAll(v)
                onSelectAll(employers, v)
              }}
            />
            <label
              className="text-capitalize ms-3"
              htmlFor="select-all-employers-in-CRM"
            >
              {localize('toggleSelection')}
            </label>
          </div>
        </div>
        <hr />
      </div>
      <div className="row">
        <div
          style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}
          className="py-1"
        >
          <div className="col-md-6">
            <div className="row">
              {employers.map(employer => (
                <div
                  className="col-md-12"
                  key={employer.id}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="d-flex align-items-center justify-content-start">
                    <Checkbox
                      id={employer.id}
                      checked={selectedEmployersData?.find(
                        e => e.targetIdentifier === employer.id
                      )}
                      onCheck={v => {
                        onSelectedEmployersChange(employer, v)
                      }}
                    />
                    <label
                      className="text-capitalize ms-3"
                      htmlFor={employer.id}
                    >
                      {localize(employer.description)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployerTab
