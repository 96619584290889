/** @format */

import React, { useEffect } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import AlternateChannelLookup from '../../../../../Components/Lookup/AlternateChannelLookup'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const ChannelDelinking = ({
  channel,
  setChannel,
  customerAccount,
  setCustomerAccount,
  accountHistoryQueryParams,
  setAccountHistoryQueryParams,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [channel?.type])

  const fetchCustomerAccount = async r => {
    setChannel(r)
    setAccountHistoryQueryParams({
      ...accountHistoryQueryParams,
      alternateChannelCustomerAccountId: r?.customerAccountId,
      alternateChannelId: r?.id,
    })
    clearValidation('AlternateChannelId')

    const url = '/lookup/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: r?.customerAccountId,
        includeBalances: false,
        includeProductDescription: true,
      },
      false,
    )

    if (success) {
      setCustomerAccount(data)
    }
  }

  return (
    <>
      <div className="row g-3 mb-2">
        <div
          validation-message={validationBag?.AlternateChannelId?.message}
          className="col-md-3 d-flex align-items-center with-validation"
        >
          <label className="text-capitalize col-4 me-2">
            {localize('pan/CardNumber')}
          </label>
          <AlternateChannelLookup
            onSelect={fetchCustomerAccount}
            displayValue={channel?.cardNumber || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 ms-2">
            {localize('channelType')}
          </label>

          <input
            type="text"
            disabled
            className="form-control"
            value={channel?.typeDescription || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('dailyLimit')}
          </label>

          <input
            type="text"
            disabled
            className="form-control ms-2 text-end"
            value={formatCurrency(channel?.dailyLimit || '', 2, 2, false)}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 me-2">
            {localize('validFrom')}
          </label>

          <input
            type="text"
            disabled
            className="form-control"
            value={formatDate(channel?.validFrom || '')}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4">{localize('expires')}</label>

          <input
            type="text"
            disabled
            className="form-control ms-2"
            value={formatDate(channel?.expires || '')}
          />
        </div>
        <div className="col-md-9 d-flex align-items-center">
          <label className="col-1 text-capitalize me-4">
            {localize('remarks')}
          </label>

          <input
            className="form-control ms-3"
            value={channel?.remarks || ''}
            onChange={e =>
              setChannel({
                ...channel,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <CustomerAccountPartial
        customer={customerAccount ? { ...customerAccount } : {}}
        readOnly
      />

      <div className="mt-2 d-flex justify-content-lg-end align-items-center gap-3">
        <div className="d-flex align-items-center">
          <Checkbox
            checked={channel?.isThirdPartNotified}
            id={'isThirdPartyNotifiedChannelDelinking'}
            onCheck={value =>
              setChannel({
                ...channel,
                isThirdPartNotified: value,
              })
            }
          />

          <label
            className="text-capitalize"
            htmlFor="isThirdPartyNotifiedChannelDelinking"
          >
            {localize('isThirdPartyNotified')} ?
          </label>
        </div>
        <div className="d-flex align-items-center">
          <Checkbox
            checked={channel?.isLocked}
            id={'isLockedChannelDelinking'}
            onCheck={value =>
              setChannel({
                ...channel,
                isLocked: value,
              })
            }
          />

          <label className="text-capitalize" htmlFor="isLockedChannelDelinking">
            {localize('isLocked')} ?
          </label>
        </div>
      </div>
    </>
  )
}

export default ChannelDelinking
