/** @format */
import localize from '../../../../../../../Global/Localize'
import { CreditBatchEntryLookup } from '../../../../../../../Components/Lookup/CreditBatchEntryLookup'
import { formatCurrency } from '../../../../../../../Helpers/formatters'
import {
  BatchEntryStatus,
  CreditBatchType,
} from '../../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import { postData } from '../../../../../../../Helpers/webApi'
import React, { useState } from 'react'

export const SundryTransactionSectionCashPickup = ({
  transactionModel,
  setTransactionModel,
  loading,
}) => {
  const [netPayable, setNetPayable] = useState('')

  const fetchNetPayables = async r => {
    const { success, data } = await postData(
      '/lookup/Teller/fetch-net-payable',
      {
        CreditBatchEntry: { ...r },
        GeneralTransactionType: 16,
      },
      false,
      null
    )
    if (success) {
      setNetPayable(data.result)
    }
  }

  const handleOnSelectCreditBatchEntry = async r => {
    if (r.creditBatchType !== Object.keys(CreditBatchType)[2]) {
      Swal.fire({
        title: 'Warning',
        text: 'Please Select Credit Batch Entry of type Cash Pickup',
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
      return
    }
    if (r.status === Object.keys(BatchEntryStatus)[1]) {
      Swal.fire({
        title: 'Warning',
        text: 'The Selected Credit Batch Entry is already posted',
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
      return
    }
    setTransactionModel({
      ...transactionModel,
      selectedCreditBatchEntry: { ...r },
    })

    fetchNetPayables(r)
  }

  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('beneficiary')}
            </label>
            <CreditBatchEntryLookup
              defaultCreditBatchType={Object.keys(CreditBatchType)[2]}
              displayValue={
                transactionModel?.selectedCreditBatchEntry?.beneficiary
              }
              onSelect={handleOnSelectCreditBatchEntry}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('entryReference')}
            </label>
            <input
              className="form-control"
              disabled
              defaultValue={
                transactionModel?.selectedCreditBatchEntry?.reference
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('batchNumber')}
            </label>
            <input
              className="form-control"
              disabled
              defaultValue={
                transactionModel?.selectedCreditBatchEntry
                  ?.paddedCreditBatchBatchNumber
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('batchReference')}
            </label>
            <input
              className="form-control"
              disabled
              defaultValue={
                transactionModel?.selectedCreditBatchEntry?.creditBatchReference
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('batchMonth')}
            </label>
            <input
              className="form-control"
              disabled
              defaultValue={
                transactionModel?.selectedCreditBatchEntry
                  ?.creditBatchMonthDescription
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('entryAmount')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                transactionModel?.selectedCreditBatchEntry?.principal,
                2,
                2,
                false
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('costCenter')}
            </label>
            <input
              className="form-control"
              disabled
              defaultValue={
                transactionModel?.selectedCreditBatchEntry
                  ?.costCenterDescription
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('netPayable')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(netPayable, 2, 2, false)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize" style={{ marginRight: '4.5rem' }}>{localize('payee')}</label>
            <input
              className="form-control"
              value={transactionModel?.cashPaymentTransactionModel?.payee || ''}
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    payee: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize" style={{ marginRight: '4.5rem' }}>
              {localize('narration')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.cashPaymentTransactionModel?.narration || ''
              }
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    narration: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize" style={{ marginRight: '4.5rem' }}>
              {localize('reference')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.cashPaymentTransactionModel?.reference || ''
              }
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    reference: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
