/** @format */

import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import { chequeBookType } from '../../../../../Global/enumeration'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import { formatDate } from '../../../../../Helpers/formatters'
import PaymentVouchers from './PaymentVouchers/PaymentVouchers'
import CreateChequeBook from './CreateChequeBook/CreateChequeBook'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

const ChequeBooks = () => {
  const ref = useRef(null)
  const [checkBooks, setCheckBooks] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [chequeBookId, setChequeBookId] = useState({})
  const [mode, setMode] = useState(null)
  const [showVouchers, setShowVouchers] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    type: Object.keys(chequeBookType)[0],
  })

  const columns = [
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('numberOfVouchers'),
      template: r => <>{r.numberOfVouchers}</>,
    },
    {
      label: localize('initialVoucherNumber'),
      template: r => <>{r.initialVoucherNumber}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('isActive') + ' ?',
      template: r => <>{r.isActive?.toString()}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r.isLocked?.toString()}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchCheckBooks = async () => {
    setIsBusy(true)
    const url = '/ChequeBook/find-chequebook-by-type-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCheckBooks(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleClickOnce = r => {
    setChequeBookId(r)
    setShowVouchers(true)
  }

  const handleClose = () => {
    setMode(null)
    setChequeBookId({})
  }

  const handleSelectChequeBook = (r, mode) => {
    setChequeBookId(r)
    setMode(mode)
    setShowVouchers(false)
  }

  const handleClickAddButton = () => {
    setMode('add')
    setShowVouchers(false)
  }

  useEffect(
    () => {
      fetchCheckBooks()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reqParams.pageIndex, reqParams.pageSize]
  )

  useEffect(() => {
    if (showVouchers) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [showVouchers])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreateChequeBook
          fetchChequeBooks={fetchCheckBooks}
          handleClose={handleClose}
          mode={mode}
          chequeBookId={chequeBookId?.id}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('chequeBooks'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <AddNewButton handleClickAddButton={handleClickAddButton} />

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex align-items-center float-right my-2">
                  <label
                    className="text-capitalize"
                    htmlFor="checkBooksPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="signatoriesPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageCount}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={reqParams?.type}
                    enumsKey="ChequeBookType"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        type: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') fetchCheckBooks()
                    }}
                  />
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchCheckBooks()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                data={checkBooks?.pageCollection}
                canClickOnce
                onClickOnce={handleClickOnce}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectChequeBook(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={checkBooks.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {showVouchers && (
        <div ref={ref} className="card bg-white rounded">
          <div className="card-body">
            <PaymentVouchers chequeBookId={chequeBookId?.id} />
          </div>
        </div>
      )}
    </>
  )
}

export default ChequeBooks
