/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import GroupedTable from '../../../Components/Tables/GroupedTable'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import Pager from '../../../Components/Tables/Pager'
import { employeeFilters } from '../../../Global/enumeration'
import { getData } from '../../../Helpers/webApi'
import TemporaryRoleModal from './Partials/TemporaryRoleModal'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import { getRoles } from '../../../Helpers/selectablesHelper'
import NonBlockingLoader from '../../../Components/Loaders/NonBlockingLoader'

function TemporaryRoles() {
  const [isBusy, setIsBusy] = useState(false)
  const [waitingMetadata, setWaitingMetadata] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedRow, setSelectedRow] = useState(null)
  const [roles, setRoles] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    employeeFilter: Object.keys(employeeFilters)[0],
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  const [
    suppressedPermissionsTemporaryRoles,
    setSuppressedPermissionsTemporaryRoles,
  ] = useState([])
  const [temporaryRoleEntity, setTemporaryRoleEntity] = useState(null)

  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/Role/find-employee-temporary-roles-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const fetchEmployeeTemporaryRoleWithSuppressedPermissions = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/Role/find-employee-suppressed-permissions-employee-temporary-role-id',
      {
        employeeTemporaryRoleId: r.id,
      },
      false,
    )

    if (success) {
      setTemporaryRoleEntity(data.employeeTemporaryRoleDto)
      setSuppressedPermissionsTemporaryRoles(
        data.employeeSuppressedPermissionDtos || [],
      )
    }
    setIsBusy(false)
  }

  const handleOnEdit = e => {
    setMode('edit')
    setSelectedRow(e)
    fetchEmployeeTemporaryRoleWithSuppressedPermissions(e)
  }

  const handleOnVerify = async e => {
    setMode('verify')
    fetchEmployeeTemporaryRoleWithSuppressedPermissions(e)
  }

  const handleOnVerifyAuditLog = e => {}

  const handleOnAdd = async () => {
    setMode('add')
    setWaitingMetadata(true)
    const allRoles = await getRoles()
    setWaitingMetadata(false)
    setSelectedRow(null)
    setRoles(allRoles)
  }

  const groupingColumns = [
    {
      label: localize('employeeName'),
      column: 'employeeCustomerFullName',
    },
    {
      label: localize('role'),
      column: 'roleName',
    },
    {
      label: `${localize('recordStatus')}`,
      column: 'recordStatusDescription',
    },
  ]

  const [groupBy, setGroupBy] = useState({
    label: localize('employeeName'),
    column: 'employeeCustomerFullName',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    { label: localize('role'), template: r => <>{r?.roleName}</> },
    { label: localize('Reason'), template: r => <>{r?.reason}</> },
    {
      label: localize('durationStartDate'),
      template: r => <>{formatDate(r?.durationStartDate)}</>,
    },
    {
      label: localize('durationEndDate'),
      template: r => <>{formatDate(r?.durationEndDate)}</>,
    },
    {
      label: localize('timeDurationStart'),
      template: r => <>{r?.timeDurationStartTime}</>,
    },
    {
      label: localize('timeDurationEndTime'),
      template: r => <>{r?.timeDurationEndTime}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('employeeCustomerFullName'),
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')}# )`,
      template: r => <>{r?.employeeCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')}# )`,
      template: r => <>{r?.employeeCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')}# )`,
      template: r => <>{r?.employeeCustomerReference3}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => (
        <>{r?.employeeCustomerIndividualIdentityCardTypeDescription}</>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedEmployeeCustomerSerialNumber}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.employeeDesignationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.employeeBranchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.employeeDepartmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeEmployeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.employeeBloodGroupDescription}</>,
    },
    {
      label: localize('NSSF'),
      template: r => <>{r?.employeeNationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('NHIF'),
      template: r => <>{r?.employeeNationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('PIN'),
      template: r => <>{r?.employeeCustomerPersonalIdentificationNumber}</>,
    },
    { label: localize('isLocked'), template: r => <>{r?.isLocked}</> },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('security'),
          localize('temporaryRoles'),
        ]}
      />
      <div className="relative">
        <div className="row">
          <div className="col-12">
            <div className="card bg-white">
              <div className="card-body">
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-3"></div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('groupBy')}
                      </label>
                      <select
                        className="form-select text-capitalize"
                        defaultValue={groupBy.column}
                        onChange={e =>
                          setGroupBy({
                            label: groupingColumns.find(
                              f => f.column === e.target.value,
                            )?.label,
                            column: e.target.value,
                          })
                        }
                      >
                        {groupingColumns.map(({ label, column }) => (
                          <option className="text-capitalize" value={column}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                      <button
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <div className="row col-12 mt-2">
                      <GroupedTable
                        contextMenu={e => (
                          <>
                            <div
                              onClick={() => {
                                handleOnEdit(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-edit-alt"></i>
                              {localize('edit')}
                            </div>
                            <div
                              onClick={() => {
                                handleOnVerify(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="far fa-check-circle"></i>
                              {localize('verify')}
                            </div>
                            <div
                              onClick={() => {
                                handleOnVerifyAuditLog(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="far fa-list-alt"></i>
                              {localize('auditLog')}
                            </div>
                          </>
                        )}
                        setGroupBy={setGroupBy}
                        groupBy={groupBy}
                        columns={columns}
                        data={tableData}
                      />
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
      <TemporaryRoleModal
        onHideModal={() => {
          setMode(null)
        }}
        roles={roles}
        setRoles={setRoles}
        waitingMetadata={waitingMetadata}
        setSuppressedPermissionsTemporaryRoles={
          setSuppressedPermissionsTemporaryRoles
        }
        suppressedPermissionsTemporaryRoles={
          suppressedPermissionsTemporaryRoles
        }
        temporaryRoleEntity={temporaryRoleEntity}
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        selectedTemporaryRole={selectedRow}
        setSelectedTemporaryRole={setSelectedRow}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default TemporaryRoles
