/** @format */
import localize from '../../../../Global/Localize'

export const AddRolesModal = ({
  rolesModel,
  setRolesModel,
  validationBag,
  clearValidation,
}) => {
  return (
    <>
      <div
        validation-message={validationBag?.Description?.message}
        className="col d-flex align-items-center with-validation"
      >
        <label className="col-auto me-2 text-capitalize">{localize('roleName')}</label>
        <input
          defaultValue={rolesModel?.roleName}
          className="form-control"
          onChange={e => {
            clearValidation('Description')
            setRolesModel({ ...rolesModel, description: e.target.value })
          }}
        />
      </div>
    </>
  )
}
