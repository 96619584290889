/** @format */

import React, { useEffect } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import AlternateChannelLookup from '../../../../../Components/Lookup/AlternateChannelLookup'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../Global/Localize'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { formatDate } from '../../../../../Helpers/formatters'
import { alternateChannelType } from '../../../../../Helpers/constants'
import { getData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const ChannelRenewal = ({
  channel,
  setChannel,
  customerAccount,
  setCustomerAccount,
  accountHistoryQueryParams,
  setAccountHistoryQueryParams,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [channel?.type])

  const disableDates = () => {
    if (
      channel?.type === alternateChannelType.AGENCYBANKING?.toString() ||
      channel?.type === alternateChannelType.BROKER?.toString() ||
      channel?.type === alternateChannelType.CITIUS?.toString() ||
      channel?.type === alternateChannelType.MCOOPCASH?.toString() ||
      channel?.type === alternateChannelType.PESAPEPE?.toString() ||
      channel?.type === alternateChannelType.SPOTCASH?.toString()
    ) {
      return true
    }

    return false
  }

  const fetchCustomerAccount = async r => {
    setChannel(r)
    setAccountHistoryQueryParams({
      ...accountHistoryQueryParams,
      alternateChannelCustomerAccountId: r?.customerAccountId,
      alternateChannelId: r?.id,
    })
    clearValidation('AlternateChannelId')
    const url = '/lookup/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: r?.customerAccountId,
        includeBalances: false,
        includeProductDescription: true,
      },
      false,
    )

    if (success) {
      setCustomerAccount(data)
    }
  }

  return (
    <>
      <div className="row g-3 mb-2">
        <div
          validation-message={validationBag?.AlternateChannelId?.message}
          className="col-md-3 d-flex align-items-center with-validation"
        >
          <label className="text-capitalize col-4 me-2">
            {localize('pan/CardNumber')}
          </label>

          <AlternateChannelLookup
            onSelect={fetchCustomerAccount}
            displayValue={channel?.cardNumber || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 ms-2">
            {localize('channelType')}
          </label>

          <input
            type="text"
            disabled
            className="form-control"
            value={channel?.typeDescription || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 ms-2">
            {localize('newPan')}
          </label>

          <input
            type="text"
            value={channel?.cardNumber || ''}
            className="form-control"
            onChange={e =>
              setChannel({
                ...channel,
                cardNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('dailyLimit')}
          </label>

          <MaskNumber
            defaultMaskValue={channel?.dailyLimit || ''}
            onMaskNumber={e => {
              setChannel({ ...channel, dailyLimit: e.target.value })
            }}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 me-2">
            {localize('validFrom')}
          </label>

          <CustomDatePicker
            name={'validFromChannelRenewal'}
            defaultDate={formatDate(channel?.validFrom)}
            onDateChange={date => {
              setChannel({ ...channel, validFrom: date })
            }}
            disabled={disableDates()}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4">{localize('expires')}</label>

          <CustomDatePicker
            name={'expiresChannelRenewal'}
            defaultDate={formatDate(channel?.expires)}
            onDateChange={date => {
              setChannel({ ...channel, expires: date })
            }}
            disabled={disableDates()}
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('remarks')}</label>

          <input
            className="form-control ms-1"
            value={channel?.remarks || ''}
            onChange={e =>
              setChannel({
                ...channel,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <CustomerAccountPartial
        customer={customerAccount ? { ...customerAccount } : {}}
        readOnly
      />

      <div className="mt-2 d-flex justify-content-lg-end align-items-center gap-3">
        <div className="d-flex align-items-center">
          <Checkbox
            checked={channel?.isThirdPartyNotified}
            id={'isThirdPartyNotifiedChannelDelinking'}
            onCheck={value =>
              setChannel({
                ...channel,
                isThirdPartyNotified: value,
              })
            }
          />

          <label
            className="text-capitalize"
            htmlFor="isThirdPartyNotifiedChannelDelinking"
          >
            {localize('isThirdPartyNotified')} ?
          </label>
        </div>
        <div className="d-flex align-items-center">
          <Checkbox
            checked={channel?.isLocked}
            id={'isLockedChannelDelinking'}
            onCheck={value =>
              setChannel({
                ...channel,
                isLocked: value,
              })
            }
          />

          <label className="text-capitalize" htmlFor="isLockedChannelDelinking">
            {localize('isLocked')} ?
          </label>
        </div>
      </div>
    </>
  )
}

export default ChannelRenewal
