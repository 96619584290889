/** @format */

import { getData } from '../../../../../../Helpers/webApi'
import { useEffect, useState } from 'react'
import { entitiesToBeChecked } from '../../../../../../Helpers/selectablesHelper'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { PleaseWait } from '../../../../../../Components/Loaders/PleaseWait'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CarryFowardsAdjustmentIndefiniteCharges = ({
  selectedStandingOrder,
  onGetCommissionsByDynamicCharge,
  onSelectedCommissionSplit,
}) => {
  useEffect(() => {
    if (selectedStandingOrder) {
      getDynamicCharges(selectedStandingOrder)
    }
  }, [selectedStandingOrder])

  const [dynamicCharges, setDynamicCharges] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const getDynamicCharges = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/LoanProduct/find-dynamic-charges-by-loan-product-id-and-recovery-mode',
      {
        loanProductId:
          r?.beneficiaryCustomerAccountCustomerAccountTypeTargetProductId,
        recoveryMode: '1282',
      },
      false,
      null
    )

    if (success) {
      setDynamicCharges(entitiesToBeChecked(data.result, data.result))
    }
    setIsBusy(false)
  }

  const handleOnCheckDynamicharge = r => {
    setDynamicCharges(r)
  }

  const getCommissionsByDynamicCharge = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DynamicCharge/find-commission-splits-by-dynamic-charge-id',
      {
        chargeId: r.id,
      },
      false,
      null
    )

    if (success) {
      onGetCommissionsByDynamicCharge(data.result)
    }
    setIsBusy(false)
  }

  // if (selectedStandingOrder && prevSelectedStandingOrder !== selectedStandingOrder) {
  //   setPrevSelectedStandingOrder(selectedStandingOrder)
  //   getDynamicCharges(selectedStandingOrder)
  // }

  const handleOnSingleCheck = (charge, checkValue) => {
    if (checkValue) {
      getCommissionsByDynamicCharge(charge)
      onSelectedCommissionSplit(charge)
    }
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CarryFowardsAdjustmentDynamicCharges
            onSingleCheck={handleOnSingleCheck}
            useExtendedDescription
            prefix={'dynamic'}
            dynamicChargesToBeSelected={dynamicCharges}
            onCheckDynamicCharge={handleOnCheckDynamicharge}
          />
        </>
      )}
    </>
  )
}

/** @format */

const CarryFowardsAdjustmentDynamicCharges = ({
  disabled,
  isBusy,
  dynamicChargesToBeSelected,
  onCheckDynamicCharge,
  useExtendedDescription,
  prefix,
  onSingleCheck,
}) => {
  const [currentlySelected, setCurrentlySelected] = useState(null)

  const handleCheckDynamicCharges = (dynamicCharges, checkValue) => {
    const com = [...dynamicChargesToBeSelected].map(comm => {
      if (comm.id === dynamicCharges.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    if (checkValue) {
      setCurrentlySelected(dynamicCharges)
    }

    onSingleCheck && onSingleCheck(dynamicCharges, checkValue)
    onCheckDynamicCharge(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {dynamicChargesToBeSelected?.map(dynamicCharge => (
            <li
              key={dynamicCharge.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${dynamicCharge.id}`}
                checked={dynamicCharge.id === currentlySelected?.id}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckDynamicCharges(dynamicCharge, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${dynamicCharge.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  dynamicCharge.id === currentlySelected?.id
                    ? 'text-primary fw-bold'
                    : ''
                }`}
              >
                {useExtendedDescription
                  ? dynamicCharge.extendedDescription
                  : dynamicCharge.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
