/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { CustomerFilter } from '../../../../../Global/enumeration'
import DatePicker from '../../../../../Components/Date/DatePicker'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { getData, postData } from '../../../../../Helpers/webApi'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import AddRefundProcessing from './AddRefundProcessing'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function RefundsProcessingCatalogue() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
      sortBy: 'paddedCustomerSerialNumber',
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
      sortBy: 'branchDescription',
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('refundType'),
      template: r => <>{r.refundTypeDescription}</>,
      sortBy: 'refundTypeDescription',
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
      sortBy: 'customerFullName',
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r.debitFullAccountNumber}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.creditFullAccountNumber}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('customerIdentityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('customerDutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('customerEmployer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r.approvedDate, true)}</>,
    },
    {
      label: localize('auditRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('auditedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('auditedDate'),
      template: r => <>{formatDate(r.auditedDate, true)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r.settledDate)}</>,
    },
    {
      label: localize('settlementRemarks'),
      template: r => <>{r.settlementRemarks}</>,
    },
    {
      label: localize('invalidatedBy'),
      template: r => <>{r.invalidatedBy}</>,
    },
    {
      label: localize('invalidatedDate'),
      template: r => <>{formatDate(r.invalidatedDate, true)}</>,
    },
    {
      label: localize('invalidationRemarks'),
      template: r => <>{r.invalidationRemarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [error, setError] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: 0,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('status'),
    column: 'statusDescription',
  })
  const [mode, setMode] = useState(null)
  const [selectedRefundNotification, setSelectedRefundNotification] = useState()
  const [refreshAt, setRefreshAt] = useState(false)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/RefundNotification/find-refund-notifications-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
      setError(true)
    }
  }
  const groupingColumns = [
    {
      label: localize('branchDescription'),
      column: 'branchDescription',
    },
    {
      label: localize('administrativeDivision'),
      column: 'customerAdministrativeDivisionDescription',
    },
    {
      label: localize('dutyStation'),
      column: 'customerDutyStationDescription',
    },
    {
      label: localize('business/group/employer'),
      column: 'customerEmployerDescription',
    },
    {
      label: localize('gender'),
      column: 'customerIndividualGenderDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'customerIndividualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'customerIndividualSalutationDescription',
    },
    {
      label: localize('membershipClass'),
      column: 'customerMembershipClassDescription',
    },
    {
      label: localize('refundType'),
      column: 'refundTypeDescription',
    },
    {
      label: localize('status'),
      column: 'statusDescription',
    },
  ]

  const handleAdd = async () => {
    setMode('add')
    setSelectedRefundNotification(null)
  }

  const handleInvalidate = async reg => {
    setIsBusy(true)
    const { success } = await postData(
      '/RefundNotification/invalidate-refund-notification',
      reg,
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      loadData()
      setMode(null)
      setSelectedRefundNotification(null)
      setRefreshAt(!refreshAt)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('refundNotificationsCatalogue'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                {error ? (
                  <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                    <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                    <div className="fs-5 text-danger">
                      {localize('failedToLoadData')}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <AddNewButton handleClickAddButton={handleAdd} />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2 mx-0">
                      <div className="d-flex align-items-center col-2">
                        <label
                          htmlFor="recordsPerPage"
                          className=""
                          style={{
                            marginLeft: -10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {localize('recordsPerPage')}
                        </label>
                        <select
                          name=""
                          id="recordsPerPage"
                          className="form-select ms-1"
                          style={{ maxWidth: 'fit-content' }}
                          defaultValue={reqParams.pageSize}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              pageIndex: 0,
                              pageSize: e.target.value,
                            })
                          }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>

                      <div className="col-2 d-flex align-items-center">
                        <label className="col-3  text-capitalize">
                          {localize('groupBy')}
                        </label>
                        <select
                          className="form-select text-capitalize"
                          defaultValue={groupBy.column}
                          style={{ maxWidth: 'fit-content' }}
                          onChange={e =>
                            setGroupBy({
                              label: groupingColumns.find(
                                f => f.column === e.target.value
                              )?.label,
                              column: e.target.value,
                            })
                          }
                        >
                          {groupingColumns.map(({ label, column }) => (
                            <option className="text-capitalize" value={column}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <label
                            className="text-capitalize"
                            htmlFor="searchByDate"
                          >
                            {localize('dateRange')}
                          </label>
                          <DatePicker
                            onSelect={v => {
                              if (v.custom) {
                                setReqParams({
                                  ...reqParams,
                                  customDate: true,
                                  startDate: v.startDate,
                                  endDate: v.endDate,
                                })
                              } else {
                                setReqParams({
                                  ...reqParams,
                                  customDate: false,
                                  dateRequestsFilter: v.value,
                                })
                              }
                            }}
                          />
                        </div>
                      </div>
                      <form
                        className="d-flex align-items-center col-5 col"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                          })
                        }}
                      >
                        <label
                          className="ms-2 text-capitalize me-2"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="CustomerFilter"
                          value={reqParams?.customerFilter}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              customerFilter: e.value,
                            })
                          }
                        />

                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => {
                            setSearch(e.target.value)
                            setReqParams({
                              ...reqParams,
                              text: e.target.value,
                            })
                          }}
                        />
                        <button
                          id="customerFilterButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            loadData()
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>

                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={data?.pageCollection}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('invalidate')
                              handleInvalidate(r)
                            }}
                          >
                            <i className="uil uil-crop-alt-rotate-right"></i>
                            {localize('invalidate')}
                          </div>
                        </>
                      )}
                    />
                    <div className="mb-2"></div>
                    <Pager
                      itemsCount={data.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {mode === 'add' && (
        <AddRefundProcessing
          refundNotification={selectedRefundNotification}
          closeModal={() => {
            setMode(null)
            setSelectedRefundNotification(null)
          }}
          loadTableData={loadData}
        />
      )}
    </>
  )
}
export default RefundsProcessingCatalogue
