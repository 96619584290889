/** @format */

import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { debitCardStatus } from '../../../../../Helpers/constants'
import {
  getOneYearFromNow,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import { postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const initialCardInfo = {
  cardType: 1,
  cardIssuingNetwork: 0,
  validFrom: getTodaysDate(),
  expires: getOneYearFromNow(),
}

const CreateDebitCardRegister = ({
  handleClose,
  mode,
  fetchCards,
  cardData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [card, setCard] = useState(initialCardInfo)
  const [customerAccount, setCustomerAccount] = useState({})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectCustomerAccount = data => {
    setCustomerAccount(data)
  }

  const handleCreateOrUpdateCard = async () => {
    setIsBusy(true)

    const url =
      mode === 'add'
        ? '/AlternateChannel/create-debit-card-register'
        : '/AlternateChannel/update-debit-card-register'

    if (mode === 'add') {
      const { success } = await postData(
        url,
        {
          ...customerAccount,
          ...card,
          customerAccountId: customerAccount?.id,
          status: mode === 'add' ? debitCardStatus.NEW : debitCardStatus.EDITED,
        },
        false
      )

      if (success) {
        showNotification('Create Debit Card Register', 'success')
        fetchCards()
        handleClose()
        setCard(initialCardInfo)
      }
    } else if (mode === 'edit') {
      const { success } = await putData(
        url,
        {
          ...cardData,
          ...card,
          customerAccountId: cardData?.customerAccountId,
          status: mode === 'add' ? debitCardStatus.NEW : debitCardStatus.EDITED,
        },
        false
      )

      if (success) {
        showNotification('Update Debit Card Register', 'success')
        fetchCards()
        handleClose()
        setCard(initialCardInfo)
      }
    }

    setIsBusy(false)
  }

  console.log('card', cardData)

  useEffect(() => {
    if (cardData?.id) {
      setCard(cardData)
      setCustomerAccount({
        id: cardData?.customerAccountId,
      })
    }
  }, [cardData])

  return (
    <ModalTemplate
      modalMode={mode}
      actionText={mode === 'add' ? 'create' : 'update'}
      handleAction={handleCreateOrUpdateCard}
      modalTitle="debitCardRecord"
      handleClose={() => {
        handleClose()
        setCard({})
      }}
      modalClassName="modal-xl"
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            customer={{
              id: customerAccount?.id,
            }}
            refreshFromServer
            onCustomerLookup={handleSelectCustomerAccount}
            readOnly={mode === 'edit'}
            message={globalValidationErrors['CustomerAccountId']?.message}
            onClearValidation={() => clearValidation('CustomerAccountId')}
          />

          <div className="row g-3">
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('cardType')}
              </label>

              <EnumsServerCombo
                className="ms-2"
                value={card?.cardType}
                onChange={e => setCard({ ...card, cardType: e.value })}
                enumsKey="DebitCardType"
                customContainerStyle={{
                  width: '100%',
                }}
                sort
                disabled={mode === 'edit'}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('issuingNetwork')}
              </label>

              <EnumsServerCombo
                className="ms-2"
                value={card?.cardIssuingNetwork}
                onChange={e =>
                  setCard({ ...card, cardIssuingNetwork: e.value })
                }
                enumsKey="CardIssuingNetwork"
                customContainerStyle={{
                  width: '100%',
                }}
                sort
              />
            </div>
            <div
              className="col-md-3 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['PrimaryAccountNumber']?.message
              }
            >
              <label className="text-capitalize col-4">
                {localize('pan/CardNumber')}
              </label>

              <input
                type="text"
                className="form-control ms-2"
                value={card?.primaryAccountNumber}
                onChange={e => {
                  clearValidation('PrimaryAccountNumber')
                  setCard({ ...card, primaryAccountNumber: e.target.value })
                }}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('dailyLimit')}
              </label>

              <MaskNumber
                className="ms-1"
                type="text"
                defaultMaskValue={card?.dailyLimit || 0.0}
                onMaskNumber={e =>
                  setCard({ ...card, dailyLimit: e.target.value })
                }
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('validFrom')}
              </label>

              <CustomDatePicker
                className="ms-2"
                name="createDebitCardValidFrom"
                defaultDate={card?.validFrom}
                onDateChange={date => setCard({ ...card, validFrom: date })}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('expires')}
              </label>

              <CustomDatePicker
                className="ms-2"
                name="createDebitCardExpires"
                defaultDate={card?.expires}
                onDateChange={date => setCard({ ...card, expires: date })}
              />
            </div>
            <div
              className="col-md-6 d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['Remarks']?.message}
            >
              <label className="text-capitalize col-2">
                {localize('remarks')}
              </label>

              <input
                type="text"
                value={card?.remarks}
                onChange={e => {
                  clearValidation('Remarks')
                  setCard({ ...card, remarks: e.target.value })
                }}
                className="form-control ms-1"
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateDebitCardRegister
