/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { auxiliaryLoanCondition } from '../../../../../Global/enumeration'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData, putData } from '../../../../../Helpers/webApi'
import { formatAsPercent, formatDate } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

function AuxilliaryLendingConditions({ loan, disabled }) {
  const [isBusy, setIsBusy] = useState(false)
  const [auxConditions, setAuxConditions] = useState([])
  const [targetLoanProduct, setTargetLoanProduct] = useState(null)

  const [condition, setCondition] = useState({
    condition: 1,
    maximumEligiblePercentage: 0,
  })

  useEffect(() => {
    loadConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadConditions() {
    setIsBusy(true)
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-auxiliary-conditions',
      { baseLoanProductId: loan?.id },
      false
    )
    if (success) {
      setAuxConditions(data?.result)
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  function removeCondition(c) {
    let auxConditionsCopy = [...auxConditions]
    auxConditionsCopy = auxConditionsCopy.filter(
      condition => condition.id !== c.id
    )
    setAuxConditions(auxConditionsCopy)
  }

  function addCondition() {
    let auxConditionsCopy = [...auxConditions]
    auxConditionsCopy.push({
      ...condition,
      baseLoanProductId: loan?.id,
      baseLoanProductDescription: loan?.description,
      targetLoanProductId: targetLoanProduct?.id,
      targetLoanProductDescription: targetLoanProduct?.description,
    })
    setAuxConditions(auxConditionsCopy)
    setCondition({
      condition: 1,
      maximumEligiblePercentage: 0,
    })
    setTargetLoanProduct(null)
  }

  async function updateConditions() {
    setIsBusy(true)
    const { success } = await putData(
      `/loanProduct/update-loan-product-auxiliary-conditions?baseLoanProductId=${loan?.id}`,
      auxConditions,
      false
    )
    if (success) {
      loadConditions()
    }
    setIsBusy(false)
  }
  const columns = [
    {
      label: localize('targetLoanProduct'),
      template: r => <>{r?.targetLoanProductDescription}</>,
    },
    {
      label: localize('condition'),
      template: r => <>{r?.conditionDescription}</>,
    },
    {
      label: localize('maximumEligiblePercentage'),
      template: r => <>{formatAsPercent(r?.maximumEligiblePercentage)}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={auxConditions} loading={isBusy} />
    </>
  ) : (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('targetProduct')}
                  </label>
                  <LoanProductLookup
                    displayValue={targetLoanProduct?.description || ''}
                    onSelect={l => setTargetLoanProduct(l)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('chargeType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="AuxiliaryLoanCondition"
                    value={condition?.condition}
                    onChange={e =>
                      setCondition({
                        ...condition,
                        condition: e.value,
                        conditionDescription:
                          auxiliaryLoanCondition[e.value].name,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                {
                  // eslint-disable-next-line eqeqeq
                  condition?.condition == 16 || condition?.condition == 1 ? (
                    <></>
                  ) : (
                    <div className="d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize col-4">
                        {localize('maximumEligible')} %
                      </label>
                      <input
                        type="number"
                        value={condition?.maximumEligiblePercentage}
                        onChange={e =>
                          setCondition({
                            ...condition,
                            maximumEligiblePercentage: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="bg-light d-flex align-items-center justify-content-end p-2 mb-3">
            <button
              className="btn btn-sm btn-success text-uppercase px-4"
              onClick={addCondition}
            >
              {localize('add')}
            </button>
          </div>
          <SimpleTable
            columns={columns}
            data={auxConditions}
            loading={isBusy}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-danger"
                  onClick={() => removeCondition(r)}
                >
                  <i className="uil uil-times-circle"></i>
                  <span className="text-capitalize">{localize('remove')}</span>
                </div>
              </>
            )}
          />
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={updateConditions} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AuxilliaryLendingConditions
