/** @format */

import localize from '../../../../../../Global/Localize'
import {
  DataAttachmentEntryType,
  DataAttachmentTransactionType,
} from '../../../../../../Global/enumeration'
/* eslint-disable */
import React, { useState } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import { CheckOffDataProcessingDataEntries } from './CheckOffDataProcessingDataEntries'
import { ViewCustomerAddress } from '../../../../../../Components/ReUsables/Customer/ViewCustomerAddress'
import { ViewCustomerSpecimen } from '../../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { ExistingCustomerDataEntryDetails } from './ExistingCustomerDataEntryDetails'
import { ProspectiveCustomerDataEntryDetails } from './ProspectiveCustomerDataEntryDetails'
import { postData } from '../../../../../../Helpers/webApi'
import EnumsServerCombo from './../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'

export const AddDataEntryDetails = ({ dataProcessingModel }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedDataAttachmentEntryType, setSelectedDataAttachmentEntryType] =
    useState(Object.keys(DataAttachmentEntryType)[0])
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)

  const [refreshEntries, setRefreshEntries] = useState(false)

  const [
    initializeDataAttachmentEntryModel,
    setInitializeDataAttachmentEntryModel,
  ] = useState({
    CustomerAccountId: '',
    DataAttachmentTransactionType: Object.keys(
      DataAttachmentTransactionType
    )[0],
    DataAttachmentEntryType: Object.keys(DataAttachmentEntryType)[0],
  })

  const initializeDataAttachmentEntry = async r => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/DataAttachmentPeriod/initialize-data-attachment-entry',
      {
        CustomerAccountId: r.id,
        DataAttachmentTransactionType:
          initializeDataAttachmentEntryModel.DataAttachmentTransactionType,
        DataAttachmentEntryType:
          initializeDataAttachmentEntryModel.DataAttachmentEntryType,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setInitializeDataAttachmentEntryModel({
        ...initializeDataAttachmentEntryModel,
        newAbility: data.newAbility,
        currentAmount: data.currentAmount,
        currentBalance: data.currentBalance,
        newBalance: data.newBalance,
      })
    } else {
      setIsBusy(false)
    }
  }

  const handleOnAddExistingCustomerEntrySelectedCustomerAccount = async r => {
    setSelectedCustomerAccount(r)
    setInitializeDataAttachmentEntryModel({
      ...initializeDataAttachmentEntryModel,
      CustomerAccountId: r.id,
    })
    await initializeDataAttachmentEntry(r)
  }

  const handleOnAddDataAttachmentEntry = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/DataAttachmentPeriod/add-data-attachment-entry',
      {
        ...initializeDataAttachmentEntryModel,
        EntryType: selectedDataAttachmentEntryType,
        TransactionType:
          initializeDataAttachmentEntryModel.DataAttachmentTransactionType,
        DataAttachmentPeriodId: dataProcessingModel.id,
        CustomerAccountId:
          selectedDataAttachmentEntryType ===
          Object.keys(DataAttachmentEntryType)[0]
            ? selectedCustomerAccount.id
            : '00000000-0000-0000-0000-000000000000',
      },
      false,
      'Add Data Attachment Entry'
    )
    if (success) {
      setIsBusy(false)
      setRefreshEntries(!refreshEntries)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize me-4">
                {localize('entryType')}
              </label>
              <EnumsServerCombo
                value={selectedDataAttachmentEntryType}
                customContainerStyle={{ width: '100%' }}
                enumsKey="DataAttachmentEntryType"
                onChange={e => {
                  setSelectedDataAttachmentEntryType(e.value)
                }}
              />
            </div>
          </div>
          {selectedDataAttachmentEntryType ===
          Object.keys(DataAttachmentEntryType)[0] ? (
            <ExistingCustomerDataEntryDetails
              initializeDataAttachmentEntryModel={
                initializeDataAttachmentEntryModel
              }
              setInitializeDataAttachmentEntryModel={
                setInitializeDataAttachmentEntryModel
              }
              onSelectCustomerAccount={
                handleOnAddExistingCustomerEntrySelectedCustomerAccount
              }
              onSelectDataAttachmentTransactionType={r => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  DataAttachmentTransactionType: r,
                })
              }}
            />
          ) : (
            <ProspectiveCustomerDataEntryDetails
              initializeDataAttachmentEntryModel={
                initializeDataAttachmentEntryModel
              }
              setInitializeDataAttachmentEntryModel={
                setInitializeDataAttachmentEntryModel
              }
              onSelectDataAttachmentTransactionType={r => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  DataAttachmentTransactionType: r,
                })
              }}
            />
          )}
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
            style={{ borderRadius: 5 }}
          >
            <button
              className="btn btn-sm btn-success text-uppercase px-4"
              onClick={handleOnAddDataAttachmentEntry}
            >
              {localize('add')}
            </button>
          </div>
          <div className="row mb-3 p-2">
            <Tab
              tabItems={[
                {
                  label: localize('dataEntries'),
                  item: (
                    <CheckOffDataProcessingDataEntries
                      refreshEntries={refreshEntries}
                      onSelectDataEntry={r => {}}
                      selectedDataAttachmentPeriod={dataProcessingModel}
                    />
                  ),
                },
                {
                  label: localize('address'),
                  item: <ViewCustomerAddress />,
                },
                {
                  label: localize('specimen'),
                  item: <ViewCustomerSpecimen />,
                },
              ]}
            />
          </div>
        </>
      )}
    </>
  )
}
