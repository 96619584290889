/** @format */

import localize from '../../../../../../../Global/Localize'
/* eslint-disable */
import React from 'react'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataLoanApplications = ({ loading, data }) => {
  const columns = [
    {
      label: localize('loanNumber'),
      template: r => <>{r.documentReferenceNumber}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    {
      label: localize('receivedDate'),
      template: r => <>{formatDate(r.receivedDate)}</>,
    },
    {
      label: localize('amountApplied'),
      template: r => <>{formatCurrency(r.amountApplied)}</>,
    },
    {
      label: localize('appraisedAmount'),
      template: r => <>{formatCurrency(r.appraisedAmount)}</>,
    },
    {
      label: localize('systemAppraisalRemarks'),
      template: r => <>{r.systemAppraisalRemarks}</>,
    },
    {
      label: localize('appraisalRemarks'),
      template: r => <>{r.appraisalRemarks}</>,
    },
    {
      label: localize('systemAppraisedAmount'),
      template: r => <>{formatCurrency(r.systemAppraisedAmount)}</>,
    },
    {
      label: localize('appraisedNetIncome'),
      template: r => <>{r.appraisedNetIncome}</>,
    },
    {
      label: localize('appraisedAmountRemarks'),
      template: r => <>{r.appraisedAmountRemarks}</>,
    },
    {
      label: localize('monthlyPaybackAmount'),
      template: r => <>{formatCurrency(r.monthlyPaybackAmount)}</>,
    },
    {
      label: localize('totalPaybackAmount'),
      template: r => <>{formatCurrency(r.totalPaybackAmount)}</>,
    },
    { label: localize('appraisedBy'), template: r => <>{r.appraisedBy}</> },
    {
      label: localize('appraisedDate'),
      template: r => <>{formatDate(r.appraisedDate)}</>,
    },
    {
      label: localize('approvedAmount'),
      template: r => <>{formatCurrency(r.approvedAmount)}</>,
    },
    {
      label: localize('approvedAmountRemarks'),
      template: r => <>{r.approvedAmountRemarks}</>,
    },
    {
      label: localize('approvedPrincipalPayment'),
      template: r => <>{formatCurrency(r.approvedPrincipalPayment)}</>,
    },
    {
      label: localize('approvedInterestPayment'),
      template: r => <>{formatCurrency(r.approvedInterestPayment)}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r.approvalRemarks}</>,
    },
    { label: localize('approvedBy'), template: r => <>{r.approvedBy}</> },
    { label: localize('approvedDate'), template: r => <>{r.approvedDate}</> },
    { label: localize('auditRemarks'), template: r => <>{r.auditRemarks}</> },
    {
      label: localize('auditTopUpAmount'),
      template: r => <>{formatCurrency(r.auditTopUpAmount)}</>,
    },
    { label: localize('auditedBy'), template: r => <>{r.auditedBy}</> },
    { label: localize('auditedDate'), template: r => <>{r.auditedDate}</> },
    {
      label: localize('disbursedAmount'),
      template: r => <>{formatCurrency(r.disbursedAmount)}</>,
    },
    {
      label: localize('disbursementRemarks'),
      template: r => <>{r.disbursementRemarks}</>,
    },
    { label: localize('disbursedBy'), template: r => <>{r.disbursedBy}</> },
    { label: localize('disbursedDate'), template: r => <>{r.disbursedDate}</> },
    {
      label: localize('reversalRemarks'),
      template: r => <>{r.reversalRemarks}</>,
    },
    { label: localize('reversedBy'), template: r => <>{r.reversedBy}</> },
    { label: localize('reversedDate'), template: r => <>{r.reversedDate}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r.loanPurposeDescription}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => <>{r.loanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => <>{r.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => <>{r.loanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('term'),
      template: r => <>{r.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('minimumMembershipPeriod'),
      template: r => <>{r.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => (
        <>{r.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('loanBalance'),
      template: r => <>{formatCurrency(r.loanProductLoanBalance)}</>,
    },
    {
      label: localize('attachedLoanBalance'),
      template: r => <>{formatCurrency(r.totalAttachedLoansBalance)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={data} columns={columns} />
      )}
    </>
  )
}
