/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const VehicleRouteLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: 'name', template: r => <>{r.description}</> },
    {
      label: 'isLocked',
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: 'modifiedBy', template: r => <>{r.modifiedBy}</> },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: 'createdBy', template: r => <>{r.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
  ]

  return (
    <>
      <SimpleLookup
        onSelect={onSelect}
        displayValue={displayValue}
        url="/vehicleRoute/find-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        title={localize('vehicleRouteLookup')}
      />
    </>
  )
}

export default VehicleRouteLookup
