/** @format */

import localize from '../../../../../../../../../Global/Localize'
import Checkbox from '../../../../../../../../../Components/Checkbox/Checkbox'
/* eslint-disable */
import React, { useState } from 'react'
import SimpleTable from '../../../../../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../../../../../Helpers/formatters'

export const CustomerAccountTransactionChequePayables = ({
  onSelectChequePayables,
  chequePayables,
}) => {
  const [selectedChequePayables, setSelectedChequePayables] = useState([])
  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedChequePayables.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedChequePayables([...selectedChequePayables, r])
              onSelectChequePayables([...selectedChequePayables, r])
            } else {
              setSelectedChequePayables(
                selectedChequePayables.filter(s => s.id !== r.id)
              )
              onSelectChequePayables(
                selectedChequePayables.filter(s => s.id !== r.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={chequePayables} />
    </>
  )
}
