/** @format */

import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr'
import { CONFIG, SESSION_KEY } from './constants'
import authHelper from './authHelper'
import 'toastr/build/toastr.min.css'
import { User } from 'oidc-client-ts'

class SignalRService extends EventTarget {
  constructor() {
    super()
    this.connection = null
    this.machineInformation = null
    this.buildSignalRConnection()
  }

  buildSignalRConnection() {
    if (
      this.connection &&
      this.connection.state !== HubConnectionState.Disconnected
    ) {
      console.warn(
        'Attempting to rebuild an active connection. Disconnect first.'
      )
      return
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(
        `${
          CONFIG.SIGNAL_R_APP_URL
        }/online-notification-hub?X-Machine=${encodeURIComponent(
          this.machineInformation
        )}`,
        {
          accessTokenFactory: () => this._getAccessToken(),
          withCredentials: false,
        }
      )
      .configureLogging(this._getLoggingLevel())
      .withAutomaticReconnect()
      .build()
  }

  startConnection() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      this.connection
        .start()
        .then(() => {
          this.registerSignalREvents()
        })
        .catch(err => console.error('Error starting the connection:', err))
    } else {
      console.warn('Connection is already in progress or connected.')
    }
  }

  setMachineInformation(machineInformation) {
    this.machineInformation = machineInformation
    this.buildSignalRConnection()
    this.dispatchEvent(new Event('readyToStartConnection'))
  }

  initializeConnection() {
    this.dispatchEvent(new Event('readyToStartConnection'))
  }

  _getAccessToken() {
    const oidcStorage = sessionStorage.getItem(SESSION_KEY)
    if (!oidcStorage) {
      return ''
    }
    return User.fromStorageString(oidcStorage).access_token
  }

  _getLoggingLevel() {
    const env = process.env.NODE_ENV
    return env === 'development' ? LogLevel.Information : LogLevel.Error
  }

  registerSignalREvents() {
    this.registerOnLockApplication()
    this.registerOnShowNotification()
    this.registerOnRefreshMenu()
    this.registerOnReceiveClientMenus()
    this.registerOnTellerSummariesInformation()
  }

  registerOnLockApplication() {
    this.connection.on('LockApplication', () => {
      this.dispatchEvent(new Event('lockApplication'))
    })
  }

  registerOnShowNotification() {
    this.connection.on('ShowNotification', message => {
      this.dispatchEvent(
        new CustomEvent('onShowNotification', { detail: message })
      )
    })
  }

  registerOnRefreshMenu() {
    this.connection.on('RefreshMenus', menus => {
      this.dispatchEvent(new CustomEvent('onRefreshMenus', { detail: menus }))
      authHelper.updateUserMenuCodes(menus.join(', '))
      return menus
    })
  }

  registerOnReceiveClientMenus() {
    this.connection.on('ReceiveClientMenus', message => {
      authHelper.updateUserMenuCodes(message)
    })
  }

  registerOnTellerSummariesInformation() {
    this.connection.on('tellerSummaries', _message => {
      this.dispatchEvent(new CustomEvent('tellerSummaries'))
    })
  }

  notifyUsersInSystemPermissionType(systemPermissionType, message) {
    this.connection.invoke(
      'NotifyUsersInSystemPermissionType',
      systemPermissionType,
      message
    )
  }

  async notifyUsersInRole(roleId, message) {
    const response = await this.connection.invoke(
      'NotifyUsersInRole',
      roleId,
      message
    )

    return response
  }

  notifyUser(thirdPartyReference, message) {
    this.connection.invoke('NotifyUser', thirdPartyReference, message)
  }

  notifyConnectedUsers(message) {
    this.connection.invoke('NotifyConnectedUsers', message)
  }
}

const signalRService = new SignalRService()

signalRService.addEventListener('readyToStartConnection', () => {
  const oidcStorage = sessionStorage.getItem(SESSION_KEY)
  if (oidcStorage) {
    signalRService.startConnection()
  }
})

export default signalRService
