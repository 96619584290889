/** @format */

import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'

function LoanCaseHistory({ history }) {
  const [localHistory, setLocalHistory] = useState(history)

  useEffect(() => {
    setLocalHistory(history)
  }, [history])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('environmentOperatingSystem'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  return <SimpleTable columns={columns} data={localHistory} />
}

export default LoanCaseHistory
