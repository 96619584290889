/** @format */

/* eslint-disable */
import React from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
import { TransactionDetails } from './Partials/TransactionDetails'
import { CashTransferRequests } from './Partials/CashTransferRequests'

const CashTransfer = () => {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('teller'),
          localize('cashTransfer'),
        ]}
      />
      <div className="card rounded bg-white">
        <div className="card-body">
          <Tab
            preload
            tabItems={[
              {
                label: localize('transactionDetails'),
                item: <TransactionDetails />,
              },
              {
                label: localize('cashTransferRequests'),
                item: <CashTransferRequests />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default CashTransfer
