/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const AccountAlertsPreference = ({
  accountAlertsPreference,
  addMode,
  onRemove,
}) => {
  const columns = [
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    { label: localize('threshold'), template: r => <>{r.threshold}</> },
    {
      label: localize('maskTransactionValue'),
      template: r => (
        <span className="text-capitalize">
          {r.maskTransactionValue.toString()}
        </span>
      ),
    },
    {
      label: localize('receiveTextAlert'),
      template: r => (
        <span className="text-capitalize">{r.receiveTextAlert.toString()}</span>
      ),
    },
    {
      label: localize('receiveEmailAlert'),
      template: r => (
        <span className="text-capitalize">
          {r.receiveEmailAlert.toString()}
        </span>
      ),
    },
    {
      label: localize('maskAccountBalance'),
      template: r => (
        <span className="text-capitalize">
          {r.maskAccountBalance.toString()}
        </span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => (
        <span className="text-capitalize">{formatDate(r.createdDate)}</span>
      ),
    },
  ]

  return (
    <SimpleTable
      contextMenu={r =>
        addMode && (
          <>
            <div
              onClick={() => {
                onRemove(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash text-danger"></i>
              {localize('remove')}
            </div>
          </>
        )
      }
      selectables
      columns={columns}
      data={accountAlertsPreference}
    />
  )
}
