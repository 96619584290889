import localize from "../../Global/Localize";
import { formatDate } from "../../Helpers/formatters";
import SimpleLookup from "./SimpleLookup";

function CompanyLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize("companyLookup")}
      url="/lookup/Company/find-company-by-filter-in-page"
      params={{ filterText: "", pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize("name"),
          template: (r) => <>{r.description}</>,
          sortBy: "description",
        },
        {
          label: localize("currency"),
          template: (r) => <>{r.currencyDescription}</>,
          sortBy: "currencyDescription",
        },
        {
          label: localize("vision"),
          template: (r) => <>{r.vision}</>,
        },
        {
          label: localize("mission"),
          template: (r) => <>{r.mission}</>,
        },
        {
          label: localize("motto"),
          template: (r) => <>{r.motto}</>,
        },
        {
          label: localize("registrationNumber"),
          template: (r) => <>{r.registrationNumber}</>,
        },
        {
          label: localize("personalIdentificationNumber"),
          template: (r) => <>{r.personalIdentificationNumber}</>,
        },
        {
          label: localize("addressLine") + " 1",
          template: (r) => <>{r.addressAddressLine1}</>,
        },
        {
          label: localize("addressLine") + " 2",
          template: (r) => <>{r.addressAddressLine2}</>,
        },
        {
          label: localize("physicalAddress"),
          template: (r) => <>{r.addressStreet}</>,
        },
        {
          label: localize("postalCode"),
          template: (r) => <>{r.addressPostalCode}</>,
        },
        {
          label: localize("city"),
          template: (r) => <>{r.addressCity}</>,
        },
        {
          label: localize("emailAddress"),
          template: (r) => <>{r.addressEmail}</>,
        },
        {
          label: localize("landLine"),
          template: (r) => <>{r.addressLandLine}</>,
        },
        {
          label: localize("addressMobileLine"),
          template: (r) => <>{r.addressMobileLine}</>,
        },
        {
          label: localize("internetAddress"),
          template: (r) => <>{r.addressInternet}</>,
        },
        {
          label: localize("txRcptIndentation(T)"),
          template: (r) => <>{r.transactionReceiptTopIndentation}</>,
        },
        {
          label: localize("txRcptIndentation(L)"),
          template: (r) => <>{r.transactionReceiptLeftIndentation}</>,
        },
        {
          label: localize("txRcptFooter"),
          template: (r) => <>{r.transactionReceiptFooter}</>,
        },
        {
          label: localize("fingerPrintBiometricThreshold"),
          template: (r) => <>{r.fingerprintBiomentricThreshold}</>,
        },
        {
          label: localize("defaultCustomerFilter"),
          template: (r) => <>{r.defaultCustomerFilterDescription}</>,
        },
        {
          label: localize("customerStatementPassword"),
          template: (r) => <>{r.customerStatementPasswordDescription}</>,
        },
        {
          label: localize("customerStatementFooter"),
          template: (r) => <>{r.customerStatementFooter}</>,
        },
        {
          label: localize("immediateMemberNumber"),
          template: (r) => <>{r.immediateMemberNumber}</>,
        },
        {
          label: localize("immediateAccountNumber"),
          template: (r) => <>{r.immediateAccountNumber}</>,
        },
        {
          label: localize("systemInitializationTime"),
          template: (r) => <>{r.timeDurationStartTime}</>,
        },
        {
          label: localize("systemLockTime"),
          template: (r) => <>{r.timeDurationEndTime}</>,
        },
        {
          label: localize("isLocked"),
          template: (r) => <>{r.isLocked}</>,
        },
        {
          label: localize("createdDate"),
          template: (r) => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  );
}
export default CompanyLookup;
