/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import AddCustomer from '../../../../Registry/Members/Partials/Customers/AddCustomer'
import ReactSelect from 'react-select'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'

function ExistingGuarantors({ guarantors, reloadGuarantors }) {
  const [localGuarantors, setLocalGuarantors] = useState([])
  const [distinctLoanProducts, setDistinctLoanProducts] = useState([])
  const [customer, setCustomer] = useState(null)
  const [filterBy, setFilterBy] = useState(null)
  const [selectedGuarantors, setSelectedGuarantors] = useState([])

  useEffect(() => {
    setLocalGuarantors(guarantors)
    loadDistinctProducts(guarantors)
    // eslint-disable-next-line
  }, [guarantors])

  function loadDistinctProducts(gs) {
    let loanProducts = new Set(gs.map(x => x.loanProductDescription))
    setDistinctLoanProducts([
      { label: 'All Products', value: null },
      ...Array.from(loanProducts).map(x => ({ label: x, value: x })),
    ])
  }

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={!!selectedGuarantors.find(x => x.id === r.id)}
          onCheck={() => {
            let isChecked = !!selectedGuarantors.find(x => x.id === r.id)
            console.warn('isChecked', isChecked)
            if (isChecked) {
              let removed = [...selectedGuarantors].filter(x => x.id !== r.id)
              setSelectedGuarantors(removed)
            } else {
              setSelectedGuarantors([...selectedGuarantors, r])
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    { label: localize('customerName'), template: r => r?.customerFullName },
    {
      label: localize('membershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('identificationNumber'),
      template: r => r?.customerIdentificationNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanProductDescription,
    },
    {
      label: localize('loanCaseApprovedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => formatCurrency(r?.loanBalanceOutstanding),
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => r?.loanDefaultAmount,
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment),
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanBranch'),
      template: r => r?.loanCaseBranchDescription,
    },
    {
      label: localize('totalShares'),
      template: r => formatCurrency(r?.totalShares),
    },
    {
      label: localize('committedShares'),
      template: r => formatCurrency(r?.committedShares),
    },
    {
      label: localize('amountGuaranteed'),
      template: r => formatCurrency(r?.amountGuaranteed),
    },
    {
      label: localize('amountPledged'),
      template: r => formatCurrency(r?.amountPledged),
    },
    { label: localize('appraisalFactor'), template: r => r?.appraisalFactor },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  async function handleReleaseAttach() {
    const { success } = await postData(
      '/loanCase/toggle-release-loan-guarantors',
      selectedGuarantors
    )
    if (success) {
      setSelectedGuarantors([])
      reloadGuarantors()
    }
  }

  return (
    <>
      <div className="row my-2 mb-3">
        <div className="d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize">
            {localize('filterBy')}
          </label>
          <div>
            <ReactSelect
              options={distinctLoanProducts}
              value={[...distinctLoanProducts].filter(
                g => g.value === filterBy
              )}
              onChange={({ value }) => setFilterBy(value)}
            />
          </div>
        </div>
      </div>
      <SimpleTable
        columns={columns}
        data={
          filterBy
            ? [...localGuarantors].filter(
                x => x.loanProductDescription === filterBy
              )
            : localGuarantors
        }
        contextMenu={r => (
          <>
            <div className="ctxt-menu-item" onClick={() => setCustomer(r)}>
              <i className="uil uil-eye"></i>
              <span className="text-capitalize">{localize('view')}</span>
            </div>
          </>
        )}
      />
      {selectedGuarantors?.length > 0 && (
        <div className="rounded border border-secondary p-2 bg-light d-flex align-items-center justify-content-end">
          <button
            className="px-4 btn btn-success"
            onClick={handleReleaseAttach}
          >
            {localize('release')} / {localize('attach')}
          </button>
        </div>
      )}
      {customer && (
        <AddCustomer
          refreshFromServer
          readOnly
          mode="view"
          customer={{ id: customer?.customerId }}
          closeModal={() => setCustomer(null)}
          onMinimize={() => {}}
        />
      )}
    </>
  )
}

export default ExistingGuarantors
