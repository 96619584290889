/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { postData, putData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import CustomerAccountLookup from '../../../../../Components/Lookup/CustomerAccountLookup'
import MobileToBankTerminalLookup from '../../../../../Components/Lookup/MobileToBankTerminalLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import MobileToBankCollectionAddress from './MobileToBankCollectionAddress'

const CreateMobileToBankCollectionModal = ({
  mode,
  selectedMobileToBankCollection,
  setSelectedMobileToBankCollection,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/MobileToBankCollection/create'
        : '/MobileToBankCollection/update'
    const { success } =
      mode === 'add'
        ? await postData(url, selectedMobileToBankCollection, false)
        : await putData(url, selectedMobileToBankCollection, false)

    if (success) {
      setIsBusy(false)
      close()
      loadTableData()
      showNotification('Mobilse To Bank Collection', 'success')
    }
    setIsBusy(false)
  }

  function close() {
    closeModal()
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle={'mobileToBankCollection'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={close}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cMobileToBankCollectionReceiveTextAlert"
              onCheck={e => {
                setSelectedMobileToBankCollection({
                  ...selectedMobileToBankCollection,
                  receiveTextAlert: e,
                })
              }}
              checked={selectedMobileToBankCollection?.receiveTextAlert}
            />
            <label
              htmlFor="cMobileToBankCollectionReceiveTextAlert"
              className="text-capitalize"
            >
              {localize('receiveTextAlert')}?
            </label>
            <div className="mx-3"></div>

            <Checkbox
              id="cMobileToBankCollectionReceiveEmailAlert"
              onCheck={e => {
                setSelectedMobileToBankCollection({
                  ...selectedMobileToBankCollection,
                  receiveEmailAlert: e,
                })
              }}
              checked={selectedMobileToBankCollection?.receiveEmailAlert}
            />
            <label
              htmlFor="cMobileToBankCollectionReceiveEmailAlert"
              className="text-capitalize"
            >
              {localize('receiveEmailAlert')}?
            </label>
            <div className="mx-3"></div>
            <Checkbox
              id="cMobileToBankCollectionIsLocked"
              onCheck={e => {
                setSelectedMobileToBankCollection({
                  ...selectedMobileToBankCollection,
                  isLocked: e,
                })
              }}
              checked={selectedMobileToBankCollection?.isLocked}
            />
            <label
              htmlFor="cMobileToBankCollectionIsLocked"
              className="text-capitalize"
            >
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card bg-white">
                <div className="card-body">
                  <>
                    {isBusy ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="row mb-2">
                          <div
                            className="col d-flex align-items-center with-validation"
                            validation-message={
                              globalValidationErrors[
                                'CustomerAccountCustomerId'
                              ]?.message
                            }
                          >
                            <label className="col-4 me-2 text-capitalize">
                              {localize('fullAccountNumber')}
                            </label>
                            <CustomerAccountLookup
                              displayValue={
                                selectedMobileToBankCollection?.customerAccountCustomerAccountNumber ||
                                ''
                              }
                              type="text"
                              className="form-control ms-2"
                              onSelect={v => {
                                clearValidation('CustomerAccountCustomerId')
                                setSelectedMobileToBankCollection(prev => ({
                                  ...prev,
                                  customerAccountCustomerAccountNumber:
                                    v.fullAccountNumber,
                                  customerAccountId: v.id,
                                  customerAccountCustomerId: v.customerId,
                                  statusDescription: v.statusDescription,
                                  remarks: v.remarks,
                                  customerAccountCustomerAccountTypeTargetProductDescription:
                                    v.customerAccountTypeTargetProductDescription,
                                  customerFullName: v.customerFullName,
                                  customerAccountCustomerMembershipClassCustomerTypeDescription:
                                    v.customerMembershipClassCustomerTypeDescription,
                                  customerAccountCustomerMembershipClassEmployerDescription:
                                    v.customerEmployerDescription,
                                  customerAccountCustomerMembershipClassIdentityCardNumber:
                                    v.customerIndividualIdentityCardNumber,
                                  customerAccountCustomerReference1:
                                    v.customerReference1,
                                  customerAccountCustomerReference2:
                                    v.customerReference2,
                                  customerAccountCustomerReference3:
                                    v.customerReference3,
                                }))
                              }}
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('accountStatus')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.statusDescription ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('accountRemarks')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.remarks || ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('productName')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerAccountTypeTargetProductDescription ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('customerName')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerFullName ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('customerType')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('customerMemberNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerReference2 ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('employer')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerMembershipClassEmployerDescription ||
                                ''
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('identificationNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerMembershipClassIdentityCardNumber ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('ref_1(Account #)')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerReference1 ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('ref_2(Membership #)')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerReference2 ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('ref_3(Personal File #)')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              disabled
                              value={
                                selectedMobileToBankCollection?.customerAccountCustomerReference3 ||
                                ''
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div
                            className="col d-flex align-items-center with-validation"
                            validation-message={
                              globalValidationErrors['MobileToBankTerminalId']
                                ?.message
                            }
                          >
                            <label className="col-4 me-2 text-capitalize">
                              {localize('mobileToBankTerminal')}
                            </label>
                            <MobileToBankTerminalLookup
                              displayValue={
                                selectedMobileToBankCollection?.mobileToBankTerminalDescription ||
                                ''
                              }
                              onSelect={v => {
                                clearValidation('MobileToBankTerminalId')
                                setSelectedMobileToBankCollection(prev => ({
                                  ...prev,
                                  mobileToBankTerminalDescription:
                                    v.description,
                                  mobileToBankTerminalId: v.id,
                                }))
                              }}
                            />
                          </div>
                          <div
                            className="col d-flex align-items-center with-validation"
                            validation-message={
                              globalValidationErrors['Description']?.message
                            }
                          >
                            <label className="col-4 text-capitalize">
                              {localize('name')}
                            </label>
                            <input
                              type="text"
                              className="form-control ms-2"
                              value={
                                selectedMobileToBankCollection?.description ||
                                ''
                              }
                              onChange={e => {
                                clearValidation('Description')
                                setSelectedMobileToBankCollection({
                                  ...selectedMobileToBankCollection,
                                  description: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('reference')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                selectedMobileToBankCollection?.reference || ''
                              }
                              onChange={e => {
                                setSelectedMobileToBankCollection({
                                  ...selectedMobileToBankCollection,
                                  reference: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>

                  <div className="row mb-3">
                    <div className="col md-12">
                      <Tab
                        tabItems={[
                          {
                            label: localize('address'),
                            item: (
                              <MobileToBankCollectionAddress
                                selectedMobileToBankCollection={
                                  selectedMobileToBankCollection
                                }
                                setSelectedMobileToBankCollection={
                                  setSelectedMobileToBankCollection
                                }
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateMobileToBankCollectionModal
