/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import localize from '../../../../Global/Localize'
import { getData } from '../../../../Helpers/webApi'
import { messageGroupTarget } from '../../../../Global/enumeration'
import Loader from '../../../../Components/Loaders/Loader'

function RolesTab({ onSelectedRoleChange, selectedRoleData, onSelectAll }) {
  const [roles, setRoles] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const fetchRoles = async () => {
    setIsBusy(true)

    const url = '/lookup/Role/find-roles'
    const { success, data } = await getData(url, {}, false)
    if (success) {
      setRoles(
        data.result.map(e => ({
          ...e,
          targetIdentifier: e.description,
          messageGroupTarget: Object.keys(messageGroupTarget)[2],
          surveyGroupTarget: Object.keys(messageGroupTarget)[2],
        }))
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  return (
    <>
      <div
        className="row  mb-2 position-sticky top-0"
        style={{
          zIndex: 10000,
        }}
      />
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div
            className="row"
            style={{
              maxHeight: '50vh',
              overflowY: 'auto',
            }}
          >
            <div className="col-md-6">
              <div className="row">
                {roles.map(role => (
                  <div
                    className="col-md-12"
                    key={role.id}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex align-items-center justify-content-start">
                      <Checkbox
                        id={role.id}
                        checked={selectedRoleData?.find(
                          data => data.targetIdentifier === role?.description
                        )}
                        onCheck={v => onSelectedRoleChange(role, v)}
                      />
                      <label className="text-capitalize ms-3" htmlFor={role.id}>
                        {localize(role.description)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-start">
            <Checkbox
              id="roles-in-crm"
              checked={selectAll}
              onCheck={v => {
                setSelectAll(v)
                onSelectAll(roles, v)
              }}
            />
            <label className="text-capitalize ms-3" htmlFor="roles-in-crm">
              {localize('toggleSelection')}
            </label>
          </div>
        </>
      )}
    </>
  )
}

export default RolesTab
