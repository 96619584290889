/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _l = {
  leaveTypes: {
    en: 'leave types',
    sw: 'aina za mapumziko',
  },
  leave: {
    en: 'Leave',
    sw: 'Mapumziko',
  },
  leafNumber: {
    en: 'leaf number',
    sw: 'idadi ya karatasi ya hundi',
  },
  loaningRemarks: {
    en: 'loaning remarks',
    sw: 'maoni ya mkopo',
  },
  loanDisbursementModes: {
    en: 'loan disbursement modes',
    sw: 'njia za malipo ya mkopo',
  },
  landLine: {
    en: 'land line',
    sw: 'nambari ya simu ya nyumbani',
  },
  loanClassificationDate: {
    en: 'loan classification date',
    sw: 'tarehe ya uainishaji wa mkopo',
  },
  lowerLimit: {
    en: 'lower limit',
    sw: 'kiwango cha chini',
  },
  levies: {
    en: 'levies',
    sw: 'ushuru',
  },
  leviesAndCharges: {
    en: 'levies and charges',
    sw: 'ushuru na gharama',
  },
  loans: {
    en: 'loans',
    sw: 'mikopo',
  },
  loanProductValidation: {
    en: 'loan product validation',
    sw: 'uthibitisho wa bidhaa ya mkopo',
  },
  loanProductValidationFailed: {
    en: 'loan product validation',
    sw: 'uthibitishaji wa bidhaa ya mkopo umeshindwa',
  },
  loanPurposes: {
    en: 'loan purposes',
    sw: 'madhumuni ya mkopo',
  },
  loaningRemark: {
    en: 'loaning remark',
    sw: 'oni ya mkopo',
  },
  loanRepaymentModes: {
    en: 'loan repayment modes',
    sw: 'njia za kurejesha mkopo',
  },
  loanPenalties: {
    en: 'loan penalties',
    sw: 'adhabu ya mkopo',
  },
  liabilities: {
    en: 'liabilities',
    sw: 'madeni',
  },
  levySplits: {
    en: 'levy splits',
    sw: 'mgawanyiko wa kodi',
  },
  leviable: {
    en: 'leviable',
    sw: 'inaweza kulipwa',
  },
  logo: {
    en: 'logo',
    sw: 'nembo',
  },
  locationDetails: {
    en: 'location details',
    sw: 'maelezo ya eneo',
  },
  loanRepaymentMode: {
    en: 'loan repayment mode',
    sw: 'njia ya kulipa mkopo',
  },
  locations: {
    en: 'locations',
    sw: 'mahali',
  },
  loanProductLookup: {
    en: 'loan product lookup',
    sw: 'utafutaji wa bidhaa za mkopo',
  },
  logoMaxHeight: {
    en: 'logo max height',
    sw: 'nembo urefu wa juu',
  },
  logoMaxWidth: {
    en: 'logo max width',
    sw: 'nembo upana wa juu',
  },
  levy: {
    en: 'levy',
    sw: 'kodi',
  },
  loanProducts: {
    en: 'loan products',
    sw: 'bidhaa za mikopo',
  },
  loanDisbursementMode: {
    en: 'loan disbursement mode',
    sw: 'njia ya malipo ya mkopo',
  },
  loanProduct: {
    en: 'loan product',
    sw: 'bidhaa ya mikopo',
  },
  loanAccounts: {
    en: 'loan accounts',
    sw: 'akaunti za mikopo',
  },
  loanProductExemption: {
    en: 'loan product exemption',
    sw: 'msamaha wa bidhaa ya mkopo',
  },
  loanProductExemptions: {
    en: 'loan product exemptions',
    sw: 'msamaha wa bidhaa za mkopo',
  },
  limit: {
    en: 'limit',
    sw: 'kikomo',
  },
  loanRegistrationLoanProductCategoryDescription: {
    en: 'loan registration loan product category description',
    sw: 'maelezo ya kitengo cha bidhaa ya mkopo wa mkopo',
  },
  LoanRegistrationTermInMonths: {
    en: 'loan registration term in months',
    sw: 'muda wa usajili wa mkopo katika miezi',
  },
  loanInterestAnnualPercentageRate: {
    en: 'loan interest annual percentage rate',
    sw: 'kiwango cha asilimia ya kila mwaka cha mkopo',
  },
  loanInterestOffsettingPercentageRate: {
    en: 'loan interest offsetting percentage rate',
    sw: 'kiwango cha asilimia ya kufidia riba ya mkopo',
  },
  loanInterestCalculationModeDescription: {
    en: 'loan interest calculation mode description',
    sw: 'maelezo ya njia ya kuhesabu riba ya mkopo',
  },
  loanRegistrationPaymentFrequencyPerYearDescription: {
    en: 'loan registration payment frequency per year description',
    sw: 'maelezo ya malipo ya usajili wa mkopo kwa mwaka',
  },
  loanRegistrationMinimumAmount: {
    en: 'loan registration minimum amount',
    sw: 'kiwango cha chini cha usajili wa mkopo',
  },
  loanRegistrationMaximumAmount: {
    en: 'loan registration maximum amount',
    sw: 'kiwango cha juu cha usajili wa mkopo',
  },
  loanRegistrationMinimumInterestAmount: {
    en: 'loan registration minimum interest amount',
    sw: 'kiwango cha chini cha riba ya mkopo',
  },
  loanRegistrationMinimumNetDisbursementAmount: {
    en: 'loan registration minimum net disbursement amount',
    sw: 'kiwango cha chini cha malipo ya mkopo',
  },
  loanRegistrationLoanProductSectionDescription: {
    en: 'loan registration loan product section description',
    sw: 'maelezo ya sehemu ya bidhaa ya mkopo ya usajili wa mkopo',
  },
  loanRegistrationConsecutiveIncome: {
    en: 'loan registration consecutive income',
    sw: 'mapato yanayofuata ya mkopo',
  },
  loanRegistrationInvestmentsMultiplier: {
    en: 'loan registration investments multiplier',
    sw: 'kizidishi cha uwekezaji wa mkopo',
  },
  loanRegistrationMinimumInvestments: {
    en: 'loan registration minimum investments',
    sw: 'kiwango cha chini cha uwekezaji wa mkopo',
  },
  loanRegistrationMinimumGuarantors: {
    en: 'loan registration minimum guarantors',
    sw: 'kiwango cha chini cha wadhamini wa mkopo',
  },
  loanRegistrationMaximumGuarantees: {
    en: 'loan registration maximum guarantees',
    sw: 'kiwango cha juu cha dhamana ya mkopo',
  },
  loanInterestChargeModeDescription: {
    en: 'loan interest charge mode description',
    sw: 'maelezo ya njia ya malipo ya riba ya mkopo',
  },
  loanInterestRecoveryModeDescription: {
    en: 'loan interest recovery mode description',
    sw: 'maelezo ya njia ya kurejesha riba ya mkopo',
  },
  loanRegistrationAggregateCheckOffRecoveryModeDescription: {
    en: 'loan registration aggregate check-off recovery mode description',
    sw: 'maelezo ya njia ya ukusanyaji wa ukaguzi wa mkopo',
  },
  loanRegistrationPayoutRecoveryModeDescription: {
    en: 'loan registration payout recovery mode description',
    sw: 'maelezo ya njia ya kurejesha malipo ya mkopo',
  },
  loanRegistrationPayoutRecoveryPercentage: {
    en: 'loan registration payout recovery percentage',
    sw: 'asilimia ya malipo ya mkopo',
  },
  loanRegistrationMinimumMembershipPeriod: {
    en: 'loan registration minimum membership period',
    sw: 'kiwango cha chini cha muda wa uanachama',
  },
  loanRegistrationStandingOrderTriggerDescription: {
    en: 'loan registration standing order trigger description',
    sw: 'maelezo ya kusimama kwa amri ya mkopo',
  },
  loanRegistrationGracePeriod: {
    en: 'loan registration grace period',
    sw: 'kipindi cha neema cha usajili wa mkopo',
  },
  loanRegistrationGuarantorSecurityModeDescription: {
    en: 'loan registration guarantor security mode description',
    sw: 'maelezo ya njia ya dhamana ya wadhamini wa mkopo',
  },
  loanRegistrationIncomeAssessmentModeDescription: {
    en: 'loan registration income assessment mode description',
    sw: 'maelezo ya njia ya tathmini ya mapato ya mkopo',
  },
  loanRegistrationRoundingTypeDescription: {
    en: 'loan registration rounding type description',
    sw: 'maelezo ya aina ya kukadiria ya mkopo',
  },
  loanRegistrationMaximumSelfGuaranteeEligiblePercentage: {
    en: 'loan registration maximum self guarantee eligible percentage',
    sw: 'kiwango cha juu cha asilimia inayostahiki dhamana ya mkopo',
  },
  loanRegistrationEvaluateStandingOrderHistoryForArrearagesRecovery: {
    en: 'loan registration evaluate standing order history for arrearages recovery',
    sw: 'tathmini historia ya amri ya kusimama kwa urejeshaji wa malimbikizo',
  },
  loanRegistrationPermitPartialOffsetting: {
    en: 'loan registration permit partial offsetting',
    sw: 'ruhusu kufidia sehemu',
  },
  loanRegistrationEnforceConsecutiveIncomeValidation: {
    en: 'loan registration enforce consecutive income validation',
    sw: 'thibitisha mapato yanayofuata ya mkopo',
  },
  loanRegistrationAllowDiscretionaryTopup: {
    en: 'loan registration allow discretionary topup',
    sw: 'maelezo ya kitengo cha bidhaa ya mkopo wa mkopo',
  },
  loanRegistrationLoaneeMustBorrowScoredLoanLimit: {
    en: 'loan registration loanee must borrow scored loan limit',
    sw: 'mkopaji lazima akopeshe kikomo cha mkopo kilichopimwa',
  },
  loanRegistrationThrottleMicroLoanDisbursement: {
    en: 'loan registration throttle micro loan disbursement',
    sw: 'kupunguza malipo ya mkopo wa mkopo',
  },
  loanRegistrationCreateStandingOrderOnLoanAudit: {
    en: 'loan registration create standing order on loan audit',
    sw: 'unda amri ya kusimama kwenye ukaguzi wa mkopo',
  },
  loanRegistrationDisburseMicroLoanLessDeductions: {
    en: 'loan registration disburse micro loan less deductions',
    sw: 'malipo ya mkopo wa mkopo chini ya makato',
  },
  loanRegistrationEnforceSystemAppraisalRecommendation: {
    en: 'loan registration enforce system appraisal recommendation',
    sw: 'thibitisha mapendekezo ya tathmini ya mfumo',
  },
  loanRegistrationChargeArrearsFee: {
    en: 'loan registration charge arrears fee',
    sw: 'malipo ya malimbikizo ya malipo',
  },
  loanRegistrationBypassAudit: {
    en: 'loan registration bypass audit',
    sw: 'kupuuza ukaguzi',
  },
  loanRegistrationThrottleScheduledArrearsRecovery: {
    en: 'loan registration throttle scheduled arrears recovery',
    sw: 'kupunguza urejeshaji wa malimbikizo uliopangwa',
  },
  loanRegistrationTrackArrears: {
    en: 'loan registration track arrears',
    sw: 'fuatilia malimbikizo',
  },
  loanRegistrationMicrocredit: {
    en: 'loan registration microcredit',
    sw: 'mkopo wa usajili wa microcredit',
  },
  loanRegistrationChargeClearanceFee: {
    en: 'loan registration charge clearance fee',
    sw: 'ada ya kibali cha usajili wa mkopo',
  },
  loanRegistrationAllowSelfGuarantee: {
    en: 'loan registration allow self guarantee',
    sw: 'ruhusu dhamana ya mkopo',
  },
  loanRegistrationSecurityRequired: {
    en: 'loan registration security required',
    sw: 'usajili wa mkopo uliolindwa',
  },
  loanRegistrationRejectIfMemberHasBalance: {
    en: 'loan registration reject if member has balance',
    sw: 'kataa usajili wa mkopo ikiwa mwanachama ana usawa',
  },
  loansGuaranteed: {
    en: 'loans guaranteed',
    sw: 'mikopo iliyohakikishwa',
  },
  loanNumber: {
    en: 'loan number',
    sw: 'nambari ya mkopo',
  },
  loanClassification: {
    en: 'loan classification',
    sw: 'uanishaji wa mkopo',
  },
  loanAmount: {
    en: 'loan amount',
    sw: 'kiwango cha mkopo',
  },
  loanBalanceExpected: {
    en: 'loan balance expected',
    sw: 'mkopo uliotarajiwa',
  },
  loanPrepaidAmount: {
    en: 'loan prepaid amount',
    sw: 'mkopo uliolipwa mapema',
  },
  loanArrearsAmount: {
    en: 'loan arrears amount',
    sw: 'kiasi mkopo wa malimbikizo ya malipo',
  },
  loanArrearsTimeline: {
    en: 'loan arrears timeline',
    sw: 'ratiba ya malimbikizo ya mkopo',
  },
  loanLossProvision: {
    en: 'loan loss provision',
    sw: 'hifadhi ya hasara ya mkopo',
  },
  loanDisbursedDate: {
    en: 'loan disbursed date',
    sw: 'tarehe ya kutolewa kwa mkopo',
  },
  loanGracePeriod: {
    en: 'loan grace period',
    sw: 'kipindi cha neema ya mkopo',
  },
  lastLoanPaymentDueDate: {
    en: 'last loan payment due date',
    sw: 'tarehe ya mwisho ya malipo ya mkopo',
  },
  lastLoanPayment: {
    en: 'last loan payment',
    sw: 'malipo ya mkopo ya mwisho',
  },
  loanTerm: {
    en: 'loan term',
    sw: 'muda wa mkopo',
  },
  loanAnnualPercentageRate: {
    en: 'loan annual percentage rate',
    sw: 'kiwango cha asilimia ya mkopo kwa mwaka',
  },
  loanPaymentFrequencyPerYear: {
    en: 'loan payment frequency per year',
    sw: 'mkopo wa malipo mara ngapi kwa mwaka',
  },
  loanInterestCalculationMode: {
    en: 'loan interest calculation mode',
    sw: 'hali ya kuhesabu riba ya mkopo',
  },
  loanGuaranteed: {
    en: 'loan guaranteed',
    sw: 'mkopo uliohakikishwa',
  },
  loansBalance: {
    en: 'loans balance',
    sw: 'mkopo uliobaki',
  },
  leftHand: {
    en: 'left hand',
    sw: 'mkono wa kushoto',
  },
  loanPurposeLookup: {
    en: 'loan purpose lookup',
    sw: 'uchunguzi wa kusudi la mkopo',
  },
  lastNumber: {
    en: 'last number',
    sw: 'nambari ya mwisho',
  },
  loan: {
    en: 'loan',
    sw: 'mkopo',
  },
  loanProductsExemptions: {
    en: 'loan products exemptions',
    sw: 'msamaha wa bidhaa za mkopo',
  },
  loanBalance: {
    en: 'loan balance',
    sw: 'mkopo uliobaki',
  },
  loanPurpose: {
    en: 'loan purpose',
    sw: 'kusudi la mkopo',
  },
  loanStatus: {
    en: 'loan status',
    sw: 'hali ya mkopo',
  },
  loanBranch: {
    en: 'loan branch',
    sw: 'tawi la mkopo',
  },
  logs: {
    en: 'logs',
    sw: 'maigizo',
  },
  localCurrency: {
    en: 'local currency',
    sw: 'sarafu ya nyumbani',
  },
  loanCycles: {
    en: 'loan cycles',
    sw: 'mzunguko wa mkopo',
  },
  loaneeMultiplier: {
    en: 'loanee multiplier',
    sw: 'kizidishi cha aliyekopa',
  },
  loaneeDivisor: {
    en: 'loanee divisor',
    sw: 'mgawanyaji wa mkopo',
  },
  lookUp: {
    en: 'Look Up',
    sw: 'Tafuta',
  },
  ledger: {
    en: 'ledger',
    sw: 'leja',
  },
  ledgerEntries: {
    en: 'ledger entries',
    sw: 'ingizo la leja',
  },
  ledgerDetails: {
    en: 'ledger details',
    sw: 'maelezo ya leja',
  },
  'loanArrearsTimeline(Days)': {
    en: 'loan arrears timeline (days)',
    sw: 'ratiba ya malimbikizo ya mkopo (siku)',
  },
  'loanLossProvision(Percentage)': {
    en: 'loan loss provision (percentage)',
    sw: 'hifadhi ya hasara ya mkopo (asilimia)',
  },
  'loanLossProvision(Amount)': {
    en: 'loan loss provision (amount)',
    sw: 'hifadhi ya hasara ya mkopo (kiasi)',
  },
  'loanTerm(Months)': {
    en: 'loan term (months)',
    sw: 'mkopo wa muda (miezi)',
  },
  lookupMode: {
    en: 'lookup mode',
    sw: 'hali ya utafutaji',
  },
  longitude: {
    en: 'longitude',
    sw: 'longitudo',
  },
  latitude: {
    en: 'latitude',
    sw: 'latitudo',
  },
  loanRegistrationTermInMonths: {
    en: 'loan registration term in months',
    sw: 'muda wa usajili wa mkopo katika miezi',
  },
  loanOrigination: {
    en: 'loan origination',
    sw: 'asili ya mkopo',
  },
  loanRequests: {
    en: 'loan requests',
    sw: 'ombi la mkopo',
  },
  loanAppraisal: {
    en: 'loan appraisal',
    sw: 'tathmini ya mkopo',
  },
  'loanPre-qualification': {
    en: 'loan pre-qualification',
    sw: 'maandalizi ya mkopo',
  },
  loanRegistration: {
    en: 'loan registration',
    sw: 'usajili wa mkopo',
  },
  loanApproval: {
    en: 'loan approval',
    sw: 'idhini ya mkopo',
  },
  loanVerification: {
    en: 'loan verification',
    sw: 'uthibitisho wa mkopo',
  },
  loanCancellation: {
    en: 'loan cancellation',
    sw: 'ukatishaji wa mkopo',
  },
  loanApplications: {
    en: 'loan applications',
    sw: 'maombi ya mkopo',
  },
  loanDisbursement: {
    en: 'loan disbursement',
    sw: 'malipo ya mkopo',
  },
  LoanRestructuring: {
    en: 'loan restructuring',
    sw: 'urekebishaji wa mkopo',
  },
  loanRescheduleRequests: {
    en: 'loan reschedule requests',
    sw: 'ombi la kurekebisha mkopo',
  },
  loanInterestCharging: {
    en: 'loan interest charging',
    sw: 'malipo ya riba ya mkopo',
  },
  loanInterestChargingAdHoc: {
    en: 'loan interest charging ad hoc',
    sw: 'malipo ya riba ya mkopo ad hoc',
  },
  loanInterestAdjustment: {
    en: 'loan interest adjustment',
    sw: 'marekebisho ya riba ya mkopo',
  },
  loanArrearagesRecovery: {
    en: 'loan arrearages recovery',
    sw: 'urejeshaji wa malimbikizo ya mkopo',
  },
  loanRecoveryExceptions: {
    en: 'loan recovery exceptions',
    sw: 'makosa ya urejeshaji wa mkopo',
  },
  loanCaseLinkage: {
    en: 'loan case linkage',
    sw: 'kiungo cha mkopo',
  },
  loanAgingSpools: {
    en: 'loan aging Spools',
    sw: 'vibiringo vya umri wa mkopo',
  },
  loanInfo: {
    en: 'loan info',
    sw: 'maelezo ya mkopo',
  },
  loanProductCategory: {
    en: 'loan product category',
    sw: 'kitengo cha bidhaa ya mkopo',
  },
  loanPart: {
    en: 'loan part',
    sw: 'sehemu ya mkopo',
  },
  loanInterest: {
    en: 'loan interest',
    sw: 'riba ya mkopo',
  },
  loanDisbursementBatch: {
    en: 'loan disbursement batch',
    sw: 'malipo ya mkopo kwa wingi',
  },
  'lookupTargetLoanApplication(s)': {
    en: 'lookup target loan application(s)',
    sw: 'tafuta maombi ya mkopo',
  },
  'lock/Unlock': {
    en: 'lock/Unlock',
    sw: 'funga/fungua',
  },
  'loansAccounts(netOff)': {
    en: 'loans accounts (net off)',
    sw: 'akaunti za mkopo',
  },
  loanLossProvisionPercentage: {
    en: 'loan loss provision(percentage)',
    sw: 'asilimia ya hifadhi ya hasara ya mkopo',
  },
  loanLastPaymentDueDate: {
    en: 'loan last payment due date',
    sw: 'tarehe ya mwisho ya malipo ya mkopo',
  },
  loanLastPayment: {
    en: 'loan last payment',
    sw: 'malipo ya mkopo ya mwisho',
  },
  lastName: {
    en: 'last name',
    sw: 'jina la mwisho',
  },
  lowestDaysInArrears: {
    en: 'lowest days in arrears',
    sw: 'siku za chini za malimbikizo',
  },
  lendorSector: {
    en: 'lendor sector',
    sw: 'sekta ya mkopeshaji',
  },
  lastPaymentAmount: {
    en: 'last payment amount',
    sw: 'kiasi cha malipo ya mwisho',
  },
  lastPaymentDate: {
    en: 'last payment date',
    sw: 'tarehe ya malipo ya mwisho',
  },
  loadedAt: {
    en: 'loaded at',
    sw: 'imepakiwa',
  },
  loanCaseDocumentReferenceNumber: {
    en: 'loan case document reference number',
    sw: 'nambari ya kumbukumbu ya hati ya mkopo',
  },
  loanCaseDisbursedAmount: {
    en: 'loan case disbursed amount',
    sw: 'kiasi kilicholipwa cha mkopo',
  },
  loanRegistrationLoanProductCategory: {
    en: 'loan registration loan product category',
    sw: 'kitengo cha bidhaa ya mkopo',
  },
  loanCaseLoanRegistrationTermInMonths: {
    en: 'loan case loan registration term in months',
    sw: 'muda wa usajili wa mkopo katika miezi',
  },
  loanCaseCustomerMembershipClassCustomerTypeDescription: {
    en: 'loan case customer membership class customer type description',
    sw: 'maelezo ya aina ya mteja wa darasa la uanachama wa mteja',
  },
  loanCaseCustomerReference1: {
    en: 'loan case customer reference 1',
    sw: 'kumbukumbu ya mteja 1',
  },
  loanCaseCustomerReference2: {
    en: 'loan case customer reference 2',
    sw: 'kumbukumbu ya mteja 2',
  },
  loanCaseReference: {
    en: 'loan case reference',
    sw: 'kumbukumbu ya mkopo',
  },
  loanCode: {
    en: 'loan code',
    sw: 'nambari ya mkopo',
  },
  loanName: {
    en: 'loan name',
    sw: 'jina la mkopo',
  },
  lastLoanRepaymentDueDate: {
    en: 'last loan repayment due date',
    sw: 'tarehe ya mwisho ya malipo ya mkopo',
  },
  loanProductSection: {
    en: 'loan product section',
    sw: 'sehemu ya bidhaa ya mkopo',
  },
  latestIncome: {
    en: 'latest income',
    sw: 'mapato ya hivi karibuni',
  },
  loanDisbursementModeLookup: {
    en: 'loan disbursement mode lookup',
    sw: 'utafutaji wa njia ya malipo ya mkopo',
  },
  loanDisbursementBatchLookup: {
    en: 'loan disbursement batch lookup',
    sw: 'utafutaji wa malipo ya mkopo kwa wingi',
  },
  loanInterestChargeMode: {
    en: 'loan interest charge mode',
    sw: 'njia ya malipo ya riba ya mkopo',
  },
  loanInterestRecoveryMode: {
    en: 'loan interest recovery mode',
    sw: 'njia ya kurejesha riba ya mkopo',
  },
  loanRegistrationStandingOrderTrigger: {
    en: 'loan registration standing order trigger',
    sw: 'amri ya kuu ya usajili wa mkopo',
  },
  loanRegistrationGuarantorSecurityMode: {
    en: 'loan registration guarantor security mode',
    sw: 'njia ya dhamana ya wadhamini wa mkopo',
  },
  loanRegistrationPaymentFrequencyPerYear: {
    en: 'loan registration payment frequency per year',
    sw: 'malipo ya usajili wa mkopo kwa mwaka',
  },
  loanRegistrationIncomeAssessmentMode: {
    en: 'loan registration income assessment mode',
    sw: 'njia ya tathmini ya mapato ya mkopo',
  },
  loanProductLoanBalance: {
    en: 'loan product loan balance',
    sw: 'mkopo uliobaki',
  },
  loansAccounts: {
    en: 'loans accounts',
    sw: 'akaunti za mkopo',
  },
  loanGuarantors: {
    en: 'loan guarantors',
    sw: 'wadhamini wa mkopo',
  },
  loanApprovedAmount: {
    en: 'loan approved amount',
    sw: 'kiasi kilichoidhinishwa cha mkopo',
  },
  loanBalanceOutstanding: {
    en: 'loan balance outstanding',
    sw: 'mkopo uliobaki',
  },
  loanLossProvisionAmount: {
    en: 'loan loss provision amount',
    sw: 'hifadhi ya hasara ya mkopo',
  },
  'loanPrincipalBalanceOutstanding(principal)': {
    en: 'loan principal balance outstanding (principal)',
    sw: 'mkopo uliobaki (msingi)',
  },
  'loanInterestBalanceOutstanding(interest)': {
    en: 'loan interest balance outstanding (interest)',
    sw: 'mkopo uliobaki (riba)',
  },
  landline: {
    en: 'land line',
    sw: 'simu ya mezani',
  },
  loaneeMustBorrowScoredLoanLimit: {
    en: 'loanee must borrow scored loan limit',
    sw: 'mkopaji lazima akopeshe kikomo cha mkopo kilichopimwa',
  },
  loanRescheduleRequest: {
    en: 'loan reschedule request',
    sw: 'ombi la kurekebisha mkopo',
  },
  loanRescheduleRequestLookup: {
    en: 'loan reschedule request lookup',
    sw: 'utafutaji wa ombi la kurekebisha mkopo',
  },
  loanRecoveryException: {
    en: 'loan recovery exception',
    sw: 'makosa ya urejeshaji wa mkopo',
  },
  lastPaymentDueDate: {
    en: 'last payment due date',
    sw: 'tarehe ya mwisho ya malipo',
  },
  lastPayment: {
    en: 'last payment',
    sw: 'malipo ya mwisho',
  },
  loanRegistrationDetails: {
    en: 'loan registration details',
    sw: 'maelezo ya usajili wa mkopo',
  },
  loanHistory: {
    en: 'loan history',
    sw: 'historia ya mkopo',
  },
  loanCollaterals: {
    en: 'loan collaterals',
    sw: 'dhamana ya mkopo',
  },
  loanClassificationMode: {
    en: 'loan classification mode',
    sw: 'njia ya uainishaji wa mkopo',
  },
  loanDefaultDate: {
    en: 'loan default date',
    sw: 'tarehe ya mkopo',
  },
  loanTermMonths: {
    en: 'loan term (months)',
    sw: 'mkopo wa muda (miezi)',
  },
  loanRoundingType: {
    en: 'loan rounding type',
    sw: 'aina ya kukadiria ya mkopo',
  },
  loanClassificationDescription: {
    en: 'loan classification description',
    sw: 'maelezo ya uainishaji wa mkopo',
  },
  loanDefaultTimeline: {
    en: 'loan default timeline',
    sw: 'ratiba ya mkopo',
  },
  loanRequest: {
    en: 'loan request',
    sw: 'ombi la mkopo',
  },
  liquidate: {
    en: 'liquidate',
    sw: 'kufuta',
  },
  liquidateFixedDeposit: {
    en: 'liquidate fixed deposit',
    sw: 'futa amana ya kudumu',
  },
  liquidation: {
    en: 'liquidation',
    sw: 'kufuta',
  },
  lookupTargetNotification: {
    en: 'lookup target notification',
    sw: 'tafuta taarifa',
  },
  loanCase: {
    en: 'loan case',
    sw: 'kumbukumbu ya mkopo',
  },
  loanDisbursementQueueLookup: {
    en: 'loan disbursement queue lookup',
    sw: 'utafutaji wa malipo ya mkopo',
  },
  loanCycle: {
    en: 'loan cycle',
    sw: 'mzunguko wa mkopo',
  },
  leadDays: {
    en: 'lead days',
    sw: 'siku za kuongoza',
  },
  location: {
    en: 'location',
    sw: 'eneo',
  },
  locationLookup: {
    en: 'location lookup',
    sw: 'utafutaji wa eneo',
  },
  initialStatementStartDate: {
    en: 'initial statement start date',
    sw: 'tarehe ya kuanza taarifa',
  },
  loanCaseApprovedAmount: {
    en: 'loan case approved amount',
    sw: 'kiasi kilichoidhinishwa cha mkopo',
  },
}
export default _l
