/** @format */

import { PleaseWait } from '../../../../../Components/Loaders/PleaseWait'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

export const Roles = ({
  disabled,
  isBusy,
  rolesToBeSelected,
  handleOnChangeRequiredApprovers,
  onCheckRole,
  prefix,
}) => {
  const handleCheckRoles = (role, checkValue) => {
    const updatedRolesList = [...rolesToBeSelected].map(comm => {
      if (comm.id === role.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckRole(updatedRolesList, role, checkValue)
  }

  return (
    <div
      className="px-4 row g-0 d-flex align-items-center"
      style={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      {isBusy ? (
        <PleaseWait />
      ) : (
        <>
          <div className="col-md-3">
            <h5 className="text-capitalize">{localize('role')}</h5>
            <div className="row g-2">
              {rolesToBeSelected &&
                rolesToBeSelected.map(role => (
                  <div
                    key={role.id}
                    className=" d-flex align-items-center col-md-12"
                  >
                    <Checkbox
                      id={`${prefix ? prefix : 'com'}-${role?.id}`}
                      checked={role?.checked}
                      disabled={disabled}
                      margined
                      onCheck={c => {
                        console.log(c)
                        handleCheckRoles(role, c)
                      }}
                    />
                    <label
                      htmlFor={`${prefix ? prefix : 'com'}-${role?.id}`}
                      className={`fw-normal ms-2 cursor-pointer ${
                        role?.checked ? 'text-primary fw-bold' : ''
                      }`}
                    >
                      {role?.roleName}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-md-5">
            <h5 className="text-capitalize">{localize('requiredApprovers')}</h5>
            <div className="row g-2">
              {rolesToBeSelected &&
                rolesToBeSelected.length > 0 &&
                rolesToBeSelected.map(role => (
                  <div className="col-12">
                    <MaskNumber
                      key={role.id}
                      className="form-control w-50"
                      numeralThousandsGroupStyle={'none'}
                      defaultMaskValue={role?.requiredApprovers}
                      onMaskNumber={e => {
                        handleOnChangeRequiredApprovers(role, e.target.value)
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
