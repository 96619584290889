/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../Global/Localize'
import {
  formatDate,
  getTwoWeeksFromDate,
  parseDate,
} from '../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import { getData, postData, putData } from '../../../Helpers/webApi'
import { CustomerPartial } from '../../../Components/ReUsables/Customer/CustomerPartial'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import Pager from '../../../Components/Tables/Pager'
import {
  populationRegisterFilter,
  populationRegisterQueryAuthOption,
  populationRegisterQueryStatus,
} from '../../../Global/enumeration'
import Tab from '../../../Components/Tabs/Tab'
import Swal from 'sweetalert2'
import DatePicker from '../../../Components/Date/DatePicker'
import Specimen from '../Members/Partials/Tabs/Specimen'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'

function PopulationRegister() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('errorResponse'),
      template: r => <>{r.errorResponse}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('membership'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: localize('identityType'),
      template: r => <>{r.identityTypeDescription}</>,
    },
    {
      label: localize('identityNumber'),
      template: r => <>{r.identityNumber}</>,
    },
    {
      label: localize('identitySerialNumber'),
      template: r => <>{r.identitySerialNumber}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
      sortBy: 'remarks',
    },
    {
      label: localize('idNumber'),
      template: r => <>{r.idNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.serialNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.gender}</>,
    },
    {
      label: localize('firstName'),
      template: r => <>{r.firstName}</>,
      sortBy: 'firstName',
    },
    {
      label: localize('otherName'),
      template: r => <>{r.otherName}</>,
    },
    {
      label: localize('surname'),
      template: r => <>{r.surname}</>,
    },
    {
      label: localize('pin'),
      template: r => <>{r.pin}</>,
    },
    {
      label: localize('citizenship'),
      template: r => <>{r.citizenship}</>,
    },
    {
      label: localize('family'),
      template: r => <>{r.family}</>,
    },
    {
      label: localize('clan'),
      template: r => <>{r.clan}</>,
    },
    {
      label: localize('ethnicGroup'),
      template: r => <>{r.ethnicGroup}</>,
    },
    {
      label: localize('occupation'),
      template: r => <>{r.occupation}</>,
    },
    {
      label: localize('placeOfBirth'),
      template: r => <>{r.placeOfBirth}</>,
    },
    {
      label: localize('placeOfDeath'),
      template: r => <>{r.placeOfDeath}</>,
    },
    {
      label: localize('placeOfLive'),
      template: r => <>{r.placeOfLive}</>,
    },
    {
      label: localize('registrationOffice'),
      template: r => <>{r.regOffice}</>,
    },
    {
      label: localize('dateOfBirth'),
      template: r => <>{formatDate(r.dateOfBirth, true)}</>,
    },
    {
      label: localize('dateOfBirthFromPassport'),
      template: r => <>{formatDate(r.dateOfBirthFromPassport, true)}</>,
    },
    {
      label: localize('dateOfDeath'),
      template: r => <>{formatDate(r.dateOfDeath, true)}</>,
    },
    {
      label: localize('dateOfIssue'),
      template: r => <>{formatDate(r.dateOfIssue, true)}</>,
    },
    {
      label: localize('dateOfExpiry'),
      template: r => <>{formatDate(r.dateOfExpiry, true)}</>,
    },
    {
      label: localize('authorizedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorizationRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorizedDate'),
      template: r => <>{formatDate(r.authorizedDate, false)}</>,
    },
    {
      label: localize('thirdPartyResponse'),
      template: r => <>{r.thirdPartyResponse}</>,
    },
    {
      label: localize('thirdPartyWalletResponse'),
      template: r => <>{r.thirdPartyWalletResponse}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 0,
    filterText: '',
    populationRegisterFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedPopulationRegister, setSelectedPopulationRegister] =
    useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(populationRegisterQueryAuthOption)[0]
  )

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'add'
        ? await postData(
            '/PopulationRegister/create-population-register-query',
            selectedPopulationRegister,
            false,
            'Operation Completed Successfully'
          )
        : await putData(
            '/PopulationRegister/authorize-population-register-query',
            {
              populationRegisterQuery: {
                ...selectedPopulationRegister,
                /**@TODO: alex-stands for the branchId of the logged in user-7/13/2023 */
                branchId: '0e58e292-bcf3-ed11-849a-402343441bbc',
              },
              populationRegisterQueryAuthOption: selectedAuth,
            },
            false,
            'Operation Completed Successfully'
          )

    if (success) {
      setMode(null)
      setSelectedPopulationRegister(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedPopulationRegister(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/PopulationRegister/find-population-register-queries-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
    }
    setIsBusy(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedPopulationRegister(null)
    setSelectedPopulationRegister(null)
  }

  const handleRequeryRegister = reg => {
    setIsBusy(true)
    const { success } = putData(
      '/PopulationRegister/requery-population-register-query',
      reg,
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setMode(null)
      setSelectedPopulationRegister(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('cusomerRegisterQueries'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleAdd} />
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="d-flex align-items-center col-2">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex align-items-center col-2">
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="PopulationRegisterQueryStatus"
                            value={reqParams?.status}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              className="text-capitalize"
                              htmlFor="searchByDate"
                            >
                              {localize('dateRange')}
                            </label>
                            <DatePicker
                              onSelect={v => {
                                if (v.custom) {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: true,
                                    startDate: v.startDate,
                                    endDate: v.endDate,
                                  })
                                } else {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: false,
                                    dateRequestsFilter: v.value,
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        <form
                          className="d-flex align-items-center col-4 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <label
                            className="ms-2 text-capitalize"
                            htmlFor="searchTable"
                          >
                            {localize('search')}
                          </label>
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="PopulationRegisterFilter"
                            value={reqParams?.populationRegisterFilter}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                populationRegisterFilter: e.value,
                              })
                            }
                          />
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.filterText}
                            onChange={e => {
                              setSearch(e.target.value)
                              setReqParams({
                                ...reqParams,
                                filterText: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="populationQueryFilterButton"
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable
                          contextMenu={r => (
                            <>
                              <div
                                onClick={async () => {
                                  setMode('view')
                                  setSelectedPopulationRegister({
                                    fullName: r.customerFullName,
                                    membershipClassCustomerTypeDescription:
                                      r.customerMembershipClassCustomerTypeDescription,
                                    membershipStatusDescription:
                                      r.customerMembershipClassCustomerStatusDescription,
                                    branchDescription:
                                      r.customerBranchDescription,
                                    employerDescription:
                                      r.customerEmployerDescription,
                                    dutyStationDescription:
                                      r.customerDutyStationDescription,
                                    administrativeDivisionDescription:
                                      r.customerAdministrativeDivisionDescription,
                                    identificationNumber:
                                      r.identificationNumber,
                                    formattedMemberNumber: r.customerReference2,
                                    formattedAccountNumber:
                                      r.customerReference1,
                                    personalFileNumber: r.customerReference3,
                                    addressAddressLine1:
                                      r.customerAddressAddressLine1,
                                    addressAddressLine2:
                                      r.customerAddressAddressLine2,
                                    addressStreet: r.customerAddressStreet,
                                    addressCity: r.customerAddressCity,
                                    addressPostalCode:
                                      r.customerAddressPostalCode,
                                    addressEmail: r.customerAddressEmail,
                                    addressLandLine: r.customerAddressLandLine,
                                    addressMobileLine:
                                      r.customerAddressMobileLine,
                                    addressInternet: r.customerAddressInternet,
                                    remarks: r.remarks,
                                  })
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-eye"></i>
                                {localize('view')}
                              </div>
                              <div
                                onClick={async () => {
                                  if (r.status == 0) {
                                    setMode('authorize')
                                    setSelectedPopulationRegister({
                                      id: r.id,
                                      fullName: r.customerFullName,
                                      membershipClassCustomerTypeDescription:
                                        r.customerMembershipClassCustomerTypeDescription,
                                      membershipStatusDescription:
                                        r.customerMembershipClassCustomerStatusDescription,
                                      branchDescription:
                                        r.customerBranchDescription,
                                      employerDescription:
                                        r.customerEmployerDescription,
                                      dutyStationDescription:
                                        r.customerDutyStationDescription,
                                      administrativeDivisionDescription:
                                        r.customerAdministrativeDivisionDescription,
                                      identificationNumber:
                                        r.identificationNumber,
                                      formattedMemberNumber:
                                        r.customerReference2,
                                      formattedAccountNumber:
                                        r.customerReference1,
                                      personalFileNumber: r.customerReference3,
                                      addressAddressLine1:
                                        r.customerAddressAddressLine1,
                                      addressAddressLine2:
                                        r.customerAddressAddressLine2,
                                      addressStreet: r.customerAddressStreet,
                                      addressCity: r.customerAddressCity,
                                      addressPostalCode:
                                        r.customerAddressPostalCode,
                                      addressEmail: r.customerAddressEmail,
                                      addressLandLine:
                                        r.customerAddressLandLine,
                                      addressMobileLine:
                                        r.customerAddressMobileLine,
                                      addressInternet:
                                        r.customerAddressInternet,
                                      remarks: r.remarks,
                                    })
                                  } else {
                                    Swal.fire(
                                      'Ooops!',
                                      'Sorry, but you have to select an entry whose status is new',
                                      'info'
                                    )
                                  }
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-unlock-alt"></i>
                                {localize('authorize')}
                              </div>
                              <div
                                onClick={async () => {
                                  if (r.errorResponse === null) {
                                    Swal.fire(
                                      'Ooops!',
                                      'Sorry, but you must first select a population register query that has an error response',
                                      'info'
                                    )
                                  } else {
                                    setMode('requery')
                                    handleRequeryRegister(r)
                                  }
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-corner-down-left"></i>
                                {localize('requery')}
                              </div>
                            </>
                          )}
                          data={tableData}
                          columns={columns}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && mode !== 'requery' && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize(mode)}
            <i className="uil uil-angle-right mx-2"></i>
            <span className="text-muted fs-6 text-uppercase">
              {localize('cusomerRegisterQueries')}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white">
            <div className="row">
              <div className="col-12">
                <div className="card bg-white">
                  <div className="card-body">
                    <>
                      <CustomerPartial
                        customer={selectedPopulationRegister}
                        message={
                          globalValidationErrors['CustomerFullName']?.message
                        }
                        onCustomerLookup={r => {
                          clearValidation('CustomerFullName')
                          setSelectedPopulationRegister({
                            ...r,
                            customerFullName: r.customerFullName,
                            customerId: r.id,
                            remarks: r.remarks,
                            IdentityNumber: r.identificationNumber,
                          })
                        }}
                        mode={mode}
                      />
                    </>
                    {mode !== 'view' ? (
                      <div className="row mb-3 g-3">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center mb-2">
                            <label className="text-capitalize me-5">
                              {localize('remarks')}
                            </label>
                            <input
                              defaultValue={selectedPopulationRegister?.remarks}
                              type="text"
                              className="form-control ms-3"
                              onChange={e => {
                                setSelectedPopulationRegister({
                                  ...selectedPopulationRegister,
                                  remarks: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {mode === 'view' ? (
                      <>
                        <div className="row mb-2">
                          <div className="col-6 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('errorResponse')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.errorResponse || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('idNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.idNumber || ''}
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('serialNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.serialNumber || ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('gender')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.gender || ''}
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('firstName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.firstName || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('otherName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.otherName || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('surname')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.surname || ''}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('pin')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.pin || ''}
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('citizenship')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.citizenship || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('family')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.family || ''}
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('clan')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={selectedPopulationRegister?.clan || ''}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('ethnicGroup')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.ethnicGroup || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('occupation')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.occupation || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfBirth')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.placeOfBirth || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfDeath')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.placeOfDeath || ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfLive')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.placeOfLive || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('regOffice')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.regOffice || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfBirth')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.dateOfBirth || ''
                              }
                            />
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfBirthFromPassport')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.dateOfBirthFromPassport ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfDeath')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.dateOfDeath || ''
                              }
                            />
                          </div>
                          <div className="col-md-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfIssue')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.dateOfIssue || ''
                              }
                            />
                          </div>
                          <div className="col-md-3 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfExpiry')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedPopulationRegister?.dateOfExpiry || ''
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    {mode === 'view' ? (
                      <div className="row mb-3">
                        <div className="col md-12">
                          <Tab
                            tabItems={[
                              {
                                label: localize('specimen'),
                                item: (
                                  <Specimen
                                    customer={selectedPopulationRegister}
                                    readOnly
                                  />
                                ),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="bg-white">
            {mode !== 'view' ? (
              <>
                {mode === 'authorize' ? (
                  <>
                    <div className="col-md-3 mt-2">
                      <div className="d-flex align-items-center mb-1">
                        <label className="text-capitalize col-4 me-2">
                          {localize('authorizationRemarks')}
                        </label>
                        <input
                          defaultValue={
                            selectedPopulationRegister?.authorizationRemarks
                          }
                          type="text"
                          className="form-control ms-4"
                          onChange={e => {
                            setSelectedPopulationRegister({
                              ...selectedPopulationRegister,
                              authorizationRemarks: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-1 mt-3">
                      <div className="d-flex align-items-center mb-2">
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="PopulationRegisterQueryAuthOption"
                          onChange={e =>
                            setSelectedAuth({
                              populationRegisterQueryAuthOption: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <button
                  className="btn btn-danger text-capitalize"
                  onClick={closeModal}
                >
                  {localize('cancel')}
                </button>
                <button
                  className="btn btn-success text-capitalize"
                  onClick={() => {
                    if (selectedPopulationRegister === null) {
                      Swal.fire(
                        'Ooops!',
                        'Sorry, but you have to select a customer',
                        'info'
                      )
                    } else {
                      handleSubmit()
                    }
                  }}
                >
                  {localize(mode === 'add' ? 'create' : 'update')}
                </button>
              </>
            ) : (
              <button
                className="btn btn-danger text-capitalize"
                onClick={closeModal}
              >
                {localize('cancel')}
              </button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default PopulationRegister
