/** @format */

export const creditBatchFlagsList = [
  {
    name: 'Preserve Account Balance?',
    checked: false,
    value: 'preserveAccountBalance',
  },
  {
    name: 'Fuzzy Matching?',
    checked: false,
    value: 'fuzzyMatching',
  },
  {
    name: 'Enforce Checkoff Cut-Off Date?',
    checked: false,
    value: 'enforceCheckoffCutOffDate',
  },
]
