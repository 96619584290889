/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

export const ExceptedCustomers = ({
  exceptedCustomers,
  onRemoveEntry,
  mode,
  handleOnCheckCustomerExceptionEntry,
  removeSelectedExceptionEntries,
}) => {
  const [selectedExceptedCustomers, setSelectedExceptedCustomers] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)

  useEffect(() => {
    handleOnCheckCustomerExceptionEntry &&
      handleOnCheckCustomerExceptionEntry(selectedExceptedCustomers)
  }, [selectedExceptedCustomers])

  let columns = [
    {
      label: '',
      template: r =>
        mode === 'edit' ? (
          <Checkbox
            checked={selectedExceptedCustomers.find(obj => obj.id === r.id)}
            onCheck={v => {
              addNewConcessionExceptionEntry(r, v)
            }}
          />
        ) : (
          <></>
        ),
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    { label: localize('name'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('ref_1Account'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2Membership'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const onCheckConcessionExceptionEntry = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedExceptedCustomers([])
      setSelectedExceptedCustomers([...exceptedCustomers])
    } else {
      setSelectedExceptedCustomers([])
      setToggleSelection(checkStatus)
    }
  }

  const addNewConcessionExceptionEntry = (customer, checkStatus) => {
    if (checkStatus) {
      if (!selectedExceptedCustomers.some(obj => obj.id === customer.id)) {
        setSelectedExceptedCustomers(prev => {
          return [...selectedExceptedCustomers, customer]
        })
      }
    } else {
      setSelectedExceptedCustomers(prev => {
        return prev.filter(obj => obj.id !== customer.id)
      })
    }
  }

  return (
    <>
      <SimpleTable
        selectables
        contextMenu={r =>
          mode === 'add' && (
            <>
              <div
                onClick={() => {
                  onRemoveEntry(r)
                }}
                className="ctxt-menu-item"
              >
                <i className="uil uil-trash text-danger"></i>
                {localize('remove')}
              </div>
            </>
          )
        }
        columns={columns}
        data={exceptedCustomers}
      />
      {mode === 'edit' && (
        <>
          <div className="d-flex align-items-end me-5 mt-2">
            <Checkbox
              id="toggle-selection"
              checked={toggleSelection}
              onCheck={v => {
                onCheckConcessionExceptionEntry(v)
              }}
            />
            <label htmlFor="toggle-selection" className="text-capitalize ms-2">
              {localize('toggleSelection')}
            </label>
          </div>
          <div className="d-flex justify-content-end align-items-end me-5 mt-2">
            <button
              onClick={removeSelectedExceptionEntries}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('remove')}
            </button>
          </div>
        </>
      )}
    </>
  )
}
