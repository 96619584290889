/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import AppraisalPeriodLookup from '../../../../Components/Lookup/AppraisalPeriodLookup'
import DepartmentLookup from '../../../../Components/Lookup/DepartmentLookup'
import PerformanceObjectiveLookup from '../../../../Components/Lookup/PerformanceObjectiveLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const PerformanceMeasureModal = ({
  mode,
  selectedPerformanceMeasure,
  setSelectedPerformanceMeasure,
  closeModal,
  loadTableData,
}) => {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    selectedPerformanceMeasure?.performanceObjectiveDepartmentId
  )
  const [selectedDepartmentDescription, setSelectedDepartmentDescription] =
    useState(
      selectedPerformanceMeasure?.performanceObjectiveDepartmentDescription
    )
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/PerformanceMeasure/update',
            selectedPerformanceMeasure || {
              description: '',
            },
            false
          )
        : await postData(
            '/PerformanceMeasure/create',
            selectedPerformanceMeasure || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      loadTableData()
      closeModal()
      showNotification('Performance Measures', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'performanceMeasure'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cPerformanceMeasureIsLocked"
              onCheck={e => {
                setSelectedPerformanceMeasure({
                  ...selectedPerformanceMeasure,
                  isLocked: e,
                })
              }}
              checked={selectedPerformanceMeasure?.isLocked}
            />
            <label
              htmlFor="cPerformanceMeasureIsLocked"
              className="text-capitalize"
            >
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors[
                    'PerformanceObjectiveDepartmentDescription'
                  ]?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('department')}
                </label>

                <DepartmentLookup
                  displayValue={
                    selectedPerformanceMeasure?.performanceObjectiveDepartmentDescription
                  }
                  onSelect={v => {
                    clearValidation('PerformanceObjectiveDepartmentDescription')
                    setSelectedDepartmentId(v.id)
                    setSelectedDepartmentDescription(v.description)
                    setSelectedPerformanceMeasure(prev => ({
                      ...prev,
                      performanceObjectiveDepartmentDescription: v.description,
                      performanceObjectiveDepartmentId: v.id,
                      performanceObjectiveDescription: '',
                      performanceObjectiveId: '',
                    }))
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['PerformanceObjectiveDescription']
                    ?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('performanceObjective')}
                </label>
                <div
                  style={{
                    width: '100%',
                    opacity: selectedPerformanceMeasure ? 1 : 0.6,
                    pointerEvents: selectedPerformanceMeasure ? 'all' : 'none',
                  }}
                >
                  <PerformanceObjectiveLookup
                    displayValue={
                      selectedPerformanceMeasure?.performanceObjectiveDescription
                    }
                    onSelect={v => {
                      clearValidation('PerformanceObjectiveDescription')
                      setSelectedPerformanceMeasure(prev => ({
                        ...prev,
                        performanceObjectiveDescription: v.description,
                        performanceObjectiveId: v.id,
                        performanceObjectivePerformancePillarDescription:
                          v.performancePillarDescription,
                        performanceObjectivePerformancePillarId:
                          v.performancePillarId,
                      }))
                    }}
                    departmentId={selectedDepartmentId}
                    departmentDescription={selectedDepartmentDescription}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['EmployeeAppraisalPeriodDescription']
                    ?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('appraisalPeriod')}
                </label>
                <AppraisalPeriodLookup
                  displayValue={
                    selectedPerformanceMeasure?.employeeAppraisalPeriodDescription
                  }
                  onSelect={v => {
                    clearValidation('EmployeeAppraisalPeriodDescription')
                    setSelectedPerformanceMeasure(prev => ({
                      ...prev,
                      employeeAppraisalPeriodDescription: v.description,
                      employeeAppraisalPeriodId: v.id,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedPerformanceMeasure?.description}
                  type="text"
                  required
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedPerformanceMeasure({
                      ...selectedPerformanceMeasure,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={globalValidationErrors['Target']?.message}
              >
                <label className="text-capitalize col-3">
                  {localize('target')}
                </label>
                <input
                  defaultValue={selectedPerformanceMeasure?.target}
                  type="text"
                  required
                  className="form-control"
                  onChange={e => {
                    clearValidation('Target')
                    setSelectedPerformanceMeasure({
                      ...selectedPerformanceMeasure,
                      target: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-3">
                  {localize('weight')}
                </label>
                <input
                  defaultValue={selectedPerformanceMeasure?.weight}
                  required
                  className="form-control"
                  onChange={e =>
                    setSelectedPerformanceMeasure({
                      ...selectedPerformanceMeasure,
                      weight: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-3">
                  {localize('answerType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="EmployeeAppraisalTargetAnswerType"
                  value={selectedPerformanceMeasure?.answerType}
                  onChange={e =>
                    setSelectedPerformanceMeasure({
                      ...selectedPerformanceMeasure,
                      answerType: e.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default PerformanceMeasureModal
