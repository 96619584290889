/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _f = {
  fingerprintBiometricThreshold: {
    en: 'fingerprint biometric threshold',
    sw: 'kizingiti cha biometriska cha uchapishaji wa kidole',
  },
  forexRates: {
    en: 'forex rates',
    sw: 'viwango vya ubadilishaji',
  },
  fixedDepositTypes: {
    en: 'fixed deposit types',
    sw: 'aina za amana zisizohamishika',
  },
  frontOfficeManagement: {
    en: 'front office management',
    sw: 'usimamizi wa ofisi ya mbele',
  },
  financialProducts: {
    en: 'financial products',
    sw: 'bidhaa za kifedha',
  },
  fixedAmount: {
    en: 'fixed amount',
    sw: 'kiasi kilichowekwa',
  },
  factorInLoanTerm: {
    en: 'factor in loan term',
    sw: 'sababu ya muda wa mkopo',
  },
  funeralHomes: {
    en: 'funeral homes',
    sw: 'nyumba za mazishi',
  },
  firstName: {
    en: 'first name',
    sw: 'jina la kwanza',
  },
  fleetProducts: {
    en: 'fleet products',
    sw: 'bidhaa za msafara',
  },
  foreignExchangeRates: {
    en: 'foreign exchange rates',
    sw: 'viwango vya fedha za kigeni',
  },
  filterCriteria: {
    en: 'filter criteria',
    sw: 'vigezo vya vichujio',
  },
  front: {
    en: 'front',
    sw: 'mbele',
  },
  fullName: {
    en: 'full name',
    sw: 'jina kamili',
  },
  fullAccountNumber: {
    en: 'full account number',
    sw: 'nambari kamili ya akaunti',
  },
  formattedCustomerMemberNumber: {
    en: 'formatted customer member number',
    sw: 'nambari ya mteja iliyoumbizwa',
  },
  funeralRiderClaims: {
    en: 'funeral rider claims',
    sw: 'mwendesha mazishi',
  },
  fileTracking: {
    en: 'file tracking',
    sw: 'ufuatiliaji wa faili',
  },
  fileDispatch: {
    en: 'file dispatch',
    sw: 'tuma faili',
  },
  fileMovementHistory: {
    en: 'file movement history',
    sw: 'historia ya harakati za faili',
  },
  fingerPrint: {
    en: 'fingerprint',
    sw: 'uchapishaji wa kidole',
  },
  fingerPrintSpecimen: {
    en: 'fingerprint specimen',
    sw: 'mfano wa uchapishaji wa kidole',
  },
  fingerPrintBiometricRegister: {
    en: 'fingerprint biometric register',
    sw: 'daftari la uchapishaji wa kidole',
  },
  flags: {
    en: 'flags',
    sw: 'ishara',
  },
  firstNumber: {
    en: 'first number',
    sw: 'nambari ya kwanza',
  },
  funeralHome: {
    en: 'funeral home',
    sw: 'nyumba ya mazishi',
  },
  family: {
    en: 'family',
    sw: 'familia',
  },
  fetchJournalEntries: {
    en: 'fetch journal entries',
    sw: 'pata machapisho ya jarida',
  },
  filterBy: {
    en: 'filter by',
    sw: 'chuja kwa',
  },
  fuzzyMatching: {
    en: 'fuzzy matching',
    sw: 'linganisha',
  },
  february: {
    en: 'february',
    sw: 'februari',
  },
  flagUnflagBankersChequeLeaf: {
    en: 'flag/unflag bankers cheque leaf',
    sw: 'weka/ondoa alama ya karatasi ya hundi ya benki',
  },
  'fileDispatch(Multi-Destination)': {
    en: 'file dispatch (multi-destination)',
    sw: 'tuma faili (fikio nyingi)',
  },
  'fileDispatch(Single-Destination)': {
    en: 'file dispatch (single-destination)',
    sw: 'tuma faili (fikio moja)',
  },
  fileReception: {
    en: 'file reception',
    sw: 'pokea faili',
  },
  fileRecall: {
    en: 'file recall',
    sw: 'le faili',
  },
  fileRegister: {
    en: 'file register',
    sw: 'daftari la faili',
  },
  'fundsConsolidation&LoansNettingOff': {
    en: 'funds consolidation & loans netting off',
    sw: 'kukusanya fedha na kukata mikopo',
  },
  formula: {
    en: 'formula',
    sw: 'fomula',
  },
  fileName: {
    en: 'file name',
    sw: 'jina la faili',
  },
  fileTitle: {
    en: 'file title',
    sw: 'kichwa cha faili',
  },
  fileMIMEType: {
    en: 'file MIME type',
    sw: 'aina ya faili ya MIME',
  },
  fingerprint: {
    en: 'fingerprint',
    sw: 'uchapishaji wa kidole',
  },
  frequency: {
    en: 'frequency',
    sw: 'marudio',
  },
  'fullAccountNumber(DR)': {
    en: 'full account number (DR)',
    sw: 'nambari kamili ya akaunti (DR)',
  },
  'fullAccountNumber(CR)': {
    en: 'full account number (CR)',
    sw: 'nambari kamili ya akaunti (CR)',
  },
  fixedDepositType: {
    en: 'fixed deposit type',
    sw: 'aina za amana zisizohamishika',
  },
  floatBalance: {
    en: 'float balance',
    sw: 'salio',
  },
  fixedDepositLiens: {
    en: 'fixed deposit liens',
    sw: 'amana zisizohamishika',
  },
  fetchStatementJournalEntries: {
    en: 'fetch statement journal entries',
    sw: 'pata machapisho ya jarida la taarifa',
  },
  formatAccountNumber: {
    en: 'format account number',
    sw: 'umbiza nambari ya akaunti',
  },
  fiscalCatalogue: {
    en: 'fiscal catalogue',
    sw: 'katalogi ya kifedha',
  },
  filter: {
    en: 'filter',
    sw: 'chujio',
  },
  fiveHundreds: {
    en: 'five hundreds',
    sw: 'mia tano',
  },
  fifties: {
    en: 'fifties',
    sw: 'hamsini',
  },
  forties: {
    en: 'forties',
    sw: 'arobaini',
  },
  fives: {
    en: 'fives',
    sw: 'tano',
  },
  fiftyCents: {
    en: 'fifty cents',
    sw: 'senti hamsini',
  },
  fixedDeposits: {
    en: 'fixed deposits',
    sw: 'amana zisizohamishika',
  },
  fixing: {
    en: 'fixing',
    sw: 'tengeneza',
  },
  fixedDepositFixing: {
    en: 'fixed deposit fixing',
    sw: 'urekebishaji wa amana zisizohamishika',
  },
  fixedDepositTypeLookup: {
    en: 'fixed deposit type lookup',
    sw: 'tafuta aina ya amana zisizohamishika',
  },
  fixedDepositPayables: {
    en: 'fixed deposit payables',
    sw: 'malipo ya amana zisizohamishika',
  },
  fixedDepositNumber: {
    en: 'fixed deposit number',
    sw: 'nambari ya amana zisizohamishika',
  },
  fixedDepositLookup: {
    en: 'fixed deposit lookup',
    sw: 'tafuta amana zisizohamishika',
  },
  fixedDepositTermination: {
    en: 'fixed deposit termination',
    sw: 'kukomesha amana zisizohamishika',
  },
  fixedDepositDetails: {
    en: 'fixed deposit details',
    sw: 'taarifa za amana zisizohamishika',
  },
  funeralRiderClaimPayables: {
    en: 'funeral rider claim payables',
    sw: 'malipo ya madai ya mwendesha mazishi',
  },
  funeralRiderClaimPayable: {
    en: 'funeral rider claim payable',
    sw: 'malipo ya bima ya mazishi',
  },
  funeralRiderClaim: {
    en: 'funeral rider claim',
    sw: 'madai ya bima ya mazishi',
  },
  funeralRiderClaimLookup: {
    en: 'funeral rider claim lookup',
    sw: 'tafuta madai ya bima ya mazishi',
  },
  fixedDepositTypeTiers: {
    en: 'fixed deposit type tiers',
    sw: 'vichupo vya aina ya amana zisizohamishika',
  },
  fixedDepositTypeTiersLookup: {
    en: 'fixed deposit type tiers lookup',
    sw: 'tafuta vichupo vya aina ya amana zisizohamishika',
  },
  filterCreteria: {
    en: 'filter criteria',
    sw: 'vigezo vya vichujio',
  },
  fileSerialNumber: {
    en: 'file serial number',
    sw: 'nambari ya faili',
  },
  frontImage1: {
    en: 'front image 1',
    sw: 'picha ya mbele 1',
  },
  frontImage2: {
    en: 'front image 2',
    sw: 'picha ya mbele 2',
  },
  fixedValue: {
    en: 'fixed value',
    sw: 'thamani iliyowekwa',
  },
  funeralHomeLookUp: {
    en: 'funeral home lookup',
    sw: 'tafuta nyumba ya mazishi',
  },
  fleetNumber: {
    en: 'fleet number',
    sw: 'nambari ya msafara',
  },
  fleetProduct: {
    en: 'fleet product',
    sw: 'bidhaa ya msafara',
  },
  fileDescription: {
    en: 'file description',
    sw: 'maelezo ya faili',
  },
  finalOrders: {
    en: 'final orders',
    sw: 'amri za mwisho',
  },
  Flags: {
    en: 'flags',
    sw: 'ishara',
  },
  'fundsConsolidation&NettingOff': {
    en: 'funds consolidation & netting off',
    sw: 'kukusanya fedha na kukata',
  },
  flagUnflagBankersChequeBook: {
    en: 'flag/unflag bankers cheque book',
    sw: 'weka/ondoa alama ya kitabu cha hundi ya benki',
  },
}

export default _f
