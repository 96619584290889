/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import ListingPage from '../../../Components/ListingPage/Index'
import { formatDate } from '../../../Helpers/formatters'
import ConcessionExceptionsModal from './Partials/ConcessionExceptionsModal'
import { getData } from '../../../Helpers/webApi'
import ViewConcessionExceptions from './Partials/ViewConcessionExceptions'

function ConcessionExceptions() {
  const [mode, setMode] = useState(null)
  const [vieMode, setVieMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [selectedConcessionException, setSelectedConcessionException] =
    useState(null)
  const [concessionExceptionEntries, setConcessionExceptionEntries] = useState(
    []
  )
  const [refreshConcessionExceptions, setRefreshConcessionExceptions] =
    useState(false)

  useEffect(() => {
    getConcessionExceptions(selectedConcessionException)
  }, [refreshConcessionExceptions])

  const getConcessionExceptions = async r => {
    if (r) {
      const { success, data } = await getData(
        '/ConcessionException/find-concession-exception-entries-by-concession-exception-id',
        {
          ConcessionExceptionId: r.id,
        }
      )
      if (success) {
        setConcessionExceptionEntries(data.result || [])
      }
    }
  }

  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('creditType'),
      template: r => <>{r.creditTypeDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <div>
      <ListingPage
        onAdd={() => {
          setConcessionExceptionEntries([])
          setSelectedConcessionException(null)
          setMode('add')
        }}
        refreshAt={refreshAt}
        url="/ConcessionException/find-concession-exception-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        map={[localize('registryManagement'), localize('concessionExceptions')]}
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                setMode('edit')
                setSelectedConcessionException(r)
                getConcessionExceptions(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('edit')}
            </div>
            <div
              onClick={() => {
                setVieMode(true)
                setSelectedConcessionException(r)
                getConcessionExceptions(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
          </>
        )}
      />
      <ConcessionExceptionsModal
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onUpdateRefreshAtExceptionEntries={r => {
          setRefreshConcessionExceptions(!refreshConcessionExceptions)
        }}
        setSelectedConcessionException={setSelectedConcessionException}
        selectedConcessionException={selectedConcessionException}
        concessionExceptionEntries={concessionExceptionEntries}
        setConcessionExceptionEntries={setConcessionExceptionEntries}
      />
      <ViewConcessionExceptions
        mode={vieMode}
        concessionException={selectedConcessionException}
        onHideModal={() => {
          setVieMode(null)
        }}
        exceptionEntries={concessionExceptionEntries}
      />
    </div>
  )
}

export default ConcessionExceptions
