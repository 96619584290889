/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import MorgueRoomLookup from '../../../../../Components/Lookup/MorgueRoomLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../Helpers/extensions'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import RoomBeds from '../RoomBeds/RoomBeds'

const CreateMorgueBed = ({
  handleClose,
  mode,
  bed,
  setBed,
  fetchMorgueBeds,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [beds, setBeds] = useState([])
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleCloseModal = () => {
    setBeds([])
    handleClose()
  }

  const handleSelectRoom = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected room is locked.'
      )

    setBed({
      ...bed,
      roomId: r?.id,
      roomType: r?.type,
      roomIsLocked: r?.isLocked,
      roomDescription: r?.description,
      isLocked: false,
      isOccupied: false,
      roomTypeDescription: r?.typeDescription,
    })
  }

  const handleAddOrUpdate = async () => {
    const url = '/Bed/update-beds-by-room-id'

    if (mode === 'add' && beds.length === 0)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please add beds first.'
      )

    if (mode === 'add' && !bed.roomDescription && beds.length === 0) {
      GlobalValidationErrors.set({
        RoomDescription: {
          message: 'Room description is required',
        },
      })
      return
    }

    if (mode === 'add' && !bed.roomDescription && beds.length === 0) {
      GlobalValidationErrors.set({
        RoomDescription: {
          message: 'Room description is required',
        },
      })
      return
    }

    if (mode === 'edit' && !bed.description) {
      GlobalValidationErrors.set({
        Description: {
          message: 'Description is required',
        },
      })
      return
    }

    const params =
      mode === 'add'
        ? beds?.map(b => ({
            ...b,
            id: null,
          }))
        : [bed]
    const message = mode === 'add' ? 'Create Beds' : 'Update Beds'

    setIsBusy(true)

    const { success } = await postData(
      url,
      {
        beds: params,
      },
      false
    )

    if (success) {
      showNotification(message, 'success')
      handleCloseModal()
      fetchMorgueBeds()
    }

    setIsBusy(false)
  }

  const handleAddBed = async () => {
    if (beds.some(b => b.description === bed.description)) {
      return showNotification(
        'Bed already exists!',
        'error',
        'Bed with same description already exists.'
      )
    }

    if (!bed.description) {
      GlobalValidationErrors.set({
        Description: !bed?.description && {
          message: 'Description is required',
        },
        RoomDescription: !bed?.roomDescription && {
          message: 'Room  is required',
        },
      })

      return
    }

    setBeds([
      ...beds,
      {
        ...bed,
        id: generateGuid(),
      },
    ])
    setBed({
      ...bed,
      description: '',
    })
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleRemoveBed = async bed => {
    setBeds(beds.filter(b => b.description !== bed.description))
  }

  useEffect(() => {
    setGlobalValidationErrors({})

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="morgueBed"
      handleClose={handleCloseModal}
      handleAction={handleAddOrUpdate}
      footerChildren={
        mode === 'edit' && (
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              id="isLocked"
              checked={bed?.isLocked}
              onCheck={e => {
                setBed({
                  ...bed,
                  isLocked: e,
                })
              }}
            />
            <label className="ms-2  text-capitalize" htmlFor="isLocked">
              {localize('isLocked')}
            </label>
            <Checkbox
              id="isOccupied"
              checked={bed?.isOccupied}
              onCheck={e => {
                setBed({
                  ...bed,
                  isOccupied: e,
                })
              }}
            />
            <label className="ms-2  text-capitalize" htmlFor="isOccupied">
              {localize('isOccupied')} ?
            </label>
          </div>
        )
      }
      disableCreate={isBusy}
      disableClose={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['RoomDescription']?.message
                }
              >
                <label htmlFor="room" className="text-capitalize col-2">
                  {localize('room')}
                </label>

                <MorgueRoomLookup
                  displayValue={bed?.roomDescription || ''}
                  onSelect={r => {
                    clearValidation('RoomDescription')
                    handleSelectRoom(r)
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="type" className="text-capitalize col-1">
                  {localize('type')}
                </label>

                <input
                  type="text"
                  id="type"
                  className="form-control ms-2"
                  value={bed?.roomTypeDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label htmlFor="type" className="text-capitalize col-1">
                  {localize('description')}
                </label>

                <input
                  type="text"
                  id="type"
                  className="form-control"
                  value={bed?.description || ''}
                  onChange={e => {
                    clearValidation('Description')
                    setBed({ ...bed, description: e.target.value })
                  }}
                />
              </div>
            </div>
            {mode === 'add' && (
              <div className="col-md-12 py-2 my-2 bg-light">
                <button
                  className="btn btn-success float-end"
                  onClick={handleAddBed}
                >
                  {localize('add')}
                </button>
              </div>
            )}
          </div>
          {mode === 'add' && (
            <Tab
              tabItems={[
                {
                  label: localize('beds'),
                  item: (
                    <>
                      <div className="row py-3 ">
                        <div className="col-md-12">
                          <RoomBeds
                            beds={beds}
                            mode={mode}
                            handleRemoveBed={handleRemoveBed}
                          />
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          )}
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateMorgueBed
