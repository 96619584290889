/** @format */

import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { AddBankersChequeBookModal } from './AddBankersChequeBookModal'
import { EditBankersChequeBookModal } from './EditBankersChequeBookModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { ViewBankersChequeBookModal } from './ViewBankersChequeBookModal'

function BankersChequeBooksModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  waitingMetadata,
  setSelectedBankersChequeBook,
  selectedBankersChequeBook,
}) {
  const [isBusy, setIsBusy] = useState()

  async function handleSubmit() {
    if (mode === 'edit') {
      return editBankersChequeBook()
    }
    if (mode === 'add') {
      return addBankersChequeBook()
    }
  }

  const addBankersChequeBook = async () => {
    setIsBusy(true)

    const { success } = await postData(
      '/BankersChequeBook/add-bankers-cheque-book-with-commissions',
      {
        bankersChequeBook: selectedBankersChequeBook || {},
        commissions:
          selectedBankersChequeBook?.commissions.filter(x => x.checked) || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Bankers Cheque Book',
        'Operation Completed Successfully',
        'success',
      )
    }

    setIsBusy(false)
  }
  const editBankersChequeBook = async () => {
    setIsBusy(true)

    const { success } = await postData(
      '/BankersChequeBook/update-bankers-cheque-book-with-commissions',
      {
        bankersChequeBook: selectedBankersChequeBook || {},
        commissions:
          selectedBankersChequeBook?.commissions.filter(x => x.checked) || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Bankers Cheque Book',
        'Operation Completed Successfully',
        'success',
      )
    }

    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('bankersChequeBook')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          disableCreate={isBusy}
          hideUpdate={mode === 'view'}
          footerChildren={
            mode !== 'view' && (
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <Checkbox
                    id={'isLocked'}
                    checked={selectedBankersChequeBook?.isLocked}
                    onCheck={v => {
                      setSelectedBankersChequeBook({
                        ...selectedBankersChequeBook,
                        isLocked: v,
                      })
                    }}
                  />
                  <label htmlFor="isLocked" className="text-capitalize ms-2">
                    {localize('isLocked')}?
                  </label>
                </div>
              </div>
            )
          }
          modalClassName={'modal-xl'}
        >
          {mode === 'add' && (
            <AddBankersChequeBookModal
              selectedBankersChequeBook={selectedBankersChequeBook}
              setSelectedBankersChequeBook={setSelectedBankersChequeBook}
              isBusy={isBusy}
            />
          )}
          {mode === 'edit' && (
            <EditBankersChequeBookModal
              selectedBankersChequeBook={selectedBankersChequeBook}
              setSelectedBankersChequeBook={setSelectedBankersChequeBook}
              isBusy={isBusy}
            />
          )}
          {mode === 'view' && (
            <ViewBankersChequeBookModal
              setSelectedBankersChequeBook={setSelectedBankersChequeBook}
              selectedBankersChequeBook={selectedBankersChequeBook}
              waitingMetadata={waitingMetadata}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default BankersChequeBooksModal
