/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
// DOCS this is deprecated

/**
 * @param {string} message
 * 
 * 
 * @
 */

const Loader = ({ message }) => {
  return (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="d-flex align-items-center">
        <i className="m-3 spinner-border spinner-sm text-black"></i>
        <span className="text-capitalize fs-4">
          {message || localize('pleaseWait')}
        </span>
      </div>
    </div>
  )
}

export default Loader
