/** @format */

/* eslint-disable */
import React from 'react'
import CustomerAccountByCustomerIdLookup from '../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import localize from '../../../../../Global/Localize'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

function CustomerAccountRefundProcessingDebitCreditPartial({
  onCustomerDebitAccountLookup,
  onCustomerCreditAccountLookup,
  selectedCreditAccount,
  selectedDebitAccount,
  iRefundNotification,
  setIRefundNotification,
  selectedCustomer,
  lookupType,
  message,
}) {
  return (
    <>
      {lookupType === 'debit' ? (
        <>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={message}
            >
              <label className="col-4 me-2 text-capitalize">
                {localize('fullAccountNumber')}
              </label>

              <CustomerAccountByCustomerIdLookup
                displayValue={selectedDebitAccount?.fullAccountNumber || ''}
                type="text"
                className="form-control ms-2"
                onSelect={v => {
                  onCustomerDebitAccountLookup(v)
                }}
                selectedCustomer={selectedCustomer}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDebitAccount?.remarks || ''}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDebitAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDebitAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedDebitAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDebitAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDebitAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedDebitAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDebitAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDebitAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedDebitAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDebitAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('penaltyBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDebitAccount?.penaltyBalance) < 0
                    ? formatCurrency(
                        selectedDebitAccount?.penaltyBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDebitAccount?.penaltyBalance || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principal')}
              </label>
              <MaskNumber
                defaultMaskValue={iRefundNotification?.principal}
                className="form-control ms-2"
                onMaskNumber={e => {
                  setIRefundNotification({
                    ...iRefundNotification,
                    principal: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interest')}
              </label>
              <MaskNumber
                defaultMaskValue={iRefundNotification?.interest}
                className="form-control ms-2"
                onMaskNumber={e => {
                  setIRefundNotification({
                    ...iRefundNotification,
                    interest: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={message}
            >
              <label className="col-4 me-2 text-capitalize">
                {localize('fullAccountNumber')}
              </label>

              <CustomerAccountByCustomerIdLookup
                displayValue={selectedCreditAccount?.fullAccountNumber || ''}
                type="text"
                className="form-control ms-2"
                onSelect={v => {
                  onCustomerCreditAccountLookup(v)
                }}
                selectedCustomer={selectedCustomer}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedCreditAccount?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedCreditAccount?.remarks || ''}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedCreditAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                type="text"
                style={{ textAlign: 'right' }}
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.availableBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.availableBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.availableBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('penaltyBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedCreditAccount?.penaltyBalance) < 0
                    ? formatCurrency(
                        selectedCreditAccount?.penaltyBalance,
                        null,
                        null,
                        false
                      )
                    : selectedCreditAccount?.penaltyBalance || ''
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default CustomerAccountRefundProcessingDebitCreditPartial
