/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
function NumberingSeriesLookup({ onSelect, displayValue, disabled }) {
  return (
    <SimpleLookup
      disabled={disabled}
      title={localize('numberingSeriesLookup')}
      url="/lookup/NumberingSeries/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('firstNumber'),
          template: r => <>{r.firstNumber}</>,
          sortBy: 'firstNumber',
        },
        {
          label: localize('nextNumber'),
          template: r => <>{r.nextNumber}</>,
          sortBy: 'nextNumber',
        },
        {
          label: localize('lastNumber'),
          template: r => <>{r.lastNumber}</>,
          sortBy: 'lastNumber',
        },
        {
          label: localize('padding'),
          template: r => <>{r.padding}</>,
          sortBy: 'padding',
        },
        {
          label: localize('prefix'),
          template: r => <>{r.prefix}</>,
          sortBy: 'prefix',
        },
        {
          label: localize('suffix'),
          template: r => <>{r.suffix}</>,
          sortBy: 'suffix',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('modifiedBy'),
          template: r => <>{r.modifiedBy}</>,
          sortBy: 'modifiedBy',
        },
        {
          label: localize('modifiedDate'),
          template: r => <>{formatDate(r.modifiedDate, false)}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
          sortBy: 'createdBy',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default NumberingSeriesLookup
