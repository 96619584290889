/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import CustomerAccountLookup from '../../Lookup/CustomerAccountLookup'
import Loader from '../../Loaders/Loader'
import { getData } from '../../../Helpers/webApi'

export const CustomerAccountPartial = ({
  onCustomerLookup,
  customer,
  message,
  hideMargin,
  onClearValidation,
  readOnly,
  productCode = '1',
  refreshFromServer,
}) => {
  const [customerAccount, setCustomerAccount] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const fetchCustomerAccount = async () => {
    setIsBusy(true)

    const url = '/lookup/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: customer?.id,
        includeBalances: false,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccount(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (refreshFromServer && customer?.id) fetchCustomerAccount()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFromServer, customer?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <div className={`row g-2 ${!hideMargin && 'mb-2'} `}>
          <div
            validation-message={message}
            className="col-3 d-flex align-items-center with-validation"
          >
            <label className={`col-4 text-capitalize ${!readOnly && 'me-2'}`}>
              {localize('fullAccountNumber')}
            </label>
            {readOnly ? (
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  customer?.fullAccountNumber ||
                  customerAccount?.fullAccountNumber || ''
                }
              />
            ) : (
              <CustomerAccountLookup
                productCode={productCode}
                displayValue={
                  customer?.fullAccountNumber ||
                  customerAccount?.fullAccountNumber || ''
                }
                onSelect={r => {
                  onCustomerLookup(r)
                  onClearValidation && onClearValidation()
                }}
              />
            )}
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.statusDescription ||
                customerAccount?.statusDescription || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customer?.remarks || customerAccount?.remarks || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerAccountTypeTargetProductDescription ||
                customerAccount?.customerAccountTypeTargetProductDescription || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerFullName || customerAccount?.customerFullName || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerEmployerDescription ||
                customerAccount?.customerEmployerDescription || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerMembershipClassCustomerTypeDescription ||
                customerAccount?.customerMembershipClassCustomerTypeDescription || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerSerialNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.paddedCustomerSerialNumber ||
                customerAccount?.paddedCustomerSerialNumber || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('identificationNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerIdentificationNumber ||
                customerAccount?.customerIdentificationNumber || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.formattedCustomerMemberNumber ||
                customerAccount?.formattedCustomerMemberNumber || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.formattedCustomerAccountNumber ||
                customerAccount?.formattedCustomerAccountNumber || ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customer?.customerPersonalFileNumber ||
                customerAccount?.customerPersonalFileNumber || ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}
