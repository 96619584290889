/** @format */

/* eslint-disable */
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import { useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'

const InitiateDividendComputationModal = ({
  mode,
  handleAction,
  handleClose,
  dividend,
  setDividend,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const fetchActivePostingPeriod = async () => {
    setIsBusy(true)
    const url = '/PostingPeriod/find-current-posting-period'

    const { data, success } = await getData(url, null, false)

    if (success) {
      setDividend(data)
    }
    setIsBusy(false)
  }

  const handleSelect = r => {
    if (r.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'This period is locked',
      })

    if (r.isClosed)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'This period is closed',
      })

    if (r.isDividendComputed)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Dividend has already been computed for this period',
      })

    setDividend({
      ...dividend,
      ...r,
    })
  }

  useEffect(() => {
    fetchActivePostingPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalTemplate
      handleAction={handleAction}
      handleClose={handleClose}
      modalMode={mode}
      actionText="update"
      modalTitle={'dividendsComputation'}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 mb-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="postingPeriod"
                  className="text-capitalize col-4"
                >
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={dividend?.description}
                  onSelect={handleSelect}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="createdDate" className="text-capitalize col-4">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control ms-1"
                  value={formatDate(dividend?.createdDate)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="startDate" className="text-capitalize col-4">
                  {localize('startDate')}
                </label>
                <input
                  type="text"
                  className="form-control ms-1"
                  value={formatDate(dividend?.durationStartDate)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="endDate" className="text-capitalize col-4">
                  {localize('endDate')}
                </label>
                <input
                  type="text"
                  className="form-control ms-1"
                  value={formatDate(dividend?.durationEndDate)}
                  disabled
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default InitiateDividendComputationModal
