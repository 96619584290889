/** @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import Commissions from '../../../../Components/Selectables/Plain/Commissions'
import {
  entitiesToBeChecked,
  getCommissions,
} from '../../../../Helpers/selectablesHelper'
import {
  chargeBenefactor,
  SystemTransactionCode,
} from '../../../../Global/enumeration'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { getData, postData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import { TextAlertsCommissions } from './Partials/TextAlertsCommissions'

function ChargeDeterminationTextAlerts() {
  const [isBusy, setIsBusy] = useState(false)
  const [commissions, setCommissions] = useState([])
  const [loadingCommissions, setLoadingCommissions] = useState(false)
  const [modalWaiting, setModalWaiting] = useState(false)
  const [selectedCommissions, setSelectedCommissions] = useState([])
  const [selectedSystemTransactionCode, setSelectedSystemTransactionCode] =
    useState('1')
  const [chargeBorneBy, setChargeBorneBy] = useState('0')

  useEffect(() => {
    retrieveApplicableCharges(selectedSystemTransactionCode)
  }, [])

  async function handleSubmit(finalData) {
    setIsBusy(true)
    setModalWaiting(true)
    const { success, data } = await postData(
      '/TextAlert/update-commissions-by-system-transaction-code',
      finalData,
      false
    )

    if (success) {
      setIsBusy(false)
      setModalWaiting(false)
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Edit Well Known Charges',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
      setModalWaiting(false)
      Swal.fire({
        icon: 'error',
        text: data.reason,
        title: 'Edit Well Known Charges',
        showCloseButton: true,
      })
    }
  }

  const retrieveApplicableCharges = async systemTransactionCode => {
    setIsBusy(true)
    setLoadingCommissions(true)
    const { success, data } = await getData(
      '/TextAlert/find-commissions-by-system-transaction-code',
      { systemTransactionCode: systemTransactionCode },
      false
    )
    const commissions = await getCommissions()
    if (success) {
      setIsBusy(false)
      setCommissions(entitiesToBeChecked(commissions, data?.result))
    }
    setLoadingCommissions(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('leviesAndCharges'),
          localize('textAlertCharges'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-auto me-2 text-capitalize">
                    {localize('systemTransactionCode')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="SystemTransactionCode"
                    value={selectedSystemTransactionCode}
                    onChange={event => {
                      setSelectedSystemTransactionCode(event.value)
                      retrieveApplicableCharges(event.value)
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-auto text-capitalize me-2">
                    {localize('chargeBorneBy')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ChargeBenefactor"
                    value={chargeBorneBy}
                    onChange={c => {
                      setChargeBorneBy(c.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('applicableCharges'),
                      item: (
                        <TextAlertsCommissions
                          prefix={'text-alert'}
                          onCheckCommissions={c => {
                            setSelectedCommissions(c.filter(x => x.checked))
                            setCommissions(c)
                          }}
                          isBusy={loadingCommissions}
                          commissionsToBeSelected={commissions}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  onClick={async () => {
                    await handleSubmit({
                      SystemTransactionCode: selectedSystemTransactionCode,
                      Commissions: selectedCommissions,
                      ChargeBenefactor: chargeBorneBy,
                    })
                  }}
                  className={`btn btn-success text-uppercase px-4 ${
                    modalWaiting ? 'disabled' : ''
                  }`}
                  type="button"
                >
                  {modalWaiting ? (
                    <i className="m-1 spinner-border spinner-sm text-light"></i>
                  ) : (
                    localize('update')
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChargeDeterminationTextAlerts
