/** @format */

import { useState } from 'react'
import { getData, postData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { notificationJobStatus } from '../../../../../Global/enumeration'

const NotificationJobs = () => {
  const [jobs, setJobs] = useState([])
  const [job, setJob] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [waitingUnPost, setWaitingUnPost] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'Status',
    value: 2,
    column: 'statusDescription',
  })
  const [reqParams, setReqParams] = useState({
    text: '',
    notificationJobFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('alertTrigger'),
      template: r => r?.alertTriggerDescription,
    },
    {
      label: localize('alertType'),
      template: r => r?.alertTypeDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r?.primaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r?.secondaryDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r?.totalValue),
    },
    {
      label: localize('customerType'),
      template: r => r?.customerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('customer'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.customerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerReference3,
    },
    {
      label: localize('exceptionMessage'),
      template: r => r?.exceptionMessage,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'alertTriggerDescription' },
    { value: 1, column: 'alertTypeDescription' },
  ]

  const fetchNotificationJobs = async () => {
    setIsBusy(true)

    const url = '/NotificationJob/find-notification-jobs-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setJobs(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectJob = r => {
    if (
      r.status !== Object.keys(notificationJobStatus)[1] &&
      r.status !== Object.keys(notificationJobStatus)[2]
    )
      return showNotification(
        'Notification Jobs',
        'error',
        'Only jobs with status "Posted" or "Exception Raised" can be reset'
      )

    setMode('reset')
    setJob(r)
  }

  const handleClose = () => {
    setMode(null)
    setJob({})
  }

  const resetNotificationJob = async () => {
    setWaitingUnPost(true)
    const url = '/NotificationJob/unpost-notification-job'

    const { success } = await postData(
      url,
      {
        notificationJobId: job?.id,
      },
      false
    )

    if (success) {
      setWaitingUnPost(false)
      handleClose()
      fetchNotificationJobs()
      showNotification('Notification Jobs', 'success')
    } else {
      setWaitingUnPost(false)
    }
  }

  useEffect(() => {
    fetchNotificationJobs()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'reset' && (
        <ActionModal
          modalTitle={`Do you want to reset ${job?.alertTypeDescription} ?`}
          cancelText="no"
          confirmText="yes"
          handleAction={resetNotificationJob}
          handleClose={handleClose}
          disableAction={isBusy}
        >
          {waitingUnPost ? (
            <Loader />
          ) : (
            <p className="text-capitalize fw-bold">
              {job?.alertTriggerDescription}
            </p>
          )}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('notificationJobs'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    htmlFor=""
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={groupBy?.value}
                    enumsKey="NotificationJobPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    htmlFor=""
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.notificationJobFilter}
                    enumsKey="NotificationJobFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        notificationJobFilter: value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchNotificationJobs()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchNotificationJobs()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={jobs?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectJob(r, 'reset')
                      }}
                    >
                      <i className="uil uil-history-alt"></i>
                      {localize('reset')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={jobs?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default NotificationJobs
