/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import MorgueBedLookup from '../../../../../../../Components/Lookup/MorgueBedLookup'
import AddressV2 from '../../../../../../../Components/Miscalleneous/AddressV2'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import {
  getCurrentTime,
  getTodaysDate,
} from '../../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../../Helpers/webApi'
import IndividualParticulars from '../IndividualParticulars/IndividualParticulars'
import NextOfKinDetails from '../NextOfKinDetails/NextOfKinDetails'

const initialParams = {
  deathTime: getCurrentTime(),
  deathDate: getTodaysDate(),
  registrationDate: getTodaysDate(),
  registrationTime: getCurrentTime(),
}

const initialNextOfKin = {
  isPrimary: false,
}

const RegisterPatient = ({ mode, handleClose, patientData, fetchPatients }) => {
  const [patient, setPatient] = useState(initialParams)
  const [isBusy, setIsBusy] = useState(false)
  const [nextOfKin, setNextOfKin] = useState(initialNextOfKin)
  const [nextOfKins, setNextOfKins] = useState([])
  const [selectedNextOfKins, setSelectedNextOfKins] = useState([])
  const [bed, setBed] = useState({})
  const [isBusyFetchingNextOfKin, setIsBusyFetchingNextOfKin] = useState(false)

  const handleSelectBed = bed => {
    setBed(bed)
  }

  const fetchBed = async () => {
    const url = '/Bed/find-bed'
    const { success, data } = await getData(
      url,
      {
        bedId: patientData?.bedId,
      },
      false
    )

    if (success) {
      setBed(data)
    }
  }

  const fetchNextOfKinsDetails = async () => {
    setIsBusyFetchingNextOfKin(true)

    const url =
      '/MorguePatient/find-morgue-patient-next-of-kins-by-morgue-patient-id'

    const { success, data } = await getData(
      url,
      {
        morguePatientId: patientData?.id,
      },
      false
    )

    if (success) {
      setNextOfKins(data?.result)
    }

    setIsBusyFetchingNextOfKin(false)
  }

  const handleAddNextOfKin = () => {
    if (!nextOfKin.identityCardNumber)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please enter a valid identity card number'
      )
    if (!nextOfKin.addressMobileLine)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please enter a valid phone number.'
      )

    setNextOfKins([...nextOfKins, nextOfKin])
    setNextOfKin({
      ...initialNextOfKin,
      identityCardNumber: '',
      nextOfKinSalutation: '',
      nextOfKinGender: '',
      firstName: '',
      lastName: '',
      addressMobileLine: '',
      addressEmail: '',
      addressLandLine: '',
      addressCity: '',
      addressStreet: '',
      addressAddressLine1: '',
      addressAddressLine2: '',
      remarks: '',
      addressPostalCode: '',
      nextOfKinIdentityCardType: '',
    })
  }

  const handleRemoveNextOfKin = () => {
    if (selectedNextOfKins?.length === 0)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a next of kin to remove'
      )

    const filteredNextOfKins = nextOfKins.filter(
      k =>
        !selectedNextOfKins?.find(
          kin => kin?.identityCardNumber === k?.identityCardNumber
        )
    )

    setNextOfKins(filteredNextOfKins)
    setSelectedNextOfKins(
      selectedNextOfKins.filter(
        k =>
          !filteredNextOfKins?.find(
            kin => kin?.identityCardNumber === k?.identityCardNumber
          )
      )
    )
  }

  const handleSelectNextOfKin = (kin, value) => {
    if (value) {
      setSelectedNextOfKins([...selectedNextOfKins, kin])
    } else {
      setSelectedNextOfKins(
        nextOfKins.filter(
          k => k?.identityCardNumber !== kin?.identityCardNumber
        )
      )
    }
  }

  const handleCreateMorguePatient = async () => {
    setIsBusy(true)

    const url = '/MorguePatient/add-morgue-patient'
    const { success, data } = await postData(
      url,
      {
        individualType: patient?.individualType ?? 0,
        bedId: bed?.id,
        individualGender: patient?.gender ?? 0,
        individualNationality: patient?.nationality ?? 0,
        individualIdentityCardType: patient?.identityCardType ?? 0,
        individualSalutation: patient?.salutation ?? 0,
        individualMaritalStatus: patient?.maritalStatus,
        individualFirstName: patient?.firstName,
        individualLastName: patient?.lastName,
        individualIdentityCardNumber: patient?.identityCardNumber,
        individualIdentityCardSerialNumber: patient?.identityCardSerialNumber,
        individualBirthDate: patient?.birthDate,
        deathTime: patient?.deathTime,
        registrationTime: patient?.registrationTime,
        deathDate: patient?.deathDate,
        registrationDate: patient?.registrationDate,
        additionalNotes: patient?.additionalNotes,
        propertyNotes: patient?.propertyNotes,
        individualGenderDescription: patient?.genderDescription,
        individualSalutationDescription: patient?.salutationDescription,
        individualIdentityCardTypeDescription:
          patient?.identityCardTypeDescription,
        ...patient,
      },
      false
    )

    if (success) {
      if (nextOfKins?.length > 0) {
        const url = `/MorguePatient/update-morgue-patient-next-of-kins-by-morgue-patient-id?morguePatientId=${data?.id}`

        const { success } = await postData(url, nextOfKins, false)

        if (success) {
          showNotification('Create Morgue Patient', 'success')
          handleClose()
          setNextOfKin(initialNextOfKin)
          setNextOfKins([])
          setSelectedNextOfKins([])
          setPatient(initialParams)
          fetchPatients()
        }

        setIsBusy(false)
      } else {
        showNotification('Create Morgue Patient', 'success')
        handleClose()
        setNextOfKin(initialNextOfKin)
        setNextOfKins([])
        setSelectedNextOfKins([])
        setPatient(initialParams)
        fetchPatients()
      }
    }

    setIsBusy(false)
  }

  const handleEditMorguePatient = async () => {
    setIsBusy(true)

    const url = '/MorguePatient/update-morgue-patient'
    const { success } = await postData(
      url,
      {
        ...patientData,
        individualType: patient?.individualType,
        bedId: bed?.id,
        individualGender: patient?.gender,
        individualNationality: patient?.nationality,
        individualIdentityCardType: patient?.identityCardType,
        individualSalutation: patient?.salutation,
        individualMaritalStatus: patient?.maritalStatus,
        individualFirstName: patient?.firstName,
        individualLastName: patient?.lastName,
        individualIdentityCardNumber: patient?.identityCardNumber,
        individualIdentityCardSerialNumber: patient?.identityCardSerialNumber,
        individualBirthDate: patient?.birthDate,
        deathTime: patient?.deathTime,
        registrationTime: patient?.registrationTime,
        deathDate: patient?.deathDate,
        registrationDate: patient?.registrationDate,
        additionalNotes: patient?.additionalNotes,
        propertyNotes: patient?.propertyNotes,
        individualGenderDescription: patient?.genderDescription,
        individualSalutationDescription: patient?.salutationDescription,
        individualIdentityCardTypeDescription:
          patient?.identityCardTypeDescription,
        isLocked: patient?.isLocked,
        ...patient,
      },
      false
    )

    if (success) {
      if (nextOfKins?.length > 0) {
        const url = `/MorguePatient/update-morgue-patient-next-of-kins-by-morgue-patient-id?morguePatientId=${patientData?.id}`

        const { success } = await postData(
          url,
          {
            ...nextOfKins,
          },
          false
        )

        if (success) {
          showNotification('Update Morgue Patient', 'success')
          handleClose()
          setNextOfKin(initialNextOfKin)
          setNextOfKins([])
          setSelectedNextOfKins([])
          setPatient(initialParams)
          fetchPatients()
        }

        setIsBusy(false)
      } else {
        showNotification('Update Morgue Patient', 'success')
        handleClose()
        setNextOfKin(initialNextOfKin)
        setNextOfKins([])
        setSelectedNextOfKins([])
        setPatient(initialParams)
        fetchPatients()
      }
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (patientData?.id) {
      setPatient({
        individualType: patientData?.individualType,
        gender: patientData?.individualGender,
        nationality: patientData?.individualNationality,
        identityCardType: patientData?.individualIdentityCardType,
        salutation: patientData?.individualSalutation,
        maritalStatus: patientData?.individualMaritalStatus,
        firstName: patientData?.individualFirstName,
        lastName: patientData?.individualLastName,
        identityCardNumber: patientData?.individualIdentityCardNumber,
        identityCardSerialNumber:
          patientData?.individualIdentityCardSerialNumber,
        birthDate: patientData?.individualBirthDate,
        deathTime: patientData?.deathTime,
        registrationTime: patientData?.registrationTime,
        deathDate: patientData?.deathDate,
        registrationDate: patientData?.registrationDate,
        additionalNotes: patientData?.additionalNotes,
        propertyNotes: patientData?.propertyNotes,
        genderDescription: patientData?.individualGenderDescription,
        salutationDescription: patientData?.individualSalutationDescription,
        identityCardTypeDescription:
          patientData?.individualIdentityCardTypeDescription,
        isLocked: patientData?.isLocked,
      })
      fetchBed()
      fetchNextOfKinsDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData?.id])

  console.log(nextOfKins)

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={mode === 'add' ? 'createMorguePatient' : 'editMorguePatient'}
      handleClose={handleClose}
      modalClassName="modal-xl"
      actionText={mode === 'add' ? 'create' : 'update'}
      handleAction={
        mode === 'add' ? handleCreateMorguePatient : handleEditMorguePatient
      }
      disableCreate={isBusy}
      footerChildren={
        mode === 'edit' && (
          <>
            <div className="d-flex align-items-center">
              <Checkbox
                checked={patient?.isLocked}
                onCheck={value => {
                  setPatient({
                    ...patient,
                    isLocked: value,
                  })
                }}
                id="isLockedEditRegisterPatient"
              />
              <label
                htmlFor="isLockedEditRegisterPatient"
                className="text-capitalize"
              >
                {localize('isLocked') + ' ?'}
              </label>
            </div>
          </>
        )
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-md-12 d-flex align-items-center">
              <label className="col-1 text-capitalize">{localize('bed')}</label>

              <MorgueBedLookup
                displayValue={bed?.description}
                onSelect={handleSelectBed}
              />
            </div>
          </div>

          <Tab
            tabItems={[
              {
                label: localize('individualParticulars'),
                item: (
                  <IndividualParticulars
                    patient={patient}
                    setPatient={setPatient}
                  />
                ),
                icon: 'uil uil-edit',
              },
              {
                label: localize('address'),
                item: (
                  <AddressV2
                    parent={patient}
                    onChange={value => setPatient(value)}
                  />
                ),
                icon: 'uil uil-edit',
              },
              {
                label: localize('extraInformation'),
                item: (
                  <div className="row g-3">
                    <div className="col-md-12 d-flex align-items-center">
                      <label className="text-capitalize col-1">
                        {localize('additionalNotes')}
                      </label>
                      <textarea
                        className="form-control"
                        cols="3"
                        onChange={e => {
                          setPatient({
                            ...patient,
                            additionalNotes: e.target.value,
                          })
                        }}
                        value={patient?.additionalNotes}
                      />
                    </div>
                    <div className="col-md-12 d-flex align-items-center">
                      <label className="text-capitalize col-1">
                        {localize('propertyNotes')}
                      </label>
                      <textarea
                        className="form-control"
                        cols="3"
                        onChange={e => {
                          setPatient({
                            ...patient,
                            propertyNotes: e.target.value,
                          })
                        }}
                        value={patient?.propertyNotes}
                      />
                    </div>
                  </div>
                ),
                icon: 'uil uil-edit',
              },
              {
                label: localize('nextOfKinDetails'),
                item: (
                  <NextOfKinDetails
                    nextOfKin={nextOfKin}
                    setNextOfKin={setNextOfKin}
                    handleAddNextOfKin={handleAddNextOfKin}
                    handleRemoveNextOfKin={handleRemoveNextOfKin}
                    handleSelectNextOfKin={handleSelectNextOfKin}
                    nextOfKins={nextOfKins}
                    selectedNextOfKins={selectedNextOfKins}
                    isBusyFetchingNextOfKin={isBusyFetchingNextOfKin}
                  />
                ),
                icon: 'uil uil-edit',
              },
            ]}
          />
        </>
      )}
    </ModalTemplate>
  )
}

export default RegisterPatient
