/** @format */

import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import { getData } from '../../Helpers/webApi'

export const QuotationBySupplierLookup = ({
  onSelect,
  displayValue,
  disabled = false,
  supplierId,
  supplierDescription,
}) => {
  const [modalActive, setModalActive] = useState()
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])

  const [reqParams, setReqParams] = useState({
    supplierId: supplierId,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const columns = [
    {
      label: localize('referenceNumber'),
      template: r => <>{r.paddedReferenceNumber}</>,
    },
    {
      label: localize('documentNumber'),
      template: r => <>{r.documentNumber}</>,
    },
    {
      label: localize('supplier'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('supplierMobileNumber'),
      template: r => <>{r.supplierAddressMobileLine}</>,
    },
    {
      label: localize('supplierEmail'),
      template: r => <>{r.supplierAddressEmail}</>,
    },
    {
      label: localize('quotationDate'),
      template: r => <>{formatDate(r.quotationDate)}</>,
    },
    { label: localize('period'), template: r => <>{r.period}</> },
    {
      label: localize('expiryDate'),
      template: r => <>{formatDate(r.expiryDate)}</>,
    },
    { label: localize('fileName'), template: r => <>{r.fileName}</> },
    { label: localize('fileTitle'), template: r => <>{r.fileTitle}</> },
    { label: localize('fileMIMEType'), template: r => <>{r.fileMIMEType}</> },
    {
      label: localize('branchDescription'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [sortedBy, setSortedBy] = useState('')
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Quotation/find-quotations-by-supplier-id-and-filter-in-page',
      { ...reqParams, supplierId: supplierId },
      false,
      null
    )
    if (success) {
      setIsBusy(false)
      setData(data)
      setTableData(data.pageCollection)
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {`Quotation Lookup - ${supplierDescription}`}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center float-right my-2 col-4">
                    <label
                      className="text-capitalize"
                      htmlFor="lookupRecordsPerPage"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="lookupRecordsPerPage"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                      })
                    }}
                  >
                    <div className="d-flex align-items-center float-right my-2">
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control ms-3"
                        id="searchTable"
                      />

                      <button
                        type="submit"
                        className="btn btn-primary ms-1 text-capitalize"
                      >
                        <i className="uil uil-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
