/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { vehicleFleetProductType } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateFeetProduct = ({
  mode,
  handleClose,
  refresh,
  setRefresh,
  fleetProduct,
  setFleetProduct,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleCloseModal = () => {
    handleClose()
    setGlobalValidationErrors({})
  }

  const handleCreateAndUpdate = async () => {
    setIsBusy(true)

    const url =
      mode === 'add'
        ? '/vehicleFleetProduct/create-vehicle-fleet'
        : '/vehicleFleetProduct/update-vehicle-fleet'

    if (mode === 'add') {
      setIsBusy(true)
      const { success } = await postData(url, fleetProduct, false)
      if (success) {
        setRefresh(!refresh)
        setFleetProduct({
          productTypeDescription: Object.keys(vehicleFleetProductType)[0],
          productType: Object.keys(vehicleFleetProductType)[0],
        })
        showNotification('Create Fleet Product', 'success')
        handleCloseModal()
      }
    } else {
      setIsBusy(true)
      const { success } = await putData(url, fleetProduct, false)
      if (success) {
        setRefresh(!refresh)
        setFleetProduct({
          productTypeDescription: Object.keys(vehicleFleetProductType)[0],
          productType: Object.keys(vehicleFleetProductType)[0],
        })
        showNotification('Update Fleet Product', 'success')
        handleCloseModal()
      }
    }

    setIsBusy(false)
  }

  console.log(fleetProduct)

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleCreateAndUpdate}
      modalClassName="modal-xl"
      modalTitle="fleetProduct"
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            disabled={isBusy}
            id="editFleetProductIsStatutory"
            checked={fleetProduct?.isStatutory}
            onCheck={e => {
              setFleetProduct({
                ...fleetProduct,
                isStatutory: e,
              })
            }}
          />
          <label
            className="ms-2 me-4 text-capitalize"
            htmlFor="editFleetProductIsStatutory"
          >
            {localize('isStatutory')} ?
          </label>
          <Checkbox
            disabled={isBusy}
            id="editFleetProductIsLocked"
            checked={fleetProduct?.isLocked}
            onCheck={e => {
              setFleetProduct({
                ...fleetProduct,
                isLocked: e,
              })
            }}
          />
          <label
            className="ms-2 me-4 text-capitalize"
            htmlFor="editFleetProductIsLocked"
          >
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-12">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['Description']?.message
              }
            >
              <label className="text-capitalize col-1">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                value={fleetProduct?.description || ''}
                onChange={e => {
                  clearValidation('Description')
                  setFleetProduct({
                    ...fleetProduct,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-2 me-2">
                {localize('productType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="vehicleFleetProductType"
                value={fleetProduct?.productType}
                onChange={e => {
                  setFleetProduct({
                    ...fleetProduct,
                    productTypeDescription: e.label,
                    productType: e.value,
                    savingsProductId: null,
                    investmentProductId: null,
                    targetProductDescription: '',
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize(
                  vehicleFleetProductType[fleetProduct.productTypeDescription]
                    ?.name || fleetProduct.productTypeDescription
                )}
              </label>
              {Number(fleetProduct.productType) === 0 ? (
                <SavingsProductLookup
                  displayValue={fleetProduct.targetProductDescription}
                  onSelect={value => {
                    setFleetProduct({
                      ...fleetProduct,
                      targetProductDescription: value.chartOfAccountAccountName,
                      savingsProductId: value.id,
                    })
                  }}
                />
              ) : (
                <InvestmentProductLookup
                  displayValue={fleetProduct.targetProductDescription}
                  defaultFilter={1}
                  onSelect={value => {
                    setFleetProduct({
                      ...fleetProduct,
                      targetProductDescription: value.chartOfAccountAccountName,
                      investmentProductId: value.id,
                    })
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('minimumContribution')}
              </label>
              <MaskNumber
                className="ms-2"
                defaultMaskValue={fleetProduct?.minimumContribution}
                onMaskNumber={e => {
                  setFleetProduct({
                    ...fleetProduct,
                    minimumContribution: e.target.rawValue,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('contributionPriority')}
              </label>
              <MaskNumber
                defaultMaskValue={fleetProduct?.priority}
                onMaskNumber={e => {
                  setFleetProduct({
                    ...fleetProduct,
                    priority: e.target.rawValue,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateFeetProduct
