/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import TransactionReferenceLookup from '../../../../../Components/Lookup/TransactionReferenceLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import AdHocLoanInterestCapitalization from '../../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { LoansGuaranteed } from '../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import { ViewCustomerInvestmentAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  membershipWithdrawalRegistrationOption,
  withdrawalNotificationCategory,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import AdHocStandingOrderExecutionModal from './AdHocStandingOrderExecutionModal/AdHocStandingOrderExecutionModal'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

const initialCustomer = {
  terminationCategory: Object.keys(withdrawalNotificationCategory)[1],
  registrationAction: Object.keys(membershipWithdrawalRegistrationOption)[0],
}

function AddCatalogueModal({
  modalMode,
  handleClose,
  onSelectCustomer,
  customerSavingsAccounts,
  customerLoanAccounts,
  customerInvestmentAccounts,
  customerLoansGuarantor,
  balance,
  loadData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [customer, setCustomer] = useState({
    terminationCategory: Object.keys(withdrawalNotificationCategory)[1],
    registrationAction: Object.keys(membershipWithdrawalRegistrationOption)[0],
  })
  const [transactionRef, setTransactionRef] = useState({})
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})
  const [adHocStandingOrder, setAdHocStandingOrder] = useState({})
  const [showAdHocStandingOrderModal, setShowAdHocStandingOrderModal] =
    useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const deceased = Object.keys(withdrawalNotificationCategory)[0]
  const voluntary = Object.keys(withdrawalNotificationCategory)[1]
  const retiree = Object.keys(withdrawalNotificationCategory)[2]

  const handleSelectCustomer = r => {
    clearValidation('WithdrawalNotification.CustomerId')
    setCustomer({
      ...r,
      terminationCategory: initialCustomer.terminationCategory,
      registrationAction: initialCustomer.registrationAction,
    })

    onSelectCustomer(r)
  }

  const handleCreateCatalogue = async () => {
    setIsBusy(true)
    const url = '/WithdrawalNotification/create-withdrawal-notification'

    const { success } = await postData(
      url,
      {
        withdrawalNotification: {
          customerId: customer?.id,
          transactionReferenceId: transactionRef?.id,
          remarks: customer?.terminationRemarks,
          customerMembershipClassMembershipTerminationNoticePeriod:
            customer?.membershipClassMembershipTerminationNoticePeriod,
          customerMembershipClassCloseSavingsAccountsOnMembershipTerminationNotification:
            customer?.membershipClassCloseSavingsAccountsOnMembershipTerminationNotification,
          netRefundable: balance?.netRefundable,
          registrationOption: customer.registrationAction,
          category: customer.terminationCategory,
        },
        membershipWithdrawalRegistrationOption: customer.registrationAction,
      },
      false
    )

    if (success) {
      handleClose()
      loadData()
      clearValidation('WithdrawalNotification.CustomerId')
      clearValidation('WithdrawalNotification.TransactionReferenceId')
      clearValidation('WithdrawalNotification.Remarks')
      showNotification('Membership Withdrawal Notifications', 'success')
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const tabItems = [
    {
      label: localize('loanAccounts'),
      item: (
        <>
          <ViewCustomerLoanAccounts
            customerLoanAccounts={customerLoanAccounts}
            handleAdHocLoan={r => {
              setLoanAccount(r)
              setShowAdHocModal(true)
            }}
            hiddenColumnsIndexes={[0]}
          />
        </>
      ),
    },
    {
      label: localize('savingsAccounts'),
      item: (
        <>
          <ViewCustomerSavingsAccounts
            customerSavingsAccounts={customerSavingsAccounts}
          />
        </>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <>
          <ViewCustomerInvestmentAccounts
            customerInvestmentAccounts={customerInvestmentAccounts}
            handleExecuteStandingOrder={r => {
              setAdHocStandingOrder(r)
              setShowAdHocStandingOrderModal(true)
            }}
          />
        </>
      ),
    },
    {
      label: localize('loanGuaranteed'),
      item: (
        <>
          <LoansGuaranteed loanGuarantors={customerLoansGuarantor} />
        </>
      ),
    },
  ]

  return (
    <>
      {showAdHocModal && (
        <AdHocLoanInterestCapitalization
          handleClose={() => {
            setShowAdHocModal(false)
          }}
          loanAccount={loanAccount}
          modalMode={modalMode}
        />
      )}
      {showAdHocStandingOrderModal && (
        <AdHocStandingOrderExecutionModal
          handleClose={() => {
            setShowAdHocStandingOrderModal(false)
          }}
          adHocStandingOrder={adHocStandingOrder}
          modalMode={modalMode}
        />
      )}

      <ModalTemplate
        modalTitle={localize('membershipWithdrawalNotification')}
        modalMode={modalMode}
        handleAction={handleCreateCatalogue}
        handleClose={() => {
          handleClose()
          clearValidation('WithdrawalNotification.CustomerId')
          clearValidation('WithdrawalNotification.TransactionReferenceId')
          clearValidation('WithdrawalNotification.Remarks')
        }}
        disableClose={isBusy}
        disableCreate={isBusy}
        modalClassName="modal-xl"
        footerChildren={
          <>
            <div className="d-flex align-items-center gap-2">
              <label className="text-capitalize">
                {localize('registrationAction')}
              </label>
              <EnumsServerCombo
                disabled={isBusy}
                customContainerStyle={{
                  maxWidth: 150,
                }}
                enumsKey="MembershipWithdrawalRegistrationOption"
                value={customer.registrationAction}
                onChange={e => {
                  setCustomer({
                    ...customer,
                    registrationAction: e.value,
                  })
                }}
              />
            </div>
          </>
        }
      >
        <div
          style={{
            position: 'relative',
          }}
        >
          <div className="row mb-2">
            <div
              validation-message={
                globalValidationErrors['WithdrawalNotification.CustomerId']
                  ?.message
              }
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <CustomersLookup
                displayValue={customer?.fullName}
                onSelect={handleSelectCustomer}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.membershipClassCustomerTypeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.membershipStatusDescription}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipWdr.Date')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={formatDate(customer?.membershipTerminationDate, true)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('domicileBranch')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.employerDescription}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('dutyWorkStation')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.dutyStationDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('adminDivision')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.administrativeDivisionDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.identificationNumber}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.formattedMemberNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.formattedAccountNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.personalFileNumber}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className=" d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors[
                    'WithdrawalNotification.TransactionReferenceId'
                  ]?.message
                }
              >
                <label className="col-4 text-capitalize">
                  {localize('transactionReference')}
                </label>
                <TransactionReferenceLookup
                  displayValue={transactionRef?.description}
                  onSelect={r => {
                    clearValidation(
                      'WithdrawalNotification.TransactionReferenceId'
                    )
                    setTransactionRef(r)
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationCategory')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="WithdrawalNotificationCategory"
                  onChange={e => {
                    setCustomer({
                      ...customer,
                      terminationCategory: e.value,
                    })
                  }}
                  value={customer?.terminationCategory}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['WithdrawalNotification.Remarks']
                    ?.message
                }
              >
                <label className="col-4 text-capitalize">
                  {localize('terminationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.terminationRemarks || ''}
                  onChange={e => {
                    clearValidation('WithdrawalNotification.Remarks')
                    setCustomer({
                      ...customer,
                      terminationRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <Tab tabItems={tabItems} />
            </div>
          </div>

          {customer && customer.terminationCategory !== deceased && (
            <div className="row mt-3 bg-light rounded p-2">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('deposits(Refundable)')}
                  </label>

                  <input
                    type="text"
                    className="form-control text-end"
                    disabled
                    value={formatCurrency(
                      customer?.terminationCategory === voluntary ||
                        customer?.terminationCategory === retiree
                        ? balance?.netInvestmentsBalance
                        : balance?.backOfficeInvestmentsBalance,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('loansBalance')}
                  </label>

                  <input
                    type="text"
                    className="form-control text-end"
                    disabled
                    value={formatCurrency(
                      customer?.terminationCategory === voluntary ||
                        customer?.terminationCategory === retiree
                        ? balance?.netLoansBalance
                        : balance?.backOfficeLoansBalance,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('netRefundable')}
                  </label>

                  <input
                    type="text"
                    className="form-control text-end"
                    disabled
                    value={formatCurrency(
                      customer?.terminationCategory === voluntary ||
                        customer?.terminationCategory === retiree
                        ? balance?.netRefundable
                        : balance?.netPartialRefundable,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {isBusy && <NonBlockingLoader />}
        </div>
      </ModalTemplate>
    </>
  )
}

export default AddCatalogueModal
