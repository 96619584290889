/** @format */

import localize from '../../../../../Global/Localize'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Swal from 'sweetalert2'
import CustomerAccountLookup from '../../../../../Components/Lookup/CustomerAccountLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { postData } from '../../../../../Helpers/webApi'
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { generateGuid } from '../../../../../Helpers/extensions'
import {
  formatDate,
  formatCurrency,
  formatAsPercent,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'

const CreateCatalogue = ({
  handleClose,
  handleAction,
  mode,
  catalogue,
  setCatalogue,
  createParams,
  setCreateParams,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [periodData, setPeriodData] = useState({})
  const [numberOfPeriodsParams, setNumberOfPeriodsParams] = useState({})
  const [repaymentScheduleData, setRepaymentScheduleTableData] = useState({})
  const [repaymentScheduleParams, setRepaymentScheduleParams] = useState({})
  const [termInMonths, setTermInMonths] = useState(0)
  const columns = [
    {
      label: localize('period'),
      template: r => <>{r?.period}</>,
    },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    {
      label: localize('startingBalance'),
      template: r => <>{formatCurrency(r?.startingBalance)}</>,
    },
    {
      label: localize('payment'),
      template: r => <>{formatCurrency(r?.payment)}</>,
    },
    {
      label: localize('interestPayment'),
      template: r => <>{formatCurrency(r?.interestPayment)}</>,
    },
    {
      label: localize('principalPayment'),
      template: r => <>{formatCurrency(r?.principalPayment)}</>,
    },
    {
      label: localize('endingBalance'),
      template: r => <>{formatCurrency(r?.endingBalance)}</>,
    },
  ]

  useEffect(() => {
    handleFetchRepaymentScheduleData()
    // eslint-disable-next-line
  }, [termInMonths, periodData])

  const handleFetchNumberOfPeriods = async () => {
    setIsBusy(true)
    const url = '/Journal/NPer'
    const { data, success } = await postData(url, numberOfPeriodsParams)

    if (success) {
      setIsBusy(false)
      setPeriodData(data.result)
    } else {
      setIsBusy(false)
    }
    setTermInMonths(Math.round(data.result))
  }

  const handleFetchRepaymentScheduleData = async () => {
    setIsBusy(true)
    const url = '/Journal/repayment-schedule'
    const { data, success } = await postData(
      url,
      {
        ...repaymentScheduleParams,
        termInMonths,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setRepaymentScheduleTableData(
        data.result.map(d => ({ ...d, id: generateGuid() }))
      )
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectCustomer = x => {
    if (x.customerAccountTypeTargetProductDescription === 'Salary Accounts') {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a valid loan account.',
      })
    } else if (Math.round(x.interestBalance) !== 0) {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Sorry but the selected customer account must not have an interest balance!',
      })
    } else if (Math.round(x.principalBalance) === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Sorry but the principal balance for the selected account is 0.00!',
      })
    } else {
      setCatalogue(x)
      setNumberOfPeriodsParams({
        interestCalculationMode: x.loanCaseLoanInterestCalculationMode,
        paymentFrequencyPerYear:
          x.loanCaseLoanRegistrationPaymentFrequencyPerYear,
        apr: x.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate,
        pmt: '',
        pv: x.principalBalance,
        roundingType: x.customerAccountTypeTargetProductRoundingType,
      })
      setRepaymentScheduleParams({
        termInMonths,
        paymentFrequencyPerYear:
          x.loanCaseLoanRegistrationPaymentFrequencyPerYear,
        gracePeriod: x.loanCaseLoanRegistrationGracePeriod,
        interestCalculationMode: x.loanCaseLoanInterestCalculationMode,
        roundingType: x.customerAccountTypeTargetProductRoundingType,
        apr: x.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate,
        pv: x.principalBalance,
        dtDate: getTodaysDate(),
        rebaseDueDate: true,
      })
    }
  }

  const tabItems = [
    {
      label: localize('reschedulingDetails'),
      item: (
        <div className="p-2 mb-2">
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('loanProduct')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerAccountTypeTargetProductDescription}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('interestCalculationMode')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    catalogue?.loanCaseLoanInterestCalculationModeDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('annualPercentageRate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatAsPercent(
                    catalogue?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate
                  )}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('paymentFrequencyPerYear')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    catalogue?.loanCaseLoanRegistrationPaymentFrequencyPerYear
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-4 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('paymentPerPeriod')}
                </label>
                <input
                  type="number"
                  className="form-control text-end"
                  onChange={e => {
                    setNumberOfPeriodsParams({
                      ...numberOfPeriodsParams,
                      pmt: e.target.value,
                    })
                  }}
                  onBlur={() => {
                    handleFetchNumberOfPeriods()
                  }}
                  value={numberOfPeriodsParams?.pmt}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('numberOfPeriods')}
                </label>
                <input
                  type="number"
                  className="form-control text-end"
                  disabled
                  value={Math.round(periodData)}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txtReference"
                  onChange={x => {
                    setCatalogue({
                      ...catalogue,
                      reference: x.target.value,
                    })
                    setCreateParams({
                      fullAccountNumber: catalogue?.fullAccountNumber,
                      customerAccountId: catalogue?.id,
                      numberOfPeriods: Math.round(periodData),
                      paymentPerPeriod: numberOfPeriodsParams?.pmt,
                      reference: x.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div
            className="mt-2 row"
            style={{
              position: 'relative',
              border: 'solid 1px #0000001f',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <div
              className="bg-white row px-2 text-capitalize mb-4"
              style={{
                position: 'absolute',
                top: '-8px',
                left: '30px',
                maxWidth: 'fit-content',
              }}
            >
              {localize('newRepaymentSchedule')}
            </div>
            <SimpleTable
              columns={columns}
              className="mt-4"
              data={repaymentScheduleData || []}
            />
          </div>
        </div>
      ),
    },
  ]

  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={'update'}
      modalTitle={localize('loanRescheduleRequest')}
      modalClassName="modal-xl"
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="fullAccountNumber"
                  className="text-capitalize col-6"
                >
                  {localize('fullAccountNumber')}
                </label>
                <CustomerAccountLookup
                  displayValue={catalogue?.fullAccountNumber}
                  onSelect={x => {
                    handleSelectCustomer(x)
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('accountStatus')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.statusDescription}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('accountRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerAccountRemarks}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('productName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerAccountTypeTargetProductDescription}
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('customerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerFullName}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('customerType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    catalogue?.customerMembershipClassCustomerTypeDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('customerSerial#')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerSerialNumber}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('employerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerEmployerDescription}
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('identification#')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerIndividualIdentityCardNumber}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('memberNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerMemberNumber}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('accountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerAccountNumber}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('personalFileNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.customerReference3}
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-4 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('bookBalance')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatCurrency(catalogue?.bookBalance)}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('principalBalance')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatCurrency(catalogue?.principalBalance)}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('interestBalance')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatCurrency(catalogue?.interestBalance)}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('loanClassification')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={catalogue?.loanClassificationDescription}
                />
              </div>
            </div>
          </div>
          <div className="mb-2">
            <Tab preload tabItems={tabItems} />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateCatalogue
