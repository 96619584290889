/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import LoanCasePartial from '../Cancellation/LoanCasePartial'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

const Verification = () => {
  const [loanCase, setLoanCase] = useState(null)
  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanVerification'),
        ]}
      />
    </>
  )
}

export default Verification
