/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { repaymentSchedule } from '../../../../../Helpers/Modules/loan'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'

function RepaymentSchedule({
  loanProduct,
  termInMonths,
  amountAppraised,
  useLoanCase,
  loanCase,
}) {
  const [schedule, setSchedule] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    getRepaymentSchedule()
    // eslint-disable-next-line
  }, [loanProduct, termInMonths, amountAppraised])

  async function getRepaymentSchedule() {
    setIsBusy(true)
    let scheduleTable = useLoanCase
      ? await repaymentSchedule(
          loanCase?.loanRegistrationTermInMonths,
          loanCase?.loanRegistrationPaymentFrequencyPerYear,
          loanCase?.loanRegistrationGracePeriod,
          loanCase?.loanInterestCalculationMode,
          loanCase?.loanInterestAnnualPercentageRate,
          -amountAppraised,
          loanCase?.loanRegistrationRoundingType,
          null,
          loanCase?.loanRegistrationRebasePaymentDueDate
        )
      : await repaymentSchedule(
          termInMonths,
          loanProduct?.loanRegistrationPaymentFrequencyPerYear,
          loanProduct?.loanRegistrationGracePeriod,
          loanProduct?.loanInterestCalculationMode,
          loanProduct?.loanInterestAnnualPercentageRate,
          -amountAppraised,
          loanProduct?.loanRegistrationRoundingType,
          null,
          loanProduct?.loanRegistrationRebasePaymentDueDate
        )
    if (schedule) {
      setSchedule(scheduleTable)
    }
    setIsBusy(false)
  }

  // async function getRepaymentScheducleUsingLoanCase() {
  //   setIsBusy(true)
  //   let scheduleTable = await repaymentSchedule(
  //     loanCase?.loanRegistrationTermInMonths,
  //     loanCase?.loanRegistrationPaymentFrequencyPerYear,
  //     loanCase?.loanRegistrationGracePeriod,
  //     loanCase?.loanInterestCalculationMode,
  //     loanCase?.loanInterestAnnualPercentageRate,
  //     -loanCase?.amountApplied,
  //     loanCase?.loanRegistrationRoundingType,
  //     null,
  //     loanCase?.loanRegistrationRebasePaymentDueDate
  //   )

  //   if (schedule) {
  //     setSchedule(scheduleTable)
  //   }
  //   setIsBusy(false)
  // }

  const columns = [
    { label: localize('period'), template: r => <>{r.period}</> },
    { label: localize('dueDate'), template: r => <>{formatDate(r.dueDate)}</> },
    {
      label: localize('startingBalance'),
      template: r => <>{formatCurrency(r.startingBalance)}</>,
    },
    {
      label: localize('payment'),
      template: r => <>{formatCurrency(r.payment)}</>,
    },
    {
      label: localize('interestPayment'),
      template: r => <>{formatCurrency(r.interestPayment)}</>,
    },
    {
      label: localize('principalPayment'),
      template: r => <>{formatCurrency(r.principalPayment)}</>,
    },
    {
      label: localize('endingBalance'),
      template: r => <>{formatCurrency(r.endingBalance)}</>,
    },
  ]

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <SimpleTable columns={columns} data={schedule} />
    </>
  )
}

export default RepaymentSchedule
