/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _w = {
  withdrawalNoticePeriod: {
    en: 'withdrawal notice period',
    sw: 'kipindi cha notisi ya kujiondoa',
  },
  withdrawalNoticeAmount: {
    en: 'withdrawal notice amount',
    sw: 'kiasi cha notisi ya uondoaji',
  },
  withdrawalInterval: {
    en: 'withdrawal interval',
    sw: 'muda wa kuondoa',
  },
  wordifiedAmount: {
    en: 'wordified amount',
    sw: 'kiasi cha maneno',
  },
  writtenBy: {
    en: 'written by',
    sw: 'imeandikwa na',
  },
  writtenDate: {
    en: 'written Date',
    sw: 'tarehe iliyoandikwa',
  },
  workFlow: {
    en: 'workflow',
    sw: 'mtiririko wa kazi',
  },
  weight: {
    en: 'weight',
    sw: 'uzito',
  },
  workStations: {
    en: 'work stations',
    sw: 'vituo vya kazi',
  },
  wellKnown: {
    en: 'well-known',
    sw: 'maalumu',
  },
  wireTransferTypes: {
    en: 'wire transfer types',
    sw: 'aina za uhamisho wa waya',
  },
  whithdrawalSettlementModes: {
    en: 'withdrawal settlement modes',
    sw: 'njia za makazi ya uondoaji',
  },
  wellKnownCharges: {
    en: 'well-known charges',
    sw: 'gharama maalumu',
  },
  workingDays: {
    en: 'working days',
    sw: 'siku za kazi',
  },
  withHoldingTaxes: {
    en: 'withholding taxes',
    sw: 'kuzuilia kodi',
  },
  workStation: {
    en: 'work station',
    sw: 'kituo cha kazi',
  },
  withdrawalNotice: {
    en: 'withdrawal notice ',
    sw: 'ilani ya uondoaji',
  },
  withdrawalSettlementModes: {
    en: 'withdrawal settlement modes',
    sw: 'njia za uondoaji wa settlement',
  },
  workStationLinkage: {
    en: 'work station linkage',
    sw: 'uhusiano wa kituo cha kazi',
  },
  workstationLookup: {
    en: 'workstation lookup',
    sw: 'tafuta kituo cha kazi',
  },
  wireTransfer: {
    en: 'wire transfer',
    sw: 'weka uhamishaji',
  },
  wireTransferOrigination: {
    en: 'wire transfer batches (origination)',
    sw: 'weka uhamishaji (asili)',
  },
  wireTransferVerification: {
    en: 'wire transfer batches (verification)',
    sw: 'weka uhamishaji (uthibitishaji)',
  },
  wireTransferAuthorization: {
    en: 'wire transfer batches (authorization)',
    sw: 'weka uhamishaji (idhini)',
  },
  workflow: {
    en: 'workflow',
    sw: 'mtiririko wa kazi',
  },
  wireTransferType: {
    en: 'wire transfer type',
    sw: 'aina ya uhamisho',
  },
  wireTransferTypeLookup: {
    en: 'wire transfer type lookup',
    sw: 'tafuta aina ya uhamisho wa pesa',
  },
  wireTransferBatches: {
    en: 'wire transfer batches',
    sw: 'kikundi cha uhamisho wa waya',
  },
  writeDate: {
    en: 'write date',
    sw: 'tarehe ya kuandika',
  },
  withdrawableAmount: {
    en: 'withdrawable amount',
    sw: 'kiasi kinachoweza kutolewa',
  },
  WriteDate: {
    en: 'write date',
    sw: 'tarehe ya kuandika',
  },
  writing: {
    en: 'writing',
    sw: 'kuandika',
  },
  withholdingTaxAmount: {
    en: 'withholding tax amount',
    sw: 'kiasi cha kodi ya kuzuia',
  },
  westernUnion: {
    en: 'western union',
    sw: 'western union',
  },
  'written/flaggedBy': {
    en: 'written/flagged by',
    sw: 'imeandikwa / imepewa alama na',
  },
  'written/flaggedDate': {
    en: 'written/flagged date',
    sw: 'tarehe iliyoandikwa / kuchaguliwa',
  },
  workingTime: {
    en: 'working time',
    sw: 'muda wa kufanya kazi',
  },
  workingDayOfWeek: {
    en: 'working day of week',
    sw: 'siku ya kazi ya wiki',
  },
  workingTimePreferences: {
    en: 'working time preferences',
    sw: 'mapendekezo ya muda wa kufanya kazi',
  },
  withdrawalSettlementModeLookup: {
    en: 'withdrawal settlement mode lookup',
    sw: 'tafuta makazi ya uondoaji',
  },
}

export default _w
