/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import Transactions from '../Transactions'

function ViewGLAccountTransactions({ glAccount, handleClose, mode }) {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="gLAccountTransactions"
      hideUpdate
      handleClose={handleClose}
      cancelText="close"
      modalClassName="modal-xl"
    >
      <Transactions glAccount={glAccount} />
    </ModalTemplate>
  )
}

export default ViewGLAccountTransactions
