/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'

const TransactionsTab = ({
  selectedTransactions,
  handleSelectTransaction,
  transactions,
}) => {
  const columns = [
    {
      label: 'select',
      template: row => (
        <>
          <Checkbox
            checked={selectedTransactions.find(x => x.id === row.id)}
            onCheck={v => handleSelectTransaction(row, v)}
          />
        </>
      ),
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('adjustmentType'),
      template: r => r.adjustmentTypeDescription,
    },
    {
      label: localize('amount'),
      template: r => formatCurrency(r.amount),
    },
    {
      label: localize('valueDate'),
      template: r => formatDate(r.valueDate),
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('modifiedBy'),
      template: r => r.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  return (
    <div className="py-3">
      <SimpleTable data={transactions} columns={columns} />
    </div>
  )
}

export default TransactionsTab
