/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import LoanBatchEntries from '../../BatchEntries/LoanBatchEntries/LoanBatchEntries'

const ViewLoanDisbursement = ({ handleClose, mode, loan }) => {
  return (
    <>
      <ModalTemplate
        modalTitle="loanDisbursementBatch"
        hideUpdate
        cancelText="close"
        modalMode={mode}
        handleClose={handleClose}
        modalClassName="modal-xl"
      >
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('batchNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.paddedBatchNumber}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('batchTotal')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(loan?.batchTotal, null, null, false)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('category')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.loanProductCategoryDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('branch')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.branchDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('disbursementMode')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.loanDisbursementModeDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.reference}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.createdBy}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(loan?.createdDate)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('status')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.statusDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('verifiedBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.auditedBy}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('verifiedDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(loan?.auditedDate)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('verificationRemarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.auditRemarks}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('authorizedBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.authorizedBy}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('verifiedDate')}
              </label>
              <input
                type="text"
                className="form-control"
                value={formatDate(loan?.authorizedDate)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('verificationRemarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={loan?.authorizationRemarks}
                disabled
              />
            </div>
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2  row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('batchEntries')}
          </div>
          <div className="row mt-4">
            <div className="ms-3">
              <LoanBatchEntries
                showSelect={false}
                hiddenColumns={[0]}
                loanDisbursementBatchId={loan?.id}
                showReset
                showView
              />
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}

export default ViewLoanDisbursement
