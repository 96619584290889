/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _p = {
  payslips: {
    en: 'Payslips',
    sw: 'stakabadhi za mishahara',
  },
  performancePillar: {
    en: 'performance pillar',
    sw: 'nguzo ya utendaji',
  },
  performancePillars: {
    en: 'Performance Pillars',
    sw: 'Nguzo Za Utendaji',
  },
  performanceObjective: {
    en: 'perfomance objective',
    sw: 'lengo la utendaji',
  },
  performanceObjectives: {
    en: 'Performance Objectives',
    sw: 'Malengo Ya Utendaji',
  },
  performancePeriods: {
    en: 'Performance Periods',
    sw: 'Vipindi Vya Utendaji',
  },
  performanceMeasures: {
    en: 'Performance Measures',
    sw: 'Hatua Za Utendaji',
  },
  performanceManagement: {
    en: 'Performance Management',
    sw: 'Usimamizi Wa Utendaji',
  },
  periods: {
    en: 'periods',
    sw: 'nyakati Za Mishahara',
  },
  processing: {
    en: 'processing',
    sw: 'usindikaji wa mishahara',
  },
  postingPeriod: {
    en: 'posting period',
    sw: 'kipindi cha kutuma',
  },
  postingPeriods: {
    en: 'posting periods',
    sw: 'vipindi vya kutuma',
  },
  pleaseWait: {
    en: 'please wait',
    sw: 'tafadhali subiri',
  },
  paddedCode: {
    en: 'code',
    sw: 'msimbo',
  },
  personalIdentificationNumber: {
    en: 'personal identification number',
    sw: 'nambari ya utambulisho wa kibinafsi',
  },
  physicalAddress: {
    en: 'physical address',
    sw: 'anwani ya mahali ulipo',
  },
  postalCode: {
    en: 'postal code',
    sw: 'msimbo',
  },
  pettyCashiers: {
    en: 'petty cashiers',
    sw: 'watunza fedha ndogo',
  },
  pointOfSaleAgencyDescription: {
    en: 'pos agency',
    sw: 'maelezo ya wakala wa mahali pa mauzo',
  },
  preserveAccountBalance: {
    en: 'preserve account  balance',
    sw: 'hifadhi salio la akaunti',
  },
  permitOverdrawing: {
    en: 'permit overdrawing',
    sw: 'ruhusa ya kuidhinisha',
  },
  paddedSerialNumber: {
    en: 'serial number',
    sw: 'nambari ya serial',
  },
  priority: {
    en: 'priority',
    sw: 'Kipaumbele',
  },
  paddedAmount: {
    en: 'padded amount',
    sw: 'kiasi kilichowekwa',
  },
  printedBy: {
    en: 'printed by',
    sw: 'imechapishwa na',
  },
  printedDate: {
    en: 'printed date',
    sw: 'tarehe iliyochapishwa',
  },
  procurementAndAssetsManagement: {
    en: 'procurement and assets management',
    sw: 'muamala na usimamizi wa mali',
  },
  provisioning: {
    en: 'provisioning',
    sw: 'utoaji',
  },
  personalFileNumber: {
    en: 'personal file number',
    sw: 'nambari ya faili ya kibinafsi',
  },
  principalInvestmentProduct: {
    en: 'principal investment product',
    sw: 'kipindi kikuu cha uwekezaji',
  },

  posTerminalAgent: {
    en: 'pos terminal agent',
    sw: 'wakala wa mwisho',
  },
  payrollNumberTypes: {
    en: 'payroll number types',
    sw: 'aina za nambari za mishahara',
  },
  parent: {
    en: 'parent',
    sw: 'zazi',
  },
  prorated: {
    en: 'prorated',
    sw: 'imethibitishwa',
  },
  postAutomaticallyOnly: {
    en: 'post automatically only',
    sw: 'chapisha kiotomatiki pekee',
  },
  parentGLAccount: {
    en: 'parent GL account',
    sw: 'akaunti zazi ya GL',
  },
  percentage: {
    en: 'percentage',
    sw: 'asilimia',
  },
  poolGLAccountName: {
    en: 'pool g/l account name',
    sw: 'jina la akaunti ya g/l',
  },
  poolGLAccountDistributionRule: {
    en: 'pool g/l account distribution rule',
    sw: 'kanuni ya usambazaji wa akaunti ya g/l',
  },
  poolAmount: {
    en: 'pool amount',
    sw: 'kiasi cha pesa',
  },
  productCategory: {
    en: 'product category',
    sw: 'kategoria ya bidhaa',
  },
  pin: {
    en: 'pin',
    sw: 'pini',
  },
  parentTemplate: {
    en: 'parent template',
    sw: 'kiolezo cha mzazi',
  },
  product: {
    en: 'product',
    sw: 'bidhaa',
  },
  productCode: {
    en: 'product code',
    sw: 'alama siri ya bidhaa',
  },
  productName: {
    en: 'product name',
    sw: 'jina la bidhaa',
  },
  productType: {
    en: 'product type',
    sw: 'aina ya bidhaa',
  },
  productSection: {
    en: 'product section',
    sw: 'sehemu ya bidhaa',
  },
  permitMultipleCustomerAccounts: {
    en: 'permit multiple customer accounts',
    sw: 'ruhusu akaunti nyingi za wateja',
  },
  paymentFrequencyPerYear: {
    en: 'payment frequency per year',
    sw: 'mzunguko wa malipo kwa mwaka',
  },
  payoutRecoveryMode: {
    en: 'payment recovery mode',
    sw: 'hali ya kurejesha malipo',
  },
  payoutRecoveryPercentage: {
    en: 'payment recovery percentage',
    sw: 'asilimia ya kurejesha malipo',
  },
  principalGLAccountName: {
    en: 'principal g/l account name',
    sw: 'jina kuu la akaunti ya g/l',
  },
  penaltyReceivedGLAccountName: {
    en: 'penalty received g/l account name',
    sw: 'adhabu iliyopokelewa jina la akaunti ya g/l',
  },
  penaltyReceivedGLAccountCostDistributionRule: {
    en: 'penalty received g/l account cost distribution rule',
    sw: 'sheria ya usambazaji wa gharama ya akaunti ya g/l',
  },
  penaltyReceivableGLAccountName: {
    en: 'penalty receivable g/l account name',
    sw: 'adhabu inayopokelewa jina la akaunti ya g/l',
  },
  penaltyReceivableGLAccountDistributionRule: {
    en: 'penalty receivable g/l account distribution rule',
    sw: 'sheria ya usambazaji wa akaunti ya g/l inayopokea adhabu',
  },
  payrollNumberTypeDescription: {
    en: 'payroll number type description',
    sw: 'maelezo ya aina ya mishahara',
  },
  payrollNumberType: {
    en: 'payroll number type',
    sw: 'aina ya nambari ya mshahara',
  },
  posTerminals: {
    en: 'point of sale terminals',
    sw: 'vituo vya mauzo',
  },
  pointOfSaleTerminals: {
    en: 'point of sale terminals',
    sw: 'vituo vya mauzo',
  },
  postingPeriodLookup: {
    en: 'posting period lookup',
    sw: 'Utafutaji wa Kipindi cha Kuchapisha',
  },
  postingPeriodsLookup: {
    en: 'posting periods lookup',
    sw: 'Utafutaji wa vipindi cha Kuchapisha',
  },
  personalFile: {
    en: 'personal file',
    sw: 'faili ya kibinafsi',
  },
  passport: {
    en: 'passport',
    sw: 'pasipoti',
  },
  payrollNumbers: {
    en: 'payroll numbers',
    sw: 'nambari za malipo',
  },
  paddedCustomerAccountNumber: {
    en: 'padded customer account number',
    sw: 'nambari ya akaunti ya mteja iliyoboreshwa',
  },
  populationRegisterQueries: {
    en: 'population register queries',
    sw: 'maswali ya usajili wa idadi ya watu',
  },
  posAgency: {
    en: 'pos agency',
    sw: 'wakala wa pos',
  },
  penaltyReceivedChartOfAccountName: {
    en: 'penalty received g/l name',
    sw: 'jina la akaunti ya adhabu iliyopokelewa',
  },
  penaltyReceivedChartOfAccountCostDistributionRuleDescription: {
    en: 'penalty received g/l cost distribution rule description',
    sw: 'maelezo ya sheria ya usambazaji wa gharama ya akaunti ya adhabu iliyopokelewa',
  },
  penaltyReceivableChartOfAccountName: {
    en: 'penalty receivable g/l name',
    sw: 'jina la akaunti ya adhabu inayopokelewa',
  },
  penaltyReceivableChartOfAccountCostDistributionRuleDescription: {
    en: 'penalty receivable g/l cost distribution rule description',
    sw: 'maelezo ya sheria ya usambazaji wa gharama ya akaunti ya adhabu inayopokelewa',
  },
  payrollNumber: {
    en: 'payroll number',
    sw: 'nambari ya malipo',
  },
  particulars: {
    en: 'particulars',
    sw: 'maelezo',
  },
  printingModeDescription: {
    en: 'printing mode description',
    sw: 'maelezo ya hali ya uchapishaji',
  },
  page: {
    en: 'page',
    sw: 'ukurasa',
  },
  principalBalance: {
    en: 'principal balance',
    sw: 'baki ya kuu',
  },
  payrollnumberTypeLookup: {
    en: 'payroll number type lookup',
    sw: 'utafutaji wa aina ya nambari ya malipo',
  },
  preview: {
    en: 'preview',
    sw: 'kuangalia mapema',
  },
  pinkyFinger: {
    en: 'pinky finger',
    sw: 'kidole kidogo',
  },
  preferredFingerDigit: {
    en: 'preferred finger digit',
    sw: 'tarakimu ya kidole kinachopendelewa',
  },
  previousLogo: {
    en: 'previous logo',
    sw: 'nembo ya awali',
  },
  performanceObjectiveLookup: {
    en: 'performance objective lookup',
    sw: 'utafutaji wa lengo la utendaji',
  },
  partnership: {
    en: 'partnership',
    sw: 'ushirikiano',
  },
  particular: {
    en: 'particular',
    sw: 'maelezo',
  },
  padding: {
    en: 'padding',
    sw: 'upandikizaji',
  },
  prefix: {
    en: 'prefix',
    sw: 'kiambishi awali',
  },
  placeOfBirth: {
    en: 'place of birth',
    sw: 'mahali pa kuzaliwa',
  },
  placeOfDeath: {
    en: 'place of death',
    sw: 'mahali pa kifo',
  },
  placeOfLive: {
    en: 'place of live',
    sw: 'mahali pa kuishi',
  },
  pfNumber: {
    en: 'personal file number.',
    sw: 'nambari ya faili ya kibinafsi',
  },
  postingPeriodClosing: {
    en: 'posting period closing',
    sw: 'kufunga kipindi cha kuchapisha',
  },
  primaryDescription: {
    en: 'primary description',
    sw: 'maelezo ya msingi',
  },
  print: {
    en: 'print',
    sw: 'chapisha',
  },
  pickDate: {
    en: 'pick date',
    sw: 'chagua tarehe',
  },
  printingMode: {
    en: 'printing mode',
    sw: 'hali ya uchapishaji',
  },
  provider: {
    en: 'provider',
    sw: 'mtoa huduma',
  },
  parentPeriod: {
    en: 'parent period',
    sw: 'kipindi kikuu',
  },
  postingDate: {
    en: 'posting date',
    sw: 'tarehe ya kuchapisha',
  },
  policyNumber: {
    en: 'policy number',
    sw: 'nambari ya sera',
  },
  principal: {
    en: 'principal ',
    sw: 'msingi ',
  },
  paymentAmount: {
    en: 'payment amount',
    sw: 'kiasi cha malipo',
  },
  paymentDate: {
    en: 'payment date',
    sw: 'tarehe ya malipo',
  },
  paymentReferenceCode: {
    en: 'payment reference code',
    sw: 'nambari ya rejeleo ya malipo',
  },
  paymentCode: {
    en: 'payment code',
    sw: 'nambari ya malipo',
  },
  paymentMode: {
    en: 'payment mode',
    sw: 'hali ya malipo',
  },
  parentHierarchy: {
    en: 'parent hierarchy',
    sw: 'daraja kuu',
  },
  poolGLAccount: {
    en: 'pool g/l account',
    sw: 'akaunti ya g/l',
  },
  primaryDetails: {
    en: 'primary details',
    sw: 'maelezo msingi',
  },
  payoutRecovery: {
    en: 'payout recovery',
    sw: 'marejesho ya malipo',
  },
  principalGLAccount: {
    en: 'principal g/l account',
    sw: 'akaunti ya msingi ya g/l',
  },
  penaltyReceivableGLAccount: {
    en: 'penalty receivable g/l account',
    sw: 'akaunti ya g/l ya adhabu inayopokelewa',
  },
  penaltyReceivedGLAccount: {
    en: 'penalty received g/l account',
    sw: 'akaunti ya g/l ya adhabu iliyopokelewa',
  },
  payoutSalary: {
    en: 'payout salary',
    sw: 'mshahara wa malipo',
  },
  priorityDescription: {
    en: 'priority description',
    sw: 'maelezo ya kipaumbele',
  },
  poolGLAccountCostDistrRule: {
    en: 'pool g/l account cost distr. rule',
    sw: 'akaunti sheria ya usambazaji wa gharama',
  },
  penaltyBalance: {
    en: 'penalty balance',
    sw: 'baki ya adhabu',
  },
  poolGlCostDistributionRuleDescription: {
    en: 'pool g/l cost distribution rule description',
    sw: 'usambazaji wa gharama ya akaunti ya g/l',
  },
  productCodeDescription: {
    en: 'product code description',
    sw: 'maelezo ya nambari ya bidhaa',
  },
  paddedTargetProductCode: {
    en: 'padded target product code',
    sw: 'nambari ya bidhaa iliyolengwa',
  },
  performancePillarLookup: {
    en: 'performance pillar lookup',
    sw: 'Utendaji wa nguzo ya utendaji',
  },
  'pre-qualification': {
    en: 'pre-qualification',
    sw: 'maandalizi ya kustahili',
  },
  phoneNumber: {
    en: 'phone number',
    sw: 'nambari ya simu',
  },
  postalAddress: {
    en: 'postal address',
    sw: 'anwani ya posta',
  },
  prequalification: {
    en: 'prequalification',
    sw: 'maandalizi ya kustahili',
  },
  printEligibility: {
    en: 'print eligibility',
    sw: 'chapisha uhalali',
  },
  paymentPerPeriod: {
    en: 'payment per period',
    sw: 'malipo kwa kipindi',
  },
  periodIsActive: {
    en: 'period is active',
    sw: 'kipindi kipo',
  },
  photo: {
    en: 'photo',
    sw: 'picha',
  },
  'phone#': {
    en: 'phone #',
    sw: 'nambari ya simu',
  },
  ppi: {
    en: 'PPI',
    sw: 'PPI',
  },
  ppiRank: {
    en: 'PPI Rank',
    sw: 'PPI nafasi',
  },
  phone: {
    en: 'phone',
    sw: 'simu',
  },
  payoutsHistory: {
    en: 'payouts history',
    sw: 'historia ya malipo',
  },
  principalAttached: {
    en: 'principal attached',
    sw: 'msingi uliowekwa',
  },
  principalAttachedFormula: {
    en: 'principal attached formula',
    sw: 'fomula kuu uliyowekwa',
  },
  paymentFrequencyPerYearDescription: {
    en: 'payment frequency per year description',
    sw: 'maelezo ya mzunguko wa malipo kwa mwaka',
  },
  principalChartOfAccountName: {
    en: 'principal g/l name',
    sw: 'jina la akaunti ya msingi',
  },
  permitPartialOffsetting: {
    en: 'permit partial offsetting',
    sw: 'ruhusa ya kufidia sehemu',
  },
  principalChartOfAccountCostDistributionRule: {
    en: 'principal g/l cost distribution rule',
    sw: 'sheria ya usambazaji wa gharama ya akaunti',
  },
  penaltyReceivedChartOfAccountCostDistributionRule: {
    en: 'penalty received g/l cost distribution rule',
    sw: 'sheria ya usambazaji wa gharama ya akaunti ya adhabu iliyopokelewa',
  },
  penaltyReceivableChartOfAccountCostDistributionRule: {
    en: 'penalty receivable g/l cost distribution rule',
    sw: 'sheria ya usambazaji wa gharama ya akaunti ya adhabu inayopokelewa',
  },
  period: {
    en: 'period',
    sw: 'kipindi',
  },
  payment: {
    en: 'payment',
    sw: 'malipo',
  },
  principalPayment: {
    en: 'principal payment',
    sw: 'malipo ya msingi',
  },
  prepaidAmount: {
    en: 'prepaid amount',
    sw: 'kiasi kilicholipwa mapema',
  },
  provisionAmount: {
    en: 'provision amount',
    sw: 'kiasi cha utoaji',
  },
  paymentFrequestPerYear: {
    en: 'payment frequency per year',
    sw: 'mzunguko wa malipo kwa mwaka',
  },
  principalAmount: {
    en: 'principal amount',
    sw: 'kiasi cha msingi',
  },
  printingCustomerAccountFee: {
    en: 'printing customer account fee',
    sw: 'chapisha uchapishaji ya akaunti',
  },
  'pin#': {
    en: 'pin #',
    sw: 'pini #',
  },
  payrollNumbersRegister: {
    en: 'payroll numbers register',
    sw: 'usajili wa nambari za malipo',
  },
  piggyBankEnabled: {
    en: 'piggy bank enabled',
    sw: 'hifadhi ya akiba imewezeshwa',
  },
  parentReport: {
    en: 'parent report',
    sw: 'ripoti kuu',
  },
  prn: {
    en: 'prn',
    sw: 'prn',
  },
  pettyCash: {
    en: 'petty cash',
    sw: 'fedha ndogo',
  },
  paymentRequests: {
    en: 'payment requests',
    sw: 'maombi ya malipo',
  },
  postedBy: {
    en: 'posted by',
    sw: 'imechapishwa na',
  },
  postedDate: {
    en: 'posted date',
    sw: 'tarehe iliyochapishwa',
  },
  paidBy: {
    en: 'paid by',
    sw: 'imelipwa na',
  },
  paidDate: {
    en: 'paid date',
    sw: 'tarehe ya malipo',
  },
  pettyCashSundryPayments: {
    en: 'petty cash sundry payments',
    sw: 'malipo mengine ya fedha ndogo',
  },
  pettyCashierChartOfAccountStatement: {
    en: 'petty cashier g/l account statement',
    sw: 'taarifa ya akaunti ya mfanyabiashara mdogo',
  },
  payee: {
    en: 'payee',
    sw: 'mlipaji',
  },
  pettyCashRequisitions: {
    en: 'petty cash requisitions',
    sw: 'ombi la fedha ndogo',
  },
  pettyCashPaymentRequests: {
    en: 'petty cash payment requests',
    sw: 'maombi ya malipo ya fedha ndogo',
  },
  payments: {
    en: 'payments',
    sw: 'malipo',
  },
  paymentVoucher: {
    en: 'payment voucher',
    sw: 'ombi la malipo',
  },
  paymentVoucherLookup: {
    en: 'payment voucher lookup',
    sw: 'utafutaji wa ombi la malipo',
  },
  partialInterestOffset: {
    en: 'partial interest offset',
    sw: 'kukatiza riba Kwa sehemu',
  },
  partialPrincipalOffsetMathExpression: {
    en: 'partial principal offset math expression',
    sw: 'kukatiza sehemu ya mtaji wa awali',
  },
  partialInterestOffsetMathExpression: {
    en: 'partial interest offset math expression',
    sw: 'kukatiza riba Kwa sehemu',
  },
  partialPrincipalOffset: {
    en: 'partial principal offset',
    sw: 'kukatiza sehemu ya mtaji wa awali',
  },
  printing: {
    en: 'printing',
    sw: 'uchapishaji',
  },
  paymentStatus: {
    en: 'payment status',
    sw: 'hali ya malipo',
  },
  'payables/receivables': {
    en: 'payables/receivables',
    sw: 'malipo/anayelipwa',
  },
  paymentSchedules: {
    en: 'payment schedules',
    sw: 'ratiba ya malipo',
  },
  paymentProcessing: {
    en: 'payment processing',
    sw: 'usindikaji wa malipo',
  },
  pettyCashRequisition: {
    en: 'petty cash requisition',
    sw: 'ombi la fedha ndogo',
  },
  postingPeriodDescription: {
    en: 'posting period description',
    sw: 'maelezo ya kipindi cha kuchapisha',
  },
  printedNumber: {
    en: 'printed number',
    sw: 'nambari iliyochapishwa',
  },
  presentingBank: {
    en: 'presenting bank',
    sw: 'benki inayowasilisha',
  },
  presentingBranch: {
    en: 'presenting branch',
    sw: 'tawi linalowasilisha',
  },
  processedBy: {
    en: 'processed by',
    sw: 'imepangwa na',
  },
  processedDate: {
    en: 'processed date',
    sw: 'tarehe iliyopangwa',
  },
  pay: {
    en: 'pay',
    sw: 'lipa',
  },
  processed: {
    en: 'processed',
    sw: 'imepangwa',
  },
  'pay/unpay': {
    en: 'pay/unpay',
    sw: 'lipa/tolipa',
  },
  'partialOffset(principal)': {
    en: 'partial offset (principal)',
    sw: 'kukatiza sehemu (msingi)',
  },
  'partialOffset(interest)': {
    en: 'partial offset (interest)',
    sw: 'kukatiza sehemu (riba)',
  },
  'partialOffsetFormula(principal)': {
    en: 'partial offset formula (principal)',
    sw: 'fomula ya kukatiza sehemu (msingi)',
  },
  'partialOffsetFormula(interest)': {
    en: 'partial offset formula (interest)',
    sw: 'fomula ya kukatiza sehemu (riba)',
  },
  payablesReceivableOrigination: {
    en: 'payables/receivables (origination)',
  },
  payablesReceivableVerification: {
    en: 'payables/receivables (verification)',
  },
  payablesReceivableAuthorization: {
    en: 'payables/receivables (authorization)',
  },
  'pan/CardNumber': {
    en: 'pan/card number',
    sw: 'nambari ya kadi',
  },
  primaryAccountNumber: {
    en: 'primary account number',
    sw: 'nambari ya akaunti kuu',
  },
  purpose: {
    en: 'purpose',
    sw: 'madhumuni',
  },
  parentGroup: {
    en: 'parent group',
    sw: 'kikundi kuu',
  },
  percentageValue: {
    en: 'percentage value',
    sw: 'asilimia',
  },
  posTerminal: {
    en: 'POS terminal',
    sw: 'kituo cha POS',
  },
  posTerminalMSISDN: {
    en: 'POS terminal MSISDN',
    sw: 'kituo cha POS MSISDN',
  },
  paySlips: {
    en: 'payslips',
    sw: 'stakabadhi ya malipo',
  },
  payslipEntries: {
    en: 'payslip entries',
    sw: 'ingizo la stakabadhi ya malipo',
  },
  'personalFile#': {
    en: 'personal file number',
    sw: 'nambari ya faili la kibinafsi',
  },
  payloadOrigin: {
    en: 'payload origin',
    sw: 'asili ya malipo',
  },
  payloadTransactionCode: {
    en: 'payload transaction code',
    sw: 'nambari ya malipo',
  },
  payloadIdentityNumber: {
    en: 'payload identity number',
    sw: 'nambari ya kitambulisho',
  },
  payloadFirstName: {
    en: 'payload first name',
    sw: 'jina la kwanza',
  },
  payloadMiddleName: {
    en: 'payload middle name',
    sw: 'jina la katikati',
  },
  payloadLastName: {
    en: 'payload last name',
    sw: 'jina la mwisho',
  },
  payloadYearOfbirth: {
    en: 'payload year of birth',
    sw: 'mwaka wa kuzaliwa',
  },
  payloadScore: {
    en: 'payload score',
    sw: 'alama',
  },
  package: {
    en: 'package',
    sw: 'mzigo',
  },
  packageTypeLookup: {
    en: 'package type lookup',
    sw: 'utafutaji wa aina ya mzigo',
  },

  procurement: {
    en: 'procurement',
    sw: 'muamala',
  },
  propertyNotes: {
    en: 'property notes',
    sw: 'maelezo ya mali',
  },
  Procurement: {
    en: 'procurement',
    sw: 'muamala',
  },
  PurchaseRequisitions: {
    en: 'purchase requisitions',
    sw: 'ombi la ununuzi',
  },
  procurementAndAssetManagement: {
    en: 'procurement and assets management',
    sw: 'muamala na usimamizi wa mali',
  },
  purchasePrice: {
    en: 'purchase price',
    sw: 'bei ya ununuzi',
  },
  purchaseRequisitions: {
    en: 'purchase requisitions',
    sw: 'ombi la ununuzi',
  },
  purchaseOrders: {
    en: 'purchase orders',
    sw: 'maagizo ya ununuzi',
  },
  picture: {
    en: 'picture',
    sw: 'picha',
  },
  parentCategory: {
    en: 'parent category',
    sw: 'kategoria kuu',
  },
  purchaseRequisition: {
    en: 'purchase requisition',
    sw: 'ombi la ununuzi',
  },
  purchaseRequisitionItems: {
    en: 'purchase requisition items',
    sw: 'vitengo vya ombi la ununuzi',
  },
  purchaseOrder: {
    en: 'purchase order',
    sw: 'maagizo ya ununuzi',
  },
  purchaseOrderItems: {
    en: 'purchase order items',
    sw: 'vitengo vya maagizo ya ununuzi',
  },
  personnel: {
    en: 'personnel',
    sw: 'wafanyakazi',
  },
  purchaseOrderEntries: {
    en: 'purchase order entries',
    sw: 'ingizo la maagizo ya ununuzi',
  },
  procurementsAndAssetManagement: {
    en: 'procurements and asset management',
    sw: 'muamala na usimamizi wa mali',
  },
  parentItem: {
    en: 'parent item',
    sw: 'kitengo kuu',
  },
  pinNumber: {
    en: 'pin number',
    sw: 'nambari ya pini',
  },
  purchaseOrderLookup: {
    en: 'purchase order lookup',
    sw: 'utafutaji wa maagizo ya ununuzi',
  },
  productsListing: {
    en: 'products listing',
    sw: 'orodha ya bidhaa',
  },
  previousValue: {
    en: 'previous value',
    sw: 'thamani ya awali',
  },
  parentDesignation: {
    en: 'parent designation',
    sw: 'daraja kuu',
  },
  'paddedCode  ': {
    en: 'code',
    sw: 'msimbo',
  },
  paySlipFor: {
    en: 'pay slip for',
    sw: 'stakabadhi ya malipo kwa',
  },
  procurementManagement: {
    en: 'procurement management',
    sw: 'usimamizi wa muamala',
  },
  palletTI: {
    en: 'pallet TI',
    sw: 'pallet TI',
  },
  palletHI: {
    en: 'pallet HI',
    sw: 'pallet HI',
  },
}
export default _p
