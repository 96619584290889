/** @format */

import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { RefundTypeCommissions } from './RefundTypeCommissions'
import React from 'react'

export const ViewRefundTypesModal = ({
  selectedRefundType,
  waitingMetadata,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('name')}</label>
          <input
            className="form-control"
            defaultValue={selectedRefundType?.description}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('transactionOwnership')}
          </label>
          <input
            className="form-control"
            value={selectedRefundType?.transactionOwnershipDescription}
            disabled
          />
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <Tab
              tabItems={[
                {
                  label: localize('applicableCharges'),
                  item: (
                    <RefundTypeCommissions
                      setSelectedRefundType={selectedRefundType}
                      selectedRefundType={selectedRefundType}
                      prefix={'refund-type-view'}
                      disabled
                      waiting={waitingMetadata}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}
