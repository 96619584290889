/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'

function MandatoryCharges({ disabled, onSelect, selected, prefix }) {
  const [debitTypes, setDebitTypes] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [selectedDebitTypes, setSelectedDebitTypes] = useState(selected)

  async function loadDebitTypes() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/debittype/find-debit-types',
      {},
      false
    )

    if (success) {
      setDebitTypes(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadDebitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!disabled) onSelect([...selectedDebitTypes])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDebitTypes])

  return (
    <div className="p-2">
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {debitTypes?.map(dt => (
            <div key={dt?.id} className=" d-flex align-items-center">
              <Checkbox
                disabled={disabled}
                id={`${prefix}_membershipClassMandatoryCharge_${dt?.id}`}
                onCheck={v => {
                  if (v) {
                    setSelectedDebitTypes([...selectedDebitTypes, dt])
                  } else {
                    let sdt = [...selectedDebitTypes]?.filter(
                      d => d.id !== dt?.id
                    )
                    setSelectedDebitTypes(sdt)
                  }
                }}
                checked={[...selectedDebitTypes]?.find(d => d?.id === dt?.id)}
              />
              <label
                htmlFor={`${prefix}_membershipClassMandatoryCharge_${dt.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  selectedDebitTypes?.find(d => d.id === dt.id)
                    ? 'text-primary fw-bold'
                    : ''
                }`}
              >
                {dt?.description}
              </label>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default MandatoryCharges
