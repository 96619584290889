/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import CustomerInfo from './Pages/CustomerInfo'
import Qualification from './Pages/Qualification'
import Appraisal from './Pages/Appraisal'

function Prequalification2() {
  const [activePage, setActivePage] = useState(0)
  const [customer, setCustomer] = useState(null)
  const [loanProduct, setLoanProduct] = useState(null)
  const [termInMonths, setTermInMonths] = useState(0)

  const pages = [
    {
      title: localize('customerInfo'),
      component: (
        <CustomerInfo
          customer={customer}
          onChangeCustomer={c => setCustomer(c)}
        />
      ),
      skippable: true,
    },
    {
      title: localize('qualification'),
      component: (
        <Qualification
          customer={customer}
          onLoanSelected={l => setLoanProduct(l)}
          onChangeTermInMonths={t => setTermInMonths(t)}
        />
      ),
      skippable: true,
    },
    {
      title: localize('appraisal'),
      component: (
        <Appraisal
          customer={customer}
          loanProduct={loanProduct}
          termInMonths={termInMonths}
        />
      ),
      skippable: true,
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[localize('loanOrigination'), localize('prequalification')]}
      />

      <div className="card">
        <div className="card-body bg-white">
          <Walkthrough
            preload
            pages={pages}
            activePage={activePage}
            changePage={p => setActivePage(p)}
          />
        </div>
      </div>
    </>
  )
}

export default Prequalification2
