/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import CreditType from '../../../../../Components/ReUsables/RecurringProcedures/CreditType/CreditType'
import CustomersTab from '../../../../../Components/ReUsables/RecurringProcedures/CustomersTab/CustomersTab'
import EmployersTab from '../../../../../Components/ReUsables/RecurringProcedures/EmployersTab/EmployersTab'
import SavingsProductTab from '../../../../../Components/ReUsables/RecurringProcedures/SavingsProductTab/SavingsProductTab'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  QueuePriority,
  months,
  standingOrderExecutionFilter,
} from '../../../../../Global/enumeration'
import { getCurrentMonth, parseDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

function DynamicSavingsFees() {
  const [dynamicSavingsFees, setDynamicSavingsFees] = useState({
    month: Object.keys(months).find(
      key => months[key].name === getCurrentMonth()
    ),
    queuePriority: Object.keys(QueuePriority)[3],
    StandingOrderExecutionFilter: Object.keys(standingOrderExecutionFilter)[0],
  })
  const [employers, setEmployers] = useState([])
  const [selectAllEmployers, setSelectAllEmployers] = useState(false)
  const [customers, setCustomers] = useState([])
  const [selectAllCustomers, setSelectAllCustomers] = useState(false)
  const [creditTypes, setCreditTypes] = useState([])
  const [selectAllCreditTypes, setSelectAllCreditTypes] = useState(false)
  const [savings, setSavings] = useState([])
  const [resetValues, setResetValues] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    Object.keys(standingOrderExecutionFilter)[0]
  )
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const toggleSelectedTab = tab => {
    setSelectedTab(tab)
  }

  const fetchPostingPeriod = async () => {
    setIsBusy(true)
    const url = '/PostingPeriod/find-current-posting-period'

    const { data, success } = await getData(url, null, false, null)

    if (success) {
      setIsBusy(false)
      setDynamicSavingsFees({
        ...dynamicSavingsFees,
        ...data,
      })
    } else {
      setIsBusy(false)
    }
  }

  const handleEmployersChange = employers => {
    setEmployers(employers)
  }

  const handleSelect = r => {
    if (r.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'This period is locked',
      })

    setDynamicSavingsFees({
      ...dynamicSavingsFees,
      ...r,
    })
  }

  const handleSelectAllEmployers = value => {
    setSelectAllEmployers(value)
  }

  const handleSelectAllCredits = value => {
    setCreditTypes(value)
  }

  const handleSelectIndividualCredits = value => {
    setCreditTypes(value)
  }

  const toggleSelectAllCredits = value => {
    setSelectAllCreditTypes(value)
  }

  const handleSelectCustomer = value => {
    setCustomers(value)
  }

  const toggleSelectAllCustomers = value => {
    setSelectAllCustomers(value)
  }

  const handleSelectSavings = value => {
    setSavings(value)
  }

  const handleUpdateCreditTypes = async () => {
    if (!creditTypes.length)
      return Swal.fire({
        icon: 'error',
        title: 'No credit types selected',
        text: 'Please select a credit type',
      })

    if (!savings.length)
      return Swal.fire({
        icon: 'error',
        title: 'No savings products selected',
        text: 'Please select a savings product',
      })

    setIsBusy(true)
    const url = '/RecurringBatch/charge-savings-dynamic-fees-by-credit-types'

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        creditTypeDTOs: creditTypes,
        savingsProductDTOs: savings,
        selectAllCreditTypes,
      },
      false,
      'Successfully charged savings dynamic fees by credit types.'
    )

    if (success) {
      setIsBusy(false)
      setCreditTypes([])
      setResetValues(true)
      setSavings([])
    } else {
      setIsBusy(false)
    }
  }

  const handleUpdateEmployers = async () => {
    setIsBusy(true)
    const url = '/RecurringBatch/charge-savings-dynamic-fees-by-employers'

    if (!employers.length)
      return Swal.fire({
        icon: 'error',
        title: 'No employers selected',
        text: 'Please select an employer',
      })

    if (!savings.length)
      return Swal.fire({
        icon: 'error',
        title: 'No savings products selected',
        text: 'Please select a savings product',
      })

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        employerDTOs: employers,
        savingsProductDTOs: savings,
        selectAllEmployers,
      },
      false,
      'Successfully charged savings dynamic fees by employers.'
    )

    if (success) {
      setIsBusy(false)
      setEmployers([])
      setResetValues(true)
      setSavings([])
    } else {
      setIsBusy(false)
    }
  }

  const handleUpdateCustomers = async () => {
    setIsBusy(true)
    const url = '/RecurringBatch/charge-savings-dynamic-fees-by-customers'

    const { success } = await putData(
      url,
      {
        recurringBatch: {
          ...dynamicSavingsFees,
        },
        customerDTOs: customers,
        savingsProductDTOs: savings,
        selectAllCustomers,
      },
      false,
      'Successfully charged savings dynamic fees by customers.'
    )

    if (success) {
      setIsBusy(false)
      setCustomers([])
      setResetValues(true)
      setSavings([])
    } else {
      setIsBusy(false)
    }
  }

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, false)
    if (success && data.result) {
      setIsBusy(false)
      setUpdateDate(false)
      setDynamicSavingsFees({
        ...dynamicSavingsFees,
        valueDate: parseDate(valueDate),
      })
    } else {
      setIsBusy(false)
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  useEffect(() => {
    fetchPostingPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('recurringProcedures'),
          localize('dynamicSavingsFees'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3 mb-3">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="postingPeriod"
                      className="text-capitalize col-4"
                    >
                      {localize('postingPeriod')}
                    </label>
                    <PostingPeriodLookup
                      displayValue={dynamicSavingsFees?.description}
                      onSelect={handleSelect}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="referenceMonth"
                      className="text-capitalize col-4"
                    >
                      {localize('referenceMonth')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="Month"
                      value={dynamicSavingsFees.month}
                      onChange={e => {
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          month: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label htmlFor="priority" className="text-capitalize col-4">
                      {localize('priority')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="QueuePriority"
                      value={dynamicSavingsFees.queuePriority}
                      onChange={e => {
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          queuePriority: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="executionFilter"
                      className="text-capitalize col-4"
                    >
                      {localize('executionFilter')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="StandingOrderExecutionFilter"
                      value={dynamicSavingsFees.StandingOrderExecutionFilter}
                      onChange={e => {
                        toggleSelectedTab(e.value)
                        setDynamicSavingsFees({
                          ...dynamicSavingsFees,
                          StandingOrderExecutionFilter: e.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="valueDate"
                      className="text-capitalize col-4"
                    >
                      {localize('valueDate')}
                    </label>
                    <CustomDatePicker
                      name="dynamicSavingsValueDate"
                      defaultDate={valueDate}
                      onDateChange={date => {
                        setValueDate(date)
                      }}
                      setDateToNull={updateDate}
                      setUpdateDate={setUpdateDate}
                    />
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-3">
                <Tab
                  preload
                  tabItems={[
                    {
                      label: localize(
                        dynamicSavingsFees?.StandingOrderExecutionFilter ===
                          Object.keys(standingOrderExecutionFilter)[0]
                          ? 'employers'
                          : dynamicSavingsFees?.StandingOrderExecutionFilter ===
                            Object.keys(standingOrderExecutionFilter)[1]
                          ? 'customers'
                          : 'creditTypes'
                      ),
                      item: (
                        <>
                          {dynamicSavingsFees?.StandingOrderExecutionFilter ===
                          Object.keys(standingOrderExecutionFilter)[0] ? (
                            <EmployersTab
                              handleSelectAllEmployers={
                                handleSelectAllEmployers
                              }
                              handleSelectedEmployersChange={
                                handleEmployersChange
                              }
                              handleUpdateEmployers={handleUpdateEmployers}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                            />
                          ) : dynamicSavingsFees?.StandingOrderExecutionFilter ===
                            Object.keys(standingOrderExecutionFilter)[1] ? (
                            <CustomersTab
                              handleSelectCustomer={handleSelectCustomer}
                              toggleSelectAllCustomers={
                                toggleSelectAllCustomers
                              }
                              handleUpdateCustomers={handleUpdateCustomers}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                            />
                          ) : (
                            <CreditType
                              handleSelectAllCredits={handleSelectAllCredits}
                              handleSelectIndividualCredits={
                                handleSelectIndividualCredits
                              }
                              toggleSelectAllCredits={toggleSelectAllCredits}
                              handleUpdateCreditTypes={handleUpdateCreditTypes}
                              setResetValues={setResetValues}
                              resetValues={resetValues}
                            />
                          )}
                        </>
                      ),
                    },
                    {
                      label: localize('savingsProducts'),
                      item: (
                        <SavingsProductTab
                          handleSelectSavings={handleSelectSavings}
                          handleUpdate={
                            selectedTab ===
                            Object.keys(standingOrderExecutionFilter)[0]
                              ? handleUpdateEmployers
                              : selectedTab ===
                                Object.keys(standingOrderExecutionFilter)[1]
                              ? handleUpdateCustomers
                              : handleUpdateCreditTypes
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default DynamicSavingsFees
