/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import SimpleTable from '../../Tables/SimpleTable'
import EditSignatory from './EditSignatory/EditSignatory'
import Loader from '../../Loaders/Loader'

const MemberCollection = ({
  customerAccountId,
  showContextMenu,
  fetchCustomerAccounts,
}) => {
  const [members, setMembers] = useState([])
  const [member, setMember] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: localize('fullName'),
      template: r => r.fullName,
    },
    {
      label: localize('gender'),
      template: r => r.genderDescription,
    },
    {
      label: localize('identityCardType'),
      template: r => r.identityCardTypeDescription,
    },
    {
      label: localize('identityCardNumber'),
      template: r => r.identityCardNumber,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r.addressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r.addressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r.addressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r.addressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r.addressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r.addressEmail,
    },
    {
      label: localize('landline'),
      template: r => r.addressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r.addressMobileLine,
    },
    {
      label: localize('addressInternet'),
      template: r => r.addressInternet,
    },
    {
      label: localize('relationship'),
      template: r => r.relationshipDescription,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const handleClose = () => {
    setMode(null)
    setMember({})
  }

  const fetchMembers = async () => {
    setIsBusy(true)

    const url =
      '/CustomerAccount/find-customer-account-signatories-by-customer-account-id'

    const { success, data } = await getData(
      url,
      {
        customerAccountId,
      },
      false
    )

    if (success) {
      setMembers(data?.result || [])
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectMember = (r, mode) => {
    setMember(r)
    setMode(mode)
  }

  useEffect(() => {
    if (customerAccountId) fetchMembers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccountId])

  return (
    <>
      {mode === 'edit' && (
        <EditSignatory
          mode={mode}
          handleClose={handleClose}
          setSignatory={setMember}
          signatory={member}
          fetchCustomerAccounts={fetchCustomerAccounts}
        />
      )}
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable
          data={members}
          columns={columns}
          contextMenu={r =>
            showContextMenu && (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectMember(r, 'edit')
                  }}
                >
                  <i className="uil uil-edit"></i>
                  <span>{localize('edit')}</span>
                </div>
              </>
            )
          }
        />
      )}
    </>
  )
}

export default MemberCollection
