/** @format */

import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import Tab from '../../../../../../Components/Tabs/Tab'
import { PayablesReceivablesEntries } from '../../Partials/PayablesReceivablesEntries'
import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

export const VerifyPayablesReceivableModal = ({
  selectedPayableReceivable,
  setSelectedPayableReceivable,
  loading,
}) => {
  const [globalValidations, setGlobalValidations] = GlobalValidationErrors.use()
  function clearValidation(column) {
    setGlobalValidations(g => {
      delete g[column]
      return g
    })
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('voucher')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.paddedVoucherNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('postingPeriod')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.postingPeriodDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chartOfAccountName')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.chartOfAccountName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('costDistributionRule')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPayableReceivable?.chartOfAccountCostDistributionRuleDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('costCenter')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.costCenterDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPayableReceivable?.customerAccountFullAccountNumber
                }
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedPayableReceivable?.customerAccountCustomerFullName
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('status')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.statusDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('voucherType')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.typeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('valueDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedPayableReceivable?.valueDate)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('totalValue')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  selectedPayableReceivable?.totalValue,
                  2,
                  2,
                  false,
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('primaryDescription')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.primaryDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('secondaryDescription')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.secondaryDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('reference')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.reference}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedPayableReceivable?.createdDate)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.auditedBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedPayableReceivable?.auditedDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verificationRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.auditRemarks}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.authorizedBy}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(selectedPayableReceivable?.authorizedDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizationRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPayableReceivable?.authorizationRemarks}
              />
            </div>
          </div>
          <Tab
            tabItems={[
              {
                label: localize('expensePayableEntries'),
                item: (
                  <PayablesReceivablesEntries
                    payableReceivableId={selectedPayableReceivable?.id}
                  />
                ),
              },
            ]}
          />
          <div className="d-flex align-content-end justify-content-end mt-2 bg-light">
            <div
              validation-message={globalValidations?.remarks?.message}
              className="d-flex align-items-center me-5 with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                value={selectedPayableReceivable?.auditRemarks}
                onChange={e => {
                  clearValidation('remarks')
                  setSelectedPayableReceivable({
                    ...selectedPayableReceivable,
                    auditRemarks: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <EnumsServerCombo
                customContainerStyle={{
                  maxWidth: 200,
                  minWidth: 200,
                }}
                enumsKey="ExpensePayableAuthOption"
                value={selectedPayableReceivable?.option}
                onChange={e => {
                  setSelectedPayableReceivable({
                    ...selectedPayableReceivable,
                    option: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
