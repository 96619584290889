/** @format */

import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Substitutes from '../../../../../../Components/ReUsables/Substitutes/Substitutes'

const ViewSubstitutes = ({
  guarantorSubstitutionRequestId,
  handleClose,
  mode,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      modalTitle="substitutes"
      hideUpdate
      modalClassName="modal-xl"
    >
      <Substitutes
        guarantorSubstitutionRequestId={guarantorSubstitutionRequestId}
      />
    </ModalTemplate>
  )
}

export default ViewSubstitutes
