/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import Checkbox from '../../Checkbox/Checkbox'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import Loader from '../../Loaders/Loader'

const ListLoanProducts = ({
  selectedLoanProducts,
  handleSelectLoanProduct,
  handleSelectAllLoanProducts,
  handleUpdateLoanProducts,
  resetValues,
  setResetValues,
  actionText,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [loans, setLoans] = useState([])
  const [selectAllLoans, setSelectAllLoans] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedLoanProducts.find(c => c.id === r.id)}
          onCheck={v => {
            handleSelectLoanProduct(r, v)
          }}
        />
      ),
    },
    {
      label: localize('code'),
      template: r => r.paddedCode,
    },
    {
      label: localize('name'),
      template: r => r.description,
    },
    {
      label: localize('terms') + localize('(months)'),
      template: r => r.loanRegistrationTermInMonths,
    },
    {
      label: localize('annualInterestRate'),
      template: r => formatAsPercent(r.loanInterestAnnualPercentageRate),
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => r.loanRegistrationPaymentFrequencyPerYearDescription,
    },
    {
      label: localize('minimumPrincipalAmount'),
      template: r => formatCurrency(r.loanRegistrationMinimumAmount),
    },
    {
      label: localize('maximumPrincipalAmount'),
      template: r => formatCurrency(r.loanRegistrationMaximumAmount),
    },
    {
      label: localize('minimumChargeableInterestAmount'),
      template: r => r.loanRegistrationMinimumInterestAmount,
    },
    {
      label: localize('productSection'),
      template: r => r.loanRegistrationLoanProductSectionDescription,
    },
    {
      label: localize('consecutiveIncome'),
      template: r => r.loanRegistrationConsecutiveIncome,
    },
    {
      label: localize('investmentsMultiplier'),
      template: r => r.loanRegistrationInvestmentsMultiplier,
    },
    {
      label: localize('minimumGuarantors'),
      template: r => r.loanRegistrationMinimumGuarantors,
    },
    {
      label: localize('maximumGuarantees'),
      template: r => r.loanRegistrationMaximumGuarantees,
    },
    {
      label: localize('interestChargeMode'),
      template: r => r.loanInterestChargeModeDescription,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => r.loanInterestRecoveryModeDescription,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => r.loanInterestCalculationModeDescription,
    },
    {
      label: localize('payoutRecoveryMode'),
      template: r => r.loanRegistrationPayoutRecoveryModeDescription,
    },
    {
      label: localize('payoutRecoveryPercentage'),
      template: r =>
        formatAsPercent(r.loanRegistrationPayoutRecoveryPercentage),
    },
    {
      label: localize('minimumMembershipPeriod') + localize('(months)'),
      template: r => r.loanRegistrationMinimumMembershipPeriod,
    },
    {
      label: localize('standingOrderTrigger'),
      template: r => r.loanRegistrationStandingOrderTriggerDescription,
    },
    {
      label: localize('gracePeriod') + localize('(days)'),
      template: r => r.loanRegistrationGracePeriod,
    },
    {
      label: localize('takeHomeType'),
      template: r => r.takeHomeTypeDescription,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => formatAsPercent(r.takeHomePercentage),
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => formatAsPercent(r.takeHomeFixedAmount),
    },
    {
      label: localize('recoveryPriority'),
      template: r => r.priority,
    },
    {
      label: localize('principal') + localize('gLAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('principal') + localize('gLAccountCostDistrRule'),
      template: r => r.chartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('interestReceivedGLAccountName'),
      template: r => r.interestReceivedChartOfAccountName,
    },
    {
      label: localize('interestReceivedGLAccountCostDistrRule'),
      template: r =>
        r.interestReceivedChartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('interestReceivableGLAccountName'),
      template: r => r.interestReceivableChartOfAccountName,
    },
    {
      label: localize('interestReceivableGLAccountCostDistrRule'),
      template: r =>
        r.interestReceivableChartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('rejectIfOwing') + ' ?',
      template: r => r.loanRegistrationRejectIfMemberHasBalance?.toString(),
    },
    {
      label: localize('enforceSecurityRules') + ' ?',
      template: r => r.loanRegistrationSecurityRequired?.toString(),
    },
    {
      label: localize('allowSelfGuarantee') + ' ?',
      template: r => r.loanRegistrationAllowSelfGuarantee?.toString(),
    },
    {
      label: localize('chargeClearanceFee') + ' ?',
      template: r => r.loanRegistrationChargeClearanceFee?.toString(),
    },
    {
      label: localize('microCredit') + ' ?',
      template: r => r.loanRegistrationMicrocredit?.toString(),
    },
    {
      label: localize('chargeArrearsFee') + ' ?',
      template: r => r.loanRegistrationChargeArrearsFee?.toString(),
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r.isLocked?.toString(),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const fetchLoanProducts = async () => {
    setIsBusy(true)
    const url = '/LoanProduct/find-loan-product-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setLoans(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchLoanProducts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (resetValues) {
      setSelectAllLoans(false)
      setResetValues(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 py-3 px-2">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="recordsPerPage"
                  className="text-capitalize"
                  style={{ marginLeft: -10, textTransform: 'capitalize' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  id="recordsPerPage"
                  className="form-select ms-3"
                  style={{ maxWidth: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 offset-md-5">
              <div className="d-flex align-items-center justify-content-end">
                <label className="text-capitalize me-2">
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    if (e.target.value === '') {
                      fetchLoanProducts()
                    }

                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchLoanProducts()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchLoanProducts()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: '50dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable columns={columns} data={loans?.pageCollection} />
          </div>
          <div className="mb-2"></div>
          <Pager
            itemsCount={loans.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="selectAllLoans"
                    checked={selectAllLoans}
                    onCheck={v => {
                      setSelectAllLoans(v)
                      handleSelectAllLoanProducts(loans.pageCollection, v)
                    }}
                  />

                  <label
                    htmlFor="selectAllLoans"
                    className="ms-2 text-capitalize"
                  >
                    {localize('toggleSelection')}
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center float-end">
                <button
                  onClick={handleUpdateLoanProducts}
                  className="btn btn-success float-end"
                >
                  {localize(actionText || 'update')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ListLoanProducts
