/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { DepreciationMethod } from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import CreateAssetType from './Partials/CreateAssetType'

function AssetTypes() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },

    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountAccountName}</>,
      sortBy: 'chartOfAccountAccountName',
    },
    {
      label: localize('accumulatedDepreciationChartOfAccountName'),
      template: r => <>{r?.accumulatedDepreciationAccountAccountName}</>,
      sortBy: 'accumulatedDepreciationAccountAccountName',
    },
    {
      label: localize('depreciationExpenseChartOfAccountName'),
      template: r => <>{r?.depreciationExpenseAccountAccountName}</>,
      sortBy: 'depreciationExpenseAccountAccountName',
    },
    {
      label: localize('gainLossChartOfAccountName'),
      template: r => <>{r?.gainLossAccountAccountName}</>,
      sortBy: 'gainLossAccountAccountName',
    },
    {
      label: localize('depreciation'),
      template: r => <>{r?.depreciationMethodDescription}</>,
      sortBy: 'depreciationMethodDescription',
    },
    {
      label: localize('usefullLife'),
      template: r => <>{r?.usefulLife}</>,
      sortBy: 'usefulLife',
    },
    {
      label: localize('decliningFactor'),
      template: r => <>{r?.factor}</>,
      sortBy: 'factor',
    },
    {
      label: localize('isTangible') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isTangible.toString())}
        </span>
      ),
      sortBy: 'isTangible',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedAssetType, setSelectedAssetType] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)

  return (
    <>
      <ListingPage
        onAdd={() => {
          setMode('add')
          setSelectedAssetType({
            depreciationMethod: Object.keys(DepreciationMethod)[0],
          })
        }}
        map={[
          localize('procurementAndAssetsManagement'),
          localize('assetTypes'),
        ]}
        useText
        columns={columns}
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        url="/AssetType/find-asset-types-by-filter-in-page"
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={() => {
                setMode('edit')
                setSelectedAssetType(r)
              }}
            >
              <i className="uil uil-edit-alt"></i>
              {localize('edit')}
            </div>
          </>
        )}
        refreshAt={refreshAt}
      />
      {(mode === 'add' || mode === 'edit') && (
        <CreateAssetType
          modalTitle={localize('assetType')}
          setRefreshAt={setRefreshAt}
          refreshAt={refreshAt}
          setMode={setMode}
          mode={mode}
          selectedAssetType={selectedAssetType}
          setSelectedAssetType={setSelectedAssetType}
        />
      )}
    </>
  )
}
export default AssetTypes
