/** @format */

import React, { useEffect, useState } from 'react'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import GuarantorsPartial from '../Registration/GuarantorsPartial'
import { getData } from '../../../../../Helpers/webApi'
import ExistingGuarantors from './ExistingGuarantors'

function SecurityQualification({
  loanCaseId,
  loanProductId,
  loaneeCustomerId,
  guarantorSecurityMode,
  guarantorSecurityModeDescription,
  reAssessLoan,
}) {
  const [guarantors, setGuarantors] = useState([])
  const [existingGuarantors, setExistingGuarantors] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reloadExistingGuarantors, setReloadExistingGuarantors] =
    useState(false)

  const [reloadGuarantors, setReloadGuarantors] = useState(false)

  useEffect(() => {
    loanCaseId && loadLoanGuarantors()
    // eslint-disable-next-line
  }, [loanCaseId, reloadGuarantors])

  useEffect(() => {
    loadExistingGuarantors()
    // eslint-disable-next-line
  }, [loanCaseId, reloadExistingGuarantors])

  useEffect(() => {
    reAssessLoan()
    // eslint-disable-next-line
  }, [reloadExistingGuarantors, reloadGuarantors])

  async function loadLoanGuarantors() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-guarantors-by-loan-case-id',
      { loanCaseId, evaluateCommitments: true },
      false
    )
    if (success) {
      setGuarantors(data)
      console.table(data?.loanGuarantors)
    }
    setIsBusy(false)
  }

  async function loadExistingGuarantors() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-guarantors-by-loanee-customer-id',
      { loaneeCustomerId, includeBalances: true },
      false
    )
    if (success) {
      setExistingGuarantors(data?.result)
    }
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('loanGuarantors'),
      icon: 'uil uil-edit',
      item: (
        <GuarantorsPartial
          loanCaseId={loanCaseId}
          loaneeCustomerId={loaneeCustomerId}
          guarantorSecurityMode={guarantorSecurityMode}
          guarantorSecurityModeDescription={guarantorSecurityModeDescription}
          loanProductId={loanProductId}
          guarantors={guarantors?.loanGuarantors}
          totalGuaranteed={guarantors?.totalAmountGuaranteed}
          onChangeGuarantors={() => setReloadGuarantors(true)}
          reAssessLoan={reAssessLoan}
          reloadGuarantors={() => setReloadGuarantors(!reloadGuarantors)}
        />
      ),
    },
    {
      label: localize('existingGuarantors'),
      icon: 'uil uil-edit',
      item: (
        <ExistingGuarantors
          guarantors={existingGuarantors}
          reloadGuarantors={() =>
            setReloadExistingGuarantors(!reloadExistingGuarantors)
          }
        />
      ),
    },
  ]

  return (
    <div>
      <Tab preload tabItems={tabItems} />
    </div>
  )
}

export default SecurityQualification
