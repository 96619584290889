/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import MessageGroups from '../../Partials/MessageGroups/MessageGroups'
import Tab from '../../../../../../Components/Tabs/Tab'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

const CreateGroupTextMessage = ({
  mode,
  handleClose,
  handleAction,
  isBusy,
  selectedUsers,
  handleSelectUser,
  textAlert,
  setTextAlert,
}) => {
  return (
    <ModalTemplate
      modalTitle="createGroupTextMessage"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={handleAction}
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              checked={textAlert?.appendSignature}
              onCheck={value => {
                setTextAlert({
                  ...textAlert,
                  appendSignature: value,
                })
              }}
              id={`${textAlert?.id}-appendSignature`}
            />
            <label
              className="text-capitalize me-1"
              htmlFor={`${textAlert?.id}-appendSignature`}
            >
              {localize('appendSignature') + ' ?'}
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-1">
                  {localize('message')}
                </label>
                <textarea
                  className="form-control"
                  placeholder={localize('message')}
                  value={textAlert?.message}
                  onChange={e =>
                    setTextAlert({
                      ...textAlert,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('recipients'),
                    item: (
                      <MessageGroups
                        handelSelectGroup={handleSelectUser}
                        selectedGroups={selectedUsers}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateGroupTextMessage
