/** @format */
import localize from '../../../../Global/Localize'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import TimePicker from '../../../../Components/Date/TimePicker'
import { SystemPermissionType } from '../../../../Global/enumeration'
import { SuppressedPermissions } from './SuppressedPermissions'
import { useEffect, useState } from 'react'
import { object, string } from 'yup'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import { generateGuid } from '../../../../Helpers/extensions'

export const EditTemporaryRole = ({
                                    selectedTemporaryRole,
                                    setSelectedTemporaryRole,
                                    setSuppressedPermissionsTemporaryRoles,
                                    suppressedPermissionsTemporaryRoles,
                                    onAddSuppressedPermissionDetails,
                                    globalValidation,
                                    isBusy,
                                    clearGlobalValidation
                                  }) => {
  const [validationBag, setValidationBag] = useState({})

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const [selectedSystemPermissionType, setSelectedSystemPermissionType] =
    useState(Object.keys(SystemPermissionType)[0])

  const [suppressedPermissionsModel, setSuppressedPermissionsModel] = useState({
    systemPermissionType: selectedSystemPermissionType,
    reason: '',
    systemPermissionTypeDescription:
    SystemPermissionType[selectedSystemPermissionType].name
  })
  const runValidation = async model => {
    let systemPermissionTypeSchema = object({
      reason: string().required('Give a reason for the permission')
    })

    try {
      await systemPermissionTypeSchema.validate(model, { abortEarly: false })
      return true
    } catch (e) {
      const validations = e.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setValidationBag(validations)
    }
  }

  const handleAddSystemPermissionType = async () => {
    const modelValid = await runValidation(suppressedPermissionsModel)
    if (modelValid) {
      if (
        suppressedPermissionsTemporaryRoles.find(
          x =>
            x.systemPermissionType ==
            suppressedPermissionsModel.systemPermissionType
        )
      ) {
        Swal.fire({
          icon: 'error',
          title: 'System Permission Type',
          text: `${suppressedPermissionsModel.systemPermissionTypeDescription} permission already added `
        })
        return
      }
      setSuppressedPermissionsTemporaryRoles([
        ...suppressedPermissionsTemporaryRoles,
        { ...suppressedPermissionsModel, id: generateGuid() }
      ])
      setSuppressedPermissionsModel({
        systemPermissionType: selectedSystemPermissionType,
        reason: '',
        systemPermissionTypeDescription:
        SystemPermissionType[selectedSystemPermissionType].name
      })
    }
  }

  const handleOnRemoveSystemPermissionTypeEntry = r => {
    setSuppressedPermissionsTemporaryRoles(
      suppressedPermissionsTemporaryRoles.filter(
        x => x.systemPermissionType != r.systemPermissionType
      )
    )
  }

  useEffect(() => {
    onAddSuppressedPermissionDetails(suppressedPermissionsTemporaryRoles)
  }, [suppressedPermissionsTemporaryRoles])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className='row mb-3'>
            <div className='col d-flex align-items-center'>
              <label className='col-3'>{localize('employee')}</label>
              <input
                className='form-control'
                disabled
                defaultValue={selectedTemporaryRole?.employeeCustomerFullName}
              />
            </div>
            <div className='col d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('branch')}
              </label>
              <input
                className='form-control'
                disabled
                defaultValue={selectedTemporaryRole?.employeeBranchDescription}
              />
            </div>
            <div className='col d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('designation')}
              </label>
              <input
                className='form-control'
                disabled
                defaultValue={
                  selectedTemporaryRole?.employeeDesignationDescription
                }
              />
            </div>
            <div className='col d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('department')}
              </label>
              <input
                className='form-control'
                disabled
                defaultValue={
                  selectedTemporaryRole?.employeeDepartmentDescription
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div
              validation-message={
                globalValidation?.['EmployeeTemporaryRole.DurationStartDate']
                  ?.message
              }
              className='col d-flex align-items-center with-validation'
            >
              <label className='col-3 text-capitalize'>
                {localize('startDate')}
              </label>
              <CustomDatePicker
                onDateChange={e => {
                  clearGlobalValidation(
                    'EmployeeTemporaryRole.DurationStartDate'
                  )
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    durationStartDate: e
                  })
                }}
                defaultDate={selectedTemporaryRole?.durationStartDate}
                name={'startDate'}
              />
            </div>
            <div
              validation-message={
                globalValidation?.[
                  'EmployeeTemporaryRole.TimeDurationStartTime'
                  ]?.message
              }
              className='col d-flex align-items-center with-validation'
            >
              <label className='col-3 text-capitalize'>
                {localize('startTime')}
              </label>
              <TimePicker
                name={'startTime'}
                defaultTime={selectedTemporaryRole?.timeDurationStartTime}
                onTimeChange={c => {
                  clearGlobalValidation(
                    'EmployeeTemporaryRole.TimeDurationStartTime'
                  )
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    timeDurationStartTime: c
                  })
                }}
              />
            </div>
            <div className='col d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('endTime')}
              </label>
              <TimePicker
                name={'endTime'}
                defaultTime={selectedTemporaryRole?.timeDurationEndTime}
                onTimeChange={c => {
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    timeDurationEndTime: c
                  })
                }}
              />
            </div>
            <div className='col d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('endDate')}
              </label>
              <CustomDatePicker
                onDateChange={e => {
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    durationEndDate: e
                  })
                }}
                defaultDate={selectedTemporaryRole?.durationEndDate}
                name={'endDate'}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3 d-flex align-items-center'>
              <label className='col-3 text-capitalize'>
                {localize('roleName')}
              </label>
              <input
                className='form-control'
                disabled
                defaultValue={selectedTemporaryRole?.roleName}
              />
            </div>
            <div
              validation-message={
                globalValidation?.['EmployeeTemporaryRole.Reason']?.message
              }
              className='col d-flex align-items-center with-validation'
            >
              <label className='col-1 text-capitalize'>
                {localize('reason')}
              </label>
              <input
                className='form-control'
                defaultValue={selectedTemporaryRole?.reason}
                onChange={e => {
                  globalValidation['EmployeeTemporaryRole.Reason'] &&
                  clearGlobalValidation('EmployeeTemporaryRole.Reason')
                  setSelectedTemporaryRole({
                    ...selectedTemporaryRole,
                    reason: e.target.value
                  })
                }}
              />
            </div>
          </div>
          <div
            className='p-2 mt-4'
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative'
            }}
          >
            <div
              className='bg-white px-2 row text-capitalize'
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120
              }}
            >
              {localize('suppressedPermissionDetails')}
            </div>
            <div className='row' style={{ marginTop: -10 }}>
              <div className='col d-flex align-items-center'>
                <label className='col-3 text-capitalize'>
                  {localize('systemPermissionType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey='SystemPermissionType'
                  onChange={e => {
                    setSelectedSystemPermissionType(e.value)
                    setSuppressedPermissionsModel({
                      ...suppressedPermissionsModel,
                      systemPermissionType: e.value,
                      systemPermissionTypeDescription:
                      SystemPermissionType[e.value].name
                    })
                  }}
                  value={selectedSystemPermissionType}
                />
              </div>
              <div
                validation-message={validationBag?.reason}
                className='col d-flex align-items-center with-validation'
              >
                <label className='col-1 text-capitalize'>
                  {localize('reason')}
                </label>
                <input
                  className='form-control'
                  value={suppressedPermissionsModel?.reason}
                  onChange={e => {
                    clearValidation('reason')
                    setSuppressedPermissionsModel({
                      ...suppressedPermissionsModel,
                      reason: e.target.value
                    })
                  }}
                />
              </div>
            </div>
            <div
              className='bg-light d-flex justify-content-end align-items-center p-2 mt-3'
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={handleAddSystemPermissionType}
                className='btn btn-success text-uppercase ms-3 px-4'
              >
                {localize('add')}
              </button>
            </div>
            <div
              className='p-2 mt-4'
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative'
              }}
            >
              <div
                className='bg-white px-2 row text-capitalize'
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                  marginBottom: -10
                }}
              >
                {localize('suppressedPermissions')}
              </div>
              <SuppressedPermissions
                handleOnRemove={handleOnRemoveSystemPermissionTypeEntry}
                data={suppressedPermissionsTemporaryRoles}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
