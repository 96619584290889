/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

function TrainingFacilitatorLookup({ disabled, onSelect, displayValue }) {
  const params = {
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  }

  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.addressCity}</> },
    { label: localize('emailAddress'), template: r => <>{r?.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r?.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return (
    <SimpleLookup
      title={localize('trainingFacilitatorLookup')}
      disabled={disabled}
      columns={columns}
      params={params}
      onSelect={onSelect}
      displayValue={displayValue}
      url="/TrainingFacilitator/find-by-filter-in-page"
    />
  )
}

export default TrainingFacilitatorLookup
