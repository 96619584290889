/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'

export const CustomerMetadataIndividualParticulars = ({
  selectedCustomer,
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('individualType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('nationality')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualNationalityDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualGenderDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('salutation')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualSalutationDescription}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualFirstName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualLastName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('birthDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedCustomer?.individualBirthDate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('educationLevel')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualEducationLevelDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualIdentityCardTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualIdentityCardNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identityCardSerialNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualIdentityCardSerialNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('pin')}</label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.personalIdentificationNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentSector')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.employmentSectorDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentTerms')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualEmploymentTermsOfServiceDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentDesignation')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualEmploymentDesignation}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employmentDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualEmploymentDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-3 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('maritalStatus')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualMaritalStatusDescription}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('disabilityType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCustomer?.individualDisabilityTypeDescription}
          />
        </div>
      </div>
    </>
  )
}
