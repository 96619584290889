/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import { postData } from '../../../Helpers/webApi'
import localize from '../../../Global/Localize'
import PostingPeriodLookup from '../../../Components/Lookup/PostingPeriodLookup'
import moment from 'moment'
import EducationVenueLookup from '../../../Components/Lookup/EducationVenueLookup'
import { parseDate } from '../../../Helpers/formatters'

function CreateEducationRegisterModal({
  mode,
  selectedEducationRegister,
  close,
  refreshData,
  currentPostingPeriodDescription,
  currentPostingPeriodId,
}) {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [educationRegister, setEducationRegister] = useState({
    durationStartDate: parseDate(selectedEducationRegister?.durationStartDate),

    durationEndDate: parseDate(selectedEducationRegister?.durationEndDate),
  })
  const [postingPeriodSelected, setPostingPeriodSelected] = useState(false)
  async function handleSubmit() {
    const postingPeriod = !postingPeriodSelected
      ? { postingPeriodId: currentPostingPeriodId }
      : { postingPeriodId: educationRegister?.postingPeriodId }

    const { success } = await postData(
      '/EducationRegister/create-education-register',
      {
        ...educationRegister,
        ...postingPeriod,
      },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      close()
      refreshData()
    }
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="membersEducationRegister"
      handleAction={handleSubmit}
      handleClose={closeModal}
      modalClassName="modal-xl"
      footerChildren={
        <>
          <Checkbox
            id="educationRegisterEnforceAuthorizationToken"
            onCheck={e => {
              setEducationRegister({
                ...educationRegister,
                enforceAuthorizationToken: e,
              })
            }}
            checked={educationRegister?.enforceAuthorizationToken}
          />
          <label
            htmlFor="educationRegisterEnforceAuthorizationToken"
            className="text-capitalize"
          >
            {localize('enforceAuthorizationToken')}?
          </label>
          <div className="mx-3"></div>
        </>
      }
    >
      <div className="row mb-3 g-3">
        <div className="col-md-12">
          <div
            className="with-validation d-flex align-items-center mb-2"
            validation-message={globalValidationErrors['Description']?.message}
          >
            <label className="text-capitalize col-1">{localize('name')}</label>
            <input
              defaultValue={educationRegister?.description}
              type="text"
              className="form-control"
              onChange={e => {
                clearValidation('Description')
                setEducationRegister({
                  ...educationRegister,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3 g-3">
        <div className="col-md-4">
          <div
            className="with-validation d-flex align-items-center mb-2"
            validation-message={
              globalValidationErrors['EducationVenueId']?.message
            }
          >
            <label className="col-3 text-capitalize">{localize('venue')}</label>
            <EducationVenueLookup
              displayValue={educationRegister?.educationVenueDescription}
              onSelect={r => {
                clearValidation('EducationVenueId')
                setEducationRegister({
                  ...educationRegister,
                  educationVenueId: r.id,
                  educationVenueDescription: r.description,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-1">
              {localize('remarks')}
            </label>
            <input
              defaultValue={educationRegister?.remarks}
              type="text"
              className="form-control"
              onChange={e => {
                setEducationRegister({
                  ...educationRegister,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3 g-3">
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('postingPeriod')}
            </label>
            <PostingPeriodLookup
              displayValue={currentPostingPeriodDescription}
              onSelect={r => {
                setPostingPeriodSelected(true)
                setEducationRegister({
                  ...educationRegister,
                  postingPeriodId: r.id,
                  postingPeriodDescription: r.description,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-2">
              {localize('startDate')}
            </label>
            <input
              type="date"
              className="form-control"
              defaultValue={moment(educationRegister?.durationStartDate).format(
                'YYYY-MM-DD'
              )}
              onChange={a =>
                setEducationRegister({
                  ...educationRegister,
                  durationStartDate: a.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-2">
              {localize('endDate')}
            </label>
            <input
              type="date"
              className="form-control"
              defaultValue={moment(educationRegister?.durationEndDate).format(
                'YYYY-MM-DD'
              )}
              onChange={a =>
                setEducationRegister({
                  ...educationRegister,
                  durationEndDate: a.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}
export default CreateEducationRegisterModal
