/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { debitCardStatus } from '../../../../Helpers/constants'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import CreateDebitCardRegister from './CreateDebitCardRegister/CreateDebitCardRegister'
import VerifyDebitCardRegister from './VerifyDebitCardRegister/VerifyDebitCardRegister'

const DebitCardsRegister = () => {
  const [cards, setCards] = useState([])
  const [card, setCard] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'Card Type',
    value: 0,
    column: 'cardTypeDescription',
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
    debitCardRegisterFilter: 16,
  })

  const columns = [
    {
      label: localize('status'),
      template: item => <>{item?.statusDescription}</>,
    },
    {
      label: localize('cardType'),
      template: item => <>{item?.cardTypeDescription}</>,
    },
    {
      label: localize('issuingNetwork'),
      template: item => <>{item?.issuingNetworkDescription}</>,
    },
    {
      label: localize('primaryAccountNumber'),
      template: item => <>{item?.primaryAccountNumber}</>,
    },
    {
      label: localize('validFrom'),
      template: item => <>{formatDate(item?.validFrom)}</>,
    },
    {
      label: localize('expires'),
      template: item => <>{formatDate(item?.expires)}</>,
    },
    {
      label: localize('dailyLimit'),
      template: item => <>{formatCurrency(item?.dailyLimit)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: item => (
        <>{item?.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerType'),
      template: item => (
        <>
          {item?.customerAccountCustomerMembershipClassCustomerTypeDescription}
        </>
      ),
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: item => <>{item?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: item => <>{item?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('remarks'),
      template: item => <>{item?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: item => <>{item?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: item => <>{formatDate(item?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'cardTypeDescription' },
    { value: 1, column: 'issuingNetworkDescription' },
    { value: 2, column: 'statusDescription' },
    { value: 3, column: 'createdBy' },
    { value: 4, column: 'modifiedBy' },
  ]

  const handleClose = () => {
    setMode(null)
    setCard({})
  }

  const handleSelectCard = (r, modalMode) => {
    if (
      modalMode === 'verify' &&
      r?.status !== debitCardStatus.NEW?.toString() &&
      r?.status !== debitCardStatus.EDITED?.toString() &&
      r?.status !== debitCardStatus.APPROVED?.toString()
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only verify new, edited or approved debit cards'
      )

    setCard(r)
    setMode(modalMode)
  }

  const handleAddCard = () => {
    setCard({})
    setMode('add')
  }

  const fetchCards = async () => {
    setIsBusy(true)

    const url = '/AlternateChannel/find-debit-card-register-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCards(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchCards()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])
  return (
    <>
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={card}
          title={`${card?.cardTypeDescription} : ${card?.primaryAccountNumber} -> ${card?.customerFullName}`}
        />
      )}

      {(mode === 'add' || mode === 'edit') && (
        <CreateDebitCardRegister
          mode={mode}
          handleClose={handleClose}
          fetchCards={fetchCards}
          cardData={card}
        />
      )}

      {mode === 'verify' && (
        <VerifyDebitCardRegister
          mode={mode}
          fetchCards={fetchCards}
          card={card}
          handleClose={handleClose}
        />
      )}

      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('debitCardsRegister'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-4">
              <AddNewButton handleClickAddButton={handleAddCard} />
            </div>
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex row align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center col-md-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div
                  className="d-flex align-items-center gap-2 col-md-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={groupBy?.value}
                    enumsKey="DebitCardRegisterPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>

                <div
                  className="d-flex align-items-center gap-2 col-md-5"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={reqParams?.debitCardRegisterFilter}
                    enumsKey="DebitCardRegisterFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        debitCardRegisterFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCards()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCards()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={cards?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectCard(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      {localize('edit')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectCard(r, 'verify')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      {localize('verify')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectCard(r, 'auditLogs')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      {localize('viewAuditLogs')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={cards?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default DebitCardsRegister
