/** @format */
import localize from '../../../../../../../Global/Localize'
import DepartmentLookup from '../../../../../../../Components/Lookup/DepartmentLookup'
import {
  formatCurrency,
  getTodaysDate,
} from '../../../../../../../Helpers/formatters'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import React, { useState } from 'react'
import { ItemRegisterLookup } from '../../../../../../../Components/Lookup/ItemRegisterLookup'
import { RequisitionEntries } from './RequisitionEntries'
import { postData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const CreateRequisitionModal = ({
  requisitionModel,
  setRequisitionModel,
  isBusy,
  requisitionEntries,
  setRequisitionEntries,
}) => {
  const [requisitionItemModel, setRequisitionItemModel] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOnAddRequisition = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/Requisition/add-requisition-entries',
      {
        ItemRegister: requisitionItemModel?.itemRegister,
        Quantity: requisitionItemModel?.quantity,
        RequisitionItems: requisitionEntries,
      },
      false,
    )
    if (success) {
      setRequisitionEntries([...requisitionEntries, data])
      setRequisitionItemModel(null)
    }
    setLoading(false)
  }

  const handleOnRemoveRequisitionEntries = e => {
    setRequisitionEntries(requisitionEntries.filter(x => x.id !== e.id))
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('department')}
              </label>
              <DepartmentLookup
                displayValue={requisitionModel?.department?.description}
                onSelect={r => {
                  setRequisitionModel({
                    ...r,
                    department: { ...r },
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <CustomDatePicker
                name="expectedDate"
                defaultDate={requisitionModel?.expectedDate}
                onDateChange={date => {
                  setRequisitionModel({
                    ...requisitionModel,
                    expectedDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('reason')}
              </label>
              <input
                className="form-control"
                value={requisitionModel?.reason || ''}
                onChange={e => {
                  setRequisitionModel({
                    ...requisitionModel,
                    reason: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
              }}
            >
              {localize('itemDetails')}
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('itemRegister')}
                </label>
                <ItemRegisterLookup
                  displayValue={
                    requisitionItemModel?.itemRegister?.description || ''
                  }
                  onSelect={r => {
                    setRequisitionItemModel({
                      ...requisitionItemModel,
                      itemRegister: { ...r },
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('inventoryCategory')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={
                    requisitionItemModel?.itemRegister
                      ?.inventoryCategoryDescription || ''
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('unitOfMeasure')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={
                    requisitionItemModel?.itemRegister
                      ?.inventoryUnitOfMeasureDescription || ''
                  }
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: -10 }}>
              <div className="col-4 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('estimatedUnitCost')}
                </label>
                <input
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    requisitionItemModel?.itemRegister?.estimatedUnitCost || '',
                    2,
                    2,
                    false,
                  )}
                />
              </div>
              <div className="col-4 d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('quantity')}
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={requisitionItemModel?.quantity || ''}
                  onChange={e =>
                    setRequisitionItemModel({
                      ...requisitionItemModel,
                      quantity: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div
                className="bg-light d-flex justify-content-end align-items-center p-2"
                style={{ borderRadius: 5 }}
              >
                <button
                  onClick={handleOnAddRequisition}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('add')}
                </button>
              </div>
            )}
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                  marginBottom: -10,
                }}
              >
                {localize('requisitionItems')}
              </div>
              <RequisitionEntries
                entries={requisitionEntries}
                handleOnRemove={handleOnRemoveRequisitionEntries}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
