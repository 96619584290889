/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { CreateIncomeAdjustmentsModal } from './CreateIncomeAdjustmentsModal'
import { EditIncomeAdjustmentsModal } from './EditIncomeAdjustmentsModal'
import swal from 'sweetalert2'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function IncomeAdjustmentsModal({
  mode,
  onHideModal,
  selectedIncomeAdjustment,
  setSelectedIncomeAdjustment,
  onUpdateRefreshAt,
}) {
  const [isBusy, setIsBusy] = useState()

  async function handleSubmit() {
    if (mode === 'add') {
      return addIncomeAdjustment()
    }
    if (mode === 'edit') {
      return editIncomeAdjustment()
    }
  }

  const addIncomeAdjustment = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/IncomeAdjustments/add-income-adjustment',
      {
        ...(selectedIncomeAdjustment || {}),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Income Adjustment',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const editIncomeAdjustment = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/IncomeAdjustments/update-income-adjustment',
      {
        ...(selectedIncomeAdjustment || {}),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Income Adjustment',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('incomeAdjustment')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          disableCreate={isBusy}
          modalClassName={'modal-lg'}
        >
          {mode === 'add' && (
            <CreateIncomeAdjustmentsModal
              isBusy={isBusy}
              selectedIncomeAdjustment={selectedIncomeAdjustment}
              setSelectedIncomeAdjustment={setSelectedIncomeAdjustment}
            />
          )}
          {mode === 'edit' && (
            <EditIncomeAdjustmentsModal
              isBusy={isBusy}
              setSelectedIncomeAdjustment={setSelectedIncomeAdjustment}
              selectedIncomeAdjustment={selectedIncomeAdjustment}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default IncomeAdjustmentsModal
