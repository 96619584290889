/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import { getData } from '../../../Helpers/webApi'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import Pager from '../../../Components/Tables/Pager'
import GroupedTable from '../../../Components/Tables/GroupedTable'
import { Modal } from 'reactstrap'
import EditCustomer from './Partials/Customers/EditCustomer'
import AddCustomer from './Partials/Customers/AddCustomer'
import { NavigationContext } from '../../../Contexts/NavigationContext'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Select from 'react-select'
import NonBlockingLoader from '../../../Components/Loaders/NonBlockingLoader'

function Customers({ menuCode }) {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('membershipClass'),
    column: 'membershipClassDescription',
  })

  const [mode, setMode] = useState(null) // 'add' or 'edit'
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [refresh, setRefresh] = useState(false)
  const [mode2, setMode2] = useState(null)

  const { navigatedComponent, navigateToComponent } =
    useContext(NavigationContext)

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.fullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.formattedAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.personalFileNumber}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.membershipStatusDescription}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r.individualBirthDate, true)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r.individualEmploymentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{formatDate(r.individualEmploymentDate, true)}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r.individualEmploymentDesignation}</>,
    },
    {
      label: localize('employmentTermsOfService'),
      template: r => <>{r.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: localize('disabilityType'),
      template: r => <>{r.individualDisabilityTypeDescription}</>,
    },
    {
      label: localize('hudumaNumber'),
      template: r => <>{r.individualHudumaNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('meetingFrequency'),
      template: r => <>{r.nonIndividualMeetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDay'),
      template: r => <>{r.nonIndividualMeetingDayOfWeekDescription}</>,
    },
    {
      label: localize('meetingTime'),
      template: r => <>{r.nonIndividualMeetingTime}</>,
    },
    {
      label: localize('meetingPlace'),
      template: r => <>{r.nonIndividualMeetingPlace}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.addressCity}</>,
    },
    {
      label: localize('emailAdress'),
      template: r => <>{r.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r.addressInternet}</>,
    },
    {
      label: localize('monthlyContribution'),
      template: r => <>{formatCurrency(r.monthlyContribution)}</>,
    },

    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.reference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.reference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.reference3}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r.electoralZoneDescription}</>,
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      template: r => <>{r.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r.individualRetirementAge}</>,
    },

    {
      label: localize('age'),
      template: r => <>{r.age}</>,
    },
    {
      label: `${localize('membershipPeriod')}(${localize('months')})`,
      template: r => <>{r.membershipPeriod}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r.administrativeDivisionDescription}</>,
    },
    {
      label: localize('isTaxExempt'),
      template: r => <>{r.isTaxExempt}</>,
    },
    {
      label: localize('inhibitGuaranteeing'),
      template: r => <>{r.inhibitGuaranteeing}</>,
    },
    {
      label: `${localize('isLocked')}`,
      template: r => <>{r.isLocked}</>,
    },
    {
      label: localize('recruitedBy'),
      template: r => <>{r.recruitedBy}</>,
    },

    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams?.pageIndex, reqParams.pageSize, refresh])

  useEffect(() => {
    let components = [...navigatedComponent]
    for (let c of components) {
      if (c.code === menuCode) {
        c.hasOpenModal = !!mode2
      }
    }
    navigateToComponent(components)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode2])

  function refreshCustomers() {
    setRefresh(!refresh)
  }

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/customer/find-customers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
    }
  }

  const groupingColumns = [
    {
      label: localize('adminDivision'),
      column: 'administrativeDivisionDescription',
    },
    {
      label: localize('domicileBranch'),
      column: 'branchDescription',
    },
    {
      label: `${localize('duty')}/${localize('workStation')}`,
      column: 'dutyStationDescription',
    },
    {
      label: localize('electoralZone'),
      column: 'electoralZoneDescription',
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      column: 'employerDescription',
    },
    {
      label: localize('employmentSector'),
      column: 'employmentSectorDescription',
    },
    {
      label: localize('disabilityType'),
      column: 'individualDisabilityType',
    },
    {
      label: localize('gender'),
      column: 'individualGenderDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'individualIdentityCardTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'individualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'individualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'individualSalutationDescription',
    },
    {
      label: localize('membershipClass'),
      column: 'membershipClassDescription',
    },
    {
      label: localize('customerType'),
      column: 'membershipClassCustomerTypeDescription',
    },
    {
      label: localize('membershipStatus'),
      column: 'membershipStatusDescription',
    },
  ]

  // const searchColumns = [
  //   ...columns.map((c, i) => ({ label: localize(c.label), value: i })),
  // ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('membersManagement'),
          localize('customers'),
        ]}
      />
      <div className="row" style={{ position: 'relative' }}>
        <div className="card bg-white">
          <div className="card-body">
            <div className="my-2">
              <button
                className="btn btn-success px-4 me-2"
                onClick={() => {
                  Swal.fire('hello', 'hello', 'error')
                  setMode('add')
                  setSelectedCustomer(null)
                }}
                style={{ display: 'none' }}
              >
                <i className="uil uil-plus-circle me-2"></i>
                {localize('create')}
              </button>
              <button
                className="btn btn-success px-4"
                onClick={() => {
                  if (minimized)
                    return Swal.fire(
                      'Pending Data',
                      'You have a minimized modal. Please close it first!',
                      'error'
                    )
                  setMode2('add')
                  setSelectedCustomer(null)
                }}
              >
                <i className="uil uil-plus-circle me-3"></i>
                {localize('create')}
              </button>
            </div>
            <div className="row mb-2 mx-0">
              <div className="d-flex align-items-center col-3">
                <label
                  htmlFor="recordsPerPage"
                  className=""
                  style={{ marginLeft: -10, textTransform: 'capitalize' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  name=""
                  id="recordsPerPage"
                  className="form-select ms-3"
                  style={{ width: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-3 d-flex align-items-center">
                <label className="col-3">Group By: </label>
                {/* <select
                  name=""
                  id=""
                  className="form-select text-capitalize"
                  defaultValue={groupBy.column}
                  onChange={e =>
                    setGroupBy({
                      label: groupingColumns.find(
                        f => f.column === e.target.value
                      )?.label,
                      column: e.target.value,
                    })
                  }
                >
                  {groupingColumns.map(({ label, column }) => (
                    <option className="text-capitalize" value={column}>
                      {label}
                    </option>
                  ))}
                </select> */}
                <div style={{ minWidth: 150 }} className="text-capitalize">
                  <Select
                    options={groupingColumns.map(({ label, column }) => ({
                      label,
                      value: column,
                    }))}
                    value={groupingColumns.find(
                      x => x.column === groupBy.column
                    )}
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumns.find(f => f.column === e.value)
                          ?.label,
                        column: e.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div
                className="d-flex align-items-center col-4 col gap-2"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({
                    ...reqParams,
                    filterText: search,
                    pageIndex: 0,
                  })
                }}
              >
                <label htmlFor="searchTable" className="text-capitalize">
                  {localize('search')}
                </label>

                {/* <EnumsCombo
                  enums={CustomerFilter}
                  className="ms-2"
                  value={reqParams?.customerFilter}
                  onChange={x =>
                    setReqParams({
                      ...reqParams,
                      customerFilter: x.value,
                    })
                  }
                /> */}
                <EnumsServerCombo
                  value={reqParams?.customerFilter}
                  enumsKey="CustomerFilter"
                  onChange={({ value }) =>
                    setReqParams({ ...reqParams, customerFilter: value })
                  }
                />
                <input
                  type="search"
                  id="searchTable"
                  className="form-control"
                  defaultValue={reqParams.filterText}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                      pageIndex: 0,
                    })
                  }
                ></input>
                <button
                  className="btn btn-primary"
                  onClick={e => {
                    e.preventDefault()
                    setRefresh(!refresh)
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>

            <GroupedTable
              groupBy={groupBy}
              columns={columns}
              data={data?.pageCollection}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (minimized)
                        return Swal.fire(
                          'Pending Data',
                          'You have a minimized modal. Please close it first!',
                          'error'
                        )
                      setMode2('view')
                      setSelectedCustomer(r)
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    {localize('view')}
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (minimized)
                        return Swal.fire(
                          'Pending Data',
                          'You have a minimized modal. Please close it first!',
                          'error'
                        )

                      setMode('edit')
                      setSelectedCustomer(r)
                    }}
                    style={{ display: 'none' }}
                  >
                    <i className="uil uil-edit-alt"></i>
                    {localize('edit')}
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (minimized)
                        return Swal.fire(
                          'Pending Data',
                          'You have a minimized modal. Please close it first!',
                          'error'
                        )
                      setMode2('edit')
                      setSelectedCustomer(r)
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    {localize('edit')}
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (minimized)
                        return Swal.fire(
                          'Pending Data',
                          'You have a minimized modal. Please close it first!',
                          'error'
                        )
                      setMode2('verify')
                      setSelectedCustomer(r)
                    }}
                  >
                    <i className="uil uil-check-circle"></i>
                    {localize('verify')}
                  </div>

                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (minimized)
                        return Swal.fire(
                          'Pending Data',
                          'You have a minimized modal. Please close it first!',
                          'error'
                        )
                    }}
                  >
                    <i className="uil uil-list-ul"></i>
                    {localize('auditLogs')}
                  </div>
                </>
              )}
            />
            <div className="mb-2"></div>
            <Pager
              itemsCount={data.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              // pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
      {mode && (
        <Modal isOpen className="modal-xl">
          <EditCustomer
            mode={mode}
            customer={selectedCustomer}
            hideModal={() => setMode(null)}
            updatePayload={c => setSelectedCustomer(c)}
            refreshCustomers={refreshCustomers}
          />
        </Modal>
      )}
      {mode2 && (
        <AddCustomer
          refreshFromServer
          readOnly={mode2 === 'view' || mode2 === 'verify'}
          mode={mode2}
          customer={selectedCustomer}
          closeModal={() => setMode2(null)}
          menuCode={menuCode}
          onMinimize={setMinimized}
          refreshCustomers={() => setRefresh(!refresh)}
        />
      )}
    </>
  )
}

export default Customers
