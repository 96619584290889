/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../../Components/Tables/Pager'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

export const ViewRequisitionEntries = ({
  entries,
  reqParams,
  setReqParams,
}) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{formatCurrency(r.itemRegisterEstimatedUnitCost)}</>,
    },
    { label: localize('quantityRequested'), template: r => <>{r.quantity}</> },
  ]

  return (
    <>
      <SimpleTable
        selectables
        data={entries?.pageCollection}
        columns={columns}
      />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
