/** @format */

import Swal from 'sweetalert2'

export const showNotification = (title, icon, text) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: text ?? 'Operation completed successfully!',
  })
}
