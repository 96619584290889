/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Select from 'react-select'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { formatDate, parseDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import EmailPager from '../../../../../Components/Tables/EmailPager'
import Loader from '../../../../../Components/Loaders/Loader'
import ReadEmail from './ReadEmail'
import CreateQuickEmail from './CreateQuickEmail'
import CreateEmail from './CreateEmail'

const EmailAlerts = () => {
  const [reqParams, setReqParams] = useState({
    startDate: parseDate(new Date()),
    endDate: parseDate(new Date()),
    text: '',
    emailAlertFilter: 1, // defaulted to to
    pageIndex: 0,
    pageSize: 10,
  })
  const [refresh, setRefresh] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [emailPayload, setEmailPayload] = useState(null)
  const [selectedMail, setSelectedMail] = useState(null)
  const [modalMode, setModalMode] = useState(null)

  useEffect(() => {
    getEmailAlerts()
    // eslint-disable-next-line
  }, [refresh, reqParams.pageSize, reqParams.pageIndex])

  async function getEmailAlerts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/emailAlert/find-email-alerts-by-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setEmailPayload(data)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('emailAlerts'),
        ]}
      />
      <div className="email-rightbar ms-0">
        <div className="card bg-white">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-end gap-2">
                <button
                  className="btn btn-success px-4"
                  onClick={() => setModalMode('create')}
                >
                  {localize('create')}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setModalMode('createQuick')}
                >
                  {localize('quickEmail')}
                </button>
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('recordsPerPage')}
                </label>
                <Select
                  value={{
                    label: reqParams.pageSize,
                    value: reqParams.pageSize,
                  }}
                  onChange={({ label, value }) =>
                    setReqParams({ ...reqParams, pageSize: value })
                  }
                  options={[
                    { label: 10, value: 10 },
                    { label: 15, value: 15 },
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                    { label: 500, value: 500 },
                    { label: 1000, value: 1000 },
                  ]}
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('dateRage')}
                </label>
                <DatePicker
                  value={{
                    startDate: reqParams.startDate,
                    endDate: reqParams.endDate,
                  }}
                  onSelect={v =>
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                      pageIndex: 0,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  sort
                  enumsKey="EmailAlertFilter"
                  onChange={({ value }) =>
                    setReqParams({ ...reqParams, emailAlertFilter: value })
                  }
                  value={reqParams.emailAlertFilter}
                />
                <input
                  type="text"
                  className="form-control"
                  value={reqParams.text}
                  onChange={e =>
                    setReqParams({ ...reqParams, text: e.target.value })
                  }
                />
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setRefresh(!refresh)
                    setReqParams({ ...reqParams, pageIndex: 0 })
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between my-3"></div>

            {isBusy ? (
              <Loader />
            ) : (
              <ul className="message-list">
                {emailPayload?.pageCollection && (
                  <>
                    {emailPayload.pageCollection.map(e => (
                      <li onClick={() => setSelectedMail(e)}>
                        <div className="col-mail col-mail-1">
                          <div className="checkbox-wrapper-mail">
                            <input
                              id="chk19"
                              type="checkbox"
                              className="form-check-input"
                            />
                            <label
                              for="chk19"
                              className="toggle form-label"
                            ></label>
                          </div>
                          <a
                            className="title"
                            href="##"
                            title={e.customerFullName}
                          >
                            {e?.customerFullName}
                          </a>
                          <span className="star-toggle far fa-star"></span>
                        </div>
                        <div className="col-mail col-mail-2 ms-2">
                          <a className="subject" href="##">
                            <span
                              // style={{ width: 60 }}
                              className={`bg-${
                                e?.mailMessageDLRStatus == 2
                                  ? 'danger'
                                  : e?.mailMessageDLRStatus == 4
                                  ? 'warning'
                                  : e?.mailMessageDLRStatus == 8
                                  ? 'success'
                                  : 'primary'
                              } badge me-2`}
                            >
                              {e?.mailMessageDLRStatusDescription}
                            </span>
                            <span className="fw-bold">
                              {e?.mailMessageSubject}
                            </span>{' '}
                            -{' '}
                            <span className="teaser">{e?.mailMessageBody}</span>
                          </a>
                          <div className="date" style={{ paddingLeft: 0 }}>
                            {formatDate(e?.createdDate)}
                          </div>
                        </div>
                      </li>
                    ))}
                    <div className="mt-3">
                      <EmailPager
                        itemsCount={emailPayload?.itemsCount}
                        pageSize={reqParams?.pageSize}
                        pageIndex={reqParams?.pageIndex}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                        pageBatchSize={10}
                      />
                    </div>
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
      {selectedMail && (
        <ReadEmail email={selectedMail} close={() => setSelectedMail(null)} />
      )}
      {modalMode === 'createQuick' && (
        <CreateQuickEmail close={() => setModalMode(null)} />
      )}
      {modalMode === 'create' && (
        <CreateEmail close={() => setModalMode(null)} />
      )}
    </>
  )
}

export default EmailAlerts
