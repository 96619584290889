/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ListInvestmentProducts from '../../InvestmentProducts/InvestmentProducts'

const InvestmentProductsTab = ({
  handleUpdateInvestmentProducts,
  handleSelectInvestmentProducts,
  resetValues,
  setResetValues,
  actionText
}) => {
  const [selectedInvestmentProducts, setSelectedInvestmentProducts] = useState(
    []
  )

  const handleSelectInvestmentProduct = (product, value) => {
    if (value) {
      setSelectedInvestmentProducts([...selectedInvestmentProducts, product])
    } else {
      setSelectedInvestmentProducts(
        selectedInvestmentProducts.filter(c => c.id !== product.id)
      )
    }
  }

  const handleSelectAllInvestmentProducts = (products, value) => {
    if (value) {
      setSelectedInvestmentProducts(products)
    } else {
      setSelectedInvestmentProducts([])
    }
  }

  useEffect(() => {
    handleSelectInvestmentProducts(selectedInvestmentProducts)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestmentProducts])

  useEffect(() => {
    if (resetValues) {
      setSelectedInvestmentProducts([])
      setResetValues(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      <ListInvestmentProducts
        handleSelectAllInvestmentProducts={handleSelectAllInvestmentProducts}
        handleSelectInvestmentProduct={handleSelectInvestmentProduct}
        handleUpdateInvestmentProducts={handleUpdateInvestmentProducts}
        selectedInvestmentProducts={selectedInvestmentProducts}
        resetValues={resetValues}
        setResetValues={setResetValues}
        actionText={actionText}
      />
    </>
  )
}

export default InvestmentProductsTab
