/** @format */
/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import Tab from '../../../../../Components/Tabs/Tab'
import LoanRequestHistory from './LoanRequestHistory'
import LoanRequestGuarantors from './LoanRequestGuarantors'
import LoanRequestOffsetableLoans from './LoanRequestOffsetableLoans'

function ViewLoanRequest({ loanRequest }) {
  const [request, setRequest] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  async function fetchRequest() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanRequest/find-loan-request-by-id',
      { loanRequestId: loanRequest?.id },
      false
    )
    if (success) {
      setRequest(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchRequest()
  }, [loanRequest])

  const tabItems = [
    {
      label: localize('history'),
      item: <LoanRequestHistory loanRequest={request} />,
    },
    {
      label: localize('offsetableLoans'),
      item: <LoanRequestOffsetableLoans loanRequest={request} />,
    },
    {
      label: localize('loanGuarantors'),
      item: <LoanRequestGuarantors loanRequest={request} />,
    },
  ]

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <div className="row">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('customerName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerFullName}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerMembershipClassCustomerTypeDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('customerMemberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.paddedCustomerMemberNumber}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerIdentificationNumber}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerBranchDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerEmployerDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('dutyWorkstation')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerDutyStationDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerAdministrativeDivisionDescription}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('membershipStatus')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerMembershipStatusDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('ref')}_1 ({localize('account')} #)
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerReference1}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('ref')}_1 ({localize('membership')} #)
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerReference2}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('ref')}_1 ({localize('personalFile')} #)
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.customerReference3}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanProduct')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.loanProductDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanProductSection')}
          </label>
          <input
            type="text"
            className="form-control"
            value={
              request?.loanProductLoanRegistrationLoanProductSectionDescription
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('annualPercentageRate')}
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'end' }}
            value={formatAsPercent(request?.annualPercentageRate)}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('interestCalculationMode')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.loanProductLoanInterestCalculationModeDescription}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanPurpose')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.loanPurposeDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('receivedDate')}
          </label>
          <input
            type="text"
            className="form-control"
            value={formatDate(request?.receivedDate)}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('amountApplied')}
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'end' }}
            value={formatCurrency(request?.amountApplied, 2, 2, false)}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('term')} ({localize('months')})
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'right' }}
            value={request?.termInMonths}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('requestOrigin')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.originDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('primaryDescription')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.primaryDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('secondaryDescription')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.secondaryDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('reference')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.reference}
            disabled
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('status')}
          </label>
          <input
            type="text"
            className="form-control"
            value={request?.statusDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('createdDate')}
          </label>
          <input
            type="text"
            className="form-control"
            value={formatDate(request?.createdDate)}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('disbursedAmount')} ({localize('net')})
          </label>
          <input
            type="text"
            className="form-control"
            value={formatCurrency(request?.netDisbursedAmount, 2, 2, false)}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center gap-2"></div>
      </div>
      <div className="mt-4">
        <Tab tabItems={tabItems} />
      </div>
    </>
  )
}

export default ViewLoanRequest
