/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'

export const QuoationEntries = ({ entries, handleOnRemove }) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
    { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
    { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
    { label: localize('daysToDeliver'), template: r => <>{r.daysToDeliver}</> },
  ]

  return (
    <>
      <SimpleTable
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                handleOnRemove(e)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash"></i>
              {localize('remove')}
            </div>
          </>
        )}
        selectables
        data={entries}
        columns={columns}
      />
    </>
  )
}
