/** @format */

import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'

function PerformanceObjectiveLookup({
  onSelect,
  displayValue,
  departmentId,
  departmentDescription,
}) {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('performanceObjectiveLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{departmentDescription}</>
        </>
      }
      url="/lookup/PerformanceObjective/find-objectives-department-filter-in-page"
      params={{
        departmentId,
        filterText: '',
        pageSize: 10,
        pageIndex: 0,
      }}
      conditionalField="departmentId"
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('performancePillar'),
          template: r => <>{r.performancePillarDescription}</>,
          sortBy: 'performancePillarDescription',
        },
        {
          label: localize('department'),
          template: r => <>{r.departmentDescription}</>,
          sortBy: 'departmentDescription',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('modifiedBy'),
          template: r => <>{r.modifiedBy}</>,
          sortBy: 'modifiedBy',
        },
        {
          label: localize('modifiedDate'),
          template: r => <>{formatDate(r.modifiedDate, true)}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
          sortBy: 'createdBy',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default PerformanceObjectiveLookup
