/** @format */

import { createContext, useState } from 'react'

export const MemoContext = createContext(null)

export function MemoContextProvider({ children }) {
  const [enumsCache, setEnumsCache] = useState({})

  return (
    <MemoContext.Provider
      value={{
        enumsCache,
        setEnumsCache,
      }}
    >
      {children}
    </MemoContext.Provider>
  )
}
