/** @format */
import { useContext } from 'react'
import { LanguageContext } from '../Contexts/LanguageContext'
import dictionary from './dictionary'

function LocalizedComponent(word) {
  const { selectedLanguage } = useContext(LanguageContext)

  const wordDict = dictionary[word]

  if (wordDict) {
    const equivalent = wordDict[selectedLanguage.code]
    if (equivalent) return <>{equivalent}</>
    else return <>{word}</>
  } else {
    return <>{word}</>
  }
}

export default function localize(word) {
  const { props } = LocalizedComponent(word)
  return props.children
}
