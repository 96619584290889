/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'

const SelectedSubstitutes = ({
  selectedSubstitutes,
  handleSelectSubstitute,
  selectedGuarantorSubstitutes,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedGuarantorSubstitutes?.find(
            x => x?.loanGuarantorId === r?.loanGuarantorId
          )}
          onCheck={value => {
            handleSelectSubstitute(r, value)
          }}
        />
      ),
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanGuarantorLoanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanGuarantorLoanCaseStatusDescription,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanGuarantorLoanProductDescription,
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanGuarantorLoanCaseLoanPurposeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r?.substituteCustomerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedSubstituteCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.paddedSubstituteCustomerAccountNumber,
    },
    {
      label: localize('identityCardNumber'),
      template: r => r?.substituteCustomerIndividualIdentityCardNumber,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r?.substituteCustomerAddressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.substituteCustomerAddressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.substituteCustomerAddressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.substituteCustomerAddressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.substituteCustomerAddressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r?.substituteCustomerAddressEmail,
    },
    {
      label: localize('substituteCustomerAddressLandLine'),
      template: r => r?.substituteCustomerAddressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.substituteCustomerAddressMobileLine,
    },
    {
      label: localize('duty') + ' ' + localize('workStation'),
      template: r => r?.substituteCustomerDutyStationDescription,
    },
    {
      label: localize('employer'),
      template: r => r?.substituteCustomerEmployerDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
  ]

  return (
    <>
      <SimpleTable data={selectedSubstitutes} columns={columns} />
    </>
  )
}

export default SelectedSubstitutes
