/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'

function SavingsProducts({ disabled, selected, onSelect, prefix }) {
  const [savingsProducts, setSavingsProducts] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(selected)

  useEffect(() => {
    if (!disabled) onSelect(selectedProducts)
  }, [selectedProducts])

  async function loadSavingsProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/savingsProduct/find-savings-products',
      {},
      false
    )
    if (success) {
      setSavingsProducts(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadSavingsProducts()
  }, [])

  return (
    <div className="px-4 py-2 ">
      {isBusy ? (
        <Loader />
      ) : (
        Array.isArray(savingsProducts) &&
        savingsProducts.map(p => (
          <div key={p.id} className="d-flex align-items-center">
            <Checkbox
              disabled={disabled}
              checked={selectedProducts.find(item => item.id === p.id)}
              onCheck={v => {
                if (v) {
                  let savP = [...selectedProducts, p]
                  setSelectedProducts(savP)
                } else {
                  let savP = [...selectedProducts].filter(sp => sp.id !== p.id)
                  setSelectedProducts(savP)
                }
              }}
              id={`${prefix}-selectedSvProduct_${p.id}`}
            />
            <label
              htmlFor={`${prefix}-selectedSvProduct_${p.id}`}
              className={`fw-normal ms-2 cursor-pointer ${
                selectedProducts.find(i => i.id === p.id)
                  ? 'fw-bold text-primary'
                  : ''
              }`}
            >
              {p.description}
            </label>
          </div>
        ))
      )}
    </div>
  )
}

export default SavingsProducts
