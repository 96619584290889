/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import CostDistributionRuleLookup from '../../../../Components/Lookup/CostDistributionRuleLookup'
import CurrencyLookup from '../../../../Components/Lookup/CurrencyLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function CreateChartOfAccountsModal({ mode, close, account, refreshData }) {
  const [selectedAccount, setSelectedAccount] = useState(account)
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    mode !== 'add' && loadChartOfAccount()
  }, [])

  async function loadChartOfAccount() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/chartOfAccount/find-chart-of-account-by-id',
      { chartofAccountId: selectedAccount?.id },
      false
    )
    if (success) {
      setSelectedAccount(data)
    }
    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleChartOfLookupChartOfAccounts = r => {
    clearValidation('ParentId')
    setSelectedAccount({
      ...selectedAccount,
      parentAccountName: r.description,
      parentId: r.id,
      accountType: r.type,
      accountCategory: r.category,
    })
  }

  const handleSelectCurrency = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected currency is locked'
      )

    setSelectedAccount({
      ...selectedAccount,
      currencyDescription: r.description,
      currencyId: r.id,
    })
  }

  const handleSelectCostDistributionRule = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected cost distribution rule is locked'
      )

    setSelectedAccount({
      ...selectedAccount,
      costDistributionRuleDescription: r.description,
      costDistributionRuleId: r.id,
    })
  }

  async function handleCreate() {
    setIsBusy(true)

    const { success } = await postData(
      '/ChartOfAccount/create-chart-of-account',
      selectedAccount || {
        parentId: '',
      }
    )

    if (success) {
      setSelectedAccount({})
      showNotification('Chart Of Accounts', 'success')
      refreshData()
      close()
    }

    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)
    const { success } = await putData(
      '/chartofaccount/update-chart-of-account',
      selectedAccount
    )
    if (success) {
      setSelectedAccount({})
      showNotification('Chart Of Accounts', 'success')
      close()
      refreshData()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    setGlobalValidationErrors({})

    if (mode === 'edit') {
      setSelectedAccount({
        ...account,
        accountCode: account.code,
        parentAccountName: account.name,
        accountCategory: account.category,
        accountType: account.type,
        accountName: account.description,
      })
    }
  }, [mode])

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle="chartOfAccounts"
      handleAction={mode === 'add' ? handleCreate : handleEdit}
      modalMode={mode}
      disableCreate={isBusy}
      // disableClose={isBusy}
      handleClose={close}
      footerChildren={
        <div className="d-flex align-items-center">
          {isBusy || (
            <>
              <div className="d-flex align-items-center mx-3">
                <Checkbox
                  checked={selectedAccount?.isControlAccount}
                  id="editCAControlAccount"
                  onCheck={v =>
                    setSelectedAccount({
                      ...selectedAccount,
                      isControlAccount: v,
                    })
                  }
                />
                <label
                  className="text-capitalize ms-1"
                  htmlFor="editCAControlAccount"
                >
                  {localize('controlAccount')} ?
                </label>
              </div>
              <div className="d-flex align-items-center mx-3">
                <Checkbox
                  checked={selectedAccount?.isReconciliationAccount}
                  onCheck={v =>
                    setSelectedAccount({
                      ...selectedAccount,
                      isReconciliationAccount: v,
                    })
                  }
                  id="editCAReconciliationAccount"
                />
                <label
                  className="text-capitalize ms-1"
                  htmlFor="editCAReconciliationAccount"
                >
                  {localize('reconciliationAccount')} ?
                </label>
              </div>
              <div className="d-flex align-items-center mx-3">
                <Checkbox
                  checked={selectedAccount?.postAutomaticallyOnly}
                  onCheck={v =>
                    setSelectedAccount({
                      ...selectedAccount,
                      postAutomaticallyOnly: v,
                    })
                  }
                  id="editCApostAutomaticallyOnly"
                />
                <label
                  className="text-capitalize ms-1"
                  htmlFor="editCApostAutomaticallyOnly"
                >
                  {localize('postAutomaticallyOnly')} ?
                </label>
              </div>
              <div className="d-flex align-items-center mx-3">
                <Checkbox
                  checked={selectedAccount?.isConfidential}
                  onCheck={v =>
                    setSelectedAccount({
                      ...selectedAccount,
                      isConfidential: v,
                    })
                  }
                  id="editCAIsConfidential"
                />
                <label
                  className="text-capitalize ms-1"
                  htmlFor="editCAIsConfidential"
                >
                  {localize('isConfidential')} ?
                </label>
              </div>
              <div className="d-flex align-items-center mx-3">
                <Checkbox
                  checked={selectedAccount?.isLocked}
                  onCheck={v =>
                    setSelectedAccount({
                      ...selectedAccount,
                      isLocked: v,
                    })
                  }
                  id="editCAIsLocked"
                />
                <label
                  className="text-capitalize ms-1"
                  htmlFor="editCAIsLocked"
                >
                  {localize('isLocked')} ?
                </label>
              </div>
            </>
          )}
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        <div className="row">
          <div className="col">
            <div
              className="d-flex align-items-center gap-2 with-validation"
              validation-message={globalValidationErrors['ParentId']?.message}
            >
              <label className="text-capitalize col-4">
                {localize('parentGLAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={selectedAccount?.parentAccountName}
                canSelectHeader
                onSelect={handleChartOfLookupChartOfAccounts}
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <label className="text-capitalize col-4">
                {localize('accountType')}
              </label>
              <EnumsServerCombo
                disabled={true}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'ChartOfAccountType'}
                value={selectedAccount?.accountType}
                onChange={({ value }) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    accountType: value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <label className="text-capitalize col-4">
                {localize('accountCategory')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey={'ChartOfAccountCategory'}
                value={selectedAccount?.accountCategory}
                onChange={({ value }) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    accountCategory: value,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center gap-2">
              <label className="text-capitalize col-4">
                {localize('costDistributionRule')}
              </label>

              <CostDistributionRuleLookup
                displayValue={selectedAccount?.costDistributionRuleDescription}
                onSelect={handleSelectCostDistributionRule}
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <label className="text-capitalize col-4">
                {localize('currency')}
              </label>
              <CurrencyLookup
                displayValue={selectedAccount?.currencyDescription}
                onSelect={handleSelectCurrency}
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <label className="text-capitalize col-4">
                {localize('gLAccountCode')}
              </label>
              <input
                className="form-control"
                value={selectedAccount?.accountCode || ''}
                onChange={e =>
                  setSelectedAccount({
                    ...selectedAccount,
                    accountCode: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex align-items-center gap-1 mt-2 with-validation"
            validation-message={globalValidationErrors['AccountName']?.message}
          >
            <label className="text-capitalize col-2">
              {localize('gLAccountName')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedAccount?.accountName}
              onChange={e => {
                setSelectedAccount({
                  ...selectedAccount,
                  accountName: e.target.value,
                })
                clearValidation('AccountName')
              }}
            />
          </div>
          <div className="d-flex align-items-center gap-1 mt-2">
            <label className="text-capitalize col-2">
              {localize('gLAccountReference')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedAccount?.accountReference}
              onChange={e =>
                setSelectedAccount({
                  ...selectedAccount,
                  accountReference: e.target.value,
                })
              }
            />
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
    </ModalTemplate>
  )
}

export default CreateChartOfAccountsModal
