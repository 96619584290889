/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _e = {
  employee: { en: 'Employee', sw: 'Mfanyikazi' },
  employees: {
    en: 'Employees',
    sw: 'Wafanyakazi',
  },
  enableMembershipTextAlerts: {
    en: 'enable membership text alerts',
    sw: 'wezesha arifa za maandishi ya uanachama',
  },
  employeeTypes: {
    en: 'employee types',
    sw: 'aina za wafanyikazi',
  },
  events: {
    en: 'Events',
    sw: 'Matukio',
  },
  exitInterviewQuestions: {
    en: 'Exit Interview Questions',
    sw: 'Maswali Ya Kujiuzulu',
  },
  exit: {
    en: 'Exit',
    sw: 'Toka',
  },
  exitInterviews: {
    en: 'Exit Interviews',
    sw: 'Mahojiano Ya Kutoka',
  },
  effectiveDate: {
    en: 'effective date',
    sw: 'tarehe ya kutumika',
  },
  emailAddress: {
    en: 'email address',
    sw: 'barua pepe',
  },
  externalAgencies: {
    en: 'external agencies',
    sw: 'mashirika ya nje',
  },
  emolumentTypes: {
    en: 'emolument types',
    sw: 'aina za mapato',
  },
  excessChartOfAccountName: {
    en: 'excess g/l name',
    sw: 'chati ya ziada ya jina la akaunti',
  },
  excessChartOfAccountCostDistributionRuleDescription: {
    en: 'excess g/l cost distr. rule',
    sw: 'usambazaji wa gharama ya akaunti',
  },
  employeeCustomerFullName: {
    en: 'employee name',
    sw: 'Jina la mfanyikazi',
  },
  employeeBranchDescription: {
    en: 'employee branch',
    sw: 'maelezo ya tawi la mfanyakazi',
  },
  extendedChargeBenefactorDescription: {
    en: 'extended charge benefactor',
    sw: 'mfadhili wa malipo yaliyopanuliwa',
  },
  endDate: {
    en: 'end date',
    sw: 'tarehe ya kumaliza',
  },
  executePayoutStandingOrders: {
    en: 'execute payout standing orders',
    sw: 'tekeleza maagizo ya kudumu ya malipo',
  },
  executeAdHocOrders: {
    en: 'execute adhoc standing orders',
    sw: 'kutekeleza maagizo ya kudumu ya adhoc',
  },
  employers: {
    en: 'employers',
    sw: 'waajiri',
  },
  employer: {
    en: 'employer',
    sw: 'mwajiri',
  },
  employmentSector: {
    en: 'employment sector',
    sw: 'sekta ya ajira',
  },
  employmentSectors: {
    en: 'employment sectors',
    sw: 'sekta za ajira',
  },
  electoralZones: {
    en: 'electoral zones',
    sw: 'kanda za uchaguzi',
  },
  electoralZone: {
    en: 'electoral zone',
    sw: 'kanda ya uchaguzi',
  },
  employmentDate: {
    en: 'employment date',
    sw: 'tarehe ya kuajiriwa',
  },
  employmentDesignation: {
    en: 'employment designation',
    sw: 'jina la ajira',
  },
  employmentTermsOfService: {
    en: 'employment terms of service',
    sw: 'masharti ya utumishi wa ajira',
  },
  educationLevel: {
    en: 'education level',
    sw: 'kiwango cha elimu',
  },
  emailAdress: {
    en: 'email address',
    sw: 'barua pepe',
  },
  enforceLoanClassificationDate: {
    en: 'enforce loan classification date',
    sw: 'kutekeleza tarehe ya uainishaji wa mkopo',
  },
  executeAdHocStandingOrders: {
    en: 'execute ad hoc standing orders',
    sw: 'kutekeleza maagizo ya kudumu ya ad hoc',
  },
  employersContribution: {
    en: "employer's contribution",
    sw: 'mchango wa mwajiri',
  },
  enforceMaximumGuarantees: {
    en: 'enforce maximum guarantees',
    sw: 'tekeleza dhamana ya juu',
  },
  effectCharges: {
    en: 'effect charges',
    sw: 'tekeleza maagizo ya kudumu',
  },
  equity: {
    en: 'equity',
    sw: 'usawa',
  },
  expenses: {
    en: 'expenses',
    sw: 'gharama',
  },
  edit: {
    en: 'edit',
    sw: 'hariri',
  },
  email: {
    en: 'email',
    sw: 'barua pepe',
  },
  exemptions: {
    en: 'exemptions',
    sw: 'misamaha',
  },
  employeeName: {
    en: 'employee name',
    sw: 'Jina la mfanyikazi',
  },
  employeeType: {
    en: 'employee type',
    sw: 'aina za mfanyikazi',
  },
  employmentStatus: {
    en: 'employment status',
    sw: 'hali ya ajira',
  },
  employeeStatus: {
    en: 'employee status',
    sw: 'hali ya mfanyikazi',
  },
  employerName: {
    en: 'employer name',
    sw: 'jina la mwajiri',
  },
  employeeTypeDescription: {
    en: 'employee type description',
    sw: 'maelezo ya aina ya mfanyikazi',
  },
  employmentStatusDescription: {
    en: 'employment status description',
    sw: 'maelezo ya hali ya ajira',
  },
  enforceInvestmentsBalance: {
    en: 'enforce investments balance',
    sw: 'tekeleza usawa wa uwekezaji',
  },
  excessChartOfAccountCostDistributionRule: {
    en: 'excess g/l cost distr. rule',
    sw: 'usambazaji wa gharama ya akaunti ya g/l',
  },
  employeeBranch: {
    en: 'employee branch',
    sw: 'tawi la mfanyakazi',
  },
  environmentUserName: {
    en: 'env. username',
    sw: 'jina la mtumiaji',
  },
  environmentIPAddress: {
    en: 'env. ip address',
    sw: 'anwani ya ip',
  },
  environmentProcessorId: {
    en: 'env. processor id',
    sw: 'kitambulisho cha mchakato',
  },
  environmentMotherboardSerialNumber: {
    en: 'env. motherboard serial number',
    sw: 'nambari ya usajili',
  },
  environmentMACAddress: {
    en: 'env. mac address',
    sw: 'anwani ya mac',
  },
  environmentOSVersion: {
    en: 'env. os version',
    sw: 'toleo la mfumo wa uendeshaji',
  },
  environmentDomainName: {
    en: 'env. domain name',
    sw: 'jina la kikoa',
  },
  environmentMachineName: {
    en: 'env. machine name',
    sw: 'jina la mashine',
  },
  enrollment: {
    en: 'enrollment',
    sw: 'usajili',
  },
  employmentTerms: {
    en: 'employment terms',
    sw: 'masharti ya ajira',
  },
  enrollmentDate: {
    en: 'enrollment date',
    sw: 'tarehe ya usajili',
  },
  enforceSystemInitializationLockTime: {
    en: 'enforce system initialization lock time',
    sw: 'tekeleza muda wa kufunga kufungia mfumo',
  },
  enforceBiometricsForLogin: {
    en: 'enforce biometrics for login',
    sw: 'tekeleza bayometriki kwa kuingia',
  },
  electronicPayslipEnabled: {
    en: 'electronic payslip enabled',
    sw: 'ripoti ya mshahara ya elektroniki imewezeshwa',
  },
  exemptCustomerDetails: {
    en: 'exempt customer details',
    sw: 'maelezo ya mteja yaliyoachwa',
  },
  exemptedCustomers: {
    en: 'exempted customers',
    sw: 'wateja waliosamehewa',
  },
  editPayrollNumbers: {
    en: 'edit payroll numbers',
    sw: 'hariri nambari za malipo ya mshahara',
  },
  exceptedCustomers: {
    en: 'excepted customers',
    sw: 'wateja wasiwepo',
  },
  enforceAuthorizationToken: {
    en: 'enforce authorization token',
    sw: 'tekeleza uthibitisho wa tokeni',
  },
  educationVenueLookup: {
    en: 'education venue lookup',
    sw: 'tafuta mahali pa kuelimishia',
  },
  errorResponse: {
    en: 'error response',
    sw: 'jibu la kosa',
  },
  ethnicGroup: {
    en: 'ethnic group',
    sw: 'kabila',
  },
  employerLookup: {
    en: 'employer lookup',
    sw: 'tafuta mwajiri',
  },
  electoralZoneLookup: {
    en: 'electoral zone lookup',
    sw: 'tafuta maeneo ya uchaguzi',
  },
  exemptionType: {
    en: 'exemption type',
    sw: 'aina ya msamaha',
  },
  export: {
    en: 'export',
    sw: 'hamisha',
  },
  environmentOperatingSystemVersion: {
    en: 'environment operating system version',
    sw: 'toleo la mfumo wa uendeshaji',
  },
  endTime: {
    en: 'end time',
    sw: 'muda wa mwisho',
  },
  entryStatus: {
    en: 'entry status',
    sw: 'hali ya kuingia',
  },
  entries: {
    en: 'entries',
    sw: 'ingizo',
  },
  executionFilter: {
    en: 'execution filter',
    sw: 'chujio cha utekelezaji',
  },
  enforceSecurityRules: {
    en: 'enforce security rules',
    sw: 'tekeleza sheria za usalama',
  },
  exceptionMessage: {
    en: 'exception message',
    sw: 'ujumbe wa kipekee',
  },
  eligibleIncomeDeductions: {
    en: 'eligible income deductions',
    sw: 'punguzo la mapato yanayostahiki',
  },
  enforceCheckoffCutOffDate: {
    en: 'enforce check-off cut off date',
    sw: 'tekeleza tarehe ya mwisho',
  },
  enableHybridLoanRecovery: {
    en: 'enable hybrid loan recovery',
    sw: 'wezesha urejeshaji wa mkopo wa mseto',
  },
  expectedPrincipal: {
    en: 'expected principal',
    sw: 'mtaji uliotarajiwa',
  },
  expectedInterest: {
    en: 'expected interest',
    sw: 'riba iliyotarajiwa',
  },
  electoralZoneDescription: {
    en: 'electoral zone description',
    sw: 'maelezo ya eneo la uchaguzi',
  },
  enrollmentRequests: {
    en: 'enrollment requests',
    sw: 'ombi la usajili',
  },
  employmentName: {
    en: 'employment name',
    sw: 'jina la ajira',
  },
  encoding: {
    en: 'encoding',
    sw: 'usimbaji',
  },
  entryType: {
    en: 'entry type',
    sw: 'aina ya kuingia',
  },
  error: {
    en: 'error',
    sw: 'hitilafu',
  },
  errorMessage: {
    en: 'error message',
    sw: 'ujumbe wa hitilafu',
  },
  'enquiries(last12Months)': {
    en: 'enquiries (last 12 months)',
    sw: 'uuliziaji (miezi 12 iliyopita)',
  },
  'enquiries(last6Months)': {
    en: 'enquiries (last 6 months)',
    sw: 'uuliziaji (miezi 6 iliyopita)',
  },
  'enquiries(last3Months)': {
    en: 'enquiries (last 3 months)',
    sw: 'uuliziaji (miezi 3 iliyopita)',
  },
  envUserName: {
    en: 'env. username',
    sw: 'jina la mtumiaji',
  },
  envMachineName: {
    en: 'env. machine name',
    sw: 'jina la mashine',
  },
  envDomainName: {
    en: 'env. domain name',
    sw: 'jina la kikoa',
  },
  envMACAddress: {
    en: 'env. mac address',
    sw: 'anwani ya mac',
  },
  envOSVersion: {
    en: 'env. os version',
    sw: 'toleo la mfumo wa uendeshaji',
  },
  envMotherboardSerial: {
    en: 'env. motherboard serial number',
    sw: 'nambari ya usajili',
  },
  envProcessorId: {
    en: 'env. processor id',
    sw: 'kitambulisho cha mchakato',
  },
  envIPAddress: {
    en: 'env. ip address',
    sw: 'anwani ya ip',
  },
  expectedRunDate: {
    en: 'expected run date',
    sw: 'tarehe ya kufanyika',
  },
  eventType: {
    en: 'event type',
    sw: 'aina ya tukio',
  },
  evaluateStandingOrderHistoryForArrearagesRecovery: {
    en: 'evaluate standing order history for arrearages recovery',
    sw: 'tathmini historia ya maagizo ya kudumu kwa urejeshaji wa malimbikizo',
  },
  enforceSecurityRule: {
    en: 'enforce security rule',
    sw: 'tekeleza sheria ya usalama',
  },
  enforceSystemAppraisalRecommendation: {
    en: 'enforce system appraisal recommendation',
    sw: 'tekeleza mapendekezo ya tathmini ya mfumo',
  },
  enforceConsecutiveIncomeValidation: {
    en: 'enforce consecutive income validation',
    sw: 'tekeleza uthibitisho wa mapato yanayofuatana',
  },
  endingBalance: {
    en: 'ending balance',
    sw: 'baki ya mwisho',
  },
  entryDetails: {
    en: 'entry details',
    sw: 'maelezo ya kuingia',
  },
  enrollmentRequestLookup: {
    en: 'enrollment request lookup',
    sw: 'tafuta ombi la usajili',
  },
  execute: {
    en: 'execute',
    sw: 'tekeleza',
  },
  expectedBalance: {
    en: 'expected balance',
    sw: 'baki lililotarajiwa',
  },
  excessAmount: {
    en: 'excess amount',
    sw: 'kiasi cha ziada',
  },
  emailAlerts: {
    en: 'email alerts',
    sw: 'arifa za barua pepe',
  },
  'e-statement': {
    en: 'e-statement',
    sw: 'taarifa ya elektroniki',
  },
  eStatementOrders: {
    en: 'e-statement orders',
    sw: 'maagizo ya taarifa ya elektroniki',
  },
  'employeeRef_1(account#)': {
    en: 'employee account number',
    sw: 'nambari ya akaunti ya mfanyakazi',
  },
  'employeeRef_2(membership#)': {
    en: 'employee membership no.',
    sw: 'nambari ya uanachama ya mfanyakazi',
  },
  'employeeRef_3(personalFile#)': {
    en: 'employee personal file number.',
    sw: 'faili la kibinafsi la mfanyakazi',
  },
  eStatementStartDate: {
    en: 'e-statement start date',
    sw: 'tarehe ya kuanza taarifa ya elektroniki',
  },
  eStatementEndDate: {
    en: 'e-statement end date',
    sw: 'tarehe ya mwisho ya taarifa ya elektroniki',
  },
  eStatementSender: {
    en: 'e-statement sender',
    sw: 'mtumaji wa taarifa ya elektroniki',
  },
  enforCeiling: {
    en: 'enforce ceiling',
    sw: 'tekeleza dari',
  },
  existingGuarantors: {
    en: 'existing guarantors',
    sw: 'wadhamini waliopo',
  },
  expectedPaymentDueDate: {
    en: 'expected payment due date',
    sw: 'tarehe ya malipo yanayotarajiwa',
  },
  enableLegacyReferenceFields: {
    en: 'enable legacy reference fields',
    sw: 'wezesha marejeleo ya zamani',
  },
  excludeClearedLoansFromAccountsListing: {
    en: 'exclude cleared loans from accounts listing',
    sw: 'ondoa mikopo iliyolipwa kutoka kwa orodha ya akaunti',
  },
  enforceRetirementAge: {
    en: 'enforce retirement age',
    sw: 'tekeleza umri wa kustaafu',
  },
  evaluateMembershipStatusForInterestCapitalization: {
    en: 'evaluate membership status for interest capitalization',
    sw: 'tathmini hali ya uanachama kwa ajili ya riba ya mtaji',
  },
  exemption: {
    en: 'exemption',
    sw: 'msamaha',
  },
  entryQuestion: {
    en: 'entry question',
    sw: 'swali la kwanza',
  },
  entryAnswer: {
    en: 'entry answer',
    sw: 'jibu la kwanza',
  },
  excessChartOfAccount: {
    en: 'excess g/l account',
    sw: 'chati ya ziada ya akaunti',
  },
  external: {
    en: 'external',
    sw: 'nje',
  },
  externalChequeCatalogue: {
    en: 'external cheque catalogue',
    sw: 'katalogi ya hundi za nje',
  },
  entryReference: {
    en: 'entry reference',
    sw: 'marejeleo ya kuingia',
  },
  entryAmount: {
    en: 'entry amount',
    sw: 'kiasi cha kuingia',
  },
  emolumentsAndAllowances: {
    en: 'emoluments and allowances',
    sw: 'mapato na posho',
  },
  editSignatories: {
    en: 'edit signatories',
    sw: 'hariri saini',
  },
  editChequeBook: {
    en: 'edit cheque book',
    sw: 'hariri kitabu cha hundi',
  },
  excludeLocked: {
    en: 'exclude locked',
    sw: 'ondoa zilizofungwa',
  },
  executionHistory: {
    en: 'execution history',
    sw: 'historia ya utekelezaji',
  },
  emolumentsPaymentSchedules: {
    en: 'emoluments payment schedules',
    sw: 'ratiba ya malipo ya mapato',
  },
  emolumentPaymentSchedules: {
    en: 'emolument payment schedules',
    sw: 'ratiba ya malipo ya mapato',
  },
  emolumentType: {
    en: 'emolument type',
    sw: 'aina ya mapato',
  },
  emolumentTypeLookup: {
    en: 'emolument type lookup',
    sw: 'tafuta aina ya mapato',
  },
  emolumentsPaymentSchedule: {
    en: 'emolument payment schedule',
    sw: 'ratiba ya malipo ya mapato',
  },
  emolumentBatchLookup: {
    en: 'emolument batch lookup',
    sw: 'tafuta kundi la mapato',
  },
  editOverdraftProtection: {
    en: 'edit overdraft protection',
    sw: 'hariri ulinzi wa mkopo',
  },
  EditOverdraftProtection: {
    en: 'edit overdraft protection',
    sw: 'hariri ulinzi wa mkopo',
  },
  editStandingOrder: {
    en: 'edit standing order',
    sw: 'hariri maagizo ya kudumu',
  },
  expectedCash: {
    en: 'expected cash',
    sw: 'pesa iliyotarajiwa',
  },
  externalChequesBanking: {
    en: 'external cheques banking',
    sw: 'benki za hundi za nje',
  },
  electronicJournals: {
    en: 'electronic journals',
    sw: 'magazeti ya elektroniki',
  },
  electronicJournal: {
    en: 'electronic journal',
    sw: 'jarida la elektroniki',
  },
  externalCheques: {
    en: 'external cheques',
    sw: 'hundi za nje',
  },
  editSalaryPeriod: {
    en: 'edit salary period',
    sw: 'hariri kipindi cha mshahara',
  },
  employeeTypeLookup: {
    en: 'employee type lookup',
    sw: 'tafuta aina ya mfanyakazi',
  },
  expensePayableEntries: {
    en: 'expense payable entries',
    sw: 'ingizo la gharama inayostahili kulipwa',
  },
  expires: {
    en: 'expires',
    sw: 'inamalizika',
  },
  employeeDetails: {
    en: 'employee details',
    sw: 'maelezo ya mfanyakazi',
  },
  emergencyContacts: {
    en: 'emergency contacts',
    sw: 'wawasiliani wa dharura',
  },
  'enforceSystemInitialization/LockTime': {
    en: 'enforce system initialization lock time',
    sw: 'tekeleza muda wa kufunga kufungia mfumo',
  },
  enableTextBase2FA: {
    en: 'enable text base 2FA',
    sw: 'wezesha uthibitisho wa maandishi',
  },
  electronicPaySlipEnabled: {
    en: 'electronic payslip enabled',
    sw: 'ripoti ya mshahara ya elektroniki imewezeshwa',
  },
  enforceTextBased2FA: {
    en: 'enforce text based 2FA',
    sw: 'tekeleza uthibitisho wa maandishi',
  },
  employeeDocuments: {
    en: 'employee documents',
    sw: 'nyaraka za mfanyakazi',
  },
  eligibleDeposits: {
    en: 'eligible deposits',
    sw: 'amana zinazostahiki',
  },
  environmentOperatingSystem: {
    en: 'environment operating system',
    sw: 'mfumo wa uendeshaji wa mazingira',
  },
  estimatedUnitCost: {
    en: 'estimated unit cost',
    sw: 'gharama ya kipimo',
  },
  economicOrder: {
    en: 'economic order',
    sw: 'agizo la kiuchumi',
  },
  extraInformation: {
    en: 'extra information',
    sw: 'taarifa ya ziada',
  },
  editMorguePatient: {
    en: 'edit morgue patient',
    sw: 'hariri mgonjwa wa maiti',
  },
  editDocument: {
    en: 'edit document',
    sw: 'hariri nyaraka',
  },
  expectedDate: {
    en: 'expected date',
    sw: 'tarehe ya kutarajiwa',
  },
  editPersonnel: {
    en: 'edit personnel',
    sw: 'hariri wafanyakazi',
  },
  expiryDate: {
    en: 'expiry date',
    sw: 'tarehe ya kumaliza',
  },
  endingBookValue: {
    en: 'ending book value',
    sw: 'thamani ya mwisho ya kitabu',
  },
  'expenseG/LAccount': {
    en: 'expense g/l account',
    sw: 'akaunti ya g/l ya gharama',
  },
  employeeDisciplinaryCases: {
    en: 'employee disciplinary cases',
    sw: 'kesi za nidhamu za mfanyakazi',
  },
  employeeDisciplinaryCase: {
    en: 'employee disciplinary case',
    sw: 'kesi ya nidhamu ya mfanyakazi',
  },
  employeeReferrals: {
    en: 'employee referrals',
    sw: 'marejeo ya mfanyakazi',
  },
  editDisciplinaryCase: {
    en: 'disciplinary case',
    sw: 'hariri kesi ya nidhamu',
  },
  enableTextBased2FA: {
    en: 'enable text based 2FA',
    sw: 'wezesha uthibitisho wa maandishi',
  },
  employeeReferral: {
    en: 'employee referral',
    sw: 'marejeo ya mfanyakazi',
  },
  editVehicle: {
    en: 'edit vehicle',
    sw: 'hariri gari',
  },
  exemptionDetails: {
    en: 'exemption details',
    sw: 'maelezo ya msamaha',
  },
  employeeEventsRegister: {
    en: 'employee events register',
    sw: 'daftari la matukio ya mfanyakazi',
  },
  externalChequesClearance: {
    en: 'external cheques clearance',
    sw: 'ondoa hundi za nje',
  },
  externalCheque: {
    en: 'external cheque',
    sw: 'hundi ya nje',
  },
  employeeLookup: {
    en: 'employee lookup',
    sw: 'tafuta mfanyakazi',
  },
  'effectCharges ': {
    en: 'effect charges',
    sw: 'tekeleza maagizo ya kudumu',
  },
}

export default _e
