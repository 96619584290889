/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

const VerifyOverdraftProtection = ({
  mode,
  handleClose,
  fetchOverdrafts,
  overdraftId,
}) => {
  const [overDraft, setOverDraft] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [verificationInfo, setVerificationInfo] = useState({
    recordAuditOption: 1,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleCloseModal = () => {
    handleClose()
    setOverDraft({})
  }

  const fetchCustomerAccountProtection = async () => {
    setIsBusy(true)
    const url = '/CustomerAccount/find-customer-account-overdraft-protection'

    const { success, data } = await getData(
      url,
      {
        customerAccountOverdraftProtectionId: overdraftId,
      },
      false
    )

    if (success) {
      setOverDraft({
        ...data,
        overDraftRemarks: data?.remarks,
      })
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleVerifyOverDraft = async () => {
    if (!verificationInfo?.remarks)
      return setGlobalValidationErrors(g => ({
        ...g,
        Remarks: {
          message: 'Please enter remarks',
        },
      }))

    const url = '/CustomerAccount/update-customer-account-overdraft-protection'

    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        ...overDraft,
        remarks: overDraft?.overDraftRemarks,
      },
      false
    )

    if (success) {
      showNotification('Update Overdraft Protection', 'success')
      fetchOverdrafts()
      handleCloseModal()
      setIsBusy(false)
      setVerificationInfo({
        recordAuditOption: 1,
      })
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (overdraftId) fetchCustomerAccountProtection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overdraftId])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="verifyOverdraftProtection"
      modalClassName="modal-xl"
      actionText={'Update'}
      disableCreate={isBusy}
      handleClose={handleCloseModal}
      handleAction={handleVerifyOverDraft}
      footerChildren={
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={globalValidationErrors?.['Remarks']?.message}
          >
            <label className="text-capitalize">{localize('remarks')}</label>
            <input
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('Remarks')
                setVerificationInfo({
                  ...verificationInfo,
                  remarks: e.target.value,
                })
              }}
              disabled={isBusy}
            />
          </div>
          <EnumsServerCombo
            disabled={isBusy}
            customContainerStyle={{
              maxWidth: 150,
              minWidth: 150,
            }}
            enumsKey="RecordAuthOption"
            value={verificationInfo?.recordAuditOption || ''}
            onChange={e => {
              setVerificationInfo({
                ...verificationInfo,
                recordAuditOption: e.value,
              })
            }}
            sort
          />
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            customer={{
              id: overDraft?.customerAccountId,
            }}
            refreshFromServer
            readOnly
          />

          <div className="row g-2">
            <div className="col-md-3 d-flex align-items-center">
              <label className="text-capitalize col-4 me-2">
                {localize('overDraftLimit')}
              </label>
              <input
                type="text"
                className="form-control ms-2 text-end"
                value={formatCurrency(
                  overDraft?.overdraftLimit,
                  null,
                  null,
                  false
                )}
                disabled
              />
            </div>

            <div className="col-md-9 d-flex align-items-center">
              <label className="text-capitalize col-1 me-4">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control ms-3"
                value={overDraft?.overDraftRemarks}
                disabled
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default VerifyOverdraftProtection
