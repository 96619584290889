/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
function PettyCashierAccountStatement({ chartOfAccountId }) {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.journalPrimaryDescription}</>,
    },

    {
      label: localize('debit'),
      template: r => <>{r.formattedDebit}</>,
    },
    {
      label: localize('credit'),
      template: r => <>{r.formattedCredit}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{r.formattedRunningBalance}</>,
    },

    {
      label: localize('secondaryDescription'),
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.journalReference}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.journalValueDate)}</>,
    },
    {
      label: localize('localCurrency'),
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    {
      label: localize('debit/credit'),
      template: r => <>{formatCurrency(r.debitCredit)}</>,
    },

    {
      label: localize('appUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
  ]

  const [balanceBF, setBalanceBF] = useState(null)
  const [debits, setDebits] = useState(null)
  const [credits, setCredits] = useState(null)
  const [balanceCF, setBalanceCF] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    chartOfAccountId: chartOfAccountId,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    filterText: '',
    journalEntryFilter: 0,
    transactionDateFilter: 2,
    tallyDebitsCredits: true,
    fetchJournalEntries: false,
  })
  const [search, setSearch] = useState('')
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.chartOfAccountId])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/JournalEntry/find-general-ledger-transactions-by-chart-of-account-id-and-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
      setBalanceBF(formatCurrency(data.balanceBroughtFoward, 2, 2, false))
      setDebits(formatCurrency(data.totalDebits, 2, 2, false))
      setCredits(formatCurrency(data.totalCredits, 2, 2, false))
      setBalanceCF(formatCurrency(data.balanceCarriedForward, 2, 2, false))
    }
    setIsBusy(false)
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-1"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <div className="d-flex align-items-center col-2">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        value={reqParams?.transactionDateFilter}
                        enumsKey={'TransactionDateFilter'}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            transactionDateFilter: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-4">
                        <label
                          className="text-capitalize col-4"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label
                        className="ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="pettyCashierStatementFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>

                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              alert('print action....')
                            }}
                          >
                            <i className="uil uil-print"></i>
                            {localize('print')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  <div className="row mb-1 g-3 mt-2">
                    {/* <div className="d-flex align-items-center mb-2">
                        <Checkbox
                          id="toggle-selection"
                          checked={toggleSelection}
                          onCheck={v => {
                            onCheckEntries(v)
                          }}
                        />
                        <label
                          htmlFor="toggle-selection"
                          className="text-capitalize ms-2"
                        >
                          {localize('toggleSelection')}
                        </label>
                      </div> */}

                    <div className="col-12">
                      <div className="d-flex justify-content-end align-items-end">
                        <label className=" text-capitalize ms-3">
                          {localize('balanceB/F')}
                        </label>
                        <input
                          type="text"
                          style={{ textAlign: 'end' }}
                          className="form-control ms-3"
                          value={balanceBF || ''}
                          disabled
                        />
                        <label className=" text-capitalize ms-3">
                          {localize('debits')}
                        </label>
                        <input
                          type="text"
                          style={{ textAlign: 'end' }}
                          className="form-control ms-3"
                          value={debits || ''}
                          disabled
                        />
                        <label className=" text-capitalize ms-3">
                          {localize('credits')}
                        </label>
                        <input
                          type="text"
                          style={{ textAlign: 'end' }}
                          className="form-control ms-3"
                          value={credits || ''}
                          disabled
                        />
                        <label className=" text-capitalize ms-3">
                          {localize('balanceC/F')}
                        </label>
                        <input
                          type="text"
                          style={{ textAlign: 'end' }}
                          className="form-control ms-3"
                          value={balanceCF || ''}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PettyCashierAccountStatement
