/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'

const TargetCustomers = ({
  data,
  handleRemove,
  selectedRecipients,
  handleSelectRecipient,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <>
          <Checkbox
            checked={selectedRecipients?.find(c => c?.id === r?.id)}
            onCheck={value => handleSelectRecipient(r, value)}
          />
        </>
      ),
    },
    {
      label: localize('name'),
      template: r => <>{r.fullName}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.memberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.accountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.personalFileNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.addressEmail}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.membershipStatusDescription}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r.individualBirthDate, true)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r.individualEmploymentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{formatDate(r.individualEmploymentDate, true)}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r.individualEmploymentDesignation}</>,
    },
    {
      label: localize('employmentTermsOfService'),
      template: r => <>{r.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine'),
      template: r => <>{r.addressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.addressCity}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r.electoralZoneDescription}</>,
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      template: r => <>{r.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r.individualRetirementAge}</>,
    },

    {
      label: localize('age'),
      template: r => <>{r.age}</>,
    },
    {
      label: `${localize('membershipPeriod')}(${localize('months')})`,
      template: r => <>{r.membershipPeriod}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r.administrativeDivisionDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable data={data} columns={columns} />
      <div className="d-flex justify-content-end align-items-center rounded bg-light mt-2 py-2 px-2">
        <button onClick={handleRemove} className="btn btn-danger">
          {localize('remove')}
        </button>
      </div>
    </>
  )
}

export default TargetCustomers
