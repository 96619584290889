/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { evaluateOffsetableLoans } from '../../../../../Helpers/Modules/loan'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../Helpers/extensions'
import FormulaInput from '../../../../../Components/Formula/FormulaInput'

function OffsetableLoans({
  customerId,
  loanProductId,
  loanCaseId,
  onChangeOffsetables,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [offsetableLoans, setOffsetableLoans] = useState([])

  useEffect(() => {
    loanProductId && customerId && getOffsetableLoans()
    // eslint-disable-next-line
  }, [loanProductId, customerId, loanCaseId])

  async function getOffsetableLoans() {
    setIsBusy(true)
    let payload = await evaluateOffsetableLoans(
      customerId,
      loanProductId,
      loanCaseId
    )
    if (payload)
      setOffsetableLoans(
        payload?.map(l => ({
          ...l,
          isSelected: l.isPreselected,
          id: generateGuid(),
        }))
      )

    setIsBusy(false)
  }

  useEffect(() => {
    onChangeOffsetables(offsetableLoans)
    // eslint-disable-next-line
  }, [offsetableLoans])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={r?.isSelected}
            onCheck={v => {
              let status = r?.isSelected
              let array = [...offsetableLoans]
              if (r?.permitSelectionAndDeselection) {
                let index = array.findIndex(x => x.id === r.id)
                // console.log('selected index loan', index)
                array[index].isSelected = !status
                setOffsetableLoans(array)
              }
            }}
          />
        </>
      ),
    },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r?.customerAccountTypeTargetProductProductSectionDescription}</>
      ),
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r?.loanDefaultAmount)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r?.principalBalance)}</>,
    },
    {
      label: localize('standingOrderPrincipal'),
      template: r => <>{formatCurrency(r?.standingOrderPrincipal)}</>,
    },
    // {
    //   label: localize('partialPrincipalOffset'),
    //   template: r => <>{formatCurrency(r?.partialPrincipalOffset)}</>,
    // },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r?.interestBalance)}</>,
    },
    {
      label: localize('standingOrderInterest'),
      template: r => <>{formatCurrency(r?.standingOrderInterest)}</>,
    },
    // {
    //   label: localize('partialInterestOffset'),
    //   template: r => <>{formatCurrency(r?.partialInterestOffset)}</>,
    // },
    {
      label: localize('partialPrincipalOffsetMathExpression'),
      template: r =>
        r?.isSelected && r?.loanCaseLoanRegistrationPermitPartialOffsetting ? (
          <div className="d-flex align-items-center gap-1">
            <FormulaInput
              value={r?.partialPrincipalOffset}
              useBlur
              onChange={e => {
                let offLoans = [...offsetableLoans]
                let currentIndex = offLoans.findIndex(o => o.id === r.id)
                console.log('currentIndex', currentIndex)
                if (currentIndex < 0) {
                } else {
                  let offsetable = offLoans[currentIndex]
                  offsetable.partialPrincipalOffset = e
                  offLoans[currentIndex] = offsetable
                  setOffsetableLoans(offLoans)
                }
              }}
            />
            {r?.partialPrincipalOffset !== null && (
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  let offLoans = [...offsetableLoans]
                  let currentIndex = offLoans.findIndex(o => o.id === r.id)
                  console.log('currentIndex', currentIndex)
                  if (currentIndex < 0) {
                  } else {
                    let offsetable = offLoans[currentIndex]
                    offsetable.partialPrincipalOffset = null
                    offLoans[currentIndex] = offsetable
                    setOffsetableLoans(offLoans)
                  }
                }}
              >
                <span className="uil uil-delete">x</span>
              </button>
            )}
          </div>
        ) : (
          <>{r?.partialPrincipalOffset}</>
        ),
    },
    {
      label: localize('partialInterestOffsetMathExpression'),
      template: r =>
        r?.isSelected && r?.loanCaseLoanRegistrationPermitPartialOffsetting ? (
          <div className="d-flex align-items-center gap-1">
            <FormulaInput
              value={r?.partialInterestOffset}
              useBlur
              onChange={e => {
                let offLoans = [...offsetableLoans]
                let currentIndex = offLoans.findIndex(o => o.id === r.id)
                if (currentIndex < 0) {
                } else {
                  let offsetable = offLoans[currentIndex]
                  offsetable.partialInterestOffset = e
                  offLoans[currentIndex] = offsetable
                  setOffsetableLoans(offLoans)
                }
              }}
            />
            {r?.partialInterestOffset !== null && (
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  let offLoans = [...offsetableLoans]
                  let currentIndex = offLoans.findIndex(o => o.id === r.id)
                  console.log('currentIndex', currentIndex)
                  if (currentIndex < 0) {
                  } else {
                    let offsetable = offLoans[currentIndex]
                    offsetable.partialInterestOffset = null
                    offLoans[currentIndex] = offsetable
                    setOffsetableLoans(offLoans)
                  }
                }}
              >
                <span className="uil uil-delete">x</span>
              </button>
            )}
          </div>
        ) : (
          <>{r?.partialInterestOffset}</>
        ),
    },
    {
      label: localize('penaltyBalance'),
      template: r => <>{formatCurrency(r?.penaltyBalance)}</>,
    },
    {
      label: localize('clearanceCharges'),
      template: r => <>{formatCurrency(r?.clearanceCharges)}</>,
    },
    {
      label: localize('customerAccountLoanCaseDisbursedDate'),
      template: r => <>{formatDate(r?.customerAccountLoanCaseDisbursedDate)}</>,
    },
    {
      label: localize('loanClassificationDescription'),
      template: r => <>{r?.loanClassificationDescription}</>,
    },
    {
      label: localize('loanDefaultTimeline'),
      template: r => <>{r?.loanDefaultTimeline}</>,
    },
    {
      label: localize('standingOrderDurationStartDate'),
      template: r => <>{formatDate(r?.standingOrderDurationStartDate)}</>,
    },
    {
      label: localize('standingOrderDurationEndDate'),
      template: r => <>{formatDate(r?.standingOrderDurationEndDate)}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{formatCurrency(r?.carryForwardsBalance)}</>,
    },
    {
      label: localize('isPreselected'),
      template: r => (
        <span className="text-capitalize">
          {r?.IsPreselected ? localize('yes') : localize('no')}
        </span>
      ),
    },
  ]

  return isBusy ? (
    <Loader />
  ) : (
    <div>
      <SimpleTable columns={columns} data={offsetableLoans} />
    </div>
  )
}

export default OffsetableLoans
