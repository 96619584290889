/** @format */

/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { NavigationContext } from '../../Contexts/NavigationContext'
import localize from '../../Global/Localize'

function ModalTemplate({
  modalTitle,
  handleClose,
  handleAction,
  modalMode,
  children,
  footerChildren,
  modalClassName = 'modal-lg',
  actionText,
  cancelText,
  hideUpdate,
  hideCancel,
  disableCreate,
  hideFooter = false,
  hideHeader = false,
  disableClose,
}) {
  const { navigatedMenuCode, navigatedComponent, navigateToComponent } =
    useContext(NavigationContext)

  const [minimized, setMinimized] = useState(false)

  useEffect(() => {
    setHasModal(true)

    return () => {
      setHasModal(false)
    }
    // eslint-disable-next-line
  }, [])

  function setHasModal(v) {
    let components = [...navigatedComponent]

    for (let c of components) {
      if (c.code === navigatedMenuCode) {
        c.hasOpenModal = !!v
      }
    }
    navigateToComponent(components)
  }

  return (
    <>
      {minimized ? (
        <div className="modal-minimized" onClick={() => setMinimized(false)}>
          <i className="uil uil-clapper-board fs-2"></i>
        </div>
      ) : (
        <Modal isOpen={!minimized} className={modalClassName}>
          {hideHeader || (
            <ModalHeader className="bg-white text-capitalize">
              {localize(modalMode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-capitalize">
                {localize(modalTitle)}
              </span>
              <button
                type="button"
                className="minimize"
                onClick={() => setMinimized(true)}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Minimize"
              >
                <span aria-hidden="true">_</span>
              </button>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                disabled={disableClose}
                style={disableClose ? { opacity: 0.2 } : { opacity: 1 }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
          )}
          <ModalBody className="bg-white">{children}</ModalBody>
          {hideFooter || (
            <ModalFooter className="bg-white">
              {footerChildren}
              {!hideCancel && (
                <button
                  onClick={handleClose}
                  className={'btn btn-danger text-capitalize'}
                  disabled={disableClose}
                >
                  {localize(cancelText || 'cancel')}
                </button>
              )}
              {!hideUpdate && (
                <button
                  className="btn btn-success"
                  onClick={handleAction}
                  disabled={disableCreate}
                >
                  {localize(
                    modalMode === 'add'
                      ? actionText || 'create'
                      : actionText || 'update'
                  )}
                </button>
              )}
            </ModalFooter>
          )}
        </Modal>
      )}
    </>
  )
}

export default ModalTemplate
