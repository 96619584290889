/** @format */

import React, { useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { chargeType } from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const EditSalaryCardEntry = ({
  selectedEntry,
  setSelectedEntry,
  closeModal,
  mode,
  loadSalaryHeads,
}) => {
  const [loading, setLoading] = useState(false)
  const handleUpdateCardEntry = async () => {
    setLoading(true)
    const { success } = await putData(
      '/SalaryCard/update-card-entry',
      selectedEntry,
      false
    )

    if (success) {
      showNotification('Update Salary Card Entry', 'success')
      setLoading(false)
      loadSalaryHeads()
      closeModal()
    }
    setLoading(false)
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="salaryCardEntries"
        handleClose={closeModal}
        handleAction={handleUpdateCardEntry}
        modalClassName="modal-xl"
        actionText={'update'}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center  ">
                <label className="col-md-3 text-capitalize">
                  {localize('groupValueType')}
                </label>
                <input
                  type="text"
                  className="form-control ms-3"
                  value={selectedEntry?.salaryGroupEntryChargeTypeDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('groupValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    selectedEntry?.salaryGroupEntryChargeFixedAmount
                  }
                  className="form-control ms-3"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-3 text-capitalize">
                  {localize('groupMinimumValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    selectedEntry?.salaryGroupEntryMinimumValue || ''
                  }
                  className="form-control ms-3"
                  disabled
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-4 d-flex align-items-center mb-2">
                <label className="col-md-3 text-capitalize me-3">
                  {localize('cardValueType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    width: '100%',
                  }}
                  value={selectedEntry?.chargeType}
                  enumsKey={'ChargeType'}
                  onChange={e => {
                    setSelectedEntry({
                      ...selectedEntry,
                      chargeType: e.value,
                      chargeTypeDescription: chargeType[e.value].name,
                    })
                  }}
                />
              </div>
              {selectedEntry?.chargeType === '1' ? (
                <div className="col-md-4 d-flex align-items-center mb-2">
                  <label className="text-capitalize col-md-2">
                    {localize('cardValue')}
                  </label>
                  <input
                    className="form-control ms-3"
                    onChange={e =>
                      setSelectedEntry({
                        ...selectedEntry,
                        chargePercentage: e.target.value,
                      })
                    }
                    defaultValue={selectedEntry.chargePercentage || ''}
                  />
                </div>
              ) : (
                ''
              )}

              {selectedEntry?.chargeType === '2' ? (
                <div className="col-md-4 d-flex align-items-center mb-2">
                  <label className="text-capitalize col-md-2">
                    {localize('cardValue')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={selectedEntry?.chargeFixedAmount || ''}
                    className="form-control ms-3"
                    onMaskNumber={e => {
                      setSelectedEntry({
                        ...selectedEntry,
                        chargeFixedAmount: e.target.value,
                      })
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditSalaryCardEntry
