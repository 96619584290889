/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import Tab from '../../../../../Components/Tabs/Tab'
import MobileToBankRequestEntries from '../MobileToBankRequestEntries'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import Swal from 'sweetalert2'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import MobileToBankRequestCustomerAccounts from './MobileToBankRequestCustomerAccounts'
import { postData, putData } from '../../../../../Helpers/webApi'
import CustomerAccountPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import {
  apportionTo,
  apportionedComponent,
} from '../../../../../Global/enumeration'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
function ReconcileMobileToBankDataModal({
  mode,
  selectedMobileToBankRequest,
  setSelectedMobileToBankRequest,
  close,
  onSetMode,
  loadData,
}) {
  const [selectedCustomer, setSelectedCustomer] = useState({
    apportionedComponent: Object.keys(apportionedComponent)[2],
    apportionTo: Object.keys(apportionTo)[0],
  })
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [EntriesToBeRemoved, setEntriesToBeRemoved] = useState([])
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [shortageAmount, setShortageAmount] = useState(null)
  const [refreshEntry, setRefreshEntry] = useState(false)

  function closeModal() {
    close()
    setGlobalValidationErrors({})
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({
        ...selectedCustomer,
        apportionTo: selectedCustomer.apportionTo,
        ...customer,
      })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Customer is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  const handleOnSelectAccount = account => {
    if (!account.isLocked) {
      setSelectedAccount({ ...selectedAccount, ...account })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedAccount(null)
    }
  }
  const addApportionmentEntry = async customer => {
    const apportionmentEntry = {
      customerAccountCustomerId: selectedCustomer.id,
      customerAccountId: selectedAccount?.id,
      mobileToBankCollectionId: selectedMobileToBankRequest.id,
      chartOfAccountId: selectedCustomer?.chartOfAccountId,
      mobileToBankRequestId: selectedMobileToBankRequest?.id,
      id: selectedMobileToBankRequest.id,
      apportionTo: selectedCustomer?.apportionTo,
      apportionedComponent: selectedCustomer?.apportionedComponent,
      apportionedAmount: selectedCustomer?.apportionedAmount,
      remarks: selectedCustomer?.remarks,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
      transactionAmount: selectedMobileToBankRequest.transAmount,
    }

    const { success } = await postData(
      '/MobileToBankRequest/create-mobile-to-bank-request-entry',
      apportionmentEntry,
      true,
      'Operation Completed Successfully'
    )
    if (success) {
      setRefreshEntry(!refreshEntry)
      setSelectedCustomer({ apportionTo: selectedCustomer?.apportionTo })
      setSelectedAccount(null)
    }
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleRemoveEntry = async () => {
    if (EntriesToBeRemoved.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'Select Atleast One Entry',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    const { success } = await putData(
      '/MobileToBankRequest/remove-mobile-to-bank-request-entries',
      EntriesToBeRemoved,
      true,
      `Entries Removed`
    )
    if (success) {
      setEntriesToBeRemoved([])
      setRefreshEntry(!refreshEntry)
    }
  }

  async function handleSubmit() {
    const { success } = await putData(
      '/MobileToBankRequest/reconcile-mobile-to-bank-request',
      {
        mobileToBankRequest: {
          ...selectedMobileToBankRequest,
          branchId: '0e58e292-bcf3-ed11-849a-402343441bbc',
          id: selectedMobileToBankRequest?.id,
        },
        /**@TODO: alex-stands for the branchId of the logged in user-7/13/2023 */
      },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      onSetMode(null)
      setSelectedMobileToBankRequest(null)
      loadData()
    }
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="mobileToBankRequests"
        handleClose={closeModal}
        handleAction={() => {
          if (
            Number(stripNonNumericCharacters(apportionedAmount)) !=
            Number(
              stripNonNumericCharacters(
                selectedMobileToBankRequest?.transAmount
              )
            )
          ) {
            Swal.fire(
              'Ooops!',
              'apportioned amount should be equal to transaction amount',
              'info'
            )
            return
          } else {
          }
          handleSubmit()
        }}
        modalClassName="modal-xl"
        actionText={mode === 'reconcile' ? 'reconcile' : ''}
        footerChildren={
          <>
            {mode == 'reconcile' ? (
              <>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('apportioned')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={apportionedAmount || ''}
                  />
                </div>

                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('shortage')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={shortageAmount || ''}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </>
        }
      >
        <div className="row mb-2">
          <div
            className="col d-flex align-items-center ms-1 with-validation"
            validation-message={
              globalValidationErrors['MobileToBankRequest.ValueDate']?.message
            }
          >
            <label className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <CustomDatePicker
              defaultDate={selectedMobileToBankRequest?.valueDate}
              name={'valueDate'}
              onDateChange={date => {
                clearValidation('MobileToBankRequest.ValueDate')
                setSelectedMobileToBankRequest({
                  ...selectedMobileToBankRequest,
                  valueDate: date,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mobileToBankTerminal')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedMobileToBankRequest?.mobileToBankTerminalDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionTime')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(
                  selectedMobileToBankRequest?.formattedTransTime,
                  false
                ) || ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionAmount')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatCurrency(selectedMobileToBankRequest?.transAmount) || ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('billReferenceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              value={selectedMobileToBankRequest?.billRefNumber || ''}
              onChange={e => {
                setSelectedMobileToBankRequest({
                  ...selectedMobileToBankRequest,
                  billRefNumber: e.target.value,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mSISDN')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.msisdn || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('businessShortCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.businessShortCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('invoiceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.invoiceNumber || ''}
            />
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('apportionment')}
          </div>

          <div className="col-12 d-flex align-items-center mb-2">
            <label className="col-1 text-capitalize">
              {localize('apportionedTo')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="ApportionTo"
              value={selectedCustomer?.apportionToDescription}
              onChange={e =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  apportionTo: e.value,
                })
              }
            />
          </div>

          {selectedCustomer?.apportionTo == 1 ? (
            <>
              <CustomerPartial
                customer={selectedCustomer}
                onCustomerLookup={r => {
                  handleOnSelectCustomer(r)
                }}
              />
              <CustomerAccountPartial
                selectedAccount={selectedAccount}
                onCustomerAccountLookup={r => {
                  handleOnSelectAccount(r)
                }}
                selectedCustomer={selectedCustomer}
              />
            </>
          ) : (
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center ms-2">
                  <label className="col-4 text-capitalize">
                    {localize('chartOfAccountName')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={selectedCustomer?.chartOfAccountName}
                    onSelect={r => {
                      setSelectedCustomer({
                        ...selectedCustomer,
                        chartOfAccountId: r.id,
                        chartOfAccountName: r.description,
                        customerAccountCustomerAccountTypeTargetProductDescription:
                          r.typeDescription,
                        mobileToBankRequestChartOfAccountCostDistributionRuleDescription:
                          r.costDistributionRuleDescription,
                        remarks: r.accountName,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountType')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      selectedCustomer?.customerAccountCustomerAccountTypeTargetProductDescription ||
                      ''
                    }
                  />
                </div>
                <div className="col-6 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('costDistributionRule')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={
                      selectedCustomer?.mobileToBankRequestChartOfAccountCostDistributionRuleDescription ||
                      ''
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="row mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['ApportionedAmount']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('amount')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedCustomer?.apportionedAmount}
                className="form-control ms-2"
                onMaskNumber={e => {
                  clearValidation('ApportionedAmount')
                  setSelectedCustomer({
                    ...selectedCustomer,
                    apportionedAmount: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('component')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                value={selectedCustomer?.apportionedComponentDescription}
                onChange={e =>
                  setSelectedCustomer({
                    ...selectedCustomer,
                    apportionedComponent: e.value,
                  })
                }
              />
            </div>
            {selectedCustomer?.apportionTo == 1 ? (
              <div className="col-6 d-flex align-items-center ">
                <label className="col-2 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={
                    selectedAccount?.customerAccountTypeTargetProductDescription ||
                    ''
                  }
                  onChange={e => {
                    clearValidation('Remarks')
                    setSelectedAccount({
                      ...selectedAccount,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            ) : (
              <div className="col-6 d-flex align-items-center ">
                <label className="col-2 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={selectedCustomer?.chartOfAccountName || ''}
                  onChange={e => {
                    clearValidation('Remarks')
                    setSelectedAccount({
                      ...selectedCustomer,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            )}
          </div>
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2"
            style={{ borderRadius: 5 }}
          >
            <button
              onClick={() => {
                addApportionmentEntry(selectedCustomer)
              }}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          </div>

          <div className="col-12 mt-2">
            <Tab
              tabItems={[
                {
                  label: localize('entries'),
                  item: (
                    <MobileToBankRequestEntries
                      showLoader={false}
                      selectedMobileToBankRequest={selectedMobileToBankRequest}
                      handleOnCheckEntry={r => {
                        setEntriesToBeRemoved(r)
                      }}
                      removeSelectedEntries={r => {
                        handleRemoveEntry()
                      }}
                      mode={mode}
                      entryStatus={1}
                      setApportioned={setApportionedAmount}
                      setShortage={setShortageAmount}
                      refreshEntry={refreshEntry}
                    />
                  ),
                },
                {
                  label: localize('customerAccounts'),
                  item: (
                    <MobileToBankRequestCustomerAccounts
                      showLoader={false}
                      selectedCustomer={selectedCustomer}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}
export default ReconcileMobileToBankDataModal
