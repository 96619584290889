/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import { getData } from '../../../../../Helpers/webApi'

// TODO:job fix this 25/08/2023
function LoansGuaranteed({ customer, filterClearedLoans = true }) {
  const [loading, setLoading] = useState(false)
  const [loansGuaranteed, setLoansGuaranteed] = useState([])

  const groupBy = { label: localize('status'), column: 'statusDescription' }

  useEffect(() => {
    fetchLoansGuaranteed()
  }, [customer])

  async function fetchLoansGuaranteed() {
    setLoading(true)
    setLoansGuaranteed([])
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-guarantors-by-loanee-customer-id',
      {
        customerId: customer?.id,
        includeOutstandingBalance: true,
        filterClearedLoans: false,
      },
      false
    )
    if (success) {
      setLoansGuaranteed(data.result)
    }
    setLoading(false)
  }

  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r.loanCaseStatusDescription}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.loaneeCustomerFullName}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.loaneeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedLoaneeCustomerMemberNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r.amountGuaranteed)}</>,
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r.amountPledged)}</>,
    },
    {
      label: localize('loanApprovedAmount'),
      template: r => <>{formatCurrency(r.loanCaseApprovedAmount)}</>,
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => <>{formatCurrency(r.loanBalanceOutstanding)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r.loanDefaultAmount)}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision'),
      template: r => <>{formatAsPercent(r.loanLossProvisionPercentage)}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{formatCurrency(r.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{r.loanLastPaymentDueDate}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r.loanLastPayment}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r.loanCaseLoanPurposeDescription}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r.loanCaseBranchDescription}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r.totalShares)}</>,
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r.committedShares)}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => <>{r.appraisalFactor}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return loading ? (
    <Loader />
  ) : (
    <div>
      <GroupedTable
        data={loansGuaranteed}
        columns={columns}
        groupBy={groupBy}
      />
    </div>
  )
}

export default LoansGuaranteed
