/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import ListSettlementBatches from '../ReUsables/BatchOrigination/ListBatchOrigination'

function MembershipWithdrawalSettlementBatchLookup({ onSelect, displayValue }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setShowModal(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setShowModal(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {showModal && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('membershipWithdrawalSettlementBatchLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            <ListSettlementBatches
              statusDisabled
              showContextMenu={false}
              showAddButton={false}
              canClickOnce
              onSelect={r => {
                onSelect(r)
                setShowModal(false)
              }}
            />
          </ModalBody>
          <ModalFooter className="bg-white p-0">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setShowModal(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default MembershipWithdrawalSettlementBatchLookup
