/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _b = {
  branches: { en: 'branches', sw: 'matawi' },
  baseCurrency: {
    en: 'base currency',
    sw: 'sarafu ya Msingi',
  },
  baseCurrencyCode: {
    en: 'base currency code',
    sw: 'msimbo',
  },
  banks: {
    en: 'banks',
    sw: 'benki',
  },
  bankersChequeBook: {
    en: "banker's cheque books",
    sw: 'kitabu cha cheki cha mabenki',
  },
  branchDescription: {
    en: 'branch',
    sw: 'tawi',
  },
  bankLinkageBranchDescription: {
    en: 'branch',
    sw: 'tawi',
  },
  bankLinkageChartOfAccountName: {
    en: 'bank linkage g/l account name',
    sw: 'jina la akaunti ya g/l',
  },
  bankLinkageBankName: {
    en: 'bank name',
    sw: 'jina la benki',
  },
  bankLinkageBankBranchName: {
    en: 'bank branch',
    sw: 'tawi la benki',
  },
  bankLinkageBankAccountNumber: {
    en: 'bank account number',
    sw: 'nambari ya akaunti',
  },
  beneficiaryProducts: {
    en: 'beneficiary products',
    sw: 'bidhaa za walengwa',
  },
  billReferenceNumber: {
    en: 'bill reference number',
    sw: 'nambari ya kumbukumbu ya bili',
  },
  birthDate: {
    en: 'birth date',
    sw: 'tarehe ya kuzaliwa',
  },
  business: {
    en: 'business',
    sw: 'biashara',
  },
  branch: {
    en: 'branch',
    sw: 'tawi',
  },
  bankLinkages: {
    en: 'bank linkages',
    sw: 'miunganisho ya benki',
  },
  biometricsThreshold: {
    en: 'biometrics threshold',
    sw: 'kizingiti cha biometriska',
  },
  beneficiarybranch: {
    en: 'beneficiary branch',
    sw: 'tawi la wanufaika',
  },
  businessDays: {
    en: 'business days',
    sw: 'siku za biashara',
  },
  bookBalance: {
    en: 'book balance',
    sw: ' pesa iliyosalia',
  },
  backOfficeManagement: {
    en: 'back office management',
    sw: 'nyuma ya usimamizi  wa ofisi',
  },
  bankersChequeBooks: {
    en: 'bankers cheque books',
    sw: 'vitabu vya cheki vya mabenki',
  },
  bankBranch: {
    en: 'bank branch',
    sw: 'tawi la benki',
  },
  bank: {
    en: 'bank',
    sw: 'benki',
  },
  beds: {
    en: 'beds',
    sw: 'vitanda',
  },
  bed: {
    en: 'bed',
    sw: 'kitanda',
  },
  billRefNumberPrefix: {
    en: 'bill ref number prefix',
    sw: 'kiambishi awali cha nambari ya kumbukumbu ya bili',
  },
  businessShortCode: {
    en: 'business short code',
    sw: 'kanuni fupi ya biashara',
  },
  bankBranches: {
    en: 'bank branches',
    sw: 'matawi ya benki',
  },
  bankName: {
    en: 'bank name',
    sw: 'jina la benki',
  },
  bankAccountNumber: {
    en: 'bank account number',
    sw: 'nambari ya akaunti ya benki',
  },
  batchTypeDescription: {
    en: 'batch type description',
    sw: 'aina ya maelezo ya kundi',
  },
  batchFileTypeDescription: {
    en: 'batch file type description',
    sw: 'maelezo ya aina ya faili ya kundi',
  },
  bankBranchName: {
    en: 'bank branch name',
    sw: 'jina la tawi la benki',
  },
  benevolentSpouse: {
    en: 'benevolent spouse',
    sw: 'mwenzi mwema',
  },
  benevolentChild: {
    en: 'benevolent child',
    sw: 'mtoto mkarimu',
  },
  back: {
    en: 'back',
    sw: 'nyuma',
  },
  bloodGroupDescription: {
    en: 'blood group description',
    sw: 'maelezo ya kundi la damu',
  },
  batchOrigination: {
    en: 'batch origination',
    sw: 'asili ya kundi',
  },
  batchVerification: {
    en: 'batch verification',
    sw: 'uthibitisho wa kundi',
  },
  batchAuthorization: {
    en: 'batch authorization',
    sw: 'idhini ya kundi',
  },
  branchLookup: {
    en: 'branch lookup',
    sw: 'utafutaji wa tawi',
  },
  bankAccounts: {
    en: 'bank accounts',
    sw: 'akaunti za benki',
  },
  bankCode: {
    en: 'bank code',
    sw: 'msimbo',
  },
  branchCode: {
    en: 'branch code',
    sw: 'msimbo',
  },
  branchName: {
    en: 'branch name',
    sw: 'jina la tawi',
  },
  biometricRegister: {
    en: 'biometric register',
    sw: 'daftari la biometriska',
  },
  biometricRegistration: {
    en: 'biometric registration',
    sw: 'usajili wa biometriska',
  },
  biometricExemptions: {
    en: 'biometric exemptions',
    sw: 'msamaha wa biometriska',
  },
  basicDetails: {
    en: 'basic details',
    sw: 'mambo ya msingi',
  },
  batchNumber: {
    en: 'batch number',
    sw: 'nambari ya kundi',
  },
  biometricExemptionPreferences: {
    en: 'biometric exemption preferences',
    sw: 'mapendeleo ya msamaha wa biometriska',
  },
  biometricExemptiondetails: {
    en: 'biometric exemption details',
    sw: 'maelezo ya msamaha wa biometriska',
  },
  boardMemberNames: {
    en: 'board member names',
    sw: 'majina ya wanachama wa bodi',
  },
  batchProcedures: {
    en: 'batch procedures',
    sw: 'taratibu za kundi',
  },
  bankToBank: {
    en: 'bank to bank',
    sw: 'benki hadi benki',
  },
  bankReconciliation: {
    en: 'bank reconciliation',
    sw: 'upatanisho wa benki',
  },
  budgetManagement: {
    en: 'budget management',
    sw: 'usimamizi wa bajeti',
  },
  'Business/Group/Employer': {
    en: 'business/group/employer',
    sw: 'biashara/kikundi/mwajiri',
  },
  branchDetails: {
    en: 'branch details',
    sw: 'maelezo ya tawi',
  },
  balanceCF: {
    en: 'balance c/f',
    sw: 'iliyosalia c/f',
  },
  balanceBF: {
    en: 'balance b/f',
    sw: 'iliyosalia b/f',
  },
  boardMemberPfNumber: {
    en: 'board member personal file number',
    sw: 'nambari ya faili ya mwanachama wa bodi',
  },
  'business/Group/Employer': {
    en: 'business/group/employer',
    sw: 'biashara/kikundi/mwajiri',
  },
  bloodGroup: {
    en: 'blood group',
    sw: 'kundi la damu',
  },
  bankAccountBalance: {
    en: 'bank account balance',
    sw: 'baki ya akaunti ya benki',
  },
  bankReconciliationEntryStatus: {
    en: 'bank reconciliation entry status',
    sw: 'hali ya kuingia kwa upatanisho wa benki',
  },
  'balanceC/F': {
    en: 'balance c/f',
    sw: 'iliyosalia c/f',
  },
  'balanceB/F': {
    en: 'balance b/f',
    sw: 'iliyosalia b/f',
  },
  batchEntries: {
    en: 'batch entries',
    sw: 'ingizo la kundi',
  },
  bankLinkageLookup: {
    en: 'bank linkage lookup',
    sw: 'utafutaji wa uunganishaji wa benki',
  },
  bankReconciliationPeriodLookup: {
    en: 'bank reconciliation period lookup',
    sw: 'utafutaji wa kipindi cha upatanisho wa benki',
  },
  bankReconciliationEntries: {
    en: 'bank reconciliation entries',
    sw: 'ingizo la upatanisho wa benki',
  },
  branchNumber: {
    en: 'branch number',
    sw: 'nambari ya tawi',
  },
  bankToBankRequestsCatalogue: {
    en: 'bank to bank requests (catalogue)',
    sw: 'orodha ya benki hadi benki',
  },
  bankToBankTerminal: {
    en: 'bank to bank terminal',
    sw: 'taminali ya benki hadi benki',
  },
  bankToBankRequests: {
    en: 'bank to bank requests',
    sw: 'ombi la benki hadi benki',
  },
  budgetManagementLookup: {
    en: 'budget management lookup',
    sw: 'tafuta usimamizi wa bajeti',
  },
  budgetHierarchiesLookup: {
    en: 'budget hierarchies lookup',
    sw: 'tafuta muundo wa bajeti',
  },
  balanceRange: {
    en: 'balance range',
    sw: 'kiwango cha baki',
  },
  batchType: {
    en: 'batch type',
    sw: 'aina ya kundi',
  },
  'batch#': {
    en: 'batch #',
    sw: 'kundi #',
  },
  batchFileType: {
    en: 'batch file type',
    sw: 'aina ya faili ya kundi',
  },
  basisValue: {
    en: 'basis value',
    sw: 'thamani ya msingi',
  },
  batchDetails: {
    en: 'batch details',
    sw: 'maelezo ya kundi',
  },
  batch: {
    en: 'batch',
    sw: 'kundi',
  },
  beneficiary: {
    en: 'beneficiary',
    sw: 'mpokeaji',
  },
  balance: {
    en: 'balance',
    sw: 'baki',
  },
  balanceStatistic: {
    en: 'balance statistic',
    sw: 'takwimu za baki',
  },
  by: {
    en: 'by',
    sw: 'na',
  },
  balanceStatistics: {
    en: 'balance statistics',
    sw: 'takwimu za baki',
  },
  batchMonth: {
    en: 'batch month',
    sw: 'mwezi wa kundi',
  },
  batchReference: {
    en: 'batch reference',
    sw: 'rejeleo la kundi',
  },
  benefactorProduct: {
    en: 'benefactor product',
    sw: 'bidhaa ya mtoaji',
  },
  bankToBankRequestVerification: {
    en: 'bank to bank request (verification)',
    sw: 'uthibitisho wa ombi la benki hadi benki',
  },
  batchTotalValue: {
    en: 'batch total value',
    sw: 'jumla ya thamani ya kundi',
  },
  beneficiaryProductName: {
    en: 'beneficiary product name',
    sw: 'jina la bidhaa ya mpokeaji',
  },
  beneficiaryFullAccountNumber: {
    en: 'beneficiary full account number',
    sw: 'nambari kamili ya akaunti ya mpokeaji',
  },
  beneficiaryName: {
    en: 'beneficiary name',
    sw: 'jina la mpokeaji',
  },
  benefactorFullAccountNumber: {
    en: 'benefactor full account number',
    sw: 'nambari kamili ya akaunti ya mtoaji',
  },
  benefactorProductName: {
    en: 'benefactor product name',
    sw: 'jina la bidhaa ya mtoaji',
  },

  bankToBankRequestsReconciliation: {
    en: 'bank to bank requests (reconciliation)',
    sw: '(upatanisho) wa ombi la benki hadi benki',
  },
  bankToBankRequestsReceipting: {
    en: 'bank to bank requests (receipting)',
    sw: '(upokeaji) wa ombi la benki hadi benki',
  },
  bankToBankRequestsVerification: {
    en: 'bank to bank requests (verification)',
    sw: '(uthibitisho) wa ombi la benki hadi benki',
  },
  bankReconciliationPeriods: {
    en: 'bank reconciliation (periods)',
    sw: 'upatanisho wa benki (vipindi)',
  },
  bankReconciliationProcessing: {
    en: 'bank reconciliation (processing)',
    sw: 'upatanisho wa benki (usindikaji)',
  },
  bankReconciliationClosing: {
    en: 'bank reconciliation (closing)',
    sw: 'upatanisho wa benki (kufunga)',
  },
  bankReconciliationCatalogue: {
    en: 'bank reconciliation (catalogue)',
    sw: '(orodha) ya upatanisho wa benki',
  },
  budgetHierarchies: {
    en: 'budget hierarchies',
    sw: 'muundo wa bajeti',
  },
  budgetAppropriation: {
    en: 'budget appropriation',
    sw: 'idhini ya bajeti',
  },
  budgetSupplements: {
    en: 'budget supplements',
    sw: 'nyongeza ya bajeti',
  },
  batchTotal: {
    en: 'batch total',
    sw: 'jumla ya kundi',
  },
  basicIncomeFormula: {
    en: 'basic income formula',
    sw: 'fomula ya mapato ya msingi',
  },
  basicIncome: {
    en: 'basic income',
    sw: 'mapato ya msingi',
  },
  beneficiaryProduct: {
    en: 'beneficiary product',
    sw: 'bidhaa ya mpokeaji',
  },
  'bouncedCheques(last12Months)': {
    en: 'bounced cheques (last 12 months)',
    sw: 'cheki zilizorudishwa (miezi 12 iliyopita)',
  },
  'bouncedCheques(last6Months)': {
    en: 'bounced cheques (last 6 months)',
    sw: 'cheki zilizorudishwa (miezi 6 iliyopita)',
  },
  'bouncedCheques(last3Months)': {
    en: 'bounced cheques (last 3 months)',
    sw: 'cheki zilizorudishwa (miezi 3 iliyopita)',
  },
  'bank(AccountNPA)': {
    en: 'bank (account NPA)',
    sw: 'benki (akaunti NPA)',
  },
  'bank(AccountPerforming)': {
    en: 'bank (account performing)',
    sw: 'benki (akaunti inayofanya kazi)',
  },
  'bank(AccountNPAHistory)': {
    en: 'bank (account NPA history)',
    sw: 'benki (historia ya akaunti NPA)',
  },
  balancesGuaranteed: {
    en: 'balances guaranteed',
    sw: 'baki zilizohakikishwa',
  },
  beneficiaryAccountName: {
    en: 'beneficiary account name',
    sw: 'jina la akaunti ya mpokeaji',
  },
  beneficiaryCustomerName: {
    en: 'beneficiary customer name',
    sw: 'jina la mteja wa mpokeaji',
  },
  benefactorCustomerName: {
    en: 'benefactor customer name',
    sw: 'jina la mteja mfadhili',
  },
  benefactorAccountName: {
    en: 'benefactor account name',
    sw: 'jina la akaunti ya mfadhili',
  },
  benefactor: {
    en: 'benefactor',
    sw: 'mfadhili',
  },
  batchStatus: {
    en: 'batch status',
    sw: 'hali ya kundi',
  },
  batchedBy: {
    en: 'batched by',
    sw: 'kundiwa na',
  },
  bypassVerification: {
    en: 'bypass verification',
    sw: 'pita uthibitisho',
  },
  bypassAudit: {
    en: 'bypass audit',
    sw: 'pita ukaguzi',
  },
  beneficiaryAccount: {
    en: 'beneficiary account',
    sw: 'akaunti ya mpokeaji',
  },
  benefactorAccount: {
    en: 'benefactor account',
    sw: 'akaunti ya mfadhili',
  },
  bulkMessageBatches: {
    en: 'bulk message batches',
    sw: 'gharama za ujumbe mwingi',
  },
  body: {
    en: 'body',
    sw: 'mwili',
  },
  'benefactorRef_1(account#)': {
    en: ' benefactor account number',
    sw: ' nambari ya akaunti ya mfadhili',
  },
  'benefactorRef_2(membership#)': {
    en: 'benefactor membership no.',
    sw: 'nambari ya uanachama ya mfadhili',
  },
  'benefactorRef_3(personalFile#)': {
    en: 'benefactor personal file number.',
    sw: 'nambari ya faili la kibinafsi ya mfadhili',
  },
  'beneficiaryRef_1(account#)': {
    en: 'beneficiary account number',
    sw: 'nambari ya akaunti ya mnufaika',
  },
  'beneficiaryRef_2(membership#)': {
    en: 'beneficiary membership no.',
    sw: 'nambari ya uanachama ya mnufaika',
  },
  'beneficiaryRef_3(personalFile#)': {
    en: 'beneficiary personal file number.',
    sw: 'nambari ya faili la kibinafsi ya mnufaika',
  },
  beneficiaryChartOfAccountName: {
    en: 'beneficiary g/l name',
    sw: 'jina la akaunti ya mnufaika',
  },
  balancesSummary: {
    en: 'balances summary',
    sw: 'muhtasari wa baki',
  },
  bypassIprsKYCValidation: {
    en: 'bypass iprs kyc validation',
    sw: 'pita uthibitisho wa iprs kyc',
  },
  bypassMpesaKYCValidation: {
    en: 'bypass mpesa kyc validation',
    sw: 'pita uthibitisho wa mpesa kyc',
  },
  borneBy: {
    en: 'borne by',
    sw: 'inayobebwa na',
  },
  banking: {
    en: 'banking',
    sw: 'benki',
  },
  bankLookup: {
    en: 'bank lookup',
    sw: 'utafutaji wa benki',
  },
  bankBranchLookup: {
    en: 'bank branch lookup',
    sw: 'utafutaji wa tawi la benki',
  },
  benefactorAccountNumber: {
    en: 'benefactor account number',
    sw: 'nambari ya akaunti ya mfadhili',
  },
  benefactorMembershipNumber: {
    en: 'benefactor membership number',
    sw: 'nambari ya uanachama ya mfadhili',
  },
  benefactorPersonalFileNumber: {
    en: 'benefactor personal file number',
    sw: 'nambari ya faili la kibinafsi ya mfadhili',
  },
  benefactorName: {
    en: 'benefactor name',
    sw: 'jina la mfadhili',
  },
  beneficiaryAccountNumber: {
    en: 'beneficiary account number',
    sw: 'nambari ya akaunti ya mnufaika',
  },
  bankersChequeBookLookup: {
    en: 'bankers cheque book lookup',
    sw: 'utafutaji wa kitabu cha cheki cha mabenki',
  },
  bankedBy: {
    en: 'banked by',
    sw: 'imehifadhiwa na',
  },
  bankedDate: {
    en: 'banked date',
    sw: 'tarehe ya kuhifadhiwa',
  },
  'bankAccount#': {
    en: 'bank account #',
    sw: 'akaunti ya benki #',
  },
  businessGroupEmployer: {
    en: 'business/group/employer',
    sw: 'biashara/kikundi/mwajiri',
  },
  billReference: {
    en: 'bill reference',
    sw: 'kumbukumbu ya bili',
  },
  badgeImageNumber: {
    en: 'badge image number',
    sw: 'nambari ya picha ya nembo',
  },
  badgeExpiryDate: {
    en: 'badge expiry date',
    sw: 'tarehe ya muda wa nembo',
  },
  badge: {
    en: 'badge',
    sw: 'nembo',
  },
  badgePicture: {
    en: 'badge picture',
    sw: 'picha ya nembo',
  },
  badgeNumber: {
    en: 'badge number',
    sw: 'nambari ya nembo',
  },
  beginningBookValue: {
    en: 'beginning book value',
    sw: 'thamani ya mwanzo',
  },
  badgeImage: {
    en: 'badge image',
    sw: 'picha ya nembo',
  },
  bulkMessageBatch: {
    en: 'bulk message batch',
    sw: 'gharama za ujumbe mwingi',
  },
  bankLinkageIsLocked: {
    en: 'bank linkage is locked',
    sw: 'kiunganishi cha benki kimefungwa',
  },
  bankLinkage: {
    en: 'bank linkage',
    sw: 'kiunganishi cha benki',
  },
}

export default _b
