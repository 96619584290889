/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
/* eslint-disable */
import React from 'react'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const UsersInRoleACL = ({ usersInRoleAcl }) => {
  const columns = [
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('applicationEmail'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    { label: localize('name'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r.customerPersonalFileNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r.designationDescription}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('department'),
      template: r => <>{r.departmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r.employeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r.bloodGroupDescription}</>,
    },
    {
      label: localize('NSSF'),
      template: r => <>{r.nationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('NHIF'),
      template: r => <>{r.nationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r.customerPersonalIdentificationNumber}</>,
    },
    {
      label: localize('hireDate'),
      template: r => <>{formatDate(r.hireDate, true)}</>,
    },
    {
      label: localize('releaseDate'),
      template: r => <>{formatDate(r.releaseDate, true)}</>,
    },
    {
      label: localize('inactivityTimeout'),
      template: r => <>{r.inactivityTimeout}</>,
    },
    {
      label: localize('customSystemInitializationTime'),
      template: r => <>{r.timeDurationStartTime}</>,
    },
    {
      label: localize('customSystemLockTime'),
      template: r => <>{r.timeDurationEndTime}</>,
    },
    {
      label: localize('enforceSystemInitialization/LockTime') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.enforceSystemInitializationLockTime?.toString()}
        </span>
      ),
    },
    {
      label: localize('printingMode'),
      template: r => <>{r.printingModeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('onlineNotificationsEnabled') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.onlineNotificationsEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceBiometricsForLogin') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.enforceBiometricsForLogin?.toString()}
        </span>
      ),
    },
    {
      label: localize('applicationCreationDate'),
      template: r => <>{formatDate(r.applicationCreationDate)}</>,
    },
    {
      label: localize('applicationIsApproved') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.applicationIsApproved?.toString()}
        </span>
      ),
    },
    {
      label: localize('applicationIsLockedOut') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.applicationIsLockedOut?.toString()}
        </span>
      ),
    },
    {
      label: localize('applicationLastActivityDate'),
      template: r => <>{formatDate(r.applicationLastActivityDate)}</>,
    },
    {
      label: localize('applicationLastLockoutDate'),
      template: r => <>{formatDate(r.applicationLastLockoutDate)}</>,
    },
    {
      label: localize('applicationLastLoginDate'),
      template: r => <>{formatDate(r.applicationLastLoginDate)}</>,
    },
    {
      label: localize('applicationLastPasswordChangedDate'),
      template: r => <>{formatDate(r.applicationLastPasswordChangedDate)}</>,
    },
  ]

  return (
    <>
      <div className="row col-12 mt-2">
        <SimpleTable columns={columns} data={usersInRoleAcl?.result} />
      </div>
    </>
  )
}
