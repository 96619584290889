/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { CreateLoanPurposesModal } from './CreateLoanPurposesModal'
import { EditLoanPurposesModal } from './EditLoanPurposesModal'
import { useEffect, useState } from 'react'
import { postData, putData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function LoanPurposeModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedLoanPurpose,
  setSelectedLoanPurpose,
}) {
  const [isBusy, setIsBusy] = useState()

  async function handleSubmit() {
    if (mode === 'add') {
      return addLoanPurpose()
    }
    if (mode === 'edit') {
      return editLoanPurpose()
    }
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  const addLoanPurpose = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/LoanPurpose/add-loan-purpose',
      {
        ...selectedLoanPurpose,
      },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire(
        'Add Loan Purpose',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const editLoanPurpose = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/LoanPurpose/update-loan-purpose',
      {
        ...selectedLoanPurpose,
      },
      false
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire(
        'Edit Loan Purpose',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('loanPurpose')}
          handleAction={handleSubmit}
          disableCreate={isBusy}
          handleClose={onHideModal}
          modalClassName={'modal-xl'}
        >
          {mode === 'add' && (
            <CreateLoanPurposesModal
              isBusy={isBusy}
              selectedLoanPurpose={selectedLoanPurpose}
              setSelectedLoanPurpose={setSelectedLoanPurpose}
            />
          )}
          {mode === 'edit' && (
            <EditLoanPurposesModal
              isBusy={isBusy}
              selectedLoanPurpose={selectedLoanPurpose}
              setSelectedLoanPurpose={setSelectedLoanPurpose}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default LoanPurposeModal
