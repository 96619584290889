/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const LeaveTypeModal = ({
  mode,
  selectedLeaveType,
  setSelectedLeaveType,
  closeModal,
  loadTableData,
}) => {
  const [showError, setShowError] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/LeaveType/update',
            selectedLeaveType || {
              description: '',
            },
            false
          )
        : await postData(
            '/LeaveType/create',
            selectedLeaveType || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Leave Types', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'leaveType'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="isLeaveTypeDeductible"
              onCheck={e => {
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  isDeductible: e,
                })
              }}
              checked={selectedLeaveType?.isDeductible}
            />
            <label htmlFor="isLeaveTypeDeductible" className="text-capitalize">
              {localize('isDeductible')}?
            </label>
            <div className="mx-3"></div>
            <Checkbox
              id="cLeaveTypeIsLocked"
              onCheck={e => {
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  isLocked: e,
                })
              }}
              checked={selectedLeaveType?.isLocked}
            />
            <label htmlFor="cLeaveTypeIsLocked" className="text-capitalize">
              {localize('isLocked')}?
            </label>
            <div className="mx-3"></div>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row  g-3">
            <div
              className="with-validation d-flex align-items-center col-md-12"
              validation-message={
                globalValidationErrors['Description']?.message
              }
            >
              <label className="text-capitalize col-2">
                {localize('name')}
              </label>
              <input
                defaultValue={selectedLeaveType?.description}
                type="text"
                className="form-control"
                onChange={e => {
                  clearValidation('Description')
                  setSelectedLeaveType({
                    ...selectedLeaveType,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center col-md-12">
              <label className="text-capitalize col-2">
                {localize('category')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{
                  width: '100%',
                }}
                enumsKey="LeaveDaysCategory"
                value={selectedLeaveType?.daysCategory}
                onChange={e =>
                  setSelectedLeaveType({
                    ...selectedLeaveType,
                    daysCategory: e.value,
                  })
                }
              />
            </div>
            <div
              className="with-validation d-flex align-items-center col-md-12"
              validation-message={
                globalValidationErrors['MaximumDays']?.message
              }
            >
              <label className="text-capitalize col-2">
                {localize('maximumDays')}
              </label>

              <MaskNumber
                defaultMaskValue={selectedLeaveType?.maximumDays}
                className={`
                    ${showError && 'border border-danger'}
                     `}
                onMaskNumber={e => {
                  if (Number(e.target.value) > 100) {
                    setShowError(true)
                  } else {
                    setShowError(false)
                  }
                  clearValidation('MaximumDays')
                  setSelectedLeaveType({
                    ...selectedLeaveType,
                    maximumDays: e.target.value,
                  })
                }}
                numeralThousandsGroupStyle="none"
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default LeaveTypeModal
