/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../../Helpers/webApi'

function CreditTypes({ selected, onSelect, readOnly }) {
  const [creditTypes, setCreditTypes] = useState([])
  const [ticked, setTicked] = useState(selected)

  useEffect(() => {
    loadCreditTypes()
  }, [])

  useEffect(() => {
    if (!readOnly) onSelect(ticked)
  }, [ticked])

  async function loadCreditTypes() {
    const { success, data } = await getData('/creditType/find-credit-types')
    if (success) {
      setCreditTypes(data?.result || [])
    }
  }

  async function itemClick(cType, value) {
    if (value) {
      let found = ticked.find(c => c.id === cType.id)
      if (!found) {
        setTicked([...ticked, cType])
      }
    } else {
      setTicked([...ticked.filter(c => c.id !== cType.id)])
    }
  }
  return (
    <div style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
      {creditTypes.map(c => (
        <CreditType
          creditType={c}
          selected={ticked.find(s => s.id === c.id)}
          onCheck={itemClick}
          disabled={readOnly}
        />
      ))}
    </div>
  )
}

function CreditType({ creditType, selected, onCheck, disabled }) {
  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Checkbox
          checked={selected}
          id={`ctype_${creditType.id}`}
          onCheck={v => {
            onCheck(creditType, v)
          }}
          disabled={disabled}
        />
        <label
          htmlFor={`ctype_${creditType.id}`}
          className={`text-capitalize fw-normal ${
            selected ? 'fw-bold text-primary' : ''
          }`}
        >
          {creditType.description}
        </label>
      </div>
    </>
  )
}

export default CreditTypes
