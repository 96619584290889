/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import SimpleTableWithSearchAndPaging from '../../../../Components/Tables/SimpleTableWithSearchAndPaging'
import localize from '../../../../Global/Localize'
import {
  formatDate,
  getLastDateOfMonth,
  getTodaysDate,
} from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import EditCostDistributionModal from './EditCostDistributionModal'
import ViewCostDistributions from './ViewCostDistributions'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

const initialRuleData = {
  durationStartDate: getTodaysDate(),
  durationEndDate: getLastDateOfMonth(),
}

function CostDistributionRules() {
  const distributionColumns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.durationStartDate, true)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.durationEndDate, true)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const rulesColumns = [
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    { label: localize('name'), template: r => <>{r?.description}</> },
    { label: localize('percentage'), template: r => <>{r?.percentage}%</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [selectedRule, setSelectedRule] = useState(initialRuleData)
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [rulesData, setRulesData] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [isFetchingRules, setIsFetchingRules] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  useEffect(() => {
    loadDistributions()
  }, [refresh, reqParams])

  useEffect(() => {
    loadRules()
  }, [selectedRule])

  async function loadRules() {
    setIsFetchingRules(true)
    setRulesData(null)
    const { success, data } = await getData(
      '/costdistributionrule/find-cost-distributions-by-cost-distribution-rule-id',
      { costDistributionRuleId: selectedRule?.id },
      false
    )
    if (success) {
      setIsFetchingRules(false)
      setRulesData(data?.result)
    }

    setIsFetchingRules(false)
  }

  async function loadDistributions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/costdistributionrule/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    }

    setIsBusy(false)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('GLAccounts'),
          localize('costDistributionRules'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white" style={{ position: 'relative' }}>
          <>
            <div className="row mb-2">
              <div className="col-md-4">
                <div className="mb-3">
                  <AddNewButton
                    handleClickAddButton={() => {
                      setSelectedRule(initialRuleData)
                      setModalMode('add')
                    }}
                  />
                </div>
              </div>
            </div>
            <SimpleTableWithSearchAndPaging
              data={data || []}
              columns={distributionColumns}
              onSearch={s => setReqParams({ ...reqParams, filterText: s })}
              searchString={reqParams.filterText}
              onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
              onPageSizeChanged={s =>
                setReqParams({ ...reqParams, pageSize: s })
              }
              isBusy={isBusy}
              canClickOnce
              onSelect={() => {}}
              onClickOnce={r => setSelectedRule(r)}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setSelectedRule(r)
                      setModalMode('edit')
                    }}
                  >
                    <i className="uil uil-edit-alt"></i>
                    <span className="text-capitalize">{localize('edit')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setModalMode('costDistributions')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span className="text-capitalize">
                      {localize('costDistributions')}
                    </span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleViewAuditLogs(r)
                    }}
                  >
                    <i className="uil uil-server"></i>
                    <span>{localize('viewAuditLogs')}</span>
                  </div>
                </>
              )}
              pageSize={reqParams.pageSize}
              currentPage={reqParams.pageIndex}
            />
          </>
        </div>
      </div>

      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}

      {modalMode === 'costDistributions' && (
        <ViewCostDistributions
          handleClose={handleCloseModal}
          mode={modalMode}
          costDistributionsTableData={rulesData}
          costDistributionsTableColumns={rulesColumns}
          title={selectedRule?.description}
          isBusy={isFetchingRules}
        />
      )}

      {(modalMode === 'add' || modalMode === 'edit') && (
        <EditCostDistributionModal
          mode={modalMode}
          rule={selectedRule}
          close={handleCloseModal}
          refresh={() => setRefresh(!refresh)}
        />
      )}
    </>
  )
}

export default CostDistributionRules
