/** @format */

import ChannelManagement from '../../Pages/AlternateChannelManagement/Operations/ChannelManagement/ChannelManagement'
import AgencyAndWebPortalRequest from '../../Pages/AlternateChannelManagement/Operations/ChannelRequestLog/AgencyBankingAndWebPortalRequest/AgencyAndWebPortalRequest'
import MobileBankingRequests from '../../Pages/AlternateChannelManagement/Operations/ChannelRequestLog/MobileBankingRequests/MobileBankingRequests'
import ChannelsRegister from '../../Pages/AlternateChannelManagement/Operations/ChannelsRegister/ChannelsRegister'
import DebitCardsRegister from '../../Pages/AlternateChannelManagement/Operations/DebitCardsRegister/DebitCardsRegister'
import { AlternateChannelConfiguration } from '../../Pages/AlternateChannelManagement/Setup/AlternateChannelConfiguration'
import { MessageTypeIndicators } from '../../Pages/AlternateChannelManagement/Setup/MessageTypeIndicators'
import AlternateChannelActivation from '../../Pages/AlternateChannelManagement/Operations/ChannelActivationRequests/AlternateChannelActivation'
import ChannelActivationRequestsArchived from '../../Pages/AlternateChannelManagement/Operations/ChannelActivationRequestsArchived/ChannelActivationRequestsArchived'
import ChannelServiceEnquiries from '../../Pages/AlternateChannelManagement/Operations/ChannelServiceEnquiries/ChannelServiceEnquiries'
import ChannelReconciliationPeriods from '../../Pages/AlternateChannelManagement/Operations/Channel Reconciliation/Periods/ChannelReconciliationPeriods'
import ChannelReconciliationProcessing from '../../Pages/AlternateChannelManagement/Operations/Channel Reconciliation/Processing/ChannelReconciliationProcessing'
import ChannelReconciliationClosing from '../../Pages/AlternateChannelManagement/Operations/Channel Reconciliation/Closing/ChannelReconciliationClosing'
import ChannelReconciliationCatalogue from '../../Pages/AlternateChannelManagement/Operations/Channel Reconciliation/Catalogue/ChannelReconciliationCatalogue'
import PointOfSaleTerminals from '../../Pages/AlternateChannelManagement/Setup/PointOfSaleTerminals/PointOfSaleTerminals'
import MobileLoans from '../../Pages/AlternateChannelManagement/Setup/MobileLoans/MobileLoans'

const alternateChannelsMenus = [
  {
    iconClass: 'uil uil-cell',
    label: 'alternateChannelManagement',
    code: 8,
    setup: [
      {
        label: 'globalConfiguration',
        component: <AlternateChannelConfiguration />,
        code: 13001,
        parentCode: 8,
      },
      {
        label: 'posTerminals',
        component: <PointOfSaleTerminals />,
        code: 13002,
        parentCode: 8,
      },
      {
        label: 'messageTypeIndicators',
        component: <MessageTypeIndicators />,
        code: 13003,
        parentCode: 8,
      },
      {
        label: 'mobileLoans',
        component: <MobileLoans />,
        code: 13004,
        parentCode: 8,
      },
    ],
    operations: [
      {
        label: 'channelManagement',
        code: 13500,
        parentCode: 8,
        component: <ChannelManagement />,
      },
      {
        label: 'channelsRegister',
        code: 13501,
        parentCode: 8,
        component: <ChannelsRegister />,
      },
      {
        label: 'debitCardsRegister',
        code: 13502,
        parentCode: 8,
        component: <DebitCardsRegister />,
      },
      {
        label: 'channelActivationRequests',
        code: 13503,
        parentCode: 8,
        component: <AlternateChannelActivation />,
      },
      {
        label: 'channelActivationRequestsArchived',
        code: 13504,
        parentCode: 0,
        component: <ChannelActivationRequestsArchived />,
      },
      {
        label: 'channelServiceEnquiries',
        code: 13505,
        parentCode: 8,
        component: <ChannelServiceEnquiries />,
      },
      {
        label: 'channelReconciliation',
        children: [
          {
            label: 'periods',
            component: <ChannelReconciliationPeriods />,
            code: 13507,
            parentCode: 8,
          },
          {
            label: 'processing',
            component: <ChannelReconciliationProcessing />,
            code: 13507,
            parentCode: 8,
          },
          {
            label: 'closing',
            component: <ChannelReconciliationClosing />,
            code: 13507,
            parentCode: 8,
          },
          {
            label: 'catalogue',
            component: <ChannelReconciliationCatalogue />,
            code: 13507,
            parentCode: 8,
          },
        ],
      },
      {
        label: 'channelRequestsLog',
        children: [
          {
            label: 'agencyBankingWebPortalRequests',
            code: 13516,
            parentCode: 0,
            component: <AgencyAndWebPortalRequest />,
          },
          {
            label: 'mobileBankingRequests',
            code: 13515,
            parentCode: 0,
            component: <MobileBankingRequests />,
          },
        ],
      },
    ],
  },
]

export default alternateChannelsMenus
