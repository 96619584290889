/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { formatDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import { getData } from '../../../../Helpers/webApi'
import Pager from '../../../../Components/Tables/Pager'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import CreateTempEmployee from './CreateTempEmployee'

function TemporaryEmployees() {
  const groupingColumns = {
    0: 'branchDescription',
    1: 'designationDescription',
    2: 'departmentDescription',
    3: 'bloodGroupDescription',
    4: 'individualGenderDescription',
    5: 'individualMaritalStatusDescription',
    6: 'individualNationalityDescription',
    7: 'individualSalutationDescription',
    8: 'isLocked',
    9: 'employmentStatusDescription',
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [employees, setEmployees] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'branch',
    column: groupingColumns[0],
    value: 0,
  })
  const [reqParams, setReqParams] = useState({
    temporaryEmployeeFilter: 1,
    pageSize: 10,
    pageIndex: 0,
    filterText: '',
  })

  const tempEmployeeColumns = [
    { label: localize('name'), template: r => <>{r?.fullName}</> },
    {
      label: localize('nationality'),
      template: r => <>{r?.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.individualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.designationDescription}</>,
    },
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('department'),
      template: r => <>{r?.departmentDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.bloodGroupDescription}</>,
    },
    {
      label: localize('nssf') + ' #',
      template: r => <>{r?.nationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('nhif') + ' #',
      template: r => <>{r?.nationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('pin') + ' #',
      template: r => <>{r?.personalIdentificationNumber}</>,
    },
    {
      label: localize('employmentStatus'),
      template: r => <>{r?.employmentStatusDescription}</>,
    },
    {
      label: localize('hireDate'),
      template: r => <>{formatDate(r?.hireDate, true)}</>,
    },
    {
      label: localize('releaseDate'),
      template: r => <>{formatDate(r?.releaseDate, true)}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, true)}</>,
    },
  ]

  useEffect(() => {
    loadTemporaryEmployees()
    // eslint-disable-next-line
  }, [reqParams?.pageIndex, reqParams?.pageSize, refresh])

  async function loadTemporaryEmployees() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/temporaryEmployee/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) setEmployees(data)
    setIsBusy(false)
  }

  return (
    <div>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('employees'),
          localize('temporaryEmployees'),
        ]}
      />
      <div className="card card-body bg-white">
        {isBusy ? (
          <Loader message="Loading Temporary Employees" />
        ) : (
          <>
            <div className="d-flex mb-2">
              <AddNewButton
                handleClickAddButton={() => {
                  setSelectedEmployeeId(null)
                  setModalMode('add')
                }}
              />
            </div>
            <div className="ms-2 d-flex align-items-center justify-content-between mb-2">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={r => setReqParams(r)}
              />
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('groupBy')}
                </label>
                <EnumsServerCombo
                  enumsKey="TemporaryEmployeePropertyGroup"
                  value={groupBy?.value}
                  onChange={({ label, value }) =>
                    setGroupBy({ label, value, column: groupingColumns[value] })
                  }
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  value={reqParams?.temporaryEmployeeFilter}
                  onChange={({ value }) =>
                    setReqParams({
                      ...reqParams,
                      temporaryEmployeeFilter: value,
                    })
                  }
                  enumsKey="TemporaryEmployeeFilter"
                />
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setRefresh(!refresh)
                  }}
                >
                  <input
                    type="search"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }
                    className="form-control"
                  />
                </form>
                <button
                  className="btn btn-primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <GroupedTable
              columns={tempEmployeeColumns}
              data={employees?.pageCollection || []}
              groupBy={groupBy}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setModalMode('view')
                      setSelectedEmployeeId(r?.id)
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span className="text-capitalize">{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setModalMode('edit')
                      setSelectedEmployeeId(r?.id)
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span className="text-capitalize">{localize('edit')}</span>
                  </div>
                </>
              )}
            />
            <Pager
              itemsCount={employees?.itemsCount || 0}
              pageSize={reqParams?.pageSize}
              pageIndex={reqParams?.pageIndex}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />{' '}
          </>
        )}
        {modalMode === 'add' && !selectedEmployeeId && (
          <CreateTempEmployee
            close={() => {
              setModalMode(null)
              setSelectedEmployeeId(null)
            }}
            modalMode={modalMode}
            temporaryEmployeeId={null}
            refreshEmployees={() => setRefresh(!refresh)}
          />
        )}
        {modalMode === 'edit' && selectedEmployeeId && (
          <CreateTempEmployee
            close={() => {
              setModalMode(null)
              setSelectedEmployeeId(null)
            }}
            modalMode={modalMode}
            temporaryEmployeeId={selectedEmployeeId}
            refreshEmployees={() => setRefresh(!refresh)}
          />
        )}
        {modalMode === 'view' && selectedEmployeeId && (
          <CreateTempEmployee
            close={() => {
              setModalMode(null)
              setSelectedEmployeeId(null)
            }}
            modalMode={modalMode}
            temporaryEmployeeId={selectedEmployeeId}
          />
        )}
      </div>
    </div>
  )
}

export default TemporaryEmployees
