/** @format */

import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import BankLinkageLookup from '../../../../../../Components/Lookup/BankLinkageLookup'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'

const ExternalChequeBanking = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [cheques, setCheques] = useState([])
  const [bank, setBank] = useState({})
  const [selectedCheques, setSelectedCheques] = useState([])
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    // ! TODO :MY - change this to true 03/10/2023
    filterByAuthorizedBranches: false,
  })

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedCheques.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedCheques([...selectedCheques, r])
            } else {
              setSelectedCheques(selectedCheques.filter(s => s?.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('branch'),
      template: item => <>{item?.branchDescription}</>,
    },
    {
      label: localize('tellerName'),
      template: item => <>{item?.tellerDescription}</>,
    },
    {
      label: localize('chequeNumber'),
      template: item => <>{item?.number}</>,
    },
    {
      label: localize('amount'),
      template: item => <>{formatCurrency(item?.amount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('drawer'),
      template: item => <>{item?.drawer}</>,
    },
    {
      label: localize('drawerBank'),
      template: item => <>{item?.drawerBank}</>,
    },
    {
      label: localize('drawerBankBranch'),
      template: item => <>{item?.drawerBankBranch}</>,
    },
    {
      label: localize('writeDate'),
      template: item => <>{formatDate(item?.writeDate, true)}</>,
    },
    {
      label: localize('chequeType'),
      template: item => <>{item?.chequeTypeDescription}</>,
    },
    {
      label: localize('maturityDate'),
      template: item => <>{formatDate(item?.maturityDate, true)}</>,
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: item => <>{item?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: item => <>{item?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setMode(null)
  }

  const fetchExternalUnBankedCheques = async () => {
    setIsBusy(true)

    const url =
      '/ExternalCheque/find-unbanked-external-cheques-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCheques(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleUpdateCheque = async () => {
    setIsBusyUpdating(true)

    const url = '/ExternalCheque/bank-external-cheque'

    const { success } = await postData(
      url,
      {
        externalCheques: selectedCheques,
        bankLinkage: bank,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setIsBusyUpdating(false)
      showNotification('External Cheques Banking', 'success')
      handleClose()
      setSelectedCheques([])
      setSelectAll(false)
      fetchExternalUnBankedCheques()
    }

    setIsBusyUpdating(false)
  }

  const handleSelectBank = bank => {
    if (bank?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected Bank is locked',
      })

    setBank(bank)
  }

  const handleClickUpdateCheque = () => {
    if (!bank?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a bank',
      })

    if (selectedCheques.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select at least one cheque',
      })

    setMode('update')
  }

  useEffect(() => {
    fetchExternalUnBankedCheques()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleAction={handleUpdateCheque}
          handleClose={handleClose}
          modalTitle="externalChequesBanking"
          disableAction={isBusyUpdating}
          disableClose={isBusyUpdating}
        >
          {isBusyUpdating ? <Loader /> : <p>Do you want to proceed?</p>}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('external'),
          localize('externalChequesBanking'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="mb-3 row">
                <div className="col-md-4 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('bank')}
                  </label>
                  <BankLinkageLookup
                    displayValue={bank?.bankName}
                    onSelect={handleSelectBank}
                  />
                </div>

                <div className="col-md-4 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('branch')}
                  </label>
                  <input
                    type="text"
                    disabled
                    value={bank?.bankBranchName}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('glAccount')}
                  </label>
                  <input
                    type="text"
                    disabled
                    value={bank?.chartOfAccountName}
                    className="form-control"
                  />
                </div>
              </div>
              <hr />

              <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                <div className="d-flex align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>

                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchExternalUnBankedCheques()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchExternalUnBankedCheques()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <SimpleTable data={cheques?.pageCollection} columns={columns} />
              <Pager
                itemsCount={cheques.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />

              <div className="mt-2 rounded bg-light p-2 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id={`selectAll-cheques-${mode}`}
                    checked={selectAll}
                    onCheck={c => {
                      setSelectAll(c)
                      if (c) {
                        setSelectedCheques(cheques?.pageCollection)
                      } else {
                        setSelectedCheques([])
                      }
                    }}
                  />

                  <label
                    htmlFor={`selectAll-cheques-${mode}`}
                    className="text-capitalize ms-2"
                  >
                    {localize('selectAll')}
                  </label>
                </div>

                <button
                  className="btn btn-success"
                  onClick={handleClickUpdateCheque}
                >
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default ExternalChequeBanking
