/** @format */

import localize from '../../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'

export const VoucherDetailsGeneralLedger = ({
  setJournalVoucherEntryModel,
  journalVoucherEntryModel,
  refreshVoucherJournalDetails,
}) => {
  const [
    voucherDetailsGeneralLedgerModel,
    setVoucherDetailsGeneralLedgerModel,
  ] = useState(null)

  useEffect(() => {
    return () => {
      setVoucherDetailsGeneralLedgerModel(null)
    }
  }, [refreshVoucherJournalDetails])

  const handleOnAddVoucherDetailsGeneralLedgerChartOfAccount = r => {
    setVoucherDetailsGeneralLedgerModel({
      ...voucherDetailsGeneralLedgerModel,
      chartOfAccountDescription: r.description,
      chartOfAccountId: r.id,
      costDistributionRuleDescription: r.costDistributionRuleDescription,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      GeneralLedgerAccount: {
        ...journalVoucherEntryModel?.GeneralLedgerAccount,
        chartOfAccountDescription: r.description,
        chartOfAccountId: r.id,
        code: r.code,
        costDistributionRuleDescription: r.costDistributionRuleDescription,
      },
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        customerAccountTypeTargetProductChartOfAccountName: r.name,
        chartOfAccountDescription: r.description,
        chartOfAccountId: r.id,
        code: r.code,
        costDistributionRuleDescription: r.costDistributionRuleDescription,
      },
    })
  }

  const handleOnAddVoucherDetailsGeneralLedgerCostCenter = r => {
    setVoucherDetailsGeneralLedgerModel({
      ...voucherDetailsGeneralLedgerModel,
      costCenterDescription: r.description,
      costCenterId: r.id,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      GeneralLedgerAccount: {
        ...journalVoucherEntryModel?.GeneralLedgerAccount,
        costCenterDescription: r.description,
        costCenterId: r.id,
      },
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        costCenterDescription: r.description,
        costCenterId: r.id,
      },
    })
  }

  const handleOnAddVoucherDetailsGeneralLedgerMaskNumber = r => {
    setVoucherDetailsGeneralLedgerModel({
      ...voucherDetailsGeneralLedgerModel,
      amount: r.target.value,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      GeneralLedgerAccount: {
        ...journalVoucherEntryModel?.GeneralLedgerAccount,
        generalLedgerAccountAmount: r.target.value,
      },
    })
  }

  const handleOnAddVoucherDetailsGeneralLedgerRemarks = e => {
    setVoucherDetailsGeneralLedgerModel({
      ...voucherDetailsGeneralLedgerModel,
      remarks: e.target.value,
    })
    setJournalVoucherEntryModel({
      ...journalVoucherEntryModel,
      GeneralLedgerAccount: {
        ...journalVoucherEntryModel?.GeneralLedgerAccount,
        remarks: e.target.value,
      },
      CustomerInformation: {
        ...journalVoucherEntryModel?.CustomerInformation,
        remarks: e.target.value,
      },
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('chartOfAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={
              voucherDetailsGeneralLedgerModel?.chartOfAccountDescription
            }
            onSelect={r => {
              handleOnAddVoucherDetailsGeneralLedgerChartOfAccount(r)
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costDistributionRule')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              voucherDetailsGeneralLedgerModel?.costDistributionRuleDescription
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costCenter')}
          </label>
          <CostCentreLookup
            displayValue={
              voucherDetailsGeneralLedgerModel?.costCenterDescription
            }
            onSelect={r => {
              handleOnAddVoucherDetailsGeneralLedgerCostCenter(r)
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            defaultMaskValue={voucherDetailsGeneralLedgerModel?.amount}
            onMaskNumber={r => {
              handleOnAddVoucherDetailsGeneralLedgerMaskNumber(r)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            defaultValue={voucherDetailsGeneralLedgerModel?.remarks}
            onChange={e => {
              handleOnAddVoucherDetailsGeneralLedgerRemarks(e)
            }}
          />
        </div>
      </div>
    </>
  )
}
