/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import CustomerAccountByCustomerIdLookup from '../../../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import localize from '../../../../../../../Global/Localize'
import {
  ProductCode,
  customerAccountStatus,
} from '../../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'

const AccountActivationTab = ({
  management,
  setManagement,
  customerAccountInfo,
}) => {
  const [customerAccount, setCustomerAccount] = useState({})

  const handleLookupCustomerAccount = r => {
    if (r?.status === Object.keys(customerAccountStatus)[3])
      return showNotification(
        'Operation not allowed',
        'error',
        'selected account is closed.'
      )

    if (r?.customerAccountTypeProductCode !== Object.keys(ProductCode)[0])
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select a savings account.'
      )

    setCustomerAccount(r)
    setManagement({
      ...management,
      activationFeeCustomerAccountId: r?.id,
    })
  }

  return (
    <>
      <div className="row g-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('remarks')}
            </label>

            <textarea
              className="form-control"
              value={management.remark}
              onChange={({ target }) => {
                setManagement({
                  ...management,
                  remark: target.value,
                })
              }}
            />
          </div>
        </div>

        <div className="col-md-3 d-flex align-items-center">
          <Checkbox
            id={'chargeActivationFees'}
            checked={management?.chargeActionFee}
            onCheck={value => {
              setManagement({
                ...management,
                chargeActionFee: value,
              })
            }}
          />

          <label
            className="text-capitalize ms-2"
            htmlFor={'chargeActivationFees'}
          >
            {localize('chargeActivationFees') + ' ?'}
          </label>
        </div>

        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('activationFeesAccountNumber')}
          </label>

          <CustomerAccountByCustomerIdLookup
            selectedCustomer={{
              id: customerAccountInfo?.customerId,
              fullName: customerAccountInfo?.customerFullName,
            }}
            onSelect={handleLookupCustomerAccount}
            displayValue={customerAccount?.fullAccountNumber}
            productCode={Object.keys(ProductCode)[0]}
            groupingColumn={{
              label: localize('productCode'),
              column: 'customerAccountTypeProductCodeDescription',
            }}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountStatus')}
          </label>

          <input
            disabled
            className="form-control"
            value={customerAccount?.statusDescription}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('productName')}
          </label>

          <input
            disabled
            className="form-control"
            value={customerAccount?.customerAccountTypeTargetProductDescription}
          />
        </div>
      </div>
    </>
  )
}

export default AccountActivationTab
