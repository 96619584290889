/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { alternateChannelType } from '../../../../../../Helpers/constants'
import { getData } from '../../../../../../Helpers/webApi'

const TransactionTypes = ({
  transactionTypeEntries,
  setTransactionTypeEntries,
  setTransaction,
  transaction,
  channel,
  handleAddEntries,
  handleSelectTransactionType,
  handleRemoveTransactionType,
  handleLockUnlockTransactionType,
  selectedEntries,
}) => {
  const [transactionTypeDescription, setTransactionTypeDescription] =
    useState('')
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: 'select ?',
      template: r => (
        <Checkbox
          id={`select${r?.id}-for-transaction-type-in-customize-alternate-channel`}
          checked={selectedEntries?.find(t => t?.id === r?.id)}
          onCheck={value => {
            handleSelectTransactionType(r, value)
          }}
        />
      ),
    },
    {
      label: 'transactionType',
      template: r => r?.transactionTypeDescription,
    },
    {
      label: 'limit',
      template: r => r?.limit,
    },
    {
      label: 'isLocked',
      template: r => r?.isLocked?.toString(),
    },
  ]

  function getDefaultTypeValue() {
    if (
      transactionTypeDescription ===
      'ConfigurableBankToMobileTransactionRequest'
    ) {
      setTransaction({
        ...transaction,
        transactionType: '24',
        transactionTypeDescription: 'Balance Enquiry',
      })
    } else if (
      transactionTypeDescription === 'ConfigurableInterSwitchTransactionType'
    )
      setTransaction({
        ...transaction,
        transactionType: '31',
        transactionTypeDescription: 'Balance Enquiry',
      })
    else if (
      transactionTypeDescription ===
      'ConfigurableSpotCashMobileBankingTransactionType'
    )
      setTransaction({
        ...transaction,
        transactionType: '700',
        transactionTypeDescription: 'Balance Enquiry',
      })
    else if (
      transactionTypeDescription === 'ConfigurableSaccoLinkTransactionRequest'
    )
      setTransaction({
        ...transaction,
        transactionType: '9',
        transactionTypeDescription: 'PesaLink',
      })
  }

  function getDefaultTransactionType() {
    if (channel?.type === alternateChannelType.INTERSWITCH?.toString())
      setTransactionTypeDescription('ConfigurableInterSwitchTransactionType')
    else if (channel?.type === alternateChannelType.PESAPEPE?.toString())
      setTransactionTypeDescription(
        'ConfigurableBankToMobileTransactionRequest'
      )
    else if (channel?.type === alternateChannelType.SPOTCASH?.toString())
      setTransactionTypeDescription(
        'ConfigurableSpotCashMobileBankingTransactionType'
      )
    else if (channel?.type === alternateChannelType.SACCOLINK?.toString())
      setTransactionTypeDescription('ConfigurableSaccoLinkTransactionRequest')
    else if (channel?.type === alternateChannelType.BROKER?.toString())
      setTransactionTypeDescription('ConfigurableBrokerTransactionRequest')
  }

  async function fetchEntryOverrides() {
    setIsBusy(true)

    const url =
      '/AlternateChannelConfiguration/find-alternate-channel-configuration-entry-overrides-by-alternate-channel-id'

    const { data, success } = await getData(
      url,
      {
        alternateChannelConfigurationId: channel?.id,
      },
      false
    )

    if (success) {
      //merge data.result with transactionTypeEntries
      let merged = []
      if (data?.result?.length > 0) {
        merged = data?.result?.map(t => {
          return {
            ...t,
            ...transactionTypeEntries,
          }
        })
      }

      setTransactionTypeEntries(merged)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    getDefaultTransactionType()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.type])

  useEffect(() => {
    getDefaultTypeValue()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionTypeDescription])

  useEffect(() => {
    if (channel?.id) fetchEntryOverrides()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id])

  return (
    <>
      {transactionTypeDescription?.length > 0 && (
        <>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('transactionType')}
              </label>
              <EnumsServerCombo
                sort
                enumsKey={transactionTypeDescription}
                value={transaction?.transactionType}
                customContainerStyle={{ width: '100%' }}
                onChange={e => {
                  setTransaction({
                    ...transaction,
                    transactionType: e?.value,
                    transactionTypeDescription: e?.label,
                  })
                }}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('limit')}
              </label>
              <MaskNumber
                defaultMaskValue={transaction?.limit || 0}
                customContainerStyle={{ width: '100%' }}
                onMaskNumber={e => {
                  setTransaction({
                    ...transaction,
                    limit: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="mt-2 bg-light rounded p-2 d-flex align-items-center justify-content-end gap-3">
            <div className="d-flex align-items-center">
              <Checkbox
                id="isLockedCustomizeAlternateChannel"
                checked={transaction?.isLocked}
                onCheck={value => {
                  setTransaction({
                    ...transaction,
                    isLocked: value,
                  })
                }}
              />

              <label
                className="text-capitalize"
                htmlFor="isLockedCustomizeAlternateChannel"
              >
                {localize('isLocked') + ' '} ?
              </label>
            </div>

            <button className="btn btn-success" onClick={handleAddEntries}>
              {localize('add')}
            </button>
          </div>

          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('transactionTypeEntries')}
            </div>
            {isBusy ? (
              <Loader />
            ) : (
              <SimpleTable columns={columns} data={transactionTypeEntries} />
            )}
            <div className="mt-2 rounded bg-light p-2 gap-3 d-flex align-items-center justify-content-end">
              <button
                onClick={handleRemoveTransactionType}
                className="btn btn-danger"
              >
                {localize('remove')}
              </button>

              <button
                className="btn btn-success"
                onClick={handleLockUnlockTransactionType}
              >
                {localize('unlock') + '/' + localize('unLock')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TransactionTypes
