/** @format */

/* eslint-disable */
import React from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'

function PayrollNumbersTab({ onPayrollRemove, showContextMenu, data }) {
  const columns = [
    {
      label: localize('numberType'),
      template: row => row?.payrollNumberTypeDescription,
    },
    {
      label: localize('reference'),
      template: row => row?.reference,
    },
    {
      label: localize('remarks'),
      template: row => row?.remarks,
    },
    {
      label: localize('modifiedBy'),
      template: row => row?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: row => formatDate(row?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: row => row?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: row => formatDate(row?.createdDate),
    },
  ]

  return (
    <>
      <Tab
        tabItems={[
          {
            label: localize('payrollNumbers'),
            item: (
              <>
                <SimpleTable
                  columns={columns}
                  data={data}
                  contextMenu={r =>
                    showContextMenu && (
                      <>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            onPayrollRemove(r)
                          }}
                        >
                          <i className="uil uil-times-circle" />
                          <span>{localize('remove')}</span>
                        </div>
                      </>
                    )
                  }
                />
              </>
            ),
          },
        ]}
      />
    </>
  )
}

export default PayrollNumbersTab
