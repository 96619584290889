/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../Components/Date/DatePicker'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import Pager from '../../../../Components/Tables/Pager'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { withdrawalNotificationStatus } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import AddCatalogueModal from './AddCatalogueModal/AddCatalogueModal'
import InvalidateCatalogue from './InvalidateCatalogueModal/InvalidateCatalogueModal'
import ViewCatalogueModal from './ViewCatalogue/ViewCatalogue'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function Catalogue() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCatalogue, setSelectedCatalogue] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [withdrawalNotifications, setWithdrawalNotifications] = useState({
    pageCollection: [],
  })
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    []
  )
  const [balance, setBalance] = useState({
    netPartialRefundable: '',
    netLoansBalance: '',
    netRefundable: '',
    netInvestmentsBalance: '',
    backOfficeInvestmentsBalance: '',
    backOfficeLoansBalance: '',
  })
  const [customerLoansGuarantor, setCustomerLoansGuarantor] = useState([])
  const [groupBy, setGroupBy] = useState({
    value: 0,
    column: 'statusDescription',
    label: localize('status'),
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('terminationCategory'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('settlementMode'),
      template: r => <>{r?.withdrawalSettlementModeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r?.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: localize('transactionReference'),
      template: r => <>{r?.transactionReferenceDescription}</>,
    },
    {
      label: localize('terminationRemarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('maturityDate'),
      template: r => formatDate(r?.maturityDate, true),
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approvalDate'),
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: localize('verificationRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verificationDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r?.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r?.settledDate)}</>,
    },
    {
      label: localize('settlementRemarks'),
      template: r => <>{r?.settlementRemarks}</>,
    },

    {
      label: localize('invalidatedBy'),
      template: r => <>{r?.invalidatedBy}</>,
    },
    {
      label: localize('invalidatedDate'),
      template: r => <>{formatDate(r?.invalidatedDate)}</>,
    },
    {
      label: localize('invalidationRemarks'),
      template: r => <> {r?.invalidationRemarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <> {r?.createdBy}</>,
    },
  ]

  const groupingColumns = [
    {
      value: 0,
      column: 'statusDescription',
    },
    {
      value: 1,
      column: 'categoryDescription',
    },
    {
      value: 2,
      column: 'branchDescription',
    },
    {
      value: 3,
      column: 'customerIndividualSalutationDescription',
    },
    {
      value: 4,
      column: 'customerIndividualGenderDescription',
    },
    {
      value: 5,
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      value: 6,
      column: 'customerIndividualNationalityDescription',
    },
    {
      value: 7,
      column: 'customerDutyStationDescription',
    },
    {
      value: 8,
      column: 'customerEmployerDescription',
    },
    {
      value: 9,
      column: 'customerAdministrativeDivisionDescription',
    },
    {
      value: 10,
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      value: 11,
      column: 'transactionReferenceDescription',
    },
  ]

  const loadData = async () => {
    setIsBusy(true)

    const { success, data } = await getData(
      '/WithdrawalNotification/find-withdrawal-notifications-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setWithdrawalNotifications(data)
    }

    setIsBusy(false)
  }

  const getCustomerAccounts = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: selectedCustomer?.customerId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerSavingsAccounts(data.customerSavingsAccounts)
      setCustomerLoanAccounts(data.customerLoanAccounts)
      setCustomerInvestmentAccounts(data.customerInvestmentAccounts)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    }

    setIsBusy(false)
  }

  const getCustomerGuaranteedLoans = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/LoanCase/find-loan-guarantor-by-customer-id',
      {
        customerId: selectedCustomer?.customerId,
        includeOutstandingBalance: true,
        filterClearedLoans: true,
      },
      false
    )
    if (success) {
      setCustomerLoansGuarantor(data?.result)
    }

    setIsBusy(false)
  }

  const handleAddButtonClick = () => {
    setModalMode('add')
    setSelectedCatalogue(null)
    setSelectedCustomer(null)
    setBalance(null)
    setCustomerInvestmentAccounts([])
    setCustomerLoanAccounts([])
    setCustomerSavingsAccounts([])
    setCustomerLoansGuarantor([])
  }

  const handleSelectCatalogue = (notification, mode) => {
    if (
      notification?.status === withdrawalNotificationStatus.INVALIDATED &&
      mode === 'invalidate'
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'Sorry, but a membership termination notification with status Invalidated CANNOT be invalidated!'
      )

    setModalMode(mode)
    setSelectedCustomer(notification)
  }

  const handleClose = () => {
    setModalMode(null)
    setSelectedCatalogue(null)
    setSelectedCustomer(null)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (selectedCustomer?.customerId) {
      getCustomerAccounts()
      getCustomerGuaranteedLoans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer?.customerId])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('membershipWithdrawalNotifications'),
          localize('catalogue'),
        ]}
      />
      {modalMode === ('add' || 'edit') && (
        <AddCatalogueModal
          customerSavingsAccounts={customerSavingsAccounts}
          customerLoanAccounts={customerLoanAccounts}
          customerInvestmentAccounts={customerInvestmentAccounts}
          customerLoansGuarantor={customerLoansGuarantor}
          modalMode={modalMode}
          selectedCatalogue={selectedCatalogue}
          handleClose={handleClose}
          onSelectCustomer={c => {
            setSelectedCustomer({
              ...c,
              customerId: c?.id,
            })
          }}
          balance={balance}
          loadData={loadData}
        />
      )}

      {modalMode === 'view' && (
        <ViewCatalogueModal
          customerSavingsAccounts={customerSavingsAccounts}
          customerLoanAccounts={customerLoanAccounts}
          customerInvestmentAccounts={customerInvestmentAccounts}
          customerLoansGuarantor={customerLoansGuarantor}
          modalMode={modalMode}
          selectedCatalogue={selectedCatalogue}
          handleClose={handleClose}
          customer={selectedCustomer}
          balance={balance}
        />
      )}
      {modalMode === 'invalidate' && (
        <InvalidateCatalogue
          customerSavingsAccounts={customerSavingsAccounts}
          customerLoanAccounts={customerLoanAccounts}
          customerInvestmentAccounts={customerInvestmentAccounts}
          customerLoansGuarantor={customerLoansGuarantor}
          modalMode={modalMode}
          selectedCatalogue={selectedCatalogue}
          handleClose={handleClose}
          notification={selectedCustomer}
          loadData={loadData}
        />
      )}

      <div className="card bg-white rounded ">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-md-3">
              <AddNewButton handleClickAddButton={handleAddButtonClick} />
            </div>
          </div>
          <div className="relative">
            <div className="row mb-3 d-flex justify-content-between align-items-center">
              <div className="col-md-2 col-lg-3 d-flex align-items-center">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              <div className="col-md-2 d-flex align-items-center gap-2">
                <label className="text-capitalize" htmlFor="groupBy">
                  {localize('groupBy')}
                </label>
                <EnumsServerCombo
                  value={groupBy.value}
                  enumsKey="WithdrawalNotificationPropertyGroup"
                  customContainerStyle={{
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                  onChange={({ label, value }) =>
                    setGroupBy({
                      label,
                      value,
                      column: groupingColumns[value]?.column,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-lg-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('dateRange')}
                </label>
                <DatePicker
                  value={{
                    startDate: reqParams.startDate,
                    endDate: reqParams.endDate,
                  }}
                  onSelect={v => {
                    if (v.custom) {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    } else {
                      setReqParams({
                        ...reqParams,
                        customDate: false,
                        dateRequestsFilter: v.value,
                      })
                    }
                  }}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-end gap-2">
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <div className="d-flex align-items-center">
                  <EnumsServerCombo
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        customerFilter: e.value,
                      })
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    value={reqParams.filterText}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                  />
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      loadData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
            </div>
            <GroupedTable
              groupBy={groupBy}
              columns={columns}
              data={withdrawalNotifications.pageCollection}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCatalogue(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCatalogue(r, 'invalidate')
                    }}
                  >
                    <i className="uil-ban"></i>
                    <span>{localize('invalidate')}</span>
                  </div>
                </>
              )}
            />
            <Pager
              itemsCount={withdrawalNotifications.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
        {isBusy && <NonBlockingLoader />}
          </div>
        </div>
      </div>
    </>
  )
}

export default Catalogue
