/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import Checkbox from '../../Checkbox/Checkbox'
import SimpleTable from '../../Tables/SimpleTable'
import { useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import { useEffect } from 'react'
import Loader from '../../Loaders/Loader'

const Substitutes = ({
  selectedSubstitutes,
  handleSelectSubstitute,
  showSelect,
  guarantorSubstitutionRequestId,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [substitutes, setSubstitutes] = useState([])

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedSubstitutes?.find(x => x?.id === r?.id)}
          onCheck={value => {
            handleSelectSubstitute(r, value)
          }}
        />
      ),
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanGuarantorLoanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanGuarantorLoanCaseStatusDescription,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanGuarantorLoanProductDescription,
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanGuarantorLoanCaseLoanPurposeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r?.substituteCustomerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedSubstituteCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.paddedSubstituteCustomerAccountNumber,
    },
    {
      label: localize('identityCardNumber'),
      template: r => r?.substituteCustomerIndividualIdentityCardNumber,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => r?.substituteCustomerAddressAddressLine1,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => r?.substituteCustomerAddressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.substituteCustomerAddressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.substituteCustomerAddressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.substituteCustomerAddressCity,
    },
    {
      label: localize('emailAddress'),
      template: r => r?.substituteCustomerAddressEmail,
    },
    {
      label: localize('landLine'),
      template: r => r?.substituteCustomerAddressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.substituteCustomerAddressMobileLine,
    },
    {
      label: localize('duty') + ' ' + localize('workStation'),
      template: r => r?.substituteCustomerDutyStationDescription,
    },
    {
      label: localize('employer'),
      template: r => r?.substituteCustomerEmployerDescription,
    },
    { label: localize('remarks'), template: r => r?.reference },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const columnsToDisplay = columns?.filter((_x, i) => i !== 0)

  const fetchSubstitutes = async () => {
    setIsBusy(true)
    const url =
      '/LoanGuarantorSubstitutionRequest/find-loan-guarantor-substitution-request-entries-by-loan-guarantor-substitution-request-id'

    const { success, data } = await getData(
      url,
      {
        LoanGuarantorSubstitutionRequestId: guarantorSubstitutionRequestId,
      },
      false
    )

    if (success) {
      setSubstitutes(data?.result)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (guarantorSubstitutionRequestId) fetchSubstitutes()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guarantorSubstitutionRequestId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            data={substitutes}
            columns={showSelect ? columns : columnsToDisplay}
          />
        </>
      )}
    </>
  )
}

export default Substitutes
