/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../Global/Localize'
import CustomerAccountLookup from '../../Lookup/CustomerAccountLookup'
import { formatCurrency } from '../../../Helpers/formatters'
import CustomerAccountsByCustomerIdLookup from '../../Lookup/CustomerAccountsByCustomerIdLookup'
function CustomerLoanAccountPartial({
  onCustomerAccountLookup,
  selectedSourceLoanAccount,
  selectedDestinationLoanAccount,
  message,
  mode = 'none',
  lookupType,
  selectedCustomer,
}) {
  return (
    <>
      {lookupType === 'source' ? (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <CustomerAccountLookup
                displayValue={
                  selectedSourceLoanAccount?.fullAccountNumber || ''
                }
                type="text"
                className="form-control ms-2"
                onSelect={v => {
                  onCustomerAccountLookup(v)
                }}
                productCode={2}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedSourceLoanAccount?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedSourceLoanAccount?.remarks || ''}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={selectedSourceLoanAccount?.customerFullName || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.customerMembershipClassCustomerTypeDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerSerial#')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.paddedCustomerSerialNumber || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.customerEmployerDescription || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identification#')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  selectedSourceLoanAccount?.customerIdentificationNumber || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.formattedCustomerMemberNumber || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.formattedCustomerAccountNumber ||
                  ''
                }
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.customerPersonalFileNumber || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control"
                disabled
                value={
                  Number(selectedSourceLoanAccount?.bookBalance) < 0
                    ? formatCurrency(
                        selectedSourceLoanAccount?.bookBalance,
                        null,
                        null,
                        false
                      )
                    : selectedSourceLoanAccount?.bookBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedSourceLoanAccount?.principalBalance) < 0
                    ? formatCurrency(
                        selectedSourceLoanAccount?.principalBalance,
                        null,
                        null,
                        false
                      )
                    : selectedSourceLoanAccount?.principalBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedSourceLoanAccount?.interestBalance) < 0
                    ? formatCurrency(
                        selectedSourceLoanAccount?.interestBalance,
                        null,
                        null,
                        false
                      )
                    : selectedSourceLoanAccount?.interestBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanClassification')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedSourceLoanAccount?.loanClassificationDescription || ''
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              {selectedCustomer ? (
                <>
                  <label className="col-4 text-capitalize">
                    {localize('fullAccountNumber')}
                  </label>
                  <CustomerAccountsByCustomerIdLookup
                    displayValue={
                      selectedDestinationLoanAccount?.fullAccountNumber || ''
                    }
                    type="text"
                    className="form-control ms-2"
                    onSelect={v => {
                      onCustomerAccountLookup(v)
                    }}
                    selectedCustomer={selectedCustomer}
                    productCode={2}
                  />
                </>
              ) : (
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('fullAccountNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      selectedDestinationLoanAccount?.fullAccountNumber || ''
                    }
                  />
                </div>
              )}
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDestinationLoanAccount?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={selectedDestinationLoanAccount?.remarks || ''}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.customerAccountTypeTargetProductDescription ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={selectedDestinationLoanAccount?.customerFullName || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.customerMembershipClassCustomerTypeDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerSerial#')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.paddedCustomerSerialNumber ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.customerEmployerDescription ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identification#')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  selectedDestinationLoanAccount?.customerIdentificationNumber ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.formattedCustomerMemberNumber ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.formattedCustomerAccountNumber ||
                  ''
                }
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.customerPersonalFileNumber ||
                  ''
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control"
                disabled
                value={
                  Number(selectedDestinationLoanAccount?.bookBalance) < 0
                    ? formatCurrency(
                        selectedDestinationLoanAccount?.bookBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDestinationLoanAccount?.bookBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDestinationLoanAccount?.principalBalance) < 0
                    ? formatCurrency(
                        selectedDestinationLoanAccount?.principalBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDestinationLoanAccount?.principalBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                style={{ textAlign: 'right' }}
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  Number(selectedDestinationLoanAccount?.interestBalance) < 0
                    ? formatCurrency(
                        selectedDestinationLoanAccount?.interestBalance,
                        null,
                        null,
                        false
                      )
                    : selectedDestinationLoanAccount?.interestBalance || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('loanClassification')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  selectedDestinationLoanAccount?.loanClassificationDescription ||
                  ''
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default CustomerLoanAccountPartial
