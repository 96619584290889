/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
function ViewTicketModal({ mode, selectedTicket, closeModal }) {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('tickets')}
        handleClose={closeModal}
        modalClassName="modal-xl"
        hideUpdate
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('reference#')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.paddedReferenceNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('category')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.categoryDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center ">
            <label className="col-3 text-capitalize">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.statusDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('priority')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.priorityDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('referenceType')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.referenceTypeDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('mode')}</label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.modeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">{localize('title')}</label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.title || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('description')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.description || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('resolution')}
            </label>
            <textarea
              type="text"
              className="form-control me-3"
              value={selectedTicket?.resolution || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('relationshipManager')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={
                selectedTicket?.relationshipManagerEmployeeCustomerFullName ||
                ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('assignedTo')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={
                selectedTicket?.relationshipManagerEmployeeCustomerFullName ||
                ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center ">
            <label className="col-3 text-capitalize">
              {localize('secondaryDescription')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.secondaryDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.secondaryDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.referenceNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center ">
            <label className="col-3 text-capitalize">
              {localize('mobileLine')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={
                selectedTicket?.assignedToEmployeeCustomerAddressMobileLine ||
                ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('resolvedBy')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.resolvedBy || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('resolvedDate')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={formatDate(selectedTicket?.resolvedBy) || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center ">
            <label className="col-3 text-capitalize">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.modifiedBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={formatDate(selectedTicket?.modifiedDate) || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={selectedTicket?.createdBy || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center ">
            <label className="col-3 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control me-3"
              value={formatDate(selectedTicket?.createdDate) || ''}
              disabled
            />
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewTicketModal
