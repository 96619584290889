/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import ViewBankToBankDataModal from '../ViewBankToBankDataModal'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Catalogue() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
      sortBy: 'paddedSerialNumber',
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
      sortBy: 'recordStatusDescription',
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
      sortBy: 'chartOfAccountName',
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
      sortBy: 'chartOfAccountCostDistributionRuleDescription',
    },
    {
      label: localize('paymentAmount'),
      template: r => <>{formatCurrency(r?.paymentAmount)}</>,
      sortBy: 'paymentAmount',
    },
    {
      label: localize('totalAmount'),
      template: r => <>{formatCurrency(r?.totalAmount)}</>,
      sortBy: 'totalAmount',
    },
    {
      label: localize('transactionReferenceCode'),
      template: r => <>{r?.transactionReferenceCode}</>,
      sortBy: 'transactionReferenceCode',
    },
    {
      label: localize('documentReferenceNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
      sortBy: 'documentReferenceNumber',
    },
    {
      label: localize('currency'),
      template: r => <>{r?.currency}</>,
      sortBy: 'currency',
    },
    {
      label: localize('bankCode'),
      template: r => <>{r?.bankCode}</>,
      sortBy: 'bankCode',
    },
    {
      label: localize('branchCode'),
      template: r => <>{r?.branchCode}</>,
      sortBy: 'branchCode',
    },
    {
      label: localize('paymentDate'),
      template: r => <>{formatDate(r?.paymentDate, false)}</>,
      sortBy: 'paymentDate',
    },
    {
      label: localize('paymentReferenceCode'),
      template: r => <>{r?.paymentReferenceCode}</>,
      sortBy: 'paymentReferenceCode',
    },
    {
      label: localize('paymentCode'),
      template: r => <>{r?.paymentCode}</>,
      sortBy: 'paymentCode',
    },
    {
      label: localize('paymentMode'),
      template: r => <>{r?.paymentMode}</>,
      sortBy: 'paymentMode',
    },
    {
      label: localize('additionalInfo'),
      template: r => <>{r?.additionalInfo}</>,
      sortBy: 'additionalInfo',
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.accountNumber}</>,
      sortBy: 'accountNumber',
    },
    {
      label: localize('accountName'),
      template: r => <>{r?.accountName}</>,
      sortBy: 'accountName',
    },
    {
      label: localize('institutionCode'),
      template: r => <>{r?.institutionCode}</>,
      sortBy: 'institutionCode',
    },
    {
      label: localize('institutionName'),
      template: r => <>{r?.institutionName}</>,
      sortBy: 'institutionName',
    },
    {
      label: localize('exceptionMessage'),
      template: r => <>{r?.exceptionMessage}</>,
      sortBy: 'exceptionMessage',
    },
    {
      label: localize('valueDate'),
      template: r => <>{r?.valueDate}</>,
      sortBy: 'valueDate',
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
      sortBy: 'auditedBy',
    },
    {
      label: localize('verifiedDate'),
      template: r => <>{r?.auditedDate}</>,
      sortBy: 'auditedDate',
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
      sortBy: 'modifiedBy',
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
      sortBy: 'modifiedDate',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    status: 0,
    filterText: '',
    bankToBankRequestFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('status'),
    column: 'statusDescription',
  })
  const [mode, setMode] = useState(null)
  const [selectedBankToBankRequest, setSelectedBankToBankRequest] = useState()
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/BankToBankRequest/find-bank-to-bank-requests-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }
  const groupingColumns = [
    {
      label: localize('verifiedBy'),
      column: 'auditedBy',
    },
    {
      label: localize('bankCode'),
      column: 'bankCode',
    },
    {
      label: localize('branchCode'),
      column: 'branchCode',
    },
    {
      label: localize('chartOfAccountName'),
      column: 'chartOfAccountName',
    },
    {
      label: localize('documentReferenceNumber'),
      column: 'documentReferenceNumber',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('paymentAmount'),
      column: 'paymentAmount',
    },
    {
      label: localize('recordStatus'),
      column: 'recordStatusDescription',
    },
    {
      label: localize('status'),
      column: 'statusDescription',
    },
    {
      label: localize('transactionReferenceCode'),
      column: 'transactionReferenceCode',
    },
  ]

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankToBank'),
          localize('bankToBankRequestsCatalogue'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2 mx-0">
                  <RecordsPerPage
                    className="col-2"
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />

                  <div className="col-2 d-flex align-items-center">
                    <label className="col-3  text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <select
                      className="form-select text-capitalize"
                      defaultValue={groupBy.column}
                      style={{ maxWidth: 'fit-content' }}
                      onChange={e =>
                        setGroupBy({
                          label: groupingColumns.find(
                            f => f.column === e.target.value
                          )?.label,
                          column: e.target.value,
                        })
                      }
                    >
                      {groupingColumns.map(({ label, column }) => (
                        <option className="text-capitalize" value={column}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-3">
                    <div className="d-flex align-items-center">
                      <label className="text-capitalize" htmlFor="searchByDate">
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <form
                    className="d-flex align-items-center col-5 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      className="ms-2"
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="BankToBankRequestFilter"
                      value={reqParams?.bankToBankRequestFilter}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          bankToBankRequestFilter: e.value,
                        })
                      }
                    />
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="bankToBankRequestFilterButton"
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>

                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setMode('view')
                          setSelectedBankToBankRequest(r)
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('view')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(r)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
                <div className="mb-2"></div>
                <Pager
                  itemsCount={data?.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      {mode === 'view' && (
        <ViewBankToBankDataModal
          selectedBankToBankRequest={selectedBankToBankRequest}
          mode={mode}
          close={handleCloseModal}
          entryStatus={1}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default Catalogue
