/** @format */

import Documents from '../../Pages/Morgue/Operations/Accounts/MorguePatients/Documents/Documents'
import Register from '../../Pages/Morgue/Operations/Accounts/MorguePatients/Register/Register'
import Samples from '../../Pages/Morgue/Operations/Accounts/MorguePatients/Samples/Samples'
import Services from '../../Pages/Morgue/Operations/Accounts/MorguePatients/Services/Services'
import ReceiptsOrPayments from '../../Pages/Morgue/Operations/Accounts/ReceiptsOrPayments/ReceiptsOrPayments'
import DailyPatientAdmission from '../../Pages/Morgue/Operations/VisualStatisitics/DailyPatientAdmission/DailyPatientAdmission'
import MonthlyPatientAdmission from '../../Pages/Morgue/Operations/VisualStatisitics/MonthlyPatientAdmission/MonthlyPatientAdmission'
import MorgueBedsStatistics from '../../Pages/Morgue/Operations/VisualStatisitics/MorgueBeds/MorgueBeds'
import FuneralHomes from '../../Pages/Morgue/Setup/FuneralHomes/FuneralHomes'
import MorgueBeds from '../../Pages/Morgue/Setup/MorgueBeds/MorgueBeds'
import MorgueRooms from '../../Pages/Morgue/Setup/MorgueRooms/MorgueRooms'
import MorgueServices from '../../Pages/Morgue/Setup/MorgueServices/MorgueServices'
import Technicians from '../../Pages/Morgue/Setup/Technicians/Technicians'
import TransactionTypes from '../../Pages/Morgue/Setup/TransactionTypes/TransactionTypes'

const morgueMenus = [
  {
    iconClass: 'uil uil-ambulance',
    label: 'morgueManagement',
    code: 10,
    setup: [
      {
        label: 'funeralHomes',
        parentCode: 0,
        component: <FuneralHomes />,
        code: 10001,
      },

      {
        label: 'technicians',
        parentCode: 0,
        component: <Technicians />,
        code: 10002,
      },
      {
        label: 'morgueRooms',
        parentCode: 0,
        component: <MorgueRooms />,
        code: 10003,
      },
      {
        label: 'morgueBeds',
        parentCode: 0,
        component: <MorgueBeds />,
        code: 10004,
      },
      {
        label: 'morgueServices',
        parentCode: 0,
        component: <MorgueServices />,
        code: 10005,
      },
      {
        label: 'transactionTypes',
        parentCode: 0,
        component: <TransactionTypes />,
        code: 10006,
      },
    ],
    operations: [
      {
        label: 'visualStatistics',
        code: 10500,
        parentCode: 0,
        children: [
          {
            label: 'morgueBeds',
            component: <MorgueBedsStatistics />,
            code: 10501,
            parentCode: 10500,
          },
          {
            label: 'dailyPatientAdmission',
            component: <DailyPatientAdmission />,
            code: 10502,
            parentCode: 10599,
          },
          {
            label: 'monthlyPatientAdmission',
            component: <MonthlyPatientAdmission />,
            code: 10503,
            parentCode: 10500,
          },
        ],
      },
      {
        label: 'accounts',
        code: 10504,
        parentCode: 0,
        children: [
          {
            label: 'receipts/Payments',
            component: <ReceiptsOrPayments />,
            code: 10505,
          },
        ],
      },
      {
        label: 'morguePatients',
        code: 10506,
        children: [
          {
            label: 'register',
            component: <Register />,
            code: 10507,
          },
          {
            label: 'documents',
            component: <Documents />,
            code: 10508,
          },
          {
            label: 'samples',
            component: <Samples />,
            code: 10509,
          },
          {
            label: 'services',
            component: <Services />,
            code: 10510,
          },
        ],
      },
    ],
  },
]

export default morgueMenus
