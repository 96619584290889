/** @format */

import React, { useState } from 'react'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'

const GoodsReceivedNoteItemLookup = ({
  onSelect,
  displayValue,
  goodsReceivedNoteId,
  noteRefNumber,
}) => {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('goodsReceivedNoteLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{noteRefNumber}</>
        </>
      }
      url="/lookup/GoodsReceivedNote/find-goods-received-note-items-by-goods-received-note-id-filter-in-page"
      params={{
        ...reqParams,
        goodsReceivedNoteId,
      }}
      conditionalField="goodsReceivedNoteId"
      columns={[
        {
          label: localize('item'),
          template: r => <>{r.itemRegisterDescription}</>,
        },
        {
          label: localize('inventoryCategory'),
          template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
        },
        {
          label: localize('assetType'),
          template: r => <>{r.itemRegisterItemTypeDescription}</>,
        },
        {
          label: localize('unitOfMeasure'),
          template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
        },
        {
          label: localize('quantity'),
          template: r => <>{formatCurrency(r.quantity)}</>,
        },
        {
          label: localize('unitCost'),
          template: r => <>{formatCurrency(r.unitCost)}</>,
        },
        {
          label: localize('totalCost'),
          template: r => <>{formatCurrency(r.totalCost)}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default GoodsReceivedNoteItemLookup
