/** @format */

import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ListSuperSaverPayables from '../Partials/ListSuperSaverPayables/ListSuperSaverPayables'
import AuthorizeSuperSaverPayable from './AuthorizeSuperSaverPayable/AuthorizeSuperSaverPayable'
import Swal from 'sweetalert2'
import { superSaverPayableStatus } from '../../../../../Global/enumeration'

const initialSuperSaverPayable = {
  superSaverPayableAuthOption: 1,
}

const Authorization = () => {
  const [reload, setReload] = useState(false)
  const [mode, setMode] = useState(null)
  const [payable, setPayable] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleClickAuthorize = r => {
    if (r?.status !== Object.keys(superSaverPayableStatus)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only authorize verified Super Saver Payable!',
      })

    setMode('authorize')
    setPayable({
      ...initialSuperSaverPayable,
      ...r,
    })
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleClose = () => {
    setMode(null)
    setPayable(initialSuperSaverPayable)
  }

  const handleAuthorizeSuperSaverPayable = async () => {
    if (!payable.auditRemarks) {
      GlobalValidationErrors.set({
        ...globalValidationErrors,
        AuditRemarks: {
          message: 'Remarks is required',
        },
      })
      return
    }

    setIsBusy(true)

    const url = '/SuperSaverPayable/authorize-super-saver-payable'

    const { success } = await postData(
      url,
      {
        superSaverPayable: payable,
        superSaverPayableAuthOption: payable?.superSaverPayableAuthOption,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setReload(true)
      handleClose()
      showNotification('Authorize Super Saver Payable', 'success')
      setIsBusy(false)
    } else setIsBusy(false)
  }

  return (
    <>
      {mode === 'authorize' && (
        <AuthorizeSuperSaverPayable
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          mode={mode}
          handleClose={handleClose}
          superSaverPayable={payable}
          setSuperSaverPayable={setPayable}
          handleAuthorizeSuperSaverPayable={handleAuthorizeSuperSaverPayable}
          loading={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('superSaverPayables'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          <ListSuperSaverPayables
            reload={reload}
            setReload={setReload}
            showAuthorize
            handleAuthorize={handleClickAuthorize}
            status={1}
          />
        </div>
      </div>
    </>
  )
}

export default Authorization
