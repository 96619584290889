/** @format */

import React from 'react'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import TimePicker from '../../../../../../../Components/Date/TimePicker'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import localize from '../../../../../../../Global/Localize'
import { getTodaysDate } from '../../../../../../../Helpers/formatters'

const IndividualParticulars = ({ patient, setPatient, readOnly }) => {
  return (
    <>
      <div className="row g-3">
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('individualType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IndividualType"
            value={patient?.relationship}
            onChange={e => setPatient({ ...patient, individualType: e.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('nationality')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Nationality"
            value={patient?.nationality}
            onChange={e => setPatient({ ...patient, nationality: e.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={patient?.salutation}
            onChange={e =>
              setPatient({
                ...patient,
                salutation: e.value,
                salutationDescription: e.label,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('gender')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={patient?.gender}
            onChange={e =>
              setPatient({
                ...patient,
                gender: e.value,
                genderDescription: e.label,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={patient?.firstName}
            onChange={e =>
              setPatient({ ...patient, firstName: e.target.value })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={patient?.lastName}
            onChange={e => setPatient({ ...patient, lastName: e.target.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('birthDate')}
          </label>
          <CustomDatePicker
            name="registerPatientBirthDate"
            defaultDate={patient?.birthDate}
            onDateChange={date => setPatient({ ...patient, birthDate: date })}
            maxDate={getTodaysDate()}
            disabled={readOnly}
          />
        </div>

        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('maritalStatus')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="MaritalStatus"
            value={patient?.maritalStatus}
            onChange={e => setPatient({ ...patient, maritalStatus: e.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={patient?.identityCardType}
            onChange={e =>
              setPatient({
                ...patient,
                identityCardType: e.value,
                identityCardTypeDescription: e.label,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            value={patient?.identityCardNumber}
            onChange={e =>
              setPatient({
                ...patient,
                identityCardNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identityCardSerialNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            value={patient?.identityCardSerialNumber}
            onChange={e =>
              setPatient({
                ...patient,
                identityCardSerialNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('dateOfDeath')}
          </label>
          <CustomDatePicker
            name="registerPatientDateOfDeath"
            defaultDate={patient?.deathDate}
            onDateChange={date => setPatient({ ...patient, deathDate: date })}
            maxDate={getTodaysDate()}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('deathTime')}
          </label>
          <TimePicker
            name="registerPatientDeathTime"
            defaultTime={patient?.deathTime}
            onTimeChange={time => setPatient({ ...patient, deathTime: time })}
            enableTime
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('registrationDate')}
          </label>
          <CustomDatePicker
            name="registerPatientRegistrationDate"
            defaultDate={patient?.registrationDate}
            onDateChange={date =>
              setPatient({ ...patient, registrationDate: date })
            }
            maxDate={getTodaysDate()}
            disabled={readOnly}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('registrationTime')}
          </label>
          <TimePicker
            name="registerPatientRegistrationTime"
            defaultTime={patient?.registrationTime}
            onTimeChange={time =>
              setPatient({ ...patient, registrationTime: time })
            }
            enableTime
            disabled={readOnly}
          />
        </div>
      </div>
    </>
  )
}

export default IndividualParticulars
