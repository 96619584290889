/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData, putData } from '../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import SearchCustomer from '../SearchCustomer'
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import { generateGuid } from '../../../../../../Helpers/extensions'
import Swal from 'sweetalert2'

function Referees({ customer, readOnly }) {
  const [referees, setReferees] = useState([])
  const [witness, setWitness] = useState(null)
  const [remarks, setRemarks] = useState('')

  useEffect(() => {
    loadReferees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadReferees() {
    const { success, data } = await getData(
      '/customer/find-referee-collection-by-customer-id',
      { customerId: customer.id }
    )
    if (success) {
      setReferees(data?.result)
    }
  }

  const columns = [
    { label: localize('name'), template: r => <>{r.witnessFullName}</> },
    { label: localize('accountNumber'), template: r => <>{r.witnessReference1}</> },
    { label: localize('memberNumber'), template: r => <>{r.witnessReference2}</> },
    { label: localize('pfNumber'), template: r => <>{r.witnessReference3}</> },
    {
      label: localize('gender'),
      template: r => <>{r.witnessIndividualGenderDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.witnessIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.witnessIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r.witnessAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r.witnessAddressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.witnessAddressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.witnessAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.witnessAddressCity}</> },
    { label: localize('email'), template: r => <>{r.witnessAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.witnessAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.witnessAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]

  function addWitness() {
    if (witness) {
      setReferees([...referees, { ...witness, remarks, id: generateGuid() }])
    }
  }

  function removeWitness(w) {
    let reffs = [...referees]
    // eslint-disable-next-line eqeqeq
    let ind = reffs.findIndex(r => r.id == w.id)
    reffs.splice(ind, 1)
    setReferees(reffs)
  }

  async function updateInformation() {
    const { success } = await putData(
      '/customer/update-referee-collection-by-customer-id?customerId=' +
        customer?.id,
      referees
    )
    if (success) {
      await loadReferees()
      Swal.fire('Operation Successful', 'Referees updated!', 'success')
    }
  }

  return readOnly ? (
    <SimpleTable data={referees} columns={columns} />
  ) : (
    <div>
      <div className="my-2">
        <SearchCustomer
          customer={witness}
          onSelect={c =>
            setWitness({
              witnessId: c?.id,
              witnessFullName: c?.fullName,
              witnessReference1: c?.reference1,
              witnessReference2: c?.reference2,
              witnessReference3: c?.reference3,
              witnessIndividualGenderDescription: c?.genderDescription,
              witnessIndividualIdentityCardTypeDescription:
                c?.individualIdentityCardTypeDescription,
              witnessIndividualIdentityCardNumber:
                c?.individualIdentityCardNumber,
              witnessAddressAddressLine1: c?.addressAddressLine1,
              witnessAddressAddressLine2: c?.addressAddressLine2,
              witnessAddressStreet: c?.addressStreet,
              witnessAddressEmail: c?.addressEmail,
              witnessAddressLandLine: c?.addressLandLine,
              witnessAddressMobileLine: c?.addressMobileLine,
            })
          }
        />
        <div className="d-flex mt-2 align-items-center">
          <label htmlFor="" className="text-capitalize col-1">
            {localize('remarks')}
          </label>
          <input
            value={remarks}
            onChange={e => setRemarks(e.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="my-2 bg-light d-flex align-items-center justify-content-end p-2">
          <button className="btn btn-info px-5" onClick={addWitness}>
            {localize('add')}
          </button>
        </div>
      </div>
      <Tab
        tabItems={[
          {
            label: localize('refereeCollection'),
            item: (
              <SimpleTable
                data={referees}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-danger text-capitalize"
                      onClick={() => removeWitness(r)}
                    >
                      <i className="uil uil-times-circle"></i>
                      <span>{localize('remove')}</span>
                    </div>
                  </>
                )}
              />
            ),
          },
        ]}
      />
      <div className="my-2 d-flex align-items-center justify-content-end">
        <button className="btn btn-success px-5" onClick={updateInformation}>
          <i className="uil uil-check"></i>
          <span>{localize('update')}</span>
        </button>
      </div>
    </div>
  )
}

export default Referees
