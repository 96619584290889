/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { AddLevyModal } from './AddLevyModal'
import { EditLevyModal } from './EditLevyModal'
import { ViewLevyModal } from './ViewLevyModal'
import { postData, putData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function LeviesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  levySplits,
  setLevySplits,
  setSelectedLevy,
  selectedLevy,
}) {
  const [isBusy, setIsBusy] = useState()

  async function handleSubmit() {
    if (mode === 'add') {
      return addLevy()
    }
    if (mode === 'edit') {
      return editLevy()
    }
  }

  const addLevy = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Levy/create-levy-with-splits',
      {
        LevyDTO: { ...(selectedLevy || {}) },
        LevySplitDTOs: levySplits || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire('Add Levy', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }
  const editLevy = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/Levy/update-levy-with-splits',
      {
        LevyDTO: { ...(selectedLevy || {}) },
        LevySplitDTOs: levySplits || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire('Edit Levy', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('levy')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disableCreate={isBusy}
          >
            {mode === 'add' && (
              <AddLevyModal
                levySplits={levySplits}
                setLevySplits={setLevySplits}
                isBusy={isBusy}
                mode={mode}
                setSelectedLevy={setSelectedLevy}
                selectedLevy={selectedLevy}
              />
            )}
            {mode === 'edit' && (
              <EditLevyModal
                levySplits={levySplits}
                setLevySplits={setLevySplits}
                isBusy={isBusy}
                mode={mode}
                setSelectedLevy={setSelectedLevy}
                selectedLevy={selectedLevy}
              />
            )}
            {mode === 'view' && (
              <ViewLevyModal
                mode={mode}
                isBusy={isBusy}
                levySplits={levySplits}
                setSelectedLevy={setSelectedLevy}
                selectedLevy={selectedLevy}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default LeviesModal
