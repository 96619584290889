/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
import CustomerLookupPartial from '../../../../../Components/ReUsables/Customer/CustomerLookupPartial'

function CreateQuickEmail({ close }) {
  const innerTabItems = [
    { label: localize('targetCustomers'), item: <>customers</> },
    { label: localize('mismatchedImportEntries'), item: <>mismatched</> },
  ]
  const tabItems = [
    {
      label: localize('recipients'),
      item: (
        <>
          <CustomerLookupPartial />
          <div className="d-flex align-items-center mt-2">
            <label htmlFor="" className="text-capitalize col-1">
              {localize('email')}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="my-3 rounded bg-light p-2 d-flex align-items-center justify-content-end gap-2">
            <button className="btn btn-success px-5">{localize('add')}</button>
            <button className="btn btn-success px-5">
              {localize('import')}
            </button>
          </div>
          <Tab tabItems={innerTabItems} />
        </>
      ),
    },
  ]
  return (
    <ModalTemplate
      modalTitle={'Quick E-Mail Message'}
      modalMode={'create'}
      handleClose={close}
      modalClassName="modal-xl"
    >
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="" className="text-capitalize col-2">
          {localize('subject')}
        </label>
        <input type="text" className="form-control ms-2" />
      </div>
      <div className="d-flex  mt-2">
        <label htmlFor="" className="text-capitalize col-2">
          {localize('message')}
        </label>
        <textarea rows={6} type="text" className="form-control ms-2" />
      </div>
      <div className="mt-2">
        <Tab tabItems={tabItems} />
      </div>
    </ModalTemplate>
  )
}

export default CreateQuickEmail
