/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import SalaryPeriodLookup from '../../../../../Components/Lookup/SalaryPeriodLookup'
import { formatDate } from '../../../../../Helpers/formatters'
import SalaryProcessingEntities from './Partials/SalaryProcessingEntities'
import { getData } from '../../../../../Helpers/webApi'

const Processing = () => {
  const [salaryProcessing, setSalaryProcessing] = useState(null)
  const [selectedSalaryGroups, setSelectedSalaryGroups] = useState({})
  const [selectedBranches, setSelectedBranches] = useState({})
  const [selectedDepartments, setSelectedDepartments] = useState({})

  const loadSalaryGroups = async () => {
    const { success, data } = await getData(
      '/SalaryGroup/find-salary-groups',
      {},
      false
    )
    if (success) {
      setSelectedSalaryGroups({
        salaryGroups: data || [],
      })
    }
  }

  const loadBranches = async () => {
    const { success, data } = await getData('/Branch/find-all', {}, false)
    if (success) {
      setSelectedBranches({
        branches: data || [],
      })
    }
  }

  const loadDepartments = async () => {
    const { success, data } = await getData(
      '/Department/find-departments',
      {},
      false
    )
    if (success) {
      setSelectedDepartments({
        departments: data || [],
      })
    }
  }

  useEffect(() => {
    if (salaryProcessing?.id) {
      loadSalaryGroups()
      loadBranches()
      loadDepartments()
    }

    // eslint-disable-next-line
  }, [salaryProcessing?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('salaryProcessing'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize me-1">
                    {localize('salaryPeriod')}
                  </label>
                  <SalaryPeriodLookup
                    displayValue={salaryProcessing?.monthDescription}
                    onSelect={r => {
                      setSalaryProcessing({
                        ...salaryProcessing,
                        id: r.id,
                        monthDescription: r.monthDescription,
                        employeeTypeDescription: r.employeeTypeDescription,
                        valueDate: r.valueDate,
                        remarks: r.remarks,
                        employeeTypeId: r.employeeTypeId,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('employeeType')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={salaryProcessing?.employeeTypeDescription}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-md-3 text-capitalize">
                    {localize('valueDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    value={formatDate(salaryProcessing?.valueDate, true)}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center ">
                  <label className="col-md-1 text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={salaryProcessing?.remarks || ''}
                    onChange={e => {
                      setSalaryProcessing({
                        ...salaryProcessing,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>

              <SalaryProcessingEntities
                salaryProcessing={salaryProcessing}
                selectedSalaryGroups={selectedSalaryGroups}
                setSelectedSalaryGroups={setSelectedSalaryGroups}
                selectedBranches={selectedBranches}
                setSelectedBranches={setSelectedBranches}
                selectedDepartments={selectedDepartments}
                setSelectedDepartments={setSelectedDepartments}
              />
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default Processing
