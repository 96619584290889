/** @format */

import localize from '../../../../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { JournalVoucherEntryType } from '../../../../../../../Global/enumeration'
import BranchLookup from '../../../../../../../Components/Lookup/BranchLookup'
import { useAuth } from 'react-oidc-context'
import PostingPeriodLookup from '../../../../../../../Components/Lookup/PostingPeriodLookup'
import { getData, postData, putData } from '../../../../../../../Helpers/webApi'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { CreateModifyJournalVoucherEntriesEditMode } from './JournalVoucherEntries'
import { VoucherDetailsGeneralLedger } from './VoucherDetailsGeneralLedger'
import { VoucherDetailsCustomer } from './VoucherDetailsCustomer'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { JournalVoucherLookup } from '../../../../../../../Components/Lookup/JournalVoucherLookup'
import { formatCurrency } from '../../../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const EditJournalVoucherModal = ({
  mode,
  selectedJournalVoucher,
  editJournalVoucherModel,
  setEditJournalVoucherModel,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }
  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  const auth = useAuth()

  const [selectedBranch, setSelectedBranch] = useState({
    description: auth?.user.profile?.cbs_branch,
    id: '',
  })

  const [selectedJournalVoucherEntry, setSelectedJournalVoucherEntry] =
    useState(Object.keys(JournalVoucherEntryType)[0])

  const [refreshVoucherJournalDetails, setRefreshVoucherJournalDetails] =
    useState(false)
  const [currentPostingPeriod, setCurrentPostingPeriod] = useState(null)
  const [selectedCostCenter, setSelectedCostCenter] = useState(null)
  const [journalVoucherEntryModel, setJournalVoucherEntryModel] = useState(null)

  const [previouslyApportioned, setPreviouslyApportioned] = useState(0)
  const [previouslyShortaged, setPreviouslyShortaged] = useState(0)

  const [refreshJournalVoucherEntries, setRefreshJournalVoucherEntries] =
    useState(false)

  const [
    selectedJournalVoucherEntriesToBeRemoved,
    setSelectedJournalVoucherEntriesToBeRemoved,
  ] = useState([])

  const getCurrentPostingPeriod = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false
    )
    if (success) {
      setCurrentPostingPeriod(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    getCurrentPostingPeriod()
  }, [])

  const handleOnAddJournalVoucherEntry = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/JournalVoucher/create-journal-voucher-entry',
      {
        journalVoucherId: selectedJournalVoucher?.id,
        chartOfAccountId:
          selectedJournalVoucherEntry ===
          Object.keys(JournalVoucherEntryType)[1]
            ? journalVoucherEntryModel?.CustomerInformation.chartOfAccountId
            : journalVoucherEntryModel?.GeneralLedgerAccount.chartOfAccountId,
        customerAccountId: journalVoucherEntryModel?.CustomerInformation.id,
        costCenterId: selectedCostCenter?.Id,
        amount:
          selectedJournalVoucherEntry ===
          Object.keys(JournalVoucherEntryType)[0]
            ? journalVoucherEntryModel.GeneralLedgerAccount
                .generalLedgerAccountAmount
            : journalVoucherEntryModel.CustomerInformation.amount,

        remarks:
          selectedJournalVoucherEntry ===
          Object.keys(JournalVoucherEntryType)[0]
            ? journalVoucherEntryModel?.GeneralLedgerAccount.remarks
            : journalVoucherEntryModel?.CustomerInformation.remarks,
      },
      false,
      'Journal Voucher Entry'
    )

    if (success) {
      setRefreshJournalVoucherEntries(!refreshJournalVoucherEntries)
      setRefreshVoucherJournalDetails(!refreshVoucherJournalDetails)
      setJournalVoucherEntryModel(null)
    }
    setIsBusy(false)
  }

  const handleOnRemoveSelectedJournalVoucherEntry = async r => {
    setIsBusy(true)
    if (selectedJournalVoucherEntriesToBeRemoved.length === 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Please Select At Least One Journal Voucher Entry To Remove',
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
      return
    }
    setIsBusy(true)
    const { success, data } = await putData(
      '/JournalVoucher/remove-journal-voucher-entry',
      {
        journalVoucherEntryDTOs: selectedJournalVoucherEntriesToBeRemoved,
      },
      false,
      'Remove Journal Voucher Entry'
    )
    if (success) {
      setSelectedJournalVoucherEntriesToBeRemoved([])
      setRefreshJournalVoucherEntries(!refreshJournalVoucherEntries)
    }
    setIsBusy(false)
  }

  const handleOnChangeValueDate = async e => {
    setIsBusy(true)
    const { success, data } = await putData(
      `/PostingPeriod/evaluate-value-date-validity/?valueDate=${e}`,
      {},
      false
    )
    if (success) {
      setEditJournalVoucherModel({
        ...editJournalVoucherModel,
        valueDate: e,
      })
    } else {
      setEditJournalVoucherModel({
        ...editJournalVoucherModel,
        valueDate: null,
      })
    }
    setIsBusy(false)
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('voucherNumber')}
                    </label>
                    <JournalVoucherLookup
                      displayValue={
                        editJournalVoucherModel?.paddedVoucherNumber
                      }
                      onSelect={r => {
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          id: r.id,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('branch')}
                    </label>
                    <BranchLookup
                      displayValue={editJournalVoucherModel?.branchDescription}
                      onSelect={r => {
                        setSelectedBranch({
                          ...selectedBranch,
                          id: r.id,
                          description: r.description,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('postingPeriod')}
                    </label>
                    <PostingPeriodLookup
                      displayValue={currentPostingPeriod?.description}
                      onSelect={r => {
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          postingPeriodId: r.id,
                        })
                        setCurrentPostingPeriod(r)
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('chartOfAccountName')}
                    </label>
                    <input
                      disabled
                      className="form-control"
                      value={editJournalVoucherModel?.chartOfAccountName}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('costDistributionRule')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        editJournalVoucherModel?.chartOfAccountCostDistributionRuleDescription
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('costCenter')}
                    </label>
                    <CostCentreLookup
                      onSelect={r => {
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          costCenterDescription: r.description,
                          costCenterId: r.id,
                        })
                      }}
                      displayValue={
                        editJournalVoucherModel?.costCenterDescription
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fullAccountNumber')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        editJournalVoucherModel?.customerAccountFullAccountNumber
                      }
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('customerName')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        editJournalVoucherModel?.customerAccountCustomerFullName
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('voucherType')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={editJournalVoucherModel?.typeDescription}
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('valueDate')}
                    </label>
                    <CustomDatePicker
                      name={'change-value-date'}
                      onDateChange={handleOnChangeValueDate}
                      defaultDate={editJournalVoucherModel?.valueDate || null}
                    />
                  </div>
                  <div
                    validation-message={
                      globalValidationErrors?.TotalValue?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('totalValue')}
                    </label>
                    <MaskNumber
                      defaultMaskValue={editJournalVoucherModel?.totalValue}
                      onMaskNumber={e => {
                        clearValidation('TotalValue')
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          totalValue: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    validation-message={
                      globalValidationErrors?.PrimaryDescription?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('primaryDescription')}
                    </label>
                    <input
                      onChange={e => {
                        clearValidation('PrimaryDescription')
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          primaryDescription: e.target.value,
                        })
                      }}
                      className="form-control"
                      value={editJournalVoucherModel?.primaryDescription}
                    />
                  </div>
                  <div
                    validation-message={
                      globalValidationErrors?.SecondaryDescription?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('secondaryDescription')}
                    </label>
                    <input
                      className="form-control"
                      onChange={e => {
                        clearValidation('SecondaryDescription')
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          secondaryDescription: e.target.value,
                        })
                      }}
                      value={editJournalVoucherModel?.secondaryDescription}
                    />
                  </div>
                  <div
                    validation-message={
                      globalValidationErrors?.Reference?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    <label className="col-3 text-capitalize">
                      {localize('reference')}
                    </label>
                    <input
                      onChange={e => {
                        clearValidation('Reference')
                        setEditJournalVoucherModel({
                          ...editJournalVoucherModel,
                          reference: e.target.value,
                        })
                      }}
                      className="form-control"
                      value={editJournalVoucherModel?.reference}
                    />
                  </div>
                </div>
                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 120,
                    }}
                  >
                    {localize('voucherEntryDetails')}
                  </div>
                  <div className="row mb-3" style={{ marginTop: -10 }}>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize me-3">
                        {localize('entryType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="JournalVoucherEntryType"
                        onChange={e => {
                          setSelectedJournalVoucherEntry(e.value)
                        }}
                        value={selectedJournalVoucherEntry}
                        sort
                      />
                    </div>
                  </div>
                  {selectedJournalVoucherEntry ===
                  Object.keys(JournalVoucherEntryType)[0] ? (
                    <VoucherDetailsGeneralLedger
                      refreshVoucherJournalDetails={
                        refreshVoucherJournalDetails
                      }
                      journalVoucherEntryModel={journalVoucherEntryModel}
                      setJournalVoucherEntryModel={setJournalVoucherEntryModel}
                    />
                  ) : (
                    <VoucherDetailsCustomer
                      refreshVoucherJournalDetails={
                        refreshVoucherJournalDetails
                      }
                      journalVoucherEntryModel={journalVoucherEntryModel}
                      setJournalVoucherEntryModel={setJournalVoucherEntryModel}
                    />
                  )}
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={handleOnAddJournalVoucherEntry}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('add')}
                    </button>
                  </div>

                  <Tab
                    tabItems={[
                      {
                        label: localize('voucherEntries'),
                        item: (
                          <CreateModifyJournalVoucherEntriesEditMode
                            onSelectJournalVoucherEntry={r => {
                              setSelectedJournalVoucherEntriesToBeRemoved(r)
                            }}
                            onFetchJournalVoucherEntries={r => {
                              setPreviouslyShortaged(r.totalShortage)
                              setPreviouslyApportioned(r.totalApportioned)
                            }}
                            refreshJournalVoucherEntries={
                              refreshJournalVoucherEntries
                            }
                            selectedJournalVoucher={selectedJournalVoucher}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportionment')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(previouslyApportioned, 2, 2, false)}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(previouslyShortaged, 2, 2, false)}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <button
                onClick={handleOnRemoveSelectedJournalVoucherEntry}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('remove')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
