/** @format */
import { getData, postData } from '../../../../../../Helpers/webApi'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  BatchAuditOption,
  batchAuthOptions,
  BatchEntryStatus,
  CustomerFilter,
} from '../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Pager from '../../../../../../Components/Tables/Pager'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const RefundBatchEntries = ({
  selectedRefundBatchEntry,
  refreshRefundBatchEntries,
  onSelectRefundBatchEntry,
}) => {
  const [selectedRefundBatchEntries, setSelectedRefundBatchEntries] = useState(
    []
  )
  const [reqParams, setReqParams] = useState({
    refundBatchId: selectedRefundBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setRefundBatchEntries] = useState([])

  const fetchRefundBatchEntries = async id => {
    const { success, data } = await getData(
      '/RefundBatch/find-refund-batches-entries-by-refund-batch-id-in-page',
      { ...reqParams, refundBatchId: id }
    )
    if (success) {
      setRefundBatchEntries(data)
    }
  }

  const handleOnRemoveSelectedRefundBatchEntries = async () => {
    if (selectedRefundBatchEntries.length === 0) {
      await Swal.fire({
        title: 'Warning',
        text: 'Please Select At Least One Refund Batch Entry To Remove',
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
      return
    }
    const { success } = await postData(
      '/RefundBatch/remove-refund-batch-entries',
      {
        RefundBatchEntries: selectedRefundBatchEntries,
      },
      true,
      'Remove Batch Entries'
    )
    if (success) {
      await fetchRefundBatchEntries(selectedRefundBatchEntry?.id)
    }
  }

  useEffect(() => {
    if (selectedRefundBatchEntry) {
      fetchRefundBatchEntries(selectedRefundBatchEntry?.id)
    }
  }, [
    selectedRefundBatchEntry?.id,
    refreshRefundBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedRefundBatchEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedRefundBatchEntries([...selectedRefundBatchEntries, r])
              onSelectRefundBatchEntry([...selectedRefundBatchEntries, r])
            } else {
              setSelectedRefundBatchEntries(
                selectedRefundBatchEntries.filter(s => s.id !== r.id)
              )
              onSelectRefundBatchEntry(
                selectedRefundBatchEntries.filter(s => s.id !== r.id)
              )
            }
          }}
        />
      ),
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('entryType'),
      template: r => <>{r.entryTypeDescription}</>,
    },
    {
      label: localize('refundType'),
      template: r => <>{r.refundTypeDescription}</>,
    },
    {
      label: localize('customerFullName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r.debitFullAccountNumber}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.creditFullAccountNumber}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('notificationRefundType'),
      template: r => <>{r.RefundNotificationRefundTypeDescription}</>,
    },
    {
      label: localize('notificationCustomerName'),
      template: r => <>{r.refundNotificationCustomerFullName}</>,
    },
    {
      label: localize('notificationDebitFullAccountNumber'),
      template: r => <>{r.refundNotificationDebitFullAccountNumber}</>,
    },
    {
      label: localize('notificationCreditFullAccountNumber'),
      template: r => <>{r.refundNotificationCreditFullAccountNumber}</>,
    },
    {
      label: localize('notificationTotalValue'),
      template: r => <>{r.refundNotificationTotalValue}</>,
    },
    {
      label: localize('notificationPrincipal'),
      template: r => <>{r.refundNotificationPrincipal}</>,
    },
    {
      label: localize('notificationInterest'),
      template: r => <>{r.refundNotificationInterest}</>,
    },
    {
      label: localize('notificationSerialNumber'),
      template: r => <>{r.paddedRefundNotificationSerialNumber}</>,
    },
    {
      label: localize('notificationStatus'),
      template: r => <>{r.refundNotificationStatusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.cCreatedBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <div className="row">
        <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
          <div className="d-flex align-items-center me-5">
            <label className="text-capitalize col-3 me-2">
              {localize('status')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchEntryStatus"
              value={reqParams?.status}
              onChange={e => {
                setReqParams({ ...reqParams, status: e.value })
              }}
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <label className="text-capitalize me-3">{localize('search')}</label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="CustomerFilter"
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  customerFilter: e.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <input
              className="form-control"
              value={reqParams?.filterText}
              onChange={e => {
                setReqParams({ ...reqParams, filterText: e.target.value })
              }}
            />
          </div>
        </div>
      </div>

      <SimpleTable columns={columns} data={batchEntries.pageCollection} />
      <div
        className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
        style={{ borderRadius: 5 }}
      >
        <button
          onClick={handleOnRemoveSelectedRefundBatchEntries}
          className="btn btn-success text-uppercase ms-3 px-4"
        >
          {localize('remove')}
        </button>
      </div>

      <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
        <div className="d-flex align-items-center me-5">
          <label htmlFor="" className="text-capitalize ms-2 me-2">
            {localize('entries')}
          </label>
          <input
            className="form-control text-end"
            disabled
            value={batchEntries.statusEntries}
          />
        </div>
      </div>
      <Pager
        itemsCount={batchEntries?.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}

export const RefundBatchEntriesViewMode = ({
  selectedRefundBatchEntry,
  refreshRefundBatchEntries,
  setAuthorizationModels,
  clearValidation,
  authorizationModels,
  setVerificationModels,
  verificationModels,
  validationErrors,
  mode,
}) => {
  const [reqParams, setReqParams] = useState({
    refundBatchId: selectedRefundBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[19],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setRefundBatchEntries] = useState([])

  const fetchRefundBatchEntries = async id => {
    const { success, data } = await getData(
      '/RefundBatch/find-refund-batches-entries-by-refund-batch-id-in-page',
      { ...reqParams, refundBatchId: id }
    )
    if (success) {
      setRefundBatchEntries(data)
    }
  }

  useEffect(() => {
    if (selectedRefundBatchEntry) {
      fetchRefundBatchEntries(selectedRefundBatchEntry?.id)
    }
  }, [
    selectedRefundBatchEntry.id,
    refreshRefundBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('entryType'),
      template: r => <>{r.entryTypeDescription}</>,
    },
    {
      label: localize('refundType'),
      template: r => <>{r.refundTypeDescription}</>,
    },
    {
      label: localize('customerFullName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r.debitFullAccountNumber}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.creditFullAccountNumber}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('notificationRefundType'),
      template: r => <>{r.RefundNotificationRefundTypeDescription}</>,
    },
    {
      label: localize('notificationCustomerName'),
      template: r => <>{r.refundNotificationCustomerFullName}</>,
    },
    {
      label: localize('notificationDebitFullAccountNumber'),
      template: r => <>{r.refundNotificationDebitFullAccountNumber}</>,
    },
    {
      label: localize('notificationCreditFullAccountNumber'),
      template: r => <>{r.refundNotificationCreditFullAccountNumber}</>,
    },
    {
      label: localize('notificationTotalValue'),
      template: r => <>{r.refundNotificationTotalValue}</>,
    },
    {
      label: localize('notificationPrincipal'),
      template: r => <>{r.refundNotificationPrincipal}</>,
    },
    {
      label: localize('notificationInterest'),
      template: r => <>{r.refundNotificationInterest}</>,
    },
    {
      label: localize('notificationSerialNumber'),
      template: r => <>{r.paddedRefundNotificationSerialNumber}</>,
    },
    {
      label: localize('notificationStatus'),
      template: r => <>{r.refundNotificationStatusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.cCreatedBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
        <div className="d-flex align-item-center me-5">
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="BatchEntryStatus"
            value={reqParams?.status}
            onChange={e => {
              setReqParams({ ...reqParams, status: e.value })
            }}
          />
        </div>
        <div className="d-flex align-item-center me-5">
          <label className="text-capitalize me-3">{localize('search')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CustomerFilter"
            onChange={e => {
              setReqParams({
                ...reqParams,
                customerFilter: e.value,
              })
            }}
          />
        </div>
        <div className="d-flex align-items-center me-5">
          <div className="input-group">
            <input
              className="form-control"
              value={reqParams?.filterText}
              onChange={e => {
                setReqParams({ ...reqParams, filterText: e.target.value })
              }}
            />
            <button
              id="FilterButton"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                fetchRefundBatchEntries(selectedRefundBatchEntry?.id)
              }}
            >
              <i className="uil uil-filter"></i>
            </button>
          </div>
        </div>
      </div>
      <SimpleTable columns={columns} data={batchEntries.pageCollection} />
      <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
        <div className="d-flex align-items-center me-5">
          <label htmlFor="" className="text-capitalize ms-2 me-2">
            {localize('entries')}
          </label>
          <input
            className="form-control"
            disabled
            value={batchEntries.statusEntries}
          />
        </div>
      </div>
      <Pager
        itemsCount={batchEntries?.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
      {mode === 'verify' && (
        <div className="d-flex align-content-end justify-content-end mt-3 mb-3 bg-light p-2">
          <div
            validation-message={
              validationErrors?.['RefundBatch.AuditRemarks']?.message
            }
            className="d-flex align-items-center me-5 with-validation"
          >
            <label className="text-capitalize ms-2 me-3">
              {localize('remarks')}
            </label>
            <input
              className="form-control"
              value={verificationModels?.auditRemarks}
              onChange={e => {
                clearValidation('CreditBatch.AuditRemarks')
                setVerificationModels({
                  ...authorizationModels,
                  auditRemarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <label className=" text-capitalize col-3 me-3">
              {localize('action')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchAuditOption"
              value={verificationModels?.auditOption}
              onChange={e => {
                setVerificationModels({
                  ...verificationModels,
                  auditOption: e.value,
                })
              }}
            />
          </div>
        </div>
      )}
      {mode === 'authorize' && (
        <div className="d-flex align-content-end justify-content-end mt-3 mb-3 bg-light p-2">
          <div
            validation-message={
              validationErrors?.['RefundBatch.AuditRemarks']?.message
            }
            className="d-flex align-items-center me-5 with-validation"
          >
            <label className="text-capitalize ms-2 me-3">
              {localize('remarks')}
            </label>
            <input
              className="form-control"
              value={authorizationModels?.authorizationRemarks}
              onChange={e => {
                clearValidation('CreditBatch.AuditRemarks')
                setAuthorizationModels({
                  ...authorizationModels,
                  authorizationRemarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center me-5">
            <label className=" text-capitalize col-3 me-3">
              {localize('action')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchAuthOptions"
              value={authorizationModels?.authOption}
              onChange={e => {
                setAuthorizationModels({
                  ...authorizationModels,
                  authOption: e.value,
                })
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}
