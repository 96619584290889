/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { ViewIdentitySpecimen } from '../ViewIdentitySpecimen'

function ViewIdentityVerification({
  identityVerification,
  handleClose,
  mode,
  title,
}) {
  return (
    <ModalTemplate
      modalMode={mode}
      modalClassName="modal-xl"
      modalTitle={
        title
          ? localize('viewIdentityVerification')
          : localize('identityVerification')
      }
      hideUpdate
      cancelText={localize('close')}
      handleClose={handleClose}
    >
      <div className="row g-3">
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.statusDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('reportType')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.reportTypeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('identityType')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.identityTypeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('identity#')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.identityNumber || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('firstName')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.firstName || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('otherName')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.otherName || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('surname')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.surname || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('gender')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.gender || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('dateOfBirth')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.dateOfBirth || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('dateOfDeath')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.dateOfDeath || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('dateOfIssue')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.dateOfIssue || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('citizenship')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.citizenship || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">{localize('clan')}</label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.clan || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('ethnicGroup')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.ethnicGroup || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('family')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.family || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('occupation')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.occupation || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('placeOfDeath')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.placeOfDeath || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('placeOfLive')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.placeOfLive || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('regOffice')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.regOffice || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('serialNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.serialNumber || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('transactionId')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.transactionId || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('apiCode')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.apiCode || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('apiCodeDescription')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.apiCodeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">{localize('error')}</label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.error || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('thirdPartyResponse')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.thirdPartyResponse || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('thirdPartyWalletResponse')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.thirdPartyWalletResponse || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('errorMessage')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.errorMessage || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.modifiedBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.modifiedDate || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.createdBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={identityVerification?.createdDate || ''}
              disabled
            />
          </div>
        </div>
      </div>
      <Tab
        preload
        tabItems={[
          {
            label: localize('specimen'),
            item: (
              <ViewIdentitySpecimen
                identityVerification={identityVerification}
              />
            ),
          },
        ]}
      />
    </ModalTemplate>
  )
}

export default ViewIdentityVerification
