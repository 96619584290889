/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function PayrollNumberTypeLookup({ displayValue, onSelect }) {
  return (
    <SimpleLookup
      title={localize('payrollnumberTypeLookup')}
      url="/lookup/PayrollNumber/find-payroll-number-types-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('productCode'),
          template: r => <>{r.customerAccountTypeProductCodeDescription}</>,
          sortBy: 'customerAccountTypeProductCodeDescription',
        },
        {
          label: localize('productName'),
          template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
          sortBy: 'customerAccountTypeTargetProductDescription',
        },
        {
          label: localize('isDefault') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isDefault.toString())}
            </span>
          ),
          sortBy: 'isDefault',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.isCreatedDate)}</>,
          sortBy: 'createdDate',
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default PayrollNumberTypeLookup
