/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import EmployerLookup from '../../../../Components/Lookup/EmployerLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import { parseDate } from '../../../../Helpers/formatters'

function EditEmployersModal({ close, mode, refresh, selectedEmployer }) {
  const [payload, setPayload] = useState(selectedEmployer)
  const [isBusy, setIsBusy] = useState(false)
  const [disbursementFactors, setDisbursementFactors] = useState(null)
  const [gve, setGve] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setGve(g => {
      delete g[column]
      return g
    })
  }

  function getValidationMessage(column) {
    if (gve && gve[column] && gve[column].message) {
      let message = gve[column].message
      return message
    } else {
      return null
    }
  }

  useEffect(() => {
    loadDisbursementFactors()
    setGve({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadDisbursementFactors() {
    if (mode === 'edit') {
      setIsBusy(true)
      const { success, data } = await getData(
        '/employer/find-disbursement-factors-by-employer-id',
        { employerId: selectedEmployer?.id },
        false
      )
      if (success) {
        setDisbursementFactors(data.result)
      }

      setIsBusy(false)
    }
  }

  async function handleCreate() {
    setIsBusy(true)
    const d = disbursementFactors.map(df => {
      delete df.id
      return df
    })
    const { success } = await postData(
      '/employer/add-employer-with-disbursement-factors',
      { employer: payload, disbursementFactors: d },
      false
    )
    if (success) {
      Swal.fire('Employers', 'Operation Successful', 'success')
      refresh()
      close()
    }

    setIsBusy(false)
  }

  async function handleUpdate() {
    setIsBusy(true)
    const d = disbursementFactors.map(df => {
      delete df.id
      return df
    })
    const { success } = await postData(
      '/employer/update-employer-with-disbursement-factors',
      { employer: payload, disbursementFactors: d },
      false
    )
    if (success) {
      refresh()
      close()
      Swal.fire('Employers', 'Operation Successful', 'success')
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle="employers"
      handleClose={close}
      disableClose={isBusy}
      disableCreate={isBusy}
      modalMode={mode}
      handleAction={mode === 'edit' ? handleUpdate : handleCreate}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              id="enforceMaximumGuaranteesEmployerEdit"
              checked={payload?.enforceMaximumGuarantees}
              onCheck={v =>
                setPayload({ ...payload, enforceMaximumGuarantees: v })
              }
            />
            <label
              htmlFor="enforceMaximumGuaranteesEmployerEdit"
              className="text-capitalize ms-2"
            >
              {localize('enforceMaximumGuarantees')}?
            </label>
          </div>
          <div className="d-flex align-items-center ms-4">
            <Checkbox
              id="employerenforceLoanClassificationDate"
              checked={payload?.enforceLoanClassificationDate}
              onCheck={v =>
                setPayload({ ...payload, enforceLoanClassificationDate: v })
              }
            />
            <label
              htmlFor="employerenforceLoanClassificationDate"
              className="text-capitalize ms-2"
            >
              {localize('enforceLoanClassificationDate')}?
            </label>
          </div>
          <div className="d-flex align-items-center ms-4">
            <Checkbox
              id="employerthrottleScoring"
              checked={payload?.throttleScoring}
              onCheck={v => setPayload({ ...payload, throttleScoring: v })}
            />
            <label
              htmlFor="employerthrottleScoring"
              className="text-capitalize ms-2"
            >
              {localize('throttleScoring')}?
            </label>
          </div>
          <div className="d-flex align-items-center ms-4">
            <Checkbox
              id="employerThrottleInternalLoaning"
              checked={payload?.throttleInternalLoaning}
              onCheck={v =>
                setPayload({ ...payload, throttleInternalLoaning: v })
              }
            />
            <label
              htmlFor="employerThrottleInternalLoaning"
              className="text-capitalize ms-2"
            >
              {localize('throttleInternalLoaning')}?
            </label>
          </div>
          <div className="d-flex align-items-center ms-4 me-5">
            <Checkbox
              id="employerIsLocked"
              checked={payload?.isLocked}
              onCheck={v => setPayload({ ...payload, isLocked: v })}
            />
            <label htmlFor="employerIsLocked" className="text-capitalize ms-2">
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      <div className="row">
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-5">
              {localize('parent')}
            </label>
            <EmployerLookup
              canSelectHeader
              displayValue={payload?.parentDescription}
              onSelect={v =>
                setPayload({
                  ...payload,
                  parentDescription: v.description,
                  parentId: v.id,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('companyCode')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.companyCode}
              onChange={e =>
                setPayload({ ...payload, companyCode: e.target.value })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('physicalAddress')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.adressStreet}
              onChange={e =>
                setPayload({
                  ...payload,
                  adressStreet: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">{localize('email')}</label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressEmail}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressEmail: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('loanClassificationDate')}
            </label>
            <CustomDatePicker
              name="employerLoanClassificationDate"
              defaultDate={parseDate(payload?.loanClassificationDate)}
              onDateChange={date =>
                setPayload({
                  ...payload,
                  loanClassificationDate: date,
                })
              }
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-5">{localize('type')}</label>
            <EnumsServerCombo
              enumsKey="EmployerType"
              sort
              value={payload?.type}
              customContainerStyle={{ width: '100%' }}
              onChange={e => setPayload({ ...payload, type: e.value })}
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('addressLine')}1
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressAddressLine1}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressAddressLine1: e.target.value,
                })
              }
            />
          </div>

          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('postalCode')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressPostalCode}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressPostalCode: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('landLine')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressLandLine}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressLandLine: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('interestArrearsGracePeriod')} ({localize('days')})
            </label>
            <MaskNumber
              defaultMaskValue={payload?.loanInterestArrearsGracePeriod}
              onMaskNumber={e =>
                setPayload({
                  ...payload,
                  loanInterestArrearsGracePeriod: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col">
          <div
            className="d-flex align-items-center with-validation"
            validation-message={getValidationMessage('Employer.Description')}
          >
            <label className="text-capitalize col-5">{localize('name')}</label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.description}
              onChange={e => {
                setPayload({ ...payload, description: e.target.value })
                clearValidation('Employer.Description')
              }}
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('addressLine')}2
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressAddressLine2}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressAddressLine2: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">{localize('city')}</label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressCity}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressCity: e.target.value,
                })
              }
            />
          </div>

          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('mobileLine')}
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={payload?.addressMobileLine}
              onChange={e =>
                setPayload({
                  ...payload,
                  addressMobileLine: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mt-2">
            <label className="text-capitalize col-5">
              {localize('maximumGuarantees')}
            </label>
            <MaskNumber
              defaultMaskValue={payload?.maximumGuarantees}
              onMaskNumber={e =>
                setPayload({
                  ...payload,
                  maximumGuarantees: Math.abs(e.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row mt-3">
          <Tab
            tabItems={[
              {
                label: localize('disbursementFactors'),
                item: (
                  <DisbursementFactors
                    editMode={mode === 'edit'}
                    disbursementFactors={disbursementFactors || []}
                    onChange={f => setDisbursementFactors(f)}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
    </ModalTemplate>
  )
}

function DisbursementFactors({ disbursementFactors, onChange, editMode }) {
  const addColumns = [
    {
      label: `${localize('disbursementDay')} (${localize('lowerLimit')})`,
      template: r => <>{r.disbursementDayLowerLimit}</>,
    },
    {
      label: `${localize('disbursementDay')} (${localize('upperLimit')})`,
      template: r => <>{r.disbursementDayUpperLimit}</>,
    },
    {
      label: `${localize('gracePeriod')}`,
      template: r => <>{r.gracePeriod}</>,
    },
    {
      label: `${localize('capitalizeInterest')} (${localize('prorated')})?`,
      template: r => (
        <span className="text-capitalize">
          {r.capitalizeProratedInterest.toString()}
        </span>
      ),
    },
  ]

  const editColumns = [
    {
      label: localize('standingOrderTrigger'),
      template: r => <>{r.standingOrderTriggerDescription}</>,
    },
    {
      label: `${localize('disbursementDay')} (${localize('lowerLimit')})`,
      template: r => <>{r.disbursementDayLowerLimit}</>,
    },
    {
      label: `${localize('disbursementDay')} (${localize('upperLimit')})`,
      template: r => <>{r.disbursementDayUpperLimit}</>,
    },
    {
      label: `${localize('gracePeriod')}`,
      template: r => <>{r.gracePeriod}</>,
    },
    {
      label: `${localize('capitalizeInterest')} (${localize('prorated')})?`,
      template: r => (
        <span className="text-capitalize">
          {r.capitalizeProratedInterest.toString()}
        </span>
      ),
    },
    {
      label: localize('recoverInterestUpfront'),
      template: r => <>{r.recoverInterestUpfront}</>,
    },
  ]

  const [limit, setLimit] = useState(null)
  const [gracePeriod, setGracePeriod] = useState(null)
  const [standingOrderTrigger, setstandingOrderTrigger] = useState(0)
  const [recoverInterestUpfront, setRecoverInterestUpfront] = useState(false)
  const [capitalizeInterest, setCapitalizeInterest] = useState(false)

  const [dFac, setDFac] = useState(disbursementFactors)

  function handleAddDisbursementFactor(e) {
    e.preventDefault()
    const ourLimit = limit - 1
    let newLimit = 0
    if (Array.isArray(dFac) && dFac.length > 0) {
      const { disbursementDayUpperLimit } = dFac.sort(
        (a, b) => a.disbursementDayLowerLimit - b.disbursementDayLowerLimit
      )[dFac.length - 1]
      newLimit = parseFloat(disbursementDayUpperLimit) + 1
    }

    if (newLimit > ourLimit) {
      return Swal.fire(
        'Invalid Split',
        'Value must be progressive (' + newLimit + ', ' + limit + ')',
        'info'
      )
    }

    let dis = {
      id: dFac?.length ? dFac.length + 1 : 0,
      disbursementDayLowerLimit: newLimit,
      disbursementDayUpperLimit: ourLimit,
      gracePeriod,
      capitalizeProratedInterest: capitalizeInterest,
    }
    if (Array.isArray(dFac)) {
      setDFac([
        ...dFac.sort(
          (a, b) => a.disbursementDayLowerLimit - b.disbursementDayLowerLimit
        ),
        dis,
      ])
    } else {
      setDFac([dis])
    }
  }

  useEffect(() => {
    onChange(dFac)
    //eslint-disable-next-line
  }, [dFac])

  return (
    <div
      className="p-2 mt-4"
      style={{
        border: 'solid 1px #0000001f',
        borderRadius: 10,
        position: 'relative',
      }}
    >
      <div
        className="bg-white px-2 row text-capitalize"
        style={{
          position: 'relative',
          top: -20,
          left: 30,
          width: 'fit-content',
        }}
      >
        {localize('tierDetails')}
      </div>
      <form onSubmit={handleAddDisbursementFactor}>
        <div className="row" style={{ marginTop: -10 }}>
          {editMode && (
            <div className="col d-flex align-items-center">
              <label className="text-capitalize me-3">
                {localize('standingOrderTrigger')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="StandingOrderTrigger"
                value={standingOrderTrigger}
                onChange={s => setstandingOrderTrigger(s.value)}
              />
            </div>
          )}
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">
              {localize('disbursementDay')} ({localize('lowerLimit')})
            </label>
            <MaskNumber
              defaultMaskValue={limit || 0}
              onMaskNumber={e => setLimit(e.target.value)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">
              {localize('gracePeriod')}
            </label>
            <MaskNumber
              defaultMaskValue={gracePeriod || 0}
              onMaskNumber={e => setGracePeriod(e.target.value)}
            />
          </div>
        </div>
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2"
          style={{ borderRadius: 5 }}
        >
          <div className="d-flex align-items-center">
            <Checkbox
              id="editEmployerCapitalizeInterest"
              checked={capitalizeInterest}
              onCheck={setCapitalizeInterest}
            />
            <label
              htmlFor="editEmployerCapitalizeInterest"
              className="text-capitalize ms-2"
            >
              {localize('capitalizeInterest')} ({localize('prorated')})?
            </label>
          </div>
          {editMode && (
            <div className="d-flex align-items-center mx-3">
              <Checkbox
                id="editEmployerrecoverInterestUpfront"
                checked={recoverInterestUpfront}
                onCheck={setRecoverInterestUpfront}
              />
              <label
                htmlFor="editEmployerrecoverInterestUpfront"
                className="text-capitalize ms-2"
              >
                {localize('recoverInterestUpfront')} ({localize('prorated')})?
              </label>
            </div>
          )}
          <button className="btn btn-success text-uppercase ms-3 px-4">
            {localize('add')}
          </button>
        </div>
      </form>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            width: 'fit-content',
            marginBottom: -10,
          }}
        >
          {localize('tiers')}
        </div>

        <SimpleTable
          columns={editMode ? editColumns : addColumns}
          data={dFac}
          contextMenu={r => (
            <>
              <div className="ctxt-menu-item">
                <i className="uil uil-edit-alt"></i>
                <span className="text-capitalize">{localize('edit')}</span>
              </div>
              <div className="ctxt-menu-item text-danger">
                <i className="uil uil-edit-alt"></i>
                <span className="text-capitalize">{localize('delete')}</span>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default EditEmployersModal
