/** @format */
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../Global/Localize'
import { SystemTransactionCode } from '../../../../../Global/enumeration'
import { BiometricExemptionsDetails } from './BiometricExemptionsDetails'

export const ModifyBiometricExemptionsDetails = ({
  onAddBiometricExemption,
  onRemoveBiometricExemption,
  setBiometricExemptionModel,
  biometricExemptionModel,
  defaultTransactionCode,
  setDefaultTransactionCode,
  selectedBiometricExemptions,
}) => {
  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 200 }}
        >
          {localize('biometricExemption')}
          {localize('details')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">
              {localize('exemptionType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="SystemTransactionCode"
              value={defaultTransactionCode}
              onChange={e => {
                setBiometricExemptionModel({
                  ...biometricExemptionModel,
                  transactionCode: e.value,
                  transactionCodeDescription:
                    SystemTransactionCode[e.value].name,
                })
                setDefaultTransactionCode(e.value)
              }}
            />
          </div>
        </div>
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2 mt-4"
          style={{ borderRadius: 5 }}
        >
          <button
            onClick={onAddBiometricExemption}
            className="btn btn-success text-uppercase ms-3 px-4"
          >
            {localize('add')}
          </button>
        </div>

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 200,
              marginBottom: -10,
            }}
          >
            {localize('biometricExemptionPreferences')}
          </div>
          <BiometricExemptionsDetails
            onRemoveBiometricExemptionDetail={r => {
              onRemoveBiometricExemption(r)
            }}
            addMode
            data={selectedBiometricExemptions}
          />
        </div>
      </div>
    </>
  )
}
