/** @format */

/* eslint-disable */
import React, { useEffect } from 'react'
import { postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../../Global/Localize'
import MonthlyBalanceSpoolLookup from '../../../../../../Components/Lookup/MonthlyBalanceSpoolLookup'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { QueuePriority, months } from '../../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
function CreateAccruedInterestDataModal({
  mode,
  selectedAccruedInterest,
  setSelectedAccruedInterest,
  loadData,
  close,
}) {
  async function handleSubmit() {
    const { success } = await postData(
      '/AccruedInterestBatch/create-accrued-interest-batch',
      selectedAccruedInterest,
      true,
      'Operation Completed Successfully'
    )
    if (success) {
      close()
      loadData()
    }
  }

  function closeModal() {
    close(null)
  }

  useEffect(() => {
    setSelectedAccruedInterest({
      month: Object.keys(months)[0],
      priority: Object.keys(QueuePriority)[0],
    })
  }, [])

  return (
    <>
      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle="accruedInterestBatch"
          handleClose={closeModal}
          modalClassName="modal-xl"
          handleAction={handleSubmit}
          actionText={mode === 'add' ? 'create' : ''}
          footerChildren={
            <>
              <Checkbox
                id="accruedInterestIgnoreNegativeYield"
                onCheck={e => {
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    ignoreNegativeYield: e,
                  })
                }}
                checked={selectedAccruedInterest?.ignoreNegativeYield}
              />
              <label
                htmlFor="accruedInterestIgnoreNegativeYield"
                className="text-capitalize"
              >
                {localize('ignoreNegativeYield')}?
              </label>
            </>
          }
        >
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('monthlyBalanceSpool')}
              </label>
              <MonthlyBalanceSpoolLookup
                displayValue={
                  selectedAccruedInterest?.customerAccountBalanceSpoolReference
                }
                onSelect={r => {
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    customerAccountBalanceSpoolId: r.id,
                    customerAccountBalanceSpoolReference: r.reference,
                  })
                }}
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('referenceMonth')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="Month"
                value={selectedAccruedInterest?.monthDescription}
                onChange={e =>
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    month: e.value,
                  })
                }
              />
            </div>

            <div className="col d-flex align-items-center ms-1 ">
              <label className="col-3 text-capitalize">
                {localize('valueDate')}
              </label>
              <CustomDatePicker
                defaultDate={selectedAccruedInterest?.valueDate}
                name={'valueDate'}
                onDateChange={date => {
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    valueDate: date,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('priority')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="QueuePriority"
                value={selectedAccruedInterest?.priorityDescription}
                onChange={e =>
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    priority: e.value,
                  })
                }
              />
            </div>

            <div className="col-8 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedAccruedInterest?.reference || ''}
                onChange={e => {
                  setSelectedAccruedInterest({
                    ...selectedAccruedInterest,
                    reference: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  )
}
export default CreateAccruedInterestDataModal
