/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatDate, parseDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import {
  funeralRiderClaimType,
  originationVerificationAuthorizationStatus,
} from '../../../../../Global/enumeration'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import Tab from '../../../../../Components/Tabs/Tab'
import FuneralRiderSinatories from './FuneralRiderSinatories'
import FuneralRiderClaimantDetails from './FuneralRiderClaimantDetails'
import FuneralRiderSpouseDetails from './FuneralRiderSpouseDetails'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import MembershipWithdrawalNotificationLookup from '../../../../../Components/Lookup/MembershipWithdrawalNotificationLookup'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import Swal from 'sweetalert2'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function FuneralRiderClaims() {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('claimType'),
      template: r => <>{r.claimTypeDescription}</>,
      sortBy: 'claimTypeDescription',
    },

    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
      sortBy: 'customerFullName',
    },
    {
      label: localize('identityNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
      sortBy: 'customerIndividualIdentityCardNumber',
    },
    {
      label: localize('account'),
      template: r => <>{r.customerReference1}</>,
      sortBy: 'customerReference1',
    },
    {
      label: localize('membership'),
      template: r => <>{r.customerReference2}</>,
      sortBy: 'customerReference2',
    },
    {
      label: localize('personalFile'),
      template: r => <>{r.customerReference3}</>,
      sortBy: 'customerReference3',
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.funeralRiderClaimantPersonalFileNumber}</>,
      sortBy: 'funeralRiderClaimantPersonalFileNumber',
    },
    {
      label: localize('identityNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
      sortBy: 'customerIndividualIdentityCardNumber',
    },
    {
      label: localize('name'),
      template: r => <>{r.funeralRiderClaimantName}</>,
      sortBy: 'funeralRiderClaimantName',
    },
    {
      label: localize('mobileNumber'),
      template: r => <>{r.funeralRiderClaimantMobileNumber}</>,
      sortBy: 'funeralRiderClaimantMobileNumber',
    },
    {
      label: localize('relationship'),
      template: r => <>{r.funeralRiderClaimantRelationship}</>,
      sortBy: 'funeralRiderClaimantRelationship',
    },
    {
      label: localize('signatureDate'),
      template: r => <>{formatDate(r.funeralRiderClaimantSignatureDate)}</>,
      sortBy: 'funeralRiderClaimantSignatureDate',
    },
    {
      label: localize('immediateSuperiorIdNumber'),
      template: r => <>{r.immediateSuperiorIdentityCardNumber}</>,
      sortBy: 'immediateSuperiorIdentityCardNumber',
    },
    {
      label: localize('immediateSuperiorName'),
      template: r => <>{r.immediateSuperiorName}</>,
      sortBy: 'immediateSuperiorName',
    },
    {
      label: localize('immediateSuperiorSignatureDate'),
      template: r => <>{formatDate(r.immediateSuperiorSignatureDate)}</>,
      sortBy: 'immediateSuperiorSignatureDate',
    },
    {
      label: localize('areaChiefName'),
      template: r => <>{r.areaChiefName}</>,
      sortBy: 'areaChiefName',
    },
    {
      label: localize('areaChiefIdentityCardNumber'),
      template: r => <>{r.areaChiefIdentityCardNumber}</>,
      sortBy: 'areaChiefIdentityCardNumber',
    },
    {
      label: localize('areaChiefSignatureDate'),
      template: r => <>{formatDate(r.areaChiefSignatureDate)}</>,
      sortBy: 'areaChiefSignatureDate',
    },
    {
      label: localize('areaChiefDelegateName'),
      template: r => <>{r.areaDelegateName}</>,
      sortBy: 'areaDelegateName',
    },
    {
      label: localize('areaChiefDelegateIdNumber'),
      template: r => <>{r.areaDelegateIdentityCardNumber}</>,
      sortBy: 'areaDelegateIdentityCardNumber',
    },
    {
      label: localize('areaChiefDelegatePfNumber'),
      template: r => <>{r.areaDelegatePersonalFileNumber}</>,
      sortBy: 'areaDelegatePersonalFileNumber',
    },
    {
      label: localize('areaChiefDelegateSignatureDate'),
      template: r => <>{formatDate(r.areaDelegateSignatureDate)}</>,
      sortBy: 'areaDelegateSignatureDate',
    },
    {
      label: localize('areaBoardMemberName'),
      template: r => <>{r.areaBoardMemberName}</>,
      sortBy: 'areaBoardMemberName',
    },
    {
      label: localize('areaBoardMemberIdNumber'),
      template: r => <>{r.areaBoardMemberIdentityCardNumber}</>,
      sortBy: 'areaBoardMemberIdentityCardNumber',
    },
    {
      label: localize('areaBoardMemberPfNumber'),
      template: r => <>{r.areaBoardMemberPersonalFileNumber}</>,
      sortBy: 'areaBoardMemberPersonalFileNumber',
    },
    {
      label: localize('areaBoardMemberSignatureDate'),
      template: r => <>{formatDate(r.areaBoardMemberSignatureDate)}</>,
      sortBy: 'areaBoardMemberSignatureDate',
    },
    {
      label: localize('claimDate'),
      template: r => <>{formatDate(r.claimDate)}</>,
      sortBy: 'claimDate',
    },
    {
      label: localize('dateOfDeath'),
      template: r => <>{formatDate(r.dateOfDeath)}</>,
      sortBy: 'dateOfDeath',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 0,
    startDate: null,
    endDate: null,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedFuneralRiderClaim, setSelectedFuneralRiderClaim] =
    useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [customer, setCustomer] = useState({})

  async function handleSubmit() {
    setIsBusy(true)
    setRefreshAt(true)
    const url =
      mode === 'add'
        ? '/FuneralRiderClaim/add-funeral-rider-claim'
        : '/FuneralRiderClaim/update-funeral-rider-claim'
    const { success } = await postData(
      url,
      {
        ...selectedFuneralRiderClaim,
        customerId: customer?.customerId,
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setMode(null)
      setSelectedFuneralRiderClaim(null)
      setCustomer({})
      setRefreshAt(true)
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedFuneralRiderClaim(null)
    setCustomer({})
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/FuneralRiderClaim/find-funeral-rider-claims-by-filter-and-date-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
    }
    setIsBusy(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedFuneralRiderClaim({
      claimType: Object.keys(funeralRiderClaimType)[0],
      claimDate: parseDate(selectedFuneralRiderClaim?.claimDate),
      dateOfDeath: parseDate(selectedFuneralRiderClaim?.dateOfDeath),
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('funeralRiderClaims')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <AddNewButton handleClickAddButton={handleAdd} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center col-2">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex align-items-center col-2">
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="OriginationVerificationAuthorizationStatus"
                            value={reqParams?.status}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                status: e.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <label
                              className="text-capitalize col-md-3"
                              htmlFor="searchByDate"
                            >
                              {localize('dateRange')}
                            </label>
                            <DatePicker
                              onSelect={v => {
                                if (v.custom) {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: true,
                                    startDate: v.startDate,
                                    endDate: v.endDate,
                                  })
                                } else {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: false,
                                    dateRequestsFilter: v.value,
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        <form
                          className="d-flex align-items-center col-5 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              text: search,
                            })
                          }}
                        >
                          <label
                            className="text-capitalize ms-2"
                            htmlFor="searchTable"
                          >
                            {localize('search')}
                          </label>
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.text}
                            onChange={e => {
                              setSearch(e.target.value)
                              setReqParams({
                                ...reqParams,
                                text: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="funeralRiderClaimFilterButton"
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable
                          contextMenu={r => (
                            <>
                              <div
                                onClick={async () => {
                                  setMode('edit')
                                  setSelectedFuneralRiderClaim(r)
                                  setCustomer(r)
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-edit-alt"></i>
                                {localize('edit')}
                              </div>
                              <div
                                onClick={async () => {
                                  setMode('view')
                                  setSelectedFuneralRiderClaim(r)
                                  setCustomer(r)
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-eye"></i>
                                {localize('view')}
                              </div>
                            </>
                          )}
                          data={tableData}
                          columns={columns}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize(mode)}
            <i className="uil uil-angle-right mx-2"></i>
            <span className="text-muted fs-6 text-uppercase">
              {localize('funeralRiderClaims')}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white">
            <div className="row">
              <div className="col-12">
                <div className="card bg-white">
                  <div className="card-body">
                    <>
                      <div className="row mb-2">
                        <div className="col d-flex align-items-center">
                          <label className="text-capitalize col-3 me-3">
                            {localize('claimType')}
                          </label>
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="FuneralRiderClaimType"
                            value={
                              selectedFuneralRiderClaim?.claimTypeDescription
                            }
                            onChange={e =>
                              setSelectedFuneralRiderClaim({
                                ...selectedFuneralRiderClaim,
                                claimType: e.value,
                              })
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('claimDate')}
                          </label>
                          <CustomDatePicker
                            disabled={mode === 'view'}
                            defaultDate={selectedFuneralRiderClaim?.claimDate}
                            name={'funeralRiderClaimantSignatureDate'}
                            onDateChange={date => {
                              setSelectedFuneralRiderClaim({
                                ...selectedFuneralRiderClaim,
                                claimDate: date,
                              })
                            }}
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('deathDate')}
                          </label>
                          <CustomDatePicker
                            disabled={mode === 'view'}
                            defaultDate={selectedFuneralRiderClaim?.dateOfDeath}
                            name={'funeralRiderClaimantSignatureDate'}
                            onDateChange={date => {
                              setSelectedFuneralRiderClaim({
                                ...selectedFuneralRiderClaim,
                                dateOfDeath: date,
                              })
                            }}
                          />
                        </div>
                      </div>

                      <div className="row g-3 mb-2">
                        <div
                          className="col d-flex align-items-center with-validation"
                          validation-message={
                            globalValidationErrors['CustomerId']?.message
                          }
                        >
                          <label className="col-4 text-capitalize">
                            {localize('customerName')}
                          </label>
                          {mode === 'view' ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={customer?.customerFullName || ''}
                              />
                            </>
                          ) : (
                            <>
                              <MembershipWithdrawalNotificationLookup
                                displayValue={customer?.customerFullName}
                                handleSelect={r => {
                                  setCustomer({})
                                  if (r?.status != 32 || r?.category != 1792) {
                                    Swal.fire(
                                      'Withdrawal Notification',
                                      `Status is ${r?.statusDescription} or Termination Category is ${r?.categoryDescription}`,
                                      'info'
                                    )
                                  } else {
                                    clearValidation('CustomerId')
                                    setCustomer(r)
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('customerType')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              customer?.customerMembershipClassCustomerTypeDescription ||
                              ''
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('membershipStatus')}
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={
                              customer?.customerMembershipStatusDescription ||
                              ''
                            }
                          />
                        </div>
                      </div>
                      <div className="row g-3 mb-2">
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('membershipWdr.Date')}
                          </label>
                          <CustomDatePicker
                            defaultDate={
                              customer?.customerMembershipTerminationDate
                            }
                            disabled
                            name={'membershipTerminationDate'}
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('domicileBranch')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={customer?.customerBranchDescription || ''}
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('employerName')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={customer?.customerEmployerDescription || ''}
                          />
                        </div>
                      </div>
                      <div className="row g-3 mb-2">
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('dutyWorkStation')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              customer?.customerDutyStationDescription || ''
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('adminDivision')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              customer?.customerAdministrativeDivisionDescription ||
                              ''
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('identificationNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={customer?.customerIdentificationNumber || ''}
                          />
                        </div>
                      </div>
                      <div className="row mb-2 g-3">
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('memberNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={
                              customer?.formattedCustomerMemberNumber || ''
                            }
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('accountNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={customer?.paddedCustomerAccountNumber || ''}
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <label className="col-4 text-capitalize">
                            {localize('personalFileNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={customer?.customerPersonalFileNumber || ''}
                          />
                        </div>
                      </div>
                      <div className="row g-3">
                        <div className="col-md-4">
                          <div className=" d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('transactionReference')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customer?.transactionReferenceDescription || ''
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className=" d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('terminationCategory')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={customer?.categoryDescription || ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('terminationRemarks')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={customer?.remarks || ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('createdBy')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={customer?.createdBy || ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('createdDate')}
                            </label>
                            <CustomDatePicker
                              defaultDate={customer?.createdDate || ''}
                              name={'createdDate'}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('status')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={customer?.statusDescription || ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row g-2 mt-1">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center mb-2">
                            <label className="col-1 text-capitalize me-3">
                              {localize('remarks')}
                            </label>
                            <input
                              defaultValue={selectedFuneralRiderClaim?.remarks}
                              type="text"
                              className="form-control ms-3"
                              onChange={e => {
                                setSelectedFuneralRiderClaim({
                                  ...selectedFuneralRiderClaim,
                                  remarks: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>

                    <div className="row mb-3">
                      <div className="col md-12">
                        <Tab
                          tabItems={[
                            {
                              label: localize('signatories'),
                              item: (
                                <FuneralRiderSinatories
                                  customer={selectedFuneralRiderClaim}
                                  setSelectedFuneralRiderClaim={
                                    setSelectedFuneralRiderClaim
                                  }
                                  immediateSuperiorIdentityCardNoValidationMessage={
                                    globalValidationErrors[
                                      'ImmediateSuperiorIdentityCardNumber'
                                    ]?.message
                                  }
                                  immediateSuperiorNameNoValidationMessage={
                                    globalValidationErrors[
                                      'ImmediateSuperiorName'
                                    ]?.message
                                  }
                                  areaChiefNameValidationMessage={
                                    globalValidationErrors['AreaChiefName']
                                      ?.message
                                  }
                                  areaDelegateNameValidationMessage={
                                    globalValidationErrors['AreaDelegateName']
                                      ?.message
                                  }
                                  areaBoardMemberNameValidationMessage={
                                    globalValidationErrors[
                                      'AreaBoardMemberName'
                                    ]?.message
                                  }
                                  clearValidation={clearValidation}
                                  mode={mode}
                                />
                              ),
                            },
                            selectedFuneralRiderClaim.claimType == 1
                              ? {
                                  label: localize('claimantDetails'),
                                  item: (
                                    <FuneralRiderClaimantDetails
                                      customer={selectedFuneralRiderClaim}
                                      setSelectedFuneralRiderClaim={
                                        setSelectedFuneralRiderClaim
                                      }
                                      claimantNameValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantName'
                                        ]?.message
                                      }
                                      claimantPfNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantIdentityCardNumber'
                                        ]?.message
                                      }
                                      claimantMobileNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantMobileNumber'
                                        ]?.message
                                      }
                                      claimantIdNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantIdentityCardNumber'
                                        ]?.message
                                      }
                                      claimantRelationshipValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantRelationship'
                                        ]?.message
                                      }
                                      clearValidation={clearValidation}
                                      mode={mode}
                                    />
                                  ),
                                }
                              : {
                                  label: localize('spouseDetails'),
                                  item: (
                                    <FuneralRiderSpouseDetails
                                      customer={selectedFuneralRiderClaim}
                                      setSelectedFuneralRiderClaim={
                                        setSelectedFuneralRiderClaim
                                      }
                                      claimantNameValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantName'
                                        ]?.message
                                      }
                                      claimantPfNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantIdentityCardNumber'
                                        ]?.message
                                      }
                                      claimantMobileNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantMobileNumber'
                                        ]?.message
                                      }
                                      claimantIdNumberValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantIdentityCardNumber'
                                        ]?.message
                                      }
                                      claimantRelationshipValidationMessage={
                                        globalValidationErrors[
                                          'FuneralRiderClaimantRelationship'
                                        ]?.message
                                      }
                                      clearValidation={clearValidation}
                                      mode={mode}
                                    />
                                  ),
                                },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="bg-white">
            <>
              <button
                className="btn btn-danger text-capitalize"
                onClick={closeModal}
              >
                {localize('cancel')}
              </button>
              <button
                className="btn btn-success text-capitalize"
                onClick={handleSubmit}
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default FuneralRiderClaims
