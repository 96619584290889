/** @format */

import { useEffect, useState } from 'react'
import { batchAuthOptions } from '../../../../../../Global/enumeration'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { putData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { AuthorizeCreditBatchesModal } from './AuthorizeCreditBatchesModal'
import { ViewCreditBatchesVerificationModal } from './ViewCreditBatchesAuthorizationModal'

function CreditBatchesAuthorizationModal({
  mode,
  onHideModal,
  selectedBatchEntry,
}) {
  const [verificationModels, setVerificationModels] = useState({
    authOption: Object.keys(batchAuthOptions)[0],
  })

  const [gbv, setGbv] = GlobalValidationErrors.use()

  useEffect(() => {
    setGbv({})
  }, [mode])

  const onAuthorizeCreditBatch = async () => {
    await putData(
      '/CreditBatch/authorize-credit-batch',
      {
        CreditBatch: {
          ...selectedBatchEntry,
          authorizationRemarks: verificationModels.authorizationRemarks,
        },
        BatchAuthOption: verificationModels.authOption,
      },
      true,
      'Authorize Credit Batch'
    )
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('creditBatch')}
            handleAction={onAuthorizeCreditBatch}
            hideUpdate={mode === 'view'}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'view' && (
              <ViewCreditBatchesVerificationModal
                selectedCreditBatch={selectedBatchEntry}
              />
            )}
            {mode === 'authorize' && (
              <AuthorizeCreditBatchesModal
                verificationModels={verificationModels}
                setVerificationModels={setVerificationModels}
                selectedCreditBatch={selectedBatchEntry}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default CreditBatchesAuthorizationModal
