/** @format */

import React from 'react'
import { useState } from 'react'
import { postData, putData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../../Helpers/formatters'

const CreateAssetType = ({
  mode,
  setMode,
  selectedAssetType,
  setSelectedAssetType,
  refreshAt,
  setRefreshAt,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [showError, setShowError] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setIsBusy(true)
    const url = '/AssetType/add-asset-type'
    const { success } = await postData(url, selectedAssetType, false)

    if (success) {
      showNotification('Asset Type', 'success')
      setMode(null)
      setSelectedAssetType(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)

    const url = '/AssetType/update-asset-type'
    const { success } = await putData(
      url,
      selectedAssetType,
      true
    )
    if (success) {
      showNotification('Asset Type', 'success')
      setMode(null)
      setSelectedAssetType(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }

  function handleClose() {
    setMode(null)
    setSelectedAssetType(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('assetType')}
        handleClose={handleClose}
        handleAction={mode === 'add' ? handleSubmit : handleEdit}
        modalClassName="modal-xl"
        footerChildren={
          <div className=" d-flex align-items-center">
            <Checkbox
              id="cAssetTypeIsTangible"
              onCheck={e => {
                setSelectedAssetType({
                  ...selectedAssetType,
                  isTangible: e,
                })
              }}
              checked={selectedAssetType?.isTangible}
            />
            <label htmlFor="cAssetTypeIsTangible" className="text-capitalize">
              {localize('isTangible') + '?'}
            </label>
          </div>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-3 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    GlobalValidationErrors['Description']?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('name')}
                  </label>
                  <input
                    defaultValue={selectedAssetType?.description}
                    type="text"
                    className="form-control"
                    onChange={e => {
                      clearValidation('Description')
                      setSelectedAssetType({
                        ...selectedAssetType,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('depreciation')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="DepreciationMethod"
                    value={selectedAssetType?.depreciationMethod}
                    onChange={e =>
                      setSelectedAssetType({
                        ...selectedAssetType,
                        depreciationMethod: e.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['ChartOfAccountAccountName']?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('g/LAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={selectedAssetType?.chartOfAccountAccountName}
                    onSelect={r => {
                      clearValidation('ChartOfAccountAccountName')
                      setSelectedAssetType({
                        ...selectedAssetType,
                        chartOfAccountId: r?.id,
                        chartOfAccountAccountName: r?.description,
                      })
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'DepreciationExpenseAccountAccountName'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('depreciationExpenseChartOfAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={
                      selectedAssetType?.depreciationExpenseAccountAccountName
                    }
                    onSelect={r => {
                      clearValidation('DepreciationExpenseAccountAccountName')
                      setSelectedAssetType({
                        ...selectedAssetType,
                        depreciationExpenseAccountId: r?.id,
                        depreciationExpenseAccountAccountName: r?.description,
                      })
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'AccumulatedDepreciationAccountAccountName'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('accumulatedDepreciationChartOfAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={
                      selectedAssetType?.accumulatedDepreciationAccountAccountName
                    }
                    onSelect={r => {
                      clearValidation(
                        'AccumulatedDepreciationAccountAccountName'
                      )
                      setSelectedAssetType({
                        ...selectedAssetType,
                        accumulatedDepreciationAccountId: r?.id,
                        accumulatedDepreciationAccountAccountName:
                          r?.description,
                      })
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['GainLossAccountAccountName']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('gainLossChartOfAccountName')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={selectedAssetType?.gainLossAccountAccountName}
                    onSelect={r => {
                      clearValidation('GainLossAccountAccountName')
                      setSelectedAssetType({
                        ...selectedAssetType,
                        gainLossAccountId: r?.id,
                        gainLossAccountAccountName: r?.description,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('usefulLife')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={selectedAssetType?.usefulLife}
                    className={`
                       ${showError && 'border border-danger'}
                      `}
                    numeralThousandsGroupStyle="none"
                    onMaskNumber={c => {
                      if (Number(c.target.value) > 100) {
                        setShowError(true)
                      } else {
                        setShowError(false)
                      }
                      setSelectedAssetType({
                        ...selectedAssetType,
                        usefulLife: stripNonNumericCharacters(c.target.value),
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateAssetType
