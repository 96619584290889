/** @format */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import GoodsReceivedNoteEntriesItems from './GoodsReceivedNoteEntriesItems'
import GoodsReceivedNoteApprovals from './GoodsReceivedNoteApprovals'

const ViewGoodsReceivedNote = ({ mode, closeModal, goodsReceivedNote }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [
    goodsReceivedNoteApprovalEntries,
    setGoodsReceivedNoteApprovalEntries,
  ] = useState([])
  const [totalCost, setTotalCost] = useState(null)

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsReceivedNote/find-goods-received-note-items-by-goods-received-note-id-filter-in-page`,
      {
        goodsReceivedNoteId: goodsReceivedNote?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
      // var costTotal = 0
      // for (let i = 0; i < data.pageCollection.itemsCount; i++) {
      //   costTotal += data.pageCollection[i].totalCost
      // }
      setTotalCost(data.pageCollection[0].totalCost)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const loadApprovalEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/GoodsReceivedNote/find-goods-received-notes-approvals',
      {
        goodsReceivedNoteId: goodsReceivedNote?.id,
      },
      false
    )
    if (success) {
      setGoodsReceivedNoteApprovalEntries(data.result)
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    loadApprovalEntries()
    // eslint-disable-next-line
  }, [goodsReceivedNote?.id, reqParams.pageSize, reqParams.pageIndex])

  const closePage = () => {
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={'view'}
      modalTitle="goodsReceivedNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={goodsReceivedNote?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="text-capitalize col-md-2">
                {localize('supplier')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('location')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.locationDescription}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('createdDate')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={formatDate(goodsReceivedNote?.createdDate)}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('goodsReceivedNoteItems'),
                  item: (
                    <GoodsReceivedNoteEntriesItems
                      mode={mode}
                      entries={existingEntries}
                      totalCost={totalCost}
                    />
                  ),
                },
                {
                  label: localize('approvals'),
                  item: (
                    <GoodsReceivedNoteApprovals
                      entries={goodsReceivedNoteApprovalEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewGoodsReceivedNote
