/** @format */

import Loader from '../../../../../Components/Loaders/Loader'
import { CreateMicroCreditGroup } from './CreateMicroCreditGroup'
import { GroupMembersEntriesEditMode } from './GroupMembersEntries'
import { useEffect, useState } from 'react'
import { MicroCreditGroupMemberDesignation } from '../../../../../Helpers/constants'
import { getData } from '../../../../../Helpers/webApi'
import { EditMemberDetails } from './EditMemberDetails'

export const EditMicroCreditGroupContainer = ({
  selectedMicroCreditGroup,
  setSelectedMicroCreditGroup,
  disableCreate,
  mode,
}) => {
  const [microCreditGroupMemberEntries, setMicroCreditGroupMemberEntries] =
    useState([])

  const [microCreditGroupMember, setMicroCreditGroupMember] = useState({
    microCreditGroupMemberDesignation:
      MicroCreditGroupMemberDesignation.ORDINARY_MEMBER,
  })

  const fetchMicroCreditGroupMembers = async () => {
    const { success, data } = await getData(
      '/MicroCreditGroup/find-micro-credit-group-members-by-micro-credit-group-id',
      { microCreditGroupId: selectedMicroCreditGroup?.id },
      false,
      null,
    )
    if (success) {
      setMicroCreditGroupMemberEntries(data.result)
    }
  }

  useEffect(() => {
    if (selectedMicroCreditGroup?.id) {
      fetchMicroCreditGroupMembers()
    }
  }, [])

  return (
    <>
      {disableCreate ? (
        <Loader />
      ) : (
        <>
          <CreateMicroCreditGroup
            microCreditGroupModel={selectedMicroCreditGroup}
            setMicroCreditGroupModel={setSelectedMicroCreditGroup}
          />
          <EditMemberDetails
            selectedMicroCreditGroup={selectedMicroCreditGroup}
            onAddNew={() => {
              fetchMicroCreditGroupMembers()
            }}
            microCreditGroupMemberEntries={microCreditGroupMemberEntries}
            setMicroCreditGroupMemberEntries={setMicroCreditGroupMemberEntries}
            microCreditGroupMember={microCreditGroupMember}
            setMicroCreditGroupMember={setMicroCreditGroupMember}
          />
          <GroupMembersEntriesEditMode
            onRemove={() => {
              fetchMicroCreditGroupMembers()
            }}
            entries={microCreditGroupMemberEntries}
          />
        </>
      )}
    </>
  )
}
