/** @format */
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { LoanRepaymentModeMappings } from './LoanRepaymentModeMappings'
import React from 'react'

export const ViewLoanRepaymentModeModal = ({
  waitingMetadata,
  repaymentModeMappings,
  selectedLoanRepaymentMode,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center ">
          <label className="col-2 text-capitalize">{localize('name')}</label>
          <input
            className="form-control"
            defaultValue={selectedLoanRepaymentMode?.description}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('standingOrderTrigger')}
          </label>
          <input
            className="form-control"
            value={selectedLoanRepaymentMode?.standingOrderTriggerDescription}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('creditType')}
          </label>
          <input
            value={selectedLoanRepaymentMode?.creditTypeDescription}
            disabled
            className="form-control"
          />
        </div>
      </div>
      <Tab
        tabItems={[
          {
            label: localize('defaultMappings'),
            item: (
              <LoanRepaymentModeMappings
                waitingMetadata={waitingMetadata}
                repaymentModeMappings={repaymentModeMappings}
              />
            ),
          },
        ]}
      />
    </>
  )
}
