/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import LoanPurposeLookup from '../../../../../Components/Lookup/LoanPurposeLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Tab from '../../../../../Components/Tabs/Tab'
import GuarantorsPartial from './GuarantorsPartial'
import {
  formatAsPercent,
  formatCurrency,
  parseDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import CustomerPayoutHistory from '../PreQualification/CustomerPayoutHistory'
import CustomerSalaryHistory from '../PreQualification/CustomerSalaryHistory'
import { evaluateLatestIncome } from '../../../../../Helpers/Modules/customer'
import {
  evaluateLoanGuarantorCommitments,
  findLoanDisbursementModeByCustomer,
} from '../../../../../Helpers/Modules/loan'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import LoanDisbursementModeLookup from '../../../../../Components/Lookup/LoanDisbursementModeLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import LoaningRemarksLookup from '../../../../../Components/Lookup/LoaningRemarksLookup'
import { getData, postData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'

function LoanSection({ customer }) {
  const [gve, setGve] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setGve(g => {
      delete g[column]
      return g
    })
  }

  const [loanProduct, setLoanProduct] = useState(null)
  const [loaneeCommitments, setLoaneeCommitments] = useState(null)
  const [loanPurpose, setLoanPurpose] = useState(null)
  const [disbursementMode, setDisbursementMode] = useState(null)
  const [customerIsAdministrative, setCustomerIsAdministrative] =
    useState(false)
  const [latestIncome, setLatestIncome] = useState(null)
  const [loanTerm, setLoanTerm] = useState(0)
  const [amountApplied, setAmountApplied] = useState(0)
  const [reference, setReference] = useState('')
  const [receivedDate, setReceivedDate] = useState(parseDate(new Date()))
  const [remarks, setRemarks] = useState('')
  const [registrationOption, setRegistrationOption] = useState(1)
  const [isBusy, setIsBusy] = useState(false)
  const [validationResult, setValidationResult] = useState({
    result: false,
    validationMessages: null,
  })

  useEffect(() => {
    setLoanTerm(loanProduct?.loanRegistrationTermInMonths)
  }, [loanProduct])

  useEffect(() => {
    customer?.id && loadDisbursementMode()
    // eslint-disable-next-line
  }, [customer])

  async function loadDisbursementMode() {
    setDisbursementMode(null)

    const d = await findLoanDisbursementModeByCustomer(customer?.id)
    if (d) {
      setDisbursementMode(d)
    }
  }

  useEffect(() => {
    // checkCustomerIsAdmin()
    // eslint-disable-next-line
  }, [customer])

  useEffect(() => {
    loanProduct?.id && customer?.id && getLatestIncome()
    loanProduct?.id && customer?.id && getLoaneeCommitments()
    // eslint-disable-next-line
  }, [customer, loanProduct])

  async function validateLoanProduct(customerId, loanProductId) {
    setLoanProduct(null)
    const { success, data } = await getData(
      '/loanOrigination/validate-loan-product-on-loan-registration',
      { customerId, loanProductId }
    )
    if (success) {
      setValidationResult(data)
      return data?.result
    }
    return false
  }

  async function getLatestIncome() {
    let income = await evaluateLatestIncome(customer?.id, loanProduct?.id)
    if (income) {
      setLatestIncome(income)
      setCustomerIsAdministrative(income.isAdministrative)
    }
  }

  async function getLoaneeCommitments() {
    let commitments = await evaluateLoanGuarantorCommitments(
      customer?.id,
      loanProduct?.id,
      customer?.id
    )
    if (commitments) setLoaneeCommitments(commitments)
  }

  async function createLoanCase() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/loanOrigination/add-loan-case',
      {
        loanCase: {
          customerId: customer?.id,
          loanProductId: loanProduct?.id,
          loanPurposeId: loanPurpose?.id,
          loanDisbursementModeId: disbursementMode?.id,
          loanRegistrationTermInMonths: loanTerm,
          amountApplied,
          reference,
          remarks,
          receivedDate,
        },
        loanRegistrationOption: registrationOption,
      },
      false
    )

    if (success) {
      const payloadString = `Serial Number: ${data.paddedSerialNumber}<br />Loan Number: ${data.documentReferenceNumber}`

      Swal.fire({
        title: 'Operation Completed Successfully',
        html: payloadString,
        icon: 'success',
      })
    }
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('loanGuarantors'),
      item: <GuarantorsPartial />,
      icon: 'uil uil-edit-alt',
      forAll: true,
    },
    {
      label: localize('loanCollaterals'),
      item: <>Guarantors</>,
      icon: 'uil uil-edit-alt',
      forAll: true,
    },
    {
      label: localize('fixedDeposits'),
      item: <>Guarantors</>,
      icon: 'uil uil-edit-alt',
      forAll: true,
    },
    {
      label: localize('existingGuarantors'),
      item: <>Guarantors</>,
      forAll: true,
    },
    {
      label: localize('payoutsHistory'),
      item: (
        <CustomerPayoutHistory
          customer={customer}
          loanProduct={loanProduct}
          onGetPayoutHistories={() => {}}
        />
      ),
      forAll: true,
    },
    {
      label: localize('salaryHistory'),
      item: (
        <CustomerSalaryHistory
          customer={customer}
          loanProduct={loanProduct}
          onGetLatestIncome={v => {}}
        />
      ),
      forAll: false,
    },
  ]

  return customer ? (
    isBusy ? (
      <Loader />
    ) : (
      <div>
        <div className="row mt-3">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanProduct')}
            </label>
            <LoanProductLookup
              displayValue={loanProduct?.description}
              onSelect={async l => {
                let passed = await validateLoanProduct(customer?.id, l?.id)
                if (passed) setLoanProduct(l)
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanProductSection')}
            </label>
            <input
              type="text"
              className="form-control"
              value={loanProduct?.loanRegistrationLoanProductSectionDescription}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('annualPercentageRate')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'right' }}
              value={formatAsPercent(
                loanProduct?.loanInterestAnnualPercentageRate
              )}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('interestCalculationMode')}
            </label>
            <input
              type="text"
              className="form-control"
              value={loanProduct?.loanInterestCalculationModeDescription}
              disabled
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanPurpose')}
            </label>
            <LoanPurposeLookup
              displayValue={loanPurpose?.description}
              onSelect={l => setLoanPurpose(l)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('latestIncome')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
              value={formatCurrency(
                latestIncome?.loanProductLatestIncome,
                2,
                2,
                false
              )}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('depositsBalance')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
              value={formatCurrency(
                loaneeCommitments?.totalShares,
                2,
                2,
                false
              )}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('loanBalance')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
              value={formatCurrency(
                latestIncome?.loanProductLoanBalance,
                2,
                2,
                false
              )}
              disabled
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('disbursementMode')}
            </label>
            <LoanDisbursementModeLookup
              displayValue={disbursementMode?.description || ''}
              onSelect={l => setDisbursementMode(l)}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('maximumAmount')}
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
              value={formatCurrency(
                latestIncome?.loanRegistrationMaximumAmount,
                2,
                2,
                false
              )}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('maximumAmount')} %
            </label>
            <input
              type="text"
              className="form-control"
              style={{ textAlign: 'end' }}
              value={formatAsPercent(
                latestIncome?.loanProductMaximumAmountPercentage
              )}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('receivedDate')}
            </label>
            <CustomDatePicker
              name="loanRegistrationReceivedDate"
              defaultDate={receivedDate}
              onDateChange={d => setReceivedDate(d)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={gve['loanReg.loanTermCheck']}
          >
            <label htmlFor="" className="text-capitalize col-4">
              {localize('term')} ({localize('months')})
            </label>
            <input
              style={{ textAlign: 'end' }}
              type="number"
              className="form-control"
              value={loanTerm}
              onBlur={e => {
                if (
                  Number(e.target.value) >
                  Number(loanProduct?.loanRegistrationTermInMonths)
                ) {
                  setLoanTerm(loanProduct?.loanRegistrationTermInMonths)
                  clearValidation('loanReg.loanTermCheck')
                } else if (Number(e.target.value) <= 0) {
                  setLoanTerm(loanProduct?.loanRegistrationTermInMonths)
                  clearValidation('loanReg.loanTermCheck')
                }
              }}
              onChange={e => {
                clearValidation('loanReg.loanTermCheck')
                if (
                  Number(e.target.value) >
                  Number(loanProduct?.loanRegistrationTermInMonths)
                ) {
                  setGve(g => ({
                    ...g,
                    'loanReg.loanTermCheck': `Loan term should be less than ${loanProduct?.loanRegistrationTermInMonths} months`,
                  }))
                } else if (Number(e.target.value) <= 0) {
                  setGve(g => ({
                    ...g,
                    'loanReg.loanTermCheck': `Loan term should be more than zero!`,
                  }))
                }
                setLoanTerm(e.target.value)
              }}
            />
          </div>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={gve['loanReg.loanAmountCheck']}
          >
            <label htmlFor="" className="text-capitalize col-4">
              {localize('amountApplied')}
            </label>
            <MaskNumber
              value={amountApplied}
              onBlur={e => {
                if (
                  Number(e.target.value) >
                  Number(latestIncome?.loanRegistrationMaximumAmount)
                ) {
                  setAmountApplied(latestIncome?.loanRegistrationMaximumAmount)
                  clearValidation('loanReg.loanAmountCheck')
                } else if (
                  Number(e.target.value) <=
                  loanProduct?.loanRegistrationMinimumAmount
                ) {
                  setAmountApplied(loanProduct?.loanRegistrationMinimumAmount)
                  clearValidation('loanReg.loanAmountCheck')
                }
              }}
              onMaskNumber={e => {
                clearValidation('loanReg.loanAmountCheck')
                if (
                  Number(stripNonNumericCharacters(e.target.value)) >
                  Number(latestIncome?.loanRegistrationMaximumAmount)
                ) {
                  setGve(g => ({
                    ...g,
                    'loanReg.loanAmountCheck': `Loan amount should be less than ${formatCurrency(
                      latestIncome?.loanRegistrationMaximumAmount,
                      2,
                      2,
                      false
                    )}`,
                  }))
                } else if (
                  Number(stripNonNumericCharacters(e.target.value)) <
                  Number(loanProduct?.loanRegistrationMinimumAmount)
                ) {
                  setGve(g => ({
                    ...g,
                    'loanReg.loanAmountCheck': `Loan amount should be more than ${formatCurrency(
                      loanProduct?.loanRegistrationMinimumAmount,
                      2,
                      2,
                      false
                    )}!`,
                  }))
                }
                setAmountApplied(stripNonNumericCharacters(e.target.value))
              }}
            />
          </div>
          <div className="col-6 d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-2">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control"
              value={reference}
              onChange={e => setReference(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <Tab
            preload
            tabItems={
              customerIsAdministrative
                ? tabItems
                : tabItems.filter(x => x.forAll === true)
            }
          />
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-end gap-2 bg-light rounded p-2">
          <label htmlFor="" className="text-capitalize">
            {localize('remarks')}
          </label>
          <div style={{ width: '20rem' }}>
            <LoaningRemarksLookup
              displayValue={remarks}
              onChange={r => setRemarks(r)}
            />
          </div>

          <label htmlFor="" className="ms-2 text-capitalize">
            {localize('registrationAction')}
          </label>
          <EnumsServerCombo
            value={registrationOption}
            enumsKey="LoanRegistrationOption"
            onChange={e => setRegistrationOption(e.value)}
          />
          <button onClick={createLoanCase} className="btn btn-success px-4">
            {localize('update')}
          </button>
        </div>
        {validationResult?.messages &&
          Array.isArray(validationResult?.messages) &&
          validationResult?.messages.length > 0 && (
            <>
              <ActionModal
                modalTitle={localize(
                  validationResult?.result
                    ? 'loanProductValidation'
                    : 'loanProductValidationFailed'
                )}
                hideCloseButton
                confirmText={localize('ok')}
                handleAction={() => {
                  setValidationResult({ ...validationResult, messages: [] })
                }}
              >
                <div className="d-flex align-items-center gap-2">
                  <i
                    className={`uil uil-exclamation-circle fs-1 text-${
                      validationResult?.result ? 'primary' : 'danger'
                    }`}
                  ></i>
                  <ul>
                    {validationResult?.messages.map(m => (
                      <li>{m}</li>
                    ))}
                  </ul>
                </div>
              </ActionModal>
            </>
          )}
      </div>
    )
  ) : (
    <>Select a Customer First</>
  )
}

export default LoanSection
