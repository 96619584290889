/* @format */

import localize from '../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../Helpers/webApi'
import SpecimenMediaItem from '../../../Pages/Registry/Members/Partials/Tabs/SpecimenMediaItem'

export const ViewCustomerSpecimen = ({
  customer,
  readOnly,
  refetchFromServer = false,
}) => {
  const [cust, setCust] = useState(customer)
  const [fetching, setFetching] = useState(false)
  const query = {
    passport: cust?.passportImageId,
    signature: cust?.signatureImageId,
    identityCardFront: cust?.identityCardFrontSideImageId,
    identityCardBack: cust?.identityCardBackSideImageId,
  }

  const [media, setMedia] = useState({
    passport: null,
    signature: null,
    identityCardFront: null,
    identityCardBack: null,
  })

  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    loadAllMedia()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cust])

  useEffect(() => {
    refetchFromServer && refetchCustomerInfo()
    // eslint-disable-next-line
  }, [])

  async function refetchCustomerInfo() {
    setFetching(true)
    const { success, data } = await getData(
      '/customer/find-customer-by-id',
      {
        customerId: customer?.id,
      },
      false
    )
    if (success) {
      setFetching(false)
      setCust(data?.customer)
    } else {
      setFetching(false)
    }
  }

  async function loadAllMedia() {
    for (let i = 0; i < Object.values(query).length; i++) {
      const imageData = await loadMedia(
        Object.values(query)[i],
        Object.keys(query)[i]
      )

      if (imageData) {
        setMedia(media => ({
          ...media,
          [Object.keys(query)[
            i
          ]]: `data:${imageData.ContentType};base64,${imageData.content}`,
        }))
      }
    }
  }

  async function loadMedia(sku, key) {
    if (sku) {
      setIsBusy(key)
      const { success, data } = await getData(
        '/base64/get-media-by-sku',
        {
          sku,
        },
        false
      )
      if (success) {
        setIsBusy(null)
        return data
      } else {
        setIsBusy(null)
        return null
      }
    } else {
    }
  }

  async function uploadImage(sku, content, key) {
    setIsBusy(key)
    const { success } = await postData(
      '/base64/post-image',
      {
        sku,
        content,
      },
      false
    )
    if (success) {
      setIsBusy(false)
    } else {
      setIsBusy(false)
      loadAllMedia()
    }
  }

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <div className="d-flex align-items-center justify-content-between mb-5">
      {Object.entries(query).map(([key, value]) => (
        <div className="spec-item-add-customer">
          <label className="mb-2 text-capitalize">{localize(key)}</label>
          <SpecimenMediaItem
            isBusy={isBusy === key}
            readOnly={readOnly}
            itemKey={key}
            base64={customer ? media[key] : null}
            onChange={(key, photo) => {
              setMedia({ ...media, [key]: photo })
              let spl = photo.split(',')
              let content = spl[1]
              uploadImage(query[key], content, key)
            }}
          />
        </div>
      ))}
    </div>
  )
}
