/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import CustomerInfo from '../PreQualification/Pages/CustomerInfo'
import LoanSection from './LoanSection'
import { getData } from '../../../../../Helpers/webApi'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'

const Registration = () => {
  const [activePage, setActivePage] = useState(0)
  const [customer, setCustomer] = useState(null)

  const [validationResult, setValidationResult] = useState({
    result: false,
    messages: null,
  })

  async function validateCustomer(customerId) {
    setValidationResult({ result: false, messages: null })
    const { success, data } = await getData(
      '/loanOrigination/validate-customer-on-loan-registration',
      { customerId }
    )
    if (success) {
      setValidationResult(data)
    }
  }

  const pages = [
    {
      title: localize('customerInfo'),
      component: (
        <CustomerInfo
          customer={customer}
          onChangeCustomer={c => {
            setCustomer(c)
            validateCustomer(c?.id)
          }}
        />
      ),
      skippable: validationResult?.result,
    },
    {
      title: localize('loanInfo'),
      component: <LoanSection customer={customer} />,
      skippable: true,
    },
  ]
  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanRegistration'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          <Walkthrough
            preload
            pages={pages}
            activePage={activePage}
            changePage={p => setActivePage(p)}
          />
        </div>
      </div>
      {validationResult?.messages &&
        Array.isArray(validationResult?.messages) &&
        validationResult?.messages.length > 0 && (
          <>
            <ActionModal
              modalTitle={localize(
                validationResult?.result
                  ? 'customerValidation'
                  : 'customerValidationFailed'
              )}
              hideCloseButton
              confirmText={localize('ok')}
              handleAction={() => {
                setValidationResult({ ...validationResult, messages: [] })
              }}
            >
              <div className="d-flex align-items-center gap-2">
                <i
                  className={`uil uil-exclamation-circle fs-1 text-${
                    validationResult?.result ? 'primary' : 'danger'
                  }`}
                ></i>
                <ul>
                  {validationResult?.messages.map(m => (
                    <li>{m}</li>
                  ))}
                </ul>
              </div>
            </ActionModal>
          </>
        )}
    </>
  )
}

export default Registration
