/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'

function CustomerTab({
  onSelectedCustomerChange,
  selectedCustomData,
  onSelectAll,
  workStation,
  reloadData,
  toggleReloadData,
  handleReset,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [data, setData] = useState({
    pageCollection: [],
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')

  const columns = [
    {
      label: 'select',
      template: r => (
        <div className="d-flex align-items-center">
          <Checkbox
            id={r?.id}
            checked={selectedCustomData?.find(c => c.id === r?.id)}
            onCheck={v => onSelectedCustomerChange(r, v)}
          />
        </div>
      ),
    },
    {
      label: 'recordStatus',
      template: r => r?.recordStatusDescription,
    },
    {
      label: 'serialNumber',
      template: r => r?.paddedSerialNumber,
    },
    {
      label: 'memberNumber',
      template: r => r?.paddedMemberNumber,
    },
    {
      label: 'customerType',
      template: r => r?.membershipClassCustomerTypeDescription,
    },
    {
      label: 'name',
      template: r => r?.fullName,
    },
    {
      label: ` ${localize('accountNumber')}`,
      template: r => <>{r?.reference1}</>,
    },
    {
      label: ` ${localize('membershipNumber')}`,
      template: r => <>{r?.reference2}</>,
    },
    {
      label: 'personalFileNumber',
      template: r => r?.personalFileNumber,
    },
    {
      label: 'membershipStatus',
      template: r => r?.membershipStatusDescription,
    },
    {
      label: 'individualType',
      template: r => r?.individualTypeDescription,
    },
    {
      label: 'gender',
      template: r => r?.individualGenderDescription,
    },
    {
      label: 'maritalStatus',
      template: r => r?.individualMaritalStatusDescription,
    },
    {
      label: 'nationality',
      template: r => r?.individualNationalityDescription,
    },
    {
      label: 'identityCardType',
      template: r => r?.individualIdentityCardTypeDescription,
    },
    {
      label: 'identityCardNumber',
      template: r => r?.individualIdentityCardNumber,
    },
    {
      label: 'identityCardSerial#',
      template: r => r?.individualIdentityCardSerialNumber,
    },
    {
      label: 'birthDate',
      template: r => formatDate(r?.individualBirthDate, true),
    },
    {
      label: 'employmentSector',
      template: r => r?.employmentSectorDescription,
    },
    {
      label: 'employmentDate',
      template: r => formatDate(r?.individualEmploymentDate, true),
    },
    {
      label: 'employmentDesignation',
      template: r => r?.individualEmploymentDesignation,
    },
    {
      label: 'employmentTermsOfService',
      template: r => r?.individualEmploymentTermsOfServiceDescription,
    },
    {
      label: 'educationLevel',
      template: r => r?.individualEducationLevelDescription,
    },
    {
      label: 'disabilityType',
      template: r => r?.individualDisabilityTypeDescription,
    },
    {
      label: 'groupName',
      template: r => r?.nonIndividualDescription,
    },
    {
      label: 'registrationNumber',
      template: r => r?.nonIndividualRegistrationNumber,
    },
    {
      label: 'registrationSerial#',
      template: r => r?.nonIndividualRegistrationSerialNumber,
    },
    {
      label: 'pin#',
      template: r => r?.personalIdentificationNumber,
    },
    {
      label: 'dateEstablished',
      template: r => <>{formatDate(r?.nonIndividualDateEstablished, true)}</>,
    },
    {
      label: 'addressLine1',
      template: r => r?.addressAddressLine1,
    },
    {
      label: 'addressLine2',
      template: r => r?.addressAddressLine2,
    },
    {
      label: 'physicalAddress',
      template: r => r?.addressAddressLine1,
    },
    {
      label: 'postalCode',
      template: r => r?.addressPostalCode,
    },
    {
      label: 'city',
      template: r => r?.addressCity,
    },
    {
      label: 'emailAddress',
      template: r => r?.addressEmail,
    },
    {
      label: 'landLine',
      template: r => r?.addressLandLine,
    },
    {
      label: 'mobileLine',
      template: r => r?.addressMobileLine,
    },
    {
      label: 'internetAddress',
      template: r => r?.addressInternet,
    },
    {
      label: 'duty/Workstation',
      template: r => r?.dutyStationDescription,
    },
    {
      label: 'electoralZone',
      template: r => r?.electoralZoneDescription,
    },
    {
      label: 'business/Group/Employer',
      template: r => r?.employerDescription,
    },
    {
      label: 'age',
      template: r => r?.age,
    },
    {
      label: 'adminDivision',
      template: r => r?.administrativeDivisionDescription,
    },
    {
      label: 'joinDate',
      template: r => formatDate(r?.registrationDate, true),
    },
    {
      label: 'inhibitGuaranteeing' + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.inhibitGuaranteeing.toString()}
        </span>
      ),
    },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: 'recruitedBy',
      template: r => r?.recruitedBy,
    },
    {
      label: 'modifiedBy',
      template: r => r?.modifiedBy,
    },
    {
      label: 'modifiedDate',
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchCustomers = async () => {
    setIsBusy(true)
    const url = '/Customer/find-customers-by-duty-station-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        dutyStationId: workStation?.dutyStationId,
      },
      false
    )

    if (success) {
      setData(data)
      toggleReloadData()
    }
    
    setIsBusy(false)
  }

  useEffect(() => {
    onSelectAll(data.pageCollection, selectAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll])

  useEffect(() => {
    fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.filterText,
    reqParams.pageIndex,
    reqParams.pageSize,
    workStation,
  ])

  useEffect(() => {
    if (reloadData) {
      fetchCustomers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center col-md-4">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="col-md-2">
              <div className="d-flex align-items-center">
                <Checkbox
                  onCheck={e => {
                    setSelectAll(e)
                  }}
                  id="-for-duty-work-station-customers"
                  checked={selectAll}
                />
                <label
                  htmlFor="-for-duty-work-station-customers"
                  className="ms-2 text-capitalize"
                >
                  {localize('toggleSelection')} ?
                </label>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end gap-2">
              <label className="text-capitalize" htmlFor="searchTable">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{
                  maxWidth: 200,
                  minWidth: 200,
                }}
                enumsKey="CustomerFilter"
                value={reqParams?.customerFilter}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    customerFilter: e.value,
                  })
                }}
              />
              <form
                className="d-flex align-items-center"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({
                    ...reqParams,
                    filterText: search,
                    pageIndex: 0,
                  })
                }}
              >
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={search}
                  onChange={e => {
                    setSearch(e.target.value)
                  }}
                />
                <button className="btn btn-primary ms-2">
                  <i className="uil uil-filter"></i>
                </button>
              </form>
            </div>
          </div>

          <>
            <SimpleTable
              useText
              url="/Customer/find-customers-by-filter-in-page"
              columns={columns}
              data={data.pageCollection}
            />
            <Pager
              pageIndex={reqParams.pageIndex}
              pageSize={reqParams.pageSize}
              itemsCount={data.itemsCount}
              onPageChange={p => {
                setReqParams({ ...reqParams, pageIndex: p })
              }}
            />
            <div className="row bg-light rounded p-2 me-1">
              <div className="col-md-4 offset-md-8">
                <button
                  className="btn btn-success float-end"
                  onClick={handleReset}
                >
                  {localize('reset')}
                </button>
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}

export default CustomerTab
