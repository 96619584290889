/** @format */

import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatAsPercent } from '../../../../../Helpers/formatters'

export const ChargeSplitEntries = ({ setSelectedCharge, selectedCharge,mode }) => {
  const columns = [
    {
      label: localize('glAccountName'),
      template: r => <>{r?.chartOfAccountAccountName}</>,
    },
    {
      label: localize('costDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.percentage)}</>,
    },
    {
      label: localize('leviable'),
      template: r => (
        <span className="text-capitalize">{r?.leviable?.toString()}</span>
      ),
    },
  ]

  return (
    <>
      <SimpleTable
        columns={columns}
        data={selectedCharge?.commissionSplits}
        contextMenu={e => (
          <>
            {mode !== 'view' && (
              <div
                onClick={() => {
                  setSelectedCharge({
                    ...selectedCharge,
                    commissionSplits: selectedCharge?.commissionSplits.filter(
                      x => x.id !== e.id,
                    ),
                  })
                }}
                className="ctxt-menu-item"
              >
                <i className="uil uil-trash"></i>
                {localize('remove')}
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
