/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

export const AccountMetadataPayoutsHistory = ({ loading, data }) => {
  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedCreditBatchBatchNumber}</>,
    },
    {
      label: localize('batchMonth'),
      template: r => <>{r.creditBatchMonthDescription}</>,
    },
    {
      label: localize('batchReference'),
      template: r => <>{r.creditBatchReference}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('reference'), template: r => <>{r.Reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('balance'),
      template: r => <>{formatCurrency(r.balance)}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={data} columns={columns} />
      )}
    </>
  )
}
