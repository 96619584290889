/** @format */

/* eslint-disable */
import React from 'react'
import { formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

function ReceptingEntries({
  //edit,
  unpostedEntries,
  handleRemove,
}) {
  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('apportionTo'),
      template: r => <>{r.apportionToDescription}</>,
    },
    {
      label: localize('apportionedComponent'),
      template: r => <>{r.apportionedComponentDescription}</>,
    },
    {
      label: localize('apportionedAmount'),
      template: r => <>{r.apportionedAmount}</>,
    },

    {
      label: localize('fullAccount'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('loanRepaymentMode'),
      template: r => <>{r.loanRepaymentModeDescription}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <div>
      <SimpleTable
        columns={columns}
        data={unpostedEntries}
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                handleRemove(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash text-danger"></i>
              {localize('remove')}
            </div>
          </>
        )}
      />
    </div>
  )
}
export default ReceptingEntries
