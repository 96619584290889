/** @format */
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { CreateLoanRecoveryExceptionModal } from './CreateLoanRecoveryExceptionModal'
import { postData } from '../../../../../../Helpers/webApi'
import { useEffect, useState } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

function LoanRecoveryExceptionModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
}) {
  const [loanRecoveryExceptionModal, setLoanRecoveryExceptionModal] =
    useState(null)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    setLoanRecoveryExceptionModal(null)
  }, [mode])

  async function handleSubmit() {
    const { success } = await postData(
      '/CustomerAccount/create-recovery-exception',
      {
        CustomerAccountId: selectedCustomerAccount?.id || '',
        PayoutRecoveryMode:
          loanRecoveryExceptionModal?.payoutRecoveryMode || '1793',
        PayoutRecoveryPercentage:
          loanRecoveryExceptionModal?.payoutRecoveryPercentage || '',
        Remarks: loanRecoveryExceptionModal?.remarks || '',
      },
      true,
      'Create Loan Recovery Exception'
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('loanRecoveryException')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            <CreateLoanRecoveryExceptionModal
              globalValidationErrors={globalValidationErrors}
              clearValidation={clearValidation}
              selectedCustomerAccount={selectedCustomerAccount}
              setSelectedCustomerAccount={setSelectedCustomerAccount}
              loanRecoveryExceptionModal={loanRecoveryExceptionModal}
              setLoanRecoveryExceptionModal={setLoanRecoveryExceptionModal}
            />
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default LoanRecoveryExceptionModal
