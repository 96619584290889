/** @format */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import React, { useEffect, useState } from 'react'
import { CreatePayablesReceivableModal } from '../Origination/Partials/CreatePayablesReceivableModal'
import { ViewPayablesReceivablesModal } from './ViewPayablesReceivablesModal'
import { EditPayablesReceivableModal } from '../Origination/Partials/EditPayablesReceivableModal'
import { VerifyPayablesReceivableModal } from '../Verification/Partials/VerifyPayablesReceivableModal'
import { AuthorizePayableReceivablesModal } from '../Authorization/Partials/AuthorizePayableReceivables'
import { postData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { ExpensePayableType } from '../../../../../Helpers/constants'

export const PayableReceivablesModal = ({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedPayableReceivable,
  setSelectedPayableReceivable,
}) => {
  const [disableCreate, setDisableCreate] = useState(false)
  const [expensePayable, setExpensePayable] = useState({
    type: ExpensePayableType.DEBIT_GL_ACCOUNT,
  })
  const [entriesMetadata, setEntriesMetadata] = useState(null)
  const [expensePayableEntries, setExpensePayableEntries] = useState([])

  const handleOnCreateExpensePayable = async () => {
    setDisableCreate(true)
    const { success, data } = await postData(
      '/lookup/ExpensePayables/create-expense-payable',
      {
        ExpensePayable: expensePayable || {},
        ExpensePayableEntries: expensePayableEntries,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      Swal.fire(
        'Payables/Receivables',
        'Operation Completed Successfully',
        'success',
      )
      onUpdateRefreshAt()
    }
    setExpensePayable({
      type: ExpensePayableType.DEBIT_GL_ACCOUNT,
    })
    setDisableCreate(false)
  }

  useEffect(() => {
    setExpensePayable({
      type: ExpensePayableType.DEBIT_GL_ACCOUNT,
    })
    setExpensePayableEntries([])
    setEntriesMetadata(null)
  }, [mode])

  const handleOnEditExpensePayable = async () => {
    setDisableCreate(true)
    const { success, data } = await postData(
      `/lookup/ExpensePayables/update-expense-payable-entry/?expensePayableId=${selectedPayableReceivable?.id}`,
      {
        ExpensePayable: expensePayable || {},
      },
      false,
    )
    if (success) {
      onSetMode(null)
      await Swal.fire(
        'Payables/Receivables',
        'Operation Completed Successfully',
        'success',
      )
      onUpdateRefreshAt()
    }
    setExpensePayable({
      type: ExpensePayableType.DEBIT_GL_ACCOUNT,
    })
    setDisableCreate(false)
  }

  const handleOnVerifyExpensePayable = async () => {
    setDisableCreate(true)
    const { success, data } = await postData(
      `/ExpensePayable/audit-expense-payable/?expensePayableAuthOption=${selectedPayableReceivable?.option}`,
      {
        ...(selectedPayableReceivable || {}),
      },
      false,
    )
    if (success) {
      onSetMode(null)
      await Swal.fire(
        'Payables/Receivables',
        'Operation Completed Successfully',
        'success',
      )
      onUpdateRefreshAt()
    }
    setExpensePayable({
      type: ExpensePayableType.DEBIT_GL_ACCOUNT,
    })
    setDisableCreate(false)
  }

  const handleOnAuthorizeExpensePayable = async () => {
    setDisableCreate(true)
    const { success, data } = await postData(
      `/ExpensePayable/authorize-expense-payable/?expensePayableAuthOption=${selectedPayableReceivable?.option}`,
      {
        ...(selectedPayableReceivable || {}),
      },
      false,
    )
    if (success) {
      onSetMode(null)
      await Swal.fire(
        'Payables/Receivables',
        'Operation Completed Successfully',
        'success',
      )
      onUpdateRefreshAt()
    }
    setExpensePayable(null)
    setDisableCreate(false)
  }

  const handleOnSubmit = () => {
    if (mode === 'add') {
      handleOnCreateExpensePayable()
    }
    if (mode === 'edit') {
      handleOnEditExpensePayable()
    }
    if (mode === 'verify') {
      handleOnVerifyExpensePayable()
    }
    if (mode === 'authorize') {
      handleOnAuthorizeExpensePayable()
    }
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('payables/receivables')}
            handleAction={handleOnSubmit}
            hideUpdate={mode === 'view'}
            handleClose={onHideModal}
            disableCreate={disableCreate}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreatePayablesReceivableModal
                expensePayable={expensePayable}
                setExpensePayable={setExpensePayable}
                entriesMetadata={entriesMetadata}
                setEntriesMetadata={setEntriesMetadata}
                expensePayableEntries={expensePayableEntries}
                setExpensePayableEntries={setExpensePayableEntries}
                onSetMode={m => {
                  onSetMode(m)
                  onUpdateRefreshAt()
                }}
              />
            )}
            {mode === 'view' && (
              <>
                <ViewPayablesReceivablesModal
                  selectedPayableReceivable={selectedPayableReceivable}
                />
              </>
            )}
            {mode === 'edit' && (
              <EditPayablesReceivableModal
                payableReceivableId={selectedPayableReceivable?.id}
              />
            )}
            {mode === 'verify' && (
              <VerifyPayablesReceivableModal
                setSelectedPayableReceivable={setSelectedPayableReceivable}
                selectedPayableReceivable={selectedPayableReceivable}
              />
            )}
            {mode === 'authorize' && (
              <AuthorizePayableReceivablesModal
                selectedPayableReceivable={selectedPayableReceivable}
                setSelectedPayableReceivable={setSelectedPayableReceivable}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}
