/** @format */
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { CreateRequisitionModal } from './CreateRequisitionModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewRequisitionModal } from './ViewRequisitionModal'
import { EditRequisitionModal } from './EditRequisitionModal'

function RequisitionModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedRequisition,
  setSelectedRequisition,
}) {
  const [requisitionEntries, setRequisitionEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      addRequisitionItems()
    }
    if (mode === 'edit') {
      editRequisitionWithItems()
    }
  }

  useEffect(() => {
    return () => {
      setRequisitionEntries([])
    }
  }, [mode])

  const addRequisitionItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Requisition/add-requisition-with-requisition-items',
      {
        Requisition: selectedRequisition,
        RequisitionItem: requisitionEntries,
        SelectedDepartment: selectedRequisition.department,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setRequisitionEntries([])
      setSelectedRequisition(null)
      swal.fire(
        'Create Requisition',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editRequisitionWithItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Requisition/update-requisition-with-requisition-items',
      {
        Requisition: selectedRequisition,
        RequisitionItem: requisitionEntries,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setRequisitionEntries([])
      setSelectedRequisition(null)
      swal.fire(
        'Edit Requisition',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('requisition')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateRequisitionModal
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                requisitionEntries={requisitionEntries}
                setRequisitionEntries={setRequisitionEntries}
                requisitionModel={selectedRequisition}
                setRequisitionModel={setSelectedRequisition}
              />
            )}
            {mode === 'view' && (
              <ViewRequisitionModal selectedRequisition={selectedRequisition} />
            )}
            {mode === 'edit' && (
              <EditRequisitionModal
                requisitionEntries={requisitionEntries}
                setRequisitionEntries={setRequisitionEntries}
                setIsBusy={setIsBusy}
                isBusy={isBusy}
                setSelectedRequisition={setSelectedRequisition}
                selectedRequisition={selectedRequisition}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default RequisitionModal
