/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useState } from 'react'
import { postData, putData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { CreateLoaningRemarkModal } from './CreateLoaningRemarkModal'
import { EditLoaningRemarkModal } from './EditLoaningRemarkModal'

function LoaningRemarksModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedLoaningRemarks,
  setSelectedLoaningRemarks,
}) {
  const [isBusy, setIsBusy] = useState()

  const addLoaningRemark = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/LoaningRemark/add-loaning-remark',
      {
        ...(selectedLoaningRemarks || {}),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Loaning Remark',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const editLoaningRemark = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/LoaningRemark/update-loaning-remark',
      {
        ...(selectedLoaningRemarks || {}),
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Loaning Remark',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  async function handleSubmit() {
    if (mode === 'add') {
      return addLoaningRemark()
    }
    if (mode === 'edit') {
      return editLoaningRemark()
    }
  }
  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('loaningRemark')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          disableCreate={isBusy}
          modalClassName={'modal-lg'}
        >
          {mode === 'add' && (
            <CreateLoaningRemarkModal
              isBusy={isBusy}
              selectedLoaningRemark={selectedLoaningRemarks}
              setSelectedLoaningRemark={setSelectedLoaningRemarks}
            />
          )}
          {mode === 'edit' && (
            <EditLoaningRemarkModal
              isBusy={isBusy}
              setSelectedLoaningRemark={setSelectedLoaningRemarks}
              selectedLoaningRemark={selectedLoaningRemarks}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default LoaningRemarksModal
