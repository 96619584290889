/** @format */

import React, { useState, useEffect } from 'react'
import LoanApplicationLookup from '../../../../../Components/Lookup/LoanApplicationLookup'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  parseDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../../Helpers/webApi'

function LoanCaseParital({
  approval,
  loanCase,
  readOnly,
  onSelect,
  onUpdate,
  setLoanCase,
}) {
  const [amountApplied, setAmountApplied] = useState(0)
  const [receivedDate, setReceivedDate] = useState()
  const [termInMonths, setTermInMonths] = useState(
    loanCase?.loanRegistrationTermInMonths
  )
  const [balancesGuaranteed, setBalancesGuaranteed] = useState('-')
  const [loanBalance, setLoanBalance] = useState('-')
  const [evaluatingLoanBalance, setEvaluatingLoanBalance] = useState(false)

  useEffect(() => {
    onUpdate && onUpdate({ amountApplied, receivedDate, termInMonths })
    // eslint-disable-next-line
  }, [receivedDate, amountApplied, termInMonths])

  useEffect(() => {
    setAmountApplied(loanCase?.amountApplied)
    setReceivedDate(loanCase?.receivedDate)
    setTermInMonths(loanCase?.loanRegistrationTermInMonths)
    loanCase && evaluateLoanBalance()
    onSelect && onSelect(loanCase)

    // loan?.id && loadBalancesGuaranteed()
    // eslint-disable-next-line
  }, [loanCase])

  async function loadBalancesGuaranteed() {
    const { success, data } = await getData(
      '/lookup/LoanCase/evaluate-balances-guaranteed-by-customer-id',
      {
        customerId: loanCase?.customerId,
        includeOutstandingBalance: true,
        filterClearedLoans: true,
      },
      false
    )
    if (success) {
      setBalancesGuaranteed(Number(data))
    }
  }

  async function evaluateLoanBalance() {
    setEvaluatingLoanBalance(true)
    const { success, data } = await getData(
      '/LoanOrigination/evaluate-loan-balance',
      {
        customerId: loanCase?.customerId,
        loanProductId: loanCase.loanProductId,
      },
      false
    )
    if (success) {
      setLoanBalance(Number(data?.result))
    }
    setEvaluatingLoanBalance(false)
  }

  return (
    <div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanNumber')}
          </label>
          <LoanApplicationLookup
            displayValue={loanCase?.documentReferenceNumber || ''}
            onLookup={onSelect}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerName')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.customerFullName || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.paddedCustomerMemberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.customerPersonalFileNumber || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanProduct')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.loanProductDescription || ''}
          />
        </div>

        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanProductSection')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={
              loanCase?.loanRegistrationLoanProductSectionDescription || ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanPurpose')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.loanPurposeDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            value={loanCase?.documentReferenceNumber || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumAmount')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            style={{ textAlign: 'end' }}
            value={
              formatCurrency(
                loanCase?.loanRegistrationMaximumAmount,
                2,
                2,
                false
              ) || ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumAmount')} %
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            style={{ textAlign: 'end' }}
            value={formatAsPercent(loanCase?.maximumAmountPercentage) || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled={readOnly}
            style={{ textAlign: 'end' }}
            value={formatCurrency(loanBalance, 2, 2, false) || ''}
          />
          {evaluatingLoanBalance && (
            <div style={{ width: 20, height: 20 }}>
              <i className="m-1 spinner-border spinner-sm text-dark"></i>
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('amountApplied')}
          </label>
          <MaskNumber
            type="text"
            className="form-control"
            defaultMaskValue={amountApplied}
            disabled={approval || !loanCase}
            onMaskNumber={() => {}}
            onBlur={e =>
              setAmountApplied(stripNonNumericCharacters(e.target.value))
            }
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('receivedDate')}
          </label>
          <CustomDatePicker
            name="loanAppraisalReceivedDate"
            defaultDate={parseDate(receivedDate)}
            disabled={approval || !loanCase}
            onDateChange={e => parseDate(setReceivedDate(e))}
          />
        </div>
        <div className="col"></div>
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('balancesGuaranteed')}
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'end' }}
            disabled={readOnly}
            value={formatCurrency(balancesGuaranteed, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumSelfGuarantee')} %
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'end' }}
            disabled={readOnly}
            value={formatAsPercent(
              loanCase?.loanRegistrationMaximumSelfGuaranteeEligiblePercentage
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('eligibleDeposits')} ({localize('selfGuarantee')})
          </label>
          <input
            type="text"
            className="form-control"
            style={{ textAlign: 'end' }}
            disabled={readOnly}
            value={formatCurrency(
              loanCase?.eligibleSelfGuaranteeInvestmentsBalance,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('term')} ({localize('months')})
          </label>
          <input
            type="number"
            className="form-control"
            style={{ textAlign: 'end' }}
            defaultValue={termInMonths}
            disabled={!loanCase}
            // onChange={e => setTermInMonths(e.target.value)}
            onBlur={e => setTermInMonths(e.target.value)}
          />
        </div>
      </div>
      <div className="my-3 bg-light rounded d-flex align-items-center justify-content-end pt-3 pb-2 px-4 gap-4">
        <div className="d-flex align-items-center gap-2">
          <Checkbox
            disabled={!loanCase?.loanRegistrationAllowSelfGuarantee}
            checked={loanCase?.strictlySelfGuarantee}
            onCheck={v => setLoanCase('strictlySelfGuarantee', v)}
            id="loanCasePartialStrictlySelfGuarantee"
          />
          <label
            htmlFor="loanCasePartialStrictlySelfGuarantee"
            className={`text-capitalize ${
              loanCase?.strictlySelfGuarantee ? 'text-primary' : ''
            }`}
          >
            {localize('strictlySelfGuarantee')}?
          </label>
        </div>

        <div className="d-flex align-items-center gap-2">
          <Checkbox
            disabled
            checked={loanCase?.loanRegistrationAllowSelfGuarantee}
          />
          <label
            htmlFor=""
            className={`text-capitalize ${
              loanCase?.loanRegistrationAllowSelfGuarantee ? 'text-primary' : ''
            }`}
          >
            {localize('allowSelfGuarantee')}?
          </label>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Checkbox
            disabled
            checked={loanCase?.loanRegistrationPermitPartialOffsetting}
          />
          <label
            htmlFor=""
            className={`text-capitalize ${
              loanCase?.loanRegistrationPermitPartialOffsetting
                ? 'text-primary'
                : ''
            }`}
          >
            {localize('permitPartialOffsetting')}?
          </label>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Checkbox
            disabled
            checked={loanCase?.loanRegistrationAllowDiscretionaryTopup}
          />
          <label
            htmlFor=""
            className={`text-capitalize ${
              loanCase?.loanRegistrationAllowDiscretionaryTopup
                ? 'text-primary'
                : ''
            }`}
          >
            {localize('allowDiscrepancyOnTopUp')}?
          </label>
        </div>
      </div>
    </div>
  )
}

export default LoanCaseParital
