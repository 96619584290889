/** @format */

import { CreateMicroCreditGroup } from './CreateMicroCreditGroup'
import { AddMemberDetails } from './AddMemberDetails'
import {
  GroupMembersEntriesAddMode,
} from './GroupMembersEntries'
import Loader from '../../../../../Components/Loaders/Loader'

export const CreateMicroCreditGroupContainer = ({
  microCreditGroupModel,
  setMicroCreditGroupModel,
  setMicroCreditGroupMember,
  microCreditGroupMember,
  microCreditGroupMemberEntries,
  setMicroCreditGroupMemberEntries,
  handleOnRemove,
  disableCreate,
}) => {
  return (
    <>
      {disableCreate ? (
        <Loader />
      ) : (
        <>
          <CreateMicroCreditGroup
            microCreditGroupModel={microCreditGroupModel}
            setMicroCreditGroupModel={setMicroCreditGroupModel}
          />
          <AddMemberDetails
            microCreditGroupMemberEntries={microCreditGroupMemberEntries}
            setMicroCreditGroupMemberEntries={setMicroCreditGroupMemberEntries}
            microCreditGroupMember={microCreditGroupMember}
            setMicroCreditGroupMember={setMicroCreditGroupMember}
          />
          <GroupMembersEntriesAddMode
            handleOnRemove={handleOnRemove}
            entries={microCreditGroupMemberEntries}
          />
        </>
      )}
    </>
  )
}
