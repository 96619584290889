/** @format */
import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { postData, putData } from '../../../../../../../Helpers/webApi'
import { CreateJournalVoucherModal } from './CreateJournalVoucherModal'
import { ViewJournalVoucherModal } from './ViewJournalVoucherModal'
import { EditJournalVoucherModal } from './EditJournalVoucherModal'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../../Components/ActionModal/ActionModal'
import { SYSTEM_PERMISSION_TYPES } from '../../../../../../../Helpers/constants'

function JournalVoucherModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedJournalVoucher,
  setSelectedJournalVoucher,
}) {
  const [actionClickedAt, setActionClicked] = useState(false)
  const [journalVoucherWithEntriesModel, setJournalVoucherWithEntriesModel] =
    useState(null)
  const [editJournalVoucherModel, setEditJournalVoucherModel] = useState(null)
  const [showVerificationDialog, setShowVerificationDialog] = useState(false)

  useEffect(() => {
    if (mode === 'edit') {
      setEditJournalVoucherModel(selectedJournalVoucher)
    }
  }, [mode])

  function onAddJournalVoucher() {
    if (mode === 'add') {
      setActionClicked(new Date())
    }
    if (mode === 'edit') {
      editJournalVoucher()
    }
  }

  useEffect(() => {
    if (journalVoucherWithEntriesModel && mode === 'add') {
      createJournalVoucher(journalVoucherWithEntriesModel)
    }
  }, [journalVoucherWithEntriesModel])

  const createJournalVoucher = async r => {
    const { success } = await postData(
      '/JournalVoucher/add-journal-voucher-with-journal-voucher-entries',
      {
        JournalVoucherDTO: r.journal,
        JournalVoucherEntryDTO: r.entries,
      },
      false,
      null
    )
    if (success) {
      showActionVerificationModal()
      onSetMode(null)
    }
  }

  const showSuccessModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Operation Successful',
      text: 'Create Journal Voucher',
    })
  }

  const handleOnSendNotification = async () => {
    setShowVerificationDialog(false)
    showSuccessModal()
    await postData(
      '/OnlineNotification/send-notification-users-in-system-permission-type',
      {
        NotificationModel: {
          Description: 'New Journal Voucher Verification Request',
        },
        SystemPermissionType:
          SYSTEM_PERMISSION_TYPES.JOURNAL_VOUCHER_VERIFICATION,
      },
      false,
      null
    )
  }
  const handleOnCancelSendNotification = () => {
    setShowVerificationDialog(false)
  }

  const showActionVerificationModal = () => {
    setShowVerificationDialog(true)
  }

  const editJournalVoucher = async r => {
    const { success } = await putData(
      '/JournalVoucher/update-journal-voucher',
      {
        ...editJournalVoucherModel,
      },
      true,
      'Journal Voucher'
    )

    if (success) {
      onSetMode(null)
    }
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('journalVoucher')}
            handleAction={onAddJournalVoucher}
            hideUpdate={mode === 'view'}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateJournalVoucherModal
                mode={mode}
                handleOnActionClicked={r => {
                  setJournalVoucherWithEntriesModel(r)
                }}
                actionClickedAt={actionClickedAt}
              />
            )}
            {mode === 'edit' && (
              <EditJournalVoucherModal
                mode={mode}
                editJournalVoucherModel={editJournalVoucherModel}
                setEditJournalVoucherModel={setEditJournalVoucherModel}
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
            {mode === 'view' && (
              <ViewJournalVoucherModal
                selectedJournalVoucher={selectedJournalVoucher}
              />
            )}
          </ModalTemplate>
        )}
      </>
      {showVerificationDialog && (
        <ActionModal
          cancelText={'NO'}
          confirmText={'YES'}
          modalTitle={'Journal Voucher Verification Request'}
          handleAction={handleOnSendNotification}
          handleClose={handleOnCancelSendNotification}
        >
          <div>Do you want to request for verification?</div>
        </ActionModal>
      )}
    </>
  )
}

export default JournalVoucherModal
