/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function MorgueRoomLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: 'name',
      template: r => <>{r.description}</>,
    },
    {
      label: 'type',
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r.isLocked.toString()}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        columns={columns}
        title={localize('morgueRoomLookup')}
        url="/lookup/MorgueRoom/find-rooms-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default MorgueRoomLookup
