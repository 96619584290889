/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { postData, putData } from '../../../../Helpers/webApi'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import CreateDividendCodes from './CreateDividendCodes/CreateDividendCodes'

function DividendCodes() {
  const [refresh, setRefresh] = useState(false)
  const [modalState, setModalState] = useState(null)
  const [selectedCode, setSelectedCode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    { label: localize('code'), template: r => <>{r?.code}</> },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClickAddButton = () => {
    setModalState('add')
    setSelectedCode(null)
  }

  const handleClose = () => {
    setModalState(null)
    setSelectedCode(null)
  }

  async function handleUpdate() {
    setIsBusy(true)
    const { success } = await putData(
      '/dividendcode/update',
      selectedCode,
      false
    )
    if (success) {
      setIsBusy(false)
      setRefresh(!refresh)
      handleClose()
      showNotification('Dividend Codes', 'success')
    }

    setIsBusy(false)
  }

  async function handleCreate() {
    setIsBusy(true)
    const { success } = await postData(
      '/dividendCode/create',
      selectedCode,
      false
    )

    if (success) {
      setRefresh(!refresh)
      handleClose()
      showNotification('Dividend Codes', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      <ListingPage
        map={[localize('registryManagement'), localize('dividendCodes')]}
        columns={columns}
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        url="/dividendcode/find-by-filter-in-page"
        refreshAt={refresh}
        onAdd={handleClickAddButton}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={() => {
                setModalState('edit')
                setSelectedCode(r)
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span className="text-capitalize">{localize('edit')}</span>
            </div>
          </>
        )}
      />
      {modalState && (
        <CreateDividendCodes
          handleAction={modalState === 'add' ? handleCreate : handleUpdate}
          handleClose={handleClose}
          isBusy={isBusy}
          mode={modalState}
          selectedCode={selectedCode}
          setSelectedCode={setSelectedCode}
        />
      )}
    </>
  )
}

export default DividendCodes
