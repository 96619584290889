/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { ViewPurchaseOrderEntries } from './ViewPurchaseOrderEntries'

export const ViewPurchaseOrderModal = ({ selectedPurchaseOrder }) => {
  const [reqParams, setReqParams] = useState({
    purchaseOrderId: selectedPurchaseOrder?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
  const fetchPurchaseOrderItems = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PurchaseOrder/find-purchase-order-items-by-purchase-order-id',
      {
        ...reqParams,
        purchaseOrderId: selectedPurchaseOrder?.id,
      },
      false,
    )
    if (success) {
      setPurchaseOrderItems(data?.result)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchPurchaseOrderItems()
  }, [selectedPurchaseOrder?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('supplier')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseOrder?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <input
                className={'form-control'}
                disabled
                value={formatDate(selectedPurchaseOrder?.expectedDate, true)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseOrder?.supplierAddressMobileLine}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseOrder?.supplierAddressEmail}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('purchaseOrderItems'),
                    item: (
                      <ViewPurchaseOrderEntries
                        entries={purchaseOrderItems}
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
