/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import ListingPage from '../../../Components/ListingPage/Index'
import Loader from '../../../Components/Loaders/Loader'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { postData, putData } from '../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import CreateVehicleRoute from './VehicleRoutes/CreateVehicleRoute/CreateVehicleRoute'

function VehicleRoutes() {
  const [isBusy, setIsBusy] = useState(false)
  const columns = [
    { label: 'name', template: r => <>{r?.description}</> },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.isLocked?.toString()?.toUpperCase()}
        </span>
      ),
    },
    { label: 'modifiedBy', template: r => <>{r?.modifiedBy}</> },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: 'createdBy', template: r => <>{r?.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r?.createdDate)}</> },
  ]

  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [route, setRoute] = useState(null)

  function handleCancel() {
    setModalMode(null)
    setRoute(null)
  }

  return (
    <>
      <ListingPage
        map={[localize('vehicleFleetManagement'), localize('vehicleRoutes')]}
        columns={columns}
        url="/vehicleRoute/find-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        onAdd={() => setModalMode('add')}
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setRoute(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
      />

      {modalMode && (
        <CreateVehicleRoute
          handleClose={handleCancel}
          mode={modalMode}
          refresh={refresh}
          setRefresh={setRefresh}
          route={route}
          setRoute={setRoute}
        />
      )}
    </>
  )
}

export default VehicleRoutes
