/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _o = {
  operations: {
    en: 'operations',
    sw: 'operesheni',
  },
  operatingBalance: {
    en: 'operating balance',
    sw: 'usawa wa uendeshaji',
  },
  overrideMemberNumberFormatting: {
    en: 'override member number formatting',
    sw: 'kubatilisha umbizo la nambari ya mwanachama',
  },
  of: {
    en: 'of',
    sw: 'ya',
  },
  ok: {
    en: 'ok',
    sw: 'sawa',
  },
  offsetableLoanProducts: {
    en: 'offsetable loan products',
    sw: 'bidhaa za mkopo zinazoweza kutolewa',
  },
  offSetableInvestmentProducts: {
    en: 'offsetable investment products',
    sw: 'bidhaa za uwekezaji zinazoweza kutekelezeka',
  },
  onChequeDeposit: {
    en: 'on cheque deposit',
    sw: 'kwenye kibali cha cheki',
  },
  offSettingPercentageRate: {
    en: 'offsetting percentage rate',
    sw: 'kupunguza kiwango cha asilimia',
  },
  offSetableSavingsProducts: {
    en: 'offsetable savings products',
    sw: 'bidhaa za akiba zinazoweza kutumika',
  },
  operationsAccessControl: {
    en: 'operations access control',
    sw: 'Udhibiti wa Ufikiaji wa Uendeshaji',
  },
  otherNames: {
    en: 'other names',
    sw: 'majina mengine',
  },
  officers: {
    en: 'officers',
    sw: 'maafisa',
  },
  officer: {
    en: 'officer',
    sw: 'afisa',
  },
  origin: {
    en: 'origin',
    sw: 'asili',
  },
  originIdentifier: {
    en: 'origin identifier',
    sw: 'kitambulisho cha asili',
  },
  onlineNotificationsEnabled: {
    en: 'online notifications enabled',
    sw: 'arifa za mtandaoni zimeamilishwa',
  },
  otherName: {
    en: 'other name',
    sw: 'jina lingine',
  },
  occupation: {
    en: 'occupation',
    sw: 'kazi',
  },
  origination: {
    en: 'origination',
    sw: 'asili',
  },
  operationsAccessControlList: {
    en: 'operations access control list',
    sw: 'orodha ya udhibiti',
  },
  organisationAccountBalance: {
    en: 'organisation account balance',
    sw: 'usawa wa akaunti ya shirika',
  },
  offsettingPercentageRate: {
    en: 'offsetting percentage rate',
    sw: 'kiwango cha asilimia kinachoweza kutekelezeka',
  },
  october: {
    en: 'october',
    sw: 'Oktoba',
  },
  operationsACL: {
    en: 'operations ACL',
    sw: 'oparesheni ACL',
  },
  outstandingLoanBalance: {
    en: 'outstanding loan balance',
    sw: 'salio la mkopo',
  },
  offsetableLoans: {
    en: 'offsetable loans',
    sw: 'mikopo inayoweza kutekelezeka',
  },
  outstandingBalance: {
    en: 'outstanding balance',
    sw: 'usawa unaostahili',
  },
  originalAmount: {
    en: 'original amount',
    sw: 'kiwango asili',
  },
  overdueBalance: {
    en: 'overdue balance',
    sw: 'usawa uliopita',
  },
  overdueDate: {
    en: 'overdue date',
    sw: 'tarehe ya ulipaji',
  },
  'other(AccountNPA)': {
    en: 'OTHER (account NPA)',
    sw: 'NYINGINE (akaunti NPA)',
  },
  'other(AccountPerforming)': {
    en: 'OTHER (account performing)',
    sw: 'NYINGINE (akaunti inayofanya kazi)',
  },
  'other(AccountNPAHistory)': {
    en: 'OTHER (account NPA history)',
    sw: 'NYINGINE (historia ya akaunti NPA)',
  },
  originalValue: {
    en: 'original value',
    sw: 'thamani ya awali',
  },
  oneThousands: {
    en: 'one thousands',
    sw: 'elfu moja',
  },
  oneHundreds: {
    en: 'one hundreds',
    sw: 'mia moja',
  },
  ones: {
    en: 'ones',
    sw: 'moja',
  },
  outstandingLoansBalance: {
    en: 'outstanding loans balance',
    sw: 'salio la mikopo inayosubiri',
  },
  operation: {
    en: 'operation',
    sw: 'operesheni',
  },
  overdraftProtection: {
    en: 'overdraft protection',
    sw: 'ulinzi wa mkopo',
  },
  overdraftLimit: {
    en: 'overdraft limit',
    sw: 'kiwango cha mkopo',
  },
  overdraftProtectionCatalogue: {
    en: 'overdraft protection catalogue',
    sw: 'orodha ya ulinzi wa mkopo',
  },
  overDraftLimit: {
    en: 'overdraft limit',
    sw: 'kiwango cha mkopo',
  },
  openingBalance: {
    en: 'opening balance',
    sw: 'baki la ufunguzi',
  },
  option: {
    en: 'option',
    sw: 'chaguo',
  },
  overallRecommendation: {
    en: 'overall recommendation',
    sw: 'mapendekezo ya jumla',
  },
  outgoingStatusCode: {
    en: 'outgoing status code',
    sw: 'msimbo',
  },
  outgoingStatusDescription: {
    en: 'outgoing status description',
    sw: 'maelezo ya hali ya kutoka',
  },
  outgoingNarration: {
    en: 'outgoing narration',
    sw: 'maelezo ya kutoka',
  },
  outgoingAvailableBalance: {
    en: 'outgoing available balance',
    sw: 'baki inayopatikana',
  },
  outgoingBookBalance: {
    en: 'outgoing book balance',
    sw: 'baki ya kitabu',
  },
  outgoingPrincipleBalance: {
    en: 'outgoing principle balance',
    sw: 'baki ya msingi',
  },
  outgoingInterestBalance: {
    en: 'outgoing interest balance',
    sw: 'baki ya riba',
  },
  outgoingLoanLimit: {
    en: 'outgoing loan limit',
    sw: 'kiwango cha mkopo',
  },
  onlineNotificationEnabled: {
    en: 'online notification enabled',
    sw: 'arifa ya mtandaoni imeamilishwa',
  },
  overallScore: {
    en: 'overall score',
    sw: 'alama ya jumla',
  },
  ofBaseUnits: {
    en: 'of base units',
    sw: 'ya vitengo vya msingi',
  },
  orders: {
    en: 'orders',
    sw: 'amri',
  },
  occurrenceDate: {
    en: 'occurrence date',
    sw: 'tarehe ya kutokea',
  },
  owner: {
    en: 'owner',
    sw: 'mmiliki',
  },
  overrideAccountNumberFormatting: {
    en: 'override account number formatting',
    sw: 'badilisha muundo wa nambari ya akaunti',
  },
  offsetableInvestmentProducts: {
    en: 'offsetable investment products',
    sw: 'bidhaa za uwekezaji zinazoweza kutekelezeka',
  },
  offsetableSavingsProducts: {
    en: 'offsetable savings products',
    sw: 'bidhaa za akiba zinazoweza kutumika',
  },
}
export default _o
