/** @format */

import moment from 'moment'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import AddNewButton from '../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../Components/Checkbox/Checkbox'
import CustomDatePicker from '../../Components/Date/custom-date-picker'
import Loader from '../../Components/Loaders/Loader'
import ModalTemplate from '../../Components/ModalTemplate/ModalTemplate'
import Pager from '../../Components/Tables/Pager'
import SimpleTable from '../../Components/Tables/SimpleTable'
import Tab from '../../Components/Tabs/Tab'
import localize from '../../Global/Localize'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../Helpers/formatters'
import { getData, postData, putData } from '../../Helpers/webApi'
import { GlobalValidationErrors } from '../../Validation/ValidationState'
import RecordsPerPage from '../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function PostingPeriods() {
  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('duration'),
      template: r => (
        <>
          {formatDate(r?.durationStartDate, true)}
          {` ${localize('to')} `}
          {formatDate(r?.durationEndDate, true)}
        </>
      ),
    },
    {
      label: localize('isActive'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isActive?.toString())}
        </span>
      ),
      sortBy: 'isActive',
    },
    {
      label: localize('isDividendComputed'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isDividendComputed?.toString())}
        </span>
      ),
      sortBy: 'isDividendComputed',
    },
    {
      label: localize('dividendComputedBy'),
      template: r => <>{r?.dividendComputedBy}</>,
    },
    {
      label: localize('dividendComputedDate'),
      template: r => <>{formatDate(r?.dividendComputedDate, true)}</>,
    },
    {
      label: localize('isClosed'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isClosed.toString())}
        </span>
      ),
      sortBy: 'isClosed',
    },
    { label: localize('closedBy'), template: r => <>{r?.closedBy}</> },
    {
      label: localize('closedDate'),
      template: r => <>{formatDate(r?.closedDate, true)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked?.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [schemes, setSchemes] = useState([])
  const [mode, setMode] = useState(null)
  const [selectedPostingPeriod, setSelectedPostingPeriod] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [onClickedOnce, setOnClickedOnce] = useState(false)
  const [tabLoading, setTabLoading] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  const loadPostingPeriodSchemes = async r => {
    setTabLoading(true)
    const { success, data } = await getData(
      `/PostingPeriod/find-posting-period-schemes?postingPeriodId=${r?.id}`,
      {},
      false
    )
    if (success) {
      setTabLoading(false)
      setSchemes(data || [])
    }
    setTabLoading(false)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/PostingPeriod/update-posting-period',
            selectedPostingPeriod,
            false
          )
        : await postData('/PostingPeriod/create', selectedPostingPeriod, false)

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedPostingPeriod(null)
      loadTableData()
      showNotification('Posting Period', 'success')
    }
    setIsBusy(false)
  }
  function closeModal() {
    setMode(null)
    setSelectedPostingPeriod(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleAdd = () => {
    setMode('add')
    let currentYear = new Date().getFullYear()
    setSelectedPostingPeriod({
      durationStartDate: moment(new Date(currentYear, 0, 1)).format(
        'MM-DD-YYYY'
      ),

      durationEndDate: moment(new Date(currentYear, 11, 31)).format(
        'MM-DD-YYYY'
      ),
    })
  }

  const handleViewAuditLogs = item => {
    setSelectedPostingPeriod(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('postingPeriods')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={selectedPostingPeriod}
          title={selectedPostingPeriod?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleAdd} />
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row d-flex justify-content-between">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <form
                      className="d-flex align-items-center col-md-5"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                      <button
                        id="banksFilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedPostingPeriod(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      canClickOnce
                      onClickOnce={async r => {
                        await loadPostingPeriodSchemes(r)
                        setOnClickedOnce(true)
                      }}
                    />

                    {(mode === 'add' || mode === 'edit') && (
                      <ModalTemplate
                        modalMode={mode}
                        modalTitle={localize('postingPeriod')}
                        handleClose={closeModal}
                        handleAction={handleSubmit}
                        actionText={mode === 'add' ? 'create' : 'update'}
                        modalClassName="modal-lg"
                        footerChildren={
                          <>
                            {mode === 'edit' ? (
                              <>
                                <Checkbox
                                  id="cPostingPeriodGenerateMonths"
                                  onCheck={e => {
                                    setSelectedPostingPeriod({
                                      ...selectedPostingPeriod,
                                      seedSchemes: e,
                                    })
                                  }}
                                  checked={selectedPostingPeriod?.seedSchemes}
                                />
                                <label
                                  htmlFor="cPostingPeriodGenerateMonths"
                                  className="text-capitalize me-2 mb-3"
                                >
                                  {localize('generateMonths')}?
                                </label>
                                <Checkbox
                                  id="cPostingPeriodIsActive"
                                  onCheck={e => {
                                    setSelectedPostingPeriod({
                                      ...selectedPostingPeriod,
                                      isActive: e,
                                    })
                                  }}
                                  checked={selectedPostingPeriod?.isActive}
                                />
                                <label
                                  htmlFor="cPostingPeriodIsActive"
                                  className="text-capitalize me-2 mb-3"
                                >
                                  {localize('isActive')}?
                                </label>
                                <div className="mx-3"></div>

                                <Checkbox
                                  id="cPostingPeriodIsLocked"
                                  onCheck={e => {
                                    setSelectedPostingPeriod({
                                      ...selectedPostingPeriod,
                                      isLocked: e,
                                    })
                                  }}
                                  checked={selectedPostingPeriod?.isLocked}
                                />
                                <label
                                  htmlFor="cPostingPeriodIsLocked"
                                  className="text-capitalize me-2 mb-3"
                                >
                                  {localize('isLocked')}?
                                </label>
                                <div className="mx-3"></div>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        }
                      >
                        {isBusy ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="row mb-3 g-3">
                              <div className="col-md-12">
                                <div
                                  className="with-validation d-flex align-items-center mb-2"
                                  validation-message={
                                    globalValidationErrors['Description']
                                      ?.message
                                  }
                                >
                                  <label className="text-capitalize col-md-1 me-1">
                                    {localize('name')}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control ms-4"
                                    defaultValue={
                                      selectedPostingPeriod?.description
                                    }
                                    onChange={a => {
                                      clearValidation('Description')
                                      setSelectedPostingPeriod({
                                        ...selectedPostingPeriod,
                                        description: a.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3 g-3">
                              <div className="col-md-6">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-md-3 ">
                                    {localize('startDate')}
                                  </label>
                                  <CustomDatePicker
                                    defaultDate={
                                      selectedPostingPeriod?.durationStartDate
                                    }
                                    name={'durationStartDate'}
                                    onDateChange={date => {
                                      setSelectedPostingPeriod({
                                        ...selectedPostingPeriod,
                                        durationStartDate: date,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-md-3">
                                    {localize('endDate')}
                                  </label>
                                  <CustomDatePicker
                                    defaultDate={
                                      selectedPostingPeriod?.durationEndDate
                                    }
                                    name={'durationEndDate'}
                                    onDateChange={date => {
                                      setSelectedPostingPeriod({
                                        ...selectedPostingPeriod,
                                        durationEndDate: date,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </ModalTemplate>
                    )}
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                  {tabLoading ? (
                    <Loader />
                  ) : (
                    <div className="row col-12 mt-2">
                      {onClickedOnce && (
                        <Tab
                          tabItems={[
                            {
                              label: localize('schemes'),
                              item: (
                                <PostingPeriodSchemes clientData={schemes} />
                              ),
                            },
                          ]}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PostingPeriodSchemes = ({ clientData, showLoader }) => {
  const columns = [
    { label: localize('month'), template: r => <>{r?.monthDescription}</> },
    {
      label: `${localize('duration')}`,
      template: r => (
        <>
          {formatDate(r?.durationStartDate, true)}
          {` ${localize('to')} `}
          {formatDate(r?.durationEndDate, true)}
        </>
      ),
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        loading={showLoader}
        data={clientData}
        columns={columns}
        selectables
      />
    </>
  )
}

export default PostingPeriods
