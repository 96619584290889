/** @format */

import React, { useState } from 'react'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import BankBranchLookup from '../../../../../../../Components/Lookup/BankBranchLookup'
import BankLookup from '../../../../../../../Components/Lookup/BankLookup'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { ChequeTypeLookup } from '../../../../../../../Components/Lookup/ChequeTypeLookup'
import localize from '../../../../../../../Global/Localize'
import {
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../../../Helpers/formatters'

export const SundryTransactionSectionChequeReceipt = ({
  transactionModel,
  loading,
  setTransactionModel,
  handleOnBlurChequeReceiptAmount,
}) => {
  const [selectedBank, setSelectedBank] = useState(null)
  const [maturityDate, setMaturityDate] = useState('')


  const calculateMaturityDate = () => {
    let maturityPeriod = transactionModel?.selectedChequeType?.maturityPeriod // assuming maturityPeriod is a number
    let newMaturityDate = new Date()
    newMaturityDate.setDate(newMaturityDate.getDate() + maturityPeriod)
    setMaturityDate(newMaturityDate)
  }

  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('glAccount')}
            </label>
            <ChartOfAccountsLookup
              displayValue={
                transactionModel?.SelectedGeneralLedgerAccount?.name
              }
              onSelect={r => {
                setTransactionModel({
                  ...transactionModel,
                  SelectedGeneralLedgerAccount: { ...r },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountType')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.SelectedGeneralLedgerAccount
                  ?.typeDescription || ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('drawer')}
            </label>
            <input
              className="form-control"
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  newExternalCheque: {
                    ...transactionModel.newExternalCheque,
                    drawer: e.target.value,
                  },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('date')}</label>
            <CustomDatePicker
              onDateChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  newExternalCheque: {
                    ...transactionModel.newExternalCheque,
                    writeDate: e,
                  },
                })
              }}
              defaultDate={
                transactionModel?.newExternalCheque?.writeDate || new Date()
              }
              maxDate={new Date()}
              name={'writeDate'}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('bank')}</label>
            <BankLookup
              displayValue={selectedBank?.description}
              onSelect={b => {
                setSelectedBank({
                  ...selectedBank,
                  bankId: b.id,
                  description: b.description,
                })
                setTransactionModel({
                  ...transactionModel,
                  newExternalCheque: {
                    ...transactionModel.newExternalCheque,
                    drawerBank: b.description,
                  },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('bankBranch')}
            </label>
            <BankBranchLookup
              bankId={selectedBank?.bankId}
              bankDescription={selectedBank?.bankBranchBankDescription}
              displayValue={selectedBank?.bankBranchDescription}
              onSelect={b => {
                //clearValidation('NewExternalCheque.DrawerBankBranch')
                setSelectedBank({
                  ...selectedBank,
                  bankBranchDescription: b.description,
                  bankBranchId: b.id,
                  bankBranchCode: b.code,
                })
                setTransactionModel({
                  ...transactionModel,
                  newExternalCheque: {
                    ...transactionModel.newExternalCheque,
                    drawerBankBranch: b.description,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('chequeType')}
            </label>
            <ChequeTypeLookup
              displayValue={transactionModel?.selectedChequeType?.description}
              onSelect={r => {
                calculateMaturityDate()
                setTransactionModel({
                  ...transactionModel,
                  selectedChequeType: { ...r },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('maturityDate')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatDate(maturityDate, true)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('amount')}
            </label>
            <MaskNumber
              onBlur={handleOnBlurChequeReceiptAmount}
              onMaskNumber={e => {
                setTransactionModel({
                  ...transactionModel,
                  chequeReceiptAmount: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('chequeNumber')}
            </label>
            <input
              className="form-control"
              value={transactionModel?.newExternalCheque?.number || ''}
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  newExternalCheque: {
                    ...transactionModel.newExternalCheque,
                    number: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label
              className="col-1 text-capitalize"
              style={{ marginRight: '4.5rem' }}
            >
              {localize('payer')}
            </label>
            <input
              className="form-control"
              value={transactionModel?.cashPaymentTransactionModel?.payer || ''}
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    payer: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label
              className="col-1 me-4 text-capitalize"
              style={{ marginRight: '4.5rem' }}
            >
              {localize('narration')}
            </label>
            <input
              className="form-control"
              value={
                transactionModel?.cashPaymentTransactionModel?.narration || ''
              }
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    narration: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="col d-flex align-items-center">
          <label
            className="col-1 me-4 text-capitalize"
            style={{ marginRight: '4.5rem' }}
          >
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={
              transactionModel?.cashPaymentTransactionModel?.reference || ''
            }
            onChange={e => {
              setTransactionModel({
                ...transactionModel,
                cashPaymentTransactionModel: {
                  ...transactionModel.cashPaymentTransactionModel,
                  reference: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
