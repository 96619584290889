/** @format */

import React, { useEffect, useState } from 'react'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import { loadMedia } from '../../../../../../Helpers/Modules/media'
import Swal from 'sweetalert2'
import { downloadReceipt } from '../../../../../../Helpers/blobs'

const TellerGLAccountStatement = ({
  chartOfAccountId,
  setPreviousTellerBalance,
  setNewTellerBalance,
  teller,
}) => {
  const [transactions, setTransactions] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [transaction, setTransaction] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusyPrinting, setIsBusyPrinting] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    journalEntryFilter: 3,
    transactionDateFilter: 2,
    tallyDebitsCredits: true,
    fetchJournalEntries: false,
    text: '',
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r?.journalCreatedDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.journalPrimaryDescription}</>,
    },
    {
      label: localize('debit'),
      template: r => <>{formatCurrency(r?.debit)}</>,
    },
    {
      label: localize('credit'),
      template: r => <>{formatCurrency(r?.credit)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r?.runningBalance)}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.journalSecondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.journalReference}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('transactionCode'),
      template: r => <>{r?.journalTransactionCodeDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.journalValueDate, true)}</>,
    },
    {
      label: localize('localCurrency'),
      template: r => <>{r?.journalLocalCurrencyDescription}</>,
    },
    {
      label: localize('debitCredit'),
      template: r => <>{formatCurrency(r?.debitCredit)}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
  ]

  const fetchTransactions = async () => {
    if (!chartOfAccountId && !teller?.id) return

    setIsBusy(true)

    const url =
      '/JournalEntry/find-general-ledger-transactions-by-chart-of-account-id-and-date-range-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        chartOfAccountId,
      },
      false
    )

    if (success) {
      setTransactions(data)
      setPreviousTellerBalance(data?.balanceCarriedForward)
      setNewTellerBalance(data?.balanceCarriedForward)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setTransaction({})
  }

  const handleClickPrint = r => {
    setTransaction(r)
    setMode('print')
  }

  const handlePrint = async () => {
    setIsBusyPrinting(true)

    const media = await loadMedia(transaction?.journalId)

    if (media?.content && mode === 'print') {
      const linkSource = `data:${media?.contentType};base64,${media?.content}`
      const downloadLink = document.createElement('a')
      const fileName = media?.fileName
      downloadLink.href = linkSource
      downloadLink.download = fileName
      document.body.appendChild(downloadLink) // Required for this to work in some browsers
      downloadLink.click()
      downloadLink.remove()
      setMode('re-print')
    } else Swal.fire('Operation failed', 'No receipt found', 'error')

    if (mode === 're-print') {
      setIsBusyPrinting(true)

      const media = await loadMedia(transaction?.journalId)

      if (media?.content && mode === 'print') {
        const linkSource = `data:${media?.contentType};base64,${media?.content}`
        const downloadLink = document.createElement('a')
        const fileName = media?.fileName
        downloadLink.href = linkSource
        downloadLink.download = fileName
        document.body.appendChild(downloadLink) // Required for this to work in some browsers
        downloadLink.click()
        downloadLink.remove()
        setMode('re-print')
      } else Swal.fire('Operation failed', 'No receipt found', 'error')
    }

    setIsBusyPrinting(false)
  }

  useEffect(() => {
    if (chartOfAccountId && teller?.id) fetchTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, chartOfAccountId, teller?.id])

  return (
    <>
      {mode === 'print' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={handleClose}
          modalTitle="vehicleCollections"
          handleAction={handlePrint}
          disableAction={isBusyPrinting}
          disableClose={isBusyPrinting}
        >
          {isBusyPrinting ? <Loader /> : <p>Do you want to print ?</p>}
        </ActionModal>
      )}
      {mode === 're-print' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={handleClose}
          modalTitle="vehicleCollections"
          handleAction={handlePrint}
          disableAction={isBusyPrinting}
          disableClose={isBusyPrinting}
        >
          {isBusyPrinting ? <Loader /> : <p>Do you want to re-print ?</p>}
        </ActionModal>
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageSize: e.target.value,
                    pageIndex: 0,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('transactionFilter')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 130,
                  minWidth: 130,
                }}
                value={reqParams?.transactionDateFilter}
                enumsKey="TransactionDateFilter"
                onChange={({ value }) =>
                  setReqParams({
                    ...reqParams,
                    transactionDateFilter: value,
                    pageIndex: 0,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams?.startDate,
                  endDate: reqParams?.endDate,
                }}
                onSelect={v => {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                    pageIndex: 0,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-1"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-1"
              >
                {localize('search')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 130,
                  minWidth: 130,
                }}
                value={reqParams?.journalEntryFilter}
                enumsKey="JournalEntryFilter"
                onChange={({ value }) =>
                  setReqParams({
                    ...reqParams,
                    journalEntryFilter: value,
                    pageIndex: 0,
                  })
                }
              />
              <input
                type="text"
                className="form-control"
                value={reqParams?.text}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    text: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    fetchTransactions()
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchTransactions()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable
            data={transactions?.pageCollection}
            columns={columns}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleClickPrint(r)
                  }}
                >
                  <i className="uil uil-print"></i>
                  <span className="text-capitalize">{localize('print')}</span>
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={transactions.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default TellerGLAccountStatement
