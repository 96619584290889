/** @format */
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewPurchaseRequisitionModal } from './ViewPurchaseRequisitionModal'
import { CreatePurchaseRequisitionModal } from './CreatePurchaseRequisitionModal'
import { EditPurchaseRequisitionModal } from './EditPurchaseRequisitionModal'

function PurchaseRequisitionModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedPurchaseRequisition,
  setSelectedPurchaseRequisition,
}) {
  const [purchaseRequistionEntries, setPurchaseRequisitionEntries] = useState(
    [],
  )
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      addRequisitionItems()
    }
    if (mode === 'edit') {
      editRequisitionWithItems()
    }
  }

  useEffect(() => {
    return () => {
      setPurchaseRequisitionEntries([])
    }
  }, [mode])

  const addRequisitionItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/PurchaseRequisition/add-purchase-requisition-with-requisition-items',
      {
        PurchaseRequisition: selectedPurchaseRequisition,
        PurchaseRequisitionItems: purchaseRequistionEntries,
        SelectedDepartment: selectedPurchaseRequisition.department,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setPurchaseRequisitionEntries([])
      swal.fire(
        'Create Purchase Requisition',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editRequisitionWithItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/PurchaseRequisition/update-purchase-requisition-with-requisition-items',
      {
        PurchaseRequisition: selectedPurchaseRequisition,
        PurchaseRequisitionItems: purchaseRequistionEntries,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setPurchaseRequisitionEntries([])
      swal.fire(
        'Edit Purchase Requisition',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('purchaseRequisition')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreatePurchaseRequisitionModal
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                purchaseRequisitionEntries={purchaseRequistionEntries}
                setPurchaseRequisitionEntries={setPurchaseRequisitionEntries}
                purchaseRequisitionModel={selectedPurchaseRequisition}
                setPurchaseRequisitionModel={setSelectedPurchaseRequisition}
              />
            )}
            {mode === 'view' && (
              <ViewPurchaseRequisitionModal
                selectedPurchaseRequisition={selectedPurchaseRequisition}
              />
            )}
            {mode === 'edit' && (
              <EditPurchaseRequisitionModal
                setIsBusy={setIsBusy}
                isBusy={isBusy}
                purchaseRequisitionEntries={purchaseRequistionEntries}
                selectedPurchaseRequisition={selectedPurchaseRequisition}
                setPurchaseRequisitionEntries={setPurchaseRequisitionEntries}
                setSelectedPurchaseRequisition={setSelectedPurchaseRequisition}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default PurchaseRequisitionModal
