/** @format */

import StandingOrderTab from '../../../../../../Registry/MembershipWithdrawal/Catalogue/AddCatalogueModal/AdHocStandingOrderExecutionModal/StandingOrderTab/StandingOrderTab'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import React from 'react'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const CustomerPreviewStandingOrders = () => {
  const columns = [
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r.beneficiaryProductDescription}</>,
    },
    {
      label: localize('expectedPrincipal'),
      template: r => <>{r.expectedPrincipal}</>,
    },
    {
      label: localize('expectedInterest'),
      template: r => <>{r.ExpectedInterest}</>,
    },
    {
      label: localize('actualInterest'),
      template: r => <>{r.actualInterest}</>,
    },
    {
      label: localize('residualPrincipal'),
      template: r => <>{r.actualPrincipal}</>,
    },
    {
      label: localize('residualInterest'),
      template: r => <>{r.residualPrincipal}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{r.chargePercentage}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{r.chargeFixedAmount}</>,
    },
    {
      label: localize('trigger'),
      template: r => <>{r.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('beneficiaryName'),
      template: r => <>{r.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r.benefactorProductDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.beneficiaryName}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.transactionCodeDescription}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  return (
    <>
      <SimpleTable columns={columns} />
    </>
  )
}
