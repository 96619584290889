/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'

function EmploymentSectorLookup({ displayValue, onSelect }) {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('medianIncome'),
      headerTextAlign: 'right',
      template: r => (
        <div style={{ textAlign: 'right' }} className="p-0">
          {formatCurrency(r.medianIncome)}
        </div>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        displayValue={displayValue}
        onSelect={onSelect}
        url={'/lookup/employmentSector/find-by-filter-in-page'}
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        title={localize('employmentSector')}
        columns={columns}
      />
    </>
  )
}

export default EmploymentSectorLookup
