/** @format */

import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../Components/Tabs/Tab'
import { postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../Helpers/formatters'

function SavingsProductSection({
  product,
  mode,
  readonly,
  close,
  onBusy,
  refreshData,
  changeMode,
  setProduct,
}) {
  const [saving, setSaving] = useState(product)
  const [isBusy, setIsBusy] = useState(false)

  async function handleCreate() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/savingsProduct/create-savings-product',
      saving,
      false,
      null
    )
    if (success) {
      Swal.fire('Operation Successful', 'Savings product created!', 'success')
      close()
      refreshData()
      changeMode('edit')
      setProduct(data)
    }
    setIsBusy(false)
  }

  async function handleUpdate() {
    setIsBusy(true)
    const { success } = await putData(
      '/savingsProduct/update-savings-product',
      saving,
      false,
      null
    )
    if (success) {
      Swal.fire('Operation Successful', 'Savings product updated!', 'success')
      close()
      refreshData()
    }
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('flags'),
      item: (
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editSPIsDefault"
                checked={saving?.isDefault}
                onCheck={v => setSaving({ ...saving, isDefault: v })}
              />
              <label htmlFor="editSPIsDefault" className="text-capitalize">
                {localize('isDefault')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editSPIsLockedFlag"
                checked={saving?.isLocked}
                onCheck={v => setSaving({ ...saving, isLocked: v })}
              />
              <label htmlFor="editSPIsLockedFlag" className="text-capitalize">
                {localize('isLocked')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editThrottleABWithFlag"
                checked={saving?.throttleAgencyBankingWithdrawals}
                onCheck={v =>
                  setSaving({ ...saving, throttleAgencyBankingWithdrawals: v })
                }
              />
              <label
                htmlFor="editThrottleABWithFlag"
                className="text-capitalize"
              >
                {localize('throttleAgencyBankingWithdrawals')}?
              </label>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editSPALFCalculation"
                checked={saving?.automateLedgerFeeCalculation}
                onCheck={v =>
                  setSaving({ ...saving, automateLedgerFeeCalculation: v })
                }
              />
              <label htmlFor="editSPALFCalculation" className="text-capitalize">
                {localize('automateLedgerFeeCalculation')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editSuppressAuthoriFlag"
                checked={
                  saving?.suppressAuthorizationForCashWithdrawalByArtificalEntity
                }
                onCheck={v =>
                  setSaving({
                    ...saving,
                    suppressAuthorizationForCashWithdrawalByArtificalEntity: v,
                  })
                }
              />
              <label
                htmlFor="editSuppressAuthoriFlag"
                className="text-capitalize"
              >
                {localize('suppressAuthorization')} (
                {localize('wdr. by artificial entity')})?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editThrottleOTCWithdrawalsFlag"
                checked={saving?.throttleOverTheCounterWithdrawals}
                onCheck={v =>
                  setSaving({ ...saving, throttleOverTheCounterWithdrawals: v })
                }
              />
              <label
                htmlFor="editThrottleOTCWithdrawalsFlag"
                className="text-capitalize"
              >
                {localize('throttleOverTheCounterWithdrawals')}?
              </label>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editPermitMultipleCAFlag"
                checked={saving?.permitMultipleCustomerAccounts}
                onCheck={v =>
                  setSaving({ ...saving, permitMultipleCustomerAccounts: v })
                }
              />
              <label
                htmlFor="editPermitMultipleCAFlag"
                className="text-capitalize"
              >
                {localize('permitMultipleCustomerAccounts')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editConcealMBLimitFlag"
                checked={saving?.concealBankToMobileListing}
                onCheck={v =>
                  setSaving({ ...saving, concealBankToMobileListing: v })
                }
              />
              <label
                htmlFor="editConcealMBLimitFlag"
                className="text-capitalize"
              >
                {localize('concealMBankingListing')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="editThrottleMBWIthDRFlag"
                checked={saving?.throttleBankToMobileWithdrawals}
                onCheck={v =>
                  setSaving({ ...saving, throttleBankToMobileWithdrawals: v })
                }
              />
              <label
                htmlFor="editThrottleMBWIthDRFlag"
                className="text-capitalize"
              >
                {localize('throttleMBankingWithdrawals')}?
              </label>
            </div>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <div className="row">
        <div className="col">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">{localize('name')}</label>
            <input
              disabled={readonly}
              type="text"
              className="form-control"
              value={saving?.description}
              onChange={e =>
                setSaving({ ...saving, description: e.target.value })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('operatingBalance')}
            </label>
            <MaskNumber
              type="text"
              disabled={readonly}
              className="form-control"
              defaultMaskValue={saving?.operatingBalance}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  operatingBalance: stripNonNumericCharacters(e.target.value),
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('interestPayableGLAccount')}
            </label>
            <ChartOfAccountsLookup
              disabled={readonly}
              displayValue={saving?.interestPayableChartOfAccountName}
              onSelect={c =>
                setSaving({
                  ...saving,
                  interestPayableChartOfAccountName: c.description,
                  interestPayableChartOfAccountId: c.id,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('withdrawalNoticePeriod')} ({localize('days')})
            </label>
            <input
              type="number"
              disabled={readonly}
              className="form-control"
              value={saving?.withdrawalNoticePeriod}
              onChange={e =>
                setSaving({
                  ...saving,
                  withdrawalNoticePeriod: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('maximumAllowedDeposit')}
            </label>
            <MaskNumber
              type="number"
              disabled={readonly}
              className="form-control"
              defaultMaskValue={saving?.maximumAllowedDeposit}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  maximumAllowedDeposit: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('billReferenceNumber')}
            </label>
            <input
              type="text"
              disabled={readonly}
              className="form-control"
              value={saving?.billReferenceNumber}
              onChange={e =>
                setSaving({
                  ...saving,
                  billReferenceNumber: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('gLAccount')}
            </label>
            <ChartOfAccountsLookup
              disabled={readonly}
              displayValue={saving?.chartOfAccountName}
              onSelect={c =>
                setSaving({
                  ...saving,
                  chartOfAccountName: c.description,
                  chartOfAccountId: c.id,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('interestExpenseGLAccount')}
            </label>
            <ChartOfAccountsLookup
              disabled={readonly}
              displayValue={saving?.interestExpenseChartOfAccountName}
              onSelect={c =>
                setSaving({
                  ...saving,
                  interestExpenseChartOfAccountName: c.description,
                  interestExpenseChartOfAccountId: c.id,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('annualPercentageYield')}
            </label>
            <input
              disabled={readonly}
              type="number"
              name=""
              id=""
              className="form-control"
              value={saving?.annualPercentageYield}
              onChange={e =>
                setSaving({
                  ...saving,
                  annualPercentageYield: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('withdrawalNoticeAmount')}
            </label>
            <MaskNumber
              type="number"
              disabled={readonly}
              className="form-control"
              defaultMaskValue={saving?.withdrawalNoticeAmount}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  withdrawalNoticeAmount: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('recoveryPriority')}
            </label>
            <input
              type="number"
              disabled={readonly}
              className="form-control"
              value={saving?.priority}
              onChange={e =>
                setSaving({
                  ...saving,
                  priority: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('minimumBalance')}
            </label>
            <MaskNumber
              defaultMaskValue={saving?.minimumBalance}
              disabled={readonly}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  minimumBalance: stripNonNumericCharacters(e.target.value),
                })
              }
            />
            {/* <input
              type="number"
              disabled={readonly}
              className="form-control"
              value={saving?.minimumBalance}
              onChange={e =>
                setSaving({ ...saving, minimumBalance: e.target.value })
              }
            /> */}
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('maximumAllowedWithdrawal')}
            </label>
            <MaskNumber
              type="number"
              disabled={readonly}
              // className="form-control"
              defaultMaskValue={saving?.maximumAllowedWithdrawal}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  maximumAllowedWithdrawal: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('productSection')}
            </label>
            <EnumsServerCombo
              disabled={readonly}
              customContainerStyle={{ width: '100%' }}
              enumsKey="ProductSection"
              value={saving?.productSection}
              onChange={e =>
                setSaving({
                  ...saving,
                  productSection: e.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('withdrawalInterval')} ({localize('days')})
            </label>
            <input
              type="number"
              disabled={readonly}
              className="form-control"
              value={saving?.withdrawalInterval}
              onChange={e =>
                setSaving({
                  ...saving,
                  withdrawalInterval: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-4">
              {localize('maximumOverdraftLimit')}
            </label>
            <MaskNumber
              type="number"
              disabled={readonly}
              className="form-control"
              defaultMaskValue={saving?.maximumOverdraftLimit}
              onMaskNumber={e =>
                setSaving({
                  ...saving,
                  maximumOverdraftLimit: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-2"></div>
      <Tab tabItems={tabItems} />
      <hr />
      <div className="d-flex align-items-center justify-content-end gap-2">
        <button className="btn btn-danger px-5" onClick={close}>
          {localize('cancel')}
        </button>
        <button
          className="btn btn-success px-5"
          onClick={mode === 'add' ? handleCreate : handleUpdate}
        >
          {localize(mode === 'add' ? 'create' : 'update')}
        </button>
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default SavingsProductSection
