/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import DepartmentLookup from '../../../../../Components/Lookup/DepartmentLookup'
import { DispatchCollection } from './Partials/DispatchCollection'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { object, string } from 'yup'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import { useAuth } from 'react-oidc-context'
import Loader from '../../../../../Components/Loaders/Loader'

function FileTrackingDispatchMultiDestination() {
  const [isBusy, setIsBusy] = useState(false)
  const [fileTrackingModel, setFileTrackingModel] = useState({
    destinationDepartmentId: '',
    remarks: '',
    carrier: '',
    fileRegisterCustomerId: '',
  })

  const auth = useAuth()
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [dispatchCollection, setDispatchCollection] = useState([])

  async function handleSubmit() {
    if (dispatchCollection.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Multi Destination Dispatch',
        text: 'Select at least one file to dispatch',
      })
      return
    }

    setIsBusy(true)
    const { success } = await putData(
      '/FileRegister/multi-destination-dispatch',
      {
        FileMovementHistories: dispatchCollection,
      },

      false
    )
    if (success) {
      setDispatchCollection([])
    }
    setIsBusy(false)
  }

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const runValidation = async model => {
    let multiDispatchModel = object({
      destinationDepartmentId: string().required(
        'Destination Department is required'
      ),
      remarks: string().required('Remarks identifier is required'),
      carrier: string().required('Carrier is required'),
      fileRegisterCustomerId: string().required('Customer is required'),
    })
    try {
      await multiDispatchModel.validate(model, { abortEarly: false })
      return true
    } catch (e) {
      const validations = e.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setValidationBag(validations)
    }
  }

  const handleOnSelectCustomer = r => {
    setSelectedCustomer(r)
    setFileTrackingModel({
      ...fileTrackingModel,
      employerDescription: r.employerDescription,
      dutyStationDescription: r.dutyStationDescription,
      fullName: r.fullName,
      formattedMemberNumber: r.formattedMemberNumber,
      individualIdentityCardNumber: r.individualIdentityCardNumber,
      fileRegisterCustomerId: r.id,
      fileRegisterCustomerIndividualSalutation: r.individualSalutation,
      fileRegisterCustomerIndividualFirstName: r.individualFirstName,
      fileRegisterCustomerIndividualLastName: r.individualLastName,
      fileRegisterCustomerFullName: r.fullName,
      sourceDepartmentDescription:
        auth?.user?.profile?.cbs_EmployeeDepartmentDescription,
    })
  }

  const onAddNewDispatchCollection = async () => {
    const modelValid = await runValidation(fileTrackingModel)
    if (modelValid) {
      if (
        dispatchCollection.find(
          x =>
            x.fileRegisterCustomerId ===
            fileTrackingModel.fileRegisterCustomerId
        )
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Multi Destination Dispatch',
          text: 'Customer already added',
        })
        return
      }
      setDispatchCollection([...dispatchCollection, fileTrackingModel])
      setSelectedCustomer(null)
      setFileTrackingModel({
        destinationDepartmentId: '',
        remarks: '',
        carrier: '',
        fileRegisterCustomerId: '',
      })
    }
  }

  const handleOnRemoveDispatchCollection = r => {
    setDispatchCollection(
      dispatchCollection.filter(
        x => x.fileRegisterCustomerId !== r.fileRegisterCustomerId
      )
    )
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('fileTracking'),
          localize('dispatch'),
          localize('multiDestination'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <CustomerPartial
                    customer={selectedCustomer}
                    message={validationBag?.fileRegisterCustomerId}
                    onCustomerLookup={r => {
                      handleOnSelectCustomer(r)
                      clearValidation('fileRegisterCustomerId')
                    }}
                  />
                  <div className="row mb-3">
                    <div
                      validation-message={
                        validationBag?.destinationDepartmentId
                      }
                      className="col-3 d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('destination')}
                      </label>
                      <DepartmentLookup
                        displayValue={fileTrackingModel?.departmentDescription}
                        onSelect={r => {
                          clearValidation('destinationDepartmentId')
                          setFileTrackingModel({
                            ...fileTrackingModel,
                            departmentDescription: r.description,
                            destinationDepartmentId: r.id,
                            destinationDepartmentDescription: r.description,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={validationBag?.remarks}
                      className="col-3 d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('remarks')}
                      </label>
                      <input
                        className="form-control"
                        value={fileTrackingModel?.remarks || ''}
                        onChange={e => {
                          clearValidation('remarks')
                          setFileTrackingModel({
                            ...fileTrackingModel,
                            remarks: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={validationBag?.carrier}
                      className="col-3 d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('carrier')}
                      </label>
                      <input
                        className="form-control"
                        value={fileTrackingModel?.carrier || ''}
                        onChange={e => {
                          clearValidation('carrier')
                          setFileTrackingModel({
                            ...fileTrackingModel,
                            carrier: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      className="bg-light d-flex justify-content-end align-items-center p-2 mt-2"
                      style={{ borderRadius: 5 }}
                    >
                      <button
                        onClick={onAddNewDispatchCollection}
                        className="btn btn-success text-uppercase ms-3 px-4"
                      >
                        {localize('add')}
                      </button>
                    </div>

                    <div
                      className="p-2 mt-4"
                      style={{
                        border: 'solid 1px #0000001f',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                    >
                      <div
                        className="bg-white px-2 row text-capitalize"
                        style={{
                          position: 'relative',
                          top: -20,
                          left: 30,
                          maxWidth: 120,
                          marginBottom: -10,
                        }}
                      >
                        {localize('dispatchCollection')}
                      </div>
                      <DispatchCollection
                        onRemoveDispatchCollection={
                          handleOnRemoveDispatchCollection
                        }
                        dispatchData={dispatchCollection}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-content-end justify-content-end">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileTrackingDispatchMultiDestination
