/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'

function ReadEmail({ email, close }) {
  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={email?.mailMessageSubject}
      handleClose={close}
      modalMode={localize('read')}
      hideUpdate
    >
      <div className="d-flex align-items-center gap-4">
        <div className="flex-grow rounded bg-light p-3">
          <h5 className="font-size-14 my-1 text-capitalize">
            {localize('mailFrom')}
          </h5>
          <small className="text-muted">from: {email?.mailMessageFrom}</small>{' '}
          <br />
          <small className="text-muted">to: {email?.mailMessageTo}</small>{' '}
          <br />
          <small className="text-muted">CC:{email?.mailMessageCC}</small>
        </div>
        <div className="flex-grow rounded bg-light p-3">
          <h5 className="font-size-14 my-1">{email?.customerFullName}</h5>
          <small className="text-muted">
            Customer Reference 1: {email?.customerReference1}
          </small>{' '}
          <br />
          <small className="text-muted">
            Customer Reference 2: {email?.customerReference2}
          </small>{' '}
          <br />
          <small className="text-muted">
            Customer Reference 3:{email?.customerReference3}
          </small>
        </div>
      </div>
      <div className="mt-4 d-flex-align-items-center">
        <h4>{email?.mailMessageSubject}</h4>
      </div>
      <div className="mt-4 ">
        {email?.mailMessageIsBodyHtml ? (
          <div
            className="mail-content"
            dangerouslySetInnerHTML={{ __html: email?.mailMessageBody }}
          ></div>
        ) : (
          <div className="mail-content">{email?.mailMessageBody}</div>
        )}
      </div>
      <div className="mt-4 rounded p-3 bg-light">
        <div className="d-flex align-items-center gap-2">
          <small className="text-capitalize">
            {localize('deliveryStatus')}:
          </small>
          <span
            className={`bg-${
              email?.mailMessageDLRStatus == 2
                ? 'danger'
                : email?.mailMessageDLRStatus == 4
                ? 'warning'
                : email?.mailMessageDLRStatus == 8
                ? 'success'
                : 'primary'
            } badge me-2`}
          >
            {email?.mailMessageDLRStatusDescription}
          </span>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('reference')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.mailMessageReference}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('origin')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.mailMessageOriginDescription}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('priority')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.mailMessagePriorityDescription}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('sendRetry')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.mailMessageSendRetry}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('modifiedBy')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.modifiedBy.toString()}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('modifiedDate')}:</small>
          <div htmlFor="" className="text-capitalize">
            {formatDate(email?.modifiedDate)}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('createdBy')}:</small>
          <div htmlFor="" className="text-capitalize">
            {email?.createdBy.toString()}
          </div>
        </div>
        <div className="d-flex mt-2 gap-2">
          <small className="text-capitalize">{localize('createdDate')}:</small>
          <div htmlFor="" className="text-capitalize">
            {formatDate(email?.createdDate)}
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ReadEmail
