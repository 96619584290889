/** @format */

import React, { useRef, useState } from 'react'
import { ProductCode } from '../../../../../Global/enumeration'
import { getData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { formatDate } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import MemberCollection from '../../../../../Components/ReUsables/MemberCollection/MemberCollection'
import EditSignatories from './EditSignatories/EditSignatories'

const Signatories = () => {
  const ref = useRef(null)
  const [customerAccounts, setCustomerAccounts] = useState([])
  const [customerAccount, setCustomerAccount] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [showSignatory, setShowSignatory] = useState(false)
  const [reqParams, setReqParams] = useState({
    productCode: Object.keys(ProductCode)[0],
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageCount: 10,
    includeBalances: false,
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setMode(null)
    setCustomerAccount({})
  }

  const handleClickOnce = r => {
    setCustomerAccount(r)
    setShowSignatory(true)
  }

  const fetchCustomerAccounts = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccount/find-customer-accounts-by-product-code-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setCustomerAccounts(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectCustomer = (r, mode) => {
    setCustomerAccount({
      ...r,
      identityCardType: 1,
      remarks: '',
    })
    setMode(mode)
  }

  useEffect(() => {
    fetchCustomerAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageCount])

  useEffect(() => {
    if (showSignatory) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [showSignatory])

  return (
    <>
      {mode === 'edit' && (
        <EditSignatories
          setSignatory={setCustomerAccount}
          handleClose={handleClose}
          signatory={customerAccount}
          mode={mode}
        />
      )}
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('signatories'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex align-items-center float-right my-2">
                  <label
                    className="text-capitalize"
                    htmlFor="signatoriesPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="signatoriesPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageCount}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageCount: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        customerFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') fetchCustomerAccounts()
                    }}
                  />
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchCustomerAccounts()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                data={customerAccounts?.pageCollection}
                canClickOnce
                onClickOnce={handleClickOnce}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectCustomer(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={customerAccounts.itemsCount}
                pageSize={reqParams.pageCount}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {showSignatory && (
        <div className="card bg-white rounded" ref={ref}>
          <div className="card-body">
            <MemberCollection
              customerAccountId={customerAccount?.id}
              showContextMenu
              fetchCustomerAccounts={fetchCustomerAccounts}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Signatories
