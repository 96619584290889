/** @format */

import React from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import IndividualParticulars from './IndividualParticulars/IndivivualParticulars'
import { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import { useEffect } from 'react'
import Specimen from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import AddressV2 from '../../../../../../Components/Miscalleneous/AddressV2'
import Loader from '../../../../../../Components/Loaders/Loader'

const CustomerPreview = ({ customerId }) => {
  const [customer, setCustomer] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const fetchCustomer = async () => {
    setIsBusy(true)
    const url = '/lookup/Customer/find-customer-by-id'

    const { success, data } = await getData(
      url,
      {
        customerId,
      },
      false
    )

    if (success) {
      setCustomer(data?.customer)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (customerId) {
      fetchCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  return (
    <>
      <Tab
        preload
        tabItems={[
          {
            label: localize('individualParticulars'),
            item: isBusy ? (
              <Loader />
            ) : (
              <IndividualParticulars customer={customer} />
            ),
          },
          {
            label: localize('address'),
            item: <AddressV2 parent={customer} refetchFromServer readOnly />,
          },
          {
            label: localize('specimen'),
            item: <Specimen readOnly customer={customer} />,
          },
        ]}
      />
    </>
  )
}

export default CustomerPreview
