/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { CustomerFilter } from '../../Global/enumeration'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Pager from '../Tables/Pager'
import GroupedTable from '../Tables/GroupedTable'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../Helpers/formatters'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'

function StandingOrderLookup({ displayValue, onSelect }) {
  const [reqParams, setReqParams] = useState({
    trigger: 0,
    filterText: '',
    customerAccountFilter: 0,
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageCount, modalActive])

  const [groupBy, setGroupBy] = useState({
    label: localize('trigger'),
    column: 'triggerDescription',
  })

  const columns = [
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
    {
      label: localize('trigger'),
      template: r => <>{r.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r.beneficiaryProductDescription}</>,
    },
    {
      label: localize('beneficiaryCustomerName'),
      template: r => <>{r.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('creditType'),
      template: r => <>{r.creditTypeDescription}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r.durationStartDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r.durationEndDate)}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r.scheduleFrequencyDescription}</>,
    },
    {
      label: localize('expectedRunDate'),
      template: r => <>{formatDate(r.scheduleExpectedRunDate)}</>,
    },
    {
      label: localize('actualRunDate'),
      template: r => <>{formatDate(r.scheduleActualRunDate)}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => (
        <>{formatDate(r.beneficiaryCustomerAccountLoanCaseDisbursedDate)}</>
      ),
    },
    {
      label: localize('disbursedAmount'),
      template: r => (
        <>
          {formatCurrency(r.beneficiaryCustomerAccountLoanCaseDisbursedAmount)}
        </>
      ),
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => <>{formatCurrency(r.paymentPerPeriod)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('capitalizedInterest'),
      template: r => <>{formatCurrency(r.CapitalizedInterest)}</>,
    },
    {
      label: localize('chargeTypeDescription'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('chargePercentage'),
      template: r => <>{formatAsPercent(r.ChargePercentage)}</>,
    },
    {
      label: localize('chargeFixedAmount'),
      template: r => <>{formatCurrency(r.ChargeFixedAmount)}</>,
    },
    {
      label: `${localize('beneficiary')}_${localize('accountNumber')}`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('beneficiary')}_${localize('memberNumber')})`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('beneficiary')}_${localize('pfNumber')})`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r.benefactorProductDescription}</>,
    },
    {
      label: localize('benefactorCustomerName'),
      template: r => <>{r.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('benefactor')}_${localize('accountNumber')})`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('benefactor')}_${localize('memberNumber')})`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('benefactor')}_${localize('pfNumber')})`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('chargeable'),
      template: r => (
        <span className="text-capitalize">{r.chargeable.toString()}</span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
  ]

  const groupingColumns = [
    {
      label: localize('trigger'),
      column: 'triggerDescription',
    },
    {
      label: localize('beneficiaryProductName'),
      column: 'beneficiaryProductDescription',
    },
    {
      label: localize('benefactorProductName'),
      column: 'benefactorProductDescription',
    },
  ]

  async function loadData() {
    setLoading(true)
    const { success, data } = await getData(
      '/lookup/StandingOrder/find-by-trigger-and-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
      setLoading(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  return (
    <>
      <>
        <div
          className="form-control d-flex align-items-center"
          style={{
            padding: 0,
            cursor: 'pointer',
            overflow: 'hidden',
          }}
          onClick={() => setModalActive(true)}
        >
          <div
            style={{
              flex: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              margin: 0,
            }}
            className="ms-2"
          >
            {displayValue}
          </div>
          <button
            className="btn btn-primary"
            style={{ margin: 0, transform: 'scale(0.9)' }}
            onClick={() => setModalActive(true)}
          >
            <i className="uil uil-search"></i>
          </button>
        </div>
        {modalActive && (
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white p-2 text-capitalize">
              {localize('standingOrder')} {localize('lookup')}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalActive(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-2">
              <div className="d-flex align-items-center justify-content-space-between ">
                <div className="d-flex align-items-center float-right my-2 col-3">
                  <label
                    className="text-capitalize"
                    htmlFor="LoanProductLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="LoanProductLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageCount}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageCount: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('groupBy')}
                  </label>
                  <select
                    className="form-select text-capitalize"
                    style={{ width: 'fit-content' }}
                    defaultValue={groupBy.column}
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumns.find(
                          f => f.column === e.target.value
                        )?.label,
                        column: e.target.value,
                      })
                    }
                  >
                    {groupingColumns.map(({ label, column }) => (
                      <option className="text-capitalize" value={column}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-auto me-3 text-capitalize">
                    {localize('product')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.trigger}
                    customContainerStyle={{
                      width: '70%',
                      maxWidth: 'fit-content',
                    }}
                    enumsKey={'StandingOrderTrigger'}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        trigger: e.value,
                      })
                    }}
                  />
                </div>

                <form
                  className="d-flex align-items-center col-3 col"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <label
                    className="ms-2 text-capitalize me-3"
                    htmlFor="searchTable"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.customerFilter}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey={'CustomerFilter'}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        customerFilter: e.value,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-3"
                    defaultValue={reqParams.filterText}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        loadData()
                      }
                    }}
                    onChange={e => {
                      setSearch(e.target.value)
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                      })
                    }}
                  />
                  <button
                    id="customLookupFilterButton"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      loadData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="py-1">
                {loading ? (
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="m-3 spinner-border text-dark"></div>
                    <span className="fs-4">Please Wait</span>
                  </div>
                ) : (
                  <GroupedTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                )}
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </ModalBody>
            <ModalFooter className="bg-white p-2">
              <button
                className="btn btn-danger  text-capitalize"
                onClick={() => setModalActive(false)}
              >
                {localize('cancel')}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </>
    </>
  )
}
export default StandingOrderLookup
