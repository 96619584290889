/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../../Components/Loaders/Loader'
import ListReversalBatches from '../../../../../../Components/ReUsables/ListReversalBatches/ListReversalBatches'
import localize from '../../../../../../Global/Localize'
import { postData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import AddNewReversalModal from './AddNewReversalModal/AddNewReversalModal'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

function Reversal() {
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [reversal, setReversal] = useState({})
  const [reload, setReload] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleClickAddButton = () => {
    setMode('add')
    setReversal({})
  }

  const handleClose = () => {
    setMode(null)
    setReversal({})
  }

  const handleCreateReversal = async () => {
    setIsBusy(true)
    if (!reversal.remarks) {
      return GlobalValidationErrors.set({
        Remarks: {
          message: 'Remarks is required',
        },
      })
    }

    const url = '/JournalReversalBatch/create-journal-reversal-batch'

    const { success } = await postData(
      url,
      {
        valueDate: reversal?.valueDate,
        remarks: reversal?.remarks,
      },
      false
    )

    if (success) {
      handleClose()
      setReload(true)
      showNotification('Create Journal Reversal Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <AddNewReversalModal
          mode={mode}
          reversal={reversal}
          setReversal={setReversal}
          handleAction={handleCreateReversal}
          handleClose={handleClose}
          globalValidationErrors={globalValidationErrors}
          setGlobalValidationErrors={setGlobalValidationErrors}
          isBusy={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('reversal'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListReversalBatches
            showCreateButton
            handleClickAddButton={handleClickAddButton}
            reload={reload}
            setReload={setReload}
            showEdit
          />
        </div>
      </div>
    </>
  )
}

export default Reversal
