/** @format */

import React from 'react'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const MorgueServiceLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: 'description', template: r => <>{r.description}</> },
    { label: 'isLocked', template: r => <>{r.isLocked.toString()}</> },
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
  ]

  return (
    <>
      <SimpleLookup
        onSelect={onSelect}
        displayValue={displayValue}
        useText
        url="/MorgueService/find-morgue-services-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        title="morgueServiceLookup"
      />
    </>
  )
}

export default MorgueServiceLookup
