/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import { formatDate, parseDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import ModalTemplate from './../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'

const ViewDisciplinaryCase = ({ mode, disciplinaryCase, handleClose }) => {
  const [selectedVehiclePersonnel, setSelectedVehiclePersonnel] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const fetchVehiclePersonnel = async () => {
    setIsBusy(true)
    const url = '/VehiclePersonnel/find-by-id'
    const { success, data } = await getData(
      url,
      {
        VehiclePersonnelId: selectedVehiclePersonnel?.vehiclePersonnelId,
      },
      false
    )
    if (success) {
      setSelectedVehiclePersonnel(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (selectedVehiclePersonnel?.vehiclePersonnelId) fetchVehiclePersonnel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehiclePersonnel?.vehiclePersonnelId])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={localize('viewDisciplinaryCase')}
      handleClose={handleClose}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('personnel')}
            </label>
            <input
              className="form-control"
              value={selectedVehiclePersonnel?.customerFullName}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('document')}
            </label>
            <input
              className="form-control"
              disabled
              value={disciplinaryCase?.fileName}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">{localize('title')}</label>
            <input
              className="form-control"
              disabled
              value={disciplinaryCase?.fileTitle}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('fileDescription')}
            </label>
            <input
              className="form-control"
              type="text"
              disabled
              value={disciplinaryCase?.fileDescription}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('incidentDate')}
            </label>
            <input
              className="form-control"
              disabled
              value={disciplinaryCase?.incidentDate}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">{localize('court')}</label>
            <input
              className="form-control"
              type="text"
              disabled
              value={disciplinaryCase?.court}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('courtDate')}
            </label>
            <input
              className="form-control"
              type="text"
              disabled
              value={parseDate(disciplinaryCase?.courtDate)}
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('cashBail')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.cashBail}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('sentForRefunds')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.sentForRefunds}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('refunded')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.refunded}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('orders')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.orders}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('finalOrders')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.finalOrders}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.createdBy}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(disciplinaryCase?.createdDate)}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control"
              value={disciplinaryCase?.modifiedBy}
              disabled
            />
          </div>
          <div className="d-flex col-md-4 align-items-center">
            <label className="text-capitalize col-3">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control"
              value={formatDate(disciplinaryCase?.modifiedDate)}
              disabled
            />
          </div>
          <div className="d-flex align-items-center col-md-12">
            <label className="text-capitalize col-1">
              {localize('description')}
            </label>
            <textarea
              className="form-control"
              value={disciplinaryCase?.description}
              disabled
            />
          </div>
          <div className="d-flex col-md-12 align-items-center">
            <label className="text-capitalize col-1">
              {localize('remarks')}
            </label>
            <textarea
              className="form-control"
              value={disciplinaryCase?.remarks}
              disabled
            />
          </div>
          <div className="d-flex col-md-12 align-items-center">
            <label className="text-capitalize col-1">
              {localize('orders')}
            </label>
            <textarea
              className="form-control"
              value={disciplinaryCase?.orders}
              disabled
            />
          </div>
          <div className="d-flex align-items-center col-md-12">
            <label className="text-capitalize col-1">
              {localize('finalOrders')}
            </label>
            <textarea
              className="form-control"
              value={disciplinaryCase?.finalOrders}
              disabled
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default ViewDisciplinaryCase
