/** @format */

import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  customerMembershipStatus,
  messageGroupTarget,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { getData, postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import CustomTab from '../CustomTab'
import EmployerTab from '../EmployerTab'
import RolesTab from '../RolesTab'

const CreateMessageGroup = ({
  handleClose,
  mode,
  refresh,
  setRefresh,
  message,
  setMessage,
  targetMessage,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedTargetData, setSelectedTargetData] = useState([])
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const fetchSelectedMessageGroupTargetData = async () => {
    setIsBusy(true)
    const url = '/MessageGroup/find-message-group-targets'

    const { success, data } = await getData(
      url,
      {
        messageGroupId: message?.id,
      },
      false
    )

    if (success) {
      setSelectedTargetData(data?.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchSelectedMessageGroupTargetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const handleCloseModal = () => {
    handleClose()
    setGlobalValidationErrors({})
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleCreateOrUpdate = async () => {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/MessageGroup/add-message-group-with-targets'
        : '/MessageGroup/update-message-group-with-targets'

    const { success } = await postData(
      url,
      {
        messageGroup: message,
        messageGroupTarget: selectedTargetData,
      },
      false
    )

    if (success) {
      handleClose()
      setRefresh(!refresh)
      showNotification('Message Groups', 'success')
    }

    setIsBusy(false)
  }

  console.log(refresh)

  return (
    <ModalTemplate
      modalTitle="messageGroup"
      modalMode={mode}
      modalClassName="modal-xl"
      handleClose={handleCloseModal}
      handleAction={handleCreateOrUpdate}
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-12">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['MessageGroup.Description']?.message
              }
            >
              <label className="text-capitalize col-1" htmlFor="name">
                {localize('name')}
              </label>
              <input
                type="text"
                id="name"
                className="form-control"
                defaultValue={message?.description || ''}
                onChange={e => {
                  clearValidation('MessageGroup.Description')
                  setMessage({
                    ...message,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-2" htmlFor="target">
                {localize('target')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="MessageTarget"
                onChange={e => {
                  setMessage({
                    ...message,
                    target: e.value,
                  })
                }}
                value={
                  message?.target || Object.keys(messageGroupTarget)[1] || ''
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label
                className="text-capitalize col-2"
                htmlFor="membershipStatus"
              >
                {localize('membershipStatus')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerMembershipStatus"
                onChange={e => {
                  setMessage({
                    ...message,
                    membershipStatus: e.value,
                  })
                }}
                value={
                  message?.membershipStatus ||
                  Object.keys(customerMembershipStatus)[0] ||
                  ''
                }
              />
            </div>
          </div>

          <>
            <Tab
              tabItems={[
                {
                  label: localize(
                    Object.values(targetMessage || messageGroupTarget)[1].name
                  ),
                  item: (
                    <div>
                      {Object.values(targetMessage)[1]?.name === 'Role' ? (
                        <div>
                          <RolesTab
                            selectedRoleData={selectedTargetData}
                            onSelectedRoleChange={(role, checked) => {
                              if (checked) {
                                setSelectedTargetData([
                                  ...selectedTargetData,
                                  role,
                                ])
                              } else {
                                setSelectedTargetData(
                                  selectedTargetData?.filter(
                                    x => x.targetIdentifier !== role.description
                                  )
                                )
                              }
                            }}
                            onSelectAll={(roles, v) => {
                              if (v) setSelectedTargetData(roles)
                              else setSelectedTargetData([])
                            }}
                          />
                        </div>
                      ) : Object.values(targetMessage)[1]?.name ===
                        'Employer' ? (
                        <EmployerTab
                          selectedEmployersData={selectedTargetData}
                          onSelectedEmployersChange={(employee, checked) => {
                            if (checked) {
                              setSelectedTargetData([
                                ...selectedTargetData,
                                employee,
                              ])
                            } else {
                              setSelectedTargetData(
                                selectedTargetData.filter(
                                  x => x.targetIdentifier !== employee.id
                                )
                              )
                            }
                          }}
                          onSelectAll={(emp, v) => {
                            if (v) setSelectedTargetData(emp)
                            else setSelectedTargetData([])
                          }}
                        />
                      ) : (
                        Object.values(targetMessage)[1]?.name === 'Custom' && (
                          <>
                            <CustomTab
                              onSelectAll={(cst, v) => {
                                if (v) setSelectedTargetData(cst)
                                else setSelectedTargetData([])
                              }}
                              onSelectedCustomerChange={(customer, checked) => {
                                if (checked) {
                                  setSelectedTargetData([
                                    ...selectedTargetData,
                                    customer,
                                  ])
                                } else {
                                  setSelectedTargetData(
                                    selectedTargetData.filter(
                                      x => x.targetIdentifier !== customer?.id
                                    )
                                  )
                                }
                              }}
                              selectedCustomData={selectedTargetData}
                            />
                          </>
                        )
                      )}
                    </div>
                  ),
                },
                {
                  label: localize('tips'),
                  item: <></>,
                },
              ]}
            />
          </>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateMessageGroup
