/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../../Components/Loaders/Loader'
import CostCentreLookup from '../../../../Components/Lookup/CostCentreLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../Helpers/extensions'
import { formatAsPercent, getTodaysDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function EditCostDistributionModal({ mode, close, rule, refresh }) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedRule, setSelectedRule] = useState(rule)
  const [rulesData, setRulesData] = useState(null)
  const [curRuleData, setCurRuleData] = useState({
    percentage: 0,
  })
  const [ruleEdit, setRuleEdit] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use({})

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const rulesColumns = [
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.percentage)}</>,
    },
  ]

  async function loadRules() {
    setIsBusy(true)
    setRulesData(null)
    const { success, data } = await getData(
      '/costdistributionrule/find-cost-distributions-by-cost-distribution-rule-id',
      { costDistributionRuleId: selectedRule?.id },
      false
    )
    if (success) {
      setRulesData(data?.result)
    }

    setIsBusy(false)
  }

  function removeTemporaryIds(rules) {
    if (!rules) return

    let rrs = [...rules]
    for (let r of rrs) {
      if (r?.id.toString().split('_')[0] === 'temporary') {
        delete r?.id
      }
    }

    return rrs
  }

  const handleSelectCostCenter = c => {
    if (c?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected cost center is locked.'
      )

    clearValidation('CostCenterDescription')
    setCurRuleData({
      ...curRuleData,
      costCenterDescription: c.description,
      costCenterId: c.id,
    })
  }

  const handleClickAddButton = () => {
    if (
      !curRuleData?.costCenterDescription ||
      !curRuleData?.costCenterId ||
      !curRuleData?.description ||
      !curRuleData?.percentage
    ) {
      GlobalValidationErrors.set({
        CostCenterDescription: !curRuleData?.costCenterDescription && {
          message: 'Cost Center is required',
        },
        Description: !curRuleData?.description && {
          message: 'Name is required',
        },
        Percentage: !curRuleData?.percentage && {
          message: 'Percentage is required',
        },
      })

      return
    }

    let cc = {
      ...curRuleData,
      id: 'temporary_' + generateGuid(),
    }
    if (!rulesData) {
      if (parseFloat(cc?.percentage) > 100) {
        GlobalValidationErrors.set({
          Percentage: {
            message: 'Total percentage cannot be greater than 100.',
          },
        })

        return
      }

      setRulesData([cc])
    } else {
      if (
        rulesData?.reduce((a, b) => a + parseFloat(b?.percentage), 0) +
          parseFloat(cc?.percentage) >
        100
      )
        return showNotification(
          'Operation not allowed',
          'error',
          'Total percentage cannot be greater than 100.'
        )

      setRulesData([...rulesData, cc])
    }
    setCurRuleData(null)
  }

  async function handleCreate() {
    setIsBusy(true)

    const { success } = await postData(
      '/CostDistributionRule/create-cost-distribution-rule-with-cost-distributions',
      {
        costDistributionRuleDTO: selectedRule || {},
        costDistributionDTOs: removeTemporaryIds(rulesData),
      },
      false
    )
    if (success) {
      showNotification('Create Cost Distribution Rules', 'success')
      close()
      refresh()
      setSelectedRule(null)
    }

    setIsBusy(false)
  }

  async function handleUpdate() {
    setIsBusy(true)
    const { success } = await putData(
      '/CostDistributionRule/update-cost-distribution-rule-with-cost-distributions',
      {
        costDistributionRuleDTO: selectedRule || {},
        costDistributionDTOs: removeTemporaryIds(rulesData),
      }
    )
    if (success) {
      showNotification('Edit Cost Distribution Rules', 'success')
      close()
      refresh()
      setSelectedRule(null)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadRules()
  }, [])

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="costDistributionRules"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={mode === 'add' ? handleCreate : handleUpdate}
      modalClassName="modal-xl"
      handleClose={close}
      footerChildren={
        mode === 'edit' && (
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              id="is-locked-cost-distribution-rule"
              checked={selectedRule?.isLocked}
              onCheck={v => {
                setSelectedRule({
                  ...selectedRule,
                  isLocked: v,
                })
              }}
            />

            <label
              htmlFor="is-locked-cost-distribution-rule"
              className="text-capitalize"
            >
              {localize('isLocked')} ?
            </label>
          </div>
        )
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div
            className="col-12 d-flex align-items-center with-validation"
            validation-message={
              globalValidationErrors['CostDistributionRuleDTO.Description']
                ?.message
            }
          >
            <label className="col-1 text-capitalize">{localize('name')}</label>
            <input
              type="text"
              className="form-control"
              value={selectedRule?.description}
              onChange={e => {
                clearValidation('CostDistributionRuleDTO.Description')
                setSelectedRule({
                  ...selectedRule,
                  description: e.target.value,
                })
              }}
            />
          </div>
          <div className="row">
            <div className="col-6 d-flex align-items-center mt-3">
              <label className="col-2 text-capitalize">
                {localize('startDate')}
              </label>
              <CustomDatePicker
                name="constDistributionRuleStartDate"
                defaultDate={selectedRule?.durationStartDate}
                minDate={getTodaysDate()}
                onDateChange={date => {
                  setSelectedRule({
                    ...selectedRule,
                    durationStartDate: date,
                  })
                }}
              />
            </div>
            <div className="col-6 d-flex align-items-center mt-3">
              <label className="col-2 text-capitalize">
                {localize('endDate')}
              </label>
              <CustomDatePicker
                minDate={selectedRule?.durationStartDate}
                name="constDistributionRuleEndDate"
                defaultDate={selectedRule?.durationEndDate}
                onDateChange={date => {
                  setSelectedRule({
                    ...selectedRule,
                    durationEndDate: date,
                  })
                }}
              />
            </div>
          </div>
          <div className="mt-3"></div>
          <Tab
            tabItems={[
              {
                label: localize('costDistribution'),
                item: (
                  <>
                    <div
                      className="p-2 mt-2"
                      style={{
                        border: 'solid 1px #0000001f',
                        borderRadius: 5,
                      }}
                    >
                      <div
                        className="bg-white row px-2 text-capitalize"
                        style={{
                          position: 'relative',
                          top: -20,
                          left: 30,
                          maxWidth: 150,
                        }}
                      >
                        {localize('distributionDetails')}
                      </div>
                      <div style={{ marginTop: -15 }} className="row">
                        <div
                          className="col d-flex align-items-center with-validation"
                          validation-message={
                            globalValidationErrors['CostCenterDescription']
                              ?.message
                          }
                        >
                          <label className="text-capitalize col-4">
                            {localize('costCenter')}
                          </label>
                          <CostCentreLookup
                            displayValue={curRuleData?.costCenterDescription}
                            onSelect={handleSelectCostCenter}
                          />
                        </div>
                        <div
                          className="col d-flex align-items-center with-validation"
                          validation-message={
                            globalValidationErrors['Description']?.message
                          }
                        >
                          <label className="text-capitalize col-4">
                            {localize('name')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={curRuleData?.description || ''}
                            onChange={e => {
                              clearValidation('Description')
                              setCurRuleData({
                                ...curRuleData,
                                description: e.target.value,
                              })
                            }}
                          />
                        </div>
                        <div
                          className="col d-flex align-items-center with-validation"
                          validation-message={
                            globalValidationErrors['Percentage']?.message
                          }
                        >
                          <label className="text-capitalize col-4">
                            {localize('percentage')}
                          </label>

                          <MaskPercentage
                            defaultMaskValue={curRuleData?.percentage || ''}
                            onMaskChange={e => {
                              clearValidation('Percentage')
                              setCurRuleData({
                                ...curRuleData,
                                percentage: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end mt-2 bg-light p-2 mb-4">
                        {ruleEdit && (
                          <>
                            <button
                              className="btn btn-outline-danger px-4 me-2"
                              onClick={() => {
                                setRuleEdit(false)
                                setCurRuleData(null)
                              }}
                            >
                              {localize('cancel')}
                            </button>
                            <button
                              className="btn btn-success px-4"
                              onClick={() => {
                                let arr = [...rulesData]
                                let i = arr?.findIndex(
                                  f => f.id === curRuleData.id
                                )
                                arr[i] = curRuleData
                                setRulesData(arr)
                                setRuleEdit(false)
                                setCurRuleData(null)
                              }}
                            >
                              {localize('update')}
                            </button>
                          </>
                        )}
                        {ruleEdit || (
                          <button
                            className="btn btn-success  px-4"
                            onClick={handleClickAddButton}
                          >
                            {localize('add')}
                          </button>
                        )}
                      </div>
                      <div
                        className="p-2 mt-2"
                        style={{
                          border: 'solid 1px #0000001f',
                          borderRadius: 5,
                        }}
                      >
                        <div
                          className="bg-white row px-2 text-capitalize"
                          style={{
                            position: 'relative',
                            top: -20,
                            left: 30,
                            maxWidth: 150,
                          }}
                        >
                          {localize('costDistributions')}
                        </div>
                        <div style={{ marginTop: -15 }} className="row px-2">
                          <SimpleTable
                            columns={rulesColumns}
                            data={rulesData}
                            contextMenu={r => (
                              <>
                                <div
                                  className="ctxt-menu-item"
                                  onClick={() => {
                                    setCurRuleData(r)
                                    setRuleEdit(true)
                                  }}
                                >
                                  <i className="uil uil-edit-alt"></i>
                                  <span className="text-capitalize">
                                    {localize('edit')}
                                  </span>
                                </div>
                                <div
                                  className="ctxt-menu-item text-danger"
                                  onClick={() => {
                                    let arr = [...rulesData]
                                    let found = arr?.findIndex(
                                      c => c.id === r?.id
                                    )
                                    arr?.splice(found, 1)
                                    setRulesData(arr)
                                  }}
                                >
                                  <i className="uil uil-times-circle"></i>
                                  <span className="text-capitalize">
                                    {localize('remove')}
                                  </span>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </ModalTemplate>
  )
}

export default EditCostDistributionModal
