/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import { getData, putData } from '../../../../Helpers/webApi'
import AddCustomer from '../../../../Pages/Registry/Members/Partials/Customers/AddCustomer'
import Checkbox from '../../../Checkbox/Checkbox'
import Loader from '../../../Loaders/Loader'
import SimpleTable from '../../../Tables/SimpleTable'
import EditLoanGuarantor from './EditLoanGuarantor/EditLoanGuarantor'

const LoanGuarantors = ({
  loanCaseId,
  selectedGuarantors,
  handleSelectGuarantor,
  handleSelectAllGuarantors,
  handleRemove,
  reload,
  setReload,
  showRemoveButton,
}) => {
  const [guarantors, setGuarantors] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [guarantor, setGuarantor] = useState({})
  const [guarantorAmounts, setGuarantorAmounts] = useState({})
  const [waitingEdit, setWaitingEdit] = useState(false)

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedGuarantors?.find(x => x?.id === r?.id)}
          onCheck={value => {
            handleSelectGuarantor(r, value)
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    { label: localize('customerName'), template: r => r?.customerFullName },
    {
      label: localize('membershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('identificationNumber'),
      template: r => r?.customerIdentificationNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanProductDescription,
    },
    {
      label: localize('loanCaseApprovedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => formatCurrency(r?.loanBalanceOutstanding),
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => r?.loanDefaultAmount,
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment),
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanBranch'),
      template: r => r?.loanCaseBranchDescription,
    },
    {
      label: localize('totalShares'),
      template: r => formatCurrency(r?.totalShares),
    },
    {
      label: localize('committedShares'),
      template: r => formatCurrency(r?.committedShares),
    },
    {
      label: localize('amountGuaranteed'),
      template: r => formatCurrency(r?.amountGuaranteed),
    },
    {
      label: localize('amountPledged'),
      template: r => formatCurrency(r?.amountPledged),
    },
    { label: localize('appraisalFactor'), template: r => r?.appraisalFactor },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const handleClickContextMenu = (r, action) => {
    setMode(action)
    setGuarantor(r)
    setGuarantorAmounts({
      amountGuaranteed: r?.amountGuaranteed,
      amountPledged: r?.amountPledged,
    })
  }

  const fetchLoanGuarantors = async () => {
    setIsBusy(true)
    const url = `/LoanCase/find-loan-guarantors-by-loan-case-id?loanCaseId=${loanCaseId}`

    const { success, data } = await getData(
      url,
      {
        evaluateCommitments: true,
      },
      false
    )

    if (success) {
      setGuarantors(data?.result)
      setIsBusy(false)

      if (reload) setReload(false)
    } else {
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setGuarantor({})
    setGuarantorAmounts({
      amountGuaranteed: 0,
      amountPledged: 0,
    })
  }

  const handleEditLoanGuarantor = async () => {
    setIsBusy(true)
    setWaitingEdit(true)

    const url = '/LoanCase/update-loan-guarantor-by-loan-case-id'

    const { success } = await putData(
      url,
      {
        loanCaseId: guarantor?.loanCaseId,
        loanGuarantors: [
          {
            ...guarantor,
            amountGuaranteed: guarantorAmounts?.amountGuaranteed,
            amountPledged: guarantorAmounts?.amountPledged,
          },
        ],
      },
      false
    )

    if (success) {
      setIsBusy(false)
      showNotification('editLoanGuarantor', 'success')
      setReload(true)
      handleClose()
      setWaitingEdit(false)
    } else {
      setIsBusy(false)
    }

    setWaitingEdit(false)
  }

  useEffect(() => {
    if (loanCaseId) fetchLoanGuarantors()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanCaseId, reload])

  return (
    <>
      {mode === 'view' && (
        <AddCustomer
          closeModal={handleClose}
          readOnly
          mode={mode}
          onMinimize={() => {}}
          customer={{
            id: guarantor?.customerId,
          }}
          refreshFromServer
        />
      )}

      {mode === 'edit' && (
        <EditLoanGuarantor
          handleClose={handleClose}
          guarantor={guarantor}
          mode={mode}
          handleEditLoanGuarantor={handleEditLoanGuarantor}
          guarantorAmounts={guarantorAmounts}
          setGuarantorAmounts={setGuarantorAmounts}
          isBusy={waitingEdit}
        />
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={columns}
            data={guarantors}
            contextMenu={r =>
              showRemoveButton && (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleClickContextMenu(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleClickContextMenu(r, 'edit')
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span>{localize('edit')}</span>
                  </div>
                </>
              )
            }
          />

          <div className="d-flex justify-content-between p-2 rounded bg-light mt-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                checked={selectAll}
                id={`selectAllLoanGuarantors-${loanCaseId}`}
                onCheck={value => {
                  setSelectAll(value)
                  handleSelectAllGuarantors(guarantors, value)
                }}
              />

              <label
                htmlFor={`selectAllLoanGuarantors-${loanCaseId}`}
                className="ms-2"
              >
                {localize('toggleSelection')}
              </label>
            </div>

            {showRemoveButton && (
              <div className="d-flex justify-content-end align-items-center">
                <button className="btn btn-success" onClick={handleRemove}>
                  {localize('remove')}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default LoanGuarantors
