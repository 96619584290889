/** @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { CustomerAccountPreviewMiniStatement } from './CustomerAccountPreview/CustomerAccountPreviewMiniStatement'
import localize from '../../../../../../../../../Global/Localize'
import Tab from '../../../../../../../../../Components/Tabs/Tab'
import { CustomerAccountPreviewSignatories } from './CustomerAccountPreview/CustomerAccountPreviewSignatories'
import { CustomerAccountPreviewUnclearedCheques } from './CustomerAccountPreview/CustomerAccountPreviewUnclearedCheques'
import { CustomerAccountPreviewSigningInstructions } from './CustomerAccountPreview/CustomerAccountPreviewSigningInstructions'
import { postData } from '../../../../../../../../../Helpers/webApi'

export const CustomerAccountPreview = ({ selectedCustomerAccount }) => {
  const [loading, setLoading] = useState({
    loadingMiniStatement: false,
    loadingSignatories: false,
    loadingUnclearedCheques: false,
    loadingSigningInstructions: false,
  })

  const [accountPreview, setAccountPreview] = useState(null)
  const fetchCustomerAccountPreview = async () => {
    setLoading({
      loadingMiniStatement: true,
      loadingSignatories: true,
      loadingUnclearedCheques: true,
      loadingSigningInstructions: true,
    })
    const { success, data } = await postData(
      '/lookup/Teller/load-customer-account-preview',
      {
        ...selectedCustomerAccount,
      },
      false,
      null
    )
    if (success) {
      setAccountPreview(data)
      setLoading({
        loadingMiniStatement: false,
        loadingSignatories: false,
        loadingUnclearedCheques: false,
        loadingSigningInstructions: false,
      })
    }
  }

  useEffect(() => {
    if (selectedCustomerAccount) {
      fetchCustomerAccountPreview()
    }
  }, [selectedCustomerAccount])

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            preload
            tabItems={[
              {
                label: localize('miniStatement'),
                item: (
                  <CustomerAccountPreviewMiniStatement
                    data={accountPreview?.generalLedgerTransaction}
                    loading={loading.loadingMiniStatement}
                  />
                ),
              },
              {
                label: localize('signatories'),
                item: (
                  <CustomerAccountPreviewSignatories
                    data={accountPreview?.customerAccountSignatory}
                    loading={loading.loadingSignatories}
                  />
                ),
              },
              {
                label: localize('unclearedCheques'),
                item: (
                  <CustomerAccountPreviewUnclearedCheques
                    unclearedCheques={accountPreview?.externalCheques}
                    loading={loading.loadingUnclearedCheques}
                  />
                ),
              },
              {
                label: localize('signingInstructions'),
                item: (
                  <CustomerAccountPreviewSigningInstructions
                    signingInstructions={
                      accountPreview?.customerAccountSignatory
                    }
                    loading={loading.loadingSigningInstructions}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
