/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { administrativeDivisionType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CreateAdministrativeDivision from './CreateAdministrativeDivision/CreateAdministrativeDivision'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function AdministrativeDivisions() {
  const [modalMode, setModalMode] = useState(null)
  const [division, setDivision] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [gbv, setGbv] = GlobalValidationErrors.use()
  const [search, setSearch] = useState('')
  const [tableMeta, setTableMeta] = useState({})
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })

  const columns = [
    {
      label: localize('description'),
      template: r => (
        <span
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        ></span>
      ),
      sortBy: 'description',
    },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
  ]

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const url = '/administrativedivision/find-by-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setModalMode(null)
    setDivision(null)
  }

  async function handleCreate() {
    setIsBusy(true)
    const { success } = await postData(
      '/administrativeDivision/create',
      division,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Administrative Divisions', 'success')
      handleClose()
    }
    setIsBusy(false)
  }
  async function handleUpdate() {
    setIsBusy(true)
    const { success } = await putData(
      '/administrativeDivision/update',
      division,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Administrative Divisions', 'success')
      handleClose()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    return () => setGbv({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const handleClickAddButton = () => {
    setDivision({
      type: administrativeDivisionType.HEADER_ENTRY,
    })
    setModalMode('add')
  }

  return (
    <>
      {modalMode && (
        <CreateAdministrativeDivision
          clearValidation={clearValidation}
          division={division}
          gbv={gbv}
          handleClose={handleClose}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          isBusy={isBusy}
          modalMode={modalMode}
          setDivision={setDivision}
        />
      )}
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('administrativeDivisions'),
        ]}
      />
      <div className="card bg-white">
        <div className="card-body">
          <AddNewButton handleClickAddButton={handleClickAddButton} />
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center mt-3 mb-3 ms-2 justify-content-between">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="traverse"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                  <label htmlFor="traverse" className="text-capitalize ms-2">
                    {localize('traverseTree') + '?'}
                  </label>
                </div>
                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                      pageIndex: 0,
                    })
                  }}
                >
                  <label
                    className="form-label text-capitalize"
                    htmlFor="admin-search"
                  >
                    {localize('search')}
                  </label>
                  <input
                    id="admin-search"
                    type="search"
                    className="form-control ms-2"
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <button
                    id="filterAdminButton"
                    type="button"
                    className="btn btn-primary ms-1"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <SimpleTable
                columns={columns}
                data={tableData}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setDivision(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                {...tableMeta}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AdministrativeDivisions
