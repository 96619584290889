/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { batchStatus } from '../../../../Global/enumeration'
import { queuePriority } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import AddNewButton from '../../../Buttons/AddNewButton/AddNewButton'
import DatePicker from '../../../Date/DatePicker'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'
import ViewDebitBatchModal from '../ViewDebitBatchModal/ViewDebitBatchModal'
import EditDebitBatchModal from './EditDebitBatchModal/EditDebitBatchModal'
import Swal from 'sweetalert2'
import Loader from '../../../Loaders/Loader'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../ViewAuditLogs/ViewAuditLogs'

const initialBatch = {
  priority: queuePriority.NORMAL,
}

const ListDebitBatches = ({
  showCreateButton,
  status,
  handleClickAddButton,
  reload,
  setReload,
  onSelect,
  canSelect,
  showEdit,
  showVerify,
  handleVerifyBatch,
  getShortage,
  showAuthorize,
  handleSelectAuthBatch,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [batches, setBatches] = useState([])
  const [batch, setBatch] = useState(initialBatch)
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: status ?? Object.keys(batchStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('batch'),
      template: r => r?.paddedBatchNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('debitType'),
      template: r => r?.debitTypeDescription,
    },
    {
      label: localize('priority'),
      template: r => r?.priorityDescription,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchDebitBatches = async () => {
    setIsBusy(true)
    const url = '/DebitBatch/find-debit-batches-by-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setBatches(data)
      if (getShortage) getShortage(data.totalShortage)
      if (setReload) setReload(false)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setBatch(initialBatch)
  }

  const handleSelectDebitBatch = (r, action) => {
    if (
      action === 'edit' &&
      r?.status !== Object.keys(batchStatus)[0] &&
      r?.status !== Object.keys(batchStatus)[4]
    ) {
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Only batches with status Pending or Rejected can be edited',
      })
    }
    setBatch({
      debitBatch: r,
      debitBatchId: r?.id,
      multiplier: 0.0,
      basisValue: 0.0,
    })

    setMode(action)
  }

  useEffect(() => {
    fetchDebitBatches()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (reload) fetchDebitBatches()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      {mode === 'view' && (
        <ViewDebitBatchModal
          batch={batch}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      {mode === 'edit' && (
        <EditDebitBatchModal
          batch={batch}
          handleClose={handleClose}
          mode={mode}
          setBatch={setBatch}
          fetchDebitBatches={fetchDebitBatches}
        />
      )}

      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}

      {showCreateButton && (
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start">
              <AddNewButton handleClickAddButton={handleClickAddButton} />
            </div>
          </div>
        </div>
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3 mt-2 d-flex align-items-center justify-content-between">
            <div className="col-md-3">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="col-md-2">
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="status" className="text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchStatus"
                  value={reqParams.status}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                      pageIndex: 0,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center gap-2">
              <label htmlFor="search" className="text-capitalize">
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams.startDate,
                  endDate: reqParams.endDate,
                }}
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center justify-content-end gap-2">
                <label htmlFor="search" className="text-capitalize">
                  {localize('search')}
                </label>

                <input
                  type="search"
                  id="search"
                  className="form-control"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchDebitBatches()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={e => {
                    fetchDebitBatches()
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchDebitBatches()
                    }
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <SimpleTable
            columns={columns}
            data={batches?.pageCollection}
            canSelect={canSelect}
            onSelect={r => onSelect(r)}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectDebitBatch(r, 'view')
                  }}
                >
                  <i className="uil uil-eye"></i>
                  <span>{localize('view')}</span>
                </div>
                {showEdit && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectDebitBatch(r, 'edit')
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span>{localize('edit')}</span>
                  </div>
                )}
                {showVerify && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleVerifyBatch(r)
                    }}
                  >
                    <i className="mdi mdi-check-decagram"></i>
                    <span>{localize('verify')}</span>
                  </div>
                )}

                {showAuthorize && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectAuthBatch(r)
                    }}
                  >
                    <i className="mdi mdi-lock-open-check-outline"></i>
                    <span>{localize('authorize')}</span>
                  </div>
                )}
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleViewAuditLogs(r)
                  }}
                >
                  <i className="uil uil-server"></i>
                  <span>{localize('viewAuditLog')}</span>
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={batches?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default ListDebitBatches
