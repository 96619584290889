/** @format */

//Catalogue
/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData, postData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import DatePicker from '../../../../../Components/Date/DatePicker'
import CreateCatalogue from './CreateCatalogue'
import {
  formatCurrency,
  formatDate,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import { CustomerFilter } from '../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function Catalogue() {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [modalMode, setModalMode] = useState(null)
  const [createParams, setCreateParams] = useState(false)
  const [catalogue, setCatalogue] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    startDate: '2000-01-01',
    endDate: getTodaysDate(),
    status: 1,
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: false,
    customDate: false,
    dateRequestsFilter: 0,
  })
  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedCustomerAccountCustomerSerialNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.postingPeriodDescription}</>,
    },
    {
      label: localize('numberOfPeriods'),
      template: r => <>{r?.numberOfPeriods}</>,
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => <>{formatCurrency(r?.paymentPerPeriod)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.customerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerAccountNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.customerAccountCustomerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.customerAccountCustomerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAccountCustomerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAccountCustomerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAccountCustomerAddressCity}</>,
    },
    {
      label: localize('e-mailAddress'),
      template: r => <>{r?.customerAccountCustomerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAccountCustomerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAccountCustomerAddressMobileLine}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r?.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r?.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchCatalogueData = async () => {
    setIsBusy(true)
    const url =
      '/LoanRescheduleRequest/find-loan-reschedule-request-by-status-and-date-range-and-filter-in-page'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleCreateLoanReschedule = async () => {
    if (!catalogue.reference) {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'The reference field is required.',
      })
    } else {
      setIsBusy(true)
      const { success } = await postData(
        '/LoanRescheduleRequest/create-loan-reschedule-request',
        { createParams },
        false,
        'Loan Reschedule Request created successfully.'
      )

      if (success) {
        setIsBusy(false)
        handleClose()
        fetchCatalogueData()
      } else {
        setIsBusy(false)
      }
    }
  }

  const handleAddButtonClick = () => {
    setModalMode('add')
  }

  const handleClose = () => {
    setModalMode(null)
  }

  useEffect(() => {
    fetchCatalogueData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanRestructuring'),
          localize('catalogue'),
        ]}
      />
      {modalMode === 'add' && (
        <CreateCatalogue
          handleAction={handleCreateLoanReschedule}
          handleClose={handleClose}
          mode={modalMode}
          catalogue={catalogue}
          setCatalogue={setCatalogue}
          createParams={createParams}
          setCreateParams={setCreateParams}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-4">
                <div className="col">
                  <AddNewButton handleClickAddButton={handleAddButtonClick} />
                </div>
              </div>
              <div className="row d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center col-2">
                  <label htmlFor="recordsPerPage" className="text-capitalize">
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    name=""
                    id="recordsPerPage"
                    className="form-select ms-3"
                    style={{ maxWidth: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                </div>
                <div className="col-2 d-flex align-items-center">
                  <label className="text-capitalize me-2" htmlFor="searchTable">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="LoanRescheduleRequestStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center col-3">
                  <label
                    className="text-capitalize"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {localize('dateRange')}
                  </label>
                  <div className="col-6 ms-2">
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col d-flex align-items-center space-between">
                  <label className="text-capitalize me-2" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="CustomerFilter"
                    value={reqParams.customerFilter}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        customerFilter: e.value,
                      })
                    }
                  />

                  <form
                    className="d-flex align-items-center col-4"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <input
                      type="text"
                      className="form-control ms-2"
                      onChange={e => setSearch(e.target.value)}
                    ></input>
                  </form>
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchCatalogueData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <SimpleTable columns={columns} data={data?.pageCollection} />
              </div>
              <Pager
                itemsCount={data?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Catalogue
