/**
 * eslint-disable
 *
 * @format
 */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function DesignationLookup({ onSelect, displayValue, disabled }) {
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        title={localize('designationLookup')}
        url="/lookup/Designation/find-designation-by-filter-in-page"
        params={{
          filterText: '',
          pageSize: 10,
          pageIndex: 0,
          updateDepth: true,
          traverseTree: true,
        }}
        columns={[
          {
            label: localize('name'),
            template: r => (
              <span
                dangerouslySetInnerHTML={{ __html: r.indentedDescription }}
              />
            ),
            sortBy: 'description',
          },
          { label: localize('remarks'), template: r => <>{r.remarks}</> },
          {
            label: localize('isLocked') + '?',
            template: r => (
              <span className="text-capitalize">
                {localize(r.isLocked.toString())}
              </span>
            ),
            sortBy: 'isLocked',
          },
          {
            label: localize('createdBy'),
            template: r => <>{r.createdBy}</>,
            sortBy: 'createdBy',
          },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r.createdDate)}</>,
            sortBy: 'createdDate',
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}
export default DesignationLookup
