/** @format */

import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { AuthorizedBranches } from './Partials/AuthorizedBranches'
import RoleLookup from '../../../../Components/Lookup/RoleLookup'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

export const EditUserModal = ({
  selectedUser,
  setSelectedUser,
  waitingMetadata,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employee')}
              </label>
              <input
                className={'form-control'}
                value={selectedUser?.customerFullName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={selectedUser?.employeeBranchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDesignationDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDepartmentDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('emailAddress')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeCustomerAddressEmail}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.['UserDto.RoleId']?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('role')}
              </label>
              <RoleLookup
                displayValue={selectedUser?.roleDescription || ''}
                onSelect={r => {
                  clearValidation('UserDto.RoleId')
                  setSelectedUser({
                    ...selectedUser,
                    roleId: r.id,
                    roleDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('userName')}
              </label>
              <input
                className="form-control"
                value={selectedUser?.description}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('authorizedBranches'),
                    item: (
                      <AuthorizedBranches
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        isBusy={waitingMetadata}
                        prefix={'edit-user-authorized-branches'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
