/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../../Components/Tables/Pager'

export const ViewPurchaseRequisitionEntries = ({
  entries,
  reqParams,
  setReqParams,
}) => {
  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    { label: localize('reason'), template: r => <>{r.reason}</> },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={entries?.pageCollection} />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
