/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../../Global/Localize'
import { useEffect } from 'react'

const MessageGroups = ({ selectedGroups, handelSelectGroup }) => {
  const [groups, setGroups] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const fetchGroups = async () => {
    setIsBusy(true)

    const url = '/MessageGroup/find-message-groups-by-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        filter: '',
        pageIndex: 0,
        // TODO: Myke - use a different API: 07/09/2023
        pageSize: 1000,
      },
      false
    )

    if (success) {
      setGroups(data?.pageCollection)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchGroups()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <div
          className="row bg-light p-2"
          style={{
            maxHeight: '50dvh',
            overflowY: 'scroll',
          }}
        >
          {groups?.map(group => (
            <div
              className="col-md-12"
              key={group?.id}
              style={{ cursor: 'pointer' }}
            >
              <div className="d-flex align-items-center justify-content-start">
                <Checkbox
                  id={`${group?.id}-message-group`}
                  checked={selectedGroups?.find(data => data?.id === group?.id)}
                  onCheck={v => handelSelectGroup(group, v)}
                />
                <label
                  className="text-capitalize ms-3"
                  htmlFor={`${group?.id}-message-group`}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {localize(group.description)}
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default MessageGroups
