/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SearchCustomer from '../../SearchCustomer'
import {
  corporationMemberType,
  gender,
  identityCardType,
  nationality,
  partnershipRelationship,
  salutation,
} from '../../../../../../../Global/enumeration'
import localize from '../../../../../../../Global/Localize'
import AddressV2 from '../../../../../../../Components/Miscalleneous/AddressV2'
import Tab from '../../../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import { generateGuid } from '../../../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

function Corporation({ customer }) {
  const columns = [
    { label: 'memberType', template: r => <>{r.memberTypeDescription}</> },
    { label: 'customerFullName', template: r => <>{r.customerFullName}</> },
    { label: 'Gender', template: r => <>{r.customerGenderDescription}</> },
    {
      label: 'identityCardType',
      template: r => <>{r.customerIdentityCardTypeDescription}</>,
    },
    {
      label: 'identityCardNumber',
      template: r => <>{r.customerIdentityCardNumber}</>,
    },
    {
      label: 'identityCardSerialNumber',
      template: r => <>{r.customerIdentityCardSerialNumber}</>,
    },
    {
      label: 'addressLine1',
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: 'addressLine2',
      template: r => <>{r.customerAddressAddressLine}</>,
    },
    { label: 'physicalAddress', template: r => <>{r.customerAddressStreet}</> },
    { label: 'postalCode', template: r => <>{r.customerAddressCity}</> },
    { label: 'city', template: r => <>{r.customerAddressCity}</> },
    { label: 'email', template: r => <>{r.customerAddressEmail}</> },
    { label: 'landLine', template: r => <>{r.customerAddressLandLine}</> },
    { label: 'mobileLine', template: r => <>{r.customerAddressMobileLine}</> },
    { label: 'fullName', template: r => <>{r.fullName}</> },
    { label: 'family', template: r => <>{r.family}</> },
    { label: 'ethnicGroup', template: r => <>{r.ethnicGroup}</> },
    { label: 'clan', template: r => <>{r.clan}</> },
    { label: 'gender', template: r => <>{r.genderDescription}</> },
    { label: 'relationship', template: r => <>{r.relationshipDescription}</> },
    { label: 'nationality', template: r => <>{r.nationalityDescription}</> },
    {
      label: 'identityCardType',
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    { label: 'identityCardNumber', template: r => <>{r.identityCardNumber}</> },
    {
      label: 'identityCardSerialNumber',
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    { label: 'addressLine1', template: r => <>{r.addressAddressLine1}</> },
    { label: 'addressLine2', template: r => <>{r.addressAddressLine2}</> },
    { label: 'physicalAddress', template: r => <>{r.addressStreet}</> },
    { label: 'postalCode', template: r => <>{r.addressPostalCode}</> },
    { label: 'city', template: r => <>{r.addressCity}</> },
    { label: 'email', template: r => <>{r.addressEmail}</> },
    { label: 'landline', template: r => <>{r.addressLandLine}</> },
    { label: 'mobileLine', template: r => <>{r.addressMobileLine}</> },
    { label: 'internet', template: r => <>{r.addressInternet}</> },
    {
      label: 'signatory',
      template: r => (
        <span className="text-capitalize">{r.signatory.toString()}</span>
      ),
    },
    { label: 'remarks', template: r => <span className="">{r.remarks}</span> },
  ]
  const [cMember, setCMember] = useState(null)
  const [memberType, setMemberType] = useState(0) // defaulted to internal
  const [members, setMembers] = useState([])
  const [signatory, setSignatory] = useState(false)

  function addMember() {
    let customerObject =
      // eslint-disable-next-line eqeqeq
      memberType == 0
        ? {
            customerId: cMember.id,
            customerGenderDescription: cMember.genderDescription,
            customerFullName: cMember.fullName,
            customerIndividualGenderDescription:
              cMember.individualGenderDescription,
            customerIdentityCardTypeDescription:
              cMember.identityCardTypeDescription,
            customerIdentityCardNumber: cMember.identityCardNumber,
            customerIdentityCardSerialNumber: cMember.identityCardSerialNumber,
            customerAddressAddressLine1: cMember.addressAddressLine1,
            customerAddressAddressLine2: cMember.addressAddressLine2,
            customerAddressAddressStreet: cMember.addressAddressStreet,
            customerAddressPostalCode: cMember.addressPostalCode,
            customerAddressCity: cMember.addressCity,
            customerAddressEmail: cMember.addressEmail,
            customerAddressLandLine: cMember.addressLandLine,
            customerAddressMobileLine: cMember.addressMobileLine,
          }
        : {
            ...cMember,
            fullName: `${cMember.salutationDescription} ${cMember.firstName} ${cMember.lastName}`,
          }

    const serializedMember = {
      ...customerObject,
      id: generateGuid(),
      signatory,
      memberType: memberType,
      memberTypeDescription: corporationMemberType[memberType].name,
    }

    setMembers([...members, serializedMember])
    setCMember(null)
    setMemberType(0)
    setSignatory(false)
  }

  function removeMember(r) {
    let ms = [...members]
    let idn = ms.findIndex(m => m.id === r.id)
    ms.splice(idn, 1)
    setMembers(ms)
  }

  async function updateMembers() {
    const { success, data } = await putData(
      '/customer/update-corporation-member-collection-by-corporation-id?corporationId=' +
        customer?.id,
      members
    )

    if (success) {
      await loadMembers()
      Swal.fire(
        'Operation Successful',
        'Membership details updated!',
        'success'
      )
    }
  }

  useEffect(() => {
    loadMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadMembers() {
    const { success, data } = await getData(
      '/customer/find-corporation-member-collection-by-corporation-id',
      { corporationId: customer?.id }
    )
    if (success) {
      setMembers(data?.result)
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <label htmlFor="" className="text-capitalize">
          {localize('memberType')}
        </label>
        <EnumsServerCombo
          customContainerStyle={{ width: '100%' }}
          enumsKey="CorporationMemberType"
          value={memberType}
          onChange={e => setMemberType(e.value)}
        />
      </div>
      {/* eslint-disable-next-line eqeqeq */}
      {memberType == 0 ? (
        <SearchCustomer customer={cMember} onSelect={c => setCMember(c)} />
      ) : (
        <>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('relationship')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="PartnershipRelationship"
                value={
                  cMember?.relationship ||
                  Object.keys(partnershipRelationship)[0]
                }
                onChange={e =>
                  setCMember({
                    ...cMember,
                    relationship: e.value,
                    relationshipDescription:
                      partnershipRelationship[e.value].name,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('nationality')}
              </label>
              <select
                className="form-select"
                value={cMember?.nationality || nationality[0]}
                onChange={e =>
                  setCMember({
                    ...cMember,
                    nationality: e.target.value,
                    nationalityDescription: nationality[e.target.value],
                  })
                }
              >
                {nationality.map((nat, i) => (
                  <option key={`nationlity_${i}`} value={i}>
                    {nat}
                  </option>
                ))}
              </select>
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('gender')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="Gender"
                value={cMember?.gender || Object.keys(gender)[0]}
                onChange={e =>
                  setCMember({
                    ...cMember,
                    gender: e.value,
                    genderDescription: gender[e.value].name,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('salutation')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="Salutation"
                value={cMember?.salutation || Object.keys(salutation)[0]}
                onChange={e =>
                  setCMember({
                    ...cMember,
                    salutation: e.value,
                    salutationDescription: salutation[e.value].name,
                  })
                }
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('firstName')}
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.firstName || ''}
                onChange={e =>
                  setCMember({ ...cMember, firstName: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('otherNames')}
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.lastName || ''}
                onChange={e =>
                  setCMember({ ...cMember, lastName: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('family')}
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.family || ''}
                onChange={e =>
                  setCMember({ ...cMember, family: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('clan')}
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.clan || ''}
                onChange={e => setCMember({ ...cMember, clan: e.target.value })}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('ethnicGroup')}
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.ethnicGroup || ''}
                onChange={e =>
                  setCMember({ ...cMember, ethnicGroup: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('identityCardType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="IdentityCardType"
                value={
                  cMember?.identityCardType || Object.keys(identityCardType)[0]
                }
                onChange={e =>
                  setCMember({
                    ...cMember,
                    identityCardType: e.value,
                    identityCardTypeDescription: identityCardType[e.value].name,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('identityCard')} #
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.identityCardNumber || ''}
                onChange={e =>
                  setCMember({ ...cMember, identityCardNumber: e.target.value })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('identityCardSerial')} #
              </label>
              <input
                type="text"
                className="form-control"
                value={cMember?.identityCardSerialNumber || ''}
                onChange={e =>
                  setCMember({
                    ...cMember,
                    identityCardSerialNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <Tab
            tabItems={[
              {
                label: 'address',
                item: (
                  <AddressV2
                    parent={null}
                    onChange={address => {
                      setCMember({ ...cMember, ...address })
                    }}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      <div className="bg-light d-flex align-items-center justify-content-end mt-2 p-2">
        <div className="d-flex align-items-center gap-2 me-4">
          <Checkbox
            id="addCopMemberSignatory"
            checked={signatory}
            onCheck={v => setSignatory(v)}
          />
          <label
            htmlFor="addCopMemberSignatory"
            className={`text-capitalize ${signatory ? 'text-primary' : ''}`}
          >
            {localize('signatory')}
          </label>
        </div>
        <button className="btn btn-info px-4" onClick={addMember}>
          {localize('add')}
        </button>
      </div>
      <div className="mt-3">
        <SimpleTable
          columns={columns}
          data={members}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-danger"
                onClick={() => removeMember(r)}
              >
                <i className="uil uil-times-circle"></i>
                <span className="text-capitalize">{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
      <div className="my-2">
        <button className="btn btn-info px-4" onClick={updateMembers}>
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default Corporation
