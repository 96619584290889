/** @format */

import localize from '../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../Helpers/formatters'
import Checkbox from '../../Checkbox/Checkbox'
import SimpleTable from '../../Tables/SimpleTable'

export const LoansGuaranteed = ({
  loanGuarantors,
  showSelect,
  selectedLoans,
  handleSelectLoan,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedLoans?.find(x => x?.id === r?.id)}
          onCheck={value => {
            handleSelectLoan(r, value)
          }}
        />
      ),
    },

    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('customerFullName'),
      template: r => <>{r?.loaneeCustomerFullName}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.loaneeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedLoaneeCustomerMemberNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r?.totalShares)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r?.committedShares)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r?.amountGuaranteed)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r?.amountPledged)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanBalance'),
      template: r => <>{formatCurrency(r?.loanBalanceOutstanding)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r?.loanClassificationDescription}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r?.loanBalanceExpected)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r?.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r?.loanDefaultAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => <>{r?.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision(Percentage)'),
      template: r => <>{formatAsPercent(r?.loanLossProvisionPercentage)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanLossProvision(Amount)'),
      template: r => <>{formatCurrency(r?.loanLossProvisionAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{formatDate(r?.loanLastPaymentDueDate)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r?.LoanLastPayment}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanCaseLoanPurposeDescription}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r?.loanCaseStatusDescription}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r?.loanCaseBranchDescription}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => <>{r?.appraisalFactor}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  const columnsToDisplay = columns?.filter((_col, i) => i !== 0)

  return (
    <SimpleTable
      data={loanGuarantors}
      columns={showSelect ? columns : columnsToDisplay}
    />
  )
}
