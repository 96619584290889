/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import Tab from '../../../../Components/Tabs/Tab'
import Specimen from '../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'

function ViewEmployee({ selectedEmployee }) {
  const [employee, setEmployee] = useState(selectedEmployee)
  const [isBusy, setIsBusy] = useState(false)

  const tabItems = [
    { label: localize('individualParticulars'), item: <>IP</> },
    { label: localize('address'), item: <>IP</> },
    { label: localize('emergencyContacts'), item: <>IP</> },
    { label: localize('dependants'), item: <>IP</> },
    { label: localize('referees'), item: <>IP</> },
    {
      label: localize('specimen'),
      item: <Specimen readOnly customer={{ id: employee?.customerId }} />,
      icon: 'uil uil-camera',
    },
    { label: localize('savingsAccounts'), item: <>IP</> },
    { label: localize('loanAccounts'), item: <>IP</> },
    { label: localize('investmentAccounts'), item: <>IP</> },
    { label: localize('disciplinaryCases'), item: <>IP</> },
    { label: localize('trainings'), item: <>IP</> },
  ]

  async function fetchEmployee() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeRegister/find-by-id',
      { employeeId: selectedEmployee?.id },
      false
    )
    if (success) {
      setEmployee(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchEmployee()
    // eslint-disable-next-line
  }, [selectedEmployee])

  return isBusy ? (
    <Loader />
  ) : (
    <div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employee')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.customerFullName}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('designation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.designationDescription}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('branch')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.branchDescription}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('department')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.departmentDescription}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employeeType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.employeeTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('bloodGroup')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.bloodGroupDescription}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('PIN')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.customerPersonalIdentificationNumber}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('NHIF')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.nationalHospitalInsuranceFundNumber}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('NSSF')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.nationalSocialSecurityFundNumber}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('costCenter')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.costCenterDescription}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('hireDate')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatDate(employee?.hireDate, true)}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('releaseDate')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatDate(employee?.releaseDate, true)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('printingMode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.printingModeDescription}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('inactivityTimeout')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.inactivityTimeout}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employmentStatus')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.employmentStatusDescription}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.remarks}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemInitializationTime')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.timeDurationStartTime}
          />
        </div>
        <div className="col d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemLockTime')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={employee?.timeDurationEndTime}
          />
        </div>
      </div>
      <div className="mt-5">
        <Tab tabItems={tabItems} />
      </div>
    </div>
  )
}

export default ViewEmployee
