/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import { LoansGuaranteed } from '../../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import InvestmentAccounts from '../../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import SavingsAccounts from '../../../../../../Components/ReUsables/SavingsAccounts/SavingsAccounts'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import {
  ProductCode,
  RecordStatus,
  customerMembershipStatus,
} from '../../../../../../Global/enumeration'
import { getData } from '../../../../../../Helpers/webApi'
import Specimen from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import SelectedSubstitutes from './SelectedSubstitutes/SelectedSubstitutes'
import './createSubstitution.scss'

const CreateSubstitution = ({
  mode,
  handleAction,
  handleClose,
  customer,
  setCustomer,
  loansGuaranteed,
  selectedLoans,
  handleSelectLoan,
  substituteDetails,
  setSubstituteDetails,
  handleAdd,
  substitutionAction,
  setSubstitutionAction,
  selectedSubstitutes,
  handleRemoveSelectedSubstitute,
  selectedGuarantorSubstitutes,
  handleSelectGuarantorSubstitute,
  isBusyUpdating,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [customerAccounts, setCustomerAccounts] = useState({
    customerLoanAccounts: [],
    customerSavingsAccounts: [],
    customerInvestmentAccounts: [],
  })
  const [substituteLoansGuaranteed, setSubstituteLoansGuaranteed] = useState([])

  const fetchCustomerAccounts = async () => {
    setIsBusy(true)
    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customer?.id,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)
    }

    setIsBusy(false)
  }

  const fetchLoansGuaranteed = async () => {
    setIsBusy(true)
    const url = '/LoanCase/find-loan-guarantors-by-loanee-customer-id'

    const { success, data } = await getData(
      url,
      {
        loaneeCustomerId: substituteDetails?.id,
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      setSubstituteLoansGuaranteed(data?.result)
    }

    setIsBusy(false)
  }

  const handleOnLookupCustomer = customer => {
    if (customer?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer is locked',
      })

    if (customer?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Selected Customer record status must be approved',
      })

    setCustomer(customer)
  }

  const handleOnLookupGuarantor = guarantor => {
    if (!customer?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a customer first',
      })

    if (guarantor?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer is locked',
      })

    if (
      guarantor?.membershipStatus !== Object.keys(customerMembershipStatus)[0]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer membership status must be active',
      })

    setSubstituteDetails(guarantor)
  }

  useEffect(() => {
    if (customer?.id) fetchCustomerAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  useEffect(() => {
    if (substituteDetails?.id) fetchLoansGuaranteed()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [substituteDetails?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="guarantorSubstitutionRequest"
      handleAction={handleAction}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableClose={isBusyUpdating}
      disableCreate={isBusyUpdating}
      footerChildren={
        <>
          <div className="d-flex align-items-center gap-2">
            <label className="text-capitalize">{localize('action')}</label>

            <EnumsServerCombo
              disabled={isBusyUpdating}
              customContainerStyle={{ width: '100%' }}
              enumsKey="LoanGuarantorSubstitutionRegistrationOption"
              onChange={e => {
                setSubstitutionAction(e.value)
              }}
              value={substitutionAction}
            />
          </div>
        </>
      }
    >
      {isBusyUpdating ? (
        <Loader />
      ) : (
        <>
          <CustomerPartial
            onCustomerLookup={handleOnLookupCustomer}
            customer={customer}
            refreshFromServer
          />
          <div className="row mt-1">
            <div className="d-flex align-items-center col-md-12">
              <label className="text-capitalize col-1">
                {localize('remarks')}
              </label>
              <input
                onChange={e => {
                  setCustomer({
                    ...customer,
                    remarks: e.target.value,
                  })
                }}
                type="text"
                className="form-control remarks"
                value={customer?.remarks}
                placeholder={localize('remarks')}
              />
            </div>
          </div>

          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('loansGuaranteed'),
                  icon: 'uil uil-edit',
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <>
                      <LoansGuaranteed
                        showSelect
                        handleSelectLoan={handleSelectLoan}
                        selectedLoans={selectedLoans}
                        loanGuarantors={loansGuaranteed}
                      />

                      <div
                        className="p-2 mt-4"
                        style={{
                          border: 'solid 1px #0000001f',
                          borderRadius: 10,
                          position: 'relative',
                        }}
                      >
                        <div
                          className="bg-white px-2  row text-capitalize"
                          style={{
                            position: 'relative',
                            top: -20,
                            left: 30,
                            maxWidth: 'fit-content',
                          }}
                        >
                          {localize('substituteDetails')}
                        </div>
                        <div className="mt-2">
                          <CustomerPartial
                            customer={substituteDetails}
                            onCustomerLookup={handleOnLookupGuarantor}
                          />
                        </div>

                        <div className="p-2 bg-light rounded d-flex justify-content-end align-items-center mb-3">
                          <button
                            onClick={handleAdd}
                            className="btn btn-success"
                          >
                            {localize('add')}
                          </button>
                        </div>

                        <Tab
                          preload
                          tabItems={[
                            {
                              label: localize('substitutes'),
                              item: (
                                <>
                                  <SelectedSubstitutes
                                    selectedSubstitutes={selectedSubstitutes}
                                    handleSelectSubstitute={
                                      handleSelectGuarantorSubstitute
                                    }
                                    selectedGuarantorSubstitutes={
                                      selectedGuarantorSubstitutes
                                    }
                                  />

                                  <div className="d-flex align-item-center justify-content-end rounded p-2 bg-light mt-2 mb-3">
                                    <button
                                      className="btn btn-danger"
                                      onClick={handleRemoveSelectedSubstitute}
                                    >
                                      {localize('remove')}
                                    </button>
                                  </div>
                                </>
                              ),
                            },
                            {
                              label: localize('loansGuaranteed'),
                              item: (
                                <LoansGuaranteed
                                  loanGuarantors={substituteLoansGuaranteed}
                                />
                              ),
                            },
                            {
                              label: localize('specimen'),
                              item: (
                                <Specimen
                                  customer={substituteDetails}
                                  readOnly
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  label: localize('loansAccounts'),
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <>
                      <LoanAccounts
                        data={customerAccounts?.customerLoanAccounts}
                        customerId={customer?.id}
                      />
                    </>
                  ),
                },
                {
                  label: localize('savingsAccounts'),
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <>
                      <SavingsAccounts
                        data={customerAccounts?.customerSavingsAccounts}
                      />
                    </>
                  ),
                },
                {
                  label: localize('investmentAccounts'),
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <>
                      <InvestmentAccounts
                        data={customerAccounts?.customerInvestmentAccounts}
                      />
                    </>
                  ),
                },
                {
                  label: localize('specimen'),
                  item: (
                    <>
                      <Specimen customer={customer} readOnly />
                    </>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateSubstitution
