/** @format */

import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import EmolumentBatchEntries from './EmolumentBatchEntries'
import Tab from '../../../../../Components/Tabs/Tab'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'

const ViewEmolumentsPaymentSchedules = ({
  mode,
  close,
  selectedEmolumentsPaymentSchedule,
}) => {
  const [reqParams, setReqParams] = useState({
    status: 2,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [search, setSearch] = useState('')
  const [entries, setEntries] = useState([])
  const [entriesCount, setEntriesCount] = useState('')
  const [batchId, setBatchId] = useState(selectedEmolumentsPaymentSchedule.id)
  const [loading, setLoading] = useState(false)

  const loadEntries = async () => {
    setLoading(true)
    const { success, data } = await getData(
      `/EmolumentBatch/find-emolument-batch-entries-by-emolument-batch-id-in-page`,
      {
        emolumentBatchId: batchId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setLoading(false)
      setEntries(data.pageCollection)
      setEntriesCount(data.itemsCount)
    } else {
      setLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [batchId, reqParams.pageSize, reqParams.pageIndex])
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="emolumentsPaymentSchedule"
        handleClose={close}
        modalClassName="modal-xl"
        hideCancel
        hideUpdate
      >
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-3 text-capitalize">
                {localize('batch#')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.paddedBatchNumber}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-3 text-capitalize">
                {localize('emolumentType')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedEmolumentsPaymentSchedule?.emolumentTypeDescription
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-2 me-5 text-capitalize">
                {localize('amount')}
              </label>
              <MaskNumber
                className="form-control me-3 ms-3"
                defaultMaskValue={
                  selectedEmolumentsPaymentSchedule?.emolumentTypeAmount
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('postingPeriodDescription')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedEmolumentsPaymentSchedule?.postingPeriodDescription
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chartOfAccountName')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.chartOfAccountName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('priorityDescription')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.priorityDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.reference}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.createdBy}
                disabled
              />
            </div>
            <div className="col-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={formatDate(
                  selectedEmolumentsPaymentSchedule?.createdDate
                )}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedBy')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.auditedBy}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verifiedDate')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={formatDate(
                  selectedEmolumentsPaymentSchedule?.auditedDate
                )}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('verificationRemarks')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.auditRemarks}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedBy')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.authorizedBy}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizedDate')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={formatDate(
                  selectedEmolumentsPaymentSchedule?.authorizedDate
                )}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('authorizationRemarks')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedEmolumentsPaymentSchedule?.authorizationRemarks}
                disabled
              />
            </div>
          </div>
          <div className="row col-12 mt-2">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Tab
                  tabItems={[
                    {
                      label: localize('batchEntries'),
                      item: (
                        <EmolumentBatchEntries
                          mode={mode}
                          reqParams={reqParams}
                          setReqParams={setReqParams}
                          search={search}
                          setSearch={setSearch}
                          entries={entries}
                          loadEntries={loadEntries}
                          entriesCount={entriesCount}
                          close={close}
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}
          </div>
        </>
      </ModalTemplate>
    </>
  )
}

export default ViewEmolumentsPaymentSchedules
