/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import SimpleTable from '../../Components/Tables/SimpleTable'
import Pager from '../../Components/Tables/Pager'
import { getData, postData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import Tab from '../../Components/Tabs/Tab'
import Levies from '../../Components/Selectables/Plain/Levies'
import { entitiesToBeChecked, getLevies } from '../../Helpers/selectablesHelper'
import Checkbox from '../../Components/Checkbox/Checkbox'
import { MaskPercentage } from '../../Components/InputMasks/MaskPercentage'
import { GlobalValidationErrors } from '../../Validation/ValidationState'
import EnumsServerCombo from '../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../Components/Loaders/Loader'
import AddNewButton from '../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function RevenueTaxes() {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  const [selectedRevenueTax, setSelectedRevenueTax] = useState(null)
  const [revenueTaxLevies, setRevenueTaxLevies] = useState([])
  const [loading, setLoading] = useState(true)
  const [showLeviesTab, setShowLeviesTab] = useState(false)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    { label: localize('taxRate'), template: r => <>{r?.taxRate}</> },
    {
      label: localize('roundingTypeDescription'),
      template: r => <>{r?.roundingTypeDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line
  }, [reqParams, refreshAt])

  const leviesToBeSelected = async r => {
    setIsBusy(true)
    setLoading(true)
    try {
      const levies = await getLevies()
      const { success, data } = await getData(
        `/RevenueTax/find-levies-by-revenue-tax-id?revenueTaxId=${r.id}`,
        false
      )
      if (success) {
        setIsBusy(false)
        setRevenueTaxLevies(entitiesToBeChecked(levies, data.result))
        setLoading(false)
      }

      setIsBusy(false)
    } catch (error) {}
  }

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/RevenueTax/find-revenue-tax-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('leviesAndCharges'),
          localize('revenueTaxes'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton
                          handleClickAddButton={async () => {
                            setMode('add')
                            setShowLeviesTab(false)
                            setRevenueTaxLevies([])
                            setSelectedRevenueTax(null)
                            setRevenueTaxLevies(await getLevies())
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-6"></div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                      <button
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setShowLeviesTab(false)
                              setMode('edit')
                              setSelectedRevenueTax(r)
                              setRevenueTaxLevies([])
                              await leviesToBeSelected(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>

                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      canClickOnce
                      onClickOnce={async r => {
                        setShowLeviesTab(true)
                        setRevenueTaxLevies([])
                        await leviesToBeSelected(r)
                      }}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-md-12">
                  {showLeviesTab && (
                    <Tab
                      tabItems={[
                        {
                          label: localize('withHoldingTaxes'),
                          item: (
                            <Levies
                              prefix={'list-levy'}
                              isBusy={loading}
                              disabled
                              leviesToBeSelected={revenueTaxLevies}
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RevenueTaxesModal
        revenueTaxModel={selectedRevenueTax}
        setRevenueTaxModel={setSelectedRevenueTax}
        selectedLevies={revenueTaxLevies}
        setSelectedLevies={setRevenueTaxLevies}
        mode={mode === 'add' || mode === 'edit'}
        onHideModal={() => setMode(null)}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={r => {
          setRefreshAt(r)
        }}
      />
    </>
  )
}

function RevenueTaxesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  revenueTaxModel,
  setRevenueTaxModel,
  selectedLevies,
  setSelectedLevies,
}) {
  const [gbv, setGbv] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setGbv({})
  }, [mode])
  async function handleSubmit(finalData) {
    const { success } = await postData(
      `${
        mode === 'add'
          ? '/RevenueTax/add-revenue-tax-with-levies'
          : '/RevenueTax/update-revenue-tax-with-levies'
      }`,
      finalData,
      false
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt(moment(new Date()).format('YYMMMDDHHmmssa'))
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Revenue Taxes',
        showCloseButton: true,
      })
    }
  }

  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('revenueTax')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div
                  validation-message={gbv?.['RevenueTax.Description']?.message}
                  className="col d-flex align-items-center with-validation"
                >
                  <label className="col-auto me-2 text-capitalize">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={revenueTaxModel?.description}
                    onChange={e => {
                      clearValidation('RevenueTax.Description')
                      setRevenueTaxModel({
                        ...revenueTaxModel,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-auto me-2 text-capitalize">
                    {localize('taxRate')}
                  </label>
                  <MaskPercentage
                    onMaskChange={c => {
                      setRevenueTaxModel({
                        ...revenueTaxModel,
                        taxRate: c.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-auto me-2 text-capitalize">
                    {localize('roundingType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="RoundingType"
                    value={revenueTaxModel?.roundingType}
                    onChange={e => {
                      setRevenueTaxModel({
                        ...revenueTaxModel,
                        roundingType: e.value,
                      })
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('withHoldingTaxes'),
                        item: (
                          <Levies
                            prefix={'create-levy'}
                            leviesToBeSelected={selectedLevies}
                            onCheckLevy={c => {
                              setSelectedLevies(c)
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id="revenue-tax-modal-is-locked"
                  checked={revenueTaxModel?.isLocked}
                  onCheck={v =>
                    setRevenueTaxModel({
                      ...revenueTaxModel,
                      isLocked: v,
                    })
                  }
                />
                <label
                  htmlFor="revenue-tax-modal-is-locked"
                  className="text-capitalize ms-2"
                >
                  {localize('isLocked')}
                </label>
              </div>
              <button
                className="btn btn-danger text-uppercase"
                onClick={onHideModal}
              >
                {localize('cancel')}
              </button>
              <button
                onClick={async () => {
                  const finalData = {
                    RevenueTax: { ...revenueTaxModel },
                    Levies: selectedLevies.filter(x => x.checked),
                  }
                  await handleSubmit(finalData)
                }}
                className={`btn btn-success text-uppercase px-4`}
                type="button"
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}

export default RevenueTaxes
