/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _t = {
  temporaryEmployees: {
    en: 'Temporary Employees',
    sw: 'Wafanyakazi Wa Muda',
  },
  trainingFacilitators: {
    en: 'Training Facilitators',
    sw: 'Wasaidizi Wa Mafunzo',
  },
  trainingTypes: {
    en: 'Training Types',
    sw: 'Aina Za Mafunzo',
  },
  trainingPeriods: {
    en: 'Training Periods',
    sw: 'Vipindi Vya Mafunzo',
  },
  takeInterview: {
    en: 'Take Interview',
    sw: 'Fanya Mahojiano',
  },
  txRcptIndentation: {
    en: 'txrcpt indentation',
    sw: 'txrcpt jongezo ',
  },
  'txRcptIndentation(T)': {
    en: 'txrcpt indentation(t)',
    sw: 'txrcpt jongezo(juu)',
  },
  'txRcptIndentation(L)': {
    en: 'txrcpt indentation(l)',
    sw: 'txrcpt jongezo(kushoto)',
  },
  txRcptFooter: {
    en: 'txrcpt footer',
    sw: 'kijachini cha mapokezi txrcpt',
  },
  treasuries: {
    en: 'treasuries',
    sw: 'hazina',
  },
  tellers: {
    en: 'tellers',
    sw: 'wahudumu wa benki',
  },
  typeDescription: {
    en: 'type',
    sw: 'aina',
  },
  transactionOwnershipDescription: {
    en: 'transaction ownership',
    sw: 'maelezo ya umiliki wa muamala',
  },
  throttleOverTheCounterWithdrawals: {
    en: 'throttle over the counter withdrawals',
    sw: 'dhibiti uondoaji wa fedha',
  },
  throttleBankToMobileWithdrawals: {
    en: 'throttle bank to mobile withdrawals',
    sw: 'kupunguza kasi ya uondoaji wa fedha benki kwenda simu ya mkononi',
  },
  throttleAgencyBankingWithdrawals: {
    en: 'throttle agency banking withdrawals',
    sw: 'kupunguza kasi ya huduma za kibenki za wakala za uondoaji',
  },
  taxReliefAmount: {
    en: 'tax relief amount',
    sw: 'kiasi cha msamaha wa kodi',
  },
  to: {
    en: 'to',
    sw: 'hadi',
  },
  type: {
    en: 'type',
    sw: 'aina',
  },
  throttleScoring: {
    en: 'throttle scoring',
    sw: 'alama ya kudhibiti',
  },
  terminationNoticePeriod: {
    en: 'termination notice period',
    sw: 'muda wa notisi ya kusitisha',
  },
  taxAlerts: {
    en: 'tax alerts',
    sw: 'tahadhari ya kodi',
  },
  transactionReferences: {
    en: 'transaction references',
    sw: 'marejeleo ya shughuli',
  },
  transactionOwnership: {
    en: 'transaction ownership',
    sw: 'umiliki wa muamala',
  },
  txtRcptIndentationTop: {
    en: 'txrcpt indentation(T)',
    sw: 'txrcpt jongezo(juu)',
  },
  txtRcptIndentation: {
    en: 'txrcpt indentation',
    sw: 'utambulisho txrcpt',
  },
  txtRcptIndentationleft: {
    en: 'txtrcpt identation left',
    sw: 'jongezo(kushoto) txrcpt',
  },
  txtRcptFooter: {
    en: 'txrcpt footer',
    sw: 'kijachini cha mapokezi txtRcpt',
  },
  transactionType: {
    en: 'transaction type',
    sw: 'aina ya muamala',
  },
  transactionThresholdPreferences: {
    en: 'transaction threshold preferences',
    sw: 'mapendeleo ya kiwango cha muamala',
  },
  treeDepth: {
    en: 'tree depth',
    sw: 'kina cha mti',
  },
  templateFileTitle: {
    en: 'template file title',
    sw: 'kichwa cha faili ya kiolezo',
  },
  templateFileMimeType: {
    en: 'template file mime type',
    sw: 'aina ya faili ya kiolezo',
  },
  tierDetails: {
    en: 'tier details',
    sw: 'maelezo ya kiwango',
  },
  taxRate: {
    en: 'tax rate',
    sw: 'kiwango cha ushuru',
  },
  textAlertCharges: {
    en: 'text alert charges',
    sw: 'gharama za arifa za maandishi',
  },
  term: {
    en: 'term',
    sw: 'kipindi',
  },
  transactionThresholdDetails: {
    en: 'transaction threshold details',
    sw: 'maelezo ya kiwango cha muamala',
  },
  threshold: {
    en: 'threshold',
    sw: 'kizingiti',
  },
  target: {
    en: 'target',
    sw: 'lengo',
  },
  traningTypes: {
    en: 'traning types',
    sw: 'aina ya mafunzo',
  },
  tellerType: {
    en: 'teller type',
    sw: 'aina ya muudumu wa benki',
  },
  templateFileName: {
    en: 'template file name',
    sw: 'jina la faili kiolezo',
  },
  taxRelief: {
    en: 'tax relief',
    sw: 'msamaha wa kodi',
  },
  technicians: {
    en: 'technicians',
    sw: 'mafundi',
  },
  technician: {
    en: 'technician',
    sw: 'fundi',
  },
  transactionTypes: {
    en: 'transaction types',
    sw: 'aina za shughuli',
  },
  textAlerts: {
    en: 'text alerts',
    sw: 'arifa za maandishi',
  },
  throttleMBankingWithdrawals: {
    en: 'throttle mbanking withdrawals',
    sw: 'kaba uondoaji wa pesa kupitia simu',
  },
  takeHomeType: {
    en: 'take home type',
    sw: 'aina ya pesa bakizi ya mteja',
  },
  takeHomePercentage: {
    en: 'take home percentage',
    sw: 'chukua kiasi cha pesa kupitia asilimia',
  },
  takeHomeFixedAmount: {
    en: 'take home fixed amount',
    sw: 'chukua pesa kwa kiasi kilichopangwa',
  },
  trackAggregateExcess: {
    en: 'track aggregate excess',
    sw: 'fuatilia ziada ya jumla',
  },
  toggleSelection: {
    en: 'toggle selection',
    sw: 'geuza uteuzi',
  },
  termInMonths: {
    en: 'term in months',
    sw: 'kipindi kwa miezi',
  },
  targetBranch: {
    en: 'target branch',
    sw: 'tawi la lengo',
  },
  targetCustomerDetails: {
    en: 'target customer details',
    sw: 'maelezo ya wateja lengwa',
  },
  targetStation: {
    en: 'target station',
    sw: 'kituo cha lengo',
  },
  tellerLookup: {
    en: 'teller lookup',
    sw: 'tafuta muhudumu wa benki',
  },
  teller: {
    en: 'teller',
    sw: 'mhudumu wa benki',
  },
  tips: {
    en: 'tips',
    sw: 'vidokezo',
  },
  transactionTypeEntries: {
    en: 'transaction type entries',
    sw: 'ingizo la aina ya muamala',
  },
  tile: {
    en: 'tile',
    sw: 'kigae',
  },
  takeHomeTypeDescription: {
    en: 'take home type description',
    sw: 'take home type description',
  },
  tiers: {
    en: 'tiers',
    sw: 'viwango',
  },
  timeDurationStartTime: {
    en: 'time duration start time',
    sw: 'muda wa kuanza',
  },
  timeDurationEndTime: {
    en: 'time duration end time',
    sw: 'muda wa kumaliza',
  },
  title: {
    en: 'title',
    sw: 'kichwa',
  },
  terminationCategory: {
    en: 'termination category',
    sw: 'kategoria ya kukomesha',
  },
  transactionReference: {
    en: 'transaction reference',
    sw: 'marejeleo ya shughuli',
  },
  terminationRemarks: {
    en: 'termination remarks',
    sw: 'maelezo ya kusitisha',
  },
  thumb: {
    en: 'thumb',
    sw: 'kidole gumba',
  },
  thirdPartyResponse: {
    en: 'third party response',
    sw: 'jibu la mtu wa tatu',
  },
  thirdPartyWalletResponse: {
    en: 'third party wallet response',
    sw: 'jibu la mkoba wa mtu wa tatu',
  },
  totalShares: {
    en: 'total shares',
    sw: 'jumla ya hisa',
  },
  transactionCode: {
    en: 'transaction code',
    sw: 'nambari ya muamala',
  },
  transactionsJournal: {
    en: 'transactions journal',
    sw: 'jarida la shughuli',
  },
  templateBasedReports: {
    en: 'template based reports',
    sw: 'ripoti zinazotegemea kiolezo',
  },
  transactionDate: {
    en: 'transaction date',
    sw: 'tarehe ya muamala',
  },
  totalValue: {
    en: 'total value',
    sw: 'jumla ya thamani',
  },
  tallyDebitCredits: {
    en: 'tally debit credits',
    sw: 'mikopo ya deni',
  },
  temporaryRoles: {
    en: 'temporary roles',
    sw: 'majukumu ya muda',
  },
  timeDurationStart: {
    en: 'time duration start',
    sw: 'muda wa kuanza',
  },
  traverseTree: {
    en: 'traverse tree',
    sw: 'pitia moja kwa moja',
  },
  transactions: {
    en: 'transactions',
    sw: 'shughuli',
  },
  transactedBy: {
    en: 'transacted by',
    sw: 'imefanywa na',
  },
  transactedDate: {
    en: 'transacted date',
    sw: 'tarehe ya shughuli',
  },
  togglePageSelection: {
    en: 'toggle page selection',
    sw: 'geuza uteuzi wa ukurasa',
  },
  terms: {
    en: 'terms',
    sw: 'muda',
  },
  transactionAmount: {
    en: 'transaction amount',
    sw: 'kiasi cha shughuli',
  },
  transactionId: {
    en: 'transaction id',
    sw: 'nambari ya shughuli',
  },
  transactionTime: {
    en: 'transaction time',
    sw: 'muda wa shughuli',
  },
  thirdPartyTransactionId: {
    en: 'third party transaction id',
    sw: 'nambari ya id',
  },
  transactionReferenceCode: {
    en: 'transaction reference code',
    sw: 'nambari ya rejeleo ya shughuli',
  },
  totalAmount: {
    en: 'total amount',
    sw: 'jumla ya kiasi',
  },
  takeHomeValue: {
    en: 'take home value',
    sw: 'chukua kiasi cha pesa',
  },
  targetProduct: {
    en: 'target product',
    sw: 'bidhaa lengwa',
  },
  targetLoanProduct: {
    en: 'target loan product',
    sw: 'bidhaa ya mkopo lengwa',
  },
  taxInclusive: {
    en: 'tax inclusive',
    sw: 'pamoja na kodi',
  },
  targetProductCode: {
    en: 'target product code',
    sw: 'nambari ya bidhaa lengwa',
  },
  trigger: {
    en: 'trigger',
    sw: 'kichocheo',
  },
  targetTransactionCode: {
    en: 'target transaction code',
    sw: 'nambari ya shughuli lengwa',
  },
  textAlertsCharges: {
    en: 'text alerts charges',
    sw: 'gharama za arifa za maandishi',
  },
  totalAllowances: {
    en: 'total allowances',
    sw: 'jumla ya posho',
  },
  totalDeductions: {
    en: 'total deductions',
    sw: 'jumla ya makato',
  },
  totalClearedEffects: {
    en: 'total cleared effects',
    sw: 'jumla ya athari zilizofutwa',
  },
  totalIncome: {
    en: 'total income',
    sw: 'jumla ya mapato',
  },
  totalAttachedBalance: {
    en: 'total attached balance',
    sw: 'jumla ya salio lililowekwa',
  },
  totalLoanInterest: {
    en: 'total loan interest',
    sw: 'jumla ya riba ya mkopo',
  },
  'term(Months)': {
    en: 'term(months)',
    sw: 'kipindi(miezi)',
  },
  totalOutstandingAmount: {
    en: 'total outstanding amount',
    sw: 'jumla ya kiasi bora',
  },
  totalOutstandingPerforming: {
    en: 'total outstanding performing',
    sw: 'jumla ya kiasi bora kinachonawiri',
  },
  totalOverdueNPAHistory: {
    en: 'total overdue NPA history',
    sw: 'jumla ya historia ya malipo yaliyopita',
  },
  totalOutstandingNPA: {
    en: 'total outstanding NPA',
    sw: 'jumla ya kiasi bora cha NPA',
  },
  totalOverdueAmount: {
    en: 'total overdue amount',
    sw: 'jumla ya kiasi cha malipo yaliyopita',
  },
  totalOverdueNPA: {
    en: 'total overdue NPA',
    sw: 'jumla ya malipo yaliyopita NPA',
  },
  totalOverduePerforming: {
    en: 'total overdue performing',
    sw: 'jumla ya malipo yaliyopita yanayonawiri',
  },
  totalOutstandingNPAHistory: {
    en: 'total outstanding NPA history',
    sw: 'jumla ya historia ya kiasi bora cha NPA',
  },
  totalMonthlyInstalmentGeneric: {
    en: 'total monthly instalment generic',
    sw: 'jumla ya malipo ya kila mwezi ya jumla',
  },
  transactionJournals: {
    en: 'transaction journals',
    sw: 'jarida la shughuli',
  },
  totalPaybackAmount: {
    en: 'total payback amount',
    sw: 'jumla ya kiasi cha malipo',
  },
  topUpAmount: {
    en: 'top up amount',
    sw: 'kiasi cha juu',
  },
  totalAttachedLoansBalance: {
    en: 'total attached loans balance',
    sw: 'jumla ya salio la mikopo iliyowekwa',
  },
  tableName: {
    en: 'table name',
    sw: 'jina la safu',
  },
  trackArrears: {
    en: 'track arrears',
    sw: 'fuatilia malipo yaliyopita',
  },
  throttleScheduledArrearsRecovery: {
    en: 'throttle scheduled arrears recovery',
    sw: 'kaba urejeshaji wa malipo yaliyopita',
  },
  throttleMicroLoanDisbursement: {
    en: 'throttle micro loan disbursement',
    sw: 'kaba utoaji wa mkopo mdogo',
  },
  termsOfService: {
    en: 'terms of service',
    sw: 'masharti ya huduma',
  },
  'transferDetails(dest.)': {
    en: 'transfer details(dest.)',
    sw: 'maelezo ya uhamisho(fikio)',
  },
  transferType: {
    en: 'transfer type',
    sw: 'aina ya uhamisho',
  },
  tickets: {
    en: 'tickets',
    sw: 'tiketi',
  },
  terminationDate: {
    en: 'termination date',
    sw: 'tarehe ya kukomesha',
  },
  targetCustomers: {
    en: 'target customers',
    sw: 'wateja lengwa',
  },
  tallyStatementEntries: {
    en: 'tally statement entries',
    sw: 'ingizo la taarifa ya malipo',
  },
  totalExpected: {
    en: 'total expected',
    sw: 'jumla ya malipo yanayotarajiwa',
  },
  treasury: {
    en: 'treasury',
    sw: 'hazina',
  },
  treasuryCashTransfer: {
    en: 'treasury cash transfer',
    sw: 'uhamisho wa fedha kutoka hazina',
  },
  transactionDetails: {
    en: 'transaction details',
    sw: 'maelezo ya muamala',
  },
  tallyByCount: {
    en: 'Tally-by-Count',
    sw: 'Kujumlisha-kwa-Idadi',
  },
  tallyByTotal: {
    en: 'Tally-by-Total',
    sw: 'Kujumlisha-kwa-Jumla',
  },
  totalCurrencyBreakdown: {
    en: 'total currency breakdown',
    sw: 'muhtasari wa jumla wa sarafu',
  },
  treasuryTrailType: {
    en: 'treasury trail type',
    sw: 'aina ya hazina',
  },
  transactionSource: {
    en: 'transaction source',
    sw: 'chanzo cha muamala',
  },
  transactionDestination: {
    en: 'transaction destination',
    sw: 'fikio ya muamala',
  },
  twoHundreds: {
    en: 'two hundreds',
    sw: 'mia mbili',
  },
  twenties: {
    en: 'twenties',
    sw: 'ishirini',
  },
  tens: {
    en: 'tens',
    sw: 'kumi',
  },
  treasuryManagementRequestAuthorization: {
    en: 'treasury management request authorization',
    sw: 'idhini ya ombi la usimamizi wa hazina',
  },
  transaction: {
    en: 'transaction',
    sw: 'muamala',
  },
  tellerChartOfAccountStatement: {
    en: 'teller g/l account statement',
    sw: 'taarifa ya akaunti ya muhudumu wa benki',
  },
  treasuryLookup: {
    en: 'treasury lookup',
    sw: 'tafuta hazina',
  },
  'term(month)': {
    en: 'term (month)',
    sw: 'kipindi (miezi)',
  },
  termination: {
    en: 'termination',
    sw: 'kukomesha',
  },
  terminate: {
    en: 'terminate',
    sw: 'komesha',
  },
  terminateFixedDeposit: {
    en: 'terminate fixed deposit',
    sw: 'komesha amana zisizohamishika',
  },
  tellerCashRequisitions: {
    en: 'teller cash requisitions',
    sw: 'mahitaji ya fedha ya muhudumu wa benki',
  },
  tellerCashRequisitionLookup: {
    en: 'teller cash requisition lookup',
    sw: 'tafuta mahitaji ya fedha ya muhudumu wa benki',
  },
  tellerBalance: {
    en: 'teller balance',
    sw: 'baki ya muhudumu wa benki',
  },
  transactionCategory: {
    en: 'transaction category',
    sw: 'kategoria ya muamala',
  },
  tellerGlAccountStatement: {
    en: 'teller g/l account statement',
    sw: 'taarifa ya akaunti ya muhudumu wa benki',
  },
  totalReceipts: {
    en: 'total receipts',
    sw: 'jumla ya mapokezi',
  },
  totalPayments: {
    en: 'total payments',
    sw: 'jumla ya malipo',
  },
  totalGuaranteed: {
    en: 'total guaranteed',
    sw: 'jumla ya dhamana',
  },
  truncatedChequesProcessing: {
    en: 'truncated cheques processing',
    sw: 'usindikaji wa hundi zilizokatwa',
  },
  truncatedChequeCatalogue: {
    en: 'truncated cheque catalogue',
    sw: 'katalogi ya hundi zilizokatwa',
  },
  tellerName: {
    en: 'teller name',
    sw: 'jina la muhudumu wa benki',
  },
  transferredBy: {
    en: 'transferred by',
    sw: 'imehamishwa na',
  },
  transferredDate: {
    en: 'transferred date',
    sw: 'tarehe ya uhamisho',
  },
  totalNetPay: {
    en: 'total net pay',
    sw: 'jumla ya malipo ya mwisho',
  },
  taxExemption: {
    en: 'tax exemption',
    sw: 'msamaha wa kodi',
  },
  transactionAccountNumber: {
    en: 'transaction account number',
    sw: 'nambari ya akaunti ya muamala',
  },
  thirdPartyNotificationResponse: {
    en: 'third party notification response',
    sw: 'jibu la arifa ya mtu wa tatu',
  },
  transactionBackingStore: {
    en: 'transaction backing store',
    sw: 'hifadhi ya muamala',
  },
  types: {
    en: 'types',
    sw: 'aina',
  },
  technicianName: {
    en: 'technician name',
    sw: 'jina la fundi',
  },
  technicianFirstName: {
    en: 'technician first name',
    sw: 'jina la kwanza la fundi',
  },
  technicianLastName: {
    en: 'technician last name',
    sw: 'jina la mwisho la fundi',
  },
  technicianOtherNames: {
    en: 'technician other names',
    sw: 'majina mengine ya fundi',
  },
  transactionTypeLookup: {
    en: 'transaction type lookup',
    sw: 'tafuta aina za muamala',
  },
  tagNumber: {
    en: 'tag number',
    sw: 'nambari ya lebo',
  },
  totalPrice: {
    en: 'total price',
    sw: 'bei jumla',
  },
  total: {
    en: 'total',
    sw: 'jumla',
  },
  totalCost: {
    en: 'total cost',
    sw: 'gharama jumla',
  },
  totalDepreciationCost: {
    en: 'total depreciation cost',
    sw: 'gharama jumla ya kupungua thamani',
  },
  trainings: {
    en: 'trainings',
    sw: 'mafunzo',
  },
  transactionFilter: {
    en: 'transaction filter',
    sw: 'chujio la muamala',
  },
  trainingFacilitator: {
    en: 'training facilitator',
    sw: 'msaidizi wa mafunzo',
  },
  trainingType: {
    en: 'training type',
    sw: 'aina ya mafunzo',
  },
  tallyDebitsCredits: {
    en: 'tally debits credits',
    sw: 'mikopo ya deni',
  },
  transactionReceiptTopIndentation: {
    en: 'transaction receipt top indentation',
    sw: 'jongezo la juu la mapokezi ya muamala',
  },
  transactionReceiptFooter: {
    en: 'transaction receipt footer',
    sw: 'kijachini cha mapokezi ya muamala',
  },
  transactionReceiptLeftIndentation: {
    en: 'transaction receipt left indentation',
    sw: 'jongezo la kushoto la mapokezi ya muamala',
  },
  trainingFacilitatorLookup: {
    en: 'training facilitator lookup',
    sw: 'tafuta msaidizi wa mafunzo',
  },
  trainingTypeLookup: {
    en: 'training type lookup',
    sw: 'tafuta aina ya mafunzo',
  },
  targetEmployees: {
    en: 'target employees',
    sw: 'wafanyakazi lengwa',
  },
  temporaryEmployee: {
    en: 'temporary employee',
    sw: 'mfanyakazi wa muda',
  },
  templateBasedReport: {
    en: 'template based report',
    sw: 'ripoti inayotegemea kiolezo',
  },
  transactionJournal: {
    en: 'transaction journal',
    sw: 'jarida la shughuli',
  },
  throttleInternalLoaning: {
    en: 'throttle internal loaning',
    sw: 'kaba mkopo wa ndani',
  },
}
export default _t
