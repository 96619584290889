/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const VehicleLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r?.registrationNumber}</>,
    },
    { label: localize('fleetNumber'), template: r => <>{r?.fleetNumber}</> },
    {
      label: localize('vehicleRoute'),
      template: r => <>{r?.vehicleRouteDescription}</>,
    },
    {
      label: localize('customerBranch'),
      template: r => <>{r?.customerBranchDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landline'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('documentReferenceNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    {
      label: localize('isLocked'),
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        onSelect={onSelect}
        displayValue={displayValue}
        url="/Vehicle/find-vehicles-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        title={localize('vehicleLookup')}
      />
    </>
  )
}

export default VehicleLookup
