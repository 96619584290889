/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import localize from '../../../Global/Localize'
import DatePicker from '../../Date/DatePicker'
import SimpleTable from '../../Tables/SimpleTable'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import Loader from '../../Loaders/Loader'

const GLAccountStatement = ({ glAccount }) => {
  const [accounts, setAccounts] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    chartOfAccountId: glAccount?.id,
    text: '',
    journalEntryFilter: 5,
    transactionDateFilter: 2,
    dateRequestsFilter: null,
    startDate: null,
    endDate: null,
    customDate: false,
  })

  const trColumns = [
    { label: 'branch', template: r => <>{r.branchDescription}</> },
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    { label: 'reference', template: r => <>{r.journalReference}</> },
    {
      label: 'primaryDescription',
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: 'debit', template: r => <>{formatCurrency(r.debit)}</> },
    { label: 'credit', template: r => <>{formatCurrency(r.credit)}</> },
    {
      label: 'runningBalance',
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: 'secondaryDescription',
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    {
      label: 'transactionCode',
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    { label: 'remarks', template: r => <>{r.remarks}</> },
    {
      label: 'valueDate',
      template: r => <>{formatDate(r.journalValueDate, true)}</>,
    },
    { label: 'appUserName', template: r => <>{r.applicationUserName}</> },
    {
      label: 'localCurrency',
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    { label: 'costCenter', template: r => <>{r.costCenterDescription}</> },
    {
      label: 'costDistributionRule',
      template: r => <>{r.costDistributionRule}</>,
    },
    {
      label: 'costDistributionPercentage',
      template: r => <>{r.costDistributionPercentage}</>,
    },
    {
      label: 'debitCredit',
      template: r => <>{formatCurrency(r.debitCredit)}</>,
    },
  ]

  const fetchAccounts = async () => {
    setIsBusy(true)
    let { success, data } = await getData(
      '/journalEntry/find-general-ledger-transactions-by-chart-of-account-id-and-date-range-and-filter-in-page',
      { ...reqParams, chartOfAccountId: glAccount?.id },
      false
    )
    if (success) {
      setIsBusy(false)
      setAccounts(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectAccount = r => {
    //TODO: myke-Implement print functionality here 14/09/2023
  }

  useEffect(() => {
    if (glAccount?.id) fetchAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, glAccount?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize" style={{ width: 150 }}>
                {localize('glAccount')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={glAccount?.name}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize" style={{ width: 150 }}>
                {localize('type')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={glAccount?.typeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label
                htmlFor=""
                className="text-capitalize"
                style={{ width: 150 }}
              >
                {localize('chartOfAccountCostDistributionRuleDescription')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={glAccount?.costDistributionRuleDescription}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-2"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e =>
                  setReqParams({ ...reqParams, pageSize: e.target.value })
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('filter')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                value={reqParams?.transactionDateFilter}
                enumsKey="TransactionDateFilter"
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, transactionDateFilter: value })
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams?.startDate,
                  endDate: reqParams?.endDate,
                }}
                onSelect={v => {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('search')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                value={reqParams?.journalEntryFilter}
                enumsKey="JournalEntryFilter"
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, journalEntryFilter: value })
                }
              />
              <input
                type="text"
                className="form-control"
                value={reqParams?.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    fetchAccounts()
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchAccounts()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>

          <SimpleTable
            columns={trColumns}
            data={accounts?.pageCollection}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item"
                  onClick={() => handleSelectAccount(r)}
                >
                  <i className="uil uil-print"></i>
                  <span className="text-capitalize">{localize('print')}</span>
                </div>
              </>
            )}
          />
        </>
      )}
    </>
  )
}

export default GLAccountStatement
