/** @format */

import Tab from '../../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../../Global/Localize'
import { CustomerReceiptApportionmentSection } from './CustomerReceiptApportionmentSection'
import { CustomerReceiptAccountStatement } from './CustomerReceiptAccountStatement'
import React from 'react'

export const CustomerReceiptTransaction = ({
  transactionModel,
  setTransactionModel,
  onAddApportionMent,
  onRemoveApportionment,
  resetEntries,
}) => {
  return (
    <>
      <Tab
        preload
        tabItems={[
          {
            label: `${localize('apportionment')} (${localize(
              'loan'
            )} / ${localize('investment')} / ${localize(
              'savings'
            )} / ${localize('glAccount')})`,
            item: (
              <CustomerReceiptApportionmentSection
                onAddApportionMent={onAddApportionMent}
                onRemoveApportionment={onRemoveApportionment}
                transactionModel={transactionModel}
                resetEntries={resetEntries}
                setTransactionModel={setTransactionModel}
              />
            ),
          },
          {
            label: localize('selectedAccountStatement'),
            item: <CustomerReceiptAccountStatement />,
          },
        ]}
      />
    </>
  )
}
