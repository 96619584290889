/** @format */

/* eslint-disable */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'
import Loader from '../../../Components/Loaders/Loader'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import localize from '../../../Global/Localize'
import { getData, postData } from '../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../Helpers/extensions'

function EditBankModal({ mode, editMode, close, selectedBank, loadTableData }) {
  const tBrColumns = [
    { label: localize('code'), template: r => <>{r?.code}</> },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.addressCity}</> },
    { label: localize('emailAddress'), template: r => <>{r?.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r?.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tempBranches, setTempBranches] = useState([])
  const [tBr, setTBr] = useState({})
  const [branchMode, setBranchMode] = useState('add')
  const [bank, setBank] = useState(editMode ? selectedBank : {})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [showError, setShowError] = useState(false)
  const [showBranchError, setShowBranchError] = useState(false)
  const [showBranchCodeError, setShowBranchCodeError] = useState(false)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadData() {
    if (editMode) {
      setIsBusy(true)
      const { success, data } = await getData(
        '/bank/find-bank-branch-by-bank-id',
        { bankId: selectedBank.id },
        false
      )

      if (success) {
        setIsBusy(false)
        setTempBranches(data.result)
      }
      setIsBusy(false)
    }
  }

  async function createBank() {
    setIsBusy(true)
    for (let b of tempBranches) {
      delete b.id
    }

    const { success } = await postData(
      '/bank/add-bank-with-bank-branches',
      {
        bank,
        bankBranches: tempBranches,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      showNotification('Banks', 'success')
    } else {
      setIsBusy(false)
    }
  }

  async function editBank() {
    setIsBusy(true)
    for (let b of tempBranches) {
      if (b.id.toString().split('-').length < 5) delete b.id
    }
    const { success } = await postData(
      '/bank/update-bank-with-bank-branches',
      {
        bank,
        bankBranches: tempBranches,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      showNotification('Banks', 'success')
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
    setShowBranchCodeError(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('bank')}
        handleClose={closeModal}
        handleAction={editMode ? editBank : createBank}
        actionText={mode === 'add' ? 'create' : 'update'}
        modalClassName="modal-xl"
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              <div
                className="with-validation col-md-6 d-flex align-items-center"
                validation-message={
                  globalValidationErrors['Bank.Description']?.message
                }
              >
                <label className="text-capitalize ms-4">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control ms-3"
                  defaultValue={
                    editMode ? selectedBank?.description : bank?.description
                  }
                  onChange={e => {
                    clearValidation(['Bank.Description'])
                    setBank({ ...bank, description: e.target.value })
                  }}
                />
              </div>
              <div
                className="with-validation col-md-6 d-flex align-items-center"
                validation-message={
                  globalValidationErrors['Bank.Code']?.message
                }
              >
                <label className="text-capitalize ms-3 me-2">
                  {localize('code')}
                </label>
                <MaskNumber
                  defaultMaskValue={editMode ? selectedBank?.code : bank?.code}
                  className={`
                       ${showError && 'border border-danger'}
                      `}
                  onMaskNumber={e => {
                    if (Number(e.target.value) > 100) {
                      setShowError(true)
                    } else {
                      setShowError(false)
                    }
                    clearValidation(['Bank.Code'])
                    setBank({ ...bank, code: e.target.value })
                  }}
                  numeralThousandsGroupStyle="none"
                />
              </div>
            </div>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('branchDetails')}
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{ marginTop: -10, marginBottom: 10 }}
                >
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4 ms-3">
                      {localize('name')}
                    </label>
                    <input
                      type="text"
                      className={`form-control
                       ${showBranchError && 'border border-danger'}
                      `}
                      onChange={e => {
                        setShowBranchError(false)
                        setTBr({ ...tBr, description: e.target.value })
                      }}
                      value={tBr?.description || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4 ms-3">
                      {localize('addressLine') + '2'}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressAddressLine2: e.target.value })
                      }
                      value={tBr?.addressAddressLine2 || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4 ms-3">
                      {localize('city')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressCity: e.target.value })
                      }
                      value={tBr?.addressCity || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4 ms-3">
                      {localize('mobileLine')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressMobileLine: e.target.value })
                      }
                      value={tBr?.addressMobileLine || ''}
                    />
                  </div>
                </div>
                <div
                  className="col"
                  style={{ marginTop: -10, marginBottom: 10 }}
                >
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('code')}
                    </label>
                    <MaskNumber
                      defaultMaskValue={tBr?.code || ''}
                      className={`form-control
                       ${showBranchCodeError && 'border border-danger'}
                      `}
                      onMaskNumber={e => {
                        setShowBranchCodeError(false)
                        if (Number(e.target.value) > 100) {
                          setShowBranchCodeError(true)
                        } else {
                          setShowBranchCodeError(false)
                        }

                        setTBr({ ...tBr, code: e.target.value })
                      }}
                      numeralThousandsGroupStyle="none"
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('physicalAddress')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressStreet: e.target.value })
                      }
                      value={tBr?.addressStreet || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('email')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressEmail: e.target.value })
                      }
                      value={tBr?.addressEmail || ''}
                    />
                  </div>
                </div>
                <div
                  className="col"
                  style={{ marginTop: -10, marginBottom: 10 }}
                >
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('addressLine') + '1'}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressAddressLine1: e.target.value })
                      }
                      value={tBr?.addressAddressLine1 || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('postalCode')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressPostalCode: e.target.value })
                      }
                      value={tBr?.addressPostalCode || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('landLine')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={e =>
                        setTBr({ ...tBr, addressLandLine: e.target.value })
                      }
                      value={tBr?.addressLandLine || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    {branchMode === 'edit' && (
                      <button
                        onClick={() => {
                          setBranchMode('add')
                          setTBr({})
                        }}
                        className="text-uppercase btn btn-outline-danger px-4"
                      >
                        {localize('cancel')}
                      </button>
                    )}
                    {branchMode === 'edit' && (
                      <button
                        onClick={() => {
                          let branchList = [...tempBranches]
                          let newList = []
                          for (let b of branchList) {
                            if (b.id === tBr?.id) {
                              newList.push(tBr)
                            } else {
                              newList.push(b)
                            }
                          }
                          setTempBranches(newList)
                          setBranchMode('add')
                          setTBr({})
                        }}
                        className="btn btn-success ms-2 text-uppercase px-4"
                      >
                        {localize('update')}
                      </button>
                    )}
                    {branchMode === 'add' && (
                      <button
                        onClick={() => {
                          if (!tBr?.description) {
                            setShowBranchError(true)
                            Swal.fire({
                              icon: 'info',
                              text: 'Bank Branch Name is Empty',
                              title: 'Bank Branch',
                              showCloseButton: true,
                            })
                          } else if (!tBr?.code) {
                            setShowBranchCodeError(true)
                            Swal.fire({
                              icon: 'info',
                              text: 'Bank Branch Code is Empty',
                              title: 'Bank Branch',
                              showCloseButton: true,
                            })
                          } else {
                            setShowBranchError(false)
                            setShowBranchCodeError(false)
                            setTempBranches([
                              ...tempBranches,
                              { id: generateGuid() },
                            ])
                            setTBr({})
                          }
                        }}
                        className="btn btn-success px-5 mx-0 text-uppercase"
                      >
                        {localize('add')}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="p-2 mt-2"
                style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
              >
                <div
                  className="bg-white row px-2 text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('branches')}
                </div>
                <div style={{ marginTop: -15 }}>
                  {
                    <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                      <SimpleTable
                        columns={tBrColumns}
                        data={tempBranches}
                        contextMenu={r => (
                          <>
                            <div
                              className="ctxt-menu-item text-capitalize text-danger"
                              onClick={() => {
                                let ts = tempBranches
                                let found = ts.findIndex(t => t.id === r?.id)
                                ts.splice(found, 1)
                                setTempBranches(ts)
                              }}
                            >
                              <i className="uil uil-times-circle text-danger text-capitalize"></i>
                              {localize('remove')}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditBankModal
