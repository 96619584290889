/** @format */
import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import GoodsReceivedNoteEntriesItems from './GoodsReceivedNoteEntriesItems'

const ApproveGoodsReceivedNote = ({
  mode,
  closeModal,
  goodsReceivedNote,
  setGoodsReceivedNote,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedAuth, setSelectedAuth] = useState(0)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsReceivedNote/find-goods-received-note-items-by-goods-received-note-id-filter-in-page`,
      {
        goodsReceivedNoteId: goodsReceivedNote?.workflowRecordId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const findGoodsReceivedNoteById = async () => {
    const { success, data } = await getData(
      `/GoodsReceivedNote/find-goods-received-note?goodsReceivedNoteId=${goodsReceivedNote?.workflowRecordId}`,
      {},
      false
    )
    if (success) {
      setGoodsReceivedNote({ ...goodsReceivedNote, data })
    }
  }

  useEffect(() => {
    loadEntries()
    findGoodsReceivedNoteById()
    // eslint-disable-next-line
  }, [
    goodsReceivedNote?.workflowRecordId,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])

  const closePage = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  const approvegoodsReceivedNote = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/WorkFlow/approve-goods-received-note-workflow-item',
      {
        ...goodsReceivedNote,
        approvalOption: selectedAuth,
        usedBiometrics: false,
      },
      false
    )

    if (success) {
      setGoodsReceivedNote(null)
      setIsBusy(false)
      closePage()
      loadTableData()
      showNotification('Approve Goods Received Note', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={'approve'}
      modalTitle="goodsReceivedNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      handleAction={approvegoodsReceivedNote}
      actionText={'approve'}
      footerChildren={
        <>
          <div
            className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
            validation-message={
              globalValidationErrors['WorkflowItem.Remarks']?.message
            }
          >
            <label className="text-capitalize col-3">
              {localize('remarks')}
            </label>
            <input
              defaultValue={goodsReceivedNote?.remarks}
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('WorkflowItem.Remarks')
                setGoodsReceivedNote({
                  ...goodsReceivedNote,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center mb-2 px-4 mt-3">
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="WorkflowApprovalOption"
              value={selectedAuth}
              onChange={e => setSelectedAuth(e.value)}
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={goodsReceivedNote?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="text-capitalize col-md-2">
                {localize('supplier')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('location')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.locationDescription}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-2 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                disabled
                value={goodsReceivedNote?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-2">
                {localize('createdDate')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={formatDate(goodsReceivedNote?.createdDate)}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('goodsReceivedNoteItems'),
                  item: (
                    <GoodsReceivedNoteEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ApproveGoodsReceivedNote
