/** @format */
import localize from '../../../../../Global/Localize'
import { useState } from 'react'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import { ChargeSplits } from './ChargeSplits'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

export const AddChargeSplit = ({ levySplits, mode, setLevySplits }) => {
  const [chargeSplitModel, setChargeSplitModel] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const handleOnAddChargeSplit = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Levy/add-levy-split-setup',
      {
        LevySplit: { ...chargeSplitModel },
        LevySplits: levySplits || [],
        GeneralLedgerAccount: chargeSplitModel?.generalLedgerAccount || {},
      },
      false,
    )
    if (success) {
      setChargeSplitModel(null)
      setLevySplits([...levySplits, { ...data }])
    }
    setIsBusy(false)
  }

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('chargeDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['GeneralLedgerAccount.Id']?.message
            }
          >
            <label htmlFor="" className="text-capitalize me-3">
              {localize('glAccount')}
            </label>
            <ChartOfAccountsLookup
              displayValue={chargeSplitModel?.generalLedgerAccount?.name}
              onSelect={e => {
                clearValidation('GeneralLedgerAccount.Id')
                setChargeSplitModel({
                  ...chargeSplitModel,
                  generalLedgerAccount: { ...e },
                })
              }}
            />
          </div>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['LevySplit.Description']?.message
            }
          >
            <label htmlFor="" className="text-capitalize me-3">
              {localize('name')}
            </label>
            <input
              className="form-control"
              value={chargeSplitModel?.description || ''}
              onChange={e => {
                clearValidation('LevySplit.Description')
                setChargeSplitModel({
                  ...chargeSplitModel,
                  description: e.target.value,
                })
              }}
            />
          </div>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['LevySplit.Percentage']?.message
            }
          >
            <label htmlFor="" className="text-capitalize me-3">
              {localize('percentage')}
            </label>
            <MaskPercentage
              defaultMaskValue={chargeSplitModel?.percentage || ''}
              onMaskChange={e => {
                clearValidation('LevySplit.Percentage')
                setChargeSplitModel({
                  ...chargeSplitModel,
                  percentage: e.target.value,
                })
              }}
            />
          </div>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div
              className="bg-light d-flex justify-content-end align-items-center p-2"
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={handleOnAddChargeSplit}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('add')}
              </button>
            </div>
          </>
        )}

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 120,
              marginBottom: -10,
            }}
          >
            {localize('chargeDetails')}
          </div>
          <ChargeSplits
            mode={mode}
            levySplits={levySplits}
            setLevySplits={setLevySplits}
          />
        </div>
      </div>
    </>
  )
}
