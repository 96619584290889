/** @format */

/* eslint-disable */
import React from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'

function RoomBeds({ beds, handleRemoveBed }) {
  const columns = [
    {
      label: 'description',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'roomDescription',
      template: r => <>{r?.roomDescription}</>,
    },
    {
      label: 'roomType',
      template: r => <>{r?.roomTypeDescription}</>,
    },
    {
      label: 'roomIsLocked' + ' ?',
      template: r => <>{r?.roomIsLocked?.toString()}</>,
    },
    {
      label: 'isLocked' + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: 'isOccupied' + ' ?',
      template: r => <>{r?.isOccupied?.toString()}</>,
    },
  ]

  return (
    <>
      {beds ? (
        <div style={{ height: '300px' }} className="overflow-auto">
          <SimpleTable
            columns={columns}
            data={beds}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleRemoveBed(r)
                  }}
                >
                  <i className="uil uil-trash text-danger"></i>
                  <span>{localize('remove')}</span>
                </div>
              </>
            )}
          />
        </div>
      ) : (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="fs-5">No Data</div>
        </div>
      )}
    </>
  )
}

export default RoomBeds
