/**
 * eslint-disable
 *
 * @format
 */

import React from 'react'

function PdfFileLookup({ id, displayValue, acceptedFiles, onSelect }) {
  const handleFileChange = event => {
    const files = event.target.files
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64DataUrl = reader.result
          const splitBase64 = base64DataUrl.split(',')
          const mimeType = splitBase64[0].split(':')[1].split(';')[0]
          const base64String = splitBase64[1] // Get only the base64 string

          onSelect({
            bufferString: base64String,
            mimeType: mimeType,
            fileName: file.name,
          })
        }
        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <>
      <label
        htmlFor={id}
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <label
          htmlFor={id}
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
        >
          <i className="uil uil-file-check-alt"></i>
        </label>
        <input
          type="file"
          name=""
          id={id}
          accept={acceptedFiles}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
    </>
  )
}

export default PdfFileLookup
