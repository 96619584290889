/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateVehicleRoute = ({
  mode,
  handleClose,
  refresh,
  setRefresh,
  route,
  setRoute,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  const handleCloseModal = () => {
    handleClose()
    setGlobalValidationErrors({})
  }

  async function handleCreate() {
    if (!route?.description) {
      GlobalValidationErrors.set({
        Description: { message: 'Description is required' },
      })

      return
    }
    setIsBusy(true)

    const { success } = await postData(
      '/vehicleRoute/create-route',
      route,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Create Vehicle Route', 'success')
      handleCloseModal()
    }

    setIsBusy(false)
  }

  async function handleUpdate() {
    if (!route?.description) {
      GlobalValidationErrors.set({
        Description: { message: 'Description is required' },
      })

      return
    }

    setIsBusy(true)
    const { success } = await putData(
      '/vehicleRoute/update-route',
      route,
      false
    )
    if (success) {
      setRefresh(!refresh)
      showNotification('Update Vehicle Route', 'success')
      handleCloseModal()
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="vehicleRoute"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={mode === 'add' ? handleCreate : handleUpdate}
      handleClose={handleCloseModal}
      footerChildren={
        mode === 'edit' && (
          <div className="d-flex align-items-center">
            <Checkbox
              id="editVehicleRouteIsLocked"
              onCheck={v => setRoute({ ...route, isLocked: v })}
              checked={route?.isLocked}
            />
            <label
              className="ms-2 me-4 text-capitalize"
              htmlFor="editVehicleRouteIsLocked"
            >
              {localize('isLocked')}
            </label>
          </div>
        )
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row">
          <div
            className="d-flex align-items-center with-validation col-md-12"
            validation-message={globalValidationErrors['Description']?.message}
          >
            <label className="text-capitalize">{localize('name')}</label>
            <input
              type="text"
              className="form-control ms-2"
              value={route?.description}
              onChange={e => {
                clearValidation('Description')
                setRoute({ ...route, description: e.target.value })
              }}
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateVehicleRoute
