/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'
function MobileToBankTerminalLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('mobileToBankTerminalLookup')}
      url="/lookup/MobileToBankTerminal/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('chartOfAccountName'),
          template: r => <>{r.chartOfAccountName}</>,
          sortBy: 'chartOfAccountName',
        },
        {
          label: localize('chartOfAccountCostDistributionRule'),
          template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
          sortBy: 'chartOfAccountCostDistributionRuleDescription',
        },
        {
          label: localize('chartOfAccountCurrency'),
          template: r => <>{r.chartOfAccountCurrencyDescription}</>,
          sortBy: 'chartOfAccountCurrencyDescription',
        },
        {
          label: localize('payrollNumberType'),
          template: r => <>{r.payrollNumberTypeDescription}</>,
          sortBy: 'payrollNumberTypeDescription',
        },
        {
          label: localize('businessShortCode'),
          template: r => <>{r.businessShortCode}</>,
          sortBy: 'businessShortCode',
        },
        {
          label: localize('billRefNumberPrefix'),
          template: r => <>{r.billRefNumberPrefix}</>,
          sortBy: 'billRefNumberPrefix',
        },
        {
          label: localize('collectionAccountPadding'),
          template: r => <>{r.collectionAccountPadding}</>,
          sortBy: 'collectionAccountPadding',
        },
        {
          label: localize('matchByPayrollNumberType'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.matchByPayrollNumberType.toString())}
            </span>
          ),
        },
        {
          label: localize('matchByMSISDN'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.matchByMSISDN.toString())}
            </span>
          ),
        },
        {
          label: localize('trackAggregateExcess'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.trackAggregateExcess.toString())}
            </span>
          ),
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default MobileToBankTerminalLookup
