/** @format */

import localize from '../../../../Global/Localize'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import Tab from '../../../../Components/Tabs/Tab'
import { AddFixedDepositTypeTiers } from './Partials/AddFixedDepositTypeTiers'
import { FixedDepositTypeLevies } from './Partials/FixedDepositTypeLevies'
import { FixedDepositTypeOffsetableLoanProducts } from './Partials/FixedDepositTypeOffsetableLoanProducts'
import Loader from '../../../../Components/Loaders/Loader'

export const EditFixedDepositTypeModal = ({
  selectedFixedDepositType,
  setSelectedFixedDepositType,
  isBusy,
}) => {
  return (
    <>
      <>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-1 text-capitalize">
                  {localize('name')}
                </label>
                <input
                  className="form-control"
                  value={selectedFixedDepositType?.description}
                  onChange={e =>
                    setSelectedFixedDepositType({
                      ...selectedFixedDepositType,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('months')}
                </label>
                <MaskNumber
                  defaultMaskValue={selectedFixedDepositType?.months}
                  onMaskNumber={r => {
                    setSelectedFixedDepositType({
                      ...selectedFixedDepositType,
                      months: r.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('advanceRate')}
                </label>
                <input
                  value={selectedFixedDepositType?.advanceRate}
                  className="form-control"
                  onChange={e => {
                    setSelectedFixedDepositType({
                      ...selectedFixedDepositType,
                      advanceRate: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('glAccount')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={
                    selectedFixedDepositType?.chartOfAccountAccountName
                  }
                  onSelect={r => {
                    setSelectedFixedDepositType({
                      ...selectedFixedDepositType,
                      chartOfAccountId: r.id,
                      chartOfAccountAccountName: r.name,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('interestExpenseGlAccount')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={
                    selectedFixedDepositType?.interestExpenseChartOfAccountAccountName
                  }
                  onSelect={r => {
                    setSelectedFixedDepositType({
                      ...selectedFixedDepositType,
                      interestExpenseChartOfAccountId: r.id,
                      interestExpenseChartOfAccountAccountName: r.name,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('tiers'),
                      item: (
                        <AddFixedDepositTypeTiers
                          selectedFixedDepositType={selectedFixedDepositType}
                          setSelectedFixedDepositType={
                            setSelectedFixedDepositType
                          }
                        />
                      ),
                    },
                    {
                      label: localize('levies'),
                      item: (
                        <FixedDepositTypeLevies
                          prefix={'edit-fixed-deposit-type-levies'}
                          selectedFixedDepositType={selectedFixedDepositType}
                          setSelectedFixedDepositType={
                            setSelectedFixedDepositType
                          }
                        />
                      ),
                    },
                    {
                      label: localize('offsetableLoanProducts'),
                      item: (
                        <FixedDepositTypeOffsetableLoanProducts
                          prefix={'edit-fixed-deposit-type-loan'}
                          selectedFixedDepositType={selectedFixedDepositType}
                          setSelectedFixedDepositType={
                            setSelectedFixedDepositType
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </>
    </>
  )
}
